<template>
  <div class="my-index-tree">
    <div class="list" v-for="item in indexList" :key="item.IndexId">
      <div class="no-children-main">
        <el-checkbox
          class="index-checkbox-item"
          v-model="item.checked"
          @change="() => $forceUpdate()"
          >{{ item.IndexName }}
          <el-input-number
            class="index-percent"
            size="mini"
            v-model="item.DF"
            :min="0"
            :precision="2"
            :max="99999"
          ></el-input-number
          >%
        </el-checkbox>
      </div>
      <div class="has-children-main" v-if="item.children">
        <indexTree :indexList="item.children" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "indexTree",
  props: {
    indexList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.my-index-tree {
  padding-top: 15px;
  .list {
    padding-left: 20px;
    margin-bottom: 10px;
    .has-children-main {
      .parent-name {
      }
      .children {
        padding-left: 10px;
        .children-item {
          padding: 5px 0;
          .it-checkbox-item {
            .it-index-percent {
              width: 70px;
              ::v-deep .el-input-number__decrease {
                display: none;
              }
              ::v-deep .el-input-number__increase {
                display: none;
              }
              ::v-deep .el-input__inner {
                width: 70px;
                padding: 0 5px;
              }
            }
          }
        }
      }
    }
    .no-children-main {
      border-bottom: 1px solid #f1f3f4;
      padding-bottom: 10px;
    }
    .index-checkbox-item {
      width: 70px;
      ::v-deep .el-input-number__decrease {
        display: none;
      }
      ::v-deep .el-input-number__increase {
        display: none;
      }
      ::v-deep .el-input__inner {
        width: 70px;
        padding: 0 5px;
      }
    }
  }
}
</style>
