<template>
  <div>
    <el-table
      v-loading="isLoading"
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 100%;"
    >
      <el-table-column fixed label="活动名称" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
            activitysData.find(c => c.Id === scope.row.HDId)
              ? activitysData.find(c => c.Id === scope.row.HDId).Name
              : activitysData.find(
                  c => c.EvaluationActivityType === scope.row.HDLX
                )
              ? activitysData.find(
                  c => c.EvaluationActivityType === scope.row.HDLX
                ).Name
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="个人得分影响小组" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.GRYXXZ + "%" }}</template>
      </el-table-column>
      <el-table-column label="小组得分影响个人" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.XZYXGR + "%" }}</template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button
            @click="delItem(scope.row)"
            type="text"
            size="small"
            class="tab-wz-butta c-red"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    activitysData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "/v1/growthsystem/DelPersonAndGroupSet",
            {
              Id: data.Id,
              token: this.$store.state.token,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message
                });
                this.$emit("delItem");
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>
