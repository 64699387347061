<template>
  <div class="tab-heng-box" style="margin-top: 0px;">
    <el-dialog
      width="20%"
      top="10%"
      :title="title"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form :model="form" ref="form">
        <el-form-item
          style="margin-bottom: unset;"
          prop="Name"
          :rules="[
            {
              required: true,
              message: '请输入活动类型名称',
              trigger: 'change'
            },
            {
              min: 1,
              max: 10,
              message: '不能超过10 个字符',
              trigger: 'change'
            }
          ]"
        >
          <el-input v-model="form.Name" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <div class="operate-box borderno-l">
      <div class="czq-box">
        <div class="inp-box-p">
          <el-button class="butt-box" @click="open('新增')">新增类型</el-button>
        </div>
      </div>
      <div class="czq-box">
        <!-- <div class="czq-name">状态:</div> -->
        <div class="inp-box-p">
          <el-select v-model="currentStatu" placeholder>
            <el-option
              v-for="item in status"
              :key="item.Key"
              :label="item.Value"
              :value="item.Key"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <table cellpadding="0" cellspacing="0">
      <thead>
        <tr>
          <td>类型名称</td>
          <td>状态</td>
          <td>操作</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-show="Data.length > 0"
          v-for="item in Data.filter(
            c => c.Statu === currentStatu || currentStatu === 0
          )"
          :key="item.Id"
        >
          <td>{{ item.Name }}</td>
          <td>{{ item.Statu === 1 ? "启用" : "停用" }}</td>
          <td>
            <div class="tab-wz-butt">
              <a class="tab-wz-butta" @click="open(item)">编辑</a>
              <a class="tab-wz-butta" @click="update(item)">{{
                item.Statu === 1 ? "停用" : "启用"
              }}</a>
              <a class="tab-wz-butta c-red" @click="delItem(item)">删除</a>
            </div>
          </td>
        </tr>
        <tr v-show="Data.length === 0">
          <td colspan="3">暂无数据</td>
        </tr>
      </tbody>
    </table>
    <!--页码开始-->

    <!--页码结束-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: [
        { Key: 0, Value: "全部" },
        { Key: 1, Value: "启用" },
        { Key: 2, Value: "停用" }
      ],
      currentStatu: 0,
      innerVisible: false,
      title: "",
      form: {}
    };
  },
  props: {
    Data: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    open(data) {
      this.title = "新增";
      if (data && data.Id) {
        this.form = JSON.parse(JSON.stringify(data));
        this.title = "编辑";
      }
      this.innerVisible = true;
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const user = JSON.parse(localStorage.getItem("userinfo"));
          this.$api.post(
            "/v1/curriculavariable/EditCurriculaVariableCategory",
            {
              token: this.$store.state.token,
              CategoryInfo: this.form,
              UserName: user.XSM,
              UserId: user.Id,
              SchoolId: user.SSZZId
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message
                });
                const data = r.Target;
                const newValue = {};
                newValue.Id = data.Id;
                newValue.Name = data.LXMC;
                newValue.Statu = data.SYZT;
                if (this.form.Id && this.form.Id.length > 0) {
                  this.$emit("updateCategoryList", newValue);
                } else {
                  this.$emit("updateAddCategoryList", newValue);
                }
              }
              this.innerVisible = false;
            }
          );
        } else {
          return false;
        }
      });
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const user = JSON.parse(localStorage.getItem("userinfo"));
          this.$api.post(
            "/v1/curriculavariable/DelCurriculaVariableCategory",
            {
              Id: data.Id,
              token: this.$store.state.token,
              UserName: user.XSM,
              UserId: user.Id
            },
            r => {
              let typeStatu = "success";
              if (r.Code !== 0) {
                typeStatu = "error";
              }
              this.$message({
                type: typeStatu,
                message: r.Message
              });
              if (r.Code === 0) {
                this.$emit("updateDelCategoryList", data);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    update(data) {
      const user = JSON.parse(localStorage.getItem("userinfo"));
      data.Statu = data.Statu === 1 ? 2 : 1;
      this.$api.post(
        "/v1/curriculavariable/UpdateCurriculaVariableCategory",
        {
          token: this.$store.state.token,
          Id: data.Id,
          Statu: data.Statu,
          UserName: user.XSM,
          UserId: user.Id
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });

            this.$emit("updateCategoryList", data);
          }
          this.innerVisible = false;
        }
      );
    }
  },
  watch: {
    innerVisible: function(newVal) {
      // eslint-disable-next-line no-empty
      if (newVal) {
      } else {
        this.form = {};
        // 重置校验结果
        this.$refs.form.resetFields();
      }
    }
  }
};
</script>
