<template>
  <el-table
    v-loading="isLoading"
    :data="dataTable"
    class="jf-table"
    border
    tooltip-effect="dark"
  >
    <el-table-column
      fixed
      prop="TXMC"
      label="体系名称"
      align="center"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column fixed="right" align="center" label="操作" width="550">
      <template slot-scope="scope">
        <el-button @click="editItem(scope.row)" type="text" size="small"
          >编辑</el-button
        >
        <el-button
          @click="delItem(scope.row)"
          type="text"
          size="small"
          class="tab-wz-butta c-red"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    dataTable: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    delItem(data) {
      this.$emit("delItem", data);
    }
  }
};
</script>
