<template>
  <div class="cont-whole-box">
    <el-form
      :model="formData"
      label-width="120px"
      style="display: inline-block;"
      ref="formData"
    >
      <div
        style="
            padding-bottom: 10px;
            margin-top: 20px;
            font-size: 14px;
            text-align: center;
          "
      >
        <el-form-item
          label="标题："
          prop="BT"
          :rules="[
            { required: true, message: '标题不能为空', trigger: 'change' },
            {
              min: 1,
              max: 50,
              message: '不能超过50 个字符',
              trigger: 'change'
            }
          ]"
        >
          <el-input
            style="width: 400px;"
            v-model="formData.BT"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
      </div>
      <div
        style="
            padding-bottom: 10px;
            margin-top: 20px;
            font-size: 14px;
            text-align: center;
          "
      >
        <el-form-item
          label="简介："
          prop="BZ"
          :rules="[
            { max: 200, message: '不能超过200个字符', trigger: 'change' }
          ]"
        >
          <el-input
            style="width: 400px;"
            type="textarea"
            :autosize="{ minRows: 6 }"
            v-model="formData.BZ"
            placeholder="请输入简介"
          ></el-input>
        </el-form-item>
      </div>

      <div style="margin-top: 20px; font-size: 14px;">
        <span class="c-g-9" style="display: block;">内容:</span>
      </div>
      <div
        class="whole-tit-mianbox"
        v-for="(item, index) in formData.WJTMList"
        :key="index"
      >
        <div style="font-size: 0px; box-sizing: border-box;">
          <div
            class="whole-tit-topleft"
            style="
                vertical-align: top;
                position: relative;
                top: 14px;
                height: 44px;
                width: 50px;
              "
          >
            {{ index + 1 }}.
          </div>
          <div class="whole-tit-toprig" style="height: 44px;">
            <el-form-item
              :prop="'WJTMList[' + index + '].FXMC'"
              :rules="[
                { required: true, message: '请输入题目', trigger: 'change' },
                {
                  min: 1,
                  max: 50,
                  message: '不能超过50 个字符',
                  trigger: 'change'
                }
              ]"
            >
              <el-input
                type="textarea"
                autosize
                placeholder="请输入不超过50字"
                class="jf-textarea-wj jf-form"
                v-model="item.FXMC"
              ></el-input>
            </el-form-item>
          </div>
          <div
            class="jf_sele-l-box"
            style="
                vertical-align: top;
                position: relative;
                top: 3px;
                height: 44px;
              "
          >
            <el-form-item
              :prop="'WJTMList[' + index + '].FXLX'"
              :rules="[
                {
                  required: true,
                  message: '请选择题目类型',
                  trigger: 'change'
                }
              ]"
            >
              <el-select
                v-model="item.FXLX"
                placeholder="请选择"
                size="small"
                class="jf-form"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in SSLB"
                  :key="item.Key"
                  :label="item.Value"
                  :value="item.Key"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item
            :prop="'WJDAList'"
            :rules="[
              {
                validator: checklength,
                SSLB: item.FXLX,
                message: '缺少答案',
                trigger: 'change'
              }
            ]"
            class="jf_wj_dx"
          ></el-form-item>
        </div>
        <div class="smtit-whole-box" v-if="item.FXLX != 3">
          <div class="smtit-box">
            <div
              class="smtit-cent"
              v-for="(da, i) in formData.WJDAList.filter(
                c => c.FXId == item.Id
              )"
              :key="i"
            >
              {{ ZM[i] }}.
              <el-form-item
                v-if="da.DALX == 1"
                :prop="'WJDAList[' + i + '].DAMC'"
                :rules="[
                  {
                    required: true,
                    message: '请输入答案',
                    trigger: 'change'
                  },
                  {
                    min: 1,
                    max: 20,
                    message: '不能超过20 个字符',
                    trigger: 'change'
                  }
                ]"
                style="display: inline-block;"
              >
                <el-input
                  v-model="da.DAMC"
                  size="small"
                  style="width: 300px; margin-left: 5px;"
                  class="jf-form"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <span v-else style="margin-left: 5px;">其他</span>
              <div class="tab-wz-buttl" style="margin-left: 30px;">
                <a
                  class="tab-wz-butta c-red"
                  href="javascript:;"
                  @click="delFX(da.Id)"
                  >删除</a
                >
              </div>
            </div>
          </div>
          <div class="smtit-butt-box" v-if="item.FXLX != 3">
            <div class="tab-wz-buttl" style="margin-right: 20px;">
              <a
                class="tab-wz-butta c-guree"
                href="javascript:;"
                @click="addFX(item.Id, 1)"
                >+添加普通选项</a
              >
            </div>
            <div class="tab-wz-buttl">
              <a
                v-if="
                  formData.WJDAList.findIndex(
                    c => c.FXId == item.Id && c.DALX == 2
                  ) == -1
                "
                class="tab-wz-butta c-guree"
                href="javascript:;"
                @click="addFX(item.Id, 2)"
                >+添加“其他”选项
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="bigtit-add-box">
        <div class="tab-wz-buttl">
          <a class="tab-wz-butta c-guree" href="javascript:;" @click="addItem"
            >+ 新增问题</a
          >
        </div>
      </div>
      <el-form-item
        label="发送对象："
        prop="JSDXLX"
        :rules="[
          { required: true, message: '请选择发送对象', trigger: 'change' }
        ]"
      >
        <el-row>
          <el-col>
            <el-select
              style="width: 100%;"
              v-model="formData.JSDXLX"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="formData.JSDXLX == 1">
          <el-col>
            <el-checkbox
              v-model="njall"
              label="1"
              key="1"
              @change="handleNJCheckAllChange"
              >全选</el-checkbox
            >
            <el-checkbox
              v-for="nj in njlist"
              v-model="xznjlist"
              :label="nj.Id"
              :key="nj.Id"
              @change="handleNJCheckedChange"
              >{{ nj.Name }}</el-checkbox
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="FSSJ">
        <el-row>
          <el-col>
            <el-radio
              v-model="formData.FSLX"
              label="1"
              @change="handleFSLXChange"
              >立即发送</el-radio
            >
            <el-radio
              v-model="formData.FSLX"
              label="2"
              @change="handleFSLXChange"
              >定时发送</el-radio
            >
            <el-date-picker
              v-if="formData.FSLX == 2"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="选择日期"
              v-model="formData.FSSJ"
            ></el-date-picker>
          </el-col>
        </el-row>
      </el-form-item>
      <div class="cent-bott-butt">
        <el-form-item>
          <el-button
            type="primary"
            @click="submitshowdata"
            :disabled="isDisabled"
            >保存</el-button
          >
          <el-button @click="canceladd">取消</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<style></style>
<script>
import { checkLength } from "@/style/js/Validator.js";

export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    const tempuser = localStorage.getItem("userinfo");
    this.tzggid = this.$route.params.ggid;
    if (tempuser) {
      const user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
      this.fileServerApiPath = user.FileServerApiPath;
      this.webFilePath = user.WebFilePath;
    }
  },
  computed: {
    // 文件的上传路径
    uploadURL: function() {
      return "/file/file/fileservice/UploadFile?token=" + this.token;
    }
  },
  mounted: function() {
    this.loadjyzdata();
    this.loadnjdata();
    this.loaddata();
  },
  methods: {
    // 取消
    canceladd: function() {
      this.$router.push({ name: "myquestionnairelist" });
    },
    handleNJCheckedChange: function() {
      if (this.xznjlist.length > 0) {
        this.njall = false;
      }
    },
    handleNJCheckAllChange: function(val) {
      if (val) {
        this.xznjlist = [];
      }
    },
    handleFSLXChange: function() {
      if (this.formData.FSLX === 1) {
        this.formData.FSSJ = "";
      }
    },
    handleJYZCheckAllChange: function(val) {
      if (val) {
        this.xzjyzlist = [];
      }
    },
    handleJYZCheckedChange: function() {
      if (this.xzjyzlist.length > 0) {
        this.jyzall = false;
      }
    },
    submitshowdata: function() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (
            this.formData.WJTMList === null ||
            this.formData.WJTMList.length <= 0
          ) {
            this.$message({
              type: "info",
              message: "请设置问卷题目!"
            });
            return;
          }
          let jsdx = "";
          const jsdxarr = [];
          if (this.formData.JSDXLX === "1") {
            if (this.njall) {
              jsdxarr.push({ JSDXLX: 1, DXId: "" });
            } else {
              if (this.xznjlist.length <= 0) {
                this.$message({
                  type: "info",
                  message: "请勾选发送对象!"
                });
                return;
              }
              for (let i = 0; i < this.xznjlist.length; i++) {
                jsdxarr.push({ JSDXLX: 3, DXId: this.xznjlist[i] });
              }
            }
          } else if (this.formData.JSDXLX === "2") {
            jsdxarr.push({ JSDXLX: 1, DXId: "" });
          } else if (this.formData.JSDXLX === "4") {
            if (this.njall) {
              jsdxarr.push({ JSDXLX: 4, DXId: this.bjid });
            }
          }
          jsdx = JSON.stringify(jsdxarr);
          this.isDisabled = true;
          this.$api.post(
            "v1/notice/AddQuestionnaire",
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              CJR: this.cjr,
              Title: this.formData.BT,
              BZ: this.formData.BZ,
              FSLX: this.formData.FSLX,
              FSSJ: this.formData.FSSJ,
              JSDXLX: this.formData.JSDXLX,
              JSDX: jsdx,
              WJTMList: this.formData.WJTMList,
              WJDAList: this.formData.WJDAList,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!"
                });
                this.isDisabled = false;
                this.$router.push({ name: "myquestionnairelist" });
              } else {
                this.$message({
                  type: "info",
                  message: "保存失败!"
                });
                this.isDisabled = false;
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    loaddata: function() {
      if (this.tzggid !== undefined && this.tzggid !== "") {
        this.$api.post(
          "v1/notice/GetQuestionnaire",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            Id: this.tzggid,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.formData = r.Target;
              this.formData.JSDXLX = "" + this.formData.JSDXLX + "";
              this.formData.FSLX = "" + this.formData.FSLX + "";
              const jsdxlist = r.Target.AttendList;
              this.xznjlist = [];
              for (let i = 0; i < jsdxlist.length; i++) {
                if (this.formData.JSDXLX == 1) {
                  if (jsdxlist[i].JSDXLX == 1) {
                    this.njall = true;
                    break;
                  } else {
                    this.xznjlist.push(jsdxlist[i].DXId);
                  }
                } else if (this.formData.JSDXLX == 2) {
                  if (jsdxlist[i].JSDXLX == 1) {
                    this.jyzall = true;
                    this.xzjyzlist = [];
                    break;
                  } else {
                    this.xzjyzlist.push(jsdxlist[i].DXId);
                  }
                }
              }
            }
          }
        );
      } else {
        this.$set(this.formData, "WJTMList", []);
        this.$set(this.formData, "WJDAList", []);
        this.$set(this.formData, "FSLX", "1");
        this.$set(this.formData, "JSDXLX", "1");
      }
    },
    addItem() {
      if (this.formData.WJTMList.length < 20) {
        const tmId = new Date().getTime() + "";
        this.formData.WJTMList.push({ Id: tmId, FXMC: "", FXLX: 1 });
      } else {
        this.$message({
          type: "info",
          message: "题目不能超过20个!"
        });
      }
    },
    delItem(index) {
      this.formData.WJDAList = this.formData.WJDAList.filter(
        c => c.FXId !== this.formData.WJTMList[index].Id
      );
      this.formData.WJTMList.splice(index, 1);
    },
    addFX(TMId, FXLX) {
      const dalength = this.formData.WJDAList.filter(c => c.FXId === TMId)
        .length;
      if (dalength < 8) {
        this.formData.WJDAList.push({
          Id: new Date().getTime() + "",
          FXId: TMId,
          DAMC: "",
          DALX: FXLX,
          DF: ""
        });
      } else {
        this.$message({
          type: "info",
          message: "答案数量不能超过8个!"
        });
      }
    },
    delFX(id) {
      const index = this.formData.WJDAList.findIndex(c => c.Id === id);
      this.formData.WJDAList.splice(index, 1);
    },
    loadjyzdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolTeacherGroup",
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.jyzlist = [];
            this.jyzlist = r.Target;
          }
        }
      );
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolOrg",
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.njlist = [];
            const list = r.Target;
            for (let i = 0; i < list.length; i++) {
              if (list[i].Hierarchy === 1) {
                this.njlist.push(list[i]);
              }
            }
          }
        }
      );
    }
  },
  data() {
    return {
      checklength: checkLength,
      isDisabled: false,
      webFilePath: "",
      fileServerApiPath: "",
      tzggid: "",
      ssxx: "",
      ssxq: "",
      token: "",
      xsmc: "新增",
      njall: false,
      jyzall: false,
      bjid: "",
      njlist: [],
      jyzlist: [],
      xznjlist: [],
      xzjyzlist: [],
      formData: {},
      cjr: "",
      SSLB: [
        { Key: 1, Value: "单选" },
        { Key: 2, Value: "多选" },
        { Key: 3, Value: "问答" }
      ],
      ZM: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ],
      options: [
        {
          value: "1",
          label: "学生"
        },
        {
          value: "2",
          label: "全体教师"
        },
        {
          value: "3",
          label: "全体师生"
        }
      ]
    };
  }
};
</script>
