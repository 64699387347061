<template>
  <div class="cont-whole-box school-grade">
    <div class="grade-content">
      <!-- 年级树 -->
      <el-tree
        ref="gradeTree"
        class="grade-tree"
        :data="treeData"
        :props="defaultProps"
        @node-click="nodeClick"
      />

      <!--表格区开始-->
      <div class="tab-heng-box margno-t grade-table">
        <div class="table-er-box">
          <el-button
            v-show="btnType.includes(1)"
            type="primary"
            size="mini"
            icon="el-icon-edit"
            @click="opengradeadd(1)"
            >新增小学段年级</el-button
          >
          <el-button
            v-show="btnType.includes(2)"
            type="primary"
            size="mini"
            icon="el-icon-edit"
            @click="opengradeadd(2)"
            >新增初中段年级</el-button
          >
          <el-button
            v-show="btnType.includes(4)"
            type="primary"
            size="mini"
            icon="el-icon-edit"
            @click="opengradeadd(4)"
            >新增高中段年级</el-button
          >
        </div>
        <br />
        <el-table :data="tableData" border style="width: 100%;">
          <el-table-column
            type="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="ZZJGMC"
            label="年级名称"
            width="180"
          ></el-table-column>
          <el-table-column label="入学时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.RXN | DateFormat('yyyy') }}
            </template>
          </el-table-column>
          <el-table-column
            prop="BM"
            label="年级别名"
            width="180"
          ></el-table-column>
          <el-table-column prop="SFBY" label="是否毕业" width="180">
            <template slot-scope="scope">
              <p v-if="scope.row.SFBY == '1'">是</p>
              <p v-else-if="scope.row.SFBY == '2'">否</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="editRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="openclasslist(scope.$index, scope.row)"
                >班级管理</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="resetgradenumber(scope.row.Id)"
                >重置年级数</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--表格区结束-->
    </div>
    <el-dialog
      class="grade-form"
      :title="addtitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="QYXXZZJGInfo" :rules="rules" ref="sbmitfrom">
        <el-form-item label="入学年份" :label-width="formLabelWidth" prop="RXN">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="QYXXZZJGInfo.RXN"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOption"
            style="width: 100%;"
            @change="
              () => {
                BMtypeChange(
                  this.QYXXZZJGInfo.BMtype,
                  this.QYXXZZJGInfo.BMtype === 11 ? 1 : null
                )
              }
            "
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="班级数量"
          :label-width="formLabelWidth"
          prop="XSSL"
        >
          <el-input
            v-model="QYXXZZJGInfo.XSSL"
            :disabled="isedite"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否毕业"
          :label-width="formLabelWidth"
          prop="SFBY"
        >
          <el-select
            v-model="QYXXZZJGInfo.SFBY"
            placeholder="请选择"
            style="width: 100%;"
          >
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="别名命名规则"
          :label-width="formLabelWidth"
          prop="BM"
        >
          <el-radio-group
            class="bm-group"
            v-model="QYXXZZJGInfo.BMtype"
            @change="BMtypeChange($event, 0)"
          >
            <el-radio class="bm-item" :label="3">一年级,二年级...</el-radio>
            <el-radio class="bm-item" :label="6"
              >学段首字+该学段入学年+级(例:初1999级)</el-radio
            >
            <el-radio class="bm-item" :label="9"
              >学段首字+该学段毕业年+届(例:初2001届)</el-radio
            >
            <!-- <el-radio class="bm-item" :label="10">使用年级名称</el-radio> -->
            <el-radio class="bm-item" :label="11">
              自定义
              <el-input
                class="custom-bm"
                v-model="customBM"
                @blur="BMtypeChange($event, 1)"
              >
              </el-input>
              <div
                style="position:relative;right:-73px;top:4px;color:#f56c6c;font-size:12px"
                v-show="QYXXZZJGInfo.BMtype === 11 && !customBM"
              >
                自定义别名不能为空
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年级别名" :label-width="formLabelWidth" prop="BM">
          <el-input readonly v-model="QYXXZZJGInfo.BM" style="width: 100%;" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitshowdata">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isInteger } from '@/style/js/Validator.js'

export default {
  name: 'SchoolGradeList',
  data() {
    const validXSSL = (rule, val, callback) => {
      let reg = /[1-9][0-9]*/
      if (!reg.test(val)) return callback(new Error('请输入大于0的整数'))
      else return callback()
    }
    return {
      token: this.$store.state.token,
      addtitle: '新增年级',
      schoolId: '',
      // loading:true,
      tableData: [],
      PagerInfo: {
        PageIndex: 0,
        PageSize: 10,
        RowCount: 0
      },
      isedite: false,
      QYXXZZJGInfo: {
        Id: '',
        ZZJGMC: '',
        FJId: '0',
        BM: '',
        BMtype: 3,
        BH: 0,
        RXN: '2019-10-11 00:30:59',
        SFBY: 2,
        SSXDLX: 1,
        BZ: '',
        TB: '',
        SSQYXXId: '',
        CJR: '',
        CJSJ: '2019-10-11 00:30:59',
        SYZT: 0,
        SFSC: 0,
        CJ: 0,
        XSSL: 0,
        NJSZ: 0
      },

      dialogFormVisible: false,
      formLabelWidth: '120px',
      rules: {
        RXN: [
          {
            required: true,
            message: '请选择时间',
            trigger: 'blur'
          }
        ],
        XSSL: [
          {
            required: true,
            message: '班级数量不能为空',
            trigger: 'blur'
          },
          {
            validator: validXSSL,
            trigger: 'blur'
          }
        ],
        SFBY: [
          { required: true, message: '请选择是否毕业', trigger: 'change' },
          {
            validator: isInteger,
            message: '请选择是否毕业',
            trigger: 'change'
          }
        ]
      },
      userName: '',
      userId: '',
      orgiztionbm: 2,
      // 树
      treeData: [
        {
          Id: 0,
          Name: '全部',
          isLeaf: false,
          children: []
        }
      ],
      defaultProps: {
        label: 'Name',
        children: 'children',
        isLeaf: 'isLeaf'
      },
      currentPeriod: {
        Id: 0
      },
      // 自定义别名
      customBM: '',
      // 新增年级学段
      XD: '',
      terms: [],
      pickerOption: {
        disabledDate(date) {
          let now = new Date()
          // 入学时间不能超过当前时间+6个月 且不能晚于当前学段的年级数量
          if (date.getTime() > now.getTime() + 6 * 30 * 24 * 60 * 60 * 1000) {
            return true
          } else return false
        }
      },
      schoolInfo: {},
      btnType: []
    }
  },
  async created() {
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.schoolId = userinfo.SSZZId
    this.QYXXZZJGInfo.SSQYXXId = this.schoolId
    this.QYXXZZJGInfo.CJR = userinfo.Id
    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    this.getTerms()
    await this.getSchoolInfo()
    this.handleXD()
    this.getGradeList(this.currentPeriod.Id)
  },
  methods: {
    handleSizeChange() {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.PagerInfo.PageIndex = val
      this.getGradeList(this.currentPeriod.Id)
    },
    // 刷新
    refloadlist: function() {
      this.getGradeList(this.currentPeriod.Id)
    },
    // 新增
    opengradeadd: function(type) {
      type === 1
        ? (this.XD = '小')
        : type === 2
        ? (this.XD = '初')
        : (this.XD = '高')

      this.dialogFormVisible = true
      this.addtitle = `新增${
        this.XD === '小' ? '小学' : this.XD === '初' ? '初中' : '高中'
      }年级`

      this.isedite = false // 编辑不能修改班级数量

      var schoolId = this.QYXXZZJGInfo.SSQYXXId
      var cjr = this.QYXXZZJGInfo.CJR

      this.QYXXZZJGInfo = {
        Id: '',
        ZZJGMC: '',
        FJId: '0',
        BM: '',
        BMtype: 3,
        BH: 0,
        RXN: '',
        SFBY: 2,
        SSXDLX: type,
        BZ: '',
        TB: '',
        SSQYXXId: schoolId,
        CJR: cjr,
        CJSJ: '2019-10-11 00:30:59',
        SYZT: 0,
        SFSC: 0,
        CJ: 0,
        XSSL: 0,
        NJSZ: 0,
        ClassNumber: 0
      }
    },
    // 修改
    editRow: function(index, row) {
      row.SSXDLX === 1
        ? (this.XD = '小')
        : row.SSXDLX === 2
        ? (this.XD = '初')
        : (this.XD = '高')

      this.addtitle = '修改年级'
      this.dialogFormVisible = true
      if (row.XSSL > 0) {
        this.isedite = true // 编辑不能修改班级数量
      }

      // this.QYXXZZJGInfo = row;

      this.QYXXZZJGInfo = {
        Id: row.Id,
        ZZJGMC: row.ZZJGMC,
        FJId: row.FJId,
        BM: row.BM,
        BMtype: '',
        BH: row.BH,
        RXN: row.RXN,
        SFBY: row.SFBY,
        SSXDLX: row.SSXDLX,
        BZ: row.BZ,
        TB: row.TB,
        SSQYXXId: row.SSQYXXId,
        CJR: row.CJR,
        CJSJ: row.CJSJ,
        SYZT: row.SYZT,
        SFSC: row.SFSC,
        CJ: row.CJ,
        XSSL: row.XSSL,
        NJSZ: row.NJSZ
      }
    },
    // 删除
    deleteRow: function(index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/qyxxzzjg/DeleteQYXXZZJG',
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.refloadlist()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 打开班级管理
    openclasslist: function(index, row) {
      this.$router.push({ name: 'SchoolClassList', params: { FJId: row.Id } })
    },
    // 重置年级号
    resetgradenumber: function(gradeId) {
      this.$api.post(
        'v1/qyxxzzjg/ResetGradeNumber',
        {
          GradeId: gradeId,
          SchoolId: this.schoolId,
          UserName: this.userName,
          UserId: this.userId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 保存
    submitshowdata: function() {
      let RXN = this.QYXXZZJGInfo.RXN
      let rxnArr = RXN.split('-')
      let now = new Date()
      let times = ''
      if (this.XD === '小') {
        times = 6 * 365 * 24 * 60 * 60 * 1000
      } else {
        times = 3 * 365 * 24 * 60 * 60 * 1000
      }

      if (
        !this.QYXXZZJGInfo.Id &&
        new Date(this.QYXXZZJGInfo.RXN).getTime() + times < now.getTime()
      )
        return this.$message.error('入学时间不符合规范')

      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          if (this.QYXXZZJGInfo.BMtype === 11 && !this.customBM) return

          this.BMtypeChange(
            this.QYXXZZJGInfo.BMtype,
            this.QYXXZZJGInfo.BMtype === 11 ? 1 : null
          )
          this.QYXXZZJGInfo.BMLX = this.QYXXZZJGInfo.BMtype
          this.$api.post(
            'v1/qyxxzzjg/AddQYXXZZJG',
            {
              QYXXZZJGInfo: this.QYXXZZJGInfo,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.dialogFormVisible = false

                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.refloadlist()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        } else {
          return false
        }
      })
    },
    // 获取年级
    getGradeList(Period) {
      this.$api.post(
        'v1/qyxxzzjg/GetSchoolGradeList',
        {
          SchoolId: this.schoolId,
          FJId: 0,
          Period,
          PagerInfo: {
            pageIndex: 0,
            pageSize: this.PagerInfo.PageSize
          },
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.PagerInfo.PageIndex = r.PagerInfo.PageIndex
            this.PagerInfo.RowCount = r.PagerInfo.RowCount
          }
        }
      )
    },
    nodeClick(data) {
      if (data.Id !== 0) this.currentPeriod = data
      this.getGradeList(data.Id)
    },
    // 别名命名规则变化
    BMtypeChange(val, custom) {
      console.log(val, custom, 'bm')
      if (!custom) {
        if (!this.QYXXZZJGInfo.RXN) return
        let rxnTimes = this.QYXXZZJGInfo.RXN.split('-')
        let RXN = Number(rxnTimes[0])
        let RXNMonth = Number(rxnTimes[1])
        if (val === 3) {
          let date = new Date()
          let year = date.getFullYear()
          let month = date.getMonth() + 1

          let res = ''
          // let reg = /-(\S*)/;
          let zh = [
            '零',
            '一',
            '二',
            '三',
            '四',
            '五',
            '六',
            '七',
            '八',
            '九',
            '十',
            '十一',
            '十二'
          ]

          // 入学年小于当前年
          if (RXN < year) {
            console.log(year - RXN, 'ssss')
            if (RXNMonth < 7 && month < 7) {
              RXN -= 1
              res = year - RXN
            }
            if (RXNMonth < 7 && month >= 7) {
              res = year - RXN
            }
            if (RXNMonth >= 7 && month < 7) {
              res = year - RXN
            }
            if (RXNMonth >= 7 && month >= 7) {
              year += 1
              res = year - RXN
            }
          }
          // 入学年等于当前年
          if (RXN === year) {
            if (RXNMonth < 7 && month < 7) res = 1
            if (RXNMonth < 7 && month >= 7) res = 2
            if (RXNMonth >= 7 && month < 7) res = 0
            if (RXNMonth >= 7 && month >= 7) res = 1
          }
          if (this.XD === '初') {
            res = zh[parseInt(res) + 6] + '年级'
          } else if (this.XD === '高') {
            res = zh[parseInt(res) + 9] + '年级'
          } else {
            res = zh[parseInt(res)] + '年级'
          }
          this.QYXXZZJGInfo.BM = res
        } else if (val === 6) {
          this.QYXXZZJGInfo.BM = this.XD + RXN + '级'
        } else if (val === 9) {
          let BYN = ''
          if (this.XD === '小') BYN = Number(RXN) + 6
          if (this.XD === '初') BYN = Number(RXN) + 3
          if (this.XD === '高') BYN = Number(RXN) + 3
          this.QYXXZZJGInfo.BM = this.XD + BYN + '届'
        } else if (val === 11) {
          console.log(111, '')
          this.QYXXZZJGInfo.BM = this.customBM
        }
      } else {
        this.QYXXZZJGInfo.BM = this.customBM
        // if (val === 1) {
        // }
      }
    },
    // 获取学期
    getTerms() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/xqb/GetXQBList',
          {
            AreaOrSchoolId: this.schoolId,
            token: this.token,
            PagerInfo: {
              pageIndex: 0,
              pageSize: 100
            }
          },
          res => {
            if (res.Code === 0) {
              this.terms = res.Target
              resolve()
            }
          }
        )
      })
    },
    // 获取学校信息
    getSchoolInfo() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/areaschool/GetSchool',
          { Id: this.schoolId, token: this.token },
          r => {
            if (r.Code === 0) {
              var schoolinforesult = r.Target || {}
              if (schoolinforesult !== null) {
                this.orgiztionbm = schoolinforesult.SFXSBM
                this.schoolInfo = schoolinforesult
                sessionStorage.setItem(
                  'schoolinfo',
                  JSON.stringify(schoolinforesult)
                )
              }
              resolve()
            }
          }
        )
      })
    },
    // 处理学段
    handleXD() {
      let btnType = []
      let result = []
      let arr = [
        '',
        this.schoolInfo.XXXZ,
        this.schoolInfo.CZXZ,
        '',
        this.schoolInfo.GZXD
      ]
      let str = ['', '小学', '初中', '', '高中']
      arr.forEach((item, index) => {
        if (item === 1) {
          btnType.push(index)
          result.push({
            Name: str[index],
            Id: index,
            isLeaf: true,
            children: null
          })
        }
      })
      this.btnType = btnType
      this.treeData[0].children = result
    }
  }
}
</script>

<style>
.cell {
  text-align: left;
}
</style>

<style lang="scss" scoped>
.school-grade {
  .grade-content {
    display: flex;
    padding: 10px 0 0 10px;
    .grade-tree {
      width: 200px;
      margin-right: 10px;
    }
    .grade-table {
      // overflow: auto;
      flex: 1;
    }
  }
  .grade-form {
    .bm-group {
      .bm-item {
        display: block;
        margin-bottom: 15px;
        font-size: 15px;
        .custom-bm {
          ::v-deep .el-input__inner {
            height: 25px;
          }
        }
      }
    }
  }
}
</style>
