<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <div
        class="leftrig-left-box popo-xk-centx"
        style="width: 200px; max-height: 900px;"
      >
        <!--树-->
        <el-tree
          ref="tree"
          :isLoading="treeDataIsLoading"
          :data="treeData"
          :props="defaultProps"
          node-key="Id"
          highlight-current
          @node-click="nodeClick"
        ></el-tree>
      </div>
      <div class="leftrig-rig-box" style="width: calc(100% - 201px);">
        <div class="margno-t">
          <div
            class="table-er-box"
            v-if="treeData.length > 0 && mainActivityId"
          >
            <div class="table-er-butt" @click="openDialog({})">
              <img
                class="table-er-buttimg"
                src="@/style/images/icon_ejdaa.png"
              />
              <span class="table-er-buttspan">新增</span>
            </div>
            <div style="display:inline-block">
              <div style="display:inline-block;margin-left:10px">
                <span style="font-size:14px">活动名称: </span>
                <el-input
                  @input="searchData"
                  style="width:120px"
                  size="medium"
                  v-model="searchName"
                  placeholder="请输入活动名称"
                ></el-input>
              </div>
            </div>
          </div>
          <Table
            :dataTable="dataTable"
            :basicData="basicData"
            :objectList="objectList"
            :isLoading="tableDataIsLoading"
            :currentActivity="currentActivity"
            @editItem="openDialog"
            @delItem="delItem"
            @setStage="setStage"
            @setDescription="setDescription"
          />
          <div class="pagin-box">
            <div class="pagin-wz">
              <!--分页-->
              <Pager
                :pagerInfo="pagerInfo"
                :total="this.pagerInfo.totalCount"
                @changeIndex="changeIndex"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Form
      :formData="formData"
      :evaluationObjectJoinType="this.basicData.EvaluationObjectJoinType"
      :PJFS="currentActivity.PJFS"
      :hdxs="handleHDXS(currentActivity.HDXS)"
      :currentActivity="currentActivity"
      :objectList="currentActivity.ObjectList"
      :visible="formVisible"
      :title="formTitle"
      :token="token"
      :user="user"
      :schoolId="schoolId"
      :term="currentTerm"
      :mainActivityId="mainActivityId"
      @closeDialog="closeDialog"
    />
    <!-- 评价阶段 -->
    <Stage
      :visible.sync="stage.visible"
      :currentActivity="currentActivity"
      :sonActivity="stage.sonActivity"
      :term="currentTerm"
      @closeStage="stage.visible = false"
    />
    <!-- 设置描述语 -->
    <Description
      :visible.sync="description.visible"
      :currentActivity="currentActivity"
      :sonActivity="description.sonActivity"
      :term="currentTerm"
      @closeDescription="description.visible = false"
    />
  </div>
</template>

<script>
import Pager from '@/components/Pager.vue'
import Table from './Table.vue'
import Form from './Form.vue'
import Stage from './evaluationTime/Index.vue'
import Description from './setDescription/Index.vue'

export default {
  components: {
    Table,
    Form,
    Pager,
    Stage,
    Description
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      defaultProps: {
        label: 'PJHDMC'
      },
      token: '',
      user: {},
      schoolId: '',
      treeDataIsLoading: false,
      treeData: [],
      dataTable: [],
      objectList: [],
      tableDataIsLoading: false,
      mainActivityId: '',
      formVisible: false,
      formData: {},
      formTitle: '',
      basicData: {
        ObjectList: []
      },
      searchName: '',
      // 当前活动
      currentActivity: {},
      currentTerm: {},
      stage: {
        visible: false,
        sonActivity: {}
      },
      description: {
        visible: false,
        sonActivity: {}
      }
    }
  },
  mounted: async function() {
    this.token = this.$store.state.token
    this.user = JSON.parse(localStorage.getItem('userinfo'))
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId
      }
    }
    this.getBasic()
  },
  methods: {
    // 获取基本数据
    getBasic() {
      this.treeDataIsLoading = true
      this.$api.post(
        '/v1/evaluationmainactivity/GetEvaluationMainActivities',
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.basicData = r.Target
            this.currentTerm = this.basicData.TermList.find(
              c => c.IsCurrent === 1
            )
            this.treeData = this.basicData.MainActivityList.sort((a, b) =>
              a.PJHDMC.localeCompare(b.PJHDMC)
            )
            if (
              this.basicData &&
              this.basicData.MainActivityList &&
              this.basicData.MainActivityList.length > 0
            ) {
              this.currentActivity = this.basicData.MainActivityList[0]

              this.nodeClick(this.basicData.MainActivityList[0])
              this.$nextTick(function() {
                this.$refs.tree.setCurrentKey(this.treeData[0].Id)
              })
            }
          }
        }
      )
    },
    // 获取活动的所有对象
    getObject(Eva) {
      this.$api.post(
        '/v1/evaluationobject/GetEvaluationObjectList',
        {
          HDXS: 0,
          EvaId: Eva.Id,
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            Eva.ObjectList = r.Target || []
            this.objectList = r.Target || []
          }
        }
      )
    },
    // 获取子活动 也就是表格数据
    getData() {
      this.tableDataIsLoading = true
      let {
        pageIndex: PageIndex,
        pageSize: PageSize,
        totalCount: RowCount
      } = this.pagerInfo

      this.$api.post(
        '/v1/evaluationmainactivity/GetActivityListForWeb',
        {
          // TermId: this.termFilterId,现在要获取主活动的所有子活动
          SearchName: this.searchName,
          token: this.token,
          ParentId: this.mainActivityId,
          PagerInfo: {
            PageIndex,
            PageSize,
            RowCount
          }
        },
        r => {
          if (r.Code === 0) {
            console.log(r.Target)
            this.dataTable = r.Target || []
            this.pagerInfo.totalCount = this.dataTable.length
            this.tableDataIsLoading = false
          }
        },
        err => {
          this.tableDataIsLoading = false
        }
      )
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val
      this.getData()
    },
    searchData() {
      this.changeIndex(1)
      this.getData()
    },
    // 左侧树点击
    nodeClick(data) {
      this.mainActivityId = data.Id
      this.currentActivity = data
      this.pagerInfo.pageIndex = 1
      this.getObject(data)
      this.getData()
    },
    openDialog(data) {
      this.formData = {}
      this.formTitle = '新增'
      if (JSON.stringify(data) !== '{}') {
        this.formData = data
        this.formTitle = '编辑'
      }
      this.formVisible = true
    },
    closeDialog(data) {
      if (data && Object.keys(data).length !== 0) {
        this.pagerInfo.pageIndex = 1
        this.getData()
      }
      this.formVisible = false
    },
    delItem(data) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.post(
            '/v1/evaluationmainactivity/DelActivity',
            {
              token: this.token,
              Id: data.Id,
              SchoolId: this.schoolId,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                  showClose: true
                })
              }
              this.pagerInfo.pageIndex = 1
              this.getData()
            }
          )
        })
        .catch(() => {})
    },
    // 处理评价方式
    handleHDXS(val) {
      let hdxs = []
      switch (val) {
        case 1:
          hdxs = [1]
          break
        case 2:
          hdxs = [2]
          break
        case 3:
          hdxs = [1, 2]
          break
        case 4:
          hdxs = [4]
          break
        case 5:
          hdxs = [1, 4]
          break
        case 6:
          hdxs = [2, 4]
          break
        case 7:
          hdxs = [1, 2, 4]
          break
      }
      return hdxs
    },
    // 学期变化
    termChange() {
      this.pagerInfo.pageIndex = 1
      this.getData()
    },
    // 设置阶段评价
    setStage(row) {
      this.stage.visible = true
      this.stage.sonActivity = row
    },
    // 设置描述语
    setDescription(row) {
      if (row !== null) {
        this.$api.post(
          '/v1/evaluationactivity/GetSZMSY',
          {
            id: row.Id,
            hdid: row.HDId,
            PJHDGLCYDXList: row.PJHDGLCYDXList.toString(),
            token: this.token
          },
          r => {
            if (r.Code === 0 && r.Target.length <= 0) {
              this.$message({
                type: 'error',
                message: '无指标数据'
              })
            } else {
              this.description.visible = true
              this.description.sonActivity = row
            }
          }
        )
      }
    }
  }
}
</script>
