<template>
  <div class="cont-whole-box">
    <!--内容操作区结束-->
    <div class="operating-btns" style="padding:20px 10px">
      <el-button
        size="small"
        icon="el-icon-download"
        style="margin-right:12px"
        @click="exportExcel(0)"
        >下载模版</el-button
      >
      <el-upload
        style="display:inline-block"
        class="upload-demo"
        ref="upload"
        accept=".xls, .xlsx"
        :action="uploadURL"
        :on-change="uploadrfid"
        :show-file-list="false"
        :auto-upload="false"
      >
        <el-button size="small" icon="el-icon-upload2">导入</el-button>
      </el-upload>
      <el-button
        size="small"
        icon="el-icon-download"
        style="margin-left:8px"
        @click="exportExcel(1)"
        >导出</el-button
      >
      <el-button
        size="small"
        @click="submit"
        icon="el-icon-finished"
        :disabled="!jd.state"
        type="primary"
        >保存</el-button
      >
      <el-button
        size="small"
        icon="el-icon-back"
        type="warning"
        @click="
          $router.replace({
            name: 'stagecourse',
          })
        "
        >返回</el-button
      >
      <div class="filter-btns">
        <div class="label">排序：</div>
        <el-select
          v-model="order"
          placeholder="请选择排序方式"
          size="small"
          @change="orderChange"
        >
          <el-option
            v-for="item in orderOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <div class="search">
          <el-input
            v-model="search"
            size="small"
            placeholder="请输入名字或者学号"
          >
          </el-input>
          <el-button
            icon="el-icon-search"
            type="primary"
            size="small"
            @click="handleSearch"
          ></el-button>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="findEmpty"
          >查找未评价行</el-button
        >
      </div>
    </div>
    <!--表格区开始-->
    <el-form class="data-page1-form" :model="form" ref="form">
      <div style="font-size:12px;color:#ccc;padding-left:20px;">
        *鼠标单击列表头部可选择列 鼠标右键列表头部可批量修改
      </div>
      <el-table
        ref="table"
        v-loading="uploading"
        height="500"
        border
        highlight-current-row
        :data="form.bpr"
        :cell-class-name="cellClassHandle"
        :header-cell-class-name="headerClassHandle"
        @header-click="headerClick"
        @header-contextmenu="headerContextMenu"
      >
        <template v-for="(item, index) in indexList">
          <el-table-column
            :key="item.key"
            :index="index"
            :prop="index === 0 ? item.bprName : item.key"
            :min-width="index === 0 ? 90 : 120"
            :fixed="index === 0 ? true : false"
          >
            <template slot="header" slot-scope="scope">
              <div class="table-header-item">
                {{ index === 0 ? "被评人" : `${item.TMMC}` }}
                <span
                  class="header-icon"
                  v-if="index !== 0 && indexList[index].SSLB !== 3"
                  @click.stop="showDAinfo(scope)"
                  ><i class="el-icon-info"></i
                ></span>
              </div>
            </template>
            <template slot-scope="scope">
              <span v-if="index === 0">{{
                scope.row[scope.column.property]
              }}</span>
              <el-form-item
                v-else
                :prop="'bpr[' + scope.$index + ']' + '.' + item.key"
              >
                <template>
                  <el-select
                    v-if="item.SSLB !== 3"
                    :disabled="!jd.state"
                    :multiple="item.SSLB === 2"
                    size="small"
                    clearable
                    v-model="scope.row[scope.column.property]"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(el, idx) in item.WJFXBList"
                      :key="el.Id"
                      :label="letter[idx]"
                      :value="el.Id"
                    >
                      <span>{{ letter[idx] }}</span>
                    </el-option>
                  </el-select>
                  <el-input
                    v-else
                    size="small"
                    clearable
                    :readonly="!jd.state"
                    v-model="scope.row[scope.column.property]"
                    placeholder="请输入"
                  ></el-input>
                </template>
              </el-form-item>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </el-form>
    <!-- 批量填充弹窗 -->
    <el-dialog
      title="自动填充"
      :visible.sync="batch.visible"
      width="40%"
      :before-close="batchSubmit"
    >
      <el-form
        class="batch-form"
        ref="batchForm"
        :model="batch"
        :rules="batch.rules"
        label-width="80px"
      >
        <el-form-item v-show="batch.selectVisible" label="批量选择" prop="val">
          <el-select
            style="width:100%"
            v-model="batch.val"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, idx) in batch.letter"
              :key="idx"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="batch.textVisible" label="批量输入" prop="val">
          <el-input v-model="batch.text" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="batch-footer">
        <el-button @click="batchSubmit">取 消</el-button>
        <el-button type="primary" @click="batchSubmit(1)">确 定</el-button>
        <el-button type="primary" @click="empty">清空所选列</el-button>
      </span>
    </el-dialog>
    <!-- 答案详情 -->
    <el-dialog
      :title="`${DAinfo.title}详情`"
      :visible.sync="DAinfo.visible"
      width="30%"
      :before-close="() => (DAinfo.visible = false)"
    >
      <div class="answer-info" v-for="item in DAinfo.data" :key="item.Id">
        <div class="letter">{{ item.TMQZ }}</div>
        <div class="answer-item">
          {{ item.FXLX === 2 ? "其它" : item.DAMC }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  naem: "stagedata",
  data() {
    return {
      ssxq: "",
      form: {
        bpr: [],
      },
      // 批量填充弹窗
      batch: {
        letter: [],
        visible: false,
        val: "",
        selectVisible: false,
        text: "",
        textVisible: false,
        rules: {
          val: [
            {
              required: true,
              message: "不能为空",
              trigger: "blur",
            },
          ],
        },
      },
      term: {},
      jd: {},
      nj: {},
      hd: {},
      bj: {},
      kc: {},
      indexList: [], //问卷题目
      index: {}, //问卷
      letter: [], //字母数组
      selectedColumn: [], //被点击的列头
      DAinfo: {
        //答案详情
        title: "",
        visible: false,
        data: [],
      },
      publicPath: process.env.BASE_URL,
      uploading: false,
      oldEvaluation: [], //旧评价 用于比较是否修改 没有修改的不传
      // 排序
      order: 1,
      orderOptions: [
        {
          label: "姓名",
          value: 1,
        },
        {
          label: "学号",
          value: 2,
        },
      ],
      // 搜索
      search: "",
      userInfo: {},
    };
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return (
        "/file/file/fileservice/UploadFile?token=" + this.$store.state.token
      );
    },
  },
  async created() {
    this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
    this.ssxq = localStorage.getItem("currentterm");
    let { hd, bj, kc, index, bpr, nj, term, jd } = this.$route.params;
    this.indexList = JSON.parse(JSON.stringify(index));
    this.term = term;
    this.hd = hd;
    this.bj = bj;
    this.kc = kc;
    bpr.sort((a, b) => a.Name.localeCompare(b.Name));
    this.form.bpr = bpr;
    this.nj = nj;
    this.jd = jd;

    if (
      this.hd.CourseTableType !== 3 &&
      this.hd.EvaluatedObject === 1 &&
      this.indexList.some((item) => item.xk && item.xk.length > 0)
    ) {
      await this.getClassCourse();
    }

    this.indexList.forEach((item) => {
      this.form.bpr.forEach((it) => {
        this.$set(it, item.key, "");
      });
    });
    this.indexList.unshift({
      bprName: "Name",
    });

    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      this.user = JSON.parse(tempuser);
    }
    for (let i = 65; i < 70; i++) {
      this.letter.push(String.fromCharCode(i));
    }
    this.getMyEvalu();
  },
  beforeRouteLeave(to, from, next) {
    from.meta.title = "";
    next();
  },
  methods: {
    // 单机列头
    headerClick(column) {
      if (!this.jd.state || column.index === 0) return;
      let idx = this.selectedColumn.findIndex(
        (item) => item.index === column.index
      );
      if (idx === -1)
        this.selectedColumn.push({
          index: column.index,
          id: column.property,
        });
      else
        this.selectedColumn = this.selectedColumn.filter(
          (item) => item.index !== column.index
        );
    },
    // 右击列头
    headerContextMenu(column) {
      if (this.selectedColumn.length === 0) return false;
      document.oncontextmenu = () => false;

      // 找出所选的列 筛选出最多选项的那个 然后弹出的下拉的最多选项就以这个为准
      let arr = this.indexList.filter(
        (item) =>
          this.selectedColumn.some((it) => it.id === item.key) &&
          item.SSLB !== 3
      );
      let lengthArr = arr.map((item) => item.WJFXBList.length);
      let maxOne = Math.max(...lengthArr);
      let result = arr.find((item) => item.WJFXBList.length === maxOne);
      let letterArr = result.WJFXBList.map((it) => it.TMQZ);
      letterArr.sort((a, b) => a.localeCompare(b));
      this.batch.letter = letterArr;

      this.batch.visible = true;
      this.batch.textVisible = this.selectedColumn.some(
        (el) => this.indexList[el.index] && this.indexList[el.index].SSLB === 3
      );
      this.batch.selectVisible = this.selectedColumn.some(
        (el) => this.indexList[el.index] && this.indexList[el.index].SSLB !== 3
      );
    },
    // 单元格类名回调函数
    cellClassHandle({ column }) {
      if (this.selectedColumn.some((item) => item.index === column.index))
        return "my-cell-class";
      else return "";
    },
    // 标头单元格类名回调函数
    headerClassHandle({ column }) {
      if (this.selectedColumn.some((item) => item.index === column.index))
        return "my-header-class";
      else return "";
    },
    // 显示答案详情
    showDAinfo(row) {
      this.DAinfo.visible = true;
      let data = this.indexList[row.$index];
      this.DAinfo.title = data.TMMC;
      this.DAinfo.data = data.WJFXBList;
    },
    submit() {
      let bpr = this.handleBPR();
      if (bpr.length === 0) return;

      this.$api.post(
        "v1/evaluationactivity/SaveUserEvalutionContent",
        {
          EvationId: this.hd.Id, //主活动或子活动id                string
          MainEvationId: this.hd.parentId ? this.hd.parentId : "", //子活动的父id               string
          EvationName: this.hd.Name, //活动名称               string
          ClassId: this.bj.Id || "", //班级Id               string
          GradeId: this.nj.Id || "", //年级Id               string
          NJ: this.nj.number ? Number(this.nj.number) : this.nj.GradeNumber, //年级数字
          EvalutionLevel: this.hd.parentId ? 2 : 1, //1主活动2子活动         int
          EvFrequency: 2, //1多次评价2单次评价 阶段活动只能单次评价         int
          UserId: this.user.Id, //创建人           string
          UserType: this.hd.HDCPR, //参评人用户类型      int
          EvaluatedObject: this.hd.EvaluatedObject, //被评价对象类型1学生2教师3班级4小组       int
          PJR: this.user.Id, //小助手评价时传教师Id                          string
          CollegePeopleList: bpr, //被评价人            Array
          // EalvtionindexList: [], //评价指标（对象中新增星星指标每颗星分数             [Array]
          SchoolId: this.user.SSZZId, //
          SchoolCode: this.user.SchoolCode,
          TermYear: this.term.SSXN,
          TermId: this.term.Id,
          TermType: this.term.XQLX, //学期上下期类型
          EvalSourse: 4, //评价来源（1Ios主动评价 、2 扫码评价 3被动采集,4评价客户端，5安卓主动评价 6.H5主动评价）
          DataSourseType: 3, //1.自定义活动-通用2.自定义活动-星星 3自定义活动-问卷
          HDXX: 4, //活动形式:4主评2互评1 自评
          HDLX: 2, //活动类型1.过程活动2.阶段性活动
          StageId: this.jd.Id, //阶段id
          CourseId: this.kc.courseId || null, //课程Id
          PJWJList: this.handlWJ(),
          token: this.user.Token,
        },
        (res) => {
          if (res.Code === 0) {
            this.$message.success("保存成功");
            this.oldEvaluation = JSON.parse(JSON.stringify(this.form.bpr)); //将保存成功后的值作为旧值
          }
        }
      );
    },
    // 处理被评人和指标
    handleBPR() {
      /// 被评人
      let CollegePeopleList = [];

      this.form.bpr.forEach((bpr) => {
        // 找到以前评价的记录
        let oldBpr = this.oldEvaluation.find((item) => item.Id === bpr.Id);
        let EalvtionindexList = [];
        let valueChanged = false;
        this.indexList.some((item) => {
          // 指标答案类型 SSLB：1.单选 2.多选 3.问答
          // 判断被评人的属性中是否有当前指标对应的属性
          if (Object.keys(bpr).some((key) => key === item.key)) {
            // 判断bpr之前的数据和现在的是否有变化 这个要根据指标的类型分别判断
            if (item.SSLB === 2) {
              // 多选是数组 需要遍历对比
              // 如果之前没值 现在有值
              if (
                (!oldBpr ||
                  !oldBpr[item.key] ||
                  oldBpr[item.key].length === 0) &&
                bpr[item.key] &&
                bpr[item.key].length > 0
              )
                return (valueChanged = true);
              // 如果之前有值 现在没值
              if (
                oldBpr &&
                oldBpr[item.key] &&
                oldBpr[item.key].length > 0 &&
                (!bpr[item.key] || bpr[item.key].length === 0)
              ) {
                return (valueChanged = true);
              }
              // 如果之前有值 现在有值且数组长度不等
              if (
                oldBpr &&
                oldBpr[item.key] &&
                oldBpr[item.key].length > 0 &&
                bpr[item.key] &&
                bpr[item.key].length > 0 &&
                oldBpr[item.key].length !== bpr[item.key].length
              )
                return (valueChanged = true);
              //  如果之前有值 现在有值 且数组相等 需要遍历比较里面的每一个是否相等
              if (
                oldBpr &&
                oldBpr[item.key] &&
                oldBpr[item.key].length > 0 &&
                bpr[item.key] &&
                bpr[item.key].length > 0 &&
                oldBpr[item.key].length === bpr[item.key].length
              ) {
                // 旧值中的某一个在新值中没有
                if (
                  oldBpr[item.key].some(
                    (el) => !bpr[item.key].some((e) => e === el)
                  )
                )
                  return (valueChanged = true);
                // 新值中的某一个在旧值中没有
                if (
                  bpr[item.key].some(
                    (el) => !oldBpr[item.key].some((e) => e === el)
                  )
                )
                  return (valueChanged = true);
              }
            } else {
              // 旧值没有且当前有值 或者 旧值和当前值不相等
              if (
                ((!oldBpr || !oldBpr[item.key]) && bpr[item.Id]) ||
                (oldBpr && oldBpr[item.key] !== bpr[item.key])
              )
                return (valueChanged = true);
            }
          }
        });
        if (valueChanged) {
          this.indexList.forEach((item) => {
            if (Object.keys(bpr).some((key) => key === item.key)) {
              if (item.SSLB === 3) {
                // 问答
                // 不管之前有没有值 那么判断现在有咩有值 有值才传
                if (bpr[item.key].trim()) {
                  EalvtionindexList.push({
                    Id: item.Id, /// 答案Id (问答类型没有答案id 所以传的题目id)
                    IndexName: null, //答案名称
                    IndexPicture: null, //指标图片
                    IndexType: 3, /// 指标类型1通用2星星3问卷
                    // Score: , /// 指标分数（根据指标类型传正负分数）
                    // StarScore: '', ///星星评价每颗星分数
                    PIndexId: item.Id, /// 题目Id
                    Description: bpr[item.key].trim(), /// 单个指标描述以及问卷问答答案
                    ObjectId: item.objId, /// 所属对象Id
                    WJId: item.WJId, //问卷id
                    WJCourseId: item.xkId || "", //题目所属学科
                    MarkType: 2, /// 指标类型1加分2减分3 中评(1 普通选项2其他选项)
                    // AattachmentList: [] /// 指标单一附件
                    FJBS: bpr[`${item.key}FJBS`],
                  });
                }
              } else if (item.SSLB === 2) {
                // 多选
                let DA = [];
                if (bpr[item.key] && bpr[item.key].length > 0) {
                  DA = bpr[item.key].reduce((pre, cur) => {
                    pre.push(item.WJFXBList.find((DA) => DA.Id === cur));
                    return pre;
                  }, []);
                }
                if (DA.length > 0) {
                  let arr = DA.reduce((pre, cur) => {
                    pre.push({
                      Id: cur.Id || null, /// 答案Id
                      IndexName: cur.DAMC || null, //答案名称
                      IndexPicture: cur.TB || null, //指标图片
                      IndexType: 3, /// 指标类型1通用2星星3问卷
                      Score: cur.DF || null, /// 指标分数（根据指标类型传正负分数）
                      // StarScore: '', ///星星评价每颗星分数
                      PIndexId: item.Id, /// 指标父Id
                      Description: null, /// 单个指标描述以及问卷问答答案
                      ObjectId: item.objId, /// 所属对象Id
                      WJId: item.WJId, //问卷id
                      WJCourseId: item.xkId || null, //题目所属学科
                      MarkType: cur.FXLX || 2, /// 指标类型1加分2减分3 中评(1 普通选项2其他选项)
                      // AattachmentList: [], /// 指标单一附件
                      FJBS: bpr[`${item.key}FJBS`] || null,
                      Remark: bpr[`${item.key}Remark`] || null,
                    });
                    return pre;
                  }, []);
                  EalvtionindexList = EalvtionindexList.concat(arr);
                }
              } else {
                // 单选
                let DA = "";
                if (bpr[item.key]) {
                  DA = item.WJFXBList.find((DA) => DA.Id === bpr[item.key]);
                }
                if (DA) {
                  EalvtionindexList.push({
                    Id: DA ? DA.Id : null, /// 答案Id
                    IndexName: DA ? DA.DAMC : null, //答案名称
                    IndexPicture: DA ? DA.TB || null : null, //指标图片
                    IndexType: 3, /// 指标类型1通用2星星3问卷
                    Score: DA ? DA.DF : null, /// 指标分数（根据指标类型传正负分数）
                    // StarScore: '', ///星星评价每颗星分数
                    PIndexId: item.Id, /// 指标父Id
                    Description: null, /// 单个指标描述以及问卷问答答案
                    ObjectId: item.objId, /// 所属对象Id
                    WJId: item.WJId, //问卷id
                    WJCourseId: item.xkId || "", //题目所属学科
                    MarkType: DA.FXLX, /// 指标类型1加分2减分3 中评(1 普通选项2其他选项)
                    // AattachmentList: [], /// 指标单一附件
                    FJBS: DA ? bpr[`${item.key}FJBS`] : null,
                    Remark: DA ? bpr[`${item.key}Remark`] : null,
                  });
                }
              }
            }
          });
        }

        if (EalvtionindexList && EalvtionindexList.length > 0) {
          CollegePeopleList.push({
            CollegePeopleId: bpr.Id, /// 被评人id
            Name: bpr.Name, /// 名称
            EalvtionindexList,
          });
        }
      });

      return CollegePeopleList;
    },
    // 处理问卷
    handlWJ() {
      // 一个问卷可能有多个对象关联 所以要以问卷id和对象id来区分每一条
      let arr = [];
      this.indexList.forEach((TM, index) => {
        if (index !== 0) {
          // 两个都不等
          if (!arr.find((it) => it.WJId === TM.WJId && it.CPDX === TM.objId)) {
            arr.push({
              CPDX: TM.objId,
              WJId: TM.WJId,
              WJName: TM.WJMC,
              WJCoure: [],
            });
          }
        }
      });
      this.indexList.forEach((TM, index) => {
        if (index !== 0) {
          let WJ_Obj = arr.find(
            (WJ) => WJ.CPDX === TM.objId && WJ.WJId === TM.WJId
          );
          if (TM.xk && WJ_Obj) {
            TM.xk.forEach((item) => {
              if (!WJ_Obj.WJCoure.find((it) => it.CourseId !== item.id)) {
                WJ_Obj.WJCoure.push({
                  CourseId: item.id,
                  CourseName: item.name,
                });
              }
            });
          }
        }
      });
      return arr;
    },
    // 批量填充确认
    batchSubmit(type) {
      if (type === 1) {
        let idx = this.letter.findIndex((item) => this.batch.val === item);
        this.selectedColumn.forEach((el) => {
          this.form.bpr.forEach((bpr) => {
            if (
              this.indexList[el.index].WJFXBList &&
              this.indexList[el.index].WJFXBList.length > 0 &&
              this.indexList[el.index].WJFXBList[idx]
            )
              this.$set(
                bpr,
                el.key ? el.key : el.id,
                this.indexList[el.index].WJFXBList[idx].Id
              );

            if (this.batch.textVisible && this.indexList[el.index].SSLB === 3)
              this.$set(bpr, el.key ? el.key : el.id, this.batch.text);
          });
        });
        this.batch.visible = false;
      } else {
        this.batch.visible = false;
        document.oncontextmenu = () => true;
      }
    },
    //批量清空
    empty() {
      this.$confirm("确认清空所选列?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.selectedColumn.forEach((el) => {
            this.form.bpr.forEach((bpr) => {
              this.$set(bpr, el.key ? el.key : el.id, "");
            });
          });
          this.batch.visible = false;
        })
        .catch(() => {});
    },
    // 获取用户评价答案 即参评人评价记录
    getMyEvalu() {
      this.$api.post(
        "v1/evaluationactivity/GetUserQuestionnaireAnswer",
        {
          EvaId: this.hd.Id, //活动id
          StageId: this.jd.Id, //	是	string	阶段Id(阶段优先)
          // WJId: this.indexList[1].WJId, //	是	string	问卷Id
          KCId: this.kc.courseId || null,
          BJId: this.bj.Id || null,
          NJId: this.nj.Id || null,
          UserId: this.user.Id, //	是	string	用户Id
          TermYear: this.term.SSXN, //	是	string	学年
          TermType: this.term.XQLX, //	是	int	学期类型
          token: this.user.Token,
        },
        (res) => {
          if (res.Code === 0) {
            this.form.bpr.forEach((bpr) => {
              let multiple = []; //用来收集每个人的多选答案
              res.Target.forEach((item) => {
                if (bpr.Id === item.BPJR) {
                  if (item.KCId) {
                    //有课程
                    let index = "";
                    if (item.SSLB === 2) {
                      //这里有可能是其它选项或者问答
                      if (item.TMId !== item.FXId) {
                        // 如果不等 这里有可能是ios的问答或者其它选项
                        let res = this.indexList.find(
                          (el) => el.Id === item.FXId
                        );
                        if (res) {
                          //有值就是问答
                          index = this.indexList.find(
                            (index) =>
                              index.Id === item.FXId &&
                              index.key &&
                              index.key.includes(item.FXId + item.KCId)
                          );
                        } else {
                          // 没值就是其他
                          index = this.indexList.find(
                            (index) =>
                              index.Id === item.TMId &&
                              index.key &&
                              index.key.includes(item.TMId + item.KCId)
                          );
                        }
                      } else {
                        // 如果相等 这是安卓的数据
                        index = this.indexList.find(
                          (index) =>
                            index.Id === item.TMId &&
                            index.key &&
                            index.key.includes(item.TMId + item.KCId)
                        );
                      }
                    } else {
                      //普通选项
                      index = this.indexList.find(
                        (index) =>
                          index.Id === item.TMId &&
                          index.key &&
                          index.key.includes(item.TMId + item.KCId)
                      );
                    }
                    if (index) {
                      if (index.SSLB === 3) {
                        if (item.TMId && item.FXId && item.TMId !== item.FXId) {
                          bpr[item.FXId + item.KCId] = item.MS;
                        } else {
                          bpr[item.TMId + item.KCId] = item.MS;
                        }
                      } else if (index.SSLB === 2) {
                        multiple.push(item);
                      } else {
                        let idx = index.WJFXBList.findIndex(
                          (fx) => item.FXId === fx.Id
                        );
                        if (idx !== -1) bpr[item.TMId + item.KCId] = item.FXId;
                      }
                      bpr[[`${index.key}FJBS`]] = item.GJBS;
                      bpr[[`${index.key}Remark`]] = item.PJNR;
                    } else {
                      let index = "";
                      if (item.SSLB === 2) {
                        if (item.TMId !== item.FXId) {
                          let res = this.indexList.find(
                            (el) => el.Id === item.FXId
                          );
                          if (res) {
                            index = this.indexList.find(
                              (index) =>
                                index.Id === item.FXId &&
                                index.key &&
                                index.key.includes(item.FXId)
                            );
                          } else {
                            index = this.indexList.find(
                              (index) =>
                                index.Id === item.TMId &&
                                index.key &&
                                index.key.includes(item.TMId)
                            );
                          }
                        } else {
                          index = this.indexList.find(
                            (index) =>
                              index.Id === item.TMId &&
                              index.key &&
                              index.key.includes(item.TMId)
                          );
                        }
                      } else {
                        index = this.indexList.find(
                          (index) =>
                            index.Id === item.TMId &&
                            index.key &&
                            index.key.includes(item.TMId)
                        );
                      }
                      if (index) {
                        if (index.SSLB === 3) {
                          if (
                            item.TMId &&
                            item.FXId &&
                            item.TMId !== item.FXId
                          ) {
                            bpr[item.FXId] = item.MS;
                          } else {
                            bpr[item.TMId] = item.MS;
                          }
                        } else if (index.SSLB === 2) {
                          multiple.push(item);
                        } else {
                          let res = this.indexList.find(
                            (Index) => Index.Id === item.TMId
                          );
                          if (res) {
                            let idx = res.WJFXBList.findIndex(
                              (fx) => fx.Id === item.FXId
                            );
                            if (idx !== -1) bpr[item.TMId] = item.FXId;
                          }
                        }
                        bpr[[`${index.key}FJBS`]] = item.GJBS;
                        bpr[[`${index.key}Remark`]] = item.PJNR;
                      }
                    }
                  } else {
                    let index = "";
                    if (item.SSLB === 2) {
                      if (item.TMId !== item.FXId) {
                        let res = this.indexList.find(
                          (el) => el.Id === item.FXId
                        );
                        if (res) {
                          index = this.indexList.find(
                            (index) =>
                              index.Id === item.FXId &&
                              index.key &&
                              index.key.includes(item.FXId)
                          );
                        } else {
                          index = this.indexList.find(
                            (index) =>
                              index.Id === item.TMId &&
                              index.key &&
                              index.key.includes(item.TMId)
                          );
                        }
                      } else {
                        index = this.indexList.find(
                          (index) =>
                            index.Id === item.TMId &&
                            index.key &&
                            index.key.includes(item.TMId)
                        );
                      }
                    } else {
                      index = this.indexList.find(
                        (index) =>
                          index.Id === item.TMId &&
                          index.key &&
                          index.key.includes(item.TMId)
                      );
                    }
                    if (index) {
                      if (index.SSLB === 3) {
                        if (item.TMId && item.FXId && item.TMId !== item.FXId) {
                          bpr[item.FXId] = item.MS;
                        } else {
                          bpr[item.TMId] = item.MS;
                        }
                      } else if (index.SSLB === 2) {
                        multiple.push(item);
                      } else {
                        let res = this.indexList.find(
                          (Index) => Index.Id === item.TMId
                        );
                        if (res) {
                          let idx = res.WJFXBList.findIndex(
                            (fx) => fx.Id === item.FXId
                          );
                          if (idx !== -1) bpr[item.TMId] = item.FXId;
                        }
                      }
                      bpr[[`${index.key}FJBS`]] = item.GJBS;
                      bpr[[`${index.key}Remark`]] = item.PJNR;
                    }
                  }
                }
              });

              // 被评人的多选问题可能存在多个 所以要将这些多选问题分类筛选出来
              let TM = multiple.reduce((pre, cur) => {
                // 如果没有 就添加
                if (!pre.some((item) => item.TMId === cur.TMId)) pre.push(cur);
                else {
                  // 如果有 看课程是否也相同
                  if (
                    cur.KCId &&
                    !pre.some(
                      (item) => item.TMId === cur.TMId && item.KCId === cur.KCId
                    )
                  )
                    pre.push(cur);
                }
                return pre;
              }, []);
              TM.forEach((item) => {
                let index = this.indexList.find((el) => el.Id === item.TMId);
                if (item.KCId) {
                  //有课程
                  // 在被评人所有多选中筛选出当前问题的所属答案（一个或多个答案）
                  let arr = multiple.filter(
                    (el) => el.TMId === item.TMId && el.KCId === item.KCId
                  );
                  if (index) {
                    arr.forEach((el) => {
                      // 找到多选答案在指标中对应的答案详情 进行排序 或者筛除当前指标没有的答案
                      let DA = index.WJFXBList.find((e) => e.Id === el.FXId);
                      if (DA) {
                        el.letter = DA.TMQZ;
                        el.enabled = true;
                      } else {
                        // 如果没找到对应的答案详情 说明在指标中没有对应的答案详情 所以不需要赋值
                        el.enabled = false;
                      }
                    });
                    arr = arr.filter((el) => el.enabled);
                    arr.sort((a, b) => a.letter.localeCompare(b.letter));
                    if (bpr[item.TMId + item.KCId]) {
                      bpr[item.TMId + item.KCId] = arr.map((el) => el.FXId);
                    } else {
                      bpr[item.TMId] = arr.map((el) => el.FXId);
                    }
                  } else {
                    console.log(item, "记录中没有再当前指标中找到对应的题目");
                  }
                } else {
                  // 无课程
                  let arr = multiple.filter((el) => el.TMId === item.TMId);
                  if (index) {
                    arr.forEach((el) => {
                      // 找到多选答案在指标中对应的答案详情 进行排序 或者筛除当前指标没有的答案
                      let DA = index.WJFXBList.find((e) => e.Id === el.FXId);
                      if (DA) {
                        el.letter = DA.TMQZ;
                        el.enabled = true;
                      } else {
                        // 如果没找到对应的答案详情 说明在指标中没有对应的答案详情 所以不需要赋值
                        el.enabled = false;
                      }
                    });

                    arr = arr.filter((el) => el.enabled);
                    arr.sort((a, b) => a.letter.localeCompare(b.letter));
                    bpr[item.TMId] = arr.map((el) => el.FXId);
                  } else {
                    console.log(item, "记录中没有再当前指标中找到对应的题目");
                  }
                }
              });
            });
          }
          this.oldEvaluation = JSON.parse(JSON.stringify(this.form.bpr));
        }
      );
    },
    // 导入
    uploadrfid(file) {
      this.uploading = true;
      let files = { 0: file.raw };
      this.readExcel(files);
    },
    readExcel(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      let indexName = [];
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // 导入的数据某一格获取了焦点后 又没有输入数据 直接保存再导入 转换的res会少指标名
        let reg = /\d+/g;
        indexName = Object.keys(worksheet)
          .reduce((pre, cur) => {
            return cur.match(reg) && cur.match(reg)[0] === "1"
              ? pre.concat(worksheet[cur].h)
              : pre;
          }, [])
          .filter((name) => name !== "被评人");
        const res = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        this.handleExcel(res, indexName);
        this.$refs.upload.value = "";
      };
      fileReader.readAsArrayBuffer(files[0]);
    },
    handleExcel(excel, names) {
      let arr = [];

      if (excel && excel.length > 0) {
        names.forEach((name) =>
          arr.push({
            indexName: name,
          })
        );

        excel.forEach((item) => {
          arr.forEach((el) => {
            el[item["被评人"]] = item[el.indexName] || "";
          });
        });

        // 判断任意指标是否评分
        let flag = this.indexList.some((Index) =>
          this.form.bpr.some((bpr) => (bpr[Index.key] ? true : false))
        );
        // 有评分提醒用户
        if (flag) {
          this.$confirm(`部分指标已有评分 是否覆盖分数!`, {
            distinguishCancelAndClose: true,
            confirmButtonText: "覆盖",
            cancelButtonText: "保留",
            type: "warning",
          })
            .then(() => {
              this.handleExcel2(arr, 1);
            })
            .catch((action) => {
              if (action === "cancel") this.handleExcel2(arr, 2);
              else this.uploading = false;
            });
        } else this.handleExcel2(arr);
      } else {
        this.uploading = false;
        this.$message.error("您导入的数据有误！");
      }
    },
    handleExcel2(arr, res) {
      arr.forEach((item) => {
        let index = ""; //通过指标名找到的指标
        // 找到名字相对应的指标
        this.indexList.forEach((el) => {
          if (item.indexName.includes(el.TMMC)) index = el;
        });
        index &&
          this.form.bpr.forEach((bpr) => {
            if (bpr[index.key]) {
              // 有值
              if (res === 1) {
                // 覆盖 且有这个人
                if (Object.hasOwnProperty.call(item, bpr.Name)) {
                  if (
                    index.SSLB === 3 &&
                    (item[bpr.Name] !== "" ||
                      item[bpr.Name] !== null ||
                      item[bpr.Name] !== undefined)
                  ) {
                    bpr[index.key] = item[bpr.Name];
                  }
                  if (
                    index.SSLB !== 3 &&
                    item[bpr.Name] !== "" &&
                    item[bpr.Name] !== null &&
                    item[bpr.Name] !== undefined
                  ) {
                    let idx = this.letter.findIndex(
                      (letter) => letter === item[bpr.Name]
                    );
                    if (idx !== -1 && index.WJFXBList[idx]) {
                      bpr[index.key] = index.WJFXBList[idx].Id;
                    }
                  }
                }
              } else {
                // 保留
              }
            } else {
              // 无值 且有这个人 直接赋值
              if (Object.hasOwnProperty.call(item, bpr.Name)) {
                if (index.SSLB === 3) {
                  bpr[index.key] = item[bpr.Name];
                } else {
                  let idx = this.letter.findIndex(
                    (letter) => letter === item[bpr.Name]
                  );
                  if (idx !== -1 && index.WJFXBList[idx]) {
                    bpr[index.key] = index.WJFXBList[idx].Id;
                  }
                }
              }
            }
          });
      });
      this.uploading = false;
      this.$message.success("导入完毕");
    },
    // 导出
    exportExcel(type) {
      let header = [];
      this.indexList.forEach((item) => {
        if (item.Id) {
          header.push(item.TMMC);
        } else header.push("被评人");
      });
      header.unshift("学号");

      let filename = "";

      if (this.hd.EvaluatedObject === 1) {
        filename = `${this.hd.Name}--${this.bj.Name}---问卷${
          type ? "评分" : "模板"
        } `;
      } else {
        filename = `${this.hd.Name}--老师---问卷${type ? "评分" : "模板"}`;
      }
      let indexList = [{ xh: "xh" }, ...this.indexList];
      import("@/style/js/Export2Excel").then((excel) => {
        let data = [];
        this.form.bpr.forEach((bpr) => {
          let arr = [];
          indexList.forEach((item) => {
            if (!item.Id && item.xh) arr.push(bpr.XH);
            else if (!item.Id && !item.xh) arr.push(bpr.Name);
            else {
              if (type) {
                if (item.SSLB === 3) {
                  arr.push(bpr[item.key]);
                } else {
                  let FX = item.WJFXBList.filter((it) =>
                    bpr[item.key].includes(it.Id)
                  );
                  if (FX) {
                    if (FX.length === 1) {
                      FX.forEach((it) => {
                        let idx = item.WJFXBList.findIndex(
                          (el) => el.Id === it.Id
                        );
                        arr.push(this.letter[idx]);
                      });
                    } else {
                      let str = "";
                      FX.forEach((it, length) => {
                        let idx = item.WJFXBList.findIndex(
                          (el) => el.Id === it.Id
                        );
                        if (idx !== -1)
                          str +=
                            this.letter[idx] +
                            (length === FX.length - 1 ? "" : "；");
                      });
                      arr.push(str);
                    }
                  } else arr.push("");
                }
              } else arr.push("");
            }
          });
          data.push(arr);
        });
        excel.export_json_to_excel({
          header, //表头 必填
          data, //具体数据 必填
          filename, //非必填
        });
      });
    },
    syncconfirm(index) {
      return new Promise((resolve) => {
        this.$confirm(`${index.TMMC}已有答案 是否覆盖!`, {
          confirmButtonText: "覆盖",
          cancelButtonText: "保留",
          type: "warning",
        })
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    },
    // 排序变化
    orderChange(val) {
      let reg = /\d+/g;
      if (val === 1) {
        this.form.bpr.sort((a, b) => a.Name.localeCompare(b.Name));
      } else {
        this.form.bpr.sort((a, b) => a.XH.match(reg)[0] - b.XH.match(reg)[0]);
      }
    },
    // 查找姓名或学号
    handleSearch() {
      if (this.search === "") return;
      let idx = this.form.bpr.findIndex(
        (item) =>
          item.Name.includes(this.search) || item.XH.includes(this.search)
      );
      if (idx === -1) {
        this.$message.error("未找到匹配对象");
      } else {
        let el_table__body = document.getElementsByClassName(
          "el-table__body"
        )[0];
        let tr_arr = el_table__body.getElementsByTagName("tr");
        this.$refs.table.bodyWrapper.scrollTop = tr_arr[idx].offsetTop;
      }
    },
    // 排空
    findEmpty() {
      let tdIndex = 0;
      let idx = this.form.bpr.findIndex((bpr) =>
        this.indexList.some((item, index) => {
          if (index === 0) return false;
          if (
            bpr[item.key] === "" ||
            bpr[item.key] === undefined ||
            bpr[item.key] === null
          ) {
            tdIndex = index;
            return true;
          } else return false;
        })
      );
      if (idx === -1) {
        this.$message.warning("未发现空白评价");
      } else {
        let el_table__body = document.getElementsByClassName(
          "el-table__body"
        )[0];
        let tr_arr = el_table__body.getElementsByTagName("tr");
        if (tr_arr[idx] && tr_arr[idx].getElementsByTagName("td")) {
          let emptyTd = tr_arr[idx].getElementsByTagName("td")[tdIndex];
          if (emptyTd) {
            emptyTd.style.border = "1px solid red";
            setTimeout(() => {
              emptyTd.style.border = "none";
            }, 1000);
          }
        }
        this.$refs.table.bodyWrapper.scrollTop = tr_arr[idx].offsetTop;
      }
    },
    // 获取班级课表
    getClassCourse() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/cousertimetable/GetCourseTableList",
          {
            ClassId: this.bj.Id,
            Period: 1,
            TermId: this.term.Id, //	是	string	学期Id
            SchoolId: this.userInfo.SSZZId, //	是	string	学校信息
            token: localStorage.getItem("Token"),
          },
          (res) => {
            if (res.Code === 0) {
              this.indexList = this.indexList.reduce((pre, cur, idx) => {
                if (idx === 0) {
                  pre.push(cur);
                } else {
                  if (
                    res.Target.ContentRowList?.some((item) =>
                      item.ContentList.some((it) =>
                        it.TeacherCourseList?.some(
                          (i) => i.TeacherCourseId === cur.xkId
                        )
                      )
                    )
                  ) {
                    pre.push(cur);
                  }
                }
                return pre;
              }, []);
              resolve();
            }
          }
        );
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.operating-btns {
  margin-bottom: 15px;
  display: flex;
  .filter-btns {
    display: flex;
    align-items: center;
    margin-left: 15px;
    .label {
      font-size: 16px;
    }
    .el-select {
      width: 120px;
    }
    .search {
      display: flex;
      align-items: center;
      margin: 0 15px;
      .el-input {
        width: 150px;
        ::v-deep .el-input__inner {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .el-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }
}
.data-page1-form {
  .table-header-item {
    position: relative;
    padding-right: 15px;
    width: calc(100% - 15px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .header-icon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .el-table {
    ::v-deep .el-table__body-wrapper {
      scroll-behavior: smooth;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
    ::v-deep .el-table__cell {
      padding: 6px 0 0;
    }
    ::v-deep .my-cell-class {
      background-color: #e0e0e0;
    }
    ::v-deep .my-header-class {
      background-color: #e0e0e0;
    }
    // ::v-deep .el-form-item__error {
    //   top: 82%;
    // }
  }
}
.answer-info {
  display: flex;
  align-items: center;
  .letter {
    margin-right: 10px;
  }
}
</style>
