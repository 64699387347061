<template>
  <div>
    <div>
      <el-button
        type="primary"
        @click="savetemplate"
        :disabled="checkcount() == true"
        >保存</el-button
      >
      <el-button type="primary" @click="showexport">生成PDF</el-button>
    </div>
    <div class="cz-cont-box pdf-center pdf-wrap">
      <div class="pdf-sider pdf-center">
        <div
          v-for="(item, index) in pageLimit"
          :key="item"
          class="pdf-center pdf-sider-item"
        >
          <div
            @click="changePage(index)"
            :class="[
              'pdf-sider-item-text',
              currentPage === item ? 'pdf-sider-item-text-active' : ''
            ]"
          >
            第{{ item }}页
          </div>
          <i
            class="el-icon-delete pdf-sider-item-remove"
            @click="delPage(index)"
          ></i>
        </div>
        <div class="pdf-center pdf-sider-item">
          <el-button
            size="small"
            icon="el-icon-plus"
            plain
            @click="addPage"
          ></el-button>
        </div>
      </div>
      <div class="pdf-center pdf-content">
        <div class="pdf-content-set">
          <div class="pdf-content-set-item">
            <el-button type="primary" @click="openTemplates"
              >选择模版</el-button
            >
            {{
              currentTemplate
                ? templates.find(o => o.value == currentTemplate)
                  ? templates.find(o => o.value == currentTemplate).label
                  : ''
                : ''
            }}
          </div>
          <div class="pdf-content-set-item">
            <el-input
              v-model="pageDatas[currentPage - 1].BT"
              style="max-width:250px"
              placeholder="请输入页面名称"
              v-if="pageDatas[currentPage - 1] != undefined"
            ></el-input>
          </div>
          <div v-if="currentTemplate == 2" class="pdf-content-set-item">
            <el-select v-model="isshownl" placeholder="是否展示" clearable>
              <div slot="prefix" style="color: #606266; line-height: 40px;">
                年龄
              </div>
              <el-option
                v-for="item in trueorfalse"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="currentTemplate == 2" class="pdf-content-set-item">
            <el-select v-model="isshowxz" placeholder="是否展示" clearable>
              <div slot="prefix" style="color: #606266; line-height: 40px;">
                星座
              </div>
              <el-option
                v-for="item in trueorfalse"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="currentTemplate == 2" class="pdf-content-set-item">
            <el-select v-model="isshowah" placeholder="是否展示" clearable>
              <div slot="prefix" style="color: #606266; line-height: 40px;">
                爱好
              </div>
              <el-option
                v-for="item in trueorfalse"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="currentTemplate == 15" class="pdf-content-set-item">
            <!-- <el-select v-model="pageDatas[currentPage - 1].GLId" clearable placeholder="请选择活动">
							<el-option v-for="item in activities" :key="item.hdid" :label="item.hdmc"
								:value="item.hdid">
							</el-option>
						</el-select> -->
            <el-cascader
              v-model="pageDatas[currentPage - 1].GLId"
              :options="XXPJHDList"
              clearable
              placeholder="请选择活动"
              :props="{ expandTrigger: 'hover' }"
            ></el-cascader>
            <!-- @change="handleChange" -->
          </div>
          <div v-if="currentTemplate == 16" class="pdf-content-set-item">
            <!-- <el-select v-model="pageDatas[currentPage - 1].GLId" clearable placeholder="请选择活动">
							<el-option
								v-for="item in activities.filter( c => c.UserEvalutionActive.IsChaildActive == 1 )"
								:key="item.ActivityId" :label="item.ActivityName" :value="item.ActivityId">
							</el-option>
						</el-select> -->
            <el-cascader
              v-model="pageDatas[currentPage - 1].GLId"
              :options="TYPJHDList"
              clearable
              placeholder="请选择活动"
              :props="{ expandTrigger: 'hover' }"
            ></el-cascader>
          </div>
          <div v-if="currentTemplate == 18" class="pdf-content-set-item">
            <el-select
              v-model="pageDatas[currentPage - 1].GLId"
              clearable
              placeholder="请选择学科"
            >
              <el-option
                v-for="item in course"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div v-if="currentTemplate == 19" class="pdf-content-set-item">
						<el-select v-model="pageDatas[currentPage - 1].GLId" clearable placeholder="请选择体系">
							<el-option v-for="item in txlist" :key="item.Id" :label="item.TXMC" :value="item.Id">
							</el-option>
						</el-select>
					</div> -->
          <div class="pdf-content-set-item">
            <el-upload
              class="upload-demo"
              accept="image/jpeg, image/png"
              :show-file-list="false"
              :auto-upload="true"
              :action="uploadURL"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                style="width:100px;height:100px;"
                v-if="currentpageimage"
                :src="currentpageimage"
                class="avatar"
              />
              <el-button v-else type="primary">上传底图(非必需)</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg、png文件，且不超过4mb
              </div>
            </el-upload>
          </div>
        </div>
        <div class="pdf-center pdf-content-preview">
          <div>通用底图</div>
          <div class="pdf-center preview">
            <el-upload
              class="upload-demo"
              accept="image/jpeg, image/png"
              :show-file-list="false"
              :auto-upload="true"
              :action="uploadURL"
              :on-success="handleBackgroudSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <el-image
                style="width: 237px; height: 336px;background:#f5f7fa"
                :src="tydt.TPLJ"
                fit="cover"
                v-if="tydt != null"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <el-button type="primary">上传通用底图</el-button>
              <div slot="tip" class="el-upload__tip">
                上传之后，未设置底图的模板都会使用此底图，只能上传jpg、png文件，且不超过4mb
              </div>
            </el-upload>
          </div>
        </div>
        <div class="pdf-content-set"></div>
      </div>
    </div>
    <el-dialog
      :lock-scroll="false"
      :append-to-body="true"
      title="选择模版"
      :visible.sync="showTemplateDialog"
      width="80%"
      center
    >
      <templates
        :templates="templates"
        :showTemplateDialog="showTemplateDialog"
        @selectTemplate="selectTemplate"
      />
    </el-dialog>
    <el-dialog
      :lock-scroll="false"
      :append-to-body="true"
      :visible.sync="showExportTypeDialog"
      width="700px"
      height="400px"
      center
    >
      <div style="margin-bottom:15px">
        选择学期：<el-select v-model="xqid" placeholder="请选择">
          <el-option
            v-for="item in terms"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
      <el-radio-group
        style="display: flex;justify-content: space-evenly;align-items: center;"
        v-model="exportType"
        class=""
      >
        <div style="display: flex;justify-content: center;align-items: center;">
          <el-radio style="margin-right: 0px;" :label="1">{{ '' }}</el-radio>
          <div
            style="border: 1px solid #ccc;width:168px;height:238px;padding: 10px;"
            @click="exportType = 1"
          >
            <el-image
              style="width: 100%; height: 100%;background:#f5f7fa;cursor:pointer"
              src=""
              fit="cover"
            >
              <div slot="error" class="image-slot">
                模版
              </div>
            </el-image>
          </div>
        </div>
        <div
          style="display: flex;justify-content: center;align-items: center;position: relative;"
        >
          <el-radio style="margin-right: 0px;" :label="2">{{ '' }}</el-radio>
          <div
            style="border: 1px solid #ccc;width:238px;height:168px;padding: 10px;display: flex;justify-content: space-between;align-items: center;"
            @click="exportType = 2"
          >
            <el-image
              style="width: 48%; height: 100%;background:#f5f7fa;cursor:pointer"
              src=""
              fit="cover"
            >
              <div slot="error" class="image-slot">
                模版
              </div>
            </el-image>
            <el-image
              style="width: 48%; height: 100%;background:#f5f7fa;cursor:pointer"
              src=""
              fit="cover"
            >
              <div slot="error" class="image-slot">
                模版
              </div>
            </el-image>
          </div>
          <div style="position: absolute;bottom: -20px;font-size: 12px;">
            注：页数最好是4的倍数
          </div>
        </div>
      </el-radio-group>

      <div class="multi-opt-box" style="margin-top: 0px;">
        <div class="multi-opt">
          <div class="multi-opt-left">年级</div>
          <div class="multi-opt-rig">
            <div
              v-for="nj in njlist"
              :key="nj.Id"
              class="d-word-widt"
              style="min-width:100px !important;"
              @click="njchange(nj.Id)"
            >
              <a
                v-if="njid == nj.Id"
                class="b-word-widta opt-rigsty whole-back"
                >{{ nj.Name }}</a
              >
              <a v-else class="b-word-widta whole-back-hover">{{ nj.Name }}</a>
            </div>
          </div>
        </div>
        <div class="multi-opt">
          <div class="multi-opt-left">班级</div>
          <div style="z-index:10;" class="multi-opt-rig">
            <div
              class="d-word-widt"
              style="min-width:100px !important;"
              v-for="bj in bjlist"
              :key="bj.Id"
              @click="bjchange(bj.Id)"
            >
              <a
                v-if="bjid == bj.Id"
                class="b-word-widta opt-rigsty whole-back"
                >{{ bj.Name }}</a
              >
              <a v-else class="b-word-widta whole-back-hover">{{ bj.Name }}</a>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="closeexport">取消</el-button>
        <el-button type="primary" @click="exportPDF">生成</el-button>
      </span>
    </el-dialog>

    <!-- 顺序插入面板 -->
    <el-dialog
      title="选择页码顺序"
      :visible.sync="insertPanel.show"
      width="30%"
    >
      <el-input-number
        v-model="insertPanel.val"
        :min="1"
        :max="this.pageLimit + 1"
        label="插入顺序"
      ></el-input-number>
      <span slot="footer" class="dialog-footer">
        <el-button @click="insertPanel.show = false">取 消</el-button>
        <el-button type="primary" @click="insertPanelSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import templates from '@/views/manager/pdf/templates'
export default {
  components: {
    templates
  },
  data() {
    return {
      ssxx: '',
      xqid: '',
      cjr: '',
      token: '',
      njlist: [],
      bjid: '',
      njid: '',
      bjlist: [],
      templateimage: require('../../../style/growth/backcover.png'),
      currentpageimage: '',
      exportType: 1,
      showExportTypeDialog: false,
      currentPage: 1,
      showTemplateDialog: false,
      pageLimit: 0,
      globalBackground: undefined,
      czbgszlb: '',
      // txlist: [],
      course: [],
      wdid: null,
      wdlist: [],
      activities: [],
      templates: [
        {
          value: 1,
          label: '封面模版',
          component: 'cover'
        },
        {
          value: 2,
          label: '基本信息模板',
          component: 'basicInfo'
        },
        {
          value: 3,
          label: '积分模板',
          component: 'points'
        },
        {
          value: 4,
          label: '报告概览',
          component: 'reportOverview'
        },
        {
          value: 5,
          label: '学业模板',
          component: 'knowledge'
        },
        // {
        // 	value: 6,
        // 	label: "课堂表现",
        // 	component: "performance"
        // },
        {
          value: 6,
          label: '作业完成情况',
          component: 'homeWork'
        },
        {
          value: 7,
          label: '体质报告',
          component: 'fitness'
        },
        {
          value: 8,
          label: '身体素质',
          component: 'physique'
        },
        {
          value: 9,
          label: '身体形态',
          component: 'bodyfigure'
        },
        {
          value: 10,
          label: '身体机能',
          component: 'bodyfunction'
        },
        {
          value: 11,
          label: '体检报告',
          component: 'physicalExamination'
        },
        {
          value: 12,
          label: '成长对话',
          component: 'comment'
        },
        {
          value: 13,
          label: '我的荣誉',
          component: 'honor'
        },
        // {
        //   value: 14,
        //   label: "课辅活动",
        //   component: "curriculaActivity"
        // },
        {
          value: 15,
          label: '星星活动模板',
          component: 'starsActivity'
        },
        {
          value: 16,
          label: '通用评价活动模板',
          component: 'generalAct'
        },
        {
          value: 17,
          label: '家校共育',
          component: 'JXGY'
        },
        {
          value: 18,
          label: '学科模板',
          component: 'subject'
        }
        // {
        // 	value: 19,
        // 	label: "体系模板",
        // 	component: "txtemplate"
        // },
        // {
        // 	value: 18,
        // 	label: "维度报告模板",
        // 	component: "dimension"
        // }, {
        // 	value: 19,
        // 	label: "维度报告模板",
        // 	component: "dimension"
        // }, {
        // 	value: 20,
        // 	label: "维度报告模板",
        // 	component: "dimension"
        // }
      ],
      tydt: null,
      pageDatas: [],
      pageData: {
        Id: '',
        SSXX: '',
        CJR: '',
        YM: 1,
        GLMB: 0,
        MBMC: '',
        BT: '',
        FJSM: '',
        TPLJ: '',
        GLLX: 0,
        GLId: ''
      },
      currentTemplate: undefined,
      XXPJHDList: [],
      TYPJHDList: [],
      terms: [],
      trueorfalse: [
        {
          value: 1,
          label: '显示'
        },
        {
          value: 2,
          label: '不显示'
        }
      ],
      isshownl: '',
      isshowxz: '',
      isshowah: '',
      insertPanel: {
        show: false,
        val: ''
      }
    }
  },
  async created() {
    this.xqid = localStorage.getItem('currentterm')
    var tempuser = localStorage.getItem('userinfo')
    if (tempuser) {
      var user = JSON.parse(tempuser)
      this.cjr = user.Id
      this.ssxx = user.SSZZId
      this.token = user.Token
      await this.getTerms()
    }
    this.loadwddata()
    this.gettemplates()
    // this.loadtxdata();
    this.loadactivities()
    this.loadnjdata()
  },
  methods: {
    showexport: function() {
      if (
        this.pageDatas == null ||
        this.pageDatas == undefined ||
        this.pageDatas.length <= 0
      ) {
        this.$message({
          type: 'info',
          message: '请先设置好页面再生成'
        })
        return
      }
      this.showExportTypeDialog = true
      this.bjid = ''
    },
    closeexport: function() {
      this.showExportTypeDialog = false
      this.bjid = ''
    },
    njchange: function(id) {
      this.bjid = ''
      this.njid = id
      this.loadbjdata()
    },
    bjchange: function(id) {
      this.bjid = id
    },
    loadnjdata: function() {
      this.$api.post(
        'v1/accountmanage/GetSchoolOrg',
        {
          SchoolId: this.ssxx,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            this.njlist = []
            var list = r.Target
            this.zzjglist = list
            for (var i = 0; i < list.length; i++) {
              if (list[i].Hierarchy == 1) {
                this.njlist.push(list[i])
              }
            }
          }
        }
      )
    },
    loadbjdata: function() {
      if (this.njid.length > 0 && this.zzjglist.length > 0) {
        this.bjlist = this.zzjglist.filter(
          c => c.Id == this.njid
        )[0].ChidOrgList
      } else {
        this.bjlist = []
      }
    },
    beforeAvatarUpload(file) {
      const ispic = file.type === 'image/jpeg' || file.type === 'image/png'
      const sizelimit = file.size / 1024 / 1024 < 4

      if (!ispic) {
        this.$message.error('上传头像图片只能是 JPG、PNG 格式!')
      }
      if (!sizelimit) {
        this.$message.error('上传头像图片大小不能超过 4MB!')
      }
      return ispic && sizelimit
    },
    handleBackgroudSuccess(response) {
      if (response.Code != 0) {
        this.$message.info('图片上传失败')
      } else {
        this.$api.post(
          'v1/growthbrochure/SaveTotalBackgroud',
          {
            SSXX: this.ssxx,
            CJR: this.cjr,
            TPLJ: response.Target.FilePath,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: 'success',
                message: '上传成功!'
              })
              console.log(r.Target, 'ssss')
              if (
                r.Target &&
                r.Target.TPLJ &&
                !r.Target.TPLJ.includes('http://')
              ) {
                r.Target.TPLJ = 'http://' + r.Target.TPLJ
              }
              this.tydt = r.Target
            } else {
              this.$message({
                type: 'info',
                message: '上传成功!'
              })
            }
          }
        )
      }
    },
    handleAvatarSuccess(response) {
      if (response.Code != 0) {
        this.$message.info('图片上传失败')
      } else {
        if (!response.Target.FilePath.includes('http://')) {
          response.Target.FilePath = 'http://' + response.Target.FilePath
        }
        this.currentpageimage = response.Target.FilePath
        this.pageDatas[this.currentPage - 1].TPLJ = this.currentpageimage
      }
    },
    init() {
      if (this.pageDatas.length < this.pageLimit) {
        this.pageDatas.push(JSON.parse(JSON.stringify(this.pageData)))
      }
    },

    loadactivities() {
      this.$api.post(
        'v1/Growth/GetczbghdList',
        {
          SchoolId: this.ssxx,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.activities = []
            var list = r.Target
            // console.log(list)
            this.XXPJHDList = []
            this.TYPJHDList = []
            if (list && list.length > 0) {
              // this.activities = list.filter(c => c.pjfs == 2);
              for (let i = 0; i < list.length; i++) {
                if (list[i].pjfs == 1) {
                  let pjhd_temp = this.TYPJHDList.find(
                    o => o.value == list[i].wdid
                  )
                  if (pjhd_temp) {
                    let children_obj = new Object()
                    children_obj.value = list[i].hdid
                    children_obj.label = list[i].hdmc
                    pjhd_temp.children.push(children_obj)
                  } else {
                    let obj = new Object()
                    obj.value = list[i].wdid
                    obj.label = list[i].wdmc
                    let children_obj = new Object()
                    children_obj.value = list[i].hdid
                    children_obj.label = list[i].hdmc
                    obj.children = [children_obj]
                    this.TYPJHDList.push(obj)
                  }
                } else {
                  let pjhd_temp = this.XXPJHDList.find(
                    o => o.value == list[i].wdid
                  )
                  if (pjhd_temp) {
                    let children_obj = new Object()
                    children_obj.value = list[i].hdid
                    children_obj.label = list[i].hdmc
                    pjhd_temp.children.push(children_obj)
                  } else {
                    let obj = new Object()
                    obj.value = list[i].wdid
                    obj.label = list[i].wdmc
                    let children_obj = new Object()
                    children_obj.value = list[i].hdid
                    children_obj.label = list[i].hdmc
                    obj.children = [children_obj]
                    this.XXPJHDList.push(obj)
                  }
                }
              }
              // console.log(this.PJHDList)
            }
          }
        }
      )
    },
    gettemplates() {
      this.$api.post(
        'v1/growthbrochure/GetTemplates',
        {
          SSXX: this.ssxx,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.pageDatas = []
            if (r.Target != null && r.Target != undefined) {
              this.pageDatas = r.Target.filter(c => c.GLLX != 4)
              this.pageDatas.forEach(item => {
                if (item.GLMB == 15 || item.GLMB == 16) {
                  if (item.GLId) {
                    item.GLId = JSON.parse(item.GLId)
                  } else {
                    item.GLId = ''
                  }
                }
                if (item.GLMB == 2) {
                  if (item.GLId) {
                    item.GLId = JSON.parse(item.GLId)
                  } else {
                    item.GLId = ['', '', '']
                  }
                  this.isshownl = item.GLId[0]
                  this.isshowxz = item.GLId[1]
                  this.isshowah = item.GLId[2]
                }
              })
              let tydtResult = r.Target.filter(c => c.GLLX == 4)[0]
              if (tydtResult && !tydtResult?.TPLJ.includes('http://')) {
                tydtResult.TPLJ = 'http://' + tydtResult.TPLJ
              }
              this.tydt = tydtResult
            }
            if (this.pageDatas.length <= 0) {
              this.addPage()
            }
            this.currentPage = 1
            this.pageLimit = this.pageDatas.length
            this.currentTemplate = this.pageDatas[0].GLMB
              ? this.pageDatas[0].GLMB
              : undefined

            if (
              this.pageDatas[0] &&
              this.pageDatas[0].TPLJ &&
              !this.pageDatas[0].TPLJ.includes('http://')
            ) {
              this.pageDatas[0].TPLJ = 'http://' + this.pageDatas[0].TPLJ
            }
            this.currentpageimage = this.pageDatas[0]?.TPLJ
              ? this.pageDatas[0].TPLJ
              : ''
          }
        }
      )
    },
    savetemplate() {
      let notsetlist = this.pageDatas.filter(c => c.GLMB == 0)
      if (notsetlist.length > 0) {
        let errpage = ''
        for (var i = 0; i < notsetlist.length; i++) {
          errpage += notsetlist[i].YM + '、'
        }
        errpage = errpage.substring(0, errpage.length - 1)
        this.$message({
          type: 'info',
          message: '请设置好第' + errpage + '页的关联模板再保存'
        })

        return
      }
      var notsetactivitylist = this.pageDatas.filter(
        c => (c.GLMB == 15 || c.GLMB == 16) && c.GLId == ''
      )
      if (notsetactivitylist.length > 0) {
        let errpage = ''
        for (var j = 0; j < notsetactivitylist.length; j++) {
          errpage += notsetactivitylist[j].YM + '、'
        }
        errpage = errpage.substring(0, errpage.length - 1)
        this.$message({
          type: 'info',
          message: '请设置好第' + errpage + '页的关联信息再保存'
        })
        return
      }
      this.pageDatas.forEach(item => {
        if (item.GLMB == 15 || item.GLMB == 16) {
          item.GLId = JSON.stringify(item.GLId)
        }
        if (item.GLMB == 2) {
          let arr = [
            this.isshownl ? this.isshownl : 1,
            this.isshowxz ? this.isshowxz : 1,
            this.isshowah ? this.isshowah : 1
          ]
          item.GLId = JSON.stringify(arr)
        }
      })
      this.$api.post(
        'v1/growthbrochure/SaveTemplate',
        {
          SSXX: this.ssxx,
          CJR: this.cjr,
          MBList: this.pageDatas,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
            this.gettemplates()
          } else {
            this.$message({
              type: 'info',
              message: '保存不成功!'
            })
          }
        }
      )
    },
    checkcount() {
      var disabled =
        this.pageDatas != undefined &&
        this.pageDatas != null &&
        this.pageDatas.length > 0
      return !disabled
    },
    addPage() {
      if (this.pageDatas.length > 0) {
        this.insertPanel.show = true
        this.insertPanel.val = this.pageLimit + 1
      } else {
        this.pageLimit++
        this.pageData.YM = this.pageLimit
        this.pageData.ssxx = this.ssxx
        this.pageData.cjr = this.cjr
        this.pageDatas.push(JSON.parse(JSON.stringify(this.pageData)))
      }
    },
    delPage(index) {
      if (this.pageDatas[index] && this.pageDatas[index].Id) {
        this.$api.post(
          'v1/growthbrochure/DeleteTemplate',
          {
            Id: this.pageDatas[index].Id,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.pageDatas.splice(index, 1)
              this.pageLimit--
            } else {
              this.$message({
                type: 'info',
                message: '删除失败!'
              })
            }
          }
        )
      } else {
        this.pageDatas.splice(index, 1)
        this.pageLimit--
      }
    },
    changePage(val) {
      this.currentPage = val + 1
      this.currentTemplate = this.pageDatas[val].GLMB
        ? this.pageDatas[val].GLMB
        : undefined
      this.currentpageimage = this.pageDatas[val]?.TPLJ
    },
    openTemplates() {
      this.showTemplateDialog = true
      scrollTo(0, 0)
    },
    selectTemplate(val) {
      if (val) {
        this.currentTemplate = val
        if (this.pageDatas[this.currentPage - 1].GLMB != val) {
          this.pageDatas[this.currentPage - 1].GLId = ''
        }
        this.pageDatas[this.currentPage - 1].GLMB = val
        this.pageDatas[this.currentPage - 1].MBMC = this.templates.find(
          o => o.value == val
        ).component
        // if (val == 18) {
        // 	this.pageDatas[this.currentPage - 1].GLLX = 2;
        // 	this.pageDatas[this.currentPage - 1].GLId = '4621';
        // } else if (val == 15 || val == 16) {
        // 	this.pageDatas[this.currentPage - 1].GLLX = 1;
        // }
        //  else if (val == 19) {
        // 	this.pageDatas[this.currentPage - 1].GLLX = 5;
        // }
        if (val > 100) {
          let wd = this.wdlist.find(o => o.value == val)
          // console.log(wd);
          this.pageDatas[this.currentPage - 1].GLLX = 2
          this.pageDatas[this.currentPage - 1].GLId = wd.keyid
        }
      }
      this.showTemplateDialog = false
    },
    exportPDF() {
      if (
        this.pageDatas == null ||
        this.pageDatas == undefined ||
        this.pageDatas.length <= 0
      ) {
        this.$message({
          type: 'info',
          message: '请先设置好页面再生成'
        })
        return
      }
      if (this.bjid == '' || this.bjid.length <= 0) {
        this.$message({
          type: 'info',
          message: '请先选择好班级再生成'
        })
        return
      }
      localStorage.setItem('templateTerm', this.xqid)
      this.pageDatas.forEach(item => {
        if (item.GLMB == 2) {
          let arr = [
            this.isshownl ? this.isshownl : 1,
            this.isshowxz ? this.isshowxz : 1,
            this.isshowah ? this.isshowah : 1
          ]
          item.GLId = arr
        }
      })
      let routeUrl = this.$router.resolve({
        path: '/pdf/export',
        query: {
          exportType: this.exportType,
          bjid: this.bjid,
          tydt: this.tydt != null ? this.tydt.TPLJ : '',
          njid: this.njid
        }
      })
      sessionStorage.removeItem('pageDatas')
      sessionStorage.setItem('pageDatas', JSON.stringify(this.pageDatas))
      window.open(routeUrl.href, '_blank')
    },
    loadtxdata() {
      this.$api.post(
        'v1/growthbrochure/GetGrowthReportSet',
        {
          SSXX: this.ssxx,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.txlist = []
            var info = r.Target
            if (info != null && info != undefined) {
              this.czbgszlb = info.SZLB
              if (info.TXList != null && info.TXList != undefined) {
                this.txlist = info.TXList
              }
            }
          }
        }
      )
    },
    loadwddata() {
      this.$api.get(
        'v1/Growth/GetDimensionReport',
        {
          SSXX: this.ssxx,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.wdlist = []
            let info = r.Target
            if (info != null && info != undefined) {
              if (info.WDZSBGInfo && info.WDZSBGInfo.length > 0) {
                let value_num = 101
                info.WDZSBGInfo.forEach(item => {
                  if (item.LX == 2) {
                    let obj = new Object()
                    obj.value = value_num
                    obj.label = item.ZSMC + '维度报告模板'
                    obj.component = 'dimension'

                    this.templates.push(obj)

                    let obj_keyid = new Object()
                    obj_keyid.value = value_num
                    obj_keyid.keyid = item.KeyId

                    this.wdlist.push(obj_keyid)

                    value_num++
                  } else if (item.LX == 1) {
                    this.wdid = item.Id
                  }
                })
                this.loadxkdata()
              }
            }
          }
        }
      )
    },
    loadxkdata() {
      this.$api.post(
        'v1/Growth/GetSubjectSet',
        {
          Id: this.wdid,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.course = []
            let info = r.Target
            if (info && info.length > 0) {
              for (let i = 0; i < info.length; i++) {
                if (!this.course.find(o => o.Id == info[i].XKXZS[0])) {
                  let obj = new Object()
                  obj.Id = info[i].XKXZS[0]
                  obj.Name = info[i].XKXZ
                  this.course.push(obj)
                }
              }
            }
          }
        }
      )
    },
    getTerms() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetTerm',
          {
            SchoolId: this.ssxx,
            token: this.token
          },
          res => {
            if (res.Code === 0) {
              this.terms = res.Target || []
              resolve()
            } else reject()
          }
        )
      })
    },
    insertPanelSubmit() {
      this.pageLimit++
      this.pageData.YM = this.insertPanel.val
      this.pageData.ssxx = this.ssxx
      this.pageData.cjr = this.cjr
      this.pageDatas.splice(
        this.insertPanel.val - 1,
        0,
        JSON.parse(JSON.stringify(this.pageData))
      )
      this.pageDatas.forEach((item, index) => (item.YM = index + 1))
      this.insertPanel.show = false
      this.currentTemplate = this.pageDatas[this.pageData.YM - 1].GLMB
        ? this.pageDatas[this.pageData.YM - 1].GLMB
        : undefined
      this.currentpageimage = this.pageDatas[this.pageData.YM - 1]?.TPLJ
    }
  },
  computed: {
    // 文件的上传路径
    uploadURL: function() {
      return '/file/file/fileservice/UploadFile?token=' + this.token
    }
  }
}
</script>
<style lang="scss" scoped>
.pdf-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-wrap {
  padding: 0px 10px;
  align-items: self-start;
}

.pdf-sider {
  width: 250px;
  flex-direction: column;
}

.pdf-sider-item {
  width: calc(100% - 10px);
  padding: 5px;
  // background: cornflowerblue;
  justify-content: space-evenly;
  border-right: 1px solid #f2f2f2;
  font-size: 16px;
}

.pdf-sider-item + .pdf-sider-item {
  border-top: 1px solid #f2f2f2;
}

.pdf-content {
  flex: 1;
  align-items: flex-start;
}

.pdf-sider-item-remove:hover {
  color: #f56c6c;
}

.pdf-sider-item-remove {
  font-size: 20px;
  cursor: pointer;
}

.pdf-sider-item-text:hover {
  color: #14d089;
}

.pdf-sider-item-text-active {
  color: #14d089;
}

.pdf-sider-item-text {
  cursor: pointer;
}

.pdf-content-set {
  flex: 1;
}

.pdf-content-preview {
  // width: 300px;
  flex-direction: column;
  margin: 20px;

  .preview {
    width: 400px;
    height: 460px;
  }
}

.el-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-image::v-deep .image-slot {
  font-size: 30px;
  color: #909399;
}

.pdf-content-set-item {
  margin: 10px;
}

.el-select {
  ::v-deep .el-input--prefix {
    .el-input__inner {
      padding-left: 50px;
    }
  }
}
</style>
