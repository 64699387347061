<template>
  <!-- 学生获奖 -->
  <div class="cont-whole-box">
    <div class="student-award">
      <!-- 顶部功能按钮 -->
      <div class="top-btns">
        <div class="left-btns">
          <el-input
            v-model="searchname"
            placeholder="请输入关键字"
            size="small"
            maxlength="20"
            prefix-icon="el-icon-search"
          />
          <el-button size="small" type="primary" @click="handleSearch()"
            >搜索</el-button
          >
        </div>
        <div class="right-btns">
          <el-button
            type="primary"
            size="small"
            @click="dialogImportVisible = true"
            >导入</el-button
          >

          <el-button type="primary" size="small" @click="downloadawardlist()"
            >导出</el-button
          >
          <!--导入，新增 v-if="flag" -->
          <el-button type="primary" size="small" @click="handleaddaward()"
            >新增</el-button
          >
        </div>
      </div>
      <!-- 筛选区域 -->
      <div class="filter-toolbar">
        <!-- 时间筛选 -->
        <div class="filter-item">
          <div class="label">时间</div>
          <div class="content">
            <el-date-picker
              size="small"
              v-model="start_end"
              type="daterange"
              :picker-options="pickerOption"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="start_end_change"
            >
            </el-date-picker>

            <div class="week-term">
              <div class="label-week-term">学期：</div>
              <el-select
                v-model="rqlx"
                placeholder="请选择"
                size="small"
                @change="rqlxchange"
              >
                <el-option
                  v-for="item in termOptions"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- 学段筛选 -->
        <div class="filter-item">
          <div class="label">学段</div>
          <div class="content content-wrap">
            <div
              v-for="xd in xdlist"
              class="wrap-item"
              :class="{
                'cur-wrap-item': xdid === xd.value
              }"
              :key="xd.value"
              @click="xdchange(xd.value)"
            >
              {{ xd.label }}
            </div>
          </div>
        </div>
        <!-- 年级筛选 -->
        <div class="filter-item">
          <div class="label">年级</div>
          <div class="content content-wrap">
            <div
              v-for="(nj, index) in njlist"
              class="wrap-item"
              :class="{
                'cur-wrap-item': index == njCurIndex
              }"
              :key="nj.Id"
              @click="njchange(nj.Id, index)"
            >
              {{ nj.Name }}
            </div>
          </div>
        </div>
        <!-- 班级筛选 -->
        <div class="filter-item">
          <div class="label">班级</div>
          <div style="z-index: 10" class="content content-wrap">
            <!-- :class="{
                'cur-wrap-item': bjid === bj.Id,
                disabled: !bj.enabled,
              }" -->
            <div
              class="wrap-item"
              v-for="(bj, index) in bjlist"
              :key="bj.Id"
              :class="{
                'cur-wrap-item': index == bjCurIndex
              }"
              @click="bjchange(bj.Id, index)"
            >
              {{ bj.Name }}
            </div>
          </div>
        </div>
        <!-- 是否允许筛选 -->
        <div class="filter-item">
          <div class="label">状态</div>
          <div class="content content-wrap">
            <div
              class="wrap-item"
              :class="{ 'cur-wrap-item': SFYX == 1 }"
              @click="awardtypechange(1)"
            >
              有效
            </div>
            <div
              class="wrap-item"
              :class="{ 'cur-wrap-item': SFYX == 2 }"
              @click="awardtypechange(2)"
            >
              无效
            </div>
          </div>
        </div>
      </div>

      <!-- 获奖列表 -->
      <div class="list">
        <div class="list-item" v-for="(hj, index) in hjlist" :key="index">
          <!-- 标题 -->
          <div class="list-item-title">
            <div class="name">{{ hj.HJMC }}</div>
            <div>
              <el-button
                :type="hj.SFYX == '1' ? 'danger' : 'primary'"
                size="small"
                @click="setawardvalid(hj)"
                >{{ hj.SFYX == '1' ? '设置为无效' : '设置为有效' }}</el-button
              >
              <!-- v-if="flag" -->
              <el-button type="primary" size="small" @click="handleaddaward(hj)"
                >编辑</el-button
              >
            </div>
          </div>
          <!-- 详情 -->
          <div class="detail">
            <!-- 详情顶部 -->
            <div class="detail-top">
              <div class="top-item">
                <span class="label">姓名：</span>
                {{ hj.CYMC }}
              </div>
              <div class="top-item">
                <span class="label">班级：</span>
                {{ getClassName(hj.BJId) }}
              </div>
              <div class="top-item">
                <span class="label">获奖类别：</span>
                {{ hj.LBMC }}
              </div>
              <div class="top-item">
                <span class="label">获奖级别：</span>
                {{ hj.DJMC }}
              </div>
              <div v-if="hj.SHZT == 2" class="top-item">
                <span class="label">得分：</span>
                {{ hj.DF }}分
              </div>
            </div>
            <!-- 主办单位 获奖日期 -->
            <div class="detail-center">
              <div class="center-item">
                主办单位：<span style="font-weight: normal">{{ hj.ZBDW }}</span>
              </div>
              <div class="center-item">
                获奖日期：<span style="font-weight: normal">{{ hj.HJSJ }}</span>
              </div>
            </div>
            <!-- 详情描述 -->
            <div class="detail-description">
              <div class="label">获奖描述：</div>
              <div class="value">
                <!-- 因为flex原因 文字超出不换行 所以需要再加一个div -->
                <div>{{ hj.BZ }}</div>
              </div>
            </div>
            <!-- 图片 -->
            <div class="images" v-if="hj.FjList && hj.FjList.length > 0">
              <div style="font-size:10px" v-for="fj in hj.FjList" :key="fj.Id">
                <el-image
                  class="img-item"
                  :src="fj.FJLJ"
                  fit="scale-down"
                  :preview-src-list="hj.FjList.map(fj => fj.FJLJ)"
                />
              </div>
            </div>
            <!-- 添加人 -->
            <div class="displ-il-boxmain">
              <div class="displ-il-box">
                <span class="c-g-9">添加人：</span>
                {{ getCJRName(hj.CJR) }} {{ hj.CJSJ }}
                <!-- {{ hj.CJSJ | DateFormat('yyyy-MM-dd') }} -->
              </div>
              <!-- 数据类型为1 -->
              <div
                v-if="hj.SHZT == 2 && hj.SFYX == 1 && hj.SJLY == 1"
                class="displ-il-box"
              >
                <!-- {{ hj.SHRQ | DateFormat("yyyy-MM-dd") }} -->
                <span class="c-g-9">审核人：</span>
                {{ hj.SHRXM }}
              </div>
              <!-- 数据类型为2 -->
              <div
                v-if="hj.SHZT == 2 && hj.SFYX == 1 && hj.SJLY == 2"
                class="displ-il-box"
              >
                <span class="c-g-9">审核人：</span>
                自动审核（批量导入）
              </div>
              <!-- 设置人 -->
              <div v-if="hj.SHZT == 2 && hj.SFYX == 2" class="displ-il-box">
                <!--  -->
                <span class="c-g-9">更改人：</span>
                <!-- | DateFormat("yyyy-MM-dd") -->
                {{ getCJRName(hj.SZR) }} {{ hj.SZSJ }}
              </div>
              <!-- SHZT: 0未知 1.未审核 2.审核通过 3.审核不通过 -->
              <!-- 无效原因 -->
              <div v-if="hj.SFYX == 2" class="displ-il-box">
                <span class="c-g-9">原因：</span>
                {{ hj.WXYY }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagin-box">
        <div class="pagin-wz">
          <Pager :pagerInfo="pagerInfo" @changeIndex="handleCurrentChange" />
        </div>
      </div>
    </div>

    <!-- 获奖无效设置 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleOptionClose"
    >
      <el-form :model="formData" ref="wxyyform">
        <el-form-item
          label="请在下方输入奖项无效的原因："
          :rules="[
            { required: true, message: '无效原因不能为空' },
            {
              min: 1,
              max: 50,
              message: '长度在1到50个字符',
              trigger: 'change'
            }
          ]"
          prop="wxyy"
        >
          <el-input
            type="textarea"
            v-model="formData.wxyy"
            placeholder="不超过50个字"
            maxlength="50"
            minlength="1"
            :rows="4"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleOptionClose()">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 导入获奖记录 -->
    <el-dialog
      title="导入获奖记录"
      :visible.sync="dialogImportVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleImportClose"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="upload"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <span
            class="essay-bt-textbunn"
            style="margin-left:0!important;"
            @click="downLoadTemplate"
            >学生获奖导入模板下载</span
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportClose()">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { sha1 } from '@/utils/common'
import XLSX from 'xlsx'
import Pager from '@/components/Pager.vue'
export default {
  components: {
    Pager
  },
  async created() {
    //YhyTODO:获取所属学校
    this.ssxq = localStorage.getItem('currentterm')
    this.term = localStorage.getItem('curTerm')
    var tempuser = localStorage.getItem('userinfo')
    if (tempuser) {
      this.user = JSON.parse(tempuser)
      let menu = this.user.menu.find(item => item.CodeOREId === 'PrizeManage')
      if (menu) this.menuType = menu.QXDJLX //1.全部 2.局部（局部时才筛选学段年级班级 全部时不筛选）
      this.ssxx = this.user.SSZZId
      this.token = this.user.Token
      this.yhid = this.user.Id
      this.yhlb = this.user.YHLX
    }
    //获取学生
    if (!localStorage.getItem('OrgStudent')) {
      await this.getOrgStudent()
    }
    //获取教师
    if (!localStorage.getItem('OrgTeacher')) {
      await this.getSchoolTeacher()
    }

    this.schoolTeacher = JSON.parse(localStorage.getItem('OrgTeacher'))
    this.schoolStudent = JSON.parse(localStorage.getItem('OrgStudent'))
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return '/file/file/fileservice/UploadFile?token=' + this.token
    }
  },
  async mounted() {
    await this.getTerms()
    await this.getTeacherClass()
    await this.loadlbdata()
    await this.loadnjdata()
    await this.loadbjdata()
    await this.GetSchoolMessageBasic()

    await this.GetSchoolMessageBasic()
    //获取角色
    await this.getRole()
    //获取获奖类别
    await this.getHJJB()
    if (this.menuType === 2) {
      //局部菜单
      let arr = []
      if (this.user.SSXD === 1) arr = [1]
      if (this.user.SSXD === 2) arr = [2]
      if (this.user.SSXD === 3) arr = [1, 2]
      this.xdlist.forEach(item => {
        if (arr.includes(item.value)) item.enabled = true
      })
    } else this.xdlist.forEach(item => (item.enabled = true))

    this.xdchange(this.xdlist[0]?.value)
    this.loaddata()
  },
  methods: {
    //匹配班级名称
    getClassName(value) {
      let name = ''
      this.zzjglist.forEach(item => {
        item.ChidOrgList.forEach(i => {
          if (i.Id == value) {
            name = i.Name
          }
        })
      })
      return name
    },
    //匹配添加人名称
    getCJRName(value) {
      let name = ''
      //先匹配教师
      let a = this.schoolTeacher.find(i => i.Id == value)

      if (a) {
        name = a.Name
      } else {
        let b = this.schoolStudent.find(i => i.Id == value)
        if (b) {
          name = b.Name
        } else {
          name = ''
        }
      }
      return name
    },
    upload(file) {
      let files = { 0: file.raw }
      this.readExcel(files)
    },
    readExcel: function(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false })
        this.submitimportdata(results)
        this.$refs.upload.value = ''
      }
      fileReader.readAsArrayBuffer(files[0])
    },
    submitimportdata(readdata) {
      console.log(readdata, 'pppp')
      if (readdata.length <= 0) {
        this.$message({
          type: 'info',
          message: '上传文件中无正确数据!'
        })
        return
      }
      this.importdata = []

      // 模板导入校验
      try {
        for (let i = 0; i < readdata.length; i++) {
          // 校验学生名称
          let student = this.schoolStudent.find(
            item => item.Name === readdata[i][this.header[0]].trim()
          )
          if (!student)
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + '行未识别获奖学生，导入失败'
            )
          // 校验学生班级
          let gradeInfo = this.zzjglist.find(item =>
            item.ChidOrgList.find(
              classInfo => classInfo.Name === readdata[i][this.header[1]].trim()
            )
          )
          if (!gradeInfo)
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + '行未识别获奖班级，导入失败'
            )
          let classInfo = gradeInfo.ChidOrgList.find(
            classInfo => classInfo.Name === readdata[i][this.header[1]].trim()
          )
          if (!classInfo)
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + '行未识别获奖班级，导入失败'
            )
          if (student.ClassId !== classInfo.Id)
            throw new Error(
              '第 ' +
                readdata[i]['__rowNum__'] +
                '行获奖学生不在当前获奖班级中，导入失败'
            )
          // 获奖名称
          if (!readdata[i][this.header[2]])
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + '获奖名称未识别，导入失败'
            )
          // 校验获奖类别
          let LB = this.ImpData.find(
            item => item.LBMC === readdata[i][this.header[3]].trim()
          )
          let DJ = {}

          if (!LB)
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行类别未识别，导入失败'
            )

          // 获奖级别
          let JB = LB.child.find(
            item => item.MC === readdata[i][this.header[4]].trim()
          )
          console.log('JB', JB)

          if (!JB)
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行级别未识别，导入失败'
            )

          //如果子级奖项，判断子奖项
          if (JB.children && JB.children.length > 0) {
            DJ = JB.children.find(
              item => item.MC === readdata[i][this.header[5]].trim()
            )
            if (!DJ)
              throw new Error(
                '第 ' + readdata[i]['__rowNum__'] + ' 行等级未识别，导入失败'
              )
          }
          // 校验时间
          try {
            new Date(readdata[i][this.header[6]])
          } catch (err) {
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行时间格式未识别，导入失败'
            )
          }

          if (isNaN(new Date(readdata[i][this.header[6]]).getTime()))
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行时间格式未识别，导入失败'
            )
          // 获奖比例
          let BL = LB.children.find(
            item => item.MC == readdata[i][this.header[7]].trim()
          )
          console.log(LB, readdata[i][this.header[7]], this.header[7], 'aaaa')
          if (!BL) {
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行计分比例未识别，导入失败'
            )
          }
          //  主办单位字数限制
          if (
            readdata[i][this.header[8]] &&
            readdata[i][this.header[8]].length > 200
          ) {
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + '行主办单位字数溢出，导入失败'
            )
          }
          // 描述
          if (
            readdata[i][this.header[9]] &&
            readdata[i][this.header[9]].length > 200
          ) {
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + '行描述字数溢出，导入失败'
            )
          }

          let obj = {
            CYMC: readdata[i][this.header[0]], //参与人
            BJMC: readdata[i][this.header[1]],
            HJMC: readdata[i][this.header[2]],
            LBMC: LB ? LB.LBMC : '',
            HJLB: LB ? LB.Id : '', //类别ID
            JBMC: JB ? JB.MC : '', //级别名称
            HJJB: JB ? JB.Id : '', //级别ID
            DJMC: DJ ? DJ.MC : '', //等级名称
            HJDJ: DJ ? DJ.Id : '', //等级ID
            BlMC: BL ? BL.MC : '', //比例名称
            HJBL: BL ? BL.Id : '', //比例ID
            BZ: readdata[i][this.header[7]],
            HJSJ: readdata[i][this.header[6]],
            ZBDW: readdata[i][this.header[9]] //主办单位
            //FZXS:'',    辅助学生
          }
          this.importdata.push(obj)
        }

        this.$api.post(
          'v1/awards/ImportData',
          {
            CYLB: 1,
            DataList: this.importdata,
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            CJR: this.yhid,
            CJRLB: this.yhlb,
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              this.$message({
                type: 'info',
                message: r.Message
              })
              this.loaddata()
              this.handleImportClose()
            }
          }
        )
      } catch (err) {
        this.$message({
          type: 'error',
          message: err.message
        })
      }
    },
    //导出
    downloadawardlist: function() {
      if (this.bjid.length > 0) {
        this.orgid = this.bjid
      } else if (this.njid.length > 0) {
        this.orgid = this.njid
      }
      let term = this.termOptions.find(item => item.Id === this.ssxq)
      let kssj = this.kssj || term.BeginTime
      let jssj = this.jssj || term.EndTime
      this.$api.post(
        'v1/awards/DownLoadAwardListForWeb',
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          SearchName: this.searchname,
          OrgId: this.orgid,
          CYLB: 1,
          CJR: this.user.Id,
          SFYX: this.SFYX,
          BeginDate: kssj,
          EndDate: jssj,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            window.open(r.Message, '_blank')
          } else {
            this.$message({
              type: 'info',
              message: r.Message
            })
          }
        }
      )
    },
    //获取学校教师
    getSchoolTeacher() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolTeacher',
          {
            schoolId: this.ssxx,
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              let list = r.Target || []
              localStorage.setItem('OrgTeacher', JSON.stringify(list))
              resolve()
            }
          }
        )
      })
    },
    // 获取学生
    getOrgStudent() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetOrgStudent',
          {
            SchoolOrgId: this.ssxx, //	是	string	组织机构ID/活动Id
            OrgType: 0, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: this.term.SSXN, //	是	string	学年
            SchoolId: this.ssxx, //	否	string	学校Id
            IsDelete: 2, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              let list = r.Target || []
              localStorage.setItem('OrgStudent', JSON.stringify(list))
              resolve()
            }
          }
        )
      })
    },
    //处理导入数据（新）
    handleImportData(list) {
      let arr = list.XXHJFLList
      let newArr = arr.filter(i => {
        if (i.HJFLDJList?.length > 0) {
          i.HJFLDJList.forEach(it => {
            it.value = it.Id
            it.label = it.MC
            it.level = it.CJ
            let res = i.HJFLDJList.filter(t => t.FID == it.Id)

            it.children = res.length > 0 ? res : null
          })
          i.child = i.HJFLDJList.filter(o => o.CJ == 1)
          i.children = i.HJJFBLList
        }
        return i.SSLB == 1
      })
      newArr.sort((a, b) => {
        a.PXH - b.PXH
      })
      this.ImpData = newArr.map(item => {
        return {
          Id: item.Id,
          LBMC: item.LBMC,
          PXH: item.PXH,
          SSLB: item.SSLB,
          child: item.child,
          children: item.children
        }
      })
    },
    //获取获奖类别
    getHJJB() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/awards/GetXXHJJCSZ',
          { SSXX: this.ssxx, HQLB: 0 },
          r => {
            if (r.Code == 0) {
              this.hjlblist.push(r.Target)
              this.handleImportData(r.Target || [])
              localStorage.setItem('XXHJLBList', JSON.stringify(r.Target))
              //权限判断
              let szlxList = this.hjlblist[0].XXHJSZList.filter(
                item => item.SZLX === 1
              )
              let roleList = szlxList.filter(item => item.SZZT === 4) //4-角色
              if (roleList.length) {
                this.flag = this.Role.RoleIds.some(it =>
                  roleList.some(item => item.RoleStr === it)
                )
              } else {
                let roleList1 = szlxList.filter(item => item.SZZT === 3) //3-班主任
                if (roleList1.length) this.flag = true
              }
              localStorage.setItem('TeacherSign', JSON.stringify(this.Role))
              resolve()
            } else {
              reject()
            }
          }
        )
      })
    },
    //获取角色
    getRole() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/evaluationactivity/GetTeacherSign',
          { TeacherId: this.yhid, SchoolId: this.ssxx, token: this.token },
          r => {
            if (r.Code == 0) {
              this.Role = r.Target

              resolve()
            } else {
              reject()
            }
          }
        )
      })
    },
    // 新增/编辑
    handleaddaward(data) {
      this.$router.push({
        name: 'addstudentaward',
        params: {
          form: data || {},
          xdlist: this.xdlist,
          lblist: this.lblist,
          djlist: this.djlist
        }
      })
    },
    //确定无效
    handleConfirm() {
      this.$refs.wxyyform.validate(valid => {
        if (valid) {
          this.$api.post(
            'v1/awards/SetValidAward',
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              Id: this.editid,
              SFYX: '2',
              YHId: this.yhid,
              WXYY: this.formData.wxyy,
              BJId: this.formData.hjbjid,
              token: this.token
            },
            () => {
              this.formData.wxyy = ''
              this.dialogVisible = false
              this.$message.success('设置成功')
              this.search()
            }
          )
        } else {
          return false
        }
      })
    },
    //关闭单选项
    handleOptionClose() {
      this.formData.wxyy = ''
      this.dialogVisible = false
    },
    handleImportClose: function() {
      this.dialogImportVisible = false
    },
    // 有效无效变化
    awardtypechange: function(at) {
      this.SFYX = at
      this.pagerInfo.pageIndex = 1
      this.loaddata()
    },
    xdchange(id) {
      let xd = this.xdlist.find(item => item.value === id)
      if (xd) {
        this.filterNJ_BJ(xd)
        this.xdid = xd.value

        if (this.njlist[0]) {
          this.njchange(this.njlist[0].Id, 0)
        } else {
          this.bjlist = []
          this.bjid = ''
        }
      }
    },
    // 选年级
    njchange(id, index) {
      this.njCurIndex = index
      this.bjlist = []
      let a = []

      if (!id) {
        let newArr = this.njlist.slice(1)
        let newbjlist = []
        newArr.forEach(item => {
          newbjlist.push(...item.ChidOrgList)
        })
        a = newbjlist

        this.bjlist = []
      } else {
        let nj = this.njlist.find(item => item.Id === id)
        this.njid = id
        a = nj.ChidOrgList
        this.bjCurIndex = 0
        this.bjlist.push({ Id: '', Name: '全部' }, ...a)
      }
    },
    bjchange(id, index) {
      this.bjCurIndex = index
      this.orgid = ''
      this.bjid = id
      this.pagerInfo.pageIndex = 1
      this.loaddata()
    },
    start_end_change(val) {
      if (val) {
        this.kssj = val[0]
        this.jssj = val[1]
      } else {
        let term = this.termOptions.find(item => item.IsCurrent === 1)
        if (term) {
          this.kssj = term.BeginTime
          this.jssj = term.EndTime
        } else {
          this.kssj = ''
          this.jssj = ''
        }
      }
      this.loaddata()
    },
    rqlxchange(val) {
      this.pagerInfo.pageIndex = 1
      let term = this.termOptions.find(item => item.Id === val)
      if (term) {
        this.kssj = term.BeginTime
        this.jssj = term.EndTime
        this.start_end = []
      }

      this.loaddata()
    },
    messagebox: function(message) {
      this.$alert('提示', message, {
        confirmButtonText: '确定'
      })
    },
    handleSearch: function() {
      this.loaddata()
    },
    //获奖列表
    loaddata: function() {
      // if (!this.bjid) return this.$message.error("未匹配到班级");
      this.$api.post(
        'v1/awards/GetAwardListForWeb',
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          SearchName: this.searchname,
          BJId: this.bjid,
          NJId: this.njid,
          CYLB: 1,
          SFYX: this.SFYX,
          AwardType: 1, //查询类别
          BeginDate: this.kssj,
          EndDate: this.jssj,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            let arr = r.Target || []
            arr.forEach(item => {
              if (item.FjList && item.FjList.length > 0) {
                item.FjList.forEach(it => {
                  if (it.FJLJ && !it.FJLJ.includes('http://')) {
                    it.FJLJ = 'http://' + it.FJLJ
                  }
                })
              }
              item.HJSJ = item.HJSJ.split(' ')[0]
            })

            this.hjlist = arr.filter(i => i.SHZT == 2)
            this.pagerInfo.totalCount = this.hjlist.length
            // this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      )
    },
    // 设置是否有效
    setawardvalid(hj) {
      this.$nextTick(() => {
        this.$refs.wxyyform.clearValidate()
      })
      if (hj.SFYX === 1) {
        // 设置无效
        this.editid = hj.Id
        this.formData.hjbjid = hj.BJId
        this.formData.wxyy = ''
        this.dialogVisible = true
      } else {
        this.$confirm('是否设置该荣誉为有效？')
          .then(() => {
            this.$api.post(
              'v1/awards/SetValidAward',
              {
                SSXX: this.ssxx,
                SSXQ: this.ssxq,
                Id: hj.Id,
                SFYX: '1',
                YHId: this.yhid,
                token: this.token
              },
              () => {
                this.$message.success('设置成功')
                this.search()
              }
            )
          })
          .catch(() => {
            return
          })
      }
    },
    // 获取年级
    loadnjdata() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          { SchoolId: this.ssxx, token: this.token },
          r => {
            if (r.Code == 0) {
              let list = r.Target || []
              localStorage.setItem('SchoolOrg', JSON.stringify(list))
              this.njlist = []
              this.zzjglist = list
              for (let i = 0; i < list.length; i++) {
                if (list[i].Hierarchy == 1) {
                  this.njlist.push(list[i])
                }
              }
              resolve()
            }
          }
        )
      })
    },
    loadbjdata: function() {
      let list = []
      if (this.njid.length > 0) {
        list = this.zzjglist.filter(c => c.Id == this.njid)[0].ChidOrgList
      } else {
        list = []
      }
      list.unshift({ Id: '', Name: '全部' })
      this.bjlist = list
    },
    //翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val
      this.loaddata()
    },
    search: function() {
      this.pagerInfo.pageIndex = 1
      this.loaddata()
    },
    handleEdit: function(id) {
      this.editid = id
      this.editextracurricular()
    },
    handleDelete: function(id) {
      this.$confirm('确定要删除该活动吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.post(
            'v1/extracurricular/DeleteJXGY',
            { SSXX: this.ssxx, SSXQ: this.ssxq, Id: id, token: this.token },
            r => {
              if (r.Code == 0) {
                this.$message({
                  type: 'info',
                  message: '删除成功!'
                })
                this.loaddata()
              } else {
                this.$message({
                  type: 'info',
                  message: '删除失败!'
                })
                this.loaddata()
              }
            }
          )
        })
        .catch(() => {
          return
        })
    },
    handleEnable: function(id, status) {
      this.$api.post(
        'v1/extracurricular/EnableChange',
        {
          Id: id,
          SSXQ: this.ssxq,
          SSXX: this.ssxx,
          Status: status,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            this.$message({
              type: 'info',
              message: '操作成功!'
            })
            this.loaddata()
          } else {
            this.$message({
              type: 'info',
              message: '操作失败!'
            })
          }
        }
      )
    },
    // 获取教师班主任 科任班级
    getTeacherClass() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetTeaherManageClass',
          {
            UserId: this.yhid,
            TermId: this.ssxq,
            token: this.token
          },
          res => {
            if (res.Code === 0) {
              this.teacherClass = res.Target || {}
              resolve()
            } else reject()
          }
        )
      })
    },
    // 处理登录人可以看到的年级班级
    filterNJ_BJ(xd) {
      let result = []
      // 登录人是否班主任 科任
      let arr = this.zzjglist.filter(item => item.SSXDLX === xd.value)

      let Lecturer = this.teacherClass.LecturerClass || []
      let main = this.teacherClass.TeacherClass || ''
      result = arr.filter(item => {
        if (this.menuType === 2) {
          if (Lecturer.some(it => it === item.Id)) item.enabled = true
          if (main.includes(item.Id)) item.enabled = true
          if (item.enabled && item.ChidOrgList) {
            item.ChidOrgList.forEach(child => {
              if (Lecturer.some(it => it.includes(child.Id)))
                child.enabled = true
              if (main.includes(child.Id)) child.enabled = true
            })
          }
        } else {
          item.enabled = true
          item.ChidOrgList.forEach(child => {
            child.enabled = true
          })
        }
        return item.enabled
      })
      result.forEach(item => {
        item.ChidOrgList = item.ChidOrgList.filter(it => it.enabled)
      })

      let list = result
      list.unshift({ Id: '', Name: '全部' })
      this.njlist = list

      return result
    },
    // 获取学校基本信息
    GetSchoolMessageBasic() {
      const timesTamp = new Date().getTime()
      const Nonce = Math.random()
      const signature = sha1(this.$store.getters.appSecret + timesTamp + Nonce)
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolMessageBasic',
          {
            SchoolId: this.ssxx,
            Signature: signature,
            TimesTamp: timesTamp,
            Nonce: Nonce,
            token: this.toekn
          },
          res => {
            if (res.Code === 0) {
              this.schoolDetail = res.Target || {}
              let arr = []
              if (this.schoolDetail.XXXZ === 1)
                arr.push({
                  label: '小学段',
                  value: 1
                })
              if (this.schoolDetail.CZXZ === 1)
                arr.push({
                  label: '中学段',
                  value: 2
                })
              arr.forEach(item => {
                item.njlist = []
                item.njlist = this.filterNJ_BJ(item)
              })
              this.xdlist = arr.filter(xd => xd.njlist && xd.njlist.length > 0)
              if (arr.length > 0) this.xdid = arr[0].value

              resolve()
            } else reject()
          }
        )
      })
    },
    getTerms() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetTerm',
          {
            SchoolId: this.user.SSZZId,
            token: this.user.Token
          },
          res => {
            if (res.Code === 0) {
              this.termOptions = res.Target || []
              let term = this.termOptions.find(item => item.IsCurrent === 1)
              if (term) {
                this.rqlx = term.Id
                this.kssj = term.BeginTime
                this.jssj = term.EndTime
                this.start_end = []
              }
              resolve()
            } else reject()
          }
        )
      })
    },
    // 处理下载模板
    export2Excel() {
      import('@/style/js/Export2ExcelSheetArray').then(excel => {
        // let str1 =
        //   "获奖类别：" +
        //   this.lblist.map((item) => `${item.SJBS}.${item.LBMC} `).join();
        // let str2 =
        //   "    获奖级别：" +
        //   this.djlist.map((item) => `${item.SJBS}.${item.HJDJMC} `).join();
        // let multiHeader = [str1 + str2];

        let tHeader = [
          '获奖学生',
          '获奖班级',
          '获奖名称',
          '获奖类别',
          '获奖级别',
          '获奖等级',
          '获奖时间',
          '计分比例',
          '主办单位',
          '描述'
        ]

        let data = [
          {
            Sheet: '学生获奖记录',
            Data: [
              [
                '小明',
                '一年级1班（要注意是否启用别名）',
                '获奖名称',
                '获奖类别',
                '获奖级别',
                '获奖等级',
                '2020-06-05（注意是文本格式）',
                '50.52%',
                '获奖备注'
              ]
            ]
          }
        ]
        excel.export_json_to_excel({
          // multiHeader: [multiHeader],
          header: tHeader, //表头 必填
          data, //具体数据 必填
          autoWidth: false,
          merges: ['A1:G1'], //合并单元格
          filename: '学生获奖模板' + '' //非必填
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j]
        })
      )
    },
    // 获取类别
    loadlbdata() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          //   "v1/awards/GetHJLBList",
          'v1/schoolprizeset/GetXXHJLBGLListByType',
          { SSXX: this.ssxx, HQLB: 1, token: this.token },
          r => {
            if (r.Code == 0) {
              this.lblist = r.Target || []
              this.lblist.sort((a, b) => a.DF - b.DF)
              resolve()
            }
          }
        )
      })
    },
    // 下载模板
    downLoadTemplate() {
      let a = document.createElement('a')
      a.href = `${this.publicPath}template/studentAwardTemplate.xlsx`
      a.download = '学生获奖导入模板'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  },
  data() {
    let _that = this
    return {
      term: '',
      njCurIndex: 0,
      bjCurIndex: 0,
      publicPath: process.env.BASE_URL,
      editid: 0,
      isLogin: true,
      hjlist: [],
      njlist: [],
      bjlist: [],
      hjlblist: [],
      zzjglist: [],
      importdata: [],
      ImpData: [], //处理过后导入的数据格式
      Role: [],
      flag: false,
      formData: { wxyy: '', hjbjid: '' },
      dialogVisible: false,
      dialogImportVisible: false,
      ssxx: '',
      ssxq: '',
      searchname: '',
      start_end: [],
      kssj: '',
      jssj: '',
      token: '',
      yhid: '',
      bjid: '',
      yhlb: 0,
      njid: '',
      orgid: '',
      schoolTeacher: '', //学校教师
      schoolStudent: '', //学校学生
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      },
      teacherClass: {}, //登录人所教班级
      xdlist: [],
      xdid: '',
      awardtype: 1,
      SFYX: 1,
      menuType: 1,
      user: {},
      termOptions: [],
      rqlx: '',
      pickerOption: {
        disabledDate(val) {
          let term = _that.termOptions.find(item => item.Id === _that.rqlx)
          if (term) {
            if (
              val.getTime() < new Date(term.BeginTime).getTime() ||
              val.getTime() > new Date(term.EndTime).getTime()
            )
              return true
            else return false
          } else return false
        }
      },
      djlist: [],
      lblist: [],
      header: [
        '获奖学生',
        '获奖班级',
        '获奖名称',
        '获奖类别',
        '获奖级别',
        '获奖等级',
        '获奖时间',
        '计分比例',
        '主办单位',
        '描述'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.student-award {
  padding: 30px;
  .top-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-btns {
      display: flex;
      align-items: center;
    }
  }
  .filter-toolbar {
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px #0000001a;
    .filter-item {
      display: flex;
      align-items: center;
      padding: 10px;
      .label {
        width: 100px;
        height: 100%;
        margin-right: 15px;
        border-right: 1px solid #ccc;
        text-align: center;
        font-size: 16px;
      }
      .content {
        flex: 1;
        display: flex;
        align-items: center;
        .week-term {
          display: flex;
          align-items: center;
          margin-left: 10px;
          font-size: 14px;
        }
      }
      .content-wrap {
        flex-wrap: wrap;
        padding-top: 10px;
        padding-right: 10px;
        .wrap-item {
          width: 65px;
          text-align: center;
          font-size: 14px;
          border-radius: 5px;
          padding: 4px 6px;
          background-color: #ffffff;
          color: #14d089;
          border: 1px solid #14d089;
          margin-bottom: 12px;
          margin-left: 10px;
          cursor: pointer;
          &.cur-wrap-item {
            color: #fff;
            background-color: #14d089;
          }
          &.disabled {
            border: none;
            color: #9b9898;
            background-color: #d9d9d9;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .list {
    margin-top: 15px;
    background-color: #fff;
    .list-item {
      margin-bottom: 15px;
      border-radius: 10px;
      box-shadow: 0px 0px 4px #0000001a;
      .list-item-title {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid #ccc;
        .name {
          color: red;
          font-size: 18px;
          font-weight: 600;
        }
      }
      .detail {
        padding: 10px;
        .detail-top {
          display: flex;
          align-items: center;
          .top-item {
            margin-right: 15px;
            font-size: 14px;
            .label {
              font-weight: 600;
            }
          }
        }
        .detail-center {
          display: flex;
          align-items: center;
          margin-top: 10px;
        }
        .detail-center > .center-item {
          font-weight: 600;
          margin-right: 20px;
          color: #666;
          font-size: 14px;
        }
        .detail-description {
          margin-top: 10px;
          font-size: 14px;
          display: flex;
          .label {
            flex-shrink: 0;
            font-weight: 600;
          }
          .value {
            flex: 1;
          }
        }
        .images {
          margin-top: 15px;
          padding-top: 10px;
          display: flex;
          flex-wrap: wrap;
          border: 1px solid #ccc;
          .img-item {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
