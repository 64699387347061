<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="30%"
    top="12%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <div class="popv-cent">
        <div class="tab-shu-box">
          <table cellpadding="0" cellspacing="0">
            <tbody>
              <el-form-item prop="TXMC">
                <tr>
                  <td class="tab-shu-titl">体系名称</td>
                  <td>
                    <el-input
                      class="jf-inpt"
                      size="medium"
                      v-model="form.TXMC"
                      placeholder="请输入体系名称"
                    ></el-input>
                  </td>
                </tr>
              </el-form-item>
            </tbody>
          </table>
        </div>
        <div class="cent-bott-butt">
          <a class="cent-bott-butta" @click="confirmDialog">确&nbsp;认</a>
          <a class="cent-bott-butta" @click="closeDialog" style>取&nbsp;消</a>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { duplicate } from "@/style/js/Validator.js";
export default {
  data() {
    return {
      form: {},
      rules: {
        TXMC: [
          { required: true, message: "请输入体系名称", trigger: "change" },
          {
            min: 1,
            max: 20,
            message: "长度在限制在 1 到 20 个字符",
            trigger: "change"
          },
          {
            validator: duplicate,
            validateData: [],
            message: "体系名称已存在",
            trigger: "change"
          }
        ]
      }
    };
  },
  props: {
    formData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    dataTable: {
      type: Array,
      default: function() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    token: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    },
    schoolId: {
      type: String,
      default: ""
    }
  },
  methods: {
    init() {
      this.$set(this, "form", JSON.parse(JSON.stringify(this.formData)));
      this.rules.TXMC[2].validateData = this.dataTable.map(c => c.TXMC);
    },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeDialog");
      });
    },
    confirmDialog() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api.post(
            "/v1/evaluationsystem/EditEvaluationSystem",
            {
              token: this.token,
              TXInfo: this.form,
              SchoolId: this.schoolId,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              if (r.Code === 0) {
                const data = r.Target;
                this.$message({
                  type: "success",
                  message: r.Message
                });
                this.$emit("closeDialog", data);
              }
            }
          );
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        this.$refs.form.resetFields();
        // 隐藏
      }
    }
  }
};
</script>
