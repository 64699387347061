<template>
  <el-dialog
    :title="HDMC"
    :visible.sync="visible"
    width="60%"
    :before-close="closeDescription"
  >
    <el-tabs
      v-model="activeName"
      v-for="(item, index) in formData"
      :key="index"
      @tab-click="handleClick"
      tab-position="left"
    >
      <el-tab-pane :label="item.ZBMC" :name="item.Id" :key="index">
        <el-checkbox
          label="一星评价描述"
          v-model="item.objList.SFGX1"
          @click.native="modifyCheck($event, item.Id, 1)"
        ></el-checkbox>
        <el-input
          placeholder="200个字以内"
          maxlength="200"
          style="width:70%;"
          v-model="item.objList.MSY1"
        ></el-input>
        <br />
        <el-checkbox
          label="二星评价描述"
          v-model="item.objList.SFGX2"
          @click.native="modifyCheck($event, item.Id, 2)"
        ></el-checkbox>
        <el-input
          placeholder="200个字以内"
          maxlength="200"
          style="width:70%;margin-top: 5px;"
          v-model="item.objList.MSY2"
        ></el-input>
        <br />
        <el-checkbox
          label="三星评价描述"
          v-model="item.objList.SFGX3"
          @click.native="modifyCheck($event, item.Id, 3)"
        ></el-checkbox>
        <el-input
          placeholder="200个字以内"
          maxlength="200"
          style="width:70%;margin-top: 5px;"
          v-model="item.objList.MSY3"
        ></el-input>
        <br />
        <el-checkbox
          label="四星评价描述"
          v-model="item.objList.SFGX4"
          @click.native="modifyCheck($event, item.Id, 4)"
        ></el-checkbox>
        <el-input
          placeholder="200个字以内"
          maxlength="200"
          style="width:70%;margin-top: 5px;"
          v-model="item.objList.MSY4"
        ></el-input>
        <br />
        <el-checkbox
          label="五星评价描述"
          v-model="item.objList.SFGX5"
          @click.native="modifyCheck($event, item.Id, 5)"
        ></el-checkbox>
        <el-input
          placeholder="200个字以内"
          maxlength="200"
          style="width:70%;margin-left: 2.2em;margin-top: 5px;"
          v-model="item.objList.MSY5"
        ></el-input>
      </el-tab-pane>
    </el-tabs>
    <div class="footer">
      <el-button size="small" @click="closeDescription">取 消</el-button>
      <el-button size="small" type="primary" @click="saveSetObject"
        >保 存</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    sonActivity: Object,
    currentActivity: Object
  },
  components: {},
  data() {
    return {
      HDMC: "设置描述语",
      activeName: "",
      form: [],
      checkList: {
        ZbId: "",
        Name: []
      },
      checkLists: [],
      formData: [],
      formDatas: []
    };
  },
  mounted() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
  },
  methods: {
    closeDescription() {
      this.$emit("closeDescription");
    },
    getSetObject() {
      this.$api.post(
        "/v1/evaluationactivity/GetSZMSY",
        {
          id: this.sonActivity.Id,
          hdid: this.sonActivity.HDId,
          PJHDGLCYDXList: this.sonActivity.PJHDGLCYDXList.toString(),
          token: localStorage.getItem("Token")
        },
        r => {
          if (r.Code === 0) {
            this.formData = [];
            this.formData = r.Target;
            this.formData && this.formData.length > 0
              ? (this.activeName = this.formData[0].Id)
              : "";
          }
        }
      );
    },
    modifyCheck(e, zbid, type) {
      if (e.target.tagName !== "INPUT") return;
      this.formData.find(item => {
        if (item.objList !== null && item.objList.ZBId === zbid) {
          if (type === 1) {
            item.objList.SFGX1 = e.target.checked;
          }
          if (type === 2) {
            item.objList.SFGX2 = e.target.checked;
          }
          if (type === 3) {
            item.objList.SFGX3 = e.target.checked;
          }
          if (type === 4) {
            item.objList.SFGX4 = e.target.checked;
          }
          if (type === 5) {
            item.objList.SFGX5 = e.target.checked;
          }
        }
      });
    },
    saveSetObject() {
      var values = [];
      this.formData.forEach(ele => {
        var obj = {
          WJId: this.sonActivity.Id,
          ZBId: ele.Id,
          SFGX1: ele.objList.SFGX1,
          MSY1: ele.objList.MSY1,
          SFGX2: ele.objList.SFGX2,
          MSY2: ele.objList.MSY2,
          SFGX3: ele.objList.SFGX3,
          MSY3: ele.objList.MSY3,
          SFGX4: ele.objList.SFGX4,
          MSY4: ele.objList.MSY4,
          SFGX5: ele.objList.SFGX5,
          MSY5: ele.objList.MSY5,
          CJR: this.user.Id
        };
        values.push(obj);
      });

      this.$api.post(
        "/v1/evaluationactivity/AddOrUpdateSZMSY",
        {
          models: values,
          token: localStorage.getItem("Token")
        },
        r => {
          if (r.Code === 0) {
            const data = r.Target;
            this.$message({
              type: "success",
              message: r.Message
            });
            this.closeDescription();
          }
        }
      );
    },
    // 切换tab
    handleClick(tab) {
      console.log(tab, "tab");
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getSetObject();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-tabs {
  ::v-deep .el-tabs__header {
    width: 90px;
  }
}
.footer {
  align-content: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
