<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="60%"
    top="1%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form :model="formData" ref="formData" :rules="rules">
      <div class="quest-titl-box">
        <div class="quest-titl" style="height: 40px;">
          <!-- <input placeholder="请输入问卷名称" type="text" class="popv-tab-inpt" /> -->
          <el-form-item
            prop="WJMC"
            :rules="[
              {
                required: true,
                message: '问卷名称不能为空',
                trigger: 'change'
              },
              {
                min: 1,
                max: 20,
                message: '不能超过20 个字符',
                trigger: 'change'
              }
            ]"
          >
            <el-input
              class="jf-quest-titl"
              v-model="formData.WJMC"
              placeholder="请输入问卷名称"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div
        class="operate-box"
        style="padding-bottom: 10px; margin-top: 10px; font-size: 14px;"
      >
        <span class="c-g-9" style="margin-left: 10px;">所属维度:</span>
        <el-form-item
          prop="SSWD"
          :rules="[
            { required: true, message: '请选择所属维度', trigger: 'change' }
          ]"
          :inline-message="true"
          style="display: inline-block; margin-bottom: unset;"
        >
          <el-cascader
            :props="{ expandTrigger: 'hover' }"
            v-model="formData.SSWD"
            :options="dimensions"
            :show-all-levels="false"
            placeholder="请选择末级维度"
            @change="handleWdSelected"
            size="small"
            class="jf-form"
            style="margin-left: 5px;"
          ></el-cascader>
        </el-form-item>
      </div>
      <div
        class="whole-tit-mianbox"
        v-for="(item, index) in formData.WJTMBList"
        :key="index"
      >
        <div
          style="font-size: 0px; box-sizing: border-box; padding-bottom: 15px;"
        >
          <div
            class="whole-tit-topleft"
            style="
              vertical-align: top;
              position: relative;
              top: 14px;
              height: 44px;
            "
          >
            {{ index + 1 }}.
          </div>
          <div class="whole-tit-toprig" style="height: 44px;">
            <el-form-item
              :prop="'WJTMBList[' + index + '].TMMC'"
              :rules="[
                { required: true, message: '请输入题目', trigger: 'change' },
                {
                  min: 1,
                  max: 50,
                  message: '不能超过50 个字符',
                  trigger: 'change'
                }
              ]"
            >
              <el-input
                type="textarea"
                autosize
                placeholder="请输入不超过50字"
                class="jf-textarea-wj jf-form"
                v-model="item.TMMC"
              ></el-input>
            </el-form-item>
          </div>
          <div
            class="jf_sele-l-box"
            style="
              vertical-align: top;
              position: relative;
              top: 3px;
              height: 44px;
            "
          >
            <el-form-item
              :prop="'WJTMBList[' + index + '].SSLB'"
              :rules="[
                {
                  required: true,
                  message: '请选择题目类型',
                  trigger: 'change'
                }
              ]"
            >
              <el-select
                v-model="item.SSLB"
                placeholder="请选择"
                size="small"
                class="jf-form"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in SSLB"
                  :key="item.Key"
                  :label="item.Value"
                  :value="item.Key"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div
            class="titdel-add-box"
            v-if="item.SSLB === 2"
            style="vertical-align: top; position: relative; top: 9px;"
          >
            最多能选的项数：&nbsp;&nbsp;
            <div
              class="digit-cutl"
              @click="clickBtnChangeInput(2, item.Id)"
            ></div>
            <div class="digit-textl">
              <div class="jf_sele-l-box">
                <el-form-item
                  :prop="'WJTMBList[' + index + '].JDXX'"
                  :rules="[
                    {
                      required: true,
                      message: '数目不能为空',
                      trigger: 'change'
                    },
                    {
                      type: 'number',
                      message: '数目必须为数字',
                      trigger: 'change'
                    }
                  ]"
                  class="jf_wj_dx"
                >
                  <el-input
                    type="number"
                    v-model.number="item.JDXX"
                    class="jf_digit-textl-input"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div
              class="digit-addl"
              style="z-index: 8000; position: relative;"
              @click="clickBtnChangeInput(1, item.Id)"
            ></div>
            <div class="tab-wz-buttl" style="font-size: 14px; float: right;">
              <a
                class="tab-wz-butta c-red"
                href="javascript:;"
                @click="delItem(index)"
                >X 删除此题</a
              >
            </div>
          </div>
        </div>
        <div class="smtit-whole-box" v-if="item.SSLB !== 3">
          <div class="smtit-box">
            <div
              class="smtit-cent"
              v-for="(fx, i) in formData.WJFXBList.filter(
                c => c.TMId === item.Id
              )"
              :key="i"
            >
              {{ ZM[i] }}.
              <el-form-item
                v-if="fx.FXLX === 1"
                :prop="'WJFXBList[' + i + '].TMMC'"
                :rules="[
                  { required: true, message: '请输入答案', trigger: 'change' },
                  {
                    min: 1,
                    max: 20,
                    message: '不能超过20 个字符',
                    trigger: 'change'
                  }
                ]"
                style="display: inline-block;"
              >
                <el-input
                  v-model="fx.TMMC"
                  size="small"
                  style="width: 300px; margin-left: 5px;"
                  class="jf-form"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <span v-else style="margin-left: 5px;">其他</span>
              &nbsp;&nbsp;&nbsp;&nbsp;分数：
              <el-form-item
                :prop="'WJFXBList[' + i + '].DF'"
                :rules="[
                  { required: true, message: '请输入分数', trigger: 'change' },
                  {
                    validator: validateNumber,
                    message: '分数必须为数字',
                    trigger: 'change'
                  }
                ]"
                style="display: inline-block;"
              >
                <el-input
                  v-model="fx.DF"
                  size="small"
                  class="jf-form"
                  style="width: 90px;"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <div class="tab-wz-buttl" style="margin-left: 30px;">
                <a
                  class="tab-wz-butta c-red"
                  href="javascript:;"
                  @click="delFX(fx.Id)"
                  >删除</a
                >
              </div>
            </div>
          </div>
          <div class="smtit-butt-box" v-if="item.SSLB !== 3">
            <div class="tab-wz-buttl" style="margin-right: 20px;">
              <a
                class="tab-wz-butta c-guree"
                href="javascript:;"
                @click="addFX(item.Id, 1)"
                >+添加普通选项</a
              >
            </div>
            <div class="tab-wz-buttl">
              <a
                v-if="
                  formData.WJFXBList.findIndex(
                    c => c.TMId === item.Id && c.FXLX === 2
                  ) === -1
                "
                class="tab-wz-butta c-guree"
                href="javascript:;"
                @click="addFX(item.Id, 2)"
                >+添加“其他”选项</a
              >
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="bigtit-add-box">
      <div class="tab-wz-buttl">
        <a class="tab-wz-butta c-guree" href="javascript:;" @click="addItem"
          >+ 新增问题</a
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { isNumber } from "@/style/js/Validator.js";
export default {
  data() {
    return {
      validateNumber: isNumber,
      submitting: false,
      labelWidth: "250px",
      rules: {},
      formData: {},
      SSLB: [
        { Key: 1, Value: "单选" },
        { Key: 2, Value: "多选" },
        { Key: 3, Value: "问答" }
      ],
      ZM: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ]
    };
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    dimensions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    init() {
      this.formData = JSON.parse(JSON.stringify(this.data));
      if (!this.formData.Id) {
        this.$set(this.formData, "WJTMBList", []);
        this.$set(this.formData, "WJFXBList", []);
      } else {
        this.$set(
          this.formData,
          "WJTMBList",
          JSON.parse(this.formData.WJTMBListJson)
        );
        this.$set(
          this.formData,
          "WJFXBList",
          JSON.parse(this.formData.WJFXBListJson)
        );
        this.formData.WJTMBList.sort(this.compare("PXH"));
        this.formData.WJFXBList.sort(this.compare("PXH"));
      }
    },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeDialog");
      });
    },
    confirmDialog() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.submitting = true;
          for (let i = 0; i < this.formData.WJTMBList.length; i++) {
            if (this.formData.WJTMBList[i].XZLX === 3) {
              this.formData.WJFXBList = this.formData.WJFXBList.filter(
                c => c.TMId !== this.formData.WJTMBList[i].Id
              );
            }
          }
          this.formData.SSXX = this.user.SSZZId;
          this.$api.post(
            "/v1/questionnaire/EditQuestionnaire",
            {
              token: this.$store.state.token,
              Questionnaire: this.formData,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              this.submitting = false;
              let data = {};
              if (this.formData.Id) {
                data = JSON.parse(JSON.stringify(this.formData));
              }
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message
                });
                this.$emit("closeDialog", data);
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    handleWdSelected(value) {
      if (value) {
        if (value.length > 1) {
          this.formData.SSWD = value[1];
          const wd = this.dimensions.find(c => c.value === value[0]);
          const wdC = wd.children.find(c => c.value === value[1]);
          this.formData.SSWDStr = wd.label + ">" + wdC.label;
        } else {
          this.formData.SSWD = value[0];
          const wd = this.dimensions.find(c => c.value === value[0]);
          this.formData.SSWDStr = wd.label;
        }
      }
    },
    addItem() {
      const tmId = new Date().getTime() + "";
      this.formData.WJTMBList.push({ Id: tmId, TMMC: "", XZLX: 1 });
    },
    delItem(index) {
      this.formData.WJFXBList = this.formData.WJFXBList.filter(
        c => c.TMId !== this.formData.WJTMBList[index].Id
      );
      this.formData.WJTMBList.splice(index, 1);
    },
    addFX(TMId, FXLX) {
      this.formData.WJFXBList.push({
        Id: new Date().getTime() + "",
        TMId: TMId,
        TMMC: "",
        FXLX: FXLX,
        DF: ""
      });
    },
    delFX(id) {
      const index = this.formData.WJFXBList.findIndex(c => c.Id === id);
      this.formData.WJFXBList.splice(index, 1);
    },
    // inputValidate(id) {
    //   let obj = this.formData.WJTMBList.find(c => c.Id === id);
    //   if (obj) {
    //     if (obj.JDXX > 9) {
    //       obj.JDXX = 9;
    //     } else if (obj.JDXX < 0) {
    //       obj.JDXX = 0;
    //     }
    //   }
    // },
    clickBtnChangeInput(type, id) {
      const obj = this.formData.WJTMBList.find(c => c.Id === id);
      if (obj) {
        if (type === 1) {
          obj.JDXX++;
        } else {
          obj.JDXX--;
        }
        if (obj.JDXX > 9) {
          obj.JDXX = 9;
        } else if (obj.JDXX < 0) {
          obj.JDXX = 0;
        }
      }
    },
    compare(p) {
      // 这是比较函数
      return function(m, n) {
        var a = m[p];
        var b = n[p];
        return a - b; // 升序
      };
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        // 隐藏
        this.$refs.formData.resetFields();
      }
    }
  }
};
</script>
