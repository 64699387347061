<template>
  <div class="data-page2">
    <!-- 顶部功能按钮 -->
    <div class="operating-btns">
      <el-button
        size="small"
        icon="el-icon-download"
        style="margin-right:12px"
        @click="exportExcel(0)"
        >下载模版</el-button
      >
      <el-upload
        style="display:inline-block"
        class="upload-demo"
        ref="upload"
        accept=".xls, .xlsx"
        :action="uploadURL"
        :on-change="uploadrfid"
        :show-file-list="false"
        :auto-upload="false"
      >
        <el-button size="small" icon="el-icon-upload2">导入</el-button>
      </el-upload>
      <el-button
        size="small"
        icon="el-icon-download"
        style="margin-left:12px"
        @click="exportExcel(1)"
        >导出</el-button
      >
      <el-button
        size="small"
        icon="el-icon-finished"
        type="primary"
        :disabled="!jd.state"
        @click="submit"
        >保存</el-button
      >
      <el-button
        size="small"
        icon="el-icon-back"
        type="warning"
        @click="
          $router.replace({
            name: 'stagecourse',
          })
        "
        >返回</el-button
      >
      <div class="filter-btns">
        <div class="label">排序：</div>
        <el-select
          v-model="order"
          placeholder="请选择排序方式"
          size="small"
          @change="orderChange"
        >
          <el-option
            v-for="item in orderOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <div class="search">
          <el-input
            v-model="search"
            size="small"
            placeholder="请输入名字或者学号"
          >
          </el-input>
          <el-button
            icon="el-icon-search"
            type="primary"
            size="small"
            @click="handleSearch"
          ></el-button>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="findEmpty"
          >查找未评价行</el-button
        >
      </div>
    </div>
    <!-- 表格套表单 -->
    <el-form class="data-page2-form" :model="form" ref="form">
      <div style="font-size:12px;color:#ccc;padding-left:20px;">
        *鼠标单击列表头部可选择列 鼠标右键列表头部可批量修改
      </div>
      <el-table
        ref="table"
        v-loading="uploading"
        height="500"
        border
        highlight-current-row
        :data="form.bpr"
        :cell-class-name="cellClassHandle"
        :header-cell-class-name="headerClassHandle"
        @header-click="headerClick"
        @header-contextmenu="headerContextMenu"
      >
        <template v-for="(item, index) in indexList">
          <el-table-column
            :key="item.Id"
            :index="index"
            :prop="index === 0 ? item.bprName : item.Id"
            :min-width="index === 0 ? 75 : 120"
            :fixed="index === 0 ? true : false"
          >
            <template slot="header">
              <el-tooltip
                class="item"
                effect="light"
                :content="item.BZ ? '备注：' + item.BZ : '暂无备注'"
                placement="top-start"
              >
                <div>
                  {{ index === 0 ? "被评人" : item.IndexName }}
                  <span
                    v-if="
                      index !== 0 &&
                        item.IndexClassify === 1 &&
                        item.IndexType === 1
                    "
                    style="color:green"
                    >{{ `(正${item.MaxScore})` }}
                  </span>
                  <span
                    v-if="
                      index !== 0 &&
                        item.IndexClassify === 1 &&
                        item.IndexType === 2
                    "
                    style="color:red"
                    >{{ `(负${item.MaxScore})` }}
                  </span>
                  <span
                    v-if="
                      index !== 0 &&
                        item.IndexClassify === 1 &&
                        item.IndexType === 3
                    "
                    >{{ `(中评${item.MaxScore})` }}
                  </span>
                  <span v-if="index !== 0 && item.IndexClassify === 2"
                    >({{ item.DefaultScore }})
                  </span>
                </div>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span v-if="index === 0">{{
                scope.row[scope.column.property]
              }}</span>
              <el-form-item
                v-else
                :prop="'bpr[' + scope.$index + ']' + '.' + item.Id"
                :rules="[
                  {
                    validator: validIndex,
                    trigger: 'blur',
                    item: item,
                  },
                ]"
              >
                <!-- 上面的id:item.Id 可以给rule加属性 -->
                <el-input
                  :class="{
                    'add-state':
                      item.IndexClassify === 1 &&
                      (item.IndexType === 1 ||
                        scope.row[scope.column.property] > 0),
                    'minus-state':
                      item.IndexClassify === 1 &&
                      (item.IndexType === 2 ||
                        scope.row[scope.column.property] < 0),
                  }"
                  size="small"
                  :readonly="!jd.state"
                  v-model="scope.row[scope.column.property]"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </el-form>
    <!-- 批量填充弹窗 -->
    <el-dialog
      title="自动填充"
      :visible.sync="batch.visible"
      width="40%"
      :before-close="batchSubmit"
    >
      <el-form
        class="batch-form"
        ref="batchForm"
        :model="batch"
        label-width="80px"
      >
        <el-form-item
          label="填充数值"
          prop="val"
          :rules="[
            {
              validator: validIndex,
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="batch.val" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <span style="color:#bdb7b7">
            * 注意：所填数值不能超过指标的最大分数或者星数
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="batch-footer">
        <el-button @click="batchSubmit">取 消</el-button>
        <el-button type="primary" @click="batchSubmit(1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "stagedata2",
  data() {
    return {
      form: {
        bpr: [],
      },
      rules: {},
      // 批量填充弹窗
      batch: {
        visible: false,
        val: "",
      },
      // 指标数组
      indexList: [],
      // 被点击的列
      selectedColumn: [],
      term: {},
      hd: {},
      nj: {},
      bj: {},
      kc: {},
      jd: {},
      user: {},
      publicPath: process.env.BASE_URL,
      uploading: false,
      oldEvaluation: [],
      // 排序
      order: 1,
      orderOptions: [
        {
          label: "姓名",
          value: 1,
        },
        {
          label: "学号",
          value: 2,
        },
      ],
      // 搜索
      search: "",
      starDescription: [],
    };
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return (
        "/file/file/fileservice/UploadFile?token=" + this.$store.state.token
      );
    },
  },
  async created() {
    let { hd, nj, term, bj, kc, jd, bpr, index } = this.$route.params;
    this.indexList = JSON.parse(JSON.stringify(index));

    bpr.sort((a, b) => a.Name.localeCompare(b.Name));
    this.form.bpr = bpr;
    this.term = term;
    this.hd = hd;
    this.nj = nj;
    this.bj = bj;
    this.kc = kc;
    this.jd = jd;

    this.indexList.forEach((item) => {
      this.form.bpr.forEach((it) => {
        this.$set(it, item.Id, "");
      });
    });
    this.indexList.unshift({
      bprName: "Name",
    });

    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      this.user = JSON.parse(tempuser);
    }

    // 如果是星星指标 需要获取描述语
    if (this.indexList.some((item) => item.IndexClassify === 2)) {
      let params = {
        MainId: this.hd.parentId ? this.hd.parentId : this.hd.Id,
        ObjIds: [],
        childId: this.hd.Id,
      };
      params.ObjIds = this.indexList.reduce((pre, cur) => {
        if (!pre.some((item) => item === cur.objId)) pre.push(cur.objId);
        return pre;
      }, []);
      params.ObjIds = params.ObjIds.join("");
      await this.getStarDescription(params);
    }

    this.getMyEvalu();
  },
  beforeRouteLeave(to, from, next) {
    from.meta.title = "";
    next();
  },
  methods: {
    // 单机列头
    headerClick(column) {
      if (!this.jd.state || column.index === 0) return;
      if (this.selectedColumn.some((item) => item.index === column.index)) {
        this.selectedColumn = [];
      } else {
        this.selectedColumn = [
          {
            index: column.index,
            property: column.property,
          },
        ];
      }
    },
    // 右击列头
    headerContextMenu(column) {
      if (this.selectedColumn.length == 0 || column.index === 0) return;
      document.oncontextmenu = () => false;
      this.batch.visible = true;
    },
    // 单元格类名回调函数
    cellClassHandle({ column }) {
      if (this.selectedColumn.some((item) => item.index === column.index))
        return "my-cell-class";
      else return "";
    },
    // 标头单元格类名回调函数
    headerClassHandle({ column }) {
      if (this.selectedColumn.some((item) => item.index === column.index))
        return "my-header-class";
      else return "";
    },
    batchSubmit(type) {
      if (type === 1) {
        this.$refs.batchForm.validate((res) => {
          if (res) {
            this.selectedColumn.forEach((item) => {
              this.form.bpr.forEach((bpr) => {
                bpr[item.property] = this.batch.val;
              });
            });
            this.batch.visible = false;
          }
        });
      } else {
        this.batch.visible = false;
        document.oncontextmenu = () => true;
      }
    },
    validIndex(rule, val, callback) {
      // let id = rule.field.match(/\]\.(\S*)/)[1];
      // let idx = rule.field.match(/\d+/)[0];
      let addReg = /^(0(\.\d{1,2})?|([1-9][0-9]*(\.\d{1,2})?))$/; //加分
      let minusReg = /^(0|-((0\.\d{1,2})|[1-9][0-9]*(\.\d{1,2})?))$/; //减分
      let middleReg = /^-?(0(\.\d{1,2})?|([1-9][0-9]*(\.\d{1,2})?))$/; //中评
      let starReg2 = /^(0|[1-9][0-9]*)$/;
      let item = rule.item
        ? rule.item
        : this.indexList[this.selectedColumn[0].index];
      if (this.hd.EvalutionFormat === 2) {
        if (val === "" || val === undefined || val === null) return callback();
        else if (!starReg2.test(val)) {
          return callback(new Error("您的输入不规范"));
        } else if (val > item.DefaultScore) {
          return callback(new Error("不能超过指标最大星数"));
        } else return callback();
      } else {
        if (val === "" || val === undefined || val === null) return callback();
        else {
          if (item.IndexType === 1) {
            if (!addReg.test(val)) {
              return callback(new Error("您的输入不规范"));
            } else if (val > item.MaxScore) {
              return callback(new Error("不能超过指标最大分数"));
            } else return callback();
          } else if (item.IndexType === 2) {
            if (!minusReg.test(val)) {
              return callback(new Error("您的输入不规范"));
            } else if (Math.abs(val) > Math.abs(item.MaxScore)) {
              return callback(new Error("不能小于指标最小分数"));
            } else return callback();
          } else {
            if (!middleReg.test(val)) {
              return callback(new Error("您的输入不规范"));
            } else if (Math.abs(val) > Math.abs(item.MaxScore)) {
              return callback(new Error("不能超过指标分数"));
            } else return callback();
          }
        }
      }
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          let bpr = this.handleBPR();
          if (bpr.length === 0) return;
          this.$api.post(
            "v1/evaluationactivity/SaveUserEvalutionContent",
            {
              EvationId: this.hd.Id, //主活动或子活动id                string
              MainEvationId: this.hd.parentId ? this.hd.parentId : "", //子活动的父id               string
              EvationName: this.hd.Name, //活动名称               string
              ClassId: this.bj.Id ? this.bj.Id : "", //班级Id               string
              GradeId: this.nj.Id ? this.nj.Id : "", //年级Id               string
              NJ: this.nj.number ? Number(this.nj.number) : this.nj.GradeNumber, //年级数字
              EvalutionLevel: this.hd.parentId ? 2 : 1, //1主活动2子活动         int
              EvFrequency: 2, //1多次评价2单次评价 阶段活动只能单次评价         int
              UserId: this.user.Id, //创建人           string
              UserType: this.hd.HDCPR, //参评人用户类型      int
              EvaluatedObject: this.hd.EvaluatedObject, //被评价对象类型1学生2教师3班级4小组       int
              PJR: this.user.Id, //小助手评价时传教师Id                          string
              CollegePeopleList: bpr, //被评价人            Array
              // EalvtionindexList: [], //评价指标（对象中新增星星指标每颗星分数             [Array]
              SchoolId: this.user.SSZZId, //
              SchoolCode: this.user.SchoolCode,
              TermYear: this.term.SSXN,
              TermId: this.term.Id,
              TermType: this.term.XQLX, //学期上下期类型
              EvalSourse: 4, //评价来源（1Ios主动评价 、2 扫码评价 3被动采集,4评价客户端，5安卓主动评价6.H5主动评价）
              DataSourseType: this.hd.EvalutionFormat, //1.自定义活动-通用2.自定义活动-星星 3自定义活动-问卷
              HDXX: 4, //活动形式:4主评2互评1 自评
              HDLX: 2, //活动类型1.过程活动2.阶段性活动
              StageId: this.jd.Id, //阶段id
              CourseId: this.kc.courseId ? this.kc.courseId : null, //课程Id
              // WJId: "", //问卷Id
              // WJName: "", //问卷名
              token: this.user.Token,
            },
            (res) => {
              if (res.Code === 0) {
                this.$message.success("保存成功");
                this.oldEvaluation = JSON.parse(JSON.stringify(this.form.bpr)); //将保存成功后的值作为旧值
              }
            }
          );
        } else {
          let flag = false;
          let bprIdx = "";
          this.form.bpr.some((bpr, idx) => {
            return this.indexList.some((Index) => {
              this.validIndex({ item: Index }, bpr[Index.Id], (res) => {
                if (res) {
                  flag = true;
                  bprIdx = idx;
                }
              });
              return flag;
            });
          });
          let bprH = bprIdx * 58;
          this.$refs.table.bodyWrapper.scrollTop = bprH;
        }
      });
    },
    handleBPR() {
      /// 被评人
      let CollegePeopleList = [];
      this.form.bpr.forEach((bpr) => {
        let EalvtionindexList = [];
        let oldBpr = this.oldEvaluation.find((item) => item.Id === bpr.Id);

        let valueChanged = false;
        this.indexList.some((item) => {
          // 之前没值且现在有值 或者 之前的值和现在的值不同
          if (Object.keys(bpr).some((key) => key === item.Id)) {
            if (
              (!oldBpr && bpr[item.Id]) ||
              (oldBpr && oldBpr[item.Id] !== bpr[item.Id])
            )
              valueChanged = true;
          }
        });
        if (valueChanged) {
          this.indexList.forEach((item) => {
            if (Object.keys(bpr).some((key) => key === item.Id)) {
              // 现在有值就传 没值不传
              if (bpr[item.Id] || bpr[item.Id] === 0) {
                console.log(item, "itemememememe");
                EalvtionindexList.push({
                  Id: item.Id, /// 指标Id
                  IndexName: item.IndexName, //指标名称
                  IndexPicture: item.Picture || null, //指标图片
                  IndexType: item.IndexClassify, /// 指标类型1通用2星星3问卷
                  Score:
                    bpr[item.Id] === undefined ||
                    bpr[item.Id] === null ||
                    bpr[item.Id] === ""
                      ? null
                      : Number(bpr[item.Id]), /// 指标分数（根据指标类型传正负分数）
                  StarScore: this.hd.EvalutionFormat === 2 ? item.Score : null, ///星星评价每颗星分数 只有星星指标有
                  PIndexId: item.ParentId, /// 指标父Id
                  Description: this.indexList.some(
                    (item) => item.IndexClassify === 2
                  )
                    ? this.handleDescription(item, bpr[item.Id])
                    : bpr[item.Id + "Remark"] || "", /// web端没得这个字段 通用和星星移动端会传过来这个数据, web端这个字段是存问卷单个指标描述以及问卷问答答案
                  ObjectId: item.objId, /// 所属对象Id
                  MarkType: item.IndexType, /// 指标类型1加分2减分3 中评(1 普通选项2其他选项)
                  // AattachmentList: [] /// 指标单一附件
                  FJBS: bpr[item.Id + "FJBS"] || "", //附件标识2
                  Remark: this.indexList.some(
                    (item) => item.IndexClassify === 2
                  )
                    ? this.handleDescription(item, bpr[item.Id])
                    : bpr[item.Id + "Remark"] || "", //描述语
                });
              }
            }
          });
        }

        if (EalvtionindexList && EalvtionindexList.length > 0) {
          CollegePeopleList.push({
            CollegePeopleId: bpr.Id, /// 被评人id
            Name: bpr.Name, /// 名称
            EalvtionindexList,
          });
        }
      });

      return CollegePeopleList;
    },
    // 获取我的评价记录 通用星星
    getMyEvalu() {
      this.$api.post(
        "v1/evaluationactivity/GetUserEvalutionRecord",
        {
          EvaltionId: this.hd.Id, //	是	string	评价活动Id
          HDLX: 2, //	是	int	1过程2阶段
          UserId: this.user.Id, //	是	string	评价用户ID
          EvalutionFormat: this.hd.EvalutionFormat, //	是	int	评价方式 1.通用指标2 星星 3问卷
          SchoolId: this.user.SSZZId, //	是	string	学校Id
          TermId: this.term.Id, //	是	string	学期Id
          SchoolCode: this.user.SchoolCode, //	是	string	学校编码
          TermYear: this.term.SSXN, //	是	string	学期学年
          TermType: this.term.XQLX, //	是	int	学期类型
          StageId: this.jd.Id, //阶段id
          // DataId: 0, //	是	int	分页最后一条数据Id 第一页0
          EObjects:
            this.hd.UserEObjectList.length > 1
              ? this.hd.UserEObjectList.map((item) => item.EObjectId).join()
              : null, //	否	string	评价对象多个用，分割（不需要不传）
          CourseId: this.kc.courseId ? this.kc.courseId : "", //	否	string	不传不做课程筛选
          OrgId: this.bj ? this.bj.Id : "", //	是	string	被评价对象组织机构（筛选数据）
          token: this.user.Token,
        },
        (res) => {
          if (res.Code === 0 && res.Target && res.Target.length > 0) {
            res.Target.forEach((el) => {
              this.form.bpr.forEach((bpr) => {
                if (
                  bpr.Id === el.CollegePeopleId &&
                  Object.keys(bpr).some((key) => {
                    if (key === el.IndexId) {
                      return true;
                    } else return false;
                  })
                ) {
                  bpr[el.IndexId] =
                    el.Score === 0 ? el.Score : el.Score ? el.Score : "";
                  bpr[`${el.IndexId}FJBS`] = el.FJBS || "asdf";
                  bpr[`${el.IndexId}Remark`] = el.PJNR || "";
                }
              });
            });

            this.oldEvaluation = JSON.parse(JSON.stringify(this.form.bpr));
          }
        }
      );
    },
    // 导入
    uploadrfid(file) {
      this.uploading = true;
      let files = { 0: file.raw };
      this.readExcel(files);
    },
    readExcel(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      let indexName = [];

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // 导入的数据某一格获取了焦点后 又没有输入数据 直接保存再导入 转换的res会少指标名
        let reg = /\d+/g;
        indexName = Object.keys(worksheet)
          .reduce((pre, cur) => {
            return cur.match(reg) && cur.match(reg)[0] === "1"
              ? pre.concat(worksheet[cur].h)
              : pre;
          }, [])
          .filter((name) => name !== "被评人");

        const res = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        this.handleExcel(res, indexName);
        this.$refs.upload.value = "";
      };
      fileReader.readAsArrayBuffer(files[0]);
    },
    handleExcel(excel, names) {
      let arr = [];
      if (excel && excel.length > 0) {
        names.forEach((name) =>
          arr.push({
            indexName: name,
          })
        );
        excel.forEach((item) => {
          arr.forEach((el) => {
            el[item["被评人"]] =
              item[el.indexName] === "" ? "" : item[el.indexName];
          });
        });
        console.log(arr, "arr");
        // 判断任意指标是否评分
        let flag = this.indexList.some((Index) =>
          this.form.bpr.some((bpr) => (bpr[Index.Id] ? true : false))
        );
        // 有评分提醒用户
        if (flag) {
          this.$confirm(`部分指标已有评分 是否覆盖分数!`, {
            distinguishCancelAndClose: true,
            confirmButtonText: "覆盖",
            cancelButtonText: "保留",
            type: "warning",
          })
            .then(() => {
              this.handleExcel2(arr, 1);
            })
            .catch((action) => {
              if (action === "cancel") this.handleExcel2(arr, 2);
              else this.uploading = false;
            });
        } else {
          this.handleExcel2(arr);
        }
      } else {
        this.uploading = false;
        this.$message.error("您导入的数据有误！");
      }
    },
    handleExcel2(arr, res) {
      arr.forEach((item) => {
        let index = ""; //通过指标名找到的指标
        // 找到名字相对应的指标
        this.indexList.forEach((el) => {
          if (
            el.IndexClassify === 1 &&
            item.indexName.includes(
              `${el.IndexName}(${
                el.IndexType === 1 ? "正" : el.IndexType === 2 ? "负" : "中评"
              }${el.MaxScore})`
            )
          )
            index = el;
          if (
            el.IndexClassify === 2 &&
            item.indexName.includes(`${el.IndexName}(${el.DefaultScore}星)`)
          )
            index = el;
        });
        this.form.bpr.forEach((bpr) => {
          if (bpr[index.Id]) {
            // 有值
            if (res === 1) {
              // 覆盖原有数据 且这个人存在
              if (Object.hasOwnProperty.call(item, bpr.Name)) {
                bpr[index.Id] = item[bpr.Name];
              }
            } else {
              // 保留原有数据
              // if (bpr[index.Id] === "") bpr[index.Id] = item[bpr.Name];
            }
          } else {
            // 没值直接赋值 导入的数据中有这个人
            if (Object.hasOwnProperty.call(item, bpr.Name)) {
              bpr[index.Id] = item[bpr.Name];
            }
          }
        });
      });
      this.uploading = false;
      this.$message.success("导入完毕");
    },
    // 导出
    exportExcel(type) {
      let header = [];
      this.indexList.forEach((item) => {
        if (item.Id) {
          if (this.hd.EvalutionFormat === 1) {
            if (item.IndexType === 1)
              header.push(item.IndexName + `(正${item.MaxScore})`);
            else if (item.IndexType === 2)
              header.push(item.IndexName + `(负${item.MaxScore})`);
            else header.push(item.IndexName + `(中评${item.MaxScore})`);
          } else {
            header.push(item.IndexName + `(${item.DefaultScore}星)`);
          }
        } else header.push("被评人");
      });
      header.unshift("学号");

      let indexType = "";
      let filename = "";

      if (this.hd.EvalutionFormat === 1) indexType = "通用指标";
      else indexType = "星星指标";

      if (this.hd.EvaluatedObject === 1) {
        filename = `${this.hd.Name}--${this.bj.Name}---${indexType}${
          type ? "评分" : "模板"
        } `;
      } else {
        filename = `${this.hd.Name}--老师---${indexType}${
          type ? "评分" : "模板"
        }`;
      }
      let indexList = [{ xh: "xh" }, ...this.indexList];
      import("@/style/js/Export2Excel").then((excel) => {
        let data = [];
        this.form.bpr.forEach((bpr) => {
          let arr = [];
          indexList.forEach((item) => {
            if (!item.Id && item.xh) arr.push(bpr.XH);
            else if (!item.Id && !item.xh) arr.push(bpr.Name);
            else {
              if (type) arr.push(bpr[item.Id]);
              else arr.push("");
            }
          });
          data.push(arr);
        });

        excel.export_json_to_excel({
          header, //表头 必填
          data, //具体数据 必填
          filename, //非必填
        });
      });
    },
    // 排序变化
    orderChange(val) {
      let reg = /\d+/g;
      if (val === 1) {
        this.form.bpr.sort((a, b) => a.Name.localeCompare(b.Name));
      } else {
        this.form.bpr.sort((a, b) => a.XH.match(reg)[0] - b.XH.match(reg)[0]);
      }
    },
    // 查找姓名或学号
    handleSearch() {
      if (this.search === "") return;
      let idx = this.form.bpr.findIndex(
        (item) =>
          item.Name.includes(this.search) || item.XH.includes(this.search)
      );
      if (idx === -1) {
        this.$message.error("未找到匹配对象");
      } else {
        let el_table__body = document.getElementsByClassName(
          "el-table__body"
        )[0];
        let tr_arr = el_table__body.getElementsByTagName("tr");
        this.$refs.table.bodyWrapper.scrollTop = tr_arr[idx].offsetTop;
      }
    },
    // 排空
    findEmpty() {
      let tdIndex = 0;
      let idx = this.form.bpr.findIndex((bpr) =>
        this.indexList.some((item, index) => {
          if (index === 0) return false;
          if (
            bpr[item.Id] === "" ||
            bpr[item.Id] === undefined ||
            bpr[item.Id] === null
          ) {
            tdIndex = index;
            return true;
          } else return false;
        })
      );
      if (idx === -1) {
        this.$message.warning("未发现空白评价");
      } else {
        let el_table__body = document.getElementsByClassName(
          "el-table__body"
        )[0];
        let tr_arr = el_table__body.getElementsByTagName("tr");
        if (tr_arr[idx] && tr_arr[idx].getElementsByTagName("td")) {
          let emptyTd = tr_arr[idx].getElementsByTagName("td")[tdIndex];
          if (emptyTd) {
            emptyTd.style.border = "1px solid red";
            setTimeout(() => {
              emptyTd.style.border = "none";
            }, 1000);
          }
        }
        this.$refs.table.bodyWrapper.scrollTop = tr_arr[idx].offsetTop;
      }
    },
    // 获取星星描述语
    getStarDescription(params) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetSZMSY",
          {
            PJHDGLCYDXList: params.ObjIds, //对象id
            hdid: params.MainId, //主活动id
            id: params.childId, //子活动id
            token: this.user.Token, //
          },
          (res) => {
            if (res.Code === 0) {
              this.starDescription = res.Target;
              resolve();
            }
          }
        );
      });
    },
    // 保存数据是处理描述语
    handleDescription(item, score) {
      let description = "";
      if (score > 0) {
        let res = this.starDescription.find((el) => el.Id == item.Id);
        if (res) {
          if (res.objList["SFGX" + score]) {
            description = res.objList["MSY" + score];
          }
        }
      }
      return description;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-page2 {
  padding: 20px 10px;
  box-shadow: 1px 1px 2px 2px #f0f0fb;

  .operating-btns {
    margin-bottom: 15px;
    display: flex;
    .filter-btns {
      display: flex;
      align-items: center;
      margin-left: 15px;
      .label {
        font-size: 16px;
      }
      .el-select {
        width: 120px;
      }
      .search {
        display: flex;
        align-items: center;
        margin: 0 15px;
        .el-input {
          width: 150px;
          ::v-deep .el-input__inner {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .el-button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
      }
    }
  }
  .data-page2-form {
    .el-table {
      ::v-deep .el-table__body-wrapper {
        scroll-behavior: smooth;
      }
      .el-form-item {
        margin-bottom: 10px;
      }
      ::v-deep .el-table__cell {
        padding: 6px 0 0;
      }
      ::v-deep .my-cell-class {
        background-color: #e0e0e0;
      }
      ::v-deep .my-header-class {
        background-color: #e0e0e0;
      }

      .add-state {
        ::v-deep .el-input__inner {
          color: green;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .minus-state {
        ::v-deep .el-input__inner {
          color: red;
          font-weight: 700;
          font-size: 16px;
        }
      }
      ::v-deep .el-form-item__error {
        top: 82%;
      }
    }
  }
}
</style>
