<template>
  <div class="cont-whole-box" style="box-shadow:unset" v-loading="loading">
    <div class="multi-opt-box" style="margin-top:0px">
      <div class="multi-opt">
        <div class="multi-opt-left">年级</div>
        <div class="multi-opt-rig">
          <el-radio-group
            v-model="gradeId"
            @change="handleGrade"
            class="jf-radio"
            size="small"
          >
            <el-row>
              <el-col
                :span="grades.length / 8"
                v-for="item in grades"
                :key="item.Id"
                ><el-radio :label="item.Id" border>{{
                  item.Name
                }}</el-radio></el-col
              >
            </el-row>
          </el-radio-group>
        </div>
      </div>
      <div class="multi-opt">
        <div class="multi-opt-left">班级</div>
        <div class="multi-opt-rig">
          <el-radio-group
            v-model="classId"
            @change="handleClass"
            class="jf-radio"
            size="small"
          >
            <el-row>
              <el-col
                :span="
                  classDatas.length / 8 === 1 ? 1.1 : classDatas.length / 8
                "
                v-for="item in classDatas.filter(c => c.ParentId === gradeId)"
                :key="item.Id"
                ><el-radio :label="item.Id" border>{{
                  item.Name
                }}</el-radio></el-col
              >
            </el-row>
          </el-radio-group>
        </div>
      </div>
      <div class="multi-opt">
        <div class="multi-opt-left">课程</div>
        <div class="multi-opt-rig">
          <el-radio-group
            v-model="courseId"
            class="jf-radio"
            style="width:100%"
            size="small"
          >
            <div class="course-item-block">
              <div
                class="course-item"
                :class="{ 'cur-course': courseId === item.Id }"
                v-for="(item, index) in courseList"
                :key="index"
              >
                <el-radio :label="item.Id" border>{{ item.Name }}</el-radio>
              </div>
            </div>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!--内容操作区结束-->
    <div style="padding:30px 0">
      <el-button icon="el-icon-download" @click="downloadTemplateVisible = true"
        >下载成绩模版</el-button
      >
      <el-button icon="el-icon-download" @click="exportScoreVisible = true"
        >导出</el-button
      >
      <div style="display:inline-block;margin-left:10px" v-if="false">
        <el-button
          v-if="!openMissTest"
          @click="openMissTest = true"
          icon="el-icon-finished"
          type="danger"
          >打开缺考</el-button
        >
        <el-button
          v-else
          @click="openMissTest = false"
          icon="el-icon-finished"
          type="danger"
          plain
          >关闭缺考</el-button
        >
      </div>

      <el-input
        style="width:150px;float:right"
        placeholder="请输入学号/姓名"
        suffix-icon="el-icon-search"
        v-model="searchStr"
      >
      </el-input>
    </div>
    <!--表格区开始-->
    <div>
      <vxe-table
        class="jftable-scrollbar"
        border
        resizable
        show-overflow
        keep-source
        align="center"
        height="416"
        v-loading="initTableLoading"
        :data="
          tableData.filter(
            c => c.Name.includes(searchStr) || c.XH.includes(searchStr)
          )
        "
      >
        <vxe-table-column
          fixed="left"
          field="XH"
          title="学号"
          sortable
          min-width="80px"
        ></vxe-table-column>
        <vxe-table-column
          fixed="left"
          field="Name"
          title="姓名"
          sortable
          min-width="80px"
        ></vxe-table-column>
        <vxe-table-column
          v-for="(item, index) in tableDataHeader.filter(c =>
            c.CourseId.includes(courseId)
          )"
          min-width="105px"
          :key="index"
          :field="item.CourseId"
          :title="item.CourseName"
        >
          <template v-slot="{ row, column }">
            <div v-if="courseId">
              <el-tag
                v-if="
                  row.CourseScoreList &&
                    row.CourseScoreList.find(
                      c => c.CourseId === column.property
                    ).MissTest &&
                    !openMissTest
                "
                disable-transitions
                type="danger"
                style="width:100%"
                >缺考</el-tag
              >
              <el-input-number
                v-else-if="
                  row.CourseScoreList &&
                    row.CourseScoreList.find(c => c.CourseId === courseId) &&
                    !row.CourseScoreList.find(c => c.CourseId === courseId)
                      .MissTest &&
                    !openMissTest &&
                    row.CourseScoreList.find(c => c.CourseId === courseId)
                      .InputType === 1
                "
                style="width:100%"
                v-model="
                  row.CourseScoreList.find(c => c.CourseId === courseId).Score
                "
                :min="0"
                :precision="1"
                :max="
                  row.CourseScoreList.find(c => c.CourseId === courseId)
                    .ScoreLimit
                "
                :controls="false"
                placeholder="暂无分数"
                disabled
              ></el-input-number>
              <el-select
                v-else-if="
                  row.CourseScoreList &&
                    !row.CourseScoreList.find(
                      c => c.CourseId === column.property
                    ).MissTest &&
                    !openMissTest &&
                    row.CourseScoreList.find(
                      c => c.CourseId === column.property
                    ) &&
                    row.CourseScoreList.find(
                      c => c.CourseId === column.property
                    ).InputType === 2
                "
                v-model="
                  row.CourseScoreList.find(c => c.CourseId === column.property)
                    .Level
                "
                placeholder="暂无等级"
                disabled
              >
                <el-option
                  v-for="(o, i) in row.CourseScoreList.find(
                    c => c.CourseId === courseId
                  ).LevelDatas"
                  :key="i"
                  :label="o.DJ"
                  :value="o.Id"
                >
                </el-option>
              </el-select>
              <el-checkbox
                v-else-if="openMissTest"
                class="jf-checkbox-red"
                v-model="
                  row.CourseScoreList.find(c => c.CourseId === column.property)
                    .MissTest
                "
                :disabled="
                  row.CourseScoreList.find(c => c.CourseId === column.property)
                    .Level ||
                    row.CourseScoreList.find(
                      c => c.CourseId === column.property
                    ).Score
                "
                border
                style="width:100%"
                >{{
                  row.CourseScoreList.find(c => c.CourseId === column.property)
                    .Level ||
                  row.CourseScoreList.find(c => c.CourseId === column.property)
                    .Score
                    ? "此处已有成绩"
                    : "缺考"
                }}</el-checkbox
              >
            </div>
            <div v-else>
              <el-tag
                v-if="
                  row.CourseScoreList &&
                    row.CourseScoreList.find(
                      c => c.CourseId === column.property
                    ) &&
                    row.CourseScoreList.find(
                      c => c.CourseId === column.property
                    ).MissTest
                "
                disable-transitions
                type="danger"
                style="width:100%"
                >缺考</el-tag
              >
              <div v-else>
                <div
                  v-if="
                    row.CourseScoreList &&
                      row.CourseScoreList.find(
                        c => c.CourseId === column.property
                      ) &&
                      row.CourseScoreList.find(
                        c => c.CourseId === column.property
                      ).InputType === 1
                  "
                >
                  {{
                    row.CourseScoreList &&
                      row.CourseScoreList.find(
                        c => c.CourseId === column.property
                      ) &&
                      row.CourseScoreList.find(
                        c => c.CourseId === column.property
                      ).Score
                  }}
                </div>
                <div v-else>
                  {{
                    row.CourseScoreList &&
                      row.CourseScoreList.find(
                        c => c.CourseId === column.property
                      ) &&
                      row.CourseScoreList.find(
                        c => c.CourseId === column.property
                      ).Level &&
                      row.CourseScoreList.find(
                        c => c.CourseId === column.property
                      ).LevelDatas.find(
                        l =>
                          l.Id ===
                          row.CourseScoreList.find(
                            c => c.CourseId === column.property
                          ).Level
                      ) &&
                      row.CourseScoreList.find(
                        c => c.CourseId === column.property
                      ).LevelDatas.find(
                        l =>
                          l.Id ===
                          row.CourseScoreList.find(
                            c => c.CourseId === column.property
                          ).Level
                      ).DJ
                  }}
                </div>
              </div>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <!-- 导入成绩 -->
    <el-dialog
      :visible.sync="importStatusVisible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="cancelImport"
    >
      <el-collapse v-model="importMsgIndex" @change="openImportMsgIndex">
        <el-collapse-item
          :disabled="item.status === 'success'"
          v-for="(item, index) in importArray"
          :key="index"
        >
          <template slot="title">
            <div style="display: inline-block;color:#303133">
              {{ item.className }}
            </div>
            <el-progress
              style="display: inline-block;width:370px;margin-left: 10px;"
              :stroke-width="3"
              :percentage="item.percentage"
              :status="item.status"
            ></el-progress>
            <el-link
              v-if="item.status"
              style="display: inline-block;margin-left: -25px;"
              :underline="false"
              :type="item.status === 'exception' ? 'danger' : item.status"
              >{{
                item.status === "success"
                  ? "导入成功"
                  : item.status === "warning"
                  ? "点击查看警告"
                  : item.status === "exception"
                  ? "点击查看错误"
                  : ""
              }}
            </el-link>
          </template>
          <el-alert
            :closable="false"
            :title="m.title"
            v-for="(m, i) in item.Msg"
            :key="i"
            :type="item.status === 'exception' ? 'error' : item.status"
          >
            <template slot="title">
              <div>{{ m.title + ":" }}</div>
              <div v-for="(desc, dex) in m.description" :key="dex">
                {{ desc }}
              </div>
            </template>
          </el-alert>
        </el-collapse-item>
      </el-collapse>
      <div v-for="item in importArray" :key="item.className"></div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :loading="!importSuccess"
          type="primary"
          @click="importStatusVisible = false"
          >完成</el-button
        >
      </span>
    </el-dialog>
    <!-- 导出模板 -->
    <exportTemplate
      v-model="downloadTemplateVisible"
      :examinationCourse="
        examination && examination.KSNJXKList ? examination.KSNJXKList : []
      "
      :basicInfo="$attrs.basicInfo"
      :teacherCourse="$attrs.teacherCourse"
      :userId="user.Id"
      :token="this.token"
      :gradesList="grades"
    ></exportTemplate>
    <!-- 导出成绩 -->
    <exportScore
      v-model="exportScoreVisible"
      :examinationCourse="
        examination && examination.KSNJXKList ? examination.KSNJXKList : []
      "
      :basicInfo="$attrs.basicInfo"
      :userId="user.Id"
      :token="this.token"
      :termId="this.termId"
      :examinationId="this.examination ? this.examination.Id : ''"
      :gradesList="grades"
    ></exportScore>
  </div>
</template>

<script>
import { handleDownload } from "@/style/js/Common.js";
import exportTemplate from "./exporttemplate";
import exportScore from "./exportScore";
export default {
  components: {
    exportTemplate,
    exportScore
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      user: {},
      examination: {},
      schoolId: "",
      termId: "",
      termYear: "",
      termType: 0,
      token: "",
      gradeId: "",
      gradeNumber: 1,
      classId: "",
      courseId: "",
      grades: [],
      classDatas: [],
      courseTable: [],
      headerClass: [],
      courseList: [],
      tableData: [],
      courseScoreList: [],
      searchStr: "",
      tableDataHeader: [],
      openMissTest: false,
      tableData1: [],
      studentScore: [],
      loading: false,
      initTableLoading: false,
      importStatusVisible: false,
      importSuccess: false,
      importArray: [],
      importMsgIndex: [],
      downloadTemplateVisible: false,
      exportScoreVisible: false,
      teacherCourse: {}
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    // 查询成绩权限设置还是以当前学期教师所教课表和当前学期班级课表为规则
    let term = localStorage.getItem("curTerm");
    if (term) {
      let term2 = JSON.parse(term);
      this.termId = term2.Id;
      this.termYear = term2.SSXN;
      this.termType = term2.XQLX;
    }
    console.log(
      this.$store.getters.currentExamination,
      "this.$store.getters.currentExamination"
    );
    this.examination = this.$store.getters.currentExamination;
    this.teacherCourse = this.$attrs.teacherCourse;
    this.loading = true;
    this.init();
  },
  methods: {
    async init() {
      console.log(this.$attrs.basicInfo, "this.$attrs.basicInfo");
      let grades = this.$attrs.basicInfo.GradeList.filter(c =>
        this.examination.KSNJXKList.map(item => item.KSNJ).includes(c.Number)
      );
      this.grades = JSON.parse(JSON.stringify(grades || []));
      // 获取教师班主任班级
      await this.getClassTeacher();
      // 筛选所有考试年级的班级
      let classArr = this.grades.reduce((pre, cur) => {
        if (cur.ChidOrgList && cur.ChidOrgList.length > 0) {
          pre = pre.concat(cur.ChidOrgList);
        }
        return pre;
      }, []);
      // 循环请求班级课表
      for (let i = 0; i < classArr.length; i++) {
        let res = await this.getCourseTable(classArr[i].Id);
        classArr[i].courseTable = res;
      }
      classArr.forEach(classInfo => this.handleFilter(classInfo));

      this.grades.forEach(grade => {
        grade.ChidOrgList = classArr.filter(
          classInfo =>
            classInfo.ParentId === grade.Id && classInfo.finalCourse.length > 0
        );
      });

      this.grades = this.grades.filter(grade => grade.ChidOrgList.length > 0);

      if (this.grades.length > 0 && this.grades[0] && this.grades[0].Id) {
        this.gradeId = this.grades[0].Id;
        this.gradeNumber = this.grades[0].Number;
        this.handleGrade();
      } else {
        this.$message.error("未匹配到年级");
        this.loading = false;
      }
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    getCourseTable(ClassId) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/evaluationactivity/GetClassSchedule",
          {
            token: this.token,
            // UserId: this.user.Id,
            ClassId,
            ClassScheduleType: 2,
            SchoolId: this.schoolId,
            TermId: this.termId
          },
          r => {
            if (r.Code === 0) {
              this.courseTable = r.Target?.ClassScheduleList || [];
              resolve(r.Target?.ClassScheduleList || []);
            }
          }
        );
      });
    },
    getClassTeacher() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/accountmanage/GetClassTeacher",
          {
            token: this.token,
            UserId: this.user.Id
          },
          r => {
            if (r.Code === 0) {
              this.headerClass = r.Target;
              this.headerClass = [].concat(
                this.headerClass ? this.headerClass : []
              );
              resolve();
            }
          }
        );
      });
    },
    getStudentScore() {
      this.$api.post(
        "/v1/examination/GetExaminationStudentScore",
        {
          token: this.token,
          SchoolId: this.schoolId,
          ExaminationId: this.examination.Id,
          ClassId: this.classId,
          TermId: this.examination.SSXQ
        },
        r => {
          if (r.Code === 0) {
            this.studentScore = r.Target || [];
            this.initTableData();
          }
        }
      );
    },
    getStudentData() {
      this.initTableLoading = true;
      if (this.gradeId) {
        this.$api.post(
          "v1/accountmanage/GetOrgStudent",
          {
            token: this.token,
            SchoolOrgId: this.gradeId,
            OrgType: 1,
            SourceType: 1
          },
          r => {
            if (r.Code === 0) {
              this.tableData1 = r.Target;
              this.tableData = this.tableData1.filter(
                c => c.ClassId === this.classId
              );
              this.getStudentScore();
              this.initGradeStudentScore();
            }
            this.loading = false;
          }
        );
      } else {
        this.loading = false;
        this.initTableLoading = false;
      }
    },
    handleGrade() {
      let grade = this.grades.find(c => c.Id === this.gradeId);
      if (grade) {
        this.gradeNumber = grade.Number;
        this.classDatas = grade.ChidOrgList;
      }

      this.classId = this.classDatas[0] ? this.classDatas[0].Id : "";
      this.getStudentData();
      this.handleClass();
    },
    handleClass() {
      this.courseList = this.grades
        .find(grade => grade.Id === this.gradeId)
        .ChidOrgList.find(
          classInfo => classInfo.Id === this.classId
        ).finalCourse;
      this.courseId = this.courseList[0].Id;

      this.tableData = this.tableData1.filter(c => c.ClassId === this.classId);
      this.initTableLoading = true;
      this.getStudentScore();
    },
    submit() {
      let convertrule = [];
      let grade = this.$attrs.basicInfo.GradeList.find(
        c => c.Id === this.gradeId
      );
      if (grade) {
        convertrule = this.$attrs.basicInfo.ConvertRuleList.filter(
          c => c.FJZ === grade.Number && c.KSLB === this.examination.KSXZM
        );

        if (this.courseId) {
          convertrule = convertrule.filter(c => c.FJLX === this.courseId);
        }
      }
      let curClass = this.classDatas.find(item => item.Id === this.classId);
      let data = this.tableData.reduce((prev, item) => {
        return prev.concat(
          item.CourseScoreList.map(c => ({
            XSId: item.Id,
            KCH: c.CourseId,
            KCMC: c.CourseName,
            DFFS: c.InputType,
            KCCJ: c.Score,
            KCDJ: c.Level,
            SFQK: c.MissTest ? 1 : 2,
            SSZZJG: item.ClassId,
            SSNJ: item.GradeId,
            NJSZ: curClass ? curClass.GradeNumber : undefined,
            JGSJ:
              (convertrule.find(item => item.FJLX === c.CourseId)
                ? convertrule.find(item => item.FJLX === c.CourseId).JBZ / 100
                : 1) *
              (c.InputType === 1
                ? c.Score
                : c.LevelDatas.find(l => l.Id === c.Level)
                ? c.LevelDatas.find(l => l.Id === c.Level).ZGF
                : 0)
          }))
        );
      }, []);
      data = data.filter(
        c => (c.KCCJ || c.SFQK === 1 || c.KCDJ) && c.KCH === this.courseId
      );
      this.$api.post(
        "/v1/examination/SaveExaminationResult",
        {
          token: this.token,
          UserId: this.user.Id,
          ExaminationId: this.examination.Id,
          XSCJBList: data,
          ClassId: this.classId,
          SchoolId: this.schoolId,
          UserName: this.user.XSM,
          CourseId: this.courseId,
          TermId: this.examination.SSXQ,
          TermYearStr: this.termYear,
          TermType: this.termType,
          ExaminationName: this.examination.KSMC,
          SchCode: this.user.SchoolCode,
          ExamType: this.examination.KSXZM,
          ExamInsertType: 3,
          NJSZ: this.gradeNumber
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
          }
        }
      );
    },
    submitForImport(importData, importItem) {
      return new Promise(resolve => {
        let convertrule = [];
        let grade = this.$attrs.basicInfo.GradeList.find(
          c => c.Id === this.gradeId
        );
        if (grade) {
          convertrule = this.$attrs.basicInfo.ConvertRuleList.filter(
            c => c.FJZ === grade.Number && c.KSLB === this.examination.KSXZM
          );
          if (this.courseId) {
            convertrule = convertrule.filter(c => c.FJLX === this.courseId);
          }
        }
        let data = importData.reduce((prev, item) => {
          return prev.concat(
            item.CourseScoreList.map(c => ({
              XSId: item.Id,
              KCH: c.CourseId,
              KCMC: c.CourseName,
              DFFS: c.InputType,
              KCCJ: c.Score,
              KCDJ: c.Level,
              SFQK: c.MissTest ? 1 : 2,
              SSZZJG: item.ClassId,
              SSNJ: item.GradeId,
              JGSJ:
                (convertrule.find(item => item.FJLX === c.CourseId)
                  ? convertrule.find(item => item.FJLX === c.CourseId).JBZ / 100
                  : 1) *
                (c.InputType === 1
                  ? c.Score
                  : c.LevelDatas.find(l => l.Id === c.Level)
                  ? c.LevelDatas.find(l => l.Id === c.Level).ZGF
                  : 0)
            }))
          );
        }, []);
        // data = data.filter(c => c.KCCJ || c.SFQK === 1 || c.KCDJ);

        data = data.filter(
          c => c.KCCJ === 0 || c.KCCJ || c.SFQK === 1 || c.KCDJ
        );
        this.$api.post(
          "/v1/examination/SaveExaminationResult",
          {
            token: this.token,
            UserId: this.user.Id,
            ExaminationId: this.examination.Id,
            XSCJBList: data,
            ClassId: importItem.classId,
            SchoolId: this.schoolId,
            UserName: this.user.XSM,
            TermId: this.examination.SSXQ,
            TermYearStr: this.termYear,
            TermType: this.termType,
            ExaminationName: this.examination.KSMC,
            SchCode: this.user.SchoolCode,
            ExamType: this.examination.KSXZM,
            ExamInsertType: 2, //1 单个班新增 2:导入 3.修改
            NJSZ: this.gradeNumber
          },
          () => {
            resolve();
          },
          f => {
            if (f.Code !== 0) {
              importItem.status = "exception";
              importItem.Msg.unshift({ title: f.Message, description: [] });
            }
          }
        );
      });
    },
    downloadTemplate() {
      handleDownload(
        "/template/学生成绩导入模版.xlsx",
        "学生成绩导入模版.xlsx"
      );
    },
    importScore({ results }) {
      this.importMsgIndex = [];
      this.importArray = [];
      this.importArray = results.map(c => ({
        className: c.Class.trim(),
        percentage: 0,
        maxProgress: 100,
        status: null,
        Msg: []
      }));
      this.importStatusVisible = true;
      for (let result of results) {
        if (!this.importStatusVisible) {
          return false;
        }
        let importData = [];
        let classInfo = this.classDatas.find(
          c => c.Name === result.Class.trim()
        );
        let importItem = this.importArray.find(
          c => c.className === result.Class.trim()
        );
        let timer = this.processProgress(importItem.percentage);
        if (classInfo) {
          importItem.classId = classInfo.Id;
          for (let item of result.Data) {
            // let SourceStudent = this.tableData1.find(
            //   c => c.XH === item["学号"] + ""
            // );
            let SourceStudents = this.tableData1.filter(
              c => c.Name === item["姓名"] + "" && c.ClassId === classInfo.Id
            );

            if (SourceStudents.length !== 0) {
              // SourceStudents.forEach(student => {
              //   student.CourseScoreList.forEach(course => {
              //     Object.keys(item).forEach(key => {
              //       if (
              //         key !== "EMPTY" &&
              //         key !== "姓名" &&
              //         key !== "学号" &&
              //         key === course.CourseName
              //       )
              //         course.Score = item[key];
              //     });
              //   });
              // });

              if (SourceStudents.length === 1) {
                let student = JSON.parse(JSON.stringify(SourceStudents[0]));
                if (student && student.CourseScoreList) {
                  Object.keys(item).forEach(xk => {
                    if (!xk.includes("EMPTY")) {
                      let score = student.CourseScoreList.find(
                        c => c.CourseName === xk
                      );
                      if (score !== undefined && score !== null) {
                        if (item[xk] === "缺考") {
                          score.MissTest = true;
                        } else {
                          if (score.InputType === 1) {
                            let studentScore = isNaN(item[xk])
                              ? 0
                              : Number(item[xk]);
                            score.Score =
                              studentScore > score.ScoreLimit
                                ? score.ScoreLimit
                                : studentScore;
                          } else {
                            let levelData = score.LevelDatas.find(c => {
                              if (
                                c.DJ.toLowerCase() ===
                                (item[xk] + "").toLowerCase()
                              ) {
                                return true;
                              } else return false;
                            });
                            score.Level = levelData ? levelData.Id : undefined;
                          }
                        }
                      } else {
                        if (!["姓名", "学号"].includes(xk)) {
                          let stuMsg = importItem.Msg.find(
                            c => c.title === item["姓名"]
                          );
                          if (stuMsg) {
                            stuMsg.description.push(
                              "系统中没有找到学科：" + xk
                            );
                          } else {
                            importItem.Msg.push({
                              title: item["姓名"],
                              description: ["系统中没有找到学科：" + xk]
                            });
                          }
                        }
                      }
                    }
                  });
                }
                importData.push(student);
              } else {
                let msgCount = importItem.Msg.filter(
                  c =>
                    c.title === item["姓名"] &&
                    c.description.includes("系统中有相同名字的学生")
                ).length;
                if (msgCount === 0) {
                  importItem.Msg.push({
                    title: item["姓名"],
                    description: ["系统中有相同名字的学生"]
                  });
                }
              }
            } else {
              importItem.Msg.push({
                title: item["姓名"],
                description: ["系统中没有找到该学生"]
              });
            }
          }
          this.submitForImport(importData, importItem).then(() => {
            this.getStudentScore();
            this.importSuccess = true;
            clearInterval(timer);
            importItem.percentage = importItem.maxProgress;
            importItem.status =
              importItem.status !== "exception"
                ? importData.length < result.Data.length
                  ? "warning"
                  : "success"
                : "exception";
          });
        } else {
          clearInterval(timer);
          this.importSuccess = true;
          importItem.percentage = 50;
          importItem.status = "exception";
          importItem.Msg.push({
            title: "未匹配到班级",
            description: []
          });
        }
      }
    },
    processProgress(data) {
      let setPercentage = () => {
        if (data < 100) {
          data += 1;
        }
      };
      return setInterval(() => {
        setPercentage();
      }, 100);
    },
    cancelImport() {
      this.importStatusVisible = false;
    },
    initTableData() {
      this.$nextTick(() => {
        this.initTableLoading = false;
      });

      let courseList = this.courseList.filter(
        item => item.NJId === this.gradeId
      );
      this.courseScoreList = [];
      this.tableData = this.tableData
        .filter(c => c.ClassId === this.classId)
        .map(c => {
          c.CourseScoreList = courseList.reduce((prev, item) => {
            let sut = this.studentScore.find(s => {
              if (c.Name === "理雅懿" && item.Name === "科学与创造") {
                // console.log(s.KCH === item.Id && s.XSId === c.Id, "fsfsdfsfd");
              }
              return s.KCH === item.Id && s.XSId === c.Id;
            });

            if (item.LevelList && item.LevelList.length > 0) {
              item.LevelList.sort((a, b) => a.DJ.localeCompare(b.DJ));
            }

            let level = "";
            if (item.LevelList && item.LevelList.length > 0) {
              if (sut && sut.KCDJ) {
                let res = item.LevelList.find(el => el.Id === sut.KCDJ);

                res ? (level = res.Id) : "";
              }
            }

            let obj = {
              CourseId: item.Id,
              CourseName: item.Name,
              Score: sut ? sut.KCCJ : undefined,
              LevelDatas: item.LevelList || [],
              Level: level,
              ScoreLimit: item.ScoreLimit,
              InputType: item.InputType,
              MissTest: sut ? (sut.SFQK == 1 ? true : false) : false
            };
            prev.push(obj);
            return prev;
          }, []);
          this.courseScoreList = c.CourseScoreList;
          c.CourseScoreList = this.courseScoreList;
          return c;
        });
      this.tableDataHeader = this.courseList
        .filter(item => item.NJId === this.gradeId)
        .map(item => ({
          CourseId: item.Id,
          CourseName: item.Name
        }));
    },
    initGradeStudentScore() {
      let courseList = this.courseList.filter(
        item => item.NJId === this.gradeId
      );
      this.tableData1 = this.tableData1.map(c => {
        c.CourseScoreList = courseList.reduce((prev, item) => {
          let obj = {
            CourseId: item.Id,
            CourseName: item.Name,
            Score: undefined,
            LevelDatas: item.LevelList,
            Level: undefined,
            ScoreLimit: item.ScoreLimit,
            InputType: item.InputType,
            MissTest: false
          };
          prev.push(obj);
          return prev;
        }, []);
        return c;
      });
    },
    openImportMsgIndex(val) {
      this.importMsgIndex = val;
    },
    // 筛选登录人匹配的年级 班级 课程
    handleFilter(classInfo) {
      // 新增人可查询所有年级所有课程 班主任查看班主任班级的所有课程 任课老师查看任课班级任课课程
      // 1.筛选出考试对应的年级 2.循环每个年级下的班级 获取班级课表，筛选考试对应的课程 3.判断老师是否这个班的班主任，是否教这个班级，是否指定录入人，是否显示这个班级
      // 年级课程
      let gradeCourse = this.$attrs.basicInfo.CourseList.find(
        item => item.Grade === classInfo.GradeNumber
      );

      let result = [];
      let course1 = []; //此班全部符合考试的课程
      let course2 = []; //科任课程
      if (gradeCourse && gradeCourse.GradeCourseList && classInfo.courseTable) {
        //筛选此班的全部课程 (班级课表和年级课程匹配 再和考试课程匹配)
        let classCourse = gradeCourse.GradeCourseList.reduce((pre, cur) => {
          classInfo.courseTable.forEach(item => {
            if (cur.Id === item.CourseId && !pre.find(el => el.Id === cur.Id)) {
              pre.push({
                Id: cur.Id,
                NJId: cur.gradeId,
                GradeNumber: classInfo.GradeNumber,
                classId: classInfo.Id,
                Name: cur.Name,
                InputType: cur.InputType,
                ScoreLimit: cur.ScoreLimit,
                LevelList: cur.LevelList
              });
            }
          });
          return pre;
        }, []);
        if (this.examination.KSNJXKList) {
          course1 = classCourse.filter(item =>
            this.examination.KSNJXKList.some(
              it => it.KSNJ === item.GradeNumber && it.KCId === item.Id
            )
          );
        }

        // 筛选科任课程
        let teacherCourse = classInfo.courseTable.reduce((pre, cur) => {
          if (
            cur.TeacherId === this.user.Id &&
            !pre.find(item => item.CourseId === cur.CourseId)
          )
            pre.push(cur);
          return pre;
        }, []);

        teacherCourse = gradeCourse.GradeCourseList.reduce((pre, cur) => {
          teacherCourse.forEach(item => {
            if (
              cur.Id === item.CourseId &&
              !pre.find(el => el.Id === item.CourseId)
            ) {
              pre.push({
                Id: item.CourseId,
                NJId: cur.gradeId,
                GradeNumber: classInfo.GradeNumber,
                classId: classInfo.Id,
                Name: cur.Name,
                InputType: cur.InputType,
                ScoreLimit: cur.ScoreLimit,
                LevelList: cur.LevelList
              });
            }
          });
          return pre;
        }, []);
        if (this.examination.KSNJXKList) {
          course2 = teacherCourse.filter(item =>
            this.examination.KSNJXKList.some(
              it => it.KSNJ === item.GradeNumber && it.KCId === item.Id
            )
          );
        }
      }
      if (
        // 指定录入人
        this.examination.KSLRRYBList.some(
          item => item.CZRId === this.user.Id
        ) ||
        this.examination.CJR === this.user.Id
      ) {
        result = course1;
      } else if (this.headerClass && this.headerClass.includes(classInfo.Id)) {
        //班主任 或 创建人
        result = course1;
      } else if (course2.length > 0) {
        //只是科任
        result = course2;
      }

      if (result.length > 0) {
        result = [{ Id: "", Name: "全部", NJId: "" }].concat(result);
      }
      console.log(result, "result");
      classInfo.finalCourse = result;
    }
  },
  computed: {
    testScore() {
      return data => {
        return JSON.stringify(data);
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.jf-radio {
  .course-item-block {
    display: flex;
    flex-wrap: wrap;
    .course-item {
      margin: 0 10px 10px 0;
      &.cur-course {
        background-color: #14d089;
        border-radius: 5px;
        ::v-deep .el-radio__label {
          color: #fff;
        }
      }
      ::v-deep .el-radio__input {
        opacity: 0;
      }
      ::v-deep .el-radio {
        border: none;
        padding-left: 0 !important;
      }
    }
  }
}
.butt-box {
  margin-bottom: 10px;
}
</style>
