<template>
  <el-dialog
    title="等级绑定"
    :visible.sync="visible"
    width="40%"
    :before-close="submit"
  >
    <el-radio-group v-model="radio2">
      <el-radio v-for="item in option" :key="item.Id" :label="item.Id">{{
        item.DJMC
      }}</el-radio>
    </el-radio-group>
    <span slot="footer" class="dialog-footer">
      <el-button @click="submit">取 消</el-button>
      <el-button type="primary" @click="submit(1)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    option: Array,
    index: Object
  },
  data() {
    return {
      radio2: ""
    };
  },
  methods: {
    submit(type) {
      if (type === 1) {
        if (!this.radio2) return;

        let { Id, Token: token, SSZZId: SSXX } = JSON.parse(
          localStorage.getItem("userinfo")
        );
        //这个接口现在是等级绑定 但是后期可能也会走数据来源 所以也可能传指标相关
        let Datas = [
          {
            SSXX,
            GLId: this.radio2, //等级id或数据来源中活动中的一级指标id
            GLLX: 1, //等级或指标
            HDId: "", //数据来源的活动id
            ZBId: this.index.id //指标id
          }
        ];
        this.$api.post(
          "v1/schoolindex/SetXXZBBB",
          {
            Datas,
            IndexId: this.index.id,
            GLLX: 1, //
            UserId: Id,
            token
          },
          res => {
            if (res.Code === 0) this.$emit("refresh");
          }
        );
      } else this.$emit("update:visible", false);
    }
  },
  watch: {
    visible(val) {
      if (val) this.radio2 = this.index.gldj || "";
    }
  }
};
</script>

<style></style>
