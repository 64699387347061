<template>
  <div class="cont-whole-box" style="text-align: center;">
    <el-form
      :model="tzgginfo"
      label-width="120px"
      style="width: 500px; display: inline-block;"
      :rules="rules"
      ref="sbmitfrom"
    >
      <el-form-item label="标题：" prop="BT">
        <el-input
          v-model="tzgginfo.BT"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="内容：" prop="NR">
        <el-input
          type="textarea"
          placeholder="请输入公告内容"
          v-model="tzgginfo.NR"
          maxlength="2000"
          :autosize="{ minRows: 9 }"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="附件：" style="width: 100%;">
        <el-upload
          class="upload-demo"
          list-type="picture-card"
          accept="image/jpeg, image/gif, image/png"
          :action="uploadURL"
          :on-error="upLoadError"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :limit="6"
          :on-exceed="handleExceed"
          :auto-upload="true"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="发送对象：" prop="JSDXLX">
        <el-row>
          <el-col>
            <el-select
              style="width: 100%;"
              v-model="tzgginfo.JSDXLX"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="tzgginfo.JSDXLX == 1">
          <el-col>
            <el-checkbox
              v-model="njall"
              label="1"
              key="1"
              @change="handleNJCheckAllChange"
              >全选</el-checkbox
            >
            <el-checkbox
              v-for="nj in njlist"
              v-model="xznjlist"
              :label="nj.Id"
              :key="nj.Id"
              @change="handleNJCheckedChange"
              >{{ nj.Name }}</el-checkbox
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="FSSJ">
        <el-row>
          <el-col>
            <el-radio
              v-model="tzgginfo.FSLX"
              label="1"
              @change="handleFSLXChange"
              >立即发送</el-radio
            >
            <el-radio
              v-model="tzgginfo.FSLX"
              label="2"
              @change="handleFSLXChange"
              >定时发送</el-radio
            >
            <el-date-picker
              v-if="tzgginfo.FSLX == 2"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="选择日期"
              v-model="tzgginfo.FSSJ"
            ></el-date-picker>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitshowdata" :disabled="isDisabled"
          >保存</el-button
        >
        <el-button @click="canceladd">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<style></style>
<script>
export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    this.tzgginfo.Id = this.$route.params.ggid;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      if (user.zp !== null && user.zp !== undefined && user.zp !== "") {
        this.CJRTX = user.zp;
      }
      this.CJRXM = user.XSM;
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
      this.fileServerApiPath = user.FileServerApiPath;
      this.webFilePath = user.WebFilePath;
    }
  },
  computed: {
    // 文件的上传路径
    uploadURL: function() {
      return "/file/file/fileservice/UploadFile?token=" + this.token;
    }
  },
  mounted: function() {
    this.loadjyzdata();
    this.loadnjdata();
    this.loaddata();
    if (this.tzgginfo.Id !== undefined && this.tzgginfo.Id.length === 36) {
      this.xsmc = "编辑";
    }
    this.tzgginfo.CJSJ = this.getdate();
  },
  methods: {
    handleRemove(fileList) {
      this.filearr = [];
      for (var i = 0; i < fileList.length; i++) {
        var response = fileList[i].response;
        this.filearr.push({
          FJMC: response.Target.FileName,
          FJLJ: response.Target.FilePath
        });
      }
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleAvatarSuccess(response) {
      if (response.Code === -3) {
        this.$message.warning("附件上传失败");
      } else {
        this.filearr.push({
          FJMC: response.Target.FileName,
          FJLJ: response.Target.FilePath
        });
      }
    },
    // 文件上传失败时的钩子
    upLoadError() {
      this.$message.info("附件上传失败");
    },
    handleExceed() {
      this.$message.info("最多只能上传6张图片");
    },
    // 取消
    canceladd: function() {
      this.$router.push({ name: "mylist" });
    },
    handleNJCheckedChange: function() {
      if (this.xznjlist.length > 0) {
        this.njall = false;
      }
    },
    handleNJCheckAllChange: function(val) {
      if (val) {
        this.xznjlist = [];
      }
    },
    handleFSLXChange: function() {
      if (this.tzgginfo.FSLX === 1) {
        this.tzgginfo.FSSJ = "";
      }
    },
    handleJYZCheckAllChange: function(val) {
      if (val) {
        this.xzjyzlist = [];
      }
    },
    handleJYZCheckedChange: function() {
      if (this.xzjyzlist.length > 0) {
        this.jyzall = false;
      }
    },
    submitshowdata: function() {
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          var jsdx = "";
          var jsdxarr = [];
          if (this.tzgginfo.JSDXLX === "1") {
            if (this.njall) {
              jsdxarr.push({ JSDXLX: 1, DXId: "" });
            } else {
              if (this.xznjlist.length <= 0) {
                this.$message({
                  type: "info",
                  message: "请勾选发送对象!"
                });
                return;
              }
              for (var i = 0; i < this.xznjlist.length; i++) {
                jsdxarr.push({ JSDXLX: 3, DXId: this.xznjlist[i] });
              }
            }
          } else if (this.tzgginfo.JSDXLX === "2") {
            jsdxarr.push({ JSDXLX: 1, DXId: "" });
          } else if (this.tzgginfo.JSDXLX === "4") {
            if (this.njall) {
              jsdxarr.push({ JSDXLX: 4, DXId: this.bjid });
            }
          }
          jsdx = JSON.stringify(jsdxarr);
          var fjstr = "";
          if (
            this.filearr !== undefined &&
            this.filearr !== null &&
            this.filearr.length > 0
          ) {
            fjstr = JSON.stringify(this.filearr);
          }
          this.isDisabled = true;
          this.$api.post(
            "v1/notice/Add",
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              CJR: this.cjr,
              CJRXM: this.CJRXM,
              CJRTX: this.CJRTX,
              Title: this.tzgginfo.BT,
              Content: this.tzgginfo.NR,
              FSLX: this.tzgginfo.FSLX,
              FSSJ: this.tzgginfo.FSSJ,
              JSDXLX: this.tzgginfo.JSDXLX,
              JSDX: jsdx,
              FJStr: fjstr,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!"
                });
                this.isDisabled = false;
                this.$router.push({ name: "mylist" });
              } else {
                this.$message({
                  type: "info",
                  message: "保存失败!"
                });
                this.isDisabled = false;
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    loaddata: function() {
      if (this.tzgginfo.Id !== undefined && this.tzgginfo.Id !== "") {
        this.$api.post(
          "v1/notice/GetSingle",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            Id: this.tzgginfo.Id,
            YHId: this.cjr,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.tzgginfo = r.Target;
              this.tzgginfo.JSDXLX = "" + this.tzgginfo.JSDXLX + "";
              this.tzgginfo.FSLX = "" + this.tzgginfo.FSLX + "";
              var fjlist = this.tzgginfo.FJList;
              if (fjlist !== null && fjlist.length > 0) {
                this.fileList = [];
                this.filearr = [];
                for (let i = 0; i < fjlist.length; i++) {
                  this.fileList.push({
                    name: fjlist[i].FJMC,
                    url: fjlist[i].FJLJ
                  });
                  this.filearr.push({
                    FJMC: fjlist[i].FJMC,
                    FJLJ: fjlist[i].FJLJ
                  });
                }
              }
              var jsdxlist = r.Target.AttendList;
              for (let i = 0; i < jsdxlist.length; i++) {
                if (this.tzgginfo.JSDXLX == 1) {
                  if (jsdxlist[i].JSDXLX == 1) {
                    this.njall = true;
                    this.xznjlist = [];
                    break;
                  } else {
                    this.xznjlist.push(jsdxlist[i].DXId);
                  }
                } else if (this.tzgginfo.JSDXLX == 2) {
                  if (jsdxlist[i].JSDXLX == 1) {
                    this.jyzall = true;
                    this.xzjyzlist = [];
                    break;
                  } else {
                    this.xzjyzlist.push(jsdxlist[i].DXId);
                  }
                }
              }
            }
          }
        );
      }
    },
    loadjyzdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolTeacherGroup",
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.jyzlist = [];
            this.jyzlist = r.Target;
          }
        }
      );
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolOrg",
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.njlist = [];
            var list = r.Target;
            for (var i = 0; i < list.length; i++) {
              if (list[i].Hierarchy === 1) {
                this.njlist.push(list[i]);
              }
            }
          }
        }
      );
    },
    dateformat: function(mydate, onlydate) {
      // 获取当前年
      var year = mydate.getFullYear();
      // 获取当前月
      var month = mydate.getMonth() + 1;
      // 获取当前日
      var date = mydate.getDate();
      if (onlydate) {
        return year + "-" + this.conver(month) + "-" + this.conver(date);
      } else {
        var h = mydate.getHours(); // 获取当前小时数(0-23)
        var m = mydate.getMinutes(); // 获取当前分钟数(0-59)
        var s = mydate.getSeconds();
        return (
          year +
          "-" +
          this.conver(month) +
          "-" +
          this.conver(date) +
          " " +
          this.conver(h) +
          ":" +
          this.conver(m) +
          ":" +
          this.conver(s)
        );
      }
    },
    getdate: function() {
      var myDate = new Date();
      // 获取当前年
      var year = myDate.getFullYear();
      // 获取当前月
      var month = myDate.getMonth() + 1;
      // 获取当前日
      var date = myDate.getDate();
      var h = myDate.getHours(); // 获取当前小时数(0-23)
      var m = myDate.getMinutes(); // 获取当前分钟数(0-59)
      var s = myDate.getSeconds();
      // 获取当前时间
      var now =
        year +
        "-" +
        this.conver(month) +
        "-" +
        this.conver(date) +
        " " +
        this.conver(h) +
        ":" +
        this.conver(m) +
        ":" +
        this.conver(s);
      return now;
    },
    conver: function(s) {
      return s < 10 ? "0" + s : s;
    }
  },
  data() {
    var validatefssj = (rule, value, callback) => {
      if (this.tzgginfo.FSLX === 2 && this.tzgginfo.FSSJ === "") {
        callback(new Error("请选择发送时间"));
      } else {
        callback();
      }
    };
    return {
      isDisabled: false,
      webFilePath: "",
      fileServerApiPath: "",
      ssxx: "",
      ssxq: "",
      token: "",
      xsmc: "新增",
      fileList: [],
      njall: false,
      jyzall: false,
      CJRXM: "",
      CJRTX: "",
      bjid: "",
      fjarr: [],
      njlist: [],
      jyzlist: [],
      xznjlist: [],
      xzjyzlist: [],
      cjr: "",
      tzgginfo: {
        Id: "",
        CJR: "",
        CJSJ: "",
        BT: "",
        NR: "",
        FSSJ: "",
        FSLX: "1",
        JSDXLX: ""
      },
      options: [
        {
          value: "1",
          label: "学生"
        },
        {
          value: "2",
          label: "全体教师"
        },
        {
          value: "3",
          label: "全体师生"
        }
      ],
      rules: {
        FSSJ: [
          {
            validator: validatefssj,
            message: "请选择发送时间",
            trigger: "change"
          }
        ],
        JSDXLX: [
          { required: true, message: "请选择发送对象", trigger: "change" }
        ],
        BT: [
          { required: true, message: "请输入标题", trigger: "change" },
          { min: 1, max: 50, message: "长度在1到50个字符", trigger: "change" }
        ],
        NR: [
          { required: true, message: "请输入内容", trigger: "change" },
          {
            min: 1,
            max: 2000,
            message: "长度在1到2000个字符",
            trigger: "change"
          }
        ]
      }
    };
  }
};
</script>
