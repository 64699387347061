<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="tab-heng-box margno-t">
        <!--列表-->
        <div class="table-er-box">
          <el-button plain icon="el-icon-edit" @click="addopen"
            >新增
          </el-button>
        </div>
        <el-table
          :data="tableData"
          class="jf-table"
          border
          tooltip-effect="dark"
          style="width: 100%;"
        >
          <el-table-column
            prop="KCMC"
            label="课程名称"
            width="180"
          ></el-table-column>
          <el-table-column prop="XD" label="学段" width="180">
            <template slot-scope="scope">
              <p v-if="scope.row.XD == '1'">小学段</p>
              <p v-else-if="scope.row.XD == '2'">中学段</p>
            </template>
          </el-table-column>
          <el-table-column prop="LRFS" label="录入方式">
            <template slot-scope="scope">
              <p v-if="scope.row.LRFS == '1'">分数</p>
              <p v-else-if="scope.row.LRFS == '2'">等级</p>
            </template>
          </el-table-column>
          <el-table-column prop="ZSFS" label="展示方式">
            <template slot-scope="scope">
              <p v-if="scope.row.ZSFS == '1'">分数</p>
              <p v-else-if="scope.row.ZSFS == '2'">等级</p>
            </template>
          </el-table-column>
          <!--<el-table-column prop="GLDJ"
                                                           label="关联等级">
                    </el-table-column>-->
          <el-table-column prop="XKZF" label="学科总分"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="editRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="rowcount"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!--弹出框-->
    <el-dialog
      :title="addtitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="QYKCInfo" :rules="rules" ref="sbmitfrom">
        <el-form-item
          label="课程名称"
          :label-width="formLabelWidth"
          prop="KCMC"
        >
          <el-input
            v-model="QYKCInfo.KCMC"
            autocomplete="on"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item label="学段" :label-width="formLabelWidth" prop="XD">
          <el-select
            v-model="QYKCInfo.XD"
            placeholder="请选择学段"
            style="width: 100%;"
          >
            <el-option label="小学段" :value="1"></el-option>
            <el-option label="中学段" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="录入方式"
          :label-width="formLabelWidth"
          prop="LRFS"
        >
          <el-radio-group v-model="QYKCInfo.LRFS">
            <el-radio :label="1">分数</el-radio>
            <el-radio :label="2">等级</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="展示方式"
          :label-width="formLabelWidth"
          prop="ZSFS"
        >
          <el-radio-group v-model="QYKCInfo.ZSFS">
            <el-radio :label="1">分数</el-radio>
            <el-radio :label="2">等级</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--<el-form-item label="关联等级" :label-width="formLabelWidth">
                            <el-select v-model="QYKCInfo.GLDJ" placeholder="请选择关联等级" style="width:100%">
                                <el-option v-for="garde in gradelist" :label="garde.djmc" :value="garde.id"></el-option>
                            </el-select>
                </el-form-item>-->
        <el-form-item
          label="学科总分"
          :label-width="formLabelWidth"
          prop="XKZF"
        >
          <el-input
            v-model="QYKCInfo.XKZF"
            autocomplete="off"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitshowdata">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isInteger } from '@/style/js/Validator.js'
export default {
  name: 'QykcList',
  data() {
    return {
      token: this.$store.state.token,
      // loading:true,
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      gradelist: [],
      QYKCInfo: {
        Id: '',
        KCMC: '',
        CJR: '',
        CJSJ: '2019-10-09 08:15:35',
        SFSC: 0,
        XD: 1,
        SSQY: '',
        LRFS: 0,
        ZSFS: 0,
        GLDJ: '',
        XKZF: 0,
        SYZT: 0
      },

      loadstate: true,
      props: {
        label: 'name',
        children: 'subnodeList'
      },
      addtitle: '新增',
      rules: {
        KCMC: [
          {
            required: true,
            message: '请输入课程名称',
            trigger: 'blur'
          }
        ],
        XD: [
          {
            required: true,
            message: '请选择学段',
            trigger: 'blur'
          }
        ],
        LRFS: [
          {
            required: true,
            message: '请选择录入方式',
            trigger: 'blur'
          },
          {
            validator: isInteger,
            message: '请选择录入方式',
            trigger: 'change'
          }
        ],
        ZSFS: [
          { required: true, message: '请选择展示方式', trigger: 'change' },
          {
            validator: isInteger,
            message: '请选择展示方式',
            trigger: 'change'
          }
        ],
        XKZF: [
          { required: true, message: '请输入总分', trigger: 'change' },
          {
            validator: isInteger,
            message: '总分为大于0的整数',
            trigger: 'change'
          }
        ]
      },
      userName: '',
      userId: ''
    }
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.QYKCInfo.CJR = userinfo.Id
    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    this.QYKCInfo.SSQY = userinfo.SSZZId
    var pagerInfo = { pageIndex: 0, pageSize: this.pagesize }
    this.$api.post(
      'v1/areacourse/GetAreaCourseList',
      {
        AreaId: this.QYKCInfo.SSQY,
        PagerInfo: pagerInfo,
        token: this.token
      },
      r => {
        if (r.Code === 0) {
          this.tableData = r.Target

          this.rowcount = r.PagerInfo.RowCount
        }
      }
    )
  },
  methods: {
    // 翻页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);

      var pagerInfo = { pageIndex: 0, pageSize: val }

      this.$api.post(
        'v1/areacourse/GetQYKCList',
        { AreaId: this.QYKCInfo.SSQY, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 翻页查询
    handleCurrentChange(val) {
      // console.log(`当前页1: ${val}`);
      var pagerInfo = { pageIndex: val, pageSize: this.pagesize }
      this.$api.post(
        'v1/areacourse/GetQYKCList',
        { AreaId: this.QYKCInfo.SSQY, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 刷新列表
    reflist: function() {
      var pagerInfo = { pageIndex: 0, pageSize: this.pagesize }

      this.$api.post(
        'v1/areacourse/GetAreaCourseList',
        { AreaId: this.QYKCInfo.SSQY, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target

            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 新增
    addopen: function() {
      if (this.QYKCInfo.SSQY === '') {
        this.$message({
          type: 'info',
          message: '区域账号异常！'
        })
        return
      }
      this.addtitle = '新增'
      var areaId = this.QYKCInfo.SSQY
      var cjr = this.QYKCInfo.CJR
      this.dialogFormVisible = true
      this.QYKCInfo = {
        Id: '',
        KCMC: '',
        CJR: cjr,
        CJSJ: '2019-10-09 08:15:35',
        SFSC: 0,
        XD: 1,
        SSQY: areaId,
        LRFS: 0,
        ZSFS: 0,
        GLDJ: '',
        XKZF: 0,
        SYZT: 0
      }
    },
    // 修改
    editRow: function(index, row) {
      this.QYKCInfo = row

      this.dialogFormVisible = true

      this.addtitle = '修改'
    },
    // 删除
    deleteRow: function(index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/areacourse/DeleteAreaCourse',
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.reflist()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    // 保存
    submitshowdata: function(event) {
      event.preventDefault()

      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.$api.post(
            'v1/areacourse/AddAreaCourse',
            {
              QYKCInfo: this.QYKCInfo,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.dialogFormVisible = false

                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.reflist()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style>
.cell {
  text-align: left;
}
</style>
