<template>
  <div class="using-help">
    <!-- 顶部操作栏 -->
    <div class="operate-btn">
      <el-button icon="el-icon-edit" plain @click="showForm(0)">
        新增
      </el-button>
    </div>

    <!-- 表格 -->
    <el-table
      height="600"
      :data="dataTable"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column prop="XSXH" label="序号"> </el-table-column>
      <el-table-column prop="BT" label="标题"> </el-table-column>
      <el-table-column label="类别">
        <template slot-scope="scope">
          <span v-if="scope.row.LB === 1">图文</span>
          <span v-else-if="scope.row.LB === 2">音频</span>
          <span v-else>视频</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="showForm(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" plain size="mini" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 页码 -->
    <div class="el-page">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="pagerInfo.PageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagerInfo.PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagerInfo.RowCount"
      ></el-pagination>
    </div>
    <!-- 表单 -->
    <el-dialog
      :title="formDialog.title"
      :visible.sync="formDialog.visible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form
        class="form"
        label-width="120px"
        ref="formDialog.form"
        :model="formDialog.form"
      >
        <el-form-item label="标题" prop="BT">
          <el-input
            v-model="formDialog.form.BT"
            @input="change($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="显示序号">
          <el-input-number
            v-model="formDialog.form.XSXH"
            :max="100"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="类别">
          <el-radio-group v-model="formDialog.form.LB" @change="switchType">
            <el-radio :label="1">图文</el-radio>
            <el-radio :label="2">语音</el-radio>
            <el-radio :label="3">视频</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="描述" v-show="visibleLb">
          <!-- <mavon-editor v-model="formDialog.form.FWB" defaultOpen="edit"/> -->
          <quill-editor
            class="quilleditor"
            v-model="formDialog.form.FWB"
            ref="myQuillEditor"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange($event)"
          >
          </quill-editor>
          <!-- 图片上传组件辅助-->
          <el-upload
            v-show="false"
            class="editor-avatar-uploader"
            :action="uploadURL"
            name="file"
            :show-file-list="false"
            list-type="picture"
            :multiple="false"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :before-upload="beforeUpload"
          >
          </el-upload>
        </el-form-item>
        <el-form-item label="语音" v-show="visibleYY">
          <el-upload
            class="upload-demo"
            ref="upload"
            :action="uploadURL"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="true"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            :on-success="onSuccess"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
            <div slot="tip" class="el-upload__tip">
              上传音频文件，且不超过10MB
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频" v-show="visibleSP">
          <el-upload
            class="upload-demo"
            ref="upload"
            :action="uploadURL"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="true"
            :before-upload="videoBeforeUpload"
            :on-exceed="handleExceed"
            :on-success="onSuccess"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
            <div slot="tip" class="el-upload__tip">
              上传视频文件，且不超过100MB
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="form-btn">
        <el-button @click="close(false)">取消</el-button>
        <el-button type="primary" @click="submit(1)">确定</el-button>
      </div>
    </el-dialog>
    <div class="progress-mask" v-show="showProgress">
      <div class="progress-block">
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="progressPercent"
        ></el-progress>
        <div style="textAlign:center;fontSize:18px">
          {{ progressMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg' //文件压缩
// 工具栏配置
import { quillEditor, Quill } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//import ImageResize from 'quill-image-resize-module' //调节图片大小
//Quill.register('modules/imageResize', ImageResize)

const titleConfig = {
  'ql-bold': '加粗',
  'ql-color': '颜色',
  'ql-font': '字体',
  'ql-code': '插入代码',
  'ql-italic': '斜体',
  'ql-link': '添加链接',
  'ql-background': '颜色',
  'ql-size': '字体大小',
  'ql-strike': '删除线',
  'ql-script': '上标/下标',
  'ql-underline': '下划线',
  'ql-blockquote': '引用',
  'ql-header': '标题',
  'ql-indent': '缩进',
  'ql-list': '列表',
  'ql-align': '文本对齐',
  'ql-direction': '文本方向',
  'ql-code-block': '代码块',
  'ql-formula': '公式',
  'ql-image': '上传图片',
  'ql-video': '插入视频',
  'ql-clean': '清除字体样式'
}
export function addQuillTitle() {
  const oToolBar = document.querySelector('.ql-toolbar')
  const aButton = oToolBar.querySelectorAll('button')
  const aSelect = oToolBar.querySelectorAll('select')
  aButton.forEach(function(item) {
    if (item.className === 'ql-script') {
      item.value === 'sub' ? (item.title = '下标') : (item.title = '上标')
    } else if (item.className === 'ql-indent') {
      item.value === '+1'
        ? (item.title = '向右缩进')
        : (item.title = '向左缩进')
    } else {
      item.title = titleConfig[item.classList[0]]
    }
  })
  aSelect.forEach(function(item) {
    item.parentNode.title = titleConfig[item.classList[0]]
  })
}

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
  ['blockquote', 'code-block'], // 引用  代码块
  [{ header: 1 }, { header: 2 }, { header: 3 }, { header: 4 }, { header: 5 }], // 1、2 级标题
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
  [{ script: 'sub' }, { script: 'super' }], // 上标/下标
  [{ indent: '-1' }, { indent: '+1' }], // 缩进
  [{ direction: 'rtl' }], // 文本方向
  [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  [{ font: [] }], // 字体种类
  [{ align: [] }], // 对齐方式
  ['clean'], // 清除文本格式
  ['link', 'image'] // 链接、图片
]

export default {
  props: {
    /* 编辑器的内容 */
    contentvalue: {
      type: String
    },
    maxwords: {
      //type: Number,
      default: 400
    }
  },
  name: 'UsingHelp',
  data() {
    return {
      pagerInfo: {
        PageIndex: 0,
        PageSize: 0,
        RowCount: 0
      },
      token: '',
      Id: '',
      schoolId: '',
      ssxq: '',
      dataTable: [],
      visibleLb: true,
      visibleYY: false,
      visibleSP: false,
      // 表单
      formDialog: {
        title: '新增',
        visible: false,
        form: {
          Id: '',
          XSXH: '',
          BT: '',
          FWB: '',
          LB: 1
        }
      },
      fileList: [],
      fileLists: [],
      content: this.contentvalue,
      quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      editorOption: {
        theme: 'snow', // or 'bubble'
        placeholder: '请输入内容',
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function(value) {
                if (value) {
                  // 触发input框选择图片文件
                  document
                    .querySelector('.editor-avatar-uploader input')
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
              link: function(value) {
                if (value) {
                  var href = prompt('请输入url')
                  this.quill.format('link', href)
                } else {
                  this.quill.format('link', false)
                }
              },
              imageResize: {
                // 图片缩放比例
                displayStyles: {
                  backgroundColor: 'black',
                  border: 'none',
                  color: 'white'
                },
                modules: ['Resize', 'DisplaySize', 'Toolbar'] // Resize 允许缩放， DisplaySize 缩放时显示像素 Toolbar 显示工具栏
              }
            }
          }
        }
      },
      showProgress: false,
      progressMessage: '',
      progressPercent: 0
    }
  },
  components: {
    quillEditor
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      let userInfo = localStorage.getItem('userinfo')
      let port = ''
      if (userInfo) {
        userInfo = JSON.parse(userInfo)
        if (
          userInfo.FileServerApiPath &&
          userInfo.FileServerApiPath.split(':') &&
          userInfo.FileServerApiPath.split(':')[2]
        ) {
          port = userInfo.FileServerApiPath.split(':')[2]
          port = port.replace(/[^0-9]/g, '')
        }
      }
      console.log(port, 'port')
      return (
        (process.env.NODE_ENV === 'production' ? port : '') +
        '/file/file/fileservice/uploadfile?key=123&attendid=123&attachtype=3&token=' +
        this.token
      )
    }
  },
  mounted: function() {
    this.token = this.$store.state.token
    this.user = JSON.parse(localStorage.getItem('userinfo'))
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId
      }
    }
    this.getData()
  },
  methods: {
    change(e) {
      //解决input输入框无法输入
      this.$forceUpdate()
    },
    close() {
      this.formDialog.visible = false
    },
    getData() {
      this.tableDataIsLoading = true
      let {
        pageIndex: PageIndex,
        pageSize: PageSize,
        totalCount: RowCount
      } = this.pagerInfo

      this.$api.post(
        '/v1/EvaluationReport/GetSybzgl',
        {
          token: this.token,
          PagerInfo: {
            PageIndex,
            PageSize,
            RowCount
          }
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target
            this.pagerInfo.RowCount = r.PagerInfo.RowCount
            this.tableDataIsLoading = false
          }
        },
        err => {
          this.tableDataIsLoading = false
        }
      )
    },
    sizeChange(val) {
      this.page.PageSize = val
    },
    currentChange(val) {
      this.page.PageIndex = val
    },
    showForm(type) {
      if (type === 0) {
        this.formDialog.title = '新增'
        this.formDialog.form = {}
        this.Id = ''
        this.$set(this.formDialog.form, 'LB', 1)
        this.fileList = []
        this.fileLists = []
      } else {
        Object.assign(this.formDialog.form, type)
        this.formDialog.title = '编辑'
        this.Id = this.formDialog.form.Id
        this.fileList = []
        if (
          (this.formDialog.form.LB === 2 || this.formDialog.form.LB === 3) &&
          this.formDialog.form.FWB !== ''
        ) {
          this.fileList = JSON.parse(this.formDialog.form.FWB)
          this.fileLists = JSON.parse(this.formDialog.form.FWB)
          this.formDialog.form.FWB = ''
        }
      }
      this.switchType(this.formDialog.form.LB)
      this.formDialog.visible = true
    },
    switchType(type) {
      if (type === 1) {
        this.visibleLb = true
        this.visibleYY = false
        this.visibleSP = false
      } else if (type === 2) {
        this.visibleYY = true
        this.visibleLb = false
        this.visibleSP = false
      } else if (type === 3) {
        this.visibleYY = false
        this.visibleLb = false
        this.visibleSP = true
      }
    },
    submit(type) {
      if (
        this.formDialog.form.BT === '' ||
        this.formDialog.form.BT === null ||
        this.formDialog.form.BT === undefined
      ) {
        return this.$message({
          type: 'error',
          message: '标题为必填项',
          showClose: true
        })
      }
      if (type === 1) {
        this.$api.post(
          this.Id !== ''
            ? 'v1/EvaluationReport/UpdateSybzgl'
            : '/v1/EvaluationReport/AddSybzgl',
          {
            Id: this.Id,
            XSXH: this.formDialog.form.XSXH,
            BT: this.formDialog.form.BT,
            FWB: this.formDialog.form.LB === 1 ? this.formDialog.form.FWB : '',
            LB: this.formDialog.form.LB,
            FileListReuqest:
              this.formDialog.form.LB !== 1 ? this.fileLists : [],
            SSXX: this.schoolId,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: 'success',
                message: '操作成功',
                showClose: true
              })
            }
            this.pagerInfo.PageIndex = 1
            this.formDialog.visible = false
            this.getData()
          }
        )
      } else {
        this.formDialog.visible = false
      }
    },
    del(data) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.post(
            '/v1/EvaluationReport/DeleteSybzgl',
            {
              token: this.token,
              Id: data.Id
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                  showClose: true
                })
              }
              this.pagerInfo.pageIndex = 1
              this.getData()
            }
          )
        })
        .catch(() => {})
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择1个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    handleRemove(file, fileList) {
      var t = this.fileLists.findIndex(
        m => m.name === file.name && m.url === file.url
      )
      this.fileLists.splice(t, 1)
      //return this.$confirm(`确定移除 ${ file.name }？`);
    },
    onSuccess(file) {
      if (file.Code === 0) {
        var obj = {}
        obj.name = file.Target.FileName
        if (
          file.Target &&
          file.Target.FilePath &&
          !file.Target.FilePath.includes('http')
        ) {
          file.Target.FilePath = 'http://' + file.Target.FilePath
        }
        obj.url = file.Target.FilePath
        this.fileLists.push(obj)
      }
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
      this.$emit('input', this.formDialog.form.FWB)
    },
    // 富文本图片上传前
    async beforeUpload(file) {
      // 显示loading动画
      this.quillUpdateImg = true
    },
    // 富文本图片上传前
    async videoBeforeUpload(file) {
      this.showProgress = true
      let res = await this.resizeVideo(file)
      // 显示loading动画
      this.quillUpdateImg = true
      this.showProgress = false
      return res
    },
    uploadSuccess(res) {
      console.log(res, 'ressss')
      const quill = this.$refs.myQuillEditor.quill
      if (res.Code === 0) {
        const length = quill.getSelection().index
        // 插入图片  res.url为服务器返回的图片地址
        if (
          res.Target &&
          res.Target.FilePath &&
          !res.Target.FilePath.includes('http')
        ) {
          res.Target.FilePath = 'http://' + res.Target.FilePath
        }
        quill.insertEmbed(length, 'image', res.Target.FilePath)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else if (res.Code === -1) {
        this.$message({
          type: 'error',
          message: '登录超时请重新登录'
        })
      } else {
        this.$message({
          type: 'error',
          message: '图片插入失败'
        })
      }
      // loading动画消失
      this.quillUpdateImg = false
    },
    // 富文本图片上传失败
    uploadError() {
      // loading动画消失
      this.quillUpdateImg = false
      this.$message.error('图片插入失败')
    },
    // 压缩文件
    resizeVideo(file) {
      this.progressPercent = 0
      let _this = this

      return new Promise(function(resolve, reject) {
        const ffmpeg = createFFmpeg({
          corePath: 'ffmpeg-core.js',
          log: false,
          progress: ({ ratio }) => {
            console.log(ratio, 'ratio')
            _this.progressPercent = Math.floor(ratio * 100)
          }
        })
        //开始压缩
        const transcode = async file => {
          const { name } = file
          await ffmpeg.load()
          _this.progressMessage = '开始压缩'
          ffmpeg.FS('writeFile', name, await fetchFile(file))

          await ffmpeg.run('-i', name, '-b', '700000', 'output.mp4')
          const data = ffmpeg.FS('readFile', 'output.mp4')
          _this.progressMessage = '压缩完成'
          //把压缩后的视频进行回显
          let files = URL.createObjectURL(
            new Blob([data.buffer], { type: 'video/mp4' })
          )
          resolve(files)
        }
        transcode(file)
      })
    }
  },
  watch: {
    contentvalue(val) {
      this.content = val
    },
    content() {
      // 内容改变事件
      this.$emit('input', this.content)
    }
  }
}
</script>

<style lang="scss" scoped>
.editcorstyle {
  width: 100%;
  height: 100%;
  line-height: normal !important;
  min-height: 200px;
}
.using-help {
  .operate-btn {
    padding: 10px;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
  }
  .el-table {
    margin-top: 10px;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
  }
  .el-page {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
  }
  .form-btn {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
  }
  .quilleditor {
    ::v-deep .ql-editor {
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
  .progress-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(163, 163, 163, 0.5);
    z-index: 9999;
    .progress-block {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 50px;
      width: 50%;
    }
  }
}
</style>
