<template>
  <div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
    <div :class="'template-wrap-' + this.exportType">
      <div :class="'basetitle' + this.exportType">
        {{ info.BT }}
      </div>
      <div :class="'totalback' + this.exportType">
        <div :class="'detailinfo' + this.exportType">
          <div :class="'xsdiv' + this.exportType">
            <img :src="xsinfo.ZP" :class="'studenticon' + this.exportType" />
            <div :class="'xsinfodiv' + this.exportType">
              <div :class="'xsinfotitle' + this.exportType">
                {{ xsinfo.XM }}
              </div>
              <br />
              <div :class="'xsinfoclass' + this.exportType">
                {{ xsinfo.BJMC }}
              </div>
            </div>
          </div>
          <div :class="'totalscore' + this.exportType">
            <div :class="'scoredesc' + this.exportType">
              得星数
            </div>
            <br />
            <div :class="'scoretext' + this.exportType">
              {{ xsinfo.ZFS }}
            </div>
          </div>
          <div
            :class="'performancedetaildiv' + this.exportType"
            v-if="activitylist.length > 0"
          >
            <el-row :gutter="10">
              <el-col
                :span="3"
                v-if="
                  activitylist[0].HDTP != null && activitylist[0].HDTP != ''
                "
              >
                <el-image
                  :class="'pjricon' + exportType"
                  :src="activitylist[0].HDTP"
                >
                </el-image>
              </el-col>
              <el-col :span="12" :class="'activityname' + this.exportType">
                {{ activitylist[0].HDMC }}
              </el-col>
              <el-col :span="4" :class="'activityscore' + this.exportType">
                {{ activitylist[0].DXS }}颗星
              </el-col>
            </el-row>
            <el-row
              :gutter="10"
              v-if="
                activitylist[0].IndexList != null &&
                  activitylist[0].IndexList.length > 0
              "
            >
              <el-col :span="3">
                <div :class="'indexname' + this.exportType">
                  {{ activitylist[0].IndexList[0].ZBMC }}:
                </div>
              </el-col>
              <el-col :span="9">
                <img
                  :class="'indexscore' + exportType"
                  v-for="i in activitylist[0].IndexList[0].DXS"
                  :key="i"
                  src="../../../../style/growth/staricon.png"
                />
              </el-col>
              <el-col :span="3">
                <div
                  v-if="activitylist[0].IndexList.length > 1"
                  :class="'indexname' + this.exportType"
                >
                  {{ activitylist[0].IndexList[1].ZBMC }}:
                </div>
              </el-col>
              <el-col :span="9">
                <div v-if="activitylist[0].IndexList.length > 1">
                  <img
                    :class="'indexscore' + exportType"
                    v-for="i in activitylist[0].IndexList[1].DXS"
                    :key="i"
                    src="../../../../style/growth/staricon.png"
                  />
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10" v-if="activitylist[0].FjList.length > 0">
              <img
                :class="'attachimage' + exportType"
                :src="activitylist[0].FjList[0].FJLJ"
              />
              <img
                v-if="activitylist[0].FjList.length > 1"
                :class="'attachimage' + exportType"
                :src="activitylist[0].FjList[1].FJLJ"
              />
              <img
                v-if="activitylist[0].FjList.length > 2"
                :class="'attachimage' + exportType"
                :src="activitylist[0].FjList[2].FJLJ"
              />
            </el-row>
          </div>
          <div
            :class="'performancedetaildiv' + this.exportType"
            v-if="activitylist.length > 1"
          >
            <el-row :gutter="10">
              <el-col
                :span="3"
                v-if="
                  activitylist[1].HDTP != null && activitylist[1].HDTP != ''
                "
              >
                <el-image
                  :class="'pjricon' + exportType"
                  :src="activitylist[1].HDTP"
                >
                </el-image>
              </el-col>
              <el-col :span="12" :class="'activityname' + this.exportType">
                {{ activitylist[1].HDMC }}
              </el-col>
              <el-col :span="4" :class="'activityscore' + this.exportType">
                {{ activitylist[1].DXS }}颗星
              </el-col>
            </el-row>
            <el-row
              :gutter="10"
              v-if="
                activitylist[1].IndexList != null &&
                  activitylist[1].IndexList.length > 0
              "
            >
              <el-col :span="3">
                <div :class="'indexname' + this.exportType">
                  {{ activitylist[1].IndexList[0].ZBMC }}:
                </div>
              </el-col>
              <el-col :span="9">
                <img
                  :class="'indexscore' + exportType"
                  v-for="i in activitylist[1].IndexList[0].DXS"
                  :key="i"
                  src="../../../../style/growth/staricon.png"
                />
              </el-col>
              <el-col :span="3">
                <div
                  v-if="activitylist[1].IndexList.length > 1"
                  :class="'indexname' + this.exportType"
                >
                  {{ activitylist[1].IndexList[1].ZBMC }}:
                </div>
              </el-col>
              <el-col :span="9">
                <div v-if="activitylist[1].IndexList.length > 1">
                  <img
                    :class="'indexscore' + exportType"
                    v-for="i in activitylist[1].IndexList[1].DXS"
                    :key="i"
                    src="../../../../style/growth/staricon.png"
                  />
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10" v-if="activitylist[1].FjList.length > 0">
              <img
                :class="'attachimage' + exportType"
                :src="activitylist[1].FjList[0].FJLJ"
              />
              <img
                v-if="activitylist[1].FjList.length > 1"
                :class="'attachimage' + exportType"
                :src="activitylist[1].FjList[1].FJLJ"
              />
              <img
                v-if="activitylist[1].FjList.length > 2"
                :class="'attachimage' + exportType"
                :src="activitylist[1].FjList[2].FJLJ"
              />
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./template.css";
export default {
  data() {
    return {
      ssxx: "",
      xqid: "",
      token: "",
      backgroudimage: "",
      exportType: 1,
      schoolinfo: {
        bookname: "学生成长评价手册",
        schoolname: "重庆市巴南区世贸小学",
        pagename: "家庭实践活动",
      },
      xsinfo: {
        SSNJ: "",
        NJ: "",
        Id: "",
        XM: "",
        XBM: 1,
        JG: "",
        XZZ: "",
        ZP: "",
        MZ: "",
        XH: "",
        XQAH: "",
        BZRMC: "",
        XQMC: "",
        SSBJ: "",
        BJMC: "",
        SSXX: "",
        XXMC: "",
        XXBM: "",
        XXTB: "",
        XZ: "",
        NL: "",
        SSXQ: "",
        SSXN: "",
        XQLX: 1,
        ZFS: "",
      },
      activitylist: [],
    };
  },
  created() {
    this.xqid = localStorage.getItem("templateTerm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
    this.exportType = this.$route.query.exportType
      ? this.$route.query.exportType * 1
      : 1;
    this.backgroudimage = this.$route.query.tydt;
    if (this.info.TPLJ == "" && this.backgroudimage != "") {
      this.info.TPLJ = this.backgroudimage;
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          SSXX: "",
          YM: 0,
          GLMB: "",
          MBMC: "",
          BT: "",
          TPLJ: "",
          GLId: "",
          GLLX: 0,
          FJSM: "",
        };
      },
    },
  },
  methods: {
    changexsdata(xsdata) {
      this.xsinfo = {
        ...this.xsinfo,
        ...xsdata.xsxx,
      };
      // this.xsinfo.Id = xsdata.Id;
      // this.xsinfo.XM = xsdata.XM;
      // this.xsinfo.XBM = xsdata.XBM;
      // this.xsinfo.ZP = xsdata.ZP;
      // this.xsinfo.XQAH = xsdata.XQAH;
      // this.xsinfo.SSXX = xsdata.SSXX;
      // this.xsinfo.SSXQ = xsdata.SSXQ;
      // this.xsinfo.SSXN = xsdata.SSXN;
      // this.xsinfo.XQLX = xsdata.XQLX;
      // this.xsinfo.BZRMC = xsdata.BZRMC;
      // this.xsinfo.NL = xsdata.NL;
      // this.xsinfo.XXMC = xsdata.XXMC;
      // this.xsinfo.XXTB = xsdata.XXTB;
      // this.xsinfo.BJMC = xsdata.BJMC;
      // this.xsinfo.XXMC = xsdata.XXMC;
      // this.xsinfo.XXBM = xsdata.XXBM;
      // this.xsinfo.XZ = xsdata.XZ;
      // this.xsinfo.SSBJ = xsdata.SSBJ;
      // this.xsinfo.SSNJ = xsdata.SSNJ;
      // this.xsinfo.NJ = xsdata.NJ;
      if (
        this.xsinfo.ZP == null ||
        this.xsinfo.ZP == undefined ||
        this.xsinfo.ZP == ""
      ) {
        this.xsinfo.ZP =
          this.xsinfo.XBM == 1
            ? require("../../../../style/images/defaultStudentBoy.png")
            : require("../../../../style/images/defaultStudentGirl.png");
      }

      this.xsinfo.ZFS = "3.0";

      this.getxsreportdata();
      // this.gettotalscore();
    },
    getxsreportdata: function() {
      let pageinfo = {
        PageIndex: 1,
        PageSize: 2,
      };
      this.$api.post(
        "v1/evaluationreport/GetStudentStarRecent",
        {
          HDId: this.info.GLId,
          SSXX: this.xsinfo.SSXX,
          SSXQ: this.xqid,
          XSId: this.xsinfo.Id,
          token: this.token,
          PagerInfo: pageinfo,
        },
        (r) => {
          if (r.Code == 0) {
            var templist = r.Target;
            this.activitylist = [];
            if (
              templist != null &&
              templist != undefined &&
              templist.length > 0
            ) {
              for (var i = 0; i < templist.length; i++) {
                if (
                  templist[i].IndexList != null &&
                  templist[i].IndexList != undefined &&
                  templist[i].IndexList.length > 0
                ) {
                  templist[i].DXS = templist[i].IndexList.reduce(function(
                    total,
                    item
                  ) {
                    return item.DXS + total;
                  },
                  0);
                } else {
                  templist[i].DXS = 0;
                }
                templist[i].PJNR = "这是一条无用的描述消息";
                this.activitylist.push(templist[i]);
              }
            }
          }
        }
      );
    },
    gettotalscore: function() {
      this.$api.post(
        "v1/evaluationreport/GetStudentTotalStar",
        {
          HDId: this.info.GLId,
          SSXX: this.xsinfo.SSXX,
          SSXQ: this.xsinfo.SSXQ,
          XSId: this.xsinfo.Id,
          token: this.token,
        },
        (r) => {
          if (r.Code == 0) {
            this.xsinfo.ZFS = r.Target.DXS;
          }
        }
      );
    },
  },
  mounted: function() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.basetitle1 {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  line-height: 140px;
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: bolder;
  color: green;
  /*#3ee5a5*/
  -webkit-text-stroke: 2px #ffffff;
  text-stroke: 2px #ffffff;
}

.basetitle2 {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  line-height: 90px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bolder;
  color: green;
  /* #00a1e9 #3ee5a5*/
  -webkit-text-stroke: 1px #ffffff;
  text-stroke: 1px #ffffff;
}

.studenticon1 {
  width: 145px;
  height: 145px;
  text-align: center;
  float: left;
  margin-top: 20px;
  margin-left: 20px;
}

.studenticon2 {
  width: 80px;
  height: 80px;
  text-align: center;
  float: left;
  margin-top: 20px;
  margin-left: 20px;
}

.clboth {
  clear: both;
}

.totalback1 {
  background-image: url("../../../../style/growth/rectangle_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 20px;
  height: 1000px;
  width: 100%;
}

.totalback2 {
  background-image: url("../../../../style/growth/rectangle_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 10px;
  height: 720px;
  width: 100%;
}

.xsdiv1 {
  height: 170px;
  width: 400px;
}

.xsdiv2 {
  height: 120px;
  width: 430px;
}

.detailinfo1 {
  background: #2caeff;
  border: 0px solid #cbe0fd;
  border-radius: 25px;
  width: 800px;
  height: 980px;
  margin-left: 20px;
}

.detailinfo2 {
  background: #2caeff;
  border: 0px solid #cbe0fd;
  border-radius: 15px;
  width: 570px;
  height: 700px;
  margin-left: 10px;
}

.xsinfodiv1 {
  margin-top: 55px;
  margin-left: 20px;
  float: left;
}

.xsinfodiv2 {
  margin-top: 30px;
  margin-left: 10px;
  float: left;
}

.xsinfotitle1 {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 45px;
}

.xsinfotitle2 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
}

.xsinfoclass1 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #000000;
  line-height: 30px;
  opacity: 0.8;
}

.xsinfoclass2 {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  opacity: 0.8;
  margin-top: -10px;
}

.indexscore1 {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 0 0 5px;
}

.indexscore2 {
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin: 0 0 0 3px;
}

.activityname1 {
  width: 400px;
  height: 40px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 50px;
}

.activityname2 {
  width: 300px;
  height: 35px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}

.activityscore1 {
  width: 100px;
  height: 40px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #333333;
  line-height: 40px;
}

.activityscore2 {
  width: 100px;
  height: 40px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #333333;
  line-height: 40px;
}

.indexname1 {
  width: 120px;
  height: 25px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 350;
  color: #333333;
  line-height: 25px;
  margin-top: 3px;
}

.indexname2 {
  width: 100px;
  height: 20px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 350;
  color: #333333;
  line-height: 20px;
  margin-top: 3px;
}

.performancedetaildiv1 {
  width: 760px;
  margin: 0 10px 10px 10px;
  max-height: 303px;
  background: #ffffff;
  border-radius: 20px;
  padding: 10px 10px 0 10px;
}

.performancedetaildiv2 {
  width: 530px;
  margin: 0 10px 10px 10px;
  max-height: 210px;
  background: #ffffff;
  border-radius: 15px;
  padding: 10px 10px 0 10px;
}

.totalscore1 {
  width: 483px;
  text-align: center;
  height: 249px;
  margin-top: -80px;
  padding-top: 5px;
  background-image: url("../../../../style/growth/score_back2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: 165px;
  margin-bottom: -10px;
}

.totalscore2 {
  width: 362px;
  text-align: center;
  height: 186px;
  margin-top: -65px;
  padding-top: 5px;
  background-image: url("../../../../style/growth/score_back2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: 115px;
  margin-bottom: -5px;
}

.scoretext1 {
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #22a049;
  margin-top: -20px;
  margin-left: -13px;
}

.scoretext2 {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #22a049;
  margin-top: -20px;
  margin-left: -13px;
}

.scoredesc1 {
  width: 100%;
  margin-top: 100px;
  margin-left: -9px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #666666;
}

.scoredesc2 {
  width: 100%;
  margin-top: 75px;
  margin-left: -7px;
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #666666;
}

.activitydesc1 {
  width: 770px;
  font-size: 25px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 10px 5px;
  color: #080808;
  line-height: 30px;
}

.activitydesc2 {
  width: 540px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 10px 5px;
  color: #080808;
  line-height: 20px;
}

.attachimage1 {
  width: 246.66px;
  height: 185px;
  margin: 10px 5px 0 5px;
}

.attachimage2 {
  width: 166.66px;
  height: 120px;
  margin: 10px 5px 0 5px;
}

.pjricon1 {
  width: 50px;
  height: 50px;
  border-radius: 15px;
}

.pjricon2 {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
</style>
