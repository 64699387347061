<template>
  <div class="content">
    <div class="toubudh-box">
      <span></span>
      <span></span>
      <span class="whole-col"></span>
    </div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div style="padding: 30px;">
          <div class="margno-t">
            <div class="table-er-box">
              <!--TODO:按钮-->
              <el-button plain @click="addItem">新增</el-button>
            </div>
            <!--TODO:内容-->
            <Table
              :tableData="
                dataTable.slice(
                  (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
                  (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                    pagerInfo.pageSize
                )
              "
              @editItem="editItem"
              @delItem="getData"
              @updateItem="updateItem"
              :user="user"
            />
            <div class="pagin-box">
              <div class="pagin-wz">
                <!--分页-->
                <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <From
      :visible="visible"
      :user="user"
      :dimensions="dimensions"
      :data="formData"
      :title="dialogTitle"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import From from "./Form.vue";
import Table from "./Table.vue";
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager,
    Table,
    From
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      schoolId: "",
      visible: false,
      dataTable: [],
      formData: {},
      dialogTitle: "新增问卷",
      dimensions: []
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.getData();
    
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    closeDialog(data) {
      if (data) {
        this.getData();
      }
      this.visible = false;
    },
    addItem() {
      this.dialogTitle = "新增问卷";
      this.formData = {};
      this.visible = true;
    },
    editItem(data) {
      this.dialogTitle = "编辑问卷";
      this.formData = data;
      this.visible = true;
    },
    getData() {
      this.getDimensions();
      this.$api.post(
        "/v1/questionnaire/GetQuestionnaireList",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target;
            this.pagerInfo.totalCount = this.dataTable.length;
            this.isLoading = false;
          }
        }
      );
      this.pagerInfo.totalCount = this.dataTable.length;
    },
    getDimensions() {
      const pageinfo = {
        PageIndex: 0,
        PageSize: parseInt(Number.MAX_SAFE_INTEGER / 10000000)
      };
      this.$api.post(
        "/v1/dimension/GetDimensionTree",
        {
          token: this.token,
          PagerInfo: pageinfo,
          SchoolId: this.schoolId
        },
        r => {
          this.dimensions = JSON.parse(JSON.stringify(r.Target));
        }
      );
    },
    updateItem(data) {
      this.dataTable.splice(
        this.dataTable.findIndex(c => c.Id === data.Id),
        1,
        data
      );
    }
  }
};
</script>
