<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'detailinfo' + this.exportType">
					<div :class="'xsdiv' + this.exportType">
						<img :src="xsinfo.ZP" :class="'studenticon' + this.exportType" />
						<div :class="'xsinfodiv' + this.exportType">
							<div :class="'xsinfotitle' + this.exportType">
								{{ xsinfo.XM }}
							</div>
							<br />
							<div :class="'xsinfoclass' + this.exportType">
								{{ xsinfo.BJMC }}
							</div>
						</div>
					</div>
					<div :class="'totalscore' + this.exportType">
						<div :class="'scoretext' + this.exportType">
							{{ xsinfo.ZFS }}
						</div>
						<br />
						<div :class="'scoredesc' + this.exportType">
							总分数
						</div>
					</div>
					<div :class="'performancedetaildiv' + this.exportType">
						<el-row :gutter="10" v-if="courselist.length > 0">
							<el-col :span="8" :class="'courseblock' + this.exportType">
								<span :class="'coursescore' + this.exportType">
									{{ courselist[0].socre }}
								</span>
								<br />
								<span :class="'coursename' + this.exportType">
									{{ courselist[0].coursename }}
								</span>
							</el-col>

							<el-col :span="8" :class="'courseblock' + this.exportType" v-if="courselist.length > 1">
								<span :class="'coursescore' + this.exportType">
									{{ courselist[1].socre }}
								</span>
								<br />
								<span :class="'coursename' + this.exportType">
									{{ courselist[1].coursename }}
								</span>
							</el-col>
							<el-col :span="8" :class="'courseblock' + this.exportType" v-if="courselist.length > 2">
								<span :class="'coursescore' + this.exportType">
									{{ courselist[2].socre }}
								</span>
								<br />
								<span :class="'coursename' + this.exportType">
									{{ courselist[2].coursename }}
								</span>
							</el-col>
						</el-row>
						<el-row :gutter="10" v-if="courselist.length > 3">
							<el-col :span="8" :class="'courseblock' + this.exportType">
								<span :class="'coursescore' + this.exportType">
									{{ courselist[3].socre }}
								</span>
								<br />
								<span :class="'coursename' + this.exportType">
									{{ courselist[3].coursename }}
								</span>
							</el-col>
							<el-col :span="8" :class="'courseblock' + this.exportType" v-if="courselist.length > 4">
								<span :class="'coursescore' + this.exportType">
									{{ courselist[4].socre }}
								</span>
								<br />
								<span :class="'coursename' + this.exportType">
									{{ courselist[4].coursename }}
								</span>
							</el-col>
							<el-col :span="8" :class="'courseblock' + this.exportType" v-if="courselist.length > 5">
								<span :class="'coursescore' + this.exportType">
									{{ courselist[5].socre }}
								</span>
								<br />
								<span :class="'coursename' + this.exportType">
									{{ courselist[5].coursename }}
								</span>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				exportType: 1,
				backgroudimage: "",
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "课堂表现"
				},
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					ZFS: 9.5
				},
				courselist: [{
						coursename: "语文",
						socre: 10.0
					},
					{
						coursename: "数学",
						socre: 9.3
					},
					{
						coursename: "音乐",
						socre: 8.7
					},
					{
						coursename: "体育",
						socre: 10.0
					},
					{
						coursename: "思想品德",
						socre: 10.0
					},
					{
						coursename: "美术",
						socre: 10.0
					}
				]
			};
		},
		created() {
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo.Id = xsdata.Id;
				this.xsinfo.XM = xsdata.XM;
				this.xsinfo.XBM = xsdata.XBM;
				this.xsinfo.ZP = xsdata.ZP;
				this.xsinfo.XQAH = xsdata.XQAH;
				this.xsinfo.SSXX = xsdata.SSXX;
				this.xsinfo.SSXQ = xsdata.SSXQ;
				this.xsinfo.SSXN = xsdata.SSXN;
				this.xsinfo.XQLX = xsdata.XQLX;
				this.xsinfo.BZRMC = xsdata.BZRMC;
				this.xsinfo.NL = xsdata.NL;
				this.xsinfo.XXMC = xsdata.XXMC;
				this.xsinfo.XXTB = xsdata.XXTB;
				this.xsinfo.SSBJ = xsdata.SSBJ;
				this.xsinfo.BJMC = xsdata.BJMC;
				this.xsinfo.XXMC = xsdata.XXMC;
				this.xsinfo.XXBM = xsdata.XXBM;
				this.xsinfo.XZ = xsdata.XZ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
			}
		},
		mounted: function() {
			if (this.courselist.length > 0) {
				var templist = this.courselist;
				for (var i = 0; i < templist.length; i++) {
					if (parseFloat(templist[i].socre) > 0) {
						templist[i].socre = "+" + templist[i].socre;
					}
				}
				this.courselist = templist;
			}
		},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 145px;
		height: 145px;
		text-align: center;
		float: left;
		margin-top: 50px;
		margin-left: 40px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 30px;
		margin-left: 30px;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/radius_dotted.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 40px;
		height: 980px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/radius_dotted.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 30px;
		height: 700px;
		width: 100%;
	}

	.xsdiv1 {
		height: 290px;
		width: 756px;
	}

	.xsdiv2 {
		height: 188px;
		width: 530px;
	}

	.detailinfo1 {
		background-image: url("../../../../style/growth/performance_detail.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 756px;
		height: 940px;
		margin-left: 40px;
	}

	.detailinfo2 {
		background-image: url("../../../../style/growth/performance_detail.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 530px;
		height: 650px;
		margin-left: 30px;
	}

	.xsinfodiv1 {
		margin-top: 70px;
		margin-left: 30px;
		float: left;
	}

	.xsinfodiv2 {
		margin-top: 40px;
		margin-left: 15px;
		float: left;
	}

	.xsinfotitle1 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 45px;
	}

	.xsinfotitle2 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}

	.xsinfoclass1 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #000000;
		line-height: 30px;
		opacity: 0.8;
	}

	.xsinfoclass2 {
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #000000;
		line-height: 20px;
		opacity: 0.8;
		margin-top: -10px;
	}

	.detailtitle1 {
		width: 756px;
		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #15d089;
		padding: 35px 0 5px 0;
		text-align: center;
		display: block;
	}

	.detailtitle2 {
		width: 530px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #15d089;
		padding: 15px 0 5px 0;
		text-align: center;
		display: block;
	}

	.detailicon1 {
		width: 40px;
		height: 40px;
	}

	.detailicon2 {
		width: 30px;
		height: 30px;
	}

	.courseblock1 {
		width: 242px;
		height: 150px;
		background: linear-gradient(0deg, #d9e483, #d9efff);
		border-radius: 20px;
		vertical-align: middle;
		text-align: center;
		margin-top: 10px;
		margin-left: 10px;
		padding: 20px 0 0 0;
	}

	.courseblock2 {
		width: 168px;
		height: 108px;
		background: linear-gradient(0deg, #d9e483, #d9efff);
		border-radius: 20px;
		vertical-align: middle;
		text-align: center;
		padding: 10px 0 0 0;
		margin-top: 10px;
		margin-left: 10px;
	}

	.coursescore1 {
		width: 172px;
		height: 70px;
		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		line-height: 70px;
	}

	.coursescore2 {
		width: 172px;
		height: 50px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		line-height: 50px;
	}

	.coursename1 {
		width: 172px;
		height: 40px;
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #080808;
		line-height: 40px;
	}

	.coursename2 {
		width: 172px;
		height: 30px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #080808;
		line-height: 30px;
	}

	.performancedetaildiv1 {
		height: 400px;
		margin-top: 150px;
	}

	.performancedetaildiv2 {
		height: 300px;
		margin-top: 100px;
	}

	.totalscore1 {
		width: 100%;
		text-align: center;
		height: 200px;
		margin-top: -20px;
	}

	.totalscore2 {
		width: 100%;
		text-align: center;
		height: 150px;
		margin-top: -10px;
	}

	.scoretext1 {
		font-size: 60px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
	}

	.scoretext2 {
		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
	}

	.scoredesc1 {
		margin-top: -20px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #080808;
	}

	.scoredesc2 {
		margin-top: -15px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #080808;
	}
</style>