<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="30%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="200px"
      class="demo-ruleForm"
      status-icon
      label-position="right"
    >
      <el-form-item
        :label-width="labelWidth"
        label="对象名称"
        prop="PJCYDXMC"
        class="el-item-input"
      >
        <el-input v-model="formData.PJCYDXMC"></el-input>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="对象形式"
        prop="HDXS"
        class="el-item-input"
      >
        <el-select v-model="formData.HDXS" placeholder @change="handdleHDXS">
          <el-option
            v-for="item in baseInfo.HDXSArray"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="评价方式"
        prop="PJFS"
        class="el-item"
      >
        <el-select v-model="formData.PJFS" placeholder>
          <el-option
            v-for="item in baseInfo.EvaluationMethod"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="formData.HDXS === 4"
        :label-width="labelWidth"
        label="主评人员"
        prop="PJCYDXLB"
        class="el-item"
      >
        <el-select
          v-model="formData.PJCYDXLB"
          placeholder
          @change="handdleChangeJoinType"
        >
          <el-option
            v-for="item in baseInfo.EvaluationObjectJoinType"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="formData.PJCYDXLB != 3 && formData.HDXS === 4"
        :label-width="labelWidth"
        prop="EvaluationObjectJoinTypeList"
        class="el-item"
      >
        <div v-if="formData.PJCYDXLB == 1" style>
          <el-select
            v-if="showMultischool"
            v-model="multischool"
            placeholder
            size="small"
            @change="handdleChangeMultischool"
          >
            <el-option
              v-for="item in multischools"
              :key="item.Key"
              :label="item.Value"
              :value="item.Key"
            ></el-option>
          </el-select>
          <el-checkbox-group v-model="formData.EvaluationObjectJoinTypeList">
            <el-checkbox
              v-for="item in baseInfo.CourseList.filter(
                c => c.MultiSchool == multischool || multischool == 0
              )"
              :key="item.Id"
              :label="item.Id"
              >{{ item.Name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div v-else-if="formData.PJCYDXLB == 2" style>
          <el-checkbox-group v-model="formData.EvaluationObjectJoinTypeList">
            <el-checkbox
              v-for="item in baseInfo.RoleList"
              :key="item.Id"
              :label="item.Id"
              >{{ item.Name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="参与年级"
        prop
        class="el-item"
      >
        <div style>
          <el-checkbox
            v-model="isAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            >全部</el-checkbox
          >
          <el-checkbox-group
            v-model="formData.GradeOfObjectList"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              v-for="item in baseInfo.GradeList.filter(c =>
                gradeFilter.includes(c.MultiSchool)
              )"
              :key="item.Key"
              :label="item.Key"
              >{{ item.Value }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="状态"
        prop="SYZT"
        class="el-item"
      >
        <el-select v-model="formData.SYZT" placeholder>
          <el-option
            v-for="item in baseInfo.SYZT"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
var checkEvaluationObjectJoinType = function(rule, value, callback) {
  if (value.length === 0 && rule.joinType !== 3) {
    return callback(new Error(rule.message));
  } else return callback();
};
export default {
  data() {
    return {
      gradeList: [],
      showMultischool: true,
      gradeFilter: [1, 2],
      multischools: [
        {
          Key: 0,
          Value: "全部"
        },
        {
          Key: 1,
          Value: "小学"
        },
        {
          Key: 2,
          Value: "中学"
        }
      ],
      multischool: 0,
      isAll: false,
      isIndeterminate: false,
      title: "新增对象",
      labelWidth: "120px",
      formData: {},
      dialogVisiable: false,
      ruleEvaluationObjectJoinTypeList: [
        {
          validator: checkEvaluationObjectJoinType,
          message: "请选择主评人员",
          trigger: "change",
          type: "Array",
          joinType: 3
        }
      ],
      rulePJCYDXLB: [
        { required: true, message: "请选择主评人员", trigger: "change" }
      ],
      rules: {
        HDXS: [
          { required: true, message: "请选择对象形式", trigger: "change" }
        ],
        PJCYDXMC: [
          { required: true, message: "请输入对象名称", trigger: "change" },
          { min: 1, max: 10, message: "长度不超过10个字", trigger: "change" }
        ],
        PJFS: [
          { required: true, message: "请选择评价方式", trigger: "change" }
        ],
        // PJCYDXLB: [
        //   { required: true, message: "请选择主评人员", trigger: "change" }
        // ],
        SYZT: [{ required: true, message: "请选择状态", trigger: "change" }]
        // EvaluationObjectJoinTypeList: [
        //   {
        //     validator: checkEvaluationObjectJoinType,
        //     message: "请选择主评人员",
        //     trigger: "change",
        //     type: "Array",
        //     joinType: 3
        //   }
        // ]
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: function() {
        return {};
      }
    },
    baseInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    initForm() {
      this.gradeList = [];
      for (let i = 0; i < this.baseInfo.GradeList.length; i++) {
        this.gradeList.push(this.baseInfo.GradeList[i].Key);
      }
      this.title = "新增对象";
      this.isAll = false;
      this.isIndeterminate = false;
      this.formData = {
        GradeOfObjectList: [],
        EvaluationObjectJoinTypeList: []
      };
      if (this.form && (this.form.Id || this.form.Id > 0)) {
        this.formData = JSON.parse(JSON.stringify(this.form));
        this.title = "编辑对象";
      }
      const checkedCount = this.formData.GradeOfObjectList.length;
      this.isAll =
        checkedCount >=
        this.baseInfo.GradeList.filter(c =>
          this.gradeFilter.includes(c.MultiSchool)
        ).length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount <
          this.baseInfo.GradeList.filter(c =>
            this.gradeFilter.includes(c.MultiSchool)
          ).length;
      if (this.formData.HDXS && this.formData.HDXS === 0) {
        this.$delete(this.formData, "HDXS");
      }
    },
    // closeDialog() {
    //   this.$emit("closeDialog");
    // },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeDialog");
      });
      // this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "保存",
      //   cancelButtonText: "放弃修改"
      // })
      //   .then(() => {
      //     //执行提交方法,执行成功后回调函数执行以下代码
      //     this.confirmDialog()
      //   })
      //   .catch(action => {
      //     if (action === "cancel") {
      //       this.$emit("closeDialog");
      //     }
      //   });
    },
    confirmDialog() {
      const data = JSON.parse(JSON.stringify(this.formData));
      this.$refs.formData.validate(valid => {
        if (valid) {
          // eslint-disable-next-line no-unused-vars
          this.$confirm("确认提交？").then(r => {
            // 执行提交方法
            const joinType = this.baseInfo.EvaluationObjectJoinType.find(
              c => c.Key === data.PJCYDXLB
            );
            if (joinType && joinType.Value) {
              data.PJCYDXLBStr = joinType.Value;
            }
            const evaluationMethod = this.baseInfo.EvaluationMethod.find(
              c => c.Key === data.PJFS
            );
            if (evaluationMethod && evaluationMethod.Value) {
              data.PJFSStr = evaluationMethod.Value;
            }
            // eslint-disable-next-line no-unused-vars
            const numberArray = [
              "未知",
              "一",
              "二",
              "三",
              "四",
              "五",
              "六",
              "七",
              "八",
              "九"
            ];
            data.GradeOfObjectList = data.GradeOfObjectList.sort(
              (a, b) => a - b
            );
            data.GradeOfObject = data.GradeOfObjectList.length > 0 ? "" : "无";
            for (let i = 0; i < data.GradeOfObjectList.length; i++) {
              let grade = this.baseInfo.GradeList.find(
                c => c.Key === data.GradeOfObjectList[i]
              );
              data.GradeOfObject += grade ? grade.Value : "";
              // numberArray[data.GradeOfObjectList[i]] + "年级";
              if (i < data.GradeOfObjectList.length - 1) {
                data.GradeOfObject += ",";
              }
            }
            data.SYZTStr = data.SYZT === 1 ? "正常" : "停用";
            data.GradeOfObjectListStr = JSON.stringify(data.GradeOfObjectList);
            this.$emit("confirmDialog", data);
          });
          // .catch(r => {});
        } else {
          return false;
        }
      });
    },
    handleCheckAllChange(val) {
      this.formData.GradeOfObjectList = val ? this.gradeList : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length;
      this.isAll =
        checkedCount ===
        this.baseInfo.GradeList.filter(c =>
          this.gradeFilter.includes(c.MultiSchool)
        ).length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount <
          this.baseInfo.GradeList.filter(c =>
            this.gradeFilter.includes(c.MultiSchool)
          ).length;
    },
    handdleChangeJoinType() {
      this.rules.EvaluationObjectJoinTypeList = this.ruleEvaluationObjectJoinTypeList;
      this.rules.EvaluationObjectJoinTypeList[0].joinType = this.formData.PJCYDXLB;
      this.formData.EvaluationObjectJoinTypeList = [];
      if (
        this.baseInfo.GradeList.filter(c => c.MultiSchool === 1).length === 0
      ) {
        this.multischool = 2;
        this.gradeFilter = [this.multischool];
        this.showMultischool = false;
      } else if (
        this.baseInfo.GradeList.filter(c => c.MultiSchool === 2).length === 0
      ) {
        this.multischool = 1;
        this.gradeFilter = [this.multischool];
        this.showMultischool = false;
      } else {
        this.multischool = 0;
        this.gradeFilter = [1, 2];
        this.showMultischool = true;
      }
    },
    handdleChangeMultischool() {
      if (this.multischool !== 0) {
        this.gradeFilter = [this.multischool];
      } else {
        this.gradeFilter = [1, 2];
      }
    },
    handdleHDXS() {
      if (this.formData.HDXS === 4) {
        this.rules.PJCYDXLB = this.rulePJCYDXLB;
      } else {
        this.rules.PJCYDXLB = [];
        this.rules.EvaluationObjectJoinTypeList = [];
      }
    }
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        this.initForm();
      } else {
        // 重置校验结果
        this.$refs.formData.resetFields();
        this.formData = { GradeOfObjectList: [] };
      }
    }
  }
};
</script>

<style scoped>
.el-item {
  max-width: 450px;
  margin-left: 5%;
}
.el-item-input {
  max-width: 340px;
  margin-left: 5%;
}
</style>
