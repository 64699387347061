<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    width="30%"
    center
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="tab-shu-box">
      <el-form ref="ruleForm">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl">新密码:</td>
              <td>
                <el-form-item>
                  <el-input
                    v-model="formData.Pwd"
                    placeholder="6-18位密码"
                    minlength="6"
                    maxlength="18"
                  ></el-input>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="saveItem()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String
  },
  data() {
    return {
      shapeLimit: [],
      validateResult: false,
      labelWidth: "250px",
      formData: {
        Pwd: ""
      },
      options: [],
      pagerInfo: {
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0
      }
    };
  },
  methods: {
    init() {
      this.formData = [];
    },
    handleClose() {
      this.$emit("update:show", false);
    },
    saveItem: function() {
      if (this.formData.Pwd === undefined || this.formData.Pwd === "") {
        this.$message({
          type: "info",
          message: "请输入密码",
          showClose: true
        });
        return;
      }
      if (this.formData.Pwd.length < 6) {
        this.$message({
          type: "info",
          message: "密码策略要求不能少于6位",
          showClose: true
        });
        return;
      }
      // 提交
      this.$api.post(
        "v1/Growth/UpdatePwd",
        { Id: this.$route.query.Id, MM: this.formData.Pwd, token: this.token },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: "操作成功"
            });
            this.handleClose();
            this.$parent.getData();
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    }
  },
  watch: {
    show(val) {
      this.init();
      if (val) {
        // 显示
      } else {
        // 隐藏
        this.$refs.ruleForm.resetFields();
      }
    }
  }
};
</script>
