<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div id="bmiChart" :class="'fitnessscorebar' + this.exportType"></div>
				<div :class="'fitnessdetailinfo' + this.exportType">
					<div :class="'charttitle' + this.exportType">
						<el-row :gutter="10">
							<el-col :span="1">
								<div :class="'infoicon' + this.exportType"></div>
							</el-col>
							<el-col :span="23">
								我的体质报告
							</el-col>
						</el-row>
					</div>
					<el-row :gutter="10">
						<el-col :span="8">
							<div id="stxtChart" :class="'chartdiv' + this.exportType"></div>
						</el-col>
						<el-col :span="8">
							<div id="stjnChart" :class="'chartdiv' + this.exportType"></div>
						</el-col>
						<el-col :span="8">
							<div id="stszChart" :class="'chartdiv' + this.exportType"></div>
						</el-col>
					</el-row>
				</div>
				<div :class="'fitnessdetailrow' + this.exportType">
					<div :class="'charttitle' + this.exportType">
						<el-row :gutter="10">
							<el-col :span="1">
								<div :class="'infoicon' + this.exportType"></div>
							</el-col>
							<el-col :span="23">
								健康分析
							</el-col>
						</el-row>
					</div>
					{{ xsinfo.jkfx }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				exportType: 1,
				backgroudimage: "",
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					TZDF: "",
					TZDFMC: "",
					STXT: "",
					STJN: "",
					STSZ: "",
					jkfx: ""
				}
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: "",
						bmiChart: null,
						stxtChart: null,
						stjnChart: null,
						stszChart: null
					};
				}
			}
		},
		methods: {
			drawstxtChartGauge(score, processcolor, title) {
				if (
					this.stxtChart != null &&
					this.stxtChart != "" &&
					this.stxtChart != undefined
				) {
					this.stxtChart.dispose();
				}
				this.stxtChart = this.$echarts.init(document.getElementById("stxtChart"));
				this.stxtChart.setOption({
					series: [{
						title: {
							offsetCenter: [0, "-40%"],
							color: "#333333"
						},
						radius: "90%", //仪表大小
						type: "gauge",
						startAngle: 200,
						endAngle: -20,
						min: 0,
						max: 100,
						splitNumber: 8,
						progress: {
							show: true
						},
						pointer: {
							show: false
						},
						itemStyle: {
							color: processcolor
						},
						axisLine: {
							lineStyle: {
								color: [
									[1, "#f2f2f2"]
								]
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false
						},
						anchor: {
							show: false
						},
						detail: {
							offsetCenter: [0, "-5%"],
							valueAnimation: true,
							formatter: function(value) {
								return value + "分";
							},
							color: "#333333"
						},
						data: [{
							value: score,
							name: title
						}]
					}]
				});
			},
			drawstjnChartGauge(score, processcolor, title) {
				if (
					this.stjnChart != null &&
					this.stjnChart != "" &&
					this.stjnChart != undefined
				) {
					this.stjnChart.dispose();
				}
				this.stjnChart = this.$echarts.init(document.getElementById("stjnChart"));
				this.stjnChart.setOption({
					series: [{
						title: {
							offsetCenter: [0, "-40%"],
							color: "#333333"
						},
						radius: "90%", //仪表大小
						type: "gauge",
						startAngle: 200,
						endAngle: -20,
						min: 0,
						max: 100,
						splitNumber: 8,
						progress: {
							show: true
						},
						pointer: {
							show: false
						},
						itemStyle: {
							color: processcolor
						},
						axisLine: {
							lineStyle: {
								color: [
									[1, "#f2f2f2"]
								]
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false
						},
						anchor: {
							show: false
						},
						detail: {
							offsetCenter: [0, "-5%"],
							valueAnimation: true,
							formatter: function(value) {
								return value + "分";
							},
							color: "#333333"
						},
						data: [{
							value: score,
							name: title
						}]
					}]
				});
			},
			drawstszChartGauge(score, processcolor, title) {
				if (
					this.stszChart != null &&
					this.stszChart != "" &&
					this.stszChart != undefined
				) {
					this.stszChart.dispose();
				}
				this.stszChart = this.$echarts.init(document.getElementById("stszChart"));
				this.stszChart.setOption({
					series: [{
						title: {
							offsetCenter: [0, "-40%"],
							color: "#333333"
						},
						radius: "90%", //仪表大小
						type: "gauge",
						startAngle: 200,
						endAngle: -20,
						min: 0,
						max: 100,
						splitNumber: 8,
						progress: {
							show: true
						},
						pointer: {
							show: false
						},
						itemStyle: {
							color: processcolor
						},
						axisLine: {
							lineStyle: {
								color: [
									[1, "#f2f2f2"]
								]
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false
						},
						anchor: {
							show: false
						},
						detail: {
							offsetCenter: [0, "-5%"],
							valueAnimation: true,
							formatter: function(value) {
								return value + "分";
							},
							color: "#333333"
						},
						data: [{
							value: score,
							name: title
						}]
					}]
				});
			},
			drawMainGauge(score, status) {
				let _this=this;
				var fontsize = this.exportType == 1 ? 30 : 20;

				if (this.bmiChart) {
					this.bmiChart.dispose();
				}
				this.bmiChart = this.$echarts.init(document.getElementById("bmiChart"));
				this.bmiChart.setOption({
					series: [{
						title: {
							show: true,
							offsetCenter: [0, "20%"],
							fontSize: fontsize,
							color: "#666666"
						},
						center: ["50%", "60%"],
						radius: "100%", //仪表大小
						type: "gauge",
						startAngle: 200,
						endAngle: -20,
						min: 0,
						max: 100,
						splitNumber: 8,
						progress: {
							width: fontsize,
							show: true
						},
						pointer: {
							show: false
						},
						itemStyle: {
							color: "#b2df00"
						},
						axisLine: {
							lineStyle: {
								color: [
									[1, "#f2f2f2"]
								],
								width: fontsize
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true
						},
						anchor: {
							show: true
						},
						detail: {
							offsetCenter: [0, "-20%"],
							valueAnimation: true,
							formatter: function(value) {
								return value + "分\n" + (status?status:'');
							}, //"{value}分\n" + this.xsinfo.TZDFMC == null ? "" : this.xsinfo.TZDFMC,
							color: "#B2DF00"
						},
						data: [{
							value: score,
							name: "我的体质情况"
						}]
					}]
				});
			},
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				this.getxsreportdata();
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthreport/GetPhysicaExaminationFream", {
						SchoolId: this.xsinfo.SSXX,
						TermId: this.xqid,
						SSXN: this.xsinfo.SSXN,
						SchoolCode: this.xsinfo.XXBM,
						StudentId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var info = r.Target;
							if (info != null && info != undefined) {
								// console.log(info)
								this.xsinfo.jkfx = info.AnalysisContent?info.AnalysisContent:'暂无数据';
								this.xsinfo.STXT = info.HeightWeightBMIScore;
								this.xsinfo.STJN = info.VitalCapacityScore;
								this.xsinfo.STSZ = info.STSZScore;
								this.xsinfo.TZDF = info.TZDF;
								this.xsinfo.TZDFMC = info.TZDFMC;
								this.drawcharts();
							}
						}
					}
				);
			},
			drawcharts: function() {
				this.drawMainGauge(this.xsinfo.TZDF, this.xsinfo.TZDFMC);
				this.drawstxtChartGauge(this.xsinfo.STXT, "#E75653", "身体形态");
				this.drawstjnChartGauge(this.xsinfo.STJN, "#79A2F1", "身体机能");
				this.drawstszChartGauge(this.xsinfo.STSZ, "#F29353", "身体素质");
			}
		},
		mounted() {},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/orange_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/orange_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.xsinfodiv1 {
		margin-top: 90px;
		margin-left: 20px;
		display: table-row;
		float: left;
		width: 165px;
	}

	.xsinfodiv2 {
		margin-top: 50px;
		margin-left: 15px;
		display: table-row;
		float: left;
		width: 125px;
	}

	.fitnessscorebar1 {
		height: 460px;
		width: 760px;
		margin-left: 40px;
		margin-top: 30px;
		background: #fbfcee;
		border-radius: 15px;
	}

	.fitnessscorebar2 {
		height: 320px;
		width: 535px;
		margin-top: 20px;
		margin-left: 30px;
		background: #fbfcee;
		border-radius: 10px;
	}

	.fitnessdetailinfo1 {
		width: 760px;
		height: 250px;
		margin-top: 10px;
		margin-left: 40px;
		background: #fbfcee;
		border-radius: 15px;
		text-align: center;
	}

	.fitnessdetailinfo2 {
		width: 535px;
		height: 180px;
		margin-top: 10px;
		margin-left: 30px;
		background: #fbfcee;
		border-radius: 10px;
		text-align: center;
	}

	.fitnessdetailrow1 {
		padding: 0 10px;
		height: 200px;
		width: 736px;
		margin-top: 10px;
		margin-left: 40px;
		background: #fbfcee;
		border-radius: 15px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #333333;
	}

	.fitnessdetailrow2 {
		height: 140px;
		width: 515px;
		margin-top: 10px;
		margin-left: 30px;
		background: #fbfcee;
		border-radius: 10px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		padding: 5px 10px;
		color: #333333;
	}

	.charttitle1 {
		width: 726px;
		color: #333333;
		text-align: left;
		line-height: 40px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		display: inline-block;
	}

	.charttitle2 {
		width: 515px;
		color: #333333;
		text-align: left;
		line-height: 20px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		display: inline-block;
	}

	.infoicon1 {
		width: 8px;
		height: 40px;
		background: linear-gradient(0deg, #ff5f88, #ffb983);
	}

	.infoicon2 {
		width: 6px;
		height: 25px;
		background: linear-gradient(0deg, #ff5f88, #ffb983);
	}

	.chartdiv1 {
		width: 240px;
		height: 210px;
	}

	.chartdiv2 {
		width: 173px;
		height: 170px;
	}
</style>