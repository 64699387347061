<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->

      <div class="tab-heng-box margno-t">
        <div class="table-er-box">
          <el-button type="primary" icon="el-icon-edit" @click="oepnadd"
            >新增角色</el-button
          >
        </div>

        <br />

        <el-table :data="tableData" border style="width: 100%;">
          <el-table-column prop="JSMC" label="角色姓名"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="editRow(scope.$index, scope.row)"
                v-if="
                  !(
                    scope.row.JSLX == 1 ||
                    scope.row.JSLX == 2 ||
                    scope.row.JSLX == 3
                  )
                "
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteRow(scope.$index, scope.row)"
                v-if="
                  !(
                    scope.row.JSLX == 1 ||
                    scope.row.JSLX == 2 ||
                    scope.row.JSLX == 3
                  )
                "
                >删除</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="oepnrolemember(scope.$index, scope.row)"
                v-if="
                  !(
                    scope.row.JSLX == 1 ||
                    scope.row.JSLX == 2 ||
                    scope.row.JSLX == 3
                  )
                "
                >管理成员</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="openrolenemberdetail(scope.$index, scope.row)"
                >查看成员</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="oepnrolepower(scope.$index, scope.row)"
                >菜单权限</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--表格区结束-->
    </div>
    <!--弹出框-->
    <el-dialog
      :title="addtitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form label-width="80px" :model="JSBInfo">
        <el-form-item label="角色名称" :label-width="formLabelWidth">
          <el-input v-model="JSBInfo.JSMC"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saverole">确 定</el-button>
      </div>
    </el-dialog>

    <!--管理成员弹出框-->
    <el-dialog
      title="管理成员"
      :visible.sync="dialogFormVisiblerole"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form>
        <el-tabs type="border-card">
          <el-tab-pane label="角色成员">
            <div style="min-height: 200px;">
              <el-tag
                v-for="item in roleTeacherList"
                :key="item.Id"
                closable
                type="info"
                @close="handleClose(item)"
                >{{ item.Name }}</el-tag
              >
            </div>
          </el-tab-pane>
        </el-tabs>
        <br />
        <br />
        <el-tabs type="border-card">
          <el-tab-pane label="教师选择">
            <div>
              <el-input
                v-model="searchTeacher"
                autocomplete="off"
                clearable
                style="width: 200px;"
              ></el-input
              >&nbsp;&nbsp;&nbsp;
              <el-button type="primary" @click="searchTecherclick"
                >查询</el-button
              >
              <el-checkbox
                style="margin-left:10px;background-color:#f5f7fa;position:relative;top:-1px"
                border
                v-model="checkedAll"
                @change="handleCheckedAll"
                >全选</el-checkbox
              >
            </div>
            <div style="height: 200px; overflow-y: auto; overflow-x: hidden;">
              <el-tag
                v-for="item in schoolTeacherList"
                :key="item.Id"
                :style="{
                  color: roleTeacherList.find(it => it.Id === item.Id)
                    ? '#f75856'
                    : '#14d089',
                  cursor: roleTeacherList.find(it => it.Id === item.Id)
                    ? 'not-allowed'
                    : 'pointer'
                }"
                @click="selectteacher(item)"
                >{{ item.Name }}</el-tag
              >
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisiblerole = false">取 消</el-button>
        <el-button type="primary" @click="saveroleTeacher">确 定</el-button>
      </div>
    </el-dialog>

    <!--查看成员弹出框-->
    <el-dialog
      title="查看成员"
      :visible.sync="dialogFormVisiblerolelist"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-tabs type="border-card">
          <el-tab-pane label="角色成员">
            <div style="min-height: 200px;">
              <el-tag v-for="item in roleTeacherDeitelList" :key="item.Id">
                {{ item.Name }}
              </el-tag>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "roleList",
  data() {
    return {
      token: this.$store.state.token,
      schoolId: "",
      tableData: [],
      formLabelWidth: "120px",
      dialogFormVisible: false,
      JSBInfo: {
        Id: "",
        JSMC: "",
        SYZT: 0,
        JSLX: null,
        SSXX: "",
        CJR: "",
        CJSJ: "2019-10-11 07:52:58",
        SFSC: 0
      },
      dialogFormVisiblerole: false,
      searchTeacher: "",
      dialogFormVisiblerolelist: false,

      schoolTeacherList: [],
      roleTeacherList: [],
      roleId: "",
      roleTeacherDeitelList: [],
      addtitle: "新增角色",
      // 全选
      checkedAll: false
    };
  },
  created: function() {
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.schoolId = userinfo.SSZZId;
    this.JSBInfo.SSXX = this.schoolId;
    this.JSBInfo.CJR = userinfo.Id;

    this.$api.post(
      "v1/role/GetRoleList",
      { schoolId: this.schoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          this.tableData = r.Target;
        }
      }
    );

    // 获取学校教师
    this.$api.post(
      "v1/role/GetRoleSchoolTeacher",
      { SchoolId: this.schoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          this.schoolTeacherList = r.Target;
        }
      }
    );
  },
  methods: {
    // 刷新
    refloadlist: function() {
      this.$api.post(
        "v1/role/GetRoleList",
        { schoolId: this.schoolId, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
          }
        }
      );
    },
    // 新增
    oepnadd: function() {
      this.addtitle = "新增角色";

      this.dialogFormVisible = true;

      this.JSBInfo.Id = "";
      this.JSBInfo.JSMC = "";
    },
    // 修改
    editRow: function(index, row) {
      this.addtitle = "修改角色";
      this.JSBInfo = row;
      this.dialogFormVisible = true;
    },
    // 删除
    deleteRow: function(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/role/DeleteJSB",
            { Id: row.Id, token: this.token },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 保存
    saverole: function() {
      if (this.JSBInfo.JSMC === "") {
        this.$message({
          type: "info",
          message: "请输入角色名称"
        });

        return;
      }

      this.$api.post(
        "v1/role/AddJSB",
        { JSBInfo: this.JSBInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: "success",
              message: r.Message,
              duration: 3000
            });

            this.dialogFormVisible = false;
            this.refloadlist();
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    },
    // 打开管理成员
    oepnrolemember: function(index, row) {
      this.dialogFormVisiblerole = true;
      this.roleId = row.Id;
      this.TeacherName = "";
      this.checkedAll = false;
      this.searchTecherclick();
      // 查询角色人员
      this.$api.post(
        "v1/role/GetRoleTeacherByRoleId",
        { RoleId: row.Id, token: this.token },
        r => {
          if (r.Code === 0) {
            this.roleTeacherList = r.Target;
          }
        }
      );
    },
    // 选择教师
    selectteacher: function(row) {
      if (this.roleTeacherList.find(item => item.Id === row.Id)) return;
      else this.roleTeacherList.push(JSON.parse(JSON.stringify(row)));

      let res = this.schoolTeacherList.every(item => {
        return this.roleTeacherList.some(it => it.Id === item.Id);
      });
      if (res) this.checkedAll = true;
    },
    // 移除选中
    handleClose: function(row) {
      this.roleTeacherList = this.roleTeacherList.filter(
        item => item.Id !== row.Id
      );
      this.checkedAll = false;
    },
    // 查询教师
    searchTecherclick: function() {
      this.$api.post(
        "v1/role/GetRoleSchoolTeacher",
        {
          SchoolId: this.schoolId,
          TeacherName: this.searchTeacher,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.schoolTeacherList = r.Target;
          }
        }
      );
    },
    // 保存角色成员
    saveroleTeacher: function() {
      if (this.roleTeacherList.length === 0) {
        this.$message({
          type: "info",
          message: "未选中人员"
        });

        return;
      }

      this.$api.post(
        "v1/role/AddJSYHGLB",
        {
          RoleId: this.roleId,
          TeacherList: this.roleTeacherList,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: "success",
              message: r.Message,
              duration: 3000
            });

            this.dialogFormVisiblerole = false;
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    },
    // 打开成员详情
    openrolenemberdetail: function(index, row) {
      this.dialogFormVisiblerolelist = true;
      console.log(row, "rou");
      this.$api.post(
        "v1/role/GetRoleTeacherByRoleId",
        { RoleId: row.Id, token: this.token },
        r => {
          if (r.Code === 0) {
            this.roleTeacherDeitelList = r.Target;
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    },
    // 打开菜单权限
    oepnrolepower: function(index, row) {
      this.$router.push({ name: "rolePowers", params: { roleId: row.Id } });
    },
    // 全选变化
    handleCheckedAll(val) {
      if (val) {
        this.schoolTeacherList.forEach(item => {
          let obj = this.roleTeacherList.find(it => it.Id === item.Id);
          if (!obj) this.roleTeacherList.push(JSON.parse(JSON.stringify(item)));
        });
      }
      // else {
      //   this.schoolTeacherList.forEach(item => {
      //     let obj = this.roleTeacherList.find(it => it.Id === item.Id);
      //     if (obj)
      //       this.roleTeacherList = this.roleTeacherList.filter(
      //         it => it.Id !== obj.Id
      //       );
      //   });
      // }
    }
  }
};
</script>

<style>
.customWidth {
  width: 50%;
}
.el-tag {
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.cell {
  text-align: left;
}
</style>
