import { saveAs } from "file-saver";
// 十进制转二进制
// bits控制位数
export function binary(num, Bits) {
  const resArry = [];
  const xresArry = [];
  let i = 0;
  // 除2取余
  for (; num > 0; ) {
    resArry.push(num % 2);
    num = parseInt(num / 2);
    i++;
  }
  // 倒序排列
  for (let j = i - 1; j >= 0; j--) {
    xresArry.push(resArry[j]);
  }
  // 报错
  if (Bits < xresArry.length) {
    console.log("控制位小于转换位数");
  }
  // 补0操作
  if (Bits) {
    for (let r = xresArry.length; r < Bits; r++) {
      xresArry.unshift("0");
    }
  }
  return xresArry.join().replace(/,/g, "");
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  const o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

export function editCurrentNaviName(obj, str) {
  const naviList =
    obj.$root.$children[0].$children[0].$refs.navi.$data.breadList;
  if (naviList) {
    const currentNavi = naviList[naviList.length - 1];
    if (currentNavi) {
      currentNavi.name = str;
    }
  }
}

export function handleDownload(url, name) {
  let a = document.createElement("A");
  a.href = url;
  url = a.href;
  console.log(url);
  getBlob(url).then(blob => {
    saveAs(blob, name);
  });
  return false;
}
function getBlob(url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.send();
  });
}

export function Compare(attr, sortType) {
  if (!["asc", "desc"].includes(sortType)) {
    sortType = "asc";
  }
  return function(a, b) {
    a = a[attr];
    b = b[attr];
    if (sortType === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };
}
