<template>
	<div>
		<div class="cont-whole-box" style="background: #f7f7f7;">
			<div style="padding: 30px;">
				<div class="essay-name-box cent">
					预览格式
					<el-button circle type="danger" @click="closecurrent" icon="el-icon-close"
						style=" margin-left:50px;"></el-button>
				</div>
			</div>
			<div class="tab-heng-box margno-t qccode-block" v-for="y in zys" :key="y">
				<div :style="'width:'+max_width+'px;height:'+(height+18)+'px;display: flex;padding-right:1px;align-content: flex-start;margin: 30px 15px;'"
					v-for="i in zys==y?num:sum_num" :key="i">
					<div :style="'width:'+width+'px ;height:'+(height+18)+'px;padding-right:4px;'">
						<img :style="'width:'+width+'px;height:'+height+'px;'" :src="qrCodeimg" />
						<div style="font-size:10px;">
							<div style="overflow: hidden;width: 100%;white-space: nowrap;text-align: center;">活动 象 指标名称
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		created() {},
		computed: {},
		mounted: function() {
			var data = JSON.parse(this.$route.query.data);
			if (data != null && data != undefined) {
				if (data.width != '0') {
					this.width = Number(data.width);
				}
				if (data.height != '0') {
					this.height = Number(data.height);
				} else {
					if (data.width != '0') {
						this.height = this.width;
					}
				}
				this.num = data.num;
			}
			if (this.num == 0) {
				this.num = parseInt(820 / (this.width > 770 ? 800 : (this.width + 30)));
			}
			this.max_width = (820 / (this.num)) - 31;
			this.sum_num = this.num * parseInt(1180 / (this.height + 78));
			this.zys = (this.imgnum % this.sum_num == 0) ? parseInt(this.imgnum / this.sum_num) : parseInt(this
					.imgnum / this.sum_num) +
				1;
			this.num = this.imgnum - ((this.zys - 1) * this.sum_num);
		},
		methods: {
			closecurrent: function() {
				window.close();
			}
		},
		data() {
			return {
				imgnum: 30,
				width: 100,
				height: 100,
				num: 0,
				max_width: 100,
				sum_num: 1,
				zys: 1,
				bordercolor: "popo-twoe-main hs-sty",
				qrcodefontcolor: "黑色",
				qrcodebordercolor: "黑色",
				qrcodecolor: "黑色",
				qrCodeimg: require("@/style/images/code-img.jpg"),
				fontcolor: "twor-mian lss-sty",
				qrcodefontset: [{
						name: "红色",
						classname: "hs-sty",
						color: "OrangeRed"
					},
					{
						name: "绿色",
						classname: "ls-sty",
						color: "Chartreuse"
					},
					{
						name: "黑色",
						classname: "lss-sty",
						color: "Black"
					},
					{
						name: "黄色",
						classname: "ys-sty",
						color: "Gold"
					},
					{
						name: "橙色",
						classname: "yss-sty",
						color: "DarkOrange"
					}
				],
				qrcodeborderset: [{
						name: "红色",
						classname: "hs-sty",
						color: "OrangeRed"
					},
					{
						name: "绿色",
						classname: "ls-sty",
						color: "Chartreuse"
					},
					{
						name: "黑色",
						classname: "lss-sty",
						color: "Black"
					},
					{
						name: "黄色",
						classname: "ys-sty",
						color: "Gold"
					},
					{
						name: "橙色",
						classname: "yss-sty",
						color: "DarkOrange"
					}
				],
				qrcodecolorset: [{
						name: "红色",
						classname: "hs-sty",
						color: "OrangeRed",
						img: "img_l_OrangeRed.png"
					},
					{
						name: "绿色",
						classname: "ls-sty",
						color: "Chartreuse",
						img: "img_l_Chartreuse.png"
					},
					{
						name: "黑色",
						classname: "lss-sty",
						color: "Black",
						img: "code-img.jpg"
					},
					{
						name: "黄色",
						classname: "ys-sty",
						color: "Gold",
						img: "img_l_Glod.png"
					},
					{
						name: "橙色",
						classname: "yss-sty",
						color: "DarkOrange",
						img: "img_l_DarkOrange.png"
					}
				],
				EWMYS: 1
			};
		}
	};
</script>
<style>
	.qccode-block {
		width: 820px;
		height: 1180px;
		margin: 10px auto;
		background: #fff;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: flex-start;
	}
</style>