<template>
  <el-table :data="tableData" border fit
    tooltip-effect="dark" style="width: 100%;">
    <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
    <el-table-column label="模块名称" align="center" prop="MBMC"></el-table-column>
    <el-table-column label="模块总分" align="center" prop="MBZF"></el-table-column>
    <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
            <el-button @click="editItem(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="delItem(scope.row)" type="text" size="small">删除</el-button>
        </template>
    </el-table-column>    
  </el-table>
</template>

<script>
export default {
    data(){
        return{
            tableData:[
                {pxh:1, MBMC:'生命厚度', MBZF:80}
            ]
        }
    },
    methods:{
        editItem(data){

        },
        delItem(data){

        }
    }

}
</script>

<style>

</style>