<template>
  <div>
    <!-- <div @click="downPFB()">下载报表</div> -->
        <div class="page-container mutilplePdf1">
            <div class="page-title">{{ pageTitle }}评分表</div>
            <p class="page-subtitle">{{ pageSubtitle }}</p>
            <div class="top-box">
                <div class="top-item">单位(公章):</div>
                <div class="top-item">校长(签字):</div>
                <div class="top-item">被考核人：{{tableRow.KHRXM}}</div>
            </div>    
            <!-- 表格 -->
         
            <el-table :data="tableRow?tableRow.JG:[]" border align="center" :span-method="arraySpanMethod">
                <el-table-column prop="khxm" align="center" label="考核项目">
                       <template slot-scope="scope">
                            <div :style="{textAlign:scope.$index == tableRow.JG.length-1?'left':'center'}">
                                {{scope.row.khxm}}
                            </div>
                        </template>
                </el-table-column>
                <el-table-column prop="fz" align="center" label="分值" width="70">
                </el-table-column>
                <el-table-column :label="`定量考核${sumObj.DLXKLable}%`" align="center">
                    <el-table-column prop="dldf" align="center" label="减分" width="120">
                        <el-table-column label="项目" prop="jfxm" align="center" >
                        </el-table-column>
                        <el-table-column prop="jffz" align="center" label="分值" width="50">
                        </el-table-column>
                    </el-table-column>
                <el-table-column label="减分原因"  align="center" prop="jfyy"> </el-table-column>
                <el-table-column label="定量考核最后得分" align="center" width="60" prop="dldf"> </el-table-column>
                </el-table-column>
                <el-table-column prop="date" align="center" :label="`定性考核${sumObj.DXKLable}%`" >
                    <el-table-column prop="date" align="center" label="分类考核" >
                        <el-table-column width="70" :label="`学生家长评(20%)`" >
                            <template slot-scope="scope">
                                <div :style="{textAlign:scope.$index == tableRow.JG.length-1?'left':'center'}">
                                    {{scope.row.xsjz}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="80" prop="jszhp" align="center" :label="`教师自评/互评\n(${sumObj.sum2BL}%)`" >
                        </el-table-column>
                        <el-table-column prop="khldxz" width="70" align="center" :label="`考核组评\n(${sumObj.sum3BL}%)`" >
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="dxdf" align="center" width="70" label="定性考核最后得分" >
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="zhdf"  align="center" width="70" label="职业道德考核最后得分" >
                
                </el-table-column>
                
            <!-- <div slot="append">
                <table  class="el-table__body my-table" style="width: 100%">
                    <tr ref="sum" class="el-table__row sum">
                    <td ref="sum_free_cell" class="el-table__cell">考核等级:{{tableRow.KHJG}}</td>
        
                    <td ref="sum_free_cell" class="el-table__cell">被考核人签字:</td>
                    </tr>
                </table>
            </div> -->
        </el-table>
     
        </div>
  </div>
</template>

<script>
export default {
    props:{
        tableRow: {
            type: Object,
            required: true,
            default: function() {
                return {};
            }
        },
        sumObj: {
            type: Object,
            required: true,
            default: function() {
                return {};
            }
        },
        pageSubtitle:String

    },
    
    data(){
        return{
            pageTitle: JSON.parse(localStorage.getItem('DevelopmentMB')).TTMC,
        }
    },
    methods:{
        arraySpanMethod({ row, column, rowIndex, columnIndex }){
                if (rowIndex == this.tableRow.JG.length-1) {
                    if(columnIndex === 0){
                        return [1,6]
                    }else if([1,2,3,4,5].includes(columnIndex)) return [0,0]
                    else if (columnIndex === 6) return [1,5]
                    else if([7,8,9,10,11].includes(columnIndex))return [0,0]
                }
            },
    }

}
</script>

<style lang="scss" scoped>
.mutilplePdf1{
    .el-table{
        border-color:black!important;
        border-right: 1px solid black!important;
        border-bottom: 1px solid black!important;
        ::v-deep .el-table__body-wrapper{
            .el-table__row{
                .el-table__cell{
                    color: black;
                    padding:  5px 0;
                    border-color:black ;
                }
            }
        }
        ::v-deep .el-table__header{
            .el-table__cell{
                padding:  5px 0;
                color: black;
                border-color:black ;
            }
        }
    }
    .my-table{
            tr{
              td:last-child{
                border-right: none;
              }  
            }
    }
   
}

.top-box{
        display: flex;
        justify-content: space-between;
        color: black;
    }
  .dialog{
    padding: 2px;
    min-width: 1200px;
    .el-dialog{
        padding: 2px;
    }
    
  }
  .page-container {
    width: 297mm;
    // height: 210mm;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
   
  .page-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .page-subtitle {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  </style>