<template>
  <!-- 二维码管理 -->
  <div class="qccode-list">
    <el-table :data="list" style="width: 800px">
      <el-table-column prop="CJRXM" label="姓名"> </el-table-column>
      <el-table-column prop="CJSJ" label="生成时间"> </el-table-column>
      <el-table-column prop="SL" label="数量"> </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-popconfirm
            title="此操作会将已生成的二维码数据同步删除!"
            @confirm="del(scope.row)"
          >
            <el-button size="mini" type="danger" slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagin-wz">
      <Pager :pagerInfo="pagerInfo" @changeIndex="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";

export default {
  name: "qrcodelist",
  components: {
    Pager
  },
  data() {
    return {
      userInfo: {},
      list: [],
      pagerInfo: {
        pageSize: 10,
        pageIndex: 0,
        totalCount: 10
      }
    };
  },
  mounted() {
    let userInfo = localStorage.getItem("userinfo");
    if (userInfo) this.userInfo = JSON.parse(userInfo);
    this.init();
  },
  methods: {
    async init() {
      await this.getList();
    },
    getList() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/qrcodegenerate/GetEWMSCXXList",
          {
            SSXX: this.userInfo.SSZZId,
            pagerInfo: this.pagerInfo,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.list = res.Target || [];
              this.pagerInfo.totalCount = res.PagerInfo.RowCount;
              resolve();
            } else reject();
          }
        );
      });
    },
    del(data) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/qrcodegenerate/DeleteEWMSCXX",
          {
            Id: data.Id,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.$message.success("删除成功");
              this.pagerInfo.pageIndex = 0;
              this.getList();
              resolve();
            } else reject();
          }
        );
      });
    },
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.qccode-list {
  width: 800px;
  .pagin-wz {
    padding: 10px;
  }
}
</style>
