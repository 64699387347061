var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"75%","center":"","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"tab-heng-box"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData}},[_c('div',{staticClass:"table-er-box"},[_c('el-form-item',{attrs:{"label":"等级名称：","rules":[
            {
              required: true,
              message: '请输入等级名称',
              trigger: 'change'
            }
          ],"prop":"DJMC","inline-message":true}},[_c('el-input',{staticStyle:{"width":"160px"},attrs:{"size":"small","placeholder":"请输入"},model:{value:(_vm.formData.DJMC),callback:function ($$v) {_vm.$set(_vm.formData, "DJMC", $$v)},expression:"formData.DJMC"}})],1)],1),_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('thead',[_c('tr',[(_vm.formData.DJLX === 2)?_c('td',[_vm._v("分数区间")]):_vm._e(),(_vm.formData.DJLX === 3)?_c('td',[_vm._v("百分比区间")]):_vm._e(),_c('td',[_vm._v("等级")]),(_vm.formData.DJLX === 1)?_c('td',[_vm._v("分数值")]):_vm._e(),(_vm.formData.DJLX !== 1)?_c('td',[_vm._v("等级描述")]):_vm._e(),_c('td',[_vm._v("操作")])])]),_c('tbody',_vm._l((_vm.formData.details),function(item,index){return _c('tr',{key:index},[(_vm.formData.DJLX === 2)?_c('td',[_c('div',{staticClass:"percent-block"},[_c('el-form-item',{staticClass:"jf-form-item",staticStyle:{"display":"inline-block"},attrs:{"rules":[
                    {
                      validator: _vm.validPersent,
                      trigger: 'blur',
                      index,
                      lx: 0
                    }
                  ],"prop":'details[' + index + '].ZDF'}},[_c('el-input-number',{staticStyle:{"width":"80px"},attrs:{"size":"small","min":-1000,"max":1500,"precision":2,"controls":false,"placeholder":"请输入"},model:{value:(item.ZDF),callback:function ($$v) {_vm.$set(item, "ZDF", $$v)},expression:"item.ZDF"}})],1),_c('span',{staticClass:"disilb-box-ll"},[_vm._v(" ≤ xx <")]),_c('el-form-item',{staticClass:"jf-form-item",staticStyle:{"display":"inline-block"},attrs:{"rules":[
                    {
                      validator: _vm.validPersent,
                      trigger: 'blur',
                      index,
                      lx: 1
                    }
                  ],"prop":'details[' + index + '].ZGF'}},[_c('el-input-number',{staticStyle:{"width":"80px"},attrs:{"size":"small","min":-1000,"max":1500,"precision":2,"controls":false,"placeholder":"请输入"},model:{value:(item.ZGF),callback:function ($$v) {_vm.$set(item, "ZGF", $$v)},expression:"item.ZGF"}})],1)],1)]):_vm._e(),(_vm.formData.DJLX === 3)?_c('td',[_c('div',{staticClass:"percent-block"},[_c('el-form-item',{staticClass:"jf-form-item",staticStyle:{"display":"inline-block"},attrs:{"prop":'details[' + index + '].ZDF',"rules":[
                    {
                      validator: _vm.validPersent,
                      trigger: 'blur',
                      index,
                      lx: 0
                    }
                  ]}},[_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"small","min":-1000,"max":1500,"precision":2,"controls":false,"placeholder":"百分比下限"},model:{value:(item.ZDF),callback:function ($$v) {_vm.$set(item, "ZDF", $$v)},expression:"item.ZDF"}})],1),_c('span',{staticClass:"disilb-box-ll"},[_vm._v(" ≤ xx <")]),_c('el-form-item',{staticClass:"jf-form-item",staticStyle:{"display":"inline-block"},attrs:{"prop":'details[' + index + '].ZGF',"rules":[
                    {
                      validator: _vm.validPersent,
                      trigger: 'blur',
                      index,
                      lx: 1
                    }
                  ]}},[_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"small","min":-1000,"max":1000,"precision":2,"controls":false,"placeholder":"百分比上限"},model:{value:(item.ZGF),callback:function ($$v) {_vm.$set(item, "ZGF", $$v)},expression:"item.ZGF"}})],1)],1)]):_vm._e(),_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"rules":[
                  {
                    required: true,
                    message: '请输入等级',
                    trigger: 'change'
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (
                        rule.validateData.filter(c => c === value).length > 1
                      ) {
                        return callback(new _vm.$window.Error(rule.message1));
                      } else return callback();
                    },
                    message: '已有相同的等级',
                    trigger: 'change',
                    validateData: _vm.formData.details
                      .filter(c => c)
                      .map(c => c.DJ)
                  }
                ],"prop":'details[' + index + '].DJ'}},[_c('el-input',{staticStyle:{"width":"160px"},attrs:{"size":"small","placeholder":"请输入"},model:{value:(item.DJ),callback:function ($$v) {_vm.$set(item, "DJ", $$v)},expression:"item.DJ"}})],1)],1),(_vm.formData.DJLX === 1)?_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"rules":[
                  {
                    required: true,
                    message: '请输入分数值',
                    trigger: 'blur'
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (
                        rule.validateData.filter(c => c === value).length > 1
                      ) {
                        return callback(new _vm.$window.Error(rule.message1));
                      } else return callback();
                    },
                    message: '已有相同的分数',
                    trigger: 'blur',
                    validateData: _vm.formData.details
                      .filter(c => c)
                      .map(c => c.ZDF)
                  }
                ],"prop":'details[' + index + '].ZDF'}},[_c('el-input-number',{staticStyle:{"width":"160px"},attrs:{"size":"small","min":-1000,"max":1000,"precision":2,"controls":false,"placeholder":"请输入"},model:{value:(item.ZDF),callback:function ($$v) {_vm.$set(item, "ZDF", $$v)},expression:"item.ZDF"}})],1)],1):_vm._e(),(_vm.formData.DJLX !== 1)?_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"prop":'details[' + index + '].MS'}},[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入"},model:{value:(item.MS),callback:function ($$v) {_vm.$set(item, "MS", $$v)},expression:"item.MS"}})],1)],1):_vm._e(),_c('td',[_c('div',{staticClass:"tab-wz-butt"},[_c('el-link',{staticClass:"c-red",staticStyle:{"margin-left":"10px"},attrs:{"underline":false},on:{"click":function($event){return _vm.delItem(index)}}},[_vm._v("删除")])],1)])])}),0)]),(_vm.formData.DJLX === 3)?_c('div',{staticClass:"table-er-box",staticStyle:{"display":"flex","justify-content":"center","margin-top":"10px"}},[_c('el-form-item',{attrs:{"prop":"LX"}},[_c('el-radio',{attrs:{"label":1},model:{value:(_vm.formData.LX),callback:function ($$v) {_vm.$set(_vm.formData, "LX", $$v)},expression:"formData.LX"}},[_vm._v("在年级范围内计算")]),_c('el-radio',{attrs:{"label":2},model:{value:(_vm.formData.LX),callback:function ($$v) {_vm.$set(_vm.formData, "LX", $$v)},expression:"formData.LX"}},[_vm._v("在班级范围内计算")])],1)],1):_vm._e()]),_c('div',{staticClass:"bigtit-add-box"},[_c('div',{staticClass:"tab-wz-buttl"},[_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":_vm.addItem}},[_vm._v("+ 新增")])],1)])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmDialog}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }