<template>
  <div class="persongrowthtree-container">
    <div
      class="persongrowthtree-item jf-center"
      style="justify-content: flex-start"
    >
      <div>背景图:</div>
      <div class="jf-center jf-center persongrowthtree-item-operations">
        <el-image
          style="width: 200px; height: 100px"
          fit="cover"
          :src="background ? background : ''"
          :preview-src-list="[background]"
        >
          <div
            slot="error"
            style="
              width: 100%;
              height: 100%;
              background: #f5f7fa;
              color: #909399;
              font-size: 30px;
            "
            class="jf-center"
          >
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <Upload @getResultUrl="saveBackGround" :mode="'split'" button>
          <el-button type="primary">更改图标</el-button>
        </Upload>
        <el-button style="color: red" type="text" @click="clearBackGround"
          >删除</el-button
        >
      </div>
    </div>
    <div v-for="(item, index) in sets" :key="index">
      <el-divider
        ><div v-if="index === 0">
          使用自定义图标:<el-switch
            v-model="isCustomIconSet"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch></div
      ></el-divider>
      <div class="persongrowthtree-item jf-center">
        <div>
          {{ index + 1 + "." }}
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="item.MS"
            maxlength="6"
            show-word-limit
            style="width:140px"
          >
          </el-input>
          起始分:<el-input-number
            :disabled="!customIconSet"
            style="width: 90px"
            v-model="item.FS"
            :min="0"
            :max="100000"
            :precision="2"
            :controls="false"
          ></el-input-number>
        </div>
        <div
          class="
            jf-center
            persongrowthtree-item-operations persongrowthtree-item-mutile
          "
        >
          <el-image
            :disabled="!customIconSet"
            style="width: 200px; height: 100px"
            :src="item.ZSLJ"
            fit="cover"
            :preview-src-list="[item.ZSLJ]"
          >
            <div
              slot="error"
              style="
                width: 100%;
                height: 100%;
                background: #f5f7fa;
                color: #909399;
                font-size: 30px;
              "
              class="jf-center"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <video
            ref="videoNode"
            class="video-js vjs-default-skin vjs-big-play-centered"
          >
            <source
              src="https://www.baidu.com/img/flexible/logo/pc/result.png"
              type="video/mp4"
            />
          </video> -->
          <Cropper
            :button="true"
            :item="{ index: index, field: 'ZSLJ' }"
            fixed
            :fixedNumber="[1, 1.132]"
            :cropWidth="100"
            :cropHeight="100"
            @getResultUrl="saveThum"
          >
            <el-button :disabled="!customIconSet" type="primary"
              >更改原图</el-button
            >
          </Cropper>
          <el-image
            :disabled="!customIconSet"
            style="width: 200px; height: 100px"
            fit="cover"
            :src="item.MZLJ"
            :preview-src-list="[item.MZLJ]"
          >
            <div
              slot="error"
              style="
                width: 100%;
                height: 100%;
                background: #f5f7fa;
                color: #909399;
                font-size: 30px;
              "
              class="jf-center"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <Cropper
            :button="true"
            :item="{ index: index, field: 'MZLJ' }"
            fixed
            :fixedNumber="[1, 1.132]"
            :cropWidth="100"
            :cropHeight="100"
            @getResultUrl="saveThum"
          >
            <el-button :disabled="!customIconSet" type="primary"
              >更改中图</el-button
            >
          </Cropper>

          <el-image
            :disabled="!customIconSet"
            style="width: 200px; height: 100px"
            fit="cover"
            :src="item.FJLJ"
            :preview-src-list="[item.FJLJ]"
          >
            <div
              slot="error"
              style="
                width: 100%;
                height: 100%;
                background: #f5f7fa;
                color: #909399;
                font-size: 30px;
              "
              class="jf-center"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <Cropper
            :button="true"
            :item="{ index: index, field: 'FJLJ' }"
            @getResultUrl="saveThum"
          >
            <el-button :disabled="!customIconSet" type="primary"
              >更改小图</el-button
            >
          </Cropper>

          <el-button
            @click="remove(index)"
            :disabled="!customIconSet"
            style="color: red"
            type="text"
            >删除</el-button
          >
        </div>
      </div>
    </div>
    <el-button
      :disabled="!customIconSet"
      icon="el-icon-plus"
      @click="add"
    ></el-button>
    <div class="jf-center">
      <el-button style="margin-top: 100px" type="primary" @click="submit"
        >保存设置</el-button
      >
    </div>
  </div>
</template>

<script>
import Cropper from "@/components/Cropper.vue";
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Upload,
    Cropper
  },
  name: "persongrowthtree",
  data() {
    return {
      isCustomIconSet: false,
      customIconSet: true,
      background: undefined,
      sets: [
        {
          FS: 0,
          ZSLJ: undefined,
          MZLJ: undefined,
          FJLJ: undefined,
          MS: undefined
        }
      ],
      loading: false
    };
  },
  props: {},
  created() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.initData();
  },
  mounted() {
    // this.initVideo();
  },
  methods: {
    initData() {
      this.getBackGround();
      this.getIconSets();
    },
    getBackGround() {
      this.$api.post(
        "/v1/growthtree/GetGrowthTreeBackGroudImage",
        {
          SSXX: this.user.SSZZId,
          CZSLX: 2,
          token: this.token
        },
        r => {
          let datas = r.Target;
          let data = datas.find(c => c.ZSLX === 2);
          if (data) {
            this.background = data.ZSLJ;
            this.isCustomIconSet = data.ZDYTB === 1 ? true : false;
          }
        }
      );
    },
    getIconSets() {
      this.$api.post(
        "/v1/growthtree/GetGrowthTreeList",
        {
          SSXX: this.user.SSZZId,
          CZSLX: 2,
          token: this.token
        },
        r => {
          let data = r.Target;
          if (data.length > 0) {
            this.sets = data.map(c => ({
              FS: c.FS,
              ZSLJ: c.ZSLJ,
              MZLJ: c.MZLJ,
              FJLJ: c.FJLJ,
              MS: c.MS
            }));
          }
        }
      );
    },
    add() {
      this.sets.push({
        FS: 0,
        ZSLJ: undefined,
        MZLJ: undefined,
        FJLJ: undefined,
        MS: undefined
      });
    },
    remove(index) {
      this.sets.splice(index, 1);
    },
    saveBackGround(path) {
      this.background = path;
    },
    clearBackGround() {
      this.background = undefined;
    },
    saveImg(path, obj) {
      this.sets[obj.index][obj.field] = path;
    },
    saveThum(path, obj) {
      this.sets[obj.index][obj.field] =
        this.user.FileServerApiPath + path.slice(path.indexOf("/"));
    },
    submit() {
      this.sets =
        this.sets.length === 1 &&
        this.sets[0] ===
          {
            FS: 0,
            ZSLJ: undefined,
            MZLJ: undefined,
            FJLJ: undefined,
            MS: undefined
          }
          ? []
          : this.sets;
      this.$api.post(
        "/v1/growthtree/SaveGrowthTreeIcons",
        {
          CJR: this.user.Id,
          SSXX: this.user.SSZZId,
          CZSLX: 2,
          ZDYTB: this.isCustomIconSet ? 1 : 2,
          ClientBackGround: this.background,
          TreeIcons: this.sets,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.sets =
              this.sets.length === 1 &&
              this.sets[0] ===
                {
                  FS: 0,
                  ZSLJ: undefined,
                  MZLJ: undefined,
                  FJLJ: undefined,
                  MS: undefined
                }
                ? [
                    {
                      FS: 0,
                      ZSLJ: undefined,
                      MZLJ: undefined,
                      FJLJ: undefined,
                      MS: undefined
                    }
                  ]
                : this.sets;
          }
        }
      );
    },
    initVideo() {
      //初始化视频方法
      this.$video(this.$refs.videoNode[0], {
        controls: true,
        preload: "auto",
        fluid: false,
        width: "200px",
        height: "100px"
      });
    }
  }
};
</script>
<style lang="css" scoped>
.persongrowthtree-container {
  font-size: 16px;
}
.persongrowthtree-item {
  justify-content: space-between;
}
.persongrowthtree-item-operations {
  width: 300px;
  justify-content: space-evenly;
}
.persongrowthtree-item-mutile {
  width: 600px;
}
</style>
