<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <div class="table-er-butt" style="border: none;">
                年级：
                <el-select
                  @change="handleTermChange"
                  style="width: 170px;"
                  v-model="ssxq"
                  placeholder="请选择学期"
                >
                  <el-option
                    v-for="xq in xqlist"
                    :label="xq.Name"
                    :key="xq.Id"
                    :value="xq.Id"
                  >
                  </el-option>
                </el-select>
                <div
                  class="table-er-butt"
                  style="margin-left: 1rem;"
                  @click="addsurvey()"
                >
                  <span class="table-er-buttspan">新增调查</span>
                </div>
              </div>
            </div>
            <el-table
              border
              :data="hdlist"
              highlight-current-row
              style="width: 100%;"
            >
              <el-table-column align="center" property="HDMC" label="名称">
                <template slot-scope="scope">
                  <el-button
                    @click="handleDetail(scope.row.Id)"
                    type="text"
                    class="c-guree"
                    >{{ scope.row.HDMC }}</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column align="center" label="起止日期">
                <template slot-scope="scope">
                  {{ scope.row.KSSJ | DateFormat("yyyy-MM-dd") }}~{{
                    scope.row.JSSJ | DateFormat("yyyy-MM-dd")
                  }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleResult(scope.row.Id)"
                    type="text"
                    class="c-guree"
                    >查看结果</el-button
                  >
                  <el-button
                    v-if="scope.row.CanEdit == 1"
                    @click="handleEdit(scope.row.Id)"
                    type="text"
                    style="color: blue;"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="scope.row.CanEdit == 1"
                    @click="handleDelete(scope.row.Id)"
                    style="color: red;"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagin-box">
              <div class="pagin-wz">
                <Pager
                  :pagerInfo="pagerInfo"
                  @changeIndex="handleCurrentChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  created() {
    // YhyTODO:获取所属学校
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.getterm();
    this.loaddata();
  },
  methods: {
    addsurvey: function() {
      this.$router.push({ name: "editsurvey" });
    },
    loaddata: function() {
      this.$api.post(
        "v1/survey/GetSurveyList",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.hdlist = [];
            this.hdlist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    getterm: function() {
      this.$api.post(
        "v1/accountmanage/GetTerm",
        {
          SchoolId: this.ssxx,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.xqlist = [];
            this.xqlist = r.Target;
          }
        }
      );
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    // 翻页查询
    handleTermChange(val) {
      this.pagerInfo.pageIndex = 1;
      this.ssxq = val;
      this.loaddata();
    },
    handleDetail: function(id) {
      this.$router.push({
        name: "surveydetail",
        params: { id: id, xqid: this.ssxq }
      });
    },
    handleResult: function(id) {
      this.$router.push({
        name: "surveyresultreport",
        params: { id: id, xqid: this.ssxq }
      });
    },
    handleEdit: function(id) {
      this.$router.push({ name: "editsurvey", params: { id: id } });
    },
    handleDelete: function(id) {
      this.$confirm("是否确定删除该满意度调查？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "v1/survey/DeleteSurvey",
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              Id: id,
              YHId: this.cjr,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "删除成功!"
                });
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "删除失败!"
                });
                this.loaddata();
              }
            }
          );
        })
        .catch(() => {});
    }
  },
  data() {
    return {
      hdlist: [],
      xqlist: [],
      ssxx: "",
      ssxq: "",
      token: "",
      editid: "",
      cjr: "",
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      }
    };
  }
};
</script>
