<template>
  <div class="ZHD_JXGY_REPORT">
    <el-tabs v-model="curTab" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in dataList"
        :key="item.Id"
        :label="item.Name || item.JHMC"
        :name="item.Id"
      >
        <el-tabs v-model="curGrade" @tab-click="clickGrade">
          <el-tab-pane
            v-for="grade in finalGrades"
            :key="grade.Id"
            :label="grade.Alias || grade.Name"
            :name="grade.Id"
          >
            <div v-if="finalCourse.length > 0">
              <el-button
                size="small"
                :class="{ curCourse: curCourse.id === course.id }"
                v-for="course in finalCourse"
                :key="course.id"
                @click="filterCourse(course)"
                >{{ course.name }}</el-button
              >
            </div>
            <el-table :data="reportList2" style="width: 100%">
              <el-table-column label="班级" width="180">
                <template slot-scope="scope">
                  <span>{{ scope.row.BJID | handleClassName(that) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="students" label="学生总数" width="180">
              </el-table-column>
              <el-table-column label="评价总次数">
                <template slot-scope="scope">
                  <span>{{ scope.row.YWCCI || scope.row.PJZL || 0 }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="FJNum" label="附件总数"> </el-table-column>
              <el-table-column label="未评学生数">
                <template slot-scope="scope">
                  <span>{{ scope.row.WWCXSS || scope.row.WPXSS }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="small"
                    @click="showDetail(scope.row)"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>

    <!-- 时间段列表 -->
    <el-dialog title="" :visible.sync="detailVisible" width="70%">
      <el-table :data="detailList" style="width: 100%">
        <el-table-column prop="SJDMC" label="文件名称" width="180">
        </el-table-column>
        <el-table-column prop="KSSJ" label="开始日期" width="180">
        </el-table-column>
        <el-table-column prop="JSSJ" label="结束日期"> </el-table-column>
        <el-table-column label="生成状态">
          <template slot-scope="scope">
            <span
              :style="{ color: scope.row.state === 1 ? '#14d089' : '#808080' }"
              >{{ scope.row.state === 1 ? "已生成" : "未生成" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="下载">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.state === 2"
              size="small"
              @click="handleDowload(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ZHD_JXGY",
  data() {
    return {
      that: this,
      userInfo: {},
      term: {},
      teacherClass: {}, //教师班级
      teacherCourse: [], //教师课程
      classCourse: [], //班级课表
      schoolCourse: [],
      schoolClass: [],
      schoolStudents: [],
      baseList: [], //家校共育和活动基础数据
      JXGY_list: [],
      parentActivities: [], //主活动数组
      parentActivity: {}, //主活动
      dataList: [], //处理后的家校共育和活动
      curTab: "",
      curGrade: "",
      curCourse: "",
      finalGrades: [], //最后显示的年级
      finalCourse: [], //最后显示的课程
      reportObj: {}, //报表对象
      reportList: [], //报表原始数据
      reportList2: [], //报表最终数据
      detailVisible: false,
      detailList: [],
      menuGlobal: false,
    };
  },
  async mounted() {
    let userInfo = localStorage.getItem("userinfo");
    if (userInfo) this.userInfo = JSON.parse(userInfo);
    let term = localStorage.getItem("curTerm");
    if (term) this.term = JSON.parse(term);

    this.handleMenuGlobal();
    await this.getSchoolStudent();
    await this.getSchoolCourse();
    await this.getSchoolClass();
    await this.getTeacherClass();
    this.teacherCourse = await this.getTeacherCourse({
      teacherId: this.userInfo.Id,
      type: 1,
    });
    // 获取报表设置的自定义活动和家校共育
    await this.getZHD_JXGY();
    // 获取家校共育详情
    await this.getJXGYinfo();
    let arr = [];
    // 筛选对应的家校共育详情
    this.baseList.forEach((item) => {
      let res = this.JXGY_list.find((it) => it.Id === item.HDId);
      if (res) arr.push(res);
    });
    this.dataList = [...this.dataList, ...arr];
    // 获取模块 找到对应的子活动 然后通过子活动的父id获取主活动详情
    let res2 = await this.getSchoolModule();
    let FJId_Arr = res2.reduce((pre, cur) => {
      if (!pre.includes(cur.FJId)) pre.push(cur.FJId);
      return pre;
    }, []);
    if (res2 && res2.length > 0) {
      await this.getActivityInfo(FJId_Arr);
      let arr2 = [];
      this.parentActivities.forEach((parent) => {
        let res = parent.EvaChildList.filter((child) =>
          this.baseList.some((it) => it.HDId === child.Id)
        );
        if (res) arr2 = [...arr2, ...res];
      });

      this.dataList = [...this.dataList, ...arr2];
    }
  },
  filters: {
    handleClassName(val, VM) {
      let name = "";
      if (val) {
        let grade = VM.schoolClass.find((item) => item.Id === VM.curGrade);
        let classObj = grade.ChidOrgList.find((item) => item.Id === val);
        name = classObj ? classObj.Alias || classObj.Name : "";
      }
      return name;
    },
  },
  methods: {
    // 判断菜单是否全局
    handleMenuGlobal() {
      if (this.userInfo.menu) {
        let menu = this.userInfo.menu.find(
          (item) => item.CodeOREId === "ReportCenter"
        );
        // 1.全局 2.局部
        if (menu && menu.QXDJLX === 1) this.menuGlobal = true;
      }
    },
    // 获取全校学生
    getSchoolStudent() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetOrgStudent",
          {
            SchoolOrgId: this.userInfo.SSZZId, //	是	string	组织机构ID/活动Id
            OrgType: 0, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: this.term.SSXN, //	是	string	学年
            SchoolId: this.userInfo.SSZZId, //	否	string	学校Id
            IsDelete: 0, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.schoolStudents = res.Target || [];
              resolve();
            } else reject();
          }
        );
      });
    },
    getSchoolCourse() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolCurriculum",
          {
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.schoolCourse = res.Target;
              resolve();
            } else reject();
          }
        );
      });
    },
    // 获取学校所有班级
    getSchoolClass() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolOrg",
          {
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            IsDelete: 2, //	是	int	1.表示需要获取已删除和禁用数据
            GradeId: "", //	是	string	根据年级Id获取年级和年级以下的组织机构（不能传学校Id不是优先获取是全校的住址机构）
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.schoolClass = res.Target;
              resolve();
            }
          }
        );
      });
    },
    // 获取老师所教班级
    getTeacherClass() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetTeaherManageClass",
          {
            TermId: this.term.Id,
            UserId: this.userInfo.Id, //	是	string	教师Id
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.teacherClass = res.Target;
              resolve();
            }
          }
        );
      });
    },
    // 获取老师所教课程
    getTeacherCourse({ teacherId, ClassId, type }) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetClassSchedule",
          {
            UserId: teacherId, //	否	string	教师Id
            ClassId: ClassId, //	否	string	班级Id
            ClassScheduleType: type, //	是	int	获取个人课程表1获取班级课程表2
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            TermId: this.TermId, //	是	string	学期Id
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              resolve(res.Target || {});
            }
          }
        );
      });
    },
    // 获取设置的家校共育和子活动
    getZHD_JXGY() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/customactivityset/GetSchoolZDYHDSZBAll", //获取设置页面所有被勾选的活动 SZLX: 1.扫码勾选 2.大屏展示 3.总榜单 4.报表
          {
            XQId: this.term.Id,
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              if (res.Target && res.Target.length > 0) {
                this.baseList = res.Target.filter((item) => item.SZLX === 4);
                if (this.baseList.length === 0) this.$message("未获取到数据");
              } else {
                this.$message("未获取到数据");
              }
              resolve();
            }
          }
        );
      });
    },
    // 获取家校共育详情
    getJXGYinfo() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/extracurricular/GetJXGYList",
          {
            SSXQ: this.term.Id,
            SSXX: this.userInfo.SSZZId,
            PagerInfo: {
              pageIndex: 0,
              pageSize: 1000,
            },
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.JXGY_list = res.Target || [];
              resolve();
            }
          }
        );
      });
    },
    // 获取学校所有模块信息
    getSchoolModule() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/accountmanage/GetSchoolModuleOrEva",
          {
            SchoolId: this.userInfo.SSZZId, // 是 string 学校Id
            TermYear: this.term.SSXN, // 是 string 当前学期学年（课辅活动使用）
            IsAll: 1, // 是 int 是否获取全部1全部2只获取学年课辅活动（2主要是获取历史学年的课辅活动）
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              let arr = [];
              this.baseList.forEach((item) => {
                let result = res.Target.find((it) => it.Id === item.HDId);
                if (result) arr.push(result);
              });
              resolve(arr);
            }
          }
        );
      });
    },
    // 获取活动详情
    getActivityInfo(EvaIds) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetEvaluationActivity",
          {
            EvaIds, //	是	字符数组	活动Id数组
            TermId: this.term.Id, //	是	string	当前学期Id
            TermBeginTime: this.term.BeginTime, //	是	datetime	学期开始时间
            TermEndTime: this.term.EndTime, //	是	datetime	学期结束时间
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.parentActivities = res.Target;
              resolve();
            }
          }
        );
      });
    },
    // 点击活动或者家校共育
    handleClick(tab) {
      this.finalCourse = [];
      this.curCourse = "";

      let res = this.dataList.find((item) => item.Id === tab.name);
      // 如果是家校共育 需要再请求家校共育详情
      if (res.JHMC) {
        this.$api.post(
          "v1/extracurricular/GetJXGYById",
          {
            Id: res.Id,
            SSXQ: this.term.Id,
            SSXX: this.userInfo.SSZZId,
            token: this.userInfo.Token,
          },
          (r) => {
            if (r.Code === 0) {
              this.filterGrade(r.Target?.KZNJ);
              this.curGrade = this.finalGrades[0]?.Id;
              this.clickGrade();
            }
          }
        );
      } else {
        let arr = this.schoolClass.reduce((pre, cur) => {
          if (res.GradeNumber.includes(cur.GradeNumber + "")) pre.push(cur.Id);
          return pre;
        }, []);
        this.filterGrade(arr.join());
        this.curGrade = this.finalGrades[0]?.Id;
        this.clickGrade();
      }
    },
    // 筛选当前活动应该显示的年级
    filterGrade(val) {
      if (this.menuGlobal) {
        let grades = this.schoolClass.reduce((pre, cur) => {
          let ChidOrgList = [];
          console.log(cur.ChidOrgList, "ChidOrgList");
          if (cur.ChidOrgList)
            ChidOrgList = JSON.parse(JSON.stringify(cur.ChidOrgList));

          pre.push({
            Id: cur.Id,
            Alias: cur.Alias,
            GradeNumber: cur.GradeNumber,
            Name: cur.Name,
            ChidOrgList,
          });
          return pre;
        }, []);
        // 活动和家校共育开展的年级
        let valArr = val.split(",");
        this.finalGrades = grades.filter((item) => valArr.includes(item.Id));
      } else {
        let mainClassId = "";
        if (this.teacherClass.TeacherClass)
          mainClassId = this.teacherClass.TeacherClass.split("-")[0];

        let gradeIds = [];
        let subClass = [];
        // 科任或班主任
        if (
          this.teacherClass &&
          this.teacherClass.LecturerClass &&
          this.teacherClass.LecturerClass.length > 0
        ) {
          gradeIds = this.teacherClass.LecturerClass.map(
            (item) => item.split("-")[0]
          );
          if (!gradeIds.find((item) => item === mainClassId))
            gradeIds.push(mainClassId);

          subClass = this.schoolClass.reduce((pre, cur) => {
            if (gradeIds && gradeIds.length > 0 && gradeIds.includes(cur.Id)) {
              let ChidOrgList = JSON.parse(
                JSON.stringify(
                  cur.ChidOrgList.filter(
                    (child) =>
                      this.teacherClass.LecturerClass.some(
                        (id) => child.Id === id
                      ) ||
                      (this.teacherClass.TeacherClass &&
                        this.teacherClass.TeacherClass === child.Id)
                  )
                )
              );
              // 如果班级是班主任班级 给个标识
              ChidOrgList.forEach((child) => {
                if (this.teacherClass.LecturerClass.includes(child.Id))
                  child.main = 2;
                if (mainClassId === child.Id) child.main = 1;
              });
              pre.push({
                Id: cur.Id,
                Alias: cur.Alias,
                GradeNumber: cur.GradeNumber,
                Name: cur.Name,
                ChidOrgList,
              });
            }
            return pre;
          }, []);
        }

        // 活动和家校共育开展的年级
        let valArr = val.split(",");
        this.finalGrades = subClass.filter((item) => valArr.includes(item.Id));
      }
    },
    async clickGrade() {
      let curTab = this.dataList.find((item) => item.Id === this.curTab);
      await this.getReportList();
      // // 家校共育
      if (curTab.JHMC) {
        // 家校共育没有学科勾选
      } else {
        let parent = this.parentActivities.find(
          (item) =>
            item.EvaChildList &&
            item.EvaChildList.find((it) => it.Id === curTab.Id)
        );

        if (
          this.reportList.length > 0 &&
          parent.AllCourse &&
          parent.AllCourse.length > 0
        ) {
          this.finalCourse = parent.AllCourse.reduce((pre, cur) => {
            let result = this.schoolCourse.find((item) => item.Id === cur);
            if (result)
              pre.push({
                name: result.Name,
                id: cur,
              });
            return pre;
          }, []);

          let classCourse = [];
          for (let i = 0; i < this.reportList.length; i++) {
            let course = await this.getCourseTable(this.reportList[i].BJID);
            if (course.ContentRowList) {
              let arr = [];
              arr = course.ContentRowList.reduce((pre, cur) => {
                if (cur.ContentList) {
                  cur.ContentList.forEach((it) => {
                    if (it.TeacherCourseList) {
                      pre = [...pre, ...it.TeacherCourseList];
                    }
                  });
                }
                return pre;
              }, []);

              classCourse.push({
                classId: this.reportList[i].BJID,
                courseList: arr,
              });
            }
          }
          this.parentActivity = parent;
          this.classCourse = classCourse;
          if (this.finalCourse[0]) {
            this.filterCourse(this.finalCourse[0]);
          }
        }
      }
    },
    // 获取班级课表
    getCourseTable(ClassId) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/cousertimetable/GetCourseTableList",
          {
            ClassId,
            Period: 1,
            SchoolId: this.userInfo.SSZZId,
            TermId: this.term.Id,
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) resolve(res.Target);
            else reject();
          }
        );
      });
    },
    // 筛选课程
    filterCourse(data) {
      this.curCourse = data;
      if (this.menuGlobal) {
        let res = this.classCourse.reduce((pre, cur) => {
          // 这个班课表有活动所包含的课表 这个班显示
          cur.courseList.forEach((course) => {
            if (
              course.TeacherCourseId === data.id &&
              !pre.some((item) => item === cur.classId)
            ) {
              pre.push(cur.classId);
            }
          });
          return pre;
        }, []);
        let classIds = this.finalGrades?.reduce((pre, cur) => {
          cur.ChidOrgList.forEach((item) => pre.push(item.Id));
          return pre;
        }, []);
        this.reportList2 = this.reportList.filter(
          (item) => res.includes(item.BJID) && classIds.includes(item.BJID)
        );
      } else {
        // 班级课表 活动课程 如果老师是这个班的班主任或者班级课表中包含了老师和活动的课程 那么这个班级就显示
        let res = this.classCourse.reduce((pre, cur) => {
          // 如果当前班级是班主任班级 并且这个班课表有活动所包含的课表 这个班显示
          if (
            this.teacherClass.TeacherClass &&
            this.teacherClass.TeacherClass.includes(cur.classId) &&
            cur.courseList.some((item) => item.TeacherCourseId === data.id) &&
            !pre.some((item) => item === cur.classId)
          ) {
            pre.push(cur.classId);
          } else {
            // 如果当前班级的课表包含老师和活动课程 这个班显示
            cur.courseList.forEach((course) => {
              if (
                course.TeacherName === this.userInfo.XSM &&
                course.TeacherCourseId === data.id &&
                !pre.some((item) => item === cur.classId)
              ) {
                pre.push(cur.classId);
              }
            });
          }
          return pre;
        }, []);
        let classIds = this.finalGrades?.reduce((pre, cur) => {
          cur.ChidOrgList.forEach((item) => pre.push(item.Id));
          return pre;
        }, []);
        this.reportList2 = this.reportList.filter(
          (item) => res.includes(item.BJID) && classIds.includes(item.BJID)
        );
      }
    },
    // 获取报表列表2
    getReportList() {
      let grade = this.schoolClass.find((item) => item.Id === this.curGrade);
      if (!grade) return this.$message.error("暂无可查看年级！");
      let HD = this.dataList.find((item) => item.Id === this.curTab);
      if (!HD.JHMC) {
        // 请求自定义活动相关的
        return new Promise((resolve, reject) => {
          this.$api.post(
            "v1/ReportCenter/GetActivityAnalysisReport_New",
            {
              endDate: HD.EndTime,
              hdId: this.curTab,
              nj: grade.GradeNumber,
              njId: grade.Id,
              xkId: this.curCourse?.id || "",
              pjlx: 0,
              ssxn: this.term.SSXN,
              ssxx: this.userInfo.SSZZId,
              startDate: HD.BeginTime,
              xqId: this.term.Id,
              xqlx: this.term.XQLX,
              token: this.userInfo.Token,
            },
            (res) => {
              if (res.Code === 0) {
                this.reportObj = res.Target || {};
                this.reportList = this.reportObj.ActivityAnalysisReport;
                let classIds = this.finalGrades?.reduce((pre, cur) => {
                  cur.ChidOrgList.forEach((item) => pre.push(item.Id));
                  return pre;
                }, []);
                console.log(classIds, "classIds");
                this.reportList2 = this.reportList.filter((item) =>
                  classIds.includes(item.BJID)
                );
                console.log(this.reportList2, "reportList2");
                this.reportList2.forEach((item) => {
                  let students = this.schoolStudents.filter(
                    (student) => student.ClassId === item.BJID
                  );
                  if (students && students.length > 0)
                    item.students = students.length;
                });
                resolve();
              } else reject();
            }
          );
        });
      } else {
        // 请求家校共育相关的
        return new Promise((resolve, reject) => {
          this.$api.post(
            "v1/ReportCenter/GetJXGYAnalysis",
            {
              ssxx: this.userInfo.SSZZId,
              ssxn: this.term.SSXN,
              njid: grade.Id,
              hdid: this.curTab,
              startDate: HD.KSRQ,
              endDate: HD.JFRQ,
              token: this.userInfo.Token,
            },
            (res) => {
              if (res.Code === 0) {
                this.reportObj = res.Target || {};
                this.reportList = this.reportObj.JXGYAnalysisReport;
                let classIds = this.finalGrades?.reduce((pre, cur) => {
                  cur.ChidOrgList.forEach((item) => pre.push(item.Id));
                  return pre;
                }, []);
                this.reportList2 = this.reportList.filter((item) =>
                  classIds.includes(item.BJID)
                );
                this.reportList2.forEach((item) => {
                  let students = this.schoolStudents.filter(
                    (student) => student.ClassId === item.BJID
                  );
                  if (students && students.length > 0)
                    item.students = students.length;
                });
                resolve();
              }
            }
          );
        });
      }
    },
    // 显示报表时间段
    showDetail(row) {
      let tab = this.baseList.find((item) => item.HDId === this.curTab);
      this.$api.post(
        "v1/dcbbjh/GetDCBBJHListBySZId",
        {
          SZId: tab.Id,
          token: this.userInfo.Token,
        },
        async (res) => {
          if (res.Code === 0) {
            let res2 = await this.getReportTimeList();
            let arr = res.Target.reduce((pre, cur) => {
              let detail = res2.find(
                (item) => item.BJId === row.BJID && cur.Id === item.JHId
              );
              if (detail) {
                cur.WJLJ = detail.WJLJ;
                cur.state = 1;
              } else {
                cur.state = 2;
              }
              pre.push(cur);
              return pre;
            }, []);
            arr.sort((a, b) => new Date(a.KSSJ) - new Date(b.KSSJ));
            arr.sort((a, b) => {
              if (a.KSSJ === b.KSSJ) {
                return new Date(a.JSSJ) - new Date(b.JSSJ);
              } else {
                return new Date(a.KSSJ) - new Date(b.KSSJ);
              }
            });
            this.detailList = arr;
            this.detailVisible = true;
          }
        }
      );
    },
    // 获取报表时间段生成数据
    getReportTimeList() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/dcwjjl/GetDCWJJLListByHDIdAndNJId",
          {
            NJId: this.curGrade,
            HDId: this.curTab,
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              resolve(res.Target || []);
            } else reject();
          }
        );
      });
    },
    // 下载
    handleDowload(row) {
      const aElement = document.createElement("a");
      aElement.setAttribute("download", row.SJDMC + "报表");
      aElement.href = row.WJLJ;
      aElement.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.curCourse {
  color: #14d089;
  background-color: #e8faf3;
}
</style>
