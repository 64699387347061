<template>
  <div class="feed-back">
    <!-- 顶部搜索栏 -->
    <div class="search-btn">
      <el-input
        v-model="search.str"
        style="width:200px;margin-right:10px"
        placeholder="请输入意见描述"
      ></el-input>
      <el-date-picker
        v-model="search.date"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <div class="state">
        <span>状态</span>
        <el-select v-model="search.state" placeholder="请选择">
          <el-option
            v-for="item in search.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button
        type="success"
        icon="el-icon-search"
        size="medium"
        @click="getData"
        >搜索</el-button
      >
    </div>

    <!-- 表格 -->
    <el-table
      height="600"
      :data="dataTable"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column prop="CJSJ" label="反馈时间" width="180">
      </el-table-column>
      <el-table-column prop="XXMC" label="学校" width="180"> </el-table-column>
      <el-table-column prop="FKZSF" label="反馈者身份" width="180">
      </el-table-column>
      <el-table-column prop="FKZMC" label="反馈人姓名" width="180">
      </el-table-column>
      <el-table-column prop="FKRBJ" label="反馈人班级" width="180">
      </el-table-column>
      <el-table-column prop="SJH" label="手机号" width="180"> </el-table-column>
      <el-table-column prop="CLZT" label="状态" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.CLZT === 0">未受理</span>
          <span v-else-if="scope.row.CLZT === 1">已受理</span>
          <span v-else-if="scope.row.CLZT === 2">已完成</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column prop="BZ" v-if="1 !== 1"> </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="showInfoDialog(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 页码 -->
    <div class="el-page">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="page.PageIndex"
        :page-sizes="[10, 20, 50]"
        :page-size="page.PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalCount"
      ></el-pagination>
    </div>
    <!-- 详情 -->
    <el-dialog
      title="详情"
      :visible.sync="info.visible"
      width="50%"
      :before-close="submit"
    >
      <el-form class="info-form" label-width="120px" :model="info.form">
        <el-form-item label="反馈时间">
          <el-input v-model="info.form.CJSJ" disabled></el-input>
        </el-form-item>
        <el-form-item label="学校">
          <el-input v-model="info.form.XXMC" disabled></el-input>
        </el-form-item>
        <el-form-item label="反馈者身份">
          <el-input v-model="info.form.FKZSF" disabled></el-input>
        </el-form-item>
        <el-form-item label="反馈者姓名">
          <el-input v-model="info.form.FKZMC" disabled></el-input>
        </el-form-item>
        <el-form-item label="反馈者班级">
          <el-input v-model="info.form.grade" disabled></el-input>
        </el-form-item>
        <el-form-item label="反馈者手机号">
          <el-input v-model="info.form.phone" disabled></el-input>
        </el-form-item>
        <el-form-item label="处理备注">
          <el-input
            type="textarea"
            v-model="info.form.BZ"
            @input="change($event)"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="form-btn">
        <div style="margin-right:10px;">
          <el-button @click="info.visible = false">返回</el-button>
        </div>
        <div v-if="this.CLZT === 1">
          <el-button type="success" @click="submit(1)" disabled>处理</el-button>
          <el-button type="primary" @click="submit(2)">完结</el-button>
        </div>
        <div v-else-if="this.CLZT === 2">
          <el-button type="success" @click="submit(1)" disabled>处理</el-button>
          <el-button type="primary" @click="submit(2)" disabled>完结</el-button>
        </div>
        <div v-else>
          <el-button type="success" @click="submit(1)">处理</el-button>
          <el-button type="primary" @click="submit(2)">完结</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from "@/style/js/Common.js";

export default {
  name: "Feedback",
  data() {
    return {
      // 搜索相关
      search: {
        str: "",
        date: [],
        state: "",
        options: [
          {
            label: "全部",
            value: null
          },
          {
            label: "未受理",
            value: 0
          },
          {
            label: "已处理",
            value: 1
          },
          {
            label: "已完成",
            value: 2
          }
        ]
      },
      dataTable: [],
      Id: "",
      CLZT: "",
      page: {
        PageIndex: 0,
        PageSize: 10,
        RowCount: 0,
        totalCount: 0
      },
      // 表单
      info: {
        visible: false,
        form: {
          Id: "",
          YJMS: "",
          CJSJ: "",
          XXMC: "",
          CLZT: "",
          FKZSF: "",
          FKZMC: "",
          BZ: "",
          SJH: "",
          FKRBJ: ""
        }
      }
    };
  },
  mounted() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.getData();
  },
  methods: {
    change(e) {
      //解决input输入框无法输入
      this.$forceUpdate();
    },
    getData() {
      this.tableDataIsLoading = true;
      var searchDate = this.search.date;
      var startDate = null;
      var endDate = null;
      if (
        searchDate !== null &&
        searchDate !== undefined &&
        searchDate.length > 0
      ) {
        startDate = formatDate(searchDate[0], "yyyy-MM-dd");
        endDate = formatDate(searchDate[1], "yyyy-MM-dd");
      }
      this.$api.post(
        "/v1/Preview/GetYJYFKBList",
        {
          token: this.token,
          pageIndex: this.page.PageIndex,
          pageSize: this.page.PageSize,
          Yjms: this.search.str || undefined,
          StartDate: startDate,
          EndDate: endDate,
          CLZT: this.search.state || undefined
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target;
            this.info.form = r.Target;
            this.page.totalCount = r.PagerInfo.RowCount;
            this.tableDataIsLoading = false;
          }
        },
        err => {
          this.tableDataIsLoading = false;
        }
      );
    },
    sizeChange(val) {
      this.page.PageSize = val;
      this.getData();
    },
    currentChange(val) {
      this.page.PageIndex = val;
      this.getData();
    },
    showInfoDialog(row) {
      Object.assign(this.info.form, row);
      this.info.visible = true;
      this.Id = this.info.form.Id;
      this.CLZT = this.info.form.CLZT;
      // if(this.CLZT !== 1){
      //   this.info.form.BZ = "";
      // }
    },
    submit(type) {
      if (type === 1) {
        if (this.info.form.BZ === "") {
          return this.$message({
            type: "info",
            message: "请填写处理原因",
            showClose: true
          });
        }

        this.$api.post(
          "v1/Preview/SubmitSuggestDetail",
          {
            YJYFKBId: this.Id,
            BZ: this.info.form.BZ
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "success",
                message: "操作成功",
                showClose: true
              });
            }
            this.page.PageIndex = 1;
            this.info.visible = false;
            this.getData();
          }
        );
      } else if (type === 2) {
        this.$api.get(
          "v1/Preview/CompleteSuggestDetail",
          {
            id: this.Id
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "success",
                message: "操作成功",
                showClose: true
              });
            }
            this.page.PageIndex = 1;
            this.info.visible = false;
            this.getData();
          }
        );
      } else {
        console.log(1, "取消");
        this.info.visible = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.feed-back {
  .search-btn {
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 1px 1px 2px 4px #f9f9f9;
    .el-date-picker {
      width: 250px;
    }
    .state {
      display: flex;
      align-items: center;
      margin-right: 10px;
      span:first-child {
        padding: 0 10px;
        font-weight: 700;
      }
      .el-select {
        width: 180px;
      }
    }
  }
  .el-table {
    box-shadow: 1px 1px 2px 2px #f3f3f3;
    margin-top: 20px;
  }
  .el-page {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }
  .form-btn {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
  }
}
</style>
