<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'medicalreport' + this.exportType">
					<img :src="xsinfo.ZP" :class="'studenticon' + this.exportType" />
					<div :class="'xsinfodiv' + this.exportType">
						<div :class="'xsinfotitle' + this.exportType">
							<font> {{ xsinfo.XM }}</font>
						</div>
						<div :class="'xsinfotitle' + this.exportType">
							<font> 性别：{{ xsinfo.XB }}</font>
							<font> 年龄：{{ xsinfo.NL }}岁</font>
							<font> 班级：{{ xsinfo.BJMC }}</font>
						</div>
					</div>
					<div class="clboth"></div>
				</div>
				<div class="clboth"></div>
				<div :class="'detailinfo' + this.exportType">
					<table :class="'tablestyle' + this.exportType">
						<tr class="theadstyle">
							<th :class="'theadfont' + this.exportType">
								体检项目
							</th>
							<th :class="'theadfont' + this.exportType">
								体检值
							</th>
						</tr>
						<tr v-for="(item, index) in tjdata" :key="index">
							<th :class="'titles' + exportType">
								{{ item.TypeName }}
							</th>
							<th :class="'values' + exportType">{{ item.TypeContent?item.TypeContent:"无" }}</th>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "体检报告"
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1
				},
				tjdata: []
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				// this.xsinfo.SSNJ = xsdata.SSNJ;
				// this.xsinfo.NJ = xsdata.NJ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				this.getxsreportdata();
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthreport/GetPhysicaExaminationReport", {
						ClassId: this.xsinfo.SSBJ,
						SchoolId: this.xsinfo.SSXX,
						TermId: this.xqid,
						SSXN: this.xsinfo.SSXN,
						XQLX: this.xsinfo.XQLX,
						SchoolCode: this.xsinfo.XXBM,
						StudentId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var info = r.Target;
							this.tjdata = [];
							if (
								info != null &&
								info != undefined &&
								info.PhysicaExaminationAPITypeList != null &&
								info.PhysicaExaminationAPITypeList != undefined &&
								info.PhysicaExaminationAPITypeList.length > 0
							) {
								this.tjdata = r.Target.PhysicaExaminationAPITypeList;
							}
						}
					}
				);
			}
		},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 165px;
		height: 165px;
		text-align: center;
		float: left;
		margin-top: 3rem;
		margin-left: 15px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 3rem;
		margin-left: 2rem;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/medicalreport_detail_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 800px;
		margin: 0px 20px;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/medicalreport_detail_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 560px;
		margin: 0px 20px;
	}

	.medicalreport1 {
		height: 200px;
		width: 756px;
		margin-top: 20px;
		margin-left: 40px;
		border-radius: 25px;
	}

	.medicalreport2 {
		height: 108px;
		width: 530px;
		margin-top: 20px;
		margin-left: 30px;
		border-radius: 15px;
	}

	.detailinfo1 {
		width: 720px;
		height: 680px;
		margin: 30px 40px 0px 40px;
		background: #ffffff;
		border-radius: 25px;
		padding-top: 10px;
	}

	.detailinfo2 {
		width: 500px;
		height: 490px;
		margin: 30px 30px 0px 30px;
		padding-top: 10px;
		background: #ffffff;
		border-radius: 15px;
	}

	.xsinfodiv1 {
		margin-top: 80px;
		margin-left: 20px;
		display: table-row;
		float: left;
	}

	.xsinfodiv2 {
		margin-top: 50px;
		margin-left: 15px;
		display: table-row;
		float: left;
	}

	.xsinfotitle1 {
		height: 40px;
		font-size: 25px;
		font-weight: bold;
		color: #ffffff;
		font-family: Microsoft YaHei;
	}

	.xsinfotitle2 {
		height: 30px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.tablestyle1 {
		width: 700px;
		height: 670px;
		margin: 0px 10px 0px 10px;
	}

	.tablestyle2 {
		width: 480px;
		height: 480px;
		margin: 0px 10px 0px 10px;
	}

	.theadstyle {
		background-color: #e5f7ff;
	}

	.theadfont1 {
		height: 45px;
		font-size: 35px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #943afe;
		line-height: 85px;
	}

	.theadfont2 {
		height: 35px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #943afe;
		line-height: 65px;
	}

	.titles1 {
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	}

	.titles2 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	}

	.values1 {
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
	}

	.values2 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
	}
</style>