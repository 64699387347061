<template>
  <el-dialog
    top="1vh"
    width="90%"
    ref="dialog"
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
  >
    <div class="cont-whole-box">
      <div style="padding: 30px;">
        <div class="operate-box">
          <el-button @click="addItem">新增对象</el-button>
          <el-button @click="showHPdialog" v-show="payLoad.type === 2"
            >互评次数</el-button
          >
          <div class="sel-box">
            <div class="czq-name">参与方式:</div>
            <el-select
              :style="payLoad.type === 2 ? 'width:200px' : ''"
              v-model="objectJoinType"
              clearable
              @change="changeTotalCount"
              placeholder
            >
              <el-option
                v-for="item in EvaluationObjectJoinType"
                :key="item.Key"
                :label="item.Value"
                :value="item.Key"
              ></el-option>
            </el-select>
          </div>

          <div class="sel-box">
            <div class="czq-name">评价方式:</div>
            <el-select
              :style="payLoad.type === 2 ? 'width:200px' : ''"
              v-model="evaluationMethod"
              clearable
              @change="changeTotalCount"
              placeholder
            >
              <el-option
                v-for="item in EvaluationMethod2"
                :key="item.Key"
                :label="item.Value"
                :value="item.Key"
              ></el-option>
            </el-select>
          </div>

          <div class="sel-box">
            <div class="czq-name">状态:</div>
            <el-select
              :style="payLoad.type === 2 ? 'width:200px' : ''"
              v-model="status"
              @change="changeTotalCount"
              placeholder
            >
              <el-option
                v-for="item in filter_baseInfo.SYZT"
                :key="item.Key"
                :label="item.Value"
                :value="item.Key"
              ></el-option>
            </el-select>
          </div>
        </div>
        <!--表格区开始-->
        <Table
          :isLoading="tableDataIsLoading"
          :tableData="
            tableData
              .filter(
                c =>
                  (c.PJCYDXLB == objectJoinType || objectJoinType == 0) &&
                  (c.PJFS == evaluationMethod || evaluationMethod == 0) &&
                  c.SYZT === status
              )
              .slice(
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                  pagerInfo.pageSize
              )
          "
          @editItem="editItem"
          @setIndex="openSetIndex"
          @delItem="delItem"
        />
        <!--表格区结束-->
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
      </div>
      <FormDialog4
        :baseInfo="baseInfo"
        :jgOption="JGoption"
        :form="form"
        :schoolGrades="schoolGrades"
        :visible="formVisiable4"
        @closeDialog="formCloseDialog"
        @confirmDialog="formConfirmDialog"
      />
      <FormDialog2
        :baseInfo="baseInfo"
        :form="form"
        :schoolGrades="schoolGrades"
        :jgOption="JGoption"
        :HPXZCSdata="HPdialog"
        :visible="formVisiable2"
        @closeDialog="formCloseDialog"
        @confirmDialog="formConfirmDialog"
      />
      <FormDialog1
        :baseInfo="baseInfo"
        :form="form"
        :schoolGrades="schoolGrades"
        :jgOption="JGoption"
        :visible="formVisiable1"
        @closeDialog="formCloseDialog"
        @confirmDialog="formConfirmDialog"
      />
      <el-dialog
        append-to-body
        title="次数限制"
        :visible.sync="HPdialog.visible"
        width="40%"
      >
        <div>
          <el-radio-group v-model="HPdialog.type" @change="HPtypeChange">
            <el-radio :label="0">每人1次</el-radio>
            <el-radio :label="1"
              >限制被评人次
              <el-input-number
                size="small"
                :min="2"
                :max="9999"
                :precision="0"
                v-model="HPdialog.number"
              />
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="payLoad.currentActivity.BPJDXLX === 1">
          <el-radio-group style="margin-top:20px" v-model="HPdialog.HPFW">
            <el-radio
              v-for="item in HPdialog.HPFWoption"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="HPdialog.visible = false">取 消</el-button>
          <el-button type="primary" @click="sumitHPnumber">确 定</el-button>
        </span>
      </el-dialog>
      <SetIndex
        :title="setIndexTitle"
        :treeDataBase="indexData[PJFS - 1]"
        :checkedData="indexSetData"
        :visible="indexVisiable"
        :evaluationObj="evaluationObj"
        :setIndexType="setIndexType"
        @canceletIndex="canceletIndex"
        @saveSetIndex="saveSetIndex"
      />
    </div>
  </el-dialog>
</template>

<script>
import Table from './Table.vue'
import FormDialog4 from './FormDialog4.vue'
import FormDialog2 from './FormDialog2.vue'
import FormDialog1 from './FormDialog1.vue'
import SetIndex from './SetIndex.vue'
import Pager from '@/components/Pager.vue'
export default {
  props: {
    dialogVisible: Boolean,
    dialogTitle: String,
    activityId: String
  },
  components: {
    Pager,
    Table,
    FormDialog4,
    FormDialog2,
    FormDialog1,
    SetIndex
  },
  inject: ['payLoad'],
  data() {
    return {
      setIndexType: 0,
      setIndexTitle: '',
      PJFS: 1,
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: '',
      user: {},
      schoolId: '',
      tableDataIsLoading: false,
      tableData: [],
      baseInfo: {
        GradeList: []
      },
      filter_baseInfo: {},
      form: {},
      formVisiable4: false,
      formVisiable2: false,
      formVisiable1: false,
      indexVisiable: false,
      objectJoinType: '',
      evaluationMethod: '',
      status: 1,
      evaluationObj: '',
      indexData: [],
      objectId: '',
      indexSetData: [],
      // 互评框相关
      HPdialog: {
        visible: false,
        type: 0,
        number: 2, //互评限制次数
        term: '', //学期
        HPFW: 1, //班级内互评
        //班级内互评选项
        HPFWoption: [
          {
            label: '班级互评',
            value: 1
          },
          {
            label: '班级小组互评',
            value: 2
          }
        ]
      },
      // 机构选项
      JGoption: [],
      EvaluationObjectJoinType: [],
      EvaluationMethod2: [],
      schoolGrades: [] //年级
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.token = this.$store.state.token
        const user = JSON.parse(localStorage.getItem('userinfo'))
        if (user) {
          if (user.SSZZId) {
            this.schoolId = user.SSZZId
          }
          this.user = user
        }
        this.getBaseData()
        this.getData()
        this.getSchoolGrade()
        this.handl
      }
    }
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val
    },
    formCloseDialog() {
      this.formVisiable4 = false
      this.formVisiable2 = false
      this.formVisiable1 = false
    },
    formConfirmDialog(data) {
      this.submit(data)
    },
    getBaseData() {
      this.baseInfo.GradeList = []
      this.$api.post(
        '/v1/evaluationobject/GetEvaluationObjectBaseInfo',
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            // 学科
            r.Target.CourseList &&
              r.Target.CourseList.forEach(item => {
                item.frequency = 0 //被评人的评价次数
              })
            // 机构
            // r.Target.GradeList.forEach(item => {
            //   item.frequency =0//被评人的评价次数
            // });
            // 角色
            r.Target.RoleList &&
              r.Target.RoleList.forEach(item => {
                item.frequency = 0 //被评人的评价次数
              })
            // 年级
            r.Target.GradeList &&
              r.Target.GradeList.forEach(item => {
                item.frequency = 0 //被评人的评价次数
              })
            // 请求的范围选择中有一个奇怪的数据 剔除
            r.Target.EvaluationObjectJoinType =
              r.Target.EvaluationObjectJoinType &&
              r.Target.EvaluationObjectJoinType.filter(
                item => item.Value !== '未知'
              )
            this.EvaluationObjectJoinType = JSON.parse(
              JSON.stringify(r.Target.EvaluationObjectJoinType)
            )
            this.EvaluationObjectJoinType.unshift({
              Key: 0,
              Value: '全部'
            })
            this.EvaluationMethod2 = JSON.parse(
              JSON.stringify(r.Target.EvaluationMethod)
            )
            this.EvaluationMethod2.unshift({
              Key: 0,
              Value: '全部'
            })
            this.baseInfo = r.Target
            this.handleGradeBy_SSXD()
            this.handleCourseBy_SSXD()
            this.filter_baseInfo = JSON.parse(JSON.stringify(this.baseInfo))
          }
        }
      )
    },
    getData() {
      this.tableDataIsLoading = true
      this.$api.post(
        '/v1/evaluationobject/GetEvaluationObjectList',
        {
          token: this.token,
          SchoolId: this.schoolId,
          EvaId: this.activityId,
          HDXS: this.payLoad.type
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target||[]
            this.pagerInfo.totalCount = this.tableData.length
          }
          this.tableDataIsLoading = false
        }
      )
    },
    submit(data) {
      data.CJR = this.user.XSM
      data.SSXX = this.schoolId
      let { Id: UserId, DLM: UserName } = JSON.parse(
        localStorage.getItem('userinfo')
      )
      this.$api.post(
        '/v1/evaluationobject/EditEvaluationObject',
        {
          EvaId: this.payLoad.currentActivity.Id, // 添加活动id
          UserId,
          UserName,
          token: this.token,
          EvaluationObjectInfo: data,
          SchoolId: this.user.SSZZId
        },
        r => {
          if (r.Code === 0) {
            this.formVisiable4 = false
            this.formVisiable2 = false
            this.formVisiable1 = false
            this.$message({
              type: 'success',
              message: r.Message
            })
            this.getData()
          }
        }
      )
    },
    sumitHPnumber() {
      let userInfo = JSON.parse(localStorage.getItem('userinfo'))
      this.$api.post(
        'v1/evastage/EditHPCS',
        {
          EvaId: this.payLoad.currentActivity.Id,
          HPCS: this.HPdialog.type === 0 ? 0 : this.HPdialog.number,
          token: userInfo.Token,
          TermYear: this.HPdialog.term.SSXN,
          TermType: this.HPdialog.term.XQLX,
          HPFW:
            this.payLoad.currentActivity.BPJDXLX === 1
              ? this.HPdialog.HPFW
              : null,
          SchoolId: userInfo.SSZZId
        },
        res => {
          if (res.Code === 0) {
            this.$emit('refresh')
            this.payLoad.currentActivity.HPCS =
              this.HPdialog.type === 0 ? 0 : this.HPdialog.number
            this.payLoad.currentActivity.HPFW = this.HPdialog.HPFW
            this.HPdialog.visible = false
          }
        }
      )
    },
    editItem(data) {
      this.form = JSON.parse(JSON.stringify(data))
      this.getJGtree()
    },
    delItem(data) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.post(
            '/v1/evaluationobject/DeleteEvaluationObject',
            {
              token: this.token,
              Id: data.Id
            },
            r => {
              if (r.Code === 0) {
                // let result = r.Target;
                this.$message({
                  type: 'success',
                  message: r.Message
                })
                this.tableData.splice(
                  this.tableData.findIndex(c => c.Id === data.Id),
                  1
                )
                this.pagerInfo.totalCount = this.tableData.length
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    addItem() {
      this.form = {}
      this.getJGtree()
    },
    // 获取通用指标
    getIndexData4() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/schoolindex/GetTreeAllData',
          {
            LX: this.payLoad.currentActivity.BPJDXLX === 2 ? 2 : 1,
            token: this.user.Token,
            SchoolId: this.user.SSZZId
          },
          res => {
            if (res.Code === 0) resolve(res.Target || [])
          }
        )
      })
    },
    handleIndex4(Index) {
      Index.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.children = item.children.filter(el => {
            if (item.pjfs !== 3) {
              // 其它来源的指标
              el.Id = el.value
              el.ZBMC = el.label
              return true
            } else return false
          })
          if (item.children && item.children.length > 0)
            this.handleIndex4(item.children)
        }
      })
    },
    async getIndexData(type) {
      this.$set(this, 'indexData', [])

      if (type === 1) {
        //通用
        let res = await this.getIndexData4()
        if (res && res.length > 0) {
          res = res.filter(item => {
            if (item.pjfs !== 3) {
              // 其它来源的指标
              item.Id = item.value
              item.ZBMC = item.label
              return true
            } else return false
          })
          this.handleIndex4(res)
        }
        this.indexData[0] = res
      }
      // 星星指标
      type === 2 && (await this.getStars())

      // 问卷
      type === 3 && (await this.getQuestions())

      this.indexVisiable = true
    },
    getStars() {
      // 被评人为学生和班级都为1 老师为2
      let LX = this.payLoad.currentActivity.BPJDXLX === 2 ? 2 : 1
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/starsindex/GetStarsIndex',
          {
            LX,
            token: this.token,
            SchoolId: this.schoolId
          },
          r => {
            if (r.Code === 0) {
              let { StarsIndexList, TreeDataList } = r.Target

              StarsIndexList &&
                StarsIndexList.forEach(item => {
                  TreeDataList &&
                    TreeDataList.forEach(el => {
                      if (item.Id === el.value) {
                        el.BZ = item.BZ
                      }
                      if (el.children && el.children.length > 0) {
                        el.children.forEach(it => {
                          if (item.Id == it.value) {
                            it.BZ = item.BZ
                          }
                        })
                      }
                    })
                })

              this.indexData[1] = TreeDataList || []
              resolve()
              // this.indexData=this.indexData.filter(c=>c.children.length>0)
            } else reject(r)
          },
          err => reject(err)
        )
      })
    },
    getQuestions() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/questionnaire/GetQuestionnaireList',
          {
            LX: this.payLoad.currentActivity.BPJDXLX === 2 ? 2 : 1,
            token: this.token,
            SchoolId: this.schoolId
          },
          r => {
            if (r.Code === 0) {
              const result = r.Target || []
              let wjList = []
              for (
                let i = 0;
                i < result.filter(c => c.SYZT === 1).length;
                i++
              ) {
                wjList.push({
                  value: result[i].Id,
                  parentCode: '',
                  label: result[i].WJMC,
                  children: [],
                  CourseIds: result[i].CourseIds
                })
              }
              // 老师评学生学科参与 那么对象就不能勾选有学科的问卷   老师评老师 不能选择有学科的问卷
              if (
                (this.payLoad.currentActivity.BPJDXLX === 1 &&
                  this.payLoad.currentActivity.HDCPR === 1 &&
                  this.payLoad.currentActivity.JZKBLX === 3) ||
                (this.payLoad.currentActivity.BPJDXLX === 2 &&
                  this.payLoad.currentActivity.HDCPR === 1)
              ) {
                wjList =
                  wjList &&
                  wjList.filter(
                    item => !item.CourseIds || item.CourseIds.length === 0
                  )
              }
              this.indexData[2] = wjList
              resolve()
              // this.indexData=this.indexData.filter(c=>c.children.length>0)
            } else reject(r)
          },
          err => reject(err)
        )
      })
    },
    openSetIndex(data) {
      if (data.PJFS === 1) {
        this.setIndexTitle = '设置通用指标'
        this.setIndexType = 1
      } else if (data.PJFS === 2) {
        this.setIndexTitle = '设置星星指标'
        this.setIndexType = 2
      } else if (data.PJFS === 3) {
        this.setIndexTitle = '设置问卷'
        this.setIndexType = 3
      }
      this.PJFS = data.PJFS
      this.objectId = data.Id
      this.indexSetData = data.EvaluationObjectIndexList
      this.getIndexData(this.setIndexType)
    },
    canceletIndex() {
      this.indexVisiable = false
    },
    saveSetIndex(indexList) {
      console.log(indexList, 'indexlis')
      this.indexVisiable = false
      const dataList = []
      const newindexData = []
      for (const index of indexList) {
        dataList.push({
          ZBId: index.value,
          PJCYDXId: this.objectId,
          BXSQD: index.BXSQD
        })
        newindexData.push(index.value)
      }
      const user = JSON.parse(localStorage.getItem('userinfo'))
      this.$api.post(
        '/v1/evaluationobject/SetIndexForEvaluationObject',
        {
          token: this.token,
          SetIndexList: dataList,
          UserId: user.Id,
          UserName: user.XSM,
          IndexType: this.setIndexType,
          SchoolId: this.schoolId,
          EvaId: this.activityId
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: 'success',
              message: r.Message
            })
            const result = this.tableData.find(c => c.Id === this.objectId)
            if (result) {
              result.EvaluationObjectIndexList = newindexData
              this.getData()
            }
          }
        }
      )
    },
    changeTotalCount() {
      this.pagerInfo.totalCount =
        this.tableData &&
        this.tableData.filter(
          c =>
            (c.PJCYDXLB == this.objectJoinType || this.objectJoinType == 0) &&
            (c.PJFS == this.evaluationMethod || this.evaluationMethod == 0) &&
            c.SYZT === this.status
        ).length
    },
    cancel() {
      this.$emit('closeObjectDialog')
    },
    showHPdialog() {
      let { HPCS, HPFW } = this.payLoad.currentActivity
      if (HPCS > 0) {
        this.HPdialog.type = 1
        this.HPdialog.number = HPCS
      } else {
        this.HPdialog.type = 0
        this.HPdialog.number = ''
      }
      this.HPdialog.HPFW = HPFW || 1
      this.HPdialog.visible = true
      this.getTerm()
    },
    // 互评值变化
    HPtypeChange(val) {
      if (val === 1) this.HPdialog.number = 2
      else this.HPdialog.number = undefined
    },
    // 获取学期
    getTerm() {
      let { SSZZId: SchoolId, Token: token } = JSON.parse(
        localStorage.getItem('userinfo')
      )

      this.$api.post(
        '/v1/accountmanage/GetTerm',
        {
          SchoolId,
          token
        },
        res => {
          if (res.Code === 0) {
            let ssxq = localStorage.getItem('currentterm')
            this.HPdialog.term =
              res.Target && res.Target.filter(item => item.Id === ssxq)
          }
        }
      )
    },
    // 获取机构
    getJGtree() {
      let { SSZZId: SchoolId, Token: token } = JSON.parse(
        localStorage.getItem('userinfo')
      )
      this.$api.post(
        'v1/schoolteaorg/GetXXJGTree',
        {
          ParentId: '0',
          SchoolId,
          token
        },
        res => {
          if (res.Code === 0) {
            this.JGoption = res.Target
            this[`formVisiable${this.payLoad.type}`] = true
          }
        }
      )
    },
    // 获取学校年级
    getSchoolGrade() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          {
            SchoolId: this.user.SSZZId, //	是	string	学校Id
            IsDelete: 2, //	是	int	1.表示需要获取已删除和禁用数据
            token: this.user.Token
          },
          res => {
            if (res.Code === 0 && res.Target) {
              this.schoolGrades = res.Target
              resolve()
            } else reject()
          }
        )
      })
    },
    // 根据学段筛选年级
    handleGradeBy_SSXD() {
      let SSXD = this.payLoad.currentActivity.SSXD || []
      if (SSXD.includes(1) && SSXD.includes(2)) {
        this.baseInfo.GradeList = this.baseInfo.GradeList.filter(grade =>
          [1, 2].includes(grade.MultiSchool)
        )
      } else if (SSXD.includes(1) && !SSXD.includes(2)) {
        this.baseInfo.GradeList = this.baseInfo.GradeList.filter(
          grade => grade.MultiSchool === 1
        )
      } else if (!SSXD.includes(1) && SSXD.includes(2)) {
        this.baseInfo.GradeList = this.baseInfo.GradeList.filter(
          grade => grade.MultiSchool === 2
        )
      } else {
        // 默认小学
        this.baseInfo.GradeList = this.baseInfo.GradeList.filter(
          grade => grade.MultiSchool === 1
        )
      }
    },
    // 根据学段筛选学科
    handleCourseBy_SSXD() {
      let SSXD = this.payLoad.currentActivity.SSXD || []
      if (SSXD.includes(1) && SSXD.includes(2)) {
        this.baseInfo.CourseList = this.baseInfo.CourseList.filter(grade =>
          [1, 2].includes(grade.MultiSchool)
        )
      } else if (SSXD.includes(1) && !SSXD.includes(2)) {
        this.baseInfo.CourseList = this.baseInfo.CourseList.filter(
          grade => grade.MultiSchool === 1
        )
      } else if (!SSXD.includes(1) && SSXD.includes(2)) {
        this.baseInfo.CourseList = this.baseInfo.CourseList.filter(
          grade => grade.MultiSchool === 2
        )
      } else {
        // 默认小学
        this.baseInfo.CourseList = this.baseInfo.CourseList.filter(
          grade => grade.MultiSchool === 1
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-input-number {
  width: 100px;
  ::v-deep .el-input-number__increase {
    display: none;
  }
  ::v-deep .el-input-number__decrease {
    display: none;
  }
  ::v-deep .el-input__inner {
    padding: 0;
  }
}
.sel-box {
  display: inline;
}
</style>
