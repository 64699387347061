<template>
  <div>
    <el-button v-if="!IsEdit" type="primary" size="mini" @click="addItem"
      >新增</el-button
    >
    <el-table
      v-if="!IsEdit"
      :data="this.dataList"
      tooltip-effect="dark"
      style="width: 100%;"
    >
      <el-table-column prop="MBNR" label="评价语"></el-table-column>
      <el-table-column prop="IsActive" label="状态"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button
            v-if="scope.row.SYZT == 1"
            @click="
              scope.row.SYZT = 2;
              scope.row.IsActive = '禁用';
            "
            type="text"
            size="small"
            >禁用</el-button
          >
          <el-button
            v-else
            @click="
              scope.row.SYZT = 1;
              scope.row.IsActive = '启用';
            "
            type="text"
            size="small"
            >启用</el-button
          >
          <el-button @click="delItem(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-form v-if="IsEdit" ref="submitForm" :model="submitForm">
      <el-form-item label="评价语">
        <el-input v-model="submitForm.MBNR"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="saveForm">保存</el-button>
        <el-button size="mini" @click="IsEdit = false">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      form: {
        Id: 0,
        HDId: "",
        SSXX: "",
        CJR: "",
        CJSJ: "",
        MBNR: "",
        SFSC: 2,
        SYZT: 1
      },
      addId: 0,
      submitForm: {},
      IsEdit: false
    };
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    Show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClose() {},
    saveForm() {
      this.submitForm.IsActive = this.submitForm.SYZT === 1 ? "启用" : "禁用";
      if (this.submitForm.Id === 0) {
        this.submitForm.Id = this.addId + 1;
        this.submitForm.CJSJ = new Date().toLocaleDateString();
        this.dataList.push(this.submitForm);
      } else {
        const index = this.dataList.findIndex(c => c.Id === this.submitForm.Id);
        if (this.dataList[index] !== undefined) {
          this.dataList[index] = this.submitForm;
        }
      }
      this.IsEdit = false;
      // this.$emit("saveCommentTemplate", this.dataList);
    },
    addItem() {
      this.submitForm = JSON.parse(JSON.stringify(this.form));
      this.IsEdit = true;
    },
    editItem(data) {
      this.submitForm = data;
      this.IsEdit = true;
    },
    delItem(data) {
      const index = this.dataList.findIndex(c => c.Id === data.Id);
      if (this.dataList[index] !== undefined) {
        this.dataList.splice(index, 1);
      }
    }
  },
  watch: {
    Show: function(newVal) {
      if (newVal) {
        this.dataList = JSON.parse(JSON.stringify(this.tableData));
        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].IsActive =
            this.dataList[i].SYZT === 1 ? "启用" : "禁用";
        }
      } else {
        this.IsEdit = false;
        this.$emit("saveCommentTemplate", this.dataList);
      }
    }
  },
  created: function() {
    this.dataList = JSON.parse(JSON.stringify(this.tableData));
    this.IsEdit = false;
    this.addId = 0;
    for (let i = 0; i < this.dataList.length; i++) {
      this.dataList[i].IsActive = this.dataList[i].SYZT === 1 ? "启用" : "禁用";
      if (this.addId < this.dataList[i].Id) {
        this.addId = this.dataList[i].Id;
      }
    }
  }
};
</script>
