<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="margno-t">
        <div class="table-er-box">
          <!--TODO:按钮-->
          <el-button plain icon="el-icon-edit" @click="editItem({})"
            >新增</el-button
          >
        </div>
        <!--TODO:内容-->
        <Table
          :tableData="
            dataTable
              .slice()
              .sort((a, b) => {
                return a.PXH - b.PXH;
              })
              .slice(
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                  pagerInfo.pageSize
              )
          "
          v-loading="isLoading"
          :user="user"
          @editItem="editItem"
          @delItem="delItem"
        />
        <div class="pagin-box">
          <div class="pagin-wz">
            <!--分页-->
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
      </div>
    </div>
    <Form
      :data="formData"
      :visible="formVisible"
      :title="formTitle"
      :shapes="shapes"
      :user="user"
      @closeDialog="closeDialog"
      @saveDialog="closeDialog"
    />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Table from "./Table.vue";
import Form from "./Form.vue";
export default {
  components: {
    Pager,
    Table,
    Form
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      schoolId: "",
      isLoading: false,
      dataTable: [],
      formData: {},
      formVisible: false,
      shapes: [],
      formTitle: ""
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.getData();
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    getData() {
      this.$api.post(
        "/v1/buttonmanager/GetData",
        {
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target.ANGLList;
            this.shapes = r.Target.ShapeList;
            this.pagerInfo.totalCount = this.dataTable.length;
            this.isLoading = false;
          }
        }
      );
    },
    editItem(data) {
      this.formData = {};
      if (data) {
        this.formData = data;
      }
      this.formVisible = true;
    },
    closeDialog(data) {
      if (data) {
        const index = this.dataTable.findIndex(c => c.Id === data.Id);
        if (index === -1) {
          this.dataTable.unshift(data);
        } else {
          this.dataTable.splice(index, 1, data);
        }
      }
      this.formVisible = false;
    },
    delItem(data) {
      const index = this.dataTable.findIndex(c => c.Id === data.Id);
      if (index !== -1) {
        this.dataTable.splice(index, 1);
      }
    }
  }
};
</script>
