<template>
    <el-dialog top="10vh" title="删除" :visible.sync="value" width="60%" ref="dialog"
        :close-on-click-modal="false" :before-close="close">
        <div class="box">
            <div class="box-item" v-for="(item,index) in bbList" :key="index">
                <div class="item-name">
                    {{ item.JHMC }}
                </div>
                <!-- <div class="item-terms">
                    <el-checkbox v-for="term in item.terms"  v-model="term.checked" :key="term.Id" :label="term.Id">
                        {{ term.Name }}
                    </el-checkbox>
                </div> -->
                <div class="item-sjzt">{{ item.SFWC == 1 ? '数据已生成':'数据未生成' }}</div>
                <el-button type="danger"  @click="delrow(item.Id)">删除</el-button>
            </div>

        </div>
        <!-- <div slot="footer" class="dialog-footer center">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </div> -->
    </el-dialog>
</template>

<script>
export default {
    props: {
      visible: {
          type: Boolean,
          default: false
      },
      //获取删除的列表
      bbList: {
        type: Array,
        default() {
          return [];
        }
      },
    },
    model: {
        prop: "visible",
        event: "change"
    },
    data() {
        return {
            value:false,
            delList:[],
            form:[],
            user:'',

        }
    },
    created(){
        this.user = JSON.parse(localStorage.getItem("userinfo"));
    },
    methods:{
        // 获取学期名称
        getTermName(data){
            let name = ''
            let list = JSON.parse(localStorage.getItem('termList'))
            let a = list.filter(i=>i.Id == data)
            if(a){
                name = a
            }
            return name
        },
        //删除
        delrow(data){
            console.log(data,'data');
            this.$confirm("确认删除？").then(() => {
                this.$api.post(
                    "/v1/mbbb/DeleteMBSCJHB",
                    {

                        Id:data,
                        token: this.user.Token
                    },
                    res => {
                        if (res.Code === 0) {
                            console.log(res.Target,'删除');
                            this.$message({
                                type: "success",
                                message: "删除成功",
                                showClose: true
                            });
                            this.$emit('refresh')
                        }else{
                            this.$message.error(res.Message)
                        }
                    }
                )
            }).catch(()=>{});
            
        },
       
        close(){
            this.$emit('canceldialog')
        },
        cancel(){},
        confirm(){}
    },
    watch:{
        visible(val) {
            this.value = val;
            if(val){
                this.delList = []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .box{
       display: flex;
       flex-direction: column;
        .box-item{
            display: flex;
            // justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            .item-name{
                font-size: 16px;
                margin-left: 10px;
                width: 50%;
            }
            .item-terms{
                flex: 1;
                margin-left: 10px;
            }
            .item-sjzt{
              margin-left: 10px;
              margin-right: 15px;
              width: 30%;
            }
            .item-btn{
              width: 20%;
            }
        }
       
    }
</style>