<template>
  <div style="display: flex;">
    <div class="box">
      选择模板：
      <div
        v-for="(item, index) in MBList"
        :key="index"
        @click="goPage(item)"
        class="item"
      >
        <el-button type="primary" size="small">{{ item.BM }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: "",
      value: "",
      user: "",
      MBList: [],
      MBID: this.$route.params.data,
      MBDM: "",
    };
  },
  created() {
    if (this.$route.fullPath === "/teacherGeneralReport") {
      this.MBDM = "TYJSBB";
    } else this.MBDM = "ZYDDBB";
    this.user = JSON.parse(localStorage.getItem("userinfo"));
  },
  mounted() {
    this.getMBGLBList();
  },
  methods: {
    goPage(value) {
      localStorage.setItem("DevelopmentMB", JSON.stringify(value));
      this.$router.push({
        name: "developmentitemlist",
        query: { MBGLID: value.Id },
      });
    },
    //报表ID
    getMBGLBList() {
      this.$api.post(
        "/v1/mbbb/GetMBGLBList",
        {
          Express: "",
          // MBID: this.MBID,
          MBDM: this.MBDM,
          pagerInfo: {
            pageIndex: 1,
            pageSize: 9000,
            totalCount: 0,
          },
          token: this.user.Token,
        },
        (r) => {
          if (r.Code === 0) {
            console.log(r.Target, "获取模板详情");
            this.MBList = r.Target;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  font-size: 16px;
  .item {
    margin: 5px 10px;
  }
}
</style>
