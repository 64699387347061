<template>
  <el-dialog
    class="evaluationTimeDialog"
    :title="`${title}评价阶段时间`"
    :visible.sync="visible"
    width="460px"
    :before-close="closeForm"
    append-to-body
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="所属学段" prop="SSXD">
        <el-select
          style="width:350px"
          v-model="form.SSXD"
          placeholder="所属学段"
        >
          <el-option
            v-for="item in SSXDoption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属学期" prop="SSXQ">
        <el-select
          style="width:350px"
          v-model="form.SSXQ"
          @change="form.dateRange = []"
          placeholder="所属学期"
        >
          <el-option
            v-for="item in SSXQoption &&
              SSXQoption.filter(item => item.Id !== 0)"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动时间" prop="dateRange">
        <el-date-picker
          :disabled="!form.SSXD || !form.SSXQ"
          v-model="form.dateRange"
          type="daterange"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button size="mini" @click="closeForm">取消</el-button>
      <el-button size="mini" type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { formatDate } from "@/utils/date.js";

export default {
  data() {
    return {
      form: {
        HDId: "",
        KSSJ: "",
        JSSJ: "",
        SSXQ: "",
        Id: null,
        SSXD: "",
        dateRange: []
      },
      rules: {
        SSXD: [
          {
            required: true,
            message: "学段不能为空",
            trigger: "blur"
          }
        ],
        SSXQ: [
          {
            required: true,
            message: "学期不能为空",
            trigger: "blur"
          }
        ],
        dateRange: [
          {
            required: true,
            message: "阶段不能为空",
            trigger: "blur"
          }
        ]
      },
      SSXDoption: [],
      pickerOptions: {
        //日期选择禁用
        disabledDate: date => {
          if (date.getTime() + 24 * 60 * 60 * 1000 < Date.now()) return true; //早于当前的 禁用

          let termRange = this.SSXQoption.find(
            item => item.Id === this.form.SSXQ
          );
          // 在所选学期之外的 不能选择
          if (termRange) {
            if (
              date.getTime() <=
                new Date(formatDate(termRange.EndTime)).getTime() &&
              date.getTime() >=
                new Date(formatDate(termRange.BeginTime)).getTime()
            ) {
              return false;
            }
          } else return true;
        }
      },
      title: ""
    };
  },
  props: {
    visible: Boolean,
    SSXQoption: Array,
    currentData: Object
  },
  inject: ["payLoad"],

  methods: {
    getSSXD() {
      //所属学段
      let { SSZZId: SchoolId, Token: token } = JSON.parse(
        localStorage.getItem("userinfo")
      );
      this.$api.post(
        "v1/areaschool/GetSchoolByPeriod",
        { SchoolId, token },
        res => {
          if (res.Code === 0) {
            this.SSXDoption = res.Target;
            let currentTerm = localStorage.getItem("currentterm");
            let obj = this.SSXQoption.find(item => item.Id === currentTerm);
            if (obj) {
              if (this.form.Id) {
                //
              } else {
                this.form.SSXD = 1;
                this.form.SSXQ = obj.Id;
              }
            }
          }
        }
      );
    },
    closeForm() {
      this.$emit("closeFormDialog");
    },
    submit() {
      let { Id: UserId, Token: token } = JSON.parse(
        localStorage.getItem("userinfo")
      );

      let addData = {
        HDId: this.payLoad.currentActivity.Id,
        KSSJ: this.form.dateRange[0],
        JSSJ: this.form.dateRange[1],
        // SSXD: this.form.SSXD,
        SSXQ: this.form.SSXQ,
        Id: this.form.Id
      };
      this.$api.post(
        "v1/evastage/EditHDKZJD",
        {
          addData,
          UserId,
          token
        },
        res => {
          if (res.Code === 0) this.$emit("closeFormDialog", 1);
        }
      );
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (Object.keys(this.currentData).length < 0) this.title = "新增";
        else this.title = "编辑";
        Object.assign(this.form, this.currentData);
        this.form.dateRange = [this.form.KSSJ, this.form.JSSJ];
        this.getSSXD();
      } else {
        Object.keys(this.form).forEach(key => {
          if (key === "dateRange") this.form[key] = [];
          else this.form[key] = "";
        });
      }
    }
  }
};
</script>
<style lang="scss">
.evaluationTimeDialog {
  min-width: 460px;
}
</style>
<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: end;
}
</style>
