var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"30%","center":"","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"tab-shu-box"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData},on:{"validate":_vm.validate}},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"tab-shu-titl pos-r"},[_vm._v(" 报表代码 "),_c('div',{staticClass:"prompt-butt-box"},[_vm._v("注")]),_c('div',{staticClass:"prompt-butt-center"},[_vm._v(" 注：报表代码需要和前端开发人员协商一致，且报表代码不允许重复。 ")])]),_c('td',[_c('el-form-item',{ref:"MBDM",staticStyle:{"height":"16px"},attrs:{"rules":[
                  {
                    required: true,
                    message: '请选择代码',
                    trigger: 'change'
                  }
                ],"prop":"MBDM"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.MBDM),callback:function ($$v) {_vm.$set(_vm.formData, "MBDM", $$v)},expression:"formData.MBDM"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.Value,attrs:{"label":`${item.Name}(${item.Description})`,"value":item.Value}})}),1)],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("报表名称")]),_c('td',[_c('el-form-item',{ref:"MBMC",staticStyle:{"height":"16px"},attrs:{"rules":[
                  {
                    required: true,
                    message: '请输入报表名称',
                    trigger: 'change'
                  }
                ],"prop":"MBMC"}},[_c('el-input',{staticClass:"jf-form",attrs:{"placeholder":"请输入内容","size":"small","prop":"MBMC"},model:{value:(_vm.formData.MBMC),callback:function ($$v) {_vm.$set(_vm.formData, "MBMC", $$v)},expression:"formData.MBMC"}})],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("对象类型")]),_c('td',[_c('el-form-item',{ref:"DXLX",staticStyle:{"height":"16px"},attrs:{"rules":[
                  {
                    required: true,
                    message: '请选择对象',
                    trigger: 'change'
                  }
                ],"prop":"DXLX"}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small","prop":"DXLX"},model:{value:(_vm.formData.DXLX),callback:function ($$v) {_vm.$set(_vm.formData, "DXLX", $$v)},expression:"formData.DXLX"}},_vm._l((_vm.reportType),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1)])])])])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmDialog}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }