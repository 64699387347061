<template>
  <!-- 大后台消息管理 -->
  <div class="management-messages">
    <div class="top-btns">
      <el-button type="primary" size="small" @click="addMessage(0)"
        >新增消息</el-button
      >
    </div>
    <div class="main">
      <el-table :data="list" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="sendSchool"
          label="发送学校"
        />
        <el-table-column show-overflow-tooltip prop="sendObj" label="发送对象">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="XXNR" label="发送内容">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="FSSJ" label="发送时间">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="JZSJ" label="截止时间">
        </el-table-column>
        <el-table-column show-overflow-tooltip label="是否跳转">
          <template slot-scope="scope">
            <span>{{ scope.row.TZDZ ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="addMessage(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" style="color:red" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="padding:10px;display:flex;justify-content:flex-end">
        <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
      </div>
    </div>
    <!-- 表单 -->
    <el-dialog :title="title" :visible.sync="showForm" width="70%">
      <el-form
        class="form"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="发送时间" prop="FSSJ">
          <el-date-picker
            v-model="form.FSSJ"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="截止时间" prop="JZSJ">
          <el-date-picker
            v-model="form.JZSJ"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发送对象" prop="FSDX">
          <el-checkbox-group v-model="form.FSDX">
            <el-checkbox :label="3">家长端</el-checkbox>
            <el-checkbox :label="1">教师端</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="发送学校" prop="sendSchool">
          <el-checkbox v-model="checkedAll" @change="allChange"
            >全选</el-checkbox
          >
          <el-tabs v-model="activeArea">
            <el-tab-pane
              v-for="item in area"
              :key="item.key"
              label=""
              :name="item.key"
            >
              <span slot="label">
                <el-checkbox
                  v-model="item.checked"
                  @change="
                    (val) => {
                      areaChange(item, val);
                    }
                  "
                  >{{ ` ` }}</el-checkbox
                >
                {{ item.name }}
              </span>
              <el-checkbox-group
                v-model="item.schoolChecked"
                @change="
                  (val) => {
                    schoolChange(item, val);
                  }
                "
              >
                <el-checkbox
                  :label="it.Id"
                  v-for="it in item.school"
                  :key="it.XXMC"
                  >{{ it.XXMC }}</el-checkbox
                >
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="跳转地址" prop="TZDZ">
          <el-select v-model="form.TZDZ" placeholder="请选择" clearable>
            <el-option
              v-for="item in TZDZ_option"
              :key="item.Id"
              :label="item.BT"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送内容" prop="XXNR">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 6 }"
            v-model="form.XXNR"
            :maxlength="200"
            resize="none"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showForm = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";

export default {
  name: "ManagementMessages",
  components: {
    Pager,
  },
  data() {
    let validFSSJ = (rule, value, callback) => {
      if (new Date(value) <= new Date())
        return callback(new Error("发送时间必须大于当前时间"));
      if (this.form.JZSJ && new Date(value) >= new Date(this.form.JZSJ))
        return callback(new Error("发送时间必须小于截止时间"));
      callback();
    };
    let validJZSJ = (rule, value, callback) => {
      if (this.form.FSSJ && new Date(value) <= new Date(this.form.FSSJ))
        return callback(new Error("截止时间必须大于发送时间"));
      callback();
    };
    return {
      list: [],
      showForm: false,
      form: {},
      rules: {
        FSSJ: [
          { required: true, message: "请选择发送日期", trigger: "change" },
          { validator: validFSSJ, trigger: "change" },
        ],
        JZSJ: [
          { required: true, message: "请选择截止日期", trigger: "change" },
          { validator: validJZSJ, trigger: "change" },
        ],
        FSDX: [
          { required: true, message: "请选择发送对象", trigger: "change" },
        ],
        sendSchool: [
          { required: true, message: "请选择发送学校", trigger: "change" },
        ],
        XXNR: [
          { required: true, message: "请选择发送内容", trigger: "change" },
        ],
      },
      title: "新增即时消息",
      checkedAll: "",
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 10,
      },
      area: [],
      activeArea: "",
      TZDZ_option: [],
    };
  },
  async mounted() {
    this.token = localStorage.getItem("Token");
    await this.getArea();
    if (this.area.length > 0) {
      for (let i = 0; i < this.area.length; i++) {
        await this.getSchool(this.area[i]);
      }
    }
    this.area = this.area.filter(
      (item) => item.school && item.school.length > 0
    );
    this.activeArea = this.area[0].key;

    await this.getList();
    this.$api.post(
      "/v1/EvaluationReport/GetSybzgl",
      {
        Title: "", //	否	string	标题模糊查询
        PageIndex: 0, //	否	int	分页
        PageSize: 1000, //	否	int	分页
      },
      (res) => {
        if (res.Code === 0) {
          this.TZDZ_option = res.Target || [];
        }
      }
    );
  },
  methods: {
    // 获取区域
    getArea() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/areaschool/GetAreaTreeByParentId",
          {
            ParentId: "000001",
            token: this.token,
          },
          (res) => {
            if (res.Code === 0) {
              let arr = res.Target || [];
              arr.forEach((item) => {
                item.checked = false;
                item.schoolChecked = [];
              });
              this.area = arr;
              resolve();
            } else reject();
          }
        );
      });
    },
    // 获取学校
    getSchool(area) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/areaschool/GetAreaSchoolList",
          {
            AreaId: area.key,
            token: this.token,
          },
          (res) => {
            if (res.Code === 0) {
              area.school = res.Target || [];
              resolve();
            } else reject();
          }
        );
      });
    },
    getList() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/xxtzb/GetXXTZList",
          {
            token: this.token,
            pagerInfo: this.pagerInfo,
          },
          (res) => {
            if (res.Code === 0) {
              let arr = res.Target || [];
              arr.sort((a, b) => new Date(b.FSSJ) - new Date(a.FSSJ));
              arr.sort((a, b) => {
                if (b.FSSJ === a.FSSJ)
                  return new Date(b.JZSJ) - new Date(a.JZSJ);
              });
              this.list = arr.reduce((pre, cur) => {
                let sendSchool = "";
                let sendObj = "";
                if (cur.SendObjectList) {
                  sendSchool = cur.SendObjectList.map((it) => it.GLDXMC).join();
                }
                if (cur.FSDX === 3) sendObj = "家长端";
                if (cur.FSDX === 1) sendObj = "教师端";
                if (cur.FSDX === 0) sendObj = "家长端,教师端";

                pre.push({
                  sendSchool,
                  sendObj,
                  ...cur,
                });
                this.pagerInfo.totalCount = res.PagerInfo.RowCount;
                return pre;
              }, []);

              resolve();
            } else reject();
          }
        );
      });
    },
    // 添加/编辑
    addMessage(data) {
      if (data) {
        this.title = "编辑即时消息";
        let FSDX = data.FSDX === 1 ? [1] : data.FSDX === 3 ? [3] : [1, 3];

        let sendSchool = data.SendObjectList.reduce((pre, cur) => {
          pre.push(cur.GLDXID);
          return pre;
        }, []);

        this.area.forEach((item) => {
          if (sendSchool.includes(item.key)) {
            item.checked = true;
            item.schoolChecked = item.school.map((it) => it.Id);
          } else {
            item.checked = false;
            item.schoolChecked = item.school
              .filter((it) => sendSchool.includes(it.Id))
              .map((it) => it.Id);
          }
        });
        if (this.area.every((item) => item.checked)) this.checkedAll = true;
        else this.checkedAll = false;

        this.form = {
          ...data,
          FSDX,
          sendSchool,
        };
      } else {
        this.title = "新增即时消息";
        this.form = {
          Id: "",
          sendSchool: [],
          FSDX: [],
          FSSJ: "",
          JZSJ: "",
          TZDZ: "",
          XXNR: "",
        };
        this.checkedAll = false;
        this.area.forEach((item) => {
          item.checked = false;
          item.schoolChecked = [];
        });
      }

      this.showForm = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    async changeIndex(val) {
      this.pagerInfo.pageIndex = val;
      await this.getList();
    },
    // 发送学校全选
    allChange(val) {
      if (val) {
        this.area.forEach((item) => {
          item.checked = true;
          item.schoolChecked = item.school.map((it) => it.Id);
        });
      } else {
        this.area.forEach((item) => {
          item.checked = false;
          item.schoolChecked = [];
        });
      }
      this.handleSendSchool();
    },
    // 发送学校区域勾选
    areaChange(area, val) {
      if (val) {
        area.schoolChecked = area.school.map((item) => item.Id);
        if (this.area.every((item) => item.checked)) {
          this.checkedAll = true;
        } else this.checkedAll = false;
      } else {
        area.schoolChecked = [];
      }
      this.handleSendSchool();
    },
    // 发送学校勾选
    schoolChange(area, val) {
      if (val.length === area.school.length) area.checked = true;
      else area.checked = false;
      this.handleSendSchool();
    },
    // 处理学校勾选数据
    handleSendSchool() {
      if (this.area.every((item) => item.checked)) this.checkedAll = true;
      else this.checkedAll = false;
      this.form.sendSchool = this.area.reduce((pre, cur) => {
        if (cur.checked) pre.push(cur.key);
        else {
          pre = pre.concat(cur.schoolChecked);
        }
        return pre;
      }, []);
      this.$refs.form.validateField("sendSchool");
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.add_edit(this.form.Id);
        }
      });
    },
    // 新增/编辑保存
    add_edit(type) {
      let XXTZGLDXBInfo = this.form.sendSchool.reduce((pre, cur) => {
        let area = this.area.find((item) => item.key === cur);
        if (area) {
          pre.push({
            XXTZID: type || null,
            GLLX: 1,
            GLDXID: cur,
          });
        } else {
          this.area.forEach((item) => {
            let school = item.school.find((el) => el.Id === cur);
            if (school)
              pre.push({
                XXTZID: type || null,
                GLLX: 2,
                GLDXID: cur,
              });
          });
        }
        return pre;
      }, []);
      return new Promise((resolve, reject) => {
        this.$api.post(
          type ? "/v1/xxtzb/UpdateXXTZ" : "/v1/xxtzb/AddXXTZ",
          {
            XXTZBInfo: {
              ...this.form,
              FSDX: this.form.FSDX.length === 1 ? this.form.FSDX[0] : 0,
              SFTZ: this.form.TZDZ ? 1 : 2,
            },
            XXTZGLDXBInfo,
            token: this.token,
          },
          (res) => {
            if (res.Code === 0) {
              this.$message.success("保存成功");
              this.showForm = false;
              this.getList();
              resolve();
            } else reject();
          }
        );
      });
    },
    del(data) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/xxtzb/DeleteXXTZ",
          {
            Id: data.Id,
            token: this.token,
          },
          (res) => {
            if (res.Code === 0) {
              this.$message.success("删除成功");
              this.getList();
              resolve();
            } else reject();
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.management-messages {
  .top-btns {
    padding: 10px;
  }
  .main {
  }

  .el-dialog__wrapper {
    ::v-deep .el-dialog {
      min-width: 600px;
    }
    .form {
    }
  }
}
</style>
