<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <el-row>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            <!--tree-->
            <el-tree
              :data="treeData"
              :props="props"
              node-key="key"
              default-expand-all
              draggable
              @node-click="handleNodeClick"
              :expand-on-click-node="false"
              :highlight-current="true"
            ></el-tree>

            <!--:load="loadNode"-->
          </div>
        </el-col>

        <el-col :span="19">
          <div class="tab-heng-box margno-t">
            <!--列表-->
            <div class="table-er-box">
              <el-button plain icon="el-icon-edit" @click="addModel"
                >新增</el-button
              >
            </div>
            <el-table
              :data="tableData"
              class="jf-table"
              border
              tooltip-effect="dark"
              table-layout="fixed;"
              style="width: 100%;"
            >
              <el-table-column prop="MKMC" label="模块名称"></el-table-column>
              <el-table-column prop="MKLB" label="模块类别">
                <template slot-scope="scope">
                  <p v-if="scope.row.MKLB == '1'">普通菜单</p>
                  <p v-else-if="scope.row.MKLB == '2'">按钮</p>
                </template>
              </el-table-column>
              <el-table-column prop="MKLX" label="模块标识">
                <template slot-scope="scope">
                  {{
                    evalutionType
                      .filter(c => c.EnumsNumber === scope.row.MKLX)
                      .map(c => c.EnumsName)
                      .join('')
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="LB" label="功能类别">
                <template slot-scope="scope">
                  <p v-if="scope.row.LB == '1'">小学段</p>
                  <p v-else-if="scope.row.LB == '2'">中学段</p>
                  <p v-else-if="scope.row.LB == '3'">小学段|中学段</p>
                  <p v-else>--</p>
                </template>
              </el-table-column>
              <el-table-column prop="SSYY" label="端类">
                <template slot-scope="scope">
                  <p v-if="scope.row.SSYY == '1'">PC</p>
                  <p v-else-if="scope.row.SSYY == '2'">APP</p>
                </template>
              </el-table-column>
              <el-table-column prop="SQBS" label="模块授权码"></el-table-column>
              <el-table-column prop="Route" label="路由"></el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="editRow(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="deleteRow(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div class="block">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="rowcount"
              ></el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--弹出框-->
    <el-dialog
      title="新增"
      width="35%"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="GNMKQXInfo" :rules="rules" ref="sbmitfrom">
        <el-form-item
          label="模块名称"
          :label-width="formLabelWidth"
          prop="MKMC"
        >
          <el-input v-model="GNMKQXInfo.MKMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="授权码" :label-width="formLabelWidth" prop="SQBS">
          <el-input v-model="GNMKQXInfo.SQBS" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="模块类别"
          :label-width="formLabelWidth"
          prop="MKLB"
        >
          <el-select v-model="GNMKQXInfo.MKLB" placeholder="请选择类别">
            <el-option label="普通菜单" :value="1"></el-option>
            <el-option label="按钮" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="模块标识"
          :label-width="formLabelWidth"
          prop="MKLX"
        >
          <el-select v-model="GNMKQXInfo.MKLX" placeholder="请选择标识">
            <el-option
              v-for="(item, index) in evalutionType"
              :key="index"
              :label="item.EnumsName"
              :value="item.EnumsNumber"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="功能类别" :label-width="formLabelWidth" prop="LB">
          <el-select v-model="GNMKQXInfo.LB" multiple placeholder="请选择类别">
            <el-option
              v-for="item in LBoptions"
              :key="item.Id"
              :label="item.name"
              :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="路由" :label-width="formLabelWidth" prop="Route">
          <el-input v-model="GNMKQXInfo.Route" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitshowdata">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'GnmkqxList',
  data() {
    return {
      token: this.$store.state.token,
      // loading:true,
      treeData: [],
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      evalutionType: [],
      GNMKQXInfo: {
        Id: '',
        MKMC: '',
        LB: [],
        MKFId: '0',
        MKLB: 1,
        SQBS: '',
        PXH: '0',
        SFSC: '2',
        SSYY: '0',
        MKLX: 0,
        Route: ''
      },
      loadstate: true,
      props: {
        label: 'name',
        children: 'subnodeList'
      },
      count: 1,
      treenode: [],
      rules: {
        MKMC: [
          {
            required: true,
            message: '请输入模块名称',
            trigger: 'change'
          }
        ],
        SQBS: [
          {
            required: true,
            message: '请输入授权码',
            trigger: 'change'
          }
        ],
        MKLB: [
          {
            required: true,
            message: '请选择模块类别',
            trigger: 'change'
          }
        ],
        Route: [
          {
            required: true,
            message: '请输入路由',
            trigger: 'change'
          }
        ]
      },
      userName: '',
      userId: '',
      // 功能类别数组
      LBoptions: [
        {
          name: '小学段',
          Id: 1
        },
        {
          name: '中学段',
          Id: 2
        }
      ]
    }
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    this.$api.post(
      'v1/gnmkqx/GetModuleEvaType',
      {
        token: this.token
      },
      r => {
        if (r.Code === 0) {
          this.evalutionType = r.Target
        }
      }
    )
    this.reftreeload()
  },
  methods: {
    addModel: function() {
      this.GNMKQXInfo.Id = ''
      this.GNMKQXInfo.MKMC = ''
      // this.GNMKQXInfo.MKFId = "0";
      this.GNMKQXInfo.LB = []
      this.GNMKQXInfo.MKLB = 1
      this.GNMKQXInfo.SQBS = ''
      this.GNMKQXInfo.PXH = '0'
      this.GNMKQXInfo.SFSC = '2'
      this.GNMKQXInfo.MKLX = '0'
      this.GNMKQXInfo.Route = ''
      this.dialogFormVisible = true
    },

    // 刷新树
    reftreeload: function() {
      this.$api.post(
        'v1/gnmkqx/GetModularByAllTree',
        { token: this.token },
        r => {
          if (r.Code === 0) {
            this.treeData = r.Target
          }
        }
      )
    },
    // tree选中
    handleNodeClick(data) {
      if (this.GNMKQXInfo.MKFId !== data.key || this.loadstate) {
        this.loadstate = false
        this.GNMKQXInfo.MKFId = data.key
        this.GNMKQXInfo.SSYY = data.type

        const pagerInfo = { pageIndex: 0, pageSize: this.pagesize }
        this.$api.post(
          'v1/gnmkqx/getGNMKQXListByParentId',
          {
            MKFId: this.GNMKQXInfo.MKFId,
            PagerInfo: pagerInfo,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.tableData = r.Target
              this.currentPage = r.PagerInfo.PageIndex
              this.pagesize = r.PagerInfo.PageSize
              this.rowcount = r.PagerInfo.RowCount
            }
          }
        )
      }
    },
    // 加载tree
    loadNode(node, resolve) {
      // 初始加载
      if (node.level === 0) {
        var startload = []

        this.$api.post(
          'v1/gnmkqx/GetGNMKQXTree',
          { MKFId: this.GNMKQXInfo.MKFId, token: this.token },
          r => {
            if (r.Code === 0) {
              startload = r.Target

              return resolve(startload)
            }
          }
        )
      } else {
        this.GNMKQXInfo.MKFId = node.data.key
        this.GNMKQXInfo.SSYY = node.data.type
        this.loadstate = true

        setTimeout(() => {
          var data = []

          this.$api.post(
            'v1/gnmkqx/GetGNMKQXTree',
            { MKFId: this.GNMKQXInfo.MKFId, token: this.token },
            r => {
              if (r.Code === 0) {
                data = r.Target

                resolve(data)
              }
            }
          )
        }, 500)
      }
    },

    // 翻页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);

      var pagerInfo = { pageIndex: 0, pageSize: val }

      this.$api.post(
        'v1/gnmkqx/getGNMKQXListByParentId',
        {
          MKFId: this.GNMKQXInfo.MKFId,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
            // loading=false;
          }
        }
      )
    },
    // 翻页查询
    handleCurrentChange(val) {
      // console.log(`当前页1: ${val}`);

      var pagerInfo = { pageIndex: val, pageSize: this.pagesize }

      this.$api.post(
        'v1/gnmkqx/getGNMKQXListByParentId',
        {
          MKFId: this.GNMKQXInfo.MKFId,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
            // loading=false;
          }
        }
      )
    },
    // 刷新节点
    reloadNode() {
      // const node = this.$refs.tree.getNode(this.GNMKQXInfo.MKFId)
    },
    // 刷新加载
    refload: function() {
      this.reftreeload()

      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize }
      this.$api.post(
        'v1/gnmkqx/getGNMKQXListByParentId',
        {
          MKFId: this.GNMKQXInfo.MKFId,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target

            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 修改
    editRow: function(index, row) {
      this.GNMKQXInfo = {
        Id: row.Id,
        MKMC: row.MKMC,
        MKFId: row.MKFId,
        MKLB: row.MKLB,
        LB: row.LB === 3 ? [1, 2] : row.LB === 0 ? [] : [row.LB],
        SQBS: row.SQBS,
        PXH: row.PXH,
        SFSC: row.SFSC,
        SSYY: row.SSYY,
        MKLX: row.MKLX,
        Route: row.Route
      }

      // this.GNMKQXInfo = row;

      this.dialogFormVisible = true
    },
    // 删除
    deleteRow: function(index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/gnmkqx/GNMKQXDelete',
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.refload()

                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    // 保存
    submitshowdata: function(event) {
      event.preventDefault()

      if (this.GNMKQXInfo.MKFId === '0') {
        this.$message({
          type: 'error',
          message: '未选中所属模块',
          showClose: true
        })
        return
      }
      let LB = eval(this.GNMKQXInfo.LB.join('+'))
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.$api.post(
            'v1/gnmkqx/GNMKQXAdd',
            {
              GNMKQXInfo: {
                ...this.GNMKQXInfo,
                LB
              },
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.dialogFormVisible = false

                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.$refs.sbmitfrom.resetFields()
                this.refload()
              }
            }
          )
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style>
.cell {
  text-align: left;
}
</style>
