<template>
  <div>
    <router-view :activities="activities" :setList="setList"></router-view>
  </div>
</template>
<script>
export default {
  props: {
    activities: {
      type: Array,
      default: function() {
        return [];
      }
    },
    setList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>
