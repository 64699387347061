<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="30%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="tab-shu-box">
      <el-form :model="formData" ref="ruleForm">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl">按钮名称</td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      required: true,
                      message: '请输入报表代码',
                      trigger: 'change'
                    }
                  ]"
                  prop="ANMC"
                >
                  <el-input
                    v-model="formData.ANMC"
                    placeholder="请输入内容"
                    size="small"
                    class="jf-form"
                    prop="ANMC"
                  ></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">支撑评价方式</td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      type: 'array',
                      required: true,
                      message: '请选择支撑评价方式',
                      trigger: 'change'
                    }
                  ]"
                  prop="checkedPJFSData"
                >
                  <el-checkbox @change="checkedPJFS" v-model="formData.TYZB"
                    >通用指标</el-checkbox
                  >
                  <el-checkbox @change="checkedPJFS" v-model="formData.XXZB"
                    >星星指标</el-checkbox
                  >
                  <el-checkbox @change="checkedPJFS" v-model="formData.WJ"
                    >问卷</el-checkbox
                  >
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">按钮属性</td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      required: true,
                      message: '请选择按钮类型',
                      trigger: 'change'
                    }
                  ]"
                  prop="ANLX"
                >
                  <el-select
                    v-model="formData.ANLX"
                    placeholder="请选择"
                    size="small"
                    class="jf-form"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="item in shapes.filter(c =>
                        shapeLimit.includes(c.Key)
                      )"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">占位</td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      required: true,
                      message: '请选择占位',
                      trigger: 'change'
                    }
                  ]"
                  prop="ZW"
                >
                  <el-select
                    v-model="formData.ZW"
                    placeholder="请选择"
                    size="small"
                    class="jf-form"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="item in zw"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">排序</td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      required: true,
                      message: '请输入排序号',
                      trigger: 'change'
                    }
                  ]"
                  prop="PXH"
                >
                  <el-input
                    type="number"
                    v-model="formData.PXH"
                    placeholder="请输入内容"
                    size="small"
                    class="jf-form"
                    prop="PXH"
                  ></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">状态</td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      required: true,
                      message: '请选择使用状态',
                      trigger: 'change'
                    }
                  ]"
                  prop="SYZT"
                >
                  <el-select
                    v-model="formData.SYZT"
                    placeholder="请选择"
                    size="small"
                    class="jf-form"
                    style="width: 100%;"
                    prop="SYZT"
                  >
                    <el-option
                      v-for="item in statu"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      shapeLimit: [],
      validateResult: false,
      labelWidth: "250px",
      formData: {},
      statu: [
        {
          Key: 1,
          Value: "正常"
        },
        {
          Key: 2,
          Value: "停用"
        }
      ],
      zw: [
        {
          Key: 1,
          Value: "上位"
        },
        {
          Key: 2,
          Value: "中位"
        },
        {
          Key: 3,
          Value: "下位"
        }
      ]
    };
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    shapes: {
      type: Array,
      default: function() {
        return [];
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    init() {
      this.formData = JSON.parse(JSON.stringify(this.data));
      if (this.formData.Id) {
        this.$set(this.formData, "TYZB", this.formData.TYZB === 1);
        this.$set(this.formData, "XXZB", this.formData.XXZB === 1);
        this.$set(this.formData, "WJ", this.formData.WJ === 1);
      } else {
        this.$set(this.formData, "TYZB", false);
        this.$set(this.formData, "XXZB", false);
        this.$set(this.formData, "WJ", false);
      }
      this.$set(this.formData, "checkedPJFSData", []);
      this.checkedPJFS();
    },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeDialog");
      });
    },
    confirmDialog() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$api.post(
            "/v1/buttonmanager/EditButton",
            {
              token: this.$store.state.token,
              ANGLInfo: this.formData,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              let data = "";
              if (r.Code === 0) {
                data = r.Target;
                data.EvaluationMethod = "";
                this.$message({
                  type: "success",
                  message: r.Message
                });
                this.$emit("saveDialog", data);
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    checkedPJFS() {
      this.formData.checkedPJFSData = [1];
      if (!this.formData.TYZB && !this.formData.XXZB && !this.formData.WJ) {
        this.formData.checkedPJFSData = [];
      }
      this.shapeLimit = [];
      if (this.formData.TYZB) {
        if (this.shapeLimit && this.shapeLimit.length > 0) {
          this.shapeLimit = this.shapeLimit.filter(
            item => [1, 2, 3, 4, 5, 6, 8, 12, 13, 9].indexOf(item) > -1
          );
        } else {
          this.shapeLimit.push(1, 2, 3, 4, 5, 6, 8, 12, 13, 9);
        }
      }
      if (this.formData.XXZB) {
        if (this.shapeLimit && this.shapeLimit.length > 0) {
          this.shapeLimit = this.shapeLimit.filter(
            item => [1, 2, 4, 5, 6, 7, 8, 10, 11, 12, 13, 9].indexOf(item) > -1
          );
        } else {
          this.shapeLimit.push(1, 2, 4, 5, 6, 7, 8, 10, 11, 12, 13, 9);
        }
      }
      if (this.formData.WJ) {
        if (this.shapeLimit && this.shapeLimit.length > 0) {
          this.shapeLimit = this.shapeLimit.filter(
            item => [1, 4, 6, 8, 12, 9].indexOf(item) > -1
          );
        } else {
          this.shapeLimit.push(1, 4, 6, 8, 12, 9);
        }
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        // 隐藏
        this.$refs.ruleForm.resetFields();
      }
    }
  }
};
</script>
