var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"70%","top":"1%","center":"","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"formData",attrs:{"model":_vm.formData,"rules":_vm.rules}},[_c('div',{staticClass:"quest-titl-box"},[_c('div',{staticClass:"quest-titl",staticStyle:{"height":"40px"}},[_c('el-form-item',{attrs:{"prop":"WJMC"}},[_c('el-input',{staticClass:"jf-quest-titl",attrs:{"placeholder":"请输入问卷名称"},model:{value:(_vm.formData.WJMC),callback:function ($$v) {_vm.$set(_vm.formData, "WJMC", $$v)},expression:"formData.WJMC"}})],1)],1)]),_c('div',{staticClass:"operate-box",staticStyle:{"padding-bottom":"10px","margin-top":"10px","font-size":"14px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"unset","width":"262px"},attrs:{"prop":"SSWD","label":"所属维度"}},[_c('el-cascader',{staticClass:"jf-form",staticStyle:{"width":"180px"},attrs:{"props":{ expandTrigger: 'hover' },"options":_vm.dimensions,"show-all-levels":false,"placeholder":"请选择末级维度","size":"small"},on:{"change":_vm.handleWdSelected},model:{value:(_vm.formData.SSWD),callback:function ($$v) {_vm.$set(_vm.formData, "SSWD", $$v)},expression:"formData.SSWD"}})],1),_c('el-form-item',{staticStyle:{"display":"inline-block","width":"105px"},attrs:{"label":"按学科参与","prop":"CYXK"}},[_c('el-checkbox',{model:{value:(_vm.formData.CYXK),callback:function ($$v) {_vm.$set(_vm.formData, "CYXK", $$v)},expression:"formData.CYXK"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.CYXK),expression:"formData.CYXK"}],staticStyle:{"display":"inline-block","width":"217px","margin-right":"10px"},attrs:{"label":"","prop":"CourseIds","rules":_vm.formData.CYXK
            ? [
                {
                  required: true,
                  message: '请选择学科',
                  trigger: 'blur'
                }
              ]
            : []}},[_c('el-select',{attrs:{"multiple":"","size":"small","placeholder":"请选择学科"},model:{value:(_vm.formData.CourseIds),callback:function ($$v) {_vm.$set(_vm.formData, "CourseIds", $$v)},expression:"formData.CourseIds"}},_vm._l((_vm.XKoptions),function(item){return _c('el-option',{key:item.Id,attrs:{"label":item.KCMC,"value":item.Id}})}),1)],1),_c('el-upload',{ref:"upload",staticClass:"upload-demo",staticStyle:{"display":"inline-block"},attrs:{"accept":".xls, .xlsx","action":_vm.uploadURL,"on-change":_vm.uploadrfid,"show-file-list":false,"auto-upload":false}},[_c('el-button',{attrs:{"slot":"trigger","plain":"","type":"primary","size":"mini"},slot:"trigger"},[_vm._v(" 导入 ")])],1),_c('a',{staticStyle:{"margin":"0 10px"},attrs:{"href":`${_vm.publicPath}template/问卷模板.xlsx`}},[_c('el-button',{attrs:{"plain":"","type":"success","size":"mini"}},[_vm._v(" 模板下载 ")])],1),_c('el-form-item',{staticStyle:{"width":"340px","display":"inline-block","margin-right":"10px"},attrs:{"label":"统一指定答案模板"}},[_c('el-select',{attrs:{"placeholder":"统一指定答案模板","size":"small"},on:{"change":_vm.answerSelectChange},model:{value:(_vm.answerSelect),callback:function ($$v) {_vm.answerSelect=$$v},expression:"answerSelect"}},_vm._l((_vm.answerOption),function(item){return _c('el-option',{key:item.Id,attrs:{"label":item.MBMC,"value":item.Id}})}),1)],1),_c('el-button',{attrs:{"plain":"","type":"warning","size":"mini","disabled":!_vm.formData.WJTMBList ||
            _vm.formData.WJTMBList.length === 0 ||
            _vm.formData.WJTMBList.every(item => !item.WJFXBList)},on:{"click":function($event){_vm.batchFSobj.visible = true}}},[_vm._v(" 批量填充分数 ")])],1),_c('el-form-item',{attrs:{"prop":"WJTMBList"}}),_vm._l((_vm.formData.WJTMBList),function(item,index){return _c('div',{key:index,staticClass:"whole-tit-mianbox"},[_c('div',{staticStyle:{"font-size":"0px","box-sizing":"border-box","padding-bottom":"15px","display":"flex","align-items":"center"}},[_c('div',{staticClass:"whole-tit-topleft"},[_vm._v(_vm._s(index + 1)+".")]),_c('el-form-item',{staticStyle:{"margin-right":"10px","margin-bottom":"15px"},attrs:{"prop":'WJTMBList[' + index + '].TMMC',"rules":[
            { required: true, message: '请输入题目名称', trigger: 'change' },
            {
              min: 1,
              max: 200,
              message: '不能超过200 个字符',
              trigger: 'change'
            }
          ]}},[_c('el-input',{staticClass:"jf-textarea-wj jf-form",attrs:{"type":"textarea","autosize":"","placeholder":"请输入不超过100字"},model:{value:(item.TMMC),callback:function ($$v) {_vm.$set(item, "TMMC", $$v)},expression:"item.TMMC"}})],1),_c('el-form-item',{staticStyle:{"width":"80px","margin-bottom":"8px"},attrs:{"prop":'WJTMBList[' + index + '].SSLB',"rules":[
            {
              required: true,
              message: '请选择题目类型',
              trigger: 'change'
            }
          ]}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(item.SSLB),callback:function ($$v) {_vm.$set(item, "SSLB", $$v)},expression:"item.SSLB"}},_vm._l((_vm.SSLB),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1),(item.SSLB === 2)?_c('div',{staticClass:"titdel-add-box"},[_c('el-form-item',{staticClass:"jf_wj_dx form-item-number",attrs:{"label":"最多","prop":'WJTMBList[' + index + '].JDXX',"rules":[
              {
                validator: _vm.validJDXX,
                trigger: 'blur'
              }
            ]}},[_c('el-input-number',{attrs:{"min":1,"max":9},model:{value:(item.JDXX),callback:function ($$v) {_vm.$set(item, "JDXX", $$v)},expression:"item.JDXX"}})],1)],1):_vm._e(),_c('el-button',{staticStyle:{"position":"relative","top":"-4px","margin-left":"10px"},attrs:{"type":"danger","size":"mini","plain":""},on:{"click":function($event){return _vm.delItem(index)}}},[_vm._v("删除此题")])],1),_c('div',[_c('el-form-item',{staticClass:"jf_wj_dx",attrs:{"prop":'WJTMBList[' + index + '].WJFXBList',"rules":[
            {
              validator: _vm.checklength,
              SSLB: item.SSLB,
              message: '缺少答案',
              trigger: 'change'
            }
          ]}})],1),(item.SSLB !== 3)?_c('div',{staticClass:"smtit-whole-box"},[_c('div',{staticClass:"smtit-box"},_vm._l((item.WJFXBList),function(fx,i){return _c('div',{key:i,staticClass:"smtit-cent"},[_c('el-form-item',{attrs:{"prop":`WJTMBList[${index}].WJFXBList[${i}].TMQZ`,"rules":[
                { required: true, message: '不能为空', trigger: 'blur' }
              ]}},[_c('el-select',{staticStyle:{"width":"65px"},attrs:{"size":"small","placeholder":"请选择"},model:{value:(fx.TMQZ),callback:function ($$v) {_vm.$set(fx, "TMQZ", $$v)},expression:"fx.TMQZ"}},_vm._l((_vm.ZM),function(e){return _c('el-option',{key:e.value,attrs:{"label":e.label,"value":e.value,"disabled":_vm.handleZMabled(item.WJFXBList, e.value)}})}),1)],1),(fx.FXLX === 1)?_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":`WJTMBList[${index}].WJFXBList[${i}].TMMC`,"rules":[
                { required: true, message: '请输入答案', trigger: 'change' },
                {
                  min: 1,
                  max: 1000,
                  message: '不能超过1000个字符',
                  trigger: 'change'
                }
              ]}},[_c('el-input',{staticClass:"jf-form",staticStyle:{"width":"300px","margin-left":"5px"},attrs:{"size":"small","placeholder":"请输入内容"},model:{value:(fx.TMMC),callback:function ($$v) {_vm.$set(fx, "TMMC", $$v)},expression:"fx.TMMC"}})],1):_c('span',{staticStyle:{"margin-left":"5px","position":"relative","top":"-9px"}},[_vm._v("其他")]),_c('span',{staticClass:"text-score"},[_vm._v(" 分数： ")]),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":`WJTMBList[${index}].WJFXBList[${i}].DF`,"rules":[
                { required: true, message: '请输入分数', trigger: 'change' },
                {
                  validator: _vm.validateNumber,
                  message: '分数必须为数字',
                  trigger: 'change'
                }
              ]}},[_c('el-input',{staticClass:"jf-form",staticStyle:{"width":"90px"},attrs:{"size":"small","placeholder":"请输入"},model:{value:(fx.DF),callback:function ($$v) {_vm.$set(fx, "DF", $$v)},expression:"fx.DF"}})],1),_c('div',{staticClass:"tab-wz-buttl",staticStyle:{"margin-left":"30px"}},[_c('a',{staticClass:"tab-wz-butta c-red",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.delFX(item, i)}}},[_vm._v("删除")])])],1)}),0),(item.SSLB !== 3)?_c('div',{staticClass:"smtit-butt-box"},[_c('div',{staticClass:"tab-wz-buttl",staticStyle:{"margin-right":"20px"}},[_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.addFX(item, 1)}}},[_vm._v("+添加普通选项")])]),_c('div',{staticClass:"tab-wz-buttl"},[(
                item.WJFXBList && !item.WJFXBList.find(it => it.FXLX === 2)
              )?_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.addFX(item, 2)}}},[_vm._v("+添加“其他”选项")]):_vm._e()])]):_vm._e()]):_vm._e()])})],2),_c('div',{staticClass:"bigtit-add-box"},[_c('div',{staticClass:"tab-wz-buttl"},[_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":_vm.addItem}},[_vm._v("+ 新增问题")])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":_vm.confirmDialog}},[_vm._v("确 定")])],1),_c('el-dialog',{attrs:{"title":"填充分数","visible":_vm.batchFSobj.visible,"width":"30%","before-close":_vm.batchFS,"append-to-body":""},on:{"update:visible":function($event){return _vm.$set(_vm.batchFSobj, "visible", $event)}}},[_c('el-form',{ref:"batchFSobjForm",attrs:{"model":_vm.batchFSobj.form}},_vm._l((Object.keys(_vm.batchFSobj.form)),function(item,index){return _c('el-form-item',{key:index,staticStyle:{"display":"flex"},attrs:{"label":item,"prop":item,"rules":[{ validator: _vm.batchValChange, trigger: 'change' }]}},[_c('el-input',{attrs:{"placeholder":"请输入自然数或两位小数"},model:{value:(_vm.batchFSobj.form[item]),callback:function ($$v) {_vm.$set(_vm.batchFSobj.form, item, $$v)},expression:"batchFSobj.form[item]"}})],1)}),1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.batchFS}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.batchFS(1)}}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }