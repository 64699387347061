<template>
  <div>
    <div class="cz-mainbox">
      <div class="cz-mainbox-tit">一.成长树设置</div>
      <div class="cz-mainbox-conts">
        <el-checkbox v-model="setData.ZCSSZInfo.SFZS"
          >展示通用成长和特色成长树</el-checkbox
        >
      </div>
      <div class="cz-cont-name">• &nbsp;选择成长体系</div>
      <div class="cz-cont-box">
        <el-checkbox-group v-model="ZSTSCZTXIds" @change="addTx">
          <div class="disilb-box-l" style="width: 110px;">
            <el-checkbox
              :disabled="!setData.ZCSSZInfo.SFZS"
              v-for="(item, key) in basicInfo.Growthsets"
              :key="key"
              :label="item.Id"
              >{{ item.Name }}</el-checkbox
            >
          </div>
        </el-checkbox-group>
      </div>
      <div class="cz-cont-name">• &nbsp;设置通用成长图片</div>
      <div class="cz-cont-box">
        <div
          class="operate-box-l"
          v-for="(item, index) in setData.ZCSSZInfo.TYCZTPList"
          :key="item.Id"
        >
          <div class="czxh-box">{{ PrefixZero(index + 1, 2) }}</div>
          <div class="disilb-box-ll">名称：</div>
          <el-input
            style="width: 150px;"
            v-model="item.MC"
            placeholder="输入"
          ></el-input>

          <div class="disilb-box-ll">起始星</div>
          <el-input
            style="width: 150px;"
            v-model="item.QSX"
            placeholder="输入"
          ></el-input>
          <div class="disilb-box-ll">颗</div>

          <div class="disilb-box-ll" style="margin-left: 20px;">
            <img
              class="cz-imgsty"
              :src="item.TwoBTP ? item.TwoBTP : $store.state.defaultPic"
            />
            <Upload
              style="display: inline-block;"
              class="c-guree el-link--inner"
              @getResultUrl="saveImg"
              :item="{ type: 2, id: item.Id }"
              :html="html2"
              :mode="'split'"
            />
          </div>
          <div class="disilb-box-ll" style="margin-left: 20px;">
            <img
              class="cz-imgsty"
              :src="item.ThreeBTP ? item.ThreeBTP : $store.state.defaultPic"
            />
            <Upload
              style="display: inline-block;"
              class="c-guree el-link--inner"
              @getResultUrl="saveImg"
              :item="{ type: 3, id: item.Id }"
              :html="html3"
              :mode="'split'"
            />
          </div>
          <div class="disilb-box-ll" style="margin-left: 40px;">
            <el-link
              :disabled="!setData.ZCSSZInfo.SFZS"
              class="c-red"
              :underline="false"
              @click="delGrowthTreeSet(item.Id)"
              >删除</el-link
            >
          </div>
        </div>
        <div class="tab-wz-buttl" style="font-size: 16px;">
          <el-link
            :disabled="!setData.ZCSSZInfo.SFZS"
            type="primary"
            :underline="false"
            @click="addGrowthTreeSet"
            >+ 新增</el-link
          >
        </div>
      </div>
    </div>
    <div class="cz-mainbox">
      <div class="cz-mainbox-tit">
        二.末级维度与学生固定特殊活动之间的关联（此处的设置用于计算维度的分值）
      </div>
      <div class="tab-heng-box" style="margin-top: 10px;">
        <div class="table-er-box">
          <div class="table-er-butt" @click="editItem('')">
            <img class="table-er-buttimg" :src="btnImg" />
            <span class="table-er-buttspan">新增</span>
          </div>
        </div>
        <table cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <td>维度名称</td>
              <td>活动名称</td>
              <td>活动分取值（%）</td>
              <td>活动最高分</td>
              <td>操作</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in setData.MJWDGLHDList" :key="item.Id">
              <td>{{ item.WDMC }}</td>
              <td>{{ item.HDMC }}</td>
              <td>{{ item.HDFQZ }}</td>
              <td>{{ item.ZGF }}</td>
              <td>
                <div class="tab-wz-butt">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="editItem(item)"
                    >编辑</el-link
                  >
                  <el-link
                    style="margin-left: 10px;"
                    class="c-red"
                    :underline="false"
                    @click="delItem(item)"
                    >删除</el-link
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!--页码开始-->
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
        <!--页码结束-->
      </div>
    </div>
    <div class="cent-bott-butt">
      <a class="cent-bott-butta" @click="toSet2">下一步</a>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="cancelItem"
      :close-on-click-modal="false"
      center
    >
      <div class="tab-shu-box over-css" style="height: 250px;">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl">选择纬度</td>
              <td>
                <el-cascader
                  :props="{ expandTrigger: 'hover' }"
                  v-model="MJWDGLHDInfo.WDId"
                  :options="basicInfo.DimensionTree"
                  :show-all-levels="false"
                  placeholder="请选择末级维度"
                  style="width: 100%;"
                  @change="handleWdSelected"
                ></el-cascader>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">活动最高分值</td>
              <td>
                <!-- <input class="popv-tab-inpt" type="text" placeholder="请输入" /> -->
                <el-input
                  v-model="MJWDGLHDInfo.ZGF"
                  placeholder="请输入"
                ></el-input>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">活动分取值</td>
              <td>
                <el-input
                  style="width: 93%;"
                  v-model="MJWDGLHDInfo.HDFQZ"
                  placeholder="请输入"
                ></el-input
                >%
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">选择活动</td>
              <td>
                <el-radio-group
                  v-model="MJWDGLHDInfo.GLHDLX"
                  @change="handdleChange"
                >
                  <div
                    style="width: 140px; margin-bottom: 0px;"
                    class="disilb-box"
                  >
                    <el-radio :label="2">作业管理</el-radio>
                  </div>
                  <div
                    style="width: 140px; margin-bottom: 0px;"
                    class="disilb-box"
                  >
                    <el-radio :label="5">运动记录</el-radio>
                  </div>
                  <div
                    style="width: 140px; margin-bottom: 0px;"
                    class="disilb-box"
                  >
                    <el-radio :label="6">阅读记录</el-radio>
                  </div>
                  <div
                    style="width: 140px; margin-bottom: 0px;"
                    class="disilb-box"
                  >
                    <el-radio :label="8">体质数据</el-radio>
                  </div>
                </el-radio-group>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="cent-bott-butt">
        <a class="cent-bott-butta" @click="cancelItem">取&nbsp;消</a>
        <a class="cent-bott-butta" @click="saveItem">确&nbsp;认</a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Pager,
    Upload
  },
  data() {
    return {
      btnImg: require("@/style/images/icon_ejdaa.png"),
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      setData: {},
      dialogVisible: false,
      MJWDGLHDInfo: {},
      html2: '<el-link type="primary" :underline="false">更改2x图片</el-link>',
      html3: '<el-link type="primary" :underline="false">更改3x图片</el-link>',
      ZSTSCZTXIds: [],
      wdIds: []
    };
  },
  props: {
    setInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    basicInfo: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  created: function() {
    this.init();
  },
  methods: {
    init() {
      this.$set(this, "setData", JSON.parse(JSON.stringify(this.setInfo)));
      this.ZSTSCZTXIds = [];
      for (const i in this.setData.ZCSSZInfo.ZSTSCZTXList) {
        this.ZSTSCZTXIds.push(this.setData.ZCSSZInfo.ZSTSCZTXList[i].CZTXId);
      }
      this.pagerInfo.totalCount = this.setData.MJWDGLHDList.length;
      for (const item of this.setData.MJWDGLHDList) {
        for (const dimension of this.basicInfo.DimensionTree) {
          if (dimension.children) {
            for (const children of dimension.children) {
              if (children.value === item.WDId) {
                item.WDMC = dimension.label + ">" + children.label;
              }
            }
          }
        }
        if (item.GLHDLX === 2) {
          item.HDMC = "作业管理";
        } else if (item.GLHDLX === 5) {
          item.HDMC = "运动记录";
        } else if (item.GLHDLX === 6) {
          item.HDMC = "阅读记录";
        } else if (item.GLHDLX === 8) {
          item.HDMC = "体质数据";
        }
      }
    },
    toSet2() {
      this.$emit("toGeneral2", this.setData);
      scrollTo(0, 0);
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    editItem(data) {
      if (data) {
        this.MJWDGLHDInfo = JSON.parse(JSON.stringify(data));
      }
      this.dialogVisible = true;
    },
    cancelItem() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.MJWDGLHDInfo = {};
          this.dialogVisible = false;
        })
        .catch(() => {});
    },
    saveItem() {
      const index = this.setData.MJWDGLHDList.findIndex(
        c => c.Id === this.MJWDGLHDInfo.Id
      );
      if (index === -1) {
        this.MJWDGLHDInfo.Id = new Date().getTime();
        this.setData.MJWDGLHDList.push(this.MJWDGLHDInfo);
      } else {
        this.setData.MJWDGLHDList.splice(index, 1, this.MJWDGLHDInfo);
      }
      this.pagerInfo.totalCount = this.setData.MJWDGLHDList.length;
      this.MJWDGLHDInfo = {};
      this.dialogVisible = false;
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.setData.MJWDGLHDList.splice(
            this.setData.MJWDGLHDList.findIndex(c => c === data),
            1
          );
          this.pagerInfo.totalCount = this.setData.MJWDGLHDList.length;
        })
        .catch(() => {});
    },
    handdleChange(val) {
      if (val === 2) {
        this.MJWDGLHDInfo.HDMC = "作业管理";
      } else if (val === 5) {
        this.MJWDGLHDInfo.HDMC = "运动记录";
      } else if (val === 6) {
        this.MJWDGLHDInfo.HDMC = "阅读记录";
      } else if (val === 8) {
        this.MJWDGLHDInfo.HDMC = "体质数据";
      }
    },
    handleWdSelected(value) {
      if (value) {
        const dimension1 = this.basicInfo.DimensionTree.find(
          c => c.value === value[0]
        );
        this.MJWDGLHDInfo.WDMC = dimension1.label;
        this.MJWDGLHDInfo.WDId = value[0];
        if (dimension1 && dimension1.children) {
          const dimension2 = dimension1.children.find(
            c => c.value === value[1]
          );
          if (dimension2) {
            this.MJWDGLHDInfo.WDMC =
              this.MJWDGLHDInfo.WDMC + " > " + dimension2.label;
            this.MJWDGLHDInfo.WDId = value[1];
          }
        }
      }
    },
    addGrowthTreeSet() {
      this.setData.ZCSSZInfo.TYCZTPList.push({ Id: new Date().getTime() });
    },
    delGrowthTreeSet(id) {
      this.setData.ZCSSZInfo.TYCZTPList.splice(
        this.setData.ZCSSZInfo.TYCZTPList.findIndex(c => c.Id === id),
        1
      );
    },
    PrefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    saveImg(path, obj) {
      const info = this.setData.ZCSSZInfo.TYCZTPList.find(c => c.Id === obj.id);
      if (info) {
        if (obj.type === 2) {
          this.$set(info, "TwoBTP", path);
        } else if (obj.type === 3) {
          this.$set(info, "ThreeBTP", path);
        }
      }
    },
    addTx(value) {
      this.setData.ZCSSZInfo.ZSTSCZTXList = [];
      if (value) {
        for (const item of value) {
          const info = this.basicInfo.Growthsets.find(c => c.Id === item);
          if (info) {
            this.setData.ZCSSZInfo.ZSTSCZTXList.push({ CZTXId: info.Id });
          }
        }
      }
    }
  }
};
</script>
