<template>
  <el-dialog
    title="成长趋势活动设置"
    :visible.sync="show"
    width="55%"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-checkbox-group v-model="tendencyList">
      <el-checkbox
        v-for="item in tendencyActivies"
        :key="item.GLId"
        :label="item.GLId"
        >{{ item.PJHDMC }}</el-checkbox
      >
    </el-checkbox-group>
    <div style="display: flex; margin-top: 30px; justify-content: center">
      <el-button type="info" size="small" @click="$emit('update:show', false)"
        >关闭</el-button
      >
      <el-button type="primary" size="small" @click="setTendencyList()"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { GetGrowthEvaSet } from "@/api/growthReportSet.js";
export default {
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tendencyList: [],
      tendencyActivies: [],
    };
  },
  methods: {
    /**
     * 获取成长趋势统计活动
     */
    async loadTendencyActivies() {
      let res = await GetGrowthEvaSet({
        xqid: localStorage.getItem("currentterm"),
        SchoolId: this.userInfo.SSZZId,
        token: this.userInfo.Token,
      });
      //{GLId:"",HDLX:0,PJHDMC:"",SFSZ:1}
      this.tendencyActivies = res;
      this.tendencyList = this.tendencyActivies
        .filter((item) => item.SFSZ === 1)
        .map((item) => item.GLId);
    },
    /**
     * 保存成长趋势活动
     */
    setTendencyList() {
      let activeList = this.tendencyActivies.reduce((pre, cur) => {
        if (this.tendencyList.includes(cur.GLId)) {
          pre.push({
            GLId: cur.GLId,
            SJLX: cur.GLSJLX,
          });
        }
        return pre;
      }, []);
      new Promise((resolve, reject) => {
        this.$api.post(
          "v1/Growth/GrowthEvaSet",
          {
            SetData: activeList,
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.$emit("update:show", false);
            }
            resolve(res.Message);
          },
          (err) => {
            this.$message.error("err");
          }
        );
      });
    },
  },

  watch: {
    show(val) {
      if (this.show) {
        this.loadTendencyActivies();
      }
    },
  },
};
</script>
<style scoped>
.footer-button {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>