<template>
  <div class="tab-heng-box margno-t">
    <div class="table-er-box">
      <el-button v-if="isEdit" :loading="isSaving" plain @click="save"
        >保存</el-button
      >
      <el-button v-else plain @click="edit">编辑</el-button>
    </div>
    <table cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td>教师1次评价对应的分值</td>
          <td v-if="isEdit">
            <el-input
              style="width: 70px; vertical-align: middle; margin-right: 5px;"
              v-model="classScoreSet.TeacherScore"
              placeholder="输入"
            ></el-input
            >分
          </td>
          <td v-else>{{ classScoreSet.TeacherScore }}分</td>
        </tr>
        <tr>
          <td>班级获得的评价次数溢出值</td>
          <td v-if="isEdit">
            <el-input
              style="width: 70px; vertical-align: middle; margin-right: 5px;"
              v-model="classScoreSet.ClassScore"
              placeholder="输入"
            ></el-input
            >次
          </td>
          <td v-else>{{ classScoreSet.ClassScore }}次</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEdit: false,
      isSaving: false
    };
  },
  props: {
    classScoreSet: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    if (JSON.stringify(this.classScoreSet) === "{}") {
      this.isEdit = true;
    }
  },
  methods: {
    edit() {
      this.isEdit = true;
    },
    save() {
      this.isSaving = true;
      this.$api.post(
        "/v1/growthsystem/SetClassScoreSet",
        {
          token: this.token,
          ClassScoreSet: this.classScoreSet,
          UserId: this.user.Id,
          UserName: this.user.XSM,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.isSaving = false;
            this.isEdit = false;
          }
        }
      );
    }
  }
};
</script>
