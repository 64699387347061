<template>
  <div style="padding: 0px;">
    <el-dialog
      width="30%"
      title="选择年级"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="popo-name-box">
        <div
          :class="[
            'popo-name-cent',
            { 'name-cent-sty': tmpGradeList.indexOf(item.GradeId) != -1 }
          ]"
          v-for="item in gradeList"
          :key="item.GradeId"
          @click="selectGrade(item.GradeId)"
        >
          <span class="popo-name-centspan">{{ item.GradeNumStr }}</span>
          <img
            class="popo-name-centimg"
            src="../../../style/images/icon_treu_dui.png"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSelectedGrade">取 消</el-button>
        <el-button type="primary" @click="confirmSelectedGrade"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div
      class="operate-box operate-box-supple"
      style="
        padding-bottom: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 14px;
      "
    >
      报名前开始查询（报名前家长可查看内容，但要等到报名开始时才能提交报名）
      <el-input
        v-model="sigUpSet.PreviewTimeSpan"
        style="width: 50px;"
        class="popv-inpt"
        placeholder="请输入"
        size="medium"
      ></el-input
      >分钟。
    </div>
    <!--表格区开始-->
    <div class="operate-box operate-box-supple" style="margin-bottom: 0px;">
      <div class="sm-conten-tit">报名设置</div>
    </div>
    <div
      class="flex-tab-box"
      v-for="item in sigUpSet.sigUpSetList"
      :key="item.Id"
      style="margin-top: 20px;"
    >
      <div class="flex-tab-a">
        <el-input
          v-model="item.BMMC"
          style="width: 150px;"
          class="popv-inpt"
          placeholder="输入名称"
          size="medium"
        ></el-input>
      </div>
      <div class="flex-tab-b">
        <div class="flex-tab-ba">
          <div class="czq-name">开始时间:</div>
          <el-date-picker
            v-model="item.KSSJ"
            type="datetime"
            placeholder="选择日期时间"
            class="dateselect"
            value-format="yyyy-MM-dd HH:mm:ss"
            size="medium"
          ></el-date-picker>
          <div class="czq-name">结束时间:</div>
          <el-date-picker
            v-model="item.JSSJ"
            type="datetime"
            placeholder="选择日期时间"
            class="dateselect"
            value-format="yyyy-MM-dd HH:mm:ss"
            size="medium"
          ></el-date-picker>
        </div>
        <div class="flex-tab-ba border">
          <el-tag
            v-for="grade in item.GradeList.filter(c =>
              gradeList.map(item => item.GradeId).includes(c.NJId)
            )"
            :key="grade.NJId"
            closable
            effect="plain"
            style="margin-right: 10px;"
            @close="handleCloseManagerTag(item, grade)"
            >{{
              gradeList.filter(c => c.GradeId === grade.NJId)[0].GradeNumStr
            }}</el-tag
          >
          <div class="tab-wz-buttl">
            <el-link
              type="primary"
              :underline="false"
              @click="openGradeSelect(item.Id)"
              >添加</el-link
            >
          </div>
        </div>
      </div>
      <div class="flex-tab-c">
        <el-link type="danger" :underline="false" @click="delItem(item.Id)"
          >删除</el-link
        >
      </div>
    </div>
    <div class="tab-wz-buttl" style="font-size: 16px; margin-top: 15px;">
      <el-link type="primary" :underline="false" @click="addItem"
        >+添加报名</el-link
      >
    </div>

    <div class="cent-bott-butt">
      <el-button
        size="medium"
        style="margin: 0px 25px;"
        @click="submit"
        :loading="isSubmitting"
        >确认</el-button
      >
      <el-button size="medium" style="margin: 0px 25px;" @click="cancel"
        >取消</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sigUpSet: { PreviewTimeSpan: "", sigUpSetList: [], IsEnableSearch: "" },
      isSubmitting: false,
      innerVisible: false,
      tmpGradeList: [],
      currentSetId: ""
    };
  },
  props: {
    SchoolId: {
      type: String,
      default: ""
    },
    TermId: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    gradeList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    addItem() {
      this.sigUpSet.sigUpSetList.push({
        Id: new Date().getTime(),
        CJR: "",
        CJSJ: new Date().toLocaleDateString(),
        SSXX: "",
        SSXQ: "",
        KSSJ: "",
        JSSJ: "",
        BMMC: "",
        GradeList: []
      });
    },
    delItem(id) {
      this.sigUpSet.sigUpSetList.splice(
        this.sigUpSet.sigUpSetList.findIndex(c => c.Id === id),
        1
      );
    },
    initData() {
      this.$api.post(
        "/v1/curriculavariable/GetCurriculaVariableBasicSetInfo",
        {
          token: this.$store.state.token,
          TermId: this.TermId,
          SchoolId: this.SchoolId
        },
        r => {
          if (r.Code === 0) {
            this.sigUpSet = r.Target;
          }
        }
      );
    },
    submit() {
      this.isSubmitting = true;
      const user = JSON.parse(localStorage.getItem("userinfo"));
      this.$api.post(
        "/v1/curriculavariable/EditCurriculaVariableBasicSetInfo",
        {
          token: this.$store.state.token,
          CurriculaVariableBasicSetInfo: this.sigUpSet,
          UserName: user.XSM,
          UserId: user.Id,
          SchoolId: this.SchoolId,
          TermId: this.TermId
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
          }
          this.innerVisible = false;
        }
      );
      this.$emit("closeOtherDialog");
    },
    cancel() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeOtherDialog");
      });
    },
    selectGrade(id) {
      if (this.tmpGradeList.indexOf(id) !== -1) {
        this.tmpGradeList.splice(
          this.tmpGradeList.findIndex(c => c === id),
          1
        );
      } else {
        this.tmpGradeList.push(id);
      }
    },
    saveGradeList() {
      const index = this.sigUpSet.sigUpSetList.findIndex(
        c => c.Id === this.currentSetId
      );
      this.sigUpSet.sigUpSetList[index].GradeList = [];
      for (let i = 0; i < this.tmpGradeList.length; i++) {
        this.sigUpSet.sigUpSetList[index].GradeList.push({
          NJId: this.tmpGradeList[i],
          BMSZId: this.currentSetId
        });
      }
      this.tmpGradeList = [];
    },
    openGradeSelect(id) {
      this.currentSetId = id;
      this.innerVisible = true;

      const selectedGradeList = this.sigUpSet.sigUpSetList[
        this.sigUpSet.sigUpSetList.findIndex(c => c.Id === this.currentSetId)
      ].GradeList;
      if (selectedGradeList) {
        for (let i = 0; i < selectedGradeList.length; i++) {
          this.tmpGradeList.push(selectedGradeList[i].NJId);
        }
      }
    },
    handdlecheck() {
      if (this.sigUpSet.IsEnableSearch === "1") {
        this.sigUpSet.IsEnableSearch = "2";
      } else {
        this.sigUpSet.IsEnableSearch = "1";
      }
    },
    cancelSelectedGrade() {
      this.innerVisible = false;
      this.tmpGradeList = [];
    },
    confirmSelectedGrade() {
      this.innerVisible = false;
      this.saveGradeList();
    },
    handleCloseManagerTag(data, grade) {
      data.GradeList.splice(
        data.GradeList.findIndex(c => c.NJId === grade.NJId),
        1
      );
    }
  },
  created: function() {
    this.initData();
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        this.isSubmitting = false;
        this.initData();
      } else {
        // 重置校验结果
        // this.$refs["formData"].resetFields();
      }
    },
    innerVisible: function(newVal) {
      if (newVal) {
        this.isSubmitting = false;
      }
    }
  }
};
</script>
