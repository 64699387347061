<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <div class="table-er-box">
        <span class="ts-span-sm" style="margin-left: 5px;">
          学期 :
          <el-select
            size="small"
            v-model="termId"
            placeholder="请选择"
            @change="getBasicData"
          >
            <el-option
              v-for="item in basicData.TermList"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            ></el-option>
          </el-select>
        </span>
        <span class="ts-span-sm">
          教研组 :
          <el-select size="small" v-model="groupId" placeholder="请选择">
            <el-option
              v-for="item in basicData.TeachingGroupList"
              :key="item.Id"
              :label="item.JYZMC"
              :value="item.Id"
            ></el-option>
          </el-select>
        </span>
        <span class="ts-span-sm">
          班主任 :
          <el-select
            size="small"
            v-model="classHeaderFilter"
            placeholder="请选择"
          >
            <el-option
              v-for="item in [
                { value: 0, label: '全部' },
                { value: 1, label: '是' },
                { value: 2, label: '否' }
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </span>
        <span class="ts-span-sm">
          <el-input
            style="margin-left: 10px; width: 150px;"
            v-model="search"
            size="small"
            placeholder="关键字搜索"
          ></el-input>
          <!-- <el-button style="margin-left: 10px;" type="primary" size="small" @click="getData">搜索</el-button> -->
        </span>
      </div>
      <div class="table-er-box" style="height:unset">
        <div
          style="font-size: 14px;margin-bottom: 10px;display: inline-block;float: right;"
        >
          <el-button
            v-if="!editEnabled"
            type="text"
            size="small"
            @click="editEnabled = true"
            >编辑</el-button
          >
          <el-button
            v-else
            type="text"
            size="small"
            @click="editEnabled = false"
            >保存</el-button
          >
          <el-button
            v-if="!editEnabled"
            v-loading="isExporting"
            type="text"
            size="small"
            @click="exportExcel"
            >导出</el-button
          >
        </div>
      </div>
      <el-table
        :data="
          basicData.TeacherList.filter(
            c =>
              (Array.from(new Set(JSON.parse(c.JYZIds)))
                .filter(c => c !== null)
                .includes(groupId) ||
                groupId === 'all') &&
              (c.SFBZR === classHeaderFilter || classHeaderFilter === 0) &&
              (c.XM.includes(search) || search.length === 0)
          ).slice(
            (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
            (pagerInfo.pageIndex - 1) * pagerInfo.pageSize + pagerInfo.pageSize
          )
        "
        class="jf-table"
        border
        tooltip-effect="dark"
        style="width: 100%;"
        v-loading="isLoading"
      >
        <el-table-column
          align="center"
          fixed
          width="150"
          prop="XH"
          label="教师编号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="XM"
          label="教师姓名"
        ></el-table-column>
        <el-table-column align="center" label="教研组">
          <template slot-scope="scope">
            {{
              basicData.TeachingGroupList.filter(c =>
                Array.from(new Set(JSON.parse(scope.row.JYZIds)))
                  .filter(c => c !== null)
                  .includes(c.Id)
              )
                .map(c => c.JYZMC)
                .join()
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否为班主任">
          <template slot-scope="scope">
            {{ scope.row.SFBZR === 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="计划完成目标">
          <template slot-scope="scope">
            <el-input-number
              v-if="editEnabled"
              v-model="tableData.find(c => c.JSId === scope.row.Id).BMPJL"
              :min="0"
              :step="1"
              size="mini"
              step-strictly
              :controls="false"
            ></el-input-number>
            <div v-else>
              {{
                tableData
                  .filter(c => c.JSId === scope.row.Id)
                  .map(c => c.BMPJL)
                  .join()
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--页码开始-->
      <div class="pagin-box">
        <div class="pagin-wz">
          <!--分页-->
          <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
        </div>
      </div>
      <!--表格区结束-->
    </div>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 9,
      // pagerInfo: {
      //   pageIndex: 0,
      //   pageSize: 9,
      //   totalCount: 0
      // },
      token: "",
      user: {},
      schoolId: "",
      tableData: [],
      basicData: { TeacherList: [] },
      termId: "",
      search: "",
      groupId: "all",
      classHeaderFilter: 0,
      editEnabled: false,
      isLoading: false,
      isExporting: false
    };
  },
  mounted: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    // this.pagerInfo.totalCount = this.dataCount;
    this.getBasicData();
  },
  methods: {
    changeIndex(val) {
      this.pageIndex = val;
    },
    getBasicData() {
      this.isLoading = true;
      this.$api.post(
        "/v1/teacherevaluationcountplanset/GetTeacherEvaluationCountPlanSetBasicData",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.basicData = r.Target;
            this.basicData.TeachingGroupList.unshift({
              Id: "all",
              JYZMC: "全部"
            });
            let term = this.basicData.TermList[0];
            if (term && this.termId.length === 0) {
              this.termId = term.Id;
            }
            // this.pagerInfo.totalCount = this.basicData.TeacherList.length;
            this.getData();
          }
        }
      );
    },
    getData() {
      this.$api.post(
        "/v1/teacherevaluationcountplanset/GetTeacherEvaluationCountPlanSetData",
        {
          token: this.token,
          SchoolId: this.schoolId,
          TermId: this.termId
        },
        r => {
          if (r.Code === 0) {
            let data = r.Target;
            this.tableData = this.basicData.TeacherList.filter(
              c => !data.map(c => c.JSId).includes(c.Id)
            )
              .map(c => ({ JSId: c.Id, BMPJL: undefined }))
              .concat(data);
            this.isLoading = false;
          }
        }
      );
    },
    submit() {
      this.isLoading = true;
      this.$api.post(
        "/v1/teacherevaluationcountplanset/EditTeacherEvaluationCountPlanSetData",
        {
          token: this.$store.state.token,
          SchoolId: this.user.SSZZId,
          TermId: this.termId,
          UserId: this.user.Id,
          UserName: this.user.XSM,
          JSMBJHBList: this.tableData.filter(c => c.BMPJL)
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.editEnabled = false;
            this.isLoading = false;
          }
        }
      );
    },
    exportExcel() {
      this.isExporting = true;
      import("@/style/js/Export2Excel").then(excel => {
        let tHeader = [
          "教师编号",
          "教师名称",
          "教研组",
          "是否为班主任",
          "计划完成目标"
        ];
        let filterVal = ["XH", "XM", "JYZ", "SFBZR", "BMPJL"];
        let data = this.formatJson(
          filterVal,
          this.basicData.TeacherList.filter(
            c =>
              (Array.from(new Set(JSON.parse(c.JYZIds)))
                .filter(c => c !== null)
                .includes(this.groupId) ||
                this.groupId === "all") &&
              (c.SFBZR === this.classHeaderFilter ||
                this.classHeaderFilter === 0) &&
              (c.XM.includes(this.search) || this.search.length === 0)
          ).map(c => ({
            XH: c.XH,
            XM: c.XM,
            JYZ: this.basicData.TeachingGroupList.filter(item =>
              Array.from(new Set(JSON.parse(c.JYZIds)))
                .filter(i => i !== null)
                .includes(item.Id)
            )
              .map(item => item.JYZMC)
              .join(),
            SFBZR: c.SFBZR === 1 ? "是" : "否",
            BMPJL: this.tableData
              .filter(item => item.JSId === c.Id)
              .map(item => item.BMPJL)
              .join()
          }))
        );
        excel.export_json_to_excel({
          header: tHeader, //表头 必填
          data, //具体数据 必填
          filename: new Date().getTime() + "" //非必填
        });
        this.isExporting = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    }
  },
  watch: {
    editEnabled(val) {
      if (val) {
        // 显示
      } else {
        // 隐藏
        this.submit();
        // this.editEnabled = false;
        // this.$message({
        //   type: "success",
        //   message: "保存"
        // });
      }
    }
  },
  computed: {
    pagerInfo() {
      return {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        totalCount: this.basicData.TeacherList.filter(
          c =>
            (Array.from(new Set(JSON.parse(c.JYZIds)))
              .filter(c => c !== null)
              .includes(this.groupId) ||
              this.groupId === "all") &&
            (c.SFBZR === this.classHeaderFilter ||
              this.classHeaderFilter === 0) &&
            (c.XM.includes(this.search) || this.search.length === 0)
        ).length
      };
    }
  }
};
</script>
