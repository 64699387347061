<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->
      <div class="tab-heng-box margno-t">
        <!--<el-select v-model="termId" @change="selectChange" placeholder="请选择学期">
                    <el-option v-for="option in termList" :label="option.XQMC" :value="option.Id">
                        {{option.XQMC}}
                    </el-option>
        </el-select>-->
        <br />
        <br />
        <el-table :data="tableData" border style="width: 100%;">
          <el-table-column prop="SSXD" label="所属学段" width="180">
            <template slot-scope="scope">
              <p v-if="scope.row.SSXD == '1'">小学段</p>
              <p v-else-if="scope.row.SSXD == '2'">中学段</p>
            </template>
          </el-table-column>
          <el-table-column prop="ZS" label="周数" width="180"></el-table-column>
          <el-table-column label="开始时间">
            <template slot-scope="scope">{{
              scope.row.KSSJ | DateFormat("yyyy-MM-dd hh:mm:ss")
            }}</template>
          </el-table-column>
          <el-table-column label="结束时间">
            <template slot-scope="scope">{{
              scope.row.JSSJ | DateFormat("yyyy-MM-dd hh:mm:ss")
            }}</template>
          </el-table-column>
          <el-table-column prop="SSY" label="所属月"></el-table-column>
        </el-table>

        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="rowcount"
          ></el-pagination>
        </div>
      </div>
      <!--表格区结束-->
    </div>
  </div>
</template>

<script>
export default {
  name: "XqWeekList",
  data() {
    return {
      token: this.$store.state.token,
      // loading:true,
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      termId: "",
      termList: [],
      schoolId: ""
    };
  },
  created: function() {
    // let userinfo = JSON.parse(sessionStorage.getItem('userinfo'));;

    // this.schoolId = userinfo.SSZZId;

    // let termpagerInfo = { pageIndex: 0, pageSize: this.pagesize };

    // this.$api.post("v1/xqb/GetXQBList", { SchoolId: this.schoolId, PagerInfo: termpagerInfo, token: this.token }, r => {
    //    if (r.Code === 0) {

    //        this.termList = r.Target;

    //        if (this.termList !== null) {

    //            if (this.termList.length > 0) {

    //                this.termId = this.termList[0].Id;

    //            }
    //        }

    //    }
    // });

    if (this.$route.params.termId !== null) {
      this.termId = this.$route.params.termId;
    }

    const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };

    this.$api.post(
      "v1/xqzcb/GetXQZCBList",
      { TermId: this.termId, PagerInfo: pagerInfo, token: this.token },
      r => {
        if (r.Code === 0) {
          this.tableData = r.Target;

          this.currentPage = r.PagerInfo.PageIndex;
          this.pagesize = r.PagerInfo.PageSize;
          this.rowcount = r.PagerInfo.RowCount;

          // loading=false;
        }
      }
    );
  },
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);

      const pagerInfo = { pageIndex: 0, pageSize: val };

      this.$api.post(
        "v1/xqzcb/GetXQZCBList",
        { TermId: this.termId, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
            this.currentPage = r.PagerInfo.PageIndex;
            this.pagesize = r.PagerInfo.PageSize;
            this.rowcount = r.PagerInfo.RowCount;
            // loading=false;
          }
        }
      );
    },
    handleCurrentChange(val) {
      // console.log(`当前页1: ${val}`);

      const pagerInfo = { pageIndex: val, pageSize: this.pagesize };

      this.$api.post(
        "v1/xqzcb/GetXQZCBList",
        { TermId: this.termId, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
            this.currentPage = r.PagerInfo.PageIndex;
            this.pagesize = r.PagerInfo.PageSize;
            this.rowcount = r.PagerInfo.RowCount;
            // loading=false;
          }
        }
      );
    },
    selectChange() {
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };

      this.$api.post(
        "v1/xqzcb/GetXQZCBList",
        { TermId: this.termId, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
            this.currentPage = r.PagerInfo.PageIndex;
            this.pagesize = r.PagerInfo.PageSize;
            this.rowcount = r.PagerInfo.RowCount;
          }
        }
      );
    }
  }
};
</script>
<style>
.cell {
  text-align: left;
}
</style>
