<template>
  <el-dialog top="5vh" title="新增" :visible.sync="value" width="60%" ref="dialog"
        :close-on-click-modal="false" :before-close="close">
        <el-form status-icon :model="form" ref="form" :rules="rules">
            <el-form-item prop="JHMC" >
                <div style="display: flex;margin-top: 20px;">
                    <div style="width: 100px;">报表名称：</div>
                    <el-input style="max-width: 218px" placeholder="不超过20个字" suffix-icon="el-icon-edit" 
                        v-model="form.JHMC" autocomplete="on" >
                    </el-input>
                </div>
            </el-form-item>
            <el-form-item  prop="XQJH" >
                <div style="margin-top: 20px;">
                    <div style="width: 100px;">学期选择：</div>
                    <el-checkbox-group style="margin-top: 20px;" v-model="form.XQJH">
                        <el-checkbox v-for="item in termList" :label="item.Id" :key="item.Id">{{item.Name}}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form-item>
            <el-form-item prop="IsSelect">
              <el-checkbox v-model="form.IsSelect" >给合计为各项平均，当某人某项为0分时，此人此项不计入分母</el-checkbox>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer center">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
  props: {
      visible: {
          type: Boolean,
          default: false
      },
      termList: {
        type: Array,
        default() {
          return [];
        }
      },
    },
    model: {
        prop: "visible",
        event: "change"
    },
  data(){
    return{
      value:false,

      form:{
          XQJH:[],
          IsSelect:false
      },
      user:'',
      rules:{
        JHMC:[
              { required: true, message: "请输入报表名称", trigger: "change" },              
        ],
        XQJH:[
            {required: true, message: "请选择报表学期集合", trigger: "change"}
        ]
      }
    }
  },
  created(){
    this.user = JSON.parse(localStorage.getItem("userinfo"));
  },
  methods:{
    //弹窗的×
    close(){
      this.$emit('canceldialog')
    },
    cancel(){
      this.$confirm("确认关闭？").then(() => {
          this.$emit('canceldialog')
      }).catch(()=>{})
    },
    confirm(){
      let form = {
          SSXX : this.user.SSZZId,
          SFWC : 2,
          CJR : this.user.Id,
          XQJH : this.form.XQJH?.join(","),
          JHMC:this.form.JHMC,
          IsSelect:this.form.IsSelect,
          BBLX:2    //1-职业道德 2-通用教师
      }
      this.$refs.form.validate((valid)=>{
          if (valid) {
              this.$emit('sumbitForm',form)
          }
      })
    },

  },
  watch:{
    visible(val) {
        this.value = val;
        if (this.$refs.form) {
            this.$refs.form.resetFields()
        }
        
    }
  }
}
</script>

<style>

</style>