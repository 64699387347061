<template>
  <!-- 新增/编辑学生获奖 -->
  <div class="edit-student-award">
    <el-form
      style="width: 800px"
      :model="hjglinfo"
      ref="sbmitfrom"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="获奖学生：" prop="HJRY">
        <span v-for="(item, index) in xsid" :key="index">
          {{ item.Name }}
        </span>
        <el-button
          type="primary"
          size="small"
          :disabled="!!hjglinfo.Id"
          @click="handleChange"
          >{{ hjglinfo.Id ? '更改' : '添加' }}</el-button
        >
      </el-form-item>
      <el-form-item label="获奖名称：" prop="HJMC">
        <el-input
          v-model="hjglinfo.HJMC"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="获奖类别：" prop="HJLB">
        <el-select
          style="width: 100%"
          v-model="hjglinfo.HJLB"
          placeholder="请选择获奖类别"
          @change="getHJJBById"
        >
          <el-option
            v-for="item in lblist"
            :key="item.Id"
            :label="item.LBMC"
            :value="item.Id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="获奖级别：" prop="DJId">
        <el-cascader
          v-model="hjglinfo.DJId"
          :options="DJList"
          ref="elCascader"
          @change="getDJMC"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="计分比例：" prop="BLID">
        <span v-show="!BLList.length">请先选择获奖类别</span>
        <el-radio-group
          v-model="hjglinfo.BLID"
          v-for="item in BLList"
          :key="item.Id"
          style="margin-right: 10px"
        >
          <el-radio :label="item">{{ item.MC }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="获奖时间：" prop="HJSJ">
        <el-date-picker
          v-model="hjglinfo.HJSJ"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="主办单位：" prop="ZBDW">
        <el-input v-model="hjglinfo.ZBDW"></el-input>
      </el-form-item>
      <el-form-item label="描述：" prop="BZ">
        <el-input
          type="textarea"
          :autosize="{ minRows: 6 }"
          placeholder="请输入描述"
          v-model="hjglinfo.BZ"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="上传图片：">
        <el-upload
          class="upload-demo"
          list-type="picture-card"
          accept="image/jpeg, image/gif, image/png"
          :action="uploadURL"
          :on-error="upLoadError"
          :on-success="handleAvatarSuccess"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :limit="6"
          :on-exceed="handleExceed"
          :auto-upload="true"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <br />
      <el-form-item>
        <el-button type="primary" @click="submitshowdata" :disabled="isDisabled"
          >保存</el-button
        >
        <el-button @click="canceladd">取消</el-button>
      </el-form-item>
    </el-form>

    <!-- 选择学生弹框 -->
    <el-dialog
      title="选择学生"
      width="90%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleChangeClose"
    >
      <div class="filter-container">
        <div class="filter-item">
          <div class="filter-item-label">学段</div>
          <div class="filter-item-content">
            <div v-for="xd in xdlist" :key="xd.value">
              <div
                class="content-item"
                :class="{ 'cur-content-item': xdid === xd.value }"
                @click="xdchange(xd.value)"
              >
                {{ xd.label }}
              </div>
            </div>
          </div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">年级</div>
          <div class="filter-item-content">
            <div
              v-for="nj in njlist"
              :key="nj.Id"
              class="content-item"
              :class="{ 'cur-content-item': njid === nj.Id }"
              @click="njchange(nj.Id)"
            >
              {{ nj.Name }}
            </div>
          </div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">班级</div>
          <div class="filter-item-content">
            <div
              class="content-item"
              :class="{ 'cur-content-item': bjid === bj.Id }"
              v-for="bj in bjlist"
              :key="bj.Id"
              @click="bjchange(bj.Id)"
            >
              {{ bj.Name }}
            </div>
          </div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">学生</div>
          <div class="filter-item-content">
            <div
              v-for="xs in xslist"
              :key="xs.Id"
              class="content-item"
              :class="{ 'cur-content-item': xsid.some(it => it.Id == xs.Id) }"
              @click="xschange(xs)"
            >
              {{ xs.Name }}
            </div>
          </div>
        </div>
        <el-button type="primary" size="" @click="xsconfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.ssxq = localStorage.getItem('currentterm')

    //获取缓存学期
    let term = localStorage.getItem('curTerm')
    if (term) this.term = JSON.parse(term)

    // 获取缓存学生
    let students = localStorage.getItem('OrgStudent')
    if (students) this.schoolStudents = JSON.parse(students)

    //从缓存获取类别中的所有信息,包括学生，教师的类别
    let list = JSON.parse(localStorage.getItem('XXHJLBList'))
    if (list) {
      this.xxHJLBList = list.XXHJFLList
    }

    var tempuser = localStorage.getItem('userinfo')
    this.hjglinfo.Id = this.$route.params.hdid
    if (tempuser) {
      var user = JSON.parse(tempuser)
      this.ssxx = user.SSZZId
      this.cjr = user.Id
      this.hjglinfo.CJRLB = user.YHLX
      this.token = user.Token
      this.fileServerApiPath = user.FileServerApiPath
      this.webFilePath = user.WebFilePath
    }
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return `/file/file/fileservice/UploadFile?token=${this.token}&attachtype=1`
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      let { form, djlist, xdlist } = this.$route.params
      this.ClassTeacherId = JSON.parse(
        localStorage.getItem('TeacherSign')
      )?.ClassTeacher
      this.xdlist = xdlist
      this.xdid = this.xdlist[0].value
      this.xdchange(this.xdid)

      //选择获奖类别(学生的)
      this.lblist = this.xxHJLBList.filter(i => {
        if (i.HJFLDJList?.length > 0) {
          i.HJFLDJList.forEach(it => {
            it.value = it.Id
            it.label = it.MC
            it.level = it.CJ
            let res = i.HJFLDJList.filter(t => t.FID == it.Id)

            it.children = res.length > 0 ? res : null
          })
          i.HJFLDJList = i.HJFLDJList.filter(o => o.CJ == 1)
        }
        return i.SSLB == 1
      })
      this.lblist.sort((a, b) => {
        a.PXH - b.PXH
      })

      //编辑
      if (form.Id) {
        this.getHJJBById(form.HJLB)
        // 处理图片
        this.hjglinfo = {
          ...this.hjglinfo,
          ...form
        }
        this.hjglinfo.BLID = this.BLList.find(i => i.Id == form.BLID)
        let a = this.schoolStudents.find(i => i.Id == form.CYId)
        if (a) {
          this.xsid.push(a)
        }

        if (form.FjList && form.FjList.length > 0) {
          this.fileList = form.FjList.map(item => ({
            name: item.FJMC,
            url: item.FJLJ,
            FJMC: item.FJMC,
            FJLJ: item.FJLJ
          }))
          this.filearr = form.FjList.map(item => ({
            name: item.FJMC,
            url: item.FJLJ,
            FJMC: item.FJMC,
            FJLJ: item.FJLJ
          }))
        }
        this.$nextTick(() => {
          this.$set(this.hjglinfo, 'DJMC', form.DJMC)
          this.$forceUpdate()
        })
      }
      this.loaddata()
    },
    // 删除图片
    handleRemove(file) {
      if (file.FJLJ) {
        this.filearr = this.filearr.filter(item => item.FJLJ !== file.FJLJ)
      } else {
        this.filearr = this.filearr.filter(
          item => item.FJLJ !== file.response.Target.FilePath
        )
      }
    },
    // 删除前的操作
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 文件上传成功
    handleAvatarSuccess(response) {
      console.log(response, 'response')
      if (response.Code == -3) {
        this.$message.warning(`附件上传失败`)
        return
      } else {
        this.filearr.push({
          name: response.Target.FileName,
          url: response.Target.FilePath,
          FJMC: response.Target.FileName,
          FJLJ: response.Target.FilePath
        })
      }
    },

    //获取二级列表的值
    getDJMC(value) {
      let a = ''
      //编辑时
      if (this.hjglinfo.Id != null && this.hjglinfo.Id) {
        if (value.length == 2) {
          a = value[value.length - 1]
        } else {
          a = value[0]
        }
      } else {
        //新增时
        if (value.length == 2) {
          a = value[value.length - 1]
        } else {
          a = value[0]
        }
      }
      this.hjglinfo.DJId = a
      this.DJList.forEach(item => {
        if (item.Id == a) {
          this.hjglinfo.DJMC = item.MC
        } else {
          if (item.children != null && item.children.length > 0) {
            item.children.forEach(it => {
              if (it.Id == a) {
                let c = item.MC
                this.hjglinfo.DJMC = c + it.MC
                this.hjglinfo.DJFS = it.FS
              }
            })
          }
        }
      })
    },
    //移除空数组
    removeChild(target) {
      target.forEach(item => {
        item['value'] = item.Id
        for (const key in item) {
          if (!item.children?.length) {
            item.children = null
          } else {
            this.removeChild(item.children)
          }
        }
      })
    },
    //根据id获取获奖级别
    getHJJBById(id) {
      let a = this.lblist.find(i => i.Id == id)
      this.BLList = a.HJJFBLList
      this.DJList = a.HJFLDJList
      this.DJList.sort((a, b) => a.PXH - b.PXH)
      this.DJList.forEach(item => {
        if (item.children != null && item.children.length > 0) {
          item.children.sort((a, b) => a.PXH - b.PXH)
        }
      })
      this.hjglinfo.LBMC = a.LBMC
    },
    //确认选择多个学生this.hjglinfo
    xsconfirm() {
      this.hjglinfo.CYMC = this.choosexs
      this.dialogVisible = false
    },
    //文件上传失败时的钩子
    upLoadError() {
      this.$message.info(`附件上传失败`)
    },
    // 上传图片数量限制
    handleExceed(file, fileList) {
      this.$message.info(`最多只能上传6张图片`)
    },
    //取消
    canceladd() {
      this.$router.push({ path: '/studentawardmanage/studentawardlist' })
    },
    // 提交
    submitshowdata() {
      // 年级id没回显
      this.hjglinfo.HJRY = this.xsid[0].Name
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          let fjstr = ''
          if (this.filearr && this.filearr.length > 0) {
            fjstr = this.filearr
          } else {
            fjstr = null
          }
          let CYIdList = []
          this.xsid.forEach(i => {
            CYIdList.push({
              Id: i.Id,
              XM: i.Name,
              BJId: i.ClassId,
              NJId: i.GradeId
            })
          })

          //找选择等级的对应的分数
          let data = {
            Id: this.hjglinfo.Id || '',
            HJMC: this.hjglinfo.HJMC,
            HJLB: this.hjglinfo.HJLB,
            LBMC: this.hjglinfo.LBMC,
            BZ: this.hjglinfo.BZ,
            CYLB: 1, // 成员类别：1学生3班级2教师4社团小组
            HJRY: CYIdList,
            SSXQ: this.ssxq,
            XQLX: this.term.XQLX,
            CJRLB: this.hjglinfo.CJRLB,
            FJArr: fjstr || [],
            DF: (this.hjglinfo.BLID.BL * this.hjglinfo.DJFS) / 100,
            CJR: this.cjr,
            SHZT: 2, //审核通过
            SSXN: this.term.SSXN,
            HJSJ: this.hjglinfo.HJSJ,
            BLID: this.hjglinfo.BLID.Id,
            BLMC: this.hjglinfo.BLID.MC,
            DJId: this.hjglinfo.DJId,
            DJMC: this.hjglinfo.DJMC,
            ZBDW: this.hjglinfo.ZBDW,
            token: this.token,
            SSXX: this.ssxx
          }

          this.isDisabled = true
          this.$api.post('v1/awards/AddAward', data, r => {
            if (r.Code == 0) {
              this.$message({
                type: 'info',
                message: '保存成功!'
              })
              this.isDisabled = false
              this.$router.push({
                path: '/studentawardmanage/studentawardlist'
              })
            } else {
              this.$message({
                type: 'info',
                message: r.Message
              })
              this.isDisabled = false
            }
          })
        } else {
          this.isDisabled = false
        }
      })
    },
    //确定单选项
    handleConfirm() {
      this.$refs.optiontextValidateForm.validate(valid => {
        if (valid) {
          this.srdxx.push(this.optiontextValidateForm.optiontext)
          this.optiontextValidateForm.optiontext = ''
          this.dialogVisible = false
        } else {
          return false
        }
      })
    },
    //更改
    handleChange() {
      this.pageIndex = 1
      this.dialogVisible = true
      this.filterStudent()
    },
    //关闭单选项
    handleChangeClose() {
      this.dialogVisible = false
    },
    // 学段变化
    xdchange(id) {
      let xd = this.xdlist.find(item => item.value === id)
      this.xdid = id
      this.njlist = xd.njlist.slice(1)
      console.log('this.njlist', this.njlist)

      if (this.njlist[0]) this.njchange(this.njlist[0].Id)
    },
    njchange(id) {
      this.njid = id
      let res = this.njlist.find(item => item.Id === id)
      if (res && res.ChidOrgList) {
        this.bjlist = res.ChidOrgList
      } else this.bjlist = []

      if (this.bjlist[0]) this.bjchange(this.bjlist[0].Id)
    },
    bjchange(id) {
      this.bjid = id
      this.filterStudent()
    },
    // 筛选学生
    filterStudent() {
      this.xslist = this.schoolStudents.filter(
        item => item.ClassId === this.bjid
      )
    },
    // 点击学生
    xschange(data) {
      const index = this.xsid.findIndex(i => i.Id === data.Id)
      if (index === -1) {
        this.xsid.push(data)
      } else {
        this.xsid.splice(index, 1)
      }
    },
    // 获取学生
    loaddata() {
      let filterList = []
      this.ClassTeacherId?.forEach(it => {
        this.schoolStudents?.forEach(item => {
          if (it === item.ClassId) {
            filterList.push(item)
          }
        })
      })
      this.xslist = filterList
      this.filterStudent()
      // return new Promise((resolve, reject) => {
      //   this.$api.post(
      //     "v1/accountmanage/GetOrgStudent",
      //     {
      //       SchoolOrgId: this.ssxx, //	是	string	组织机构ID/活动Id
      //       OrgType: 0, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
      //       SourceType: 1, //	是	int	1 通用 2：课辅活动
      //       TermYearStr: this.term.SSXN, //	是	string	学年
      //       SchoolId: this.ssxx, //	否	string	学校Id
      //       IsDelete: 2, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
      //       token: this.token,
      //     },
      //     (r) => {
      //       if (r.Code == 0) {
      //         this.schoolStudents = r.Target || [];
      //         console.log(this.schoolStudents);
      //         //过滤班主任的学生
      //         let filterList = [];
      //         this.ClassTeacherId?.forEach((it) => {
      //           this.schoolStudents?.forEach((item) => {
      //             if (it === item.ClassId) {
      //               filterList.push(item);
      //             }
      //           });
      //         });
      //         this.xslist = filterList;
      //         console.log(filterList);
      //         this.filterStudent();
      //         resolve();
      //       }
      //     }
      //   );
      // });
    },

    // 获取年级
    loadnjdata() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          { SchoolId: this.ssxx, token: this.token },
          r => {
            if (r.Code == 0) {
              this.zzjglist = r.Target
              this.njlist = this.zzjglist.filter(
                item => item.SSXDLX === this.xdid
              )
              if (this.njlist[0]) {
                this.njid = this.njlist[0].Id
              }
              resolve()
            }
          }
        )
      })
    },
    // 获取班级
    loadbjdata() {
      if (this.njlist[0]) {
        this.bjlist = this.njlist[0].ChidOrgList || []
        if (this.bjlist[0]) this.bjid = this.bjlist[0].Id
      } else {
        this.bjlist = []
      }
    },
    //翻页
    handleSizeChange(val) {
      this.pagesize = val
      this.filterStudent()
    },
    //翻页查询
    handleCurrentChange(val) {
      this.pageIndex = val
      this.filterStudent()
    }
  },
  data() {
    return {
      isDisabled: false,
      webFilePath: '',
      fileServerApiPath: '',
      dialogFormVisible: false,
      ssxx: '',
      ssxq: '',
      term: {},
      dialogVisible: false,
      token: '',
      lblist: [],
      BLList: [],
      DJList: [],
      xxHJLBList: [],
      djlist: [],
      xdlist: [],
      njlist: [],
      schoolStudents: [],
      xslist: [],
      orgid: '',
      xdid: '',
      njid: '',
      bjid: '',
      xsid: [],
      ClassTeacherId: '',
      pageIndex: 1,
      pagesize: 30,
      rowcount: 0,
      bjlist: [],
      zzjglist: [],
      srfslx: [],
      srdxx: [],
      filearr: [],
      fileList: [],
      cjr: '',
      hjglinfo: {
        Id: '',
        CJR: '',
        CJSJ: '',
        HJRY: '',
        HJMC: '',
        DJId: '', //等级ID
        DJMC: '', //等级名称
        DJFS: '', //等级分数
        HJLB: '', //类别ID
        LBMC: '', //列别名称
        BZ: '',
        BLID: '',
        BLMC: '',
        CYMC: ['请选择获奖学生'],
        HJSJ: '',
        CJRLB: 0,
        DF: 0,
        ZBDW: '',
        SHZT: 1
      },
      rules: {
        HJMC: [
          { required: true, message: '请输入获奖名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在1到50个字符', trigger: 'change' }
        ],
        HJRY: [
          { required: true, message: '请选择获奖成员', trigger: 'change' }
        ],
        BZ: [
          {
            min: 0,
            max: 500,
            message: '长度不能超过500个字符',
            trigger: 'change'
          }
        ],
        HJLB: [
          { required: true, message: '请选择获奖类别', trigger: 'change' }
        ],
        DJId: [
          { required: true, message: '请选择获奖级别', trigger: 'change' }
        ],
        BLID: [
          { required: true, message: '请选择计分比例', trigger: 'change' }
        ],
        HJSJ: [{ required: true, message: '请选择时间', trigger: 'change' }]
      },
      schoolDetail: {}, //学校详情
      testvalue: []
    }
  },
  destroyed() {
    localStorage.removeItem('TeacherSign')
  }
}
</script>

<style lang="scss" scoped>
.edit-student-award {
  .filter-container {
    padding-top: 10px;
    .filter-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      box-shadow: 1px 1px 2px 2px #ededed;
      padding: 10px;
      .filter-item-label {
        width: 40px;
        text-align: center;
        margin-right: 15px;
      }
      .filter-item-content {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 10px 0 10px;
        .content-item {
          padding: 5px 10px;
          border-radius: 5px;
          box-shadow: 1px 1px 2px 2px #ededed;
          margin-bottom: 10px;
          margin-right: 10px;
          cursor: pointer;
          &.cur-content-item {
            color: #fff;
            background-color: #0fd59d;
          }
        }
      }
    }
  }
}
</style>
