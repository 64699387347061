<template>
  <div>
    <div
      v-if="
        !IsEdit &&
          levelsetData.list !== undefined &&
          levelsetData.list.length > 0 &&
          levelsetData.list.find(
            c => c.JJMC !== undefined && c.JJMC !== null && c.JJMC !== ''
          )
      "
    >
      <div v-for="item in levelsetData.list" :key="item.Id" class="div-liu">
        <span class="span-liu">
          晋级名称:
          <span>{{ item.JJMC }}</span>
        </span>
        <span class="span-liu">
          {{ PJFS === 2 ? "星数" : "分数" }}:
          <span>{{ item.JJFS }}</span>
        </span>
        <img
          class="img-liu"
          :src="
            item.TB
              ? item.TB
              : 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'
          "
        />
      </div>
      <!-- <div v-for="item in levelsetData.list" :key="item.Id">
        <div style="margin-bottom:22px">
    <span style="margin-right:30px">晋级名称:</span>
    <span style="margin-right:84px">{{item.JJMC}}</span>
    <span style="margin-right:28px">分数:</span>
    <span style="margin-right:128px">{{item.JJFS}}</span>
    <span><img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" style="height:22px;width:22px;border-radius:50%"></span>
      </div>

      </div>-->
    </div>

    <el-button
      type="primary"
      size="mini"
      v-if="!IsEdit"
      @click="IsEdit = true"
      >{{ EditBtnTxt }}</el-button
    >

    <el-form
      :inline="true"
      v-if="IsEdit"
      :model="levelsetData"
      label-width="100px"
      class="demo-dynamic"
    >
      <div v-for="(item, index) in levelsetData.list" :key="item.Id">
        <el-form-item label-width="70px" label="晋级名称">
          <el-input
            size="mini"
            style="width: 100px;"
            v-model="item.JJMC"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="40px">
          <label slot="label">{{ PJFS === 2 ? "星数" : "分数" }}</label>
          <!-- <el-input size="mini" style="width:100px" v-model.number="item.JJFS"></el-input> -->
          <el-input-number
            style="width: 100px;"
            size="mini"
            v-model="item.JJFS"
            :precision="PJFS === 2 ? 0 : 1"
            :step="PJFS === 2 ? 1 : 0.1"
            :controls="false"
          ></el-input-number>
        </el-form-item>
        <el-form-item label-width="40px" label="图标">
          <Upload
            style="margin-top: 3%;"
            :itemId="item.Id"
            :itemPic="item.TB"
            :width="30"
            :height="30"
            @getResultUrl="getResultUrlgetResultUrl"
            :circle="true"
            html
          />
        </el-form-item>
        <el-form-item label-width="50px" label=" ">
          <el-link
            type="primary"
            :underline="false"
            v-if="levelsetData.list.length > 1"
            @click.prevent="removeItem(index)"
            >删除</el-link
          >
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" size="mini" @click="submitForm"
          >提交</el-button
        >
        <el-button size="mini" @click="addItem">添加项</el-button>
        <el-button size="mini" @click="IsEdit = false">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style scoped>
.span-liu {
  font-size: 14px;
  margin-right: 82px;
  vertical-align: middle;
}
.span-liu > span {
  margin-left: 21px;
}
.img-liu {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  vertical-align: middle;
}
.div-liu {
  margin-bottom: 22px;
}
</style>
<script>
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Upload
  },
  data() {
    return {
      UploadVisiable: false,
      IsEdit: false,
      levelsetData: {
        list: []
      },
      EditBtnTxt: "新增"
    };
  },
  props: {
    setList: {
      type: Array,
      required: true
    },
    visiable: Boolean,
    PJFS: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getResultUrlgetResultUrl(filePath, itemId) {
      const item = this.levelsetData.list.find(c => c.Id === itemId);
      if (item) {
        item.TB = filePath;
      }
    },
    addItem() {
      this.levelsetData.list.push({
        Id: Date.now().toString(),
        HDId: "",
        JJMC: "",
        JJFS: "",
        TB: "",
        CJR: "",
        CJSJ: new Date().toLocaleDateString(),
        SFSC: 2
      });
    },
    removeItem(index) {
      if (this.levelsetData.list[index] !== undefined) {
        this.levelsetData.list.splice(index, 1);
      }
    },
    submitForm() {
      this.$emit("saveLevelSet", this.levelsetData.list);
      this.IsEdit = false;
      if (this.levelsetData.list.length > 0) {
        this.EditBtnTxt = "编辑";
      } else {
        this.addItem();
      }
    }
  },
  created: function() {
    this.levelsetData.list = JSON.parse(JSON.stringify(this.setList));
    this.EditBtnTxt = "新增";
    if (
      this.levelsetData.list.length > 0 &&
      this.levelsetData.list.find(
        c => c.JJMC !== undefined && c.JJMC !== null && c.JJMC !== ""
      )
    ) {
      this.EditBtnTxt = "编辑";
    } else {
      // if (newVal) {
      //   this.addItem();
      // }
      // this.addItem();
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    IsEdit: function(newVal) {
      // this.levelsetData.list = JSON.parse(JSON.stringify(this.setList));
      this.EditBtnTxt = "新增";
      if (
        this.levelsetData.list.length > 0 &&
        this.levelsetData.list.find(
          c => c.JJMC !== undefined && c.JJMC !== null && c.JJMC !== ""
        )
      ) {
        this.EditBtnTxt = "编辑";
      } else {
        if (this.levelsetData.list.length === 0) {
          this.addItem();
        }
      }
    },
    visiable: function(newVal) {
      this.EditBtnTxt = "新增";
      if (!newVal) {
        this.IsEdit = newVal;
      } else {
        this.levelsetData.list = JSON.parse(
          JSON.stringify(
            this.setList.filter(
              c => c.JJMC !== undefined && c.JJMC !== null && c.JJMC !== ""
            )
          )
        );
        if (this.levelsetData.list.length > 0) {
          this.EditBtnTxt = "编辑";
        }
      }
    }
  }
};
</script>
