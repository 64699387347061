import api from "./http";
// 报表中心-学科报表相关接口

// 获取详情
export const GetXXBBData = params => {
  return new Promise((resolve, reject) => {
    api.get(
      "v1/Growth/GetXXBBData",
      params,
      res => {
        if (res.Code === 0) resolve(res || {});
      },
      err => reject(err)
    );
  });
};
// 导出
export const GetXXBBDataToExcel = params => {
  window.location.href = (process.env.NODE_ENV === 'development' ? "http://" +
    params.url +
    "/"  : '/api/')
     +
    "v1/Growth/GetXXBBDataToExcel?ssxx=" +
    params.ssxx +
    "&bjid=" +
    params.bjid +
    "&xkId=" +
    params.xkId +
    "&njid=" +
    params.njid +
    "&queryKeys=" +
    params.queryKeys +
    "&px=" +
    params.px +
    "&pageIndex=" +
    params.pageIndex +
    "&pageSize=" +
    params.pageSize +
    "&token=" +
    params.token;
};
// 获取报表表格
export const GetXXBBReportData = params => {
  return new Promise((resolve, reject) => {
    api.get(
      "v1/Growth/GetXXBBReportData",
      params,
      res => {
        if (res.Code === 0) resolve(res || {});
      },
      err => reject(err)
    );
  });
};
