<template>
  <el-table
    :data="this.tableData"
    border
    fit
    tooltip-effect="dark"
    style="width: 100%;"
    @row-dblclick="showItem"
  >
    <el-table-column
      fixed
      prop="PJHDMC"
      label="活动名称"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column fixed label="活动类型" show-overflow-tooltip>
      <template slot-scope="scope">
        <span>{{ scope.row.HDLX === 1 ? "过程活动" : "阶段活动" }} </span>
      </template>
    </el-table-column>
    <el-table-column
      prop="SSBZMBStr"
      label="所属标准模板"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="EvaluatedObjectStr"
      label="被评人"
      min-width="40"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="EvalutionFormatStr"
      label="评价方式"
      min-width="40"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="HDXSStr"
      label="活动形式"
      min-width="40"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column fixed="right" label="操作">
      <template slot-scope="scope">
        <el-button @click="editItem(scope.row)" type="text" size="small"
          >编辑</el-button
        >
        <el-button
          @click="delItem(scope.row)"
          type="text"
          size="small"
          class="tab-wz-butta c-red"
          >删除</el-button
        >
        <el-button
          v-show="scope.row.HDXS >= 4 && scope.row.SZZPBPR !== 1"
          @click="setItemObect(scope.row, 4)"
          type="text"
          size="small"
          >主评设置</el-button
        >
        <el-button
          v-show="
            (scope.row.HDXS === 2 ||
              scope.row.HDXS === 3 ||
              scope.row.HDXS === 6 ||
              scope.row.HDXS === 7) &&
              scope.row.SZZPBPR !== 1
          "
          @click="setItemObect(scope.row, 2)"
          type="text"
          size="small"
          >互评设置</el-button
        >
        <el-button
          v-show="
            (scope.row.HDXS === 1 ||
              scope.row.HDXS === 3 ||
              scope.row.HDXS === 5 ||
              scope.row.HDXS === 7) &&
              scope.row.SZZPBPR !== 1
          "
          @click="setItemObect(scope.row, 1)"
          type="text"
          size="small"
          >自评设置</el-button
        >
        <el-button
          v-show="scope.row.SZZPBPR === 1"
          @click="setItemObect(scope.row, 10)"
          type="text"
          size="small"
          >设置管理员</el-button
        >
        <el-button
          v-show="scope.row.SZZPBPR === 1"
          @click="setItemObect(scope.row, 11)"
          type="text"
          size="small"
          >设置指标</el-button
        >
        <el-button
          v-show="
            scope.row.HDLX === 2 &&
              (!scope.row.SFYXZJHD || scope.row.SFYXZJHD === 2)
          "
          @click="setItemObect(scope.row, 12)"
          type="text"
          size="small"
          >设置评价时间</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true,
      default: function() {
        return [];
      },
    },
    user: {
      type: Object,
      required: true,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    showItem(row) {
      this.$emit("showItem", row);
    },
    setItemObect(data, type) {
      this.$emit("setItemObect", { data, type });
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.post(
            "/v1/evaluationactivity/DelEvaluationActivity",
            {
              token: this.$store.state.token,
              EvaluationActivityId: data.Id,
              UserId: this.user.Id,
              UserName: this.user.XSM,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                });
                this.$emit("delItem");
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
