<template>
  <el-dialog
    top="1vh"
    width="60%"
    ref="dialog"
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
  >
    <div style="height: 43px; width: 100%">
      <el-popover placement="left" width="228" v-model="popoverVisible">
        <div>
          <el-button
            style="width: 104px; margin: 5px"
            size="mini"
            type="primary"
            v-for="(item, i) in baseInfo.EvaluationActivityTemplateList"
            :key="i"
            @click="importSetTemplate(i)"
            >{{ item.TemplateName }}</el-button
          >
        </div>
        <el-button
          style="float: right"
          size="mini"
          type="primary"
          slot="reference"
          >导入活动模板</el-button
        >
      </el-popover>
    </div>
    <el-form
      status-icon
      :model="form"
      :ref="formName"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
      label-position="right"
    >
      <el-form-item
        label="活动名称"
        :label-width="formLabelWidth"
        prop="PJHDMC"
      >
        <el-input
          style="max-width: 218px"
          suffix-icon="el-icon-edit"
          v-model="form.PJHDMC"
          autocomplete="on"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动类型" :label-width="formLabelWidth" prop="HDLX">
        <el-select
          v-model="form.HDLX"
          :disabled="form.SZZPBPR === 1 && !!form.Id"
          placeholder
          @change="HDLXchange"
        >
          <el-option
            v-for="item in HDLXOption"
            :key="item.value"
            :disabled="form.BPJDXLX === 3 && item.value === 2"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="活动被评人"
        :label-width="formLabelWidth"
        prop="BPJDXLX"
      >
        <el-select
          v-model="form.BPJDXLX"
          :disabled="form.SZZPBPR === 1 && !!form.Id"
          placeholder
          @change="BPRchange"
        >
          <el-option
            v-for="item in baseInfo.BeEvaluationObjects"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
            :disabled="
              (form.HDCPR === 2 && (item.Key === 1 || item.Key === 3)) ||
                !form.HDLX
            "
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="活动参评人"
        :label-width="formLabelWidth"
        prop="HDCPR"
      >
        <el-select
          v-model="form.HDCPR"
          :disabled="form.SZZPBPR === 1 && !!form.Id"
          placeholder
          @change="CPRchange"
        >
          <el-option
            v-for="item in HDCPRoption"
            :disabled="
              ((form.BPJDXLX === 1 || form.BPJDXLX === 3) &&
                item.value === 2) ||
                !form.HDLX
            "
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :inline-message="true"
        label="活动形式"
        :label-width="formLabelWidth"
        prop="hdxsarry"
      >
        <el-checkbox-group
          :disabled="form.SZZPBPR === 1 && !!form.Id"
          v-model="form.hdxsarry"
          style="display: inline-block"
        >
          <el-checkbox
            v-for="item in HDXSoption"
            :key="item.Key"
            :label="item.Key"
            :disabled="
              item.disabled ||
                ([2, 3].includes(item.Key) &&
                  form.JZKBLX &&
                  [2, 3].includes(form.JZKBLX))
            "
            >{{ item.Value }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="小组参与"
        :label-width="formLabelWidth"
        prop="XZCYFS"
      >
        <el-select
          v-model="form.XZCYFS"
          :disabled="form.SZZPBPR === 1 && !!form.Id"
          placeholder
        >
          <el-option
            v-for="item in baseInfo.GroupParticipationTypes"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
            :disabled="groupJoinDisable(item)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="加载课表"
        :label-width="formLabelWidth"
        prop="JZKBLX"
      >
        <el-select
          v-model="form.JZKBLX"
          :disabled="form.SZZPBPR === 1 && !!form.Id"
          placeholder
          @change="JZKBLXchange"
        >
          <el-option
            v-for="item in baseInfo.CourseTableTypes"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
            :disabled="courseTableDisable(item)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属学段" :label-width="formLabelWidth" prop="SSXD">
        <el-select v-model="form.SSXD" placeholder="请选择所属学段" multiple>
          <el-option
            v-for="item in SSXDoption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="评价方式" :label-width="formLabelWidth" prop="PJFS">
        <el-select
          v-model="form.PJFS"
          placeholder
          @change="handdleSelectedEvaluationMethod"
        >
          <el-option
            v-for="item in baseInfo.EvalutionFormat &&
              baseInfo.EvalutionFormat.filter(item => item.Key !== 4)"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="wjxz"
        v-if="
          form.PJFS === 3 &&
            form.HDLX === 2 &&
            ((form.HDCPR === 1 && form.BPJDXLX === 1) ||
              (form.HDCPR === 1 && form.BPJDXLX === 2))
        "
        label="限制人数"
        :label-width="formLabelWidth"
      >
        <el-radio-group
          class="wjxz-pjfs"
          @change="WJXZ_PJFS_change"
          v-model="WJXZ.PJFS"
        >
          <el-radio :label="1">选人后再评</el-radio>
          <el-radio :label="2">选问题后选人</el-radio>
        </el-radio-group>
        <div class="wjxz-2" v-show="WJXZ.PJFS === 2">
          <el-select
            size="mini"
            style="width:80px"
            v-model="WJXZ.letter"
            placeholder="请选择"
          >
            <el-option
              v-for="item in letterOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            size="mini"
            style="width:80px"
            v-model="WJXZ.compare"
            placeholder="请选择"
          >
            <el-option
              v-for="item in compareOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input-number
            style="width:80px"
            class="wjxz-num"
            size="mini"
            :precision="0"
            v-model="WJXZ.num"
            :min="0"
            :max="90"
          ></el-input-number>
          <el-radio v-model="WJXZ.numType" :label="1">百分比</el-radio>
          <el-radio v-model="WJXZ.numType" :label="2">固定人数</el-radio>
        </div>
      </el-form-item>

      <el-form-item
        :label-width="formLabelWidth"
        v-if="form.PJFS == 1"
        prop="ZBCXFS"
      >
        <el-radio-group v-model="form.ZBCXFS">
          <el-radio
            v-for="item in ZBCXFSoption"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label-width="formLabelWidth"
        v-else-if="form.PJFS == 2"
        prop="ZHDTJFS"
      >
        <div>
          <el-checkbox
            v-model="allowCreateChildActivity"
            @change="handdelCheckAllowCreateChildActivity"
            >允许用户在里面自建活动</el-checkbox
          >
          <div>成长报告总活动得分：</div>
        </div>
        <div>
          <el-radio-group v-model="form.ZHDTJFS">
            <el-radio
              v-for="item in baseInfo.StatisticsTypes"
              :key="item.Key"
              :label="item.Key"
              :disabled="!allowCreateChildActivity"
              >{{
                item.Key === 1 ? '取子活动得分总和' : '取子活动的平均分'
              }}</el-radio
            >
          </el-radio-group>
        </div>
      </el-form-item>
      <template>
        <div class="BM-list" v-show="form.ZBCXFS === 3">
          <el-form-item
            label="勾选加分项别名"
            :label-width="formLabelWidth"
            prop="JFXBM"
          >
            <el-input
              style="max-width: 218px"
              v-model="form.JFXBM"
              autocomplete="on"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="减分项别名"
            :label-width="formLabelWidth"
            prop="KFXBM"
          >
            <el-input
              style="max-width: 218px"
              v-model="form.KFXBM"
              autocomplete="on"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="自定义打分别名"
            :label-width="formLabelWidth"
            prop="ZDYBM"
          >
            <el-input
              style="max-width: 218px"
              v-model="form.ZDYBM"
              autocomplete="on"
            ></el-input>
          </el-form-item>
        </div>
      </template>
      <el-form-item
        class="SZZPBRP"
        v-show="
          form.HDLX === 1 &&
            form.BPJDXLX !== 3 &&
            form.HDCPR === 1 &&
            form.hdxsarry.length > 0 &&
            form.hdxsarry.every(item => item === 1) &&
            form.XZCYFS === 1 &&
            form.JZKBLX === 1
        "
        label="管理员自行设定评价人和被评价人"
        :label-width="formLabelWidth"
        prop="SZZPBPR"
      >
        <el-select
          v-model="form.SZZPBPR"
          :disabled="form.SZZPBPR === 1 && !!form.Id"
          @change="selectSZZPBPR"
        >
          <el-option
            v-for="item in SZZPBPROption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item
        label="评价频次"
        :label-width="formLabelWidth"
        prop="DCPJSZ"
      >
        <el-select v-model="form.DCPJSZ" placeholder>
          <el-option
            v-for="item in baseInfo.EvFrequencys"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item
        label="活动描述"
        :label-width="formLabelWidth"
        prop="HDMS"
        class="HDMS"
      >
        <el-input
          type="textarea"
          style="max-width: 218px"
          v-model="form.HDMS"
          placeholder="不超过200个字"
        ></el-input>
      </el-form-item>
      <el-form-item label="选择按钮" :label-width="formLabelWidth">
        <div style="width: 90%">
          <el-checkbox-group
            v-model="form.btnList"
            @change="handdlecheckboxBtns"
          >
            <el-checkbox
              v-for="item in baseInfo.Btns &&
                baseInfo.Btns.filter(btn => btn.ANLX !== 4)"
              :key="item.Id"
              :label="item.Id"
              :disabled="disabledBtn(item)"
              >{{ item.ANMC }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item label="选择报表模版" :label-width="formLabelWidth" prop>
        <el-checkbox-group
          v-model="form.reportList"
          @change="handdlecheckboxReportTypes"
        >
          <el-checkbox
            :disabled="
              (form.BPJDXLX === 2 && form.HDCPR === 2) ||
                ((!form.XZCYFS || form.XZCYFS === 1 || form.PJFS === 3) &&
                  item.Key === 2) ||
                (form.BPJDXLX === 3 && [1, 2, 4, 5].includes(item.Key)) ||
                (form.SFYXZJHD !== 1 && item.Key === 5)
            "
            v-for="item in baseInfo.ReportTypes &&
              baseInfo.ReportTypes.filter(it => it.Key !== 2)"
            :key="item.Key"
            :label="item.Key"
          >
            {{ item.Value }}
            <el-select
              v-model="reportTemplateList[item.Key - 1]"
              placeholder
              size="mini"
              @change="
                handdleReportTemplateSelected(
                  reportTemplateList[item.Key - 1],
                  item.Key
                )
              "
              :disabled="
                (form.BPJDXLX === 2 && form.HDCPR === 2) ||
                  ((!form.XZCYFS || form.XZCYFS === 1 || form.PJFS === 3) &&
                    item.Key === 2) ||
                  (form.BPJDXLX === 3 && [1, 2, 4, 5].includes(item.Key)) ||
                  (form.SFYXZJHD !== 1 && item.Key === 5)
              "
            >
              <el-option
                v-for="template in item.EvaluationActivityReportTemplateList.filter(
                  c => c.ReportModel !== 9 && c.ReportModel !== 10
                )"
                v-show="
                  !(
                    template.ReportName === '班级饼图' ||
                    template.ReportName === '小组饼图'
                  )
                "
                :key="template.ReportModel"
                :label="template.ReportName"
                :value="template.ReportModel"
                :disabled="reportSelectDisabled(item, template)"
              ></el-option>
            </el-select>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <div
        style="
          padding-left: calc(20% - 148px);
          padding-left: -webkit-calc(20% - 148px);
          padding-left: -moz-calc(20% - 148px);
          margin-bottom: 22px;
        "
        class="prompt-title-box"
      >
        注：评价按钮均为每个活动必带，点击被评价者后进入评价页面
      </div>
      <el-form-item label="设置晋级(可选项)" :label-width="formLabelWidth" prop>
        <el-button type="primary" size="mini" @click="handdleLevelUpSet"
          >设置</el-button
        >
      </el-form-item>
      <el-form-item
        v-show="form.PJFS !== 3"
        label="强制上传附件"
        :label-width="formLabelWidth"
      >
        <el-checkbox
          v-model="form.QZSCFJ"
          :true-label="1"
          :false-label="2"
        ></el-checkbox>
      </el-form-item>
      <el-form-item label="是否审核生效" :label-width="formLabelWidth">
        <el-checkbox
          :disabled="
            !form.hdxsArray ||
              (form.hdxsarry.length === 1 && form.hdxsarry[0] === 2)
          "
          v-model="form.SHSX"
          :true-label="1"
          :false-label="2"
        ></el-checkbox>
        <el-checkbox
          :disabled="form.SHSX !== 1"
          v-model="form.SHSFF"
          :true-label="1"
          :false-label="2"
          >审核是赋分</el-checkbox
        >
      </el-form-item>
      <el-form-item label="是否公示" :label-width="formLabelWidth">
        <el-checkbox
          :disabled="
            !form.hdxsarry ||
              (form.hdxsarry.length === 1 && form.hdxsarry[0] === 2)
          "
          v-model="form.GS"
          :true-label="1"
          :false-label="2"
        ></el-checkbox>
        <div v-show="form.GS === 1">
          <el-checkbox v-model="form.GSJP" :true-label="1" :false-label="2"
            >自评</el-checkbox
          >
          <el-checkbox v-model="form.GSZP" :true-label="1" :false-label="2"
            >主评</el-checkbox
          >
          公示天数
          <el-input-number
            v-model="form.GSTS"
            :min="1"
            :max="100"
            size="mini"
            style="margin-right:10px;width:60px"
          ></el-input-number>
          <el-checkbox v-model="form.GSJSJF" :true-label="1" :false-label="2"
            >公示天数结束后记分</el-checkbox
          >
        </div>
      </el-form-item>

      <el-form-item
        label="机构组长评价师添加组员共享"
        :label-width="formLabelWidth"
      >
        <!--  老师评老师 且仅主评时-->
        <el-checkbox
          :disabled="
            form.BPJDXLX !== 2 &&
              form.HDCPR !== 1 &&
              (!form.hdxsArray ||
                !form.hdxsarry.includes(1) ||
                form.hdxsarry.length > 1)
          "
          v-model="form.ZYGX"
          :true-label="1"
          :false-label="2"
        ></el-checkbox>
      </el-form-item>
      <el-form-item label="显示注释" :label-width="formLabelWidth">
        <el-checkbox
          v-model="form.SFXSZS"
          :true-label="1"
          :false-label="2"
        ></el-checkbox>
      </el-form-item>
      <el-form-item label="影响班级评价" :label-width="formLabelWidth">
        <!-- 被评人为学生时有效  -->
        <el-checkbox
          :disabled="form.PJFS !== 1 || form.BPJDXLX !== 1 || form.HDLX !== 1"
          v-model="form.YXBJPJ"
          :true-label="1"
          :false-label="2"
        ></el-checkbox>
      </el-form-item>

      <el-form-item
        :inline-message="true"
        style="margin-bottom: 8px"
        label="是否开放为共享模板"
        :label-width="formLabelWidth"
        prop="SFKFGX"
      >
        <el-select v-model="form.SFKFGX" placeholder>
          <el-option
            v-for="item in baseInfo.IsShareTemplate"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
      <div
        style="
          padding-left: calc(20% - 148px);
          padding-left: -webkit-calc(20% - 148px);
          padding-left: -moz-calc(20% - 148px);
          margin-bottom: 22px;
        "
        class="prompt-title-box"
      >
        注:共享后,其它学校可以在活动管理页面复制此活动相关的所有设置,包括活动,对象,指标,角色名称,课程等.
      </div>
      <el-form-item
        label="评价语模板(可选项)"
        :label-width="formLabelWidth"
        prop
      >
        <el-button type="primary" size="mini" @click="handdleCommentTemplateSet"
          >设置</el-button
        >
      </el-form-item>
      <el-form-item label="编码" :label-width="formLabelWidth" prop="HDBM">
        <el-input style="width:217px" v-model="form.HDBM" />
      </el-form-item>
      <el-form-item
        :label="`活动默认${form.PJFS && form.PJFS === 2 ? '星' : '分'}`"
        :label-width="formLabelWidth"
        prop="HDMRF"
      >
        <el-input-number
          v-model="form.HDMRF"
          :precision="0"
          :min="0"
          :max="form.PJFS === 2 ? 5 : 100"
          style="width:217px"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        label="是否签名"
        :label-width="formLabelWidth"
        prop
        v-show="form.HDLX == 1 && form.PJFS == 1"
      >
        <el-checkbox
          v-model="form.SFQM"
          :true-label="1"
          :false-label="2"
        ></el-checkbox>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>

    <el-dialog
      width="40%"
      top="30vh"
      title="设置晋级"
      :visible.sync="innerVisibleByLevelUpSet"
      :close-on-click-modal="false"
      append-to-body
    >
      <EvaLuationActivityLevelSet
        :PJFS="form.PJFS"
        :setList="form.EvaluationActivityLevelSetList"
        :visiable="innerVisibleByLevelUpSet"
        @saveLevelSet="saveLevelSet"
      />
    </el-dialog>

    <el-dialog
      width="40%"
      top="30vh"
      title="设置评价语模板"
      :visible.sync="innerVisibleByCommentTemplateSet"
      :close-on-click-modal="false"
      append-to-body
    >
      <EvaluationActivityCommentTemplate
        :tableData="form.EvaluationActivityCommentTemplateList"
        :Show="innerVisibleByCommentTemplateSet"
        @saveCommentTemplate="saveCommentTemplate"
      />
    </el-dialog>
  </el-dialog>
</template>

<script>
import { binary } from '@/style/js/Common.js'
import { handle_SSXD } from '@/utils/common'
import { pinyin } from 'pinyin-pro'
import EvaLuationActivityLevelSet from '@/views/manager/evaluationactivityset/EvaLuationActivityLevelSet.vue'
import EvaluationActivityCommentTemplate from '@/views/manager/evaluationactivityset/EvaluationActivityCommentTemplate.vue'
export default {
  components: {
    EvaLuationActivityLevelSet,
    EvaluationActivityCommentTemplate
  },
  data() {
    let validateHDXS = (rule, value, callback) => {
      if ([2, 3].includes(this.form.HDCPR) && this.form.BPJDXLX === 1) {
        let success = false
        for (const item of value) {
          if ([2, 3].includes(item)) {
            success = true
          }
        }
        if (success) {
          return callback()
        } else {
          return callback(new Error(rule.message))
        }
      } else {
        return callback()
      }
    }
    let validateStatisticsTypes = (rule, value, callback) => {
      if (this.form.SFYXZJHD === 1) {
        if (value === undefined) {
          callback(new Error('请选择子活动统计方式'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    let validHDMRF = (rule, value, callback) => {
      let reg = /^([0-9]{1,2}|100)$/
      if (value !== 0 && !value) callback()
      else if (!reg.test(value)) callback(new Error('请输入0-100整数'))
      else callback()
    }
    let valid_HDBM = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9]+$/ //英文字符
      if (!value) callback(new Error('编码不能为空'))
      else if (!reg.test(value)) callback(new Error('请输入英文或者数字'))
      else if (value.length > 10) callback(new Error('编码不能超过10个字符'))
      else callback()
    }
    return {
      sourceObjectIds: [],
      roleList: [],
      popoverVisible: false,
      innerVisibleByLevelUpSet: false,
      innerVisibleByCommentTemplateSet: false,
      allowCreateChildActivity: false,
      hdxs: '000',
      form: {
        SFKFGX: 2
      },
      submitForm: {},
      formName: 'form',
      isShow: false,
      formLabelWidth: '20%',
      btnList: [],
      reportList: [],
      reportTemplateList: [],
      oldBPJDXLX: 0,
      SSXDoption: [], // 所属学段option
      rules: {
        PJHDMC: [
          { required: true, message: '请输入评价活动名称', trigger: 'change' },
          { max: 50, message: '长度不能超过50个字', trigger: 'change' }
        ],
        BPJDXLX: [
          { required: true, message: '请选择活动被评人', trigger: 'change' }
        ],
        roleList: [
          {
            type: 'array',
            required: true,
            message: '请选择教师角色',
            trigger: 'change'
          }
        ],
        HDCPR: [
          { required: true, message: '请选择活动参评人', trigger: 'change' }
        ],
        hdxsarry: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一项活动形式',
            trigger: 'change'
          },
          {
            validator: validateHDXS,
            trigger: 'change',
            message: '必须选择自评或互评'
          }
        ],
        ZBCXFS: [
          { required: true, message: '请选择指标呈现方式', trigger: 'blur' }
        ],
        ZHDTJFS: [
          // {required: true, message: "请选择子活动统计方式", trigger: "change"}
          { validator: validateStatisticsTypes, trigger: 'blur' }
        ],
        XZCYFS: [{ required: true, message: '请选择小组', trigger: 'change' }],
        JZKBLX: [{ required: true, message: '请选择课表', trigger: 'change' }],
        PJFS: [
          { required: true, message: '请选择评价方式', trigger: 'change' }
        ],
        SSXD: [
          { required: true, message: '请选择所属学段', trigger: 'change' }
        ],
        DCPJSZ: [
          { required: true, message: '请选择评价频次', trigger: 'change' }
        ],
        SFKFGX: [
          {
            required: true,
            message: '请选择是否开放为共享模板',
            trigger: 'change'
          }
        ],
        HDMS: [
          //活动描述
          {
            max: 200,
            message: '不能超过200个字',
            trigger: 'change'
          }
        ],
        HDLX: [
          {
            required: true,
            message: '活动类别不能为空',
            trigger: 'blur'
          }
        ],
        HDMRF: [
          {
            validator: validHDMRF,
            message: '请输入0-100整数',
            trigger: 'change'
          }
        ],
        HDBM: [
          {
            validator: valid_HDBM,
            trigger: 'change'
          }
        ]
      },
      HDLXOption: [
        {
          value: 1,
          label: '过程活动'
        },
        {
          value: 2,
          label: '阶段活动'
        }
      ],
      SZZPBPROption: [
        {
          value: 1,
          label: '是'
        },
        {
          value: 0,
          label: '否'
        }
      ],
      // 活动形式辅助下拉数据
      HDXSoption: [],
      // 参评人下拉
      HDCPRoption: [
        {
          value: 1,
          label: '老师'
        },
        {
          value: 2,
          label: '学生'
        }
      ],
      // 参与方式下拉
      ZBCXFSoption: [
        {
          label: '指标纵向呈现',
          value: 1
        },
        {
          label: '指标倒数二级横向呈现',
          value: 2
        },
        {
          label: '指标类别横向呈现',
          value: 3
        }
      ],
      // 问卷限制人数
      WJXZ: {
        PJFS: 1,
        letter: 'A',
        compare: 1,
        num: 1,
        numType: 1
      },
      letterOption: [
        {
          label: 'A',
          value: 'A'
        },
        {
          label: 'B',
          value: 'B'
        },
        {
          label: 'C',
          value: 'C'
        },
        {
          label: 'D',
          value: 'D'
        },
        {
          label: 'E',
          value: 'E'
        },
        {
          label: 'F',
          value: 'F'
        }
      ],
      compareOption: [
        {
          label: '=',
          value: 1
        },
        {
          label: '<=',
          value: 2
        },
        {
          label: '>=',
          value: 3
        }
      ]
    }
  },
  props: {
    dialogVisible: Boolean,
    formData: {
      type: Object,
      required: true
    },
    dialogTitle: String,
    baseInfo: {
      type: Object,
      required: true
    }
    // allObject: {
    //   type: Array,
    //   default: function() {
    //     return [];
    //   }
    // }
  },
  created: function() {
    this.getSSXD()
  },
  methods: {
    // 获取所属学段option
    getSSXD() {
      //所属学段
      let schoolInfo = JSON.parse(sessionStorage.getItem('schoolinfo'))
      let str = [
        { Name: '小学', Id: 1 },
        { Name: '初中', Id: 2 },
        { Name: '高中', Id: 4 }
      ]
      let arr = ['XXXZ', 'CZXZ', 'GZXD']
      this.SSXDoption = arr.reduce((pre, cur, index) => {
        if (schoolInfo[cur] === 1) {
          pre.push({
            Name: str[index].Name,
            Id: str[index].Id
          })
        }
        return pre
      }, [])
    },
    checkedRole(val) {
      this.roleList = val
      this.form.roleList = val
    },
    saveCommentTemplate(data) {
      this.form.EvaluationActivityCommentTemplateList = data
    },
    saveLevelSet(data) {
      this.form.EvaluationActivityLevelSetList = data
    },
    handdleLevelUpSet() {
      this.innerVisibleByLevelUpSet = true
    },
    handdleCommentTemplateSet() {
      this.innerVisibleByCommentTemplateSet = true
    },
    handdleReportTemplateSelected(value, bblx) {
      if (this.form.EvaluationActivityReportTemplateList !== undefined) {
        const obj = this.form.EvaluationActivityReportTemplateList.find(
          c => c.BBLX === bblx
        )
        if (obj !== undefined) {
          obj.BBXZ = value
        } else {
          const report = this.baseInfo.ReportTypes.filter(
            c => c.Key === 3
          ).map(c =>
            c.EvaluationActivityReportTemplateList.filter(
              o => o.ReportModel === 3
            )
          )
          if (report && report[0] && report[0][0] && report[0][0].ReportId) {
            this.form.EvaluationActivityReportTemplateList.push({
              Id: 0,
              HDId: undefined,
              BBLX: bblx,
              BBXZ: value,
              BBId: report[0][0].ReportId
            })
          }
        }
      }
    },
    // 处理报表下拉
    handdlecheckboxReportTypes(value) {
      this.$set(this.form, 'EvaluationActivityReportTemplateList', [])
      value.forEach(item => {
        const report = this.baseInfo.ReportTypes.find(c => c.Key === item)
        const xz = this.reportTemplateList[item - 1]
        if (report && xz) {
          const bb = report.EvaluationActivityReportTemplateList.find(
            c => c.ReportModel === xz
          )
          if (bb) {
            this.form.EvaluationActivityReportTemplateList.push({
              Id: 0,
              HDId: this.form.Id,
              BBLX: item,
              BBXZ: bb.ReportModel,
              BBId: bb.ReportId
            })
          }
        }
      })
    },
    handdlecheckboxBtns(value) {
      this.form.EvaluationActivityButtonList = []
      value.forEach(id => {
        this.baseInfo.Btns.forEach(btn => {
          if (btn.Id === id) {
            this.form.EvaluationActivityButtonList.push({
              Id: 0,
              HDId: this.form.Id,
              ANId: btn.Id,
              ANLX: btn.ANLX
            })
          }
        })
      })
    },
    handdleSelectedEvaluationMethod(value) {
      if (value !== 1) {
        this.$refs[this.formName].clearValidate('ZBCXFS')
      }
      if (value !== 2) {
        this.allowCreateChildActivity = false
        this.form.SFYXZJHD = 2
        this.$refs[this.formName].clearValidate('ZHDTJFS')
      }
    },
    handdelCheckAllowCreateChildActivity(value) {
      this.form.SFYXZJHD = 2
      if (value) {
        this.form.SFYXZJHD = 1
      }
    },
    cancel() {
      this.$confirm('确认关闭？').then(() => {
        this.$emit('canceldialog')
      })
    },
    // 保存
    confirm() {
      this.submitForm = JSON.parse(JSON.stringify(this.form))
      this.submitForm.RoleList = []
      for (let i = 0; i < this.roleList.length; i++) {
        this.submitForm.RoleList.push({
          JSId: this.roleList[i],
          HDId: this.submitForm.Id ? this.submitForm.Id : '',
          Id: 0
        })
      }
      this.submitForm.EvaluationActivityReportTemplateList = []
      if (!this.form.EvaluationActivityReportTemplateList) {
        this.form.EvaluationActivityReportTemplateList = []
      }
      const reportList = this.form.EvaluationActivityReportTemplateList
      for (let i = 0; i < reportList.length; i++) {
        if (
          (this.form.BPJDXLX !== 3 && reportList[i].BBLX === 1) ||
          (this.form.XZCYFS !== 1 && reportList[i].BBLX === 2) ||
          reportList[i].BBLX === 4 ||
          (this.form.SFYXZJHD === 1 && reportList[i].BBLX === 5) ||
          reportList[i].BBLX === 3
        ) {
          const bblx = this.baseInfo.ReportTypes.find(
            c => c.Key === reportList[i].BBLX
          )
          if (bblx) {
            const bb = bblx.EvaluationActivityReportTemplateList.find(
              c => c.ReportModel === reportList[i].BBXZ
            )
            if (bb) {
              const bbId = bb.ReportId
              if (bbId) {
                if (reportList[i].BBXZ === 4) {
                  if (this.form.SFYXZJHD === 1) {
                    this.submitForm.EvaluationActivityReportTemplateList.push({
                      Id: reportList[i].Id,
                      BBLX: reportList[i].BBLX,
                      BBId: bbId,
                      HDId: reportList[i].HDId,
                      BBXZ: reportList[i].BBXZ
                    })
                  }
                } else {
                  this.submitForm.EvaluationActivityReportTemplateList.push({
                    Id: reportList[i].Id,
                    BBLX: reportList[i].BBLX,
                    BBId: bbId,
                    HDId: reportList[i].HDId,
                    BBXZ: reportList[i].BBXZ
                  })
                }
              }
            }
          }
        }
      }
      if (this.submitForm.SFYXZJHD !== 1) {
        this.submitForm.SFYXZJHD = 2
        const bntInfo = this.baseInfo.Btns.find(c => c.ANLX === 7)
        if (bntInfo && this.submitForm.EvaluationActivityButtonList) {
          const index = this.submitForm.EvaluationActivityButtonList.findIndex(
            c => c.ANId === bntInfo.Id
          )
          if (
            this.submitForm.EvaluationActivityButtonList &&
            this.submitForm.EvaluationActivityButtonList.length > 0 &&
            index !== -1
          ) {
            this.submitForm.EvaluationActivityButtonList.splice(
              this.submitForm.EvaluationActivityButtonList.findIndex(
                c => c.ANId === bntInfo.Id
              ),
              1
            )
          }
        }
      }

      if (this.form.PJFS !== 3) {
        this.submitForm.WJSZGZ = null
      } else if (
        this.form.HDLX === 2 &&
        this.form.PJFS === 3 &&
        (this.form.BPJDXLX === 2 || this.form.BPJDXLX === 1) &&
        this.form.HDCPR === 1
      ) {
        let WJSZGZ = ''
        WJSZGZ = this.WJXZ.PJFS + WJSZGZ
        if (this.WJXZ.PJFS === 2) {
          WJSZGZ = `${WJSZGZ},${this.WJXZ.letter},${this.WJXZ.compare},${this.WJXZ.numType},${this.WJXZ.num}`
        } else {
          this.submitForm.WJSZGZ = '1'
        }
        this.submitForm.WJSZGZ = WJSZGZ
      }

      if (
        this.submitForm.XZCYFS === 1 &&
        this.submitForm.EvaluationActivityButtonList
      ) {
        this.submitForm.EvaluationActivityButtonList = this.submitForm.EvaluationActivityButtonList.filter(
          c => c.ANLX !== 13
        )
      }
      this.submitForm.QZSCFJ = this.submitForm.QZSCFJ || 2
      // 活动形式
      switch (eval(this.form.hdxsarry.join('+'))) {
        case 1: //主评
          this.submitForm.HDXS = 4
          break
        case 3:
          if (this.form.hdxsarry.includes(3)) {
            // 自评
            this.submitForm.HDXS = 1
          } else {
            // 主评和互评
            this.submitForm.HDXS = 6
          }
          break
        case 4: //主评和自评
          this.submitForm.HDXS = 5
          break
        case 6: //全选
          this.submitForm.HDXS = 7
          break
        case 2: //互评
          this.submitForm.HDXS = 2
          break
        case 5: //互评和自评
          this.submitForm.HDXS = 3
          break
      }
      if (!this.form.HDBM) {
        let letterArr = []
        try {
          letterArr = pinyin(this.form.PJHDMC, {
            toneType: 'none',
            pattern: 'first',
            type: 'array'
          })
          if (letterArr) {
            let letter = ''
            letterArr.forEach(it => (letter += it.toUpperCase()))
            this.$set(this.form, 'HDBM', letter)
            this.submitForm.HDBM = letter
          }
        } catch (err) {
          //
        }
      }
      // 强制上传附件
      this.$refs[this.formName].validate(valid => {
        if (valid) {
          this.$confirm('确认提交？')
            // eslint-disable-next-line no-unused-vars
            .then(r => {
              let reportType = this.baseInfo.ReportTypes.find(c => c.Key === 3)
              if (reportType) {
                let report = reportType.EvaluationActivityReportTemplateList.find(
                  c => c.ReportModel === 9
                )
                if (report) {
                  this.submitForm.BJZFXBId = report.ReportId
                }
              }
              let mainActivityReportType = this.baseInfo.ReportTypes.find(
                c => c.Key === 5
              )
              if (mainActivityReportType) {
                let report = mainActivityReportType.EvaluationActivityReportTemplateList.find(
                  c => c.ReportModel === 10
                )
                if (report) {
                  this.submitForm.ZHDGRBBId = report.ReportId
                }
              }

              //编辑时过滤合活动形式不匹配的原对象
              if (
                this.submitForm.EvaluationObjectList &&
                this.submitForm.EvaluationObjectList.length > 0
              ) {
                this.filterObjectSet()
              }
              this.submitForm.SourceObjectIds = this.sourceObjectIds

              delete this.submitForm.roleList
              // 执行提交方法
              this.$emit('sumbitForm', this.submitForm)
            })
            // eslint-disable-next-line no-unused-vars
            .catch(r => {})
        } else {
          return false
        }
      })
    },
    filterObjectSet() {
      let hdxsArray = new Set()
      let result = []
      if ([2, 3, 6, 7].includes(this.submitForm.HDXS)) {
        hdxsArray.add(new Set([2, 3, 6, 7]))
      }
      if ([4, 5, 6, 7].includes(this.submitForm.HDXS)) {
        hdxsArray.add(new Set([4, 5, 6, 7]))
      }
      if ([1, 3, 5, 7].includes(this.submitForm.HDXS)) {
        hdxsArray.add(new Set([1, 3, 5, 7]))
      }
      for (let item of hdxsArray) {
        result = [...new Set([...new Set(result), ...item])]
      }
      let sourceObjectIds = JSON.parse(
        JSON.stringify(this.submitForm.EvaluationObjectList)
      )
      // this.submitForm.EvaluationObjectList = this.submitForm.EvaluationObjectList.filter(
      //   c =>
      //     this.allObject
      //       .filter(
      //         c =>
      //           result.includes(c.HDXS) &&
      //           this.submitForm.EvaluationObjectList.map(
      //             item => item.PJCYDXId
      //           ).includes(c.Id)
      //       )
      //       .map(c => c.Id)
      //       .includes(c.PJCYDXId)
      // );
      this.sourceObjectIds = sourceObjectIds.filter(
        c =>
          !this.submitForm.EvaluationObjectList.map(c => c.PJCYDXId).includes(
            c.PJCYDXId
          )
      )
    },
    // 活动类型变化
    HDLXchange() {
      // 活动类型为空 禁用活动形式
      if (!this.form.HDLX) {
        this.form.hdxsarry = []
        this.HDXSoption.forEach(item => (item.disabled = true))
        return
      }
      // 活动类型为过程活动 禁用互评
      if (this.form.HDLX === 1) {
        let idx = this.form.hdxsarry.findIndex(val => val === 2)
        if (idx !== -1) this.form.hdxsarry.splice(idx, 1)
        this.HDXSoption.forEach(item => {
          if (item.Key === 2) item.disabled = true
        })
      }
      // 活动类型为阶段活动 且 (被评人不为班级 或者 (被评人不为老师且参评人不为学生)) 恢复互评
      if (
        this.form.HDLX === 2 &&
        (this.form.BPJDXLX !== 3 ||
          (this.form.BPJDXLX !== 2 && this.form.HDCPR !== 2))
      ) {
        this.HDXSoption.forEach(item => {
          if (item.Key === 2) item.disabled = false
        })
      }
      this.BPRchange()
      this.CPRchange()
    },
    //  被评人变化
    BPRchange() {
      // 被评人为空 禁用活动形式
      if (!this.form.BPJDXLX) {
        this.HDXSoption.forEach(item => (item.disabled = true))
        return
      }
      // 被评人为老师时
      if (this.form.BPJDXLX === 2) {
        // 参评人为学生 只能主评
        if (this.form.HDCPR === 2) {
          this.form.hdxsarry = [1]
          this.HDXSoption.forEach(item => {
            if (item.Key !== 1) item.disabled = true
          })
        }
        // 参评人为老师
        if (this.form.HDCPR === 1) {
          //过程活动
          if (this.form.HDLX === 1) {
            this.HDXSoption.forEach(item => {
              if (item.Key !== 2) item.disabled = false
            })
          } else {
            this.HDXSoption.forEach(item => (item.disabled = false))
          }
        }
      }
      //被评人为学生时 参评人只能为老师
      if (this.form.BPJDXLX === 1 && this.form.HDLX) {
        // 过程活动
        if (this.form.HDLX === 1) {
          this.HDXSoption.forEach(item => {
            if (item.Key !== 2) item.disabled = false
          })
        } else {
          this.HDXSoption.forEach(item => (item.disabled = false))
        }
        // 被评人为学生禁用个体总分表
        this.reportTemplateList = this.reportTemplateList.filter(
          item => item !== 12
        )
      }
      //被评人为班级时 参评人只能为老师 只能主评
      if (this.form.BPJDXLX === 3 && this.form.HDLX) {
        this.form.HDLX = 1
        this.form.hdxsarry = [1]
        this.HDXSoption.forEach(item => (item.disabled = true))
      }
    },
    // 参评人变化
    CPRchange() {
      if (!this.form.HDLX) return
      // 参评人为老师  被评人不为班级
      if (this.form.HDCPR === 1 && this.form.BPJDXLX !== 3) {
        // 过程活动
        if (this.form.HDLX === 1) {
          this.HDXSoption.forEach(item => {
            if (item.Key !== 2) item.disabled = false
          })
        } else {
          //阶段活动
          this.HDXSoption.forEach(item => (item.disabled = false))
        }
      }

      // 参评人为老师且被评人为班级 或者 参评人为学生且被评人为老师 只能主评
      if (
        (this.form.HDCPR === 1 && this.form.BPJDXLX === 3) ||
        (this.form.HDCPR === 2 && this.form.BPJDXLX === 2)
      ) {
        this.form.hdxsarry = [1]
        this.HDXSoption.forEach(item => (item.disabled = true))
      }
    },
    // 报表下拉禁用
    reportSelectDisabled(item, template) {
      //(报表类型为班级报表 且报表模块为不等于8 且被评价人为班级) 或 (报表模块为8 且被评价人不为班级)
      if (
        item.Key === 3 &&
        ((template.ReportModel !== 8 && this.form.BPJDXLX === 3) ||
          (template.ReportModel === 8 && this.form.BPJDXLX !== 3))
      )
        return true
      //评价方式为通用指标 且不属于通用类
      if (this.form.PJFS === 1 && template.SupportGeneralIndex !== 1)
        return true
      //评价方式为星星指标 且不属于星星类
      if (this.form.PJFS === 2) {
        if (template.SupportStarsIndex !== 1) return true
        else {
          // 星星总报表
          if (template.ReportModel === 4 && this.form.SFYXZJHD !== 1)
            return true
        }
      }
      // 评价方式为问卷 且不属于问卷类
      if (this.form.PJFS === 3) {
        if (this.form.BPJDXLX === 1 && template.ReportModel === 12) return true
        else if (template.SupportQuestionIndex !== 1) return true
      }
      // 评价方式为空 禁用
      if (!this.form.PJFS) return true
      // 被评价人为学生 禁用个体总分表
      if (this.form.BPJDXLX === 1 && template.ReportModel === 12) return true
    },
    // 管理员是否自行设定
    selectSZZPBPR(val) {
      if (val) {
        this.form.hdxsarry = [1]
        this.HDXSoption.forEach(item => {
          if (item.Key !== 1) item.disabled = true
        })
      } else {
        this.BPRchange()
        this.CPRchange()
      }
    },
    importSetTemplate(type) {
      const tmp = this.baseInfo.EvaluationActivityTemplateList[type]
      if (tmp && tmp.EvaluationActivityInfo) {
        this.init(tmp.EvaluationActivityInfo)
      }
      this.popoverVisible = false
    },
    // 表单初始化
    init(data) {
      if (data) {
        this.form = JSON.parse(JSON.stringify(data))
        this.$set(this.form, 'hdxsarry', [])
        this.reportTemplateList = []
      } else {
        this.form = JSON.parse(JSON.stringify(this.formData))
      }
      this.form.SFKFGX ? '' : (this.form.SFKFGX = 2)
      if (this.form.HDCPR === 0) {
        this.form.HDCPR = ''
      }
      this.$set(this.form, 'QZSCFJ', this.formData.QZSCFJ || '')
      this.$set(this.form, 'roleList', [])
      this.$set(this.form, 'btnList', [])
      this.$set(this.form, 'reportList', [])
      // this.form.btnList = [];
      // this.form.reportList = [];
      if (this.form.RoleList) {
        for (let i = 0; i < this.form.RoleList.length; i++) {
          this.roleList.push(this.form.RoleList[i].JSId)
          this.form.roleList.push(this.form.RoleList[i].JSId)
        }
      }
      let tmphdxsarry = []
      if (this.form.HDXS !== undefined) {
        tmphdxsarry = binary(this.form.HDXS, 3).split('')
      }
      for (let i = 0; i < tmphdxsarry.length; i++) {
        if (tmphdxsarry[i] !== '0') {
          this.form.hdxsarry.push(this.baseInfo.EvaluationActivityStyles[i].Key)
        }
      }
      if (
        this.form.EvaluationActivityButtonList &&
        this.form.EvaluationActivityButtonList.length > 0
      ) {
        this.form.EvaluationActivityButtonList.forEach(item => {
          const btn = this.baseInfo.Btns.find(c => c.Id === item.ANId)
          // if (btn && btn.ANLX !== 10) this.form.btnList.push(btn.Id)
          if (btn) this.form.btnList.push(btn.Id)
        })
      }

      if (this.form.EvaluationActivityReportTemplateList !== undefined) {
        this.form.EvaluationActivityReportTemplateList.forEach(item => {
          this.form.reportList.push(item.BBLX)
        })
        this.baseInfo.ReportTypes.forEach(item => {
          const info = this.form.EvaluationActivityReportTemplateList.find(
            c => c.BBLX === item.Key
          )
          console.log(info, 'info')
          this.reportTemplateList.push(info ? info.BBXZ : undefined)
        })
      }
      this.allowCreateChildActivity = this.form.SFYXZJHD === 1
      if (this.form && this.form.BPJDXLX) {
        this.oldBPJDXLX = this.form.BPJDXLX
      }
      // 问卷评价选人类型
      if (this.form.HDLX === 2 && this.form.PJFS === 3 && this.form.WJSZGZ) {
        let WJSZGZ = this.form.WJSZGZ.split(',')
        this.WJXZ.PJFS = Number(WJSZGZ[0])
        if (this.WJXZ.PJFS === 2) {
          this.WJXZ.letter = WJSZGZ[1]
          this.WJXZ.compare = Number(WJSZGZ[2])
          this.WJXZ.numType = Number(WJSZGZ[3])
          this.WJXZ.num = Number(WJSZGZ[4])
        }
      }
      // 处理学段回显
      let XD_Num = this.form.SSXD ? this.form.SSXD[0] : ''
      this.$set(this.form, 'SSXD', handle_SSXD(XD_Num))
    },
    // 加载课表方式变化
    JZKBLXchange(type) {
      // 有课表示 活动形式不能互评 自评
      if (type === 2 || type === 3) {
        this.form.hdxsarry = [1]
      }
    },
    // 按钮禁用
    disabledBtn(item) {
      // BPJDXLX:1.学生 2.老师 3.班级
      // HDCPR：1.老师 2.学生
      // ANLX：1.多评 2.撤销 3.指标管理 4.小组管理 5.我的评价 6.扫一扫 7.往期活动 8.日期选择 9.班级报表 10.总报表 11.主活动表 12.个人报表 13.小组报表

      // 学生评老师 禁用指标管理 扫一扫 班级报表 个人报表
      if (
        this.form.BPJDXLX === 2 &&
        this.form.HDCPR === 2 &&
        (item.ANLX === 6 ||
          item.ANLX === 3 ||
          item.ANLX === 9 ||
          item.ANLX === 12)
      )
        return true
      // 被评人为班级 禁用个人报表
      else if (item.ANLX === 12 && this.form.BPJDXLX === 3) return true
      // 没得子活动的 禁用往期活动（子活动才有往期活动）
      else if (this.form.SFYXZJHD !== 1 && item.ANLX === 7) return true
      // 无小组参与 禁用小组管理 小组报表
      else if (
        ![2, 3].includes(this.form.XZCYFS) &&
        [4, 13].includes(item.ANLX)
      )
        return true
      // 没得子活动的  主活动报表
      else if (this.form.SFYXZJHD !== 1 && item.ANLX === 11) return true
      // 通用指标 禁用往期活动  主活动报表
      else if (this.form.PJFS === 1 && [7, 11].includes(item.ANLX)) return true
      // 星星指标 禁用指标管理
      else if (this.form.PJFS === 2 && [3].includes(item.ANLX)) return true
      // 问卷指标 禁用撤销 指标管理 我的评价 往期活动 总报表 主活动报表 小组报表
      else if (this.form.PJFS === 3 && [2, 3, 5, 7, 11, 13].includes(item.ANLX))
        return true
      //   // 没得子活动、问卷指标、通用指标， 禁用总报表的星星总报表
      // else if(this.form.PJFS ===1 || this.form.SFYXZJHD !==1 || this.form.PJFS ===3) {
      // }
      else return false

      // return (
      //   (this.form.BPJDXLX === 2 &&
      //     this.form.HDCPR === 2 &&
      //     (item.ANLX === 6 ||
      //       item.ANLX === 3 ||
      //       item.ANLX === 9 ||
      //       item.ANLX === 12)) ||
      //   (item.ANLX === 12 && this.form.BPJDXLX === 3) ||
      //   (this.form.SFYXZJHD !== 1 && item.ANLX === 7) ||
      //   (![2, 3].includes(this.form.XZCYFS) && [4, 13].includes(item.ANLX)) ||
      //   (this.form.SFYXZJHD !== 1 && (item.ANLX === 10 || item.ANLX === 11)) ||
      //   (this.form.PJFS === 1 && [7, 10, 11].includes(item.ANLX)) ||
      //   (this.form.PJFS === 2 && [3].includes(item.ANLX)) ||
      //   (this.form.PJFS === 3 && [2, 3, 5, 7, 10, 11, 13].includes(item.ANLX))
      // );
    },
    // 先选指标候选人变化
    WJXZ_PJFS_change(val) {
      // 老师评老师选问题时 只能无课表
      if (val === 2 && this.form.BPJDXLX === 2 && this.form.HDCPR === 1)
        this.form.JZKBLX = 1
    }
  },
  watch: {
    dialogVisible: function(newVal) {
      this.roleList = []
      if (this.$refs[this.formName] !== undefined) {
        this.$refs[this.formName].resetFields()
      }
      this.reportTemplateList = []
      if (newVal) {
        this.init()
        this.HDLXchange()
        this.BPRchange()
        this.CPRchange()
        // this.form.SFYXZJHD = 2;
      } else {
        this.$refs.dialog.$el.scrollTop = 0
      }
    },
    baseInfo: {
      handler(newVal) {
        if (newVal.EvaluationActivityStyles) {
          const arr = JSON.parse(
            JSON.stringify(newVal.EvaluationActivityStyles)
          )
          this.HDXSoption = arr.map(item => {
            item.disabled = false
            return item
          })
        }
      },
      deep: true
    }
  },
  computed: {
    // eslint-disable-next-line no-unused-vars
    groupJoinDisable(data) {
      return function(data) {
        return (
          (data.Key === 2 || data.Key === 3) &&
          (this.form.BPJDXLX === 3 || this.form.BPJDXLX === 2)
        )
      }
    },
    // eslint-disable-next-line no-unused-vars
    courseTableDisable(data) {
      return function(data) {
        if (this.form.BPJDXLX === 2 && this.form.HDCPR === 2) {
          return data.Key === 2 || data.Key === 3
        } else if (this.form.BPJDXLX === 1) {
          return data.Key === 2
        } else if (this.form.BPJDXLX === 2) {
          return data.Key === 3
        } else if (this.form.BPJDXLX === 3) {
          return data.Key === 3
        } else if (
          this.form.HDLX === 2 &&
          this.form.HDCPR === 1 &&
          this.form.BPJDXLX === 2 &&
          this.form.PJFS === 3 &&
          this.WJXZ.PJFS === 2
        )
          return data.Key === 2 || data.Key === 3
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number__decrease {
  display: none;
}
::v-deep .el-input-number__increase {
  display: none;
}
::v-deep .el-input__inner {
  padding-left: 10px;
  padding-right: 10px;
}
.demo-ruleForm {
  .SZZPBRP {
    ::v-deep .el-form-item__label {
      text-align: left;
      line-height: 20px;
    }
  }
  .HDMS {
    .el-textarea {
      ::v-deep .el-textarea__inner {
        max-height: 120px;
        min-height: 50px !important;
      }
    }
  }
  .BM-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .el-form-item {
      width: 30%;
      ::v-deep .el-form-item__label {
        width: 100px !important;
        font-size: 12px;
      }
      ::v-deep .el-form-item__content {
        display: flex;
        width: calc(100% - 100px);
        .el-input {
          width: 100%;
          .el-input__inner {
            width: 100%;
          }
        }
      }
    }
  }
  .wjxz {
    .wjxz-pjfs {
      display: flex;
      align-items: center;
      height: 40px;
      .el-radio {
        width: 120px;
      }
    }
    .wjxz-2 {
      display: flex;
      align-items: center;
      padding: 5px 0;
      .el-select {
        margin-right: 5px;
      }
      .wjxz-num {
        margin-right: 5px;
        ::v-deep .el-input__inner {
          padding: 0;
        }
      }
    }
  }
}
</style>
