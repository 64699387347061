<template>
  <div>
    <el-table
      v-loading="isLoading"
      :data="tableData"
      :span-method="objectSpanMethod"
      class="jf-table"
      border
      tooltip-effect="dark"
    >
      <el-table-column
        align="center"
        prop="ParentIndexName"
        label="父级指标"
        min-width="150"
        fixed
      ></el-table-column>
      <el-table-column
        align="center"
        prop="IndexName"
        label="指标"
        min-width="150"
        fixed
      ></el-table-column>
      <el-table-column align="center" label="全选" min-width="50" fixed>
        <template slot-scope="scope">
          <el-checkbox
            :indeterminate="
              multipleSelection.filter(c => c.ZBId === scope.row.IndexId)
                .length < CurriculaList.length &&
                multipleSelection.filter(c => c.ZBId === scope.row.IndexId)
                  .length > 0
            "
            :value="
              multipleSelection.filter(c => c.ZBId === scope.row.IndexId)
                .length > 0
            "
            @change="handleSelectionChangeForAll(scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="110"
        v-for="(item, index) in CurriculaList"
        :key="index"
      >
        <template slot="header">{{ item.Name }}</template>
        <el-table-column align="center" width="110">
          <template slot="header">
            <el-checkbox
              :ref="'allIndex_' + item.Id"
              @change="handleSelectionChangeForCurricula(item)"
            ></el-checkbox>
          </template>
          <template slot-scope="scope">
            <el-checkbox
              :ref="scope.row.IndexId + '_' + item.Id"
              @change="handleSelectionChangeForIndex(item, scope.row)"
            ></el-checkbox>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="cent-bott-butt">
      <el-button
        size="medium"
        style="margin: 0px 25px;"
        @click="saveIndex"
        :loading="isSubmitting"
        >确认</el-button
      >
      <el-button size="medium" style="margin: 0px 25px;" @click="cancel"
        >取消</el-button
      >
    </div>
  </div>
</template>

<script>
var rowId = 0;
export default {
  data() {
    return {
      test: true,
      isLoading: false,
      tableData: [],
      multipleSelection: [],
      isSubmitting: false
    };
  },
  props: {
    CurriculaList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    SchoolId: {
      type: String,
      default: ""
    },
    TermId: {
      type: String,
      default: ""
    },
    User: {
      type: Object,
      default: function() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSelectionChangeForAll(val) {
      if (this.multipleSelection.filter(c => c.ZBId === val.IndexId).length) {
        this.multipleSelection = this.multipleSelection.filter(
          c => c.ZBId !== val.IndexId
        );
        for (let i = 0; i < this.CurriculaList.length; i++) {
          this.$refs[
            val.IndexId + "_" + this.CurriculaList[i].Id
          ][0].model = false;
          this.$refs[
            val.IndexId + "_" + this.CurriculaList[i].Id
          ][1].model = false;
          this.indexAllCurriculaCheckStatu(this.CurriculaList[i].Id);
        }
      } else {
        for (let i = 0; i < this.CurriculaList.length; i++) {
          let indexInfo = {};
          indexInfo.CJR = this.User.Id;
          indexInfo.SSXX = this.SchoolId;
          indexInfo.SSXQ = this.TermId;
          indexInfo.HDId = this.CurriculaList[i].Id;
          indexInfo.ZBId = val.IndexId;
          indexInfo.FJZBId = val.ParentIndexId;
          indexInfo.ZBFZ = val.Score;
          indexInfo.PJLX = val.EvaluationType;
          indexInfo.ZBTP = val.Icon;
          indexInfo.SSWD = val.DimensionId;
          indexInfo.ZBLX = val.IndexType;
          this.$refs[
            val.IndexId + "_" + this.CurriculaList[i].Id
          ][0].model = true;
          this.$refs[
            val.IndexId + "_" + this.CurriculaList[i].Id
          ][1].model = true;
          this.multipleSelection.push(indexInfo);
          this.indexAllCurriculaCheckStatu(this.CurriculaList[i].Id);
        }
      }
    },
    handleSelectionChangeForIndex(item, scop) {
      let currentIndex = this.multipleSelection.findIndex(
        c => c.ZBId === scop.IndexId && c.HDId === item.Id
      );
      if (currentIndex === -1) {
        let indexInfo = {};
        indexInfo.CJR = this.User.Id;
        indexInfo.SSXX = this.SchoolId;
        indexInfo.SSXQ = this.TermId;
        indexInfo.HDId = item.Id;
        indexInfo.ZBId = scop.IndexId;
        indexInfo.FJZBId = scop.ParentIndexId;
        indexInfo.ZBFZ = scop.Score;
        indexInfo.PJLX = scop.EvaluationType;
        indexInfo.ZBTP = scop.Icon;
        indexInfo.SSWD = scop.DimensionId;
        indexInfo.ZBLX = scop.IndexType;
        this.multipleSelection.push(indexInfo);
      } else {
        this.multipleSelection.splice(currentIndex, 1);
      }
      this.indexAllCurriculaCheckStatu(item.Id);
    },
    handleSelectionChangeForCurricula(item) {
      if (this.multipleSelection.filter(c => c.HDId === item.Id).length > 0) {
        this.multipleSelection = this.multipleSelection.filter(
          c => c.HDId !== item.Id
        );
        for (let i = 0; i < this.tableData.length; i++) {
          this.$refs[
            this.tableData[i].IndexId + "_" + item.Id
          ][0].model = false;
          this.$refs[
            this.tableData[i].IndexId + "_" + item.Id
          ][1].model = false;
        }
        this.$refs["allIndex_" + item.Id][0].model = false;
        this.$refs["allIndex_" + item.Id][1].model = false;
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          let indexInfo = {};
          indexInfo.CJR = this.User.Id;
          indexInfo.SSXX = this.SchoolId;
          indexInfo.SSXQ = this.TermId;
          indexInfo.HDId = item.Id;
          indexInfo.ZBId = this.tableData[i].IndexId;
          indexInfo.FJZBId = this.tableData[i].ParentIndexId;
          indexInfo.ZBFZ = this.tableData[i].Score;
          indexInfo.PJLX = this.tableData[i].EvaluationType;
          indexInfo.ZBTP = this.tableData[i].Icon;
          indexInfo.SSWD = this.tableData[i].DimensionId;
          indexInfo.ZBLX = this.tableData[i].IndexType;
          this.$refs[this.tableData[i].IndexId + "_" + item.Id][0].model = true;
          this.$refs[this.tableData[i].IndexId + "_" + item.Id][1].model = true;
          this.multipleSelection.push(indexInfo);
        }
        this.$refs["allIndex_" + item.Id][0].model = true;
        this.$refs["allIndex_" + item.Id][1].model = true;
      }
      this.indexAllCurriculaCheckStatu(item.Id);
    },
    indexAllCurriculaCheckStatu(itemId) {
      let currentCheck = this.$refs["allIndex_" + itemId];
      if (currentCheck && currentCheck.length > 1) {
        currentCheck[0].indeterminate = false;
        currentCheck[1].indeterminate = false;
      }

      let currentLength = this.multipleSelection.filter(c => c.HDId === itemId)
        .length;
      if (currentLength < this.tableData.length) {
        this.$refs["allIndex_" + itemId][0].selfModel = false;
        this.$refs["allIndex_" + itemId][1].selfModel = false;
        if (currentLength > 0) {
          this.$refs["allIndex_" + itemId][0].indeterminate = true;
          this.$refs["allIndex_" + itemId][1].indeterminate = true;
          this.test = true;
        }
      } else {
        this.$refs["allIndex_" + itemId][0].selfModel = true;
        this.$refs["allIndex_" + itemId][1].selfModel = true;
      }
    },
    // eslint-disable-next-line no-unused-vars
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let rowspan = 0;
      let colspan = 0;
      if (columnIndex === 0) {
        if (row.ParentIndexId !== rowId) {
          rowId = row.ParentIndexId;
          rowspan = row.ChildCount;
          colspan = 1;
        }
        return {
          rowspan: rowspan,
          colspan: colspan
        };
      }
    },
    getSetIndex() {
      this.$api.post(
        "/v1/curriculavariable/GetIndexByCurriculaVariable",
        {
          token: this.$store.state.token,
          CurriculaIds: this.CurriculaList,
          TermId: this.TermId
        },
        r => {
          if (r.Code === 0) {
            this.multipleSelection = r.Target;
            this.multipleSelection = this.multipleSelection.filter(c =>
              this.tableData.map(c => c.IndexId).includes(c.ZBId)
            );
            for (let i = 0; i < this.multipleSelection.length; i++) {
              this.$refs[
                this.multipleSelection[i].ZBId +
                  "_" +
                  this.multipleSelection[i].HDId
              ][0].model = true;
              this.$refs[
                this.multipleSelection[i].ZBId +
                  "_" +
                  this.multipleSelection[i].HDId
              ][0].model = true;
              this.indexAllCurriculaCheckStatu(this.multipleSelection[i].HDId);
            }
            this.isLoading = false;
          }
        }
      );
    },
    initData() {
      console.log(12);
      this.$set(this, "test", false);
      this.$api.post(
        "/v1/curriculavariable/GetCurriculaVariableIndexByManager",
        {
          token: this.$store.state.token,
          SchoolId: this.SchoolId
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
            this.getSetIndex();
          }
        }
      );
    },
    saveIndex() {
      this.isSubmitting = true;
      this.$api.post(
        "/v1/curriculavariable/SetCurriculaVariableIndex",
        {
          token: this.$store.state.token,
          IndexList: this.multipleSelection,
          UserId: this.User.Id,
          UserName: this.User.XSM,
          TermId: this.TermId
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
          }
        }
      );
      this.isSubmitting = false;
      this.$emit("closeOtherDialog");
    },
    cancel() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeOtherDialog");
      });
    }
  },
  created: function() {
    this.initData();
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        rowId = 0;
        this.isLoading = true;
        this.initData();
      } else {
        this.isLoading = false;
        this.multipleSelection = [];
        // 重置校验结果
        // this.$refs["formData"].resetFields();
      }
    }
  }
};
</script>
