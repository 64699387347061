<template>
  <el-table
    v-loading="isLoading"
    :data="dataTable"
    border
    tooltip-effect="dark"
    style="width: 100%;"
    class="jf-table"
  >
    <el-table-column
      fixed
      align="center"
      prop="PXH"
      label="排序"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      fixed
      align="center"
      prop="PJHDMC"
      label="子活动名称"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      align="center"
      prop="HDLX"
      label="活动类型"
      show-overflow-tooltip
    >
      <span>{{
        currentActivity.HDLX === 1 ? '过程性活动' : '阶段性活动'
      }}</span>
    </el-table-column>
    <el-table-column align="center" label="所属对象" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ scope.row.PJHDGLCYDXList | handleObjName(that) }}
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      label="开始时间"
      prop="KSSJ"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column align="center" label="活动状态" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ scope.row.SYZT == 1 ? '启用' : '禁用' }}
      </template>
    </el-table-column>
    <el-table-column align="center" label="是否结束" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ scope.row.SFJSSY == 1 ? '结束' : '开启' }}
      </template>
    </el-table-column>
    <el-table-column align="center" label="参与年级" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ scope.row.PJHDGLCYDXList | handleGrad(that) }}
      </template>
    </el-table-column>
    <el-table-column align="center" fixed="right" label="操作" width="180">
      <template slot-scope="scope">
        <el-button @click="editItem(scope.row)" type="text" size="small"
          >编辑</el-button
        >
        <el-button
          @click="delItem(scope.row)"
          type="text"
          size="small"
          class="tab-wz-butta c-red"
          >删除</el-button
        >
        <el-button
          v-show="currentActivity.HDLX === 2"
          @click="setStage(scope.row)"
          type="text"
          size="small"
          >设置阶段评价</el-button
        >
        <el-button @click="setDescription(scope.row)" type="text" size="small">
          设置描述语
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    basicData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    dataTable: {
      type: Array,
      default: function() {
        return []
      }
    },
    objectList: {
      type: Array,
      default: function() {
        return []
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    currentActivity: Object
  },
  data() {
    return {
      that: this
    }
  },
  filters: {
    handleObjName(val, that) {
      let str = ''
      that.currentActivity.ObjectList &&
        that.currentActivity.ObjectList.forEach((obj, index) => {
          val.forEach(id => {
            if (id === obj.Id) {
              str +=
                index === that.currentActivity.ObjectList.length - 1
                  ? obj.PJCYDXMC
                  : obj.PJCYDXMC + ','
            }
          })
        })

      return str
    },
    handleGrad(val, that) {
      let str = ''
      let grade = []

      that.currentActivity.ObjectList &&
        that.currentActivity.ObjectList.forEach(obj => {
          val.forEach(id => {
            if (id === obj.Id) {
              grade = [...grade, ...obj.GradeOfObjectList]
            }
          })
        })
      // 去重排序
      grade = Array.from(new Set(grade)).sort((a, b) => a - b)
      grade.forEach((item, index) => {
        str += index === grade.length - 1 ? item + '年级' : item + '年级，'
      })
      return str
    }
  },
  methods: {
    editItem(data) {
      this.$emit('editItem', data)
    },
    delItem(data) {
      this.$emit('delItem', data)
    },
    setStage(data) {
      this.$emit('setStage', data)
    },
    setDescription(data) {
      this.$emit('setDescription', data)
    }
  }
}
</script>
