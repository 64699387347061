<template>
  <el-tree
    :props="props"
    :load="loadNode"
    @node-click="nodeClick"
    :expand-on-click-node="false"
    lazy
    highlight-current
  >
  </el-tree>
</template>

<script>
export default {
  data() {
    return {
      props: {
        label: "name",
        isLeaf: "leaf",
        newNode: {
          type: Array,
          required: false
        }
      }
    };
  },
  methods: {
    loadNode(node, resolve) {
      var parentId = "0";
      if (node.data !== undefined) {
        parentId = node.data.data_id;
      }
      this.$emit("loadNode", parentId, res => {
        resolve(res);
      });
    },
    nodeClick(data, node) {
      var param = {};
      param.Id = data.data_id;
      param.Nodetype = data.data_nodetype;
      param.level = data.data_level;
      param.nodeId = data.id;
      param.ParentId = data.data_parentid;
      this.$emit("changeNode", param, node);
    }
  }
};
</script>
