import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Index from "@/views/Index.vue";
import { menuRoutes } from "./menuroutes.js";

import store from "@/store/index.js";
Vue.use(VueRouter);
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
// eslint-disable-next-line no-unused-vars
const sourceMenuRoutes = JSON.parse(JSON.stringify(menuRoutes));
function getRoutes(menuRoutes) {
  // const index = menuRoutes.findIndex(c => c.groupcode);
  // if (index !== -1) {
  //   const children = menuRoutes[index].children;
  //   menuRoutes.splice(
  //     menuRoutes.findIndex(c => c.groupcode),
  //     1
  //   );
  //   children.unshift(index, 0);
  //   Array.prototype.splice.apply(menuRoutes, children);
  //   getRoutes(menuRoutes);
  // }
  return menuRoutes;
}

// eslint-disable-next-line no-unused-vars
function permissionMenu(data, userType, codes) {
  data = data.filter(
    c =>
      c.meta &&
      c.meta.roleType &&
      (c.meta.roleType === userType || codes.includes(c.meta.permissionCode))
  );
  for (let item of data.filter(c => c.children && c.children.length > 0)) {
    permissionMenu(item.children, userType, codes);
  }
  return data;
}
const routes = [
  {
    path: "/",
    name: "主页",
    component: Home,
    meta: {
      requireAuth: true, // 反向验证
      title: "主页"
    }
  },
  {
    path: "/login",
    name: "登录",
    component: Login,
    meta: {
      requireAuth: true // 反向验证
    }
  },
  {
    path: "/qrcodepreview",
    name: "预览",
    component: resolve =>
      require(["@/views/qrcode/QrCodePreView.vue"], resolve),
    meta: {
      requireAuth: true // 反向验证
    }
  },
  {
    path: "/qrcodeprint",
    name: "下载",
    component: resolve => require(["@/views/qrcode/QrCodePrint.vue"], resolve),
    meta: {
      requireAuth: true // 反向验证
    }
  },
  {
    path: "/pdf/download",
    name: "pdf下载",
    meta: {
      requireAuth: true
    },
    component: resolve => require(["@/views/manager/pdf/download.vue"], resolve)
  },
  {
    path: "/pdf/export",
    name: "pdf打印",
    meta: {
      requireAuth: true
    },
    component: resolve => require(["@/views/manager/pdf/export.vue"], resolve)
  },
  {
    path: "/cover",
    name: "cover",
    meta: {
      requireAuth: true
    },
    component: resolve =>
      require(["@/views/manager/pdf/templates/cover.vue"], resolve)
  },
  {
    path: "/UsingHelpIndex",
    name: "UsingHelpIndex",
    meta: {
      requireAuth: true // 反向验证
    },
    component: resolve =>
      require(["@/views/manager/UsingHelp/UsingHelpIndex.vue"], resolve)
  },
  {
    path: "/UsingHelpListIndex",
    name: "UsingHelpListIndex",
    meta: {
      requireAuth: true // 反向验证
    },
    component: resolve =>
      require(["@/views/manager/UsingHelp/UsingHelpListIndex.vue"], resolve)
  }
  // {
  //   path: "/UsingHelp",
  //   name: "UsingHelp",
  //   meta: {
  //     requireAuth: true // 反向验证
  //   },
  //   component: resolve =>
  //     require(["@/views/UsingHelp.html"], resolve)
  // }
  // {
  //   path: "/index",
  //   name: "index",
  //   component: Index,
  //   meta: {
  //     title: "首页",
  //   },
  //   children: getRoutes(menuRoutes),
  // },
];

const IndexRoutes = [
  {
    path: "/index",
    name: "index",
    component: Index,
    meta: {
      title: "首页"
    },
    children: []
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
const dev = false;
var storeTemp = store;
let registerRouteFresh = true;
router.beforeEach((to, from, next) => {
  let loginSucess = sessionStorage.getItem("loginSucess");
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // }
  if (!storeTemp.state.token) {
    storeTemp.commit("saveToken", window.localStorage.Token);
  }
  if (!to.meta.requireAuth) {
    const isLogin = sessionStorage.getItem("userinfo");
    if (storeTemp.state.token && isLogin) {
      if (!loginSucess || registerRouteFresh) {
        // IndexRoutes[0].children = [];
        // eslint-disable-next-line no-unused-vars
        if (dev) {
          router.addRoutes(IndexRoutes);
        } else {
          let user = JSON.parse(isLogin);
          let menu = JSON.parse(JSON.stringify(sourceMenuRoutes));
          let permList = JSON.parse(storeTemp.state.permissions);
          IndexRoutes[0].children = getRoutes(menuRoutes).filter(c => {
            // c.meta &&
            // c.meta.roleType &&
            // (c.meta.roleType === user.YHLX ||
            //   permList.includes(c.meta.permissionCode))
            if (c.meta && c.meta.roleType && c.meta.roleType === user.YHLX) {
              if (user.YHLX === 1) {
                return user.menu.some(item => {
                  if (item.CodeOREId === c.meta.code) {
                    c.meta.title = item.Name;
                    if (
                      item.ChildMenusList &&
                      item.ChildMenusList.length > 0 &&
                      c.children.length > 0
                    ) {
                      c.children = c.children.filter(it => {
                        return item.ChildMenusList.some(
                          el => el.CodeOREId === it.meta.code
                        );
                      });
                    } else {
                      if (c.meta.code === "StageEvaluation") {
                        console.log(c, "c");
                      } else {
                        c.children = [];
                      }
                    }

                    return true;
                  } else return false;
                });
              } else return true;
            } else return false;
          });
          // console.log(IndexRoutes[0].children,"12222222222222222")
          console.log('IndexRoutes:', IndexRoutes)
          router.addRoutes(IndexRoutes);
          storeTemp.commit("updateMenu", IndexRoutes[0].children);
          // storeTemp.commit(
          //   "updateMenu",
          //   permissionMenu(menu, user.YHLX, permList)
          // );
        }
        registerRouteFresh = false;
        sessionStorage.setItem("loginSucess", true);
        next({ ...to, replace: true });
      }
      next();
    } else {
      sessionStorage.removeItem("loginSucess");
      sessionStorage.removeItem("userinfo");
      localStorage.removeItem("Token");
      localStorage.removeItem("userinfo");
      localStorage.removeItem("MenuList");
      localStorage.removeItem("PermList");
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    }
  } else {
    next();
  }
});
export default router;
