var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"public-hd whole-back"},[_c('div',{staticClass:"contanier"},[_c('div',{staticClass:"hd-top paddno-l"},[_vm._m(0),_c('div',{staticClass:"r-box f-r"},[_c('div',{staticClass:"btn-login"},[_c('router-link',{staticClass:"btn-login-a whole-col",attrs:{"to":{ path: '/login' }}},[_vm._v("登录")])],1)])]),_c('div',{staticClass:"con-top"},[_c('div',{staticClass:"tp-caption"},[_c('span',{staticClass:"t-mk"},[_vm._v("中小学生综合素质评价")]),_c('div',{staticClass:"btn_download my_btn_download",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showEWM = true}}},[_c('span',{staticClass:"btn_download-a whole-col-hov"},[_vm._v("下载app")])]),_c('div',{staticClass:"btn_download my_btn_download",staticStyle:{"margin-top":"20px","cursor":"pointer"},on:{"click":_vm.downloadCS}},[_c('a',{staticClass:"btn_download-a whole-col-hov"},[_vm._v("下载PC端")])]),_c('div',{staticClass:"btn_download my_btn_download",staticStyle:{"margin-top":"20px","cursor":"pointer"},on:{"click":_vm.download_ZZZD}},[_c('a',{staticClass:"btn_download-a whole-col-hov"},[_vm._v("自助评价终端机")])])]),_vm._m(1)])])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('el-dialog',{attrs:{"title":"app下载","visible":_vm.showEWM,"width":"50%"},on:{"update:visible":function($event){_vm.showEWM=$event}}},[_c('div',{staticClass:"app-erm"},[_c('img',{attrs:{"src":require('@/style/images/图片2.png'),"alt":""}}),_c('img',{attrs:{"src":require('@/style/images/图片1.png'),"alt":""}})])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-box f-l"},[_c('img',{attrs:{"src":require("../style/images/logo_index.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tp-img"},[_c('img',{attrs:{"src":require("../style/images/img_index.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assayplan-box"},[_c('div',{staticClass:"contanier"},[_c('div',{staticClass:"title-head"},[_c('span',{staticClass:"s-tit"},[_vm._v("Data analysis")]),_c('span',{staticClass:"b-tit"},[_vm._v("大数据分析 ⁄ 模型数据分析")]),_c('img',{staticClass:"title-head-img",attrs:{"src":require("../style/images/tit_line.jpg")}})]),_c('div',{staticClass:"assayplan-bd"},[_c('img',{attrs:{"src":require("../style/images/assayplan_img.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-box"},[_c('div',{staticClass:"contanier"},[_c('div',{staticClass:"title-head"},[_c('span',{staticClass:"s-tit"},[_vm._v("Product features")]),_c('span',{staticClass:"b-tit"},[_vm._v("产品特色")]),_c('img',{attrs:{"src":require("../style/images/tit_line.jpg")}})]),_c('div',{staticClass:"product-con"},[_c('div',{staticClass:"pdt-list"},[_c('img',{staticClass:"pdt-list-img",attrs:{"src":require("../style/images/img_01.jpg")}}),_c('div',{staticClass:"pdt-txt"},[_vm._v(" 借助智能设备实现对学生部分行为数据实现自动采集。 ")])]),_c('div',{staticClass:"pdt-list"},[_c('img',{staticClass:"pdt-list-img",attrs:{"src":require("../style/images/img_02.jpg")}}),_c('div',{staticClass:"pdt-txt"},[_vm._v(" 分析学生行为数据,发现偏离及时纠偏,实现过程反馈和控制。 ")])]),_c('div',{staticClass:"pdt-list"},[_c('img',{staticClass:"pdt-list-img",attrs:{"src":require("../style/images/img_03.jpg")}}),_c('div',{staticClass:"pdt-txt"},[_vm._v(" 分析每个学生的个体情况定制养成方案。 ")])]),_c('div',{staticClass:"pdt-list"},[_c('img',{staticClass:"pdt-list-img",attrs:{"src":require("../style/images/img_04.jpg")}}),_c('div',{staticClass:"pdt-txt"},[_vm._v(" 对于学生的发展潜能偏好进行基于数字化的分析。 ")])]),_c('div',{staticClass:"pdt-list"},[_c('img',{staticClass:"pdt-list-img",attrs:{"src":require("../style/images/img_05.jpg")}}),_c('div',{staticClass:"pdt-txt"},[_vm._v(" 与西南大学合作建立科学的评价模型，为学生成长和学校发展提供伴随式服务。 ")])]),_c('div',{staticClass:"pdt-list"},[_c('img',{staticClass:"pdt-list-img",attrs:{"src":require("../style/images/img_06.jpg")}}),_c('div',{staticClass:"pdt-txt"},[_vm._v(" 家长实时掌控孩子在校的表现情况。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case-box"},[_c('div',{staticClass:"contanier"},[_c('div',{staticClass:"title-head"},[_c('span',{staticClass:"s-tit"},[_vm._v("Successful case")]),_c('span',{staticClass:"b-tit"},[_vm._v("成功案例")]),_c('img',{attrs:{"src":require("../style/images/tit_line.jpg")}})]),_c('div',{staticClass:"case-con"},[_c('img',{attrs:{"src":require("../style/images/case_img.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features-box"},[_c('div',{staticClass:"contanier"},[_c('div',{staticClass:"features-con"},[_c('div',{staticClass:"ft-list"},[_c('div',{staticClass:"hd"},[_vm._v(" 合作 ")]),_c('div',{staticClass:"con"},[_c('img',{staticClass:"con-img",attrs:{"src":require("../style/images/ft-tit-line.jpg")}}),_c('span',{staticClass:"con-span"},[_vm._v("电话：023-6869 4689")]),_c('span',{staticClass:"con-span"},[_vm._v("邮箱：junfa01@163.com")]),_c('span',{staticClass:"con-span"},[_vm._v("地址：重庆石桥铺西部商城C座19-1")])])]),_c('div',{staticClass:"ft-list"},[_c('div',{staticClass:"ft-box"},[_c('img',{attrs:{"src":require("../style/images/code-img.jpg")}}),_c('span',{staticClass:"ft-box-span"},[_vm._v("扫一扫 客户端下载")])])]),_c('div',{staticClass:"ft-list"},[_c('div',{staticClass:"t-r",staticStyle:{"text-align":"right"}},[_c('div',{staticClass:"hd"},[_vm._v(" 服务 ")]),_c('div',{staticClass:"con"},[_c('img',{staticClass:"con-img",attrs:{"src":require("../style/images/ft-tit-line.jpg")}}),_c('span',{staticClass:"con-span"},[_vm._v("电话：023-6869 4689")]),_c('span',{staticClass:"con-span"},[_vm._v("QQ： 6869 4689")]),_c('span',{staticClass:"con-span"},[_vm._v("邮箱： junfa02@163.com")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-box"},[_vm._v(" Copyright © 2012 - 2022 重庆骏发科技发展有限公司. All Rights Reserved. "),_c('a',{attrs:{"target":"_blank","href":"https://beian.miit.gov.cn"}},[_vm._v("渝ICP备15010168号-2")])])
}]

export { render, staticRenderFns }