<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="30%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <div v-if="setIndexType === 3">
      <div
        v-for="item in treeData"
        :key="item.value"
        style="
          display: block;
          height: 26px;
          align-items: center;
          -webkit-box-align: center;
        "
      >
        <el-radio
          style="margin-left: 23px;"
          v-model="radio"
          :label="item.value"
          >{{ item.label }}</el-radio
        >
      </div>
    </div>
    <el-tree
      v-else
      :data="treeData"
      show-checkbox
      default-expand-all
      node-key="value"
      ref="tree"
      highlight-current
      :default-checked-keys="checkedData"
      :props="defaultProps"
      @check-change="checkChange"
    ></el-tree>
    <!-- <div class="buttons">
      <el-button @click="getCheckedNodes">通过 node 获取</el-button>
      <el-button @click="getCheckedKeys">通过 key 获取</el-button>
      <el-button @click="setCheckedNodes">通过 node 设置</el-button>
      <el-button @click="setCheckedKeys">通过 key 设置</el-button>
      <el-button @click="resetChecked">清空</el-button>
    </div>-->
    <span class="dialog-footer" v-if="!validate">
      <div class="prompt-title-box" style="color: red; text-align: center;">
        请至少选择一项
      </div>
    </span>
    <span slot="footer" class="dialog-footer" v-if="treeData.length > 0">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      validate: true,
      checked: [],
      radio: "",
      defaultProps: {
        children: "children",
        label: "label"
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    checkedData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    title: {
      type: String,
      default: ""
    },
    setIndexType: {
      type: Number,
      default: 1
    }
  },
  methods: {
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("canceletIndex");
        this.validate = true;
      });
    },
    confirmDialog() {
      let data = [];
      if (this.setIndexType === 3) {
        if (this.radio) {
          data.push({ value: this.radio });
        }
      } else {
        const list = this.$refs.tree.getCheckedNodes();
        if (list && list.length > 0) {
          data = list.filter(c => c.children.length === 0);
          // data = list;
        }
      }
      if (data.length > 0) {
        this.$emit("saveSetIndex", data);
      } else {
        this.validate = false;
      }
    },
    checkChange() {
      const list = this.$refs.tree.getCheckedNodes();
      if (list && list.length > 0) {
        this.validate = true;
      } else {
        this.validate = false;
      }
    }
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        // 初始化
        // this.checked=JSON.stringify(checkedData)
        if (this.setIndexType === 3) {
          this.radio = this.checkedData[0] ? this.checkedData[0] : "";
        } else {
          if (this.$refs.tree) {
            this.$refs.tree.setCheckedKeys(this.checkedData);
          }
        }
      } else {
        // 清空数据
        // this.resetChecked();
      }
    }
  }
};
</script>
