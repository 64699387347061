<template>
  <div>
    <el-table
      :data="tableData"
      class="jf-table"
      border
      tooltip-effect="dark"
      style="width: 100%;"
    >
      <el-table-column fixed prop="JTMC" label="集团名称"></el-table-column>
      <el-table-column
        prop="XXSL"
        label="学校数量"
        width="150"
      ></el-table-column>
      <el-table-column prop="XXMC" label="所属学校" width="450">
        <template slot-scope="scope">
          <span>
            <div
              class="teacher-item"
              v-for="item in scope.row.JTSSXXInfo"
              :key="item.Id"
            >
              <span>学校:{{ item.XXMC }}</span>
              <span>{{ "\xa0\xa0\xa0" }}</span>
              <el-button
                @click="delXXItem(item.Id)"
                type="text"
                size="small"
                icon="el-icon-delete"
                >删除
              </el-button>
            </div>
          </span>
          <span>
            <el-button
              @click="addXXItem(scope.row.Id)"
              type="text"
              size="small"
              class="el-icon-circle-plus-outline"
              >添加
            </el-button>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="ZH" label="集团管理员" width="450">
        <template slot-scope="scope">
          <span>
            <div
              class="teacher-item"
              v-for="item in scope.row.JTZHGLInfo"
              :key="item.Id"
            >
              <span>账号:{{ item.ZH }}</span>
              <span>{{ "\xa0\xa0\xa0" }}</span>
              <span>密码:{{ item.MM }}</span>
              <span>{{ "\xa0\xa0\xa0" }}</span>
              <el-button
                @click="addPwdItem(item.Id)"
                type="text"
                size="small"
                icon="el-icon-edit"
                >修改密码
              </el-button>
              <el-button
                @click="delZHItem(item.Id)"
                type="text"
                size="small"
                icon="el-icon-delete"
                >删除
              </el-button>
            </div>
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button
            @click="delItem(scope.row)"
            type="text"
            size="small"
            icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Reviews :show.sync="showReviews" />
    <PwdReviews :show.sync="showPwdReviews" />

    <XXForm
      :data="tableData"
      :visible.sync="visibleXX"
      title="添加学校"
      :shapes="shapes"
      :user="user"
    />
    <PwdForm :data="tableData" title="修改密码" :shapes="shapes" :user="user" />
  </div>
</template>

<script>
import Reviews from "./XXForm.vue";
import PwdReviews from "./PwdForm.vue";
import XXForm from "./XXForm.vue";
import PwdForm from "./PwdForm.vue";

export default {
  components: {
    Reviews,
    PwdReviews,
    XXForm,
    PwdForm
  },
  data() {
    return {
      spanData: [],
      visibleXX: false,
      showReviews: false, //显示弹窗
      showPwdReviews: false //显示弹窗
    };
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    },
    shapes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  mounted() {},
  methods: {
    getData() {
      this.$emit("getDataTable");
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.delete(
            "/v1/Growth/DeleteGroup",
            {
              token: this.$store.state.token,
              id: data.Id
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message
                });
                this.$emit("getDataTable");
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    delXXItem(xxId) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$api.delete(
          "/v1/Growth/DeleteJTSSXX",
          {
            token: this.$store.state.token,
            id: xxId
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "success",
                message: r.Message
              });
              this.$emit("getDataTable");
            }
          }
        );
      });
    },
    delZHItem(zhId) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$api.delete(
          "/v1/Growth/DeleteJTZHGL",
          {
            token: this.$store.state.token,
            id: zhId
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "success",
                message: r.Message
              });
              this.$emit("getDataTable");
            }
          }
        );
      });
    },
    addXXItem(val) {
      this.showReviews = true;
      this.$router.push({
        query: { Id: val }
      });
    },
    addPwdItem(val) {
      this.showPwdReviews = true;
      this.$router.push({
        query: { Id: val }
      });
    }
  }
};
</script>
