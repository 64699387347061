<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <div class="tab-heng-box margno-t">
        <div
          class="table-er-box"
          style="
                font-size: 14px;
                margin-bottom: 10px;
                margin-right: 10px;
                display: block;
                text-align: right;
              "
        >
          <el-button type="success" @click="gotomine()">我的问卷管理</el-button>
        </div>
        <div class="table-er-box">
          <div class="table-er-butt">
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择开始日期"
              v-model="kssj"
            ></el-date-picker>
            <span
              class="ts-span-sm"
              style="margin-left: 2px; margin-right: 2px;"
              >—</span
            >
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择结束日期"
              v-model="jssj"
            ></el-date-picker>
            <el-radio-group
              v-model="rqlx"
              @change="rqlxchange()"
              style="margin-left: 10px; margin-top: -10px;"
            >
              <el-radio-button :label="1">本期</el-radio-button>
              <el-radio-button :label="2">本周</el-radio-button>
            </el-radio-group>
          </div>
          <div
            style="
                  font-size: 14px;
                  margin-bottom: 10px;
                  margin-right: 10px;
                  display: inline-block;
                  float: right;
                "
          >
            <el-input
              v-model="searchname"
              placeholder="请输入关键字"
              maxlength="20"
              prefix-icon="el-icon-search"
            ></el-input>
            <span
              style="color: #14d089; margin-left: 5px; cursor: pointer;"
              @click="handleSearch()"
              >搜索</span
            >
          </div>
        </div>
        <el-table
          :data="noticelist"
          highlight-current-row
          style="width: 100%;"
          :border="false"
        >
          <el-table-column property="BT">
            <template slot-scope="scope">
              <span @click="handleDetail(scope.row.Id, scope.row.XXFL)">{{
                scope.row.BT
              }}</span>
              <el-badge
                v-if="scope.row.ReadStatus == '2'"
                class="item"
                value="new"
              ></el-badge>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <span v-if="scope.row.ReadStatus == '1'">已读</span>
              <span v-else>未读</span>
            </template>
          </el-table-column>
          <el-table-column property="CJSJ">
            <template slot-scope="scope">
              {{ scope.row.CJSJ | DateFormat("yyyy-MM-dd") }}
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="rowcount"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.item {
  margin-top: 5px;
}

.tab-heng-box table tbody tr td,
.tab-heng-box table thead tr td {
  border: none;
}
</style>
<script>
export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.userid = user.Id;
      this.yhlb = user.UserType;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    rqlxchange: function() {
      this.kssj = "";
      this.jssj = "";
      this.loaddata();
    },
    gotomine: function() {
      this.$router.push({ name: "myquestionnairelist" });
    },
    handleDetail: function(id) {
      this.editid = id;
      this.$router.push({
        name: "questionnairedetail",
        params: { ggid: this.editid }
      });
    },
    handleSearch: function() {
      this.rqlx = 0;
      this.loaddata();
    },
    loaddata: function() {
      var pagerInfo = { pageIndex: this.pageIndex, pageSize: this.pagesize };
      this.$api.post(
        "v1/notice/GetWebListForMe",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          YHId: this.userid,
          RQLX: this.rqlx,
          XXFL: 2,
          SearchName: this.searchname,
          KSSJ: this.kssj,
          JSSJ: this.jssj,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.noticelist = [];
            this.noticelist = r.Target;
            this.rowcount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    // 翻页
    handleSizeChange(val) {
      this.pagesize = val;
      this.loaddata();
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.loaddata();
    }
  },
  data() {
    return {
      isLogin: true,
      noticelist: [],
      ssxx: "",
      ssxq: "",
      searchname: "",
      userid: "",
      kssj: "",
      jssj: "",
      token: "",
      rqlx: 0,
      currentPage: 0,
      pageIndex: 1,
      pagesize: 10,
      rowcount: 0
    };
  }
};
</script>
