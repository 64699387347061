var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"30%","center":"","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"tab-shu-box"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData}},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("按钮名称")]),_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"rules":[
                  {
                    required: true,
                    message: '请输入报表代码',
                    trigger: 'change'
                  }
                ],"prop":"ANMC"}},[_c('el-input',{staticClass:"jf-form",attrs:{"placeholder":"请输入内容","size":"small","prop":"ANMC"},model:{value:(_vm.formData.ANMC),callback:function ($$v) {_vm.$set(_vm.formData, "ANMC", $$v)},expression:"formData.ANMC"}})],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("支撑评价方式")]),_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"rules":[
                  {
                    type: 'array',
                    required: true,
                    message: '请选择支撑评价方式',
                    trigger: 'change'
                  }
                ],"prop":"checkedPJFSData"}},[_c('el-checkbox',{on:{"change":_vm.checkedPJFS},model:{value:(_vm.formData.TYZB),callback:function ($$v) {_vm.$set(_vm.formData, "TYZB", $$v)},expression:"formData.TYZB"}},[_vm._v("通用指标")]),_c('el-checkbox',{on:{"change":_vm.checkedPJFS},model:{value:(_vm.formData.XXZB),callback:function ($$v) {_vm.$set(_vm.formData, "XXZB", $$v)},expression:"formData.XXZB"}},[_vm._v("星星指标")]),_c('el-checkbox',{on:{"change":_vm.checkedPJFS},model:{value:(_vm.formData.WJ),callback:function ($$v) {_vm.$set(_vm.formData, "WJ", $$v)},expression:"formData.WJ"}},[_vm._v("问卷")])],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("按钮属性")]),_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"rules":[
                  {
                    required: true,
                    message: '请选择按钮类型',
                    trigger: 'change'
                  }
                ],"prop":"ANLX"}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.formData.ANLX),callback:function ($$v) {_vm.$set(_vm.formData, "ANLX", $$v)},expression:"formData.ANLX"}},_vm._l((_vm.shapes.filter(c =>
                      _vm.shapeLimit.includes(c.Key)
                    )),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("占位")]),_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"rules":[
                  {
                    required: true,
                    message: '请选择占位',
                    trigger: 'change'
                  }
                ],"prop":"ZW"}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.formData.ZW),callback:function ($$v) {_vm.$set(_vm.formData, "ZW", $$v)},expression:"formData.ZW"}},_vm._l((_vm.zw),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("排序")]),_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"rules":[
                  {
                    required: true,
                    message: '请输入排序号',
                    trigger: 'change'
                  }
                ],"prop":"PXH"}},[_c('el-input',{staticClass:"jf-form",attrs:{"type":"number","placeholder":"请输入内容","size":"small","prop":"PXH"},model:{value:(_vm.formData.PXH),callback:function ($$v) {_vm.$set(_vm.formData, "PXH", $$v)},expression:"formData.PXH"}})],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("状态")]),_c('td',[_c('el-form-item',{staticClass:"jf-form-item",attrs:{"rules":[
                  {
                    required: true,
                    message: '请选择使用状态',
                    trigger: 'change'
                  }
                ],"prop":"SYZT"}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small","prop":"SYZT"},model:{value:(_vm.formData.SYZT),callback:function ($$v) {_vm.$set(_vm.formData, "SYZT", $$v)},expression:"formData.SYZT"}},_vm._l((_vm.statu),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1)])])])])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmDialog}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }