<template>
    <el-dialog top="5vh" :visible.sync="value" width="297mm" class="my-dialog"  ref="dialog"
        :close-on-click-modal="false" :before-close="close">
      <el-button @click="downPFB()"  style="float: left;margin-left: 20px;" size="small" type="primary" >下载报表</el-button>
			<el-button @click="printpdf" type="primary" size="small" icon="el-icon-printer">打印</el-button>
        
      <div class="page-container pdfCentent" ref="print">
        <div class="page-title">{{ pageTitle }}评分表</div>
        <p class="page-subtitle">{{ pageSubtitle }}</p>
        <div class="top-box">
            <div class="top-item">单位(公章):</div>
            <div class="top-item">校长(签字):</div>
            <div class="top-item">被考核人：{{tableRow.KHRXM}}</div>
        </div>    
        <!-- 表格 -->
        <el-table :data="tableData"  border align="center" :span-method="arraySpanMethod">
            <el-table-column  label="考核项目" align="center">
                <template slot-scope="scope">
                    <div :style="{textAlign:scope.$index == tableData.length-1?'left':'center'}">
                        {{scope.row.khxm}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="fz" align="center" label="分值" width="70">
            </el-table-column>
            <el-table-column align="center" :label="`定量考核${sumObj.DLXKLable}%`">
                <el-table-column prop="dldf" align="center" label="减分" >
                    <el-table-column prop="jfxm" align="center" label="项目">
                    </el-table-column>
                    <el-table-column prop="jffz" align="center" label="分值" width="50">
                    </el-table-column>
                </el-table-column>
            <el-table-column label="减分原因"  align="center" >
                <template slot-scope="scope">
                    {{scope.row.jfyy}}
                </template>
            </el-table-column>
            <el-table-column label="定量考核最后得分" width="60" align="center" prop="dldf"> </el-table-column>
            </el-table-column>
            <el-table-column align="center" prop="date" :label="`定性考核${sumObj.DXKLable}%`" >
                <el-table-column prop="date" align="center" label="分类考核" >
                    <!-- <el-table-column  width="70" :label="`学生家长评(20%)`" > -->
                    <el-table-column  width="70" :label="`学生家长评(${sumObj.sum1BL}%)`" >
                        <template slot-scope="scope">
                            <div :style="{textAlign:scope.$index == tableData.length-1?'left':'center'}">
                                {{scope.row.xsjz}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="jszhp" align="center" width="80" :label="`教师自评/互评(${sumObj.sum2BL}%)`" >
                    </el-table-column>
                    <el-table-column prop="khldxz" align="center" width="70" :label="`考核组评(${sumObj.sum3BL}%)`" >
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="dxdf" align="center" width="70" label="定性考核最后得分" >
                </el-table-column>
            </el-table-column>
            <el-table-column prop="zhdf"  align="center" width="70" label="职业道德考核最后得分" >
            
            </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </template>
  
  <script>
    import JSZip from "jszip";
    import FileSaver from "file-saver";

    export default {
      props: {
          visible: {
              type: Boolean,
              default: false
          },
          tableRow: {
              type: Object,
              required: true,
              default: function() {
                  return {};
              }
          },
          pageSubtitle:String,
          
      },
      model: {
          prop: "visible",
          event: "change"
      },
      data() {
          return {
              value:false,
              pageTitle: JSON.parse(localStorage.getItem('DevelopmentMB')).TTMC,
              sumObj:{},
              tableData:[],
              zips:null,
          }
      },
      methods:{
        //打印
        printpdf: function() {
          console.log(this.$refs.print,'this.$refs.print')
          if(this.tableData){
            this.$print(this.$refs.print,{},1);
          }
        },
        //和并列
        arraySpanMethod({ row, column, rowIndex, columnIndex }){
            if (rowIndex == this.tableData.length-1) {
                if(columnIndex === 0){
                    return [1,6]
                }else if([1,2,3,4,5].includes(columnIndex)) return [0,0]
                else if (columnIndex === 6) return [1,5]
                else if([7,8,9,10,11].includes(columnIndex))return [0,0]
            }
        },
        // 下载
        async downPFB(){
            this.zips = new JSZip();   
            let title = this.pageTitle
            let p = await this.getPdfFromHtml2('pdfCentent',title)
            console.log(p);
            await this.zips.file(
                `${p.name}.pdf`,
                p.PDF.output("blob")
            );
            this.zips.generateAsync({
                type: "blob",
            }).then((content) => {
                FileSaver.saveAs(
                content,
                this.tableRow.KHRXM +"-"+"个人评分表"+".zip"
                );
            })
        },
        close(){
            this.$emit('canceldialog')
        },
      },
        watch:{
            visible(val) {
                this.value = val;
                if(val){
                      this.sumObj = JSON.parse(localStorage.getItem("sumObj"));
                      this.tableData = this.tableRow.JG 
                }
            }
        }
    }
  </script>
  
  <style lang="scss" scoped>
    .el-table{
        border-color:black ;
        border-right: 1px solid black!important;
        border-bottom: 1px solid black!important;
      ::v-deep .el-table__body-wrapper{
          .el-table__row{
              .el-table__cell{
                  padding:  5px 0;
                  color: black;
                  border-color:black ;
                  border :1px solid black !important;
                  .cell{
                      padding: 0 5px;
                  }
              }
          }
      }
      ::v-deep .el-table__header{
          .el-table__cell{
              padding:  5px 0;
              color: black;
              border-color:black ;
              border :1px solid black !important;
              .cell{
                      padding: 0 5px;
                  }
          }
      }
    }
    .my-table{
        tr{
          td:last-child{
            border-right: none;
          }  
        }
        
    }
    .my-dialog{
        padding: 2px;
        min-width: 1200px;
        ::v-deep .el-dialog__body{
            padding: 1px;
            max-height: 210mm;
            height: 210mm;
        }
    }
  .top-box{
        display: flex;
        justify-content: space-between;
        color: black;
    }
  .page-container {
    width: 297mm;
    height: 210mm;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    height: calc(100% - 32px);
    overflow-y: scroll;
  }
   
  .page-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .page-subtitle {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  </style>