import store from "@/store";

export const compareVerson = (SSYW, flag) => {
  let oldVersion = localStorage.getItem("oldVersion");

  let newVersion = store.getters.versionNo.find(
    item => item && item.SSYW && item.SSYW === SSYW
  );
  if (oldVersion) {
    oldVersion = JSON.parse(oldVersion);
    let index = oldVersion.findIndex(
      item => item && item.SSYW && item.SSYW === SSYW
    );
    if (flag) {
      if (index === -1) {
        newVersion && oldVersion.push(newVersion);
        localStorage.setItem("oldVersion", JSON.stringify(oldVersion));
        return false;
      } else {
        if (
          oldVersion &&
          oldVersion[index] &&
          oldVersion[index].HCBM &&
          newVersion &&
          newVersion.HCBM &&
          oldVersion[index].HCBM === newVersion.HCBM
        )
          return true;
        else {
          newVersion && (oldVersion[index] = newVersion);
          localStorage.setItem("oldVersion", JSON.stringify(oldVersion));
          return false;
        }
      }
    } else {
      newVersion && oldVersion.push(newVersion);
      localStorage.setItem("oldVersion", JSON.stringify(oldVersion));
      return false;
    }
  } else {
    newVersion &&
      localStorage.setItem("oldVersion", JSON.stringify([].concat(newVersion)));
    return false;
  }
};
