<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'totalscore' + this.exportType">
					<img :src="xsinfo.ZP" :class="'studenticon' + this.exportType" />
					<div :class="'xsinfodiv' + this.exportType">
						<div :class="'xsinfotitle' + this.exportType">
							{{ xsinfo.XM }}
						</div>
					</div>
					<div :class="'scoredesc' + this.exportType"
						v-if="xsinfo.XQZP!=undefined&&xsinfo.XQZP!='undefined'&&xsinfo.XQZP!='null'">
						<el-row :gutter="10">
							<el-col :class="'scoretitle' + this.exportType">
								学期总评
							</el-col>
						</el-row>
						<el-row :gutter="10">
							<el-col :class="'scoredescstr' + this.exportType">
								{{ xsinfo.XQZP }}
							</el-col>
						</el-row>
					</div>
					<div class="clboth"></div>
				</div>
				<div :id="'myChart'+ info.YM" :class="'scorebar' + this.exportType" v-show="xszsdata.length>0"></div>
				<div :class="'detailinfo' + this.exportType">
					<div :class="'detailrow' + this.exportType" v-if="hdzsdata.length > 0">
						<el-row :gutter="10">
							<el-col :class="'detailstr' + this.exportType">
								<span style="color:#c88500;">●</span> {{ hdzsdata[0].name }}
							</el-col>
							<el-col :class="'detailscore' + this.exportType">
								{{ hdzsdata[0].dj }}
							</el-col>
						</el-row>
					</div>
					<div :class="'detailrow' + this.exportType" v-if="hdzsdata.length > 1">
						<el-row :gutter="10">
							<el-col :span="12" :class="'detailstr' + this.exportType">
								<span style="color: #ffaa00;">●</span> {{ hdzsdata[1].name }}
							</el-col>
							<el-col :span="12" :class="'detailscore' + this.exportType">
								{{ hdzsdata[1].dj }}
							</el-col>
						</el-row>
					</div>
					<div :class="'detailrow' + this.exportType" v-if="hdzsdata.length > 2">
						<el-row :gutter="10">
							<el-col :span="12" :class="'detailstr' + this.exportType">
								<span style="color: #c88500;">●</span> {{ hdzsdata[2].name }}
							</el-col>
							<el-col :span="12" :class="'detailscore' + this.exportType">
								{{ hdzsdata[2].dj }}
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				myChart: null,
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "实践创新素养"
				},
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					XQZP: "",
					SFZSEJYM: 1 //1环状图2柱状图3不展示
				},
				dimesion: null,
				xszsdata: [],
				hdzsdata: [],
				txdf: null,
				xswddata: null,
				xsfw: 1 //1班级 2年级 3班级+年级
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ? this.$route.query.exportType * 1 : 1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
			// this.loaddimesiondata();
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			drawBar(xarry, xsarr, bjarr, njarr) {
				if (this.myChart) {
					this.myChart.dispose();
				}
				this.myChart = this.$echarts.init(document.getElementById("myChart" + this.info.YM));
				let xdata = ['个人'];
				let serieslist = [{
					name: "个人",
					type: "bar",
					data: xsarr,
					itemStyle: {
						color: "#55007f"
					}
				}];
				if (this.xsfw == 1) {
					xdata.push('班级平均');
					serieslist.push({
						name: "班级平均",
						type: "bar",
						data: bjarr,
						itemStyle: {
							color: "#0000ff"
						}
					});
				} else if (this.xsfw == 2) {
					xdata.push('年级平均');
					serieslist.push({
						name: "年级平均",
						type: "bar",
						data: njarr,
						itemStyle: {
							color: "#2fc8ff"
						}
					});
				} else {
					xdata.push('班级平均');
					serieslist.push({
						name: "班级平均",
						type: "bar",
						data: bjarr,
						itemStyle: {
							color: "#0000ff"
						}
					});
					xdata.push('年级平均');
					serieslist.push({
						name: "年级平均",
						type: "bar",
						data: njarr,
						itemStyle: {
							color: "#2fc8ff"
						}
					});
				}
				this.myChart.setOption({
					tooltip: {},
					xAxis: {
						data: xarry
					},
					yAxis: {
						show: false
					},
					legend: {
						bottom: "10",
						data: xdata
					},
					series: serieslist
				});
			},
			drawPie(xspiedata) {
				if (this.myChart) {
					this.myChart.dispose();
				}
				this.myChart = this.$echarts.init(document.getElementById("myChart" + this.info.YM));
				this.myChart.setOption({
					tooltip: {
						show: false
					},
					legend: {
						orient: "vertical",
						left: "left"
					},
					series: [{
						type: "pie",
						radius: ["60%", "90%"],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 5,
							borderColor: "#fff",
							borderWidth: 1
						},
						label: {
							show: false,
							position: "center"
						},
						emphasis: {
							label: {
								show: true,
								fontWeight: "bold"
							}
						},
						labelLine: {
							show: false
						},
						data: xspiedata
					}]
				});
			},
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.xsxx.Id;
				// this.xsinfo.XM = xsdata.xsxx.XM;
				// this.xsinfo.XBM = xsdata.xsxx.XBM;
				// this.xsinfo.ZP = xsdata.xsxx.ZP;
				// this.xsinfo.XQAH = xsdata.xsxx.XQAH;
				// this.xsinfo.SSXX = xsdata.xsxx.SSXX;
				// this.xsinfo.SSXQ = xsdata.xsxx.SSXQ;
				// this.xsinfo.SSXN = xsdata.xsxx.SSXN;
				// this.xsinfo.XQLX = xsdata.xsxx.XQLX;
				// this.xsinfo.BZRMC = xsdata.xsxx.BZRMC;
				// this.xsinfo.NL = xsdata.xsxx.NL;
				// this.xsinfo.XXMC = xsdata.xsxx.XXMC;
				// this.xsinfo.XXTB = xsdata.xsxx.XXTB;
				// this.xsinfo.BJMC = xsdata.xsxx.BJMC;
				// this.xsinfo.XXMC = xsdata.xsxx.XXMC;
				// this.xsinfo.XXBM = xsdata.xsxx.XXBM;
				// this.xsinfo.XZ = xsdata.xsxx.XZ;
				if (!this.xsinfo.ZP) {
					this.xsinfo.ZP = this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				let templatedata = JSON.parse(xsdata.ZHSY);
				if (templatedata) {
					this.xsinfo.XQZP = templatedata.JG;
					// this.xsinfo.XQZP = 'A';
					// console.log(templatedata,'templatedata')
					let template = JSON.parse(localStorage.getItem("schooltemplate_" + this.xqid)).ZHSY;
					// console.log(template,'template')
					this.xsinfo.SFZSEJYM = 2; //固定为2，有且只有柱状图，移除值为1时环状图的情况
					let subdata_temp = templatedata.find(o => o.Id == 'W' + this.info.GLId);
					let template_temp = template.WDBG.find(o => o.Id == 'W' + this.info.GLId);
					// console.log(subdata_temp);
					// console.log(template_temp);
					// this.xsfw = 3;
					this.xszsdata = [];
					this.hdzsdata = [];
					if (template_temp && subdata_temp) {
						this.xsfw = template_temp.Type;
						if (subdata_temp) {
							let subdata = subdata_temp.WDBG;
							for (let i = 0; i < subdata.length; i++) {
								// console.log(subdata[i])
								let hdtype = 0;
								if (template.Sdtype == 2) {
									hdtype = 1;
								} else if (template.Sdtype == 3) {
									hdtype = 2;
								}

								let hd_temp = template_temp.Hd.find(o => o.Id == subdata[i].Id && o.Type == hdtype);
								if (hd_temp) {
									let obj = new Object();
									obj.name = hd_temp.Name;
									obj.dj = subdata[i].Value;
									obj.score = subdata[i].Score;
									obj.bjpj = subdata[i].CAVG;
									obj.njpj = subdata[i].GAVG;
									this.xszsdata.push(obj);
								}

								let hd_temp_data = template_temp.Hd.find(o => o.Id == subdata[i].Id && o.Type != 1);
								if (hd_temp_data) {
									let obj = new Object();
									obj.name = hd_temp_data.Name;
									obj.dj = subdata[i].Value;
									obj.score = subdata[i].Score;
									obj.bjpj = subdata[i].CAVG;
									obj.njpj = subdata[i].GAVG;
									this.hdzsdata.push(obj);
								}
							}
						}
						this.drawchart();

					}
				} // this.getxsreportdata();
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthreport/GetGrowthReport", {
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xsinfo.SSXQ,
						XQXN: this.xsinfo.SSXN,
						XQLX: this.xsinfo.XQLX,
						XXBM: this.xsinfo.XXBM,
						XSId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						this.xszsdata = [];
						if (r.Code == 0) {
							var info = r.Target;
							this.txdf = null;
							if (info != null && info != undefined) {
								if (
									info.SchoolStuReBasicSet != null &&
									info.SchoolStuReBasicSet != undefined
								) {
									this.xsinfo.SFZSEJYM = info.SchoolStuReBasicSet.SFZSEJYM;
								}
								if (
									info.StudentGrowthReport != null &&
									info.StudentGrowthReport != undefined &&
									info.StudentGrowthReport.TXDF != null &&
									info.StudentGrowthReport.TXDF != undefined
								) {
									this.txdf = JSON.parse(info.StudentGrowthReport.TXDF);
									if (this.dimesion != null) {
										this.xswddata = this.txdf.filter(
											c => c.MC == this.dimesion.WDMC
										)[0];
									}
									if (this.xswddata != null) {
										this.xsinfo.XQZP =
											this.xswddata.Grade != null && this.xswddata.Grade !=
											"" ?
											this.xswddata.Grade :
											this.xswddata.DF;
										let subdata = this.xswddata.SData;
										if (subdata) {
											for (var i = 0; i < subdata.length; i++) {
												subdata[i].Grade = subdata[i].Grade != null &&
													subdata[i].Grade !=
													"" ? subdata[i].Grade : subdata[i].Score;
												var obj = new Object();
												obj.name = subdata[i].IdOrMC;
												obj.dj = subdata[i].Grade;
												obj.score = subdata[i].Score;
												obj.bjpj = subdata[i].ClassAVG;
												this.xszsdata.push(obj);
											}
										}
									}
								}
								this.drawchart();
							}
						}
					}
				);
			},
			drawchart: function() {
				if (this.xsinfo.SFZSEJYM == 1) {
					let xspiedata = this.xszsdata.map(item => {
						return Object.assign({}, {
							name: item.name,
							value: item.score
						});
					});
					this.drawPie(xspiedata);
				} else {
					this.drawBar(
						this.xszsdata.map(item => {
							return item.name;
						}),
						this.xszsdata.map(item => {
							return item.score;
						}),
						this.xszsdata.map(item => {
							return item.bjpj;
						}),
						this.xszsdata.map(item => {
							return item.njpj;
						})
					);
				}
			},
			loaddimesiondata: function() {
				this.$api.post(
					"v1/growthbrochure/GetDimension", {
						Id: this.info.GLId,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							this.dimesion = r.Target;
						}
					}
				);
			}
		},
		mounted() {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #0063da;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #0063da;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 125px;
		height: 125px;
		text-align: center;
		float: left;
		margin-top: 40px;
		margin-left: 30px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 10px;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/dot_blue_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/dot_blue_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.totalscore1 {
		height: 200px;
		width: 756px;
		margin-top: 30px;
		margin-left: 40px;
		background: #e6f0fe;
		border-radius: 25px;
	}

	.totalscore2 {
		height: 120px;
		width: 530px;
		margin-top: 20px;
		margin-left: 30px;
		background: #e6f0fe;
		border-radius: 15px;
	}

	.scoretitle1 {
		margin-top: 25px;
		width: 125px;
		height: 22px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.scoretitle2 {
		margin-top: 15px;
		width: 80px;
		height: 18px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.scoredescstr1 {
		width: 125px;
		height: 34px;
		font-size: 34px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		vertical-align: middle;
		margin-top: 20px;
	}

	.scoredescstr2 {
		width: 80px;
		height: 30px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		vertical-align: middle;
		margin-top: 10px;
	}

	.scoredesc1 {
		width: 125px;
		height: 112px;
		text-align: center;
		float: right;
		vertical-align: middle;
		margin-top: 30px;
		margin-right: 40px;
	}

	.scoredesc2 {
		width: 80px;
		height: 75px;
		text-align: center;
		float: right;
		vertical-align: middle;
		margin-top: 20px;
		margin-right: 30px;
	}

	.xsinfodiv1 {
		margin-top: 90px;
		margin-left: 20px;
		display: table-row;
		float: left;
		width: 165px;
	}

	.xsinfodiv2 {
		margin-top: 50px;
		margin-left: 15px;
		display: table-row;
		float: left;
		width: 125px;
	}

	.xsinfotitle1 {
		width: 200px;
		height: 22px;
		font-size: 20px;
		font-weight: bold;
		color: #080808;
		font-family: Microsoft YaHei;
	}

	.xsinfotitle2 {
		width: 140px;
		height: 18px;
		font-size: 17px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
	}

	.scorebar1 {
		height: 400px;
		width: 756px;
		margin-top: 10px;
		margin-left: 40px;
		background: #e6f0fe;
		border-radius: 15px;
	}

	.scorebar2 {
		height: 300px;
		width: 530px;
		margin-top: 10px;
		margin-left: 30px;
		background: #e6f0fe;
		border-radius: 10px;
	}

	.detailinfo1 {
		width: 756px;
		height: 270px;
		margin-top: 10px;
		margin-left: 40px;
	}

	.detailinfo2 {
		width: 530px;
		height: 210px;
		margin-top: 10px;
		margin-left: 30px;
	}

	.detailrow1 {
		height: 100px;
		width: 756px;
		margin-top: 10px;
		background: #e6f0fe;
		border-radius: 15px;
	}

	.detailrow2 {
		height: 70px;
		width: 530px;
		margin-top: 10px;
		background: #e6f0fe;
		border-radius: 10px;
	}

	.detailstr1 {
		margin-left: 80px;
		width: 425px;
		text-align: left;
		line-height: 100px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.detailstr2 {
		margin-left: 40px;
		width: 280px;
		text-align: left;
		line-height: 70px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.detailscore1 {
		margin-left: 20px;
		width: 205px;
		text-align: center;
		line-height: 100px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.detailscore2 {
		margin-left: 40px;
		width: 120px;
		text-align: center;
		line-height: 70px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}
</style>