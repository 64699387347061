<template>
  <div class="cont-whole-box" style="text-align:center;">
    <el-form
      :model="hjglinfo"
      label-width="120px"
      style="width:500px;display:inline-block"
      :rules="rules"
      ref="sbmitfrom"
    >
      <el-form-item label="获奖班级：" prop="CYId">
        {{ hjglinfo.CYMC }}
        <el-button type="primary" @click="handleChange">更改</el-button>
      </el-form-item>
      <el-form-item label="获奖名称：" prop="HJMC">
        <el-input
          v-model="hjglinfo.HJMC"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="获奖类别：" prop="HJLB">
        <el-select
          style="width:100%;"
          v-model="hjglinfo.HJLB"
          placeholder="请选择获奖类别"
        >
          <el-option
            v-for="item in lblist"
            :key="item.Id"
            :label="item.LBMC"
            :value="item.Id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="获奖级别：" prop="HJJB">
        <el-select
          style="width:100%;"
          v-model="hjglinfo.HJJB"
          @change="handleHJJBChange()"
          placeholder="请选择获奖级别"
        >
          <el-option
            v-for="item in djlist"
            :key="item.Id"
            :label="item.HJDJMC"
            :value="item.Id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述：" prop="BZ">
        <el-input
          type="textarea"
          :autosize="{ minRows: 6 }"
          placeholder="请输入描述"
          v-model="hjglinfo.BZ"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="上传图片：">
        <el-upload
          class="upload-demo"
          list-type="picture-card"
          accept="image/jpeg, image/gif, image/png"
          :action="uploadURL"
          :on-error="upLoadError"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :limit="6"
          :on-exceed="handleExceed"
          :auto-upload="true"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <br />
      <el-form-item>
        <el-button type="primary" @click="submitshowdata" :disabled="isDisabled"
          >保存</el-button
        >
        <el-button @click="canceladd">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      title="选择班级"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleChangeClose"
    >
      <div class="multi-opt-box" style="margin-top: 0px;">
        <div class="multi-opt">
          <div class="multi-opt-left">年级</div>
          <div class="multi-opt-rig">
            <div
              v-for="nj in njlist"
              :key="nj.Id"
              class="d-word-widt"
              @click="njchange(nj.Id)"
            >
              <a
                v-if="njid == nj.Id"
                class="b-word-widta opt-rigsty whole-back"
                >{{ nj.Name }}</a
              >
              <a v-else class="b-word-widta whole-back-hover">{{ nj.Name }}</a>
            </div>
          </div>
        </div>
        <div class="multi-opt">
          <div class="multi-opt-left">班级</div>
          <div style="z-index:10;" class="multi-opt-rig">
            <div
              class="d-word-widt"
              v-for="bj in bjlist"
              :key="bj.Id"
              @click="bjchange(bj.Id, bj.Name, bj.Id, bj.TB)"
            >
              <a
                v-if="bjid == bj.Id"
                class="b-word-widta opt-rigsty whole-back"
                >{{ bj.Name }}</a
              >
              <a v-else class="b-word-widta whole-back-hover">{{ bj.Name }}</a>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    this.hjglinfo.Id = this.$route.params.hdid;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.hjglinfo.CJRLB = user.YHLX;
      this.token = user.Token;
      this.fileServerApiPath = user.FileServerApiPath;
      this.webFilePath = user.WebFilePath;
    }
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return "/file/file/fileservice/UploadFile?token=" + this.token;
    }
  },
  mounted: function() {
    this.loadlbdata();
    this.loaddjdata();
    this.loadnjdata();
  },
  methods: {
    handleRemove(fileList) {
      this.filearr = [];
      for (var i = 0; i < fileList.length; i++) {
        var response = fileList[i].response;
        this.filearr.push({
          FJMC: response.Target.FileName,
          FJLJ: response.Target.FilePath
        });
      }
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleAvatarSuccess(response) {
      if (response.Code == -3) {
        this.$message.warning(`附件上传失败`);
        return;
      } else {
        this.filearr.push({
          FJMC: response.Target.FileName,
          FJLJ: response.Target.FilePath
        });
      }
    },
    //文件上传失败时的钩子
    upLoadError() {
      this.$message.info(`附件上传失败`);
    },
    handleExceed() {
      this.$message.info(`最多只能上传6张图片`);
    },
    //取消
    canceladd: function() {
      this.$router.push({ path: "/classawardmanage/classawardlist" });
    },
    submitshowdata: function() {
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          var fjstr = "";
          if (
            this.filearr != undefined &&
            this.filearr != null &&
            this.filearr.length > 0
          ) {
            fjstr = JSON.stringify(this.filearr);
          }
          this.isDisabled = true;
          this.$api.post(
            "v1/awards/AddAward",
            {
              HJMC: this.hjglinfo.HJMC,
              HJJB: this.hjglinfo.HJJB,
              HJLB: this.hjglinfo.HJLB,
              BZ: this.hjglinfo.BZ,
              CYLB: 3,
              CYId: this.hjglinfo.CYId,
              CYMC: this.hjglinfo.CYMC,
              ZP: this.hjglinfo.ZP,
              BJId: this.hjglinfo.BJId,
              CJRLB: this.hjglinfo.CJRLB,
              DF: this.hjglinfo.DF,
              SFLRSDXS: 2,
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              CJR: this.cjr,
              FJStr: fjstr,
              token: this.token
            },
            r => {
              if (r.Code == 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!"
                });
                this.isDisabled = false;
                this.$router.push({ path: "/classawardmanage/classawardlist" });
              } else {
                this.$message({
                  type: "info",
                  message: "保存失败!"
                });
                this.isDisabled = false;
                return;
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    //确定单选项
    handleConfirm() {
      this.$refs.optiontextValidateForm.validate(valid => {
        if (valid) {
          this.srdxx.push(this.optiontextValidateForm.optiontext);
          this.optiontextValidateForm.optiontext = "";
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    //更改
    handleHJJBChange() {
      var jbinfo = this.djlist.filter(c => c.Id == this.hjglinfo.HJJB)[0];
      this.hjglinfo.DF = jbinfo.DF;
      this.hjglinfo.HJJBMC = jbinfo.HJDJMC;
    },
    //更改
    handleChange() {
      this.orgid = "";
      this.njid = "";
      this.bjid = "";
      this.pageIndex = 1;
      this.dialogVisible = true;
    },
    //关闭单选项
    handleChangeClose() {
      this.dialogVisible = false;
    },
    njchange: function(id) {
      this.orgid = "";
      this.njid = id;
      this.pageIndex = 1;
      this.loadbjdata();
    },
    bjchange: function(id, xm, bj, zp) {
      this.hjglinfo.CYId = id;
      this.hjglinfo.CYMC = xm;
      this.hjglinfo.ZP = zp;
      this.hjglinfo.BJId = bj;
      this.dialogVisible = false;
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolOrg",
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code == 0) {
            this.njlist = [];
            var list = r.Target;
            this.zzjglist = list;
            for (var i = 0; i < list.length; i++) {
              if (list[i].Hierarchy == 1) {
                this.njlist.push(list[i]);
              }
            }
          }
        }
      );
    },
    loadbjdata: function() {
      if (this.njid.length > 0) {
        this.bjlist = this.zzjglist.filter(
          c => c.Id == this.njid
        )[0].ChidOrgList;
      } else {
        this.bjlist = [];
      }
    },
    loadlbdata: function() {
      this.$api.post(
        "v1/awards/GetHJLBList",
        { SSXX: this.ssxx, HQLB: 3, token: this.token },
        r => {
          if (r.Code == 0) {
            this.lblist = r.Target;
          }
        }
      );
    },
    loaddjdata: function() {
      this.$api.post(
        "v1/awards/GetHJDJList",
        { SSXX: this.ssxx, HQLB: 3, token: this.token },
        r => {
          if (r.Code == 0) {
            this.djlist = r.Target;
          }
        }
      );
    }
  },
  data() {
    return {
      isDisabled: false,
      webFilePath: "",
      fileServerApiPath: "",
      dialogFormVisible: false,
      ssxx: "",
      ssxq: "",
      dialogVisible: false,
      token: "",
      lblist: [],
      djlist: [],
      njlist: [],
      xslist: [],
      bjid: "",
      njid: "",
      orgid: "",
      pageIndex: 1,
      pagesize: 30,
      rowcount: 0,
      bjlist: [],
      zzjglist: [],
      srfslx: [],
      srdxx: [],
      filearr: [],
      fileList: [],
      cjr: "",
      hjglinfo: {
        Id: 0,
        CJR: "",
        CJSJ: "",
        CYId: "",
        CYMC: "",
        ZP: "",
        HJMC: "",
        HJJB: "",
        HJJBMC: "",
        HJLB: "",
        BZ: "",
        BJId: "",
        CJRLB: 0,
        DF: 0,
        SFLRSDXS: 2
      },
      rules: {
        HJMC: [
          { required: true, message: "请输入获奖名称", trigger: "change" },
          { min: 1, max: 50, message: "长度在1到50个字符", trigger: "change" }
        ],
        CYId: [
          { required: true, message: "请选择获奖班级", trigger: "change" }
        ],
        BZ: [
          {
            min: 0,
            max: 500,
            message: "长度不能超过500个字符",
            trigger: "change"
          }
        ],
        HJLB: [
          { required: true, message: "请选择获奖类别", trigger: "change" }
        ],
        HJJB: [{ required: true, message: "请选择获奖级别", trigger: "change" }]
      }
    };
  }
};
</script>
