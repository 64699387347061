<template>
  <div class="cont-whole-box">
    <div class="cent-topbutt-box" style="padding-top:10px">
      <div class="top-butt-box">
        <!-- <div
          @click="switchTab(0)"
          :class="[
            classType === 0 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]"
        >
          小组与个人
        </div> -->
        <div @click="switchTab(1)" :class="[
            classType === 1 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]">
          个人分体系
        </div>
        <div @click="switchTab(2)" :class="[
            classType === 2 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]">
          个人特色体系
        </div>
        <div @click="switchTab(3)" :class="[
            classType === 3 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]">
          班级成长体系
        </div>
        <div @click="switchTab(4)" :class="[
            classType === 4 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]">
          个人综合分设置
        </div>
        <div @click="switchTab(5)" :class="[
            classType === 5 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]">
          班级综合分设置
        </div>
        <div @click="switchTab(6)" :class="[
            classType === 6 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]">
          背景图设置
        </div>
        <div @click="switchTab(7)" :class="[
            classType === 7 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]">
          个人成长树
        </div>
        <div @click="switchTab(8)" :class="[
            classType === 8 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]">
          班级树
        </div>
      </div>
    </div>
    <div style="padding: 0px 30px;">
      <div class="tab-heng-box" style="margin-top:10px">
        <router-view :activities="activities" :setList="setList" :backGroundImageData="backGroundImageData"
          :classScoreSet="classScoreSet" :personScoreSet="personScoreSet" @saveBackGroundImageData="save"
          @getGroupSet="getGroupSet"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    menuRoutes
  } from '@/router/menuroutes.js'
  export default {
    data() {
      return {
        classType: 1,
        routes: menuRoutes
          .find(c => c.groupcode === 'comprehensiveSet')
          .children.find(item => item.name === 'growthsystem'),
        activities: [],
        setList: [],
        backGroundImageData: require('@/style/images/jxhd_back.png'),
        classScoreSet: {},
        personScoreSet: {},
        user: {}
      }
    },
    methods: {
      init() {
        if (this.classType !== -1) {
          this.$router.push(
            this.routes.path + '/' + this.routes.children[this.classType].path
          )
        }
      },
      switchTab(classType) {
        this.classType = classType

        this.$router.push(
          this.routes.path + '/' + this.routes.children[this.classType].path
        )
      },
      getActivities() {
        this.$api.post(
          '/v1/growthsystem/GetPersonAndGroupSetBasicData', {
            token: this.$store.state.token,
            SchoolId: this.user.SSZZId
          },
          r => {
            if (r.Code === 0) {
              let result = [] //子活动
              if (r.Target && r.Target.length > 0) {
                r.Target.forEach(item => {
                  if (
                    item.SFYXZJHD === 1 &&
                    item.ChildEvaList &&
                    item.ChildEvaList.length > 0
                  ) {
                    result = [...result, ...item.ChildEvaList]
                  }
                })
              }
              r.Target = r.Target.filter(
                item => !result.some(it => it.Id === item.Id)
              )
              this.activities = r.Target
              // if (this.routes.name !== this.$route.name) {
              //   console.log(this.routes.children, this.$route.name, "sdfasfd");
              //   this.classType = this.routes.children.findIndex(
              //     c => c.name === this.$route.name
              //   );
              // }
            }
          }
        )
      },
      getGroupSet() {
        this.$api.post(
          '/v1/growthsystem/GetGroupSet', {
            token: this.$store.state.token,
            SchoolId: this.user.SSZZId
          },
          r => {
            if (r.Code === 0) {
              this.setList = r.Target
            }
          }
        )
      },
      getBackgroundImage() {
        this.$api.post(
          '/v1/growthsystem/GetBackgroundImage', {
            token: this.$store.state.token,
            SchoolId: this.user.SSZZId
          },
          r => {
            if (r.Code === 0) {
              this.backGroundImageData = r.Target
              if (this.backGroundImageData === null) {
                this.backGroundImageData = undefined
              }
            }
          }
        )
      },
      getClassScoreSet() {
        this.$api.post(
          '/v1/growthsystem/GetClassScoreSet', {
            token: this.$store.state.token,
            SchoolId: this.user.SSZZId
          },
          r => {
            if (r.Code === 0) {
              this.classScoreSet = r.Target
            }
          }
        )
      },
      getStudentScoreSet() {
        this.$api.post(
          '/v1/growthsystem/GetPersonScoreSet', {
            token: this.$store.state.token,
            SchoolId: this.user.SSZZId
          },
          r => {
            if (r.Code === 0) {
              this.personScoreSet = r.Target
            }
          }
        )
      },
      save(data) {
        this.backGroundImageData = data
      }
    },
    created: function() {
      this.user = JSON.parse(localStorage.getItem('userinfo'))
      this.init()
      this.getActivities()
      this.getGroupSet()
      this.getBackgroundImage()
      this.getClassScoreSet()
      this.getStudentScoreSet()
    }
  }
</script>