<template>
  <!-- 教师通用报表-项目 -->
  <div class="setXM-box">
    <div class="top-btns">
      <el-button size="mini" type="success" @click="edit_XM(0)">新增</el-button>
      <el-button
        size="mini"
        type="success"
        @click="$router.push({ name: 'zhbbmbglindex' })"
        >返回</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="PXH" label="排序号"> </el-table-column>
      <el-table-column prop="MC" label="项目名称"> </el-table-column>
      <el-table-column prop="BL" label="占比"> </el-table-column>
      <el-table-column label="操作" width="330">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="edit_XM(scope.row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="del_XM(scope.row)"
            >删除</el-button
          >
          <el-button size="mini" type="success" @click="to_HD(scope.row)"
            >关联活动</el-button
          >
          <el-button size="mini" type="primary" @click="to_ZXM(scope.row)"
            >添加子项</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <XMDialog
      :show="XM_Dialog.show"
      :title="XM_Dialog.title"
      :form="XM_Form"
      @XM_Submit="XM_Submit"
    />
    <HDDialog :show="HD_Dialog.show" @HD_Submit="HD_Submit" />
  </div>
</template>

<script>
import XMDialog from "./setXM.vue";
import HDDialog from "./setHD.vue";

export default {
  name: "teachersetXM",
  components: {
    XMDialog,
    HDDialog,
  },
  data() {
    return {
      userInfo: {},
      MBInfo: {},
      BBInfo: {},
      baseTableData: [],
      tableData: [],
      XM_GLHD_Base: [],
      XM_Dialog: {
        title: "新增项目",
        show: false,
      },
      XM_Form: {
        PXH: 0,
        XMMC: "",
        ZB: 0,
        HD: [],
        ZXM: [],
      },

      ZXM_Dialog: {
        title: "新增项目",
        show: false,
      },
      ZXM_Form: {
        PXH: 0,
        XMMC: "",
        ZB: 0,
        HD: [],
        ZXM: [],
      },
      ZXM_Rules: {
        XMMC: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      HD_Dialog: {
        show: false,
      },
      baseActivityList: [], //原始自定义活动列表
      activityList: [],
      baseFixList: [], //原始固定活动列表
      fixList: [],
    };
  },
  async created() {
    this.MBInfo = JSON.parse(localStorage.getItem("MBInfo"));
    this.BBInfo =
      this.$route.query.data || JSON.parse(localStorage.getItem("BBInfo"));
    this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
    await this.getXMGLHD();
    this.getXM_List();
  },
  methods: {
    getXM_List() {
      this.$api.post(
        "/v1/mbbb/GetGLHDBList",
        {
          MBGLID: this.BBInfo.Id,
          IsGetHDList: 1, //1.主项目 2.子项目 3.活动
          LX: 3,
          token: this.userInfo.Token,
        },
        (res) => {
          if (res.Code === 0) {
            let arr = res.Target || [];
            localStorage.setItem("TYJSBB_Data", JSON.stringify(arr));
            this.baseTableData = arr;
            let arr2 = arr.filter(
              (item) =>
                item.LX === 3 &&
                item.FID === "0" &&
                item.MBGLID === this.BBInfo.Id
            );
            arr2.sort((a, b) => a.PXH - b.PXH);
            console.log(arr2, "获取到的主项目");
            this.tableData = arr2;
          }
        }
      );
    },
    // 编辑项目
    edit_XM(XM) {
      if (XM) {
        this.XM_Form = {
          ...XM,
          PXH: XM.PXH,
          XMMC: XM.MC,
          ZB: XM.BL,
        };
      }
      this.XM_Dialog.title = XM ? "编辑" : "新增";
      this.XM_Dialog.show = true;
    },
    // 删除项目
    del_XM(XM) {
      this.$confirm("确认删除？")
        .then(() => {
          this.$api.post(
            "/v1/mbbb/DeleteGLHDB",
            {
              Id: XM.Id,
              token: this.userInfo.Token,
            },
            (res) => {
              if (res.Code === 0) {
                this.$message.success("删除成功");
                this.getXM_List();
              }
            }
          );
        })
        .catch(() => {});
    },
    // 保存项目
    XM_Submit(val) {
      if (!val) {
        this.XM_Dialog.show = false;
        return;
      }
      let type = 0;
      let url = "/v1/mbbb/AddGLHDB";
      if (this.XM_Dialog.title === "编辑") {
        type = 1;
        url = "/v1/mbbb/UpdateGLHDB";
      }

      let obj = {
        Id: this.XM_Form.Id || "",
        MBGLID: this.BBInfo.Id,
        MC: val.XMMC,
        IsSelect: 2, //阶段平均 1.勾选 2.不勾选（默认）
        GLHD: null, //新增是不传
        HDXS: null, //因为是项目 不传
        BL: val.ZB,
        LX: 3, //教师通用报表设置项目里面 所有的LX都是3
        HDLX: null, //4.过程 5.阶段 6.道德报表 7.教师通用报表
        FID: "0", //
        PXH: val.PXH,
        CJR: !type ? this.userInfo.Id : null,
        SSXX: this.userInfo.SSZZId,
      };

      this.$api.post(
        url,
        {
          GLHDBInfo: [obj],
          MBGLID: !type ? this.BBInfo.Id : null,
          MBLX: !type ? this.MBInfo.MBDM : null,
          token: this.userInfo.Token,
        },
        (res) => {
          if (res.Code === 0) {
            this.getXM_List();
            this.XM_Dialog.show = false;
          }
        }
      );
    },
    // 添加活动
    to_HD(row) {
      localStorage.setItem("XMInfo", JSON.stringify(row));
      localStorage.setItem("XM_Type", JSON.stringify(1));
      this.$router.push({
        name: "teachersetHD",
      });
    },
    HD_Submit(val) {
      console.log(val, "val");
      if (!val) {
        this.HD_Dialog.show = false;
        return;
      }
    },
    // 新增子项目
    to_ZXM(row) {
      localStorage.setItem("XMInfo", JSON.stringify(row));
      this.$router.push({
        name: "teachersetZXM",
        query: { XMInfo: row, BBInfo: this.BBInfo, MBInfo: this.MBInfo },
      });
    },
    // 获取项目关联活动详情
    getXMGLHD() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetSchoolEvaByBPJDXLX",
          {
            BPJDXLX: 2,
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.XM_GLHD_Base = res.Target || [];
              this.XM_GLHD_Base.sort((a, b) =>
                a.PJHDMC.localeCompare(b.PJHDMC)
              );
              localStorage.setItem(
                "XM_GLHD_Base",
                JSON.stringify(this.XM_GLHD_Base)
              );
              resolve();
            }
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.setXM-box {
  .XM_Dialog {
    .XM_Form {
    }
    .ZXM_Box {
      padding: 10px;
      .ZXM_Item {
        padding-top: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
  ::v-deep .HD_Dialog {
    max-height: 600px;
    overflow-y: scroll;
    .activity-list {
      .act-item {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 1px 1px 2px 2px #dfdbdb;
        .normal,
        .question {
          display: flex;
          align-items: center;
          .act-name {
            width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .question-list {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-left: 10px;
            margin-right: 10px;
            .question-item {
              display: flex;
              align-items: center;
              margin-right: 10px;
              margin-bottom: 10px;
              .obj-list {
                display: flex;
                align-items: center;
                .obj-item {
                  margin-right: 10px;
                }
              }
            }
          }
        }
        .normal {
          .obj-list {
            display: flex;
            align-items: center;
            padding-left: 10px;
            margin-right: 10px;
            .obj-item {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
