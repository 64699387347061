<template>
  <div class="cont-whole-box">
    <div class="cent-topbutt-box cz-top-bacbox">
      <div class="top-butt-box">
        <div
          @click="switchTab(0)"
          :class="[
            menuTab === 0 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]"
        >
          通用设置
        </div>
        <div
          @click="switchTab(1)"
          :class="[
            menuTab === 1 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]"
        >
          <div
            @click="switchTab(1)"
            :class="[currentType === 1 ? 'tablil-xz-sty' : '', 'tablil-xz']"
          ></div>
          使用体系生成报告
        </div>
        <div
          @click="switchTab(2)"
          :class="[
            menuTab === 2 ? 'cent-butt-sty whole-col' : '',
            'cent-butt whole-col-hov'
          ]"
        >
          <div
            @click="switchTab(2)"
            :class="[currentType === 2 ? 'tablil-xz-sty' : '', 'tablil-xz']"
          ></div>
          使用纬度生成报告
        </div>
      </div>
    </div>
    <div style="padding: 30px; box-sizing: border-box;">
      <div class="sm-conten-tit">{{ title }}</div>

      <!-- 通用1页 -->
      <general1
        v-if="menuTab === 0 && enableGeneral1"
        :setInfo="setInfo"
        :basicInfo="basicInfo"
        @toGeneral2="toGeneral2"
      />
      <!-- 通用2页 -->
      <general2
        v-if="menuTab === 0 && enableGeneral2"
        :setInfo="setInfo"
        :basicInfo="basicInfo"
        @toGeneral1="toGeneral1"
      />

      <!-- 体系生成 -->
      <systemSet
        v-if="menuTab === 1 && enableSystem"
        :setInfo="setInfo"
        :basicInfo="basicInfo"
        :SZLB="currentType"
        @toGeneral1="toGeneral1"
      />

      <!-- 维度生成 -->
      <dimensionSet
        v-if="menuTab === 2 && enableDimension"
        :setInfo="setInfo"
        :basicInfo="basicInfo"
        :SZLB="currentType"
        @toGeneral1="toGeneral1"
      />
    </div>
  </div>
</template>

<script>
import general1 from "./general/Set1";
import general2 from "./general/Set2";
import systemSet from "./system/Index";
import dimensionSet from "./dimension/Index";
export default {
  components: {
    general1,
    general2,
    systemSet,
    dimensionSet
  },
  data() {
    return {
      currentType: 0,
      setInfo: {},
      basicInfo: {},
      menuTab: 0,
      enableGeneral1: false,
      enableGeneral2: false,
      enableSystem: false,
      enableDimension: false,
      title: "通用设置",
      token: "",
      user: {},
      schoolId: ""
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.init();
  },
  methods: {
    init() {
      this.getBasicData();
      this.getSetData();
    },
    switchTab(menuTab) {
      const isClear = !(this.setInfo.SZLB === menuTab) && menuTab !== 0;
      this.menuTab = menuTab;
      if (menuTab === 1) {
        this.currentType = menuTab;
        this.enableSystem = true;
        this.enableDimension = false;
        this.enableGeneral1 = false;
        this.enableGeneral2 = false;
        // 按体系
        this.title = "使用体系生成报告";
      } else if (menuTab === 2) {
        this.currentType = menuTab;
        this.enableSystem = false;
        this.enableDimension = true;
        this.enableGeneral1 = false;
        this.enableGeneral2 = false;
        // 按维度
        this.title = "使用纬度生成报告";
      } else {
        // 通用设置
        this.enableGeneral1 = true;
        this.enableGeneral2 = false;
        this.title = "通用设置";
      }
      if (isClear) {
        // this.setInfo.XSMC = "";
        // this.setInfo.TXYS = 0;
        // this.setInfo.XZFW = 1;
        // this.setInfo.SFZSEJYM = 3;
        // this.setInfo.XSFW = 1;
        // this.setInfo.GLId = "";
        // this.setInfo.KSId = "";
        // this.setInfo.SystemDisplaySetList = [];
        // this.setInfo.ExamScoreProportionList = [];
        // this.setInfo.AssociationSetList = [];
      }
    },
    toGeneral1(data, menuTab = undefined) {
      console.log(data, menuTab);
      if (menuTab !== undefined) {
        this.menuTab = menuTab;
      }

      this.setInfo = data;

      this.currentType = this.setInfo.SZLB;
      this.enableGeneral1 = true;
      this.enableGeneral2 = false;
      this.enableSystem = false;
      this.enableDimension = false;
    },
    toGeneral2(data) {
      if (this.setInfo.SZLB === this.currentType) {
        this.setInfo = data;
      }
      this.enableGeneral1 = false;
      this.enableGeneral2 = true;
      this.enableSystem = false;
      this.enableDimension = false;
    },
    getBasicData() {
      this.$api.post(
        "/v1/growthreport/GetBasicData",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.basicInfo = r.Target;
          }
        }
      );
    },
    getSetData() {
      this.$api.post(
        "/v1/growthreport/GetGrowthReportSet",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.setInfo = r.Target;
            this.currentType = this.setInfo ? this.setInfo.SZLB : 0;
            this.setInfo.EndComment.CommentList =
              this.setInfo.EndComment.CommentList.length > 0
                ? this.setInfo.EndComment.CommentList
                : [{}, {}, {}, {}, {}];
            this.enableGeneral1 = true;
          }
        }
      );
    }
  }
};
</script>
