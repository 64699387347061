<template>
  <el-form style="width: 500px; display: inline-block;" label-width="120px">
    <el-form-item label="录入人员：">
      <el-checkbox-group v-model="checkedrecordroles">
        <el-checkbox
          :label="2"
          @change="checked => recordonchange(checked, '2', '')"
          >学生班主任</el-checkbox
        >
        <br />
        <el-checkbox
          v-for="role in rolelist"
          :key="role.Id"
          :label="role.Id"
          @change="checked => recordonchange(checked, '3', role.Id)"
          >{{ role.JSMC }}</el-checkbox
        >
      </el-checkbox-group>
    </el-form-item>
    <el-divider></el-divider>
    <el-form-item label="是否需要审核：">
      <el-radio-group v-model="needverify">
        <el-radio
          :label="1"
          @change="checked => verifyonchange(true, '1', '1', '')"
          >无需审核</el-radio
        >
        <br />
        <el-radio :label="3">要审核</el-radio>
        <el-form-item label="选择审核人：" v-if="needverify == '3'">
          <el-checkbox-group v-model="bothcheckedroles">
            <el-checkbox
              v-for="role in rolelist"
              :key="role.Id"
              :label="role.Id"
              @change="checked => verifyonchange(checked, '3', '3', role.Id)"
              >{{ role.JSMC }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "ClassSet",
  data() {
    return {
      ssxx: "",
      cjr: "",
      rolelist: [],
      checkedrecordroles: [],
      checkedverifyroles: [],
      needverify: 0,
      bothcheckedroles: []
    };
  },
  created: function() {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.ssxx = userinfo.SSZZId;
    this.cjr = userinfo.Id;
    this.loadroles();
    this.loadrecorders();
    this.loadverifiers();
  },
  mounted: function() {},
  methods: {
    loadroles: function() {
      this.$api.post(
        "v1/schoolprizeset/GetRoleList",
        { token: this.$store.state.token, SchoolId: this.ssxx },
        r => {
          this.rolelist = [];
          var list = r.Target;
          if (list !== null && list !== undefined && list.length > 0) {
            for (var i = 0; i < list.length; i++) {
              this.rolelist.push({
                Id: list[i].Id,
                JSMC: list[i].JSMC
              });
            }
          }
        }
      );
    },
    loadrecorders: function() {
      this.$api.post(
        "v1/schoolprizeset/GetClassRecordType",
        { SSXX: this.ssxx, token: this.$store.state.token },
        r => {
          this.checkedrecordroles = [];
          if (r.Code === 0) {
            var list = r.Target;
            if (list !== null && list !== undefined && list.length > 0) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].LRRYLX !== 3) {
                  this.checkedrecordroles.push(list[i].LRRYLX);
                } else {
                  this.checkedrecordroles.push(list[i].LRRYGL);
                }
              }
            }
          }
        }
      );
    },
    loadverifiers: function() {
      this.$api.post(
        "v1/schoolprizeset/GetClassVerifyType",
        { SSXX: this.ssxx, token: this.$store.state.token },
        r => {
          this.needverify = 0;
          this.checkedverifyroles = [];
          this.bothcheckedroles = [];
          if (r.Code === 0) {
            var list = r.Target;
            if (list !== null && list !== undefined && list.length > 0) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].SHLX === 1) {
                  this.needverify = 1;
                  return;
                } else if (list[i].SHLX === 3) {
                  this.needverify = 3;
                  this.bothcheckedroles.push(list[i].GLJS);
                }
              }
            }
          }
        }
      );
    },
    recordonchange: function(ischecked, lx, id) {
      if (ischecked) {
        this.$api.post(
          "v1/schoolprizeset/AddClassRecordType",
          {
            CJR: this.cjr,
            SSXX: this.ssxx,
            LRRYLX: lx,
            LRRYGL: id,
            token: this.$store.state.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
          }
        );
      } else {
        this.$api.post(
          "v1/schoolprizeset/DeleteClassRecordType",
          {
            SSXX: this.ssxx,
            LRRYLX: lx,
            YHId: this.cjr,
            LRRYGL: id,
            token: this.$store.state.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
          }
        );
      }
    },
    verifyonchange: function(ischecked, shlx, glshlx, id) {
      if (ischecked) {
        this.$api.post(
          "v1/schoolprizeset/AddClassVerifyType",
          {
            CJR: this.cjr,
            SSXX: this.ssxx,
            SHLX: shlx,
            GLSHLX: glshlx,
            GLJS: id,
            token: this.$store.state.token
          },
          r => {
            if (r.Code === 0) {
              if (shlx === 1) {
                this.bothcheckedroles = [];
              }
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
          }
        );
      } else {
        this.$api.post(
          "v1/schoolprizeset/DeleteClassVerifyType",
          {
            SSXX: this.ssxx,
            SHLX: shlx,
            GLSHLX: glshlx,
            YHId: this.cjr,
            GLJS: id,
            token: this.$store.state.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
          }
        );
      }
    }
  }
};
</script>
