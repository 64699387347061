<template>
  <el-dialog
    title="新增管理员"
    :visible.sync="visible"
    width="70%"
    :before-close="closeForm"
    append-to-body
  >
    <!-- 搜索 -->
    <div class="search-box">
      <el-input v-model="searchStr"></el-input>
      <el-button @click="handleSearch">搜索</el-button>
    </div>
    <!-- 多选框区域 -->
    <div class="checked-box">
      <el-checkbox-group v-model="checkedArr">
        <el-checkbox
          v-for="item in searchTeacherList"
          :key="item.Id"
          :label="item"
          >{{ item.Name }}</el-checkbox
        >
      </el-checkbox-group>
    </div>

    <!-- 参与类型勾选区域 -->
    <div class="type-option">
      <div class="option-item" v-for="item in checkedArr" :key="item.Id">
        <div class="name">
          {{ item.Name }}
        </div>
        <el-select v-model="item.CYRYLX" placeholder="请选择">
          <el-option
            v-for="item in typeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          type="danger"
          size="mini"
          icon="el-icon-delete"
          circle
          @click="del(item)"
        ></el-button>
      </div>
    </div>
    <div class="footer">
      <el-button size="mini" @click="closeForm">取消</el-button>
      <el-button size="mini" type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      searchStr: "",
      checkedArr: [],
      teacherList: [],
      searchTeacherList: []
    };
  },
  props: {
    visible: Boolean,
    typeOption: Array,
    checkedPerson: Array
  },
  inject: ["payLoad"],
  mounted() {
    this.getTearcher();
  },
  methods: {
    closeForm() {
      this.checkedArr = [];
      this.$emit("closeFormDialog");
    },
    handleSearch() {
      if (this.searchStr) {
        this.searchTeacherList = this.teacherList.filter(item =>
          item.Name.includes(this.searchStr)
        );
      } else {
        this.searchTeacherList = this.teacherList;
      }
    },
    getTearcher() {
      let userinfo = JSON.parse(localStorage.getItem("userinfo"));
      this.$api.post(
        "/v1/teacher/GetSchoolTeacherByAll",
        { SchoolId: userinfo.SSZZId, token: userinfo.Token },
        r => {
          if (r.Code === 0) {
            this.teacherList = r.Target.map(item => {
              return {
                ...item,
                CYRYLX: 1
              };
            });
            let arr = [];
            this.checkedPerson.forEach(it => {
              this.teacherList.forEach(teacher => {
                if (teacher.Id === it.CYRY) arr.push(teacher);
              });
            });
            this.checkedArr = arr;
            this.searchTeacherList = this.teacherList;
          }
        }
      );
    },
    del(data) {
      this.checkedArr = this.checkedArr.filter(item => item.Id !== data.Id);
    },
    submit() {
      let addDatas = this.checkedArr.map(item => {
        return {
          HDId: this.payLoad.currentActivity.Id,
          CYRY: item.Id,
          CYRYLX: item.CYRYLX
        };
      });
      let { Id: UserId, Token: token } = JSON.parse(
        localStorage.getItem("userinfo")
      );

      this.$api.post(
        "v1/evamember/AddHDCYRYB",
        {
          addDatas,
          UserId,
          token
        },
        res => {
          if (res.Code === 0) {
            this.$message({
              message: "新增成功",
              type: "success"
            });
            this.$emit("closeFormDialog", 1);
          }
        }
      );
    }
  },
  watch: {
    visible(val) {
      if (val) this.getTearcher();
    }
  }
};
</script>

<style lang="scss" scoped>
.search-box {
  .el-input {
    width: 200px;
  }
  .el-button {
    border-left: none;
  }
}
.checked-box {
  padding: 10px 5px;
  min-height: 100px;
  margin-top: 10px;
  border: 1px solid #ccc;
}
.type-option {
  padding: 10px;
  height: 200px;
  border: 1px solid #ccc;
  margin: 10px 0;
  overflow-y: scroll;
  .option-item {
    display: inline-block;
    margin: 0 10px 10px 0;
    width: 212px;
    .name {
      display: inline-block;
      width: 70px;
    }
    .el-select {
      width: 110px;
    }
  }
}
.footer {
  display: flex;
  justify-content: end;
}
</style>
