<template>
  <div>
    <el-dialog
      :title="onlyMember ? '查看成员' : '管理成员'"
      :top="onlyMember ? '8%' : '1%'"
      :visible.sync="visible"
      width="83%"
      center
      :before-close="closeDialog"
      class="jf-form"
      :close-on-click-modal="false"
    >
      <div class="cont-whole-box">
        <div style="padding: 30px 30px 10px 30px;">
          <div class="stud-box">
            <div class="sm-conten-tit">
              已有成员
              <span class="sm-tit-span"
                >（共计{{ pendingCancelMembers.length }}人）</span
              >
            </div>
            <ul class="stud-box-ul" v-loading="loadingCancelMembers">
              <div
                v-if="!onlyMember"
                class="operate-box paddno"
                style="margin: 10px 0px;"
              >
                <div class="table-er-butt" @click="calcelMemberAll">
                  <img
                    class="table-er-buttimg"
                    src="../../../style/images/icon_selectall.png"
                  />
                  <span class="table-er-buttspan">全选</span>
                </div>
                <div
                  class="table-er-butt float-rig"
                  @click="submitCancelMember"
                >
                  <span class="table-er-buttspan">确认删除</span>
                </div>
              </div>
              <div class="stud-ove-box">
                <li
                  :class="[
                    'stud-box-ulli',
                    {
                      'stud-ul-delesty': !onlyMember
                    },
                    {
                      'stud-ul-delesty-sty':
                        cancelMembers.findIndex(
                          c => c.MemberId === item.MemberId
                        ) !== -1
                    }
                  ]"
                  v-for="item in pendingCancelMembers"
                  :key="item.Id"
                  @click="dropMember(item)"
                >
                  <img
                    class="stud-box-ulli-img"
                    :src="
                      item.Photo
                        ? item.Photo
                        : item.Gender === 2
                        ? $store.state.defaultStudentGirl
                        : $store.state.defaultStudentBoy
                    "
                  />
                  <div class="stud-name">{{ item.MemberName }}</div>
                </li>
              </div>
            </ul>
          </div>
          <div class="stud-box" v-if="!onlyMember || curriculaData.KZSZ === 2">
            <div style="margin-top: 40px; border-bottom: 1px solid #e5e5e5;">
              <div class="sm-conten-tit">
                {{
                  curriculaData.KZSZ === 2
                    ? "选择班级"
                    : "从下面的学生中进行添加"
                }}
              </div>
            </div>
            <!--内容操作区开始-->
            <div class="multi-opt-box margno-t borderno-l">
              <div class="multi-opt">
                <div class="multi-opt-left">年级</div>
                <div class="multi-opt-rig">
                  <!--内容：2个字时“b-word-widt”，3个字时“c-word-widt”,4.5个字时“ef-word-widt”-->
                  <div
                    :class="[
                      { 'b-word-widt': item.Name.length <= 2 },
                      {
                        'c-word-widt':
                          item.Name.length > 2 && item.Name.length <= 3
                      },
                      {
                        'ef-word-widt':
                          item.Name.length > 3 && item.Name.length <= 5
                      },
                      { 'max-word-widt': item.Name.length > 5 }
                    ]"
                    v-for="item in currentOrgList"
                    :key="item.Id"
                    @click="clickGradeHandle(item.Id)"
                  >
                    <a
                      :class="[
                        { 'opt-rigsty whole-back': item.Id === gradeId },
                        'whole-back-hover b-word-widta'
                      ]"
                      >{{ item.Name }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="multi-opt" v-if="gradeId">
                <div class="multi-opt-left">班级</div>
                <div class="multi-opt-rig">
                  <div
                    :class="[
                      { 'b-word-widt': item.Name.length <= 2 },
                      {
                        'c-word-widt':
                          item.Name.length > 2 && item.Name.length <= 3
                      },
                      {
                        'ef-word-widt':
                          item.Name.length > 3 && item.Name.length <= 5
                      },
                      { 'max-word-widt': item.Name.length > 5 }
                    ]"
                    v-for="item in currentOrgList.find(c => c.Id === gradeId)
                      ? currentOrgList.find(c => c.Id === gradeId).ChidOrgList
                      : []"
                    :key="item.Id"
                    @click="clickClassHandle(item.Id)"
                  >
                    <a
                      :class="[
                        { 'opt-rigsty whole-back': item.Id === classId },
                        'whole-back-hover b-word-widta'
                      ]"
                      >{{ item.Name }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!--内容操作区结束-->
            <ul
              v-if="!onlyMember"
              class="stud-box-ul"
              style="line-height: 12px;"
              v-loading="loadingAddMembers"
            >
              <div class="operate-box paddno" style="margin: 10px 0px;">
                <div class="table-er-butt" @click="addMemberAll">
                  <img
                    class="table-er-buttimg"
                    src="../../../style/images/icon_selectall.png"
                  />
                  <span class="table-er-buttspan">全选</span>
                </div>
                <div class="table-er-butt float-rig" @click="submitAddMember">
                  <span class="table-er-buttspan">确认添加</span>
                </div>
              </div>
              <el-tooltip
                v-for="item in pendingAddMembers"
                :key="item.Id"
                :disabled="item.SigUpState === 1"
                placement="top"
              >
                <div slot="content">
                  {{
                    [
                      { key: 2, label: "可报名活动的类别已满" },
                      { key: 3, label: "学生可报名总数已满" },
                      { key: 4, label: "该活动可报名人数已满" },
                      { key: 5, label: "该年级可报名人数已满" },
                      { key: 11, label: "该活动已结束报名" },
                      { key: 12, label: "已报名了该活动" }
                    ]
                      .filter(c => c.key === item.SigUpState)
                      .map(c => c.label)
                      .join()
                  }}
                </div>
                <li
                  :class="[
                    'stud-box-ulli',
                    {
                      'stud-ul-sty':
                        addMembers.findIndex(
                          c => c.MemberId === item.MemberId
                        ) !== -1
                    },
                    {
                      'jf-disable': item.SigUpState !== 1
                    }
                  ]"
                  @click="
                    addMember(
                      item.SigUpState,
                      item.MemberId,
                      item.StudentCode,
                      item.GradeId
                    )
                  "
                >
                  <img
                    class="stud-box-ulli-img"
                    :src="
                      item.Photo
                        ? item.Photo
                        : item.Gender === 2
                        ? $store.state.defaultStudentGirl
                        : $store.state.defaultStudentBoy
                    "
                  />
                  <div class="stud-name">{{ item.MemberName }}</div>
                </li>
              </el-tooltip>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classId: "",
      gradeId: "",
      loadingCancelMembers: false,
      loadingAddMembers: false,
      pendingCancelMembers: [], // 可撤销成员
      pendingAddMembers: [], // 可添加成员
      cancelMembers: [], // 待撤销成员
      addMembers: [], // 待添加成员
      currentOrgList: [],
      classIds: []
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onlyMember: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    },
    orgList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    curriculaId: {
      type: String,
      default: ""
    },
    categoryId: {
      type: String,
      default: ""
    },
    endDate: {
      type: String,
      default: ""
    },
    joinGradeStr: {
      type: String,
      default: ""
    },
    termId: {
      type: String,
      default: ""
    },
    curriculaData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    closeDialog() {
      if (this.onlyMember) {
        this.$emit("closeMemberManagerDialog");
      } else {
        this.$confirm("确认关闭？").then(() => {
          this.$emit("closeMemberManagerDialog");
        });
      }
    },
    // 获取可撤销的成员
    getCancelAddMemberList() {
      this.loadingCancelMembers = true;
      if (this.curriculaData.KZSZ === 2) {
        this.$api.post(
          "/v1/accountmanage/GetOrgStudent",
          {
            token: this.$store.state.token,
            SchoolId: this.user.SSZZId,
            OrgType: 2,
            SchoolOrgIds: this.classIds
          },
          r => {
            if (r.Code === 0) {
              let data = r.Target;

              this.pendingCancelMembers = data.map(c => ({
                ClassId: c.ClassId,
                Id: c.Id,
                MemberId: c.Id,
                MemberName: c.Name,
                JoinStatu: 1,
                Photo: c.ZP,
                StudentCode: c.XH
              }));
            }
            this.loadingCancelMembers = false;
          }
        );
      } else {
        this.$api.post(
          "/v1/curriculavariable/GetCanCelAddMemberList",
          {
            token: this.$store.state.token,
            SchoolId: this.user.SSZZId,
            TermId: this.termId,
            CurriculaId: this.curriculaId
          },
          r => {
            if (r.Code === 0) {
              this.pendingCancelMembers = r.Target;
            }
            this.loadingCancelMembers = false;
          }
        );
      }
    },
    // 获取可添加的成员
    getAllowAddMemberList() {
      this.loadingAddMembers = true;
      this.$api.post(
        "/v1/curriculavariable/GetAllowAddMemberList",
        {
          token: this.$store.state.token,
          SchoolId: this.user.SSZZId,
          TermId: this.termId,
          ClassId: this.classId,
          CurriculaId: this.curriculaId,
          CategoryId: this.categoryId,
          EndDate: this.endDate,
          Source: 1,
          GradeId: this.gradeId
        },
        r => {
          if (r.Code === 0) {
            this.pendingAddMembers = r.Target;
          }
          this.loadingAddMembers = false;
        }
      );
    },
    // 提交添加成员
    submitAddMember() {
      this.$api.post(
        "/v1/curriculavariable/AddMember",
        {
          token: this.$store.state.token,
          SchoolId: this.user.SSZZId,
          TermId: this.termId,
          ClassId: this.classId,
          CurriculaId: this.curriculaId,
          CategoryId: this.categoryId,
          TeacherId: this.user.Id,
          MemberList: this.addMembers
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            let newData = JSON.parse(JSON.stringify(this.curriculaData));
            newData.CurrentCount += this.addMembers.length;
            newData.CurrentJoinCount += this.addMembers.length;
            newData.CurrentCount =
              newData.CurrentCount > newData.ToTotalLimit
                ? newData.ToTotalLimit
                : newData.CurrentCount;
            newData.CurrentJoinCount =
              newData.CurrentJoinCount > newData.ToTotalLimit
                ? newData.ToTotalLimit
                : newData.CurrentJoinCount;
            this.addMembers = [];
            this.cancelMembers = [];
            this.getCancelAddMemberList();
            this.getAllowAddMemberList();
            this.$emit("updateTableData", newData);
          }
        }
      );
    },
    submitCancelMember() {
      this.$api.post(
        "/v1/curriculavariable/CanCelAddMember",
        {
          token: this.$store.state.token,
          SchoolId: this.user.SSZZId,
          TermId: this.termId,
          ClassId: this.classId,
          CurriculaId: this.curriculaId,
          CategoryId: this.categoryId,
          TeacherId: this.user.Id,
          MemberList: this.cancelMembers
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            let newData = JSON.parse(JSON.stringify(this.curriculaData));
            newData.CurrentCount -= this.cancelMembers.length;
            newData.CurrentJoinCount -= this.cancelMembers.length;
            newData.CurrentCount =
              newData.CurrentCount < 0 ? 0 : newData.CurrentCount;
            newData.CurrentJoinCount =
              newData.CurrentJoinCount < 0 ? 0 : newData.CurrentJoinCount;
            this.addMembers = [];
            this.cancelMembers = [];
            this.getCancelAddMemberList();
            this.getAllowAddMemberList();
            this.$emit("updateTableData", newData);
          }
        }
      );
    },
    // 撤销添加成员
    cancelAddMember() {},
    // 选择年级
    clickGradeHandle(gradeId) {
      this.gradeId = gradeId;
      // this.getCancelAddMemberList();
      if (this.curriculaData.KZSZ === 1) {
        const classList = this.currentOrgList.find(c => c.Id === gradeId)
          ? this.currentOrgList.find(c => c.Id === gradeId).ChidOrgList
          : [];
        if (classList.length > 0) {
          this.classId = classList[0].Id;
        }
        this.getAllowAddMemberList();
      }
    },
    // 选择班级
    clickClassHandle(classId) {
      this.classId = classId;
      // this.getCancelAddMemberList();
      if (this.curriculaData.KZSZ === 1) {
        this.getAllowAddMemberList();
      }
    },
    // 添加成员
    addMember(sigUpState, studentId, studentCode, gradeId) {
      if (sigUpState === 1) {
        const index = this.addMembers.findIndex(c => c.MemberId === studentId);
        if (index !== -1) {
          this.addMembers.splice(index, 1);
        } else {
          this.addMembers.push({
            MemberId: studentId,
            StudentCode: studentCode,
            GradeId: gradeId
          });
        }
      }
    },
    // 删除成员
    dropMember(member) {
      if (!this.onlyMember) {
        const index = this.cancelMembers.findIndex(
          c => c.MemberId === member.MemberId
        );
        if (index !== -1) {
          this.cancelMembers.splice(index, 1);
        } else {
          this.cancelMembers.push({
            Id: member.Id,
            MemberId: member.MemberId,
            ClassId: member.ClassId,
            GradeId: member.GradeId,
            StudentCode: member.StudentCode
          });
        }
      }
    },
    // 添加成员（全选）
    addMemberAll() {
      if (this.addMembers.length < this.pendingAddMembers.length) {
        this.addMembers = [];
        for (let i = 0; i < this.pendingAddMembers.length; i++) {
          this.addMembers.push({
            MemberId: this.pendingAddMembers[i].MemberId,
            StudentCode: this.pendingAddMembers[i].StudentCode,
            GradeId: this.pendingAddMembers[i].GradeId
          });
        }
      } else {
        this.addMembers = [];
      }
    },
    calcelMemberAll() {
      if (this.cancelMembers.length < this.pendingCancelMembers.length) {
        this.cancelMembers = this.pendingCancelMembers.map(c => ({
          MemberId: c.MemberId,
          StudentCode: c.StudentCode,
          GradeId: c.GradeId
        }));
      } else {
        this.cancelMembers = [];
      }
    }
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        this.classId = "";
        if (this.curriculaData.KZSZ === 2) {
          this.gradeId = "";
          this.classIds = Array.from(
            new Set(
              this.curriculaData.ManagerList.filter(c => c.Id !== null).map(
                c => c.ClassId
              )
            )
          );
          this.currentOrgList = this.orgList.filter(c =>
            Array.from(
              new Set(
                this.curriculaData.ManagerList.filter(c => c.Id !== null).map(
                  c => c.GradeId
                )
              )
            ).includes(c.Id)
          );
          for (let item of this.currentOrgList) {
            item.ChidOrgList = item.ChidOrgList.filter(c =>
              Array.from(
                new Set(
                  this.curriculaData.ManagerList.filter(c => c.Id !== null).map(
                    c => c.ClassId
                  )
                )
              ).includes(c.Id)
            );
          }
        } else {
          this.currentOrgList = this.orgList.filter(c =>
            this.joinGradeStr.includes(c.GradeNumber)
          );
          if (
            !this.onlyMember &&
            this.currentOrgList &&
            this.currentOrgList.length > 0
          ) {
            this.clickGradeHandle(this.currentOrgList[0].Id);
          }
        }
        this.getCancelAddMemberList();
        // eslint-disable-next-line no-empty
        this.cancelMembers = [];
        this.addMembers = [];
      }
    }
  }
};
</script>
