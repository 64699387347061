<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'genie_back' + this.exportType" :style="{ backgroundImage: 'url(' + xsinfo.BJT + ')' }">
					<div :class="'geniedesc' + this.exportType">
						<font :class="'geniedescstr' + this.exportType" v-if="xsinfo.LevelDesc">
							{{ xsinfo.LevelDesc }}
						</font>
					</div>
					<img :src="xsinfo.LevelImage" :class="'genielevel' + this.exportType" />
					<div class="clboth"></div>
				</div>
				<div style="display:inline-block;">
					<el-row>
						<el-col :span="8">
							<div :class="'studenticondiv' + this.exportType">
								<img :class="'studenticon' + this.exportType" :src="xsinfo.ZP" v-if="xsinfo.ZP != ''" />
							</div>
						</el-col>
						<el-col :span="16">
							<div :class="'infodiv' + this.exportType">
								<div>
									<div :class="'labletext' + this.exportType">
										姓 名:
									</div>
									<div :class="'underline' + this.exportType">
										<font :class="'textcolr' + this.exportType">
											{{ xsinfo.XM }}
										</font>
									</div>
								</div>
								<div>
									<div :class="'labletext' + this.exportType">
										班 级:
									</div>
									<div :class="'underline' + this.exportType">
										<font :class="'textcolr' + this.exportType">
											{{ xsinfo.BJMC }}
										</font>
									</div>
								</div>
								<div v-if="isshownl==1">
									<div :class="'labletext' + this.exportType">
										年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;龄:
									</div>
									<div :class="'underline' + this.exportType">
										<font :class="'textcolr' + this.exportType">
											{{ xsinfo.NL }}岁
										</font>
									</div>
								</div>
								<div>
									<div :class="'labletext' + this.exportType">
										班主任:
									</div>
									<div :class="'underline' + this.exportType">
										<font :class="'textcolr' + this.exportType">
											{{ xsinfo.BZRMC }}
										</font>
									</div>
								</div>
								<div v-if="isshowxz==1">
									<div :class="'labletext' + this.exportType">
										星 座:
									</div>
									<div :class="'underline' + this.exportType">
										<font :class="'textcolr' + this.exportType">
											{{ xsinfo.XZ }}
										</font>
									</div>
								</div>
								<div v-if="isshowah==1">
									<div :class="'labletext' + this.exportType">
										爱 好:
									</div>
									<div :class="'underline' + this.exportType">
										<font :class="'textcolr' + this.exportType">
											{{ xsinfo.XQAH }}
										</font>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
					<div class="clboth"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				token: "",
				exportType: 1,
				backgroudimage: "",
				schoolinfo: {
					levelimage: require("@/style/genie/level_1.png"),
					pagename: "基本信息"
				},
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					LevelDesc: "",
					LevelImage: require("../../../../style/genie/level_1.png"),
					BJT: require("../../../../style/growth/genie_back.png"),
				},
				isshownl: 0,
				isshowxz: 0,
				isshowah: 0
			};
		},
		created() {
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ? this.$route.query.exportType * 1 : 1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
			this.isshownl = this.info.GLId[0];
			this.isshowxz = this.info.GLId[1];
			this.isshowah = this.info.GLId[2];
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// console.log(xsdata)
				// this.xsinfo.Id = xsdata.xsxx.Id;
				// this.xsinfo.XM = xsdata.xsxx.XM;
				// this.xsinfo.XBM = xsdata.xsxx.XBM;
				// this.xsinfo.ZP = xsdata.xsxx.ZP;
				// this.xsinfo.XQAH = xsdata.xsxx.XQAH;
				// this.xsinfo.SSXX = xsdata.xsxx.SSXX;
				// this.xsinfo.SSXQ = xsdata.xsxx.SSXQ;
				// this.xsinfo.SSXN = xsdata.xsxx.SSXN;
				// this.xsinfo.XQLX = xsdata.xsxx.XQLX;
				// this.xsinfo.BZRMC = xsdata.xsxx.BZRMC;
				// this.xsinfo.NL = xsdata.xsxx.NL;
				// this.xsinfo.XXMC = xsdata.xsxx.XXMC;
				// this.xsinfo.XXTB = xsdata.xsxx.XXTB;
				// this.xsinfo.SSBJ = xsdata.xsxx.SSBJ;
				// this.xsinfo.BJMC = xsdata.xsxx.BJMC;
				// this.xsinfo.XXBM = xsdata.xsxx.XXBM;
				// this.xsinfo.XZ = xsdata.xsxx.XZ;
				// this.xsinfo.LevelDesc = xsdata.xsxx.LevelDesc;
				// this.xsinfo.ZP = xsdata.xsxx.ZP;
				if (this.xsinfo.ZP == null || this.xsinfo.ZP == undefined || this.xsinfo.ZP == "") {
					this.xsinfo.ZP = this.xsinfo.XBM == 1 ? require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				// this.info = xsdata.pageData;
				if (xsdata.BJT != undefined && xsdata.BJT != null && xsdata.BJT != "") {
					this.xsinfo.BJT = xsdata.BJT;
				}
				if (xsdata.CZSDJ == undefined || xsdata.CZSDJ == null || xsdata.CZSDJ == "") {
					if (xsdata.NZDJ > 0 && xsdata.NZDJ <= 22) {
						this.xsinfo.LevelImage = require("../../../../style/genie/level_" + xsdata.NZDJ + ".png");
					}
				} else {
					this.xsinfo.LevelImage = xsdata.CZSDJ;
				}
				this.xsinfo.LevelDesc = xsdata.CZSDJMS;
				// this.getxsgenielevel();
			}
			// ,
			// downloadImage: function(imgsrc) { //下载图片地址和图片名（无效）

			// 	var image = new Image();

			// 	// 解决跨域 Canvas 污染问题,

			// 	image.setAttribute("crossorigin", "anonymous");

			// 	image.onload = function() {

			// 		var canvas = document.createElement("canvas");

			// 		canvas.width = image.width;

			// 		canvas.height = image.height;

			// 		var context = canvas.getContext("2d");

			// 		context.drawImage(image, 0, 0, image.width, image.height);

			// 		var url = canvas.toDataURL("image/png"); //将图片格式转为base64

			// 		console.log(url, "base64")

			// 	};

			// 	image.src = imgsrc + '?time=' + Date.now(); //注意，这里是灵魂，否则依旧会产生跨域问题

			// 	console.log(image.src, "image.src")

			// 	return image.src;

			// }
		}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticondiv1 {
		width: 165px;
		height: 165px;
		text-align: center;
		margin-top: 3rem;
		margin-left: 5rem;
	}

	.studenticondiv2 {
		width: 80px;
		height: 80px;
		text-align: center;
		margin-top: 2rem;
		margin-left: 4rem;
	}

	.studenticon1 {
		width: 165px;
		height: 165px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
	}

	.genielevel1 {
		width: 320px;
		height: 376px;
		text-align: center;
		margin-top: 60px;
		margin-left: -2rem;
	}

	.genielevel2 {
		width: 240px;
		height: 286px;
		text-align: center;
		margin-top: 60px;
		margin-left: -2rem;
	}

	.geniedescstr1 {
		margin-top: 20px;
		display: inline-block;
		width: 155px;
		height: 34px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
	}

	.geniedescstr2 {
		margin-top: 15px;
		display: inline-block;
		width: 103px;
		height: 18px;
		font-size: 10px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
	}

	.geniedesc1 {
		width: 155px;
		height: 118px;
		text-align: center;
		float: left;
		margin-top: 290px;
		margin-left: 95px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-image: url("../../../../style/growth/genie_desc.png");
	}

	.geniedesc2 {
		width: 103px;
		height: 85px;
		text-align: center;
		float: left;
		margin-top: 235px;
		margin-left: 70px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-image: url("../../../../style/growth/genie_desc.png");
	}

	.clboth {
		clear: both;
	}

	.labletext1 {
		margin-top: 25px;
		display: inline-block;
		height: 28px;
		width: 100px;
		font-size: 26px;
		font-family: Microsoft YaHei;
		text-align: justify;
		font-weight: bold;
		color: #080808;
		display: inline-block;
	}

	.underline1 {
		margin-top: 30px;
		display: inline-block;
		width: 294px;
		border-bottom: 2px solid #080808;
		height: 34px;
		font-size: 26px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #080808;
	}

	.textcolr1 {
		margin-left: 10px;
		margin-bottom: 5px;
	}

	.pagename1 {
		text-align: center;
		margin-top: 2rem;
		width: 100%;
		height: 70px;
		font-size: 70px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #00a1e9;
		-webkit-text-stroke: 3px #ffffff;
		text-stroke: 3px #ffffff;
	}

	.nameline1 {
		margin-top: 30px;
		display: inline-block;
	}

	.labletext2 {
		width: 80px;
		height: 24px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		margin-top: 20px;
		text-align: justify;
		display: inline-block;
	}

	.underline2 {
		margin-top: 10px;
		display: inline-block;
		width: 140px;
		border-bottom: 1px solid #080808;
		height: 24px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #080808;
	}

	.textcolr2 {
		margin-left: 10px;
		margin-bottom: 5px;
	}

	.pagename2 {
		text-align: center;
		margin-top: 10px;
		width: 100%;
		height: 53px;
		font-size: 52px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #00a1e9;
		-webkit-text-stroke: 3px #ffffff;
		text-stroke: 3px #ffffff;
	}

	.nameline2 {
		margin-top: 20px;
		display: inline-block;
	}

	.infodiv1 {
		margin-top: 20px;
		margin-left: 2rem;
	}

	.infodiv2 {
		margin-top: 10px;
		margin-left: 3rem;
	}

	.bkimg {
		background-size: 100% 100%;
	}

	.genie_back1 {
		background-repeat: no-repeat;
		background-size: 100% 100%;
		height: 490px;
		width: 756px;
		margin-top: 30px;
		margin-left: 40px;
	}

	.genie_back2 {
		background-repeat: no-repeat;
		background-size: 100% 100%;
		height: 388px;
		width: 530px;
		margin-top: 20px;
		margin-left: 30px;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/radius_dotted.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/radius_dotted.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}
</style>