<template>
  <div>
    <div class="toubudh-box">
      <span class="toubudh-box-span">业务管理</span>
      <span class="toubudh-box-span whole-col">家校共育</span>
    </div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div style="border-bottom: 1px solid #f2f2f2; padding-top: 30px;">
          <div class="top-butt-box">
            <div class="cent-butt whole-col-hov cent-butt-sty whole-col">
              计划管理
            </div>
            <div class="cent-butt whole-col-hov" @click="goto()">类别管理</div>
          </div>
        </div>
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <div class="table-er-butt" @click="addextracurricular()">
                <span class="table-er-buttspan">新增学校计划</span>
              </div>
              <div
                style="
                  font-size: 14px;
                  margin-bottom: 10px;
                  display: inline-block;
                  float: right;
                "
              >
                <el-input
                  style="width: 200px;"
                  type="text"
                  placeholder="输入计划名称/计划类别"
                  v-model="jhmc"
                  maxlength="50"
                ></el-input>
                <span
                  style="color: #14d089; margin-left: 5px; cursor: pointer;"
                  @click="search()"
                  >搜索</span
                >
              </div>
            </div>
            <el-table
              ref="singleTable"
              border
              :data="hdlist"
              highlight-current-row
              style="width: 100%;"
            >
              <el-table-column
                align="center"
                label="序号"
                
                width="50" property="PXH"
              >
              </el-table-column>
              <el-table-column align="center" property="JHMC" label="计划名称">
              </el-table-column>
              <el-table-column align="center" property="KZNJ" label="开展年级">
              </el-table-column>
              <el-table-column align="center" property="LBMC" label="计划类别">
              </el-table-column>
              <el-table-column align="center" property="SYZTMC" label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.SYZT == '1'">
                    <el-tag type="success" effect="light">
                      {{ scope.row.SYZTMC }}
                    </el-tag>
                  </span>
                  <span v-else>
                    <el-tag type="danger" effect="light">
                      {{ scope.row.SYZTMC }}
                    </el-tag>
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" property="PXH" label="排序">
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.SYZT == 1"
                    @click="handleEnable(scope.row.Id, 2)"
                    style="color: red;"
                    type="text"
                    >禁用</el-button
                  >
                  <el-button
                    v-else
                    @click="handleEnable(scope.row.Id, 1)"
                    type="text"
                    style="color: green;"
                    >启用</el-button
                  >
                  <el-button
                    @click="handleEdit(scope.row.Id)"
                    type="text"
                    style="color: blue;"
                    >编辑</el-button
                  >
                  <el-button
                    @click="handleDelete(scope.row.Id)"
                    style="color: red;"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagin-box">
              <div class="pagin-wz">
                <Pager
                  :pagerInfo="pagerInfo"
                  @changeIndex="handleCurrentChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.yhid = user.Id;
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    addextracurricular: function() {
      this.$router.push({ name: "bjjxgy" });
    },
    editextracurricular: function() {
      this.$router.push({ name: "bjjxgy", params: { hdid: this.editid } });
    },
    goto: function() {
      this.$router.push({ name: "jxgylb" });
    },
    messagebox: function(message) {
      this.$alert("提示", message, {
        confirmButtonText: "确定"
      });
    },
    loaddata: function() {
      this.$api.post(
        "v1/extracurricular/GetJXGYList",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          JHMC: this.jhmc,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.hdlist = [];
            this.hdlist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    search: function() {
      this.pagerInfo.pageIndex = 1;
      this.loaddata();
    },
    handleEdit: function(id) {
      this.editid = id;
      this.editextracurricular();
    },
    handleDelete: function(id) {
      this.$confirm("确定要删除该活动吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "v1/extracurricular/DeleteJXGY",
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              Id: id,
              YHId: this.yhid,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "删除成功!"
                });
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "删除失败!"
                });
                this.loaddata();
              }
            }
          );
        })
        .catch(() => {});
    },
    handleEnable: function(id, status) {
      this.$api.post(
        "v1/extracurricular/EnableChange",
        {
          Id: id,
          YHId: this.yhid,
          SSXQ: this.ssxq,
          SSXX: this.ssxx,
          Status: status,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "info",
              message: "操作成功!"
            });
            this.loaddata();
          } else {
            this.$message({
              type: "info",
              message: "操作失败!"
            });
          }
        }
      );
    }
  },
  data() {
    return {
      isLogin: true,
      hdlist: [],
      wdlist: [],
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      },
      ssxx: "",
      ssxq: "",
      yhid: "",
      jhmc: "",
      token: "",
      editid: ""
    };
  }
};
</script>
