<template>
  <!-- 教师通用报表-子项目 -->
  <div class="setZXM-box">
    <div class="top-btns">
      <el-button size="mini" type="success" @click="edit_XM(0)">新增</el-button>
      <el-button
        size="mini"
        type="success"
        @click="$router.push({ name: 'teachersetXM' })"
        >返回</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="PXH" label="排序号"> </el-table-column>
      <el-table-column prop="XM" label="父项"> </el-table-column>
      <el-table-column prop="MC" label="子项目"> </el-table-column>
      <el-table-column prop="BL" label="占比"> </el-table-column>
      <el-table-column label="操作" width="330">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="edit_XM(scope.row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="del_XM(scope.row)"
            >删除</el-button
          >
          <el-button size="mini" type="success" @click="to_HD(scope.row)"
            >关联活动</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 设置子项目弹窗 -->
    <XMDialog
      :show="ZXM_Dialog.show"
      :title="ZXM_Dialog.title"
      :form="ZXM_Form"
      @XM_Submit="XM_Submit"
    />
    <!-- 设置子项目活动 -->
    <HDDialog :show="HD_Dialog.show" @HD_Submit="HD_Submit" />
  </div>
</template>

<script>
import XMDialog from "./setXM.vue";
import HDDialog from "./setHD.vue";

export default {
  name: "teachersetZXM",
  components: {
    XMDialog,
    HDDialog,
  },
  data() {
    return {
      userInfo: {},
      MBInfo: {},
      BBInfo: {},
      XMInfo: {},
      tableData: [],
      ZXM_Dialog: {
        show: false,
        title: "新增",
      },
      ZXM_Form: {},
      HD_Dialog: {
        show: false,
      },
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
    this.MBInfo = JSON.parse(localStorage.getItem("MBInfo"));
    this.BBInfo = JSON.parse(localStorage.getItem("BBInfo"));
    this.XMInfo = JSON.parse(localStorage.getItem("XMInfo"));
    this.getXM_List();
  },
  methods: {
    getXM_List() {
      this.$api.post(
        "/v1/mbbb/GetGLHDBList",
        {
          MBGLID: this.BBInfo.Id,
          FID: this.XMInfo.Id,
          IsGetHDList: 2, //子项目传2
          LX: 3,
          token: this.userInfo.Token,
        },
        (res) => {
          if (res.Code === 0) {
            let arr = res.Target || [];
            let arr2 = arr.filter(
              (item) => item.LX === 3 && item.FID === this.XMInfo.Id
            );
            arr2.sort((a, b) => a.PXH - b.PXH);
            console.log(arr2, "获取到的子项目");
            arr2.forEach((ZXM) => {
              ZXM.XM = this.XMInfo.MC;
            });
            this.tableData = arr2;
          }
        }
      );
    },
    // 处理子项目数据
    handle_ZXM() {
      //
    },
    // 编辑项目
    edit_XM(ZXM) {
      console.log(ZXM, "ZXM");
      if (ZXM) {
        this.ZXM_Form = {
          ...ZXM,
          PXH: ZXM.PXH,
          XMMC: ZXM.MC,
          ZB: ZXM.BL,
        };
      }
      this.ZXM_Dialog.title = ZXM ? "编辑" : "新增";
      this.ZXM_Dialog.show = true;
    },
    // 删除项目
    del_XM(XM) {
      this.$confirm("确认删除？")
        .then(() => {
          this.$api.post(
            "/v1/mbbb/DeleteGLHDB",
            {
              Id: XM.Id,
              token: this.userInfo.Token,
            },
            (res) => {
              if (res.Code === 0) {
                this.$message.success("删除成功");
                this.getXM_List();
              }
            }
          );
        })
        .catch(() => {});
    },
    to_HD(row) {
      localStorage.setItem("ZXMInfo", JSON.stringify(row));
      localStorage.setItem("XM_Type", JSON.stringify(2));
      this.$router.push({
        name: "teachersetHD",
      });
    },
    HD_Submit(val) {
      console.log(val, "val");
      if (!val) {
        this.HD_Dialog.show = false;
        return;
      }
    },
    // 保存项目
    XM_Submit(val) {
      if (!val) {
        this.ZXM_Dialog.show = false;
        return;
      }
      let type = 0;
      let url = "/v1/mbbb/AddGLHDB";
      if (this.ZXM_Dialog.title === "编辑") {
        type = 1;
        url = "/v1/mbbb/UpdateGLHDB";
      }

      let obj = {
        Id: this.ZXM_Form.Id || null,
        MBGLID: this.BBInfo.Id,
        MC: val.XMMC,
        IsSelect: 2, //阶段平均 1.勾选 2.不勾选（默认）
        GLHD:
          this.ZXM_Form.HD && this.ZXM_Form.HD.length > 0
            ? this.ZXM_Form.HD
            : null, //新增是不传
        HDXS: null, //因为是项目 不传
        BL: val.ZB,
        LX: 3, //教师通用报表设置项目里面 所有的LX都是3
        HDLX: null, //4.过程 5.阶段 6.道德报表 7.教师通用报表
        FID: this.XMInfo.Id, //
        PXH: val.PXH,
        SSXX: this.userInfo.SSZZId,
        CJR: !type ? this.userInfo.Id : null,
      };

      this.$api.post(
        url,
        {
          GLHDBInfo: [obj],
          MBGLID: !type ? this.BBInfo.Id : null,
          MBLX: !type ? this.MBInfo.MBDM : null,
          token: this.userInfo.Token,
        },
        (res) => {
          if (res.Code === 0) {
            this.getXM_List();
            this.ZXM_Dialog.show = false;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.setXM-box {
}
</style>
