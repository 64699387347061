<template>
  <div class="main-big">
    <div class="main-box">
      <!--左侧菜单栏开始-->
      <div class="main-left" style="z-index: 1200">
        <div class="main-left-boxnone">
          <div class="logo-z-box whole-back">
            <img src="../style/images/icon_home_logo.png" />
          </div>
          <div class="left-bott-box">
            <MenuTree />
            <!-- <el-menu :default-active="$route.path" router :default-openeds="['1']" @open="handleOpen" @close="handleClose">
                            <template v-for="(mainroute,index) in $router.options.routes" v-if="!mainroute.hidden">
                                <el-menu-item :index="mainroute.path" v-if="mainroute.children.length<=0">{{mainroute.menuname}}</el-menu-item>
                                <el-menu-item :index="mainroute.children[0].path" v-else-if="mainroute.children.length==1">{{mainroute.children[0].menuname}}</el-menu-item>
                                <el-submenu :index="index+''" v-else>
                                    <template slot="title">
                                        {{mainroute.menuname}}
                                    </template>
                                    <template v-for="(firstchild,cindex) in mainroute.children" v-if="!firstchild.hidden">
                                        <el-submenu :index="index+cindex+''" v-if="firstchild.children.length > 0">
                                            <template slot="title">
                                                {{firstchild.menuname}}
                                            </template>
                                            <template v-for="(second,sindex) in firstchild.children" v-if="!second.hidden">
                                                <el-submenu :index="index+cindex+sindex+''" v-if="second.children.length > 0">
                                                    <template slot="title">
                                                        {{second.menuname}}
                                                    </template>
                                                    <el-menu-item v-for="third in second.children" :index="third.path" :key="third.path" v-if="!third.hidden">{{third.menuname}}</el-menu-item>
                                                </el-submenu>
                                                <el-menu-item :index="second.path" v-else>{{second.menuname}}</el-menu-item>
                                            </template>
                                        </el-submenu>
                                        <el-menu-item :index="firstchild.path" v-else>{{firstchild.menuname}}</el-menu-item>
                                    </template>
                                </el-submenu>
                            </template>
            </el-menu>-->
          </div>
        </div>
      </div>
      <div class="main-right">
        <div class="content-main">
          <div class="rig-top-box whole-back">
            <span class="rig-top-boxspan">中小学生素质管理评价与解决方案</span>
            <div class="news-box" style="position: relative">
              <div class="pope-news">
                <img class="pope-news-img" :src="this.userPhoto" />
                <span class="pope-news-span">{{ userName }}</span>
              </div>
              <!-- <el-dropdown>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>手机绑定</el-dropdown-item>
                  <el-dropdown-item>修改密码</el-dropdown-item>
                  <el-dropdown-item>修改资料</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
              <!-- <div class="toubucz toubucz-sty" title="消息">
                <img src="../style/images/tongz.png" />
                <div class="prompt-d"></div>
              </div> -->

              <div class="toubucz" title="退出" @click="logout">
                <img src="../style/images/tuic.png" />
              </div>
            </div>
          </div>
          <div class="content">
            <div class="toubudh-box">
              <Breadcrumb ref="navi" />
            </div>
            <div style="padding: 40px">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuTree from "@/components/MenuTree.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  components: {
    MenuTree,
    Breadcrumb,
  },
  data: function () {
    return {
      userName: "",
      userPhoto: require("../style/images/tx1.png"),
    };
  },
  created: function () {
    var user = localStorage.getItem("userinfo");
    if (user) {
      user = JSON.parse(user);
      this.userName = user.XSM || "用户";
      if (user.zp !== null && user.zp !== undefined && user.zp !== "") {
        this.userPhoto = require(`${user.zp}`);
      }
    }
  },
  mounted: function () {
    // console.log(this.hasPermission);
    // console.log(this.$router.options.routes);
  },
  methods: {
    logout: function () {
      this.$confirm("确认退出吗?", "提示", {})
        .then(() => {
          sessionStorage.removeItem("userinfo");
          localStorage.removeItem("Token");
          localStorage.removeItem("userinfo");
          localStorage.removeItem("MenuList");
          localStorage.removeItem("PermList");
          sessionStorage.removeItem("loginSucess");
          this.$store.commit("saveToken", undefined);

          localStorage.clear(); //退出清除所有缓存
          this.$router.push({ path: "/login" });
          location.reload(); //退出重新加载浏览器的js代码  避免更新代码时无效
        })
        .catch(() => {});
    },
    // eslint-disable-next-line no-unused-vars
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    // eslint-disable-next-line no-unused-vars
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-menu {
  border-right: unset;
  background-color: unset;
}
</style>
