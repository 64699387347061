<!--
 方法：
    导入成功事件(需要在父级组件中实现，参数接收该事件返回的数据)
    on-success({ results, header })
    导入成功前事件(需要在父级组件中实现，参数接收该事件返回的数据)
    beforeUpload()
-->
<template>
  <div @click="handleUpload">
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      @change="handleClick"
    />
    <slot></slot>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  data() {
    return {
      excelData: {
        header: null,
        results: null
      }
    };
  },
  props: {
    beforeUpload: {
      Type: Function,
      default: function() {}
    },
    onSuccess: {
      Type: Function,
      default: function() {}
    },
    itemKey: {
      Type: Object,
      default: function() {}
    }
  },
  methods: {
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload(rawFile) {
      this.$refs["excel-upload-input"].value = null;
      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData(rawFile) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet, { raw: false });
          this.generateData({ header, results });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      for (C = range.s.c; C <= range.e.c; ++C) {
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        let hdr = "UNKNOWN " + C;
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    generateData({ header, results }) {
      for (let cell of results) {
        for (let item in cell) {
          if (!isNaN(Number(cell[item]))) {
            cell[item] = Number(cell[item]);
          }
        }
      }
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData, this.itemKey);
    }
  }
};
</script>
<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>
