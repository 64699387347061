var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"title":"设置对象","visible":_vm.visible,"width":"30%","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',[_vm._v("已选择：")]),_vm._l((_vm.currentSetObject),function(item){return _c('el-tag',{key:item,staticClass:"objtag",attrs:{"closable":""},on:{"close":function($event){return _vm.delObject(item)}}},[_vm._v(" "+_vm._s(_vm.allObject.find(c => c.Id === item) ? _vm.allObject.find(c => c.Id === item).PJCYDXMC : "")+" ")])}),_c('div',{staticStyle:{"border-bottom":"1px solid #e5e5e5","margin":"20px"}}),(_vm.hdxs >= 4)?_c('div',{staticClass:"popo-ovebox-titbox",staticStyle:{"padding-top":"40px","width":"100%"}},[_c('div',{staticClass:"popo-ovebox-tit"},[_vm._v("主评")]),_c('div',{staticStyle:{"border-bottom":"1px solid #f2f2f2","padding-bottom":"10px"}},[_c('div',{staticClass:"disilb-box-l",staticStyle:{"width":"80px","text-align":"left"}},[_c('div',{staticClass:"tablil-xz-name epp-ov-l"},[_vm._v("参与方式:")])]),_c('el-select',{attrs:{"size":"small","placeholder":"请选择"},on:{"change":_vm.changeSelectJoinType},model:{value:(_vm.currentJoinType),callback:function ($$v) {_vm.currentJoinType=$$v},expression:"currentJoinType"}},_vm._l((_vm.evaluationObjectJoinType),function(item){return _c('el-option',{key:item.Key,attrs:{"disabled":(_vm.JZKBLX === 3 && item.Key !== 1) ||
                (_vm.JZKBLX === 2 &&
                  _vm.currentSetObject.length > 0 &&
                  !_vm.allObject
                    .filter(c => _vm.currentSetObject.includes(c.Id))
                    .map(c => c.PJCYDXLB)
                    .includes(item.Key)),"label":item.Value,"value":item.Key}})}),1)],1),_c('div',{staticClass:"popo-ovebox-l",staticStyle:{"background":"unset"}},[_c('el-checkbox-group',{model:{value:(_vm.currentSetObject),callback:function ($$v) {_vm.currentSetObject=$$v},expression:"currentSetObject"}},_vm._l((_vm.allObject.filter(
              c =>
                c.PJCYDXLB === _vm.currentJoinType &&
                c.PJFS == _vm.PJFS &&
                c.SYZT === 1 &&
                c.SFSC === 2 &&
                c.HDXS === 4
            )),function(item){return _c('el-checkbox',{key:item.Id,attrs:{"label":item.Id}},[_vm._v(_vm._s(item.PJCYDXMC))])}),1)],1)]):_vm._e(),([2, 3, 6, 7].includes(_vm.hdxs))?_c('div',{staticClass:"popo-ovebox-titbox",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"popo-ovebox-tit"},[_vm._v("互评")]),_c('div',{staticClass:"popo-ovebox-l",staticStyle:{"background":"unset"}},[_c('el-checkbox-group',{model:{value:(_vm.currentSetObject),callback:function ($$v) {_vm.currentSetObject=$$v},expression:"currentSetObject"}},_vm._l((_vm.allObject.filter(
              c =>
                c.SYZT === 1 &&
                c.SFSC === 2 &&
                c.HDXS === 2 &&
                c.PJFS === _vm.PJFS
            )),function(item){return _c('el-checkbox',{key:item.Id,attrs:{"label":item.Id}},[_vm._v(_vm._s(item.PJCYDXMC))])}),1)],1)]):_vm._e(),([1, 3, 5, 7].includes(_vm.hdxs))?_c('div',{staticClass:"popo-ovebox-titbox",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"popo-ovebox-tit"},[_vm._v("自评")]),_c('div',{staticClass:"popo-ovebox-l",staticStyle:{"background":"unset"}},[_c('el-checkbox-group',{model:{value:(_vm.currentSetObject),callback:function ($$v) {_vm.currentSetObject=$$v},expression:"currentSetObject"}},_vm._l((_vm.allObject.filter(
              c =>
                c.SYZT === 1 &&
                c.SFSC === 2 &&
                c.HDXS === 1 &&
                c.PJFS === _vm.PJFS
            )),function(item){return _c('el-checkbox',{key:item.Id,attrs:{"label":item.Id}},[_vm._v(_vm._s(item.PJCYDXMC))])}),1)],1)]):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.setObject}},[_vm._v("确 定")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }