<template>
  <router-view
    :token="token"
    :user="user"
    :schoolId="schoolId"
    :basicInfo="data"
    :teacherCourse="teacherCourse"
  ></router-view>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      token: "",
      user: {},
      schoolId: "",
      termId: "",
      teacherCourse: [],
      term: {}
    };
  },
  created: async function() {
    this.term = JSON.parse(localStorage.getItem("curTerm"));
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    await this.getTeacherCourse();
    this.init();
  },
  methods: {
    init(termId) {
      this.$api.post(
        "/v1/examination/GetExaminationBasicInfo",
        {
          token: this.token,
          termId: termId || this.term.Id,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            let { GradeList, CourseList } = r.Target;
            CourseList.forEach(course => {
              let grade = GradeList.find(
                grade => course.Grade === grade.Number
              );
              if (grade) {
                course.GradeCourseList.forEach(it => (it.gradeId = grade.Id));
              }
            });
            this.data = r.Target;
          }
        }
      );
    },
    // 获取教师所教课程
    getTeacherCourse(termId) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetClassSchedule",
          {
            UserId: this.user.Id, //	否	string	教师Id
            ClassId: "", //	否	string	班级Id
            ClassScheduleType: 1, //	是	int	获取个人课程表1获取班级课程表2
            SchoolId: this.schoolId, //	是	string	学校Id
            TermId: termId || this.term.Id, //	是	string	学期Id
            token: this.user.Token
          },
          res => {
            if (res.Code === 0 && res.Target) {
              this.teacherCourse = res.Target;
              resolve();
            }
          }
        );
      });
    }
  }
};
</script>
