<template>
  <div>
    <div style="padding: 40px;">
      <el-row :gutter="20">
        <el-col :span="6">
          <router-link :to="{ name: 'TeacherMange' }">
            <el-button style="width:180px;" type="primary"
              >1.导入教师，设置角色<br />用户管理-教师管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'SchoolGradeList' }">
            <el-button style="width:180px;" type="primary"
              >2.年班级设置<br />教务管理-学级管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'StudentMange' }">
            <el-button style="width:180px;" type="primary"
              >3.导入学生并分班<br />用户管理-学生管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'levelset' }">
            <el-button style="width:180px;" type="primary"
              >4.等级管理<br />教务管理-等级管理</el-button
            >
          </router-link>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:20px;">
        <el-col :span="6">
          <router-link :to="{ name: 'XxkcbList' }">
            <el-button style="width:180px;" type="primary"
              >5.学校课程管理<br />教务管理-学校课程</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'SchollTermM' }">
            <el-button style="width:180px;" type="primary"
              >6.新增学期和周次<br />教务管理-学期管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'TeachingGroupList' }">
            <el-button style="width:180px;" type="primary"
              >7.管理教研组<br />教务管理-教研组管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'CourseTableSet' }">
            <el-button style="width:180px;" type="primary"
              >8.课表设置<br />教务管理-课表管理</el-button
            >
          </router-link>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:20px;">
        <el-col :span="6">
          <router-link :to="{ name: 'CourseMange' }">
            <el-button style="width:180px;" type="primary"
              >9.新增课表<br />教务管理-课表管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'evaluationsystem' }">
            <el-button style="width:180px;" type="primary"
              >10.管理体系(非必)<br />评价管理-体系管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'evaluationactivityset' }">
            <el-button style="width:180px;" type="primary"
              >11.新增活动<br />基础管理-活动管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'schoolindex' }">
            <el-button style="width:180px;" type="primary"
              >12.通用指标管理<br />评价管理-通用指标管理</el-button
            >
          </router-link>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:20px;">
        <el-col :span="6">
          <router-link :to="{ name: 'starsindex' }">
            <el-button style="width:180px;" type="primary"
              >13.星星指标管理<br />评价管理-星星指标管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'questionnaire' }">
            <el-button style="width:180px;" type="primary"
              >14.问卷管理<br />评价管理-评价问卷管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'evaluationobjectset' }">
            <el-button style="width:180px;" type="primary"
              >15.对象管理<br />评价管理-对象管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'evaluationactivityset' }">
            <el-button style="width:180px;" type="primary"
              >16.活动挂接对象<br />基础管理-活动管理</el-button
            >
          </router-link>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:20px;">
        <el-col :span="6">
          <router-link :to="{ name: 'MenuList' }">
            <el-button style="width:180px;" type="primary"
              >17.菜单设置<br />基础管理-菜单管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'RoleManage' }">
            <el-button style="width:180px;" type="primary"
              >18.角色菜单权限设置<br />用户管理-角色管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'growthsystem' }">
            <el-button style="width:180px;" type="primary"
              >19.成长体系设置<br />综合设置-成长体系</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'growthreportset' }">
            <el-button style="width:180px;" type="primary"
              >20.成长报告设置<br />综合设置-成长报告设置</el-button
            >
          </router-link>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:20px;">
        <el-col :span="6">
          <router-link :to="{ name: 'schoolawardset' }">
            <el-button style="width:180px;" type="primary"
              >21.学校获奖管理<br />综合设置-学校获奖设置</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'curriculavariablemanager' }">
            <el-button style="width:180px;" type="primary"
              >22.课辅活动管理<br />综合设置-课辅活动管理</el-button
            >
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'jxgy' }">
            <el-button style="width:180px;" type="primary">
              23.家校共育管理<br />综合设置-家校共育
            </el-button>
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link :to="{ name: 'studentexchangearticle' }">
            <el-button style="width:180px;" type="primary"
              >24.兑换管理<br />综合设置-兑换管理</el-button
            >
          </router-link>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:20px;">
        <el-col :span="6">
          <router-link :to="{ name: 'survey' }">
            <el-button style="width:180px;" type="primary"
              >25.家长满意度调查<br />综合设置-家长满意度</el-button
            >
          </router-link>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:20px;">
        <el-col>
          不知道我还有哪些地方没有设置？
          <el-button style="margin-left:20px;" type="success" @click="autocheck"
            >自动检测一下</el-button
          >
        </el-col>
      </el-row>
      <el-row v-if="errmessage != ''" :gutter="20" style="margin-top:20px;">
        <el-col>
          <p style="color:red;font-size:20px;">{{ errmessage }}</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  created() {
    // YhyTODO:获取所属学校
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
    }
  },
  mounted: function() {},
  methods: {
    autocheck: function() {
      this.errmessage = "";
      this.$api.post(
        "v1/schoolcheck/GetSchoolPreCheckStatus",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          token: this.token
        },
        r => {
          this.errmessage = r.Target;
        }
      );
    }
  },
  data() {
    return {
      ssxx: "",
      ssxq: "",
      token: "",
      errmessage: ""
    };
  }
};
</script>
