<template>
  <el-dialog
    title="设置积分生成规则"
    :visible.sync="visible"
    width="60%"
    top="1%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <div style="padding: 30px;">
      <div
        class="operate-box operate-box-supple"
        style="margin-bottom: 10px; margin-top: 0px;"
      >
        <div class="sm-conten-tit">
          积分生成规则
        </div>
      </div>
      <div class="over-css" style="max-height: 200px;">
        <div role="group" aria-label="checkbox-group" class="el-checkbox-group">
          <div
            class="whi-disilb-box"
            style="font-size: 14px;display:flex;justify-content: start;align-items: center;width: 100%;"
          >
            <el-radio
              @change="changeGenerateType"
              v-model="formData.GenerateType"
              label="0"
              >周期生成</el-radio
            >
            <el-radio
              @change="changeGenerateType"
              v-model="formData.GenerateType"
              label="1"
              >实时生成</el-radio
            >
          </div>
        </div>
      </div>

      <div
        v-if="formData.GenerateType === '0'"
        class="operate-box operate-box-supple"
        style="margin-bottom: 10px; margin-top: 0px;"
      >
        <div class="sm-conten-tit">
          计算频次
        </div>
      </div>
      <div
        v-if="formData.GenerateType === '0'"
        class="over-css"
        style="max-height: 200px;"
      >
        <div role="group" aria-label="checkbox-group" class="el-checkbox-group">
          <div
            class="whi-disilb-box"
            style="font-size: 14px;display:flex;justify-content: start;align-items: center;width: 100%;"
          >
            <el-input-number
              :disabled="formData.GenerateType === '1'"
              v-model="formData.GenerateRules.ZXPC"
              size="small"
              style="width:40px;margin:auto 10px"
              :controls="false"
              :precision="0"
              :min="1"
              :max="9"
            ></el-input-number>
            <div><span class="c-b-9">周/次</span></div>
            <div style="margin-left:100px">
              <span class="c-b-9">周期结束(23:59:59)前</span>
            </div>
            <el-input-number
              :disabled="formData.GenerateType === '1'"
              v-model="formData.GenerateRules.PYL"
              size="small"
              style="width:55px;margin:auto 10px"
              :controls="false"
              :precision="0"
              :min="0"
            ></el-input-number>
            <div><span class="c-b-9">小时启动计算</span></div>
          </div>
        </div>
      </div>
      <div
        class="operate-box operate-box-supple"
        style="margin-bottom: 10px; margin-top: 0px;"
      >
        <div class="sm-conten-tit">
          选择活动
          <span class="c-g-9">(勾选参与活动，并填写参与比值)</span>
        </div>
      </div>
      <div class="over-css" style="max-height: 200px;">
        <el-checkbox-group v-model="activities">
          <div
            class="whi-disilb-box"
            style="font-size: 14px;"
            v-for="item in formData.GenerateType === '0'
              ? formData.ActivitiesPeriod
              : formData.ActivitiesRealTime"
            :key="item.Id"
          >
            <el-checkbox :label="item.Id">{{ item.Name }}</el-checkbox>
            <div
              style="display:inline-block;margin-left:10px"
              v-if="item.Id === 'ScoreManager'"
            >
              <el-select
                size="medium"
                style="width:200px"
                popper-class="jf-examination-select"
                v-model="examinationValues"
                multiple
                placeholder="请选择考试类型"
              >
                <el-option
                  v-for="Exam in item.ExaminationDatas"
                  :key="Exam.ExamType"
                  :label="
                    Exam.Value
                      ? Exam.ExamName + '(' + Exam.Value + '%)'
                      : Exam.ExamName
                  "
                  :value="Exam.ExamType"
                >
                  <span style="float: left">{{ Exam.ExamName }}</span>
                  <div
                    @click.stop="placeholder"
                    style="float:right;width: 50px;"
                  >
                    <el-input
                      v-model="Exam.Value"
                      placeholder=""
                      size="mini"
                    ></el-input
                    >%
                  </div>
                </el-option>
              </el-select>
            </div>
            <div style="display:inline-block;margin-left:10px" v-else>
              <el-input-number
                v-model="item.Value"
                size="medium"
                style="width: 55px;"
                placeholder="请输入"
                :controls="false"
                :precision="0"
                :min="0"
                :max="100"
              ></el-input-number>
              %
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div
        v-if="formData.GenerateType === '0'"
        class="operate-box operate-box-supple"
        style="margin-bottom: 10px; margin-top: 40px;"
      >
        <div class="sm-conten-tit">
          加分项
          <span class="c-g-9"
            >(选择班级成长体系，当本班在本周在本班级成长体系中得星时，直接给本班的每个学生加上后面设置的积分。选择获奖管理，当学生获奖时，直接给该学生加上获奖一定得分比例的积分)</span
          >
        </div>
      </div>
      <div
        v-if="formData.GenerateType === '0'"
        class="over-css"
        style="font-size: 14px; max-height: 200px;"
      >
        <el-checkbox-group v-model="classSystems">
          <div
            class="whi-disilb-box"
            style="font-size: 14px;"
            v-for="item in formData.ClassSystems"
            :key="item.Id"
          >
            <el-checkbox
              :disabled="formData.GenerateType === '1'"
              :label="item.Id"
              >{{ item.Name }}</el-checkbox
            >
            <el-input-number
              :disabled="formData.GenerateType === '1'"
              v-model="item.Value"
              size="medium"
              style="width: 55px;margin-left:10px"
              placeholder="请输入"
              :controls="false"
              :precision="0"
              :min="0"
              :max="100"
            ></el-input-number>
            {{ item.ActivityType !== 20 ? "分" : "%" }}
          </div>
        </el-checkbox-group>
      </div>
      <div
        v-if="formData.GenerateType === '0'"
        class="operate-box operate-box-supple"
        style="margin-bottom: 10px; margin-top: 40px;"
      >
        <div class="sm-conten-tit">
          积分得分规则
          <span class="c-g-9"
            >(学生积分每周生成一次，计算时直接以本周的实际得分计算，使用学生在班级的排名给予相应的分值，设置时名次必须连贯且不被重复，当学生本周的得分为0时，给予其最低阶的得分)</span
          >
        </div>
      </div>
      <div v-if="formData.GenerateType === '0'" style="padding-top: 10px;">
        <el-form :model="this" ref="ruleForm">
          <el-form-item>
            <div
              class="longjt-main-box"
              v-for="(item, index) in formData.Ranks"
              :key="item.Id"
            >
              <el-form-item
                label="起始名次："
                style="display:inline-block"
                :rules="[
                  {
                    required: true,
                    message: '请输入起始名次',
                    trigger: 'blur'
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (rule.JSMC && value > rule.JSMC) {
                        return callback(new $window.Error(rule.message1));
                      } else if (
                        rule.validateData.filter(
                          c =>
                            c.KSMC &&
                            c.JSMC &&
                            value <= c.JSMC &&
                            value >= c.KSMC
                        ).length > 1
                      ) {
                        return callback(new $window.Error(rule.message2));
                      } else return callback();
                    },
                    message1: '不能大于结束名次',
                    message2: '与已有名次冲突',
                    trigger: 'blur',
                    JSMC: item.JSMC,
                    validateData: formData.Ranks
                  }
                ]"
                :prop="'formData.Ranks[' + index + '].KSMC'"
              >
                <el-input-number
                  :disabled="formData.GenerateType === '1'"
                  v-model="item.KSMC"
                  size="medium"
                  style="width: 70px; margin-bottom: 2px;"
                  placeholder="请输入"
                  :controls="false"
                  :precision="0"
                  :min="1"
                  :max="9999"
                ></el-input-number>
              </el-form-item>
              <div class="longjt-main-b">~</div>
              <el-form-item
                label="结束名次："
                style="display:inline-block"
                :rules="[
                  {
                    required: true,
                    message: '请输入结束名次',
                    trigger: 'change'
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (rule.KSMC && value < rule.KSMC) {
                        return callback(new $window.Error(rule.message1));
                      } else if (
                        rule.validateData.filter(
                          c =>
                            c.KSMC &&
                            c.JSMC &&
                            value <= c.JSMC &&
                            value >= c.KSMC
                        ).length > 1
                      ) {
                        return callback(new $window.Error(rule.message2));
                      } else return callback();
                    },
                    message1: '不能小于起始名次',
                    message2: '与已有名次冲突',
                    trigger: 'blur',
                    KSMC: item.KSMC,
                    validateData: formData.Ranks
                  }
                ]"
                :prop="'formData.Ranks[' + index + '].JSMC'"
              >
                <el-input-number
                  :disabled="formData.GenerateType === '1'"
                  v-model="item.JSMC"
                  size="medium"
                  style="width: 70px; margin-bottom: 2px;"
                  placeholder="请输入"
                  :controls="false"
                  :precision="0"
                  :min="1"
                  :max="9999"
                ></el-input-number>
              </el-form-item>
              <div class="longjt-main-c"></div>
              <el-form-item
                label="得分："
                style="display:inline-block"
                :rules="[
                  {
                    required: true,
                    message: '请输入得分',
                    trigger: 'change'
                  }
                ]"
                :prop="'formData.Ranks[' + index + '].DHF'"
              >
                <el-input-number
                  :disabled="formData.GenerateType === '1'"
                  v-model="item.DHF"
                  size="medium"
                  style="width: 70px; margin-bottom: 2px;"
                  placeholder="请输入"
                  :controls="false"
                  :precision="0"
                  :min="0"
                  :max="9999"
                ></el-input-number>
              </el-form-item>
              <div class="tab-wz-buttl" style="margin-left: 50px;">
                <a class="tab-wz-butta c-red" @click="delItem(index)">删除</a>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="bigtit-add-box">
          <div class="tab-wz-buttl">
            <a class="tab-wz-butta c-guree" @click="addItem">+ 新增</a>
          </div>
        </div>
      </div>
      <el-form v-else :model="validateObj" ref="ruleForm"></el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      validateObj: {},
      examinationValues: [],
      submitting: false,
      activityPeriodSet: [],
      activityRealTimeSet: [],
      rules: {
        字段名: [
          { required: true, message: "请输入名称", trigger: "change" },
          {
            min: 3,
            max: 5,
            message: "长度在 3 到 5 个字符",
            trigger: "change"
          }
        ]
      },
      formData: {
        GenerateRules: {
          ZXPC: undefined,
          PYL: undefined
        }
      },
      activities: [],
      classSystems: []
    };
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    },
    schoolId: {
      type: String,
      default: ""
    },
    token: {
      type: String,
      default: ""
    }
  },
  methods: {
    placeholder() {},
    init() {
      this.$set(this, "formData", JSON.parse(JSON.stringify(this.data)));
      if (!this.formData.GenerateRules) {
        this.formData.GenerateRules = {};
        this.formData.GenerateRules.ZXPC = undefined;
        this.formData.GenerateRules.PYL = undefined;
      }
      this.activities = (this.formData.GenerateType === "0"
        ? this.formData.ActivitiesPeriod
        : this.formData.ActivitiesRealTime
      )
        .filter(
          c =>
            c.Value || c.ExaminationDatas.filter(item => item.Value).length > 0
        )
        .map(c => c.Id);
      let examination = (this.formData.GenerateType === "0"
        ? this.formData.ActivitiesPeriod
        : this.formData.ActivitiesRealTime
      ).find(c => c.Id === "ScoreManager");
      if (examination) {
        this.examinationValues = examination.ExaminationDatas.filter(
          c => c.Value
        ).map(c => c.ExamType);
      }

      this.classSystems = this.formData.ClassSystems.filter(c => c.Value).map(
        c => c.Id
      );
    },
    changeGenerateType(val) {
      this.activities = (val === "0"
        ? this.formData.ActivitiesPeriod
        : this.formData.ActivitiesRealTime
      )
        .filter(
          c =>
            c.Value || c.ExaminationDatas.filter(item => item.Value).length > 0
        )
        .map(c => c.Id);
      let examination = (this.formData.GenerateType === "0"
        ? this.formData.ActivitiesPeriod
        : this.formData.ActivitiesRealTime
      ).find(c => c.Id === "ScoreManager");
      if (examination) {
        this.examinationValues = examination.ExaminationDatas.filter(
          c => c.Value
        ).map(c => c.ExamType);
      }
    },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeDialog");
      });
      // this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "保存",
      //   cancelButtonText: "放弃修改"
      // })
      //   .then(() => {
      //     this.confirmDialog();
      //   })
      //   .catch(action => {
      //     if (action === "cancel") {
      //       this.$emit("closeDialog");
      //     }
      //   });
    },
    confirmDialog() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submitting = true;
          const submitData = JSON.parse(JSON.stringify(this.formData));

          submitData.Activities = (this.formData.GenerateType === "0"
            ? this.formData.ActivitiesPeriod
            : this.formData.ActivitiesRealTime
          ).filter(c => this.activities.includes(c.Id));
          let index = submitData.Activities.findIndex(
            c => c.Id === "ScoreManager"
          );
          if (index !== -1) {
            let examination = submitData.Activities[index];
            submitData.Activities.splice(index, 1);
            submitData.Activities = submitData.Activities.concat(
              examination.ExaminationDatas.filter(
                c =>
                  this.examinationValues.includes(c.ExamType) &&
                  c.Value &&
                  !isNaN(c.Value)
              ).map(c => ({
                ActivityType: 9,
                Id: "ScoreManager",
                Name: c.ExamName,
                Value: c.Value,
                ExamType: c.ExamType
              }))
            );
          }
          submitData.ClassSystems = submitData.ClassSystems.filter(c =>
            this.classSystems.includes(c.Id)
          );
          this.$api.post(
            "/v1/StudentExchangeArticle/SaveRolesSetData",
            {
              token: this.token,
              SchoolId: this.schoolId,
              ExchangeArticleScoreRole: submitData,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              if (r.Code === 0) {
                this.$emit("closeDialog");
                this.$message({
                  type: "success",
                  message: r.Message
                });
              }
              this.submitting = false;
            },
            f => {
              this.$message({
                type: "error",
                message: f.Message
              });
              this.submitting = false;
            }
          );
        } else {
          return false;
        }
      });
    },
    addItem() {
      this.formData.Ranks.push({});
    },
    delItem(index) {
      this.formData.Ranks.splice(index, 1);
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        // 隐藏
        this.formData = {};
        this.activities = [];
        this.classSystems = [];
        this.formData.GenerateRules = {};
        this.formData.GenerateRules.ZXPC = undefined;
        this.formData.GenerateRules.PYL = undefined;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  right: 50%;
}
div::v-deep input {
  // background-color: #fbfbfb;
  background-color: white;
}
div::v-deep .el-dialog {
  min-width: 775px;
}
.longjt-main-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.longjt-main-b {
  height: unset;
}
.longjt-main-c {
  height: 34px;
}
div::v-deep .el-form-item__content {
  display: inline-block;
}
</style>
