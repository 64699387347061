<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'totalscore' + this.exportType">
					<div :class="'studenticondiv' + this.exportType">
						<img :src="xsinfo.ZP" :class="'studenticon' + this.exportType" v-if="xsinfo.ZP" />
					</div>
					<div :class="'xsinfodiv' + this.exportType">
						<div :class="'xsinfotitle' + this.exportType">
							<font :style="exportType==1?'font-size: 1.6rem':'font-size: 1.3rem'"> {{ xsinfo.XM }}</font>
							<font style="font-size: 1rem;"> {{ xsinfo.BJMC }}</font>
						</div>
						<div :class="'crownback' + this.exportType" v-if="xsinfo.JJDJ&&xsinfo.JJDJ>0">
							<img v-for="i in HGS" :key="i + 'crown'" src="../../../../style/growth/crown.png"
								:class="'staricon' + exportType" />
							<img v-for="i in XXS" :key="i + 'star'" src="../../../../style/growth/star.png"
								:class="'staricon' + exportType" />
						</div>
					</div>
					<div :class="'scoredesc' + this.exportType">
						<span :class="'scoretitle' + this.exportType">
							本期<br />积分
						</span>
						<span :class="'scoredescstr' + this.exportType">
							￥{{ xsinfo.XQJF }}
						</span>
					</div>
					<div class="clboth"></div>
				</div>
				<div class="clboth"></div>
				<div :class="'detailinfo' + this.exportType">
					<div :class="'detailtitle' + this.exportType">
						<img :class="'detailicon' + this.exportType" src="../../../../style/growth/points_icon2.png" />
						我的积分
					</div>
					<el-row :gutter="10" v-if="jflist.length > 0">
						<el-col :span="8" :class="'activityblock' + this.exportType">
							<span :class="'activityscore' + this.exportType">
								{{ jflist[0].JF }}
							</span>
							<span :class="'activityname' + this.exportType">
								{{ jflist[0].Name }}
							</span>
						</el-col>

						<el-col :span="8" :class="'activityblock' + this.exportType" v-if="jflist.length > 1">
							<span :class="'activityscore' + this.exportType">
								{{ jflist[1].JF }}
							</span>
							<span :class="'activityname' + this.exportType">
								{{ jflist[1].Name }}
							</span>
						</el-col>
						<el-col :span="8" :class="'activityblock' + this.exportType" v-if="jflist.length > 2">
							<span :class="'activityscore' + this.exportType">
								{{ jflist[2].JF }}
							</span>
							<span :class="'activityname' + this.exportType">
								{{ jflist[2].Name }}
							</span>
						</el-col>
					</el-row>
					<el-row :gutter="10" v-if="jflist.length > 3">
						<el-col :span="8" :class="'activityblock' + this.exportType">
							<span :class="'activityscore' + this.exportType">
								{{ jflist[3].JF }}
							</span>
							<span :class="'activityname' + this.exportType">
								{{ jflist[3].Name }}
							</span>
						</el-col>
						<el-col :span="8" :class="'activityblock' + this.exportType" v-if="jflist.length > 4">
							<span :class="'activityscore' + this.exportType">
								{{ jflist[4].JF }}
							</span>
							<span :class="'activityname' + this.exportType">
								{{ jflist[4].Name }}
							</span>
						</el-col>
						<el-col :span="8" :class="'activityblock' + this.exportType" v-if="jflist.length > 5">
							<span :class="'activityscore' + this.exportType">
								{{ jflist[5].JF }}
							</span>
							<span :class="'activityname' + this.exportType">
								{{ jflist[5].Name }}
							</span>
						</el-col>
					</el-row>
					<div :class="'detailtitle' + this.exportType" v-if="articlelist.length > 0">
						<img :class="'detailicon' + this.exportType" src="../../../../style/growth/points_icon2.png" />
						我的兑换
					</div>
					<el-row :gutter="10">
						<el-col :span="8" :class="'articleblock' + this.exportType" v-if="articlelist.length > 0">
							<span :class="'articlescore' + this.exportType">
								{{ articlelist[0].Count }}
							</span>
							<span :class="'articlename' + this.exportType">
								{{ articlelist[0].Name }}
							</span>
						</el-col>
						<el-col :span="8" :class="'articleblock' + this.exportType" v-if="articlelist.length > 1">
							<span :class="'articlescore' + this.exportType">
								{{ articlelist[1].Count }}
							</span>
							<span :class="'articlename' + this.exportType">
								{{ articlelist[1].Name }}
							</span>
						</el-col>
						<el-col :span="8" :class="'articleblock' + this.exportType" v-if="articlelist.length > 2">
							<span :class="'articlescore' + this.exportType">
								{{ articlelist[2].Count }}
							</span>
							<span :class="'articlename' + this.exportType">
								{{ articlelist[2].Name }}
							</span>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				exportType: 1,
				backgroudimage: "",
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "积分"
				},
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXTB: "",
					XXBM: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: "",
					XQJF: "",
					JJDJ: ""
				},
				articlelist: [],
				jflist: []
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.xsxx.Id;
				// this.xsinfo.XM = xsdata.xsxx.XM;
				// this.xsinfo.XBM = xsdata.xsxx.XBM;
				// this.xsinfo.ZP = xsdata.xsxx.ZP;
				// this.xsinfo.XQAH = xsdata.xsxx.XQAH;
				// this.xsinfo.SSXX = xsdata.xsxx.SSXX;
				// this.xsinfo.SSXQ = xsdata.xsxx.SSXQ;
				// this.xsinfo.SSXN = xsdata.xsxx.SSXN;
				// this.xsinfo.XQLX = xsdata.xsxx.XQLX;
				// this.xsinfo.BZRMC = xsdata.xsxx.BZRMC;
				// this.xsinfo.NL = xsdata.xsxx.NL;
				// this.xsinfo.XXMC = xsdata.xsxx.XXMC;
				// this.xsinfo.XXTB = xsdata.xsxx.XXTB;
				// this.xsinfo.SSBJ = xsdata.xsxx.SSBJ;
				// this.xsinfo.BJMC = xsdata.xsxx.BJMC;
				// this.xsinfo.XXBM = xsdata.xsxx.XXBM;
				// this.xsinfo.XH = xsdata.xsxx.XH;
				// this.xsinfo.XZ = xsdata.xsxx.XZ;
				if (!this.xsinfo.ZP) {
					this.xsinfo.ZP = this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				// this.info = xsdata.pageData;
				this.xsinfo.XQJF = xsdata.xsjf.JF;
				this.xsinfo.JJDJ = xsdata.xsjf.CrownCount;
				this.articlelist = xsdata.xsjf.ArticleList;
				this.jflist = xsdata.xsjf.JFList;
				// this.getxsjf();
			},
			getxsjf: function() {
				this.$api.post(
					"v1/growthbrochure/GetStudentPoints", {
						SchoolId: this.xsinfo.SSXX,
						TermId: this.xqid,
						XQLX: this.xsinfo.XQLX,
						StudentCode: this.xsinfo.XH,
						TermYearStr: this.xsinfo.SSXN,
						TermType: this.xsinfo.XQLX,
						SchCode: this.xsinfo.XXBM,
						StudentId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var info = r.Target;
							if (info != null && info != undefined) {
								this.xsinfo.XQJF = info.JF;
								this.xsinfo.JJDJ = info.CrownCount;
								this.articlelist = info.ArticleList;
								this.jflist = info.JFList;
							}
						}
					}
				);
			}
		},
		computed: {
			HGS: function() {
				return parseInt(this.xsinfo.JJDJ / 5);
			},
			XXS: function() {
				return parseInt(this.xsinfo.JJDJ % 5);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticondiv1 {
		width: 165px;
		height: 165px;
		text-align: center;
		float: left;
		margin-top: 3rem;
		margin-left: 3rem;
	}

	.studenticondiv2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 3rem;
		margin-left: 2rem;
	}

	.studenticon1 {
		width: 165px;
		height: 165px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/radius_dotted.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/radius_dotted.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.totalscore1 {
		height: 290px;
		width: 756px;
		margin-top: 30px;
		margin-left: 40px;
		background: #15d089;
		border-radius: 25px;
	}

	.totalscore2 {
		height: 188px;
		width: 530px;
		margin-top: 20px;
		margin-left: 30px;
		background: #15d089;
		border-radius: 15px;
	}

	.detailinfo1 {
		width: 756px;
		height: 680px;
		margin-top: -40px;
		margin-left: 40px;
		background: #ffffff;
		border-radius: 25px;
	}

	.detailinfo2 {
		width: 530px;
		height: 510px;
		margin-top: -30px;
		margin-left: 30px;
		background: #ffffff;
		border-radius: 15px;
	}

	.scoretitle1 {
		margin-top: 25px;
		width: 125px;
		height: 22px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
		float: left;
	}

	.scoretitle2 {
		margin-top: 15px;
		width: 80px;
		height: 18px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
		float: left;
	}

	.scoredescstr1 {
		width: 210px;
		height: 34px;
		font-size: 34px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ff2828;
		vertical-align: middle;
		margin-top: -17px;
		float: right;
	}

	.scoredescstr2 {
		width: 135px;
		height: 30px;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ff2828;
		vertical-align: middle;
		margin-top: -15px;
		float: right;
	}

	.scoredesc1 {
		width: 260px;
		height: 112px;
		text-align: center;
		float: right;
		vertical-align: middle;
		margin-top: 5rem;
		margin-right: 2rem;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-image: url("../../../../style/growth/points_cloud.png");
	}

	.scoredesc2 {
		width: 174px;
		height: 75px;
		text-align: center;
		float: right;
		vertical-align: middle;
		margin-top: 3rem;
		margin-right: 2rem;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-image: url("../../../../style/growth/points_cloud.png");
	}

	.xsinfodiv1 {
		margin-top: 80px;
		margin-left: 20px;
		display: table-row;
		float: left;
		width: 165px;
	}

	.xsinfodiv2 {
		margin-top: 50px;
		margin-left: 15px;
		display: table-row;
		float: left;
		width: 180px;
	}

	.crownback1 {
		margin-top: 20px;
		width: 200px;
		height: 30px;
		background-color: darkgreen;
		border-radius: 15px;
		vertical-align: middle;
	}

	.crownback2 {
		margin-top: 10px;
		width: 130px;
		height: 25px;
		background-color: darkgreen;
		border-radius: 10px;
	}

	.staricon1 {
		width: 20px;
		height: 20px;
		margin: 5px 0px 0px 5px;
	}

	.staricon2 {
		width: 15px;
		height: 15px;
		margin: 5px 0px 0px 5px;
	}

	.xsinfotitle1 {
		width: 200px;
		height: 22px;
		font-size: 20px;
		font-weight: bold;
		color: #ffffff;
		font-family: Microsoft YaHei;
	}

	.xsinfotitle2 {
		width: 180px;
		height: 18px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.detailtitle1 {
		width: 756px;
		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #15d089;
		padding: 35px 0 5px 0;
		text-align: center;
		display: block;
	}

	.detailtitle2 {
		width: 530px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #15d089;
		padding: 15px 0 5px 0;
		text-align: center;
		display: block;
	}

	.detailicon1 {
		width: 40px;
		height: 40px;
	}

	.detailicon2 {
		width: 30px;
		height: 30px;
	}

	.activityblock1 {
		width: 242px;
		height: 150px;
		background: linear-gradient(0deg, #c7d9ff, #dae6ff);
		border-radius: 20px;
		vertical-align: middle;
		text-align: center;
		margin-top: 10px;
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.activityblock2 {
		width: 168px;
		height: 108px;
		background: linear-gradient(0deg, #c7d9ff, #dae6ff);
		border-radius: 20px;
		vertical-align: middle;
		text-align: center;
		margin-top: 10px;
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.activityscore1 {
		width: 172px;
		height: 70px;
		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		line-height: 70px;
	}

	.activityscore2 {
		width: 172px;
		height: 50px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		line-height: 50px;
	}

	.activityname1 {
		width: 172px;
		height: 40px;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #080808;
		line-height: 40px;
	}

	.activityname2 {
		width: 172px;
		height: 21px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #080808;
		line-height: 21px;
	}

	.articleblock1 {
		width: 242px;
		height: 150px;
		background: linear-gradient(0deg, #fde991, #fff5c9);
		border-radius: 20px;
		vertical-align: middle;
		text-align: center;
		margin-top: 10px;
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.articleblock2 {
		width: 168px;
		height: 108px;
		background: linear-gradient(0deg, #fde991, #fff5c9);
		border-radius: 20px;
		vertical-align: middle;
		text-align: center;
		margin-top: 10px;
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.articlescore1 {
		width: 172px;
		height: 70px;
		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		line-height: 70px;
	}

	.articlescore2 {
		width: 172px;
		height: 50px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		line-height: 50px;
	}

	.articlename1 {
		width: 172px;
		height: 40px;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #080808;
		line-height: 40px;
	}

	.articlename2 {
		width: 160px;
		height: 24px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #080808;
		line-height: 24px;
	}
</style>