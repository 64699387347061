<template>
  <div class="block">
    <el-pagination
      @current-change="handleCurrentChange"
      background
      layout="total,prev, pager, next,jumper"
      :total="total != undefined ? total : pagerInfo.totalCount"
      :page-size="pagerInfo.pageSize || pagerInfo.PageSize"
      :hide-on-single-page="hasHide"
      :current-page.sync="pagerInfo.pageIndex"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    pagerInfo: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      default: undefined,
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("changeIndex", val);
    },
  },
  computed: {
    hasHide() {
      return this.total != undefined
        ? this.total === 0
        : this.pagerInfo.totalCount === 0;
    },
  },
};
</script>
