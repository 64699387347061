<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->
      <div class="margno-t">
        <div class="table-er-box">
          <el-button @click="opendialog">新增活动</el-button>
          <!-- <el-button icon="el-icon-cloudy">导入共享活动</el-button> -->
          <el-input
            v-model="searchStr"
            style="width:200px;margin-left:10px"
            placeholder="关键字搜索"
          ></el-input>
          <el-button style="margin-left:10px" type="primary" @click="search"
            >搜索</el-button
          >
        </div>
        <Table
          :tableData="tableData"
          @editItem="editItem"
          @showItem="showItem"
          @setItemObect="setItemObect"
          @delItem="getTableData"
          :user="user"
        />
        <!--页码开始-->
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
        <!--页码结束-->
      </div>
      <!--表格区结束-->
    </div>
    <!--内容区结束-->
    <formdialog
      :dialogTitle="dialogTitle"
      :formData="formData"
      :dialogVisible="dialogVisible"
      :baseInfo="baseInfo"
      :allObject="allObject"
      @canceldialog="canceldialog"
      @sumbitForm="sumbitForm"
    />
    <activitydialog
      :formData="formData"
      v-model="activityDialogVisible"
      :baseInfo="baseInfo"
      :allObject="allObject"
    />
    <!-- <ObjectSet
      :visible="objectSetVisible"
      :schoolId="SchoolId"
      :allObject="allObject"
      :user="user"
      :evaluationObjectJoinType="evaluationObjectJoinType"
      :PJFS="currentPJFS"
      :activityId="currentActivityId"
      :hdxs="activityHDXS"
      :JZKBLX="currentJZKBLX"
      :evaluationObjectList="currentObjectList"
      @closeObjectSetDialog="closeObjectSetDialog"
      @saveObjectSetDialog="saveObjectSetDialog"
    /> -->
    <ObjectSet
      :dialogVisible="objectSetVisible"
      :dialogTitle="dialogTitle"
      :type="objecType"
      :activityHDXS="activityHDXS"
      :activityId="currentActivityId"
      @closeObjectDialog="
        objectSetVisible = false
        activityId = ''
      "
      @refresh="getTableData(searchStr)"
    />
    <ActivityManager
      :visible.sync="activityManager.visible"
      @closeActivityManager="activityManager.visible = false"
    />
    <EvaluationTime
      :visible.sync="evaluationTime.visible"
      @closeEvaluationTime="evaluationTime.visible = false"
    />

    <SetIndex
      :title="setIndex.title"
      :treeData="setIndex.data"
      :checkedData="setIndex.checkedData"
      :visible.sync="setIndex.visible"
      :evaluationObj="setIndex.evaluationObj"
      :setIndexType="setIndex.type"
      @canceletIndex="cancelIndex"
      @saveSetIndex="saveIndex"
    />
  </div>
</template>

<script>
import Pager from '@/components/Pager.vue'
import Table from '@/views/manager/evaluationactivityset/Table.vue'
import formdialog from '@/views/manager/evaluationactivityset/FormDialog.vue'
import activitydialog from '@/views/manager/evaluationactivityset/ActivityDialog.vue'
// import ObjectSet from "@/views/manager/evaluationactivityset/ObjectSet.vue";
import ObjectSet from '@/views/manager/evaluationactivityset/evaluationobjectset/Index.vue'
// import testdialog from "@/views/manager/evaluationactivityset/testForm.vue";
import ActivityManager from '@/views/manager/evaluationactivityset/activityManager/Index.vue'
import EvaluationTime from '@/views/manager/evaluationactivityset/evaluationTime/Index.vue'
import SetIndex from '@/views/manager/evaluationactivityset/evaluationobjectset/SetIndex.vue'
export default {
  components: {
    formdialog,
    Table,
    Pager,
    ObjectSet,
    activitydialog,
    ActivityManager,
    EvaluationTime,
    SetIndex
  },
  provide() {
    return {
      payLoad: {
        currentActivity: {}, //当前点击的活动
        type: 0 //当前点击的评价类型按钮
      }
    }
  },
  data() {
    return {
      currentPJFS: 0,
      activityHDXS: 0,
      allObject: [],
      baseInfo: {},
      tableData: [],
      formData: {},
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      dialogVisible: false,
      activityDialogVisible: false,
      dialogTitle: '新增活动',
      SchoolId: '',
      CJR: '',
      objectSetVisible: false,
      user: {},
      evaluationObjectJoinType: [],
      currentActivityId: '',
      currentObjectList: [],
      currentJZKBLX: 1,
      searchStr: '',
      objecType: '', //新增对象的类型
      activityManager: {
        //活动管理员相关
        visible: false
      },
      evaluationTime: {
        // 评价时间相关
        visible: false
      },
      setIndex: {
        //指标相关
        title: '',
        data: [],
        checkedData: [],
        visible: false,
        evaluationObj: {},
        type: 1
      }
    }
  },
  methods: {
    editItem(data) {
      this.formData = JSON.parse(JSON.stringify(data))
      this.formData.hdxsarry = []
      this.formData.btnList = []
      this.dialogTitle = '编辑活动'
      this.dialogVisible = true
    },
    showItem(data) {
      this.formData = JSON.parse(JSON.stringify(data))
      this.formData.hdxsarry = []
      this.formData.btnList = []
      this.dialogTitle = ''
      this.activityDialogVisible = true
    },
    getBaseInfo() {
      this.$api.post(
        '/v1/evaluationactivity/GetEvaluationActivityFormBasicInformation',
        {
          token: this.$store.state.token,
          SchoolId: this.SchoolId
        },
        resp => {
          var data = resp.Target
          // 删除自定义小组
          data.GroupParticipationTypes = data.GroupParticipationTypes.filter(
            item => item.Key !== 3
          )
          this.baseInfo = data
          this.getTableData()
          this.getObjectBaseData()
        }
      )
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val
      this.getTableData()
    },
    search() {
      this.pagerInfo.pageIndex = 1
      this.getTableData()
    },
    getTableData() {
      this.$api.post(
        '/v1/evaluationactivity/GetEvaluationActivityList',
        {
          token: this.$store.state.token,
          SchoolId: this.SchoolId,
          SearchStr: this.searchStr,
          pagerInfo: this.pagerInfo
        },
        resp => {
          var data = resp.Target
          this.pagerInfo.totalCount = resp.PagerInfo.RowCount
          this.tableData = data
          console.log(data)
        }
      )
    },
    canceldialog() {
      this.dialogVisible = false
    },
    opendialog() {
      this.dialogTitle = '新增活动'
      this.formData = {}
      this.formData.hdxsarry = []
      this.formData.btnList = []
      this.formData.EvaluationActivityLevelSetList = []
      this.formData.EvaluationActivityCommentTemplateList = []
      this.formData.reportList = []
      this.dialogVisible = true
    },
    sumbitForm(data) {
      data.CJR = this.user.Id
      data.SSXX = this.SchoolId
      this.$api.post(
        '/v1/evaluationactivity/EditEvaluationActivity',
        {
          token: this.$store.state.token,
          EvaluationActivityInfo: data
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: 'success',
              message: r.Message
            })
            this.dialogVisible = false
            this.getTableData()
          } else {
            this.$message.error(r.Message)
          }
        },
        err => {
          this.$message.error(err.Message)
        }
      )
    },
    async setItemObect({ data, type }) {
      this.currentPJFS = data.PJFS
      this.dialogTitle = '对象列表'
      this.currentActivityId = data.Id
      this.activityHDXS = data.HDXS
      this.currentJZKBLX = data.JZKBLX
      this._provided.payLoad.currentActivity = data
      this._provided.payLoad.type = type
      switch (type) {
        case 10: //设置管理员
          this.activityManager.visible = true
          break
        case 4: //主评
          this.objectSetVisible = true
          break
        case 2: //互评
          this.objectSetVisible = true
          break
        case 1: //自评
          this.objectSetVisible = true
          break
        case 12: //评价时间
          this.evaluationTime.visible = true
          break
        case 11: //指标
          this.setIndex.visible = true
          this.setIndex.evaluationObj =
            (data &&
              data.EvaluationObjectList &&
              data.EvaluationObjectList[0]) ||
            {}
          this.setIndex.checkedData =
            data.Target &&
            data.Target.reduce((pre, cur) => {
              return pre.concat(cur.Id)
            }, [])
          switch (data.PJFS) {
            case 1:
              this.setIndex.title = '设置通用指标'
              this.setIndex.type = 1
              break
            case 2:
              this.setIndex.title = '设置星星指标'
              this.setIndex.type = 2
              break
            case 3:
              this.setIndex.title = '设置问卷'
              this.setIndex.type = 3
              break
          }
          this.getIndexData()
          break
      }
    },
    closeObjectSetDialog() {
      this.objectSetVisible = false
    },
    saveObjectSetDialog() {
      this.objectSetVisible = false
      this.getTableData()
    },
    getAllObject() {
      this.$api.post(
        '/v1/evaluationobject/GetEvaluationObjectList',
        {
          token: this.$store.state.token,
          SchoolId: this.SchoolId
        },
        r => {
          if (r.Code === 0) {
            this.allObject = r.Target
          }
        }
      )
    },
    getObjectBaseData() {
      this.$api.post(
        '/v1/evaluationobject/GetEvaluationObjectBaseInfo',
        {
          token: this.$store.state.token,
          SchoolId: this.SchoolId
        },
        r => {
          if (r.Code === 0) {
            this.evaluationObjectJoinType = r.Target.EvaluationObjectJoinType
          }
        }
      )
    },
    // 获取指标tree
    getIndexData() {
      let url = ''
      switch (this.setIndex.type) {
        case 1:
          url = '/v1/schoolindex/GetTreeAllData'
          break
        case 2:
          url = '/v1/starsindex/GetStarsIndex'
          break
        case 3:
          url = '/v1/questionnaire/GetQuestionnaireList'
          break
      }
      this.$api.post(
        url,
        {
          token: this.user.Token,
          SchoolId: this.user.SSZZId
        },
        r => {
          if (r.Code === 0) {
            if (this.setIndex.type === 3) {
              this.setIndex.data = r.Target.filter(c => c.SYZT === 1).map(
                item => {
                  return {
                    value: item.Id,
                    parentCode: '',
                    label: item.WJMC,
                    children: []
                  }
                }
              )
            } else this.setIndex.data = r.Target
          }
        }
      )
    },
    //退出指标选择
    cancelIndex() {
      this.setIndex.visible = false
    },
    saveIndex(indexList) {
      //保存指标选择
      this.setIndex.visible = false
      const dataList = []
      const newindexData = []
      for (const index of indexList) {
        dataList.push({
          ZBId: index.value,
          PJCYDXId: this.setIndex.evaluationObj.PJCYDXId
        })
        newindexData.push(index.value)
      }
      const user = JSON.parse(localStorage.getItem('userinfo'))
      this.$api.post(
        '/v1/evaluationobject/SetIndexForEvaluationObject',
        {
          token: user.Token,
          SetIndexList: dataList,
          UserId: user.Id,
          UserName: user.XSM,
          IndexType: this.setIndex.type,
          SchoolId: this.SchoolId
        },
        r => {
          if (r.Code === 0) {
            this.getTableData()
            this.$message({
              type: 'success',
              message: r.Message
            })
          }
        }
      )
    }
  },
  created: function() {
    const user = JSON.parse(localStorage.getItem('userinfo'))
    this.user = user
    console.log(user, 'user')
    if (user !== undefined && user !== '' && user !== null) {
      this.user = user
      if (user.SSZZId !== undefined && user.SSZZId !== null) {
        console.log(1212, '121212')
        this.SchoolId = user.SSZZId
      }
      this.CJR = user.XSM
    }
    this.getBaseInfo()
  }
}
</script>
