<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="essay-name-box">{{ hdmc }}-{{ subname }}</div>
    </div>
    <div class="tab-heng-box margno-t">
      <div class="table-er-box" style="float: right;">
        <div class="table-er-butt" @click="downloadclassreportdetail()">
          <span class="table-er-buttspan c-blue">下载</span>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>调查项</td>
            <td>平均得分</td>
            <td v-for="da in dalist" :key="da.DAId">{{ da.DAMC }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="rt in reportlist" :key="rt.TMId">
            <td>{{ rt.TMXH }}.{{ rt.TMMC }}</td>
            <td>{{ rt.Score }}</td>
            <td v-for="da in rt.DAList" :key="da.DAId">
              <span
                v-if="da.WZSR == 1 && da.AnswerCount > 0"
                class="c-blue"
                @click="
                  handleTextDetailReport(rt.TMId, da.DAId, rt.TMMC, rt.TMXH)
                "
                >{{ da.AnswerCount }}人</span
              >
              <span v-else>{{ da.AnswerCount }}人</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style></style>
<script>
export default {
  created() {
    this.ssxq = this.$route.params.xqid;
    var tempuser = localStorage.getItem("userinfo");
    this.dcid = this.$route.params.id;
    this.bjid = this.$route.params.bjid;
    this.hdmc = localStorage.getItem("surveyname");
    this.subname = localStorage.getItem("classsurveysubname");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
  },
  computed: {},
  mounted: function() {
    this.loaddata();
  },
  methods: {
    downloadclassreportdetail: function() {
      this.$api.post(
        "v1/survey/DownLoadClassReportDetail",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          BJId: this.bjid,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            window.open(r.Message, "_blank");
          } else {
            this.$message({
              type: "info",
              message: r.Message
            });
          }
        }
      );
    },
    handleTextDetailReport: function(tmid, daid, tmmc, tmxh) {
      localStorage.setItem("textsurveysubname", this.subname);
      localStorage.setItem("surveypjx", tmxh + "." + tmmc);
      this.$router.push({
        name: "reportdetailtext",
        params: {
          id: this.dcid,
          tmid: tmid,
          daid: daid,
          sslb: 2,
          atid: this.bjid,
          xqid: this.ssxq
        }
      });
    },
    loaddata: function() {
      this.$api.post(
        "v1/survey/GetClassReportDetail",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          BJId: this.bjid,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.reportlist = [];
            this.reportlist = r.Target;
            if (this.reportlist.length > 0) {
              this.dalist = this.reportlist[0].DAList;
            }
          }
        }
      );
    }
  },
  data() {
    return {
      ssxx: "",
      ssxq: "",
      token: "",
      hdmc: "",
      subname: "",
      reportlist: [],
      dalist: [],
      dcid: "",
      bjid: ""
    };
  }
};
</script>
