<template>
  <el-dialog
    title="展示设置"
    :visible.sync="show"
    custom-class="ZSSZ-dialog"
    width="90%"
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-form ref="form" class="form" :model="form" label-width="100px">
      <!-- 横向展示 -->
      <div class="HXZS">
        <el-form-item label="" prop="HXZS">
          <el-radio
            v-model="form.HXZS"
            :label="1"
            @change="mainRadioChange($event, 0)"
            >横向展示</el-radio
          >
        </el-form-item>
        <div class="MSY">
          <!-- 自定义描述语 -->
          <div class="customMSY">
            <el-form-item prop="ZDYMSY">
              <el-radio
                v-model="form.ZDYMSY"
                :label="1"
                :disabled="form.ZXZS === 2"
                @change="HXchildChange($event, 1)"
                >自定义描述语(在表格最后新增一行来显示)</el-radio
              >
            </el-form-item>
            <el-form-item class="MSY1" prop="MSY1">
              <el-input
                type="textarea"
                :rows="2"
                :disabled="form.ZXZS === 2"
                placeholder="请输入内容"
                v-model="form.MSY1"
              >
              </el-input>
            </el-form-item>
          </div>
          <!-- 字段描述语 -->
          <div class="ZDMSY">
            <el-form-item prop="ZDMSY">
              <el-radio
                v-model="form.ZDMSY"
                :label="1"
                :disabled="form.ZXZS === 2"
                @change="HXchildChange($event, 2)"
                >使用字段等级描述展示，描述语之间用逗号分隔;星数展示时取其指标的描述语（仅单选指标时有效）</el-radio
              >
            </el-form-item>
            <el-form-item class="MSY2" prop="MSY2" label="展示前缀">
              <el-input
                type="textarea"
                :rows="2"
                :disabled="form.ZXZS === 2"
                placeholder="请输入内容"
                v-model="form.MSY2"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="SYMK1" prop="SYMK1">
              <el-checkbox v-model="form.SYMK1" :disabled="form.ZXZS === 2"
                >使用模块等级描述来做后缀显示</el-checkbox
              >
            </el-form-item>
          </div>
          <!-- 统计数据描述语 -->
          <div class="TJSJMSY">
            <el-form-item prop="TJSJMSY">
              <el-radio
                v-model="form.TJSJMSY"
                :label="1"
                :disabled="form.ZXZS === 2"
                @change="HXchildChange($event, 3)"
                >使用统计数据展示（统计该学科的得到的末级评价指标，只取前五条数据）</el-radio
              >
            </el-form-item>
            <el-form-item class="MSY3" prop="MSY3" label="展示前缀">
              <el-input
                type="textarea"
                :rows="2"
                :disabled="form.ZXZS === 2"
                placeholder="请输入内容"
                v-model="form.MSY3"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="SYMK2" prop="SYMK2">
              <el-checkbox v-model="form.SYMK2" :disabled="form.ZXZS === 2"
                >使用模块等级描述来做后缀显示</el-checkbox
              >
            </el-form-item>
          </div>
        </div>
      </div>
      <!-- 纵向展示 -->
      <div class="ZXZS">
        <el-form-item prop="ZXZS">
          <el-radio
            v-model="form.ZXZS"
            :label="2"
            @change="mainRadioChange($event, 1)"
            >纵向展示</el-radio
          >
        </el-form-item>
        <div class="MSY">
          <!-- 等级描述语 -->
          <div class="DJMSY">
            <el-form-item class="DJMSY-checkbox" prop="DJMSY">
              <el-radio
                v-model="form.DJMSY"
                :disabled="form.HXZS === 1"
                :label="1"
                >展示字段等级描述（新增一列展示等级描述）;星数展示时取其指标的描述语（仅单选指标时有效）</el-radio
              >
              <el-radio
                v-model="form.DJMSY"
                :disabled="form.HXZS === 1"
                :label="2"
                >展示问卷问题及获得等级</el-radio
              >
            </el-form-item>
            <div class="WJXQ-block" ref="WJXQblock">
              <el-form-item class="WJXQ" prop="WJXQ">
                <el-checkbox v-model="form.WJXQ" :disabled="form.HXZS === 1"
                  >展示问卷详情（用第4列展示答案详情）</el-checkbox
                >
              </el-form-item>
            </div>
          </div>
        </div>
      </div>

      <!-- 计入占比 -->
      <div class="ZB">
        <div class="label">计入占比：</div>
        <div class="ZB-content">
          <el-form-item prop="ZPZB" label="主评占比：">
            <el-input-number
              v-model="form.ZPZB"
              size="small"
              :precision="0"
              :min="0"
              :max="100"
            />%
          </el-form-item>
          <el-form-item prop="ZJZB" label="自评占比：">
            <el-input-number
              v-model="form.ZJZB"
              size="small"
              :precision="0"
              :min="0"
              :max="100"
            />%
          </el-form-item>
          <el-form-item prop="HPZB" label="互评占比：">
            <el-input-number
              v-model="form.HPZB"
              size="small"
              :precision="0"
              :min="0"
              :max="100"
            />%
          </el-form-item>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show', false)"
        >取 消</el-button
      >
      <el-button type="primary" size="small" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddShowModuleSet } from "@/api/growthReportSet.js";

export default {
  props: {
    show: [Boolean],
    fatherData: [Object],
    XKFZ: [Object]
  },
  data() {
    return {
      // 本地回显需要
      form: {
        HXZS: 1, //横向展示
        ZDYMSY: "", //自定义描述语
        MSY1: "", //描述语1
        ZDMSY: "", //字段描述语
        MSY2: "", //描述语2
        SYMK1: "", //使用模块1
        TJSJMSY: "", //统计数据描述语
        MSY3: "", //描述语3
        SYMK2: "", //使用模块2
        //-------------------------------------
        ZXZS: "", //纵向展示
        DJMSY: "", //等级描述语
        WJXQ: "", //问卷详情
        //--------------------------------------
        ZPZB: 100, //主评占比
        ZJZB: 0, //自评占比
        HPZB: 0 //互评占比
      },
      // 传参需要
      form1: {
        ZSMKId: "",
        ZSFS: 0, //展示方式(1横向,//2纵向)
        ZSLX: 0,
        /*
        展示类型 1自定义描述语 2使用字段等级描述展示，
        描述语之间用逗号分隔 3使用统计数据展示 4展示字段等级描述 5展示问卷问题及获得等级
        */
        ZDYMSY: "string", //自定义描述语
        SFQY: 0, //是否启用(0未启用,//1启用)
        ZPZB: 0, //主评占比
        ZJZB: 0, //自评占比
        HPZB: 0 //互评占比
      }
    };
  },
  methods: {
    init() {
      let info = this.fatherData.ZSSZInfo;
      console.log(info, "info");
      if (info && info.Id) {
        info.ZSFS === 1 ? (this.form.HXZS = 1) : (this.form.ZXZS = 2);
        switch (info.ZSLX) {
          case 1:
            this.form.ZDYMSY = 1;
            this.form.MSY1 = info.ZDYMSY;

            break;
          case 2:
            this.form.ZDMSY = 1;
            this.form.MSY2 = info.ZDYMSY;
            this.form.SYMK1 = info.SFQY ? true : false;
            break;
          case 3:
            this.form.TJSJMSY = 1;
            this.form.MSY3 = info.ZDYMSY;
            this.form.SYMK2 = info.SFQY ? true : false;
            break;
          case 4:
            this.form.DJMSY = 1;
            this.form.WJXQ = info.SFQY ? true : false;
            break;
          case 5:
            this.form.WJXQ = info.SFQY ? true : false;
            this.form.DJMSY = 2;
        }

        this.form.ZPZB = info.ZPZB || 0;
        this.form.ZJZB = info.ZJZB || 0;
        this.form.HPZB = info.HPZB || 0;
      }
    },
    // 横向纵向redio变化 二选一
    mainRadioChange(val, type) {
      if (type) {
        // 纵向展示
        val ? (this.form.HXZS = "") : "";
      } else {
        // 横向展示
        val ? (this.form.ZXZS = "") : "";
      }
    },
    // 横向子radio变化 三选一
    HXchildChange(val, type) {
      if (type === 1) {
        // 自定义描述语
        if (val) {
          this.form.ZDMSY = "";
          this.form.TJSJMSY = "";
        }
      } else if (type === 2) {
        // 字段描述语
        if (val) {
          this.form.ZDYMSY = "";
          this.form.TJSJMSY = "";
        }
      } else {
        // 统计描述语
        if (val) {
          this.form.ZDYMSY = "";
          this.form.ZDMSY = "";
        }
      }
    },
    // 提交
    async submit() {
      if (this.form.HXZS) {
        this.form1.ZSFS = 1;
        if (this.form.ZDYMSY) {
          this.form1.ZSLX = 1;
          this.form1.ZDYMSY = this.form.MSY1;
        }
        if (this.form.ZDMSY) {
          this.form1.ZSLX = 2;
          this.form1.ZDYMSY = this.form.MSY2;
          this.form1.SFQY = this.form.SYMK1 ? 1 : 0;
        }
        if (this.form.TJSJMSY) {
          this.form1.ZSLX = 3;
          this.form1.ZDYMSY = this.form.MSY3;
          this.form1.SFQY = this.form.SYMK2 ? 1 : 0;
        }
      } else {
        this.form1.ZSFS = 2;
        this.form1.ZSLX = this.form.DJMSY ? this.form1.ZSLX : 0;
        this.form1.SFQY = this.form.WJXQ ? 1 : 0;
      }
      this.form1.ZPZB =
        this.form.ZPZB !== "" && this.form.ZPZB !== undefined
          ? this.form.ZPZB
          : 0;
      this.form1.ZJZB =
        this.form.ZJZB !== "" && this.form.ZJZB !== undefined
          ? this.form.ZJZB
          : 0;
      this.form1.HPZB =
        this.form.HPZB !== "" && this.form.HPZB !== undefined
          ? this.form.HPZB
          : 0;

      this.form1.Id = this.fatherData.Id;
      await AddShowModuleSet(this.form1);
      this.$message.success("操作成功");
      this.$emit("update:show", false);
      this.$emit("refresh");
    }
  },
  watch: {
    show(val) {
      if (val) {
        if (this.fatherData.zdList && this.fatherData.zdList.length > 0) {
          let res = "";
          // 空数组调用every方法会返回true
          res = this.fatherData.zdList.every(item => {
            return item.ZSMKGLZBInfo.every(it => it.ZBLX === 3);
          });
          this.$nextTick(() => {
            this.$refs.WJXQblock.style.display = res ? "block" : "none";
          });
        } else {
          this.$nextTick(() => {
            this.$refs.WJXQblock.style.display = "none";
          });
        }

        this.init();
      } else {
        this.form = {
          HXZS: 1, //横向展示
          ZDYMSY: "", //自定义描述语
          MSY1: "", //描述语1
          ZDMSY: "", //字段描述语
          MSY2: "", //描述语2
          SYMK1: "", //使用模块1
          TJSJMSY: "", //统计数据描述语
          MSY3: "", //描述语3
          SYMK2: "", //使用模块2
          //-------------------------------------
          ZXZS: "", //纵向展示
          DJMSY: "", //等级描述语
          WJXQ: "", //问卷详情
          //--------------------------------------
          ZPZB: 100, //主评占比
          ZJZB: 0, //自评占比
          HPZB: 0 //互评占比
        };
      }
    }
  }
};
</script>

<style lang="scss">
.ZSSZ-dialog {
  box-sizing: border-box;
  min-width: 760px;
  .el-dialog__body {
    .form {
      .HXZS {
        .MSY {
          .customMSY {
            padding-left: 20px;
            .MSY1 {
              padding-left: 10px;
              width: 600px;
            }
          }
          .ZDMSY {
            padding-left: 20px;
            .MSY2 {
              padding-left: 100px;
              width: 600px;
            }
            .SYMK1 {
              padding-left: 34px;
            }
          }
          .TJSJMSY {
            padding-left: 20px;
            .MSY3 {
              padding-left: 100px;
              width: 600px;
            }
            .SYMK2 {
              padding-left: 32px;
            }
          }
        }
      }
      .ZXZS {
        .MSY {
          padding-left: 20px;
        }
        .DJMSY-checkbox {
          .el-radio {
            display: block;
          }
          .el-radio:first-child {
            margin-bottom: 15px;
          }
        }
        .WJXQ {
          padding-left: 20px;
        }
      }
      .ZB {
        .label {
          margin-bottom: 15px;
        }
        .ZB-content {
          display: flex;
          align-items: center;
          padding-left: 15px;
          .el-form-item {
            .el-input-number {
              margin-right: 5px;
              .el-input-number__decrease {
                display: none;
              }
              .el-input-number__increase {
                display: none;
              }
              .el-input__inner {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
