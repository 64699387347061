<template>
  <!-- 设置项目/子项目弹窗 -->
  <el-dialog
    custom-class="XM_Dialog"
    :title="title"
    :visible="dialogShow"
    width="50%"
  >
    <el-form
      :model="XM_Form"
      ref="XM_Form"
      :rules="XM_Rules"
      label-width="100px"
      class="XM_Form"
    >
      <el-form-item label="排序号">
        <el-input-number v-model="XM_Form.PXH" :min="0" :precision="0" />
      </el-form-item>
      <el-form-item label="项目名称" prop="XMMC">
        <el-input style="width:180px" v-model="XM_Form.XMMC" />
      </el-form-item>
      <el-form-item label="占比" prop="ZB">
        <el-input-number
          :precision="2"
          :min="0"
          :max="100"
          v-model="XM_Form.ZB"
        />%
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogShow = false">取 消</el-button>
      <el-button type="primary" @click="XM_Submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["show", "title", "form"],
  data() {
    return {
      XM_Form: {
        PXH: 0,
        XMMC: "",
        ZB: 0,
        HD: [],
      },
      XM_Rules: {
        XMMC: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
    };
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("XM_Submit");
      },
    },
  },
  methods: {
    // 保存项目
    XM_Submit() {
      this.$emit("XM_Submit", this.XM_Form);
    },
  },
  watch: {
    form(val) {
      this.XM_Form = JSON.parse(JSON.stringify(val));
    },
    show(val) {
      if (!val) {
        this.$nextTick(() => {
          this.XM_Form.XMMC = "";
          this.XM_Form.PXH = "";
          this.XM_Form.ZB = "";
          this.$refs.XM_Form.resetFields();
        });
      }
    },
  },
};
</script>

<style></style>
