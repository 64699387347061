<template>
  <div class="cont-whole-box" style="text-align: center;">
    <el-form
      :model="healthcheckinfo"
      style="width: 100%;  display: inline-block;"
      ref="sbmitfrom"
    >
      <el-form-item>
        <el-col :span="6" style="margin-left:0px;">
          <el-form-item label-width="100px" label="姓名：">
            {{ healthcheckinfo.XSXM }}
          </el-form-item>
        </el-col>
        <el-col :span="6" style="margin-left:0px;">
          <el-form-item label-width="100px" label="性别：">
            <span v-if="healthcheckinfo.XBM == '1'">
              男
            </span>
            <span v-else>
              女
            </span>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="学号：">
            {{ healthcheckinfo.XJH }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="年龄：">
            {{ healthcheckinfo.NL }}
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="身高：" prop="SG">
            <el-input-number
              style="width:140px;"
              size="medium"
              :precision="2"
              :max="300"
              :step="10"
              :min="0"
              v-model="healthcheckinfo.SG"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="体重：" prop="TZ">
            <el-input-number
              style="width:140px;"
              size="medium"
              :precision="2"
              :max="300"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.TZ"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="肺活量：" prop="FHL">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="10000"
              :step="100"
              :min="0"
              v-model="healthcheckinfo.FHL"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="收缩压：" prop="SSY">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="200"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.SSY"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="舒张压：" prop="SZY">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="300"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.SZY"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="血红蛋白：" prop="XHDB">
            <el-input
              style="width:140px;"
              size="small"
              maxlength="50"
              v-model="healthcheckinfo.XHDB"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="左眼视力：" prop="ZYSL">
            <el-input-number
              style="width:140px;"
              size="medium"
              :precision="1"
              :max="5.2"
              :step="0.1"
              :min="0"
              v-model="healthcheckinfo.ZYSL"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="右眼视力：" prop="YYSL">
            <el-input-number
              style="width:140px;"
              size="medium"
              :precision="1"
              :max="5.2"
              :step="0.1"
              :min="0"
              v-model="healthcheckinfo.YYSL"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="结膜炎：" prop="JMY">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="300"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.JMY"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="左眼沙眼：" prop="ZYSY">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="300"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.ZYSY"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="右眼沙眼：" prop="YYSY">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="300"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.YYSY"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="色觉：" prop="SJ">
            <el-input
              style="width:140px;"
              size="small"
              maxlength="50"
              v-model="healthcheckinfo.SJ"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="眼病：" prop="YB">
            <el-input
              style="width:140px;"
              size="small"
              maxlength="50"
              v-model="healthcheckinfo.YB"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="龋失补：" prop="QSB">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="20"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.QSB"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="乳龋患：" prop="RQH">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="20"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.RQH"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="乳龋失：" prop="RQS">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="20"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.RQS"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="乳龋补：" prop="RQB">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="20"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.RQB"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="恒龋患：" prop="HQH">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="20"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.HQH"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="恒龋失：" prop="HQS">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="20"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.HQS"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="恒龋补：" prop="HQB">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="20"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.HQB"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="牙周：" prop="YZ">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="100"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.YZ"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="牙龈：" prop="YY">
            <el-input
              style="width:140px;"
              size="small"
              maxlength="50"
              v-model="healthcheckinfo.YY"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="心：" prop="Xin">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="10000"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.Xin"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="肺：" prop="Fei">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="10000"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.Fei"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="肝：" prop="Gan">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="10000"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.Gan"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="脾：" prop="Pi">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="10000"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.Pi"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="腹部：" prop="FB">
            <el-input
              style="width:140px;"
              size="small"
              maxlength="50"
              v-model="healthcheckinfo.FB"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="头部：" prop="TB">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="200"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.TB"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="颈部：" prop="JB">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="200"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.JB"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="脊柱：" prop="JZ">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="200"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.JZ"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="四肢：" prop="SZ">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="200"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.SZ"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="100px" label="皮肤：" prop="PF">
            <el-input-number
              style="width:140px;"
              size="medium"
              :max="200"
              :step="1"
              :min="0"
              v-model="healthcheckinfo.PF"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="6">
          <el-form-item label-width="100px" label="甲状腺：" prop="JZX">
            <el-input
              style="width:140px;"
              size="small"
              maxlength="50"
              v-model="healthcheckinfo.JZX"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <br />
      <el-form-item>
        <el-button type="primary" @click="submitshowdata" :disabled="isDisabled"
          >保存</el-button
        >
        <el-button @click="canceladd">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  created() {
    var tempuser = localStorage.getItem("userinfo");
    this.healthcheckinfo.XSId = this.$route.params.xsid;
    this.ssxq = this.$route.params.xqid;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    // 取消
    canceladd: function() {
      this.$router.push({ path: "/HealthCheck" });
    },
    submitshowdata: function() {
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/physicalquality/SaveHealthCheck",
            {
              DataInfo: this.healthcheckinfo,
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              CJR: this.cjr,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!"
                });
                this.isDisabled = false;
                this.$router.push({ path: "/HealthCheck" });
              } else {
                this.$message({
                  type: "info",
                  message: "保存失败!"
                });
                this.isDisabled = false;
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    loaddata: function() {
      if (
        this.healthcheckinfo.XSId !== undefined &&
        this.healthcheckinfo.XSId !== "" &&
        this.ssxq !== ""
      ) {
        this.$api.post(
          "v1/physicalquality/GetHealthCheck",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            XSId: this.healthcheckinfo.XSId,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              for (let i in r.Target || {}) {
                if (i === "JMY" && r.Target[i] === "无") r.Target[i] = "";
                if (i === "YYSY" && r.Target[i] === "无") r.Target[i] = "";
                if (i === "ZYSY" && r.Target[i] === "无") r.Target[i] = "";
              }
              this.healthcheckinfo = r.Target;
            }
          }
        );
      }
    }
  },
  data() {
    return {
      isDisabled: false,
      ssxx: "",
      ssxq: "",
      token: "",
      cjr: "",
      healthcheckinfo: {
        XSId: "",
        XSXM: "",
        BJId: "",
        BJMC: "",
        MZ: "",
        XJH: "",
        XBM: 1,
        NL: 0,
        SG: 0,
        TZ: 0,
        FHL: 0,
        SSY: 0,
        SZY: 0,
        XHDB: "",
        ZYSL: 0,
        YYSL: 0,
        JMY: "",
        ZYSY: "",
        YYSY: "",
        SJ: "",
        YB: "",
        QSB: "",
        RQH: "",
        RQS: "",
        RQB: "",
        HQH: "",
        HQS: "",
        HQB: "",
        YZ: "",
        YY: "",
        Xin: "",
        Fei: "",
        Gan: "",
        Pi: "",
        FB: "",
        TB: "",
        JB: "",
        JZ: "",
        SZ: "",
        PF: "",
        JZX: "",
        SSXX: "",
        SSXQ: ""
      }
    };
  }
};
</script>
