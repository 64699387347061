<template>
  <!-- admin 区域课程 -->
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <el-row>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            <!--tree-->
            <el-tree
              :props="props"
              :load="loadNode"
              lazy
              :highlight-current="true"
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
        </el-col>

        <el-col :span="19">
          <div class="tab-heng-box margno-t">
            <!--列表-->
            <div class="table-er-box">
              <el-button
                plain
                icon="el-icon-edit"
                :loading="addBtnLoading"
                @click="addopen"
                >新增</el-button
              >
            </div>
            <el-table
              :data="tableData"
              class="jf-table"
              border
              tooltip-effect="dark"
              style="width: 100%;"
            >
              <el-table-column
                prop="KCMC"
                label="课程名称"
                width="180"
              ></el-table-column>
              <el-table-column prop="XD" label="学段" width="180">
                <template slot-scope="scope">
                  <p v-if="scope.row.XD == '1'">小学</p>
                  <p v-else-if="scope.row.XD == '2'">初中</p>
                  <p v-else-if="scope.row.XD == '4'">高中</p>
                  <p v-else></p>
                </template>
              </el-table-column>
              <el-table-column prop="LRFS" label="录入方式">
                <template slot-scope="scope">
                  <p v-if="scope.row.LRFS == '1'">分数</p>
                  <p v-else-if="scope.row.LRFS == '2'">等级</p>
                </template>
              </el-table-column>
              <el-table-column prop="ZSFS" label="展示方式">
                <template slot-scope="scope">
                  <p v-if="scope.row.ZSFS == '1'">分数</p>
                  <p v-else-if="scope.row.ZSFS == '2'">等级</p>
                </template>
              </el-table-column>
              <!--<el-table-column prop="GLDJ"
                                                     label="关联等级">
              </el-table-column>-->
              <el-table-column prop="XKZF" label="学科总分"></el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    :loading="editBtnLoading"
                    @click="editRow(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="deleteRow(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div class="block">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="rowcount"
              ></el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--弹出框-->
    <el-dialog
      :title="addtitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="QYKCInfo" :rules="rules" ref="sbmitfrom">
        <el-form-item
          label="课程名称"
          :label-width="formLabelWidth"
          prop="KCMC"
        >
          <el-input
            v-model="QYKCInfo.KCMC"
            autocomplete="on"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item label="学段" :label-width="formLabelWidth" prop="XD">
          <el-select
            v-model="QYKCInfo.XD"
            placeholder="请选择学段"
            style="width: 100%;"
          >
            <el-option label="小学" :value="1"></el-option>
            <el-option label="初中" :value="2"></el-option>
            <el-option label="高中" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="录入方式"
          :label-width="formLabelWidth"
          prop="LRFS"
        >
          <el-radio-group v-model="QYKCInfo.LRFS" @change="LRFSchange">
            <el-radio :label="1">分数</el-radio>
            <el-radio :label="2">等级</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="展示方式"
          :label-width="formLabelWidth"
          prop="ZSFS"
        >
          <el-radio-group v-model="QYKCInfo.ZSFS">
            <el-radio :label="1" :disabled="QYKCInfo.LRFS === 2">分数</el-radio>
            <el-radio :label="2">等级</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="学科总分"
          :label-width="formLabelWidth"
          prop="XKZF"
        >
          <el-input
            :disabled="QYKCInfo.LRFS === 2"
            v-model="QYKCInfo.XKZF"
            autocomplete="off"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="关联末级维度"
          :label-width="formLabelWidth"
          prop="WDId"
        >
          <el-cascader
            v-model="QYKCInfo.WDId"
            :options="WDoption"
            :props="WDprops"
            clearable
          ></el-cascader>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitshowdata">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isInteger } from '@/style/js/Validator.js'

export default {
  name: 'QykcList',
  data() {
    let validZSZF = (rule, value, callback) => {
      if (this.QYKCInfo.LRFS === 1) {
        if (!Number(value)) {
          callback(new Error('请输入整数'))
        } else {
          const re = /^[0-9]*[1-9][0-9]*$/
          const rsCheck = re.test(value)
          if (!rsCheck) {
            callback(new Error('请输入整数'))
          } else {
            callback()
          }
        }
      } else {
        return callback()
      }
    }
    return {
      token: this.$store.state.token,
      // loading:true,
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      gradelist: [],
      QYKCInfo: {
        Id: '',
        KCMC: '',
        CJR: '',
        CJSJ: '2019-10-09 08:15:35',
        SFSC: 0,
        XD: 1,
        SSQY: '',
        LRFS: 0,
        ZSFS: 0,
        GLDJ: '',
        XKZF: '',
        SYZT: 0
      },
      loadstate: true,
      props: {
        label: 'name',
        children: 'subnodeList'
      },
      addtitle: '新增',
      addBtnLoading: false,
      editBtnLoading: false,
      rules: {
        KCMC: [
          {
            required: true,
            message: '请输入课程名称',
            trigger: 'blur'
          }
        ],
        XD: [
          {
            required: true,
            message: '请选择学段',
            trigger: 'blur'
          }
        ],
        LRFS: [
          {
            required: true,
            message: '请选择录入方式',
            trigger: 'blur'
          },
          {
            validator: isInteger,
            message: '请选择录入方式',
            trigger: 'change'
          }
        ],
        ZSFS: [
          { required: true, message: '请选择展示方式', trigger: 'change' },
          {
            validator: isInteger,
            message: '请选择展示方式',
            trigger: 'change'
          }
        ],
        XKZF: [
          {
            validator: validZSZF,
            trigger: 'change'
          }
        ],
        WDId: [
          {
            required: true,
            message: '关联维度不能为空',
            trigger: 'blur'
          }
        ]
      },
      userName: '',
      userId: '',
      WDoption: [],
      WDprops: {
        checkStrictly: true,
        emitPath: false,
        value: 'Id',
        label: 'WDMC',
        children: 'children'
      },
      userinfo: {}
    }
  },
  created: async function() {
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.QYKCInfo.CJR = userinfo.Id

    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    this.userinfo = userinfo
  },
  methods: {
    // tree选中
    handleNodeClick(data) {
      if (this.QYKCInfo.SSQY !== data.key || this.loadstate) {
        this.loadstate = false
        this.QYKCInfo.SSQY = data.key

        var pagerInfo = { pageIndex: 0, pageSize: this.pagesize }
        this.$api.post(
          'v1/areacourse/GetAreaCourseList',
          {
            AreaId: this.QYKCInfo.SSQY,
            PagerInfo: pagerInfo,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.tableData = r.Target

              this.rowcount = r.PagerInfo.RowCount
            }
          }
        )
      }
    },
    // 加载tree
    loadNode(node, resolve) {
      // 初始加载
      if (node.level === 0) {
        var startload = []

        this.$api.post(
          'v1/areaschool/GetAreaTreeByParentId',
          { ParentId: this.QYKCInfo.SSQY, token: this.token },
          r => {
            if (r.Code === 0) {
              startload = r.Target
              return resolve(startload)
            }
          }
        )
      } else {
        this.QYKCInfo.SSQY = node.data.key

        this.loadstate = true

        setTimeout(() => {
          var data = []

          this.$api.post(
            'v1/areaschool/GetAreaTreeByParentId',
            { ParentId: this.QYKCInfo.SSQY, token: this.token },
            r => {
              if (r.Code === 0) {
                data = r.Target

                resolve(data)
              }
            }
          )
        }, 500)
      }
    },

    // 翻页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);

      var pagerInfo = { pageIndex: 0, pageSize: val }

      this.$api.post(
        'v1/areacourse/GetQYKCList',
        { AreaId: this.QYKCInfo.SSQY, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 翻页查询
    handleCurrentChange(val) {
      // console.log(`当前页1: ${val}`);

      var pagerInfo = { pageIndex: val, pageSize: this.pagesize }

      this.$api.post(
        'v1/areacourse/GetQYKCList',
        { AreaId: this.QYKCInfo.SSQY, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 刷新列表
    reflist: function() {
      var pagerInfo = { pageIndex: 0, pageSize: this.pagesize }

      this.$api.post(
        'v1/areacourse/GetAreaCourseList',
        { AreaId: this.QYKCInfo.SSQY, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target

            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 新增
    addopen: async function() {
      if (this.QYKCInfo.SSQY === '') {
        this.$message({
          type: 'info',
          message: '请选择区域'
        })
        return
      }
      if (this.QYKCInfo.SSQY.length <= 6) {
        this.$message({
          type: 'info',
          message: '该区域不能新增'
        })
        return
      }

      this.addtitle = '新增'

      var areaId = this.QYKCInfo.SSQY
      var cjr = this.QYKCInfo.CJR
      this.addBtnLoading = true
      if (this.WDoption.length === 0) {
        await this.getWdOption() //获取区域一级维度
        for (let i = 0; i < this.WDoption.length; i++) {
          await this.getWdOption(this.WDoption[i]) //获取二级维度
        }
      }
      this.dialogFormVisible = true
      this.addBtnLoading = false
      this.QYKCInfo = {
        Id: '',
        KCMC: '',
        CJR: cjr,
        CJSJ: '2019-10-09 08:15:35',
        SFSC: 0,
        XD: 1,
        SSQY: areaId,
        LRFS: 0,
        ZSFS: 0,
        GLDJ: '',
        XKZF: 0,
        SYZT: 0
      }
    },
    // 修改
    editRow: async function(index, row) {
      this.QYKCInfo = row
      if (this.WDoption.length === 0) {
        await this.getWdOption() //获取区域一级维度
        for (let i = 0; i < this.WDoption.length; i++) {
          await this.getWdOption(this.WDoption[i]) //获取二级维度
        }
      }
      this.editBtnLoading = true
      this.dialogFormVisible = true
      this.addtitle = '修改'
      this.editBtnLoading = false
    },
    // 删除
    deleteRow: function(index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/areacourse/DeleteAreaCourse',
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.reflist()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    // 保存
    submitshowdata: function(event) {
      event.preventDefault()
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.$api.post(
            'v1/areacourse/AddAreaCourse',
            {
              QYKCInfo: this.QYKCInfo,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.dialogFormVisible = false

                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.reflist()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        } else {
          return false
        }
      })
    },

    // 获取维度
    getWdOption(Parent) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/dimension/GetDimension',
          {
            AreaId: this.QYKCInfo.SSQY,
            ParentId: Parent ? Parent.Id : '',
            PagerInfo: {
              pageIndex: 1,
              pageSize: 99
            },
            token: this.userinfo.Token
          },
          res => {
            if (res.Code === 0 && res.Target) {
              if (Parent) {
                if (res.Target.Target && res.Target.Target.length > 0) {
                  Parent.children = res.Target.Target
                  Parent.disabled = true
                } else Parent.children = null
              } else {
                this.WDoption = res.Target.Target
              }
              resolve()
            } else reject()
          }
        )
      })
    },
    // 录入方式改变
    LRFSchange(val) {
      if (val === 2) {
        this.QYKCInfo.ZSFS = 2
        this.QYKCInfo.XKZF = undefined
      }
    }
  }
}
</script>
<style>
.cell {
  text-align: left;
}
</style>
