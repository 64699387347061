<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <div class="leftrig-left-box" style="width: 150px;">
        <!--tree-->
        <el-tree
          :props="defaultProps"
          :data="treedata"
          :highlight-current="true"
          @node-click="handleNodeClick"
        ></el-tree>
        <!--:load="loadNode"-->
      </div>
      <div class="leftrig-rig-box">
        <!--表格区开始-->
        <div class="tab-heng-box margno-t">
          <div class="table-er-box">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="coursetableset"
              >课表设置</el-button
            >

            <el-button
              type="primary"
              icon="el-icon-edit"
              v-if="addbtn == 1"
              @click="addopen"
              >批量新增</el-button
            >

            <el-button
              type="primary"
              icon="el-icon-edit"
              v-else-if="addbtn == 2"
              @click="addcolse"
              >取消</el-button
            >

            <el-button
              type="primary"
              icon="el-icon-edit"
              v-else
              @click="dialogFormVisible = true"
              >确定新增</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openImportKbClose"
              >导入课表</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="copyLastTermCouseTable"
              >复制上期课表</el-button
            >
          </div>
          <br />
          <br />
          <el-checkbox-group v-model="checkList">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <td v-if="tableTitleList.length > 0">星期</td>
                  <td v-for="item in tableTitleList" :key="item">{{ item }}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in contentRowList" :key="index">
                  <td v-for="(conent, idx) in item.arr" :key="idx">
                    <span v-if="conent.type === 2">未设置节次</span>
                    <span v-else>
                      <span v-if="conent.ContentType == 1">
                        {{ conent.FirstName }}
                      </span>
                      <span v-else-if="conent.ContentType == 2">
                        <el-checkbox
                          :label="conent.FirstName"
                          :style="dialogcheckVisible"
                          @change="selectcheck"
                        >
                          <span style="color: red;">新增</span> </el-checkbox
                        >&nbsp;

                        <span
                          v-if="dialogcheckNameVisible == 1"
                          style="color: red;"
                          @click="addsaveopen(conent.FirstName, conent)"
                          >新增</span
                        >

                        <el-tag
                          type="danger"
                          effect="plain"
                          closable
                          v-for="(course, i) in conent.TeacherCourseList"
                          :key="i"
                          @close="tagClose(course)"
                          >{{ course.ContentName }}</el-tag
                        >
                      </span>
                      <span v-else>
                        <el-tag
                          type="danger"
                          effect="plain"
                          closable
                          v-for="(course, i) in conent.TeacherCourseList"
                          :key="i"
                          @close="tagClose(course)"
                          >{{ course.ContentName }}</el-tag
                        >
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-checkbox-group>
        </div>
        <!--表格区结束-->
      </div>
    </div>
    <el-dialog
      title="导入课表"
      :visible.sync="dialogImportKbVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleImportKbClose"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="getuploaddata"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <a
            :href="`${publicPath}template/课表导入模板.xlsx`"
            class="essay-bt-textbunn"
            style="margin-left:0!important;"
            >课表导入模板下载</a
          >
        </el-form-item>
        <div class="py-text">{{ readingData }}</div>
        <div class="py-text">{{ imretunmessge }}</div>
        <el-form-item>
          <el-button @click="handleImportKbClose()">取 消</el-button>
          <el-button @click="importKbSave()">导入</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--弹出框-->
    <el-dialog
      title="选择课程及教师"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-tabs type="border-card">
          <el-tab-pane label="年级课程">
            <div style="min-height: 100px;">
              <el-radio-group v-model="JSSKBInfo.KCId">
                <el-radio
                  :label="item.KCYSId"
                  v-for="(item, idx) in teachercourseList"
                  :key="idx"
                  >{{ item.KCMC }}</el-radio
                >
              </el-radio-group>
            </div>
          </el-tab-pane>
        </el-tabs>
        <br />
        <br />
        <el-tabs type="border-card">
          <el-tab-pane label="任课老师">
            <div style="display:flex">
              <el-select
                v-model="roleId"
                placeholder="请选择分组"
                @change="teachergroupchage"
              >
                <el-option label="全部" value="0"></el-option>
                <el-option
                  v-for="item in schoolteachergrouplist"
                  :key="item.Id"
                  :label="item.JYZMC"
                  :value="item.Id"
                ></el-option>
              </el-select>
              <el-input
                style="width:170px;margin-left:10px"
                placeholder="请输入内容"
                v-model="search"
                clearable
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="teachergroupchage"
                ></el-button>
              </el-input>
            </div>
            <br />
            <div style="height: 300px; overflow-y: auto; overflow-x: hidden;">
              <el-checkbox-group v-model="selectTeacherList">
                <el-checkbox
                  :label="teacher.Id"
                  v-for="teacher in schoolteacherList"
                  :key="teacher.Id"
                  >{{ teacher.Name }}</el-checkbox
                >
              </el-checkbox-group>
              <div
                style="height:100%;text-align:center;padding-top:50px;box-sizing:border-box"
                v-if="!schoolteacherList || schoolteacherList.length === 0"
              >
                没找到哟
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <br />
        <div>
          <el-select v-model="JSSKBInfo.KKLX" placeholder="上课方式">
            <el-option v-bind:value="1" label="正常"></el-option>
            <el-option v-bind:value="2" label="单周"></el-option>
            <el-option v-bind:value="3" label="双周"></el-option>
          </el-select>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitshowdata">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
export default {
  name: 'CourseTable',
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return '/file/file/fileservice/UploadFile?token=' + this.token
    }
  },
  data() {
    return {
      token: this.$store.state.token,
      publicPath: process.env.BASE_URL,
      tableTitleList: [],
      contentRowList: [],
      conent: {},
      treedata: [],
      tabletree: [],
      schoolId: '',
      classId: '0',
      gradeId: '',
      header: [
        '节次',
        '星期一',
        '__EMPTY',
        '星期二',
        '__EMPTY_1',
        '星期三',
        '__EMPTY_2',
        '星期四',
        '__EMPTY_3',
        '星期五',
        '__EMPTY_4'
      ],
      results: null,
      imretunmessge: '',
      readingData: '',
      excelData: [],
      gradenumber: 0,
      SSXD: 1,
      period: '1',
      loadstate: true,
      props: {
        label: 'name',
        children: 'zones'
      },
      addbtn: 1,
      JSSKBInfo: {
        Id: 0,
        BJId: '',
        KCId: '',
        SSXQ: '',
        KKLX: 1,
        SSXD: 0,
        CJR: '',
        CJSJ: '2019-09-29 07:02:18',
        SFSC: 0,
        SSXX: '',
        SKJS: '',
        XQJ: 0,
        JC: 0
      },
      checkList: [],
      dialogcheckVisible: 'display:none',
      dialogFormVisible: false,
      dialogImportKbVisible: false,
      dialogcheckNameVisible: 1,
      teachercourseList: [],
      selectTeacherList: [],
      schoolteacherList: [],
      schoolteachallList: [],
      schoolteachergrouplist: [],
      roleId: '0',
      roleList: [],
      userName: '',
      userId: '',
      defaultProps: {
        children: 'subnodeList',
        label: 'name'
      },
      termId: '',
      currentNode: {},
      search: '', //搜索教师
      curGroupTearcher: [], //当前根据分组请求到的数据
      arr: [], //用来判断导入数据中是否有节次
      dayStr: ['一', '二', '三', '四', '五', '六']
    }
  },
  created: function() {
    var userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.schoolId = userinfo.SSZZId
    this.JSSKBInfo.SSXX = userinfo.SSZZId
    this.JSSKBInfo.CJR = userinfo.Id
    this.userName = userinfo.DLM
    this.userId = userinfo.Id

    var currentterm = localStorage.getItem('currentterm')
    if (currentterm !== null) {
      this.termId = currentterm
    }
    // this.termId = termId;

    // 获取学校教师成员
    this.$api.post(
      'v1/role/GetRoleSchoolTeacher',
      { SchoolId: this.schoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          this.schoolteachallList = r.Target
        }
      }
    )

    // 获取教研组
    this.$api.post(
      'v1/teachinggroup/TeachingGroupList',
      { SchoolId: this.schoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          this.schoolteachergrouplist = r.Target
        }
      }
    )

    // 获取tree
    this.$api.post(
      'v1/qyxxzzjg/GetSchoolGradeandClassTree',
      { SchoolId: this.schoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          r.Target.sort((a, b) => a.type - b.type)
          this.treedata = r.Target
          if (
            this.treedata[0] &&
            this.treedata.subnodeList &&
            this.treedata.subnodeList[0]
          ) {
            this.classId = this.treedata[0].subnodeList[0].key
            this.getClassCourseTable(1)
          }
        }
      }
    )
  },
  methods: {
    // tree选中
    async handleNodeClick(data) {
      console.log(data, '左侧树点击')
      if (this.classId !== data.key || this.loadstate) {
        this.loadstate = false
        this.currentNode = data
        this.classId = data.key
        this.period = data.type
        this.gradeId = data.parentId
        this.gradenumber = data.gradeNumber
        this.SSXD = data.type || 0
        if (this.gradeId !== '0') {
          await this.getClassCourseTable()
        } else {
          this.tableTitleList = []
          this.contentRowList = []
          this.$message({
            type: 'info',
            message: '请选择班级加载课程表'
          })
        }
      }
    },
    // 加载tree
    loadNode(node, resolve) {
      // 初始加载
      if (node.level === 0) {
        var startload = []

        this.$api.post(
          'v1/qyxxzzjg/GetSchoolOrganizationByTree',
          { SchoolId: this.schoolId, FJId: this.classId, token: this.token },
          r => {
            if (r.Code === 0) {
              startload = r.Target

              return resolve(startload)
            }
          }
        )
      } else {
        this.classId = node.data.key
        this.loadstate = true

        setTimeout(() => {
          var data = []

          this.$api.post(
            'v1/qyxxzzjg/GetSchoolOrganizationByTree',
            { SchoolId: this.schoolId, FJId: this.classId, token: this.token },
            r => {
              if (r.Code === 0) {
                data = r.Target

                resolve(data)
              }
            }
          )
        }, 500)
      }
    },
    // 刷新列表
    refloadlist: function() {
      this.dialogcheckNameVisible = 1
      this.dialogcheckVisible = 'display:none'
      this.$api.post(
        'v1/cousertimetable/GetCourseTableList',
        {
          SchoolId: this.schoolId,
          TermId: this.termId,
          Period: this.period,
          ClassId: this.classId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.handleTitleList(r)
            this.contentRowList = this.handleContentRowList(
              r.Target.ContentRowList || []
            )
          }
        }
      )
    },
    // 课表设置
    coursetableset: function() {
      this.$router.push({ name: 'CourseTableSet' })
    },
    // 打开批量新增
    addopen: function() {
      console.log(this.contentRowList,'批量');
      
      this.clearselect()
      this.checkList = []
      this.addbtn = 2
      this.dialogcheckVisible = 'display:block'
      this.dialogcheckNameVisible = 2
      this.$api.post(
        'v1/xxkcb/GetGradeCourse',
        {
          SchoolId: this.schoolId,
          GradeId: this.gradenumber,
          SSXD: this.SSXD,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.teachercourseList = r.Target
          }
        }
      )

      this.schoolteacherList = this.schoolteachallList
    },
    // 打开批量关闭
    addcolse: function() {
      this.addbtn = 1
      this.dialogcheckVisible = 'display:none'
      this.dialogcheckNameVisible = 1
    },
    // 选中check
    selectcheck: function(data) {
      this.addbtn = 3
    },
    // 删除标签
    tagClose: function(course) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 删除
          this.$api.post(
            'v1/teachercourse/DeleteJSSKB',
            { Id: course.Id, token: this.token },
            r => {
              if (r.Code === 0) {
                this.refloadlist()
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 删除标签
    copyLastTermCouseTable: function() {
      this.$confirm('是否复制同一学年上学期课表？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 删除
          this.$api.post(
            'v1/teachercourse/CopyTermCourseTable',
            { TermId: this.termId, SchoolId: this.schoolId, token: this.token },
            r => {
              if (r.Code === 0) {
                this.$message.success('复制成功')
                this.refloadlist()
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 打开弹出框
    addsaveopen: function(selectVaule, conent) {
      
      this.clearselect()
      this.dialogFormVisible = true
      this.checkList = []
      this.checkList.push(selectVaule)
      this.conent = conent
      // var gradeId = this.classId.substr(0, 6);
      this.$api.post(
        'v1/xxkcb/GetGradeCourse',
        {
          SchoolId: this.schoolId,
          GradeId: this.gradenumber,
          SSXD: this.SSXD,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.teachercourseList = r.Target
          }
        }
      )
      this.search = ''
      this.roleId = '0'
      this.teachergroupchage()
    },
    // 清空选择
    clearselect: function() {
      this.JSSKBInfo.KCId = ''
      this.selectTeacherList = []
    },
    // 保存
    submitshowdata: function() {
      // let checkbox =this.$refs['checkbox']
      // let arr1=checkbox.filter(item=>{
      //   return item.checked === true
      // })
      // console.log(arr1,'111111')
      if (!this.JSSKBInfo.KCId) return this.$message.error('请选择课程')
      if (!this.selectTeacherList || this.selectTeacherList.length === 0)
        return this.$message.error('请选择教师')

      if (this.classId.length > 6) {
        this.JSSKBInfo.BJId = this.classId
        this.JSSKBInfo.SSXQ = this.termId
        this.JSSKBInfo.SSXD = this.SSXD
        let arr = []
        if (this.arr.length > 0) {
          console.log(this.arr,'arr');
          arr = this.checkList.reduce((pre, cur) => {
            this.arr.forEach(it => {
              let result = it.arr.find(i => i.FirstName === cur)
              if (result) {
                let str = cur + '_' + result.CourseSetId
                pre.push(str)
              }
            })
            return pre
          }, [])
        } else {
          arr = [this.checkList[0] + '_' + this.conent.CourseSetId]
        }
        console.log('arr',arr);
        
        this.$api.post(
          'v1/teachercourse/AddTeacherCourse',
          {
            JSSKBInfo: this.JSSKBInfo,
            TeacherCheckList: this.selectTeacherList,
            FestivalsCheckList: arr,
            UserName: this.userName,
            UserId: this.userId,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.addbtn = 1
              this.dialogcheckVisible = 'display:none'
              this.dialogFormVisible = false

              if (r.Message.length === 5) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })
                this.refloadlist()
              } else {
                this.$confirm(r.Message, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.refloadlist()
                  })
                  .catch(() => {
                    this.refloadlist()
                  })
              }
            } else {
              this.$message({
                type: 'error',
                message: r.Message,
                showClose: true
              })
            }
          }
        )
      }
    },
    // 教研组查询
    teachergroupchage: function() {
      this.schoolteacherList = []
      if (this.roleId !== '0') {
        this.$api.post(
          'v1/teachinggroup/GetTeacheingGroupByGroupId',
          { JYZId: this.roleId, token: this.token },
          r => {
            if (r.Code === 0) {
              if (r.Target.length > 0) {
                this.curGroupTearcher = r.Target
                this.schoolteacherList = this.search
                  ? r.Target.filter(item => item.Name.includes(this.search))
                  : r.Target
              }
            }
          }
        )
      } else {
        this.schoolteacherList = this.search
          ? this.schoolteachallList.filter(item =>
              item.Name.includes(this.search)
            )
          : this.schoolteachallList
      }
    },
    //关闭弹出框
    openImportKbClose: function() {
      this.excelData = []
      this.imretunmessge = ''
      this.readingData = ''
      this.dialogImportKbVisible = true
    },
    //关闭弹出框
    handleImportKbClose: function() {
      this.dialogImportKbVisible = false
    },
    // 处理导入课表的数据
    getuploaddata(file) {
      this.excelData = []
      this.imretunmessge = ''
      this.readingData = ''
      let files = { 0: file.raw }
      const rawFile = files[0]
      if (!rawFile) return
      this.upload(rawFile)
    },
    upload(rawFile) {
      if (!this.beforeUpload) {
        this.readerData(rawFile)
        return
      }
      const before = this.beforeUpload(rawFile)
      if (before) {
        this.readerData(rawFile)
      }
    },
    readerData(rawFile) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'array' })
          var sheetCount = workbook.SheetNames.length
          for (var i = 0; i < sheetCount; i++) {
            const sheetName = workbook.SheetNames[i]
            const worksheet = workbook.Sheets[sheetName]
            const results = XLSX.utils.sheet_to_json(worksheet, { raw: false })
            this.generateData(results, sheetName)
          }
          if (this.excelData != null && this.excelData.length > 0) {
            this.readingData = '读取数据完成！点击导入导入按钮！'
            this.imretunmessge = ''
          } else {
            this.readingData = '文件读取失败！'
          }
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    generateData(results, sheetName) {
      console.log(results, 'result')
      const obj = {
        ClassName: sheetName || this.currentNode.name,
        ClassTbData: []
      }
      for (var i = 1; i < results.length; i++) {
        var rowData = []
        const row = results[i]
        for (var j = 0; j < this.header.length; j++) {
          var clleName = this.header[j]
          var cellData = row[clleName]
          if (cellData) {
            rowData.push(cellData)
          } else {
            rowData.push('')
          }
        }
        console.log(rowData, 'rowData')
        // 导入的时候 导入的数据对应的节次可能在系统中没有设置 所以需要提前置空
        let emptyIndex = []
        if (rowData[0] && this.contentRowList[Number(rowData[0]) - 1]) {
          let aaa = this.contentRowList[Number(rowData[0]) - 1]
          aaa.arr.forEach((item, index) => {
            if (item.type === 2)
              emptyIndex.push(index + (index <= 1 ? 0 : index - 1))
          })
        }
        emptyIndex.forEach(it => {
          rowData[it] = ''
          rowData[it + 1] = ''
        })
        // 全部都是空格或者没值的 不传
        if (rowData.some(it => it && it.trim())) obj.ClassTbData.push(rowData)
      }
      console.log(obj, 'obj')
      this.excelData.push(obj)
      // this.onSuccess && this.onSuccess(this.excelData, this.itemKey);
    },
    //导入数据
    importKbSave: function() {
      this.imretunmessge = ''
      if (this.excelData == null || this.excelData.length == 0) {
        this.imretunmessge = '未读取到导入数据！'
        return
      }
      this.$api.post(
        'v1/teachercourse/ImportTeacherCourse',
        {
          SchoolId: this.schoolId,
          TermId: this.termId,
          UserName: this.userName,
          UserId: this.userId,
          SSXD: this.SSXD,
          ClassTableList: this.excelData,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.dialogImportKbVisible = false
            this.$message({
              type: 'success',
              message: r.Message
            })
            this.getClassCourseTable()
          } else {
            this.imretunmessge = r.Message
          }
        }
      )
    },
    // 获取班级课表
    getClassCourseTable(flag) {
      console.log(flag, 'wewe')
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/cousertimetable/GetCourseTableList',
          {
            SchoolId: this.schoolId,
            TermId: this.termId,
            Period: this.period,
            ClassId: this.classId,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              if (flag) {
                this.arr = this.handleContentRowList(
                  r.Target.ContentRowList || []
                )
              } else {
                
                this.handleTitleList(r)
                this.contentRowList = this.handleContentRowList(
                  r.Target.ContentRowList || []
                )
              }
              this.arr = this.handleContentRowList(
                  r.Target.ContentRowList || []
                )
              resolve()
            } else reject(r)
          },
          err => reject(err)
        )
      })
    },
    // 处理班级课表
    handleContentRowList(data) {
      let arr = []
      arr = data.reduce((pre, cur) => {
        cur.ContentList.forEach(item => {
          let res = pre.find(it => it.Week === item.Week)
          if (res) {
            res.course.push(item)
          } else {
            pre.push({
              Week: item.Week,
              course: [item]
            })
          }
        })
        return pre
      }, [])
      // 排序
      arr.forEach(item => item.course.sort((a, b) => a.Sort - b.Sort))
      arr.sort((a, b) => a.week - b.week)
      // 找出节次最大的
      let num = arr.map(item => item.course.length)
      let max = Math.max(...num)
      arr.forEach(item => {
        let num2 = item.course.map(it => it.Sort)
        let empty = []
        for (let i = 1; i <= max; i++) {
          let emptyNum = num2.find(el => el === i)
          if (!emptyNum) empty.push(i)
        }
        empty.forEach(el =>
          item.course.push({
            Sort: el,
            type: 2,
            Week: item.Week
          })
        )
        item.course.sort((a, b) => a.Sort - b.Sort)
      })
      let result = arr.reduce((pre, cur) => {
        if (pre.length === 0) {
          for (let i = 1; i <= max; i++) {
            pre.push({
              JC: i,
              arr: []
            })
          }
        }
        if (cur.Week === 0) {
          cur.course.forEach((item, index) => {
            item.FirstName = `第${index + 1}节`
          })
        }
        cur.course.forEach(item => {
          let obj = pre.find(el => el.JC === item.Sort)
          obj.arr.push(item)
        })
        return pre
      }, [])
      return result
    },
    handleTitleList(r) {
      if (r.Target && r.Target.TableTitleList) {
        this.tableTitleList = this.dayStr.reduce((pre, cur) => {
          let aaa = r.Target.TableTitleList.find(item => item.includes(cur))
          if (aaa) {
            pre.push(aaa)
          }
          return pre
        }, [])
        let sunday = r.Target.TableTitleList.find(
          item =>
            item.includes('天') || item.includes('末') || item.includes('日')
        )
        if (sunday) this.tableTitleList.push(sunday)
      }
    }
  }
}
</script>
