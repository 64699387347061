<template>
  <el-breadcrumb :separator="sepaRator">
    <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
    <el-breadcrumb-item
      v-for="(item, key) in breadList"
      :key="key"
      :to="{ path: item.path === '/index/' ? '' : item.path }"
      >{{ item.name }}</el-breadcrumb-item
    >
    <!-- <el-breadcrumb-item>
      <a href="/">活动管理</a>
    </el-breadcrumb-item>-->
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      breadList: [],
    };
  },
  props: {
    sepaRator: {
      type: String,
      default: "/",
    },
  },
  created: function () {
    this.getBreadList();
  },
  methods: {
    getBreadList() {
      this.breadList = [];
      for (const item of this.$route.matched.filter(
        (c) => c.meta && c.meta.title && c.meta.title !== ""
      )) {
        this.breadList.push({ name: item.meta.title, path: item.path });
        console.log(this.breadList);
      }
    },
  },
  watch: {
    $route: function () {
      this.getBreadList();
    },
  },
};
</script>
