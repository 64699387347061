<template>
  <!-- 德体美劳弹窗 -->
  <el-dialog
    custom-class="select-level-dialog"
    title=""
    :visible.sync="show"
    width="70%"
    :close-on-click-modal="false"
    :show-close="false"
    append-to-body
  >
    <div class="level-container">
      <div class="title-block">
        <div class="text">等级勾选</div>
        <el-input size="small" placeholder="请输入内容" v-model="seachText">
          <el-button
            slot="append"
            size="small"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
      </div>
      <el-radio-group v-model="form.selected">
        <el-radio
          style="margin-bottom:20px"
          v-for="item in levelOptions2"
          :key="item.Id"
          :label="item.Id"
          >{{ item.DJMC }}</el-radio
        >
      </el-radio-group>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:show', false)">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    levelOptions: [Array],
    show: [Boolean],
    formData: [Object]
  },
  data() {
    return {
      form: {},
      levelOptions2: [],
      seachText: ""
    };
  },
  methods: {
    submit() {
      this.$emit("submitLevel", this.form.selected);
    },
    handleSearch() {
      let res = this.levelOptions.filter(
        item => item.GLDJLX !== 1 && item.GLDJLX !== 2
      );
      if (this.seachText.trim()) {
        this.levelOptions2 = res.filter(item =>
          item.DJMC.includes(this.seachText)
        );
      } else {
        this.levelOptions2 = res;
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.form = JSON.parse(JSON.stringify(this.formData));
        this.levelOptions2 = this.levelOptions.filter(
          item => item.GLDJLX !== 1 && item.GLDJLX !== 2
        );
        this.$set(this.form, "selected", this.formData.GLDJId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-block {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  .text {
    margin-right: 10px;
  }
  ::v-deep .el-input {
    width: 200px;
  }
}
</style>
