<template>
  <div>
    <div class="cz-cont-box pdf-center pdf-wrap">
      <div class="pdf-sider pdf-center">
        <div
          v-for="(item, index) in pageTemplateNumber"
          :key="item"
          class="pdf-center pdf-sider-item"
        >
          <div
            @click="currentPageTemplate = index + 1"
            :class="[
              'pdf-sider-item-text',
              currentPageTemplate === item ? 'pdf-sider-item-text-active' : ''
            ]"
          >
            第{{ item }}页
          </div>
          <i
            class="el-icon-delete pdf-sider-item-remove"
            @click="delPage(index)"
          ></i>
        </div>
        <div class="pdf-center pdf-sider-item">
          <el-button
            size="small"
            icon="el-icon-plus"
            plain
            @click="addPage"
          ></el-button>
        </div>
      </div>
      <div class="pdf-center pdf-content">
        <div class="pdf-content-set">
          <div class="pdf-content-set-item">
            <el-button type="primary" @click="openTemplates"
              >选择模版</el-button
            >
          </div>
          <div class="pdf-content-set-item">
            <el-input
              v-model="pagesData[currentPageTemplate - 1].title"
              style="max-width:250px"
              placeholder="请输入标题"
            ></el-input>
          </div>
          <div class="pdf-content-set-item">
            <el-select
              v-model="pagesData[currentPageTemplate - 1].dimension"
              clearable
              placeholder="请选择维度"
            >
              <el-option
                v-for="item in dimensions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="pdf-content-set-item">
            <el-button type="primary">上传底图</el-button>(非必须)
          </div>
        </div>
        <div class="pdf-center pdf-content-preview">
          <div>每张模板尺寸</div>
          <div class="pdf-center preview">
            <el-image
              style="width: 300px; height: 420px;background:#f5f7fa"
              src=""
              fit="cover"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPageTemplate: 1,
      pageTemplateNumber: 1,
      pageTemplateData: [
        {
          id: "1",
          name: "模版1"
        },
        {
          id: "2",
          name: "模版2"
        },
        {
          id: "3",
          name: "模版3"
        },
        {
          id: "4",
          name: "模版4"
        },
        {
          id: "5",
          name: "模版5"
        },
        {
          id: "6",
          name: "模版6"
        },
        {
          id: "7",
          name: "模版7"
        },
        {
          id: "8",
          name: "模版8"
        },
        {
          id: "9",
          name: "模版9"
        }
      ],
      dimensions: [
        { id: "1", name: "维度1" },
        { id: "2", name: "维度2" },
        { id: "3", name: "维度3" }
      ],
      pagesData: [
        { number: 0, template: "", title: "", dimension: "", background: "" }
      ],
      pageData: {
        number: 0,
        template: "",
        title: "",
        dimension: "",
        background: ""
      }
    };
  },
  props: {},
  created() {
    this.pagesData.push(JSON.parse(JSON.stringify(this.pageData)));
  },
  methods: {
    addPage() {
      this.pagesData.push(JSON.parse(JSON.stringify(this.pageData)));
      this.pageTemplateNumber++;
    },
    delPage(index) {
      this.pagesData.splice(index, 1);
      this.pageTemplateNumber--;
    },
    openTemplates() {
      this.$router.push({
        path: "pdfTemplates",
        query: { report: this.currentPageTemplate }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.pdf-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdf-wrap {
  padding: 0px 10px;
  align-items: self-start;
}
.pdf-sider {
  width: 250px;
  flex-direction: column;
}
.pdf-sider-item {
  width: calc(100% - 10px);
  padding: 5px;
  // background: cornflowerblue;
  justify-content: space-evenly;
  border-right: 1px solid #f2f2f2;
  font-size: 16px;
}
.pdf-sider-item + .pdf-sider-item {
  border-top: 1px solid #f2f2f2;
}
.pdf-content {
  flex: 1;
  align-items: flex-start;
}
.pdf-sider-item-remove:hover {
  color: #f56c6c;
}
.pdf-sider-item-remove {
  font-size: 20px;
  cursor: pointer;
}
.pdf-sider-item-text:hover {
  color: #14d089;
}
.pdf-sider-item-text-active {
  color: #14d089;
}
.pdf-sider-item-text {
  cursor: pointer;
}
.pdf-content-set {
  flex: 1;
}
.pdf-content-preview {
  // width: 300px;
  flex-direction: column;
  margin: 20px;
  .preview {
    width: 400px;
    height: 460px;
  }
}
.el-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-image::v-deep .image-slot {
  font-size: 30px;
  color: #909399;
}
.pdf-content-set-item {
  margin: 10px;
}
</style>
