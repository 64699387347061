<template>
  <div>
    <el-dialog
      title="设置对象"
      :visible.sync="visible"
      width="30%"
      :before-close="closeDialog"
      :close-on-click-modal="false"
    >
      <div>已选择：</div>
      <el-tag
        v-for="item in currentSetObject"
        :key="item"
        closable
        class="objtag"
        @close="delObject(item)"
      >
        {{
          allObject.find(c => c.Id === item)
            ? allObject.find(c => c.Id === item).PJCYDXMC
            : ""
        }}
      </el-tag>
      <div style="border-bottom: 1px solid #e5e5e5; margin: 20px;"></div>
      <div
        v-if="hdxs >= 4"
        class="popo-ovebox-titbox"
        style="padding-top: 40px; width: 100%;"
      >
        <div class="popo-ovebox-tit">主评</div>

        <div style="border-bottom: 1px solid #f2f2f2; padding-bottom: 10px;">
          <div class="disilb-box-l" style="width: 80px; text-align: left;">
            <div class="tablil-xz-name epp-ov-l">参与方式:</div>
          </div>
          <el-select
            size="small"
            v-model="currentJoinType"
            @change="changeSelectJoinType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in evaluationObjectJoinType"
              :disabled="
                (JZKBLX === 3 && item.Key !== 1) ||
                  (JZKBLX === 2 &&
                    currentSetObject.length > 0 &&
                    !allObject
                      .filter(c => currentSetObject.includes(c.Id))
                      .map(c => c.PJCYDXLB)
                      .includes(item.Key))
              "
              :key="item.Key"
              :label="item.Value"
              :value="item.Key"
            ></el-option>
          </el-select>
        </div>
        <div class="popo-ovebox-l" style="background: unset;">
          <el-checkbox-group v-model="currentSetObject">
            <el-checkbox
              :label="item.Id"
              v-for="item in allObject.filter(
                c =>
                  c.PJCYDXLB === currentJoinType &&
                  c.PJFS == PJFS &&
                  c.SYZT === 1 &&
                  c.SFSC === 2 &&
                  c.HDXS === 4
              )"
              :key="item.Id"
              >{{ item.PJCYDXMC }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div
        v-if="[2, 3, 6, 7].includes(hdxs)"
        class="popo-ovebox-titbox"
        style="width: 100%;"
      >
        <div class="popo-ovebox-tit">互评</div>
        <div class="popo-ovebox-l" style="background: unset;">
          <el-checkbox-group v-model="currentSetObject">
            <el-checkbox
              :label="item.Id"
              v-for="item in allObject.filter(
                c =>
                  c.SYZT === 1 &&
                  c.SFSC === 2 &&
                  c.HDXS === 2 &&
                  c.PJFS === PJFS
              )"
              :key="item.Id"
              >{{ item.PJCYDXMC }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div
        v-if="[1, 3, 5, 7].includes(hdxs)"
        class="popo-ovebox-titbox"
        style="width: 100%;"
      >
        <div class="popo-ovebox-tit">自评</div>
        <div class="popo-ovebox-l" style="background: unset;">
          <el-checkbox-group v-model="currentSetObject">
            <el-checkbox
              :label="item.Id"
              v-for="item in allObject.filter(
                c =>
                  c.SYZT === 1 &&
                  c.SFSC === 2 &&
                  c.HDXS === 1 &&
                  c.PJFS === PJFS
              )"
              :key="item.Id"
              >{{ item.PJCYDXMC }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="setObject">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSetObject: [],
      currentJoinType: "",
      sourceObjectIds: []
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    schoolId: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    },
    allObject: {
      type: Array,
      default: function() {
        return [];
      }
    },
    evaluationObjectJoinType: {
      type: Array,
      default: function() {
        return [];
      }
    },
    activityId: {
      type: String,
      default: ""
    },
    hdxs: {
      type: Number,
      default: 0
    },
    PJFS: {
      type: Number,
      default: 0
    },
    evaluationObjectList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    JZKBLX: {
      type: Number,
      default: 1
    }
  },
  created: function() {},
  methods: {
    delObject(data) {
      this.currentSetObject.splice(
        this.currentSetObject.findIndex(c => c === data),
        1
      );
    },
    changeSelectJoinType() {
      // this.currentSetObject=[]
    },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeObjectSetDialog");
      });
    },
    setObject() {
      const setIndexs = [];
      for (let i = 0; i < this.currentSetObject.length; i++) {
        const set = {};
        set.PJCYDXId = this.currentSetObject[i];
        set.HDId = this.activityId;
        setIndexs.push(set);
      }
      this.$api.post(
        "/v1/evaluationobject/SetEvaluationActivityObject",
        {
          token: this.$store.state.token,
          EvaluationActivityObjectSetList: setIndexs,
          EvaluationActivityId: this.activityId,
          SourceObjectIds: this.sourceObjectIds,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.$emit("saveObjectSetDialog", this.activityId, setIndexs);
          }
        }
      );
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.currentJoinType = "";
        this.currentSetObject = [];
      } else {
        for (let i = 0; i < this.evaluationObjectList.length; i++) {
          if (
            this.allObject.find(
              c => c.Id === this.evaluationObjectList[i].PJCYDXId
            )
          ) {
            this.currentSetObject.push(this.evaluationObjectList[i].PJCYDXId);
          }
        }
        if (this.evaluationObjectList && this.evaluationObjectList.length > 0) {
          this.currentJoinType = this.allObject.filter(
            c =>
              this.evaluationObjectList.map(c => c.PJCYDXId).includes(c.Id) &&
              c.PJCYDXLB !== 0 &&
              c.PJCYDXLB
          )[0].PJCYDXLB;
        }
        this.sourceObjectIds = JSON.parse(
          JSON.stringify(this.currentSetObject)
        );
      }
    }
  }
};
</script>
<style scoped>
.objtag {
  margin-right: 10px;
  margin-top: 20px;
}
</style>
