<template>
    <el-dialog top="5vh" :title="dialogTitle" :visible.sync="value" width="60%" ref="dialog"
     :close-on-click-modal="false" :before-close="closeDialog">
        <el-form :model="formData" ref="ruleForm">
            <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
                <el-input style="max-width: 218px" suffix-icon="el-icon-edit" 
                    v-model="form.PXH" autocomplete="on" >
                </el-input>
            </el-form-item>
            <el-form-item label="模块名称" :label-width="formLabelWidth" prop="PXH">
                <el-input style="max-width: 218px" suffix-icon="el-icon-edit" 
                    v-model="form.MKMC" autocomplete="on" >
                </el-input>
            </el-form-item>
            <el-form-item label="模块总分" :label-width="formLabelWidth" prop="PXH">
                <el-input style="max-width: 218px" suffix-icon="el-icon-edit" 
                    v-model="form.MKZF" autocomplete="on" >
                </el-input>
            </el-form-item>
            <el-divider></el-divider>
            <div style="font-weight: 600;">关联</div>
            <el-form-item label="活动及综合报表" :label-width="formLabelWidth" prop="PXH">
                <el-input style="max-width: 218px" suffix-icon="el-icon-edit" 
                    v-model="form.MKZF" autocomplete="on" >
                </el-input>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        dialogTitle:String,
    },
    model: {
        prop: "visible",
        event: "change"
    },
    data(){
        return{
            value:false,
            form:{},
            formData: {},
            formLabelWidth:'20%',

        }
    },
    methods:{
        closeDialog(){
            this.$emit('canceldialog')
        }
    },
    watch:{
        visible(val){
            this.value = val
            // if(val){
            //     this.$set(this, "formData", JSON.parse(JSON.stringify(this.data)));
            // }
        }
    }
}
</script>

<style>

</style>