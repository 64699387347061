<!--
    属性:
    treeData:节点数据
    isLoading:loadng动画。默认为false（一般在获取数据前将此参数值设置为true，获取数据后将数据设为false，即会达到常用效果）
    nodeKey:节点数据的主键名称(必传)
    lazy:是否启用懒加载
    load:懒加载方法
    defaultProps：节点数据的模版对象
    {
          children: "可更改为其它名称，默认为children",
          label: "可更改为其它名称，默认为label",
          parent:"可更改为其它名称，默认为parent",
          isLeaf:"是否有子节点，懒加载的时候有用,可更改为其它名称,默认为isLeaf"
          class:"可更改为其它名称,默认为class",
          icon:"节点图标,默认为icon"
        }
    expandAll:全部展开
    enableCheck:是否启用勾选模式，类型boolean，默认为false
    disableReverseRelation:是否禁止子节点反向关联父组件，类型boolean，默认为false（默认不禁止）
    enableDraggable:是否启用节点拖动，类型boolean，默认为false
    disableDrop:禁止拖动的节点的nodeKey的数组
    disableInsert:禁止放入的节点的nodeKey的数组
    expandedNode:默认展开nodeKey的数组的节点
    checkedNode:默认选中nodeKey的数组的节点
-->
<!---->
<!--
    方法：
    点击节点事件(需要在父级组件中实现，参数接收该事件返回的数据)
    nodeClick(节点数据，节点)
    拖动结束事件(需要在父级组件中实现，参数接收该事件返回的数据)
    onDrop(被拖动的节点数据,放置位置（before、after、inner）,放置相关节点数据)
    勾选节点事件(需要在父级组件中实现，参数接收该事件返回的数据)
    onChange(是否勾选,节点数据)
    获取已勾选的所有节点数据(需要手动调用)
    getCheckedNodes()
    新增节点(需要手动调用)
    addNode(待处理节点, 新节点数据, 排序字段(不需要排序则不传), 排序方式（'asc','desc'不需要排序则不传）)
    删除节点(需要手动调用)
    removeNode(待处理节点)
    更新节点(需要手动调用)
    updateNode(待处理节点, 新节点数据, 排序字段(不需要排序则不传), 排序方式（'asc','desc'不需要排序则不传）)
-->
<template>
  <el-tree
    v-loading="isLoading"
    :data="treeData"
    :props="defaultProps"
    @node-click="handleNodeClick"
    :expand-on-click-node="false"
    :filter-node-method="filterNode"
    :show-checkbox="enableCheck"
    :draggable="enableDraggable"
    :allow-drop="allowDrop"
    :allow-drag="allowDrag"
    @node-drop="handleDrop"
    :check-strictly="disableReverseRelationInner"
    ref="tree"
    highlight-current
    :lazy="lazy"
    :load="load"
    :node-key="nodeKey"
    :default-expanded-keys="expandedNode"
    :default-checked-keys="checkedNode"
    :default-expand-all="expandAll"
    @check="onChange"
  >
    <span class="custom-tree-node" slot-scope="{ node, data }">
      <span :class="data[defaultProps.class]"
        ><i :class="data[defaultProps.icon]"></i>{{ node.label }}</span
      >
    </span>
  </el-tree>
</template>

<script>
import { Compare } from "@/style/js/Common.js";
export default {
  data() {
    return {
      data: [],
      disableReverseRelationInner: false
    };
  },
  props: {
    treeData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    enableCheck: {
      type: Boolean,
      default: false
    },
    disableReverseRelation: {
      type: Boolean,
      default: false
    },
    enableDraggable: {
      type: Boolean,
      default: false
    },
    expandAll: {
      type: Boolean,
      default: false
    },
    defaultProps: {
      type: Object,
      default: function() {
        return {
          children: "children",
          label: "label",
          parent: "parent",
          class: "class",
          icon: "icon"
        };
      }
    },
    nodeKey: {
      type: String,
      default: ""
    },
    disableDrop: {
      type: Array,
      default: function() {
        return [];
      }
    },
    disableInsert: {
      type: Array,
      default: function() {
        return [];
      }
    },
    expandedNode: {
      type: Array,
      default: function() {
        return [];
      }
    },
    checkedNode: {
      type: Array,
      default: function() {
        return [];
      }
    },
    lazy: {
      type: Boolean,
      default: false
    },
    load: {
      type: Function,
      default: function() {}
    },
    classArray: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    addNode(node, data, sortField, sortType) {
      const newData = JSON.parse(JSON.stringify(data));
      const childrenKey = this.defaultProps.children;
      if (!node.data[childrenKey]) {
        this.$set(node.data, childrenKey, []);
      }
      node.data[childrenKey].push(newData);
      if (sortField) {
        node.data[childrenKey].sort(Compare(sortField, sortType));
      }
    },
    removeNode(node) {
      const childrenKey = this.defaultProps.children;
      const parent = node.parent;
      const children = parent.data[childrenKey] || parent.data;
      const index = children.findIndex(d => d === node.data);
      if (index !== -1) {
        children.splice(index, 1);
      }
    },
    updateNode(node, data, sortField, sortType) {
      const childrenKey = this.defaultProps.children;
      const parent = node.parent;
      const children = parent.data[childrenKey] || parent.data;
      const index = children.findIndex(d => d === node.data);
      if (index !== -1) {
        children.splice(index, 1, data);
        if (sortField) {
          children.sort(Compare(sortField, sortType));
        }
      }
    },
    handleNodeClick(data, node) {
      this.$emit("nodeClick", data, node);
    },
    filterNode(value, data) {
      return this.$emit("filterNode", value, data);
    },
    allowDrop(draggingNode, dropNode, type) {
      if (this.nodeKey) {
        if (this.disableInsert.includes(dropNode.data[this.nodeKey])) {
          return type !== "inner";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    allowDrag(draggingNode) {
      if (this.nodeKey) {
        return !this.disableDrop.includes(draggingNode.data[this.nodeKey]);
      } else {
        return true;
      }
    },
    getCheckedNodes() {
      return this.$refs.tree.getCheckedNodes();
    },
    handleDrop(draggingNode, dropNode, dropType) {
      let dropNodeData = dropNode.data;
      this.$emit("onDrop", draggingNode.data, dropType, dropNodeData);
    },
    onChange(data, checked) {
      const statu = checked.checkedKeys.includes(data[this.nodeKey]);
      if (this.disableReverseRelation) {
        this.setRelatedChecked(data, statu);
      }
      this.$emit("onChange", statu, data);
    },
    setRelatedChecked(data, statu) {
      const childrenKey = this.defaultProps.children;
      const parentKey = this.defaultProps.parent;
      for (const item of Array.from(
        new Set(
          []
            .concat(data.id)
            .concat(this.findChildrenNode(data, childrenKey))
            .concat(
              statu
                ? this.findParentNode(
                    data[this.nodeKey],
                    childrenKey,
                    parentKey
                  )
                : []
            )
        )
      )) {
        this.$refs.tree.setChecked(item, statu);
      }
    },
    findParentNode(value, childrenKey, parentKey) {
      const datas = this.treeData;
      var arrRes = [];
      if (datas.length === 0) {
        if (value) {
          arrRes.unshift(value);
        }
        return arrRes;
      }
      const rev = (data, nodeId) => {
        for (var i = 0, length = data.length; i < length; i++) {
          const node = data[i];
          if (node[this.nodeKey] === nodeId) {
            arrRes.unshift(nodeId);
            rev(datas, node[parentKey]);
            break;
          } else {
            if (node[childrenKey] && node[childrenKey].length > 0) {
              rev(node[childrenKey], nodeId);
            }
          }
        }
        return arrRes;
      };
      arrRes = rev(datas, value);
      return arrRes;
    },
    findChildrenNode(data, childrenKey) {
      let array = [];
      if (data[childrenKey] && data[childrenKey].length > 0) {
        array = data[childrenKey].map(c => c[this.nodeKey]);
        for (const item of data[childrenKey]) {
          array = array.concat(this.findChildrenNode(item, childrenKey));
        }
      }
      return array;
    }
  },
  created: function() {
    this.disableReverseRelationInner =
      this.nodeKey.length > 0 && this.disableReverseRelation;
    // this.data = this.treeData;
  }
};
</script>
<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
