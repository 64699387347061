<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div style="padding: 30px 30px 10px 30px">
          <div class="stud-box" v-loading="isLogin">
            <div class="sm-conten-tit">请选择评价活动</div>
            <ul class="stud-box-ul" v-show="hdlist.length > 0" style="height: auto;">
              <li class="stud-box-ulli" v-for="hd in hdlist" :key="hd.Id" @click="activityonclick(hd)">
                <img class="stud-box-ulli-img" :src="hd.HDTP ? hd.HDTP : defaultImg" />
                <div class="stud-name">{{ hd.Name }}</div>
              </li>
            </ul>
            <div v-show="hdlist.length === 0" style="padding:15px;text-align:center;font-size:16px">
              暂无活动
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选择子活动" :visible.sync="showHDchild" width="60%" :before-close="() => (showHDchild = false)">
      <div class="childHD-list">
        <div class="child-item" v-for="item in mainHD.EvaChildList" :key="item.ID" @click="closeChild(item)">
          <el-image :src="item.Picture ? item.Picture : defaultImg"></el-image>
          <div class="name">
            {{ item.Name }}
          </div>
        </div>
      </div>
      <div v-show="!mainHD.EvaChildList || mainHD.EvaChildList.length === 0"
        style="padding:15px;text-align:center;font-size:16px">
        暂无活动
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import defaultImg from '@/style/images/activity_default.jpg'
  import {
    compareVerson
  } from '@/utils/versionNo.js'
  import {
    getVersionNo
  } from '@/api/versionNo.js'

  export default {
    name: 'stageactivity',
    created() {
      this.ssxq = localStorage.getItem('currentterm')
      var tempuser = localStorage.getItem('userinfo')
      if (tempuser) {
        this.user = JSON.parse(tempuser)
      }
    },
    data() {
      return {
        isLogin: true,
        user: {},
        allNJ: [],
        njlist: [],
        hdlist: [],
        teacherSign: {},
        teacherClassId: {},
        ssxq: '',
        term: {},
        showHDchild: false,
        mainHD: {},
        childHD: {},
        defaultImg
      }
    },
    mounted() {
      this.loaddata()
    },
    methods: {
      activityonclick(hd) {
        this.mainHD = hd
        if (hd.EvaChildList) this.showHDchild = true
        else
          this.$router.push({
            name: 'stagecourse',
            params: {
              hd,
              teacherSign: this.teacherSign,
              userNJ: this.njlist,
              allNJ: this.allNJ,
              term: this.term
            }
          })
      },
      closeChild(hd) {
        this.showHDchild = false
        this.childHD = hd
        this.$router.push({
          name: 'stagecourse',
          params: {
            hd,
            teacherSign: this.teacherSign,
            userNJ: this.njlist,
            allNJ: this.allNJ,
            term: this.term
          }
        })
      },
      // 加载数据
      async loaddata() {
        if (
          !this.$store.getters.versionNo ||
          this.$store.getters.versionNo.length === 0
        )
          await getVersionNo(this.user)
        await this.getTerms()
        await this.getAllActivities()
        await this.getTeacherSign()
        await this.getTeacherClassId()
        await this.getNJ()
        this.handleTeacherNj()
        await this.getTeacherCourse()

        let bjArr = this.njlist.reduce((pre, cur) => {
          return cur.ChidOrgList ? pre.concat(cur.ChidOrgList) : pre
        }, [])

        for (let i = 0; i < bjArr.length; i++) {
          await this.getBJcourse(bjArr[i], this.teacherCourseTable)
        }

        this.filterLast()
      },
      // 获取学期
      getTerms() {
        let terms = JSON.parse(localStorage.getItem('terms'))
        return new Promise((resolve, reject) => {
          if (!compareVerson(1, terms && terms.length > 0)) {
            this.$api.post(
              '/v1/accountmanage/GetTerm', {
                SchoolId: this.user.SSZZId,
                token: this.user.Token
              },
              res => {
                if (res.Code === 0) {
                  localStorage.setItem('terms', JSON.stringify(res.Target || []))
                  this.term = res.Target.find(item => item.Id === this.ssxq)
                  resolve()
                } else {
                  reject(undefined)
                }
              }
            )
          } else {
            this.term = terms.find(item => item.Id === this.ssxq)
            resolve()
          }
        })
      },
      // 获取活动
      getAllActivities() {
        let activities = JSON.parse(localStorage.getItem('activities'))
        return new Promise((resolve, reject) => {
          if (!compareVerson(11, activities && activities > 0)) {
            this.$api.post(
              '/v1/evaluationactivity/GetStageActivities', {
                SchoolId: this.user.SSZZId,
                // SJGS:this.user.SSZZId,
                TermIdOrYear: this.ssxq,
                BeginTime: this.term.BeginTime,
                EndTime: this.term.EndTime,
                token: this.user.Token
              },
              r => {
                if (r.Code === 0) {
                  localStorage.setItem(
                    'activities',
                    JSON.stringify(r.Target || [])
                  )
                  this.hdlist = r.Target
                  resolve()
                }
              },
              err => {
                this.loading = false
                this.$message.err(err)
                reject(err)
              }
            )
          } else {
            this.hdlist = activities
            resolve()
          }
        })
      },
      // 获取老师能够参与活动的标识
      getTeacherSign() {
        return new Promise((resolve, reject) => {
          this.$api.post(
            '/v1/evaluationactivity/GetTeacherSign', {
              TeacherId: this.user.Id,
              SchoolId: this.user.SSZZId,
              SchoolCode: this.user.SchoolCode,
              token: this.user.Token
            },
            res => {
              if (res.Code === 0) {
                this.teacherSign = res.Target
                resolve()
              }
            },
            err => {
              this.loading = false
              this.$message.err(err)
              reject(err)
            }
          )
        })
      },
      // 获取班主任所带班级和科任班级
      getTeacherClassId() {
        return new Promise((resolve, reject) => {
          this.$api.post(
            'v1/accountmanage/GetUserClassOrCurriculum', {
              UserId: this.user.Id,
              TermId: this.ssxq,
              token: this.user.Token
            },
            r => {
              if (r.Code === 0) {
                this.teacherClassId = r.Target
                resolve()
              }
            },
            err => {
              this.loading = false
              this.$message.err(err)
              reject(err)
            }
          )
        })
      },
      // 获取学校所有年级
      getNJ() {
        let allNJ = JSON.parse(localStorage.getItem('allNJ'))
        return new Promise((resolve, reject) => {
          if (!compareVerson(2, allNJ && allNJ.length > 0)) {
            this.$api.post(
              'v1/accountmanage/GetSchoolOrg', {
                SchoolId: this.user.SSZZId,
                token: this.user.Token
              },
              r => {
                if (r.Code === 0) {
                  localStorage.setItem('allNJ', JSON.stringify(r.Target || []))
                  this.allNJ = r.Target
                  this.njlist = JSON.parse(JSON.stringify(this.allNJ))
                  resolve()
                }
              },
              err => {
                this.loading = false
                this.$message.err(err)
                reject(err)
              }
            )
          } else {
            this.allNJ = allNJ
            this.njlist = JSON.parse(JSON.stringify(this.allNJ))
            resolve()
          }
        })
      },
      // 处理老师带的年级 班级
      handleTeacherNj() {
        // 筛选教师所在的年级
        this.njlist = this.njlist.filter(nj => {
          if (nj.ChidOrgList && nj.ChidOrgList.length > 0) {
            nj.ChidOrgList = nj.ChidOrgList.reduce((pre, cur) => {
              let flag = false
              if (cur.Id === this.teacherClassId.TeacherClass) {
                //班主任年级 班级
                nj.main = true
                cur.main = true
                flag = true
              }
              if (
                this.teacherClassId.LecturerClass.some(bjId => bjId === cur.Id)
              ) {
                //任课年级 班级
                nj.KR = true
                cur.KR === true
                flag = true
              }

              return flag ? pre.concat(cur) : pre
            }, [])
            return nj.ChidOrgList && nj.ChidOrgList.length > 0 ? true : false
          }
        })
      },
      // 获取老师所教课程
      getTeacherCourse() {
        return new Promise((resolve, reject) => {
          this.$api.post(
            'v1/accountmanage/GetUserCurriculum', {
              UserIdOrGrade: this.user.Id,
              TermId: this.ssxq,
              SchoolId: this.user.SSZZId,
              GetDataType: 1, //1. 教师所授课程 2.班级所授课程
              token: this.user.Token
            },
            res => {
              if (res.Code === 0) {
                if (res.Target && res.Target.length === 0)
                  this.$message.error('未获取到教师课表')

                let arr = []
                if (res.Target) {
                  arr = res.Target.reduce((pre, cur) => {
                    return pre.findIndex(
                        item => item.CourseId === cur.CourseId
                      ) === -1 ?
                      pre.concat(cur) :
                      pre
                  }, [])
                }
                this.teacherCourseTable = arr
                resolve()
              }
            },
            err => {
              this.loading = false
              this.$message.err(err)
              reject(err)
            }
          )
        })
      },
      // 班级所授课程
      getBJcourse(bj, course) {
        return new Promise((resolve, reject) => {
          this.$api.post(
            'v1/cousertimetable/GetCourseTableList', {
              SchoolId: this.user.SSZZId,
              TermId: this.ssxq,
              Period: 1,
              ClassId: bj.Id,
              token: this.user.Token
            },
            res => {
              if (res.Code == 0) {
                bj.course = []
                res.Target.ContentRowList.forEach(item => {
                  item.ContentList.forEach(it => {
                    if (it.TeacherCourseList && it.TeacherCourseList.length > 0) {
                      it.TeacherCourseList.forEach(el => {
                        if (
                          course.some(
                            e =>
                            e.CourseId === el.TeacherCourseId &&
                            this.user.XSM === el.TeacherName
                          )
                        ) {
                          if (
                            !bj.course.some(
                              item =>
                              item.courseId === el.TeacherCourseId &&
                              item.courseName === el.CourseName
                            )
                          ) {
                            bj.course.push({
                              nj: bj.ParentId,
                              bj: bj.Id,
                              courseName: el.CourseName,
                              courseId: el.TeacherCourseId
                            })
                          }
                        }
                      })
                    }
                  })
                })
                resolve()
              }
            },
            err => {
              this.loading = false
              this.$message.err(err)
              reject(err)
            }
          )
        })
      },
      // 简单过滤不匹配的活动
      filterActivity() {
        function fn(hd) {
          // 没有对象的活动不显示
          if (!hd.UserEObjectList || hd.UserEObjectList.length === 0) return false
          // 筛选参评人为老师的主评或互评的阶段活动 先只要老师主评学生的活动，老师主评老师 老师互评 老师自评的暂时不要
          // (hd.ActiveType === 2 ||
          //   hd.ActiveType === 4 ||
          //   hd.ActiveType === 6 ||
          //   hd.ActiveType === 7) &&
          // hd.HDCPR === 1
          else if (hd.HDCPR === 1 && hd.EvaluatedObject === 1) {
            // 将活动中的自评对象过滤掉 互评且被评人为学生的对象过滤掉 对象没得指标的也过滤掉
            hd.UserEObjectList = hd.UserEObjectList.filter(it => {
              if (it.ObjectType !== 1) {
                // 自评的不需要

                // 互评且被评人为学生的不需要
                if (it.ObjectType === 2 && hd.EvaluatedObject !== 2) return false
                // 对象中没得指标的也不需要
                else if (!it.EvaIndexList) return false
                else {
                  it.RoleOrCourse = JSON.parse(it.RoleOrCourse)
                  // 将对象中的指标转为数组
                  it.EvaIndexList = it.EvaIndexList ?
                    JSON.parse(it.EvaIndexList) :
                    []
                  return true
                }
              } else return false
            })
            return hd.UserEObjectList.length > 0 ? true : false
          } else return false
        }
        this.hdlist = this.hdlist.filter(item => {
          if (item.EvaChildList && item.EvaChildList.length > 0) {
            let {
              EvalutionFormat,
              ActiveType,
              HDCPR,
              EvaluatedObject,
              Id,
              CourseTableType
            } = item

            item.EvaChildList = item.EvaChildList.filter(it => {
              it.parentId = Id
              it.ActiveType = ActiveType
              it.HDCPR = HDCPR
              it.EvaluatedObject = EvaluatedObject
              it.CourseTableType = CourseTableType
              it.EvalutionFormat = EvalutionFormat

              return fn(it)
            })
            return true
          } else return fn(item)
        })
      },
      // 详细筛选用户能够参与的对象
      handlObj(hd) {
        // 将活动上的有用信息赋值到对象上 方便后面筛选
        hd.UserEObjectList.forEach(obj => {
          obj.my_custom = {
            enabled: false, //对象是否可用
            parentId: hd.Id, //活动Id
            CourseTableType: hd.CourseTableType, //活动课表参与类型
            cpr: hd.HDCPR, //参评人
            cprCourse: [], //参评人可评学科
            cprType: [], //参评人角色集1.班主任 2.科任 3.自定义老师 4.机构 5.全部老师
            cprGrade: [], //参评人可评年级
            cprBJ: [], //参评人可评班级
            bpr: hd.EvaluatedObject, //被评人类型
            bprType: [] //被评人角色
          }
        })
        // 处理对象数据
        hd.UserEObjectList.forEach(obj => {
          // 教师是否被指定参与这个活动
          if (this.teacherSign.PJHDIds && this.teacherSign.PJHDIds > 0) {
            if (this.teacherSign.PJHDIds.includes(obj.my_custom.parentId)) {
              obj.my_custom.enabled = true
            }
          } else if (obj.my_custom.CourseTableType === 1) {
            // 无课表
            if (obj.GradeNumbers && obj.GradeNumbers.length > 0) {
              // 老师被评人为学生
              obj.RoleOrCourse.forEach(el => {
                if (el.DataType === 2) {
                  // 角色参与
                  if (el.RoleType === 1) {
                    //班主任主评学生--------- 教师是否为班主任 班主任所在的年级是否在对象所评年集中 如果是 就将班主任所带班 放入nj数组中
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      if (
                        this.teacherSign.ClassTeacher &&
                        this.teacherSign.ClassTeacher.length > 0
                      ) {
                        this.njlist.forEach(nj => {
                          if (
                            nj.main &&
                            obj.GradeNumbers.includes(nj.GradeNumber + '')
                          ) {
                            obj.my_custom.enabled = true
                            obj.my_custom.cprType.push({
                              type: 1,
                              JGPJLX: 0
                            })
                            nj.ChidOrgList.forEach(bj => {
                              if (bj.main) {
                                obj.my_custom.cprBJ.push({
                                  nj: nj.Id,
                                  bj: bj.Id,
                                  bjType: 1,
                                  Name: bj.Name
                                })
                              }
                            })
                            obj.my_custom.cprGrade.push(nj)
                          }
                        })
                      }
                    }
                  } else if (el.RoleType === 3) {
                    // 科任就是在一个班一直教的学科的老师 任课就是上课的意思 可能是临时调配的老师
                    //科任主评学生-------------老师带的除了班主任班 其它班所在年级都是任课班
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      this.njlist.forEach(nj => {
                        if (obj.GradeNumbers.includes(nj.GradeNumber + '')) {
                          nj.ChidOrgList.forEach(bj => {
                            obj.my_custom.enabled = true
                            obj.my_custom.cprType.push({
                              type: 2,
                              JGPJLX: 0
                            })
                            let idx = obj.my_custom.cprBJ.findIndex(
                              item => item.bj === bj.Id
                            )
                            if (idx === -1) {
                              obj.my_custom.cprBJ.push({
                                nj: nj.Id,
                                bj: bj.Id,
                                bjType: 2,
                                Name: bj.Name
                              })
                            }
                          })

                          let idx2 = obj.my_custom.cprGrade.findIndex(
                            item => item.Id === nj.Id
                          )
                          if (idx2 === -1) {
                            obj.my_custom.cprGrade.push(nj)
                          }
                        }
                      })
                    }
                  } else {
                    // 其他角色评学生 ---------其他角色不再受班级的限制 只要参评人满足其他角色 那么参评人就可以评对象所评年级的所有班级
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      this.allNJ.forEach(nj => {
                        if (obj.GradeNumbers.includes(nj.GradeNumber + '')) {
                          nj.all = true
                          obj.my_custom.cprGrade.push(nj)
                          nj.ChidOrgList.forEach(bj => {
                            obj.my_custom.cprBJ.push({
                              bj: bj.Id,
                              bjType: 3,
                              nj: nj.Id,
                              Name: bj.Name
                            })
                          })
                        }
                      })

                      if (obj.my_custom.cprGrade.length > 0) {
                        obj.my_custom.enabled = true
                        obj.my_custom.cprType.push({
                          type: 3,
                          JGPJLX: ''
                        })
                      }
                    }
                  }
                } else {
                  // 机构评学生暂时禁用
                  // 机构主评学生  教师所在机构是否在对象的机构中 老师所在的机构可以参评勾选的所有年级
                  // if (
                  //   this.teacherSign.JGIds &&
                  //   this.teacherSign.JGIds.length > 0 &&
                  //   this.teacherSign.JGIds.some(jg =>
                  //     jg.JGId.includes(el.RoleCourseId)
                  //   )
                  // ) {
                  //   this.allNJ.forEach(nj => {
                  //     if (obj.GradeNumbers.includes(nj.GradeNumber + "")) {
                  //       nj.all = true;
                  //       obj.my_custom.cprGrade.push(nj);
                  //     }
                  //   });
                  //   if (obj.my_custom.cprGrade.length > 0) {
                  //     obj.my_custom.enabled = true;
                  //     obj.my_custom.cprType.push({
                  //       type: 4,
                  //       JGPJLX: 3 //1.组长主评 2.组员主评 3.这个组所有人
                  //     });
                  //   }
                  // }
                }
              })
            } else {
              // 老师评老师
              obj.RoleOrCourse.forEach(el => {
                if (obj.ObjectType === 4 && el.DataType === 2 && el.PJLX === 1) {
                  // 主评  参评人角色参与

                  if (el.RoleType === 1) {
                    // 参评人为班主任
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      obj.my_custom.enabled = true
                      obj.my_custom.cprType.push({
                        type: 1,
                        JGPJLX: ''
                      })
                    }
                  } else if (el.RoleType === 3) {
                    // 科任老师
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      obj.my_custom.enabled = true
                      obj.my_custom.cprType.push({
                        type: 2,
                        JGPJLX: ''
                      })
                    }
                  } else {
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      obj.my_custom.enabled = true
                      obj.my_custom.cprType.push({
                        type: 3,
                        JGPJLX: ''
                      })
                    }
                  }
                }
                // 被评人角色参与

                if (obj.ObjectType === 4 && el.DataType === 2 && el.PJLX === 2) {
                  // 被评老师为班主任
                  if (el.RoleType === 1) {
                    obj.my_custom.bprType.push({
                      type: 1,
                      typeId: 'df7275bc-7241-400b-a84c-5529ab789a43'
                    })
                  } else if (el.RoleType === 3) {
                    // 被评老师为科任
                    obj.my_custom.bprType.push({
                      type: 2,
                      typeId: '2f8c993e-1384-4237-bc7b-2d621dc748d3'
                    })
                  } else {
                    if (
                      el.RoleCourseId === '11662cb1-2c7d-42a9-9d1f-aad1cd149822'
                    ) {
                      // 角色之全部老师
                      obj.my_custom.bprType.push({
                        type: 5,
                        typeId: el.RoleCourseId
                      })
                    } else {
                      // 其它角色
                      obj.my_custom.bprType.push({
                        type: 3,
                        typeId: el.RoleCourseId
                      })
                    }
                  }
                }
                // 参评人机构参与 主评
                if (obj.ObjectType === 4 && el.DataType === 4 && el.PJLX === 1) {
                  if (
                    this.teacherSign.JGIds &&
                    this.teacherSign.JGIds.some(
                      jg =>
                      jg.JGId.includes(el.RoleCourseId) && jg.ZZLB === el.JGPJLX
                    )
                  ) {
                    obj.my_custom.enabled = true
                    obj.my_custom.bprType.push({
                      type: 4,
                      JGPJLX: el.JGPJLX === 1 ? 2 : 1,
                      JGId: el.RoleCourseId
                    })
                  }
                }

                // 互评 被评人角色参与
                if (obj.ObjectType === 2 && el.DataType === 2 && el.PJLX === 2) {
                  if (el.RoleType === 1) {
                    // 互评班主任 参评人是否为班主任
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      obj.my_custom.enabled = true
                      obj.my_custom.bprType.push({
                        type: 1
                      })
                    }
                  } else if (el.RoleType === 3) {
                    //  互评科任 参评人是否为科任老师
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      obj.my_custom.enabled = true
                      obj.my_custom.bprType.push({
                        type: 2
                      })
                    }
                  } else {
                    if (
                      this.teacherSign.RoleIds &&
                      this.teacherSign.RoleIds.includes(el.RoleCourseId)
                    ) {
                      obj.my_custom.enabled = true
                      if (
                        el.RoleOrCourse === '11662cb1-2c7d-42a9-9d1f-aad1cd149822'
                      ) {
                        // 被评人为全部老师
                        obj.my_custom.bprType.push({
                          type: 5,
                          typeId: el.RoleCourseId
                        })
                      } else {
                        // 被评人为其它角色
                        obj.my_custom.bprType.push({
                          type: 3,
                          typeId: el.RoleCourseId
                        })
                      }
                    }
                  }
                }
                // 互评机构
                if (obj.ObjectType === 2 && el.DataType === 4 && el.PJLX === 2) {
                  if (
                    this.teacherSign.JGIds &&
                    this.teacherSign.JGIds.some(jg =>
                      jg.JGId.includes(el.RoleCourseId)
                    )
                  ) {
                    obj.my_custom.enabled = true
                    obj.my_custom.bprType.push({
                      type: 4,
                      JGPJLX: 3,
                      JGId: el.RoleCourseId
                    })
                  }
                }
              })
            }
          } else if (obj.my_custom.CourseTableType === 3) {
            // 参评人课表
            this.njlist.forEach(nj => {
              // 参评人所带年级是否在对象年级中
              if (obj.GradeNumbers.includes(nj.GradeNumber + '')) {
                //参评人所带年级中所教学科是否在对象中
                let kcarr = []
                nj.ChidOrgList.forEach(bj => {
                  if (bj.course && bj.course.length > 0) {
                    bj.course.forEach(kc => kcarr.push(kc))
                  }
                })

                kcarr.forEach(kc =>
                  obj.RoleOrCourse.forEach(el => {
                    if (kc.courseId === el.RoleCourseId) {
                      obj.my_custom.enabled = true
                      obj.my_custom.cprCourse.push(kc)
                    }
                  })
                )
              }
            })
          }
        })
      },
      // 最后再出来一遍
      filterLast() {
        this.filterActivity()
        this.hdlist.forEach(hd => {
          if (hd.EvaChildList) {
            hd.EvaChildList.forEach(childHD => {
              this.handlObj(childHD)
            })
          } else this.handlObj(hd)
        })

        // 详细处理过活动的每个对象后 再过滤掉不可用对象和活动
        this.hdlist = this.hdlist.filter(hd => {
          if (hd.EvaChildList) {
            // 筛选可用的子活动
            hd.EvaChildList = hd.EvaChildList.filter(childHD => {
              // 没得阶段的活动也不要
              if (childHD.ActEvaStageList && childHD.ActEvaStageList.length === 0)
                return false
              else {
                // 子活动是否有可用对象
                childHD.UserEObjectList = childHD.UserEObjectList.filter(
                  obj => obj.my_custom.enabled
                )
                return childHD.UserEObjectList.length > 0 ? true : false
              }
            })
            return hd.EvaChildList.length > 0 ? true : false
          } else {
            // 筛选活动的可用对象
            hd.UserEObjectList = hd.UserEObjectList.filter(
              obj => obj.my_custom.enabled
            )

            // 活动是否有可用的对象
            return hd.UserEObjectList.length > 0 ? true : false
          }
        })
        this.hdlist.sort((a, b) => a.Name.localeCompare(b.Name))
        this.isLogin = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .childHD-list {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;

    .child-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #fff;

      &:hover {
        border-radius: 5px;
        border: 1px solid #27ba4f;
      }

      .el-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .name {
        margin-top: 5px;
      }
    }
  }
</style>