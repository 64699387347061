<template>
  <div class="tab-heng-box margno-t">
    <div class="xt-back-boxl">
      <div
        class="xt-back-box"
        :style="
          'background: url(' +
            backGroundImageData +
            ')no-repeat center center;background-size: cover;-webkit-background-size: cover;-o-background-size: cover;background-position: center center;'
        "
      ></div>
    </div>
    <div class="textali-cent-box">
      <div class="tab-wz-buttl">
        <Upload @getResultUrl="save" :html="html" :mode="'split'" />
      </div>
    </div>
  </div>
</template>

<script>
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Upload
  },
  data() {
    return {
      img: "",
      token: "",
      user: {},
      schoolId: "",
      html:
        '<a class="tab-wz-butta c-guree" href="javascript:void(0);">更换背景图片</a>'
    };
  },
  props: {
    backGroundImageData: {
      type: String,
      default: require("@/style/images/jxhd_back.png")
    }
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
  },
  methods: {
    save(filePath) {
      const form = { TP: filePath, CJR: this.user.Id, SSXX: this.schoolId };
      this.$api.post(
        "/v1/growthsystem/SetBackgroundImage",
        {
          token: this.token,
          GRBBJTSZInfo: form,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.$emit("saveBackGroundImageData", filePath);
          }
        }
      );
    }
  }
};
</script>
