<template>
  <!-- 此控件除了传入参数字段，还需传入回调方法@getResultUrl -->
  <div style="display: inline-block;">
    <el-upload
      ref="upload"
      class="avatar-uploader"
      :action="uploadUrl"
      :show-file-list="false"
      :file-list="fileList"
      :before-upload="beforeAvatarUpload"
      :on-change="onChange"
      :auto-upload="false"
    >
      <div v-if="html" v-html="html"></div>
      <slot v-else-if="button"></slot>
      <i v-else class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :title="title" :visible.sync="cropperVisible" width="30%" center>
      <div style="width:100%;height:500px">
        <!-- <vueCropper
          v-if="resultUrl"
          ref="cropper"
          :img="imgUrl"
          outputType="png"
          @realTime="realTimeImg"
        ></vueCropper> -->
        <vue-cropper
          autoCrop
          :img="imgUrl"
          ref="cropper"
          :centerBox="false"
          :fixed="fixed"
          :fixedNumber="fixedNumber"
          :outputType="fileExten"
          :autoCropWidth="autoCropWidth"
          :autoCropHeight="autoCropHeight"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cropperVisible = false">取 消</el-button>
        <el-button type="primary" @click="getCropData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper
  },
  data() {
    return {
      modeArray: ["merge", "split"], // merge:图片代替按钮。split:图片不代替按钮
      resultUrl: "",
      radius: 0,
      option: undefined,
      cropperVisible: false,
      imgUrl: undefined,
      fileList: [],
      files: [],
      uploadUrl: "",
      fileExten: "",
      autoCropWidth: 0,
      autoCropHeight: 0,
      fileAA: ""
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    // 控件显示图片
    itemPic: {
      type: String,
      default: ""
    },
    // 控件所属Id
    itemId: {
      type: String,
      default: ""
    },
    // 控件所属对象
    item: {
      type: Object,
      default: function() {
        return undefined;
      }
    },
    // 控件显示图片宽
    width: {
      type: Number,
      default: 0
    },
    // 控件显示图片高
    height: {
      type: Number,
      default: 0
    },
    // 控件显示图片左边距
    left: {
      type: Number,
      default: 0
    },
    // 控件显示图片顶部边距
    top: {
      type: Number,
      default: 0
    },
    // 图片是否为圆型:默认为方形
    circle: {
      type: Boolean,
      default: false
    },
    // 代替控件的html代码
    html: {
      type: String,
      default: ""
    },
    // 模式
    // 'merge:图片代替按钮。split:图片不代替按钮
    mode: {
      type: String,
      default: "merge"
    },
    button: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fileType: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fixed: {
      type: Boolean,
      default: false
    },
    fixedNumber: {
      type: Array,
      default: () => {
        return [];
      }
    },
    cropWidth: {
      type: Number,
      default: 0
    },
    cropHeight: {
      type: Number,
      default: 0
    }
  },
  methods: {
    // handleAvatarSuccess(res, file) {
    //   // this.imageUrl='https://img.nga.178.com/avatars/2002/faf/891/002/42540975_0.jpg?10'
    //   if (res.Code === 0) {
    //     const img = URL.createObjectURL(file.raw);
    //     if (this.mode === this.modeArray[0]) {
    //       this.resultUrl = img;
    //       // this.imgUrl = img;
    //       // this.cropperVisible = true;
    //     }
    //     // this.$emit(
    //     //   "getResultUrl",
    //     //   res.Target.FilePath,
    //     //   this.item ? this.item : this.itemId
    //     // );
    //   } else {
    //     this.$message({
    //       type: "error",
    //       message: "上传接口出错"
    //     });
    //   }
    // },
    beforeAvatarUpload(file) {
      console.log(file, "file");
      this.fileAA = file.raw;
      console.log(typeof file.size, "typeofsize");
      const fileType = (this.fileType.length > 0
        ? this.fileType
        : ["image/jpeg", "image/png"]
      ).includes(file.raw.type);
      const fileSize = file.size / 1024 / 1024 <= 2;

      // const fileType = true;
      // const fileSize = true;
      if (!fileType) {
        this.$message.error(
          "上传图片只能是 " +
            (this.fileType.length > 0
              ? this.fileType.map(c => c.replace("image/", "")).join("或者")
              : "JPG或者png") +
            " 格式!"
        );
      }
      if (!fileSize) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      new Promise(function(resolve) {
        let image = new Image();
        image.onload = function() {
          resolve(image);
        };
        image.src = URL.createObjectURL(file.raw);
      }).then(
        res => {
          this.autoCropWidth = (res.width * this.cropWidth) / 100;
          this.autoCropHeight = (res.width * this.cropHeight) / 100;
        },
        () => {
          return Promise.reject();
        }
      );
      return fileType && fileSize;
    },
    onChange(file, fileList) {
      if (this.beforeAvatarUpload(file)) {
        this.imgUrl = URL.createObjectURL(file.raw);
        this.files = file.raw;
        this.fileExten = file.name
          .slice(file.name.indexOf(".") + 1)
          .toLowerCase();
        this.cropperVisible = fileList.length > 0 ? true : false;
      }
    },
    getCropData() {
      this.$refs.cropper.getCropData(data => {
        this.submit(data.replace(/^data:image\/\w+;base64,/, ""));
      });
      // this.$refs.cropper.getCropBlob(data => {
      //   console.log(data, "data");
      //   this.submit(data);
      // });
    },
    submit(data) {
      // const formdata = new FormData();
      // formdata.append("id", 1);
      // formdata.append("file", this.fileAA);
      // formdata.append("file", data);
      // let xhr = new XMLHttpRequest();
      // xhr.open(
      //   "POST",
      //   "/file/file/fileservice/uploadfile?key=123&attendid=123&attachtype=3&token=" +
      //     this.$store.state.token,
      //   true
      // );
      // xhr.setRequestHeader("Content-Type", "multipart/form-data");
      // xhr.send(`file:${data}`);
      // xhr.send(formdata);
      // xhr.onreadystatechange = () => {
      //   if (xhr.readyState === 4 && xhr.status === 200) {
      //     console.log(xhr, "xhr");
      //     if (xhr.response) {
      //       console.log(JSON.parse(xhr.response), "response");
      //     }
      //   }
      // };
      // this.$api.post(
      //   "/file/file/fileservice/uploadfile?key=123&attendid=123&attachtype=3&token=" +
      //     this.$store.state.token,
      //   data,
      //   res => {
      //     console.log(res, "res");
      //   }
      // );
      this.$api.post(
        "/v1/fileservice/fileupload",
        {
          FileBaseString: data,
          FileExten: this.fileExten,
          AttachType: 3,
          FileStatus: 5,
          token: this.$store.state.token
        },
        r => {
          this.$emit(
            "getResultUrl",
            r.Target.FilePath,
            this.item ? this.item : this.itemId
          );
          this.cropperVisible = false;
        },
        undefined,
        "/file/"
      );
    }
  }
};
</script>
