<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    // 页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("evaluationStore")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("evaluationStore"))
        )
      );
    }

    // 刷新页面之前将store中的数据保存下来
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        "evaluationStore",
        JSON.stringify(this.$store.state)
      );
    });
  },
  props: {},
  methods: {}
};
</script>

<style lang="css">
@import "./style/css/common.css";
@import "./style/css/reset.css";
@import "./style/css/custom.css";
@import "./style/css/superTables.css";
@import "./style/css/tableStyle.css";
</style>
