<template>
  <div>
    <el-button type="primary" @click="addNode">添加等级</el-button>
    <div class="dataBox" v-if="setGradeList.length > 0">
      <div class="left-box">
        <div class="item" v-for="(item, index) in setGradeList" :key="index">
          <span
            class="node"
            :class="{ active: currentIndex == index }"
            @click="choseCurNode(item, index)"
            >{{ item.label }}</span
          >
          <el-button type="text" size="mini" @click="addChildNode(item, 2)">
            添加子级
          </el-button>
          <el-button type="text" size="mini" @click="editNode(item, 3)">
            编辑
          </el-button>
          <el-button type="text" size="mini" @click="delNode(item.Id)">
            删除
          </el-button>
        </div>
      </div>
      <div class="custom-divider"></div>
      <div class="right-box">
        <div class="div" v-if="rightData.length > 0">
          <div class="item" v-for="it in rightData" :key="it.Id">
            <span class="node">{{ it.label }}</span>
            <el-button type="text" size="mini" @click="editNode(it, 3)">
              编辑
            </el-button>
            <el-button type="text" size="mini" @click="delNode(it.Id)">
              删除
            </el-button>
          </div>
        </div>
        <div v-else class="notips">
          未设置子级
        </div>
      </div>
    </div>
    <div style="margin-top:20px;" v-else>
      未设置等级
    </div>
    <!-- 等级设置弹窗 -->
    <el-dialog
      title="等级设置弹窗"
      top="30vh"
      width="45%"
      :visible.sync="setGradeInformVisible"
      append-to-body
    >
      <el-form :model="setGradeInfo" ref="setGradeInfo">
        <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
          <el-input
            v-model="setGradeInfo.PXH"
            style="max-width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="等级名称" :label-width="formLabelWidth" prop="MC">
          <el-input
            v-model="setGradeInfo.MC"
            style="max-width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="margin-top:10px"
          label="等级分数"
          :label-width="formLabelWidth"
          prop="FS"
        >
          <el-input-number
            v-model="setGradeInfo.FS"
            :min="0"
            :max="100"
            :precision="3"
            style="max-width: 300px;"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer center">
        <el-button @click="setGradeInformVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmSetGradeDialog"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <div slot="footer" class="dialog-footer" style="margin-top:15px">
      <el-button @click="cancleOuter">取 消</el-button>
      <el-button type="primary" @click="confirmOuter">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    GradeInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    setGradeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  data() {
    return {
      setGradeInfo: {},
      // setGradeList:[],   //设置等级List
      formLabelWidth: '120px',
      tc: false,
      setGradeInformVisible: false,
      id: 1,
      currentNode: {},
      type: 1, //同级或子级
      ssxx: '',
      CJ: 0, //添加的层级,
      rightData: [],
      currentIndex: 0
    }
  },
  created: function() {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    this.ssxx = userinfo.SSZZId
  },
  mounted() {
    console.log('this.setGradeList', this.setGradeList)
    if (this.setGradeList.length > 0) {
      let list = this.setGradeList[0].children
      this.rightData = list.sort((a, b) => a.pxh - b.pxh)
    }
  },
  methods: {
    // 选择当前父级别，显示子级
    choseCurNode(value, index) {
      this.rightData = value.children.sort((a, b) => a.pxh - b.pxh)
      this.currentIndex = index
    },
    // 新增等级
    addChildNode(value, type) {
      console.log('添加子级', value)
      this.currentNode = value
      console.log('this.currentNode', this.currentNode)

      this.type = type
      //清空
      this.setGradeInfo = {}
      this.setGradeInformVisible = true
    },
    //编辑
    editNode(value, type) {
      this.type = type
      // this.currentNode = value
      console.log('编辑子级', value)
      this.setGradeInformVisible = true
      this.setGradeInfo = {
        Id: value.Id,
        PXH: value.pxh,
        FS: value.fs,
        MC: value.label
      }
    },
    //删除
    delNode(value) {
      this.$api.post(
        'v1/schoolprizeset/DeleteXXHJSZ',
        {
          Id: value,
          token: this.$store.state.token
        },
        r => {
          if (r.Code == 0) {
            this.$message({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
            this.$emit('refresh')
          }
        }
      )
    },

    //添加等级
    addNode() {
      this.type = 1
      this.setGradeInformVisible = true
      this.setGradeInfo = {}
    },
    //外层弹窗取消
    cancleOuter() {
      this.$emit('cancelDJdialog')
      this.rightData = []
      this.currentIndex = 0
    },

    //外层弹窗确认
    confirmOuter() {
      this.$emit('sumbitFormDJ', this.setGradeList)
      this.rightData = []
      this.currentIndex = 0
    },

    //内层弹窗确认
    confirmSetGradeDialog() {
      this.setGradeInformVisible = false
      //父级的新增
      if (this.type == 1) {
        this.$api.post(
          '/v1/schoolprizeset/AddLevel',
          {
            XXHJSZInfo: {
              PXH: this.setGradeInfo.PXH,
              MC: this.setGradeInfo.MC,
              FS: this.setGradeInfo.FS,
              FID: 0,
              CJ: 1,
              LBID: this.GradeInfo.Id,
              children: []
            },
            token: this.$store.state.token
          },
          r => {
            if (r.Code == 0) {
              this.$message({
                type: 'success',
                message: r.Message,
                duration: 3000
              })
              this.$emit('refresh')
            } else {
              this.$message({
                type: 'error',
                message: r.Message,
                showClose: true
              })
            }
          }
        )
      } else if (this.type == 2) {
        //添加子级
        this.$api.post(
          '/v1/schoolprizeset/AddLevel',
          {
            XXHJSZInfo: {
              PXH: this.setGradeInfo.PXH,
              MC: this.setGradeInfo.MC,
              FS: this.setGradeInfo.FS,
              FID: this.currentNode.Id,
              CJ: 2,
              LBID: this.GradeInfo.Id,
              children: []
            },
            token: this.$store.state.token
          },
          r => {
            if (r.Code == 0) {
              this.$message({
                type: 'success',
                message: r.Message,
                duration: 3000
              })
              this.$emit('refresh')
            } else {
              this.$message({
                type: 'error',
                message: r.Message,
                showClose: true
              })
            }
          }
        )
      } else if (this.type == 3) {
        //编辑
        this.$api.post(
          '/v1/schoolprizeset/AddLevel',
          {
            XXHJSZInfo: {
              Id: this.setGradeInfo.Id,
              PXH: this.setGradeInfo.PXH,
              MC: this.setGradeInfo.MC,
              FS: this.setGradeInfo.FS,
              FID: this.currentNode.Id,
              LBID: this.GradeInfo.Id
            },
            token: this.$store.state.token
          },
          r => {
            if (r.Code == 0) {
              this.$message({
                type: 'success',
                message: r.Message,
                duration: 3000
              })
              this.$emit('refresh')
            } else {
              this.$message({
                type: 'error',
                message: r.Message,
                showClose: true
              })
            }
          }
        )
      }
    }
  },
  watch: {
    visible(val) {
      this.tc = val
      this.currentIndex = 0
      if (this.setGradeList.length > 0) {
        this.rightData = this.setGradeList[0].children.sort(
          (a, b) => a.pxh - b.pxh
        )
      }
      // this.$nextTick(() => {
      //   this.rightData = this.setGradeList[0].children.sort((a,b)=>b.pxh-a.pxh)
      // })
    },
    setGradeList(newValue, oldValue) {
      this.setGradeList = newValue
      if (this.setGradeList.length > 0) {
        let list = this.setGradeList[this.currentIndex].children
        this.rightData = list.sort((a, b) => a.pxh - b.pxh)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-divider {
  width: 1px; /* 自定义垂直分隔线的宽度 */
  // height: 100%; /* 设置垂直分隔线的高度 */
  border-left: 1px solid #dfe1e0; /* 设置垂直分隔线的样式 */
}
.active {
  color: black;
}
.dataBox {
  display: flex;
  padding: 20px;
  background: #f9fbfa;

  .left-box {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-right: 10px;
    // box-shadow: 0px 0px 5px;
    border-radius: 10px;
    color: #a7a9a8;
    .item {
      margin: 5px 0;
      .node {
        margin-right: 10px;
        font-size: 16px;
      }
    }
  }
  .right-box {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: flex-start;
    // box-shadow: 0px 0px 5px;
    border-radius: 10px;
    .item {
      margin: 5px 0;
      .node {
        margin-right: 10px;
        font-size: 16px;
      }
    }
    .notips {
      display: flex;
      align-items: center;
      margin: 5px 0;
    }
  }
}
</style>
