<template>
  <div>
    <div :class="qrCodeborderclass">
      <div class="twor-box hs-back" style=" display:block; float:right;">
        <img class="twor-box-img" :src="qrCodeimg" />
        <div :class="qrsize">
          <span class="twor-mian-span">语文</span>
          <span class="twor-mian-span">上课专注</span>
        </div>
      </div>
      <div class="xj-box" style="display:block; float:left;">
        <div class="xj-name" style="height:115px!important;">
          <span class="xj-name-span" style="width:170px !important;"
            >二维码中心图标：</span
          >
          <div
            style="width: 112px!important;height:112px!important;display: inline-block;"
          >
            <Upload
              @getResultUrl="getUploadUrl"
              :left="0"
              :width="100"
              :height="100"
              :itemPic="
                EWMSZInfo.Logo ? EWMSZInfo.Logo : $store.state.defaultPic
              "
            />
          </div>
        </div>
        <div class="xj-name">
          <span class="xj-name-span" style="width:170px !important;"
            >边框颜色：</span
          >
          <div class="diy" style="z-index: 99999;">
            <div :class="bordercolorclass">
              <div class="hs-back"></div>
              <span>{{ bordercolorText }}</span>
              <img src="../../style/images/icon_col_xl.png" />
            </div>
            <ul>
              <li
                class="hs-sty"
                @click="bordercolorselect('红色', 'hs-sty', 'OrangeRed')"
              >
                <!--红色=“OrangeRed”-->
                <div class="hs-back"></div>
                <span>红色</span>
              </li>
              <li
                class="ls-sty"
                @click="bordercolorselect('绿色', 'ls-sty', 'Chartreuse')"
              >
                <!--绿色=“Chartreuse”-->
                <div class="hs-back"></div>
                <span>绿色</span>
              </li>
              <li
                class="lss-sty"
                @click="bordercolorselect('黑色', 'lss-sty', 'Black')"
              >
                <!--黑色=“black”-->
                <div class="hs-back"></div>
                <span>黑色</span>
              </li>
              <li
                class="ys-sty"
                @click="bordercolorselect('黄色', 'ys-sty', 'Gold')"
              >
                <!--黄色=“Gold”-->
                <div class="hs-back"></div>
                <span>黄色</span>
              </li>
              <li
                class="yss-sty"
                @click="bordercolorselect('橙色', 'yss-sty', 'DarkOrange')"
              >
                <!--橙色=“DarkOrange”-->
                <div class="hs-back"></div>
                <span>橙色</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="xj-name">
          <span class="xj-name-span" style="width:170px !important;"
            >二维码颜色：</span
          >
          <div class="diy" style="z-index: 99998;">
            <div :class="qrCodecolorclass">
              <div class="hs-back"></div>
              <span>{{ qrCodecolorText }}</span>
              <img src="../../style/images/icon_col_xl.png" />
            </div>
            <ul>
              <li
                class="hs-sty"
                @click="
                  qrCodecolorselect(
                    '红色',
                    'hs-sty',
                    'img_l_OrangeRed.png',
                    'OrangeRed'
                  )
                "
              >
                <!--红色=“OrangeRed”-->
                <div class="hs-back"></div>
                <span>红色</span>
              </li>
              <li
                class="ls-sty"
                @click="
                  qrCodecolorselect(
                    '绿色',
                    'ls-sty',
                    'img_l_Chartreuse.png',
                    'Chartreuse'
                  )
                "
              >
                <!--绿色=“Chartreuse”-->
                <div class="hs-back"></div>
                <span>绿色</span>
              </li>
              <li
                class="lss-sty"
                @click="
                  qrCodecolorselect('黑色', 'lss-sty', 'code-img.jpg', 'Black')
                "
              >
                <!--黑色=“black”-->
                <div class="hs-back"></div>
                <span>黑色</span>
              </li>
              <li
                class="ys-sty"
                @click="
                  qrCodecolorselect('黄色', 'ys-sty', 'img_l_Glod.png', 'Gold')
                "
              >
                <!--黄色=“Gold”-->
                <div class="hs-back"></div>
                <span>黄色</span>
              </li>
              <li
                class="yss-sty"
                @click="
                  qrCodecolorselect(
                    '橙色',
                    'hs-back',
                    'img_l_DarkOrange.png',
                    'DarkOrange'
                  )
                "
              >
                <!--橙色=“DarkOrange”-->
                <div class="hs-back"></div>
                <span>橙色</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="xj-name">
          <span class="xj-name-span" style="width:170px !important;"
            >字体颜色：</span
          >
          <div class="diy" style="z-index: 99993;">
            <div :class="sizecolorclass">
              <div class="hs-back"></div>
              <span>{{ sizecolorText }}</span>
              <img src="../../style/images/icon_col_xl.png" />
            </div>
            <ul>
              <li
                class="hs-sty"
                @click="sizecolorselect('红色', 'hs-sty', 'OrangeRed')"
              >
                <!--红色=“OrangeRed”-->
                <div class="hs-back"></div>
                <span>红色</span>
              </li>
              <li
                class="ls-sty"
                @click="sizecolorselect('绿色', 'ls-sty', 'Chartreuse')"
              >
                <!--绿色=“Chartreuse”-->
                <div class="hs-back"></div>
                <span>绿色</span>
              </li>
              <li
                class="lss-sty"
                @click="sizecolorselect('黑色', 'lss-sty', 'Black')"
              >
                <!--黑色=“black”-->
                <div class="hs-back"></div>
                <span>黑色</span>
              </li>
              <li
                class="ys-sty"
                @click="sizecolorselect('黄色', 'ys-sty', 'Gold')"
              >
                <!--黄色=“Gold”-->
                <div class="hs-back"></div>
                <span>黄色</span>
              </li>
              <li
                class="yss-sty"
                @click="sizecolorselect('橙色', 'yss-sty', 'DarkOrange')"
              >
                <!--橙色=“DarkOrange”-->
                <div class="hs-back"></div>
                <span>橙色</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="xzsty-box">
          <div
            class="tablil-xz tablil-xz-sty"
            v-if="borderyes"
            @click="boderselect(1)"
          ></div>
          <div class="tablil-xz" v-else @click="boderselect(1)"></div>
          <div class="tablil-xz-name" style="font-size: 14px;">
            有边框指标与课程一排字样
          </div>
          <div
            class="tablil-xz tablil-xz-sty"
            v-if="borderno"
            @click="boderselect(2)"
          ></div>
          <div class="tablil-xz" v-else @click="boderselect(2)"></div>
          <div class="tablil-xz-name" style="font-size: 14px;">
            无边框指标与课程一排字样
          </div>
        </div>
        <div class="cent-bott-butt" style="margin-top:10px;">
          <el-button @click="submitdata()" type="primary" :disabled="isDisabled"
            >保&nbsp;存</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Upload
  },
  computed: {
    // 文件的上传路径
    uploadURL: function() {
      return "/file/file/fileservice/UploadFile?token=" + this.token;
    }
  },
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.userid = user.Id;
      this.token = user.Token;
    }
    this.loaddata();
  },
  mounted: function() {},
  methods: {
    // 上传图片路径
    getUploadUrl(filePath) {
      this.EWMSZInfo.Logo = filePath;
    },
    // 二维码边框选择
    boderselect: function(type) {
      if (type == 2 || type == "2") {
        this.borderno = true;
        this.borderyes = false;
        this.EWMSZInfo.EWMYS = "2";
        this.qrCodeborderclass = "";
      } else {
        this.borderno = false;
        this.borderyes = true;
        this.EWMSZInfo.EWMYS = "1";
        this.qrCodeborderclass = "popo-twoe-main lss-sty";
        var borderinfo = this.qrcodeborderset.filter(
          c => c.color == this.EWMSZInfo.BKYSM
        )[0];
        if (borderinfo != null && borderinfo != undefined) {
          this.qrCodeborderclass = "popo-twoe-main " + borderinfo.classname;
        }
      }
    },
    // 边框颜色
    bordercolorselect: function(sizeText, classText, values) {
      this.bordercolorclass = "xlx-box " + classText;
      this.bordercolorText = sizeText;
      if (this.borderyes) {
        this.qrCodeborderclass = "popo-twoe-main " + classText;
      }
      this.EWMSZInfo.BKYSM = values;
    },
    // 二维码颜色
    qrCodecolorselect: function(sizeText, classText, imgname, values) {
      this.qrCodecolorclass = "xlx-box " + classText;
      this.qrCodecolorText = sizeText;

      this.qrCodeimg = require("@/style/images/" + imgname);
      this.EWMSZInfo.EWMYSM = values;
    },
    // 字体颜色
    sizecolorselect: function(sizeText, classText, values) {
      this.sizecolorclass = "xlx-box " + classText;
      this.sizecolorText = sizeText;
      this.qrsize = "twor-mian " + classText;
      this.EWMSZInfo.ZTYSM = values;
    },
    loaddata: function() {
      this.$api.post(
        "v1/quickmarkregulation/GetEWMSZ",
        {
          SSXX: this.ssxx,
          QrCodeSetType: 2,
          token: this.token
        },
        r => {
          if (r.Target != null && r.Target != undefined) {
            this.EWMSZInfo = r.Target;
            this.boderselect(this.EWMSZInfo.EWMYS);
            var fontinfo = this.qrcodefontset.filter(
              c => c.color == this.EWMSZInfo.ZTYSM
            )[0];
            if (fontinfo != null && fontinfo != undefined) {
              this.sizecolorselect(
                fontinfo.name,
                fontinfo.classname,
                fontinfo.color
              );
            }
            var borderinfo = this.qrcodeborderset.filter(
              c => c.color == this.EWMSZInfo.BKYSM
            )[0];
            if (borderinfo != null && borderinfo != undefined) {
              this.bordercolorselect(
                borderinfo.name,
                borderinfo.classname,
                borderinfo.color
              );
            }
            var qrcolorinfo = this.qrcodecolorset.filter(
              c => c.color == this.EWMSZInfo.EWMYSM
            )[0];
            if (qrcolorinfo != null && qrcolorinfo != undefined) {
              this.qrCodecolorselect(
                qrcolorinfo.name,
                qrcolorinfo.classname,
                qrcolorinfo.img,
                qrcolorinfo.color
              );
            }
          } else {
            this.EWMSZInfo.Id = 0;
            this.EWMSZInfo.EWMYS = 1;
            this.EWMSZInfo.BKYSM = "Black";
            this.EWMSZInfo.EWMYSM = "Black";
            this.EWMSZInfo.ZTYSM = "Black";
            this.boderselect(1);
            this.sizecolorselect("黑色", "lss-sty", "Black");
            this.qrCodecolorselect("黑色", "lss-sty", "code-img.jpg", "Black");
            this.bordercolorselect("黑色", "lss-sty", "Black");
          }
        }
      );
    },
    submitdata: function() {
      this.EWMSZInfo.SZLX = 2;
      this.EWMSZInfo.SSXX = this.ssxx;
      this.isDisabled = true;
      this.$api.post(
        "v1/quickmarkregulation/AddEWMSZ",
        {
          EWMSZInfo: this.EWMSZInfo,
          SSXX: this.ssxx,
          token: this.token
        },
        r => {
          this.isDisabled = false;
          if (r.Code == 0) {
            this.$message({
              type: "info",
              message: "保存成功",
              showClose: true
            });
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    }
  },
  data() {
    return {
      isDisabled: false,
      ssxx: "",
      ssxq: "",
      token: "",
      userid: "",
      qrcodeborderset: [
        { name: "红色", classname: "hs-sty", color: "OrangeRed" },
        { name: "绿色", classname: "ls-sty", color: "Chartreuse" },
        { name: "黑色", classname: "lss-sty", color: "Black" },
        { name: "黄色", classname: "ys-sty", color: "Gold" },
        { name: "橙色", classname: "yss-sty", color: "DarkOrange" }
      ],
      qrcodefontset: [
        { name: "红色", classname: "hs-sty", color: "OrangeRed" },
        { name: "绿色", classname: "ls-sty", color: "Chartreuse" },
        { name: "黑色", classname: "lss-sty", color: "Black" },
        { name: "黄色", classname: "ys-sty", color: "Gold" },
        { name: "橙色", classname: "yss-sty", color: "DarkOrange" }
      ],
      qrcodecolorset: [
        {
          name: "红色",
          classname: "hs-sty",
          color: "OrangeRed",
          img: "img_l_OrangeRed.png"
        },
        {
          name: "绿色",
          classname: "ls-sty",
          color: "Chartreuse",
          img: "img_l_Chartreuse.png"
        },
        {
          name: "黑色",
          classname: "lss-sty",
          color: "Black",
          img: "code-img.jpg"
        },
        {
          name: "黄色",
          classname: "ys-sty",
          color: "Gold",
          img: "img_l_Glod.png"
        },
        {
          name: "橙色",
          classname: "yss-sty",
          color: "DarkOrange",
          img: "img_l_DarkOrange.png"
        }
      ],
      // 设置二维码
      qrCodeFormVisible: false,
      borderno: false,
      borderyes: true,
      // 二维码框
      qrCodeborderclass: "popo-twoe-main lss-sty",
      qrCodeimg: require("@/style/images/code-img.jpg"),
      qrsize: "twor-mian lss-sty",
      // 选择颜色
      bordercolorclass: "xlx-box lss-sty",
      bordercolorText: "黑色",
      qrCodecolorclass: "xlx-box lss-sty",
      qrCodecolorText: "黑色",
      sizecolorclass: "xlx-box lss-sty",
      sizecolorText: "黑色",
      // 二维码提交参数
      EWMSZInfo: {
        Id: 0,
        EWMYS: 1,
        SZLX: 2,
        SSXX: "",
        Logo: "",
        GLId: "",
        BKYSM: "Black",
        EWMYSM: "Black",
        ZTYSM: "Black"
      }
    };
  }
};
</script>
