<template>
  <!-- 德体美劳弹窗 -->
  <el-dialog
    custom-class="DTML-dialog"
    :title="`${form.WDMC}设置`"
    :visible.sync="show"
    width="90%"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <!-- 学业成绩计入维度设置 -->
    <div class="grade-set">
      <div class="label">学业成绩计入维度设置：</div>
      <el-checkbox v-model="form.YK">
        月考
        <el-input-number
          :precision="2"
          :min="0"
          placeholder="计入比例"
          size="small"
          v-model="form.YKBL"
        />
        %
      </el-checkbox>
      <el-checkbox v-model="form.QZ">
        期中
        <el-input-number
          :precision="2"
          :min="0"
          placeholder="计入比例"
          size="small"
          v-model="form.QZBL"
        />
        %
      </el-checkbox>
      <el-checkbox v-model="form.QM">
        期末
        <el-input-number
          :precision="2"
          :min="0"
          placeholder="计入比例"
          size="small"
          v-model="form.QMBL"
        />
        %
      </el-checkbox>
    </div>
    <!-- 活动+活动明细  维度+活动明细  显示 -->
    <div class="activity-block">
      <!-- 过程活动 -->
      <div class="process-activity">
        <div
          class="process-activity-item"
          v-for="item in form.activities"
          :key="item.GLId"
        >
          <el-input-number
            :precision="2"
            :min="0"
            size="small"
            v-model="item.BFB"
            @change="handleBFB(item)"
          />
          %
          <el-tooltip
            class="activity-item"
            effect="light"
            :disabled="handleTootip(item.label)"
            :content="item.label"
            placement="top"
          >
            <el-checkbox v-model="item.checked" @change="handelChange(item)">{{
              item.label
            }}</el-checkbox>
          </el-tooltip>
          <el-select
            size="small"
            v-model="item.GLDJLX"
            placeholder="请选择"
            @focus="handelSelect(item)"
          >
            <el-option
              v-for="el in levelTypeOption"
              :key="el.GLDJLX"
              :label="el.DJMC"
              :value="el.GLDJLX"
            >
              <span>{{ el.DJMC }}</span>
            </el-option>
          </el-select>
          <el-tooltip
            class="activity-item"
            effect="light"
            :disabled="
              !levelOptions.find((el) => el.Id === item.GLDJId) ||
              levelOptions.find((el) => el.Id === item.GLDJId).DJMC < 9
            "
            :content="
              levelOptions.find((el) => el.Id === item.GLDJId)
                ? levelOptions.find((el) => el.Id === item.GLDJId).DJMC
                : ''
            "
            placement="top"
          >
            <div
              class="level-select"
              size="mini"
              type="text"
              v-show="item.checked && item.GLDJLX === 3"
              @click="handleSelectLevel(item)"
            >
              {{ item | handleDJMC(levelOptions) }}
            </div>
          </el-tooltip>
        </div>
      </div>
      <!-- 固定活动 -->
      <div class="fixed-activity">
        <div
          class="fixed-activity-item"
          v-for="(item, index) in form.fixActivity"
          :key="index"
          v-show="handleShow(1, item)"
        >
          <el-input-number
            :precision="2"
            :min="0"
            size="small"
            v-model="item.BFB"
            @change="handleBFB(item)"
          />
          %
          <el-checkbox v-model="item.checked" @change="handelChange(item)">{{
            item.label
          }}</el-checkbox>
        </div>
      </div>
      <!-- 数据维度 -->
      <div class="data-dimension">
        <div
          class="data-dimension-item"
          v-for="item in form.dataDimension"
          :key="item.GLId"
          v-show="handleShow(2, item)"
        >
          <el-input-number
            :precision="2"
            :min="0"
            size="small"
            v-model="item.BFB"
            @change="handleBFB(item)"
          />
          %
          <el-checkbox v-model="item.checked" @change="handelChange(item)">{{
            item.label
          }}</el-checkbox>

          <el-tooltip
            effect="light"
            :disabled="handleTootip(item.label)"
            :content="item.label"
            placement="top"
          >
            <div
              class="dimension-select"
              v-show="item.checked"
              @click="handleSelectDimension(item)"
            >
              {{ item | handleWDMC(dimensionOption) }}
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="footer-button">
        <el-button type="info" size="small" @click="$emit('update:show', false)"
          >关闭</el-button
        >
        <el-button type="primary" size="small" @click="addSecond"
          >确定</el-button
        >
      </div>
    </div>

    <!-- 勾选等级 -->
    <SelectLevelDialog
      :show.sync="selectLevelObj.show"
      :formData="selectLevelObj.data"
      :levelOptions="levelOptions"
      @submitLevel="submitLevel"
    />

    <!-- 勾选末级维度 -->
    <SelectDimension
      :show.sync="selectDimension.show"
      :formData="selectDimension.data"
      :dimensionOption="dimensionOption"
      @submitDimension="submitDimension"
    />
  </el-dialog>
</template>

<script>
import { saveFirstDimensions, addSecond } from "@/api/growthReportSet.js";
import SelectLevelDialog from "@/views/Integrated/GrowthReportSet/Comprehensive/SelectLevel.vue";
import SelectDimension from "@/views/Integrated/GrowthReportSet/Comprehensive/SelectDimension.vue";

export default {
  props: {
    show: [Boolean],
    firstLevel: {
      type: Object,
      default() {
        return {};
      },
    },
    levelOptions: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    dimensionOption: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    DTMLform: [Object],
    refreshData: {
      type: Function,
      required: true,
    },
  },
  components: {
    SelectLevelDialog,
    SelectDimension,
  },
  data() {
    return {
      form: {},
      baseLevelTypeOption: [
        { DJMC: "分数", GLDJLX: 1 },
        { DJMC: "不展示", GLDJLX: 2 },
        { DJMC: "等级", GLDJLX: 3 },
        { DJMC: "星数", GLDJLX: 4 },
      ],
      levelTypeOption: [],
      selectLevelObj: {
        show: false,
        data: {},
      },
      selectDimension: {
        show: false,
        data: {},
      },
      userInfo: {},
    };
  },
  filters: {
    handleDJMC(val, levels) {
      if (val.GLDJId) {
        let res = levels.find((el) => el.Id === val.GLDJId);
        return res ? res.DJMC : "选择";
      } else return "选择";
    },
    handleWDMC(val, dimension) {
      if (val.EJWDId) {
        let res = "";
        dimension.forEach((el) => {
          if (el.value === val.EJWDId) {
            res = el;
          } else {
            if (el.children) {
              el.children.forEach((e) => {
                if (e.value === val.EJWDId) res = e;
              });
            }
          }
        });
        return res ? res.label : "选择";
      } else return "选择";
    },
  },
  mounted() {
    let userInfo = localStorage.getItem("userinfo");
    if (userInfo) this.userInfo = JSON.parse(userInfo);
    this.levelTypeOption = JSON.parse(JSON.stringify(this.baseLevelTypeOption));
  },
  methods: {
    //各星星活动的展示设置里，加上星数显示
    handelSelect(item) {
      this.levelTypeOption = JSON.parse(
        JSON.stringify(this.baseLevelTypeOption)
      );
      if (item.PJFS != 2) {
        const index = this.baseLevelTypeOption.findIndex(
          (el) => el.GLDJLX === 4
        );
        if (index !== -1) {
          this.levelTypeOption.splice(index, 1);
        }
      }
    },
    //多选框事件
    handelChange(value) {
      if (value.checked) {
        if (value.BFB == undefined) value.BFB = 100;
      } else {
        value.BFB = undefined;
      }
    },
    handleBFB(value) {
      value.checked = true;
    },
    // 二级页面数据保存
    async addSecond() {
      let ZHGLSZBInfo = [];
      let activity = [];
      let fix = [];

      try {
        if (
          this.form.activities.some(
            (item) => item.checked && item.GLDJLX === 3 && item.GLDJId === ""
          )
        )
          return this.$message.error("请选择关联等级");
        if (
          this.form.dataDimension.some(
            (item) => item.checked && item.EJWDId === ""
          )
        )
          return this.$message.error("请选择关联维度");
      } catch {
        //
      }

      let dimension = [];
      activity = this.form.activities.reduce((pre, cur) => {
        if (cur.checked) {
          return pre.concat({
            GLId: cur.GLId, //关联ID
            GLSJLX: 2, //关联数据类型1.维度 2.自定义活动 3.固定活动
            GLDJLX: cur.GLDJLX, //关联等级类型 1分数，2不展示，3关联等级 4百分百转等级 5分数转等级
            GLDJId: cur.GLDJLX === 3 ? cur.GLDJId : "", //关联等级Id
            SJLX: 0,
            EJWDId: "",
            BFB: cur.BFB || 0,
          });
        } else return pre;
      }, []);

      fix = this.form.fixActivity.reduce((pre, cur) => {
        if (cur.checked) {
          return pre.concat({
            GLId: "",
            GLDJId: "",
            GLDJLX: 0,
            GLSJLX: 3, //关联数据类型1.维度 2.自定义活动 3.固定活动
            SJLX: cur.GLId, // 8体质数据 5运动数据 6阅读数据 38体检数据 39心理健康 40 学业总评
            EJWDId: "", //二级维度Id
            XKId: cur.GLId === 40 ? cur.XKId : "",
            BFB: cur.BFB || 0,
          });
        } else return pre;
      }, []);

      dimension = this.form.dataDimension.reduce((pre, cur) => {
        if (cur.checked) {
          return pre.concat({
            GLId: "",
            GLDJId: "",
            GLDJLX: "",
            GLSJLX: 3, //关联数据类型1.维度 2.自定义活动 3.固定活动 4.阶段互动
            SJLX: cur.GLId, // 8体质数据 5运动数据 6阅读数据 38体检数据 39心理健康
            EJWDId: cur.EJWDId || "", //二级维度Id
            BFB: cur.BFB || 0,
          });
        } else return pre;
      }, []);

      ZHGLSZBInfo = ZHGLSZBInfo.concat(activity, fix, dimension);

      let obj = JSON.parse(JSON.stringify(this.form));

      let arr3 = ["YK", "QZ", "QM"];
      arr3.forEach((key) => {
        if (obj[key]) {
          obj[key] = 1;
          obj[`${key}BL`] === "" ? (obj[`${key}BL`] = 0) : "";
        } else {
          obj[key] = 2;
          obj[`${key}BL`] = 0;
        }
      });

      obj.ZT2 ? (obj.ZT = 1) : (obj.ZT = 2);
      delete obj.activities;
      delete obj.dataDimension;
      delete obj.fixActivity;
      delete obj.XSFW;
      console.log("[obj]", [obj]);
      console.log("ZHGLSZBInfo", ZHGLSZBInfo);

      await saveFirstDimensions({
        WDZSBGInfo: [obj],
        token: this.userInfo.Token,
      });

      await addSecond({
        id: obj.Id,
        ZHGLSZBInfo,
        token: this.userInfo.Token,
      });
      this.refreshData();
      this.$message.success("操作成功");
      this.$emit("update:show", false);
    },
    // 根据二级页面设置显隐固定活动和数据维度
    handleShow(type, data) {
      if (type === 2) {
        if (this.firstLevel.EJYMSZ === 1) {
          return data.GLId === 38 ? false : true;
        } else {
          return true;
        }
      } else {
        if (data.GLId === 6) return false;
        if (this.firstLevel.EJYMSZ === 1) {
          return true;
        } else {
          return data.GLId === 38 ? false : true;
        }
      }
    },
    // 根据活动名称长度是否显示tooltip
    handleTootip(label) {
      if (label) {
        return label.length < 10;
      } else return true;
    },
    handleSelectLevel(item) {
      this.selectLevelObj.data = item;
      this.selectLevelObj.show = true;
    },
    submitLevel(val) {
      this.selectLevelObj.data.GLDJId = val;
      this.selectLevelObj.show = false;
    },
    handleSelectDimension(item) {
      this.selectDimension.show = true;
      this.selectDimension.data = item;
    },
    submitDimension(val) {
      this.selectDimension.data.EJWDId = val;
      this.selectDimension.show = false;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.form = JSON.parse(JSON.stringify(this.DTMLform));
        // console.log("[form]", this.form);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 德体美劳弹窗
.DTML-dialog {
  .grade-set {
    margin-top: 15px;
    padding: 15px 5px;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
    display: flex;
    align-items: center;
    .label {
      margin-right: 10px;
    }
    ::v-deep .el-input-number__decrease,
    ::v-deep .el-input-number__increase {
      display: none;
    }
    ::v-deep .el-input__inner {
      padding: 0 5px;
    }
  }
  .activity-block {
    margin: 15px 0;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
    padding: 10px 5px;
    .stage-activity-container {
      .label {
        margin: 10px 0;
      }
    }
    .process-activity,
    .stage-activity,
    .fixed-activity,
    .data-dimension {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;
      box-shadow: 1px 1px 2px 2px #f9fbfb;

      .process-activity-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        width: 50%;
        .el-select {
          width: 100px;
          margin: 0 10px;
        }
        .level-select {
          color: #4adba4;
          max-width: 75px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .stage-activity-item,
      .fixed-activity-item,
      .data-dimension-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        box-shadow: 1px 1px 2px 2px #f9fbfb;
        .el-select {
          width: 100px;
          margin: 0 10px;
        }
      }
    }

    .data-dimension {
      margin-top: 15px;
      .data-dimension-item {
        min-width: 150px;
        margin: 0 40px 25px 0;
        box-shadow: 3px 4px 2px 2px #f9fbfb;
        .dimension-select {
          padding-left: 8px;
          display: block;
          max-width: 80px;
          cursor: pointer;
          color: #14d089;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .process-activity {
      ::v-deep .el-checkbox__label {
        width: 110px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        top: 5px;
      }
      .process-activity-item {
        // margin-right: 20px;
        // width: 330px;
        .level-select {
          color: #4adba4;
          max-width: 75px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }
    }
    .fixed-activity {
      flex-wrap: wrap;
      .fixed-activity-item {
        margin-bottom: 0 40px 20px 0;
        margin-right: 25px;
        .dimension-select {
          padding-left: 8px;
          display: block;
          max-width: 80px;
          cursor: pointer;
          color: #14d089;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  .footer-button {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
