// import Vue from "vue";

/**
 * 管理端路由自定义
 * 路由说明
 * path 路由地址
 * menuname 菜单显示名称
 * component 加载的内容
 * children 子路由
 * hidden 菜单中是否显示 true 不显示
 * requireAuth 是否需要验证token true 不验证
 * groupcode 子路由为子菜单时需要此字段，父菜单和子菜单都需要，值一致
 * meta｛
 *  title：“菜单名称”,
 *  roleType:4.学校管理员5.区域管理员
 * ｝
 *
 */

export const menuRoutes = [
	// 大后台
	{
		path: "/GnmkqxList",
		name: "GnmkqxList",
		meta: {
			title: "功能模块管理",
			roleType: 5
		},
		component: resolve => require(["@/views/gnmkqx/GnmkqxList.vue"], resolve)
	},
	{
		path: "/ConfigList",
		name: "ConfigList",
		meta: {
			title: "缓存配置项管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/globalconfigset/ConfigList.vue"], resolve)
	},
	{
		path: "/AreaSchoolM",
		name: "AreaSchoolM",
		redirect: "/AreaSchoolM/AreaSchoolList",
		meta: {
			title: "区域学校",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/areaschool/AreaSchoolM.vue"], resolve),
		children: [{
			path: "AreaSchoolList",
			name: "AreaSchoolList",
			meta: {
				roleType: 5
			},
			component: resolve =>
				require(["@/views/areaschool/AreaSchoolList.vue"], resolve)
		},
		{
			path: "SchoolPower",
			name: "SchoolPower",
			meta: {
				title: "选择菜单模块",
				roleType: 5
			},
			component: resolve =>
				require(["@/views/areaschool/SchoolPower.vue"], resolve)
		}
		]
	},
	{
		path: "/SchoolManage",
		name: "SchoolManage",
		redirect: "/SchoolManage/SchoolList",
		meta: {
			title: "区域学校",
			roleType: 6
		},
		component: resolve =>
			require(["@/views/areaschool/SchoolManage.vue"], resolve),
		children: [{
			path: "SchoolList",
			name: "SchoolList",
			meta: {
				roleType: 6
			},
			component: resolve =>
				require(["@/views/areaschool/SchoolList.vue"], resolve)
		},
		{
			path: "SchoolPower",
			name: "SchoolPower",
			meta: {
				title: "选择菜单模块",
				roleType: 6
			},
			component: resolve =>
				require(["@/views/areaschool/SchoolPower.vue"], resolve)
		}
		]
	},
	{
		path: "/TermMange",
		name: "TermMange",
		redirect: "/TermMange/XqbList",
		meta: {
			title: "区域学期",
			roleType: 5
		},
		component: resolve => require(["@/views/xqb/TermMange.vue"], resolve),
		children: [{
			path: "XqbList",
			name: "XqbList",
			meta: {
				roleType: 5
			},
			component: resolve => require(["@/views/xqb/XqbList.vue"], resolve)
		},
		{
			path: "XqWeekList",
			name: "XqWeekList",
			meta: {
				title: "学期周期",
				roleType: 5
			},
			component: resolve => require(["@/views/xqb/XqWeekList.vue"], resolve)
		}
		]
	},
	{
		path: "/AreaTermM",
		name: "AreaTermM",
		redirect: "/AreaTermM/AreaTermList",
		meta: {
			title: "区域学期",
			roleType: 6
		},
		component: resolve => require(["@/views/xqb/AreaTermM.vue"], resolve),
		children: [{
			path: "AreaTermList",
			name: "AreaTermList",
			meta: {
				roleType: 6
			},
			component: resolve => require(["@/views/xqb/AreaTermList.vue"], resolve)
		},
		{
			path: "XqWeekList",
			name: "XqWeekList",
			meta: {
				title: "学期周期",
				roleType: 6
			},
			component: resolve => require(["@/views/xqb/XqWeekList.vue"], resolve)
		}
		]
	},
	{
		path: "/QykcList",
		name: "QykcList",
		meta: {
			title: "区域课程",
			roleType: 5
		},
		component: resolve => require(["@/views/xxkcb/QykcList.vue"], resolve)
	},
	{
		path: "/AreaCourse",
		name: "AreaCourse",
		meta: {
			title: "区域课程",
			roleType: 6
		},
		component: resolve => require(["@/views/xxkcb/AreaCourse.vue"], resolve)
	},
	{
		path: "/arealevelset",
		name: "arealevelset",
		meta: {
			title: "区域等级管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/areaLevelset/Index.vue"], resolve)
	},
	{
		path: "/arealevelset",
		name: "arealevelset",
		meta: {
			title: "区域等级管理",
			roleType: 6
		},
		component: resolve =>
			require(["@/views/manager/areaLevelset/AreaLeveSet.vue"], resolve)
	},
	{
		path: "/dimension1",
		name: "dimension1",
		meta: {
			title: "区域学生维度管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/dimension/Index.vue?t=1"], resolve)
	},
	{
		path: "/dimension2",
		name: "dimension2",
		meta: {
			title: "区域教师维度管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/dimension/Index.vue?t=2"], resolve)
	},
	{
		path: "/dimension",
		name: "dimension",
		meta: {
			title: "区域维度管理",
			roleType: 6
		},
		component: resolve =>
			require(["@/views/manager/dimension/AreaDy.vue"], resolve)
	},
	{
		path: "/AreaPrizeSet",
		name: "AreaPrizeSet",
		meta: {
			title: "区域获奖管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/areaprizeset/AreaPrizeSet.vue"], resolve)
	},
	{
		path: "/SchoolPrizeSet",
		name: "SchoolPrizeSet",
		meta: {
			title: "区域获奖管理",
			roleType: 6
		},
		component: resolve =>
			require(["@/views/areaprizeset/SchoolPrizeSet.vue"], resolve)
	},
	{
		path: "/PhysicalQualityRuleList",
		name: "PhysicalQualityRuleList",
		meta: {
			title: "体质数据设置",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/physicalquality/PhysicalQualityRuleList.vue"], resolve)
	},
	{
		path: "/reporttemplate",
		name: "reporttemplate",
		meta: {
			title: "报表模板管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/reporttemplate/Index.vue"], resolve)
	},
	{
		path: "/integratedreporttemplate",
		name: "integratedreporttemplate",
		meta: {
			title: "综合报表模板管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/integratedreporttemplate/Index.vue"], resolve)
	},
	{
		path: "/buttonmanager",
		name: "buttonmanager",
		meta: {
			title: "按钮管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/buttonmanager/Index.vue"], resolve)
	},
	{
		path: "/areaindex",
		name: "areaindex",
		meta: {
			title: "区域指标",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/areaindex/Table.vue"], resolve)
	},
	{
		path: "/areaindex",
		name: "areaindex",
		meta: {
			title: "区域指标",
			roleType: 6
		},
		component: resolve =>
			require(["@/views/manager/areaindex/AearIndexList.vue"], resolve)
	},
	{
		path: "/feedback",
		name: "Feedback",
		meta: {
			title: "问题反馈",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/Feedback/Index.vue"], resolve)
	},
	{
		path: "/usinghelp",
		name: "UsingHelp",
		meta: {
			title: "使用帮助",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/UsingHelp/index.vue"], resolve)
	},
	{
		path: "/jtmanager",
		name: "Jtmanager",
		meta: {
			title: "集团管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/manager/jtmanager/Index.vue"], resolve)
	},
	{
		path: "/ManagementMessages",
		name: "ManagementMessages",
		meta: {
			title: "消息管理",
			roleType: 5
		},
		component: resolve =>
			require(["@/views/ManagementMessages/Index.vue"], resolve)
	},
	// 学校管理员
	{
		path: "",
		meta: {
			title: "用户管理",
			roleType: 4,
			code: "UserManager"
		},
		component: resolve => require(["@/views/student/Index.vue"], resolve),
		groupcode: "userManager",
		children: [
    {
			path: "/StudentMange",
			name: "StudentMange",
			redirect: "/StudentMange/StudentList",
			meta: {
				title: "学生管理",
				roleType: 4,
				code: "StudentManage"
			},
			component: resolve =>
				require(["@/views/student/StudentMange.vue"], resolve),
			children: [{
				path: "StudentList",
				name: "StudentList",
				meta: {
					roleType: 4
				},
				component: resolve =>
					require(["@/views/student/StudentList.vue"], resolve)
			},
			{
				path: "EditStudent",
				name: "EditStudent",
				meta: {
					title: "新增学生",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/student/EditStudent.vue"], resolve)
			},
			{
				path: "ClassAssignment",
				name: "ClassAssignment",
				meta: {
					title: "分班管理",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/student/ClassAssignment.vue"], resolve)
			}
			]
		},
		{
			path: "/TeacherMange",
			name: "TeacherMange",
			meta: {
				title: "教师管理",
				roleType: 4,
				code: "TeacherManage"
			},
			redirect: "/TeacherMange/TeacherList",
			component: resolve =>
				require(["@/views/teacher/TeacherMange.vue"], resolve),
			children: [{
				path: "TeacherList",
				name: "TeacherList",
				meta: {
					roleType: 4
				},
				component: resolve =>
					require(["@/views/teacher/TeacherList.vue"], resolve)
			},
			{
				path: "EditTeacher",
				name: "EditTeacher",
				meta: {
					title: "教师新增",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/teacher/EditTeacher.vue"], resolve)
			},
			{
				path: "TeacherDetail",
				name: "TeacherDetail",
				meta: {
					title: "教师详情",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/teacher/TeacherDetail.vue"], resolve)
			}
			]
		},
		{
			path: "/RoleManage",
			name: "RoleManage",
			meta: {
				title: "角色管理",
				roleType: 4,
				code: "RoleManage"
			},
			redirect: "/RoleManage/roleList",
			component: resolve => require(["@/views/role/RoleManage.vue"], resolve),
			children: [{
				path: "roleList",
				name: "roleList",
				meta: {
					roleType: 4
				},
				component: resolve =>
					require(["@/views/role/roleList.vue"], resolve)
			},
			{
				path: "rolePowers",
				name: "rolePowers",
				meta: {
					title: "角色权限",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/role/rolePowers.vue"], resolve)
			}
			]
		}
		]
	},
	{
		path: "",
		meta: {
			title: "基础管理",
			roleType: 4,
			code: "BaseManager"
		},
		component: resolve => require(["@/views/BaseManage/Index.vue"], resolve),
		groupcode: "basicManager",
		children: [
			{
				path: "/PreCheck",
				name: "precheck",
				meta: {
					title: "配置检查",
					roleType: 4,
					code: "Configuration"
				},
				component: resolve =>
					require(["@/views/precheck/PreCheck.vue"], resolve)
			},
			{
				path: "/MenuList",
				name: "MenuList",
				meta: {
					title: "菜单管理",
					roleType: 4,
					code: "MenuManage"
				},
				component: resolve => require(["@/views/menus/MenuList.vue"], resolve)
			},
			{
				path: "/evaluationactivityset",
				name: "evaluationactivityset",
				meta: {
					title: "活动管理",
					roleType: 4,
					code: "ActivityManage"
				},
				component: resolve =>
					require(["@/views/manager/evaluationactivityset/Index.vue"], resolve)
			},
			{
				path: "/zdybbmanage",
				name: "zdybbmanage",
				meta: {
					title: "自定义报表",
					roleType: 4,
					code: "BaseCustomReports"
				},
				component: resolve =>
					require(["@/views/customreport/CustomReportMain.vue"], resolve),
				redirect: "/zdybbmanage/zdybb",
				children: [{
					path: "zdybb",
					name: "zdybb",
					meta: {
						roleType: 4
					},
					component: resolve =>
						require(["@/views/customreport/ReportList.vue"], resolve)
				},
				{
					path: "zdybbdata/:bbid?",
					name: "zdybbdata",
					meta: {
						title: "自定义报表查看",
						roleType: 4
					},
					component: resolve =>
						require(["@/views/customreport/ReportData.vue"], resolve)
				}
				]
			},
			{
				path: "/pageview",
				name: "pageview",
				meta: {
					title: "首页轮播",
					roleType: 4,
					code: "CarouselManage"
				},
				component: resolve =>
					require(["@/views/pageview/PageViewList.vue"], resolve)
			},
			{
				path: "/zhbbmbgl",
				name: "zhbbmbgl",
				redirect: "/zhbbmbgl/zhbbmbglselectMB",
				meta: {
					title: "综合报表管理",
					roleType: 4,
					code: "zhbbmbManage"
				},
				component: resolve =>
					require(["@/views/zhbbmbManage/router_view.vue"], resolve),
				children: [
					{
						path: "zhbbmbglselectMB",
						name: "zhbbmbglselectMB",
						meta: {
							title: "选择模板",
							roleType: 4
						},
						component: resolve =>
							require(["@/views/zhbbmbManage/selectMB.vue"], resolve)
					},
					// 教师通用报表列表和教师职业道德报表用的列表用的同一个
					{
						path: "zhbbmbglindex",
						name: "zhbbmbglindex",
						meta: {
							title: '教师通用报表',
							roleType: 4
						},
						component: resolve =>
							require(["@/views/zhbbmbManage/Index.vue"], resolve)
					},
					// 教师职业道德设置项目
					{
						path: "setxm",
						name: "setxm",
						meta: {
							title: "设置项目",
							roleType: 4,
							code: "hdManage"
						},
						component: resolve =>
							require(["@/views/zhbbmbManage/setXM.vue"], resolve)
					},
					{
						path: "sethd",
						name: "sethd",
						meta: {
							title: "设置活动",
							roleType: 4,
							code: "hdManage"
						},
						component: resolve =>
							require(["@/views/zhbbmbManage/setHD.vue"], resolve)
					},
					//教师通用报表设置项目
					{
						path: "teachersetXM",
						name: "teachersetXM",
						meta: {
							title: "设置项目",
							roleType: 4,
						},
						component: resolve =>
							require(["@/views/zhbbmbManage/teacherGeneral/teachersetXM.vue"], resolve)
					},
					//教师通用报表设置子项目
					{
						path: "teachersetZXM",
						name: "teachersetZXM",
						meta: {
							title: "设置子项目",
							roleType: 4,
						},
						component: resolve =>
							require(["@/views/zhbbmbManage/teacherGeneral/teachersetZXM.vue"], resolve)
					},
					//教师通用报表设置活动
					{
						path: "teachersetHD",
						name: "teachersetHD",
						meta: {
							title: "关联活动",
							roleType: 4,
						},
						component: resolve =>
							require(["@/views/zhbbmbManage/teacherGeneral/teachersetHD.vue"], resolve)
					},

				]
			}
		]
	},
	{
		path: "",
		meta: {
			title: "教务管理",
			roleType: 4,
			code: "EducationalManage"
		},
		component: resolve => require(["@/views/Educational/Index.vue"], resolve),
		groupcode: "teachingManager",
		children: [
    {
      path: "/SchoolGradeList",
      name: "SchoolGradeList",
      meta: {
        title: "学级管理",
        roleType: 4,
        code: "GradeManage"
      },
      redirect: "/SchoolGradeList/GradeList",
      component: resolve =>
        require(["@/views/schoolorganization/Index.vue"], resolve),
      children: [{
        path: "GradeList",
        name: "GradeList",
        meta: {
          title: "",
          roleType: 4
        },
        component: resolve =>
          require([
            "@/views/schoolorganization/SchoolGradeList.vue"
          ], resolve)
			},
			{
				path: "SchoolClassList",
				name: "SchoolClassList",
				meta: {
					title: "班级管理",
					roleType: 4
				},
				component: resolve =>
					require([
						"@/views/schoolorganization/SchoolClassList.vue"
					], resolve)
			}
			]
		},
		{
			path: "/levelset",
			name: "levelset",
			meta: {
				title: "等级管理",
				roleType: 4,
				code: "LevelManage"
			},
			component: resolve =>
				require(["@/views/manager/levelset/Index.vue"], resolve)
		},
		{
			path: "/XxkcbList",
			name: "XxkcbList",
			meta: {
				title: "学校课程",
				roleType: 4,
				code: "SchoolCourse"
			},
			component: resolve => require(["@/views/xxkcb/XxkcbList.vue"], resolve)
		},
		{
			path: "/SchollTermM",
			name: "SchollTermM",
			meta: {
				title: "学期管理",
				roleType: 4,
				code: "TermManage"
			},
			redirect: "/SchollTermM/SchoolTermList",
			component: resolve =>
				require(["@/views/schoolterm/SchollTermM.vue"], resolve),
			children: [{
				path: "SchoolTermList",
				name: "SchoolTermList",
				meta: {
					title: "",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/schoolterm/SchoolTermList.vue"], resolve)
			},
			{
				path: "TermWeek",
				name: "TermWeek",
				meta: {
					title: "周次",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/schoolterm/TermWeek.vue"], resolve)
			}
			]
		},
		{
			path: "/organization",
			name: "Organization",
			meta: {
				title: "机构管理",
				roleType: 4,
				code: "OrganizationManage"
			},
			component: resolve =>
				require(["@/views/organization/index.vue"], resolve)
		},
		{
			path: "/TeachingGroupList",
			name: "TeachingGroupList",
			meta: {
				title: "教研组管理",
				roleType: 4
			},
			component: resolve =>
				require(["@/views/teachinggroup/TeachingGroupList.vue"], resolve)
		},
		{
			path: "/CourseMange",
			name: "CourseMange",
			meta: {
				title: "课表管理",
				roleType: 4,
				code: "CourseTableManage"
			},
			redirect: "/CourseMange/CouserTable",
			component: resolve =>
				require(["@/views/cousertimetable/CourseMange.vue"], resolve),
			children: [{
				path: "CouserTable",
				name: "CouserTable",
				meta: {
					title: "",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/cousertimetable/CouserTable.vue"], resolve)
			},
			{
				path: "CourseTableSet",
				name: "CourseTableSet",
				meta: {
					title: "课表设置",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/cousertimetable/CourseTableSet.vue"], resolve)
			}
			]
		},
		{
			path: "/physicalqualitylist",
			name: "physicalqualitylist",
			meta: {
				title: "学生体质数据",
				roleType: 4,
				code: "Corporeity"
			},
			component: resolve =>
				require(["@/views/physicalquality/PhysicalQualityList.vue"], resolve)
		},
		{
			path: "/HealthCheck",
			name: "HealthCheck",
			meta: {
				title: "学生体检数据",
				roleType: 4,
				code: "HealthExamination"
			},
			redirect: "/HealthCheck/List",
			component: resolve =>
				require(["@/views/physicalquality/HealthCheckMain.vue"], resolve),
			children: [{
				path: "List",
				name: "HealthCheckList",
				children: [],
				meta: {
					roleType: 4
				},
				component: resolve =>
					require(["@/views/physicalquality/HealthCheckList.vue"], resolve)
			},
			{
				path: "EditHealthCheck/:xsid?/:xqid?",
				name: "EditHealthCheck",
				children: [],
				meta: {
					title: "编辑",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/physicalquality/EditHealthCheck.vue"], resolve)
			}
			]
		}
		]
	},
	{
		path: "",
		meta: {
			title: "评价管理",
			roleType: 4,
			code: "EvaluationManage"
		},
		component: resolve =>
			require(["@/views/EvaluationManage/Index.vue"], resolve),
		groupcode: "evaluationManager",
		children: [
			{
				path: "/evaluationsystem",
				name: "evaluationsystem",
				meta: {
					title: "体系管理",
					roleType: 4,
					code: "SystemManage"
				},
				component: resolve =>
					require(["@/views/manager/evaluationsystem/Index.vue"], resolve)
			},
			{
				path: "",
				meta: {
					title: "学生指标管理",
					roleType: 4,
					code: "StudentIndexManage"
				},
				component: resolve => require(["@/views/EvaluationManage/Indexrole.vue"], resolve),
				groupcode: "StudentIndexManage",
				children: [
					{
						path: "/schoolindex1",
						name: "schoolindex1",
						meta: {
							title: "通用指标管理",
							roleType: 4,
							code: "NormalIndexManage"
						},
						component: resolve =>
							require(["@/views/manager/schoolindex/Table.vue?t=1"], resolve)
					},
					{
						path: "/starsindex1",
						name: "starsindex1",
						meta: {
							title: "星星指标管理",
							roleType: 4,
							code: "StarsIndexManage"
						},
						component: resolve =>
							require(["@/views/manager/starsIndex/Index.vue?t=1"], resolve)
					},
					{
						path: "/questionnaire1",
						name: "questionnaire1",
						meta: {
							title: "评价问卷管理",
							roleType: 4,
							code: "EvaluationQuestionManage"
						},
						component: () => import("@/views/manager/questionnaire/Index.vue?t=1")
					}
				]
			},
			{
				path: "",
				meta: {
					title: "教师指标管理",
					roleType: 4,
					code: "TeacherIndexManage"
				},
				component: resolve => require(["@/views/EvaluationManage/Indexrole.vue"], resolve),
				groupcode: "TeacherIndexManage",
				children: [
					{
						path: "/schoolindex2",
						name: "schoolindex2",
						meta: {
							title: "通用指标管理",
							roleType: 4,
							code: "NormalIndexManage"
						},
						component: resolve =>
							require(["@/views/manager/schoolindex/Table.vue?t=2"], resolve)
					},
					{
						path: "/starsindex2",
						name: "starsindex2",
						meta: {
							title: "星星指标管理",
							roleType: 4,
							code: "StarsIndexManage"
						},
						component: resolve =>
							require(["@/views/manager/starsIndex/Index.vue?t=2"], resolve)
					},
					{
						path: "/questionnaire2",
						name: "questionnaire2",
						meta: {
							title: "评价问卷管理",
							roleType: 4,
							code: "EvaluationQuestionManage"
						},
						component: () => import("@/views/manager/questionnaire/Index.vue?t=2")
					}
				]
			},
			// {
			//   path: "/evaluationobjectset",
			//   name: "evaluationobjectset",
			//   meta: {
			//     title: "对象管理",
			//     roleType: 4
			//   },
			//   component: resolve =>
			//     require(["@/views/manager/evaluationobjectset/Index.vue"], resolve)
			// },

			{
				path: "/testtree",
				name: "testtree",
				meta: {
					title: "测试树",
					roleType: 4
				},
				hidden: true,
				component: resolve =>
					require(["@/views/manager/starsIndex/TestTree.vue"], resolve)
			},
			{
				path: "/teacherevaluationcountplanset",
				name: "teacherevaluationcountplanset",
				meta: {
					title: "教师目标计划",
					roleType: 4,
					code: "TeacherPlan"
				},
				component: resolve =>
					require([
						"@/views/manager/teacherevaluationcountplanset/Index.vue"
					], resolve)
			},
			{
				path: "/qrcodemanage",
				name: "qrcodemanage",
				meta: {
					title: "二维码",
					permissionCode: "GenerateQrCode",
					roleType: 4,
					code: "Evaluation-QR-Code"
				},
				component: resolve =>
					require(["@/views/qrcode/QrCodeManage.vue"], resolve)
			},
			{
				path: "/qrcodecolor",
				name: "qrcodecolor",
				meta: {
					title: "二维码设置",
					permissionCode: "GenerateQrCode",
					roleType: 4,
					code: "QRCodeSet"
				},
				component: resolve =>
					require(["@/views/qrcode/QrCodeRule.vue"], resolve)
			},
			{
				path: "/qrcodelist",
				name: "qrcodelist",
				meta: {
					title: "二维码生成信息",
					permissionCode: "GenerateQrCode",
					roleType: 4,
					code: "QRCodeList"
				},
				component: resolve =>
					require(["@/views/qrcode/QrCodeList.vue"], resolve)
			}
		]
	},
	{
		path: "",
		meta: {
			title: "综合设置",
			roleType: 4,
			code: "IntegratedSet"
		},
		component: resolve => require(["@/views/Integrated/Index.vue"], resolve),
		groupcode: "comprehensiveSet",
		children: [{
			path: "/studentexchangearticle",
			name: "studentexchangearticle",
			meta: {
				title: "兑换管理",
				roleType: 4,
				code: 'exchangeManage'
			},
			children: [],
			component: resolve =>
				require(["@/views/manager/studentexchangearticle/Index.vue"], resolve)
		},
		{
			path: "/curriculavariablemanager",
			name: "curriculavariablemanager",
			meta: {
				title: "课辅活动管理",
				roleType: 4,
				code: "SupplementaryActivity"
			},
			component: resolve =>
				require([
					"@/views/manager/curriculavariablemanager/Index.vue"
				], resolve)
		},
		{
			path: "/jxgy",
			name: "jxgygl",
			meta: {
				title: "家校共育",
				roleType: 4,
				code: "FamilySchoolCooperation"
			},
			redirect: "/jxgy/jhgl",
			component: resolve =>
				require(["@/views/extracurricular/ExtracurricularMain.vue"], resolve),
			children: [{
				path: "jhgl",
				name: "jxgy",
				children: [],
				meta: {
					roleType: 4
				},
				component: resolve =>
					require([
						"@/views/extracurricular/ExtracurricularList.vue"
					], resolve)
			},
			{
				path: "lbgl",
				name: "jxgylb",
				children: [],
				meta: {
					title: "家校共育类别",
					roleType: 4
				},
				component: resolve =>
					require([
						"@/views/extracurricular/ExtracurricularCategoryList.vue"
					], resolve)
			},
			{
				path: "bj/:hdid?",
				name: "bjjxgy",
				children: [],
				meta: {
					title: "编辑家校共育",
					roleType: 4
				},
				component: resolve =>
					require([
						"@/views/extracurricular/EditExtracurricular.vue"
					], resolve)
			}
			]
		},
		{
			path: "/evaluationmainactivitymanager",
			name: "evaluationmainactivitymanager",
			meta: {
				title: "主活动管理",
				roleType: 4,
				code: "MainActivityManage"
			},
			component: () =>
				import("@/views/manager/evaluationmainactivitymanager/Index.vue")
		},
		{
			path: "/schoolawardset",
			name: "schoolawardset",
			meta: {
				title: "学校获奖设置",
				roleType: 4,
				code: "SchoolPrizeSet"
			},
			component: () =>
				import("@/views/schoolawardmanage/schoolawardlevel.vue")
		},
		{
			path: "/growthreportset",
			name: "GrowthReportSet",
			meta: {
				title: "成长报告设置",
				roleType: 4,
				code: "GrowthReportSet"
			},
			component: () => import("@/views/Integrated/GrowthReportSet/Index.vue")
		},
		{
			path: "/growthsystem",
			name: "growthsystem",
			meta: {
				title: "成长体系",
				roleType: 4,
				code: "GrowthSystem"
			},
			component: () => import("@/views/manager/growthsystem/Index.vue"),
			children: [{
				path: "personandgroup",
				name: "personandgroup",
				meta: {
					title: "个人与小组",
					roleType: 4
				},
				component: () =>
					import("@/views/manager/growthsystem/personandgroup/Index.vue")
			},
			{
				path: "persongrowth",
				name: "persongrowth",
				meta: {
					title: "个人成长体系",
					roleType: 4
				},
				component: () =>
					import("@/views/manager/growthsystem/persongrowth/Index.vue"),
				children: [{
					path: "",
					name: "table",
					meta: {
						roleType: 4
					},
					component: () =>
						import("@/views/manager/growthsystem/persongrowth/Table.vue")
				},
				{
					path: "form",
					name: "form",
					meta: {
						title: "体系",
						roleType: 4
					},
					component: () =>
						import("@/views/manager/growthsystem/persongrowth/Form.vue")
				},
				]
			},
			{
				path: "personspecialgrowth",
				name: "personspecialgrowth",
				meta: {
					title: "个人特色体系",
					roleType: 4
				},
				component: () =>
					import(
						"@/views/manager/growthsystem/personSpecialGrowth/Index.vue"
					),
				children: [{
					path: "",
					name: "table",
					meta: {
						roleType: 4
					},
					component: () =>
						import(
							"@/views/manager/growthsystem/personSpecialGrowth/Table.vue"
						)
				},
				{
					path: "form",
					name: "form",
					meta: {
						title: "体系",
						roleType: 4
					},
					component: () =>
						import(
							"@/views/manager/growthsystem/personSpecialGrowth/Form.vue"
						)
				}
				]
			},
			{
				path: "classgrowth",
				name: "classgrowth",
				meta: {
					title: "班级成长体系",
					roleType: 4
				},
				component: () =>
					import("@/views/manager/growthsystem/classgrowth/Index.vue"),
				children: [{
					path: "",
					name: "table",
					meta: {
						roleType: 4
					},
					component: () =>
						import("@/views/manager/growthsystem/classgrowth/Table.vue")
				},
				{
					path: "form",
					name: "form",
					meta: {
						title: "体系",
						roleType: 4
					},
					component: () =>
						import("@/views/manager/growthsystem/classgrowth/Form.vue")
				}
				]
			},
			{
				path: "personscoreset",
				name: "personscoreset",
				meta: {
					title: "个人综合分值设置",
					roleType: 4
				},
				component: () =>
					import("@/views/manager/growthsystem/personscoreset/Index.vue")
			},
			{
				path: "classscoreset",
				name: "classscoreset",
				meta: {
					title: "班级综合分值设置",
					roleType: 4
				},
				component: () =>
					import("@/views/manager/growthsystem/classscoreset/Index.vue")
			},
			{
				path: "backgroundset",
				name: "backgroundset",
				meta: {
					title: "背景图设置",
					roleType: 4
				},
				component: () =>
					import("@/views/manager/growthsystem/backgroundset/Index.vue")
			},
			{
				path: "persongrowthtree",
				name: "persongrowthtree",
				meta: {
					title: "个人成长成长树",
					roleType: 4
				},
				component: () =>
					import("@/views/manager/growthsystem/persongrowthtree/index.vue")
			},
			{
				path: "classgrowthtree",
				name: "classgrowthtree",
				meta: {
					title: "班级树",
					roleType: 4
				},
				component: () =>
					import("@/views/manager/growthsystem/classgrowthtree/index.vue")
			}
			]
		},
		{
			path: "/teacherdevelopmentsystem",
			name: "teacherdevelopmentsystem",
			meta: {
				title: "教师发展体系设置",
				roleType: 4,
				// code: "GrowthSystem"
			},
			component: () => import("@/views/manager/teacherDevelopmentSystem/Index.vue"),
		},
		{
			path: "/survey",
			name: "survey",
			meta: {
				title: "家长满意度",
				roleType: 4,
				code: "FamilySatisfaction"
			},
			redirect: "/survey/surveylist",
			component: resolve =>
				require(["@/views/survey/SurveyMain.vue"], resolve),
			children: [{
				path: "surveylist",
				name: "surveylist",
				children: [],
				meta: {
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/SurveyList.vue"], resolve)
			},
			{
				path: "editsurvey/:id?",
				name: "editsurvey",
				children: [],
				meta: {
					title: "编辑家长满意度",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/EditSurvey.vue"], resolve)
			},
			{
				path: "surveyresultreport/:id?/:xqid?",
				name: "surveyresultreport",
				children: [],
				meta: {
					title: "查看结果",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/SurveyResultReport.vue"], resolve)
			},
			{
				path: "suggestionreport/:id?/:xqid?",
				name: "suggestionreport",
				children: [],
				meta: {
					title: "意见建议结果",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/SuggestionReport.vue"], resolve)
			},
			{
				path: "schoolreport/:id?/:xqid?",
				name: "schoolreport",
				children: [],
				meta: {
					title: "学校满意度调查",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/SchoolReport.vue"], resolve)
			},
			{
				path: "classreport/:id?/:xqid?",
				name: "classreport",
				children: [],
				meta: {
					title: "班级满意度调查",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/ClassReport.vue"], resolve)
			},
			{
				path: "classreportdetail/:id?/:bjid?/:xqid?",
				name: "classreportdetail",
				children: [],
				meta: {
					title: "班级满意度调查详情",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/ClassReportDetail.vue"], resolve)
			},
			{
				path: "teacherreport/:id?/:kcid?/:xqid?",
				name: "teacherreport",
				children: [],
				meta: {
					title: "学科老师满意度调查",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/TeacherReport.vue"], resolve)
			},
			{
				path: "teacherreportdetail/:id?/:kcid?/:jsid?/:xqid?",
				name: "teacherreportdetail",
				children: [],
				meta: {
					title: "学科老师满意度调查详情",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/TeacherReportDetail.vue"], resolve)
			},
			{
				path: "reportdetailtext/:id?/:tmid?/:daid?/:sslb?/:atid?/:kcid?/:xqid?",
				name: "reportdetailtext",
				children: [],
				meta: {
					title: "答案输入项内容",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/ReportDetailText.vue"], resolve)
			},
			{
				path: "surveydetail/:id?/:xqid?",
				name: "surveydetail",
				children: [],
				meta: {
					title: "家长满意度详情",
					roleType: 4
				},
				component: resolve =>
					require(["@/views/survey/SurveyDetail.vue"], resolve)
			}
			]
		},
		{
			path: "/evalutionSet",
			name: "evalutionSet",
			meta: {
				title: "其他设置",
				roleType: 4,
				code: "OtherSet"
			},
			children: [],
			component: resolve =>
				require(["@/views/evalutionSet/evalutionSet.vue"], resolve)
		},
		{
			path: "/pdf",
			name: "pdf",
			meta: {
				title: "成长手册",
				roleType: 4,
				code: "GrowthHandbook"
			},
			component: resolve => require(["@/views/manager/pdf"], resolve),
			children: [{
				path: "export",
				name: "exportpdf",
				component: resolve =>
					require(["@/views/manager/pdf/export.vue"], resolve)
			}]
		}
			// {
			//   path: "/pdf",
			//   name: "pdf",
			//   meta: {
			//     title: "成长手册",
			//     roleType: 4,
			//     code: "GrowthHandbook"
			//   },
			//   component: resolve =>
			//     require(["@/views/manager/pdf/templates/subject"], resolve),
			//   children: [
			//     {
			//       path: "export",
			//       name: "exportpdf",
			//       component: resolve =>
			//         require(["@/views/manager/pdf/export.vue"], resolve)
			//     }
			//   ]
			// }
		]
	},

	{
		path: "",
		groupcode: "examination",
		meta: {
			title: "成绩管理",
			permissionCode: "ScoreManager",
			roleType: 1,
			code: "ScoreManager"
		},
		component: resolve => require(["@/views/Examination/Index.vue"], resolve),
		children: [{
			path: "/examinationmanager",
			name: "examinationmanager",
			meta: {
				title: "考试管理",
				roleType: 1,
				code: "ExaminationManage"
			},
			redirect: "/examinationmanager/List",
			component: resolve =>
				require([
					"@/views/manager/examination/examinationmanager/Index.vue"
				], resolve),
			children: [{
				path: "List",
				name: "examinationmanagerList",
				meta: {
					roleType: 1
				},
				component: resolve =>
					require([
						"@/views/manager/examination/examinationmanager/Table.vue"
					], resolve)
			},
			{
				path: "Edit",
				name: "examinationmanagerEdit",
				meta: {
					title: "考试",
					roleType: 1
				},
				props: true,
				component: resolve =>
					require([
						"@/views/manager/examination/examinationmanager/Form.vue"
					], resolve)
			},
			{
				path: "Input",
				name: "examinationmanagerInput",
				props: true,
				meta: {
					title: "成绩录入",
					roleType: 1
				},
				component: resolve =>
					require([
						"@/views/manager/examination/examinationinput/ScoreInput.vue"
					], resolve)
			},
			{
				path: "ScoreRead",
				name: "ScoreRead",
				props: true,
				meta: {
					title: "查询成绩",
					roleType: 1
				},
				component: resolve =>
					require([
						"@/views/manager/examination/examinationinput/ScoreRead.vue"
					], resolve)
			}
			]
		}]
	},
	{
		path: "",
		groupcode: "awardmanage",
		meta: {
			title: "获奖管理",
			roleType: 1,
			code: "PrizeManage"
		},
		component: resolve => require(["@/views/awardsmanage/Index.vue"], resolve),
		children: [{
			path: "/studentawardmanage",
			name: "studentawardmanage",
			meta: {
				title: "学生获奖",
				roleType: 1,
				code: "StudentPrize"
			},
			redirect: "/studentawardmanage/studentawardlist",
			component: resolve =>
				require(["@/views/awardsmanage/AwardMain.vue"], resolve),
			children: [{
				path: "studentawardlist",
				name: "studentawardlist",
				meta: {
					roleType: 1
				},
				component: resolve =>
					require(["@/views/awardsmanage/StudentAwardList.vue"], resolve)
			},
			{
				path: "addstudentaward",
				name: "addstudentaward",
				meta: {
					title: "获奖",
					roleType: 1
				},
				component: resolve =>
					require(["@/views/awardsmanage/EditStudentAward.vue"], resolve)
			}
			]
		},
		// {
		// 	path: "/classawardmanage",
		// 	name: "classawardmanage",
		// 	meta: {
		// 		title: "班级获奖",
		// 		roleType: 1,
		// 		code: "ClassPrize"
		// 	},
		// 	redirect: "/classawardmanage/classawardlist",
		// 	component: resolve =>
		// 		require(["@/views/awardsmanage/AwardMain.vue"], resolve),
		// 	children: [{
		// 		path: "classawardlist",
		// 		name: "classawardlist",
		// 		meta: {
		// 			roleType: 1
		// 		},
		// 		component: resolve =>
		// 			require(["@/views/awardsmanage/ClassAwardList.vue"], resolve)
		// 	},
		// 	{
		// 		path: "addclassaward",
		// 		name: "addclassaward",
		// 		meta: {
		// 			title: "获奖",
		// 			roleType: 1
		// 		},
		// 		component: resolve =>
		// 			require(["@/views/awardsmanage/EditClassAward.vue"], resolve)
		// 	}
		// 	]
		// },
		{
			path: "/teacherawardmanage",
			name: "teacherawardmanage",
			meta: {
				title: "教师获奖",
				roleType: 1,
				code: "TeacherPrize"
			},
			redirect: "/teacherawardmanage/teacherawardlist",
			component: resolve =>
				require(["@/views/awardsmanage/AwardMain.vue"], resolve),
			children: [{
				path: "teacherawardlist",
				name: "teacherawardlist",
				meta: {
					roleType: 1
				},
				component: resolve =>
					require(["@/views/awardsmanage/TeacherAwardList.vue"], resolve)
			},
			{
				path: "addteacheraward",
				name: "addteacheraward",
				meta: {
					title: "获奖",
					roleType: 1
				},
				component: resolve =>
					require(["@/views/awardsmanage/EditTeacherAward.vue"], resolve)
			}
			]
		},
		// {
		// 	path: "/teamawardmanage",
		// 	name: "teamawardmanage",
		// 	meta: {
		// 		title: "团队获奖",
		// 		roleType: 1,
		// 		code: "TeamPrize"
		// 	},
		// 	redirect: "/teamawardmanage/teamawardlist",
		// 	component: resolve =>
		// 		require(["@/views/awardsmanage/AwardMain.vue"], resolve),
		// 	children: [{
		// 		path: "teamawardlist",
		// 		name: "teamawardlist",
		// 		meta: {
		// 			roleType: 1
		// 		},
		// 		component: resolve =>
		// 			require(["@/views/awardsmanage/TeamAwardList.vue"], resolve)
		// 	},
		// 	{
		// 		path: "addteamaward",
		// 		name: "addteamaward",
		// 		meta: {
		// 			title: "获奖",
		// 			roleType: 1
		// 		},
		// 		component: resolve =>
		// 			require(["@/views/awardsmanage/EditTeamAward.vue"], resolve)
		// 	}
		// 	]
		// },
		{
			path: "/verifyaward",
			name: "verifyaward",
			menuname: "获奖审核",
			meta: {
				title: "获奖审核",
				roleType: 1,
				code: "PrizeCheck"
			},
			redirect: "/verifyaward/awardlist",
			component: resolve =>
				require(["@/views/awardsmanage/AwardMain.vue"], resolve),
			children: [{
				path: "awardlist",
				name: "awardlist",
				meta: {
					roleType: 1
				},
				component: resolve =>
					require(["@/views/awardsmanage/AwardVerifyList.vue"], resolve)
			}]
		}
		]
	},
	{
		path: "",
		groupcode: "oa",
		meta: {
			title: "办公管理",
			permissionCode: "CampusOffice",
			roleType: 1,
			code: "OAManage"
		},
		component: resolve => require(["@/views/OAManage/Index.vue"], resolve),
		children: [{
			path: "/notice",
			name: "notice",
			meta: {
				title: "公告管理",
				roleType: 1,
				code: "NoticeManage"
			},
			redirect: "/notice/noticelist",
			component: resolve =>
				require(["@/views/notice/NoticeMain.vue"], resolve),
			children: [{
				path: "noticelist",
				name: "mylist",
				children: [],
				meta: {
					roleType: 1
				},
				component: resolve =>
					require(["@/views/notice/NoticeList.vue"], resolve)
			},
			{
				path: "editnotice/:ggid?",
				name: "editnotice",
				children: [],
				meta: {
					title: "编辑通知公告",
					roleType: 1
				},
				component: resolve =>
					require(["@/views/notice/EditNotice.vue"], resolve)
			},
			{
				path: "noticedetail/:ggid?",
				name: "noticedetail",
				children: [],
				meta: {
					title: "通知公告详情",
					roleType: 1
				},
				component: resolve =>
					require(["@/views/notice/NoticeDetail.vue"], resolve)
			},
			{
				path: "noticereadreport/:ggid?",
				name: "noticereadreport",
				children: [],
				meta: {
					title: "通知公告阅读记录",
					roleType: 1
				},
				component: resolve =>
					require(["@/views/notice/NoticeReadReport.vue"], resolve)
			}
			]
		},
		{
			path: "/questionnairesurvey",
			name: "questionnairesurvey",
			meta: {
				title: "问卷管理",
				roleType: 1,
				code: "OA-QuestionManage"
			},
			redirect: "/questionnairesurvey/questionnairesurveylist",
			component: resolve =>
				require(["@/views/notice/QuestionnaireMain.vue"], resolve),
			children: [{
				path: "questionnairesurveylist",
				name: "myquestionnairelist",
				children: [],
				meta: {
					roleType: 1
				},
				component: resolve =>
					require(["@/views/notice/QuestionnaireList.vue"], resolve)
			},
			{
				path: "editquestionnaire/:ggid?",
				name: "editquestionnaire",
				children: [],
				meta: {
					title: "编辑问卷调查",
					roleType: 1
				},
				component: resolve =>
					require(["@/views/notice/EditQuestionnaire.vue"], resolve)
			},
			{
				path: "questionnairedetail/:ggid?",
				name: "questionnairedetail",
				children: [],
				meta: {
					title: "问卷详情",
					roleType: 1
				},
				component: resolve =>
					require(["@/views/notice/QuestionnaireDetail.vue"], resolve)
			},
			{
				path: "questionnaireanswer/:ggid?/:fxid?",
				name: "questionnaireanswer",
				children: [],
				meta: {
					title: "问卷详情",
					roleType: 1
				},
				component: resolve =>
					require(["@/views/notice/QuestionnaireAnswer.vue"], resolve)
			}
			]
		}

			// {
			//   path: "/reportmanage",
			//   name: "reportmanage",
			//   meta: {
			//     title: "自定义报表",
			//     roleType: 1
			//   },
			//   component: resolve =>
			//     require(["@/views/customreport/CustomReportMain.vue"], resolve),
			//   redirect: "/reportmanage/reportlist",
			//   children: [
			//     {
			//       path: "reportlist",
			//       name: "reportlist",
			//       meta: {
			//         roleType: 1
			//       },
			//       component: resolve =>
			//         require(["@/views/customreport/SchoolReportList.vue"], resolve)
			//     },
			//     {
			//       path: "reportdata/:reportid?",
			//       name: "reportdata",
			//       meta: {
			//         title: "自定义报表查看",
			//         roleType: 1
			//       },
			//       component: resolve =>
			//         require(["@/views/customreport/SchoolReportData.vue"], resolve)
			//     }
			//   ]
			// },
		]
	},
	{
		path: "",
		groupcode: "reportCenter",
		meta: {
			title: "报表中心",
			permissionCode: "CampusOffice",
			roleType: 1,
			code: "ReportCenter"
		},
		component: resolve => require(["@/views/reportCenter/Index.vue"], resolve),
		children: [
			{
				path: "/reportmanage",
				name: "reportmanage",
				meta: {
					title: "自定义报表",
					roleType: 1,
					code: "CenterCustomReport"
				},
				redirect: "/reportmanage/reportlist",
				component: resolve =>
					require([
						"@/views/reportCenter/customReport/CustomReportMain.vue"
					], resolve),
				children: [{
					path: "reportlist",
					name: "reportlist",
					meta: {
						roleType: 1
					},
					component: resolve =>
						require([
							"@/views/reportCenter/customReport/ReportList.vue"
						], resolve)
				},
				{
					path: "reportdata",
					name: "reportdata",
					meta: {
						title: "自定义报表查看",
						roleType: 1
					},
					component: resolve =>
						require([
							"@/views/reportCenter/customReport/ReportData.vue"
						], resolve)
				}
				]
			},
			{
				path: "/classcenter",
				name: "classcenter",
				meta: {
					title: "班级中心",
					roleType: 1,
					code: "CenterClassReport"
				},
				component: resolve =>
					require(["@/views/reportCenter/classCenter/Index.vue"], resolve)
			},
			{
				path: "/studycenter",
				name: "studycenter",
				meta: {
					title: "学科中心",
					roleType: 1,
					code: "CenterStudyReport"
				},
				component: resolve =>
					require(["@/views/reportCenter/studyCenter/Index.vue"], resolve)
			},
			{
				path: "/ZHD_JXGY",
				name: "ZHD_JXGY",
				meta: {
					title: "子活动及家校共育",
					roleType: 1,
					code: "ZHD_JXGY"
				},
				component: resolve =>
					require(["@/views/reportCenter/ZHD_JXGY/Index.vue"], resolve)
			},
			{
				path: "/FinalArchives_Index",
				meta: {
					title: "期末归档",
					roleType: 4,
					code: "FinalArchives"
				},
				redirect: '/FinalArchives_Index/FinalArchives_Grade',
				component: resolve =>
					require(["@/views/reportCenter/finalArchives/index.vue"], resolve),
				children: [
					{
						path: "FinalArchives_Grade",
						name: "FinalArchives_Grade",
						meta: {
							roleType: 4,
						},
						component: resolve =>
							require(["@/views/reportCenter/finalArchives/grade.vue"], resolve)
					},
					{
						path: "FinalArchives_Export",
						name: "FinalArchives_Export",
						meta: {
							roleType: 4,
						},
						component: resolve =>
							require(["@/views/reportCenter/finalArchives/export.vue"], resolve)
					},
				]
			},
		]
	},
	{
		path: "",
		groupcode: "DevelopmentCenter2",
		meta: {
			title: "发展中心",
			permissionCode: "CampusOffice",
			roleType: 1,
			code: "DevelopmentCenter2"
		},
		component: resolve => require(["@/views/developmentCenter/ZYDDBB/router_view.vue"], resolve),
		children: [
			{
				path: "/developmentcenter-view",
				name: "developmentcenter-view",
				meta: {
					title: "职业道德报表",
					roleType: 1,
					code: "ZYDDBB"
				},
				redirect: '/developmentcenter-view/developmentcenter',
				component: resolve =>
					require(["@/views/developmentCenter/ZYDDBB/developmentcenter-view.vue"], resolve),
				children: [
					{
						path: "developmentcenter",
						name: "developmentcenter",
						meta: {
							// title:'123',
							roleType: 1,
						},
						component: resolve =>
							require(["@/views/developmentCenter/ZYDDBB/Main.vue"], resolve),

					},
					{
						path: "developmentitemlist",
						name: "developmentitemlist",
						meta: {
							// title: "哈哈",
							roleType: 1,
						},
						component: resolve =>
							require(["@/views/developmentCenter/ZYDDBB/Index.vue"], resolve)
					},
				]
			},
			{
				path: "/teacherGeneralReport",
				name: "teacherGeneralReport",
				meta: {
					title: "教师通用报表",
					roleType: 1,
					code: "TYJSBB"
				},
				redirect: '/teacherGeneralReport/teacherIndex',
				component: resolve =>
					require(["@/views/developmentCenter/TYJSBB/router_view.vue"], resolve),
				children: [
					{
						path: "teacherIndex",
						name: "teacherIndex",
						meta: {
							roleType: 1,
						},
						component: resolve =>
							require(["@/views/developmentCenter/TYJSBB/Index.vue"], resolve)
					},
					{
						path: "/teachersetXM",
						name: "teachersetXM",
						meta: {
							title: "设置项目",
							roleType: 1,
						},
						component: resolve =>
							require(["@/views/developmentCenter/TYJSBB/setXM.vue"], resolve)
					},
					{
						path: "/teacherMain",
						name: "teacherMain",
						meta: {
							title: "通用教师报表",
							roleType: 1,
						},
						component: resolve =>
							require(["@/views/developmentCenter/TYJSBB/Main.vue"], resolve)
					},

				]
			},

		]
	},

	{
		path: "/stageevaluation",
		name: "stageevaluation",
		meta: {
			title: "阶段评价",
			roleType: 1,
			code: "StageEvaluation"
		},
		redirect: "/stageevaluation/stageactivity",
		component: resolve =>
			require(["@/views/manager/stageevaluation/Main.vue"], resolve),
		children: [{
			path: "stageactivity",
			name: "stageactivity",
			children: [],
			meta: {
				roleType: 1
			},
			component: resolve =>
				require(["@/views/manager/stageevaluation/ActivityList.vue"], resolve)
		},
		{
			path: "stagecourse",
			name: "stagecourse",
			children: [],
			meta: {
				title: "课程选择",
				roleType: 1
			},
			component: resolve =>
				require(["@/views/manager/stageevaluation/CourseList.vue"], resolve)
		},
		{
			path: "stagedata",
			name: "stagedata",
			children: [],
			meta: {
				title: "学业述评",
				roleType: 1
			},
			component: resolve =>
				require(["@/views/manager/stageevaluation/DataPage.vue"], resolve)
		},
		{
			path: "stagedata2",
			name: "stagedata2",
			children: [],
			meta: {
				title: "学业述评",
				roleType: 1
			},
			component: resolve =>
				require(["@/views/manager/stageevaluation/DataPage2.vue"], resolve)
		}
		]
	},
	{
		path: '/comprehensive',
		name: 'comprehensive',
		meta: {
			title: '成长对话',
			roleType: 1,
			code: 'Comprehensive'
		},
		redirect: '/comprehensive/conversation',
		component: resolve => require(['@/views/growthconversation/Main.vue'], resolve),
		children: [
			{
				path: "/comprehensive/conversation",
				name: "conversation",
				meta: {
					title: "成长对话",
					roleType: 1,
					code: "GrowthDialogue"
				},
				children: [],
				component: resolve =>
					require(["@/views/growthconversation/ConversationList.vue"], resolve)
			},
		]
	},

	{
		path: "/QrCode",
		name: "QrCode",
		meta: {
			title: "二维码",
			permissionCode: "GenerateQrCode",
			roleType: 1,
			code: "GenerateQrCode"
		},
		component: resolve => require(["@/views/qrcode/QrCodeSet.vue"], resolve),
		children: []
	},
	{
		path: "/imageManage",
		name: "ImageManage",
		meta: {
			title: "图片管理",
			permissionCode: "ImageManage",
			roleType: 1,
			code: "ImageManage"
		},
		component: resolve => require(["@/views/imageManage/index.vue"], resolve),
	},

	{
		path: "/QrCodePreview",
		name: "QrCodePreview",
		meta: {
			title: "二维码预览",
			permissionCode: "QrCodePreview",
			roleType: 1,
			code: "QrCodePreview"
		},
		component: resolve =>
			require(["@/views/qrcode/QrCodePreView.vue"], resolve),
		children: []
	}
];