<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div
        v-if="formData.length > 0"
        class="cz-cont-name-l"
        style="font-size: 1rem; color: #333333;"
      >
        {{ formData[0].TMPXH }}.&nbsp;{{ formData[0].FXMC }}({{
          SSLB[formData[0].FXLX - 1].Value
        }})
      </div>
      <div
        v-for="da in formData"
        :key="da.DAId"
        class="tab-heng-box"
        style="margin-top: 1rem;"
      >
        <div class="essay-t-ms" style="color: #666666; margin-top: 2rem;">
          {{ ZM[da.DAPXH - 1] }}：{{ da.DAMC }}
        </div>
        <el-table
          :show-header="true"
          cellpadding="0"
          border
          cellspacing="0"
          :data="da.ClassList"
          highlight-current-row
          style="width: 100%;"
        >
          <el-table-column
            property="BJMC"
            align="center"
            label="班级"
          ></el-table-column>
          <el-table-column
            property="ClassCount"
            align="center"
            label="人数"
          ></el-table-column>
          <el-table-column property="AnswerCount" align="center" label="姓名">
            <template slot-scope="scope">
              <span v-for="user in scope.row.UserList" :key="user.CJR">{{
                user.CJRXM
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    this.tzggid = this.$route.params.ggid;
    this.fxid = this.$route.params.fxid;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
    }
  },
  computed: {
    listTemp: function() {
      var list = this.list;
      var arrTemp = [];
      var index = 0;
      var sectionCount = 3;
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    loaddata: function() {
      if (this.tzggid !== undefined && this.tzggid !== "") {
        this.$api.post(
          "v1/notice/GetQuestionnaireAnswerDetail",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            TZGGId: this.tzggid,
            FXId: this.fxid,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.formData = r.Target;
            }
          }
        );
      }
    }
  },
  data() {
    return {
      isDisabled: false,
      webFilePath: "",
      fileServerApiPath: "",
      tzggid: "",
      fxid: "",
      ssxx: "",
      ssxq: "",
      token: "",
      formData: {},
      cjr: "",
      SSLB: [
        { Key: 1, Value: "单选" },
        { Key: 2, Value: "多选" },
        { Key: 3, Value: "问答" }
      ],
      ZM: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ],
      options: [
        {
          value: "1",
          label: "学生"
        },
        {
          value: "2",
          label: "老师"
        },
        {
          value: "3",
          label: "全体师生"
        }
      ]
    };
  }
};
</script>
