<template>
  <div class="popv-cent">
    <div class="stud-box">
      <div
        class="operate-box"
        style="padding-bottom: 10px; margin-top: 10px; font-size: 14px;"
      >
        每个学生参与的活动总数设置
        <el-input
          v-model="data.StudentTotalityLimit"
          style="width: 50px;"
          class="popv-inpt"
          placeholder="请输入"
          size="medium"
        ></el-input>
      </div>
      <div
        class="col-back-box"
        style="
          font-size: 14px;
          height: 220px;
          overflow-x: hidden;
          overflow-y: auto;
        "
      >
        <div
          class="operate-box"
          style="padding-bottom: 10px; margin-top: 10px;"
        >
          <div
            v-for="item in basicData.CategoryList"
            :key="item.Id"
            class="seac-btn-box"
            style="margin-right: 10px;"
            @click="currentCategoryId = item.Id"
          >
            <a
              :class="[
                'seac-btn-boxa',
                {
                  'btn-boxa-sty whole-back border-col border-col whole-back-hover':
                    item.Id === currentCategoryId
                }
              ]"
              href="javascript:void(0);"
              >{{ item.Name }}</a
            >
          </div>
        </div>
        <div class="er-mianbox-l">
          <div
            v-for="item in gradeSigUpLimitList.filter(
              c => c.CategoryId === currentCategoryId
            )[0].GradeSigUpLimit"
            :key="item.GradeNum"
            class="er-box-l"
            style="width: 140px;"
          >
            {{ item.GradeNumStr }}
            <el-input
              v-model="item.CountStr"
              style="width: 50px;"
              class="popv-inpt"
              placeholder="请输入"
              size="medium"
            ></el-input
            >个
          </div>
        </div>
      </div>
      <div class="prompt-title-box">温馨提示：输入"-1"时表示不限制个数</div>
      <div class="cent-bott-butt">
        <el-button
          size="medium"
          style="margin: 0px 25px;"
          @click="submit"
          :loading="isSubmitting"
          >确认</el-button
        >
        <el-button size="medium" style="margin: 0px 25px;" @click="cancel"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      data: {},
      currentCategoryId: "",
      gradeSigUpLimitList: [{ CategoryId: "", GradeSigUpLimit: [] }]
    };
  },
  props: {
    SchoolId: {
      type: String,
      default: ""
    },
    basicData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    initData() {
      this.$api.post(
        "/v1/curriculavariable/GetCurriculaVariableCategorySigUpLimit",
        {
          token: this.$store.state.token,
          SchoolId: this.SchoolId
        },
        r => {
          if (r.Code === 0) {
            this.data = r.Target;
            this.currentCategoryId = this.basicData.CategoryList[0].Id;
            this.gradeSigUpLimitList = [];
            for (let i = 0; i < this.basicData.CategoryList.length; i++) {
              const gradeSigUpLimit = JSON.parse(
                JSON.stringify(this.basicData.JoinGradeList)
              );
              const categoryId = this.basicData.CategoryList[i].Id;
              const obj = this.data.CategorySigUpSLimit.find(
                c => c.CategoryId === categoryId
              );
              if (
                obj &&
                obj.GradeSigUpLimit &&
                obj.GradeSigUpLimit.length > 0
              ) {
                for (let j = 0; j < obj.GradeSigUpLimit.length; j++) {
                  if (
                    gradeSigUpLimit.find(
                      c => c.GradeNum === obj.GradeSigUpLimit[j].GradeNum
                    )
                  ) {
                    gradeSigUpLimit.find(
                      c => c.GradeNum === obj.GradeSigUpLimit[j].GradeNum
                    ).CountStr = obj.GradeSigUpLimit[j].Limit;
                  }
                }
              }

              this.gradeSigUpLimitList.push({
                CategoryId: categoryId,
                GradeSigUpLimit: gradeSigUpLimit
              });
            }
          }
        }
      );
    },
    submit() {
      this.isSubmitting = true;
      const user = JSON.parse(localStorage.getItem("userinfo"));
      this.data.CategorySigUpSLimit = [];
      for (let i = 0; i < this.gradeSigUpLimitList.length; i++) {
        const gradeSigUpLimit = [];
        for (
          let j = 0;
          j < this.gradeSigUpLimitList[i].GradeSigUpLimit.length;
          j++
        ) {
          const limit = parseInt(
            this.gradeSigUpLimitList[i].GradeSigUpLimit[j].CountStr
          );
          if (limit) {
            gradeSigUpLimit.push({
              GradeNum: this.gradeSigUpLimitList[i].GradeSigUpLimit[j].GradeNum,
              Limit: limit
            });
          }
        }
        if (gradeSigUpLimit.length > 0) {
          this.data.CategorySigUpSLimit.push({
            CategoryId: this.gradeSigUpLimitList[i].CategoryId,
            GradeSigUpLimit: gradeSigUpLimit
          });
        }
      }

      this.$api.post(
        "/v1/curriculavariable/EditCurriculaVariableCategorySigUpLimit",
        {
          token: this.$store.state.token,
          CategoryGradeSigUpLimit: this.data,
          UserName: user.XSM,
          UserId: user.Id,
          SchoolId: this.SchoolId
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
          }
        }
      );
      this.$emit("closeOtherDialog");
    },
    cancel() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeOtherDialog");
      });
    }
  },
  created: function() {
    this.initData();
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        this.isSubmitting = false;
        this.initData();
      } else {
        // 重置校验结果
        // this.$refs["formData"].resetFields();
      }
    }
  }
};
</script>
