<template>
  <el-dialog
    :title="`${title}${setIndexType === 3 ? '' : '(右键指标名称可全选)'}`"
    :visible.sync="visible"
    width="50%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
  >
    <div v-if="setIndexType === 3">
      <el-checkbox-group v-model="radio">
        <el-checkbox
          v-for="item in treeData"
          :key="item.value"
          :label="item.value"
        >
          {{ item.label }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <el-tree
      v-else
      :data="treeData"
      show-checkbox
      default-expand-all
      node-key="value"
      ref="tree"
      check-strictly
      highlight-current
      :default-checked-keys="checked"
      :props="defaultProps"
      @check-change="checkChange"
    >
      <div class="custom-tree-node index-tree" slot-scope="{ node, data }">
        <el-tooltip
          class="item"
          effect="dark"
          :disabled="handleTooltip(0, data)"
          :content="node.label"
          placement="top-start"
        >
          <span class="label" @contextmenu.prevent="rightClick(data)"
            >{{ node.label }}
          </span>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          :disabled="handleTooltip(1, data)"
          :content="data.BZ"
          placement="top-start"
        >
          <span class="bz"> {{ data.BZ }} </span>
        </el-tooltip>
        <div
          class="unshow"
          v-show="
            setIndexType === 1 && checkedList.some(it => it.Id === data.Id)
          "
        >
          <el-checkbox v-model="data.BXSQD" :false-label="2" :true-label="1"
            >不显示前端</el-checkbox
          >
        </div>
      </div>
    </el-tree>
    <span class="dialog-footer" v-if="!validate">
      <div class="prompt-title-box" style="color: red; text-align: center;">
        请至少选择一项
      </div>
    </span>
    <span slot="footer" class="dialog-footer" v-if="treeData.length > 0">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      validate: true,
      checked: [],
      checkedList: [],
      radio: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    treeDataBase: {
      type: Array,
      default: function() {
        return []
      }
    },
    checkedData: {
      type: Array,
      default: function() {
        return []
      }
    },
    title: {
      type: String,
      default: ''
    },
    setIndexType: {
      type: Number,
      default: 1
    }
  },
  methods: {
    closeDialog() {
      this.$confirm('确认关闭？').then(() => {
        this.$emit('canceletIndex')
        this.validate = true
      })
    },
    confirmDialog() {
      let data = []
      if (this.setIndexType === 3) {
        // 问卷
        if (this.radio.length > 0) {
          this.radio.forEach(item => {
            data.push({ value: item })
          })
        }
      } else {
        let checked = this.$refs.tree.getCheckedNodes() || []
        checked.forEach(item => {
          let res = []
          this.filterAllChildren(item, res)
          console.log(res, 'res')
          // 勾选的node没得子孙 那么这个node就是末级 满足提交条件
          if (res.length === 0) data.push(item)
          else {
            // 有子孙 但是子孙没有被勾选 满足提交条件
            if (!res.some(el => checked.some(it => it.value === el.value)))
              data.push(item)
          }
        })
      }
      console.log(data, 'data')
      if (data.length > 0) {
        this.$emit('saveSetIndex', data)
      } else {
        this.validate = false
      }
    },
    checkChange() {
      const list = this.$refs.tree.getCheckedNodes()
      this.checkedList = list || []
      if (list && list.length > 0) {
        this.validate = true
      } else {
        this.validate = false
      }
    },
    // tree右键全选或者全空
    rightClick(data) {
      if (!data.children || data.children.length === 0) return
      let res = []
      this.filterLastIndex(data, res)
      let checked = this.$refs.tree.getCheckedNodes()

      // 判断该指标的子孙级指标是否有被勾选
      let flag = ''
      let arr = []
      flag = res.every(id => checked.some(item => item.value === id))
      if (flag) {
        // 自己和子孙级全被勾选 就全空
        arr = checked.filter(item => !res.some(id => id === item.value))
        this.$refs.tree.setCheckedKeys(arr.map(item => item.value))
      } else {
        // 没有被勾选的设置为勾选
        res.forEach(id => {
          let idx = checked.findIndex(item => item.value === id)
          if (idx === -1) arr.push(id)
        })
        this.$refs.tree.setCheckedKeys([
          ...arr,
          ...checked.map(item => item.value)
        ])
      }
    },
    // tree右键时找出所有子孙级指标
    filterLastIndex(data, res) {
      let idx = res.findIndex(id => id === data.value)
      if (idx === -1) res.push(data.value)
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => this.filterLastIndex(item, res))
      }
    },
    // 提交时找勾选指标的所有子孙指标
    filterAllChildren(data, res) {
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => {
          res.push(item)
          this.filterAllChildren(item, res)
        })
      }
    },
    // 显影文字提示
    handleTooltip(type, data) {
      return type
        ? data.BZ
          ? data.BZ.length < 20
          : false
        : data.label.length < 20
    },
    // 递归回显显示前端
    handle_SFXSQD(list, cur) {
      list.forEach(item => {
        if (item.value === cur.ZBID) {
          item.BXSQD = cur.BXSQD === 1 ? 1 : 2
        } else {
          if (item.children) {
            this.handle_SFXSQD(item.children, cur)
          }
        }
      })
    }
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        // 初始化
        if (this.setIndexType === 3) {
          this.treeData = JSON.parse(JSON.stringify(this.treeDataBase))
          let arr = []
          this.checkedData.forEach(it => {
            this.treeData.forEach(item => {
              if (it.ZBID === item.value) arr.push(it.ZBID)
            })
          })
          this.radio = arr
        } else {
          let treeData = JSON.parse(JSON.stringify(this.treeDataBase))
          this.checkedData.forEach(it => {
            this.handle_SFXSQD(treeData, it)
          })
          this.treeData = treeData

          this.$nextTick(() => {
            if (this.$refs.tree)
              this.$refs.tree.setCheckedKeys(
                this.checkedData.map(item => item.ZBID)
              )
          })
        }
      } else {
        // 清空数据
        // this.resetChecked();
      }
    }
  }
}
</script>

<style lang="scss">
.custom-tree-node,
.index-tree {
  width: calc(100% - 18px - 24px - 22px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bz,
  .label {
    width: calc((100% - 100px) / 2);
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .unshow {
    width: 100px;
  }
}
</style>
