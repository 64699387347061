<template>
  <el-form style="width: 500px; display: inline-block;" label-width="120px">
    <el-form-item label="录入人员：">
      <el-checkbox-group v-model="checkedrecordroles">
        <el-checkbox
          :label="1"
          @change="checked => recordonchange(checked, '1', '')"
          >老师自己</el-checkbox
        >
        <br />
        <el-checkbox
          v-for="role in rolelist"
          :key="role.Id"
          :label="role.Id"
          @change="checked => recordonchange(checked, '3', role.Id)"
          >{{ role.JSMC }}</el-checkbox
        >
      </el-checkbox-group>
    </el-form-item>
    <el-divider></el-divider>
    <el-form-item label="是否需要审核：">
      <el-radio-group v-model="needverify">
        <el-radio
          :label="1"
          @change="checked => verifyonchange(true, '1', '1', '')"
          >无需审核</el-radio
        >
        <br />
        <el-radio :label="3">要审核</el-radio>
        <el-form-item label="选择审核人：" v-if="needverify == '3'">
          <el-checkbox-group v-model="bothcheckedroles">
            <el-checkbox
              v-for="role in rolelist"
              :key="role.Id"
              :label="role.Id"
              @change="checked => verifyonchange(checked, '3', '3', role.Id)"
              >{{ role.JSMC }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-radio-group>
    </el-form-item>
    <el-divider></el-divider>
    <el-checkbox
      v-model="isopenteacherselectstudent"
      @change="checked => openteacherselectstudent(checked)"
      >开启录入所带学生获奖</el-checkbox
    >
    <el-divider v-if="isopenteacherselectstudent"></el-divider>
    <el-form-item label="录入人员：" v-if="isopenteacherselectstudent">
      <el-checkbox-group
        v-model="teacherselectstudentcheckedrecordroles"
        v-if="isopenteacherselectstudent"
      >
        <el-checkbox
          :label="4"
          @change="checked => recordonchange(checked, '4', '', '')"
          >老师自己</el-checkbox
        >
        <br />
        <el-checkbox
          v-for="role in rolelist"
          :key="role.Id"
          :label="role.Id"
          @change="checked => recordonchange(checked, '5', role.Id)"
          >{{ role.JSMC }}</el-checkbox
        >
      </el-checkbox-group>
    </el-form-item>
    <el-divider v-if="isopenteacherselectstudent"></el-divider>
    <el-form-item label="是否需要审核：" v-if="isopenteacherselectstudent">
      <el-radio-group v-model="teacherselectstudentneedverify">
        <el-radio
          :label="4"
          @change="checked => verifyonchange(true, '4', '1', '')"
          >无需审核</el-radio
        >
        <br />
        <el-radio :label="5">要审核</el-radio>
        <el-form-item
          label="选择审核人："
          v-if="teacherselectstudentneedverify == '5'"
        >
          <el-checkbox-group v-model="teacherselectstudentcheckedverifyroles">
            <el-checkbox
              v-for="role in rolelist"
              :key="role.Id"
              :label="role.Id"
              @change="checked => verifyonchange(checked, '5', '5', role.Id)"
              >{{ role.JSMC }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "TeacherSet",
  data() {
    return {
      ssxx: "",
      cjr: "",
      rolelist: [],
      checkedrecordroles: [],
      checkedverifyroles: [],
      needverify: 0,
      bothcheckedroles: [],
      isopenteacherselectstudent: false,
      teacherselectstudentcheckedrecordroles: [],
      teacherselectstudentcheckedverifyroles: [],
      teacherselectstudentneedverify: 0
    };
  },
  created: function() {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.ssxx = userinfo.SSZZId;
    this.cjr = userinfo.Id;
    this.loadroles();
    this.loadrecorders();
    this.loadverifiers();
  },
  mounted: function() {},
  methods: {
    loadroles: function() {
      this.$api.post(
        "v1/role/GetRoleList",
        { token: this.$store.state.token, SchoolId: this.ssxx },
        r => {
          this.rolelist = [];
          var list = r.Target;
          if (list !== null && list !== undefined && list.length > 0) {
            for (var i = 0; i < list.length; i++) {
              this.rolelist.push({
                Id: list[i].Id,
                JSMC: list[i].JSMC
              });
            }
          }
        }
      );
    },
    loadrecorders: function() {
      this.$api.post(
        "v1/schoolprizeset/GetTeacherRecordType",
        { SSXX: this.ssxx, token: this.$store.state.token },
        r => {
          this.checkedrecordroles = [];
          this.teacherselectstudentcheckedrecordroles = [];
          if (r.Code === 0) {
            var list = r.Target;
            if (list !== null && list !== undefined && list.length > 0) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].LRRYLX === 1) {
                  this.checkedrecordroles.push(list[i].LRRYLX);
                } else if (list[i].LRRYLX === 3) {
                  this.checkedrecordroles.push(list[i].LRRYGL);
                } else if (list[i].LRRYLX === 4) {
                  this.teacherselectstudentcheckedrecordroles.push(
                    list[i].LRRYLX
                  );
                } else if (list[i].LRRYLX === 5) {
                  this.teacherselectstudentcheckedrecordroles.push(
                    list[i].LRRYGL
                  );
                }
              }
            }
          }
          this.setopenteacherselectstudentstatus();
        }
      );
    },
    loadverifiers: function() {
      this.$api.post(
        "v1/schoolprizeset/GetTeacherVerifyType",
        { SSXX: this.ssxx, token: this.$store.state.token },
        r => {
          this.needverify = 0;
          this.bothcheckedroles = [];
          this.teacherselectstudentneedverify = 0;
          this.teacherselectstudentcheckedverifyroles = [];
          if (r.Code === 0) {
            var list = r.Target;
            if (list !== null && list !== undefined && list.length > 0) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].SHLX === 1) {
                  this.needverify = 1;
                } else if (list[i].SHLX === 3) {
                  this.needverify = 3;
                  this.bothcheckedroles.push(list[i].GLJS);
                } else if (list[i].SHLX === 4) {
                  this.teacherselectstudentneedverify = 4;
                } else if (list[i].SHLX === 5) {
                  this.teacherselectstudentneedverify = 5;
                  this.teacherselectstudentcheckedverifyroles.push(
                    list[i].GLJS
                  );
                }
              }
            }
          }
          this.setopenteacherselectstudentstatus();
        }
      );
    },
    openteacherselectstudent: function(ischecked) {
      if (ischecked) {
        this.isopenteacherselectstudent = true;
      } else {
        this.isopenteacherselectstudent = false;
        this.$api.post(
          "v1/schoolprizeset/DeleteTeacherSelectStudentVerifyType",
          { SSXX: this.ssxx, YHId: this.cjr, token: this.$store.state.token },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
            this.teacherselectstudentcheckedrecordroles = [];
            this.teacherselectstudentneedverify = 0;
            this.teacherselectstudentcheckedverifyroles = [];
          }
        );
      }
    },
    setopenteacherselectstudentstatus: function() {
      this.isopenteacherselectstudent = false;
      if (
        (this.teacherselectstudentcheckedrecordroles !== null &&
          this.teacherselectstudentcheckedrecordroles !== undefined &&
          this.teacherselectstudentcheckedrecordroles.length > 0) ||
        (this.teacherselectstudentcheckedverifyroles !== null &&
          this.teacherselectstudentcheckedverifyroles !== undefined &&
          this.teacherselectstudentcheckedverifyroles.length > 0) ||
        this.teacherselectstudentneedverify > 0
      ) {
        this.isopenteacherselectstudent = true;
      }
    },
    recordonchange: function(ischecked, lx, id) {
      if (ischecked) {
        this.$api.post(
          "v1/schoolprizeset/AddTeacherRecordType",
          {
            CJR: this.cjr,
            SSXX: this.ssxx,
            LRRYLX: lx,
            LRRYGL: id,
            token: this.$store.state.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
          }
        );
      } else {
        this.$api.post(
          "v1/schoolprizeset/DeleteTeacherRecordType",
          {
            SSXX: this.ssxx,
            LRRYLX: lx,
            YHId: this.cjr,
            LRRYGL: id,
            token: this.$store.state.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
          }
        );
      }
    },
    verifyonchange: function(ischecked, shlx, glshlx, id) {
      if (ischecked) {
        this.$api.post(
          "v1/schoolprizeset/AddTeacherVerifyType",
          {
            CJR: this.cjr,
            SSXX: this.ssxx,
            SHLX: shlx,
            GLSHLX: glshlx,
            GLJS: id,
            token: this.$store.state.token
          },
          r => {
            if (r.Code === 0) {
              if (shlx === 1) {
                this.bothcheckedroles = [];
              } else if (shlx === 4) {
                this.teacherselectstudentcheckedverifyroles = [];
              }
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
          }
        );
      } else {
        this.$api.post(
          "v1/schoolprizeset/DeleteTeacherVerifyType",
          {
            SSXX: this.ssxx,
            SHLX: shlx,
            GLSHLX: glshlx,
            YHId: this.cjr,
            GLJS: id,
            token: this.$store.state.token
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: "info",
                message: "操作成功"
              });
            }
          }
        );
      }
    }
  }
};
</script>
