<template>
  <div class="feed-back">
    <div class="search-btn">
      <el-input
        v-model="queryKeys"
        style="width:200px;margin-right:10px"
        placeholder="请输入集团名称关键字"
      ></el-input>
      <el-button
        type="success"
        size="medium"
        icon="el-icon-search"
        @click="getData"
        >搜索</el-button
      >
      <el-button plain icon="el-icon-edit" @click="add">新增</el-button>
    </div>
    <!--TODO:内容-->
    <Table
      :tableData="dataTable"
      v-loading="isLoading"
      :user="user"
      @getDataTable="getData"
    />
    <div class="pagin-box">
      <div class="pagin-wz">
        <!--分页-->
        <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
      </div>
    </div>
    <Form
      :data="dataTable"
      :visible.sync="visible"
      :title="formTitle"
      :shapes="shapes"
      :user="user"
      @closeDialog="closeDialog"
      @saveDialog="closeDialog"
      @getData="getData"
    />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Table from "./Table.vue";
import Form from "./Form.vue";

export default {
  name: "Jtmanager",
  components: {
    Pager,
    Table,
    Form
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0
      },
      token: "",
      user: {},
      schoolId: "",
      isLoading: false,
      visible: false,
      dataTable: [],
      shapes: [],
      formTitle: "",
      queryKeys: ""
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    this.getData();
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
      this.getData();
    },
    getData() {
      this.$api.post(
        "/v1/Growth/QueryGroup",
        {
          token: this.user.Token,
          pageIndex: this.pagerInfo.pageIndex,
          pageSize: this.pagerInfo.pageSize,
          queryKeys: this.queryKeys
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    add() {
      this.visible = true;
    },
    closeDialog(data) {
      if (data) {
        const index = this.dataTable.findIndex(c => c.Id === data.Id);
        if (index === -1) {
          this.dataTable.unshift(data);
        } else {
          this.dataTable.splice(index, 1, data);
        }
      }
      this.visible = false;
    },
    closeDialogXX(data) {
      if (data) {
        const index = this.dataTable.findIndex(c => c.Id === data.Id);
        if (index === -1) {
          this.dataTable.unshift(data);
        } else {
          this.dataTable.splice(index, 1, data);
        }
      }
      this.visibleXX = false;
    },
    delItem(data) {
      const index = this.dataTable.findIndex(c => c.Id === data.Id);
      if (index !== -1) {
        this.dataTable.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.feed-back {
  .search-btn {
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 1px 1px 2px 4px #f9f9f9;
    .el-date-picker {
      width: 250px;
    }
    .state {
      display: flex;
      align-items: center;
      margin-right: 10px;
      span:first-child {
        padding: 0 10px;
        font-weight: 700;
      }
      .el-select {
        width: 180px;
      }
    }
  }
  .el-table {
    box-shadow: 1px 1px 2px 2px #f3f3f3;
    margin-top: 20px;
  }
  .el-page {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }
  .form-btn {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
  }
}
</style>
