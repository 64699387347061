<template>
  <div class="cont-whole-box">
    <div style="padding: 0px 30px 30px 30px;">
      <!--内容操作区开始-->
      <div class="multi-opt-box">
        <div
          class="multi-opt-box"
          style="padding-bottom: 0px; border-bottom: 1px solid #e5e5e5;"
        >
          <div class="multi-opt">
            <div class="multi-opt-rig">
              <!--内容：2个字时“b-word-widt”，3个字时“c-word-widt”,4.5个字时“ef-word-widt”-->
              <div
                @click="selectGrade(item.Id)"
                class="ef-word-widt"
                v-for="(item, index) in schoolOrgList"
                :key="index"
              >
                <a
                  :class="[
                    item.Id === gradeId ? 'opt-rigsty whole-back' : '',
                    'ef-word-widta whole-back-hover'
                  ]"
                  >{{ item.Name }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="popo-xk-cent"
          style="height: 130px; box-sizing: border-box; padding-left: 10px;"
        >
          <div
            @click="selectclass(item)"
            v-for="(item, index) in schoolOrgList.find(c => c.Id === gradeId)
              ? schoolOrgList.find(c => c.Id === gradeId).ChidOrgList
              : []"
            :key="index"
            class="disilb-box"
            style="width: 160px;"
          >
            <div
              :class="[item.Id === classId ? 'tabli-xz-sty' : '', 'tabli-xz']"
            ></div>
            <div class="tablil-xz-name epp-ov" title="以位参加">
              {{ item.Name }}
            </div>
          </div>
        </div>
      </div>
      <!--内容操作区结束-->
      <!--内容表格区开始-->
      <div class="tab-heng-box">
        <el-tag
          v-for="tag in tags"
          :key="tag.Id"
          closable
          @close="handleClose(tag)"
          >{{ tag.XM }}</el-tag
        >
        <!--:type="tag.type"-->
      </div>

      <!--内容表格区结束-->
      <div class="cent-bott-butt">
        <el-button type="primary" @click="submitshowdata">保存</el-button>
        <el-button @click="canceladd">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClassAssignment",
  data() {
    return {
      token: this.$store.state.token,
      schoolOrgList: [],
      tags: [],
      agradeindex: 0,
      aclassindex: 0,
      gradeId: "",
      classId: "",
      shcoolId: "",
      userName: "",
      userId: ""
    };
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.shcoolId = userinfo.SSZZId;
    this.userName = userinfo.DLM;
    this.userId = userinfo.Id;

    this.tags = this.$route.params.studentList;

    // 初始化年级
    this.$api.post(
      "v1/accountmanage/GetSchoolOrg",
      { SchoolId: this.shcoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          this.schoolOrgList = r.Target;
        }
      }
    );
  },
  methods: {
    // 学生删除
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },
    selectGrade(data) {
      this.gradeId = data;
    },
    selectclass: function(data) {
      this.classId = data.Id;
    },
    // 取消
    canceladd: function() {
      this.$router.push({ path: "StudentList" });
    },
    // 保存
    submitshowdata: function() {
      if (this.classId === "") {
        this.$message({
          type: "error",
          message: "班级不能为空",
          showClose: true
        });
        return;
      }

      var students = "";
      for (var i = 0; i < this.tags.length; i++) {
        students += this.tags[i].Id + ",";
      }
      if (students === "") {
        this.$message({
          type: "error",
          message: "学生不能为空",
          showClose: true
        });
        return;
      }

      this.$api.post(
        "v1/student/ConfirmStudentGroupClass",
        {
          StudentIds: students,
          ClassId: this.classId,
          GradeId: this.gradeId,
          SchoolId: this.shcoolId,
          UserName: this.userName,
          UserId: this.userId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: "success",
              message: r.Message,
              duration: 3000
            });

            this.$router.push({ path: "StudentList" });
          }
        }
      );
    }
  }
};
</script>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
