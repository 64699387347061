var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{staticClass:"jf-form",attrs:{"title":_vm.onlyMember ? '查看成员' : '管理成员',"top":_vm.onlyMember ? '8%' : '1%',"visible":_vm.visible,"width":"83%","center":"","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"cont-whole-box"},[_c('div',{staticStyle:{"padding":"30px 30px 10px 30px"}},[_c('div',{staticClass:"stud-box"},[_c('div',{staticClass:"sm-conten-tit"},[_vm._v(" 已有成员 "),_c('span',{staticClass:"sm-tit-span"},[_vm._v("（共计"+_vm._s(_vm.pendingCancelMembers.length)+"人）")])]),_c('ul',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingCancelMembers),expression:"loadingCancelMembers"}],staticClass:"stud-box-ul"},[(!_vm.onlyMember)?_c('div',{staticClass:"operate-box paddno",staticStyle:{"margin":"10px 0px"}},[_c('div',{staticClass:"table-er-butt",on:{"click":_vm.calcelMemberAll}},[_c('img',{staticClass:"table-er-buttimg",attrs:{"src":require("../../../style/images/icon_selectall.png")}}),_c('span',{staticClass:"table-er-buttspan"},[_vm._v("全选")])]),_c('div',{staticClass:"table-er-butt float-rig",on:{"click":_vm.submitCancelMember}},[_c('span',{staticClass:"table-er-buttspan"},[_vm._v("确认删除")])])]):_vm._e(),_c('div',{staticClass:"stud-ove-box"},_vm._l((_vm.pendingCancelMembers),function(item){return _c('li',{key:item.Id,class:[
                  'stud-box-ulli',
                  {
                    'stud-ul-delesty': !_vm.onlyMember
                  },
                  {
                    'stud-ul-delesty-sty':
                      _vm.cancelMembers.findIndex(
                        c => c.MemberId === item.MemberId
                      ) !== -1
                  }
                ],on:{"click":function($event){return _vm.dropMember(item)}}},[_c('img',{staticClass:"stud-box-ulli-img",attrs:{"src":item.Photo
                      ? item.Photo
                      : item.Gender === 2
                      ? _vm.$store.state.defaultStudentGirl
                      : _vm.$store.state.defaultStudentBoy}}),_c('div',{staticClass:"stud-name"},[_vm._v(_vm._s(item.MemberName))])])}),0)])]),(!_vm.onlyMember || _vm.curriculaData.KZSZ === 2)?_c('div',{staticClass:"stud-box"},[_c('div',{staticStyle:{"margin-top":"40px","border-bottom":"1px solid #e5e5e5"}},[_c('div',{staticClass:"sm-conten-tit"},[_vm._v(" "+_vm._s(_vm.curriculaData.KZSZ === 2 ? "选择班级" : "从下面的学生中进行添加")+" ")])]),_c('div',{staticClass:"multi-opt-box margno-t borderno-l"},[_c('div',{staticClass:"multi-opt"},[_c('div',{staticClass:"multi-opt-left"},[_vm._v("年级")]),_c('div',{staticClass:"multi-opt-rig"},_vm._l((_vm.currentOrgList),function(item){return _c('div',{key:item.Id,class:[
                    { 'b-word-widt': item.Name.length <= 2 },
                    {
                      'c-word-widt':
                        item.Name.length > 2 && item.Name.length <= 3
                    },
                    {
                      'ef-word-widt':
                        item.Name.length > 3 && item.Name.length <= 5
                    },
                    { 'max-word-widt': item.Name.length > 5 }
                  ],on:{"click":function($event){return _vm.clickGradeHandle(item.Id)}}},[_c('a',{class:[
                      { 'opt-rigsty whole-back': item.Id === _vm.gradeId },
                      'whole-back-hover b-word-widta'
                    ]},[_vm._v(_vm._s(item.Name))])])}),0)]),(_vm.gradeId)?_c('div',{staticClass:"multi-opt"},[_c('div',{staticClass:"multi-opt-left"},[_vm._v("班级")]),_c('div',{staticClass:"multi-opt-rig"},_vm._l((_vm.currentOrgList.find(c => c.Id === _vm.gradeId)
                    ? _vm.currentOrgList.find(c => c.Id === _vm.gradeId).ChidOrgList
                    : []),function(item){return _c('div',{key:item.Id,class:[
                    { 'b-word-widt': item.Name.length <= 2 },
                    {
                      'c-word-widt':
                        item.Name.length > 2 && item.Name.length <= 3
                    },
                    {
                      'ef-word-widt':
                        item.Name.length > 3 && item.Name.length <= 5
                    },
                    { 'max-word-widt': item.Name.length > 5 }
                  ],on:{"click":function($event){return _vm.clickClassHandle(item.Id)}}},[_c('a',{class:[
                      { 'opt-rigsty whole-back': item.Id === _vm.classId },
                      'whole-back-hover b-word-widta'
                    ]},[_vm._v(_vm._s(item.Name))])])}),0)]):_vm._e()]),(!_vm.onlyMember)?_c('ul',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingAddMembers),expression:"loadingAddMembers"}],staticClass:"stud-box-ul",staticStyle:{"line-height":"12px"}},[_c('div',{staticClass:"operate-box paddno",staticStyle:{"margin":"10px 0px"}},[_c('div',{staticClass:"table-er-butt",on:{"click":_vm.addMemberAll}},[_c('img',{staticClass:"table-er-buttimg",attrs:{"src":require("../../../style/images/icon_selectall.png")}}),_c('span',{staticClass:"table-er-buttspan"},[_vm._v("全选")])]),_c('div',{staticClass:"table-er-butt float-rig",on:{"click":_vm.submitAddMember}},[_c('span',{staticClass:"table-er-buttspan"},[_vm._v("确认添加")])])]),_vm._l((_vm.pendingAddMembers),function(item){return _c('el-tooltip',{key:item.Id,attrs:{"disabled":item.SigUpState === 1,"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s([ { key: 2, label: "可报名活动的类别已满" }, { key: 3, label: "学生可报名总数已满" }, { key: 4, label: "该活动可报名人数已满" }, { key: 5, label: "该年级可报名人数已满" }, { key: 11, label: "该活动已结束报名" }, { key: 12, label: "已报名了该活动" } ] .filter(c => c.key === item.SigUpState) .map(c => c.label) .join())+" ")]),_c('li',{class:[
                  'stud-box-ulli',
                  {
                    'stud-ul-sty':
                      _vm.addMembers.findIndex(
                        c => c.MemberId === item.MemberId
                      ) !== -1
                  },
                  {
                    'jf-disable': item.SigUpState !== 1
                  }
                ],on:{"click":function($event){return _vm.addMember(
                    item.SigUpState,
                    item.MemberId,
                    item.StudentCode,
                    item.GradeId
                  )}}},[_c('img',{staticClass:"stud-box-ulli-img",attrs:{"src":item.Photo
                      ? item.Photo
                      : item.Gender === 2
                      ? _vm.$store.state.defaultStudentGirl
                      : _vm.$store.state.defaultStudentBoy}}),_c('div',{staticClass:"stud-name"},[_vm._v(_vm._s(item.MemberName))])])])})],2):_vm._e()]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }