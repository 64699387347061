<template>
  <div>
    <div class="cz-mainbox" style="padding-top: 15px;">
      <!-- 显示名称 -->
      <div
        class="cz-cont-box"
        style="display:flex;justify-content: space-between;"
      >
        <div>
          <div class="disilb-box-ll">显示名称：</div>
          <el-input
            style="width: 160px; vertical-align: middle; margin-right: 20px;"
            size="medium"
            v-model="setData.XSMC"
            placeholder="请输入名称"
          ></el-input>
        </div>
        <div style="display:flex;align-items:center;font-size:14px">
          <el-switch
            v-model="parentReport"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
          <span style="padding-left:8px">
            开启本学期家长端成长报告
          </span>
        </div>
      </div>

      <!-- 图形样式 -->
      <div
        class="cz-cont-box"
        style="margin-top: 30px;display:flex;justify-content:space-between"
      >
        <div>
          <div class="disilb-box-ll">图形样式：</div>
          <el-select
            size="medium"
            v-model="setData.TXYS"
            placeholder="请选择"
            style="width: 160px;"
          >
            <el-option
              v-for="item in txys"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </div>
        <el-button type="primary" size="mini" @click="showReviews = true"
          >评语设置</el-button
        >
      </div>

      <!-- 总图显示范围 -->
      <div class="cz-cont-name">• &nbsp;总图显示范围</div>
      <div class="cz-cont-box">
        <el-checkbox-group @change="checkedXZFW" v-model="xzfw">
          <el-checkbox
            v-for="item in xzfwArr"
            :disabled="item.key === 1"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-checkbox
          >
        </el-checkbox-group>
      </div>

      <!-- 二级页面设置 -->
      <div class="second-level-set">
        <div class="left">
          <div class="label">二级页面设置：</div>
          <el-select v-model="secondLevel" placeholder="请选择">
            <el-option
              v-for="item in secondLevelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="right">
          <div class="label">维度学期总评：</div>
          <el-select v-model="dimensionTotal" placeholder="请选择">
            <el-option
              v-for="item in dimensionTotalOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <!--二级维度展示方式  -->
      <div class="second-dimension-set">
        <div class="label">二级维度展示方式：</div>
      </div>

      <!--  维度详情----------------- -->
      <div class="dimension-item-block">
        <div
          class="dimension-item"
          v-for="item in dimensionList"
          :key="item.name"
        >
          <!-- 维度名称 -->
          <div class="label" style="width: 76px;">{{ item.name }}</div>
          <!-- 维度层级 -->
          <el-select
            size="small"
            style="width:150px;margin-right:10px"
            class="dimension-level"
            v-model="item.level"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dimensionLevel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- 维度别名 -->
          <div class="alias">
            <el-checkbox v-model="item.aliasEnable"></el-checkbox>
            <span>启用别名</span>
            <el-input
              :disabled="!item.aliasEnable"
              style="width:130px;margin-left:5px"
              size="small"
              v-model="item.alias"
              placeholder="请输入别名"
            ></el-input>
          </div>
          <!-- 维度总评展示方式 -->
          <div class="dimension-show-method">
            <div class="label">维度学期总评</div>
            <el-select
              style="width:90px;margin-right:10px"
              size="small"
              v-model="item.showType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dimensionShowType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 维度分数转等级 -->
            <el-select
              size="small"
              style="width:150px"
              v-show="item.showType"
              v-model="item.score2Level"
              placeholder="请选择"
            >
              <el-option
                v-for="item in score2Level"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-button class="set-btn" size="mini" type="primary">设置</el-button>
        </div>
      </div>
      <!-- 子页展示方式 -->
      <!-- <div class="cz-cont-box" style="margin-top: 30px;">
        <div class="disilb-box-ll">子页一级维度总分展示方式：</div>
        <el-select
          size="medium"
          v-model="setData.YJWDZSFS"
          placeholder="请选择"
          style="width: 160px;"
        >
          <el-option
            v-for="item in [
              { key: 1, value: '分数' },
              { key: 2, value: '等级' },
              { key: 0, value: '不展示' }
            ]"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
        <el-select
          v-if="setData.YJWDZSFS === 2"
          size="medium"
          v-model="setData.YJWDZSFSGLDJ"
          placeholder="请选择"
          style="width: 160px;margin-left:20px"
        >
          <el-option
            v-for="item in basicInfo.LevelDataList.filter(
              c => c.LevelType === 2
            )"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
      </div> -->

      <!-- 二级页面设置 -->
      <!-- <div class="cz-cont-name">• &nbsp;二级页面展示</div>
      <div class="cz-cont-box">
        <el-radio-group v-model="setData.SFZSEJYM">
          <el-radio :label="1">以活动组成的环状图</el-radio>
          <el-radio :label="2">以活动组成的柱状图</el-radio>
          <el-radio :label="3">不展示</el-radio>
        </el-radio-group>
      </div> -->

      <!-- 学业成长与维度 -->
      <!-- <div class="cz-cont-name">• &nbsp;学业成长与维度的挂接</div>
      <div class="cz-cont-box">
        <div class="disilb-box-ll">课堂评价</div>
        <div class="disilb-box-ll">+</div>
        <div class="disilb-box-ll">成绩管理：</div>
        <el-select
          size="medium"
          v-model="setData.KSLX"
          placeholder="请选择"
          style="width: 180px;"
        >
          <el-option
            v-for="item in basicInfo.KSBList"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
        <el-select
          v-if="setData.SZLB === 1"
          size="medium"
          v-model="setData.GLId"
          placeholder="请选择"
          style="width: 180px; padding-left: 10px;"
        >
          <el-option
            v-for="item in basicInfo.Growthsets"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <el-select
          v-if="setData.SZLB === 2"
          size="medium"
          v-model="setData.GLId"
          placeholder="请选择"
          style="width: 180px; padding-left: 10px;"
        >
          <el-option
            v-for="item in basicInfo.DimensionTree"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div> -->

      <!-- 设置占比 -->
      <!-- <div class="cz-cont-name">• &nbsp;设置各占比</div>
      <div class="cz-cont-box">
        <div class="tab-heng-box" style="margin-top: 10px;">
          <div class="table-er-box">
            <div class="table-er-butt" @click="editExamScoreProportion('')">
              <img class="table-er-buttimg" :src="btnImg" />
              <span class="table-er-buttspan">新增</span>
            </div>
          </div>
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <td>学科</td>
                <td>年级</td>
                <td>课堂表现</td>
                <td>学业成绩</td>
                <td>操作</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in setData.ExamScoreProportionList.slice(
                  (pagerInfo1.pageIndex - 1) * pagerInfo1.pageSize,
                  (pagerInfo1.pageIndex - 1) * pagerInfo1.pageSize +
                    pagerInfo1.pageSize
                )"
                :key="index"
              >
                <td>
                  {{
                    basicInfo.CourseList.find(c => c.Id === item.CourseId)
                      ? basicInfo.CourseList.find(c => c.Id === item.CourseId)
                          .Name
                      : ""
                  }}
                </td>
                <td>
                  {{
                    gradeNumStrArray
                      .filter((c, i) => item.GradeData.includes(i))
                      .join(",")
                  }}
                </td>
                <td>
                  {{
                    !isNaN(item.Proportion)
                      ? parseFloat(((1 - item.Proportion) * 100).toFixed(2)) +
                        "%"
                      : ""
                  }}
                </td>
                <td>
                  {{
                    !isNaN(item.Proportion)
                      ? parseFloat((item.Proportion * 100).toFixed(2)) + "%"
                      : ""
                  }}
                </td>
                <td>
                  <div class="tab-wz-butt">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="editExamScoreProportion(item)"
                      >编辑</el-link
                    >
                    <el-link
                      style="margin-left: 10px;"
                      class="c-red"
                      :underline="false"
                      @click="delExamScoreProportion(item)"
                      >删除</el-link
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="pagin-box">
            <div class="pagin-wz">
              <Pager :pagerInfo="pagerInfo1" @changeIndex="changeIndex1" />
            </div>
          </div>
     
        </div>
      </div> -->

      <!-- 课堂表现 -->
      <!-- <div class="cz-cont-name">• &nbsp;课堂表现和成绩分析的显示范围</div>
      <div class="cz-cont-box">
        <el-checkbox-group @change="checkedXSFW" v-model="xsfw">
          <el-checkbox
            v-for="item in xzfwArr"
            :disabled="item.key === 1"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-checkbox
          >
        </el-checkbox-group>
      </div> -->

      <div v-if="setData.SZLB === 1" class="cz-cont-name">• &nbsp;体系</div>
      <div v-if="setData.SZLB === 1" class="cz-cont-box">
        <div class="tab-heng-box" style="margin-top: 10px;">
          <div class="table-er-box">
            <div class="table-er-butt" @click="editSystemDisplaySet('')">
              <img class="table-er-buttimg" :src="btnImg" />
              <span class="table-er-buttspan">新增</span>
            </div>
          </div>
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <td>体系名称</td>
                <td>活动名称</td>
                <td>展示方式</td>
                <td>选择等级方式</td>
                <td>操作</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in setData.SystemDisplaySetList.slice(
                  (pagerInfo2.pageIndex - 1) * pagerInfo2.pageSize,
                  (pagerInfo2.pageIndex - 1) * pagerInfo2.pageSize +
                    pagerInfo2.pageSize
                )"
                :key="index"
              >
                <td>
                  {{
                    basicInfo.Growthsets.find(c => c.Id === item.SystemId)
                      ? basicInfo.Growthsets.find(c => c.Id === item.SystemId)
                          .Name
                      : ""
                  }}
                </td>
                <td>
                  {{
                    basicInfo.Activities.filter(c =>
                      item.SystemDisplayDetailSetList.map(
                        o => o.ActivityId
                      ).includes(c.Id)
                    )
                      .map(c => c.Name)
                      .join(",")
                  }}
                </td>
                <td>
                  {{
                    item.DisplayType === 1
                      ? "分数"
                      : item.DisplayType === 2
                      ? "等级"
                      : "均无"
                  }}
                </td>
                <td>
                  {{
                    basicInfo.LevelDataList.filter(c =>
                      item.SystemDisplayDetailSetList.map(
                        o => o.LevelId
                      ).includes(c.Id)
                    )
                      .map(c => c.Name)
                      .join("")
                  }}
                </td>
                <td>
                  <div class="tab-wz-butt">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="editSystemDisplaySet(item)"
                      >编辑</el-link
                    >
                    <el-link
                      style="margin-left: 10px;"
                      class="c-red"
                      :underline="false"
                      @click="delSystemDisplaySet(item)"
                      >删除</el-link
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!--页码开始-->
          <div class="pagin-box">
            <div class="pagin-wz">
              <Pager :pagerInfo="pagerInfo2" @changeIndex="changeIndex2" />
            </div>
          </div>
          <!--页码结束-->
        </div>
      </div>

      <!-- <div class="cz-cont-name">• &nbsp;活动</div>
      <div class="cz-cont-box">
        <div class="tab-heng-box" style="margin-top: 10px;">
          <div class="table-er-box">
            <div
              class="table-er-butt"
              style="width: 150px;"
              @click="editAssociationSet('', 1)"
            >
              <img class="table-er-buttimg" :src="btnImg" />
              <span class="table-er-buttspan">新增系统内部活动</span>
            </div>
            <div
              class="table-er-butt"
              style="width: 150px;"
              @click="editAssociationSet('', 2)"
            >
              <img class="table-er-buttimg" :src="btnImg" />
              <span class="table-er-buttspan">新增外联活动</span>
            </div>
          </div>
          <div class="prompt-title-box">
            备注：这里设置的是在自动评语下面展示的活动；从评语下面开始排。
          </div>
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <td>排序</td>
                <td>显示名称</td>
                <td>对应的活动</td>
                <td>活动类型</td>
                <td>操作</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in setData.AssociationSetList.slice(
                  (pagerInfo3.pageIndex - 1) * pagerInfo3.pageSize,
                  (pagerInfo3.pageIndex - 1) * pagerInfo3.pageSize +
                    pagerInfo3.pageSize
                )"
                :key="index"
              >
                <td>{{ item.OrderNum }}</td>
                <td>{{ item.Name }}</td>
                <td>
                  {{
                    basicInfo.GrowthReportAssociationTypeList.filter(
                      c => c.value == item.ActivityType
                    )
                      .map(
                        c =>
                          c.label +
                          (c.children
                            ? "/" +
                              c.children
                                .filter(a => a.value === item.GLHDId)
                                .map(a => a.label)
                                .join("")
                            : "")
                      )
                      .join(",")
                  }}
                </td>
                <td>
                  {{
                    associationSetActivityTypeArray
                      .filter(c => c.key == item.Type)
                      .map(c => c.value)
                      .join(",")
                  }}
                </td>
                <td>
                  <div class="tab-wz-butt">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="editAssociationSet(item)"
                      >编辑</el-link
                    >
                    <el-link
                      style="margin-left: 10px;"
                      class="c-red"
                      :underline="false"
                      @click="delAssociationSet(item)"
                      >删除</el-link
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="pagin-box">
            <div class="pagin-wz">
              <Pager :pagerInfo="pagerInfo3" @changeIndex="changeIndex3" />
            </div>
          </div>

        </div>
      </div> -->
    </div>
    <div class="cent-bott-butt">
      <a class="cent-bott-butta" style="width: 100px;" @click="toSet1"
        >返回通用设置</a
      >
      <a class="cent-bott-butta" style="width: 100px;" @click="save">完成</a>
    </div>

    <el-dialog
      title="设置占比"
      :visible.sync="examScoreProportionVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="cancelItem"
      center
    >
      <div class="tab-shu-box over-css" style="height: 250px;">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl">选择学科</td>
              <td>
                <el-select
                  size="medium"
                  v-model="examScoreProportionForm.CourseId"
                  placeholder="请选择"
                  @change="selectCourse"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in basicInfo.CourseList"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">考试的成绩占比</td>
              <td>
                <el-input
                  style="width: 93%;"
                  v-model="examScoreProportionForm.Proportion"
                  placeholder="请输入"
                  size="small"
                ></el-input
                >%
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">选择年级</td>
              <td>
                <el-checkbox
                  :indeterminate="isGradeIndeterminate"
                  v-model="gradeCheckAll"
                  @change="checkedAllGrade"
                  >全部</el-checkbox
                >
                <el-checkbox-group
                  v-model="examScoreProportionForm.GradeData"
                  @change="checkedGrade"
                >
                  <el-checkbox
                    v-for="item in basicInfo.GradeDataList.filter(
                      c =>
                        !examScoreProportionForm.CourseId ||
                        (basicInfo.CourseList.find(
                          item => item.Id === examScoreProportionForm.CourseId
                        )
                          ? basicInfo.CourseList.find(
                              item =>
                                item.Id === examScoreProportionForm.CourseId
                            ).Grades
                          : []
                        ).includes(c.GradeNum)
                    )"
                    :key="item.GradeNum"
                    :label="item.GradeNum"
                    >{{ gradeNumStrArray[item.GradeNum] }}</el-checkbox
                  >
                </el-checkbox-group>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">综合表现的等级设置</td>
              <td>
                <el-select
                  size="medium"
                  v-model="examScoreProportionForm.LevelId"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in basicInfo.LevelDataList"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="cent-bott-butt">
        <a class="cent-bott-butta" @click="cancelItem">取&nbsp;消</a>
        <a class="cent-bott-butta" @click="saveExamScoreProportion"
          >确&nbsp;认</a
        >
      </div>
    </el-dialog>

    <el-dialog
      title="配置"
      :visible.sync="systemDisplaySetVisible"
      :close-on-click-modal="false"
      width="45%"
      :before-close="cancelItem"
      center
    >
      <div class="tab-shu-box over-css" style="height: 280px;">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl">选择体系</td>
              <td>
                <el-select
                  size="medium"
                  v-model="systemDisplaySetForm.SystemId"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in basicInfo.Growthsets"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">体系展示方式</td>
              <td>
                <el-select
                  size="medium"
                  v-model="systemDisplaySetForm.DisplayType"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in displayTypeArray"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  ></el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td
                class="tab-shu-titl"
                style="vertical-align: top; padding-top: 70px;"
              >
                选择活动
              </td>
              <td>
                <div class="tab-heng-box margno">
                  <el-checkbox-group v-model="systemDisplaySetList">
                    <table cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <td>是否选中</td>
                          <td>活动名称</td>
                          <td>展示方式</td>
                          <td>选择等级</td>
                          <td>最高分</td>
                          <td>取值比（%）</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in systemDisplaySetActivities"
                          :key="index"
                        >
                          <td>
                            <el-checkbox :label="item.ActivityId">{{
                              ""
                            }}</el-checkbox>
                          </td>
                          <td>{{ item.ActivityName }}</td>
                          <td>
                            <el-select
                              v-model="item.DisplayType"
                              placeholder="请选择"
                            >
                              <el-option
                                v-for="item in displayTypeArray.filter(
                                  c => c.key !== 3
                                )"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key"
                              ></el-option>
                            </el-select>
                          </td>
                          <td>
                            <el-select
                              v-model="item.LevelId"
                              placeholder="请选择"
                            >
                              <el-option
                                v-for="item in basicInfo.LevelDataList"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                              ></el-option>
                            </el-select>
                          </td>
                          <td>
                            <el-input
                              v-model="item.HighestScore"
                              placeholder="最高分"
                            ></el-input>
                          </td>
                          <td>
                            <el-input
                              v-model="item.Proportion"
                              placeholder="取值比"
                            ></el-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </el-checkbox-group>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="cent-bott-butt">
        <a class="cent-bott-butta" @click="cancelItem">取&nbsp;消</a>
        <a class="cent-bott-butta" @click="saveSystemDisplaySet">确&nbsp;认</a>
      </div>
    </el-dialog>

    <el-dialog
      :title="associationSetDialogTitle"
      :visible.sync="associationSetVisible"
      :close-on-click-modal="false"
      :width="associationSetDialogWidth"
      :before-close="cancelItem"
      center
    >
      <div class="tab-shu-box over-css" style="height: 280px;">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl">显示名称</td>
              <td>
                <el-input
                  v-model="associationSetForm.Name"
                  placeholder="请输入"
                ></el-input>
              </td>
            </tr>
            <tr v-if="associationSetForm.Type === 1">
              <td class="tab-shu-titl">对应的活动</td>
              <td>
                <el-cascader
                  style="width: 100%;"
                  v-model="associationSetFormTempValue"
                  :options="basicInfo.GrowthReportAssociationTypeList"
                  @change="selectAssociation"
                  :props="{ expandTrigger: 'hover' }"
                  ref="cascaderRef"
                ></el-cascader>
                <!-- <el-select
                  v-model="associationSetForm.ActivityType"
                  style="width: 100%;"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in basicInfo.GrowthReportAssociationTypeList"
                    :key="item.Type"
                    :label="item.Name"
                    :value="item.Type"
                  ></el-option>
                </el-select> -->
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">排序号</td>
              <td>
                <el-input
                  type="number"
                  v-model.number="associationSetForm.OrderNum"
                  placeholder="请输入"
                ></el-input>
              </td>
            </tr>
            <tr v-if="associationSetForm.Type === 1">
              <td
                class="tab-shu-titl"
                :rowspan="
                  associationSetForm.DisplayType &&
                  associationSetForm.DisplayType !== 3
                    ? 2
                    : 1
                "
              >
                展示方式
              </td>
              <td>
                <el-select
                  v-model="associationSetForm.DisplayType"
                  style="width: 100%;"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in displayTypeArray"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  ></el-option>
                </el-select>
              </td>
            </tr>
            <tr
              v-if="
                associationSetForm.Type === 1 &&
                  associationSetForm.DisplayType &&
                  associationSetForm.DisplayType === 2
              "
            >
              <!-- <td class="tab-shu-titl"></td> -->
              <td>
                <el-select
                  v-model="associationSetForm.LevelId"
                  style="width: 100%;"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in basicInfo.LevelDataList.filter(
                      c => c.LevelType == associationSetForm.DisplayType
                    )"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </td>
            </tr>
            <tr v-if="associationSetForm.Type === 2">
              <td class="tab-shu-titl">外联地址</td>
              <td>
                <el-input
                  v-model="associationSetForm.AssociationAddress"
                  placeholder="请输入"
                ></el-input>
              </td>
            </tr>
            <tr v-if="associationSetForm.Type === 2">
              <td class="tab-shu-titl">外联关联</td>
              <td>
                <el-radio-group v-model="associationSetForm.AssociationType">
                  <el-radio :label="1">
                    使用入学年份+班级号+姓名&nbsp;
                    <span class="c-g-9">(例：/201703/李明入）</span>
                  </el-radio>
                  <el-radio :label="2" style="margin-top: 5px;">
                    使用学生身份证号&nbsp;
                    <span class="c-g-9">(例：/510214198006051334)</span>
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="cent-bott-butt">
        <a class="cent-bott-butta" @click="cancelItem">取&nbsp;消</a>
        <a class="cent-bott-butta" @click="saveAssociationSet">确&nbsp;认</a>
      </div>
    </el-dialog>

    <!-- 评语设置 -->
    <Reviews :show.sync="showReviews" />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Reviews from "./Reviews.vue";
export default {
  components: {
    Pager,
    Reviews
  },
  data() {
    return {
      associationSetFormTempValue: [],
      pagerInfo1: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      gradeCheckAll: false,
      isGradeIndeterminate: false,
      pagerInfo2: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      pagerInfo3: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      gradeNumStrArray: [
        "未知",
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "初一",
        "初二",
        "初三"
      ],
      btnImg: require("@/style/images/icon_ejdaa.png"),
      setData: {},
      token: "",
      user: {},
      schoolId: "",
      txys: [
        {
          key: 1,
          value: "雷达图"
        },
        {
          key: 2,
          value: "环状图"
        }
      ],
      xzfwArr: [
        {
          key: 1,
          value: "学生"
        },
        {
          key: 2,
          value: "班级平均"
        },
        {
          key: 4,
          value: "年级平均"
        }
      ],
      displayTypeArray: [
        {
          key: 1,
          value: "分数"
        },
        {
          key: 2,
          value: "等级"
        },
        {
          key: 3,
          value: "均无"
        }
      ],
      xzfw: [],
      xsfw: [],
      examScoreProportionVisible: false,
      examScoreProportionSource: {},
      examScoreProportionForm: {},
      systemDisplaySetVisible: false,
      systemDisplaySetSource: {},
      systemDisplaySetForm: {},
      systemDisplaySetList: [],
      systemDisplaySetActivities: [],
      associationSetVisible: false,
      associationSetSource: {},
      associationSetForm: {},
      associationSetDialogTitle: "",
      associationSetDialogWidth: "",
      associationSetActivityTypeArray: [
        {
          key: 1,
          value: "内部活动"
        },
        {
          key: 2,
          value: "外联活动"
        }
      ],
      // -------------
      parentReport: false, //开启家长端成长报告
      showReviews: false, //显示评语弹窗
      secondLevel: "", //二级页面设置
      secondLevelOptions: [],
      dimensionTotal: "", //维度学期总评
      dimensionTotalOptions: [],
      //维度数组
      dimensionList: [
        {
          name: "品德发展",
          level: 1,
          aliasEnable: false,
          alias: "",
          showType: "",
          score2Level: ""
        },
        {
          name: "学业发展",
          level: "",
          aliasEnable: false,
          alias: "",
          showType: "",
          score2Level: ""
        },
        {
          name: "身心发展",
          level: "",
          aliasEnable: false,
          alias: "",
          showType: "",
          score2Level: ""
        }
      ],
      // 维度等级下拉
      dimensionLevel: [
        {
          label: "一级维度1",
          value: 1
        },
        {
          label: "一级维度2",
          value: 2
        },
        {
          label: "一级维度3",
          value: 3
        }
      ],
      // 维度总评下拉
      dimensionShowType: [
        {
          label: "等级",
          value: 1
        },
        {
          label: "不展示",
          value: 0
        }
      ],
      // 维度分数转等级下拉
      score2Level: [
        {
          label: "分数转等级1",
          value: 1
        },
        {
          label: "分数转等级2",
          value: 2
        },
        {
          label: "分数转等级3",
          value: 3
        }
      ]
    };
  },
  props: {
    setInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    basicInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    SZLB: {
      type: Number,
      default: 1
    }
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.init();
  },
  methods: {
    checkedXSFW(value) {
      this.setData.XSFW = 0;
      for (const i of value) {
        this.setData.XSFW += i;
      }
    },
    checkedXZFW(value) {
      this.setData.XZFW = 0;
      for (const i of value) {
        this.setData.XZFW += i;
      }
    },
    init() {
      this.setData = JSON.parse(JSON.stringify(this.setInfo));
      if (this.setInfo.SZLB !== this.SZLB) {
        this.setData.XSMC = "";
        this.setData.TXYS = 0;
        this.setData.XZFW = 1;
        this.setData.SFZSEJYM = 3;
        this.setData.XSFW = 1;
        this.setData.GLId = "";
        this.setData.KSLX = undefined;
        this.setData.SystemDisplaySetList = [];
        this.setData.ExamScoreProportionList = [];
        this.setData.AssociationSetList = [];
      }
      this.pagerInfo1.totalCount = this.setData.ExamScoreProportionList.length;
      this.pagerInfo2.totalCount = this.setData.SystemDisplaySetList.length;
      this.pagerInfo3.totalCount = this.setData.AssociationSetList.length;
      if (this.setData.TXYS === 0) {
        this.setData.TXYS = undefined;
      }
      const tmpXzfwArr = JSON.parse(JSON.stringify(this.xzfwArr));
      tmpXzfwArr.sort(function(a, b) {
        return b.key - a.key;
      });
      let XZFW = this.setData.XZFW;
      let XSFW = this.setData.XSFW;
      for (const item of tmpXzfwArr) {
        if (XZFW - item.key >= 0) {
          XZFW -= item.key;
          this.xzfw.push(item.key);
        }
        if (XSFW - item.key >= 0) {
          XSFW -= item.key;
          this.xsfw.push(item.key);
        }
      }
      this.setData.KSLX =
        this.setData.KSLX === 0 ? undefined : this.setData.KSLX;
    },
    toSet1() {
      // this.$set(this,"setInfo",this.setData)
      this.$emit("toGeneral1", this.setData, 0);
      scrollTo(0, 0);
    },
    changeIndex1(val) {
      this.pagerInfo1.pageIndex = val;
    },
    changeIndex2(val) {
      this.pagerInfo2.pageIndex = val;
    },
    changeIndex3(val) {
      this.pagerInfo3.pageIndex = val;
    },
    cancelItem() {
      this.examScoreProportionVisible = false;
      this.systemDisplaySetVisible = false;
      this.associationSetVisible = false;
    },
    editExamScoreProportion(data) {
      this.$set(this, "examScoreProportionSource", {});
      this.$set(this, "examScoreProportionForm", {});
      this.$set(this.examScoreProportionSource, "GradeData", []);
      this.$set(this.examScoreProportionForm, "GradeData", []);
      this.$set(this.examScoreProportionSource, "GradeType", 2);
      this.$set(this.examScoreProportionForm, "GradeType", 2);
      if (data) {
        this.examScoreProportionSource = data;
        this.$set(
          this,
          "examScoreProportionForm",
          JSON.parse(JSON.stringify(data))
        );
        this.examScoreProportionForm.Proportion =
          Math.floor(this.examScoreProportionForm.Proportion * 10000) / 100;
      }
      this.isGradeIndeterminate =
        this.examScoreProportionForm.GradeData.length > 0 &&
        this.examScoreProportionForm.GradeData.length <
          this.basicInfo.GradeDataList.length
          ? true
          : false;
      this.gradeCheckAll =
        this.examScoreProportionForm.GradeData.length >=
        this.basicInfo.GradeDataList.length
          ? true
          : false;
      this.examScoreProportionVisible = true;
    },
    saveExamScoreProportion() {
      this.examScoreProportionForm.Proportion =
        this.examScoreProportionForm.Proportion / 100;
      if (JSON.stringify(this.examScoreProportionSource.GradeData) !== "[]") {
        this.setData.ExamScoreProportionList.splice(
          this.setData.ExamScoreProportionList.findIndex(
            c => c === this.examScoreProportionSource
          ),
          1,
          this.examScoreProportionForm
        );
      } else {
        this.setData.ExamScoreProportionList.unshift(
          this.examScoreProportionForm
        );
        this.pagerInfo1.totalCount = this.setData.ExamScoreProportionList.length;
      }
      this.examScoreProportionVisible = false;
    },
    delExamScoreProportion(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.setData.ExamScoreProportionList.splice(
            this.setData.ExamScoreProportionList.findIndex(c => c === data),
            1
          );
          this.pagerInfo1.totalCount = this.setData.ExamScoreProportionList.length;
          this.examScoreProportionVisible = false;
        })
        .catch(() => {
          this.examScoreProportionVisible = false;
        });
    },
    selectCourse() {
      this.$set(this.examScoreProportionForm, "GradeData", []);
      this.isGradeIndeterminate = false;
      this.gradeCheckAll = false;
    },
    checkedAllGrade() {
      this.examScoreProportionForm.GradeType = this.gradeCheckAll ? 1 : 2;
      let course = this.basicInfo.CourseList.find(
        c => c.Id === this.examScoreProportionForm.CourseId
      );
      let gradeData = [];
      if (course && course.Grades) {
        gradeData = course.Grades.filter(c =>
          this.basicInfo.GradeDataList.map(item => item.GradeNum).includes(c)
        );
      }
      this.$set(
        this.examScoreProportionForm,
        "GradeData",
        this.gradeCheckAll ? gradeData : []
      );
      this.isGradeIndeterminate = false;
    },
    checkedGrade(value) {
      const checkedCount = value.length;
      this.gradeCheckAll =
        checkedCount ===
        this.basicInfo.GradeDataList.map(c => c.GradeNum).length;
      this.examScoreProportionForm.GradeType = this.gradeCheckAll ? 1 : 2;
      this.isGradeIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.basicInfo.GradeDataList.map(c => c.GradeNum).length;
    },
    save() {
      this.setData.SZLB = this.SZLB;
      this.$api.post(
        "/v1/growthreport/SetGeneralGrowthReportSet",
        {
          token: this.token,
          GrwothSetInfo: this.setData,
          SchoolId: this.schoolId,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.toSet1(this.SZLB);
          }
        }
      );
    },
    editSystemDisplaySet(data) {
      this.$set(this, "systemDisplaySetSource", {});
      this.$set(this, "systemDisplaySetForm", {});
      this.systemDisplaySetList = [];
      if (data) {
        this.systemDisplaySetList = data.SystemDisplayDetailSetList.map(
          c => c.ActivityId
        );
        this.systemDisplaySetSource = data;
        this.$set(
          this,
          "systemDisplaySetForm",
          JSON.parse(JSON.stringify(data))
        );
      }
      this.systemDisplaySetActivities = this.basicInfo.Activities.map(item => ({
        ActivityId: item.Id,
        ActivityName: item.Name,
        ActivityType: item.Type,
        DisplayType:
          data && data.SystemDisplayDetailSetList.length > 0
            ? data.SystemDisplayDetailSetList.find(
                c => c.ActivityId === item.Id
              )
              ? data.SystemDisplayDetailSetList.find(
                  c => c.ActivityId === item.Id
                ).DisplayType
              : this.displayTypeArray[0].key
            : this.displayTypeArray[0].key,
        LevelId:
          this.systemDisplaySetSource.SystemDisplayDetailSetList &&
          this.systemDisplaySetSource.SystemDisplayDetailSetList.find(
            c => c.ActivityId === item.Id
          )
            ? this.systemDisplaySetSource.SystemDisplayDetailSetList.find(
                c => c.ActivityId === item.Id
              ).LevelId
            : "",
        HighestScore:
          this.systemDisplaySetSource.SystemDisplayDetailSetList &&
          this.systemDisplaySetSource.SystemDisplayDetailSetList.find(
            c => c.ActivityId === item.Id
          )
            ? this.systemDisplaySetSource.SystemDisplayDetailSetList.find(
                c => c.ActivityId === item.Id
              ).HighestScore
            : "",
        Proportion:
          this.systemDisplaySetSource.SystemDisplayDetailSetList &&
          this.systemDisplaySetSource.SystemDisplayDetailSetList.find(
            c => c.ActivityId === item.Id
          )
            ? parseFloat(
                (
                  this.systemDisplaySetSource.SystemDisplayDetailSetList.find(
                    c => c.ActivityId === item.Id
                  ).Proportion * 100
                ).toFixed(2)
              )
            : ""
      }));

      this.systemDisplaySetVisible = true;
    },
    saveSystemDisplaySet() {
      this.systemDisplaySetForm.SystemDisplayDetailSetList = this.systemDisplaySetActivities
        .filter(c => this.systemDisplaySetList.includes(c.ActivityId))
        .map(item => {
          item.LevelId = item.DisplayType === 1 ? "" : item.LevelId;
          item.HighestScore = Number(item.HighestScore);
          item.Proportion = parseFloat((item.Proportion / 100).toFixed(2));
          // eslint-disable-next-line no-unused-vars
          const { ActivityName, ...obj } = item;
          return obj;
        });
      if (JSON.stringify(this.systemDisplaySetSource) !== "{}") {
        this.setData.SystemDisplaySetList.splice(
          this.setData.SystemDisplaySetList.findIndex(
            c => c === this.systemDisplaySetSource
          ),
          1,
          this.systemDisplaySetForm
        );
      } else {
        this.setData.SystemDisplaySetList.unshift(this.systemDisplaySetForm);
        this.pagerInfo2.totalCount = this.setData.SystemDisplaySetList.length;
      }
      this.systemDisplaySetVisible = false;
    },
    delSystemDisplaySet(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.setData.SystemDisplaySetList.splice(
            this.setData.SystemDisplaySetList.findIndex(c => c === data),
            1
          );
          this.pagerInfo2.totalCount = this.setData.SystemDisplaySetList.length;
          this.systemDisplaySetVisible = false;
        })
        .catch(() => {
          this.systemDisplaySetVisible = false;
        });
    },
    editAssociationSet(data, type) {
      this.$set(this, "associationSetSource", {});
      this.$set(this, "associationSetForm", {});
      let value = JSON.parse(JSON.stringify(this.associationSetFormTempValue));
      this.$set(this, "associationSetFormTempValue", []);
      this.associationSetDialogTitle = "新增";
      if (data) {
        this.associationSetDialogTitle = "修改";
        this.associationSetSource = data;
        this.$set(this, "associationSetForm", JSON.parse(JSON.stringify(data)));
        type = data.Type;
        if (value.length > 0 && data.ActivityType !== value[0]) {
          this.$set(this.$refs.cascaderRef.panel, "checkedValue", []);
          this.$set(this.$refs.cascaderRef.panel, "activePath", []);
          this.$refs.cascaderRef.panel.syncActivePath();
        }

        this.associationSetFormTempValue.push(
          this.associationSetForm.ActivityType
        );
        if (this.associationSetForm.GLHDId) {
          this.associationSetFormTempValue.push(this.associationSetForm.GLHDId);
        }
        this.$set(
          this,
          "associationSetFormTempValue",
          JSON.parse(JSON.stringify(this.associationSetFormTempValue))
        );
      }
      this.associationSetForm.Type = type;
      this.associationSetDialogTitle +=
        type === 1 ? "系统内部活动" : "外联活动";
      this.associationSetDialogWidth = type === 1 ? "30%" : "35%";
      this.associationSetVisible = true;
    },
    saveAssociationSet() {
      if (JSON.stringify(this.associationSetSource) !== "{}") {
        this.setData.AssociationSetList.splice(
          this.setData.AssociationSetList.findIndex(
            c => c === this.associationSetSource
          ),
          1,
          this.associationSetForm
        );
      } else {
        this.setData.AssociationSetList.push(this.associationSetForm);
        this.pagerInfo3.totalCount = this.setData.AssociationSetList.length;
      }
      this.setData.AssociationSetList = this.setData.AssociationSetList.sort(
        (x, y) => {
          if (x.OrderNum < y.OrderNum) {
            return -1;
          } else if (x.OrderNum > y.OrderNum) {
            return 1;
          } else {
            return 0;
          }
        }
      );
      this.associationSetVisible = false;
    },
    delAssociationSet(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.setData.AssociationSetList.splice(
            this.setData.AssociationSetList.findIndex(c => c === data),
            1
          );
          this.pagerInfo3.totalCount = this.setData.AssociationSetList.length;
          this.associationSetVisible = false;
        })
        .catch(() => {
          this.associationSetVisible = false;
        });
    },
    selectAssociation(value) {
      if (value && value.length > 0) {
        this.associationSetForm.ActivityType = value[0];
        if (value[1]) {
          this.associationSetForm.GLHDId = value[1];
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.second-level-set,
.second-dimension-set {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 30px;
  padding: 10px;
  border: 1px solid #f2f2f2;
  font-size: 14px;
  .left,
  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      margin-right: 10px;
    }
  }
}
.dimension-item-block {
  box-sizing: border-box;
  margin-top: 30px;
  padding: 10px;
  border: 1px solid #f2f2f2;
  font-size: 14px;
  .dimension-item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    .label {
      margin-right: 10px;
    }
    .dimension-show-method,
    .alias {
      display: flex;
      align-items: center;
      .el-checkbox {
        margin-right: 5px;
      }
    }
    .alias {
      margin-right: 10px;
    }
    .set-btn {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
