<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <!-- <div class="table-er-butt" @click="handleAdd()">
                <span class="table-er-buttspan">新增</span>
              </div> -->
            </div>
            <el-table :data="bblist" highlight-current-row style="width: 100%;">
              <el-table-column
                align="center"
                label="序号"
                type="index"
                width="50"
              >
              </el-table-column>
              <el-table-column align="center" property="BBMC" label="名称">
              </el-table-column>
              <!-- <el-table-column align="center" label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.SYZT == '1'">
                    启用
                  </span>
                  <span v-else>
                    禁用
                  </span>
                </template>
              </el-table-column> -->
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleDetail(scope.row)"
                    type="text"
                    class="c-guree"
                    >预览</el-button
                  >
                  <!-- <el-button
                    v-if="scope.row.SYZT == 1"
                    @click="handleEnable(scope.row.Id, 2)"
                    style="color: red;"
                    type="text"
                    >禁用</el-button
                  > -->
                  <!-- <el-button
                    v-else
                    @click="handleEnable(scope.row.Id, 1)"
                    style="color: green;"
                    type="text"
                    >启用</el-button
                  > -->
                  <!-- <el-button
                    @click="handleEdit(scope.row.Id)"
                    style="color: blue;"
                    type="text"
                    >编辑</el-button
                  > -->
                  <!-- <el-button
                    @click="handleDelete(scope.row.Id)"
                    style="color: red;"
                    type="text"
                    >删除</el-button
                  > -->
                </template>
              </el-table-column>
            </el-table>
            <div class="pagin-box">
              <div class="pagin-wz">
                <Pager
                  :pagerInfo="pagerInfo"
                  @changeIndex="handleCurrentChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 表单 -->
    <el-dialog
      :title="edittitle"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleEditClose"
      :close-on-click-modal="false"
    >
      <el-form
        class="form"
        label-width="120px"
        style="width: 600px; display: inline-block;"
        :rules="rules"
        :model="zdybbinfo"
        ref="editbbform"
        :inline="true"
      >
        <el-form-item label="名称：" prop="BBMC">
          <el-input
            v-model="zdybbinfo.BBMC"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <div class="RQLX_block">
          <div class="RQLX_name">查询条件：</div>
          <el-checkbox-group v-model="RQLX_checkList" @change="RQLX_change">
            <el-checkbox :label="1">按周 @begindate @enddate</el-checkbox>
            <el-checkbox :label="2">本周 本期 @begindate @enddate</el-checkbox>
            <el-checkbox :label="4">按月 @begindate @enddate</el-checkbox>
            <el-checkbox :label="3"
              >日期段 开始日期 @begindate 结束日期 @enddate</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <el-row style="display:flex">
          <span style="padding-left:30px;padding-right:20px">
            按班级查询
          </span>
          <el-checkbox-group v-model="abjcx2">
            <el-checkbox :label="1">班级@class</el-checkbox>
            <el-checkbox :label="2">年级@grade</el-checkbox>
          </el-checkbox-group>
        </el-row>
        <el-form-item style="padding-left:120px">
          <el-row>
            <el-checkbox v-model="dxqcx"
              >多学期查询 @teamid @ssxn @xqlx</el-checkbox
            >
          </el-row>
          <el-row>
            <el-checkbox v-model="zdybbinfo.XK"
              >按学科查询 @subject</el-checkbox
            >
          </el-row>
          <el-row>
            <el-checkbox v-model="ZDYCX_checked">搜索框</el-checkbox>
            <el-input
              style="width:200px;margin:0 10px"
              size="small"
              v-model="zdybbinfo.ZDYCXTS"
              maxlength="10"
              show-word-limit
              placeholder="10个字以内的提示符"
            ></el-input>
            @input
          </el-row>
          <el-row>
            <el-checkbox v-model="zdybbinfo.DLYH">登录用户ID @user</el-checkbox>
          </el-row>
        </el-form-item>
        <el-form-item class="ZSL" label="展示列：">
          <div class="ZSL-block">
            <div
              class="tag-item"
              v-for="(tag, index) in ltmcarr"
              :key="index"
              @mouseover="tag.showArrow = true"
              @mouseout="tag.showArrow = false"
            >
              <span
                class="arrow"
                v-show="tag.showArrow"
                @click="sortTag(tag, index)"
              >
                <i class="el-icon-caret-left"></i>
              </span>
              <div class="tag-content">
                {{ tag.value }}
              </div>
              <span class="tag-del" @click="handleClose(tag)"
                ><i class="el-icon-circle-close"></i
              ></span>
            </div>
          </div>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
          >
            <el-button slot="append" @click="handleInputConfirm"
              >确定</el-button
            >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            icon="el-icon-plus"
            circle
            type="primary"
          ></el-button>
        </el-form-item>
        <br />
        <el-form-item label="SQL执行脚本：" prop="SQLText">
          <el-input
            style="width:470px;"
            type="textarea"
            :rows="5"
            placeholder="请输入计划内容"
            v-model="zdybbinfo.SQLText"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item>
          <el-button @click="handleEditClose()">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager,
  },
  created() {
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.zdybbinfo.CJR = user.Id;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    handleDetail: function(row) {
      this.$router.push({ name: "reportdata", params: { row } });
    },
    handleClose(tag) {
      this.ltmcarr = this.ltmcarr.filter((item) => item.value !== tag.value);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      if (inputValue) {
        this.ltmcarr.push({
          value: inputValue,
          showArrow: false,
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 保存
    handleConfirm() {
      this.$refs.editbbform.validate((valid) => {
        if (valid) {
          if (this.dxqcx) {
            this.zdybbinfo.DXQ = 1;
          } else {
            this.zdybbinfo.DXQ = 2;
          }
          if (this.abjcx2.length === 0) {
            this.zdybbinfo.BJCX = 3;
          } else if (this.abjcx2.length === 1) {
            this.zdybbinfo.BJCX = this.abjcx2[0];
          } else {
            this.zdybbinfo.BJCX = 1;
          }
          if (this.ZDYCX_checked) {
            if (!this.zdybbinfo.ZDYCXTS.trim())
              return this.$message.error("请输入搜索框提示字符！");
            this.zdybbinfo.ZDYCX = "1";
          } else {
            this.zdybbinfo.ZDYCX = "2";
          }
          if (this.ltmcarr.length <= 0) {
            this.$message({
              type: "info",
              message: "请输入展示列!",
            });
          }
          this.zdybbinfo.SSXX = this.ssxx;
          this.zdybbinfo.LTMC = this.ltmcarr
            .map((item) => item.value)
            .join(",");
          this.$api.post(
            "v1/customreport/AddZDYBB",
            {
              Id: this.zdybbinfo.Id,
              SSXX: this.ssxx,
              CJR: this.zdybbinfo.CJR,
              BBMC: this.zdybbinfo.BBMC,
              RQLX: this.RQLX_checkList[0] || null,
              BJCX: this.zdybbinfo.BJCX,
              DXQ: this.zdybbinfo.DXQ,
              ZDYCX: this.zdybbinfo.ZDYCX,
              ZDYCXTS: this.zdybbinfo.ZDYCXTS,
              DLYH: this.zdybbinfo.DLYH,
              XK: this.zdybbinfo.XK,
              LTMC: this.zdybbinfo.LTMC,
              SQLText: this.zdybbinfo.SQLText,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!",
                });
                this.$refs.editbbform.resetFields();
                this.ltmcarr = [];
                this.zdybbinfo.RQLX = 0;
                this.dialogVisible = false;
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "保存失败!",
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    // 关闭
    handleEditClose() {
      this.$refs.editbbform.resetFields();
      this.ltmcarr = [];
      this.zdybbinfo.RQLX = 0;
      this.dialogVisible = false;
    },
    handleAdd: function() {
      this.edittitle = "新增";
      this.editid = "";
      this.editcustomreport();
    },
    editcustomreport: function() {
      this.dialogVisible = true;
      this.abjcx2 = [];
      this.dxqcx = false;
      if (this.editid !== "") {
        this.loadbbdata();
      } else {
        this.zdybbinfo.Id = "";
        this.zdybbinfo.SSXX = "";
        this.zdybbinfo.LTMC = "";
        this.zdybbinfo.BBMC = "";
        this.zdybbinfo.RQLX = 0;
        this.zdybbinfo.BJCX = "";
        this.zdybbinfo.DXQ = "";
        this.zdybbinfo.ZDYCX = "";
        this.zdybbinfo.ZDYCXTS = "";
        this.zdybbinfo.DLYH = false;
        this.zdybbinfo.SQLText = "";
        this.dxqcx = false;
        this.RQLX_checkList = [];

        this.abjcx2 = [];
      }
    },
    goto: function() {
      this.$router.push("/jxgylb");
    },
    messagebox: function(message) {
      this.$alert("提示", message, {
        confirmButtonText: "确定",
      });
    },
    loaddata: function() {
      this.$api.post(
        "v1/customreport/GetReportList",
        { SSXX: this.ssxx, PagerInfo: this.pagerInfo, token: this.token },
        (r) => {
          if (r.Code === 0) {
            let list = r.Target || [];
            this.bblist = list.filter((item) => item.SYZT === 1);
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    loadbbdata: function() {
      if (this.editid !== "") {
        this.$api.post(
          "v1/customreport/GetReport",
          { Id: this.editid, token: this.token },
          (r) => {
            if (r.Code === 0) {
              this.zdybbinfo = r.Target;
              this.dxqcx = this.zdybbinfo.DXQ === 1;
              // 处理班级查询
              if (this.zdybbinfo.BJCX === 1) {
                this.abjcx2 = [1, 2];
              }
              if (this.zdybbinfo.BJCX === 2) {
                this.abjcx2 = [2];
              }
              if (this.zdybbinfo.BJCX === 3) {
                this.abjcx2 = [];
              }
              // 处理日期查询
              if (this.zdybbinfo.RQLX)
                this.RQLX_checkList = [this.zdybbinfo.RQLX];
              else this.RQLX_checkList = [];

              // 处理自定义查询
              if (this.zdybbinfo.ZDYCX === 1) {
                this.ZDYCX_checked = true;
              } else {
                this.ZDYCX_checked = false;
                this.zdybbinfo.ZDYCXTS = "";
              }
              // 处理登录用户
              if (this.zdybbinfo.DLYH === 1) {
                this.zdybbinfo.DLYH = true;
              } else {
                this.zdybbinfo.DLYH = false;
              }
              this.zdybbinfo.XK = !!this.zdybbinfo.XK;
              let arr = this.zdybbinfo.LTMC.split(",");
              let arr2 = [];
              if (arr.length > 0) {
                arr.forEach((item) => {
                  arr2.push({
                    value: item,
                    showArrow: false,
                  });
                });
              }
              this.ltmcarr = arr2;
            }
          }
        );
      }
    },
    // 翻页
    handleSizeChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    handleEdit: function(id) {
      this.edittitle = "编辑";
      this.editid = id;
      this.editcustomreport();
    },
    handleDelete: function(id) {
      this.$confirm("确定要删除该报表吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.post(
            "v1/customreport/DeleteReport",
            {
              Id: id,
              SSXX: this.ssxx,
              YHId: this.zdybbinfo.CJR,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "删除失败!",
                });
                this.loaddata();
              }
            }
          );
        })
        .catch(() => {});
    },
    handleEnable: function(id, status) {
      this.$api.post(
        "v1/customreport/EnableReport",
        {
          Id: id,
          SSXX: this.ssxx,
          YHId: this.zdybbinfo.CJR,
          Status: status,
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.loaddata();
          } else {
            this.$message({
              type: "info",
              message: "操作失败!",
            });
          }
        }
      );
    },
    // 移动展示列
    sortTag(tag, index) {
      if (index > 0) {
        this.ltmcarr.splice(index, 1);
        this.ltmcarr.splice(index - 1, 0, tag);
      }
    },
    RQLX_change(val) {
      if (val.length > 1) {
        this.RQLX_checkList = [val[val.length - 1]];
      }
    },
  },
  data() {
    var valicxtj = (rule, value, callback) => {
      if (this.zdybbinfo.RQLX <= 0) {
        console.log(rule, "rule");
        callback(new Error("请选择查询条件"));
      } else {
        callback();
      }
    };
    return {
      isLogin: true,
      edittitle: "",
      bblist: [],
      ltmcarr: [],
      ssxx: "",
      token: "",
      inputValue: "",
      editid: "",
      dialogVisible: false,
      innerVisible: false,
      inputVisible: false,
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0,
      },
      abjcx2: [],
      dxqcx: false,
      ZDYCX_checked: false,
      zdybbinfo: {
        Id: "",
        SSXX: "",
        CJR: "",
        LTMC: "",
        BBMC: "",
        RQLX: 0,
        BJCX: "",
        ZDYCX: "",
        ZDYCXTS: "",
        DLYH: false,
        XK: false,
        DXQ: "",
        SQLText: "",
      },
      rules: {
        BBMC: [
          { required: true, message: "请输入报表名称", trigger: "change" },
          { min: 1, max: 20, message: "长度在1到20个字符", trigger: "change" },
        ],
        SQLText: [
          { required: true, message: "请输入计划内容", trigger: "change" },
          {
            min: 1,
            max: 500,
            message: "长度在1到500个字符",
            trigger: "change",
          },
        ],
        RQLX: [
          { validator: valicxtj, message: "请选择查询条件", trigger: "change" },
        ],
      },
      RQLX_checkList: [],
    };
  },
  watch: {
    abjcx2: {
      handler(val) {
        if (val.length === 1 && val[0] === 1) {
          this.abjcx2 = [1, 2];
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  .RQLX_block {
    display: flex;
    padding: 10px;
    .RQLX_name {
      padding-left: 28px;
      margin-right: 12px;
    }
    .el-checkbox-group {
      flex: 1;
      .el-checkbox {
        margin-bottom: 5px;
      }
    }
  }
  .ZSL {
    .ZSL-block {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .tag-item {
        position: relative;
        min-width: 50px;
        border: 1px solid rgb(219, 218, 218);
        margin-left: 10px;
        padding: 5px 12px;
        border-radius: 5px;
        margin-bottom: 10px;
        .arrow {
          position: absolute;
          left: 1px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          font-size: 14px;
          line-height: 0;
          &:hover {
            color: green;
          }
        }
        .tag-content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 30px;
          font-size: 14px;
          line-height: 1.5;
          text-align: center;
        }
        .tag-del {
          position: absolute;
          right: 1px;
          top: 1px;
          line-height: 0;
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
      }
    }
  }
}
</style>
