import api from "./http";
import store from "@/store";
// 获取版本号
export function getVersionNo(user) {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/home/GetSchoolCacheSeriesNO",
      {
        SSXX: user.SSZZId,
        token: user.Token
      },
      res => {
        if (res.Code === 0) {
          store.commit("SAVE_VERSION_NO", res.Target || []);
          resolve();
        }
      },
      err => reject(err)
    );
  });
}
