<template>
  <div>设置项目</div>
</template>

<script>
export default {
    name:'teachersetXM',
}
</script>

<style>

</style>