<template>
  <el-table :data="tableData" class="jf-table" border
    tooltip-effect="dark" style="width: 100%;">
    <el-table-column type="index" fixed width="60" label="编号"></el-table-column>
    
    <el-table-column prop="MBMC" label="模板名称"></el-table-column>
    <el-table-column label="模板代码" prop="MBDM_Text"></el-table-column>
    <el-table-column label="对象类型" prop="DXLX">
        <template slot-scope="scope">
          {{
            scope.row.DXLX === 1? "学生": "老师"
          }}
        </template>
    </el-table-column>
    <el-table-column label="操作">
        <template slot-scope="scope">
            <el-button type="text" size="small" @click="editItem(scope.row)" >编辑</el-button >
            <el-button @click="delItem(scope.row)" type="text" size="small" 
                class="tab-wz-butta c-red">删除</el-button >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        tableData: {
            type: Array,
            default: function() {
                return [];
            }
        },
        reportType: {
            type: Array,
            default: function() {
                return [];
            }
        },
        user: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    methods:{
        editItem(data) {
            this.$emit("editItem", data);
        },
        delItem(data) {
            this.$confirm("确认删除？").then(() => {
                this.$emit("delItem",data);
            }).catch(()=>{});
        },
        
    },
}
</script>

<style>

</style>