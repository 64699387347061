<template>
  <!-- 教师端成长对话 -->
  <div style="display: flex;flex-direction: row;width: 100%;">
    <ul  style="padding-top: 40px;font-size: 14px;width: 10%;">
        <li :class="{'active': index === curIndex}" class="liopt" v-for="(item,index) in arrList" :key="index" @click="handleItem(item.PJLX,index)">
          {{item.PJMC}}
        </li>
      </ul>
    <div style="padding: 40px 0;width: 90%;">
      <div class="cont-whole-box" >
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <div class="table-er-butt" style="border: none;">
                <div class="table-er-butt" @click="handlemultiedit()">
                  <span class="table-er-buttspan c-blue">批量评价</span>
                </div>
              </div>
            </div>
            <el-table
              ref="singleTable"
              border
              @selection-change="handleSelectionChange"
              :data="datalist"
              type="selection"
              highlight-current-row
              style="width: 100%;"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column
                align="center"
                property="XM"
                width="185"
                label="学生姓名"
              >
              </el-table-column>
              <el-table-column align="center" property="PJNR" label="评语"></el-table-column>
              <el-table-column v-if="PJLXTpye == 2"  align="center" width="105" property="PJRname" label="评价人">
              </el-table-column>
              <el-table-column align="center" label="操作" width="105">
                <template slot-scope="scope">
                  <el-button
                    @click="handleEdit(scope.row)"
                    type="text"
                    style="color: blue;"
                    >编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="edittitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
      style="min-width:470px;"
      :before-close="handleEditClose"
      center
    >
      <el-form
        label-width="80px"
        style="width: 400px; display: inline-block;"
        :rules="rules"
        :model="pydx"
        ref="editconfigform"
        label-position="right"
        :inline="true"
      >
        <el-form-item label="描述：" prop="PJNR">
          <el-input
            style="width:100%; min-width:310px;"
            type="textarea"
            :rows="5"
            placeholder="请输入描述"
            v-model="pydx.PJNR"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item>
          <el-button @click="handleEditClose()">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.xxbm = user.SchoolCode;
      this.userid = user.Id;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.setGrowthConversation()
    this.loadbjdata();
  },
  methods: {
    //点击评价方式获取具体名单
    handleItem(data,index){
      this.curIndex = index
      this.PJLXTpye = data
      this.loadconvdata(this.bjid,data)
    },
    //获取评价的方式
    setGrowthConversation(){
      this.$api.post(
        "v1/customactivityset/GetGrowthCommentSet",
        {
          SchoolId: this.ssxx,
          token: this.token,
        },r => {
          if (r.Code === 0) {
            let arr = r.Target
            if(arr.SP){
              this.arrList.push({PJMC:arr.SP,PJLX:1})
            }
            if(arr.HP){
              this.arrList.push({PJMC:arr.HP,PJLX:2})
            }
            if(arr.ZP){
              this.arrList.push({PJMC:arr.ZP,PJLX:3})
            }
          }
        }
      )
    },
    handlemultiedit: function() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          type: "info",
          message: "请勾选要批量评价的学生!"
        });
        return;
      }
      this.edittitle = "教师评语";
      this.studentdatalist = [];
      this.pydx.PJNR = "";
      for (var i = 0; i < this.multipleSelection.length; i++) {
        var tempobj = new Object({
          StudentId: this.multipleSelection[i].Id,
          StudentName: this.multipleSelection[i].XM
        });
        this.studentdatalist.push(tempobj);
      }
      this.dialogVisible = true;
    },
    // 关闭
    handleEditClose() {
      this.$refs.editconfigform.resetFields();
      this.dialogVisible = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    loadstudentdata: function() {},
    // 保存
    handleConfirm() {
      this.$refs.editconfigform.validate(valid => {
        if (valid) {
          this.$api.post(
            "v1/growthfootprint/MultiSaveGrowthConversation",
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              XXBM: this.xxbm,
              BJId: this.bjid,
              PJLX: this.PJLXTpye?this.PJLXTpye:this.arrList[0].PJLX,
              PJNR: this.pydx.PJNR,
              PJR: this.userid,
              StudentList: this.studentdatalist,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: r.Message
                });
                this.$refs.editconfigform.resetFields();
                this.dialogVisible = false;
                this.PJLXTpye = this.arrList[0].PJLX
                this.curIndex = 0
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: r.Message
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    loadbjdata: function() {
      this.$api.post(
        "v1/accountmanage/GetClassTeacher",
        { SchoolId: this.ssxx, UserId: this.userid, token: this.token },
        r => {
          if (r.Code === 0) {
            this.bjid = r.Target;
            this.loaddata();
          }
        }
      );
    },
    loaddata: function() {
      if (this.bjid.length > 0) {
        this.$api.post(
          "v1/student/GetXSXXBList",
          {
            SchoolId: this.ssxx,
            ClassId: this.bjid,
            IsBY: 2,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.studentlist = r.Target;
              if(this.arrList.length>0){
                this.loadconvdata(this.bjid,this.arrList[0].PJLX);
              }
            }
          }
        );
      }
    },
    loadconvdata: function(bjid,PJLX) {
      this.$api.post(
        "v1/growthfootprint/GetGrowthConversation",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          XXBM: this.xxbm,
          BJId: bjid,
          PJLX: PJLX,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.datalist = [];
            let convlist = r.Target;

            //找评价人
            convlist.forEach(item=>{
              item.PJRname = this.getPJRname(item.PJR)
            })

            for (var i = 0; i < this.studentlist.length; i++) {
              if (this.studentlist[i].SYZT == 1) {
                var tempobj = new Object({
                  Id: this.studentlist[i].Id,
                  XM: this.studentlist[i].XM,
                  PJNR: "",
                  PJRname:""
                });
                if (
                  convlist !== null &&
                  convlist.length > 0 &&
                  convlist.find(c => c.XSId == tempobj.Id)
                ) {
                  tempobj.PJNR = convlist.find(c => c.XSId == tempobj.Id).PJNR;
                  tempobj.PJRname = convlist.find(c => c.XSId == tempobj.Id).PJRname;
                }
                this.datalist.push(tempobj);
              }
            }
          }
        }
      );
    },
    //获取评价人姓名
    getPJRname(data){
      let PJRname = ''
      
      let a = this.studentlist.find(c=>c.Id == data)
      console.log(a);
      if(a){
        PJRname = a.XM
      }else{
        PJRname = ''
      }
      return PJRname
    },
    handleEdit: function(val) {
      this.studentdatalist = [];
      var tempobj = new Object({
        StudentId: val.Id,
        StudentName: val.XM
      });
      this.studentdatalist.push(tempobj);
      this.pydx.PJNR = val.PJNR;
      this.dialogVisible = true;
    }
  },
  data() {
    return {
      bjid: "",
      ssxx: "",
      xxbm: "",
      ssxq: "",
      userid: "",
      pydx: {
        PJNR: ""
      },
      multipleSelection: [],
      studentdatalist: [],
      isLogin: true,
      datalist: [],
      studentlist: [],
      edittitle: "",
      dialogVisible: false,
      token: "",
      editid: "",
      pjnr: "",
      rules: {
        PJNR: [
          { required: true, message: "请输入评语内容", trigger: "change" },
          { min: 1, max: 500, message: "长度在1到500个字符", trigger: "change" }
        ]
      },
      arrList:[],   //左边选项列表
      curIndex:0,
      PJLXTpye:'' //评价类型
      // PJLXTpye:this.arrList[0].PJLX //评价类型
    };
  }
};
</script>
<style lang="scss" scoped>
.liopt{
  width:100px;
  padding: 10px;
}
  .active {
    color: #14d089;
  }
</style>