<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="50%"
    :before-close="handleClose"
  >
    <el-form
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item label="机构名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item
        label="组长"
        prop="manager"
        v-show="currentTreeNode.key !== '0' && currentTreeNode.type === 1"
      >
        <div class=" manager-checked">
          <el-checkbox-group v-model="form.manager" @change="managerChange">
            <el-checkbox
              v-for="item in managerList"
              :key="item.Id"
              :label="item.Id"
              >{{ item.Name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="teacher-list">
          <div class="teacher-item" v-for="item in form.manager" :key="item">
            <div class="teacher-name">
              {{ item | filterName(currentVM) }}
            </div>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              circle
              @click="del('manager', item)"
            ></el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="组员"
        prop="menber"
        v-show="currentTreeNode.key !== '0' && currentTreeNode.type === 1"
      >
        <div class=" menber-checked">
          <el-checkbox-group v-model="form.menber" @change="menberChange">
            <el-checkbox
              v-for="item in menberList"
              :key="item.Id"
              :label="item.Id"
              >{{ item.Name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="teacher-list">
          <div class="teacher-item" v-for="item in form.menber" :key="item">
            <div class="teacher-name">
              {{ item | filterName(currentVM) }}
            </div>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              circle
              @click="del('menber', item)"
            ></el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleClose(1)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    visible: Boolean,
    formData: Object,
    currentTreeNode: Object
  },
  data() {
    return {
      form: {
        Id: "",
        name: "",
        manager: [],
        menber: []
      },
      rules: {
        name: [
          { required: true, message: "机构名称不能为空", trigger: "blur" }
        ],
        manager: [{ required: true, message: "组长不能为空", trigger: "blur" }],
        menber: [{ required: true, message: "组员不能为空", trigger: "blur" }]
      },
      teacherList: [], //全部老师
      managerList: [], //组长老师
      menberList: [], //成员老师
      currentVM: this
    };
  },
  filters: {
    filterName(val, VM) {
      let obj = VM.teacherList.find(el => el.Id === val);
      if (obj) return obj.Name;
      else return "";
    }
  },
  methods: {
    getTeacher() {
      let userinfo = JSON.parse(localStorage.getItem("userinfo"));
      this.$api.post(
        "/v1/teacher/GetSchoolTeacherByAll",
        { SchoolId: userinfo.SSZZId, token: userinfo.Token },
        r => {
          if (r.Code === 0) {
            this.teacherList = r.Target;
            this.managerList = this.teacherList.map(item => item);
            this.menberList = this.teacherList.map(item => item);

            // 每次获得了叫教师 都要处理一下
            if (this.form.manager.length > 0 || this.form.menber.length > 0) {
              this.managerChange(this.form.manager);
              this.menberChange(this.form.menber);
            }
          }
        }
      );
    },
    managerChange(managers) {
      this.menberList = this.teacherList.filter(
        item => !managers.includes(item.Id)
      );
    },
    menberChange(menbers) {
      this.managerList = this.teacherList.filter(
        item => !menbers.includes(item.Id)
      );
    },
    del(role, teacher) {
      if (role === "manager") {
        this.form.manager = this.form.manager.filter(item => item !== teacher);
      }

      if (role === "menber") {
        this.form.menber = this.form.menber.filter(item => item !== teacher);
      }

      if (!this.managerList.find(item => item.Id === teacher)) {
        let obj = this.teacherList.find(item => item.Id === teacher);
        this.managerList.push(JSON.parse(JSON.stringify(obj)));
      }

      if (!this.menberList.find(item => item.Id === teacher)) {
        let obj = this.teacherList.find(item => item.Id === teacher);
        this.menberList.push(JSON.parse(JSON.stringify(obj)));
      }
    },
    handleClose(type) {
      if (type === 1) {
        this.$refs.form.validate(res => {
          if (res || (!res && this.currentTreeNode.key === "0")) {
            let JGCYList = null;
            if (this.currentTreeNode.key !== "0") {
              let arr = [];
              this.form.manager.forEach(id => {
                let obj = this.teacherList.find(item => item.Id === id);
                arr.push({
                  JSId: obj.Id,
                  ZZLB: 1
                });
              });
              this.form.menber.forEach(id => {
                let obj = this.teacherList.find(item => item.Id === id);
                arr.push({
                  JSId: obj.Id,
                  ZZLB: 2
                });
              });
              JGCYList = arr;
            }

            let { Id: UserId, SSZZId: SSXX, Token: token } = JSON.parse(
              localStorage.getItem("userinfo")
            );
            let JGLX = 1;
            if (this.currentTreeNode.key === "0") {
              JGLX = 1;
            } else if (
              this.currentTreeNode.type === 1 ||
              this.currentTreeNode.type === 2
            ) {
              JGLX = 2;
            }
            this.$api.post(
              "v1/schoolteaorg/AddXXJGCY",
              {
                XXJGInfo: {
                  Id: this.form.Id || null, //机构id
                  JYZMC: this.form.name, //机构名称
                  SSXX, //学校id
                  FId: this.currentTreeNode.key, //父id
                  JGLX //1机构目录 2机构
                },
                JGCYList,
                UserId,
                token
              },
              res => {
                if (res.Code === 0) this.$emit("closeForm", 1);
              }
            );
          }
        });
      } else {
        this.$emit("closeForm");
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getTeacher();
      } else {
        this.$refs.form.clearValidate();
      }
    },
    formData: {
      handler(val) {
        Object.assign(this.form, val);

        this.form.Id = this.form.key;

        if (
          Object.prototype.hasOwnProperty.call(val, "jgcy") &&
          val.jgcy instanceof Array
        ) {
          this.form.manager = val.jgcy
            .filter(item => item.ZZLB === 1)
            .map(item => item.JSId);

          this.form.menber = val.jgcy
            .filter(item => item.ZZLB === 2)
            .map(item => item.JSId);
        }
        if (!val.key) {
          this.form.manager = [];
          this.form.menber = [];
          this.form.Id = "";
          this.form.name = "";
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  .manager-checked,
  .menber-checked {
    border: 1px solid #ccc;
    padding: 10px;
  }

  .teacher-list {
    min-height: 100px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    .teacher-item {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
