<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="margno-t">
        <div class="table-er-box">
          <el-button @click="opendialog">新增</el-button>
        </div>
        <Table
          :tableData="tableData"
          @showItem="showItem"
          @editItem="editItem"
          @delItem="delItem"
          @setHDItem="setHDItem"
        />
      </div>
    </div>
    <!-- 新增编辑弹窗 -->
    <Dialog
      @canceldialog="canceldialog"
      @sumbitForm="sumbitForm"
      :levelOption="levelOption"
      :dialogTitle="dialogTitle"
      :formData="formData"
      :MBLX="MBInfo.MBDM"
      v-model="dialogVisible"
    />
  </div>
</template>

<script>
import Dialog from "@/views/zhbbmbManage/Dialog.vue";
import Table from "@/views/zhbbmbManage/Table.vue";
// import setHD from "@/views/zhbbmbManage/setHD.vue";

export default {
  components: { Dialog, Table },
  data() {
    return {
      dialogTitle: "新增",
      dialogVisible: false,
      setHDdialogVisible: false, //设置活动弹窗
      setXMdialogVisible: false, //设置项目弹窗
      formData: {},
      tableData: [],
      ssxx: "",
      xxbm: "",
      userid: "",
      token: "",
      levelOption: [],
      MBInfo: {},
    };
  },
  created() {
    let MBInfo = this.$route.query.data;
    if (MBInfo) {
      this.MBInfo = MBInfo;
      localStorage.setItem("MBInfo", JSON.stringify(this.MBInfo));
    } else {
      this.MBInfo = JSON.parse(localStorage.getItem("MBInfo"));
    }
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.xxbm = user.SchoolCode;
      this.userid = user.Id;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.getTableList();
    this.getLevelOptions();
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.data && to.query.data.MBDM === 1)
      to.meta.title = "教师职业道德报表";
    if (to.query.data && to.query.data.MBDM === 2)
      to.meta.title = "教师通用报表";
    next();
  },
  methods: {
    // 获取关联等级下拉
    getLevelOptions() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/levelmanager/GetLeaveSet",
          {
            SchoolId: this.ssxx,
            token: this.token,
          },
          (res) => {
            if (res.Code === 0) {
              this.levelOption = res.Target || [];
              resolve();
            }
          },
          (err) => reject(err)
        );
      });
    },
    //获取Table表格数据
    getTableList() {
      this.$api.post(
        "/v1/mbbb/GetMBGLBList",
        {
          MBID: this.MBInfo.Id,
          pagerInfo: {
            pageIndex: 1,
            pageSize: 9000,
            totalCount: 0,
          },
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            console.log(r.Target, "获取Table表格数据");
            let list = r.Target || [];
            list.forEach((i) => {
              i.MBMC = this.MBInfo.MBMC;
            });
            this.tableData = list;
          }
        }
      );
    },
    opendialog() {
      this.dialogTitle = "新增";
      this.dialogVisible = true;
      this.formData = {};
    },
    showItem(data) {
      this.dialogTitle = "";
      this.dialogVisible = true;
    },
    // 编辑
    editItem(data) {
      this.dialogTitle = "编辑模板";
      this.formData = data;
      this.dialogVisible = true;
    },
    //删除
    delItem(data) {
      console.log("删除", data.Id);
      this.$api.post(
        "/v1/mbbb/DeleteMBGLB",
        {
          Id: data.Id,
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: "删除成功",
              showClose: true,
            });
            this.getTableList();
          }
        }
      );
    },
    // 设置活动
    setHDItem(data) {
      this.setHDdialogVisible = true;
      this.dialogTitle = "设置活动";
    },
    //取消按钮
    canceldialog() {
      this.dialogVisible = false;
      this.setHDdialogVisible = false;
    },
    // 确认按钮
    confirmdialog() {
      this.dialogVisible = false;
    },
    //新增确认保存
    sumbitForm(data) {
      console.log(data, "新增确认保存");
      this.dialogVisible = false;
      data.SSXX = this.ssxx;
      data.CJR = this.userid;
      //新增弹窗
      if (this.dialogTitle.includes("新增")) {
        this.$api.post(
          "/v1/mbbb/AddMBGLB",
          {
            token: this.$store.state.token,
            MBGLBInfo: data,
          },
          (r) => {
            if (r.Code == 0) {
              this.$message({
                type: "success",
                message: "新增成功",
                showClose: true,
              });
              this.getTableList();
            }
          }
        );
      } else {
        // 编辑
        this.$api.post(
          "/v1/mbbb/UpdateMBGLB",
          {
            token: this.$store.state.token,
            MBGLBInfo: data,
          },
          (r) => {
            if (r.Code == 0) {
              console.log(r.Target);
              this.$message({
                type: "success",
                message: "编辑成功",
                showClose: true,
              });
              this.getTableList();
            }
          }
        );
      }
    },
  },
};
</script>

<style></style>
