<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->
      <div class="tab-heng-box margno-t">
        <div class="table-er-box">
          <el-button plain icon="el-icon-edit" @click="oepntermadd"
            >学期维护</el-button
          >
        </div>
        <el-table
          :data="tableData"
          class="jf-table"
          border
          tooltip-effect="dark"
          style="width: 100%;"
        >
          <el-table-column prop="XQMC" label="学期名称"></el-table-column>
          <el-table-column prop="SSXN" label="所属学年"></el-table-column>
          <el-table-column prop="XQLX" label="学期类型">
            <template slot-scope="scope">
              <p v-if="scope.row.XQLX == '1'">上学期</p>
              <p v-else-if="scope.row.XQLX == '2'">下学期</p>
            </template>
          </el-table-column>
          <!--<el-table-column prop="XXXDLX"
                                                           label="学段">
                                              <template slot-scope="scope">
                                                  <p v-if="scope.row.XXXDLX=='1'">
                                                      小学段
                                                  </p>
                                                  <p v-else-if="scope.row.XXXDLX=='2'">
                                                      中学段
                                                  </p>
                                              </template>
                    </el-table-column>-->
          <el-table-column label="开始时间">
            <template slot-scope="scope">
              {{ scope.row.KSSJ | DateFormat("yyyy-MM-dd") }}
            </template>
          </el-table-column>
          <el-table-column label="结束时间">
            <template slot-scope="scope">
              {{ scope.row.JSSJ | DateFormat("yyyy-MM-dd") }}
            </template>
          </el-table-column>
          <el-table-column prop="XQZS" label="周次"></el-table-column>
          <el-table-column prop="SFDQXQ" label="是否当前学期">
            <template slot-scope="scope">
              <p v-if="scope.row.SFDQXQ == '1'">是</p>
              <p v-else-if="scope.row.SFDQXQ == '2'">否</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="selectWeek(scope.$index, scope.row)"
                >查看周次</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="editRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="rowcount"
          ></el-pagination>
        </div>
      </div>
      <!--表格区结束-->
    </div>
    <el-dialog
      title="学期管理"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="XQBInfo" :rules="rules" ref="sbmitfrom">
        <el-form-item
          label="学期名称"
          :label-width="formLabelWidth"
          prop="XQMC"
        >
          <el-input v-model="XQBInfo.XQMC" autocomplete="off"></el-input>
        </el-form-item>
        <!--<el-form-item label="学段" :label-width="formLabelWidth">
                            <el-select v-model="XQBInfo.XXXDLX" placeholder="请选择学段" style="width:100%">
                                <el-option label="小学段" :value="1"></el-option>
                                <el-option label="中学段" :value="2"></el-option>
                            </el-select>
                </el-form-item>-->
        <el-form-item label="学期类型" :label-width="formLabelWidth">
          <el-radio-group v-model="XQBInfo.XQLX">
            <el-radio :label="1">上学期</el-radio>
            <el-radio :label="2">下学期</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="开始时间"
          :label-width="formLabelWidth"
          prop="KSSJ"
        >
          <!--<el-col :span="11" style="width:100%">-->
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="XQBInfo.KSSJ"
            style="width: 100%;"
            @blur="calculationzc"
          ></el-date-picker>
          <!--</el-col>-->
        </el-form-item>
        <el-form-item
          label="结束时间"
          :label-width="formLabelWidth"
          prop="JSSJ"
        >
          <!--<el-col :span="11" style="width:100%">-->
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="XQBInfo.JSSJ"
            style="width: 100%;"
            @blur="calculationzc"
          ></el-date-picker>
          <!--</el-col>-->
        </el-form-item>
        <el-form-item label="周期" :label-width="formLabelWidth" prop="XQZS">
          {{ XQBInfo.XQZS }}
        </el-form-item>
        <el-form-item label="是否当前学期" :label-width="formLabelWidth">
          <el-radio-group v-model="XQBInfo.SFDQXQ">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitshowdata">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isInteger } from "@/style/js/Validator.js";

export default {
  name: "AreaTermList",
  data() {
    return {
      token: this.$store.state.token,
      // loading:true,
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      XQBInfo: {
        Id: "",
        XQMC: "",
        XQZS: "0",
        KSSJ: "",
        JSSJ: "",
        SSXN: "0",
        XQLX: 1,
        SFDQXQ: 1,
        SSQYXX: "",
        XXXDLX: 1,
        CJR: "",
        CJSJ: "2019-09-09 09:35:24",
        SFSC: "2",
        SYZT: "1"
      },
      dialogFormVisible: false,
      formLabelWidth: "120px",
      xqzcbInfoList: [],
      rules: {
        XQMC: [
          {
            required: true,
            message: "请输入学期名称",
            trigger: "blur"
          }
        ],
        KSSJ: [
          {
            required: true,
            message: "请选择日期",
            trigger: "blur"
          }
        ],
        JSSJ: [
          {
            required: true,
            message: "请选择日期",
            trigger: "blur"
          }
        ],
        XQZS: [
          {
            required: true,
            message: "周次",
            trigger: "blur"
          },
          {
            validator: isInteger,
            message: "正在获取周次",
            trigger: "change"
          }
        ]
      },
      userName: "",
      userId: "",
      props: {
        label: "name",
        children: "subnodeList"
      }
    };
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.XQBInfo.CJR = userinfo.Id;
    this.userName = userinfo.DLM;
    this.userId = userinfo.Id;
    this.XQBInfo.SSQYXX = userinfo.SSZZId;
    const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };
    this.$api.post(
      "v1/xqb/GetXQBList",
      {
        AreaOrSchoolId: this.XQBInfo.SSQYXX,
        PagerInfo: pagerInfo,
        token: this.token
      },
      r => {
        if (r.Code === 0) {
          this.tableData = r.Target;

          this.currentPage = r.PagerInfo.PageIndex;
          this.pagesize = r.PagerInfo.PageSize;
          this.rowcount = r.PagerInfo.RowCount;
        }
      }
    );
  },
  methods: {
    // tree选中
    handleNodeClick(data) {
      if (this.XQBInfo.SSQYXX !== data.key || this.loadstate) {
        this.loadstate = false;
        this.XQBInfo.SSQYXX = data.key;
        const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };
        this.$api.post(
          "v1/xqb/GetXQBList",
          { AreaOrSchoolId: data.key, PagerInfo: pagerInfo, token: this.token },
          r => {
            if (r.Code === 0) {
              this.tableData = r.Target;

              this.currentPage = r.PagerInfo.PageIndex;
              this.pagesize = r.PagerInfo.PageSize;
              this.rowcount = r.PagerInfo.RowCount;
            }
          }
        );
      }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);

      const pagerInfo = { pageIndex: 0, pageSize: val };

      this.loadList(pagerInfo);
    },
    handleCurrentChange(val) {
      // console.log(`当前页1: ${val}`);

      const pagerInfo = { pageIndex: val, pageSize: this.pagesize };

      this.loadList(pagerInfo);
    },

    // 刷新列表
    refloadlist: function() {
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };

      this.loadList(pagerInfo);
    },
    // 加载列表
    loadList: function(pagerInfo) {
      this.$api.post(
        "v1/xqb/GetXQBList",
        {
          AreaOrSchoolId: this.XQBInfo.SSQYXX,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;

            this.currentPage = r.PagerInfo.PageIndex;
            this.pagesize = r.PagerInfo.PageSize;
            this.rowcount = r.PagerInfo.RowCount;

            // loading=false;
          }
        }
      );
    },

    // 新增
    oepntermadd: function() {
      if (this.XQBInfo.SSQYXX === "") {
        this.$message({
          type: "info",
          message: "区域账号异常！"
        });
        return;
      }
      this.dialogFormVisible = true;

      var cjr = this.XQBInfo.CJR;
      var schoolId = this.XQBInfo.SSQYXX;
      this.XQBInfo = {
        Id: "",
        XQMC: "",
        XQZS: "0",
        KSSJ: "",
        JSSJ: "",
        SSXN: "0",
        XQLX: 1,
        SFDQXQ: 1,
        SSQYXX: schoolId,
        XXXDLX: 1,
        CJR: cjr,
        CJSJ: "2019-09-09 09:35:24",
        SFSC: "2",
        SYZT: "1"
      };
    },
    // 查看周次
    selectWeek: function(index, row) {
      this.$router.push({ name: "XqWeekList", params: { termId: row.Id } });
    },
    // 修改
    editRow: function(index, row) {
      this.XQBInfo = row;
      this.dialogFormVisible = true;

      // console.log(index, row);
    },
    // 删除
    deleteRow: function(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/xqb/XQBDelete",
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    // 周次计算
    calculationzc: function() {
      if (this.XQBInfo.KSSJ !== "" && this.XQBInfo.JSSJ !== "") {
        this.$api.post(
          "v1/xqzcb/GetXQZCByDateList",
          {
            startDate: this.XQBInfo.KSSJ,
            endDate: this.XQBInfo.JSSJ,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.xqzcbInfoList = r.Target;
              this.XQBInfo.XQZS = r.Target.length;

              // this.$notify({
              //    type: "success",
              //    message: r.Message,
              //    duration: 3000
              // });
            } else {
              this.$message({
                type: "error",
                message: r.Message,
                showClose: true
              });
            }
          }
        );
      }
    },
    // 保存
    submitshowdata: function() {
      // event.preventDefault();

      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.$api.post(
            "v1/xqb/XQBAdd",
            {
              XQBInfo: this.XQBInfo,
              xqzcbInfoList: this.xqzcbInfoList,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token,
              CJDXLX: 0
            },
            r => {
              if (r.Code === 0) {
                this.dialogFormVisible = false;
                this.refloadlist();
                this.$notify({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style>
.cell {
  text-align: left;
}
</style>
