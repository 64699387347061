<template>
    <el-dialog top="5vh" :visible.sync="value" width="297mm" class="my-dialog"  ref="dialog"
        :close-on-click-modal="false" :before-close="close">
        <el-button @click="downPFB()"  style="float: left;" size="small" type="primary" >下载报表</el-button>
        <div class="page-container pdfCentent">
        <div class="page-title">{{ pageTitle }}评分表</div>
        <p class="page-subtitle">{{ pageSubtitle }}</p>
        <div class="top-box">
            <div class="top-item">单位(公章):</div>
            <div class="top-item">校长(签字):</div>
            <div class="top-item">被考核人：{{tableRow.KHRXM}}</div>
        </div>    
        <!-- 表格 -->
        <el-table :data="tableData" border align="center">
            <el-table-column prop="khxm" align="center" label="考核项目">
            </el-table-column>
            <el-table-column prop="fz" align="center" label="分值" >
            </el-table-column>
            <el-table-column :label="`定量考核${sumObj.DLXKLable}%`">
                <el-table-column prop="dldf" align="center" label="减分" width="120">
                    <el-table-column prop="jfxm" align="center" label="项目" width="100">
                    </el-table-column>
                    <el-table-column prop="jffz" align="center" label="分值" width="50">
                    </el-table-column>
                </el-table-column>
            <el-table-column label="减分原因" width="80" align="center" prop="jfyy"> </el-table-column>
            <el-table-column label="定量考核最后得分" align="center" width="50" prop="dldf"> </el-table-column>
            </el-table-column>
            <el-table-column prop="date" :label="`定性考核${sumObj.DXKLable}%`" >
                <el-table-column prop="date" align="center" label="分类考核" >
                    <el-table-column prop="xsjz" align="center" :label="`学生家长评(${sumObj.sum1BL}%)`" >
                    </el-table-column>
                    <el-table-column prop="jszhp" align="center" :label="`教师自评/互评(${sumObj.sum2BL}%)`" >
                    </el-table-column>
                    <el-table-column prop="khldxz" align="center" :label="`考核领导小组评(${sumObj.sum3BL}%)`" >
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="dxdf" align="center" label="定性考核最后得分" >
                </el-table-column>
            </el-table-column>
            <el-table-column prop="zhdf"  align="center" label="职业道德考核最后得分" >
            
            </el-table-column>
            <!-- 合计表格列 -->
            <!-- <div slot="append">
                <table v-if="tableData.length != 0" class="el-table__body" style="width: 100%">
                    <tr ref="sum" class="el-table__row sum">
                    <td colspan="10" class="el-table__cell"></td>
                    <td ref="sum_total_amount" class="el-table__cell">合计:{{getSum('num')}}</td>
                    </tr>
                </table>
            </div> -->
        <div slot="append">
            <table v-if="tableData.length != 0" class="el-table__body" style="width: 100%">
                <tr ref="sum" class="el-table__row sum">
                <td ref="sum_free_cell" class="el-table__cell">考核等级:{{tableRow.KHJG}}</td>
    
                <td ref="sum_free_cell" class="el-table__cell">被考核人签字:</td>
                </tr>
            </table>
        </div>
        </el-table>
        
        </div>
    </el-dialog>
  </template>
  
  <script>
    import JSZip from "jszip";
    import FileSaver from "file-saver";

    export default {
        
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            tableRow: {
                type: Object,
                required: true,
                default: function() {
                    return {};
                }
            },
            pageSubtitle:String,
            
        },
        model: {
            prop: "visible",
            event: "change"
        },
        data() {
            return {
                value:false,
                pageTitle: JSON.parse(localStorage.getItem('DevelopmentMB')).TTMC,
                sumObj:{},
                tableData:[],
                zips:null,
            }
        },
        created(){
            
          
        },
        methods:{
            // 下载
           async downPFB(){
                this.zips = new JSZip();   
                let title = this.pageTitle
                let p = await this.getPdfFromHtml2('pdfCentent',title)
                console.log(p);
                await this.zips.file(
                    `${p.name}-${new Date().getTime()}.pdf`,
                    p.PDF.output("blob")
                );
                this.zips.generateAsync({
                    type: "blob",
                }).then((content) => {
                    FileSaver.saveAs(
                    content,
                    this.tableData.KHRXM + new Date().getTime() + ".zip"
                    );
                });
            },

        close(){
            this.$emit('canceldialog')
        },
        
  
      },
        watch:{
          visible(val) {
            this.value = val;
            if(val){
                  this.sumObj = JSON.parse(localStorage.getItem("sumObj"));
              this.tableData =  this.tableRow.JG
            }
          }
        }
    }
  </script>
  
  <style lang="scss" scoped>
    .el-table{
        ::v-deep .el-table__body-wrapper{
            .el-table__row{
                .el-table__cell{
                    padding:  5px 0;
                }
            }
    }
    }
    .my-dialog{
        padding: 2px;
        min-width: 1200px;
        ::v-deep .el-dialog__body{
            padding: 1px;
            max-height: 210mm;
            height: 210mm;
        }
    }
  .top-box{
        display: flex;
        justify-content: space-between;
    }
  .page-container {
    width: 297mm;
    height: 210mm;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    height: calc(100% - 32px);
    overflow-y: scroll;
  }
   
  .page-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .page-subtitle {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  </style>