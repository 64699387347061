<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <el-row>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-tree
              :props="defaultProps"
              :data="pcmenulist"
              show-checkbox
              node-key="key"
              default-expand-all
              :default-checked-keys="pcdefaultcheck"
              @check="pchandleCheck"
              :expand-on-click-node="false"
            ></el-tree>

            <!--:load="pcloadNode"-->
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-tree
              :props="defaultProps"
              :data="appmenulist"
              show-checkbox
              node-key="key"
              default-expand-all
              :default-checked-keys="appdefaultcheck"
              @check="apphandleCheck"
              :expand-on-click-node="false"
            ></el-tree>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolPower",
  data() {
    return {
      token: this.$store.state.token,
      schoolId: "",
      schoolbm: "",
      pcmenulist: [],

      pcparentId: "0",
      pcloadstate: true,
      pcdefaultcheck: [],

      appmenulist: [],
      appparentId: "0",
      apploadstate: true,
      appdefaultcheck: [],
      defaultProps: {
        children: "subnodeList",
        label: "name"
      },
      pckeylist: [],
      appkeylist: [],
      userName: "",
      userId: ""
    };
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));

    this.userName = userinfo.DLM;
    this.userId = userinfo.Id;

    this.schoolId = this.$route.params.Id;
    this.schoolbm = this.$route.params.XXBM;

    setTimeout(() => {
      this.$api.post(
        "v1/gnmkqx/GetModularByFirstTree",
        { MenuType: 1, token: this.token },
        r => {
          if (r.Code === 0) {
            this.pcmenulist = r.Target;
          }
        }
      );

      this.$api.post(
        "v1/gnmkqx/GetModularByFirstTree",
        { MenuType: 2, token: this.token },
        r => {
          if (r.Code === 0) {
            this.appmenulist = r.Target;
          }
        }
      );

      this.pcdefaulttree();
      this.appdefaulttree();
    }, 500);
  },
  methods: {
    // 刷新默认
    pcdefaulttree: function() {
      // 默认选中
      this.$api.post(
        "v1/areaschool/GetAreaSChoolMenu",
        { SchoolId: this.schoolId, SSYY: 1, token: this.token },
        r => {
          if (r.Code === 0) {
            this.pcdefaultcheck = r.Target;
          }
        }
      );
    },
    // pc选中保存
    pchandleCheck: function(data, checkinfo) {
      var checkidList = [];
      // 是否取消全部
      var isdeleteall = false;
      var checkedNodelist = checkinfo.checkedNodes;
      if (checkedNodelist.length > 0) {
        checkedNodelist.forEach(item => {
          if (item.parentId !== "0") {
            var XXMKGLInfo = {
              Id: 0,
              SSQYXX: this.schoolId,
              QYXXBM: this.schoolbm,
              GNMKId: item.key
            };
            checkidList.push(XXMKGLInfo);
          }
        });
      } else {
        isdeleteall = true;
      }

      // 是否选中
      var isselect = false;
      if (this.pcdefaultcheck.length <= checkidList.length) {
        isselect = true;
      }

      this.$api.post(
        "v1/areaschool/AddAreaSchoolMenu",
        {
          SchoolModularList: checkidList,
          SchoolId: this.schoolId,
          IsMenu: isdeleteall,
          IsChecked: isselect,
          CheckedId: data.key,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.pcdefaulttree();

            this.$notify({
              type: "success",
              message: r.Message,
              duration: 3000
            });
          }
        }
      );
    },
    // 刷新默认
    appdefaulttree: function() {
      // 默认选中
      this.$api.post(
        "v1/areaschool/GetAreaSChoolMenu",
        { SchoolId: this.schoolId, SSYY: 2, token: this.token },
        r => {
          if (r.Code === 0) {
            this.appdefaultcheck = r.Target;
          }
        }
      );
    },
    // app选中保存
    apphandleCheck: function(data, checkinfo) {
      var checkidList = [];
      // 是否取消全部
      var isdeleteall = false;
      var checkedNodelist = checkinfo.checkedNodes;
      if (checkedNodelist.length > 0) {
        checkedNodelist.forEach(item => {
          if (item.parentId !== "0") {
            var XXMKGLInfo = {
              Id: 0,
              SSQYXX: this.schoolId,
              QYXXBM: this.schoolbm,
              GNMKId: item.key
            };
            checkidList.push(XXMKGLInfo);
          }
        });
      } else {
        isdeleteall = true;
      }

      // 是否选中
      var isselect = false;
      if (this.appdefaultcheck.length <= checkidList.length) {
        isselect = true;
      }

      this.$api.post(
        "v1/areaschool/AddAreaSchoolMenu",
        {
          SchoolModularList: checkidList,
          SchoolId: this.schoolId,
          IsMenu: isdeleteall,
          IsChecked: isselect,
          CheckedId: data.key,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.appdefaulttree();

            this.$notify({
              type: "success",
              message: r.Message,
              duration: 3000
            });
          }
        }
      );
    }
  }
};
</script>
