<template>
  <div class="cont-whole-box">
    <div style="border-bottom: 1px solid #f2f2f2; padding-top: 30px;">
      <div class="top-butt-box">
        <div
          class="cent-butt cent-butt-sty whole-col whole-col-hov"
          @click="handleChangeTabs(1)"
        >
          按指标层级
        </div>
        <div class="cent-butt whole-col-hov" @click="handleChangeTabs(2)">
          按维度层级
        </div>
      </div>
    </div>

    <div class="leftrig-box">
      <div class="leftrig-left-box" style="width: -webkit-calc(11% - 0px);">
        <Tree
          :indexFilter="indexFilter"
          @changeNode="changeNode"
          :newNode="newNode"
          ref="Tree"
          v-if="reFresh"
        />
      </div>
      <div class="leftrig-rig-box" style="max-width: calc(88% - 1px);">
        <!--表格区开始-->
        <div class="margno-t">
          <div class="table-er-box">
            <el-button
              icon="el-icon-circle-plus-outline"
              v-if="this.indexFilter == 1 && areaId.includes('-')"
              @click="dialogFormVisible = true"
              >添加</el-button
            >
            <div
              class="prompt-title-box"
              style="color: red;"
              v-else-if="this.indexFilter == 1 && !areaId.includes('-')"
            >
              只能在区域下添加指标
            </div>
          </div>
          <el-table
            :data="tableData"
            class="jf-table"
            border
            tooltip-effect="dark"
            style="width: 100%;"
          >
            <el-table-column
              fixed
              prop="zbmc"
              label="指标名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="bz"
              label="备注"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="!IsHierarchy"
              prop="fjzbmc"
              label="父指标名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="df"
              label="指标积分"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="zblxStr"
              label="评价类型"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="pjfsStr"
              label="评价方式"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="tjlxStr"
              label="统计类型"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="IsHierarchy"
              prop="zbmrf"
              label="指标默认分"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="IsHierarchy"
              prop="zbmf"
              label="指标满分"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              fixed="right"
              v-if="IsHierarchy"
              label="操作"
              width="100"
            >
              <template slot-scope="scope">
                <el-button @click="editItem(scope.row)" type="text" size="small"
                  >编辑</el-button
                >
                <el-button
                  @click="delItem(scope.row)"
                  class="tab-wz-butta c-red"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="prompt-title-box">
            温馨提示：1.建议评价类型和评价方式和指标积分只设置在末级指标上；2.建议只在一级指标上设置关联维度
          </div>
          <!--页码开始-->
          <div class="pagin-box">
            <div class="pagin-wz">
              <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
            </div>
          </div>
          <!--页码结束-->
        </div>
        <!--表格区结束-->
      </div>
    </div>
    <el-dialog
      title="添加指标"
      :visible.sync="dialogFormVisible"
      :before-close="cancelForm"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :ref="formName" :rules="rules">
        <el-form-item
          label="指标名称"
          :label-width="formLabelWidth"
          prop="zbmc"
        >
          <el-input v-model="form.zbmc" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="form.bz" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item label="指标积分" :label-width="formLabelWidth" prop="df">
          <el-input v-model="form.df" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          label="评价类型"
          :label-width="formLabelWidth"
          prop="zblx"
        >
          <el-select v-model="form.zblx" placeholder="请选择评价类型">
            <el-option
              v-for="item in pjlx"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="评价方式"
          :label-width="formLabelWidth"
          prop="pjfs"
        >
          <el-select v-model="form.pjfs" placeholder="请选择评价方式">
            <el-option
              v-for="item in pjfs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="统计类型"
          :label-width="formLabelWidth"
          prop="tjlx"
        >
          <el-select v-model="form.tjlx" placeholder="请选择统计类型">
            <el-option
              v-for="item in tjlx"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="末级维度" :label-width="formLabelWidth">
          <!-- <el-select v-model="form.sswd" placeholder="请选择末级维度">
            <el-option
              v-for="item in sswd"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>-->
          <el-cascader
            :props="{ expandTrigger: 'hover' }"
            v-model="form.sswd"
            :options="sswd"
            :show-all-levels="false"
            placeholder="请选择末级维度"
            @change="handleWdSelected"
          ></el-cascader>
        </el-form-item>

        <el-form-item
          label="指标默认分"
          :label-width="formLabelWidth"
          prop="zbmrf"
        >
          <el-input v-model="form.zbmrf" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          label="指标满分"
          :label-width="formLabelWidth"
          prop="zbmf"
        >
          <el-input v-model="form.zbmf" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          label="指标间隔分"
          :label-width="formLabelWidth"
          prop="zbjgf"
        >
          <el-input v-model="form.zbjgf" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label="排序号" :label-width="formLabelWidth">
          <el-input v-model="form.pxh" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label="图标" :label-width="formLabelWidth">
          <!-- <el-input v-model="form.zbtp"></el-input> -->
          <Upload
            v-if="dialogFormVisible"
            :itemPic="form.zbtp ? form.zbtp : $store.state.defaultImage"
            :width="50"
            :height="50"
            @getResultUrl="getResultUrl"
            :circle="true"
            html
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="confirmForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Tree from "@/views/manager/areaindex/Tree.vue";
import Upload from "@/components/Upload.vue";
import { isFloat, isUintNumber } from "@/style/js/Validator.js";
export default {
  components: {
    Pager,
    Tree,
    Upload
  },
  data() {
    return {
      treeDataIsLoading: false,
      nodeType: 0,
      visible: true,
      pjlx: [
        {
          value: 1,
          label: "加分"
        },
        {
          value: 2,
          label: "减分"
        },
        {
          value: 3,
          label: "中评"
        }
      ],
      pjfs: [
        {
          value: 1,
          label: "勾选打分"
        },
        {
          value: 2,
          label: "自定义打分"
        }
      ],
      tjlx: [
        {
          value: 1,
          label: "累计得分"
        },
        {
          value: 2,
          label: "取最高分"
        }
      ],
      sswd: [
        {
          value: "zhinan",
          label: "指南",
          children: []
        }
      ],
      tableData: [],
      areaId: "000001",
      parentId: "",
      indexFilter: 1,
      dimensionId: "",
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      dialogFormVisible: false,
      formLabelWidth: "120px",
      level: 0,
      newNode: [],
      node_id: "",
      form: {},
      currentNode: {},
      reFresh: true,
      rules: {
        zbmc: [
          { required: true, message: "请输入指标名称", trigger: "change" }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        df: [
          { required: true, message: "请输入指标积分", trigger: "change" },
          {
            validator: isUintNumber,
            message: "分数必须为自然数",
            trigger: "change"
          },
          {
            validator: isFloat,
            message: "分数最高精确到2位小数",
            trigger: "change"
          }
        ],
        zblx: [
          { required: true, message: "请选择评价类型", trigger: "change" }
        ],
        pjfs: [
          { required: true, message: "请选择评价方式", trigger: "change" }
        ],
        tjlx: [
          { required: true, message: "请选择统计类型", trigger: "change" }
        ],
        // sswd: [
        //   { required: true, message: "请选择末级维度", trigger: "change" }
        // ],
        zbmrf: [
          {
            validator: isUintNumber,
            message: "分数必须为自然数",
            trigger: "change"
          },
          {
            validator: isFloat,
            message: "分数最高精确到2位小数",
            trigger: "change"
          }
        ],
        zbmf: [
          {
            validator: isUintNumber,
            message: "分数必须为自然数",
            trigger: "change"
          },
          {
            validator: isFloat,
            message: "分数最高精确到2位小数",
            trigger: "change"
          }
        ],
        zbjgf: [
          {
            validator: isUintNumber,
            message: "分数必须为自然数",
            trigger: "change"
          },
          {
            validator: isFloat,
            message: "分数最高精确到2位小数",
            trigger: "change"
          }
        ]
      },
      formName: "form"
    };
  },
  methods: {
    handleWdSelected(value) {
      if (value) {
        if (value.length > 1) {
          this.form.sswd = value[1];
        } else {
          this.form.sswd = value[0];
        }
      }
    },
    getDimensions() {
      const pageinfo = {
        PageIndex: 0,
        PageSize: parseInt(Number.MAX_SAFE_INTEGER / 10000000)
      };
      this.sswd = [];
      this.$api.post(
        "/v1/dimension/GetDimensionTree",
        {
          token: this.$store.state.token,
          AreaId: this.areaId,
          PagerInfo: pageinfo
        },
        r => {
          this.sswd = JSON.parse(JSON.stringify(r.Target));
          // for(let i=0;i<data.length;i++){
          //   let childrenList=data[i].ChildrenList
          //   this.sswd.push({value:data[i].Id,label:data[i].WDMC,children:childrenList})
          // }
        }
      );
    },
    getData() {
      // const that = this;
      this.$api.post(
        "/v1/areaindex/GetIndex",
        {
          token: this.$store.state.token,
          ParentId: this.parentId,
          AreaId: this.areaId,
          IndexFilter: this.indexFilter,
          DimensionId: this.dimensionId,
          PagerInfo: this.pagerInfo
        },
        r => {
          var resp = r.Target;
          var data = resp.Target;
          this.pagerInfo.totalCount = resp.PagerInfo.RowCount;
          this.tableData = [];
          for (var i = 0; i < data.length; i++) {
            this.tableData.push({
              id: data[i].Id,
              zbmc: data[i].ZBMC,
              bz: data[i].BZ,
              df: data[i].DF,
              zblx: data[i].ZBLX,
              pjfs: data[i].PJFS,
              tjlx: data[i].TJLX,
              zbmrf: data[i].ZBMRF,
              zbmf: data[i].ZBMF,
              sswd: data[i].SSWD,
              zbjgf: data[i].ZBJGF,
              zbtp: data[i].ZBTP,
              pxh: data[i].PXH,
              cjr: data[i].CJR,
              cjsj: data[i].CJSJ,
              syzt: data[i].SYZT,
              cj: data[i].CJ,

              zblxStr: data[i].zblxStr,
              pjfsStr: data[i].pjfsStr,
              tjlxStr: data[i].tjlxStr,
              fjzbmc: data[i].fjzbmc
            });
          }
        }
      );
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
      this.getData();
    },
    // eslint-disable-next-line no-unused-vars
    changeNode(obj, node) {
      this.dimensionId = "";
      this.parentId = obj.Id;
      this.areaId = obj.AreaId;
      this.level = 1;
      this.nodeType = obj.Nodetype;
      if (obj.Nodetype === 1) {
        this.areaId = obj.Id;
      } else if (obj.Nodetype === 2) {
        this.dimensionId = obj.Id;
      } else if (obj.Nodetype === 3) {
        this.level = ++obj.level;
      }
      // this.node_id = obj.nodeId;
      // this.currentNode = node;
      this.getData();
      this.getDimensions();
    },
    confirmForm() {
      this.$refs[this.formName].validate(valid => {
        if (valid) {
          this.$confirm("确认提交？")
            // eslint-disable-next-line no-unused-vars
            .then(r => {
              this.submitForm();
            })
            // eslint-disable-next-line no-unused-vars
            .catch(r => {});
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.$confirm("确认关闭？").then(() => {
        this.dialogFormVisible = false;
        this.form = {};
        this.$refs[this.formName].resetFields();
      });
      // this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "保存",
      //   cancelButtonText: "放弃修改"
      // })
      //   .then(() => {
      //     //执行提交方法,执行成功后回调函数执行以下代码
      //     this.$refs[this.formName].validate(valid => {
      //       if (valid) {
      //         this.submitForm();
      //       } else {
      //         return false;
      //       }
      //     });
      //   })
      //   .catch(action => {
      //     if (action === "cancel") {
      //       this.dialogFormVisible = false;
      //       this.form = {};
      //       this.$refs[this.formName].resetFields();
      //     }
      //   });
    },
    submitForm() {
      this.form.fjid = this.parentId;
      this.form.ssqy = this.areaId;
      this.form.cj = this.level;
      this.form.NodeType = this.nodeType;
      const user = JSON.parse(localStorage.getItem("userinfo"));
      if (user !== undefined && user !== "" && user !== null) {
        this.form.cjr = user.Id;
      }
      this.$api.post(
        "/v1/areaindex/EditIndex",
        { QYZBApiInfo: this.form, token: this.$store.state.token },
        r => {
          var resp = r.Target;
          var result = resp.Target;
          const data = {};
          data.id = result.Id;
          data.zbmc = result.ZBMC;
          data.bz = result.BZ;
          data.df = result.DF;
          data.zblx = result.ZBLX;
          data.pjfs = result.PJFS;
          data.tjlx = result.TJLX;
          data.zbmrf = result.ZBMRF;
          data.zbmf = result.ZBMF;
          data.sswd = result.SSWD;
          data.zbjgf = result.ZBJGF;
          data.zbtp = result.ZBTP;
          data.pxh = result.PXH;
          data.cjr = result.CJR;
          data.cjsj = result.CJSJ;
          data.syzt = result.SYZT;
          data.cj = result.CJ;
          data.zblxStr = result.zblxStr;
          data.pjfsStr = result.pjfsStr;
          data.tjlxStr = result.tjlxStr;
          data.fjzbmc = result.fjzbmc;
          const newNode = {
            name: result.ZBMC,
            data_nodetype: 3,
            data_id: result.Id,
            data_ordernum: result.PXH,
            data_remark: result.BZ,
            data_level: result.CJ,
            data_parentid: result.FJId,
            data_areaid: result.SSQY,
            leaf: true
          };
          if (
            this.form.id !== undefined &&
            this.form.id !== "" &&
            this.form.id !== null
          ) {
            if (resp.Code === 0) {
              this.$message({
                type: "success",
                message: "修改成功"
              });

              this.tableData.splice(
                this.tableData.findIndex(c => c.id === this.form.id),
                1,
                data
              );
              this.updateNode(newNode, 1);
            } else {
              this.$message({
                type: "success",
                message: "修改失败，请联系管理员"
              });
            }
          } else {
            if (resp.Code === 0) {
              this.$message({
                type: "success",
                message: "添加成功"
              });
              this.tableData.unshift(data);
              if (this.tableData.length > this.pagerInfo.pageSize) {
                this.tableData.pop();
              }
              this.pagerInfo.totalCount++;
              this.updateNode(newNode);
            } else {
              this.$message({
                type: "success",
                message: "添加失败，请联系管理员"
              });
            }
          }
          this.dialogFormVisible = false;
          this.form = {};
          this.$refs[this.formName].resetFields();
        }
      );
    },
    updateNode(newNode, isUpdate) {
      const key = this.$refs.Tree.$refs.tree.getCurrentKey();
      const list = this.$refs.Tree.$refs.tree.getNode(key).childNodes;
      const nodes = [];
      for (let i = 0; i < list.length; i++) {
        nodes.push(list[i].data);
      }
      if (isUpdate && isUpdate === 1) {
        nodes.splice(
          nodes.findIndex(c => c.data_id === newNode.data_id),
          1,
          newNode
        );
      } else {
        nodes.unshift(newNode);
      }
      this.$refs.Tree.$refs.tree.updateKeyChildren(key, nodes);
    },
    deleteNode(nodeId) {
      const key = this.$refs.Tree.$refs.tree.getCurrentKey();
      const list = this.$refs.Tree.$refs.tree.getNode(key).childNodes;
      if (list && list.length > 0) {
        const nodes = [];
        for (let i = 0; i < list.length; i++) {
          nodes.push(list[i].data);
        }
        nodes.splice(
          nodes.findIndex(c => c.data_id === nodeId),
          1
        );
        this.$refs.Tree.$refs.tree.updateKeyChildren(key, nodes);
      }
    },
    editItem(data) {
      this.dialogFormVisible = true;
      this.form = Object.assign({}, data);
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const ids = [];
          ids.push(data.id);
          this.$api.post(
            "/v1/areaindex/DelIndex",
            { Ids: ids, token: this.$store.state.token },
            r => {
              var resp = r.Target;
              let typeStatu = "success";
              if (resp.Code !== 0) {
                typeStatu = "error";
              } else {
                this.deleteNode(data.id);
                this.getData();
              }
              this.$message({
                type: typeStatu,
                message: resp.Message
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handleChangeTabs(indexfilter) {
      this.areaId = "000001";
      const elements = document.getElementsByClassName("cent-butt");
      if (this.indexFilter !== indexfilter) {
        if (indexfilter === 2) {
          this.indexFilter = indexfilter;
          elements[1].className =
            "cent-butt cent-butt-sty whole-col whole-col-hov";
          elements[0].className = "cent-butt whole-col-hov";
        } else {
          this.indexFilter = indexfilter;
          elements[0].className =
            "cent-butt cent-butt-sty whole-col whole-col-hov";
          elements[1].className = "cent-butt whole-col-hov";
        }

        // event.Target.className="cent-butt cent-butt-sty whole-col whole-col-hov"
        this.reFresh = false;
        this.$nextTick(() => {
          this.reFresh = true;
          this.tableData = [];
          this.pagerInfo.totalCount = 0;
        });
      }
    },
    getResultUrl(filePath) {
      this.form.ZBTP = filePath;
    }
  },
  created: function() {
    this.getDimensions();
    this.getData();
  },
  computed: {
    IsHierarchy: function() {
      return this.indexFilter === 1;
    }
  }
};
</script>
