<template>
  <div class="my-cascader" data-mask="any">
    <div
      class="top"
      :class="{ highLight1: highLight1 }"
      :ref="randomClass"
      @click="handleClick"
    >
      <div class="value-label">
        {{ checkList.map(item => item.label).join() }}
      </div>
      <div class="arrow-up" v-show="!showArrow && checkList.length === 0">
        ^
      </div>
      <div class="arrow-down" v-show="showArrow && checkList.length === 0">
        ^
      </div>
      <div
        class="clear-btn"
        v-show="checkList.length > 0"
        @click.stop="
          checkList = [];
          currentActived = [];
        "
      >
        x
      </div>
    </div>
    <div ref="cascaderBox" class="cascader-box" v-show="showCascader">
      <div class="triangleUp" v-show="triangle"></div>
      <div class="triangleDown" v-show="!triangle"></div>
      <div class="block" v-for="(item, index) in options2" :key="index">
        <template v-if="!item[0].leaf">
          <div class="cascader-item">
            <div
              class="cascader-it"
              v-for="it in item"
              :key="it.value"
              :class="{
                highlight2: currentActived.some(
                  el => el.data.value === it.value
                )
              }"
              @click="handleOption(it, index)"
            >
              {{ it.label }}
              <div class="right-arrow">></div>
            </div>
          </div>
        </template>
        <template v-else>
          <el-checkbox-group
            class="cascader-item"
            v-model="checkList"
            @change="valueChange"
          >
            <el-checkbox
              class="cascader-it"
              v-for="it in item"
              :disabled="it.disabled"
              :key="it.value"
              :label="it"
              >{{ it.label }}
            </el-checkbox>
          </el-checkbox-group>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkValue: Array,
    options: Array
  },
  data() {
    return {
      showArrow: false,
      showCascader: false,
      currentActived: [],
      typeArray: [],
      options2: [],
      triangle: false,
      highLight1: false,
      //  随机唯一ref 用于top标签 当一个页面使用了多个cascader的时候 方便每个cascader找到自己的top标签用于定位
      randomClass: ""
    };
  },
  computed: {
    checkList: {
      get() {
        return this.checkValue || [];
      },
      set(val) {
        this.$emit("update:checkValue", val);
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleTop);
    window.addEventListener("mousewheel", this.handleTop);
    window.addEventListener("click", this.isClickCascader);
    this.randomClass = "top" + new Date().getTime();
  },
  methods: {
    handleClick(e) {
      this.showArrow = !this.showArrow;
      this.showCascader = !this.showCascader;
      if (this.options2.length === 0) {
        this.getIndexData(this.options[0], indexData => {
          this.options[0].children = indexData;
          this.options2 = [this.options, indexData];
          this.currentActived = [{ index: 0, data: this.options2[0][0] }];
          this.typeArray = [0];
        });
      }
      this.handleTop();
    },
    handleOption(data, index) {
      if (data.children && data.children instanceof Array) {
        this.getIndexData(data, indexData => {
          data.children = indexData;
          this.options2 = [...this.options2.slice(0, index + 1), data.children];
          if (!this.typeArray.includes(index)) {
            this.typeArray.push(index);
            this.currentActived.push({
              index,
              data
            });
          } else {
            this.currentActived.forEach(item => {
              if (item.index === index) item.data = data;
              this.typeArray = this.typeArray.filter(el => el <= index);
            });
            this.currentActived = this.currentActived.filter(
              el => el.index <= index
            );
          }
        });
      }
    },
    valueChange(e) {
      let max = Math.max(...this.typeArray);
      let parent = this.currentActived.find(item => item.index === max).data;
      this.checkList = e.filter(ele =>
        parent.children.some(aa => aa.activityId === ele.activityId)
      );
      this.handleTop();
    },
    handleTop() {
      this.$nextTick(() => {
        let { left, top, bottom } = this.$refs[
          this.randomClass
        ].getBoundingClientRect();
        if (this.$refs.cascaderBox) {
          this.$refs.cascaderBox.style.left = left + "px";
          let bodyHeight = document.querySelector("body").offsetHeight;
          let h = this.$refs.cascaderBox.clientHeight;
          if (bodyHeight - bottom - 10 > h) {
            this.$refs.cascaderBox.style.top = bottom + 10 + "px";
            this.triangle = true;
          } else {
            this.$refs.cascaderBox.style.top = top - h - 10 + "px";
            this.triangle = false;
          }
        }
      });
    },
    // 判断点击事件是否包含了cascader 如果不是 就隐藏cascader
    isClickCascader(e) {
      if (
        e.path.some(item => item.dataset && item.dataset.mask) &&
        this.showCascader
      )
        this.highLight1 = true;
      else if (!e.path.some(item => item.dataset && item.dataset.mask)) {
        this.highLight1 = false;
        this.showCascader = false;
        this.showArrow = false;
      } else this.highLight1 = false;
    },
    // 获取指标
    getIndexData(activity, callback) {
      let { Token: token, SSZZId: SchoolId } = JSON.parse(
        localStorage.getItem("userinfo")
      );
      this.$api.post(
        "v1/evaluationobject/GetEvaluationObjectList",
        {
          EvaId: activity.Id,
          token,
          HDXS: activity.HDXS,
          SchoolId
        },
        obj => {
          if (obj.Code === 0) {
            this.$api.post(
              "/v1/evaluationobject/GetEvaluationObjectIndexByTop",
              {
                ObjectIds: obj.Target.map(item => item.Id),
                token
              },
              res => {
                console.log(res, "res");
                if (res.Code === 0) {
                  if (res.Target.length > 0) {
                    res.Target.forEach(item => {
                      item.children = [];
                      item.leaf = true;
                      item.value = item.IndexId;
                      item.label = item.IndexName;
                      item.activityId = activity.Id; // 因为不同的活动可能有一样的指标 所以要把活动id加上去 作为指标的唯一标识
                    });
                  } else {
                    res.Target = [
                      {
                        children: [],
                        leaf: true,
                        value: 0,
                        label: "空",
                        activityId: activity.Id,
                        disabled: true
                      }
                    ];
                  }

                  callback && callback(res.Target);
                } else {
                  callback &&
                    callback([
                      {
                        children: [],
                        leaf: true,
                        value: 0,
                        label: "空",
                        activityId: activity.Id,
                        disabled: true
                      }
                    ]);
                }
              }
            );
          }
        }
      );
      // 问卷
      // this.$api.post(
      //   "/v1/questionnaire/GetQuestionnaireList",
      //   {
      //     token: this.token,
      //     SchoolId: this.schoolId
      //   },
      //   r => {
      //     if (r.Code === 0) {
      //       const result = r.Target;
      //       const wjList = [];
      //       for (let i = 0; i < result.filter(c => c.SYZT === 1).length; i++) {
      //         wjList.push({
      //           value: result[i].Id,
      //           parentCode: "",
      //           label: result[i].WJMC,
      //           children: []
      //         });
      //       }
      //       this.indexData[2] = wjList;
      //       // this.indexData=this.indexData.filter(c=>c.children.length>0)
      //     }
      //   }
      // );
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleTop);
    window.removeEventListener("mousewheel", this.handleTop);
    window.removeEventListener("click", this.isClickCascader);
  }
};
</script>

<style lang="scss" scoped>
.my-cascader {
  display: inline-block;
  font-size: 14px;
  .top {
    position: relative;
    padding: 0 20px 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 185px;
    min-height: 40px;
    cursor: pointer;
    &.highLight1 {
      border: 1px solid #14d089;
    }
    .value-label {
      // padding-top: 8px;
    }
    .arrow-up,
    .arrow-down {
      color: #ccc;
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-60%) rotateZ(180deg);
      transition: all 1s;
    }
    .arrow-down {
      transform: translateY(-50%);
    }
    .clear-btn {
      width: 12px;
      height: 12px;
      text-align: center;
      line-height: 12px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      color: #ccc;
      font-size: 15px;
      border: 1px solid #ccc;
      transition: all 0.5s;
      &:hover {
        color: red;
        border: 1px solid red;
      }
    }
  }
  .cascader-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000000;
    display: flex;
    color: #606266;
    box-shadow: 1px 1px 8px rgb(218, 214, 214);
    background-color: #fff;
    .block {
      height: 250px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0.5px;
      }
      min-width: 100px;
      background-color: #ffffff;
      border-right: 1px solid #ccc;
      .cascader-item {
        min-width: 80px;
        background-color: #ffffff;
        padding: 5px 8px;
        .cascader-it {
          display: flex;
          justify-content: space-between;
          padding: 2px 0;
          cursor: pointer;
          &.highlight2 {
            color: #14d089;
          }
          .right-arrow {
            margin-left: 5px;
          }
        }
        .el-checkbox {
          display: block;
        }
      }
    }
    .block:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .block:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: none;
    }
    .triangleUp {
      position: absolute;
      top: -10px;
      left: 10px;
      width: 0;
      height: 0;
      border-left: 5px solid #f5f4f4;
      border-right: 5px solid #f5f4f4;
      border-bottom: 10px solid #ffffff;
    }
    .triangleDown {
      position: absolute;
      left: 10px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-left: 5px solid #f5f4f4;
      border-right: 5px solid #f5f4f4;
      border-top: 12px solid #ffffff;
    }
  }
}
</style>
