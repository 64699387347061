<template>
  <div class="cont-whole-box" style="text-align: center;">
    <br />
    <br />
    <br />
    <el-form
      :model="JSXXBInfo"
      :rules="rules"
      ref="sbmitfrom"
      label-width="80px"
      style="width: 500px; display: inline-block;"
    >
      <el-form-item label="姓名" prop="XM">{{ JSXXBInfo.XM }}</el-form-item>
      <el-form-item label="性别" prop="XBM">
        <span v-if="JSXXBInfo.XBM == 1">男</span>
        <span else>女</span>
      </el-form-item>

      <el-form-item label="联系电话" prop="LXDH">
        {{ JSXXBInfo.LXDH }}
      </el-form-item>
      <el-form-item label="身份证号">{{ JSXXBInfo.SFZJH }}</el-form-item>
      <el-form-item label="邮      箱">{{ JSXXBInfo.DZYX }}</el-form-item>
      <el-form-item label="来校时间">{{ JSXXBInfo.LXSJ }}</el-form-item>
      <el-form-item label="教师岗位">{{ JSXXBInfo.JSGW }}</el-form-item>
      <el-form-item label="所属学段">
       {{ JSXXBInfo.SSXD === 1 ? "小学段" : (JSXXBInfo.SSXD === 2 ? "中学段" : (JSXXBInfo.SSXD === 3 ? "小学段|中学段" : "--")) }}
      </el-form-item>
      <el-form-item label="最高学历">
        <span v-if="JSXXBInfo.ZGXW == 1">大专</span>
        <span v-else-if="JSXXBInfo.ZGXW == 2">本科</span>
        <span v-else-if="JSXXBInfo.ZGXW == 3">研究生</span>
        <span v-else-if="JSXXBInfo.ZGXW == 4">博士</span>
        <span v-else-if="JSXXBInfo.ZGXW == 5">博士后</span>
      </el-form-item>

      <el-form-item label="照片">
        <el-image
          :src="JSXXBInfo.ZP"
          style="
                vertical-align: middle;
                width: 150px;
                height: 150px;
                margin-left: -230px;
              "
        ></el-image
        >&nbsp;&nbsp;
      </el-form-item>
      <br />
      <br />
      <br />
      <br />
      <el-form-item>
        <el-button @click="canceladd">返回</el-button>
      </el-form-item>
    </el-form>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: "TeacherDetail",
  data() {
    return {
      token: this.$store.state.token,

      JSXXBInfo: {
        Id: "",
        XM: "",
        XBM: 0,
        LXDH: "",
        SFZJH: "",
        DZYX: "",
        LXSJ: "2019-09-09 01:29:45",
        JSGW: "",
        ZGXW: 1,
        ZP: "",
        SSXX: "",
        SYZT: 0,
        SFSC: 0,
        BH: "",
        SSXD:0
      }
    };
  },
  created: function() {
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    // this.JSXXBInfo.cjr = userinfo.id;
    this.JSXXBInfo.SSXX = userinfo.SSZZId;
    this.fileServerApiPath = userinfo.fileServerApiPath;
    this.webFilePath = userinfo.webFilePath;

    if (this.$route.params.JSXXBInfo !== null) {
      this.JSXXBInfo = this.$route.params.JSXXBInfo;
      var t = new Date(this.JSXXBInfo.LXSJ.replace(/-/g, "/"));
      this.JSXXBInfo.LXSJ =
        t.getFullYear() + "-" + (t.getMonth() + 1) + "-" + t.getDate();
    }
  },
  methods: {
    // 取消
    canceladd: function() {
      this.$router.push({ path: "TeacherList" });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  text-align: left;
}
.el-image {
  padding-left: 230px;
}
.el-form-item__content {
  padding-left: 30px;
}
</style>
