<template>
  <!-- 评语设置 -->
  <el-dialog
    class="reviews-dialog"
    title="智能评语设置"
    :visible.sync="show"
    width="80%"
    :before-close="handleClose"
  >
    <!--评语 -->
    <div class="reviews-set">
      <div class="reviews-item-block">
        <div class="reviews-item" v-for="item in reviewsList" :key="item.id">
          <el-checkbox v-model="item.checked">{{ item.label }}</el-checkbox>
          <el-input
            size="small"
            v-model="item.text1"
            class="text1"
            style="width:160px"
            :placeholder="`你在本学期的${item.label}表现`"
          />
          <el-select
            size="small"
            style="margin:0 10px;width:150px"
            v-model="item.percent2level"
            placeholder="请选择"
          >
            <el-option
              v-for="item in percent2level"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            size="small"
            v-model="item.text2"
            placeholder="打败了"
            class="text2"
            style="width:100px"
          />
          <el-input
            size="small"
            readonly
            v-model="item.text3"
            placeholder="××"
            class="text3"
            style="width:80px;margin:0 10px"
          />
          <el-input
            size="small"
            v-model="item.text4"
            placeholder="的同学"
            class="text4"
            style="width:100px"
          />
        </div>
      </div>
    </div>

    <!-- 课堂表现 -->
    <div class="classroom-performance">
      <div class="title">
        <span>课堂表现</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 课堂表扬 -->
      <div class="praise">
        <div class="intro">
          <el-checkbox v-model="checked"
            >课堂表现表扬项：(取表扬次数最多的前几项进行评语)</el-checkbox
          >
          <el-input-number
            v-model="num"
            :min="1"
            :max="10"
            :step="0"
            label="描述文字"
            size="small"
          ></el-input-number
          >项
        </div>
        <div class="content">
          <el-input
            v-model="input"
            placeholder="孩子在课堂上多次得到"
            size="small"
          ></el-input>
          <div>xxx xxx</div>
          <el-input
            v-model="input"
            placeholder="方面的表扬，老师给你大大的赞！"
            size="small"
          ></el-input>
        </div>
      </div>

      <!-- 课堂批评 -->
      <div class="criticize">
        <div class="intro">
          <el-checkbox v-model="checked"
            >课堂表现批评项：(取批评次数最多的前几项进行评语)</el-checkbox
          >
          <el-input-number
            v-model="num"
            :min="1"
            :max="10"
            :step="0"
            label="描述文字"
            size="small"
          ></el-input-number
          >项
        </div>
        <div class="content">
          <el-input
            v-model="input"
            placeholder="孩子在课堂上多次得到"
            size="small"
          ></el-input>
          <div>xxx xxx</div>
          <el-input
            v-model="input"
            placeholder="方面的表扬，老师给你大大的赞！"
            size="small"
          ></el-input>
        </div>
      </div>
    </div>

    <!-- 校园有礼 -->
    <div class="school-politeness">
      <div class="title">
        <el-checkbox v-model="checked">校园有礼</el-checkbox>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="content">
        <el-input v-model="input" placeholder="获得了" size="small"></el-input>
        <div>xxx xxx</div>

        <el-input v-model="input" placeholder="等" size="small"></el-input>
        <div>XXX</div>
        <el-input
          v-model="input"
          placeholder="次校园有礼加分"
          size="small"
        ></el-input>
      </div>
    </div>

    <!-- 课辅活动 -->
    <div class="kewai-acitvity">
      <div class="title">
        <el-checkbox v-model="checked">课辅活动项</el-checkbox>
      </div>
      <div class="content">
        <el-input
          v-model="input"
          placeholder="积极残疾学校的课辅活动课程"
          size="small"
        ></el-input>
        <div>xxx xxx</div>
        <el-input
          v-model="input"
          placeholder="并得到了"
          size="small"
        ></el-input>
        <div>XXX</div>
        <el-input
          v-model="input"
          placeholder="方面的表扬"
          size="small"
        ></el-input>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      reviewsList: [
        {
          id: 1,
          checked: false,
          percent2level: "",
          label: "综合素养",
          text1: ``,
          text2: "打败了",
          text3: "××",
          text4: "的同学"
        },
        {
          id: 2,
          checked: false,
          percent2level: "",
          label: "德",
          text1: ``,
          text2: "打败了",
          text3: "××",
          text4: "的同学"
        },
        {
          id: 3,
          checked: false,
          percent2level: "",
          label: "智",
          text1: ``,
          text2: "打败了",
          text3: "××",
          text4: "的同学"
        }
      ],
      percent2level: [
        {
          label: "百分比转等级1",
          value: 1
        },
        {
          label: "百分比转等级2",
          value: 2
        }
      ],
      // 课堂表现
      classroomPerformance: {
        acitvity: {},
        praise: {
          enabled: false,
          num: "",
          text1: "",
          data: [],
          text2: ""
        }
      }
    };
  },
  methods: {
    handleClose() {
      this.$emit("update:show", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.reviews-dialog {
  ::v-deep .el-dialog {
    min-width: 940px;
  }
  .reviews-set {
    .reviews-item-block {
      .reviews-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .el-checkbox {
          width: 100px;
        }
      }
    }
  }
}
</style>
