<template>
  <el-table
    :data="tableData"
    class="jf-table"
    border
    tooltip-effect="dark"
    style="width: 100%;"
  >
    <el-table-column fixed prop="BBDM" label="报表代码"></el-table-column>
    <el-table-column prop="BBMC" label="报表名称"></el-table-column>
    <el-table-column label="报表类型">
      <template slot-scope="scope">
        {{
          reportType
            .filter(c => c.Key === scope.row.BBLX)
            .map(c => c.Value)
            .join("")
        }}
      </template>
    </el-table-column>
    <el-table-column
      prop="EvaluationMethod"
      label="支撑评价方式"
    ></el-table-column>
    <el-table-column label="状态">
      <template slot-scope="scope">{{
        scope.row.SYZT === 1 ? "正常" : "停用"
      }}</template>
    </el-table-column>
    <el-table-column fixed="right" label="操作" width="250">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="editItem(scope.row)"
          >编辑</el-button
        >
        <el-button @click="updateItem(scope.row)" type="text" size="small">
          {{ scope.row.SYZT === 1 ? "停用" : "启用" }}
        </el-button>
        <el-button
          @click="delItem(scope.row)"
          type="text"
          size="small"
          class="tab-wz-butta c-red"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    reportType: {
      type: Array,
      default: function() {
        return [];
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "/v1/reporttemplate/DelReportTemplate",
            {
              Id: data.Id,
              token: this.$store.state.token,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              let typeStatu = "success";
              if (r.Code !== 0) {
                typeStatu = "error";
              }
              this.$message({
                type: typeStatu,
                message: r.Message
              });
              this.$emit("delItem", data);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    updateItem(data) {
      let Statu = 1;
      if (data.SYZT === 1) {
        Statu = 2;
      }
      this.$api.post(
        "/v1/reporttemplate/UpdateReportTemplate",
        {
          Id: data.Id,
          Statu: Statu,
          token: this.$store.state.token,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          let typeStatu = "success";
          if (r.Code !== 0) {
            typeStatu = "error";
          }
          this.$message({
            type: typeStatu,
            message: r.Message
          });
          data.SYZT = Statu;
          this.$emit("updateItem", data);
        }
      );
    }
  }
};
</script>
