<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="51%"
    height="100%"
    center
    :before-close="closeDialog"
  >
    <div
      class="popv-cent"
      style="padding: 10px; position: relative; height: 516px;"
    >
      <div class="popo-xk-cent">
        <el-checkbox-group
          v-model="checkedIds"
          @change="handleCheckedActivities"
        >
          <div class="disilb-box" v-for="item in activitysData" :key="item.Id">
            <el-checkbox
              v-if="
                ((item.EvaluationActivityType === 1 &&
                  disableData.findIndex(c => c.HDId === item.Id) !== -1) ||
                  (item.EvaluationActivityType !== 1 &&
                    disableData.findIndex(
                      c => c.HDLX === item.EvaluationActivityType
                    ) !== -1)) &&
                  item.Id !== currentActivity.HDId
              "
              disabled
              class="tablil-xz-name epp-ov"
              :label="item.Id"
              >{{ item.Name }}</el-checkbox
            >
            <el-checkbox
              v-else
              class="tablil-xz-name epp-ov"
              :label="item.Id"
              >{{ item.Name }}</el-checkbox
            >
          </div>
        </el-checkbox-group>
      </div>
      <div class="popo-xk-centa">
        <div class="operate-box-supple">
          个人得分时小组的得分
          <el-input
            style="width: 50px;"
            class="popv-inpt jf-form"
            placeholder="请输入"
            size="small"
            v-model="GRYXXZ"
          ></el-input
          >%
        </div>
        <div class="operate-box-supple">
          小组得分时个人的得分
          <el-input
            style="width: 50px;"
            class="popv-inpt jf-form"
            placeholder="请输入"
            size="small"
            v-model="XZYXGR"
          ></el-input
          >%
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      checkedIds: [],
      checkedActivities: [],
      XZYXGR: "",
      GRYXXZ: "",
      currentId: "",
      isEdit: false,
      disableData: []
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    activitysData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    disablePropData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currentActivity: {
      type: Object,
      default: function() {
        return {};
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    init() {
      this.checkedIds = [];
      this.checkedActivities = [];
      this.XZYXGR = "";
      this.GRYXXZ = "";
      for (let i = 0; i < this.disableData.length; i++) {
        const index = this.activitysData.findIndex(
          c =>
            (c.Id === this.disableData[i].HDId &&
              c.EvaluationActivityType === 1) ||
            (c.EvaluationActivityType !== 1 &&
              c.EvaluationActivityType === this.disableData[i].HDLX)
        );
        if (index !== -1) {
          this.checkedIds.push(this.activitysData[index].Id);
        }
      }
      if (Object.keys(this.currentActivity).length !== 0) {
        let info = {};
        if (this.currentActivity.HDLX === 1) {
          info = this.activitysData.find(
            c => c.Id === this.currentActivity.HDId
          );
        } else {
          info = this.activitysData.find(
            c => c.EvaluationActivityType === this.currentActivity.HDLX
          );
        }
        if (info && info.Id) {
          this.checkedActivities.push({
            Id: this.currentActivity.Id,
            GRYXXZ: "",
            HDId: this.currentActivity.HDId,
            HDLX: this.currentActivity.HDLX,
            SSXX: this.user.SSZZId,
            XZYXGR: ""
          });
          if (this.checkedIds.findIndex(c => c === info.Id) === -1) {
            this.checkedIds.push(info.Id);
          }
          this.XZYXGR = this.currentActivity.XZYXGR;
          this.GRYXXZ = this.currentActivity.GRYXXZ;
        }
      }
      this.disableData = this.disablePropData.filter(
        c => !this.checkedActivities.map(c => c.HDId).includes(c.HDId)
      );
      // for(let i=0;i<this.checkedActivities.length;i++){
      //   this.disableData.splice(this.disableData.findIndex(c=>c.HDId===this.checkedActivities[i].HDId),1)
      // }
      this.isEdit = false;
      if (this.checkedActivities.length > 0 && this.checkedActivities[0].Id) {
        this.isEdit = true;
        this.currentId = this.checkedActivities[0].Id;
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    save() {
      for (let i = 0; i < this.checkedActivities.length; i++) {
        this.$set(this.checkedActivities[i], "XZYXGR", this.XZYXGR);
        this.$set(this.checkedActivities[i], "GRYXXZ", this.GRYXXZ);
      }
      this.$api.post(
        "/v1/growthsystem/EditPersonAndGroupSet",
        {
          token: this.$store.state.token,
          XZYGRYXSZList: this.checkedActivities,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.$emit("closeDialog", true);
          }
        }
      );
    },
    handleCheckedActivities() {
      if (this.isEdit && this.checkedActivities.length > 0) {
        this.checkedIds.splice(
          this.checkedIds.findIndex(c => c === this.checkedActivities[0].HDId),
          1
        );
      }
      this.$set(this, "checkedActivities", []);
      // this.checkedActivities=[]
      for (let i = 0; i < this.checkedIds.length; i++) {
        const info = this.activitysData.find(c => c.Id === this.checkedIds[i]);
        if (
          info &&
          ((info.EvaluationActivityType === 1 &&
            this.disableData.findIndex(c => c.HDId === info.Id) === -1) ||
            (info.EvaluationActivityType !== 1 &&
              this.disableData.findIndex(
                c => c.HDLX === info.EvaluationActivityType
              ) === -1))
        ) {
          const obj = {
            HDId: info.EvaluationActivityType === 1 ? info.Id : null,
            HDLX: info.EvaluationActivityType,
            XZYXGR: this.XZYXGR,
            GRYXXZ: this.GRYXXZ,
            SSXX: this.user.SSZZId
          };
          if (this.currentId && this.isEdit) {
            obj.Id = this.currentId;
          }
          this.checkedActivities.push(obj);
        }
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        // 隐藏
      }
    }
  }
};
</script>
