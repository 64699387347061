<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div
              class="table-er-box"
              style="
                font-size: 14px;
                margin-bottom: 10px;
                margin-right: 10px;
                display: block;
              "
            >
              <el-button type="success" @click="handleaddnotice()"
                >新增公告</el-button
              >
            </div>
            <div class="table-er-box">
              <div class="table-er-butt">
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择开始日期"
                  v-model="kssj"
                ></el-date-picker>
                <span
                  class="ts-span-sm"
                  style="margin-left: 2px; margin-right: 2px;"
                  >—</span
                >
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择结束日期"
                  v-model="jssj"
                ></el-date-picker>
                <el-radio-group
                  v-model="rqlx"
                  @change="rqlxchange()"
                  style="margin-left: 10px; margin-top: -10px;"
                >
                  <el-radio-button :label="1">本期</el-radio-button>
                  <el-radio-button :label="2">本周</el-radio-button>
                </el-radio-group>
              </div>
              <div
                style="
                  font-size: 14px;
                  margin-bottom: 10px;
                  margin-right: 10px;
                  display: inline-block;
                  float: right;
                "
              >
                <el-input
                  v-model="searchname"
                  placeholder="请输入关键字"
                  maxlength="20"
                  prefix-icon="el-icon-search"
                ></el-input>
                <span
                  style="color: #14d089; margin-left: 5px; cursor: pointer;"
                  @click="handleSearch()"
                  >搜索</span
                >
              </div>
            </div>
            <el-table
              :show-header="false"
              :data="noticelist"
              highlight-current-row
              style="width: 100%;"
              :border="false"
            >
              <el-table-column>
                <template slot-scope="scope">
                  <i v-if="scope.row.XXFL == '1'" class="el-icon-message"></i>
                  <i v-else class="el-icon-edit-outline"></i>
                </template>
              </el-table-column>
              <el-table-column property="BT">
                <template slot-scope="scope">
                  <span @click="handleDetail(scope.row.Id, scope.row.XXFL)">
                    {{ scope.row.BT }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.ReadStatus == '1'"
                    style="color: #14d089;"
                    >已发送</span
                  >
                  <span v-else style="color: red;">未发送</span>
                </template>
              </el-table-column>
              <el-table-column property="CJSJ">
                <template slot-scope="scope">
                  {{ scope.row.CJSJ | DateFormat("yyyy-MM-dd") }}
                </template>
              </el-table-column>
              <el-table-column align="center" style="width: 200px;">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="color: blue;"
                    @click="handleEdit(scope.row.Id, scope.row.XXFL)"
                    >编辑</el-button
                  >
                  <el-button
                    style="color: red;"
                    type="text"
                    @click="handleDelete(scope.row.Id)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagin-box">
              <div class="pagin-wz">
                <Pager
                  :pagerInfo="pagerInfo"
                  @changeIndex="handleCurrentChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.item {
  margin-top: 5px;
}

.tab-heng-box table tbody tr td,
.tab-heng-box table thead tr td {
  border: none;
}
</style>
<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.userid = user.Id;
      this.yhlb = user.UserType;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    handleDetail: function(id) {
      this.editid = id;
      this.$router.push({
        name: "noticedetail",
        params: { ggid: this.editid }
      });
    },
    rqlxchange: function() {
      this.pagerInfo.pageIndex = 1;
      this.kssj = "";
      this.jssj = "";
      this.loaddata();
    },
    handleDelete: function(id) {
      this.$confirm("确定要删除该公告吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "v1/notice/Delete",
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              Id: id,
              YHId: this.userid,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "删除成功!"
                });
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "删除失败!"
                });
                this.loaddata();
              }
            }
          );
        })
        .catch(() => {});
    },
    handleEdit: function(id) {
      this.editid = id;
      this.$router.push({ name: "editnotice", params: { ggid: this.editid } });
    },
    handleaddnotice: function() {
      this.editid = "";
      this.$router.push({ name: "editnotice" });
    },
    handleSearch: function() {
      this.rqlx = 0;
      this.pagerInfo.pageIndex = 1;
      this.loaddata();
    },
    loaddata: function() {
      this.$api.post(
        "v1/notice/GetMyList",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          YHId: this.userid,
          RQLX: this.rqlx,
          XXFL: 1,
          SearchName: this.searchname,
          KSSJ: this.kssj,
          JSSJ: this.jssj,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.noticelist = [];
            this.noticelist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    }
  },
  data() {
    return {
      isLogin: true,
      noticelist: [],
      ssxx: "",
      ssxq: "",
      editid: "",
      searchname: "",
      userid: "",
      kssj: "",
      jssj: "",
      token: "",
      rqlx: 0,
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      }
    };
  }
};
</script>
