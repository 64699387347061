<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + exportType">
			<div :class="'basetitle' + exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + exportType">
				<div :class="'detailinfo' + exportType">
					<div :class="'list' + exportType">
						<div :class="'list-item' + exportType" v-for="item in list" :key="item.Id">
							<div :class="'name' + exportType">
								{{ item.name }}
							</div>
							<div :class="'charts' + exportType">
								<div :class="'title' + exportType">完成率</div>
								<el-progress :width="exportType==1?200:150" type="circle" :percentage="item.value"
									:format="handlePercent"></el-progress>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		List
	} from "echarts";
	import "./template.css";
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "作业完成情况",
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					ZFS: "",
				},
				activitylist: [],
				// --------------------------------------------
				list: [
					// {
					// 	Id: "我是课程1",
					// 	name: "课程1",
					// 	value: 100
					// },
					// {
					// 	Id: "我是课程2",
					// 	name: "课程2",
					// 	value: 100
					// }
				],
				wdid: "",
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			//地址栏传入维度ID即可展示维度相关数据，不传或为空及展示所有数据，如有需要可自行修改传值方式
			this.wdid = this.$route.query.wdid;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		mounted() {
			// this.init();
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: "",
					};
				},
			},
		},
		methods: {
			handlePercent(val) {
				return `${val}%`;
			},
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx,
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				// this.xsinfo.SSNJ = xsdata.SSNJ;
				// this.xsinfo.NJ = xsdata.NJ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				if (xsdata.ZYWCQK) {
					var templatedata = JSON.parse(xsdata.ZYWCQK);
					// console.log(templatedata, "templatedata");
					var template = JSON.parse(localStorage.getItem("schooltemplate_" + this.xqid)).KCLB;
					// console.log(template, 'template');
					let wbhd_temp;
					this.list = [];
					if (this.wdid) {
						wbhd_temp = templatedata.filter((o) => o.wdid == this.wdid);
					} else {
						wbhd_temp = templatedata;
					}
					if (wbhd_temp && template) {
						wbhd_temp.forEach((item) => {
							let kc_template = template.find((o) => o.KCID == item[0].KCId);
							if (kc_template) {
								if (this.list.length < 3) {
									var model = {
										id: item[0].KCId,
										name: kc_template.KCMC,
										value: item[0].WCL,
									};
									// console.log(model);
									this.list.push(model);
								}
							}
						});
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 120px;
		height: 120px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 20px;
		border-radius: 50%;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 20px;
		border-radius: 50%;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/rectangle_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 20px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/rectangle_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 720px;
		width: 100%;
	}

	.xsdiv1 {
		height: 170px;
		width: 400px;
	}

	.xsdiv2 {
		height: 120px;
		width: 430px;
	}

	.detailinfo1 {
		border: 0px solid #cbe0fd;
		border-radius: 25px;
		width: 800px;
		height: 980px;
		margin-left: 20px;
	}

	.detailinfo2 {
		// background: #2caeff;
		border: 0px solid #cbe0fd;
		border-radius: 15px;
		width: 570px;
		height: 712px;
		margin-left: 10px;
	}

	.xsinfodiv1 {
		margin-top: 40px;
		margin-left: 20px;
		float: left;
	}

	.xsinfodiv2 {
		margin-top: 30px;
		margin-left: 10px;
		float: left;
	}

	.xsinfotitle1 {
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 1.5;
	}

	.xsinfotitle2 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}

	.xsinfoclass1 {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #000000;
		line-height: 1.5;
		opacity: 0.8;
	}

	.xsinfoclass2 {
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #000000;
		line-height: 20px;
		opacity: 0.8;
		margin-top: -10px;
	}

	.indexscore1 {
		width: 30px;
		height: 30px;
		line-height: 30px;
		margin: 0 0 0 5px;
	}

	.indexscore2 {
		width: 25px;
		height: 25px;
		line-height: 25px;
		margin: 0 0 0 3px;
	}

	.activityname1 {
		width: 400px;
		height: 40px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 50px;
	}

	.activityname2 {
		width: 300px;
		height: 35px;
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 40px;
	}

	.activityscore1 {
		width: 100px;
		height: 40px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		color: #333333;
		line-height: 40px;
	}

	.activityscore2 {
		width: 100px;
		height: 40px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		color: #333333;
		line-height: 40px;
	}

	.indexname1 {
		width: 120px;
		height: 25px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 350;
		color: #333333;
		line-height: 25px;
		margin-top: 3px;
	}

	.indexname2 {
		width: 100px;
		height: 20px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 350;
		color: #333333;
		line-height: 20px;
		margin-top: 3px;
	}

	.performancedetaildiv1 {
		width: 760px;
		margin: 0 10px 10px 10px;
		max-height: 303px;
		background: #ffffff;
		border-radius: 20px;
		padding: 10px 10px 0 10px;
	}

	.performancedetaildiv2 {
		width: 530px;
		margin: 0 10px 10px 10px;
		max-height: 210px;
		background: #ffffff;
		border-radius: 15px;
		padding: 10px 10px 0 10px;
	}

	.totalscore1 {
		width: 483px;
		text-align: center;
		height: 249px;
		margin-top: -100px;
		padding-top: 5px;
		background-image: url("../../../../style/growth/score_back2.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-left: 165px;
		margin-bottom: -10px;
	}

	.totalscore2 {
		width: 362px;
		text-align: center;
		height: 138px;
		margin-top: -65px;
		padding-top: 5px;
		background-image: url("../../../../style/growth/score_back2.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-left: 115px;
		margin-bottom: -5px;
	}

	.scoretext1 {
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #22a049;
		margin-top: 100px;
		margin-left: -13px;
	}

	.scoretext2 {
		font-size: 33px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #22a049;
		margin-top: 46px;
		margin-left: -12px;
	}

	.scoredesc1 {
		width: 100%;
		margin-left: -9px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #666666;
	}

	.scoredesc2 {
		width: 100%;
		margin-left: -6px;
		font-size: 10px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #666666;
	}

	.activitydesc1 {
		width: 770px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		padding: 10px 5px;
		color: #080808;
		line-height: 30px;
	}

	.activitydesc2 {
		width: 540px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		padding: 10px 5px;
		color: #080808;
		line-height: 20px;
	}

	.attachimage1 {
		width: 246.66px;
		height: 185px;
		margin: 10px 5px 0 5px;
	}

	.attachimage2 {
		width: 166.66px;
		height: 120px;
		margin: 10px 5px 0 5px;
	}

	.pjricon1 {
		width: 50px;
		height: 50px;
		border-radius: 15px;
	}

	.pjricon2 {
		width: 40px;
		height: 40px;
		border-radius: 10px;
	}

	.list1 {
		.list-item1 {
			padding: 10px;

			.name1 {
				padding-left: 10px;
				border-left: 2px solid red;
				font-size: 16px;
			}

			.charts1 {
				position: relative;
				margin: 10px auto;
				width: 200px;
				height: 200px;

				.title1 {
					position: absolute;
					left: 50%;
					bottom: 50px;
					transform: translateX(-50%);
					font-size: 20px;
				}
			}
		}
	}

	.list2 {
		.list-item2 {
			padding: 10px;

			.name2 {
				padding-left: 10px;
				border-left: 2px solid red;
				font-size: 16px;
			}

			.charts2 {
				position: relative;
				margin: 10px auto;
				width: 150px;
				height: 150px;

				.title2 {
					position: absolute;
					left: 50%;
					bottom: 40px;
					transform: translateX(-50%);
					font-size: 20px;
				}
			}
		}
	}
</style>