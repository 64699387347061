<template>
  <!-- 发展中心 -->
  <div class="center-study-report">
    <!-- 年级以及学科等筛选 -->
    <div class="filter-container">
      <!-- 学期筛选 -->
      <div style="display: flex;align-items:center">
        <!-- @change="handleSelectChange" -->
        <div style="width: 100px;">报表名称：</div>
        <el-select
          v-model="bbid"
          size="small"
          placeholder="请选择"
          @change="handleSelectChange"
        >
          <el-option
            v-for="item in bbList"
            :key="item.Id"
            :label="item.JHMC"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 姓名搜索 -->
      <div class="search">
        <el-input
          v-model="searchname"
          placeholder="请输入关键字"
          size="small"
          maxlength="20"
          prefix-icon="el-icon-search"
        />
        <el-button size="small" type="primary" @click="handleSearch"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="btn-box">
      <el-button size="small" type="primary" @click="opendialog"
        >生成新报表</el-button
      >
      <el-button size="small" type="primary" @click="delItem"
        >删除报表</el-button
      >
      <el-button size="small" type="primary" @click="downPFB"
        >批量下载个人评分表</el-button
      >
      <el-button type="primary" size="small" @click="lookKH" :disabled="!bbid"
        >查看考核结果名单</el-button
      >
    </div>
    <!-- 表格内容 -->
    <Table
      :tableData="tableData"
      @handleSelectionChange="handleSelectionChange"
    />
    <!-- 弹窗 -->
    <Dialog
      v-model="addDialog"
      :termList="termList"
      @canceldialog="addDialog = false"
      @sumbitForm="sumbitForm"
    ></Dialog>
    <delDialog
      v-model="delDialog"
      @refresh="refresh"
      :bbList="bbList"
      @canceldialog="delDialog = false"
      @sumbitForm="sumbitForm"
    ></delDialog>
    <multiplePdf
      class="pdf1"
      :sumObj="sumObj"
      :tableRow="tableRow"
      :pageSubtitle="pageSubtitle"
    ></multiplePdf>
    <multiplePdf2
      class="pdf2"
      :pageSubtitle="pageSubtitle"
      :MBGLID="MBGLID"
      v-model="dialog2"
      @canceldialog="dialog2 = false"
    ></multiplePdf2>
    <div v-show="downLoadPercent.show" class="downloadMask">
      <div class="box">
        <el-progress :percentage="downLoadPercent.value"></el-progress>
      </div>
      <div class="closeBtn" @click="downLoadPercent.show = false">
        <el-button type="info" icon="el-icon-circle-close" circle></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import multiplePdf from "./multiplePdf.vue";
import multiplePdf2 from "./Dialog2.vue";
import Table from "./Table.vue";
import Dialog from "./addDialog.vue";
import delDialog from "./delDialog.vue";

import JSZip from "jszip";
import FileSaver from "file-saver";
export default {
  name: "developmentitemList",
  components: { Table, Dialog, delDialog, multiplePdf, multiplePdf2 },
  data() {
    return {
      bbList: [],
      bbid: "",
      searchname: "",
      addDialog: false,
      delDialog: false,
      dialog2: false,
      user: "",
      tableData: [], //Table数据
      termList: [],
      baseList: [], //Table基础数据
      selectedColumns: [], //勾选的列
      MBGLID: this.$route.query.MBGLID,
      tableRow: {},
      currentCount: 0,
      totalCount: 0,
      pageSubtitle: "",
      sumObj: {}, //传递给下载个人评分表的总和数据
      downLoadPercent: {
        show: false,
        value: 0,
      },
    };
  },

  methods: {
    //选择报表名称的方法
    handleSelectChange(data) {
      this.getTableData();
      this.getpageSubtitle();
    },
    //查看考核结果名单
    lookKH() {
      this.dialog2 = true;
    },
    handleSelectionChange(selection) {
      this.selectedColumns = selection;
    },
    //获取学期列表
    getTermList() {
      return new Promise((resolve, rej) => {
        this.$api.post(
          "v1/accountmanage/GetTerm",
          {
            SchoolId: this.user.SSZZId,
            token: this.user.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.termList = res.Target || [];
              localStorage.setItem(
                "termList",
                JSON.stringify(res.Target || [])
              );
              resolve();
            } else {
              this.$message.error(res.Message);
            }
          }
        );
      });
    },
    // 下载
    async downPFB() {
      this.downLoadPercent.show = true;
      let xsarrtemp = this.selectedColumns;
      this.zips = new JSZip();
      this.totalCount = xsarrtemp.length;
      for (let index = 0; index < xsarrtemp.length; index++) {
        this.tableRow = xsarrtemp[index];
        await this.handlePdf(this.tableRow.KHRXM);
      }
    },
    //获取表格的数据（后台生成）
    getTableData() {
      this.$api.post(
        "/v1/mbbb/GetZYDDBBList",
        {
          MBGLID: this.MBGLID,
          SCJHID: this.bbid,
          JSMC: "",
          token: this.user.Token,
        },
        (r) => {
          if (r.Code === 0) {
            // this.tableData
            let list = r.Target || [];
            let b = "";
            this.baseList = list.map((item) => {
              let a = JSON.parse(item.JGJSON);
              b = a.find((i) => i.khxm == "合计");
              return { ...item, ...(b || {}), JG: a };
            });
            this.tableData = this.baseList.sort((a, b) => b.zhdf - a.zhdf);

            this.tableRow = this.tableData[0];

            console.log(r.Target, "获取表格的数据（后台生成）");
          }
        }
      );
    },
    //重新获取数据
    refresh() {
      this.getMBSCJHBList();
    },
    //搜索
    handleSearch() {
      if (this.searchname) {
        this.tableData = this.baseList.filter((i) =>
          i.KHRXM.includes(this.searchname)
        );
      } else {
        this.tableData = this.baseList;
      }
    },
    //新增弹窗按钮
    opendialog() {
      this.dialogTitle = "新增";
      this.addDialog = true;
    },
    canceldialog() {
      this.addDialog = false;
    },
    //生成新报表接口
    sumbitForm(data) {
      data.MBGLID = this.MBGLID;
      console.log(data, "data");
      this.addDialog = false;
      this.$api.post(
        "/v1/mbbb/AddMBSCJHB",
        {
          MBSCJHBInfo: data,
          token: this.user.Token,
        },
        (res) => {
          if (res.Code === 0) {
            console.log(res.Target, "获取报表名称列表");
            this.$message({
              type: "success",
              message: "新增成功",
              showClose: true,
            });
            this.getMBSCJHBList();
          } else {
            this.$message.error(res.Message);
          }
        }
      );
    },
    //删除报表按钮
    delItem() {
      this.dialogTitle = "删除";
      this.delDialog = true;
    },
    //获取报表名称列表
    getMBSCJHBList() {
      return new Promise((resolve, rej) => {
        this.$api.post(
          "/v1/mbbb/GetMBSCJHBList",
          {
            MBGLID: this.MBGLID,
            token: this.user.Token,
          },
          (res) => {
            if (res.Code === 0) {
              let arr = res.Target || [];
              this.bbList = arr.reduce((pre, cur) => {
                let terms = [];
                let a = cur.XQJH.split(",");

                this.termList.forEach((item) => {
                  terms.push({
                    Id: item.Id,
                    Name: item.Name,
                    checked: false,
                  });
                });
                if (a) {
                  terms.forEach((i) => {
                    i.checked = !!a.find((t) => t == i.Id);
                  });
                }
                pre.push({
                  JHMC: cur.JHMC,
                  SFWC: cur.SFWC,
                  Id: cur.Id,
                  terms,
                });
                return pre;
              }, []);
              if (this.bbList && this.bbList.length > 0) {
                this.bbid = this.bbList[0].Id;
                this.getTableData();
                this.getpageSubtitle();
              }
              resolve();
            } else {
              this.$message.error(res.Message);
            }
          }
        );
      });
    },

    handlePdf(title) {
      return new Promise((resolve, reject) => {
        try {
          this.$nextTick(async () => {
            let p = await this.getPdfFromHtml2("mutilplePdf1", title);
            console.log(p, "paa");
            await this.zips.file(
              `${title}-个人评分表.pdf`,
              p.PDF.output("blob")
            );
            this.currentCount++;

            this.downLoadPercent.value = Number(
              ((this.currentCount / this.totalCount) * 100).toFixed(2)
            );
            resolve();
          });
        } catch (err) {
          reject(err);
        }
      });
    },

    //获取副标题
    getpageSubtitle() {
      this.$api.post(
        "/v1/mbbb/GetMBSCJHBById",
        {
          Id: this.bbid,
          token: this.user.Token,
        },
        (r) => {
          if (r.Code === 0) {
            this.pageSubtitle = r.Target.JHMC;
          }
        }
      );
    },

    //获取个人评分表比例
    getGLHDBListZB() {
      this.$api.post(
        "/v1/mbbb/GetGLHDBList",
        {
          MBGLID: this.MBGLID,
          token: this.user.Token,
        },
        (r) => {
          if (r.Code === 0) {
            console.log(r.Target, "r.Target");
            let list = r.Target;

            let DLXKLable = "";
            let DXKLable = "";
            list.forEach((i) => {
              if (i.FID == 0 && i.LX == 1) {
                DLXKLable = i.BL;
              }
              if (i.FID == 0 && i.LX == 2) {
                DXKLable = i.BL;
              }
            });
            let DLXKArr1 = list.filter(
              (item) => item.FID == 1 && item.HDLX == 2
            );
            let DLXKArr2 = list.filter(
              (item) => item.FID == 1 && item.HDLX == 3
            );
            let DLXKArr3 = list.filter(
              (item) => item.FID == 1 && item.HDLX == 4
            );
            let sum1BL = this.getSumBl(DLXKArr1);
            let sum2BL = this.getSumBl(DLXKArr2);
            let sum3BL = this.getSumBl(DLXKArr3);
            this.sumObj = {
              sum1BL,
              sum2BL,
              sum3BL,
              DLXKLable,
              DXKLable,
            };
            localStorage.setItem("sumObj", JSON.stringify(this.sumObj));
          }
        }
      );
    },
    getSumBl(arr) {
      let sum = 0;
      arr.forEach((i) => {
        sum += i.BL;
      });
      return sum;
    },
  },
  async created() {
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    await this.getTermList();
  },
  async mounted() {
    await this.getMBSCJHBList();
    this.getGLHDBListZB();
  },
  watch: {
    currentCount(val) {
      if (val === this.totalCount) {
        this.downLoadPercent.show = false;
        this.currentCount = 0;
        this.downLoadPercent.value = 0;
        this.zips
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            FileSaver.saveAs(
              content,
              "职业道德报表" + new Date().getTime() + ".zip"
            );
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.center-study-report {
  font-size: 14px;
  .filter-container {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .search {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .pdf1 {
    position: fixed;
    left: 99999px;
    top: 0;
  }
  .downloadMask {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color: rgba(241, 241, 241, 0.6);
    .box {
      padding: 0 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%, -50%);
      width: 200px;
      border-radius: 15px;
      .el-progress {
        display: flex;
        align-items: center;
      }
    }
    .closeBtn {
      position: absolute;
      right: 15px;
      top: 15px;
      border-radius: 50%;
    }
  }
}
</style>
