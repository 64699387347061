<template>
  <div>
    <el-dialog
      title="下载模版"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="multi-opt-box">
        <div class="multi-opt">
          <div class="multi-opt-left">年级</div>
          <div class="multi-opt-rig">
            <el-radio-group
              v-model="gradeId"
              @change="handleGrade"
              class="jf-radio"
              size="small"
            >
              <el-radio
                class="el-radio-item"
                :class="{ 'el-radio-item-cur': gradeId === item.Id }"
                v-for="item in gradesList"
                :key="item.Id"
                :label="item.Id"
                border
                >{{ item.Name }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="multi-opt class-block ">
          <div class="multi-opt-left">班级</div>
          <div class="multi-opt-rig class-item">
            <el-checkbox
              class="all-check"
              :indeterminate="isIndeterminateClass"
              v-model="classCheckAll"
              @change="handleCheckAllClass"
              >全选</el-checkbox
            >
            <el-checkbox-group
              v-model="classIds"
              @change="handleClass"
              size="small"
            >
              <el-checkbox
                v-for="item in classDatas"
                :key="item.Id"
                :label="item.Id"
                >{{ item.Name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="multi-opt kc-block ">
          <div class="multi-opt-left">课程</div>
          <div class="multi-opt-rig  kc-item">
            <el-checkbox
              :indeterminate="isIndeterminateCourse"
              v-model="courseCheckAll"
              class="all-check"
              @change="handleCheckAllCourse"
              >全选</el-checkbox
            >
            <el-checkbox-group
              v-model="courseIds"
              @change="handleCourse"
              size="small"
            >
              <div style="display:flex;flex-wrap:wrap">
                <div
                  style="margin-right:10px"
                  v-for="item in courseList"
                  :key="item.Id"
                >
                  <el-checkbox :label="item.Id">{{ item.Name }}</el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportData" :loading="isExporting"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      gradeId: "",
      classIds: [],
      courseIds: [],
      grades: [],
      classDatas: [],
      courseList: [],
      classCheckAll: false,
      isIndeterminateClass: false,
      courseCheckAll: false,
      isIndeterminateCourse: false,
      isExporting: false
    };
  },
  model: {
    prop: "visible",
    event: "change"
  },

  props: [
    "visible",
    "examinationCourse",
    "basicInfo",
    "userId",
    "token",
    "teacherCourse",
    "gradesList"
  ],
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    handleGrade() {
      this.classCheckAll = false;
      this.isIndeterminateClass = false;
      let grade = this.gradesList.find(c => c.Id === this.gradeId);
      if (grade) {
        this.classDatas = grade.ChidOrgList;
        this.courseList = this.classDatas.reduce((pre, cur) => {
          cur.finalCourse.forEach(course => {
            if (
              !pre.some(item => item.Id === course.Id) &&
              course.Name !== "全部"
            )
              pre.push(course);
          });
          return pre;
        }, []);
      }
      this.classIds = [];
      this.courseIds = [];
    },
    handleClass(val) {
      this.isIndeterminateClass =
        val.length > 0 && val.length < this.classDatas.length;

      this.classCheckAll =
        this.classDatas.length !== 0 && val.length === this.classDatas.length;
    },
    handleCheckAllClass(val) {
      this.classIds = val ? this.classDatas.map(c => c.Id) : [];
      this.isIndeterminateClass = false;
    },
    handleCourse(val) {
      let checkedCount = val.length;
      this.courseCheckAll = checkedCount === this.courseList.length;
      this.isIndeterminateCourse =
        checkedCount > 0 && checkedCount < this.courseList.length;
    },
    handleCheckAllCourse(val) {
      this.courseIds = val ? this.courseList.map(c => c.Id) : [];
      this.isIndeterminateCourse = false;
    },
    getStudentData() {
      this.isExporting = true;
      return new Promise(resolve => {
        this.$api.post(
          "v1/accountmanage/GetOrgStudent",
          {
            token: this.token,
            SchoolOrgId: this.gradeId,
            OrgType: 1,
            SourceType: 1
          },
          r => {
            if (r.Code === 0) {
              this.isExporting = false;
              resolve(r.Target);
            }
          }
        );
      });
    },
    exportData() {
      if (this.classIds.length === 0) return this.$message.error("请选择班级");
      if (this.courseIds.length === 0) return this.$message.error("请选择学科");

      // 筛选出班级
      let checkClass = this.classDatas
        .filter(c => this.classIds.includes(c.Id))
        .map(c => ({ Id: c.Id, Name: c.Name }));

      // 筛选课程
      let checkCourse = this.courseList
        .filter(
          c =>
            (c.NJId === this.gradeId || !c.NJId) &&
            this.courseIds.includes(c.Id)
        )
        .map(c => ({ Id: c.Id, Name: c.Name }));
      let arr1 = this.gradesList.reduce((pre, cur) => {
        cur.ChidOrgList.forEach(it => {
          if (checkClass.some(el => el.Id === it.Id)) pre.push(it);
        });
        return pre;
      }, []);

      if (checkClass && checkClass.length > 0) {
        this.getStudentData().then(studentData => {
          this.exportExcel(arr1, checkCourse, studentData);
          this.dialogVisible = false;
        });
      }
    },
    exportExcel(checkClass, checkCourse, studentData) {
      studentData.sort((a, b) => a.Name.localeCompare(b.Name));
      import("@/style/js/Export2ExcelSheetArray").then(excel => {
        let tHeader = checkClass.reduce((pre, cur) => {
          let course = cur.finalCourse.filter(item =>
            checkCourse.some(it => it.Id === item.Id)
          );
          if (course.length > 0) {
            pre.push(["学号", "姓名"].concat(course.map(c => c.Name)));
          } else {
            pre.push(["学号", "姓名"]);
          }
          return pre;
        }, []);
        let data = checkClass.map(c => ({
          Sheet: c.Name,
          Data: this.formatJson(
            ["XH", "Name"],
            studentData.filter(item => item.ClassId === c.Id)
          )
        }));
        excel.export_json_to_excel({
          header: tHeader, //表头 必填
          data, //具体数据 必填
          filename: "学生成绩导入模版" //非必填
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$emit("change", false);
        this.dialogVisible = false;
        this.classIds = [];
        this.gradeId = "";
        this.courseIds = [];
        this.classDatas = [];
        this.classCheckAll = false;
        this.isIndeterminateClass = false;
        this.courseCheckAll = false;
        this.isIndeterminateCourse = false;
        this.isExporting = false;
      } else {
        this.gradeId = this.gradesList[0] ? this.gradesList[0].Id : "";
        this.handleGrade();
      }
    },
    visible(val) {
      this.dialogVisible = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.multi-opt-rig {
  .el-radio-item {
    border: none;
    ::v-deep .el-radio__input {
      display: none;
    }
    &.el-radio-item-cur {
      background-color: #43d9a1;
      ::v-deep .el-radio__label {
        color: #fff;
      }
    }
  }
}
.class-block,
.kc-block {
  display: flex;
  align-items: center;
  .class-item,
  .kc-item {
    display: flex;
    align-items: center;
    .all-check {
      margin-right: 10px;
    }
  }
}
</style>
