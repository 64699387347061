<template>
  <div class="login-back whole-back">
    <div class="login-bg">
      <div class="hd-top">
        <div class="l-box">
          <img style="height: 30px;" src="../style/images/logo_index.png" />
        </div>
      </div>
      <div class="login-con">
        <div class="login-two-name">中小学生综合素质评价</div>
        <div class="login-box">
          <div class="login-title">
            <span class="login-title-chin">欢迎登录</span>
          </div>
          <el-form
            v-show="loginType"
            ref="loginForm"
            :model="user"
            :rules="rules"
          >
            <el-form-item prop="name">
              <el-input
                prefix-icon="el-icon-user"
                v-model="user.name"
                clearable
                placeholder="用户名/手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="pass">
              <el-input
                v-model="user.pass"
                prefix-icon="el-icon-lock"
                clearable
                show-password
                @keydown.enter.native="login"
                type="password"
                placeholder="密码"
              ></el-input>
            </el-form-item>
            <div class="login-help">
              <div class="fl">
                <el-checkbox v-model="user.rememberme">记住密码</el-checkbox>
              </div>
            </div>
          </el-form>
          <div v-show="loginType" class="login-btn">
            <el-button
              type="success"
              class="login-btn-a whole-back whole-back-hov"
              @click="login"
              round
              >登录</el-button
            >
          </div>
          <div class="login-prompt whole-col-hov">
            <!-- <el-button type="text" @click="messagebox()">忘记密码</el-button> -->
          </div>
          <!-- 右上角二维码切换 -->
          <div class="qccode-tip" @click="toggleLoginType"></div>
          <!-- 二维码登录 -->
          <div v-show="!loginType" class="qccode-login-box">
            <img :src="qcObj.img" alt="" />
            <span>*使用成长指南针教师端扫码登录</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#app {
  height: 100%;
}
</style>
<script>
import { getVersionNo } from '@/api/versionNo.js'

export default {
  created() {
    if (this.$store.state.token && this.$store.state.token.length >= 128) {
      this.isLogin = true
    }
  },
  mounted: function() {
    if (this.$route.query.logintimeout === 'true') {
      this.$notify({
        type: 'warning',
        message: '登录超时,请重新登录!',
        duration: 2000
      })
    }
  },
  methods: {
    login: function() {
      this.$store.commit('saveToken', '')
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$api.post(
            'v1/home/TeacherLogin',
            {
              UserName: this.user.name,
              Password: this.user.pass,
              RequestSource: 3,
              ChannelId: '', //	否	string	极光推送客户端ID
              PlatType: 2 //	是	int	设备平台：1.浏览器设备 2.pc设备 3 安卓 4苹果 5.windows phone 6.智能终端
            },
            res => {
              if (res.Code === 0) {
                let token = res.Target.Token
                let user = res.Target.UserInfo
                this.$store.commit('SET_USERINFO', res.Target)
                user.FileServerApiPath = res.Target.FileServerApiPath
                user.SchoolCode = res.Target.SchoolCode
                user.SchoolName = res.Target.SchoolName
                user.ServerTime = res.Target.ServerTime
                user.Token = res.Target.Token
                user.TotalCacheSeriesNo = res.Target.TotalCacheSeriesNo
                user.VideoTime = res.Target.VideoTime
                user.VoiceTime = res.Target.VoiceTime
                user.WebFilePath = res.Target.WebFilePath

                this.$api.post(
                  'v1/perm/GetUserButtionPermList',
                  { token: token, UserId: user.Id },
                  async r => {
                    this.$store.commit(
                      'savePermission',
                      JSON.stringify(r.Target)
                    )
                    if (this.user.rememberme) {
                      localStorage.setItem('userName', this.user.name)
                      localStorage.setItem('passWord', this.user.pass)
                      localStorage.setItem(
                        'rememberUserName',
                        this.user.rememberme
                      )
                    }
                    await this.getUserMenu(user)

                    sessionStorage.removeItem('userinfo')
                    localStorage.removeItem('Token')
                    localStorage.removeItem('userinfo')
                    localStorage.removeItem('MenuList')
                    localStorage.removeItem('PermList')

                    this.$store.commit('saveToken', token)
                    localStorage.setItem('userinfo', JSON.stringify(user))
                    sessionStorage.setItem('userinfo', JSON.stringify(user))

                    await this.getSchoolInfo(user)
                    await this.getCurrentTerm(user, token)
                    await getVersionNo(user)
                    // this.getUserPermission(r.Target.Id);

                    var resultuser = localStorage.getItem('userinfo')
                    var xsuser = ''
                    if (resultuser) {
                      resultuser = JSON.parse(resultuser)
                      xsuser = resultuser.XSM || '用户'
                    }

                    this.$notify({
                      type: 'success',
                      message: '欢迎你,' + xsuser + '!',
                      duration: 2000
                    })

                    this.$router.push({
                      path:
                        !this.$route.query.redirect ||
                        this.$route.query.redirect.includes('login')
                          ? '/index'
                          : this.$route.query.redirect
                    })
                  },
                  () => {}
                )
              } else {
                this.messagebox(res.Message)
              }
            },
            f => {
              this.$notify({
                type: 'warning',
                message: f.Message,
                duration: 3000
              })
            }
          )
        } else {
          return false
        }
      })
    },
    // 获取当前学期
    getCurrentTerm(user, token) {
      return new Promise((resolve, reject) => {
        if (user.SSZZId !== '' && user.YHLX !== 5 && user.YHLX !== 6) {
          this.$api.post(
            '/v1/accountmanage/GetTerm',
            {
              SchoolId: user.SSZZId,
              token: token
            },
            r => {
              if (r.Code === 0 && r.Target) {
                let term = r.Target.find(item => item.IsCurrent === 1)
                localStorage.setItem('currentterm', term.Id)
                localStorage.setItem('curTerm', JSON.stringify(term))
                localStorage.setItem('terms', JSON.stringify(r.Target || []))
              }
              resolve()
            },
            err => reject(err)
          )
        } else resolve()
      })
    },
    // 获取用户菜单
    getUserMenu(user) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/menus/GetUserMenus',
          {
            UserId: user.Id,
            UserType: user.YHLX,
            RequestType: 1,
            SchoolId: user.SSZZId,
            SSXD: user.SSXD, //所属学段
            token: user.Token
          },
          res => {
            if (res.Code === 0) {
              user.menu = res.Target
              resolve()
            } else reject(res)
          }
        )
      })
    },
    messagebox: function(message) {
      this.$alert('提示', message, {
        confirmButtonText: '确定'
      })
    },
    getViews: function(path) {
      return resolve => {
        require.ensure([], require => {
          resolve(require('@/views/' + path + '.vue'))
        })
      }
    },
    // 切换登录方式
    toggleLoginType() {
      this.loginType = !this.loginType
      this.qcObj.field = ''
      clearInterval(this.qcObj.timer)
      if (!this.loginType) {
        this.getQcImg()
      }
    },
    // 获取二维码
    getQcImg() {
      this.$api.post(
        'v1/home/GetQRCode',
        {
          // GetQRCode: this.getQRCode ,
          GetQRCode: !this.qcObj.field, // 第一步获取二维码，这个GetQRCode参数要为true
          LogoPath: this.qcObj.img,
          Field: this.qcObj.field
          // Token: this.$store.getters.token
        },
        res => {
          if (!this.qcObj.field && res.Code === 0) {
            this.qcObj.img = res.Target.Path
            this.qcObj.field = res.Target.Field
            clearInterval(this.qcObj.timer)
            this.qcObj.timer = setInterval(this.getQcImg, 500)
          } else if (this.qcObj.field && res.Code === 0) {
            // 第二步：用户点击确定登录时返回的值
            clearInterval(this.qcObj.timer)
            this.qcObj.timer = ''
            this.qcObj.field = ''
            let token = res.Target.Token
            // 为了与账号登录的数据保持一致
            let obj = {
              ...res.Target
            }
            res.Target.UserInfo = obj
            let user = res.Target.UserInfo
            this.$store.commit('SET_USERINFO', res.Target)
            user.FileServerApiPath = res.Target.FileServerApiPath
            user.SchoolCode = res.Target.SchoolCode
            user.SchoolName = res.Target.SchoolName
            user.ServerTime = res.Target.ServerTime
            user.Token = res.Target.Token
            user.TotalCacheSeriesNo = res.Target.TotalCacheSeriesNo
            user.VideoTime = res.Target.VideoTime
            user.VoiceTime = res.Target.VoiceTime
            user.WebFilePath = res.Target.WebFilePath

            this.$api.post(
              'v1/perm/GetUserButtionPermList',
              { token: token, UserId: user.Id },
              async r => {
                this.$store.commit('savePermission', JSON.stringify(r.Target))
                if (this.user.rememberme) {
                  localStorage.setItem('userName', this.user.name)
                  localStorage.setItem('passWord', this.user.pass)
                  localStorage.setItem('rememberUserName', this.user.rememberme)
                }
                await this.getUserMenu(user)

                sessionStorage.removeItem('userinfo')
                localStorage.removeItem('Token')
                localStorage.removeItem('userinfo')
                localStorage.removeItem('MenuList')
                localStorage.removeItem('PermList')

                this.$store.commit('saveToken', token)
                localStorage.setItem('userinfo', JSON.stringify(user))
                sessionStorage.setItem('userinfo', JSON.stringify(user))

                await this.getCurrentTerm(user, token)
                await getVersionNo(user)
                // this.getUserPermission(r.Target.Id);

                var resultuser = localStorage.getItem('userinfo')
                var xsuser = ''
                if (resultuser) {
                  resultuser = JSON.parse(resultuser)
                  xsuser = resultuser.XSM || '用户'
                }
                // 欢迎词
                this.$notify({
                  type: 'success',
                  message: '欢迎你,' + xsuser + '!',
                  duration: 2000
                })
                // 跳转
                this.$router.push({
                  path:
                    !this.$route.query.redirect ||
                    this.$route.query.redirect.includes('login')
                      ? '/index'
                      : this.$route.query.redirect
                })
              },
              () => {}
            )
          } else {
            clearInterval(this.qcObj.timer)
            this.$message.error('出错了')
          }
        },
        f => {
          clearInterval(this.timer)
          if (f.Code !== -5) {
            if (f.Code === 504) {
              window.alert('api error, HTTP CODE: ' + f.Target)
            } else {
              this.$Message.error({
                content: f.Message,
                duration: 2000,
                closable: true
              })
            }
            this.loginType = true
          }
        }
      )
    },
    // 获取学校详情
    getSchoolInfo(userInfo) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolMessage',
          {
            SchoolId: userInfo.SSZZId,
            token: userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              sessionStorage.setItem(
                'schoolinfo',
                JSON.stringify(res.Target || {})
              )
              resolve()
            } else reject()
          }
        )
      })
    }
  },
  data() {
    return {
      isLogin: false,
      user: {
        name: localStorage.getItem('userName'),
        pass: localStorage.getItem('passWord'),
        rememberme: localStorage.getItem('rememberUserName') === 'true'
      },
      rules: {
        name: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        pass: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      },
      loginType: true, // 1.用户名登录 2.二维码登录
      qcObj: {
        img: require('@/style/images/qrcode-default.png'),
        field: '',
        timer: ''
      }
    }
  }
}
// eslint-disable-next-line no-unused-vars
function getViews(path) {
  return resolve => {
    require.ensure([], require => {
      resolve(require('@/views/' + path + '.vue'))
    })
  }
}
</script>

<style lang="scss" scoped>
.login-box {
  .qccode-tip {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 50px;
    height: 50px;
    background: url('../style/images/qrcode.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .qccode-login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    text-align: center;
    img {
      width: 200px;
      height: 200px;
    }
  }
}
</style>
