<template>
  <div>
    <div style="display: flex;">
      <div class="box">
        选择模板：
        <div
          v-for="(item, index) in MBList"
          :key="index"
          @click="goPage(item)"
          class="item"
        >
          <el-button type="primary" size="small">{{ item.MBMC }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: "",
      MBList: "",
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    this.getMBBList();
  },

  methods: {
    getMBBList() {
      this.$api.post(
        "/v1/mbbb/GetMBBList",
        {
          Express: "",
          token: this.user.Token,
        },
        (r) => {
          if (r.Code == 0) {
            console.log(r.Target, "getMBBList");
            this.MBList = r.Target;
          }
        }
      );
    },
    goPage(value) {
      this.$router.push({ name: "zhbbmbglindex", query: { data: value } });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;
}
</style>
