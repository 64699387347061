import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
//583 826
export default {
	install(Vue) {
		Vue.prototype.getPdfFromHtml1 = async function(id, title) {
			const element = document.getElementsByClassName(id);
			// console.log(element)
			const opts = {
				scale: 2, // 缩放比例，提高生成图片清晰度
				dpi: 300,
				// canvas: canvas,
				useCORS: true, // 允许加载跨域的图片
				// allowTaint: false, // 允许图片跨域，和 useCORS 二者不可共同使用
				tainttest: true, // 检测每张图片都已经加载完成
				logging: false // 日志开关，发布的时候记得改成 false
			};

			let PDF = new JsPDF("", "pt", "a4");
			for (let i = 0; i < element.length; i++) {
				let canvas = await html2Canvas(element[i], opts);
				let defaultwidth = 841.89;
				let defaultheight = 592.28;
				let contentWidth = canvas.width;
				let contentHeight = canvas.height;
				let pageHeight = Math.round((contentWidth / defaultheight) * defaultwidth);
				let leftHeight = contentHeight;
				let position = 0;
				let imgWidth = 595.28;
				let imgHeight = Math.round((defaultheight / contentWidth) * contentHeight);
				let pageData = canvas.toDataURL("image/jpeg", 1.0);
				if (leftHeight < pageHeight) {
					PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
				} else {
					while (leftHeight > 0) {
						PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
						leftHeight -= pageHeight;
						position -= defaultwidth;
						if (leftHeight > 0 || i < element.length - 1) {
							PDF.addPage();
						}
					}
				}
			}
			let obj = new Object();
			obj.PDF = PDF;
			obj.name = title;
			return obj;
		};
		Vue.prototype.getPdfFromHtml2 = async function(id, title) {
			const element = document.getElementsByClassName(id);
			const opts = {
				scale: 2, // 缩放比例，提高生成图片清晰度
				dpi: 300,
				useCORS: true, // 允许加载跨域的图片
				// allowTaint: false, // 允许图片跨域，和 useCORS 二者不可共同使用
				tainttest: true, // 检测每张图片都已经加载完成
				logging: false // 日志开关，发布的时候记得改成 false
			};
			if (element == null) return;

			let PDF = new JsPDF("l", "pt", "a4");
			for (let i = 0; i < element.length; i++) {
				let canvas = await html2Canvas(element[i], opts);
				let defaultwidth = 596.28;
				let defaultheight = 841.89;
				let contentWidth = canvas.width;
				let contentHeight = canvas.height;
				let pageHeight = Math.round((contentWidth / defaultheight) * defaultwidth);
				let leftHeight = contentHeight;
				let position = 0;
				let imgWidth = defaultheight;
				let imgHeight = Math.round((defaultheight / contentWidth) * contentHeight);
				let pageData = canvas.toDataURL("image/jpeg", 1.0);
				if (leftHeight < pageHeight + 11) {
					PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
				} else {
					while (leftHeight > 0) {
						PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
						leftHeight -= pageHeight;
						position -= defaultwidth;
						if (leftHeight > 0 || i < element.length - 1) {
							PDF.addPage();
						}
					}
				}
			}
			let obj = new Object();
			obj.PDF = PDF;
			obj.name = title;
			return obj;
		};
		Vue.prototype.getPdfFromHtml3 = async function(id, title) {
			const element = document.getElementsByClassName(id);
			// console.log(element)
			const opts = {
				scale: 1, // 缩放比例，提高生成图片清晰度
				dpi: 600,
				// canvas: canvas,
				useCORS: true, // 允许加载跨域的图片
				// allowTaint: false, // 允许图片跨域，和 useCORS 二者不可共同使用
				tainttest: true, // 检测每张图片都已经加载完成
				logging: false // 日志开关，发布的时候记得改成 false
			};

			let PDF = new JsPDF("", "pt", "a4");
			for (let i = 0; i < element.length; i++) {
				let canvas = await html2Canvas(element[i], opts);
				let defaultwidth = 841.89;
				let defaultheight = 592.28;
				let contentWidth = canvas.width;
				let contentHeight = canvas.height;
				let pageHeight = Math.round((contentWidth / defaultheight) * defaultwidth);
				let leftHeight = contentHeight;
				let position = 0;
				let imgWidth = 595.28;
				let imgHeight = Math.round((defaultheight / contentWidth) * contentHeight);
				let pageData = canvas.toDataURL("image/jpeg", 1.0);
				if (leftHeight < pageHeight) {
					PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
					if (i < element.length - 1) {
						PDF.addPage();
					}
				} else {
					while (leftHeight > 0) {
						PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
						leftHeight -= pageHeight;
						position -= defaultwidth;
						if (leftHeight > 0 || i < element.length - 1) {
							PDF.addPage();
						}
					}
				}
			}
			let obj = new Object();
			obj.PDF = PDF;
			obj.name = title;
			return obj;
		};
	},
	CanvasToImg: function() {
		new Promise((resolve, reject) => {

		});
	}
};