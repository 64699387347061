<template>
  <div>
    <el-table
      v-loading="isLoading"
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 150%;"
      class="jf-table"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        fixed
        prop="HDMC"
        label="活动名称"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="活动维度" width="122" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ breadthQuery(dimensions, scope.row.SSWD) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="LXMC"
        label="活动类型"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="BMSHStr"
        label="需要审核"
        width="80"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="TotalLimit"
        label="活动总人数"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="CurrentCount"
        label="已报名人数"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="CurrentJoinCount"
        label="已参加人数"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="Managers"
        label="活动负责人"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="JoinGradeStr"
        label="开展年级"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="KZSZStr"
        label="开展方式"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="KSSJ"
        label="开始时间"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="JSSJ"
        label="结束时间"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="IsEnd" label="状态" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.IsEnd === 1 ? "已结束" : "未结束" }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            v-if="new Date(scope.row.KSSJ.replace(/-/g, '/')) > new Date()"
            :disabled="multipleSelection.length > 0"
            @click="editItem(scope.row)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            v-if="scope.row.KZSZ === 1"
            :disabled="multipleSelection.length > 0"
            @click="memberManage(scope.row)"
            type="text"
            size="small"
            >管理成员</el-button
          >
          <el-button
            :disabled="multipleSelection.length > 0"
            @click="memberManage(scope.row, true)"
            type="text"
            size="small"
            >查看成员</el-button
          >
          <UploadExcel
            style="display: inline-block;margin-left: 10px;margin-right: 10px;"
            :on-success="importMembers"
            :itemKey="scope.row"
            ><el-button
              v-if="scope.row.KZSZ === 1"
              :disabled="multipleSelection.length > 0"
              type="text"
              size="small"
              >导入成员</el-button
            ></UploadExcel
          >
          <el-button
            v-if="new Date(scope.row.KSSJ.replace(/-/g, '/')) > new Date()"
            :disabled="multipleSelection.length > 0"
            @click="delItem(scope.row)"
            type="text"
            size="small"
            class="tab-wz-butta c-red"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel.vue";
export default {
  components: {
    UploadExcel,
  },
  data() {
    return {
      multipleSelection: [],
    };
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dimensions: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    memberManage(data, onlymember) {
      this.$emit("memberManage", data, onlymember);
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("delItem", data);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      // for (let i = 0; i < val.length; i++) {
      //   this.multipleSelection.push({ Id: val[i].Id, Name: val[i].HDMC });
      // }
      this.multipleSelection = val.map((item) => {
        return { Id: item.Id, Name: item.HDMC };
      });
      this.$emit("handleSelectionChange", this.multipleSelection);
    },
    breadthQuery(tree, id) {
      var stark = [];

      stark = stark.concat(tree);

      while (stark.length) {
        var temp = stark.shift();
        if (temp.children) {
          stark = stark.concat(temp.children);
        }
        if (temp.value === id) {
          return temp.label;
        }
      }
    },
    importMembers({ results }, activity) {
      this.$emit("importMember", results, activity);
    },
  },
};
</script>
