<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="40%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="tab-shu-box">
      <el-form ref="ruleForm">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl">集团名称</td>
              <td>
                <el-form-item class="jf-form-item" prop="JTMC">
                  <el-input
                    v-model="formData.JTMC"
                    placeholder="请输入内容"
                    size="small"
                    class="jf-form"
                    prop="JTMC"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">所属学校</td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      type: 'array',
                      trigger: 'change'
                    }
                  ]"
                  prop="checkedPJFSData"
                >
                  <el-select
                    v-model="formData.JTSSXXInfo"
                    multiple
                    collapse-tags
                    class="jf-form"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.text"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl"></td>
              <td>
                <el-button plain icon="el-icon-edit" @click="addItem"
                  >新增</el-button
                >
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">集团管理员</td>
              <td>
                <el-table :data="tableData" style="width: 100%">
                  <el-table-column prop="ZH" label="账号">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.ZH"
                        placeholder="账号"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="MM" label="密码">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.MM"
                        placeholder="密码"
                        maxlength="6"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                      <el-button
                        @click="deleteItem(scope.$index, scope.row)"
                        type="text"
                        size="small"
                        class="tab-wz-butta c-red"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="saveItem">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { pinyin } from "pinyin-pro";

export default {
  data() {
    return {
      shapeLimit: [],
      validateResult: false,
      labelWidth: "250px",
      formData: {
        Id: "",
        JTMC: "",
        XXSL: "",
        CJSJ: "",
        JTSSXXInfo: [],
        JTZHGLInfo: []
      },
      tableData: [],
      options: [],
      number: 0
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    shapes: {
      type: Array,
      default: function() {
        return [];
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    init() {
      this.getSchoolAll();
      this.formData = {};
      this.tableData = [];
      this.number = 0;
    },
    getSchoolAll() {
      this.$api.get(
        "/v1/areaschool/GetAreaSchoolAll",
        {
          token: this.$store.state.token
        },
        r => {
          if (r.Code === 0) {
            let data = r.Target;
            if (data !== null) {
              this.options = [];
              for (let i = 0; i < data.length; i++) {
                this.options.push(data[i]);
              }
            }
          }
        }
      );
    },
    closeDialog() {
      this.$emit("update:visible", false);
    },
    addItem: function() {
      if (
        this.formData.JTMC === "" ||
        this.formData.JTMC === null ||
        this.formData.JTMC === undefined
      ) {
        this.$message({
          type: "info",
          message: "请输入集团名称",
          showClose: true
        });
        return;
      }
      this.number = this.number + 1;

      let zh = "";
      let firstChar = this.formData.JTMC.slice(0, 1);
      if (firstChar) {
        let letter = pinyin(firstChar)
          .slice(0, 1)
          .toUpperCase();
        zh += letter;
      }
      let secondChar = this.formData.JTMC.slice(1, 2);
      if (secondChar) {
        let letter = pinyin(secondChar)
          .slice(0, 1)
          .toUpperCase();
        zh += letter;
      }

      let mm = "";
      for (var i = 1; i <= 6; i++) {
        const num = Math.floor(Math.random() * 10);
        mm += num;
      }
      var info = {
        ZH: "GP" + zh + (this.number < 10 ? "0" + this.number : this.number),
        MM: mm
      };
      this.tableData.push(info);
    },
    deleteItem: function(index, row) {
      if (index === 0) {
        index = 1;
      }
      this.tableData.splice(this.tableData.indexOf(row), index);
    },
    saveItem: function() {
      if (
        this.formData.JTMC === "" ||
        this.formData.JTMC === null ||
        this.formData.JTMC === undefined
      ) {
        this.$message({
          type: "info",
          message: "请输入集团名称",
          showClose: true
        });
        return;
      }
      if (
        this.formData.JTSSXXInfo === undefined ||
        this.formData.JTSSXXInfo <= 0
      ) {
        this.$message({
          type: "info",
          message: "请选择学校",
          showClose: true
        });
        return;
      }
      if (this.tableData.length <= 0) {
        this.$message({
          type: "info",
          message: "请添加账号密码",
          showClose: true
        });
        return;
      }
      var obj = Array();
      for (let i = 0; i < this.options.length; i++) {
        var arr = this.formData.JTSSXXInfo.map(function(o) {
          return o;
        });
        if (arr.indexOf(this.options[i].id) > -1) {
          obj.push({ SSXX: this.options[i].id, XXMC: this.options[i].text });
        }
      }
      // 提交
      this.$api.post(
        "v1/Growth/AddGroup",
        {
          JTMC: this.formData.JTMC,
          JTSSXXList: obj,
          JTZHGLList: this.tableData,
          token: this.user.Token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: "操作成功"
            });
            this.$emit("update:visible", false);
            this.$emit("getData");
            this.number = 0;
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        // 隐藏
        this.$refs.ruleForm.resetFields();
      }
    }
  }
};
</script>
