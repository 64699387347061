const state = {
  data: undefined,
  versionNo: []
};
const getters = {
  currentExamination: state => {
    return state.data;
  },
  versionNo: state => state.versionNo
};
const mutations = {
  SET_CURRENTEXAMINATION(state, data) {
    state.data = data || undefined;
  },
  SAVE_VERSION_NO(state, data) {
    state.versionNo = data;
  }
};

export default {
  state,
  getters,
  mutations
};
