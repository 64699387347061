<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <div
        class="leftrig-left-box popo-xk-centx"
        style="width: 200px; max-height: 900px;"
      >
        <!--树-->
        <Tree @loadNode="getTreeData" @changeNode="changeNode" />
      </div>
      <div class="leftrig-rig-box" style="width: calc(100% - 201px);">
        <div class="margno-t">
          <div class="table-er-box" v-if="areaId.includes('-')">
            <!--TODO:按钮-->
            <el-button plain @click="addItem(1)">新增等级转分数</el-button>
            <el-button plain @click="addItem(2)">新增分数转等级</el-button>
          </div>
          <div class="prompt-title-box" style="color: red;" v-else>
            只能在区域下添加等级
          </div>
          <!--TODO:内容-->
          <Table
            :tableData="
              dataTable.slice(
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                  pagerInfo.pageSize
              )
            "
            @editItem="editItem"
            :user="user"
            @delItem="saveDialog"
            v-loading="isLoading"
          />
          <div class="pagin-box">
            <div class="pagin-wz">
              <!--分页-->
              <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <From
      :visible="visible"
      :data="formData"
      :dataList="details"
      :title="dialogTitle"
      :user="user"
      :area="areaId"
      @closeDialog="closeDialog"
      @saveDialog="saveDialog"
    />
  </div>
</template>

<script>
import From from "./Form.vue";
import Table from "./Table.vue";
import Pager from "@/components/Pager.vue";
import Tree from "@/views/manager/dimension/Tree.vue";
export default {
  components: {
    Tree,
    Pager,
    Table,
    From
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      areaId: "",
      visible: false,
      dataTable: [],
      formData: {},
      details: [],
      dialogTitle: "新增等级",
      statu: [
        { Key: 1, Value: "正常" },
        { Key: 2, Value: "停用" }
      ],
      isLoading: false,
      treeData: []
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
  },
  methods: {
    getTreeData(parentId, cab) {
      this.areaId = parentId;
      this.$api.post(
        "/v1/areaschool/GetAreaTree",
        {
          token: this.$store.state.token,
          ParentId: parentId,
          PagerInfo: this.pagerInfo
        },
        r => {
          var data = r.Target;
          var treeData = [];
          for (var i = 0; i < data.length; i++) {
            var leaf = true;
            if (data[i].HasChilden === 1) {
              leaf = false;
            }
            treeData.push({
              name: data[i].Name,
              data_nodetype: data[i].NodeType,
              data_id: data[i].Id,
              data_ordernum: data[i].Ordernum,
              data_remark: data[i].Remark,
              data_level: data[i].Level,
              data_parentid: data[i].ParentCode,
              leaf: leaf
            });
          }
          cab(treeData);
        }
      );
    },
    changeNode(param) {
      if (param.Nodetype === 1) {
        this.areaId = param.Id;
      }
      this.changeIndex(1);
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
      this.getData();
    },
    closeDialog() {
      this.visible = false;
    },
    saveDialog() {
      this.getData();
      this.visible = false;
    },
    addItem(type) {
      this.dialogTitle = "新增等级";
      this.formData = {};
      this.formData.DJLX = type;
      this.details = [];
      this.visible = true;
    },
    editItem(data) {
      this.dialogTitle = "编辑等级";
      this.formData = data;
      this.details = data.Details;
      this.visible = true;
    },
    getData() {
      this.isLoading = true;
      this.$api.post(
        "/v1/levelmanager/GetLeaveSet",
        {
          token: this.token,
          SchoolId: this.areaId
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target;
            this.pagerInfo.totalCount = this.dataTable.length;
            this.isLoading = false;
          }
        }
      );
    }
  }
};
</script>
