<template>
  <!-- 此控件除了传入参数字段，还需传入回调方法@getResultUrl -->
  <div style="display: inline-block;">
    <el-upload
      class="avatar-uploader"
      :disabled="disabled"
      :action="uploadURL"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img
        v-if="resultUrl"
        :key="resultUrl"
        :src="resultUrl"
        :style="{
          width: width + 'px',
          height: height + 'px',
          display: 'block',
          cursor: 'pointer',
          'border-radius': radius + '%',
          'margin-left': left + 'px',
          'margin-top': top + 'px'
        }"
      />
      <div v-else-if="html" v-html="html"></div>
      <slot v-else-if="button"></slot>
      <i v-else class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modeArray: ["merge", "split"], // merge:图片代替按钮。split:图片不代替按钮
      resultUrl: "",
      radius: 0
    };
  },
  props: {
    // 控件显示图片
    itemPic: {
      type: String,
      default: ""
    },
    // 控件所属Id
    itemId: {
      type: String,
      default: ""
    },
    // 控件所属对象
    item: {
      type: Object,
      default: function() {
        return undefined;
      }
    },
    // 控件显示图片宽
    width: {
      type: Number,
      default: 0
    },
    // 控件显示图片高
    height: {
      type: Number,
      default: 0
    },
    // 控件显示图片左边距
    left: {
      type: Number,
      default: 0
    },
    // 控件显示图片顶部边距
    top: {
      type: Number,
      default: 0
    },
    // 图片是否为圆型:默认为方形
    circle: {
      type: Boolean,
      default: false
    },
    // 代替控件的html代码
    html: {
      type: String,
      default: ""
    },
    // 模式
    // 'merge:图片代替按钮。split:图片不代替按钮
    mode: {
      type: String,
      default: "merge"
    },
    button: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fileType: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      // this.imageUrl='https://img.nga.178.com/avatars/2002/faf/891/002/42540975_0.jpg?10'
      if (res.Code === 0) {
        const img = URL.createObjectURL(file.raw);
        if (this.mode === this.modeArray[0]) {
          this.resultUrl = img;
        }
        if (!res.Target.FilePath.includes("http://"))
          res.Target.FilePath = "http://" + res.Target.FilePath;

        this.$emit(
          "getResultUrl",
          res.Target.FilePath,
          this.item ? this.item : this.itemId
        );
      } else {
        this.$message({
          type: "error",
          message: "上传接口出错"
        });
      }
    },
    beforeAvatarUpload(file) {
      const fileType = (this.fileType.length > 0
        ? this.fileType
        : ["image/jpeg", "image/png"]
      ).includes(file.type);
      const fileSize = file.size / 1024 / 1024 <= 10;

      // const fileType = true;
      // const fileSize = true;
      if (!fileType) {
        this.$message.error(
          "上传图片只能是 " +
            (this.fileType.length > 0
              ? this.fileType.map(c => c.replace("image/", "")).join("或者")
              : "JPG或者png") +
            " 格式!"
        );
      }
      if (!fileSize) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return fileType && fileSize;
    }
  },
  mounted: function() {
    if (this.circle) {
      this.radius = 50;
    }
    if (this.itemPic) {
      this.resultUrl = this.itemPic;
    }
  },
  computed: {
    // 文件的上传路径
    uploadURL: function() {
      return (
        "/file/file/fileservice/uploadfile?key=123&attendid=123&attachtype=3&token=" +
        this.$store.state.token
      );
    }
  },
  watch: {
    itemPic(val) {
      this.resultUrl = val;
    }
  }
};
</script>
