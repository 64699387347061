<template>
  <div class="persongrowthtree-container">
    <div
      class="persongrowthtree-item jf-center"
      style="flex-flow: column;align-items: flex-start;"
    >
      <div>家长端主页背景图:</div>
      <div class="jf-center jf-center persongrowthtree-item-operations">
        <el-image
          style="width: 200px; height: 100px"
          fit="cover"
          :src="background ? background : ''"
          :preview-src-list="[background]"
        >
          <div
            slot="error"
            style="
              width: 100%;
              height: 100%;
              background: #f5f7fa;
              color: #909399;
              font-size: 30px;
            "
            class="jf-center"
          >
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <Upload @getResultUrl="saveBackGround" :mode="'split'" button>
          <el-button type="primary">更改图标</el-button>
        </Upload>
        <el-button style="color: red" type="text" @click="clearBackGround"
          >删除</el-button
        >
      </div>
      <div>成长报告页背景图:</div>
      <div class="jf-center jf-center persongrowthtree-item-operations">
        <el-image
          style="width: 200px; height: 100px"
          fit="cover"
          :src="reportBackground ? reportBackground : ''"
          :preview-src-list="[reportBackground]"
        >
          <div
            slot="error"
            style="
              width: 100%;
              height: 100%;
              background: #f5f7fa;
              color: #909399;
              font-size: 30px;
            "
            class="jf-center"
          >
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <Upload @getResultUrl="saveReportBackGround" :mode="'split'" button>
          <el-button type="primary">更改图标</el-button>
        </Upload>
        <el-button style="color: red" type="text" @click="clearReportBackGround"
          >删除</el-button
        >
      </div>
    </div>
    <div v-for="(item, index) in sets" :key="index">
      <el-divider
        ><div v-if="index === 0">
          使用自定义图标:<el-switch
            v-model="isCustomIconSet"
            active-color="#13ce66"
            inactive-color="#c7c7c7"
          >
          </el-switch></div
      ></el-divider>
      <div class="persongrowthtree-item jf-center">
        <div>
          {{ index + 1 + "." }}
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="item.MS"
            maxlength="6"
            show-word-limit
            style="width:140px"
          >
          </el-input>
          起始分:<el-input-number
            :disabled="!customIconSet"
            style="width: 90px"
            v-model="item.FS"
            :min="0"
            :max="100000"
            :precision="2"
            :controls="false"
          ></el-input-number>
        </div>
        <div
          class="
            jf-center
            persongrowthtree-item-operations persongrowthtree-item-mutile
          "
        >
          <el-image
            :disabled="!customIconSet"
            style="width: 200px; height: 100px"
            :src="item.ZSLJ"
            fit="cover"
            :preview-src-list="[item.ZSLJ]"
          >
            <div
              slot="error"
              style="
                width: 100%;
                height: 100%;
                background: #f5f7fa;
                color: #909399;
                font-size: 30px;
              "
              class="jf-center"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <Cropper
            :button="true"
            :item="{ index: index, field: 'ZSLJ' }"
            fixed
            :fixedNumber="[1, 1.132]"
            :cropWidth="100"
            :cropHeight="100"
            @getResultUrl="saveThum"
          >
            <el-button :disabled="!customIconSet" type="primary"
              >更改原图</el-button
            >
          </Cropper>
          <el-image
            :disabled="!customIconSet"
            style="width: 200px; height: 100px"
            fit="cover"
            :src="item.MZLJ"
            :preview-src-list="[item.MZLJ]"
          >
            <div
              slot="error"
              style="
                width: 100%;
                height: 100%;
                background: #f5f7fa;
                color: #909399;
                font-size: 30px;
              "
              class="jf-center"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <Cropper
            :button="true"
            :item="{ index: index, field: 'MZLJ' }"
            fixed
            :fixedNumber="[1, 1.132]"
            :cropWidth="100"
            :cropHeight="100"
            @getResultUrl="saveThum"
          >
            <el-button :disabled="!customIconSet" type="primary"
              >更改中图</el-button
            >
          </Cropper>
          <el-image
            :disabled="!customIconSet"
            style="width: 200px; height: 100px"
            fit="cover"
            :src="item.FJLJ"
            :preview-src-list="[item.FJLJ]"
          >
            <div
              slot="error"
              style="
                width: 100%;
                height: 100%;
                background: #f5f7fa;
                color: #909399;
                font-size: 30px;
              "
              class="jf-center"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <Cropper
            :button="true"
            :item="{ index: index, field: 'FJLJ' }"
            @getResultUrl="saveThum"
          >
            <el-button :disabled="!customIconSet" type="primary"
              >更改小图</el-button
            >
          </Cropper>

          <el-button
            @click="remove(index)"
            :disabled="!customIconSet"
            style="margin-left:10px"
            size="mini"
            type="danger"
            >删除</el-button
          >
          <el-button @click="showSet(item)" size="mini" type="primary"
            >设置条件</el-button
          >
        </div>
      </div>
    </div>
    <el-button
      :disabled="!customIconSet"
      icon="el-icon-plus"
      @click="add"
    ></el-button>
    <div class="jf-center">
      <el-button style="margin-top:100px" type="primary" @click="submit"
        >保存设置</el-button
      >
    </div>

    <!-- 设置条件弹窗 -->
    <el-dialog title="设置条件" :visible.sync="setVisible" width="50%">
      <div class="set-dialog">
        <div class="set-block" v-if="setData.length > 0">
          <div class="set-item" v-for="setItem in setData" :key="setItem.Id">
            <div class="name">{{ setItem.MC }}</div>
            <el-select
              class="BJLX"
              size="small"
              v-model="setItem.BJLX"
              placeholder="请选择"
            >
              <el-option
                v-for="item in setOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input-number
              class="SZFS"
              size="small"
              v-model="setItem.SZFS"
              :min="-1000"
              :max="10000"
              :precision="2"
            />分
          </div>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitSetInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Cropper from "@/components/Cropper.vue";
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Upload,
    Cropper
  },
  name: "persongrowthtree",
  data() {
    return {
      isCustomIconSet: false,
      customIconSet: true,
      background: undefined,
      reportBackground: undefined,
      sets: [
        {
          FS: 0,
          ZSLJ: undefined,
          MZLJ: undefined,
          FJLJ: undefined,
          MS: undefined
        }
      ],
      loading: false,
      cropperVisible: false,
      // ----------------------------------------
      setVisible: false,
      curRow: {}, //当前点击的
      //设置条件详情
      setData: [
        {
          MC: "个人习题1",
          Id: "",
          SZFS: 111, //设置分数
          BJLX: 1, //1：大于 2大于等于
          SSCZSDJ: "", //综合成长树等级(体系id)
          CZTXId: "" //成长体系Id
        }
      ],
      setOption: [
        {
          value: 1,
          label: "＞"
        },
        {
          value: 2,
          label: "＞＝"
        }
      ]
    };
  },
  props: {},
  created() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.initData();
  },
  mounted() {
    // this.initVideo();
  },
  methods: {
    initData() {
      this.getBackGround();
      this.getIconSets();
    },
    getBackGround() {
      this.$api.post(
        "/v1/growthtree/GetGrowthTreeBackGroudImage",
        {
          SSXX: this.user.SSZZId,
          CZSLX: 1,
          token: this.token
        },
        r => {
          let data = r.Target;
          if (data) {
            let client = data.find(c => c.ZSLX === 2);
            let report = data.find(c => c.ZSLX === 3);
            this.background = client ? client.ZSLJ : undefined;
            this.reportBackground = report ? report.ZSLJ : undefined;
            this.isCustomIconSet = data[0]
              ? data[0].ZDYTB === 1
                ? true
                : false
              : false;
          }
        }
      );
    },
    getIconSets() {
      this.$api.post(
        "/v1/growthtree/GetGrowthTreeList",
        {
          SSXX: this.user.SSZZId,
          CZSLX: 1,
          token: this.token
        },
        r => {
          let data = r.Target;
          if (data.length > 0) {
            this.sets = data.map(c => ({
              FS: c.FS,
              ZSLJ: c.ZSLJ,
              MZLJ: c.MZLJ,
              FJLJ: c.FJLJ,
              MS: c.MS,
              Id: c.Id
            }));
          }
        }
      );
    },
    add() {
      this.sets.push({
        FS: 0,
        ZSLJ: undefined,
        MZLJ: undefined,
        FJLJ: undefined,
        MS: undefined
      });
    },
    remove(index) {
      this.sets.splice(index, 1);
    },
    saveBackGround(path) {
      this.background = path;
    },
    clearBackGround() {
      this.background = undefined;
    },
    saveReportBackGround(path) {
      this.reportBackground = path;
    },
    clearReportBackGround() {
      this.reportBackground = undefined;
    },
    saveImg(path, obj) {
      this.sets[obj.index][obj.field] = path;
    },
    saveThum(path, obj) {
      this.sets[obj.index][obj.field] =
        this.user.FileServerApiPath + path.slice(path.indexOf("/"));
    },
    submit() {
      this.sets =
        this.sets.length === 1 &&
        this.sets[0] ===
          {
            FS: 0,
            ZSLJ: undefined,
            MZLJ: undefined,
            FJLJ: undefined,
            MS: undefined
          }
          ? []
          : this.sets;
      let dj = 1;
      for (let item of this.sets) {
        item.DJ = dj++;
      }
      this.$api.post(
        "/v1/growthtree/SaveGrowthTreeIcons",
        {
          CJR: this.user.Id,
          SSXX: this.user.SSZZId,
          CZSLX: 1,
          ZDYTB: this.isCustomIconSet ? 1 : 2,
          ClientBackGround: this.background,
          ReportBackGround: this.reportBackground,
          TreeIcons: this.sets,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.sets =
              this.sets.length === 1 &&
              this.sets[0] ===
                {
                  FS: 0,
                  ZSLJ: undefined,
                  MZLJ: undefined,
                  FJLJ: undefined,
                  MS: undefined
                }
                ? [
                    {
                      FS: 0,
                      ZSLJ: undefined,
                      MZLJ: undefined,
                      FJLJ: undefined,
                      MS: undefined
                    }
                  ]
                : this.sets;
          }
        }
      );
    },
    initVideo() {
      //初始化视频方法
      this.$video(this.$refs.videoNode[0], {
        controls: true,
        preload: "auto",
        fluid: false,
        width: "200px",
        height: "100px"
      });
    },
    // 显示设置条件弹窗
    async showSet(row) {
      this.curRow = row;
      this.setVisible = true;
      await this.getSetInfo(row.Id);
    },
    // 获取对应的弹窗信息
    getSetInfo(GrowthGradeId) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/growthtree/GetGrowthTreeGradeSet",
          {
            GrowthGradeId,
            SchoolId: this.user.SSZZId,
            token: this.user.Token
          },
          res => {
            if (res.Code === 0 && res.Target) {
              this.setData = [];
              res.Target.forEach(item => {
                item.SSCZSDJ = this.curRow.Id;
                if (!item.CZTXId) item.CZTXId = item.Id;
                if (item.BJLX === 0) item.BJLX = 1;
              });
              res.Target.sort((a, b) => a.MC.localeCompare(b.MC));
              this.setData = res.Target;
              resolve();
            } else reject();
          }
        );
      });
    },
    // 提交设置信息
    submitSetInfo() {
      this.$api.post(
        "v1/growthtree/SaveGrowthTreeGradeSet",
        {
          SaveList: this.setData.reduce((pre, cur) => {
            return pre.concat({
              SSCZSDJ: cur.SSCZSDJ,
              BJLX: cur.BJLX,
              CZTXId: cur.Id,
              SZFS: cur.SZFS
            });
          }, []),
          token: this.user.Token,
          SchoolId: this.user.SSZZId
        },
        res => {
          if (res.Code === 0) {
            this.$message.success("设置成功");
            this.setVisible = false;
          }
        }
      );
    }
  }
};
</script>
<style lang="css" scoped>
.persongrowthtree-container {
  font-size: 16px;
}
.persongrowthtree-item {
  justify-content: space-between;
}
.persongrowthtree-item-operations {
  width: 300px;
  justify-content: space-evenly;
}
.persongrowthtree-item-mutile {
  width: 600px;
}
</style>
<style lang="scss" scoped>
.set-dialog {
  .set-block {
    padding: 0 10px;
    .set-item {
      display: flex;
      align-items: center;
      padding: 5px 0;
      .name {
        min-width: 100px;
      }
      .BJLX {
        width: 100px;
        margin: 0 10px;
      }
      .SZFS {
        width: 100px;
        ::v-deep .el-input-number__decrease {
          display: none;
        }
        ::v-deep .el-input-number__increase {
          display: none;
        }
        ::v-deep .el-input {
          width: 100%;
          .el-input__inner {
            padding: 0;
            padding-left: 10px;
          }
        }
      }
    }
  }
}
</style>
