<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <el-table :data="bblist" highlight-current-row style="width: 100%;">
              <el-table-column
                align="center"
                label="序号"
                type="index"
                width="50"
              >
              </el-table-column>
              <el-table-column align="center" property="BBMC" label="名称">
              </el-table-column>
              <el-table-column align="center" label="数据查看">
                <template slot-scope="scope">
                  <el-button
                    @click="handleDetail(scope.row.Id)"
                    type="text"
                    class="c-guree"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagin-box">
              <div class="pagin-wz">
                <Pager
                  :pagerInfo="pagerInfo"
                  @changeIndex="handleCurrentChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  created() {
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    handleDetail: function(id) {
      this.$router.push({ name: "reportdata", params: { reportid: id } });
    },
    loaddata: function() {
      this.$api.post(
        "v1/customreport/GetSchoolReportList",
        { SSXX: this.ssxx, PagerInfo: this.pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.bblist = [];
            this.bblist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    // 翻页
    handleSizeChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    }
  },
  data() {
    return {
      bblist: [],
      ssxx: "",
      token: "",
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      }
    };
  }
};
</script>
