<template>
  <el-table
    :data="tableData"
    class="jf-table"
    border
    tooltip-effect="dark"
    style="width: 100%;"
  >
    <el-table-column
      fixed
      prop="PXH"
      align="center"
      label="序号"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      fixed
      prop="WPMC"
      align="center"
      label="物品名称"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="DHDJ"
      align="center"
      label="价格"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="ZSL"
      align="center"
      label="总数量"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="KCL"
      align="center"
      label="剩余数量"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="YDHS"
      align="center"
      label="已兑换数"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="YJYS"
      align="center"
      label="已交易数"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="DHKSRQ"
      align="center"
      label="兑换开始日期"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="DHJZRQ"
      align="center"
      label="兑换结束日期"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="CJSJ"
      align="center"
      label="创建日期"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column align="center" label="状态" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ scope.row.SYZT === 1 ? "正常" : "暂停" }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" align="center" label="操作" width="250">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="editItem(scope.row)"
          >编辑</el-button
        >
        <el-button
          v-if="new Date(scope.row.DHKSRQ.replace(/-/g, '/')) > new Date()"
          type="text"
          size="small"
          @click="delItem(scope.row)"
          class="c-red"
          >删除</el-button
        >
        <el-button
          v-if="new Date(scope.row.DHKSRQ.replace(/-/g, '/')) <= new Date()"
          type="text"
          size="small"
          @click="editInventory(scope.row.Id, scope.row.KCL)"
          >修改库存</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    token: {
      type: String,
      default: ""
    }
  },
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "/v1/StudentExchangeArticle/DelArticlesForWeb",
            {
              token: this.token,
              ExchangeId: data.Id
            },
            r => {
              if (r.Code === 0) {
                this.$emit("delItem", data);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    editInventory(id, inventory) {
      this.$emit("editInventory", id, inventory);
    }
  }
};
</script>
