<template>
  <div
    v-loading.fullscreen.lock="fullscreenLoading"
    class="main-article"
    element-loading-text="生成PDF内容中..."
  >
    <div class="article__heading">
      <div class="article__heading__title">
        {{ article.title }}
      </div>
    </div>
    <div ref="content" class="node-article-content" v-html="article.content" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      article: "",
      fullscreenLoading: false
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      import("./content.js").then(data => {
        const { title } = data.default;
        document.title = title;
        this.article = data.default;
        setTimeout(() => {
          this.$nextTick(() => {
            this.fullscreenLoading = false;
            window.print();
          });
        }, 3000);
      });
    }
  }
};
</script>

<style lang="scss">
@mixin clearfix {
  &:before {
    display: table;
    content: "";
    clear: both;
  }

  &:after {
    display: table;
    content: "";
    clear: both;
  }
}

.node-article-content {
  width: 840px;
}

.node-article-content-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 1188px;
}

.exportpdf-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: red;
}

.progress {
  /*position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 45vh;
  z-index: 1111111111;
  width: 60%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  box-shadow: 0 2px 12px 0 #0000001a;*/
}

.el-loading-spinner {
  top: 40vh;
}

.main-wrap {
  /*width: 100%;*/
}
</style>
