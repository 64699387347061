<template>
  <el-dialog
    class="evaluationTimeDialog"
    title="新增评价阶段时间"
    :visible.sync="visible"
    width="550px"
    :before-close="closeForm"
    append-to-body
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="所属学段" prop="SSXD">
        <el-select
          style="width:400px"
          v-model="form.SSXD"
          placeholder="所属学段"
        >
          <el-option
            v-for="item in SSXDoption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动时间" prop="dateRange">
        <el-date-picker
          style="width:400px"
          :disabled="!form.SSXD"
          v-model="form.dateRange"
          type="daterange"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button size="mini" @click="closeForm">取消</el-button>
      <el-button size="mini" type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: {
        HDId: '',
        KSSJ: '',
        JSSJ: '',
        SSXQ: '',
        Id: null,
        SSXD: '',
        dateRange: []
      },
      rules: {
        SSXD: [
          {
            required: true,
            message: '学段不能为空',
            trigger: 'blur'
          }
        ],
        SSXQ: [
          {
            required: true,
            message: '学期不能为空',
            trigger: 'blur'
          }
        ],
        dateRange: [
          {
            required: true,
            message: '阶段不能为空',
            trigger: 'blur'
          }
        ]
      },
      pickerOptions: {
        //日期选择禁用
        disabledDate: date => {
          if (date.getTime() < Date.now() - 24 * 60 * 60 * 1000) return true //早于当前的 禁用

          let termRange = this.SSXQoption.find(
            item => item.Id === this.sonActivity.SSXQ
          )
          if (termRange) {
            if (
              date.getTime() <=
                new Date(termRange.EndTime.replace(/-/g, '/')).getTime() &&
              date.getTime() >=
                new Date(termRange.BeginTime.replace(/-/g, '/')).getTime()
            ) {
              return false
            }
          } else return true
        }
      },
      SSXDoption: []
    }
  },
  props: {
    visible: Boolean,
    currentData: Object,
    SSXQoption: Array,
    sonActivity: Object
  },
  mounted() {
    this.getSSXD()
  },
  methods: {
    getSSXD() {
      //所属学段
      let { SSZZId: SchoolId, Token: token } = JSON.parse(
        localStorage.getItem('userinfo')
      )
      this.$api.post(
        'v1/areaschool/GetSchoolByPeriod',
        { SchoolId, token },
        res => {
          if (res.Code === 0) {
            this.SSXDoption = res.Target
          }
        }
      )
    },
    closeForm() {
      this.$emit('closeFormDialog')
    },
    submit() {
      let { Id: UserId, SSZZId, Token: token } = JSON.parse(
        localStorage.getItem('userinfo')
      )

      let addData = {
        KSSJ: this.form.dateRange[0],
        JSSJ: this.form.dateRange[1],
        HDId: this.sonActivity.Id,
        // SSXD: this.form.SSXD,
        SSXQ: this.sonActivity.SSXQ,
        Id: this.form.Id,
        ZHDId: this.sonActivity.HDId
      }
      this.$api.post(
        'v1/evastage/EditHDKZJD',
        {
          addData,
          UserId,
          token,
          SchoolId: SSZZId
        },
        res => {
          if (res.Code === 0) this.$emit('closeFormDialog', 1)
        }
      )
    }
  },
  watch: {
    visible(val) {
      if (val) {
        Object.assign(this.form, this.currentData)
        this.form.dateRange = [this.form.KSSJ, this.form.JSSJ]
      } else {
        Object.keys(this.form).forEach(key => {
          if (key === 'dateRange') this.form[key] = []
          else this.form[key] = ''
        })
      }
    }
  }
}
</script>
<style lang="scss">
.evaluationTimeDialog {
  min-width: 460px;
}
</style>
<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: end;
}
</style>
