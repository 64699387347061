<template>
  <!-- 教师通用报表-活动 -->
  <div class="container">
    <div style="padding:10px 0">
      <el-button type="primary" size="mini" @click="setHD">设置活动</el-button>
    </div>
    <div class="custom-container list">
      <div class="act-item" v-for="(obj, index) in customList" :key="index">
        <div class="name">{{ obj.HDMC }}--{{ obj.objName }}</div>
        <el-input-number size="mini" v-model="obj.BL"></el-input-number>%
        <el-button
          type="danger"
          style="margin-left:20px;"
          size="mini"
          @click="delHD(obj)"
          >删除</el-button
        >
      </div>
    </div>
    <div class="question-container list">
      <div class="act-item" v-for="(obj, index) in questionList" :key="index">
        <div class="name">
          {{ obj.HDMC }}--{{ obj.questionName }}--{{ obj.objName }}
        </div>
        <el-input-number size="mini" v-model="obj.BL"></el-input-number>%
        <el-button
          type="danger"
          style="margin-left:20px;"
          size="mini"
          @click="delHD(obj)"
          >删除</el-button
        >
      </div>
    </div>
    <div class="reports-container list">
      <div class="act-item" v-for="(report, index) in reportList" :key="index">
        <div class="name">{{ report.JHMC }}</div>
        <el-input-number size="mini" v-model="report.BL"></el-input-number>%
        <el-button
          type="danger"
          style="margin-left:20px;"
          size="mini"
          @click="delHD(report)"
          >删除</el-button
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>

    <HDDialog
      :show="dialogShow"
      :MBInfo="MBInfo"
      :HD_Data="[...customList, ...questionList, ...reportList]"
      :reportTreeList="reportTreeList"
      :reportCheck="reportCheck"
      @HD_Submit="HD_Submit"
    />
  </div>
</template>

<script>
import HDDialog from './setHD.vue'
export default {
  components: {
    HDDialog
  },
  data() {
    return {
      MBInfo: {},
      BBInfo: {},
      XMInfo: {},
      XM_GLHD_Base: [],
      // 自定义活动
      customList: [],
      // 问卷
      questionList: [],
      // 固定活动
      fixList: [],
      // 报表
      reportList: [],
      dialogShow: false,
      reportTreeList: [],
      reportCheck: [],
      XM_Type: 1
    }
  },
  async created() {
    this.userInfo = JSON.parse(localStorage.getItem('userinfo'))
    this.XM_GLHD_Base = JSON.parse(localStorage.getItem('XM_GLHD_Base'))
    this.MBInfo = JSON.parse(localStorage.getItem('MBInfo'))
    this.BBInfo = JSON.parse(localStorage.getItem('BBInfo'))

    this.XM_Type = JSON.parse(localStorage.getItem('XM_Type')) //1.主项目 2.子项目
    this.XMInfo = JSON.parse(
      localStorage.getItem(this.XM_Type === 1 ? 'XMInfo' : 'ZXMInfo')
    )

    await this.getReportTree()

    await this.getZXM_List()
  },
  methods: {
    getZXM_List() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/mbbb/GetGLHDBList',
          {
            MBGLID: this.BBInfo.Id,
            FID: this.XMInfo.Id,
            IsGetHDList: 3,
            LX: 3,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              let arr = res.Target || []

              let arr2 = arr.filter(item => item.LX === 3)
              this.handleZXM_List(arr2)
              resolve()
            }
          }
        )
      })
    },
    handleZXM_List(val) {
      let arr = val.filter(item => {
        if (item.HDLX === 7) {
          return item.LX === 3 && item.FID === this.XMInfo.Id
        } else {
          return (
            item.LX === 3 &&
            item.FID === this.XMInfo.Id &&
            item.MBGLID === this.BBInfo.Id
          )
        }
      })
      console.log(arr, '得到的数据')
      let customList = []
      let questionList = []
      let reports = []
      arr.forEach(item => {
        let hd = this.XM_GLHD_Base.find(hd => hd.Id === item.GLHD)
        if (hd && (hd.PJFS === 1 || hd.PJFS === 2)) {
          customList.push({
            Id: item.Id,
            BL: item.BL,
            HDID: item.GLHD,
            IsSelect: item.IsSelect,
            HDLX: item.HDLX,
            HDMC: hd.PJHDMC,
            HDXS: item.HDXS,
            objHDXS: item.HDXS,
            objName:
              item.HDXS === 1 ? '自评' : item.HDXS === 2 ? '互评' : '主评'
          })
        } else if (hd && hd.PJFS === 3) {
          if (hd.HDWJList && hd.HDWJList.length > 0) {
            let WJ = hd.HDWJList.find(
              WJ => WJ.WJId === item.WJID && WJ.HDXS === item.HDXS
            )
            if (WJ) {
              questionList.push({
                Id: item.Id,
                BL: item.BL,
                HDID: item.GLHD,
                HDLX: item.HDLX,
                HDMC: hd.PJHDMC,
                HDXS: item.HDXS,
                IsSelect: item.IsSelect,
                objHDXS: item.HDXS,
                questionId: WJ.WJId,
                WJID: WJ.WJId,
                questionName: WJ.WJMC,
                objName:
                  item.HDXS === 1 ? '自评' : item.HDXS === 2 ? '互评' : '主评'
              })
            }
          }
        } else if (item.HDLX === 7) {
          let reportInfo = this.findReoport(item)
          reports.push({
            BL: item.BL,
            HDLX: item.HDLX,
            Id: item.Id,
            GLHD: item.GLHD,
            MBGLID: item.MBGLID,
            JHMC: reportInfo.Name
          })
        }
      })
      this.customList = customList
      this.questionList = questionList
      this.reportList = reports
    },
    setHD() {
      this.dialogShow = true
    },
    HD_Submit(val) {
      if (!val) {
        this.dialogShow = false
        return
      }

      let { custom, question, reports } = val

      this.customList = custom.reduce((pre, cur) => {
        let res = this.customList.find(
          item => item.HDID === cur.HDID && item.objHDXS === cur.objHDXS
        )
        if (res) {
          pre.push({
            ...res,
            IsSelect: cur.IsSelect,
            PJFS: cur.PJFS
          })
        } else {
          pre.push({
            ...cur
          })
        }
        return pre
      }, [])

      this.questionList = question.reduce((pre, cur) => {
        let res = this.questionList.find(
          item =>
            item.HDID === cur.HDID &&
            item.WJID === cur.WJID &&
            item.objHDXS === cur.objHDXS
        )
        if (res) {
          pre.push({
            ...res,
            IsSelect: cur.IsSelect,
            PJFS: cur.PJFS
          })
        } else {
          pre.push({
            ...cur
          })
        }
        return pre
      }, [])

      this.reportList = reports.reduce((pre, cur) => {
        let res = this.reportList.find(
          item => item.MBGLID === cur.MBGLID && item.HDLX == cur.HDLX
        )
        if (res) {
          pre.push({
            ...res,
            IsSelect: 2,
            JHMC: cur.JHMC,
            GLHD: cur.GLHD,
            MBGLID: this.BBInfo.Id
          })
        } else {
          pre.push({
            ...cur,
            IsSelect: 2,
            MBGLID: this.BBInfo.Id
          })
        }
        return pre
      }, [])
      this.dialogShow = false
    },
    // 保存
    confirm() {
      let custom = this.customList.reduce((pre, cur) => {
        pre.push({
          Id: cur.Id,
          MBGLID: this.BBInfo.Id,
          // MC: "ddd",
          IsSelect: 2,
          GLHD: cur.HDID,
          PJFS: cur.PJFS,
          HDXS: cur.HDXS,
          BL: cur.BL,
          LX: 3,
          HDLX: cur.HDLX,
          FID: this.XMInfo.Id,
          CJR: this.userInfo.Id,
          SSXX: this.userInfo.SSZZId
        })
        return pre
      }, [])

      let question = this.questionList.reduce((pre, cur) => {
        pre.push({
          Id: cur.Id,
          MBGLID: this.BBInfo.Id,
          // MC: "ddd",
          IsSelect: cur.IsSelect,
          GLHD: cur.HDID,
          HDXS: cur.HDXS,
          PJFS: cur.PJFS,
          WJID: cur.WJID,
          BL: cur.BL,
          LX: 3,
          HDLX: cur.HDLX,
          FID: this.XMInfo.Id,
          CJR: this.userInfo.Id,
          SSXX: this.userInfo.SSZZId
        })
        return pre
      }, [])
      let reports = this.reportList.reduce((pre, cur) => {
        pre.push({
          Id: cur.Id,
          MBGLID: cur.MBGLID,
          // MC: "ddd",
          IsSelect: 2,
          GLHD: cur.GLHD,
          // HDXS: 0,
          BL: cur.BL,
          LX: 3,
          HDLX: cur.HDLX,
          FID: this.XMInfo.Id,
          CJR: this.userInfo.Id,
          SSXX: this.userInfo.SSZZId
        })
        return pre
      }, [])
      let GLHDBInfo = [...custom, ...question, ...reports]
      let url = '/v1/mbbb/AddGLHDB'
      if (GLHDBInfo.some(item => item.Id)) {
        url = '/v1/mbbb/UpdateGLHDB'
      }
      this.$api.post(
        url,
        {
          GLHDBInfo,
          MBGLID: this.BBInfo.Id,
          token: this.userInfo.Token
        },
        res => {
          if (res.Code === 0) {
            this.$message.success('保存成功')
            let name = this.XM_Type === 1 ? 'teachersetXM' : 'teachersetZXM'
            this.$router.push({ name })
          }
        }
      )
    },
    cancel() {
      let name = this.XM_Type === 1 ? 'teachersetXM' : 'teachersetZXM'
      this.$router.push({ name })
    },
    // 获取报表树
    getReportTree() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/mbbb/GetMBSCJHBTree',
          {
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              let arr = res.Target || []
              this.handleReportTree(arr)
              this.reportTreeList = arr
              resolve()
            }
          }
        )
      })
    },
    // 处理报表树
    handleReportTree(list) {
      list.forEach(item => {
        if (item.Children && item.Children.length > 0) {
          this.handleReportTree(item.Children || [])
        } else {
          item.Children = null
          item.leaf = true
        }
      })
    },
    // 找到报表对应的详情
    findReoport(data) {
      let level3 = []
      this.reportTreeList.find(report => {
        if (report.Children) {
          report.Children.forEach(item => {
            if (item.Children) {
              item.Children.forEach(it => {
                if (it.Id + '' === data.GLHD) {
                  this.reportCheck.push([report.Id, item.Id, it.Id])
                }
                level3.push(it)
              })
            }
          })
        }
      })
      return level3.find(item => item.Id + '' === data.GLHD) || {}
    },
    // 删除活动
    delHD(data) {
      if (data.Id) {
        this.$api.post(
          '/v1/mbbb/DeleteGLHDB',
          {
            Id: data.Id,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              if (data.WJID) {
                this.questionList = this.questionList.filter(
                  item => item.Id !== data.Id
                )
              } else if (data.HDLX === 7) {
                this.reportList = this.reportList.filter(
                  item => item.Id !== data.Id
                )
              } else {
                this.customList = this.customList.filter(
                  item => item.Id !== data.Id
                )
              }
              this.$message.success('删除成功')
            }
          }
        )
      } else {
        if (data.WJID) {
          this.questionList = this.questionList.filter(
            item => item.Id !== data.Id
          )
        } else if (data.HDLX === 7) {
          this.reportList = this.reportList.filter(item => item.Id !== data.Id)
        } else {
          this.customList = this.customList.filter(item => item.Id !== data.Id)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .list {
    .act-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .name {
        width: 400px;
        font-weight: bold;
      }
    }
  }
}
</style>
