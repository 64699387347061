<template>
  <!-- 学生家长评活动弹窗 -->
  <el-dialog
    width="80%"
    top="5vh"
    title="设置活动"
    :visible="showDialog"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="container">
      <!-- 按维度选择 -->
      <div class="choseWD">
        <div class="left">按维度选择</div>
        <div class="rig">
          <el-radio-group v-model="choseWD" style="margin-top:10px">
            <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio>
            <el-radio :label="3">备选项</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="custom-activity">
        <div class="custom-item" v-for="custom in customList" :key="custom.Id">
          <div class="act-name">{{ custom.name }}</div>
          <div class="custom-objs">
            <div class="obj-item" v-for="obj in custom.obj" :key="obj.name">
              <el-checkbox v-model="obj.check">{{ obj.name }}</el-checkbox>
            </div>
            <div class="obj-item" v-if="custom.hasIsSelect">
              <el-checkbox v-model="custom.IsSelect">各阶段之平均</el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="question-activity">
        <div
          class="act-item"
          v-for="questionAct in questionList"
          :key="questionAct.Id"
        >
          <div class="act-name">{{ questionAct.name }}</div>
          <div class="questionAct-question">
            <div
              class="question-item"
              v-for="(question, idx) in questionAct.question"
              :key="idx"
            >
              <div class="question-name">{{ question.name }}</div>
              <div class="question-objs">
                <div
                  class="obj-item"
                  v-for="obj in question.obj"
                  :key="obj.name"
                >
                  <el-checkbox v-model="obj.check">{{ obj.name }}</el-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="obj-item" v-if="questionAct.hasIsSelect">
            <el-checkbox v-model="questionAct.IsSelect"
              >各阶段之平均</el-checkbox
            >
          </div>
        </div>
      </div>
      <!-- <div class="fix-activity">
        <div class="fix-item" v-for="fix in fixList" :key="fix.name">
          <el-checkbox v-model="fix.check">{{ fix.name }}</el-checkbox>
        </div>
      </div> -->
      <div class="reports">
        <div class="title">报表选择：</div>
        <el-cascader
          ref="cascader"
          v-model="reportCheck2"
          :options="reportTreeList2"
          :show-all-levels="false"
          :props="props"
        >
        </el-cascader>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showDialog = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ['show', 'MBInfo', 'HD_Data', 'reportTreeList', 'reportCheck'],
  data() {
    return {
      userInfo: {},
      XM_GLHD_Base: [],
      activityList: [],
      customList: [],
      questionList: [],
      fixList: [],
      reportTreeList2: [],
      reportCheck2: [],
      props: {
        lazy: false,
        multiple: true,
        children: 'Children',
        leaf: 'Leaf',
        value: 'Id',
        label: 'Name',
        disabled: 'disabled'
      },
      choseWD:'', //选择维度
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.show
      },
      set(val) {
        if (!val) {
          this.$emit('HD_Submit')
        }
      }
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userinfo'))
  },
  methods: {
    handleBaseHD() {
      let customList = []
      let questionList = []
      let fixList = [{ name: '获奖管理', check: false }]
      this.XM_GLHD_Base.forEach(item => {
        item.HDID = item.Id
        if (item.PJFS === 1 || item.PJFS === 2) {
          let act = {
            name: item.PJHDMC,
            HDID: item.Id,
            HDLX: item.HDLX === 1 ? 5 : 6,
            hasIsSelect: item.HDLX === 2,
            PJFS: item.PJFS,
            IsSelect: item.HDLX === 2 ? false : null,
            obj: []
          }
          act.obj = this.handleHDXS(item, act)
          customList.push(act)
        }
        if (item.PJFS === 3) {
          let act = {
            name: item.PJHDMC,
            HDID: item.Id,
            HDLX: item.HDLX === 1 ? 5 : 6,
            hasIsSelect: item.HDLX === 2,
            PJFS: item.PJFS,
            IsSelect: item.HDLX === 2 ? false : null,
            question: []
          }
          if (item.HDWJList) {
            let arr = item.HDWJList.reduce((pre, WJ) => {
              WJ.HDID = WJ.HdId
              if (
                !pre.find(
                  item => item.HDXS === WJ.HDXS && item.WJId === WJ.WJId
                )
              )
                pre.push(WJ)
              return pre
            }, [])

            arr.forEach(WJ => {
              let question = {
                name: WJ.WJMC,
                WJID: WJ.WJId,
                HDID: item.Id,
                obj: []
              }
              question.obj = this.handleHDXS(WJ, act)
              act.question.push(question)
            })
            questionList.push(act)
          }
        }
      })
      this.customList = customList
      this.questionList = questionList
      this.fixList = fixList
    },
    handleHDXS(val, act) {
      let arrBase = [
        {
          HDXS: 1,
          HDID: val.HDID,
          WJID: val.WJId,
          name: '自评',
          check: false
        },
        {
          HDXS: 2,
          HDID: val.HDID,
          WJID: val.WJId,
          name: '互评',
          check: false
        },
        {
          HDXS: 4,
          HDID: val.HDID,
          WJID: val.WJId,
          name: '主评',
          check: false
        }
      ]
      let arr = []
      switch (val.HDXS) {
        case 1:
          arr = JSON.parse(JSON.stringify([arrBase[0]]))
          break
        case 2:
          arr = JSON.parse(JSON.stringify([arrBase[1]]))
          break
        case 4:
          arr = JSON.parse(JSON.stringify([arrBase[2]]))
          break
        case 3:
          arr = JSON.parse(JSON.stringify([arrBase[0], arrBase[1]]))
          break
        case 5:
          arr = JSON.parse(JSON.stringify([arrBase[0], arrBase[2]]))
          break
        case 6:
          arr = JSON.parse(JSON.stringify([arrBase[1], arrBase[2]]))
          break
        case 7:
          arr = JSON.parse(JSON.stringify(arrBase))
          break
      }
      arr.forEach(obj => {
        if (obj.WJID) {
          let res = this.HD_Data.find(
            item =>
              item.HDID === obj.HDID &&
              item.WJID === obj.WJID &&
              item.HDXS === obj.HDXS
          )
          if (res) {
            obj.check = true
            obj.Id = res.Id
            act.IsSelect = res.IsSelect === 1 ? true : false
          }
        } else {
          let res = this.HD_Data.find(
            item => item.HDID === obj.HDID && item.HDXS === obj.HDXS
          )
          if (res) {
            obj.check = true
            obj.Id = res.Id
            act.IsSelect = res.IsSelect === 1 ? true : false
          }
        }
      })
      return arr
    },
    //取消选择活动
    cancel() {
      this.$confirm('确认关闭？')
        .then(() => {
          this.$emit('cancelHDdialog')
        })
        .catch(() => {})
    },
    //形成闭环
    close() {
      this.$emit('HD_Submit')
    },
    //确认选择活动
    confirm() {
      let custom = this.customList.reduce((pre, cur) => {
        cur.obj.forEach(obj => {
          if (obj.check) {
            pre.push({
              HDID: cur.HDID,
              HDMC: cur.name,
              HDXS: obj.HDXS,
              PJFS: cur.PJFS,
              HDLX: cur.HDLX,
              objHDXS: obj.HDXS,
              objName: obj.name,
              IsSelect: cur.IsSelect ? 1 : 2,
              BL: ''
            })
          }
        })
        return pre
      }, [])
      let question = this.questionList.reduce((pre, cur) => {
        cur.question.forEach(question => {
          question.obj.forEach(obj => {
            if (obj.check) {
              pre.push({
                HDID: cur.HDID,
                HDMC: cur.name,
                HDLX: cur.HDLX,
                HDXS: obj.HDXS,
                PJFS: cur.PJFS,
                questionName: question.name,
                questionId: question.WJID,
                WJID: question.WJID,
                objHDXS: obj.HDXS,
                objName: obj.name,
                IsSelect: cur.IsSelect ? 1 : 2,
                BL: ''
              })
            }
          })
        })
        return pre
      }, [])
      let cascaderData = this.$refs.cascader.getCheckedNodes()
      let reports = cascaderData.reduce((pre, cur) => {
        if (cur.level === 3) {
          pre.push({
            MBGLID: cur.data.MBGLID,
            GLHD: cur.data.Id,
            HDLX: 7,
            BL: '',
            JHMC: cur.data.Name
          })
        }
        return pre
      }, [])
      this.$emit('HD_Submit', { custom, question, reports })
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.XM_GLHD_Base = JSON.parse(localStorage.getItem('XM_GLHD_Base'))
        this.reportTreeList2 = JSON.parse(JSON.stringify(this.reportTreeList))
        this.reportCheck2 = JSON.parse(JSON.stringify(this.reportCheck))
        this.handleBaseHD()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-height: 700px;
  overflow-y: scroll;
  .custom-activity {
    padding: 10px;
    .custom-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .act-name {
        width: 200px;
      }
      .custom-objs{
        display: flex;
        .obj-item{
          margin-left: 10px;
        }
        .obj-item:nth-child(1){
          margin-left: 0px;
        }
      }
    }
  }
  .question-activity {
    padding: 10px;
    .act-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .act-name {
        width: 200px;
      }
      .questionAct-question {
        flex: 1;
        display: flex;
        align-items: center;
        .question-item {
          display: flex;
          align-items: center;
          margin-right: 10px;
          .question-name {
            margin-right: 10px;
          }
          .question-objs {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .reports {
    display: flex;
    align-items: center;
    padding: 10px;
  }
}
</style>
