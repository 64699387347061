<template>
  <!-- 新增/编辑教师获奖 -->
  <div class="edit-teacher-award">
    <el-form
      style="width: 800px"
      :model="hjglinfo"
      label-width="140px"
      :rules="rules"
      ref="sbmitfrom"
    >
      <el-form-item label="获奖教师：" prop="HJRY">
        <span v-for="(item, index) in xsid" :key="index"> {{ item.XM }}</span>
        <el-button
          type="primary"
          :disabled="!!hjglinfo.Id"
          @click="handleChange"
          >{{ hjglinfo.Id ? '更改' : '新增' }}</el-button
        >
      </el-form-item>
      <el-form-item label="获奖名称：" prop="HJMC">
        <el-input
          v-model="hjglinfo.HJMC"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="获奖类别：" prop="HJLB">
        <el-select
          style="width: 100%"
          v-model="hjglinfo.HJLB"
          @change="handleHJLBChange"
          placeholder="请选择获奖类别"
        >
          <el-option
            v-for="item in lblist"
            :key="item.Id"
            :label="item.LBMC"
            :value="item.Id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="获奖级别：" prop="DJId">
        <el-cascader
          v-model="hjglinfo.DJId"
          :options="DJList"
          @change="getDJMC"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="计分比例：" prop="BLID">
        <span v-show="!BLList.length">请先选择获奖类别</span>
        <el-radio-group
          v-model="hjglinfo.BLID"
          v-for="item in BLList"
          :key="item.Id"
          style="margin-right: 10px"
        >
          <el-radio :label="item">{{ item.MC }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="获奖时间：" prop="HJSJ">
        <el-date-picker
          v-model="hjglinfo.HJSJ"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="主办单位" prop="ZBDW">
        <el-input v-model="hjglinfo.ZBDW" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="辅导学生" prop="FZXS">
        <el-input v-model="hjglinfo.FZXS" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="描述：" prop="BZ">
        <el-input
          type="textarea"
          :autosize="{ minRows: 6 }"
          placeholder="请输入描述"
          v-model="hjglinfo.BZ"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="上传图片：">
        <el-upload
          class="upload-demo"
          list-type="picture-card"
          accept="image/jpeg, image/gif, image/png"
          :action="uploadURL"
          :on-error="upLoadError"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :limit="6"
          :on-success="handleAvatarSuccess"
          :on-exceed="handleExceed"
          :auto-upload="true"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <br />
      <el-form-item>
        <el-button type="primary" @click="submitshowdata" :disabled="isDisabled"
          >保存</el-button
        >
        <el-button @click="canceladd">取消</el-button>
      </el-form-item>
    </el-form>
    <!-- 选择教师弹窗 -->
    <el-dialog
      title="选择教师"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleChangeClose"
    >
      <div
        class="table-er-box"
        style="border-bottom: 1px solid #f2f2f2; text-align: right"
      >
        <div
          style="
            font-size: 14px;
            margin-bottom: 10px;
            display: inline-block;
            float: left;
          "
        >
          <el-input
            style="width: 200px"
            v-model="searchname"
            placeholder="请输入关键字"
            maxlength="20"
            size="small"
            clearable
            prefix-icon="el-icon-search"
          ></el-input>
          <span
            style="color: #14d089; margin-left: 5px; cursor: pointer"
            @click="handleSearch()"
            >搜索</span
          >
        </div>
      </div>
      <div class="multi-opt-box" style="margin-top: 0px">
        <div class="multi-opt">
          <div class="multi-opt-rig">
            <div
              class="d-word-widt"
              v-for="xs in list"
              :key="xs.Id"
              @click="xschange(xs)"
            >
              <a
                class="b-word-widta whole-back-hover"
                :style="
                  xsid.some(it => it.Id == xs.Id)
                    ? 'color: white; background-color: #0fd59d'
                    : ''
                "
                >{{ xs.XM }}</a
              >
            </div>
          </div>
        </div>
        <div class="confirmbtn">
          <el-button type="primary" @click="xsconfirm">确定</el-button>
        </div>
      </div>
      <!-- <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="rowcount"
        ></el-pagination>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.ssxq = localStorage.getItem('currentterm')
    var tempuser = localStorage.getItem('userinfo')
    let term = localStorage.getItem('curTerm')
    let list = JSON.parse(localStorage.getItem('XXHJLBList'))
    if (list) {
      this.xxHJLBList = list.XXHJFLList
      let XXHJSZList = list.XXHJSZList
      XXHJSZList.forEach(item => {
        if (
          (item.SZLX == 2 && item.SZZT == 1) ||
          (item.SZLX == 2 && item.SZZT == 2)
        ) {
          this.hjglinfo.SHZT = 2
        }
      })
    }
    // 获取缓存学生
    let teachers = localStorage.getItem('OrgTeacher')
    if (teachers) this.schoolTeachers = JSON.parse(teachers)

    if (term) this.term = JSON.parse(term)
    this.hjglinfo.Id = this.$route.params.hdid
    if (tempuser) {
      var user = JSON.parse(tempuser)
      this.ssxx = user.SSZZId
      this.cjr = user.Id
      this.hjglinfo.CJRLB = user.YHLX
      this.token = user.Token
      this.fileServerApiPath = user.FileServerApiPath
      this.webFilePath = user.WebFilePath
    }
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return `/file/file/fileservice/UploadFile?token=${this.token}&attachtype=1`
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      let form = this.$route.params.form
      console.log(form, 'form')
      //选择获奖类别(教师的)
      this.lblist = this.xxHJLBList.filter(i => {
        if (i.HJFLDJList?.length > 0) {
          i.HJFLDJList.forEach(it => {
            it.value = it.Id
            it.label = it.MC
            it.level = it.CJ
            let res = i.HJFLDJList.filter(t => t.FID == it.Id)

            it.children = res.length > 0 ? res : null
          })
          i.HJFLDJList = i.HJFLDJList.filter(o => o.CJ == 1)
        }
        return i.SSLB == 2
      })

      this.lblist.sort((a, b) => {
        a.PXH - b.PXH
      })
      this.handleHJLBChange(form.HJLB)
      if (form.Id) {
        // 处理图片
        this.hjglinfo = {
          ...this.hjglinfo,
          ...form
        }

        this.hjglinfo.BLID = this.BLList.find(i => i.Id == form.BLID)
        let a = this.schoolTeachers.find(i => i.Id == form.CYId)
        if (a) {
          this.xsid.push(a)
        }

        if (form.FjList && form.FjList.length > 0) {
          this.fileList = form.FjList.map(item => ({
            name: item.FJMC,
            url: item.FJLJ,
            FJMC: item.FJMC,
            FJLJ: item.FJLJ
          }))
          this.filearr = form.FjList.map(item => ({
            name: item.FJMC,
            url: item.FJLJ,
            FJMC: item.FJMC,
            FJLJ: item.FJLJ
          }))
        }
      }

      await this.loaddata()
    },
    handleRemove(file) {
      if (file.FJLJ) {
        this.filearr = this.filearr.filter(item => item.FJLJ !== file.FJLJ)
      } else {
        this.filearr = this.filearr.filter(
          item => item.FJLJ !== file.response.Target.FilePath
        )
      }
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 文件上传成功
    handleAvatarSuccess(response) {
      console.log(response, 'dsaf')
      if (response.Code == -3) {
        this.$message.warning(`附件上传失败`)
        return
      } else {
        this.filearr.push({
          FJMC: response.Target.FileName,
          FJLJ: response.Target.FilePath
        })
      }
    },
    //文件上传失败时的钩子
    upLoadError() {
      this.$message.info(`附件上传失败`)
    },
    handleExceed() {
      this.$message.info(`最多只能上传6张图片`)
    },
    //取消
    canceladd: function() {
      this.$router.push({ path: '/teacherawardmanage/teacherawardlist' })
    },
    // 提交
    submitshowdata() {
      this.hjglinfo.HJRY = this.xsid[0].XM
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          var fjstr = ''
          if (this.filearr && this.filearr.length > 0) {
            fjstr = this.filearr
          }
          let CYIdList = []
          this.xsid.forEach(i => {
            CYIdList.push({
              Id: i.Id,
              XM: i.XM
            })
          })
          //找选择等级的对应的分数
          let data = {
            Id: this.hjglinfo.Id || '',
            HJMC: this.hjglinfo.HJMC,
            HJLB: this.hjglinfo.HJLB,
            LBMC: this.hjglinfo.LBMC,
            BZ: this.hjglinfo.BZ,
            CYLB: 2, // 成员类别：1学生3班级2教师4社团小组
            HJRY: CYIdList,
            SSXQ: this.ssxq,
            XQLX: this.term.XQLX,
            CJRLB: this.hjglinfo.CJRLB,
            FJArr: fjstr || [],
            DF: (this.hjglinfo.BLID.BL * this.hjglinfo.DJFS) / 100,
            CJR: this.cjr,
            SHZT: 2, //是否审核
            SSXN: this.term.SSXN,
            HJSJ: this.hjglinfo.HJSJ,
            BLID: this.hjglinfo.BLID.Id,
            BLMC: this.hjglinfo.BLID.MC,
            DJId: this.hjglinfo.DJId,
            DJMC: this.hjglinfo.DJMC,
            ZBDW: this.hjglinfo.ZBDW,
            FZXS: this.hjglinfo.FZXS,
            token: this.token,
            SSXX: this.ssxx
          }
          this.isDisabled = true
          this.$api.post('v1/awards/AddAward', data, r => {
            if (r.Code == 0) {
              this.$message({
                type: 'info',
                message: '保存成功!'
              })
              this.isDisabled = false
              this.$router.push({
                path: '/teacherawardmanage/teacherawardlist'
              })
            } else {
              this.$message({
                type: 'info',
                message: r.Message
              })
              this.isDisabled = false
            }
          })
        } else {
          this.isDisabled = false
        }
      })
    },
    //确定单选项
    handleConfirm() {
      this.$refs.optiontextValidateForm.validate(valid => {
        if (valid) {
          this.srdxx.push(this.optiontextValidateForm.optiontext)
          this.optiontextValidateForm.optiontext = ''
          this.dialogVisible = false
        } else {
          return false
        }
      })
    },
    //更改
    handleLRSDXSChange() {
      if (this.hjglinfo.SFLRSDXS == 1) {
        this.hqlb = 1
      } else {
        this.hqlb = 2
      }
      this.hjglinfo.HJJB = ''
      this.hjglinfo.HJLB = ''
    },
    getDJMC(value) {
      let a = ''
      //编辑时
      if (this.hjglinfo.Id != null && this.hjglinfo.Id) {
        if (value.length == 2) {
          a = value[value.length - 1]
        } else {
          a = value[0]
        }
      } else {
        // 新增时
        if (value.length == 2) {
          a = value[value.length - 1]
        } else {
          a = value[0]
        }
      }
      this.hjglinfo.DJId = a
      this.DJList.forEach(item => {
        if (item.Id == a) {
          this.hjglinfo.DJMC = item.MC
        } else {
          if (item.children != null && item.children.length > 0) {
            item.children.forEach(it => {
              if (it.Id == a) {
                let c = item.MC
                this.hjglinfo.DJMC = c + it.MC
                this.hjglinfo.DJFS = it.FS
              }
            })
          }
          // else{
          //   this.hjglinfo.DJMC = item.MC
          //   this.hjglinfo.DJFS = item.FS
          // }
        }
      })
    },
    //移除空数组
    removeChild(target) {
      target.forEach(item => {
        item['value'] = item.label
        for (const key in item) {
          if (!item[key].length) {
            item[key] = ''
          } else if (typeof item[key] == 'object') {
            this.removeChild(item[key])
          }
        }
      })
    },
    handleHJLBChange(id) {
      let a = this.lblist.find(i => i.Id == id)
      if (a) {
        this.BLList = a.HJJFBLList
        this.DJList = a.HJFLDJList
        this.DJList.sort((a, b) => a.PXH - b.PXH)
        this.DJList.forEach(item => {
          if (item.children != null && item.children.length > 0) {
            item.children.sort((a, b) => a.PXH - b.PXH)
          }
        })
        this.hjglinfo.LBMC = a.LBMC
      }
    },

    //更改
    handleChange() {
      this.searchname = ''
      this.njid = ''
      this.bjid = ''
      this.pageIndex = 1
      this.dialogVisible = true
      this.loaddata()
    },
    // 搜索
    handleSearch() {
      if (!this.searchname || !this.searchname.trim()) this.list = this.xslist
      else {
        this.list = this.xslist.filter(item =>
          item.XM.includes(this.searchname)
        )
      }
    },
    //关闭单选项
    handleChangeClose() {
      this.dialogVisible = false
    },
    xsconfirm() {
      this.hjglinfo.CYMC = this.choosexs
      this.dialogVisible = false
    },
    xschange(data) {
      const index = this.xsid.findIndex(i => i.Id === data.Id)
      if (index === -1) {
        this.xsid.push(data)
      } else {
        this.xsid.splice(index, 1)
      }
    },
    // 获取教师
    loaddata() {
      this.$api.post(
        'v1/teacher/GetTeacherManagerList',
        {
          SchoolId: this.ssxx,
          SYZT: 1,
          TeacherName: this.searchname,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            this.xslist = r.Target || []
            this.list = this.xslist
          }
        }
      )
    },
    //翻页
    handleSizeChange(val) {
      this.pagesize = val
      this.loaddata()
    },
    //翻页查询
    handleCurrentChange(val) {
      this.pageIndex = val
      this.loaddata()
    }
  },
  data() {
    return {
      isDisabled: false,
      webFilePath: '',
      fileServerApiPath: '',
      dialogFormVisible: false,
      ssxx: '',
      ssxq: '',
      xsid: [],
      LBId: '',
      term: '',
      choosexs: [],
      dialogVisible: false,
      token: '',
      lblist: [],
      djlist: [],
      bllist: [],
      njlist: [],
      xslist: [],
      BLList: [],
      DJList: [],
      xxHJLBList: [],
      schoolTeachers: [],
      list: [],
      bjid: '',
      njid: '',
      searchname: '',
      pageIndex: 1,
      pagesize: 30,
      rowcount: 0,
      bjlist: [],
      zzjglist: [],
      srfslx: [],
      srdxx: [],
      filearr: [],
      fileList: [],
      hqlb: 2,
      cjr: '',
      hjglinfo: {
        Id: 0,
        CJR: '',
        CJSJ: '',
        HJRY: '',
        DJId: '',
        DJFS: '', //等级分数
        HJMC: '',
        HJJB: '',
        DJMC: '',
        HJSJ: '',
        HJLB: '',
        BZ: '',
        BLID: '',
        CYMC: [],
        LBMC: '',
        BJId: '',
        CJRLB: 0,
        DF: 0,
        SHZT: 1,
        FZXS: '', //辅助学生
        SFLRSDXS: 2,
        HJJBMC: ''
      },
      rules: {
        HJMC: [
          { required: true, message: '请输入获奖名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在1到50个字符', trigger: 'change' }
        ],
        HJRY: [
          { required: true, message: '请选择获奖成员', trigger: 'change' }
        ],
        BZ: [
          {
            min: 0,
            max: 500,
            message: '长度不能超过500个字符',
            trigger: 'change'
          }
        ],
        HJLB: [
          { required: true, message: '请选择获奖类别', trigger: 'change' }
        ],
        DJId: [
          { required: true, message: '请选择获奖级别', trigger: 'change' }
        ],
        BLID: [
          { required: true, message: '请选择计分比例', trigger: 'change' }
        ],
        HJSJ: [{ required: true, message: '请选择时间', trigger: 'change' }]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-student-award {
  .filter-container {
    padding-top: 10px;
    .filter-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      box-shadow: 1px 1px 2px 2px #ededed;
      padding: 10px;
      .filter-item-label {
        width: 40px;
        text-align: center;
        margin-right: 15px;
      }
      .content-item {
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 1px 1px 2px 2px #ededed;
        margin-bottom: 10px;
        margin-right: 10px;
        cursor: pointer;
        &.cur-content-item {
          color: #fff;
          background-color: #0fd59d;
        }
      }
    }
  }
}
.multi-opt-rig {
  z-index: 10;
  width: 100%;
  height: 460px;
  overflow: auto;
}
.confirmbtn {
  display: flex;
  justify-content: right;
  margin-right: 25px;
}
</style>
