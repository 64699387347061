<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'totalscore' + this.exportType">
					<img :src="xsinfo.ZP" :class="'studenticon' + this.exportType" />
					<div :class="'xsinfodiv' + this.exportType">
						<div :class="'xsinfotitle' + this.exportType">
							{{ xsinfo.XM }}
						</div>
					</div>
					<div :class="'scoredesc' + this.exportType">
						<el-row :gutter="10">
							<el-col :class="'scoretitle' + this.exportType">
								学期总分
							</el-col>
						</el-row>
						<el-row :gutter="10">
							<el-col :class="'scoredescstr' + this.exportType">
								{{ xsinfo.XQZF }}
							</el-col>
						</el-row>
					</div>
					<div class="clboth"></div>
				</div>
				<div id="myChart" :class="'scorebar' + this.exportType"></div>
				<div :class="'detailinfo' + this.exportType">
					<div :class="'detailrow' + this.exportType" v-if="xszsdata.length > 0">
						<el-row :gutter="10">
							<el-col :class="'detailstr' + this.exportType">
								{{ xszsdata[0].name }}
							</el-col>
							<el-col :class="'detailscore' + this.exportType">
								{{ xszsdata[0].dj }}
							</el-col>
						</el-row>
					</div>
					<div :class="'detailrow' + this.exportType" v-if="xszsdata.length > 1">
						<el-row :gutter="10">
							<el-col :span="12" :class="'detailstr' + this.exportType">
								{{ xszsdata[1].name }}
							</el-col>
							<el-col :span="12" :class="'detailscore' + this.exportType">
								{{ xszsdata[1].dj }}
							</el-col>
						</el-row>
					</div>
					<div :class="'detailrow' + this.exportType" v-if="xszsdata.length > 2">
						<el-row :gutter="10">
							<el-col :span="12" :class="'detailstr' + this.exportType">
								{{ xszsdata[2].name }}
							</el-col>
							<el-col :span="12" :class="'detailscore' + this.exportType">
								{{ xszsdata[2].dj }}
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				activities: [],
				myChart: null,
				backgroudimage: "",
				exportType: 1,
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					XQZF: "",
					SFZSEJYM: 1 //1环状图2柱状图3不展示
				},
				txinfo: null,
				xszsdata: [],
				txdf: null,
				xstxdata: null
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
			this.loadactivities();
			this.loadtxdata();
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			drawBar(xarry, xsarr) {
				if (
					this.myChart != null &&
					this.myChart != "" &&
					this.myChart != undefined
				) {
					this.myChart.dispose();
				}
				this.myChart = this.$echarts.init(document.getElementById("myChart"));
				this.myChart.setOption({
					tooltip: {},
					xAxis: {
						data: xarry
					},
					yAxis: {},
					legend: {
						bottom: "10",
						data: ["个人"],
						show: false
					},
					series: [{
						name: "个人",
						type: "bar",
						data: xsarr,
						itemStyle: {
							color: "#0063DA"
						}
					}]
				});
			},
			drawPie(xspiedata) {
				if (
					this.myChart != null &&
					this.myChart != "" &&
					this.myChart != undefined
				) {
					this.myChart.dispose();
				}
				this.myChart = this.$echarts.init(document.getElementById("myChart"));
				this.myChart.setOption({
					tooltip: {
						show: false
					},
					legend: {
						orient: "vertical",
						left: "left"
					},
					series: [{
						type: "pie",
						radius: ["60%", "90%"],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 5,
							borderColor: "#fff",
							borderWidth: 1
						},
						label: {
							show: false,
							position: "center"
						},
						emphasis: {
							label: {
								show: true,
								fontWeight: "bold"
							}
						},
						labelLine: {
							show: false
						},
						data: xspiedata
					}]
				});
			},
			changexsdata(xsdata) {
				this.xsinfo.Id = xsdata.Id;
				this.xsinfo.XM = xsdata.XM;
				this.xsinfo.XBM = xsdata.XBM;
				this.xsinfo.ZP = xsdata.ZP;
				this.xsinfo.XQAH = xsdata.XQAH;
				this.xsinfo.SSXX = xsdata.SSXX;
				this.xsinfo.SSXQ = xsdata.SSXQ;
				this.xsinfo.SSXN = xsdata.SSXN;
				this.xsinfo.XQLX = xsdata.XQLX;
				this.xsinfo.BZRMC = xsdata.BZRMC;
				this.xsinfo.NL = xsdata.NL;
				this.xsinfo.XXMC = xsdata.XXMC;
				this.xsinfo.XXTB = xsdata.XXTB;
				this.xsinfo.BJMC = xsdata.BJMC;
				this.xsinfo.XXMC = xsdata.XXMC;
				this.xsinfo.XXBM = xsdata.XXBM;
				this.xsinfo.XZ = xsdata.XZ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				this.getxsreportdata();
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthreport/GetGrowthReport", {
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xsinfo.SSXQ,
						XQXN: this.xsinfo.SSXN,
						XQLX: this.xsinfo.XQLX,
						XXBM: this.xsinfo.XXBM,
						XSId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						this.xszsdata = [];
						if (r.Code == 0) {
							var info = r.Target;
							this.txdf = null;
							if (info != null && info != undefined) {
								if (
									info.SchoolStuReBasicSet != null &&
									info.SchoolStuReBasicSet != undefined
								) {
									this.xsinfo.SFZSEJYM = info.SchoolStuReBasicSet.SFZSEJYM;
								}
								if (
									info.StudentGrowthReport != null &&
									info.StudentGrowthReport != undefined &&
									info.StudentGrowthReport.TXDF != null &&
									info.StudentGrowthReport.TXDF != undefined
								) {
									this.txdf = JSON.parse(info.StudentGrowthReport.TXDF);
									if (this.txinfo != null) {
										this.xstxdata = this.txdf.filter(
											c => c.MC == this.txinfo.TXMC
										)[0];
									}
									if (this.xstxdata != null) {
										this.xsinfo.XQZF =
											this.xstxdata.Grade != null && this.xstxdata.Grade != "" ?
											this.xstxdata.Grade :
											this.xstxdata.DF;
										let subdata = this.xstxdata.SData;
										if (
											subdata != null &&
											subdata != undefined &&
											subdata.length > 0
										) {
											for (var i = 0; i < subdata.length; i++) {
												if (subdata[i].Score > 0) {
													subdata[i].Grade =
														subdata[i].Grade != null && subdata[i].Grade != "" ?
														subdata[i].Grade :
														subdata[i].Score;

													var obj = new Object();
													obj.name = "自定义活动";
													obj.dj = subdata[i].Grade;
													obj.score = subdata[i].Score;
													switch (subdata[i].IdOrMc) {
														case "CurriculaVariable":
															obj.name = "课辅活动";
															break;
														case "HomeWork":
															obj.name = "学生作业";
															break;
														case "Hornor":
															obj.name = "学生荣誉";
															break;
														case "HomesShooling":
															obj.name = "家校共育";
															break;
														case "Physique":
															obj.name = "体质得分";
															break;
														default:
															var temphd =
																this.activities != null &&
																this.activities.length > 0 ?
																this.activities.find(
																	c => c.ActivityId == subdata[i].IdOrMc
																) :
																null;
															if (temphd != null) {
																obj.name = temphd.ActivityName;
															}
															break;
													}
													this.xszsdata.push(obj);
												}
											}
										}
									}
								}
								this.drawchart();
							}
						}
					}
				);
			},
			drawchart: function() {
				if (this.xsinfo.SFZSEJYM == 1) {
					let xspiedata = this.xszsdata.map(item => {
						return Object.assign({}, {
							name: item.name,
							value: item.score
						});
					});
					this.drawPie(xspiedata);
				} else {
					this.drawBar(
						this.xszsdata.map(item => {
							return item.name;
						}),
						this.xszsdata.map(item => {
							return item.score;
						})
					);
				}
			},
			loadtxdata: function() {
				this.$api.post(
					"v1/growthbrochure/GetTX", {
						Id: this.info.GLId,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							this.txinfo = r.Target;
						}
					}
				);
			},
			loadactivities() {
				this.$api.post(
					"v1/evaluationactivity/GetSchoolQRCodeActivity", {
						SchoolId: this.ssxx,
						token: this.token
					},
					r => {
						if (r.Code === 0) {
							this.activities = [];
							this.activities = r.Target;
						}
					}
				);
			}
		},
		mounted() {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #0063da;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #0063da;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 125px;
		height: 125px;
		text-align: center;
		float: left;
		margin-top: 40px;
		margin-left: 30px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 10px;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/dot_blue_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/dot_blue_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.totalscore1 {
		height: 200px;
		width: 756px;
		margin-top: 30px;
		margin-left: 40px;
		background: #e6f0fe;
		border-radius: 25px;
	}

	.totalscore2 {
		height: 120px;
		width: 530px;
		margin-top: 20px;
		margin-left: 30px;
		background: #e6f0fe;
		border-radius: 15px;
	}

	.scoretitle1 {
		margin-top: 25px;
		width: 125px;
		height: 22px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.scoretitle2 {
		margin-top: 15px;
		width: 80px;
		height: 18px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.scoredescstr1 {
		width: 145px;
		height: 34px;
		font-size: 34px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		vertical-align: middle;
		margin-top: 20px;
	}

	.scoredescstr2 {
		width: 100px;
		height: 30px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		vertical-align: middle;
		margin-top: 10px;
	}

	.scoredesc1 {
		width: 125px;
		height: 112px;
		text-align: center;
		float: right;
		vertical-align: middle;
		margin-top: 30px;
		margin-right: 40px;
	}

	.scoredesc2 {
		width: 80px;
		height: 75px;
		text-align: center;
		float: right;
		vertical-align: middle;
		margin-top: 20px;
		margin-right: 30px;
	}

	.xsinfodiv1 {
		margin-top: 90px;
		margin-left: 20px;
		display: table-row;
		float: left;
		width: 165px;
	}

	.xsinfodiv2 {
		margin-top: 50px;
		margin-left: 15px;
		display: table-row;
		float: left;
		width: 125px;
	}

	.xsinfotitle1 {
		width: 200px;
		height: 22px;
		font-size: 20px;
		font-weight: bold;
		color: #080808;
		font-family: Microsoft YaHei;
	}

	.xsinfotitle2 {
		width: 140px;
		height: 18px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
	}

	.scorebar1 {
		height: 400px;
		width: 756px;
		margin-top: 10px;
		margin-left: 40px;
		background: #e6f0fe;
		border-radius: 15px;
	}

	.scorebar2 {
		height: 300px;
		width: 530px;
		margin-top: 10px;
		margin-left: 30px;
		background: #e6f0fe;
		border-radius: 10px;
	}

	.detailinfo1 {
		width: 756px;
		height: 270px;
		margin-top: 10px;
		margin-left: 40px;
	}

	.detailinfo2 {
		width: 530px;
		height: 210px;
		margin-top: 10px;
		margin-left: 30px;
	}

	.detailrow1 {
		height: 100px;
		width: 756px;
		margin-top: 10px;
		background: #e6f0fe;
		border-radius: 15px;
	}

	.detailrow2 {
		height: 70px;
		width: 530px;
		margin-top: 10px;
		background: #e6f0fe;
		border-radius: 10px;
	}

	.detailstr1 {
		margin-left: 80px;
		width: 425px;
		text-align: left;
		line-height: 100px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.detailstr2 {
		margin-left: 40px;
		width: 280px;
		text-align: left;
		line-height: 70px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.detailscore1 {
		margin-left: 20px;
		width: 205px;
		text-align: center;
		line-height: 100px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.detailscore2 {
		margin-left: 40px;
		width: 120px;
		text-align: center;
		line-height: 70px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}
</style>