<template>
  <!-- 德体美劳弹窗 -->
  <el-dialog
    custom-class="select-dimension-dialog"
    title="勾选维度"
    :visible.sync="show"
    width="70%"
    :close-on-click-modal="false"
    :show-close="false"
    append-to-body
  >
    <div class=" tree-block">
      <el-tree
        :data="dimensionOption2"
        ref="tree"
        node-key="value"
        :default-expanded-keys="expandedKeys"
        show-checkbox
        empty-text="暂无维度"
        :check-strictly="true"
        :props="defaultProps"
        @check-change="checkChange"
      ></el-tree>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:show', false)">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dimensionOption: [Array],
    show: [Boolean],
    formData: [Object]
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
        disabled: "disabled"
      },
      expandedKeys: [],
      dimensionOption2: [],
      curDimension: ""
    };
  },
  methods: {
    checkChange(data, val) {
      if (data.leaf) {
        this.curDimension = val ? data.value : "";
      }
      if (!data.leaf && (!data.children || data.children.length === 0)) {
        this.curDimension = val ? data.value : "";
      }
      this.$nextTick(() => {
        if (data.leaf && val) {
          this.dimensionOption2.forEach(el => {
            el.disabled = true;
            if (el.children && el.children.length > 0) {
              el.children.forEach(it => {
                if (it.value !== data.value) it.disabled = true;
              });
            }
          });
        }
        if (data.leaf && !val) {
          this.dimensionOption2.forEach(el => {
            el.disabled = false;
            if (el.children && el.children.length > 0) {
              el.children.forEach(it => {
                it.disabled = false;
              });
            }
          });
        }
        if (
          !data.leaf &&
          (!data.children || data.children.length === 0) &&
          val
        ) {
          this.dimensionOption2.forEach(el => {
            if (data.value !== el.value) el.disabled = true;
            if (el.children && el.children.length > 0) {
              el.children.forEach(it => (it.disabled = true));
            }
          });
        }
        if (
          !data.leaf &&
          (!data.children || data.children.length === 0) &&
          !val
        ) {
          this.dimensionOption2.forEach(el => {
            el.disabled = false;
            if (el.children && el.children.length > 0) {
              el.children.forEach(it => (it.disabled = false));
            }
          });
        }
      });
    },
    submit() {
      this.$emit("submitDimension", this.curDimension);
      this.curDimension = "";
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.dimensionOption2 = JSON.parse(
          JSON.stringify(this.dimensionOption)
        );

        let form = JSON.parse(JSON.stringify(this.formData));

        if (form && form.checked && form.EJWDId) {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([]);
            this.expandedKeys = [form.EJWDId];
            // this.$refs.tree.setCheckedKeys([form.EJWDId]);
            let node = this.$refs.tree.getNode(form.EJWDId);
            if (node) {
              this.$refs.tree.setChecked(node, true);
              this.checkChange(node.data, true);
            }
          });
        }
        this.form = form;
      }
    }
  }
};
</script>

<style></style>
