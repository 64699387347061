<template>
  <!-- 设置活动-->
  <div class="div">
    <el-form
      status-icon
      :model="form"
      :ref="formName"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
      label-position="right"
    >
      <div class="form-top" style="margin-bottom: 20px;font-size: 16px;">
        定量考核设置
      </div>
      <el-form-item label="定量考核占比" :label-width="formLabelWidth">
        <el-input
          style="max-width: 218px"
          suffix-icon="el-icon-edit"
          v-model="form.BL"
          autocomplete="on"
        >
        </el-input>
        %
      </el-form-item>
      <el-form-item label="定量考核关联活动" :label-width="formLabelWidth" prop>
        <div v-if="dialogActicity1 != null && dialogActicity1.length > 0">
          <div class="hd-box">
            <div class="hd-item" v-for="item in dialogActicity1" :key="item.Id">
              <div
                class="hd-zb"
                v-for="(it, index) in item.checkList"
                :key="index"
              >
                <div class="hd-name">{{ item.PJHDMC }}--</div>
                <div>{{ it.pjlx }}</div>
                <div class="hd-ipt">活动占比:</div>
                <el-input
                  style="max-width: 218px"
                  suffix-icon="el-icon-edit"
                  v-model="it.value"
                  autocomplete="on"
                >
                </el-input
                >%
              </div>
            </div>
          </div>
        </div>
        <el-button type="primary" size="mini" @click="handleDLKHSet(1)">{{
          dialogActicity1 != null && dialogActicity1.length > 0
            ? '重新设置'
            : '设置'
        }}</el-button>
      </el-form-item>
      <el-divider></el-divider>
      <div class="form-top" style="margin-bottom: 20px;font-size: 16px;">
        定性考核设置
      </div>
      <el-form-item
        label="定性考核占比"
        :label-width="formLabelWidth"
        prop="PJHDMC"
      >
        <el-input
          style="max-width: 218px"
          suffix-icon="el-icon-edit"
          v-model="form.BL2"
          autocomplete="on"
        >
        </el-input
        >%
      </el-form-item>
      <el-form-item label="学生家长评活动" :label-width="formLabelWidth" prop>
        <div v-if="dialogActicity2 != null && dialogActicity2.length > 0">
          <div class="hd-box">
            <div class="hd-item" v-for="item in dialogActicity2" :key="item.Id">
              <div
                class="hd-zb"
                v-for="(it, index) in item.checkList"
                :key="index"
              >
                <div class="hd-name">{{ item.PJHDMC }}--</div>
                <div>{{ it.pjlx }}</div>
                <div class="hd-ipt">活动占比:</div>
                <el-input
                  style="max-width: 218px"
                  suffix-icon="el-icon-edit"
                  v-model="it.value"
                  autocomplete="on"
                >
                </el-input
                >%
              </div>
            </div>
          </div>
          <el-button type="primary" size="mini" @click="handleDLKHSet(2)"
            >重新设置</el-button
          >
        </div>
        <el-button v-else type="primary" size="mini" @click="handleDLKHSet(2)"
          >设置</el-button
        >
      </el-form-item>
      <el-form-item label="教师自评/互评" :label-width="formLabelWidth" prop>
        <div v-if="dialogActicity3 != null && dialogActicity3.length > 0">
          <div class="hd-box">
            <div class="hd-item" v-for="item in dialogActicity3" :key="item.Id">
              <div
                class="hd-zb"
                v-for="(it, index) in item.checkList"
                :key="index"
              >
                <div class="hd-name">{{ item.PJHDMC }}--</div>
                <div>{{ it.pjlx }}</div>
                <div class="hd-ipt">活动占比:</div>
                <el-input
                  style="max-width: 218px"
                  suffix-icon="el-icon-edit"
                  v-model="it.value"
                  autocomplete="on"
                >
                </el-input
                >%
              </div>
            </div>
          </div>
          <el-button type="primary" size="mini" @click="handleDLKHSet(3)"
            >重新设置</el-button
          >
        </div>
        <el-button v-else type="primary" size="mini" @click="handleDLKHSet(3)"
          >设置</el-button
        >
      </el-form-item>
      <el-form-item label="考核小组评" :label-width="formLabelWidth" prop>
        <div v-if="dialogActicity4 != null && dialogActicity4.length > 0">
          <div class="hd-box">
            <div class="hd-item" v-for="item in dialogActicity4" :key="item.Id">
              <div
                class="hd-zb"
                v-for="(it, index) in item.checkList"
                :key="index"
              >
                <div class="hd-name">{{ item.PJHDMC }}--</div>
                <div>{{ it.pjlx }}</div>
                <div class="hd-ipt">活动占比:</div>
                <el-input
                  style="max-width: 218px"
                  suffix-icon="el-icon-edit"
                  v-model="it.value"
                  autocomplete="on"
                >
                </el-input
                >%
              </div>
            </div>
          </div>
          <el-button type="primary" size="mini" @click="handleDLKHSet(4)"
            >重新设置</el-button
          >
        </div>
        <el-button v-else type="primary" size="mini" @click="handleDLKHSet(4)"
          >设置</el-button
        >
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer center">
      <el-button @click="cancel">取 消</el-button>
      <el-button v-if="GLHDBInfo" type="primary" @click="confirm(2)"
        >保存</el-button
      >
      <el-button v-else type="primary" @click="confirm(1)">确 定</el-button>
    </div>

    <!-- 定量考核关联活动弹窗 -->
    <Dialog1
      :dialogTitle="setHDdialogTitle"
      :activityList="activityList2"
      @cancelHDdialog="DLKHdialog = false"
      @sumbitForm="sumbitForm"
      v-model="DLKHdialog"
    />
  </div>
</template>

<script>
import Dialog1 from './tc/setHDDialog.vue'

export default {
  components: {
    Dialog1
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dialogTitle: String
  },
  model: {
    prop: 'visible',
    event: 'change'
  },

  data() {
    return {
      value: false,
      form: {
        BL: '',
        BL2: 0
      },
      formName: 'form',
      DLKHdialog: false,
      formLabelWidth: '20%',
      setHDdialogTitle: '',
      checkList: [],
      checkValue: false,
      rules: {},
      activityList: [],
      activityList2: [],
      user: '',
      rowData: '', //传递的哪一条数据
      dialogActicity1: [], //第1个弹窗返回的勾选列表
      dialogActicity2: [], //第2个弹窗返回的勾选列表
      dialogActicity3: [], //第3个弹窗返回的勾选列表
      dialogActicity4: [], //第4个弹窗返回的勾选列表
      GLHDBInfo: '', //关联活动表详情，回显数据
      MBInfo: 1
    }
  },

  created() {
    this.user = JSON.parse(localStorage.getItem('userinfo'))
    this.MBInfo = JSON.parse(localStorage.getItem('MBInfo'))
  },
  methods: {
    //匹配活动名称
    getHDName(data) {
      let name = ''
      let a = this.activityList.find(i => i.Id == data)
      if (a) {
        name = a.PJHDMC
      }
      return name
    },
    //匹配评价

    //获取关联活动表详情
    getGLHDBList() {
      this.$api.post(
        '/v1/mbbb/GetGLHDBList',
        {
          MBGLID: this.rowData.Id,
          token: this.user.Token
        },
        res => {
          if (res.Code === 0) {
            let list = res.Target
            if (list.length > 0) {
              this.GLHDBInfo = true
            } else {
              this.GLHDBInfo = false
            }
            //筛选是哪一个的活动
            this.dialogActicity1 = list.filter(i => i.HDLX == 1)
            this.dialogActicity2 = list.filter(i => i.HDLX == 2)
            this.dialogActicity3 = list.filter(i => i.HDLX == 3)
            this.dialogActicity4 = list.filter(i => i.HDLX == 4)
            this.form.BL = list.find(i => i.FID == 0 && i.LX == 1)?.BL || 0
            this.form.BL2 = list.find(i => i.FID == 0 && i.LX == 2)?.BL || 0

            // 把数据写成可以回显的格式
            this.dialogActicity1 = this.handleData(this.dialogActicity1)
            this.dialogActicity2 = this.handleData(this.dialogActicity2)
            this.dialogActicity3 = this.handleData(this.dialogActicity3)
            this.dialogActicity4 = this.handleData(this.dialogActicity4)
          }
        }
      )
    },
    //回显数据
    handleData(list) {
      let k = ['自评', '互评', '', '主评']
      let arr = list.reduce((pre, cur) => {
        let obj = pre.find(item => item.Id == cur.GLHD)

        if (cur.GLHD && obj) {
          //判断是那个HDLX
          if (obj.checkList) {
            obj.checkList.push({
              pjlx: k[cur.HDXS - 1],
              value: cur.BL
            })
          }
          // else {
          //   obj.checkList = [
          //     {
          //       pjlx: k[cur.HDXS - 1],
          //       value: cur.BL,
          //     },
          //   ];
          // }
        } else {
          if (cur.GLHD) {
            pre.push({
              Id: cur.GLHD,
              PJHDMC: this.getHDName(cur.GLHD),
              HDLX: cur.HDLX,
              checkList: [
                {
                  pjlx: k[cur.HDXS - 1],
                  value: cur.BL
                }
              ]
            })
          }
          // else {
          //   console.log('cur',cur);

          //   pre.push(cur);
          // }
        }
        return pre
      }, [])
      return arr
    },
    // 活动形式
    getHDXSarr(data) {
      let arr = []
      switch (data) {
        case 1: //主评
          arr.push({ pjlxname: '自评', pjlx: 1 })
          break
        case 2: //互评
          arr.push({ pjlxname: '互评', pjlx: 2 })
          break
        case 3:
          arr.push({ pjlxname: '自评', pjlx: 1 }, { pjlxname: '互评', pjlx: 2 })
          break
        case 4: //主评和自评
          arr.push({ pjlxname: '主评', pjlx: 4 })
          break
        case 5:
          arr.push({ pjlxname: '自评', pjlx: 1 }, { pjlxname: '主评', pjlx: 4 })
          break
        case 6:
          arr.push({ pjlxname: '互评', pjlx: 2 }, { pjlxname: '主评', pjlx: 4 })
          break
        case 7:
          arr.push(
            { pjlxname: '自评', pjlx: 1 },
            { pjlxname: '互评', pjlx: 2 },
            { pjlxname: '主评', pjlx: 4 }
          )
          break
      }
      return arr
    },
    //获取活动列表
    getActivityList() {
      return new Promise((resolve, reject) => {
        this.$api.get(
          '/v1/Growth/GetActivitye',
          {
            SSXX: this.user.SSZZId,
            token: this.user.Token
          },
          res => {
            if (res.Code === 0) {
              let list = res.Target || []
              // localStorage.setItem('',JSON.parse())
              let list2 = list.filter(c => c.BPJDXLX == 2)
              if (list2.length === 0) this.$message.error('未获取到活动')
              list2.forEach(item => {
                item.checkList = []
                item.HDLXarr = this.getHDXSarr(item.HDXS)
              })
              this.activityList = list2
              resolve()
            }
          },
          err => reject(err)
        )
      })
    },

    // 点击弹窗
    handleDLKHSet(type) {
      let keyObj = {
        1: { title: '定量考核关联活动', activityList: 'dialogActicity1' },
        2: { title: '学生家长评活动', activityList: 'dialogActicity2' },
        3: { title: '教师自评/互评', activityList: 'dialogActicity3' },
        4: { title: '考核小组评', activityList: 'dialogActicity4' }
      }
      this.setHDdialogTitle = keyObj[type].title

      //type=1是定量
      if (type == 1) {
        this.activityList2 = this.activityList.filter(item => item.HDLX == 1)
      } else {
        if (this.setHDdialogTitle.includes('教师')) {
          //如果是教师，去掉主评
          let list = this.activityList.filter(
            item => item.HDLX == 2 && item.HDXS != 4
          )
          // 如果是6，7等HDLX, 去掉主评
          list.forEach(item => {
            item.HDLXarr = item.HDLXarr.filter(i => i.pjlx !== 4)
          })
          this.activityList2 = list
        } else
          this.activityList2 = this.activityList.filter(item => item.HDLX == 2)
      }

      //清空每个定性考核中上一个弹窗选中的，回显也不用显示（注释的部分）
      this.activityList2.forEach(item => {
        item.checkList = []
        // let a = this[keyObj[type].activityList].find(it=>it.Id ==item.Id&&it.checkList.length>0)
        // if(a){
        //   item.checkList = a.checkList
        // }
      })
      this.DLKHdialog = true
    },

    //取消设置活动
    cancel() {
      this.$confirm('确认关闭？')
        .then(() => {
          this.$router.go(-1)
        })
        .catch(() => {})
    },
    //确认设置活动,保存设置活动占比
    confirm(type) {
      let c = [
        'dialogActicity1',
        'dialogActicity2',
        'dialogActicity3',
        'dialogActicity4'
      ]
      let arr = []

      c.forEach((t, index) => {
        this[t].forEach(item => {
          item.checkList.forEach(i => {
            arr.push({
              MBGLID: this.rowData.Id, //表格的row.id
              GLHD: item.Id, //活动id
              HDXS: i.pjlx == '自评' ? 1 : i.pjlx == '互评' ? 2 : 4,
              BL: i.value, // 占比
              PJFS: i.PJFS,
              LX: index ? 2 : 1, //定性定量
              HDLX: index + 1, //设置活动弹窗从1开始
              FID: index ? 'dxkh' : 'dlkh', //设置活动弹窗为1
              SSXX: this.user.SSZZId,
              CJR: this.user.Id,
              MBLX: this.MBInfo.MBDM //模板类型
            })
          })
        })
      })

      let e = [
        {
          BL: Number(this.form.BL2 || 0), // 占比
          CJR: this.user.Id,
          Id: 'dxkh',
          LX: 2, //1.定量 2.定性
          FID: 0, //定性定量
          SSXX: this.user.SSZZId,
          MBGLID: this.rowData.Id, //表格的row.id
          MBLX: this.MBInfo.MBDM //模板类型
        },
        {
          BL: Number(this.form.BL || 0), // 占比
          CJR: this.user.Id,
          Id: 'dlkh',
          LX: 1, //1.定量 2.定性
          FID: 0, //定性定量
          SSXX: this.user.SSZZId,
          MBGLID: this.rowData.Id, //表格的row.id
          MBLX: this.MBInfo.MBDM //模板类型
        }
      ]
      // console.log(arr.concat(e), "arr.concat(e)");
      this.$api.post(
        '/v1/mbbb/AddGLHDB',
        {
          GLHDBInfo: arr.concat(e),
          MBGLID: this.rowData.Id,
          token: this.user.Token
        },
        res => {
          if (res.Code === 0) {
            this.$message({
              type: 'success',
              message: type == 1 ? '新增成功' : '编辑成功',
              showClose: true
            })
            this.$router.push({ name: 'zhbbmbglindex' })
          } else {
            this.$message.error(res.Message)
          }
        }
      )
    },
    sumbitForm({ type, a }) {
      this.DLKHdialog = false
      if (!type) return
      let keyObj = {
        1: 'dialogActicity1',
        2: 'dialogActicity2',
        3: 'dialogActicity3',
        4: 'dialogActicity4'
      }
      if (a) {
        this[keyObj[type]] = a.reduce((pre, cur) => {
          let arr = cur.checkList.map(it => {
            return {
              pjlx: it,
              PJFS: cur.PJFS,
              value: 0
            }
          })

          pre.push({
            Id: cur.Id,
            PJHDMC: cur.PJHDMC,
            checkList: arr
          })
          return pre
        }, [])
      } else this[keyObj[type]] = []
    }
  },
  watch: {
    value(val) {
      // this.roleList = [];
      // this.reportTemplateList = [];
      if (val) {
        // this.init();
      } else {
        // this.$refs.dialog.$el.scrollTop = 0;
        this.form = {}
        this.$emit('change', false)
      }
    },
    visible(val) {
      this.value = val
    }
  },
  async mounted() {
    await this.getActivityList()
    this.rowData = this.$route.params.data
    this.getGLHDBList()
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  ::v-deep .el-form-item {
    .el-form-item__content {
      line-height: 1.5;
    }
  }
}
.el-input-number {
  ::v-deep .el-input-number__decrease {
    display: none;
  }
  ::v-deep .el-input-number__increase {
    display: none;
  }
  ::v-deep .el-input__inner {
    padding: 0;
  }
}
.hd-box {
  display: flex;
  flex-direction: column;
  .hd-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px 20px;
    .hd-name {
      // width: 20%;
      font-size: 14px;
      font-weight: 600;
    }
    .hd-zb {
      margin-left: 15px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .hd-ipt {
        margin-left: 10px;
      }
    }
  }
}
</style>
