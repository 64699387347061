<template>
  <div>
    <div style="display: flex;">
        <div class="box">选择模板：
          <div v-for="(item,index) in MBList" :key="index" @click="goPage(item.Id)"
             class="item">
             <el-button type="primary" size="small">{{ item.MBMC }}</el-button>
            </div>
        </div>
    </div>
        <router-view></router-view>
    
  </div>
</template>

<script>
export default {
    data(){
        return{
            user:'',
            MBList:'',
        }
    },
    created(){
        this.user = JSON.parse(localStorage.getItem("userinfo"));
        this.getMBBList()
    },

    methods:{
        getMBBList(){
            this.$api.post(
                '/v1/mbbb/GetMBBList',
                {
                    Express:'',
                    token:this.user.Token
                },
                r=>{
                    if(r.Code == 0){
                        console.log(r.Target,'getMBBList');
                        this.MBList = r.Target
                    }
                }
            )
        },
        goPage(value){
            this.$router.push({name:'developmentcenter',params:{data:value}})
        }
    }

}
</script>

<style>

</style>