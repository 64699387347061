import api from "./http";

// 其它设置相关接口 --------------------------------

// 获取其它设置表格数据
export const getOtherSetTabel = (params) => {
  return new Promise((resolve, reject) => {
    api.get(
      "v1/Growth/GetSysActivity",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};
// 其它设置 新增
export const addOtherSetForm = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "/v1/Growth/AddSysActivity",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 其它设置 编辑
export const editOtherSetForm = (params) => {
  return new Promise((resolve, reject) => {
    api.put(
      "/v1/Growth/EditSysActivity",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 其它设置 删除
export const delOtherSetROw = (params) => {
  return new Promise((resolve, reject) => {
    api.delete(
      "/v1/Growth/DeleteSysActivity",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};

// 维度报告设置 ------------------------------------------------

// 查询一级页面综合素养
export const getFirstLevel = (params) => {
  return new Promise((resolve, reject) => {
    api.get(
      "/v1/Growth/GetComprehensive",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || {});
      },
      (err) => reject(err)
    );
  });
};
// 新增一级页面 综合素养
export const saveFirstLevel = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "/v1/Growth/AddComprehensive",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 编辑一级页面 综合素养
export const editFirstLevel = (params) => {
  return new Promise((resolve, reject) => {
    api.put(
      "/v1/Growth/EditComprehensive",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};

// 获取一级维度报告设置
export const getiFrstDimensionReport = (params) => {
  return new Promise((resolve, reject) => {
    api.get(
      "v1/Growth/GetDimensionReport",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || {});
      },
      (err) => reject(err)
    );
  });
};
// 新增一级维度
export const saveFirstDimension = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/AddOrUpdateDimensionReport",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 编辑一级维度
export const saveFirstDimensions = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/AddOrUpdateDimensionReports",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 开启成长报告
export const WhetherOpen = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "/v1/Growth/WhetherOpen",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};

// 删除一级维度
export const deletFirstDimension = (params) => {
  return new Promise((resolve, reject) => {
    api.delete(
      "v1/Growth/DeleteDimensionReport",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 获取一级维度下的学科
export const getFirstDimensionSubject = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      `v1/Growth/GetDimensionSubject?ssxx=${params.ssxx}`,
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};
// 新增二级页面设置
export const addSecond = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/AddVeidooSet",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 新增智能评语
export const addComment = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "/v1/Growth/AddOrUpdateCommentSet",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 获取智能评语
export const getComment = (params) => {
  return new Promise((resolve, reject) => {
    api.get(
      "/v1/Growth/GetCommentSet",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target);
      },
      (err) => reject(err)
    );
  });
};
// 新增学科
export const AddSubjectSet = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/AddSubjectSet",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 获取学科表格
export const GetSubjectSet = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/GetSubjectSet",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};
// 删除学科
export const DeleteSubjectSet = (params) => {
  return new Promise((resolve, reject) => {
    api.delete(
      "v1/Growth/DeleteSubjectSet",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 获取展示模块
export const GetShowModule = (params) => {
  return new Promise((resolve, reject) => {
    api.get(
      "v1/Growth/GetShowModule",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};
// 新增展示模块
export const AddShowModule = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/AddShowModule",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 删除展示模块
export const DeleteShowModule = (params) => {
  return new Promise((resolve, reject) => {
    api.delete(
      "v1/Growth/DeleteShowModule",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 新增字段时 弹出的活动选择
export const GetMKActivity = (params) => {
  return new Promise((resolve, reject) => {
    api.get(
      "/v1/Growth/GetActivity",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};
// 勾选活动后 请求对应的指标
export const GetMKIndicators = (params) => {
  return new Promise((resolve, reject) => {
    api.get(
      "/v1/Growth/GetIndicators",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};
// 新增字段
export const AddShowModuleField = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/AddShowModuleField",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};
// 新增展示设置
export const AddShowModuleSet = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/AddShowModuleSet",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};

//
// 生成模板
export const createTemplate = (params) => {
  return new Promise((resolve, reject) => {
    api.get(
      "v1/Growth/CreateTemplate",
      params,
      (res) => {
        if (res.Code === 0) resolve();
      },
      (err) => reject(err)
    );
  });
};
// 生成评语
export const CreatePyData = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/CreatePyData",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Message);
      },
      (err) => reject(err)
    );
  });
};

//  获取成长报告设置统计活动
export const GetGrowthEvaSet = (params) => {
  return new Promise((resolve, reject) => {
    api.post(
      "v1/Growth/GetGrowthEvaSet",
      params,
      (res) => {
        if (res.Code === 0) resolve(res.Target || []);
      },
      (err) => reject(err)
    );
  });
};
