<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="grid-content bg-purple-light">
        <el-menu
          :default-active="selectType"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">学生获奖</el-menu-item>
          <el-menu-item index="2">教师获奖</el-menu-item>
          <el-menu-item index="3">班级获奖</el-menu-item>
          <el-menu-item index="4">社团获奖</el-menu-item>
        </el-menu>
      </div>
      <br />

      <!--学生获奖-->
      <div class="grid-content bg-purple-light" v-show="studenthtml">
        <!--列表-->
        <!--<el-button type="primary" icon="el-icon-edit" @click="">设置</el-button>-->
        <el-button plain icon="el-icon-edit" @click="opencategory"
          >类别管理</el-button
        >
        <el-button plain icon="el-icon-edit" @click="openaddstudent"
          >新增获奖等级</el-button
        >

        <br />
        <br />
        <el-table
          :data="studenttableData"
          class="jf-table"
          border
          tooltip-effect="dark"
          style="width: 100%;"
        >
          <el-table-column
            type="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column prop="HJDJMC" label="奖项等级"></el-table-column>
          <el-table-column prop="JF" label="学生加分"></el-table-column>
          <el-table-column prop="JSJF" label="班主任加分">
            <template slot-scope="scope">
              <span v-if="scope.row.JSJFLX == '1'">
                {{ scope.row.JSJF }}
              </span>
              <span v-else>{{ scope.row.JSJF }}%</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="studenteditRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="studentdeleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--教师获奖-->
      <div class="grid-content bg-purple-light" v-show="teacherhtml">
        <!--列表-->
        <el-button plain icon="el-icon-edit" @click="opencategory"
          >类别管理</el-button
        >
        <el-button plain icon="el-icon-edit" @click="openaddteacher"
          >新增获奖等级</el-button
        >

        <br />
        <br />
        <el-table
          :data="teachertableData"
          class="jf-table"
          border
          tooltip-effect="dark"
          style="width: 100%;"
        >
          <el-table-column
            type="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column prop="HJDJMC" label="奖项等级"></el-table-column>
          <el-table-column prop="JF" label="加分"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="teachereditRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="teacherdeleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--班级获奖-->
      <div class="grid-content bg-purple-light" v-show="classhtml">
        <!--列表-->
        <el-button plain icon="el-icon-edit" @click="opencategory"
          >类别管理</el-button
        >
        <el-button plain icon="el-icon-edit" @click="openaddclass"
          >新增获奖等级</el-button
        >

        <br />
        <br />
        <el-table
          :data="classtableData"
          class="jf-table"
          border
          tooltip-effect="dark"
          style="width: 100%;"
        >
          <el-table-column
            type="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column prop="HJDJMC" label="奖项等级"></el-table-column>
          <el-table-column prop="BJDF" label="班级得分"></el-table-column>
          <el-table-column prop="BZRDF" label="班主任加分"></el-table-column>
          <el-table-column prop="BJCYDF" label="班级成员得分"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="classeditRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="classdeleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--班级获奖-->
      <div class="grid-content bg-purple-light" v-show="associationhtml">
        <!--列表-->
        <el-button plain icon="el-icon-edit" @click="opencategory"
          >类别管理</el-button
        >
        <el-button plain icon="el-icon-edit" @click="openaddassociation"
          >新增获奖等级</el-button
        >

        <br />
        <br />
        <el-table
          :data="associationtableData"
          class="jf-table"
          border
          tooltip-effect="dark"
          style="width: 100%;"
        >
          <el-table-column
            type="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column prop="HJDJMC" label="奖项等级"></el-table-column>
          <el-table-column prop="DTJSDF" label="带团教师得分"></el-table-column>
          <el-table-column prop="TDCYDF" label="团队成员得分"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="associationeditRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="associationdeleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      title="新增"
      :visible.sync="studentdialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form
        :model="QYXSHJSZInfo"
        :rules="stduentrules"
        ref="stduentsbmitfrom"
      >
        <el-form-item
          label="奖项等级"
          :label-width="formLabelWidth"
          prop="HJDJMC"
        >
          <el-input v-model="QYXSHJSZInfo.HJDJMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分数" :label-width="formLabelWidth" prop="JF">
          <el-input v-model="QYXSHJSZInfo.JF" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="老师类型" :label-width="formLabelWidth">
          <el-radio-group v-model="QYXSHJSZInfo.JSJFLX">
            <el-radio :label="1">分数</el-radio>
            <el-radio :label="2">比列</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="老师加分"
          :label-width="formLabelWidth"
          v-if="QYXSHJSZInfo.JSJFLX == '1'"
          prop="JSJF"
        >
          <el-input
            v-model="QYXSHJSZInfo.JSJF"
            autocomplete="off"
            style="width: 95%;"
          ></el-input>
          <span>分</span>
        </el-form-item>
        <el-form-item
          label="老师加分"
          :label-width="formLabelWidth"
          v-else
          prop="JSJF"
        >
          <el-input
            v-model="QYXSHJSZInfo.JSJF"
            autocomplete="off"
            style="width: 95%;"
          ></el-input>
          <span>%</span>
        </el-form-item>
        <el-form-item label :label-width="formLabelWidth"
          >备注：比例，占学生加分的百分比</el-form-item
        >
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="studentdialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitstudentdata"
          :disabled="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--教师获奖弹出框-->
    <el-dialog
      title="新增"
      :visible.sync="teacherdialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form
        :model="QYJSHJSZInfo"
        :rules="teacherrules"
        ref="teachersbmitfrom"
      >
        <el-form-item
          label="奖项等级"
          :label-width="formLabelWidth"
          prop="HJDJMC"
        >
          <el-input v-model="QYJSHJSZInfo.HJDJMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分数" :label-width="formLabelWidth" prop="JF">
          <el-input v-model="QYJSHJSZInfo.JF" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="teacherdialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitteacherdata"
          :disabled="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--班级获奖弹出框-->
    <el-dialog
      title="新增"
      :visible.sync="classdialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="QYBJHJSZInfo" :rules="classrules" ref="classsbmitfrom">
        <el-form-item
          label="奖项等级"
          :label-width="formLabelWidth"
          prop="HJDJMC"
        >
          <el-input v-model="QYBJHJSZInfo.HJDJMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="班级得分"
          :label-width="formLabelWidth"
          prop="BJDF"
        >
          <el-input v-model="QYBJHJSZInfo.BJDF" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="班主任得分"
          :label-width="formLabelWidth"
          prop="BZRDF"
        >
          <el-input v-model="QYBJHJSZInfo.BZRDF" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="班级成员得分"
          :label-width="formLabelWidth"
          prop="BJCYDF"
        >
          <el-input v-model="QYBJHJSZInfo.BJCYDF" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="classdialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitclassdata"
          :disabled="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--社团获奖弹出框-->
    <el-dialog
      title="新增"
      :visible.sync="associationdialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form
        :model="QYSTHJSZInfo"
        :rules="associationrules"
        ref="associationsbmitfrom"
      >
        <el-form-item
          label="奖项等级"
          :label-width="formLabelWidth"
          prop="HJDJMC"
        >
          <el-input v-model="QYSTHJSZInfo.HJDJMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="带团教师得分"
          :label-width="formLabelWidth"
          prop="DTJSDF"
        >
          <el-input v-model="QYSTHJSZInfo.DTJSDF" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="团队成员得分"
          :label-width="formLabelWidth"
          prop="TDCYDF"
        >
          <el-input v-model="QYSTHJSZInfo.TDCYDF" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="associationdialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="submitassociationdata"
          :disabled="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--类别设置弹出框-->
    <el-dialog
      title="类别管理"
      :visible.sync="categorydialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <div class="table-er-box" style="float: left;">
        <el-button type="primary" icon="el-icon-edit" @click="addcategory"
          >新增</el-button
        >
      </div>
      <br />
      <br />
      <el-table
        class="jf-table"
        border
        tooltip-effect="dark"
        :data="categorylist"
      >
        <el-table-column prop="LBMC" label="类别名称">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.LBMC"
              placeholder="类别名称"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="SSWD" label="所属维度">
          <template slot-scope="scope">
            <el-select v-model="scope.row.SSWD" placeholder="请选择维度">
              <el-option
                v-for="option in wdlist"
                :key="option.Id"
                :label="option.WDMC"
                :value="option.Id"
                >{{ option.WDMC }}</el-option
              >
            </el-select>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="deletecategory(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="categorydialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="savecategory" :disabled="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isNumber } from "@/style/js/Validator.js";

export default {
  name: "AreaPrizeSet",
  data() {
    return {
      isDisabled: false,
      token: this.$store.state.token,
      // loading:true,
      studenttableData: [],
      teachertableData: [],
      classtableData: [],
      associationtableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,

      studentdialogFormVisible: false,
      teacherdialogFormVisible: false,
      classdialogFormVisible: false,
      associationdialogFormVisible: false,
      studenthtml: true,
      teacherhtml: false,
      classhtml: false,
      associationhtml: false,
      formLabelWidth: "120px",
      props: {
        label: "name",
        children: "zones"
      },
      userId: "",
      username: "",
      selectType: "1",
      parentId: "",
      // 类别管理
      categorydialogFormVisible: false,
      categorylist: [],
      wdlist: [],

      // 学生获奖
      QYXSHJSZInfo: {
        Id: "",
        HJDJMC: "",
        JF: 0,
        JSJF: 0,
        JSJFLX: 1,
        CJR: "",
        CJSJ: "2019-10-14 01:07:03",
        SSQY: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0
      },
      // 教师获奖
      QYJSHJSZInfo: {
        Id: "",
        HJDJMC: "",
        JF: 0,
        CJR: "",
        CJSJ: "2019-10-14 01:08:08",
        SSQY: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0
      },
      // 班级获奖
      QYBJHJSZInfo: {
        Id: "",
        HJDJMC: "",
        BJDF: 0,
        BZRDF: 0,
        BJCYDF: 0,
        CJR: "",
        CJSJ: "2019-10-14 01:09:11",
        SSQY: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0
      },
      // 社团获奖
      QYSTHJSZInfo: {
        Id: "",
        HJDJMC: "",
        DTJSDF: 0,
        TDCYDF: 0,
        CJR: "",
        CJSJ: "2019-10-14 01:10:31",
        SSQY: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0
      },
      stduentrules: {
        HJDJMC: [
          {
            required: true,
            message: "请输入获奖等级",
            trigger: "blur"
          }
        ],
        JF: [
          {
            validator: isNumber,
            message: "加分必须为数字",
            trigger: "blur"
          }
        ],
        JSJF: [
          {
            validator: isNumber,
            message: "教师加分必须为数字",
            trigger: "blur"
          }
        ]
      },
      teacherrules: {
        HJDJMC: [
          {
            required: true,
            message: "请输入获奖等级",
            trigger: "blur"
          }
        ],
        JF: [
          {
            validator: isNumber,
            message: "加分必须为数字",
            trigger: "blur"
          }
        ]
      },
      classrules: {
        HJDJMC: [
          {
            required: true,
            message: "请输入获奖等级",
            trigger: "blur"
          }
        ],
        BJDF: [
          {
            validator: isNumber,
            message: "班级得分必须为数字",
            trigger: "blur"
          }
        ],
        BZRDF: [
          {
            validator: isNumber,
            message: "班主任加分必须为数字",
            trigger: "blur"
          }
        ],
        BJCYDF: [
          {
            validator: isNumber,
            message: "班级成员得分必须为数字",
            trigger: "blur"
          }
        ]
      },
      associationrules: {
        HJDJMC: [
          {
            required: true,
            message: "请输入获奖等级",
            trigger: "blur"
          }
        ],
        DTJSDF: [
          {
            validator: isNumber,
            message: "带团教师得分必须为数字",
            trigger: "blur"
          }
        ],
        TDCYDF: [
          {
            validator: isNumber,
            message: "团队成员得分必须为数字",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.username = userinfo.DLM;
    this.userId = userinfo.Id;
    this.parentId = userinfo.SSZZId;
    if (this.parentId.length > 6) {
      this.refloadlist();
    }
  },
  methods: {
    // 切换列表
    handleSelect(key) {
      this.selectType = key;

      if (this.parentId.length > 6) {
        this.refloadlist();
      }
    },
    // 刷新列表
    refloadlist: function() {
      this.studenthtml = false;
      this.teacherhtml = false;
      this.classhtml = false;
      this.associationhtml = false;
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };

      if (this.selectType == 1) {
        this.studenthtml = true;

        this.$api.post(
          "v1/areaprizeset/GetStudentPrizeList",
          { SSQY: this.parentId, PagerInfo: pagerInfo, token: this.token },
          r => {
            if (r.Code === 0) {
              this.studenttableData = r.Target;

              // this.currentPage = r.PagerInfo.PageIndex;
              // this.pagesize = r.PagerInfo.PageSize;
              // this.rowcount = r.PagerInfo.RowCount;
            }
          }
        );
      } else if (this.selectType == 2) {
        this.teacherhtml = true;
        this.$api.post(
          "v1/areaprizeset/GetTeacherPrizeList",
          { SSQY: this.parentId, PagerInfo: pagerInfo, token: this.token },
          r => {
            if (r.Code === 0) {
              this.teachertableData = r.Target;

              // this.currentPage = r.PagerInfo.PageIndex;
              // this.pagesize = r.PagerInfo.PageSize;
              // this.rowcount = r.PagerInfo.RowCount;
            }
          }
        );
      } else if (this.selectType == 3) {
        this.classhtml = true;
        this.$api.post(
          "v1/areaprizeset/GetClassPrizeList",
          { SSQY: this.parentId, PagerInfo: pagerInfo, token: this.token },
          r => {
            if (r.Code === 0) {
              this.classtableData = r.Target;

              // this.currentPage = r.PagerInfo.PageIndex;
              // this.pagesize = r.PagerInfo.PageSize;
              // this.rowcount = r.PagerInfo.RowCount;
            }
          }
        );
      } else if (this.selectType == 4) {
        this.associationhtml = true;
        this.$api.post(
          "v1/areaprizeset/GetAssociationPrizeList",
          { SSQY: this.parentId, PagerInfo: pagerInfo, token: this.token },
          r => {
            if (r.Code === 0) {
              this.associationtableData = r.Target;

              // this.currentPage = r.PagerInfo.PageIndex;
              // this.pagesize = r.PagerInfo.PageSize;
              // this.rowcount = r.PagerInfo.RowCount;
            }
          }
        );
      }
    },

    //* **********************学生获奖***********************

    // 打开新增学生
    openaddstudent: function() {
      if (this.parentId === "") {
        this.$message({
          type: "info",
          message: "请选择区域"
        });
        return;
      }
      if (this.parentId.length <= 6) {
        this.$message({
          type: "info",
          message: "该区域不能新增"
        });
        return;
      }
      this.studentdialogFormVisible = true;
      this.QYXSHJSZInfo = {
        Id: "",
        HJDJMC: "",
        JF: 0,
        JSJF: 0,
        JSJFLX: 1,
        CJR: "",
        CJSJ: "2019-10-14 01:07:03",
        SSQY: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0
      };
      this.QYXSHJSZInfo.SSQY = this.parentId;
      this.QYXSHJSZInfo.CJR = this.userId;
      this.isDisabled = false;
    },

    // 学生修改
    studenteditRow: function(index, row) {
      this.isDisabled = false;
      this.QYXSHJSZInfo = row;
      this.studentdialogFormVisible = true;
    },
    // 学生删除
    studentdeleteRow: function(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/areaprizeset/DeleteStudentPrize",
            {
              Id: row.Id,
              SSQY: this.parentId,
              YHId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 保存学生
    submitstudentdata: function() {
      if (this.QYXSHJSZInfo.HJDJMC.length > 8) {
        this.$message({
          type: "info",
          message: "奖项等级过长"
        });
      }
      this.$refs.stduentsbmitfrom.validate(valid => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/areaprizeset/AddStudentPrize",
            { QYXSHJSZInfo: this.QYXSHJSZInfo, token: this.token },
            r => {
              if (r.Code === 0) {
                this.studentdialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.isDisabled = false;
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },

    //* **********************教师获奖***************************

    // 打开新增
    openaddteacher: function() {
      if (this.parentId === "") {
        this.$message({
          type: "info",
          message: "请选择区域"
        });
        return;
      }
      if (this.parentId.length <= 6) {
        this.$message({
          type: "info",
          message: "该区域不能新增"
        });
        return;
      }
      this.isDisabled = false;
      this.teacherdialogFormVisible = true;
      this.QYJSHJSZInfo = {
        Id: "",
        HJDJMC: "",
        JF: 0,
        CJR: "",
        CJSJ: "2019-10-14 01:08:08",
        SSQY: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0
      };
      this.QYJSHJSZInfo.SSQY = this.parentId;
      this.QYJSHJSZInfo.CJR = this.userId;
    },

    // 修改
    teachereditRow: function(index, row) {
      this.isDisabled = false;
      this.QYJSHJSZInfo = row;
      this.teacherdialogFormVisible = true;
    },
    // 删除
    teacherdeleteRow: function(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/areaprizeset/DeleteTeacherPrize",
            {
              Id: row.Id,
              SSQY: this.parentId,
              YHId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 保存
    submitteacherdata: function(event) {
      event.preventDefault();
      if (this.QYJSHJSZInfo.HJDJMC.length > 8) {
        this.$message({
          type: "info",
          message: "奖项等级过长"
        });
      }
      this.$refs.teachersbmitfrom.validate(valid => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/areaprizeset/AddTeacherPrize",
            { QYJSHJSZInfo: this.QYJSHJSZInfo, token: this.token },
            r => {
              if (r.Code === 0) {
                this.teacherdialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.isDisabled = false;
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },

    //* **********************班级获奖***************************
    // 打开新增
    openaddclass: function() {
      if (this.parentId === "") {
        this.$message({
          type: "info",
          message: "请选择区域"
        });
        return;
      }
      if (this.parentId.length <= 6) {
        this.$message({
          type: "info",
          message: "该区域不能新增"
        });
        return;
      }
      this.classdialogFormVisible = true;
      this.isDisabled = false;
      this.QYBJHJSZInfo = {
        Id: "",
        HJDJMC: "",
        BJDF: 0,
        BZRDF: 0,
        BJCYDF: 0,
        CJR: "",
        CJSJ: "2019-10-14 01:09:11",
        SSQY: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0
      };
      this.QYBJHJSZInfo.SSQY = this.parentId;
      this.QYBJHJSZInfo.CJR = this.userId;
    },

    // 修改
    classeditRow: function(index, row) {
      this.isDisabled = false;
      this.QYBJHJSZInfo = row;
      this.classdialogFormVisible = true;
    },
    // 删除
    classdeleteRow: function(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/areaprizeset/DeleteClassPrize",
            {
              Id: row.Id,
              SSQY: this.parentId,
              YHId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 保存
    submitclassdata: function(event) {
      event.preventDefault();
      if (this.QYBJHJSZInfo.HJDJMC.length > 8) {
        this.$message({
          type: "info",
          message: "奖项等级过长"
        });
      }
      this.$refs.classsbmitfrom.validate(valid => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/areaprizeset/AddClassPrize",
            { QYBJHJSZInfo: this.QYBJHJSZInfo, token: this.token },
            r => {
              if (r.Code === 0) {
                this.classdialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.isDisabled = false;
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },

    //* **********************社团获奖***************************
    // 打开新增
    openaddassociation: function() {
      if (this.parentId === "") {
        this.$message({
          type: "info",
          message: "请选择区域"
        });
        return;
      }
      if (this.parentId.length <= 6) {
        this.$message({
          type: "info",
          message: "该区域不能新增"
        });
        return;
      }
      this.associationdialogFormVisible = true;
      this.QYSTHJSZInfo = {
        Id: "",
        HJDJMC: "",
        DTJSDF: 0,
        TDCYDF: 0,
        CJR: "",
        CJSJ: "2019-10-14 01:10:31",
        SSQY: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0
      };
      this.isDisabled = false;
      this.QYSTHJSZInfo.SSQY = this.parentId;
      this.QYSTHJSZInfo.CJR = this.userId;
    },
    // 修改
    associationeditRow: function(index, row) {
      this.QYSTHJSZInfo = row;
      this.associationdialogFormVisible = true;
      this.isDisabled = false;
    },
    // 删除
    associationdeleteRow: function(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/areaprizeset/DeleteAssociationPrize",
            {
              Id: row.Id,
              SSQY: this.parentId,
              YHId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 保存
    submitassociationdata: function(event) {
      event.preventDefault();
      if (this.QYSTHJSZInfo.HJDJMC.length > 8) {
        this.$message({
          type: "info",
          message: "奖项等级过长"
        });
      }
      this.$refs.associationsbmitfrom.validate(valid => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/areaprizeset/AddAssociationPrize",
            { QYSTHJSZInfo: this.QYSTHJSZInfo, token: this.token },
            r => {
              if (r.Code === 0) {
                this.associationdialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });
                this.refloadlist();
              } else {
                this.isDisabled = false;
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },

    /** **********************类别管理*************************************/
    // 打开类别
    opencategory: function() {
      this.categorylist = [];
      if (this.parentId === "") {
        this.$message({
          type: "info",
          message: "请选择区域"
        });
        return;
      }
      if (this.parentId.length <= 6) {
        this.$message({
          type: "info",
          message: "该区域不能新增"
        });
        return;
      }
      this.categorydialogFormVisible = true;
      // 获取维度
      this.$api.post(
        "v1/areaprizeset/GetWDBListTreeByAreaId",
        { AreaId: this.parentId, token: this.token },
        r => {
          if (r.Code === 0) {
            this.wdlist = r.Target;
          }
        }
      );
      // 获取类别列表
      this.loadlbdata();
    },
    loadlbdata: function() {
      // 获取类别列表
      this.$api.post(
        "v1/areaprizeset/GetQYHJLBGLListByType",
        {
          AreaId: this.parentId,
          PrizeCategory: this.selectType,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.isDisabled = false;
            var resultlist = r.Target;
            if (resultlist !== null) {
              this.categorylist = resultlist;
            }
          }
        }
      );
    },
    // 打开新增
    addcategory: function() {
      var categoryinfo = {
        Id: "",
        CJR: this.userId,
        CJSJ: "2020-01-07",
        SSQY: this.parentId,
        SYZT: 0,
        SFSC: 0,
        LBMC: "",
        SSWD: "",
        LBSS: this.selectType
      };
      this.isDisabled = false;
      this.categorylist.push(categoryinfo);
    },
    // 删除类别
    deletecategory: function(index, row) {
      this.categorylist.splice(this.categorylist.indexOf(row), 1);
      if (row.Id !== "") {
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            // 提交
            this.$api.post(
              "v1/areaprizeset/DeleteQYHJLBGL",
              {
                Id: row.Id,
                SSQY: this.parentId,
                YHId: this.userId,
                token: this.token
              },
              r => {
                if (r.Code === 0) {
                  this.loadlbdata();
                  this.$message({
                    type: "success",
                    message: r.Message,
                    duration: 3000
                  });
                }
              }
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      }
    },
    // 保存
    savecategory: function() {
      if (this.categorylist.length === 0) {
        this.$message({
          type: "info",
          message: "未添加类别",
          showClose: true
        });
        return;
      }
      var isadd = false;
      var result = "";
      this.categorylist.forEach((item, index) => {
        if (!isadd) {
          var row = index + 1;
          if (item.LBMC === "") {
            result = "第" + row + "行,类别名称";
            isadd = true;
            return;
          }
          if (item.SSWD === "") {
            result = "第" + row + "行,未选择维度";
            isadd = true;
          }
        }
      });
      if (isadd) {
        this.$message({
          type: "info",
          message: result,
          showClose: true
        });
        return;
      }
      // 提交
      this.isDisabled = true;
      this.$api.post(
        "v1/areaprizeset/AddQYHJLBGL",
        {
          CategoryList: this.categorylist,
          SSQY: this.parentId,
          YHId: this.userId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message,
              duration: 3000
            });
            this.loadlbdata();
          } else {
            this.isDisabled = false;
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    }
  }
};
</script>

<style>
.customWidth {
  width: 45%;
}
</style>
