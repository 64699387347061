<template>
  <div class="FinalArchives_Export">
    <div class="filterBtn">
      排序：
      <el-radio-group v-model="sort">
        <el-radio :label="1">按学号</el-radio>
        <el-radio :label="2">按学籍号</el-radio>
        <el-radio :label="3">按姓名</el-radio>
      </el-radio-group>
    </div>
    <div class="main">
      <div class="multipleBtn">
        <el-button type="primary" size="mini" @click="dowLoad(0)"
          >年级导出</el-button
        >
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        @selection-change="selectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="Name" label="班级"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="preview(scope.row)"
              >预览</el-button
            >
            <el-button size="mini" type="warning" @click="dowLoad(scope.row)"
              >导出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="预览" :visible.sync="previewShow" width="90%">
      <div class="previewDom"></div>
    </el-dialog>
  </div>
</template>

<script>
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
export default {
  name: 'FinalArchives_Export',
  data() {
    return {
      userInfo: {},
      terms: [],
      term: {},
      sort: 1,
      list: [],
      selected: [],
      grade: {},
      gradeCourse: [],
      teachers: [],
      students: [],
      report: [],
      previewShow: false
    }
  },
  async mounted() {
    let { gradeId, curTermId } = this.$route.query
    this.userInfo = JSON.parse(localStorage.getItem('userinfo'))

    this.terms = JSON.parse(localStorage.getItem('terms'))
    this.term = this.terms.find(item => item.Id === curTermId)

    this.teachers = JSON.parse(localStorage.getItem('schoolTeachers'))
    this.students = JSON.parse(localStorage.getItem('schoolStudents'))
    this.gradeList = JSON.parse(localStorage.getItem('schoolGrades'))
    this.grade = this.gradeList.find(item => item.Id === gradeId)

    this.list = this.grade.ChidOrgList || []
    await this.getGradeCourse()
  },
  methods: {
    // 获取年级课程
    getGradeCourse() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/xxkcb/GetGradeCourse',
          {
            GradeId: this.grade.GradeNumber + '',
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.gradeCourse = res.Target || []
              resolve()
            }
          }
        )
      })
    },
    selectionChange(data) {
      this.selected = data
    },
    // 预览
    preview(row) {
      this.dowLoad(row, 1)
    },
    // 下载
    async dowLoad(row, type) {
      let BJID = ''
      if (row) {
        BJID = row.Id
        this.selected = [row]
      } else {
        if (this.selected.length > 0)
          BJID = this.selected.map(item => item.Id).join(',')
        else {
          this.selected = this.grade.ChidOrgList
          BJID = this.grade.ChidOrgList.map(item => item.Id).join(',')
        }
      }

      await this.getReport(BJID)
      if (type === 1) {
        // 预览
        this.preExcel()
      } else {
        this.export2Excel()
      }
    },
    // 获取报表
    getReport(BJID) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/ReportCenter/GetXYBBList',
          {
            BJID,
            SSXX: this.userInfo.SSZZId,
            SSXQ: this.term.Id,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.report = res.Target
              this.handleSort()
              resolve()
            }
          }
        )
      })
    },
    // 数据处理
    handleSort() {
      this.report.forEach(item => {
        item.KCLB.forEach(it => {
          let course = this.gradeCourse.find(
            course => it.KCID === course.KCYSId
          )
          if (course) it.courseName = course.KCMC
        })
        item.KCLB.sort((a, b) => a.PXH - b.PXH)

        let bj = this.grade.ChidOrgList.find(bj => bj.Id === item.BJID)
        if (bj) {
          item.className = bj.Name
          let teacher = this.teachers.find(teacher => teacher.Id === bj.BZRID)
          if (teacher) item.BZR = teacher.Name
        }
        let studenInfo = this.students.find(student => student.Id === item.XSID)
        if (studenInfo) {
          item.Name = studenInfo.Name
          item.XMPY = studenInfo.XMPY
          item.XJH = studenInfo.XJH
          item.XH = studenInfo.XH
        }
      })
    },
    // 导出
    export2Excel() {
      // 排序 1.学号 2.学籍号 3.姓名
      let keyArr = ['XH', 'XJH']
      this.report.sort((a, b) => {
        let aa = ''
        let bb = ''
        let key = keyArr[this.sort - 1]
        if (this.sort === 3) {
          return a.XMPY.localeCompare(b.XMPY)
        } else {
          if (a[key] && a[key].match(/\d+/) && a[key].match(/\d+/)[0]) {
            aa = Number(a[key].match(/\d+/)[0])
          }
          if (b[key] && b[key].match(/\d+/) && b[key].match(/\d+/)[0]) {
            bb = Number(b[key].match(/\d+/)[0])
          }
          return aa - bb
        }
      })
      let zip = new JSZip()
      let folder = zip.folder(this.grade.Name)
      let classArr = this.selected.map(classItem => classItem)
      this.selected = []
      import('@/style/js/Export2Excel').then(excel => {
        classArr.forEach(classItem => {
          let data = this.report.filter(item => item.BJID === classItem.Id)
          if (data && data.length > 0) {
            let multiHeader = [
              classItem.Name,
              '',
              '',
              `班主任：${data[0].BZR}`,
              '',
              '',
              '',
              '',
              `学期：${this.term.Name}`,
              '',
              '',
              '',
              '',
              '',
              '',
              '学生综合成绩册'
            ]
            let merges2 = []
            let baseNum = 'C'.charCodeAt() //最开始要合并的地方
            let aNum = 'A'.charCodeAt() //字母A开始的数字
            let multiHeader2 = data[0].KCLB.reduce(
              (pre, cur, idx) => {
                let num = baseNum + idx * 3
                let preLetter = ''
                let nexLetter = ''
                if (num > 90) {
                  // 如果大于90 那么要从新从A开始 因为表格超过字母Z后又从A开始了
                  num = num - 90
                  num = num - 1
                  preLetter = `A${String.fromCharCode(aNum + num)}`
                  nexLetter = `A${String.fromCharCode(aNum + num + 2)}`
                } else if (num <= 90 && num + 2 > 90) {
                  preLetter = String.fromCharCode(num)
                  num = num + 2 - 90
                  num = num - 1
                  nexLetter = `A${String.fromCharCode(aNum + num)}`
                } else {
                  preLetter = String.fromCharCode(num)
                  nexLetter = String.fromCharCode(num + 2)
                }
                merges2.push(`${preLetter}2:${nexLetter}2`)
                return pre.concat([cur.courseName, '', ''])
              },
              ['', '']
            )
            let header = data[0].KCLB.reduce(
              (pre, cur) => {
                return pre.concat(['平时', '阶段', '总评'])
              },
              ['序号', '姓名']
            )
            let data2 = data.reduce((pre, cur, index) => {
              let arr = cur.KCLB.reduce((p, c) => {
                if (p.length === 0) p = p.concat([index + 1, cur.Name])
                return p.concat([c.PS || '', c.JD || '', c.ZP || ''])
              }, [])
              pre.push(arr)
              return pre
            }, [])
            while (multiHeader.length !== header.length) {
              multiHeader.push('')
            }
            let merges = ['A2:B2', 'A1:B1', 'D1:G1', 'I1:N1', 'P1:T1']
            let filename = classItem.Name
            let { excelData } = excel.json_to_excel2({
              header,
              multiHeader: [multiHeader, multiHeader2],
              data: data2,
              merges: [...merges, ...merges2],
              filename
            })
            folder.file(classItem.Name + '.xlsx', excelData, { type: 'blob' })
          }
        })
        zip.generateAsync({ type: 'blob' }).then(content => {
          saveAs(content, `${this.grade.Name}期末归档` + '.zip')
        })
      })
    },
    // 预览
    preExcel() {
      // 排序 1.学号 2.学籍号 3.姓名
      let keyArr = ['XH', 'XJH']
      this.report.sort((a, b) => {
        let aa = ''
        let bb = ''
        let key = keyArr[this.sort - 1]
        if (this.sort === 3) {
          return a.XMPY.localeCompare(b.XMPY)
        } else {
          if (a[key] && a[key].match(/\d+/) && a[key].match(/\d+/)[0]) {
            aa = Number(a[key].match(/\d+/)[0])
          }
          if (b[key] && b[key].match(/\d+/) && b[key].match(/\d+/)[0]) {
            bb = Number(b[key].match(/\d+/)[0])
          }
          return aa - bb
        }
      })
      let classArr = this.selected.map(classItem => classItem)
      this.selected = []
      import('@/style/js/Export2Excel').then(excel => {
        classArr.forEach(classItem => {
          let data = this.report.filter(item => item.BJID === classItem.Id)
          if (data && data.length > 0) {
            let multiHeader = [
              classItem.Name,
              '',
              '',
              `班主任：${data[0].BZR}`,
              '',
              '',
              '',
              '',
              `学期：${this.term.Name}`,
              '',
              '',
              '',
              '',
              '',
              '',
              '学生综合成绩册'
            ]
            let merges2 = []
            let baseNum = 'C'.charCodeAt() //最开始要合并的地方
            let aNum = 'A'.charCodeAt() //字母A开始的数字
            let multiHeader2 = data[0].KCLB.reduce(
              (pre, cur, idx) => {
                let num = baseNum + idx * 3
                let preLetter = ''
                let nexLetter = ''
                if (num > 90) {
                  // 如果大于90 那么要从新从A开始 因为表格超过字母Z后又从A开始了
                  num = num - 90
                  num = num - 1
                  preLetter = `A${String.fromCharCode(aNum + num)}`
                  nexLetter = `A${String.fromCharCode(aNum + num + 2)}`
                } else if (num <= 90 && num + 2 > 90) {
                  preLetter = String.fromCharCode(num)
                  num = num + 2 - 90
                  num = num - 1
                  nexLetter = `A${String.fromCharCode(aNum + num)}`
                } else {
                  preLetter = String.fromCharCode(num)
                  nexLetter = String.fromCharCode(num + 2)
                }
                merges2.push(`${preLetter}2:${nexLetter}2`)
                return pre.concat([cur.courseName, '', ''])
              },
              ['', '']
            )
            let header = data[0].KCLB.reduce(
              (pre, cur) => {
                return pre.concat(['平时', '阶段', '总评'])
              },
              ['序号', '姓名']
            )
            let data2 = data.reduce((pre, cur, index) => {
              let arr = cur.KCLB.reduce((p, c) => {
                if (p.length === 0) p = p.concat([index + 1, cur.Name])
                return p.concat([c.PS || '', c.JD || '', c.ZP || ''])
              }, [])
              pre.push(arr)
              return pre
            }, [])
            while (multiHeader.length !== header.length) {
              multiHeader.push('')
            }
            let merges = ['A2:B2', 'A1:B1', 'D1:G1', 'I1:N1', 'P1:T1']
            let filename = classItem.Name
            let { XLSX2, ws } = excel.json_to_excel2({
              header,
              multiHeader: [multiHeader, multiHeader2],
              data: data2,
              merges: [...merges, ...merges2],
              filename
            })
            let res = XLSX2.utils.sheet_to_html(ws)
            this.previewShow = true
            this.$nextTick(() => {
              let previewDom = document.querySelector('.previewDom')
              previewDom.innerHTML = res
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.FinalArchives_Export {
  .filterBtn {
    padding: 10px;
    margin-bottom: 15px;
  }
  .main {
    .multipleBtn {
      width: 100px;
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .previewDom {
    ::v-deep table {
      width: 100%;
      text-align: center;
      border-top: 1px solid #ccc;
      tr {
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        td {
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}
</style>
