<template>
  <div>
    <div class="cont-whole-box">
      <div style="padding: 30px;">
        <div class="essay-name-box">{{ rowData && rowData.BBMC }}</div>
        <div class="tab-heng-box" style="margin-top: 10px">
          <div class="operate-box borderno-l">
            <!-- 多学期查询 -->
            <div class="operate-item" v-if="rowData.DXQ == 1">
              <div class="operate-item-name">学期:</div>
              <el-select
                size="small"
                v-model="form.curTerm"
                placeholder="请选择学期"
                @change="termChange"
              >
                <el-option
                  v-for="term in terms"
                  :key="term.Id"
                  :label="term.Name"
                  :value="term.Id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- ---------------- -->
            <div>
              <!-- 按周 -->
              <div class="operate-item" v-if="rowData.RQLX == 1">
                <div class="operate-item-name">周次:</div>
                <el-select
                  size="small"
                  v-model="form.curWeek"
                  placeholder="请选择周次"
                >
                  <el-option
                    v-for="week in weekList"
                    :key="week.Id"
                    :label="week.label"
                    :value="week.Id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 本周 本期 -->
              <div
                class="operate-item"
                v-else-if="rowData.RQLX == 2 && rowData.DXQ !== 1"
              >
                <div class="operate-item-name">本周本期:</div>
                <el-select size="small" v-model="form.week_term">
                  <el-option
                    v-for="item in week_term_option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 按月 -->
              <div class="operate-item" v-else-if="rowData.RQLX == 4">
                <div class="operate-item-name">月份:</div>
                <el-select
                  size="small"
                  v-model="form.curMonth"
                  placeholder="请选择月份"
                >
                  <el-option
                    v-for="item in monthList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 按时间段 -->
              <div class="operate-item" v-else-if="rowData.RQLX == 3">
                <div class="operate-item-name">时间:</div>
                <el-date-picker
                  size="small"
                  popper-class="datepicker-z-index"
                  style="width: 140px;"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="开始日期"
                  v-model="form.beginTime"
                ></el-date-picker>
                <span style="padding:0 10px">~</span>
                <el-date-picker
                  size="small"
                  popper-class="datepicker-z-index"
                  style="width: 140px;"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="结束日期"
                  v-model="form.endTime"
                ></el-date-picker>
              </div>
            </div>
            <!-- 3是全部 1是班级 2是年级 -->
            <div class="grade_class">
              <!-- 按年级 -->
              <div
                class="operate-item"
                v-if="rowData.BJCX === 2 || rowData.BJCX === 1"
              >
                <div class="operate-item-name">年级:</div>
                <el-select
                  size="small"
                  v-model="form.nj"
                  placeholder="请选择年级"
                  @change="njChange"
                >
                  <el-option
                    v-for="nj in gradeList"
                    :label="nj.Name"
                    :key="nj.Id"
                    :value="nj.Id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 按班级 -->
              <div class="operate-item" v-if="rowData.BJCX == 1">
                <div class="operate-item-name">班级:</div>
                <el-select
                  size="small"
                  v-model="form.bj"
                  placeholder="请选择年级"
                >
                  <el-option
                    v-for="bj in classList"
                    :label="bj.Name"
                    :key="bj.Id"
                    :value="bj.Id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 学科 -->
              <div class="operate-item" v-if="rowData.XK == 1">
                <div class="operate-item-name">学科:</div>
                <el-select
                  size="small"
                  v-model="form.XKID"
                  placeholder="请选择学科"
                >
                  <el-option
                    v-for="item in schoolCourse"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 搜索 -->
              <div class="operate-item" v-if="rowData.ZDYCX == 1">
                <el-input
                  size="small"
                  v-model="form.search"
                  :placeholder="rowData.ZDYCXTS"
                ></el-input>
              </div>
            </div>

            <div class="seac-btn-box" style="margin-right:10px">
              <el-button
                size="small"
                type="primary"
                class="seac-btn-boxa"
                @click="loaddata"
                >搜索</el-button
              >
            </div>
            <div class="seac-btn-box">
              <el-button
                size="small"
                type="primary"
                class="seac-btn-boxa"
                @click="handleExport"
                >导出</el-button
              >
            </div>
          </div>
          <table cellpadding="0" cellspacing="0" v-if="headers.length > 0">
            <thead>
              <tr>
                <td v-for="(header, key) in headers" :key="key">
                  {{ header }}
                </td>
              </tr>
            </thead>
            <tbody v-if="rows.length > 0">
              <tr v-for="(row, key) in rows" :key="key">
                <td v-for="(column, key) in row.Value" :key="key">
                  {{ column }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.rowData = this.$route.params.row;
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      this.userInfo = JSON.parse(tempuser);
    }
  },
  async mounted() {
    await this.getSchoolTerms();
    await this.getSchoolCourse();
    await this.getSchoolGrades();
    await this.getUserClass();
    await this.getUserCourse();
    this.handleReportType();
  },
  methods: {
    // 获取所有学期
    getSchoolTerms() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetTerm",
          {
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            token: this.userInfo.Token
          },
          r => {
            if (r.Code === 0) {
              this.terms = r.Target || [];
              this.term = this.terms.find(item => item.IsCurrent === 1);
              this.form.curTerm = this.term.Id;
              resolve();
            }
          }
        );
      });
    },
    // 获取学校课程
    getSchoolCourse() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolCurriculum",
          {
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            token: this.userInfo.Token
          },
          r => {
            if (r.Code === 0) {
              let arr = r.Target || [];
              this.schoolCourse = arr.filter(item => item.SFSC !== 1);
              resolve();
            }
          }
        );
      });
    },
    // 获取登录人课表
    getUserCourse() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetUserCurriculum",
          {
            UserIdOrGrade: this.userInfo.Id, //	是	string	教师Id或年级数字
            TermId: this.term.Id, //	是	string	学期Id
            SchoolId: this.userInfo.SSZZId, //	是	string	学校信息
            GetDataType: 1, //	是	int	1 教师所受课程 2班级所属课程
            token: this.userInfo.Token
          },
          r => {
            if (r.Code === 0) {
              this.userCourse = r.Target || [];
              resolve();
            }
          }
        );
      });
    },
    // 获取登录人所教班级
    getUserClass() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetUserClassOrCurriculum",
          {
            UserId: this.userInfo.Id, //	是	string	用户Id
            TermId: this.term.Id, //	是	string	学期ID
            token: this.userInfo.Token
          },
          r => {
            if (r.Code === 0) {
              this.userClass = r.Target || [];
              resolve();
            }
          }
        );
      });
    },
    // 获取学校组织机构
    getSchoolGrades() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolOrg",
          { SchoolId: this.userInfo.SSZZId, token: this.userInfo.Token },
          r => {
            if (r.Code === 0) {
              let arr = r.Target || [];
              arr.unshift({
                Name: "全部",
                Id: "all"
              });
              arr.forEach(item => {
                if (item.ChidOrgList) {
                  item.ChidOrgList.unshift({
                    Name: "全部",
                    Id: "all"
                  });
                } else {
                  item.ChidOrgList = [
                    {
                      Name: "全部",
                      Id: "all"
                    }
                  ];
                }
              });
              this.baseGradeList = arr;
              this.gradeList = arr;
              this.form.nj = this.gradeList[0].Id;
              this.classList = this.gradeList[0].ChidOrgList;
              this.form.bj = this.classList[0].Id;
              resolve();
            }
          }
        );
      });
    },
    loaddata: function() {
      // 年级全部就传学校id 班级全部就传年级id
      let ZZJGId = "";
      if (this.rowData.BJCX === 2) {
        ZZJGId = this.form.nj === "all" ? this.userInfo.SSZZId : this.form.nj;
      }
      if (this.rowData.BJCX === 1) {
        if (this.form.nj === "all") {
          ZZJGId = this.userInfo.SSZZId;
        }
        if (this.form.nj !== "all") {
          ZZJGId = this.form.bj === "all" ? this.form.nj : this.form.bj;
        }
      }
      // 日期
      let BeginDate = "";
      let EndDate = "";
      if (this.rowData.RQLX === 1) {
        // 周 如果没有多学期 以当前学期的周 如果是多学期 以当前选中的学期的周
        let week = this.term.weekList.find(
          item => item.Id === this.form.curWeek
        );
        if (week) {
          BeginDate = week.BeginTime;
          EndDate = week.EndTime;
        }
      } else if (this.rowData.RQLX === 2) {
        // 本周本期
        if (this.term.weekList) {
          if (this.form.week_term === 1) {
            let week = this.term.weekList.find(
              item =>
                new Date(item.BeginTime) <= Date.now() &&
                Date.now() <= new Date(item.EndTime)
            );
            if (week) {
              BeginDate = week.BeginTime;
              EndDate = week.EndTime;
            }
          } else {
            BeginDate = this.term.BeginTime;
            EndDate = this.term.EndTime;
          }
        }
      } else if (this.rowData.RQLX === 3) {
        // 开始结束时间
        BeginDate = this.form.beginTime;
        EndDate = this.form.endTime;
      } else {
        // 月
        let month = this.monthList.find(
          item => item.value === this.form.curMonth
        );
        if (month) {
          let monthBegin = new Date(month.year, month.month - 1, 1);
          let monthEnd = new Date(month.year, month.month, 0);
          BeginDate = `${monthBegin.getFullYear()}-${monthBegin.getMonth() +
            1}-${monthBegin.getDate()} 00:00:00`;
          EndDate = `${monthEnd.getFullYear()}-${monthEnd.getMonth() +
            1}-${monthEnd.getDate()} 23:59:59`;
        }
      }
      // 学科
      let XKID = "";
      if (this.rowData.XK === 1) {
        XKID = this.form.XKID;
      }
      let UserBasicsMessage = {
        UserId: this.rowData.DLYH === 1 ? this.userInfo.Id : null,
        SchoolCode: this.userInfo.SchoolCode
      };
      let data = {
        SSXX: this.userInfo.SSZZId,
        SSXQ: this.term.Id,
        BBId: this.rowData.Id,
        BeginDate,
        EndDate,
        ZZJGId,
        XKID,
        XQLX: this.term.XQLX,
        InputStr: this.form.search,
        token: this.userInfo.Token,
        UserBasicsMessage
      };
      this.$api.post("v1/customreport/GetReportData", data, r => {
        if (r.Code === 0 && r.Target != null) {
          this.headers = r.Target.Headers;
          if (r.Target.Rows) {
            r.Target.Rows.forEach(item => {
              item.Value.forEach((el, index) => {
                if (typeof el === "string") {
                  if (el.includes("AM")) {
                    item.Value[index] = el.replace("AM", "");
                  }
                  if (el.includes("PM")) {
                    item.Value[index] = el.replace("PM", "");
                  }
                }
              });
            });
          }
          this.rows = r.Target.Rows;
        }
      });
    },
    // 判断报表是哪种模式
    handleReportType() {
      // 日期查询类型
      if (this.rowData.DXQ) {
        this.form.curTerm = this.term.Id;
      }

      if (this.rowData.RQLX === 1) {
        // 按周
        this.handleWeekList();
      }
      if (this.rowData.RQLX === 2) {
        //本周本期
        this.form.week_term = 1;
      }
      if (this.rowData.RQLX === 4) {
        // 按月
        this.handleMonthList();
      }
      if (this.rowData.RQLX === 3) {
        // 日期段
        this.form.beginTime = this.term.BeginTime;
        this.form.endTime = this.term.EndTime;
      }

      if (this.rowData.BJCX === 1) {
        // 班级查询
      }
      if (this.rowData.BJCX === 2) {
        // 年级查询
      }
      if (this.rowData.BJCX === 3) {
        //年级班级查询
      }
      if (this.rowData.XK === 1) {
        // 学科
      }
      this.loaddata();
    },
    // 处理默认周和周下拉
    handleWeekList() {
      if (this.term.weekList) {
        this.term.weekList.forEach(item => {
          item.label = "第" + item.WeekNumber + "周";
          if (
            new Date(item.BeginTime) <= Date.now() &&
            new Date(item.EndTime) >= Date.now()
          )
            this.form.curWeek = item.Id;
        });
        this.term.weekList.sort((a, b) => a.WeekNumber - b.WeekNumber);
        this.weekList = this.term.weekList;
      } else {
        this.weekList = [];
        this.form.curWeek = "";
      }
    },
    // 处理默认月和月下拉
    handleMonthList() {
      let firstMonth = new Date(this.term.BeginTime).getMonth() + 1;
      let firstYear = new Date(this.term.BeginTime).getFullYear();
      let lastMonth = new Date(this.term.EndTime).getMonth() + 1;
      let lastYear = new Date(this.term.EndTime).getFullYear();
      let year = "";
      let month = "";
      if (lastMonth - firstMonth < 0) {
        lastMonth = lastMonth + 12;
      }
      this.monthList = [];
      for (let i = firstMonth; i <= lastMonth; i++) {
        let label = "";
        let value = "";
        if (i > 12) {
          label = lastYear + "年" + (i - 12) + "月";
          value = i - 12 + "+";
          year = lastYear;
          month = i - 12;
        } else {
          label = firstYear + "年" + i + "月";
          value = i + "";
          year = firstYear;
          month = i;
        }
        this.monthList.push({
          label,
          value,
          year,
          month
        });
      }

      this.form.curMonth = this.monthList.find(
        item =>
          item.value === new Date().getMonth() + 1 + "" ||
          item.value === new Date().getMonth() + 1 + "+"
      ).value;
    },
    // 学期变化
    async termChange() {
      let term = this.terms.find(item => item.Id === this.form.curTerm);
      if (term) {
        this.term = term;
        this.handleWeekList();
        this.handleMonthList();
        await this.getUserClass();
        await this.getUserCourse();
        await this.getSchoolGrades();
      }
    },
    njChange() {
      let grade = this.gradeList.find(item => item.Id === this.form.nj);
      this.classList = grade.ChidOrgList;
      this.form.bj = this.classList[0].Id;
    },
    // 最后处理登录人所能看到的报表0
    handleFinal() {
      // 判断登录人是否管理员 是否班主任 是否科任 根据这些条件来显示对应的筛选条件
      // 1.管理员(教育局人员)查看所有 2.班主任查看班主任班级所有 3.科任查看所教班级所教课程
      if (this.userInfo.YHLX === 4) {
        //管理员全部显示
      } else if (this.userInfo.YHLX === 1) {
        //
      }
    },
    // 导出
    handleExport() {
      import("@/style/js/Export2ExcelSheetArray").then(excel => {
        let tHeader = this.headers;
        let data = [
          {
            Sheet: "自定义报表",
            Data: this.rows.map(item => item.Value)
          }
        ];
        excel.export_json_to_excel({
          header: tHeader, //表头 必填
          data, //具体数据 必填
          filename: new Date().getTime() + "" //非必填
        });
      });
    }
  },

  data() {
    return {
      form: {
        curTerm: "",
        curWeek: "",
        curMonth: "",
        week_term: "",
        beginTime: "",
        endTime: "",
        XKID: "",
        nj: "",
        bj: "",
        search: ""
      },
      userInfo: {},
      terms: [],
      term: {},
      weekList: [],
      monthList: [],
      week_term_option: [
        {
          label: "本周",
          value: 1
        },
        {
          label: "本期",
          value: 2
        }
      ],
      headers: [],
      baseGradeList: [],
      gradeList: [],
      classList: [],
      schoolCourse: [],
      userClass: [],
      userCourse: [],
      rows: [],
      rowData: {}
    };
  }
};
</script>

<style lang="scss" scoped>
.operate-box {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  .operate-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    .operate-item-name {
      margin-right: 5px;
    }
    .el-select {
      width: 150px;
    }
  }
  .grade_class {
    display: flex;
  }
}
</style>
