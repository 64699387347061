<template>
  <div>
    <div class="ditab-box margno">
      <div class="ditab-cent">
        <div class="ditab-cent-left">
          <span class="ditab-cent-leftspan">家校共育</span>
        </div>
        <div class="ditab-cent-rig">
          <div class="ditab-rig-box">
            <div class="ditab-rig-bl">教师发起的计划</div>
            <div class="ditab-rig-al">
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  正常完成
                  <input
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 5px;
                    "
                    type="text"
                    placeholder="输入"
                    class="popv-tab-inpt"
                    v-model="personScoreSet.Data.TeacherPlanScore"
                  />
                  分
                </div>
              </div>
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  延时完成
                  <input
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 5px;
                    "
                    type="text"
                    placeholder="输入"
                    class="popv-tab-inpt"
                    v-model="personScoreSet.Data.TeacherPlanDelayScore"
                  />
                  分
                </div>
              </div>
            </div>
          </div>
          <div class="ditab-rig-box">
            <div class="ditab-rig-bl">家长发起的计划</div>
            <div class="ditab-rig-al">
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  正常完成
                  <input
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 5px;
                    "
                    type="text"
                    placeholder="输入"
                    class="popv-tab-inpt"
                    v-model="personScoreSet.Data.ParentPlanScore"
                  />
                  分
                </div>
              </div>
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  延时完成
                  <input
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 5px;
                    "
                    type="text"
                    placeholder="输入"
                    class="popv-tab-inpt"
                    v-model="personScoreSet.Data.ParentPlanDelayScore"
                  />
                  分
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ditab-cent">
        <div class="ditab-cent-left">
          <span class="ditab-cent-leftspan">作业管理</span>
        </div>
        <div class="ditab-cent-rig">
          <div class="ditab-rig-box">
            <div class="ditab-rig-bl">完成一次</div>
            <div class="ditab-rig-al">
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  <input
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 5px;
                    "
                    type="text"
                    placeholder="输入"
                    class="popv-tab-inpt"
                    v-model="personScoreSet.Data.HomeWorkScore"
                  />
                  分
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ditab-cent">
        <div class="ditab-cent-left">
          <span class="ditab-cent-leftspan">运动量及阅读数据</span>
        </div>
        <div class="ditab-cent-rig">
          <div class="ditab-rig-box">
            <div class="ditab-rig-bl">完成一次</div>
            <div class="ditab-rig-al">
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  1小时运动量
                  <input
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 5px;
                    "
                    type="text"
                    placeholder="输入"
                    class="popv-tab-inpt"
                    v-model="personScoreSet.Data.SportScore"
                  />
                  分
                </div>
              </div>
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  1小时阅读量
                  <input
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 5px;
                    "
                    type="text"
                    placeholder="输入"
                    class="popv-tab-inpt"
                    v-model="personScoreSet.Data.ReadScore"
                  />
                  分
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ditab-cent">
        <div class="ditab-cent-left">
          <span class="ditab-cent-leftspan">学业成绩</span>
        </div>
        <div class="ditab-cent-rig">
          <div class="ditab-rig-box">
            <div class="ditab-rig-bl">考试类别</div>
            <div class="ditab-rig-al">
              <div
                v-for="item in personScoreSet.ExaminationTypes"
                :key="item.Key"
                class="disilb-box-l"
                style="width: 200px;"
              >
                <el-radio v-model="currentKSLB" :label="item.Key">{{
                  item.Value
                }}</el-radio>
              </div>
            </div>
          </div>
          <div class="ditab-rig-box">
            <div
              class="ditab-rig-bl"
              style="height: auto; line-height: 1; border-left: none;"
            >
              计入系数
            </div>
            <div class="ditab-rig-al" style="height: auto;">
              <div
                class="tab-heng-box"
                style="
                  margin-top: 10px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                "
              >
                <div class="prompt-title-box">
                  注:假设某生语文成绩为100分,记入系数为10%,则记入10分进学生综合得分
                </div>
                <table cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <td>学科</td>
                      <td
                        v-for="(item, index) in personScoreSet.GradeList"
                        :key="item.Id"
                        :class="{ actived: actived === index }"
                        @click="clickHead(index)"
                        @contextmenu.prevent="rightClickHead(index)"
                      >
                        {{ item.Name }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in personScoreSet.CourseList"
                      :key="item.Id"
                    >
                      <td>{{ item.KCMC }}</td>
                      <td
                        v-for="(grade, i) in personScoreSet.GradeList.length"
                        :key="grade"
                        :class="{ actived: i === actived }"
                      >
                        <input
                          style="width: 50px;"
                          type="text"
                          placeholder="请输入"
                          class="popv-tab-inpt"
                          v-model="
                            personScoreSet.Data.CoursePercentSetList.find(
                              c =>
                                c.CourseId === item.Id &&
                                c.KSLB === parseInt(currentKSLB)
                            ).Percents[i]
                          "
                        />
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cent-bott-butt">
      <el-button :loading="isSaving" plain @click="save">提&nbsp;交</el-button>
    </div>

    <!-- 个人综合分 学业成绩批量填充 -->
    <el-dialog title="批量填充分数" :visible.sync="batchForm.show" width="30%">
      <el-input-number
        v-model="batchForm.val"
        :min="0"
        :max="100"
        label="请输入整数"
        :precision="0"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchForm.show = false">取 消</el-button>
        <el-button type="primary" @click="handleBatch">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSaving: false,
      scoreEditVisible: false,
      currentKSLB: 1,
      actived: "",
      batchForm: {
        show: false,
        val: ""
      }
    };
  },
  props: {
    personScoreSet: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    if (JSON.stringify(this.classScoreSet) === "{}") {
      this.isEdit = true;
    }
    this.init();
  },
  methods: {
    init() {
      for (let i = 0; i < this.personScoreSet.CourseList.length; i++) {
        for (let kslx of this.personScoreSet.ExaminationTypes) {
          if (
            this.personScoreSet.Data.CoursePercentSetList.findIndex(
              c =>
                c.CourseId === this.personScoreSet.CourseList[i] &&
                c.KSLB === kslx.Key
            ) === -1
          ) {
            const Percents = [];
            for (let i = 0; i < this.personScoreSet.GradeList.length; i++) {
              Percents.push("");
            }
            this.personScoreSet.Data.CoursePercentSetList.push({
              CourseId: this.personScoreSet.CourseList[i].Id,
              KSLB: kslx.Key,
              Percents: Percents
            });
          }
        }
      }
      // this.currentKSLB=this.personScoreSet.Data.
    },
    save() {
      this.isSaving = true;
      this.$api.post(
        "/v1/growthsystem/SetPersonScoreSet",
        {
          token: this.token,
          StudentScoreSetData: this.personScoreSet.Data,
          UserId: this.user.Id,
          UserName: this.user.XSM,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.isSaving = false;
          }
        }
      );
    },
    // 单机表头
    clickHead(index) {
      if (this.actived === "") {
        this.actived = index;
      } else {
        this.actived === index ? (this.actived = "") : (this.actived = index);
      }
    },
    // 右击表头
    rightClickHead(index) {
      this.actived === index ? (this.batchForm.show = true) : "";
    },
    // 批量填充
    handleBatch() {
      this.personScoreSet.Data.CoursePercentSetList.forEach(item => {
        item.Percents &&
          item.Percents[this.actived] !== null &&
          item.Percents[this.actived] !== undefined &&
          (item.Percents[this.actived] = this.batchForm.val);
      });

      this.batchForm.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.actived {
  background-color: #f7f9fb;
}
</style>
