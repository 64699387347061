<template>
  <el-dialog
    title="分类设置"
    :visible.sync="visible"
    width="50%"
    :before-close="handleClose"
  >
    <!-- 分类设置 -->
    <div class="category-box">
      <!-- 顶部操作按钮 -->
      <div class="operate-btn">
        <el-button type="primary" plain @click="add">新增</el-button>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="FLMC" label="类别名称"> </el-table-column>
        <el-table-column width="150px" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              plain
              @click="add(1, scope.row)"
            >
              编辑
            </el-button>
            <el-button type="danger" size="mini" plain @click="del(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 表单 -->
    <el-dialog
      :title="formDialog.title"
      :visible.sync="formDialog.visible"
      width="40%"
      :before-close="submit"
      append-to-body
    >
      <el-form :model="formDialog.form" label-width="70px">
        <el-form-item label="类别名称" prop="name">
          <el-input v-model="formDialog.form.FLMC" />
        </el-form-item>
      </el-form>
      <div style="padding:15px;display:flex;justify-content:flex-end">
        <el-button @click="formDialog.visible = false">取 消</el-button>
        <el-button type="primary" @click="submit(1)">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      schoolId: "",
      token: "",
      user: {},
      tableData: [],
      formDialog: {
        title: "新增",
        visible: false,
        form: {
          Id: "",
          FLMC: "",
        },
      },
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.getData();
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
    getData() {
      this.$api.get(
        "/v1/StudentExchangeArticle/GetDHWPFLList",
        {
          SSXX: this.schoolId,
        },
        (r) => {
          if (r.Code === 0) {
            this.tableData = [];
            this.tableData = r.Target;
          }
        }
      );
    },
    add(type, row) {
      if (type === 1) {
        Object.assign(this.formDialog.form, row);
        this.formDialog.title = "编辑";
      } else {
        Object.keys(this.formDialog.form).forEach((key) => {
          this.formDialog.form[key] = "";
        });
        this.formDialog.title = "新增";
      }
      this.formDialog.visible = true;
    },
    del(row) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.delete(
          "/v1/StudentExchangeArticle/DeleteDHWPFL",
          {
            token: this.token,
            Id: row.Id,
          },
          (r) => {
            if (r.Code === 0) {
              this.getData();
              this.$emit("refreshCategory");
              this.$message({
                type: "success",
                message: r.Message,
              });
            }
          }
        );
      });
    },
    submit(type) {
      if (
        this.formDialog.form.FLMC === null ||
        this.formDialog.form.FLMC === "" ||
        this.formDialog.form.FLMC === undefined
      ) {
        return this.$message({
          type: "error",
          message: "请输入类别名称",
        });
      }
      var obj = {
        Id: this.formDialog.form.Id,
        FLMC: this.formDialog.form.FLMC,
        SSXX: this.schoolId,
        CJR: this.user.Id,
      };
      if (obj.Id === "") {
        this.$api.post(
          "/v1/StudentExchangeArticle/AddDHWPFL",
          {
            DHWPFLInfo: obj,
          },
          (r) => {
            if (r.Code === 0) {
              this.getData();
              this.formDialog.visible = false;
              this.$emit("refreshCategory");
              this.$message({
                type: "success",
                message: r.Message,
              });
            }
          }
        );
      } else {
        this.$api.put(
          "/v1/StudentExchangeArticle/UpdateDHWPFL",
          {
            DHWPFLInfo: obj,
          },
          (r) => {
            if (r.Code === 0) {
              this.getData();
              this.formDialog.visible = false;
              this.$emit("refreshCategory");
              this.$message({
                type: "success",
                message: r.Message,
              });
            }
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-box {
  padding: 10px;
  .operate-btn {
    padding: 10px;
    box-shadow: 1px 1px 2px 3px #f7f7f7;
    margin-bottom: 15px;
  }
  .el-table {
    box-shadow: 1px 1px 2px 3px #f7f7f7;
  }
}
</style>
