<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="40%"
    top="1%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      class="demo-ruleForm"
      status-icon
      label-position="right"
    >
      <el-form-item
        :label-width="labelWidth"
        label="物品名称"
        prop="WPMC"
        class="el-item-input"
      >
        <el-input v-model="formData.WPMC" style="width: 80%;"></el-input>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="兑换价格"
        prop="DHDJ"
        class="el-item-input"
      >
        <el-input
          :disabled="
            formData.Id && formData.DHKSRQ
              ? new Date(formData.DHKSRQ.replace(/-/g, '/')) <= new Date()
              : false
          "
          v-model="formData.DHDJ"
          style="width: 80%;"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="物品总数量"
        prop="ZSL"
        class="el-item-input"
      >
        <el-input
          :disabled="
            formData.Id && formData.DHKSRQ
              ? new Date(formData.DHKSRQ.replace(/-/g, '/')) <= new Date()
              : false
          "
          v-model="formData.ZSL"
          style="width: 80%;"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="兑换开始时间"
        prop="DHKSRQ"
        class="el-item-input"
      >
        <el-date-picker
          :disabled="
            formData.Id && formData.DHKSRQ
              ? new Date(formData.DHKSRQ.replace(/-/g, '/')) <= new Date()
              : false
          "
          style="width: 80%;"
          v-model="formData.DHKSRQ"
          type="datetime"
          placeholder="选择日期时间"
          :editable="false"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="兑换结束时间"
        prop="DHJZRQ"
        class="el-item-input"
      >
        <el-date-picker
          style="width: 80%;"
          v-model="formData.DHJZRQ"
          type="datetime"
          placeholder="选择日期时间"
          :editable="false"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="物品状态"
        prop="SYZT"
        class="el-item-input"
      >
        <el-select
          v-model="formData.SYZT"
          placeholder="请选择"
          style="width: 80%;"
        >
          <el-option
            v-for="item in [
              { value: 1, label: '正常' },
              { value: 2, label: '暂停' }
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="使用结束时间"
        prop="SYJSSJ"
        class="el-item-input"
      >
        <el-date-picker
          style="width: 80%;"
          v-model="formData.SYJSSJ"
          type="datetime"
          placeholder="选择日期时间"
          :editable="false"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="物品分类"
        prop="FLId"
        class="el-item-input"
      >
        <el-select
          v-model="formData.FLId"
          placeholder="请选择"
          style="width: 80%;"
        >
          <el-option
            v-for="item in category"
            :key="item.Id"
            :label="item.ArticleCategoryName"
            :value="item.Id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="物品属性"
        prop="WPLB"
        class="el-item-input"
      >
        <el-select
          v-model="formData.WPLB"
          placeholder="请选择"
          style="width: 80%;"
        >
          <el-option
            v-for="item in [
              { value: 1, label: '实物' },
              { value: 2, label: '虚拟' }
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="物品描述"
        class="el-item-input"
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 2 }"
          placeholder="请输入内容"
          v-model="formData.BZ"
          style="width: 80%;"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="每人每期兑换次数限制："
        class="el-item-input"
      >
        <el-input-number
          placeholder="请输入整数"
          v-model="formData.DHSL"
          style="width: 80%;"
          :min="0"
          :max="10000"
          :precision="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="允许兑换的年级："
        class="el-item-input"
      >
        <el-checkbox
          label="全选"
          v-model="gradeCheckAll"
          @change="
            grades = gradeCheckAll
              ? gradeList.map(item => item.GradeNumber)
              : [];
              isIndeterminate = false
          "
        />
        <el-checkbox-group
        v-model="grades"
        @change="
            gradeCheckAll =
              gradeList.length > 0 &&
              grades.length === gradeList.length;
          "
        >
          <el-checkbox
            v-for="grade in gradeList"
            :key="grade.GradeNumber"
            :label="grade.GradeNumber"
            >{{ grade.Name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        :label-width="labelWidth"
        label="排序"
        class="el-item-input"
      >
        <el-input-number
          size="small"
          placeholder="排序"
          :min="1"
          v-model="formData.PXH"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="物品图标"
        class="el-item-input"
      >
        <Upload
          :itemPic="formData.WPTP ? formData.WPTP : $store.state.defaultImage"
          :width="50"
          :height="50"
          @getResultUrl="getResultUrl"
          :circle="true"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { isFloat, isUintNumber } from '@/style/js/Validator.js'

import Upload from '@/components/Upload.vue'
export default {
  components: {
    Upload
  },
  data() {
    return {
      labelWidth: '140px',
      submitting: false,
      isIndeterminate: true,
      rules: {
        WPMC: [
          { required: true, message: '请输入物品名称', trigger: 'change' },
          {
            min: 1,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'change'
          }
        ],
        DHDJ: [
          { required: true, message: '请输入兑换价格', trigger: 'change' },
          {
            validator: isUintNumber,
            message: '价格必须为自然数',
            trigger: 'change'
          },
          {
            validator: isFloat,
            message: '价格最高精确到2位小数',
            trigger: 'change'
          }
        ],
        ZSL: [
          { required: true, message: '请输入物品总数量', trigger: 'change' },
          {
            validator: isUintNumber,
            message: '数量必须为自然数',
            trigger: 'change'
          }
        ],
        DHKSRQ: [
          { required: true, message: '请选择兑换开始时间', trigger: 'change' }
        ],
        DHJZRQ: [
          { required: true, message: '请选择兑换结束时间', trigger: 'change' }
        ],
        SYZT: [
          { required: true, message: '请选择物品状态', trigger: 'change' }
        ],
        FLId: [
          { required: true, message: '请选择物品分类', trigger: 'change' }
        ],
        SYJSSJ: [
          { required: true, message: '请选择使用结束时间', trigger: 'change' }
        ],
        WPLB: [{ required: true, message: '请选择物品属性', trigger: 'change' }]
      },
      formData: {
        DHSL:0,
        grades: []
      },
      gradeCheckAll: false,
      grades:[]
    }
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    },
    category: {
      type: Array,
      default: function() {
        return []
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: function() {
        return {}
      }
    },
    schoolId: {
      type: String,
      default: ''
    },
    token: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    gradeList: {
      type: Array
    }
  },
  methods: {
    async init() {
      this.formData = JSON.parse(JSON.stringify(this.data))
      this.grades = []
      let arr = []
      if(this.formData.DHWPSZList)
      arr = this.formData.DHWPSZList.filter(i=>i.DHSL>=0)
      if(arr.length === this.gradeList.label)this.gradeCheckAll = true
      if(this.formData.DHWPSZList.length>0){        
        if(arr.length>0){
          this.grades = arr.reduce((pre, cur) => {
            if (cur.NJ) {
              pre.push(cur.NJ)
            }
            return pre
          }, [])

          if(this.grades.length == this.gradeList.length)this.gradeCheckAll = true
        }
        // this.$set(
        //   this.grades,
        //   arr.length>0
        //     ? arr.reduce((pre, cur) => {
        //         if (cur.NJ) {
        //           pre.push(cur.NJ)
        //         }
        //         return pre
        //       }, [])
        //     : []
        // )
        
      } else {
        // this.formData.grades = []
        this.grades = []
      }
      if(arr.length>0){
        this.$set(
          this.formData,
          'DHSL',
          this.formData.DHWPSZList && this.formData.DHWPSZList[0]
            ? (arr[0].DHSL) || ''
            : ''
        )
      }else{
        this.formData.DHSL = 0
      }
      // 如果表单的分类id不在分类选项中 那么把表单的分类id置空
      if (this.formData.FLId) {
        let res = this.category.find(item => item.Id === this.formData.FLId)
        if (!res) this.formData.FLId = ''
      }
    },
    closeDialog() {
      this.$confirm('确认关闭？').then(() => {
        this.$emit('closeDialog')
      })
      // this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "保存",
      //   cancelButtonText: "放弃修改"
      // })
      //   .then(() => {
      //     this.confirmDialog();
      //   })
      //   .catch(action => {
      //     if (action === "cancel") {
      //       this.$emit("closeDialog");
      //     }
      //   });
    },
    confirmDialog() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.submitting = true
          let DHWPSZList = undefined
          if (this.formData.DHSL>=0) {
            DHWPSZList = this.grades.reduce((pre, cur) => {
              pre.push({
                DHSL: this.formData.DHSL,
                NJ: cur
              })
              return pre
            }, [])
          }
          // this.formData.grades = this.grades;
          console.log('DHWPSZList:',DHWPSZList);
          
          this.$api.post(
            '/v1/StudentExchangeArticle/EditArticleForWeb',
            {
              token: this.token,
              DHWPBInfo: {
                ...this.formData,
                DHWPSZList,
                grades: undefined,
                DHSL: undefined
              }
            },
            r => {
              if (r.Code === 0) {
                this.submitting = false
                this.$emit('closeDialog')
                this.$message({
                  type: 'success',
                  message: r.Message
                })
              }
            }
          )
        } else {
          return false
        }
      })
    },
    getResultUrl(path) {
      this.formData.WPTP = path
    }
  },
  watch: {
    visible(val) {
      if (val && this.title=='编辑物品') {
        this.init()
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }

        // 显示
      } else {
         // 隐藏
         this.formData = {}
         this.gradeCheckAll = false
        this.grades=[]
      }
    }
  }
}
</script>
