<template>
  <el-dialog
    class="answer-table"
    title="答案模板"
    :visible.sync="visible"
    width="50%"
    :before-close="close"
  >
    <el-button type="primary" size="mini" @click="addAnswer(0)">新增</el-button>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="MBMC" label="模板名称"> </el-table-column>
      <el-table-column label="操作">
        <template scope="{row}">
          <el-button type="primary" size="mini" @click="addAnswer(1, row)"
            >编辑</el-button
          >
          <el-button type="danger" size="mini" @click="del(row)"
            >删除</el-button
          >
          <el-button
            :type="row.SYZT === 1 ? 'success' : 'danger'"
            size="mini"
            @click="enabled(row)"
            >{{ row.SYZT === 1 ? "启用" : "停用" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Pager :pagerInfo="pager" @changeIndex="changeIndex" />
    <el-dialog
      class="answer-form-dialog"
      :title="formDialog.title"
      :visible.sync="formDialog.visible"
      width="60%"
      :before-close="submit"
      append-to-body
    >
      <el-form
        ref="form"
        class="answer-form"
        :model="formDialog.form"
        label-width="80px"
      >
        <el-form-item
          label="模板名称"
          prop="MBMC"
          style="display:inline-block;width:70%"
          :rules="[
            {
              required: true,
              message: '模板名称不能为空',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="formDialog.form.MBMC"></el-input>
        </el-form-item>
        <el-form-item label="启用" style="display:inline-block" prop="state">
          <el-switch
            v-model="formDialog.form.state"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          prop="WJDAList"
          :rules="[
            {
              validator: checkAnswerLength,
              message: '缺少答案',
              trigger: 'blur',
            },
          ]"
          class="jf_wj_dx"
        >
        </el-form-item>
        <div
          class="answer-item"
          v-for="(item, idx) in formDialog.form.WJDAList"
          :key="idx"
        >
          <el-form-item
            :prop="`WJDAList[${idx}].TMQZ`"
            :rules="[
              {
                required: true,
                message: '不能为空',
                trigger: 'blur',
              },
            ]"
          >
            <el-select
              size="small"
              style="width:65px"
              v-model="item.TMQZ"
              placeholder="请选择"
            >
              <el-option
                v-for="e in ZM"
                :key="e.value"
                :label="e.label"
                :value="e.value"
                :disabled="handleZMabled(formDialog.form.WJDAList, e.value)"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label-width="10px"
            style="flex:1"
            :prop="`WJDAList[${idx}].DAMC`"
            :rules="[
              {
                required: true,
                message: '答案名称不能为空',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              size="small"
              :readonly="item.FXLX === 2"
              v-model="item.DAMC"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="65px"
            label="分数："
            style="width:140px"
            :prop="`WJDAList[${idx}].DF`"
            :rules="[
              {
                required: true,
                message: '不能为空',
                trigger: 'blur',
              },
            ]"
          >
            <el-input-number
              size="small"
              :precision="2"
              :min="0"
              :max="999"
              v-model="item.DF"
            ></el-input-number>
          </el-form-item>

          <el-button size="mini" type="danger" @click="delAnswer(idx)"
            >删除</el-button
          >
        </div>
        <div class="push-answer">
          <el-button
            class="normal-answer"
            size="mini"
            plain
            :disabled="formDialog.form.WJDAList.length === 26"
            @click="pushAnswer(1)"
            icon="el-icon-plus"
            >添加普通选项</el-button
          >
          <el-button
            class="else-answer"
            size="mini"
            plain
            v-show="!formDialog.form.WJDAList.some((item) => item.FXLX === 2)"
            :disabled="formDialog.form.WJDAList.length === 26"
            @click="pushAnswer(2)"
            icon="el-icon-plus"
            >添加'其他'选项</el-button
          >
        </div>
      </el-form>
      <div class="footer">
        <el-button type="info" size="mini" @click="submit">取消</el-button>
        <el-button type="primary" size="mini" @click="submit(1)"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import Pager from "@/components/Pager.vue";
export default {
  props: {
    visible: Boolean,
  },
  components: {
    Pager,
  },
  data() {
    return {
      formDialog: {
        visible: false,
        title: "新增",
        form: {
          Id: "",
          MBMC: "",
          WJDAList: [],
          SYZT: 1,
          state: true,
        },
      },
      ZM: [],
      pager: {
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0,
      },
      userinfo: {},
      tableData: [],
    };
  },
  async mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.pager.pageIndex = 1;

    let arr = [];
    for (var i = 0; i < 25; i++) {
      let str = String.fromCharCode(65 + i);
      arr.push({
        label: str,
        value: str,
      });
    }
    this.ZM = arr;
    await this.getAnswer();
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    changeIndex(val) {
      this.pager.pageIndex = val;
      this.getTableData();
    },
    getAnswer() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/questionnaire/GetQueAnTem",
          {
            SchoolId: this.userinfo.SSZZId,
            token: this.userinfo.Token,
            PagerInfo: this.pager,
          },
          (res) => {
            if (res.Code === 0) {
              this.pager.totalCount = res.PagerInfo.RowCount;
              this.tableData = res.Target || [];
              this.tableData.forEach((item) => {
                if (item.WJDAList && item.WJDAList.every((it) => it.TMQZ)) {
                  item.WJDAList.sort((a, b) => a.TMQZ.localeCompare(b.TMQZ));
                }
              });
              resolve();
            }
          },
          (err) => reject(err)
        );
      });
    },
    addAnswer(type, row) {
      if (type === 1) {
        Object.assign(this.formDialog.form, row);
        this.formDialog.form.state = row.SYZT === 1 ? true : false;
      } else {
        this.formDialog.form.Id = "";
        this.formDialog.form.MBMC = "";
        this.formDialog.form.state = true;
        this.formDialog.form.WJDAList = [];
      }
      this.formDialog.title = type ? "编辑" : "新增";
      this.formDialog.visible = true;
    },
    // 删除模板
    del(row) {
      this.$confirm("确定删除模板？", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "取消",
      }).then(() => {
        this.$api.post(
          "v1/questionnaire/DelteAnTem",
          {
            token: this.userinfo.Token,
            UserId: this.userinfo.Id,
            Ids: [row.Id],
          },
          (res) => {
            if (res.Code === 0) {
              this.pager.pageIndex = 1;
              this.getAnswer();
            }
          }
        );
      });
    },
    // 禁用启用
    enabled(row) {
      this.$api.post(
        "v1/questionnaire/EnDisAnTem",
        {
          Id: row.Id,
          State: row.SYZT === 1 ? 2 : 1,
          token: this.userinfo.Token,
        },
        (res) => {
          if (res.Code === 0) {
            this.pager.pageIndex = 1;
            this.getAnswer();
          }
        }
      );
    },
    pushAnswer(FXLX) {
      let Id = new Date().getTime() + "";
      if (FXLX === 1) {
        this.formDialog.form.WJDAList.push({
          Id,
          DF: "",
          DAMC: "",
          FXLX,
        });
      } else {
        this.formDialog.form.WJDAList.push({
          Id,
          DF: "",
          DAMC: "其他",
          FXLX,
        });
      }
    },
    FSchange(rule, val, callback) {
      let reg = /^([1-9][0-9]?(\.[0-9]{1,2})?)$|^(0\.0[1-9])$|^(0\.[1-9][0-9]?)$|^100$/;
      if (!val) return callback(new Error("不能为空"));
      else if (!reg.test(val)) return callback(new Error("整数或2位小数"));
      else return callback();
    },
    checkAnswerLength(rule, val, callback) {
      if (val.length === 0) {
        return callback(new Error("答案不能为空"));
      } else {
        return callback();
      }
    },
    // 删除答案
    delAnswer(idx) {
      this.formDialog.form.WJDAList = this.formDialog.form.WJDAList.filter(
        (it, index) => index !== idx
      );
    },
    submit(type) {
      if (type === 1) {
        this.$refs.form.validate((res) => {
          if (res) {
            let { SSZZId: SSXX, Token: token, Id: UserId } = this.userinfo;
            this.formDialog.form.SSXX = SSXX;
            this.formDialog.form.SYZT = this.formDialog.form.state ? 1 : 2;
            if (!this.formDialog.form.Id) this.formDialog.form.Id = null;
            this.$api.post(
              "v1/questionnaire/EditAnTem",
              {
                Data: this.formDialog.form,
                UserId,
                token,
              },
              (res) => {
                if (res.Code === 0) {
                  this.$message("添加成功");
                  this.formDialog.visible = false;
                  this.formDialog.form.MBMC = "";
                  this.formDialog.form.WJDAList = [];
                  this.pager.pageIndex = 1;
                  this.getAnswer();
                }
              }
            );
          }
        });
      } else {
        this.formDialog.visible = false;
      }
    },
    handleZMabled(WJFXBList, cur) {
      if (WJFXBList && WJFXBList.length > 0) {
        return WJFXBList.some((fx) => fx.TMQZ && fx.TMQZ === cur);
      } else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input-number {
  ::v-deep .el-input-number__decrease {
    display: none;
  }
  ::v-deep .el-input-number__increase {
    display: none;
  }
  ::v-deep .el-input__inner {
    padding: 0 5px;
    width: 70px;
  }
}
.answer-form-dialog {
  .answer-form {
    .answer-item {
      display: flex;
      align-items: flex-start;
      .el-button {
        position: relative;
        top: 6px;
        margin-left: 15px;
      }
    }
    .push-answer {
      padding-left: 40px;
      display: flex;
      height: 30px;
      align-items: center;
      margin-bottom: 20px;
      .el-button {
        margin-left: 10px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
