<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->

      <div class="operate-box">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item label="类型">
            <el-select v-model="searchForm.sjlx" placeholder="类型">
              <el-option label="BMI指数" :value="1"></el-option>
              <el-option label="肺活量" :value="2"></el-option>
              <el-option label="50米跑" :value="3"></el-option>
              <el-option label="坐位体前屈" :value="4"></el-option>
              <el-option label="跳绳" :value="5"></el-option>
              <el-option label="跳绳加分" :value="6"></el-option>
              <el-option label="一分钟仰卧起坐" :value="7"></el-option>
              <el-option label="50*8往返" :value="8"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级">
            <el-select v-model="searchForm.nj" placeholder="年级">
              <el-option label="一年级" :value="1"></el-option>
              <el-option label="二年级" :value="2"></el-option>
              <el-option label="三年级" :value="3"></el-option>
              <el-option label="四年级" :value="4"></el-option>
              <el-option label="五年级" :value="5"></el-option>
              <el-option label="六年级" :value="6"></el-option>
              <el-option label="七年级" :value="7"></el-option>
              <el-option label="八年级" :value="8"></el-option>
              <el-option label="九年级" :value="9"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button plain @click="searchlist">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tab-heng-box">
        <div class="table-er-box">
          <el-button plain icon="el-icon-edit" @click="openadd">新增</el-button>

          <el-button plain icon="el-icon-edit">开启编辑</el-button>
          <el-button plain icon="el-icon-setting">保存并关闭编辑</el-button>

          <!--<el-button type="primary" icon="el-icon-top-right">下载全部</el-button>

          <el-button type="primary" icon="el-icon-bottom-right" @click="">下载数据</el-button>-->
        </div>
        <el-table
          :data="tableData"
          class="jf-table"
          border
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          style="width: 100%;"
        >
          <el-table-column prop="SJLX" label="类型">
            <template slot-scope="scope">
              <p v-if="scope.row.SJLX == '1'">BMI指数</p>
              <p v-else-if="scope.row.SJLX == '2'">肺活量</p>
              <p v-else-if="scope.row.SJLX == '3'">50米跑</p>
              <p v-else-if="scope.row.SJLX == '4'">坐位体前屈</p>
              <p v-else-if="scope.row.SJLX == '5'">跳绳</p>
              <p v-else-if="scope.row.SJLX == '6'">跳绳加分</p>
              <p v-else-if="scope.row.SJLX == '7'">一分钟仰卧起坐</p>
              <p v-else>50*8往返</p>
            </template>
          </el-table-column>
          <el-table-column prop="NJ" label="年级">
            <template slot-scope="scope">
              <p v-if="scope.row.SJLX == '1'">一年级</p>
              <p v-else-if="scope.row.SJLX == '2'">二年级</p>
              <p v-else-if="scope.row.SJLX == '3'">三年级</p>
              <p v-else-if="scope.row.SJLX == '4'">四年级</p>
              <p v-else-if="scope.row.SJLX == '5'">五年级</p>
              <p v-else-if="scope.row.SJLX == '6'">六年级</p>
              <p v-else-if="scope.row.SJLX == '7'">七年级</p>
              <p v-else-if="scope.row.SJLX == '8'">八年级</p>
              <p v-else>九年级</p>
            </template>
          </el-table-column>

          <el-table-column prop="XSXB" label="性别">
            <template slot-scope="scope">
              <p v-if="scope.row.XSXB == '1'">男</p>
              <p v-else-if="scope.row.XSXB == '2'">女</p>
            </template>
          </el-table-column>
          <el-table-column prop="KSZS" label="开始指数（>=)"></el-table-column>
          <el-table-column prop="JSZS" label="结束指数(<=)"></el-table-column>
          <el-table-column prop="DF" label="得分"></el-table-column>
          <el-table-column prop="DJMC" label="等级名称"></el-table-column>

          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="deleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--表格区结束-->
    </div>

    <!--弹出框-->
    <el-dialog
      title="新增"
      :visible.sync="adddialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="XSTZSJDFInfo" :rules="xstzsjdfrules" ref="sbmitfrom">
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-select v-model="XSTZSJDFInfo.SJLX" placeholder="类型">
            <el-option label="BMI指数" :value="1"></el-option>
            <el-option label="肺活量" :value="2"></el-option>
            <el-option label="50米跑" :value="3"></el-option>
            <el-option label="坐位体前屈" :value="4"></el-option>
            <el-option label="跳绳" :value="5"></el-option>
            <el-option label="跳绳加分" :value="6"></el-option>
            <el-option label="一分钟仰卧起坐" :value="7"></el-option>
            <el-option label="50*8往返" :value="8"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级" :label-width="formLabelWidth">
          <el-select v-model="XSTZSJDFInfo.NJ" placeholder="年级">
            <el-option label="一年级" :value="1"></el-option>
            <el-option label="二年级" :value="2"></el-option>
            <el-option label="三年级" :value="3"></el-option>
            <el-option label="四年级" :value="4"></el-option>
            <el-option label="五年级" :value="5"></el-option>
            <el-option label="六年级" :value="6"></el-option>
            <el-option label="七年级" :value="7"></el-option>
            <el-option label="八年级" :value="8"></el-option>
            <el-option label="九年级" :value="9"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别" :label-width="formLabelWidth">
          <el-radio-group v-model="XSTZSJDFInfo.XSXB">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="开始指数"
          :label-width="formLabelWidth"
          prop="KSZS"
        >
          <el-input v-model="XSTZSJDFInfo.KSZS" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="结束指数"
          :label-width="formLabelWidth"
          prop="JSZS"
        >
          <el-input v-model="XSTZSJDFInfo.JSZS" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="得分" :label-width="formLabelWidth" prop="DF">
          <el-input v-model="XSTZSJDFInfo.DF" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="等级名称"
          :label-width="formLabelWidth"
          prop="DJMC"
        >
          <el-input v-model="XSTZSJDFInfo.DJMC" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="adddialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="savedata">确 定</el-button>
      </div>
    </el-dialog>

    <!--弹出框-->
    <!--<el-dialog :title="dialogForm.titleName" :visible.sync="dialogFormVisible">
            <el-form :model="dialogForm" label-width="80px">
                <el-form-item label="导出数据" :label-width="formLabelWidth">
                </el-form-item>
                <el-form-item label="导入数据" :label-width="formLabelWidth">
                    <el-input v-model="dialogForm.pathUrl" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary">确 定</el-button>
            </div>
    </el-dialog>-->
  </div>
</template>

<script>
import { isNumber } from "@/style/js/Validator.js";
export default {
  name: "SchoolPhysicalDataSetList",
  data() {
    return {
      token: this.$store.state.token,
      tableData: [],
      searchForm: {
        sjlx: 1,
        nj: 1
      },
      userId: "",
      userName: "",
      // 新增
      adddialogFormVisible: false,
      XSTZSJDFInfo: {
        Id: 0,
        SJLX: 1,
        NJ: 1,
        KSZS: "",
        JSZS: "",
        DF: "",
        DJMC: "",
        XSXB: 1,
        CJR: "",
        CJSJ: "2020-01-08"
      },
      xstzsjdfrules: {
        DJMC: [
          {
            required: true,
            message: "请输入等级名称",
            trigger: "blur"
          }
        ],
        KSZS: [
          {
            validator: isNumber,
            message: "加分必须为数字",
            trigger: "blur"
          }
        ],
        JSZS: [
          {
            validator: isNumber,
            message: "教师加分必须为数字",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.userName = userinfo.DLM;
    this.userId = userinfo.Id;

    this.searchlist();
  },
  methods: {
    // 查询列表
    searchlist: function() {
      this.$api.post(
        "v1/schoolphysicaldata/SchoolPhysicalDataSetList",
        {
          FitnessTypes: this.searchForm.sjlx,
          Grade: this.searchForm.nj,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
          }
        }
      );
    },
    // 打开新增关闭
    openadd: function() {
      this.adddialogFormVisible = true;

      this.canceladd();
    },
    // 关闭清空
    canceladd: function() {
      this.XSTZSJDFInfo = {
        Id: 0,
        SJLX: 0,
        NJ: 0,
        KSZS: 0,
        JSZS: 0,
        DF: 0,
        DJMC: "",
        XSXB: 1,
        CJR: "",
        CJSJ: "2020-01-08"
      };
    },
    // 保存
    savedata: function() {
      if (this.XSTZSJDFInfo.SJLX === 0) {
        this.$message({
          type: "info",
          message: "请选择类型"
        });
        return;
      }
      if (this.XSTZSJDFInfo.NJ === 0) {
        this.$message({
          type: "info",
          message: "请选择年级"
        });
        return;
      }
      this.XSTZSJDFInfo.CJR = this.userId;

      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.$api.post(
            "v1/schoolphysicaldata/SchoolPhysicalDataSetAdd",
            {
              XSTZSJDFInfo: this.XSTZSJDFInfo,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.adddialogFormVisible = false;

                this.$notify({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });

                this.searchlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    // 删除行
    deleteRow: function() {}
  }
};
</script>
