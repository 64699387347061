<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <div class="table-er-butt" @click="addconfig()">
                <span class="table-er-buttspan">添加</span>
              </div>
              <div class="table-er-butt" @click="handleRefresh()">
                <span class="table-er-buttspan">刷新配置项缓存</span>
              </div>
              <div
                style="
                  font-size: 14px;
                  margin-bottom: 10px;
                  display: inline-block;
                  float: right;
                "
              >
                <el-input
                  style="width: 200px;"
                  type="text"
                  placeholder="输入计划名称/计划类别"
                  v-model="searchname"
                  maxlength="50"
                ></el-input>
                <span
                  style="color: #14d089; margin-left: 5px; cursor: pointer;"
                  @click="search()"
                  >搜索</span
                >
              </div>
            </div>
            <el-table
              ref="singleTable"
              border
              :data="configlist"
              highlight-current-row
              style="width: 100%;"
            >
              <el-table-column
                align="center"
                property="ConfigName"
                label="名称"
              >
              </el-table-column>
              <el-table-column
                align="center"
                property="KeyName"
                label="配置项Key"
              >
              </el-table-column>
              <el-table-column
                align="center"
                property="ConfigType"
                label="配置项类型"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.ConfigType == '1'">
                    文本框
                  </span>
                  <span v-else-if="scope.row.ConfigType == '2'">
                    下拉框
                  </span>
                  <span v-else-if="scope.row.ConfigType == '3'">
                    单选框
                  </span>
                  <span v-else-if="scope.row.ConfigType == '4'">
                    多行文本框
                  </span>
                  <span v-else-if="scope.row.ConfigType == '5'">
                    复选框
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                property="CreateTime"
                label="创建时间"
              >
                <template slot-scope="scope">
                  {{ scope.row.CreateTime | DateFormat("yyyy-MM-dd hh:mm") }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleEdit(scope.row.Id)"
                    type="text"
                    style="color: blue;"
                    >编辑</el-button
                  >
                  <el-button
                    @click="handleDelete(scope.row.Id)"
                    style="color: red;"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagin-box">
              <div class="pagin-wz">
                <Pager
                  :pagerInfo="pagerInfo"
                  @changeIndex="handleCurrentChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="edittitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
      style="min-width:470px;"
      :before-close="handleEditClose"
    >
      <el-form
        label-width="120px"
        style="width: 500px; display: inline-block;"
        :rules="rules"
        :model="configinfo"
        ref="editconfigform"
        :inline="true"
      >
        <el-form-item label="配置项名称：" prop="ConfigName">
          <el-input
            v-model="configinfo.ConfigName"
            style="width:270px;"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="配置项Key：" prop="KeyName">
          <el-input
            :disabled="configinfo.Id != ''"
            v-model="configinfo.KeyName"
            style="width:270px;"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="配置项值内容：" prop="ValueContent">
          <el-input
            v-model="configinfo.ValueContent"
            style="width:270px;"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="配置项类型：" prop="ConfigType">
          <el-row>
            <el-col>
              <el-select
                style="width:270px;"
                v-model="configinfo.ConfigType"
                placeholder="请选择配置项类型"
              >
                <el-option
                  v-for="item in configtype"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          prop="Extend"
          v-if="
            configinfo.ConfigType == '2' ||
              configinfo.ConfigType == '3' ||
              configinfo.ConfigType == '5'
          "
        >
          <el-row
            v-for="(extd, extindex) in extend"
            :key="extindex"
            style=" width:470px;display: inline-block;"
          >
            <el-col class="line" :span="4">值:</el-col>
            <el-col style="display:block;" :span="8">
              <el-input
                v-model="extd.value"
                style="width:120px;"
                maxlength="50"
              ></el-input>
            </el-col>
            <el-col class="line" :span="4">显示名称:</el-col>
            <el-col style="display:block;" :span="8">
              <el-input
                v-model="extd.label"
                style="width:120px;"
                show-word-limit
              ></el-input>
            </el-col>
          </el-row>
          <el-button type="primary" @click="handleaddextend()"
            >增加类型内容</el-button
          >
        </el-form-item>
        <br />
        <el-form-item label="描述：" prop="Description">
          <el-input
            style="width:100%; min-width:270px;"
            type="textarea"
            :rows="5"
            placeholder="请输入描述"
            v-model="configinfo.Description"
            maxlength="400"
            show-word-limit
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item>
          <el-button @click="handleEditClose()">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="删除确认"
      :visible.sync="deletedialogVisible"
      :close-on-click-modal="false"
      width="40%"
      style="min-width:470px;"
      :before-close="handleDeleteClose"
    >
      <el-form
        label-width="120px"
        style="width: 500px; display: inline-block;"
        :rules="rules"
        :model="configinfo"
        ref="deleteconfigform"
        :inline="true"
      >
        <el-form-item
          label="删除指令："
          :rules="[
            { required: true, message: '删除指令不能为空' },
            {
              min: 1,
              max: 20,
              message: '长度在1到20个字符',
              trigger: 'change'
            }
          ]"
          prop="DeleteCode"
        >
          <el-input
            v-model="DeleteCode"
            style="width:270px;"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item>
          <el-button @click="handleDeleteClose()">取 消</el-button>
          <el-button type="primary" @click="handleDeleteConfirm()"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  created() {
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    addconfig: function() {
      this.edittitle = "新增";
      this.editid = "";
      this.editconfig();
    },
    // 关闭
    handleEditClose() {
      this.$refs.editconfigform.resetFields();
      this.extend = [];
      this.dialogVisible = false;
    },
    // 关闭
    handleDeleteClose() {
      this.$refs.deleteconfigform.resetFields();
      this.extend = [];
      this.deletedialogVisible = false;
    },
    handleDelete: function(id) {
      this.editid = id;
      this.DeleteCode = "";
      this.deletedialogVisible = true;
    },
    editconfig: function() {
      this.dialogVisible = true;
      this.abjcx = false;
      this.dxqcx = false;
      if (this.editid !== "") {
        this.loadconfigdata();
      } else {
        this.configinfo.Id = "";
        this.configinfo.ConfigType = "";
        this.extend = [];
        this.extend.push({ value: "", label: "" });
      }
    },
    loadconfigdata: function() {
      this.$api.post(
        "v1/config/GetConfig",
        { Id: this.editid, token: this.token },
        r => {
          if (r.Code === 0) {
            this.configinfo = r.Target;
            this.configinfo.ConfigType = "" + this.configinfo.ConfigType;
            this.extend = r.Target.ExtendList;
          }
        }
      );
    },
    // 保存
    handleConfirm() {
      this.$refs.editconfigform.validate(valid => {
        if (valid) {
          this.configinfo.ExtendList = this.extend;
          this.$api.post(
            "v1/config/AddConfig",
            {
              ConfigInfo: this.configinfo,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!"
                });
                this.$refs.editconfigform.resetFields();
                this.extend = [];
                this.dialogVisible = false;
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "保存失败!"
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    loaddata: function() {
      this.$api.post(
        "v1/config/GetList",
        {
          SearchName: this.searchname,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.configlist = [];
            this.configlist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    search: function() {
      this.pagerInfo.pageIndex = 1;
      this.loaddata();
    },
    handleaddextend: function() {
      this.extend.push({ value: "", label: "" });
    },
    handleEdit: function(id) {
      this.editid = id;
      this.editconfig();
    },
    handleDeleteConfirm: function(id) {
      this.$refs.deleteconfigform.validate(valid => {
        if (valid) {
          this.$confirm("确定要删除该活动吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              this.$api.post(
                "v1/config/DeleteJXGY",
                {
                  SSXX: this.ssxx,
                  SSXQ: this.ssxq,
                  Id: id,
                  YHId: this.yhid,
                  token: this.token
                },
                r => {
                  if (r.Code === 0) {
                    this.$message({
                      type: "info",
                      message: "删除成功!"
                    });
                    this.loaddata();
                  } else {
                    this.$message({
                      type: "info",
                      message: "删除失败!"
                    });
                    this.loaddata();
                  }
                }
              );
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    handleRefresh: function() {
      this.$confirm("确定要刷新配置项到缓存吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "v1/config/RefreshSystemConfig",
            {
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "操作成功!"
                });
              } else {
                this.$message({
                  type: "info",
                  message: "操作失败!"
                });
              }
            }
          );
        })
        .catch(() => {});
    }
  },
  data() {
    var valiconfigtype = (rule, value, callback) => {
      if (
        this.extend.length <= 0 ||
        this.extend.filter(c => c.value != "" && c.label != "").length <= 0
      ) {
        callback(new Error("请选择查询条件"));
      } else {
        callback();
      }
    };
    return {
      isLogin: true,
      configlist: [],
      configtype: [
        { value: "1", label: "文本框" },
        { value: "2", label: "下拉框" },
        { value: "3", label: "单选框" },
        { value: "4", label: "多行文本框" },
        { value: "5", label: "复选框" }
      ],
      DeleteCode: "",
      extend: [],
      pagerInfo: {
        pageIndex: 1,
        pageSize: 20,
        totalCount: 0
      },
      edittitle: "",
      configinfo: {
        Id: "",
        ConfigName: "",
        KeyName: "",
        ValueContent: "",
        IsPrivate: 2,
        ConfigType: "",
        Description: "",
        Extend1: "",
        Extend2: "",
        Extend3: ""
      },
      deletedialogVisible: false,
      dialogVisible: false,
      searchname: "",
      token: "",
      editid: "",
      rules: {
        DeleteCode: [
          { required: true, message: "请输入删除指令", trigger: "change" },
          { min: 1, max: 50, message: "长度在1到50个字符", trigger: "change" }
        ],
        ConfigName: [
          { required: true, message: "请输入配置项名称", trigger: "change" },
          { min: 1, max: 50, message: "长度在1到50个字符", trigger: "change" }
        ],
        KeyName: [
          { required: true, message: "请输入配置项key", trigger: "change" },
          { min: 1, max: 50, message: "长度在1到50个字符", trigger: "change" }
        ],
        ConfigType: [
          { required: true, message: "请选择配置项类型", trigger: "change" }
        ],
        ValueContent: [
          { required: true, message: "请输入配置项值内容", trigger: "change" },
          { min: 1, max: 500, message: "长度在1到500个字符", trigger: "change" }
        ],
        Description: [
          {
            min: 0,
            max: 400,
            message: "长度在400个字符以内",
            trigger: "change"
          }
        ],
        SQLText: [
          { required: true, message: "请输入计划内容", trigger: "change" },
          {
            min: 1,
            max: 500,
            message: "长度在1到500个字符",
            trigger: "change"
          }
        ],
        Extend: [
          {
            validator: valiconfigtype,
            message: "请添加类型内容",
            trigger: "change"
          }
        ]
      }
    };
  }
};
</script>
