<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="50%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="200px"
      class="demo-ruleForm"
      status-icon
      label-position="right"
    >
      <el-form-item
        :label-width="labelWidth"
        label="名称"
        prop="PJCYDXMC"
        class="el-item-input"
      >
        <el-input
          style="max-width: 217px"
          v-model="formData.PJCYDXMC"
        ></el-input>
      </el-form-item>
      <!-- 被评人 -->
      <el-form-item
        :label-width="labelWidth"
        label="被评人"
        prop="EvaluatedObjectStr"
        class="el-item-input"
      >
        <el-input
          v-model="formData.EvaluatedObjectStr"
          readonly
          style="max-width: 217px"
        ></el-input>
      </el-form-item>

      <el-form-item
        v-show="formData.BPJDXLX === 2"
        label="范围选择"
        :label-width="labelWidth"
        class="el-item"
        prop="PJCYDXLB"
      >
        <el-select v-model="formData.PJCYDXLB" placeholder>
          <el-option
            v-for="item in baseInfo.EvaluationObjectJoinType"
            v-show="
              ((formData.BPJDXLX === 1 || formData.BPJDXLX === 3) &&
                item.Key === 3) ||
                (formData.BPJDXLX === 2 && item.Key !== 3)
            "
            :disabled="payLoad.currentActivity.JZKBLX !== 3 && item.Key === 1"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        key="limitType1"
        label="限制条件"
        :label-width="labelWidth"
        class="el-item"
        prop="XZCSLX"
      >
        <el-checkbox
          v-if="payLoad.currentActivity.HDLX === 1"
          name="noLimit"
          @change="XZCSLXchange('noLimit')"
          v-model="XZCSLXOption.noLimit.state"
          >不限制
        </el-checkbox>
        <el-checkbox
          v-if="payLoad.currentActivity.HDLX === 2"
          name="singleLimit"
          disabled
          v-model="XZCSLXOption.singleLimit.state"
          >单次评价限制</el-checkbox
        >
        <el-checkbox
          v-if="payLoad.currentActivity.HDLX === 1"
          name="weekLimit"
          @change="XZCSLXchange('weekLimit')"
          v-model="XZCSLXOption.weekLimit.state"
          >按周限评价次数
          <el-input
            v-show="formData.BPJDXLX === 1"
            type="number"
            class="checkbox-input"
            v-model.number="XZCSLXOption.weekLimit.frequency"
            @change.native="handleEvaluationNumber($event, 'weekLimit')"
          ></el-input>
        </el-checkbox>
        <el-checkbox
          v-if="payLoad.currentActivity.HDLX === 1"
          name="QXlimit"
          @change="XZCSLXchange('QXlimit')"
          v-model="XZCSLXOption.QXlimit.state"
          >按期限评价次数
          <el-input
            v-show="formData.BPJDXLX === 1"
            type="number"
            class="checkbox-input"
            v-model.number="XZCSLXOption.QXlimit.frequency"
            @change.native="handleEvaluationNumber($event, 'QXlimit')"
          ></el-input>
        </el-checkbox>
      </el-form-item>
      <el-form-item
        v-show="formData.BPJDXLX === 1"
        :label-width="labelWidth"
        label="参与年级"
        prop="GradeOfObjectList"
        class="el-item"
      >
        <div>
          <!-- <el-checkbox v-model="allGradeCheck" @change="allGradeChange"
            >全选</el-checkbox
          > -->
          <el-checkbox-group v-model="formData.GradeOfObjectList">
            <el-checkbox
              v-for="item in schoolGrades.filter(item =>
                gradeFilter.includes(item.SSXDLX)
              )"
              :key="item.Id"
              :label="item.Id"
              :disabled="disabledGradeCheckebox(item, 1)"
              >{{ item.Name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item
        v-show="formData.BPJDXLX === 2"
        :label-width="labelWidth"
        class="el-item"
      >
        <!-- 按学科参与时显示 -->
        <div style="position: reletive" v-if="formData.PJCYDXLB === 1">
          <span style="position: absolute; left: -66px; top: 0">参与学科</span>
          <el-checkbox-group
            v-model="BPRrangeCheckbox"
            @change="BPRrangeChange"
          >
            <el-checkbox
              v-for="(item, index) in baseInfo.CourseList.filter(
                c => c.MultiSchool == multischool || multischool == 0
              )"
              :key="item.Id"
              :label="item.Id"
              >{{ item.Name }}
              <el-input
                v-if="payLoad.currentActivity.HDLX === 1"
                type="number"
                class="checkbox-input"
                v-model.number="item.frequency"
                @change.native="
                  handleEvaluationNumber(
                    $event,
                    'CourseList',
                    index,
                    'frequency'
                  )
                "
              ></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </div>

        <!-- 按角色参与时显示 -->
        <div style="position: reletive" v-if="formData.PJCYDXLB === 2">
          <span style="position: absolute; left: -66px; top: 1px"
            >参与角色</span
          >
          <el-checkbox-group v-model="BPRrangeCheckbox">
            <el-checkbox
              v-for="(item, index) in baseInfo.RoleList"
              :key="item.Id"
              :label="item.Id"
              >{{ item.Name }}
              <el-input
                v-if="payLoad.currentActivity.HDLX === 1"
                type="number"
                class="checkbox-input"
                v-model.number="item.frequency"
                @change.native="
                  handleEvaluationNumber($event, 'RoleList', index, 'frequency')
                "
              ></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 按机构参与时显示 -->
        <div style="position: reletive" v-if="formData.PJCYDXLB === 4">
          <span style="position: absolute; left: -66px; top: 1px"
            >参与机构</span
          >
          <el-checkbox-group v-model="BPRrangeCheckbox">
            <el-checkbox
              v-for="(item, index) in jgOption1"
              :key="item.Key"
              :label="item.Key"
              >{{ item.Name }}
              <el-input
                v-if="payLoad.currentActivity.HDLX === 1"
                type="number"
                class="checkbox-input"
                v-model.number="item.frequency"
                @change.native="
                  handleEvaluationNumber($event, 'jigou', index, 'frequency')
                "
              ></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="编码"
        prop="PJCYDXBM"
        class="el-item"
      >
        <el-input style="width:217px" v-model="formData.PJCYDXBM" />
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="状态"
        prop="SYZT"
        class="el-item"
      >
        <el-select v-model="formData.SYZT" placeholder>
          <el-option
            v-for="item in baseInfo.SYZT"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { pinyin } from 'pinyin-pro'
import { handle_SSXD } from '@/utils/common'
var checkEvaluationObjectJoinType = function(rule, value, callback) {
  if (value.length === 0 && rule.joinType !== 3) {
    return callback(new Error(rule.message))
  } else return callback()
}
export default {
  data() {
    let valid_HDBM = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9]+$/ //英文字符
      if (!value) callback(new Error('编码不能为空'))
      else if (!reg.test(value)) callback(new Error('请输入英文或者数字'))
      else if (value.length > 10) callback(new Error('编码不能超过10个字符'))
      else callback()
    }
    return {
      gradeList: [],
      showMultischool: true,
      gradeFilter: [],
      multischool: 0,
      title: '新增对象',
      labelWidth: '120px',
      formData: {
        SYZT: 1,
        GradeOfObjectList: [],
        PJCYDXGLLXList: [],
        XZCSLX: null,
        PJCS: 0,
        PJCYDXLB: null //被评人的范围选择
      },
      dialogVisiable: false,
      ruleEvaluationObjectJoinTypeList: [
        {
          validator: checkEvaluationObjectJoinType,
          message: '请选择主评人员',
          trigger: 'change',
          type: 'Array',
          joinType: 3
        }
      ],
      rulePJCYDXLB: [
        { required: true, message: '请选择主评人员', trigger: 'change' }
      ],
      rules: {
        HDXS: [
          { required: true, message: '请选择对象形式', trigger: 'change' }
        ],
        PJCYDXMC: [
          { required: true, message: '请输入对象名称', trigger: 'change' },
          { min: 1, max: 200, message: '长度不超过200个字', trigger: 'change' }
        ],
        PJFS: [
          { required: true, message: '请选择评价方式', trigger: 'change' }
        ],
        // PJCYDXLB: [
        //   { required: true, message: "请选择主评人员", trigger: "change" }
        // ],
        SYZT: [{ required: true, message: '请选择状态', trigger: 'change' }],
        PJCYDXBM: [
          { required: true, message: '编码不能为空', trigger: 'blur' },
          { validator: valid_HDBM, trigger: 'blur' }
        ]
        // EvaluationObjectJoinTypeList: [
        //   {
        //     validator: checkEvaluationObjectJoinType,
        //     message: "请选择主评人员",
        //     trigger: "change",
        //     type: "Array",
        //     joinType: 3
        //   }
        // ]
      },
      // 限制次数辅助数据
      XZCSLXOption: {
        noLimit: {
          value: 0,
          state: false
        },
        singleLimit: {
          value: 1,
          state: false
        },
        QXlimit: {
          value: 2,
          state: false,
          frequency: 0
        },
        weekLimit: {
          value: 3,
          state: false,
          frequency: 0
        }
      },
      //班级内互评选项
      inGradeEvaluateOption: [
        {
          label: '班级内互评',
          value: 1
        },
        {
          label: '班级内小组互评',
          value: 2
        }
      ],
      // 被评人范围选择多选框
      BPRrangeCheckbox: [],
      // 年级全选
      allGradeCheck: false,
      jgOption1: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: function() {
        return {}
      }
    },
    baseInfo: {
      type: Object,
      required: true
    },
    jgOption: Array,
    schoolGrades: Array
  },
  inject: ['payLoad'],
  methods: {
    initForm() {
      this.handleGradeBy_SSXD()
      this.title = '新增对象'

      Object.assign(this.formData, {
        GradeOfObjectList: [],
        EvaluationObjectJoinTypeList: []
      })
      if (this.form && (this.form.Id || this.form.Id > 0)) {
        Object.assign(
          this.formData,
          JSON.parse(JSON.stringify(this.form)) || {}
        )
        this.formData.PJCYDXBM2 = this.form.PJCYDXBM

        // 回显年级
        this.formData.GradeOfObjectList = this.form.GradeOfObjectList.map(
          number => {
            let grade = this.schoolGrades.find(
              grade => grade.GradeNumber === number
            )
            if (grade) return grade.Id
          }
        )
        this.title = '编辑对象'
      }
      if (this.formData.HDXS && this.formData.HDXS === 0) {
        this.$delete(this.formData, 'HDXS')
      }

      this.payLoad.currentActivity.HDLX === 2
        ? (this.XZCSLXOption.singleLimit.state = true)
        : ''

      switch (this.form.XZCSLX) {
        case 0:
          this.XZCSLXOption.noLimit.state = true
          this.XZCSLXOption.weekLimit.state = false
          this.XZCSLXOption.weekLimit.frequency = 0
          this.XZCSLXOption.QXlimit.state = false
          this.XZCSLXOption.QXlimit.frequency = 0
          break
        case 3:
          this.XZCSLXOption.weekLimit.state = true
          this.XZCSLXOption.weekLimit.frequency = this.form.PJCS
          this.XZCSLXOption.QXlimit.state = false
          this.XZCSLXOption.QXlimit.frequency = 0
          this.XZCSLXOption.noLimit.state = false
          break
        case 2:
          this.XZCSLXOption.QXlimit.state = true
          this.XZCSLXOption.QXlimit.frequency = this.form.PJCS
          this.XZCSLXOption.weekLimit.state = false
          this.XZCSLXOption.weekLimit.frequency = 0
          this.XZCSLXOption.noLimit.state = false
          break
      }
      this.formData.PJCS = this.form.PJCS

      if (this.form && this.form.PJCYDXGLLXList) {
        this.BPRrangeCheckbox = this.form.PJCYDXGLLXList.map(item => item.GLId)
        // 自评角色参与
        if (this.form.PJCYDXLB === 2) {
          this.baseInfo.RoleList.forEach(item => {
            let res = this.form.PJCYDXGLLXList.find(el => el.GLId === item.Id)
            if (res) item.frequency = res.PJXZCS
          })
        }

        // 自评机构参与
        if (this.form.PJCYDXLB === 4) {
          this.jgOption1.forEach(item => {
            let res = this.form.PJCYDXGLLXList.find(el => item.Key === el.GLId)
            if (res) item.frequency = res.PJXZCS
          })
        }
      }
    },
    // closeDialog() {
    //   this.$emit("closeDialog");
    // },
    closeDialog() {
      this.$confirm('确认关闭？').then(() => {
        this.$emit('closeDialog')
      })
      // this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "保存",
      //   cancelButtonText: "放弃修改"
      // })
      //   .then(() => {
      //     //执行提交方法,执行成功后回调函数执行以下代码
      //     this.confirmDialog()
      //   })
      //   .catch(action => {
      //     if (action === "cancel") {
      //       this.$emit("closeDialog");
      //     }
      //   });
    },
    // 处理关联类型
    handleGLLX() {
      let GLLXListArr = []
      let GLkey2 = ''
      let valueKey2 = ''
      switch (this.formData.PJCYDXLB) {
        case 1:
          GLkey2 = 'CourseList'
          valueKey2 = 'Id'
          break
        case 2:
          GLkey2 = 'RoleList'
          valueKey2 = 'Id'
          break
        case 4:
      }
      // 被评人关联
      // 如果被评人范围为机构
      if (this.formData.PJCYDXLB === 4) {
        this.BPRrangeCheckbox.forEach(Key => {
          let jg = this.jgOption1.find(item => item.Key === Key)
          if (jg) {
            GLLXListArr.push({
              GLID: jg.Id, //自己id
              GLLX: 4, //学科角色机构
              PJLX: 2, //评价人或被评人
              JGPJLX: jg.type,
              PJXZCS: jg.frequency //后面填的次数
            })
          }
        })
      } else {
        this.BPRrangeCheckbox.forEach(item => {
          this.baseInfo[GLkey2].forEach(el => {
            if (item === el[valueKey2]) {
              GLLXListArr.push({
                GLID: item, //自己id
                GLLX: this.formData.PJCYDXLB, //学科角色机构
                PJLX: 2, //评价人或被评人
                PJXZCS: el.frequency
              })
            }
          })
        })
      }
      return GLLXListArr
    },
    confirmDialog() {
      const data = JSON.parse(JSON.stringify(this.formData))

      if (
        this.payLoad.currentActivity.HDCPR === 2 &&
        (!this.formData.GradeOfObjectList ||
          this.formData.GradeOfObjectList.length === 0)
      )
        return this.$message.error('年级不能为空')
      if (!this.formData.PJCYDXBM) {
        let letterArr = []
        try {
          letterArr = pinyin(this.formData.PJCYDXMC, {
            toneType: 'none',
            pattern: 'first',
            type: 'array'
          })
          if (letterArr) {
            let letter = ''
            letterArr.forEach(it => (letter += it.toUpperCase()))
            this.$set(this.formData, 'PJCYDXBM', letter)
            data.PJCYDXBM = letter
          }
        } catch (err) {
          //
        }
      }
      this.$refs.formData.validate(valid => {
        if (valid) {
          // eslint-disable-next-line no-unused-vars
          this.$confirm('确认提交？').then(r => {
            // 执行提交方法
            const joinType = this.baseInfo.EvaluationObjectJoinType.find(
              c => c.Key === data.PJCYDXLB
            )
            if (joinType && joinType.Value) {
              data.PJCYDXLBStr = joinType.Value
            }
            const evaluationMethod = this.baseInfo.EvaluationMethod.find(
              c => c.Key === data.PJFS
            )
            if (evaluationMethod && evaluationMethod.Value) {
              data.PJFSStr = evaluationMethod.Value
            }

            data.GradeOfObjectList = data.GradeOfObjectList.map(Id => {
              let grade = this.schoolGrades.find(grade => grade.Id === Id)
              if (grade) return grade.GradeNumber
            })
            data.GradeOfObject = data.GradeOfObjectList.length > 0 ? '' : '无'
            data.GradeOfObjectList.forEach(GradeNumber => {
              let grade = this.schoolGrades.find(
                c => c.GradeNumber === GradeNumber
              )
              data.GradeOfObject += grade ? grade.Name : ''
            })
            data.SYZTStr = data.SYZT === 1 ? '正常' : '停用'
            data.GradeOfObjectListStr = JSON.stringify(data.GradeOfObjectList)

            // 处理被评对象关联类型
            if (this.formData.BPJDXLX === 2) {
              data.PJCYDXGLLXList = this.handleGLLX()

              try {
                if (!data.PJCYDXGLLXList || data.PJCYDXGLLXList.length === 0)
                  return this.$message.error(
                    `请勾选${
                      this.formData.PJCYDXLB === 1
                        ? '学科'
                        : this.formData.PJCYDXLB === 2
                        ? '角色'
                        : '机构'
                    }`
                  )
              } catch (err) {
                //
              }
            }
            // 阶段活动才有多次限制
            if (this.payLoad.currentActivity.HDLX === 1) {
              Object.keys(this.XZCSLXOption).forEach((key, index) => {
                if (this.XZCSLXOption[key].state && index !== 0) {
                  data.PJCS = this.XZCSLXOption[key].frequency
                  data.XZCSLX = this.XZCSLXOption[key].value
                }
                if (this.XZCSLXOption[key].state && index === 0)
                  data.PJCS = null
              })
            } else {
              data.XZCSLX = 1
            }
            this.$emit('confirmDialog', data)
          })
          // .catch(r => {});
        } else {
          return false
        }
      })
    },
    // 判断学段年级
    handleGradeBy_SSXD() {
      let SSXD = this.payLoad.currentActivity.SSXD[0]
      this.gradeFilter = handle_SSXD(SSXD)
    },
    handdleChangeJoinType() {
      this.rules.EvaluationObjectJoinTypeList = this.ruleEvaluationObjectJoinTypeList
      this.rules.EvaluationObjectJoinTypeList[0].joinType = this.formData.PJCYDXLB
      this.formData.EvaluationObjectJoinTypeList = []
      if (
        this.baseInfo.GradeList.filter(c => c.MultiSchool === 1).length === 0
      ) {
        this.multischool = 2
        this.gradeFilter = [this.multischool]
        this.showMultischool = false
      } else if (
        this.baseInfo.GradeList.filter(c => c.MultiSchool === 2).length === 0
      ) {
        this.multischool = 1
        this.gradeFilter = [this.multischool]
        this.showMultischool = false
      } else {
        this.multischool = 0
        this.gradeFilter = [1, 2]
        this.showMultischool = true
      }
    },
    handdleChangeMultischool() {
      if (this.multischool !== 0) {
        this.gradeFilter = [this.multischool]
      } else {
        this.gradeFilter = [1, 2]
      }
    },
    // 限制次数辅助数据变化
    XZCSLXchange(val) {
      if (this.XZCSLXOption[val].state) {
        Object.keys(this.XZCSLXOption).forEach(key => {
          if (val !== key) {
            this.XZCSLXOption[key].frequency = 0
            this.XZCSLXOption[key].state = false
          } else {
            this.formData.XZCSLX = this.XZCSLXOption[key].value
          }
        })
      } else {
        this.XZCSLXOption[val].frequency = 0
      }
    },
    // 处理当前活动
    handleCurrentActivity() {
      let HDCPRname = ''
      switch (this.payLoad.currentActivity.HDCPR) {
        case 1:
          HDCPRname = '老师'
          break
        case 2:
          HDCPRname = '学生'
          break
        default:
          HDCPRname = '无'
      }
      let {
        BPJDXLX,
        HDCPR,
        EvaluatedObjectStr,
        PJFS, //指标
        SSXX,
        SFSC,
        // HDXS,//主 互 自
        PJFSStr
      } = this.payLoad.currentActivity
      this.formData = {
        ...this.formData,
        BPJDXLX,
        HDCPR,
        EvaluatedObjectStr,
        PJFS,
        SSXX,
        SFSC,
        HDXS: 1,
        PJFSStr,
        HDCPRname
      }
    },
    // 禁用年级多选框
    disabledGradeCheckebox(item, type) {
      let arr = JSON.parse(
        JSON.stringify(this.formData.GradeOfObjectList)
      ).sort((a, b) => a - b)
      let grade = this.schoolGrades.find(grade => grade.Id === arr[0])
      if (grade && item.SSXDLX !== grade.SSXDLX) return true
    },
    // 处理参评人的评价次数
    handleEvaluationNumber(e, type, idx, key) {
      let reg = /^(0|[1-9]([0-9]{1,3})?)$/
      if (!reg.test(e.target.value)) {
        if (this.formData.BPJDXLX === 1) {
          this.XZCSLXOption[type].frequency = 0
        } else {
          this.baseInfo[type][idx][key] = 0
        }
      }
    },
    // 年级全选
    allGradeChange(val) {
      if (val) {
        this.formData.GradeOfObjectList = this.baseInfo.GradeList.map(
          item => item.Key
        )
      } else {
        this.formData.GradeOfObjectList = []
      }
    },
    // 处理机构options
    handleJGoption() {
      let arr1 = []
      this.jgOption.forEach(item => {
        arr1 = [
          ...arr1,
          ...[
            {
              Key: item.key,
              Id: item.key,
              Name: `${item.name}`,
              type: 1,
              frequency: 0
            }
          ]
        ]
      })
      this.jgOption1 = arr1
    }
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        this.handleJGoption()
        this.initForm()
        this.handleCurrentActivity()
      } else {
        // 重置校验结果
        this.$refs.formData.resetFields()
        this.formData = {
          SYZT: 1,
          GradeOfObjectList: [],
          PJCYDXGLLXList: [],
          XZCSLX: null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-item {
  max-width: 600px;
  margin-left: 5%;
}
.el-item-input {
  max-width: 500px;
  margin-left: 5%;
}
.checkbox-input {
  width: 40px;
  height: 22px;
  ::v-deep .el-input__inner {
    padding: 0 !important;
    line-height: 22px;
    height: 100%;
    width: 100%;
  }
}
</style>
