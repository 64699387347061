<template>
	<div class="cont-whole-box">
		<div class="leftrig-box">
			<div class="leftrig-left-box" style="width: 200px; max-height: 900px;">
				<Tree @loadNode="getTreeData" @changeNode="changeNode" />
			</div>
			<div class="leftrig-rig-box" style="width: calc(100% - 201px);">
				<!--表格区开始-->
				<div class="margno-t">
					<div class="table-er-box" v-if="areaId.includes('-')">
						<el-button icon="el-icon-circle-plus-outline" @click="dialogFormVisible = true">新增</el-button>
						<el-button icon="el-icon-remove-outline" @click="delItem">删除</el-button>
						<el-button icon="el-icon-circle-check" @click="enableItem" :disabled="enableBtn">启用</el-button>
						<el-button icon="el-icon-circle-close" @click="disableItem"
							:disabled="disableBtn">禁用</el-button>
						<el-button icon="el-icon-video-pause" v-if="showChildenDimension"
							@click="rootDimensionManager">返回</el-button>
					</div>
					<div class="prompt-title-box" style="color: red;" v-else>
						只能在区域下添加维度
					</div>
					<Table :dataList="tableData" :levelSetData="levelSetData" @delItem="delItem" @editItem="editItem"
						@disableItem="disableItem" @childenDimensionManager="childenDimensionManager"
						@handleSelectionChange="handleSelectionChange" @enableItem="enableItem" @levelSet="levelSet" />
					<!--页码开始-->
					<div class="pagin-box">
						<div class="pagin-wz">
							<Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
						</div>
					</div>
					<!--页码结束-->
				</div>
				<!--表格区结束-->
			</div>
		</div>
		<el-dialog :title="dialogTile" :visible.sync="dialogFormVisible" :before-close="cancelForm"
			:close-on-click-modal="false" width="30%">
			<el-form :model="formData" :ref="formName" :rules="rules">
				<el-form-item label="评价维度名称" :label-width="formLabelWidth" prop="WDMC">
					<el-input v-model="formData.WDMC" autocomplete="on"></el-input>
				</el-form-item>
				<el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
					<el-input v-model.number="formData.PXH" autocomplete="on"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancelForm">取 消</el-button>
				<el-button type="primary" @click="confirmForm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tree from "@/views/manager/dimension/Tree.vue";
	import Table from "@/views/manager/dimension/Table.vue";
	import Pager from "@/components/Pager.vue";
	export default {
		name: "dimension",
		components: {
			Tree,
			Table,
			Pager
		},
		data() {
			return {
				parentTableData: [],
				parentPagerInfo: {},
				tableData: [],
				pagerInfo: {
					pageIndex: 0,
					pageSize: 9,
					totalCount: 0
				},
				formData: {},
				areaId: "",
				parentId: "",
				dialogFormVisible: false,
				formLabelWidth: "120px",
				dialogTile: "新增维度",
				showChildenDimension: false,
				multipleSelection: [],
				levelSetData: [],
				disableBtn: true,
				enableBtn: true,
				popoverVisible: false,
				formName: "form",
				LX: 0,
				rules: {
					WDMC: [{
							required: true,
							message: "请输入评价维度名称",
							trigger: "blur"
						}
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					],
					PXH: [{
							required: true,
							message: "请输入排序号",
							trigger: "blur"
						},
						{
							type: "number",
							message: "排序号必须为数字"
						}
					]
				}
			};
		},
		created() {
			this.resetData()
		},
		methods: {
			resetData() {
				// 根据不同路由地址，返回不同数据
				if (this.$route.fullPath === '/dimension1') {
					this.LX = 1;
				} else if (this.$route.fullPath === '/dimension2') {
					this.LX = 2;
				}
			},
			getTreeData(parentId, cab) {
				this.areaId = parentId;
				this.$api.post(
					"/v1/areaschool/GetAreaTree", {
						token: this.$store.state.token,
						ParentId: parentId,
						PagerInfo: this.pagerInfo
					},
					r => {
						var data = r.Target;
						var treeData = [];
						for (var i = 0; i < data.length; i++) {
							var leaf = true;
							if (data[i].HasChilden === 1) {
								leaf = false;
							}
							treeData.push({
								name: data[i].Name,
								data_nodetype: data[i].NodeType,
								data_id: data[i].Id,
								data_ordernum: data[i].Ordernum,
								data_remark: data[i].Remark,
								data_level: data[i].Level,
								data_parentid: data[i].ParentCode,
								leaf: leaf
							});
						}
						cab(treeData);
					}
				);
			},
			getTableData() {
				this.$api.post(
					"/v1/dimension/GetDimension", {
						token: this.$store.state.token,
						ParentId: this.parentId,
						AreaId: this.areaId,
						LX: this.LX,
						PagerInfo: this.pagerInfo
					},
					r => {
						var resp = r.Target;
						var data = resp.Target;
						this.pagerInfo.totalCount = resp.PagerInfo.RowCount;
						this.tableData = data.sort((a, b) => {
							return a.PXH - b.PXH;
						});
						this.tableData.ssqymc = "";
						this.getLevelData();
					}
				);
			},
			getLevelData() {
				this.$api.post(
					"/v1/levelmanager/GetLeaveSet", {
						token: this.$store.state.token,
						SchoolId: this.areaId
					},
					r => {
						if (r.Code === 0) {
							this.levelSetData = r.Target;
						}
					}
				);
			},
			changeIndex(val) {
				this.pagerInfo.pageIndex = val;
				this.getTableData();
			},
			editItem(data) {
				// this.parentId = data.FJId;
				this.dialogTile = "编辑维度";
				this.dialogFormVisible = true;
				this.formData = Object.assign({}, data);
			},
			delItem(data) {
				this.$confirm("确定删除?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning"
					})
					.then(() => {
						const ids = [];
						if (this.multipleSelection.length > 0 && data.Id === undefined) {
							for (const item of this.multipleSelection) {
								ids.push(item.Id);
							}
						} else {
							if (data.Id) {
								ids.push(data.Id);
							}
						}
						if (ids.length > 0) {
							this.$api.post(
								"/v1/dimension/DelDimension", {
									Ids: ids,
									token: this.$store.state.token,
									AreaId: this.areaId
								},
								r => {
									var resp = r.Target;
									let typeStatu = "success";
									if (resp.Code !== 0) {
										typeStatu = "error";
									} else {
										this.multipleSelection = [];
									}
									this.$message({
										type: typeStatu,
										message: resp.Message
									});
									this.getTableData();
								}
							);
						} else {
							this.$message({
								message: "请选择要删除的数据",
								type: "warning"
							});
						}
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除"
						});
					});
			},
			cancelForm() {
				this.$confirm("确认关闭？").then(() => {
					this.dialogFormVisible = false;
					this.formData = {};
					this.$refs[this.formName].resetFields();
				});
				// this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
				//   distinguishCancelAndClose: true,
				//   confirmButtonText: "保存",
				//   cancelButtonText: "放弃修改"
				// })
				//   .then(() => {
				//     //执行提交方法,执行成功后回调函数执行以下代码
				//     this.$refs[this.formName].validate(valid => {
				//       if (valid) {
				//         this.submitForm();
				//       } else {
				//         return false;
				//       }
				//     });
				//   })
				//   .catch(action => {
				//     if (action === "cancel") {
				//       this.dialogFormVisible = false;
				//       this.formData = {};
				//       this.$refs[this.formName].resetFields();
				//     }
				//   });
			},
			confirmForm() {
				this.$refs[this.formName].validate(valid => {
					if (valid) {
						this.$confirm("确认提交？")
							// eslint-disable-next-line no-unused-vars
							.then(r => {
								this.submitForm();
							})
							// eslint-disable-next-line no-unused-vars
							.catch(r => {});
					} else {
						return false;
					}
				});
			},
			levelSet(data) {
				data.LX = this.LX;
				this.$api.post(
					"/v1/dimension/EditDimension", {
						WDBInfo: data,
						token: this.$store.state.token
					},
					r => {
						var resp = r.Target;
						let typeStatu = "success";
						if (resp.Code !== 0) {
							typeStatu = "error";
						}
						this.$message({
							type: typeStatu,
							message: resp.Message
						});
						this.getTableData();
					}
				);
			},
			submitForm() {
				if (
					this.formData.Id === undefined ||
					this.formData.Id === "" ||
					this.formData.Id === null
				) {
					this.formData.SSQY = this.areaId;
					this.formData.FJId = this.parentId;
					const user = JSON.parse(localStorage.getItem("userinfo"));
					if (user !== undefined && user !== "" && user !== null) {
						this.formData.CJR = user.XSM;
					}
				}
				this.formData.LX = this.LX;
				this.$api.post(
					"/v1/dimension/EditDimension", {
						WDBInfo: this.formData,
						token: this.$store.state.token
					},
					r => {
						var resp = r.Target;
						let typeStatu = "success";
						if (resp.Code !== 0) {
							typeStatu = "error";
						}
						this.$message({
							type: typeStatu,
							message: resp.Message
						});
						this.getTableData();
						this.dialogFormVisible = false;
						this.formData = {};
						this.$refs[this.formName].resetFields();
					}
				);
			},
			// eslint-disable-next-line no-unused-vars
			changeNode(param, node) {
				if (param.Nodetype === 1) {
					this.areaId = param.Id;
				}
				this.getTableData();
			},
			disableItem(data) {
				const ids = [];
				if (this.multipleSelection.length > 0 && data.Id === undefined) {
					for (const item of this.multipleSelection) {
						ids.push(item.Id);
					}
				} else {
					ids.push(data.Id);
				}
				this.$api.post(
					"/v1/dimension/UpdateDimension", {
						Ids: ids,
						TrueOrFalse: 2,
						token: this.$store.state.token,
						AreaId: this.areaId
					},
					r => {
						var resp = r.Target;
						let typeStatu = "success";
						if (resp.Code !== 0) {
							typeStatu = "error";
						} else {
							this.multipleSelection = [];
						}
						this.$message({
							type: typeStatu,
							message: resp.Message
						});
						this.getTableData();
					}
				);
			},
			enableItem(data) {
				const ids = [];
				if (this.multipleSelection.length > 0 && data.Id === undefined) {
					for (const item of this.multipleSelection) {
						ids.push(item.Id);
					}
				} else {
					ids.push(data.Id);
				}
				this.$api.post(
					"/v1/dimension/UpdateDimension", {
						Ids: ids,
						TrueOrFalse: 1,
						token: this.$store.state.token
					},
					r => {
						var resp = r.Target;
						let typeStatu = "success";
						if (resp.Code !== 0) {
							typeStatu = "error";
						} else {
							this.multipleSelection = [];
						}
						this.$message({
							type: typeStatu,
							message: resp.Message
						});
						this.getTableData();
					}
				);
			},
			childenDimensionManager(data) {
				this.parentTableData = Object.assign([], this.tableData);
				this.parentPagerInfo = Object.assign({}, this.pagerInfo);
				this.parentId = data.Id;
				this.showChildenDimension = true;
				this.getTableData();
			},
			rootDimensionManager() {
				this.parentId = "";
				this.showChildenDimension = false;
				this.tableData = Object.assign([], this.parentTableData).sort((a, b) => {
					return a.PXH - b.PXH;
				});
				this.pagerInfo = Object.assign({}, this.parentPagerInfo);
			},
			handleSelectionChange(val) {
				this.enableBtn = true;
				this.disableBtn = true;
				if (val.find(c => c.SYZT === 1) !== undefined) {
					this.disableBtn = false;
				}
				if (val.find(c => c.SYZT === 2) !== undefined) {
					this.enableBtn = false;
				}
				this.multipleSelection = val;
			}
		},
		watch: {
			$route: {
				handler: 'resetData'
			}
		}
	};
</script>