<template>
  <el-dialog
    top="5vh"
    width="60%"
    ref="dialog"
    :visible.sync="value"
    :close-on-click-modal="false"
  >
    <div class="center">
      <div class="activity">
        <div class="activity-item center">
          <div class="activity-title">活动名称</div>
          <div class="activity-content">{{ form.PJHDMC }}</div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">动被评人</div>
          <div class="activity-content vertical">
            {{
              (baseInfo.BeEvaluationObjects
                ? baseInfo.BeEvaluationObjects.filter(
                    c => c.Key === form.BPJDXLX
                  ).map(c => c.Value)
                : []
              ).join("")
            }}
            <div
              v-if="form.BPJDXLX === 2"
              class="center activity-content-expand"
            >
              <el-tag
                v-for="item in (baseInfo.RoleList
                  ? baseInfo.RoleList
                  : []
                ).filter(c =>
                  (form.roleList ? form.roleList : []).includes(c.Id)
                )"
                :key="item.Id"
                type="primary"
                size="small"
                >{{ item.Name }}</el-tag
              >
            </div>
          </div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">活动参评人</div>
          <div class="activity-content">
            {{
              (baseInfo.EvaluationObjects ? baseInfo.EvaluationObjects : [])
                .filter(c => c.Key === form.HDCPR)
                .map(c => c.Value)
                .join("")
            }}
          </div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">活动形式</div>
          <div class="activity-content">
            <el-tag
              v-for="item in (baseInfo.EvaluationActivityStyles
                ? baseInfo.EvaluationActivityStyles
                : []
              ).filter(c =>
                (form.hdxsarry ? form.hdxsarry : []).includes(c.Key)
              )"
              :key="item.Key"
              type="primary"
              size="small"
              >{{ item.Value }}</el-tag
            >
          </div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">小组参与</div>
          <div class="activity-content">
            {{
              (baseInfo.GroupParticipationTypes
                ? baseInfo.GroupParticipationTypes
                : []
              )
                .filter(c => c.Key === form.XZCYFS)
                .map(c => c.Value)
                .join("")
            }}
          </div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">加载课表</div>
          <div class="activity-content">
            {{
              (baseInfo.CourseTableTypes ? baseInfo.CourseTableTypes : [])
                .filter(c => c.Key === form.JZKBLX)
                .map(c => c.Value)
                .join("")
            }}
          </div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">评价方式</div>
          <div class="activity-content">
            {{
              (baseInfo.EvalutionFormat ? baseInfo.EvalutionFormat : [])
                .filter(c => c.Key === form.PJFS)
                .map(c => c.Value)
                .join("")
            }}
          </div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">评价频次</div>
          <div class="activity-content">
            {{
              (baseInfo.EvFrequencys ? baseInfo.EvFrequencys : [])
                .filter(c => c.Key === form.DCPJSZ)
                .map(c => c.Value)
                .join("")
            }}
          </div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">选择按钮</div>
          <div class="activity-content">
            <el-tag
              v-for="item in (baseInfo.Btns ? baseInfo.Btns : []).filter(c =>
                (form.btnList ? form.btnList : []).includes(c.ANLX)
              )"
              :key="item.Id"
              type="primary"
              size="small"
              >{{ item.ANMC }}</el-tag
            >
          </div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">选择报表模版</div>
          <div class="activity-content"></div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">设置晋级</div>
          <div class="activity-content"></div>
        </div>
        <div class="activity-item center">
          <div class="activity-title">设置评价语模板</div>
          <div class="activity-content"></div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { binary } from "@/style/js/Common.js";
export default {
  data() {
    return {
      value: false,
      sourceObjectIds: [],
      roleList: [],
      popoverVisible: false,
      innerVisibleByLevelUpSet: false,
      innerVisibleByCommentTemplateSet: false,
      allowCreateChildActivity: false,
      hdxs: "000",
      form: {},
      submitForm: {},
      formName: "form",
      isShow: false,
      formLabelWidth: "20%",
      btnList: [],
      reportList: [],
      reportTemplateList: [],
      oldBPJDXLX: 0
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      required: true
    },
    baseInfo: {
      type: Object,
      required: true
    }
    // allObject: {
    //   type: Array,
    //   default: function() {
    //     return [];
    //   }
    // }
  },
  model: {
    prop: "visible",
    event: "change"
  },
  methods: {
    init(data) {
      if (data) {
        this.form = JSON.parse(JSON.stringify(data));
        this.$set(this.form, "hdxsarry", []);
        this.reportTemplateList = [];
      } else {
        this.form = JSON.parse(JSON.stringify(this.formData));
      }
      if (this.form.HDCPR === 0) {
        this.form.HDCPR = "";
      }
      this.$set(this.form, "roleList", []);
      this.$set(this.form, "btnList", []);
      this.$set(this.form, "reportList", []);
      // this.form.btnList = [];
      // this.form.reportList = [];
      if (this.form.RoleList) {
        for (let i = 0; i < this.form.RoleList.length; i++) {
          this.roleList.push(this.form.RoleList[i].JSId);
          this.form.roleList.push(this.form.RoleList[i].JSId);
        }
      }
      let tmphdxsarry = [];
      if (this.form.HDXS !== undefined) {
        tmphdxsarry = binary(this.form.HDXS, 3).split("");
      }
      for (let i = 0; i < tmphdxsarry.length; i++) {
        if (tmphdxsarry[i] !== "0") {
          this.form.hdxsarry.push(
            this.baseInfo.EvaluationActivityStyles[i].Key
          );
        }
      }
      if (this.form.EvaluationActivityButtonList !== undefined) {
        for (
          let i = 0;
          i < this.form.EvaluationActivityButtonList.length;
          i++
        ) {
          const index = this.baseInfo.Btns.findIndex(
            c => c.ANLX === this.form.EvaluationActivityButtonList[i].ANLX
          );
          if (index !== -1) {
            this.form.btnList.push(index);
          }
        }
      }

      if (this.form.EvaluationActivityReportTemplateList !== undefined) {
        for (
          let i = 0;
          i < this.form.EvaluationActivityReportTemplateList.length;
          i++
        ) {
          this.form.reportList.push(
            this.form.EvaluationActivityReportTemplateList[i].BBLX
          );
        }
        for (let i = 0; i < this.baseInfo.ReportTypes.length; i++) {
          const info = this.form.EvaluationActivityReportTemplateList.find(
            c => c.BBLX === this.baseInfo.ReportTypes[i].Key
          );
          this.reportTemplateList.push(info ? info.BBXZ : undefined);
        }
      }
      this.allowCreateChildActivity = this.form.SFYXZJHD === 1;
      if (this.form && this.form.BPJDXLX) {
        this.oldBPJDXLX = this.form.BPJDXLX;
      }
    }
  },
  watch: {
    value(val) {
      this.roleList = [];
      this.reportTemplateList = [];
      if (val) {
        this.init();
      } else {
        this.$refs.dialog.$el.scrollTop = 0;
        this.form = {};
        this.$emit("change", false);
      }
    },
    visible(val) {
      this.value = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity {
  font-size: 14px;
  color: #606266;
  width: 60%;
  .activity-item {
    border: 1px solid #ccc;
    border-color: #000;
    width: 100%;
    // padding: 20px 0;
    .activity-title {
      border-right: 1px solid #ccc;
      border-color: rgb(99, 98, 98);
      min-height: 60px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .activity-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .activity-content-expand {
        margin: 10px 0;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    .top {
      align-items: center;
    }
    .vertical {
      flex-direction: column;
    }
  }
  .activity-item + .activity-item {
    border-top: unset;
  }
}
.top {
  align-items: flex-start;
}
.vertical {
  flex-direction: column;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
