<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->
      <div class="tab-heng-box margno-t">
        <div class="table-er-box">
          <el-button type="primary" icon="el-icon-edit" @click="addtablerow"
            >新增</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="submitshowdata"
            style="float: right;"
            >保存</el-button
          >
        </div>
        <br />
        <br />
        <el-table :data="tableData" border style="width: 100%;">
          <el-table-column
            type="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column prop="ZZJGMC" label="班级名称">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.ZZJGMC"
                placeholder="班级名称"
                @blur="updateRowName(scope.$index, scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="BM" v-if="orgiztionbm == 1" label="班级别名">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.BM"
                placeholder="班级名称"
                @blur="updateRowName(scope.$index, scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="校区">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.SSXQ"
                placeholder="请选择"
                @change="updateRowName(scope.$index, scope.row)"
              >
                <el-option value label="无"></el-option>
                <el-option
                  v-for="item in schoolfq"
                  :key="item.Id"
                  :label="item.MC"
                  :value="item.Id"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="班号">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.BH"
                placeholder="班号"
                @blur="updateRowName(scope.$index, scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop label="主班主任">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                v-if="scope.row.MainList.length == 0"
                @click="oepnselectclassteacher(scope.$index, scope.row, 1)"
                >添加</el-button
              >
              <div
                class="teacher-block"
                v-else
                @click="oepnselectclassteacher(scope.$index, scope.row, 1)"
              >
                <div
                  v-for="item in scope.row.MainList"
                  :key="item.TeacherId"
                  class="c-blue"
                >
                  {{ item.TeacherName }}&nbsp;
                  <span
                    class="delete-role"
                    @click.stop="delRole(item, 1, scope)"
                    ><i class="el-icon-circle-close"></i
                  ></span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop min-width="120" label="副班主任">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                v-if="scope.row.AssistantList.length == 0"
                @click="oepnselectclassteacher(scope.$index, scope.row, 2)"
                >添加</el-button
              >
              <div
                class="teacher-block"
                v-else
                @click="oepnselectclassteacher(scope.$index, scope.row, 2)"
              >
                <div
                  v-for="item in scope.row.AssistantList"
                  :key="item.TeacherId"
                  class="c-blue"
                >
                  {{ item.TeacherName }}&nbsp;
                  <span
                    class="delete-role"
                    @click.stop="delRole(item, 2, scope)"
                    ><i class="el-icon-circle-close"></i
                  ></span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="deleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--表格区结束-->
    </div>
    <el-dialog
      title="选择班主任"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <div class="popv-cent">
        <div class="stud-box">
          <div style="font-size: 14px; margin-bottom: 10px;">
            <input
              style="width: 200px;"
              class="popv-tab-inpt"
              type="text"
              v-model="teacherName"
              placeholder="请输入关键字"
            />
            <span
              style="color: #14d089; margin-left: 5px; cursor: pointer;"
              @click="searchteacher"
              >搜索</span
            >
          </div>
          <ul class="stud-box-ul" style="height: 250px;">
            <li
              v-for="(item, i) in teacherlist"
              :class="item.IsStyle"
              :key="i"
              @click="selectteacher(i, item)"
            >
              <img class="stud-box-ulli-img" src="item.Picture" />
              <div class="stud-name">{{ item.Name }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveselectclassteacher"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SchoolClassList",
  data() {
    return {
      token: this.$store.state.token,
      schoolId: "",
      // loading:true,
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      teacherlist: [],
      teacheralllist: [],
      queryTeaherList: [],
      fileServerApiPath: "",
      webFilePath: "",
      rowIndex: 0,
      rowType: 0,
      cjr: "",
      fjId: "",
      teacherName: "",
      selectTeacherList: [],
      userName: "",
      userId: "",
      oddteacherList: [],
      schoolfq: [],
      orgiztionbm: 2,
      sclassteachertype: 0
    };
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.schoolId = userinfo.SSZZId;
    this.cjr = userinfo.DLM;
    this.fileServerApiPath = userinfo.fileServerApiPath;
    this.webFilePath = userinfo.webFilePath;
    this.fjId = this.$route.params.FJId;
    this.userName = userinfo.DLM;
    this.userId = userinfo.Id;

    const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };

    this.$api.post(
      "v1/qyxxzzjg/GetSchoolClassList",
      {
        SchoolId: this.schoolId,
        FJId: this.fjId,
        PagerInfo: pagerInfo,
        token: this.token
      },
      r => {
        if (r.Code === 0) {
          this.tableData = r.Target;

          // 获取已选中教师
          if (this.tableData !== null && this.tableData.length > 0) {
            this.tableData.forEach(item => {
              if (item.MainList !== null && item.MainList.length > 0) {
                item.MainList.forEach(item1 => {
                  this.oddteacherList.push(item1.TeacherId);
                });
              }
              if (
                item.AssistantList !== null &&
                item.AssistantList.length > 0
              ) {
                item.AssistantList.forEach(item2 => {
                  this.oddteacherList.push(item2.TeacherId);
                });
              }
            });
          }
        }
      }
    );

    this.$api.post(
      "v1/teacher/GetSchoolTeacherByAll",
      { SchoolId: this.schoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          var alllist = r.Target;
          this.$api.post(
            "v1/qyxxzzjg/GetNotGradeTeacher",
            { SchoolId: this.schoolId, GradeId: this.fjId, token: this.token },
            r1 => {
              if (r1.Code === 0) {
                var notgardelist = r1.Target;
                alllist.forEach(item => {
                  if (notgardelist.indexOf(item.Id) === -1) {
                    this.teacheralllist.push(item);
                  }
                });
              }
            }
          );
        }
      }
    );

    // 获取校区
    this.$api.post(
      "v1/qyxxzzjg/GetSchoolPartition",
      { SchoolId: this.schoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          this.schoolfq = r.Target;
        }
      }
    );

    // 获取学校信息
    var schoolinfo = sessionStorage.getItem("schoolinfo");
    if (
      schoolinfo === null ||
      schoolinfo === "" ||
      schoolinfo === "undefined"
    ) {
      this.$api.post(
        "v1/areaschool/GetSchool",
        { Id: this.schoolId, token: this.token },
        r => {
          if (r.Code === 0) {
            var schoolinforesult = r.Target;

            if (schoolinforesult !== null) {
              sessionStorage.setItem(
                "schoolinfo",
                JSON.stringify(schoolinforesult)
              );
              this.orgiztionbm = JSON.stringify(schoolinforesult).SFXSBM;
            }
          }
        }
      );
    } else {
      this.orgiztionbm = JSON.parse(schoolinfo).SFXSBM;
    }
  },
  methods: {
    // 刷新
    refloadList: function() {
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };

      this.$api.post(
        "v1/qyxxzzjg/GetSchoolClassList",
        {
          SchoolId: this.schoolId,
          FJId: this.fjId,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;

            this.currentPage = r.PagerInfo.PageIndex;
            this.pagesize = r.PagerInfo.PageSize;
            this.rowcount = r.PagerInfo.RowCount;
          }
        }
      );
    },

    // 新增行
    addtablerow: function() {
      var tablerow = {
        Id: "",
        ZZJGMC: "",
        BM: "",
        SSXQ: "",
        MainList: [],
        AssistantList: []
      };

      this.tableData.push(tablerow);
    },
    // 删除
    deleteRow: function(index, row) {
      if (row.Id === "" || row.Id === undefined) {
        this.tableData.splice(index, 1);

        return;
      }

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/qyxxzzjg/DeleteQYXXZZJG",
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });

                this.refloadList();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 修改名称
    updateRowName: function(index) {
      this.tableData[index].IsUpdate = 1;
    },
    // 打开选择教师
    oepnselectclassteacher: function(index, row, type) {
      this.dialogFormVisible = true;
      this.rowIndex = index;
      this.rowType = type;
      this.sclassteachertype = type;
      // 移除已选中教师
      this.teacherlist = [];
      if (this.oddteacherList !== null && this.oddteacherList.length > 0) {
        this.teacheralllist.forEach(item => {
          if (this.oddteacherList.indexOf(item.Id) === -1) {
            this.teacherlist.push(item);
          }
        });
      } else {
        this.teacherlist = this.teacheralllist;
      }

      this.teacherlist.forEach((item, index) => {
        this.teacherlist[index].IsStyle = "stud-box-ulli";
      });
      // 选中教师
      this.selectTeacherList = [];
      this.teacherName = "";
    },
    // 查询教师
    searchteacher: function() {
      // this.$api.post("v1/teacher/GetSchoolTeacherByAll", { "SchoolId": this.schoolId, "TeacherName": this.teacherName, token: this.token }, r => {
      //    if (r.Code === 0) {
      //        this.teacherlist = r.Target;
      //    }
      // });
      this.teacherlist = [];
      this.queryTeaherList = [];
      // 查询并过滤掉已经设置的教师
      if (
        this.teacherName !== "" &&
        this.teacherName !== null &&
        this.teacherName !== "undefined"
      ) {
        this.queryTeaherList = this.teacheralllist.filter(
          c => c.Name.indexOf(this.teacherName) > -1
        );
        if (this.queryTeaherList !== null && this.queryTeaherList.length > 0) {
          if (this.oddteacherList !== null && this.oddteacherList.length > 0) {
            this.queryTeaherList.forEach(item => {
              if (this.oddteacherList.indexOf(item.Id) === -1) {
                this.teacherlist.push(item);
              }
            });
          } else {
            this.teacherlist = this.queryTeaherList;
          }
        }
      } else {
        if (this.oddteacherList !== null && this.oddteacherList.length > 0) {
          this.teacheralllist.forEach(item => {
            if (this.oddteacherList.indexOf(item.Id) === -1) {
              this.teacherlist.push(item);
            }
          });
        } else {
          this.teacherlist = this.teacheralllist;
        }
      }
      // 查询结果标记已选择教师
      this.teacherlist.forEach((item, index) => {
        if (
          this.selectTeacherList !== null &&
          this.selectTeacherList.length > 0
        ) {
          if (this.selectTeacherList.find(c => c.TeacherId === item.Id)) {
            this.teacherlist[index].IsStyle = "stud-box-ulli stud-ul-sty";
          } else {
            this.teacherlist[index].IsStyle = "stud-box-ulli";
          }
        } else {
          this.teacherlist[index].IsStyle = "stud-box-ulli";
        }
      });
    },
    // 选中教师
    selectteacher: function(index, row) {
      var teacherId = row.Id;
      // 如果选中取消选中
      if (this.teacherlist[index].IsStyle === "stud-box-ulli stud-ul-sty") {
        this.teacherlist[index].IsStyle = "stud-box-ulli";
        if (this.selectTeacherList.length > 0) {
          // 取消选中
          this.selectTeacherList.forEach((item, sindex) => {
            if (item.TeacherId === teacherId) {
              this.selectTeacherList.splice(sindex, 1);
            }
          });
        }
      } else {
        // 主班主任
        if (this.sclassteachertype === 1) {
          this.teacherlist.forEach((item, cindex) => {
            if (item.Id === teacherId) {
              this.teacherlist[cindex].IsStyle = "stud-box-ulli stud-ul-sty";
              this.selectTeacherList.push({
                TeacherId: row.Id,
                TeacherName: row.Name
              });
            } else {
              this.teacherlist[cindex].IsStyle = "stud-box-ulli";
              if (this.selectTeacherList.length > 0) {
                // 取消选中
                this.selectTeacherList.forEach((sitem, sindex) => {
                  if (sitem.TeacherId === item.Id) {
                    this.selectTeacherList.splice(sindex, 1);
                  }
                });
              }
            }
          });
        } else {
          teacherId = row.Id;
          this.teacherlist[index].IsStyle = "stud-box-ulli stud-ul-sty";
          this.selectTeacherList.push({
            TeacherId: row.Id,
            TeacherName: row.Name
          });
        }
      }
    },

    // 保存选中教师
    saveselectclassteacher: function() {
      if (this.rowType === 1) {
        if (this.selectTeacherList.length === 1) {
          // 移除已选中
          if (this.tableData[this.rowIndex].MainList.length > 0) {
            this.tableData[this.rowIndex].MainList.forEach(item => {
              this.oddteacherList.splice(
                this.oddteacherList.indexOf(item.TeacherId),
                1
              );
            });
          }

          this.tableData[this.rowIndex].MainList = this.selectTeacherList;
        } else {
          if (this.selectTeacherList.length > 1) {
            this.$message({
              type: "info",
              message: "只能选择一个主班主任"
            });
            return;
          } else {
            this.$message({
              type: "info",
              message: "请选择一个主班主任"
            });
            return;
          }
        }
      } else {
        if (
          this.selectTeacherList.length >= 1 &&
          this.selectTeacherList.length <= 6
        ) {
          // 移除已选中
          if (this.tableData[this.rowIndex].AssistantList.length > 0) {
            this.tableData[this.rowIndex].AssistantList.forEach(item => {
              this.oddteacherList.splice(
                this.oddteacherList.indexOf(item.TeacherId),
                1
              );
            });
          }

          this.tableData[this.rowIndex].AssistantList = this.selectTeacherList;
        } else {
          this.$message({
            type: "info",
            message: "副班主任至少1个,最多6个"
          });
          return;
        }
      }
      // 控制班主任唯一
      this.selectTeacherList.forEach(item => {
        this.oddteacherList.push(item.TeacherId);
      });
      this.dialogFormVisible = false;
      this.tableData[this.rowIndex].IsUpdate = 1;
    },
    // 保存
    submitshowdata: function() {
      this.$api.post(
        "v1/qyxxzzjg/AddClassXXZZJGB",
        {
          SchoolClassList: this.tableData,
          UserName: this.userName,
          UserId: this.userId,
          FJId: this.fjId,
          SchoolId: this.schoolId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.dialogFormVisible = false;
            this.$notify({
              type: "success",
              message: r.Message,
              duration: 3000
            });
            this.refloadList();
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    },
    // 删除老师 info：老师详情 type：老师类型1.班主任 2.副班主任 scope:表格
    delRole(info, type, scope) {
      if (type === 1) {
        this.tableData[scope.$index].MainList = [];
        this.tableData[scope.$index].IsUpdate = 1;
      } else {
        this.tableData[scope.$index].AssistantList = this.tableData[
          scope.$index
        ].AssistantList.filter(item => item.TeacherId !== info.TeacherId);
        this.tableData[scope.$index].IsUpdate = 1;
      }
    }
  }
};
</script>

<style>
.cell {
  text-align: left;
}
</style>

<style lang="scss" scoped>
.teacher-block {
  display: flex;
  flex-wrap: wrap;
  .c-blue {
    position: relative;
    padding: 10px;
    .delete-role {
      position: absolute;
      right: 0;
      top: 0;
      color: red;
      cursor: pointer;
      font-size: 12px;
      &:hover {
        font-size: 14px !important;
      }
    }
  }
}
</style>
