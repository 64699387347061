<template>
  <el-dialog
    title="活动管理员"
    :visible.sync="visible"
    width="70%"
    :before-close="closeActivityManager"
  >
    <div class="operation-box">
      <el-button @click="formDialog.visible = true">新增管理员</el-button>
      <div class="search-block">
        <el-input
          v-model="searchStr"
          style="width:200px;margin-left:10px"
          placeholder="关键字搜索"
        ></el-input>
        <el-button style="margin-left:10px" type="primary" @click="search">
          搜索
        </el-button>
      </div>
      <div class="select-block">
        <div class="type-label">人员类型</div>
        <el-select v-model="personType" placeholder="请选择">
          <el-option
            v-for="item in typeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      fit
      tooltip-effect="dark"
      style="width: 100%;"
    >
      <el-table-column
        fixed
        prop="name"
        label="参与人员"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column fixed label="参与类别" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span
            >{{
              row.CYRYLX === 1
                ? "管理人员"
                : row.CYRYLX === 2
                ? "评价人"
                : "被评价人"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button
            @click="delItem(scope.row)"
            type="text"
            size="small"
            class="tab-wz-butta c-red"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagin-box">
      <div class="pagin-wz">
        <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
      </div>
    </div>
    <Form
      :visible.sync="formDialog.visible"
      width="70%"
      :checkedPerson="tableData"
      :typeOption="typeOption"
      @closeFormDialog="closeFormDialog"
    />
  </el-dialog>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Form from "@/views/manager/evaluationactivityset/activityManager/Form.vue";
export default {
  props: {
    visible: Boolean
  },
  components: {
    Pager,
    Form
  },
  inject: ["payLoad"],
  data() {
    return {
      searchStr: "",
      tableData: [],
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      // 表单相关
      formDialog: {
        visible: false,
        form: {}
      },
      personType: "",
      typeOption: [
        {
          label: "管理人员",
          value: 1
        }
        // {
        //   label: "评价人",
        //   value: 2
        // },
        // {
        //   label: "被评人",
        //   value: 3
        // }
      ]
    };
  },
  methods: {
    closeActivityManager() {
      this.$emit("closeActivityManager");
    },
    getTableData() {
      this.$api.post(
        "/v1/evamember/GetHDCYRYBList",
        {
          token: this.$store.state.token,
          // SearchStr: this.searchStr,
          // PagerInfo: this.pagerInfo,
          EvaId: this.payLoad.currentActivity.Id,
          CYRYLX: 1 //"1管理人员,2评价人,3被评价人"
        },
        resp => {
          let userinfo = JSON.parse(localStorage.getItem("userinfo"));
          this.$api.post(
            "/v1/teacher/GetSchoolTeacherByAll",
            { SchoolId: userinfo.SSZZId, token: userinfo.Token },
            r => {
              if (r.Code === 0) {
                resp.Target.forEach(element => {
                  r.Target.forEach(item => {
                    if (item.Id === element.CYRY) element.name = item.Name;
                  });
                });
                this.tableData = resp.Target;
              }
            }
          );
          this.pagerInfo.totalCount = resp.PagerInfo.RowCount;
        }
      );
    },
    closeFormDialog(type) {
      if (type === 1) this.getTableData();
      this.formDialog.visible = false;
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    search() {
      this.pagerInfo.pageIndex = 1;
    },
    editItem(row) {
      this.formDialog.form = row;
      this.formDialog.visible = true;
    },
    delItem(row) {
      this.$api.post(
        "/v1/evamember/DeleteHDCYRYB",
        {
          Ids: `${row.CYRY}`,
          token: JSON.parse(localStorage.getItem("userinfo")).Token
        },
        res => {
          console.log(res, "res");
        }
      );
    }
  },
  watch: {
    visible(val) {
      if (val) this.getTableData();
    }
  }
};
</script>

<style lang="scss" scoped>
.operation-box {
  display: flex;
  align-items: center;
  height: 100px;
  .select-block {
    display: flex;
    align-items: center;
    .type-label {
      padding: 0 8px;
      font-family: "微软雅黑";
    }
  }
}
</style>
