<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div id="reportChart" :class="'radardiv' + this.exportType"></div>
				<div :class="'detailrow' + this.exportType">
					<div :class="'msyclass'+this.exportType">{{ xsinfo.msy }}</div>
					<el-row :gutter="10" v-if="xsinfo.attachlist.length > 0">
						<el-image :class="'attachimage' + exportType" :src="xsinfo.attachlist[0]"></el-image>
						<el-image v-if="xsinfo.attachlist.length > 1" :class="'attachimage' + exportType"
							:src="xsinfo.attachlist[1]"></el-image>
						<el-image v-if="xsinfo.attachlist.length > 2" :class="'attachimage' + exportType"
							:src="xsinfo.attachlist[2]"></el-image>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				reportChart: null,
				exportType: 1,
				backgroudimage: "",
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "我的报告"
				},
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					msy: "",
					attachlist: [],
					txys: 1, //1雷达图2环状图
					Zstpye: 1
				},
				czbgdata: []
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			drawRadar(grdata, bjdata, njdata, radarindicator) {
				var fontsize = this.exportType == 1 ? 20 : 15;
				if (this.reportChart) {
					this.reportChart.dispose();
				}
				this.reportChart = this.$echarts.init(
					document.getElementById("reportChart")
				);

				let xdata = ['个人值'];
				let serieslist = [{
					name: "个人值",
					// type: "bar",
					value: grdata,
					areaStyle: {
						color: "rgba(238, 106, 35, 0.3)"
					}
				}];
				if (this.xsinfo.Zstpye == 1) {
					xdata.push('班级平均');
					serieslist.push({
						name: "班级平均",
						// type: "bar",
						value: bjdata,
						areaStyle: {
							color: "rgba(260, 75, 235, 0.3)"
						}
					});
				} else if (this.xsinfo.Zstpye == 2) {
					xdata.push('年级平均');
					serieslist.push({
						name: "年级平均",
						// type: "bar",
						value: njdata,
						areaStyle : {
							color: "rgba(34, 115, 194, 0.3)"
						}
					});
				} else {
					xdata.push('班级平均');
					serieslist.push({
						name: "班级平均",
						// type: "bar",
						value: bjdata,
						areaStyle: {
							color: "rgba(260, 75, 235, 0.3)"
						}
					});
					xdata.push('年级平均');
					serieslist.push({
						name: "年级平均",
						// type: "bar",
						value: njdata,
						areaStyle: {
							color: "rgba(34, 115, 194, 0.3)"
						}
					});
				}
				this.reportChart.setOption({
					// color: ["rgba(238, 106, 35)", "rgba(34, 115, 194)"],
					title: {
						show: false
					},
					legend: {
						orient: "vertical",
						left: "left",
						textStyle: {
							color: "#333333",
							fontSize: fontsize,
							fontWeight: 400
						},
						data: xdata
					},
					radar: {
						indicator: radarindicator,
						axisName: {
							color: "#333333",
							fontSize: fontsize,
							fontWeight: 400
						}
					},
					series: [{
						type: "radar",
						data: serieslist
					}]
				});
			},
			drawBar(grdata, njdata, xarry) {
				if (this.reportChart) {
					this.reportChart.dispose();
				}
				this.reportChart = this.$echarts.init(
					document.getElementById("reportChart")
				);
				this.reportChart.setOption({
					tooltip: {},
					xAxis: {
						data: xarry
					},
					yAxis: {},
					legend: {
						bottom: "10",
						data: ["个人值", "年级平均值"]
					},
					series: [{
							name: "个人值",
							type: "bar",
							data: grdata,
							itemStyle: {
								color: "#ee6a23"
							}
						},
						{
							name: "年级平均值",
							type: "bar",
							data: njdata,
							itemStyle: {
								color: "#2273c2"
							}
						}
					]
				});
			},
			drawPie(xspiedata) {
				if (this.reportChart) {
					this.reportChart.dispose();
				}
				this.reportChart = this.$echarts.init(document.getElementById("reportChart"));
				this.reportChart.setOption({
					tooltip: {
						show: false
					},
					legend: {
						orient: "vertical",
						left: "left"
					},
					series: [{
						type: "pie",
						radius: ["60%", "90%"],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 5,
							borderColor: "#fff",
							borderWidth: 1
						},
						label: {
							show: false,
							position: "center"
						},
						emphasis: {
							label: {
								show: true,
								fontWeight: "bold"
							}
						},
						labelLine: {
							show: false
						},
						data: xspiedata
					}]
				});
			},
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.xsxx.Id;
				// this.xsinfo.XM = xsdata.xsxx.XM;
				// this.xsinfo.XBM = xsdata.xsxx.XBM;
				// this.xsinfo.ZP = xsdata.xsxx.ZP;
				// this.xsinfo.XQAH = xsdata.xsxx.XQAH;
				// this.xsinfo.SSXX = xsdata.xsxx.SSXX;
				// this.xsinfo.SSXQ = xsdata.xsxx.SSXQ;
				// this.xsinfo.SSXN = xsdata.xsxx.SSXN;
				// this.xsinfo.XQLX = xsdata.xsxx.XQLX;
				// this.xsinfo.BZRMC = xsdata.xsxx.BZRMC;
				// this.xsinfo.NL = xsdata.xsxx.NL;
				// this.xsinfo.XXMC = xsdata.xsxx.XXMC;
				// this.xsinfo.XXTB = xsdata.xsxx.XXTB;
				// this.xsinfo.SSBJ = xsdata.xsxx.SSBJ;
				// this.xsinfo.BJMC = xsdata.xsxx.BJMC;
				// this.xsinfo.XXMC = xsdata.xsxx.XXMC;
				// this.xsinfo.XXBM = xsdata.xsxx.XXBM;
				// this.xsinfo.XZ = xsdata.xsxx.XZ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}

				// this.info = xsdata.pageData;
				// 模板实体
				let template = JSON.parse(localStorage.getItem("schooltemplate_" + this.xqid));
				// console.log(template, "template")


				// 数据实体
				let templatedata = JSON.parse(xsdata.ZHSY);
				// console.log(templatedata, "templatedata")

				let datalist = [];


				this.xsinfo.msy = '';
				if (templatedata) {
					for (let i = 0; i < templatedata.length; i++) {
						let hdmodel = template.ZHSY.WDBG.find((item) => item.Id == templatedata[i].Id);
						if (hdmodel) {
							// console.log(hdmodel)
							templatedata[i].MC = hdmodel.Name;
							datalist.push(templatedata[i]);
						}
					}
					// 数据描述
					let templatedatapyms = JSON.parse(xsdata.ZhsyData)
					if (templatedatapyms) {
						this.xsinfo.attachlist = templatedatapyms.Images;
						for (let i = 0; i < template.ZHSY.pyms.length; i++) {
							this.xsinfo.msy += template.ZHSY.pyms[i].replace(/&&/g, templatedatapyms.MS[i]);
						}
					}
					this.xsinfo.txys = template.ZHSY.Type;
					this.xsinfo.Zstpye = template.ZHSY.Zstpye;
				}
				this.czbgdata = datalist;

				if (this.czbgdata != null && this.czbgdata != undefined && this.czbgdata.length > 0) {
					var dfmax = Math.max.apply(Math, this.czbgdata.map(item => {
						return item.DF;
					}));
					var gavgmax = Math.max.apply(Math, this.czbgdata.map(item => {
						return item.GAVG;
					}));
					var max = Math.max(dfmax, gavgmax);
					if (this.xsinfo.txys == 1) {
						let indicator = this.czbgdata.map(item => {
							return Object.assign({}, {
								name: item.MC
							});
						});
						this.drawRadar(
							this.czbgdata.map(item => {
								return item.DF;
							}),
							this.czbgdata.map(item => {
								return item.CAVG;
							}),
							this.czbgdata.map(item => {
								return item.GAVG;
							}),
							indicator
						);
					} else {
						// this.drawBar(
						// 	this.czbgdata.map(item => {
						// 		return item.DF;
						// 	}),
						// 	this.czbgdata.map(item => {
						// 		return item.GAVG;
						// 	}),
						// 	this.czbgdata.map(item => {
						// 		return item.MC;
						// 	})
						// );
						let xspiedata = this.czbgdata.map(item => {
							return Object.assign({}, {
								name: item.MC,
								value: item.DF
							});
						});
						this.drawPie(xspiedata);
					}
				}

				// this.getxsreportdata();
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthreport/GetGrowthReport", {
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xsinfo.SSXQ,
						XQXN: this.xsinfo.SSXN,
						XQLX: this.xsinfo.XQLX,
						XXBM: this.xsinfo.XXBM,
						XSId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var info = r.Target;
							if (info != null && info != undefined) {
								if (info.SchoolStuReBasicSet != null && info.SchoolStuReBasicSet != undefined) {
									this.xsinfo.txys = info.SchoolStuReBasicSet.TXYS;
								}
								if (info.StudentGrowthReport != null && info.StudentGrowthReport != undefined) {
									if (info.StudentGrowthReport.TXDF != null && info.StudentGrowthReport.TXDF !=
										undefined) {
										this.czbgdata = JSON.parse(info.StudentGrowthReport.TXDF);
									}
									this.xsinfo.msy = info.StudentGrowthReport.HDPY;
									if (info.StudentGrowthReport.HDPYTP != null && info.StudentGrowthReport
										.HDPYTP != undefined)
										this.xsinfo.attachlist = info.StudentGrowthReport.HDPYTP.split(",");
								}
							}
							if (this.czbgdata != null && this.czbgdata != undefined && this.czbgdata.length > 0) {
								var dfmax = Math.max.apply(Math, this.czbgdata.map(item => {
									return item.DF;
								}));
								var gavgmax = Math.max.apply(Math, this.czbgdata.map(item => {
									return item.GAVG;
								}));
								var max = Math.max(dfmax, gavgmax);
								if (this.xsinfo.txys == 1) {
									let indicator = this.czbgdata.map(item => {
										return Object.assign({}, {
											name: item.MC
										});
									});

									this.drawRadar(
										this.czbgdata.map(item => {
											return item.DF;
										}),
										this.czbgdata.map(item => {
											return item.GAVG;
										}),
										indicator
									);
								} else {
									this.drawBar(
										this.czbgdata.map(item => {
											return item.DF;
										}),
										this.czbgdata.map(item => {
											return item.GAVG;
										}),
										this.czbgdata.map(item => {
											return item.MC;
										})
									);
								}
							}
						}
					}
				);
			}
		},
		mounted() {},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/dot_orange_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/dot_orange_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.radardiv1 {
		height: 465px;
		width: 760px;
		margin-left: 40px;
		margin-top: 30px;
		border-radius: 15px;
	}

	.radardiv2 {
		height: 390px;
		width: 535px;
		margin-top: 20px;
		margin-left: 30px;
		border-radius: 10px;
	}


	.detailrow1 {
		padding: 5px 10px;
		height: 465px;
		min-height: 360px;
		width: 736px;
		margin-top: 10px;
		margin-left: 40px;
		border-radius: 15px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #333333;

		.msyclass1 {
			height: 235px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 8;
		}

	}

	.detailrow2 {
		height: 265px;
		width: 515px;
		margin-top: 10px;
		margin-left: 30px;
		border-radius: 10px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		padding: 5px 10px;
		color: #333333;

		.msyclass2 {
			height: 120px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 5;
		}
	}

	.attachimage1 {
		width: 236.66px;
		height: 185px;
		margin: 20px 5px 0 5px;
	}

	.attachimage2 {
		width: 160.66px;
		height: 140px;
		margin: 5px 5px 0 5px;
	}
</style>