<template>
  <el-table :data="tableData"
            border
            fit
            tooltip-effect="dark"
            style="width: 100%;"
            @row-dblclick="showItem">
    <el-table-column type="index"
                     fixed
                     width="60"
                     label="编号"></el-table-column>
    <el-table-column fixed
                     prop="MBMC"
                     min-width="80"
                     label="模板名称"
                     show-overflow-tooltip></el-table-column>
    <el-table-column fixed
                     prop="BM"
                     min-width="80"
                     label="别名"
                     show-overflow-tooltip></el-table-column>
    <el-table-column fixed
                     prop="TTMC"
                     min-width="100"
                     label="抬头名称"
                     show-overflow-tooltip></el-table-column>
    <el-table-column fixed="right"
                     label="操作">
      <template slot-scope="scope">
        <el-button @click="editItem(scope.row)"
                   type="text"
                   size="small">编辑</el-button>
        <el-button @click="delItem(scope.row)"
                   type="text"
                   size="small">删除</el-button>
        <el-button v-if="MBDM === 1"
                   @click="setHDItem(scope.row)"
                   type="text"
                   size="small">设置活动</el-button>
        <el-button @click="setXMItem(scope.row)"
                   type="text"
                   size="small">设置项目</el-button>
        <!-- <el-button @click="AssociateItem(scope.row)" type="text" size="small">关联等级</el-button> -->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
  },
  data () {
    return {
      user: "",
      MBDM: 1,
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    //控制跳转与禁用
    this.MBDM = JSON.parse(localStorage.getItem("MBInfo")).MBDM;
  },

  methods: {
    showItem (row) {
      this.$emit("showItem", row);
    },
    //编辑
    editItem (data) {
      this.$emit("editItem", data);
    },
    //删除
    delItem (data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("delItem", data);
        })
        .catch(() => { });
    },
    // 设置活动
    setHDItem (data) {
      this.$router.push({ name: "sethd", params: { data: data } });
    },
    // 设置项目
    setXMItem (data) {
      // let name = 'setxm'
      // if(this.MBDM == 2) name = 'teachersetXM'
      // if(this.MBDM == 1) name = 'setxm'
      if (this.MBDM == 1) {
        //职业道德报表
        this.$api.post(
          "/v1/mbbb/GetGLHDBList",
          {
            MBGLID: data.Id,
            token: this.user.Token,
          },
          (res) => {
            if (res.Code === 0) {
              let list = res.Target;
              if (list.length <= 0) {
                this.$message.error("请先设置活动");
              } else {
                let DX = false;
                let DL = false;
                let list2 = list.filter((i) => i.GLHD != null);
                //定量定性活动至少有一条
                let listDL = list2.filter((i) => i.LX == 1).length;
                let listDX = list2.filter((i) => i.LX == 2).length;

                if (listDL <= 0 && listDX <= 0) {
                  this.$message.error("请先设置活动中的定量或定性活动");
                } else {
                  this.$router.push({ name: "setxm", params: { data: data } });
                }
              }
            }
          }
        );
        //
      } else if (this.MBDM == 2) {
        // console.log(data, 'data');
        localStorage.setItem("BBInfo", JSON.stringify(data));
        this.$router.push({ name: "teachersetXM", query: { data: data } });
      }
    },
    //关联等级
    AssociateItem () {
      console.log("关联等级");
    },
  },
};
</script>

<style></style>
