<template>
  <el-dialog
    title="活动评价时间"
    :visible.sync="visible"
    width="70%"
    :before-close="closeEvaluationTime"
  >
    <div class="operation-box">
      <el-button
        @click="
          formDialog.visible = true
          currentData = {}
        "
        >新增评价时间</el-button
      >
      <!-- <div class="search-block">
        <el-input
          v-model="searchStr"
          style="width:200px;margin-left:10px"
          placeholder="关键字搜索"
        ></el-input>
        <el-button style="margin-left:10px" type="primary" @click="search">
          搜索
        </el-button>
      </div> -->
      <div class="select-block">
        <div class="type-label">学期</div>
        <el-select v-model="SSXQ" placeholder="请选择" @change="SSXQchange">
          <el-option
            v-for="item in SSXQoption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      fit
      tooltip-effect="dark"
      style="width: 100%;"
    >
      <el-table-column
        fixed
        prop="KSSJ"
        label="起始时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column fixed prop="JSSJ" label="结束时间" show-overflow-tooltip>
      </el-table-column>
      <el-table-column fixed label="学期" show-overflow-tooltip>
        <template scope="{row}">
          <span
            >{{
              SSXQoption.find(item => item.Id === row.SSXQ)
                ? SSXQoption.find(item => item.Id === row.SSXQ).Name
                : ''
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button
            @click="editItem(scope.row)"
            :disabled="handleDisabled(scope.row)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            @click="delItem(scope.row)"
            type="text"
            size="small"
            class="tab-wz-butta c-red"
            >删除</el-button
          >
          <el-button
            v-if="payLoad.currentActivity.HPCS >= 2"
            @click="createHPperson(scope.row)"
            type="primary"
            size="small"
            class="tab-wz-butta "
            >预生成评价人数据</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagin-box">
      <div class="pagin-wz">
        <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
      </div>
    </div>
    <Form
      :visible.sync="formDialog.visible"
      width="70%"
      :currentData="currentData"
      :SSXQoption="SSXQoption"
      @closeFormDialog="closeForm"
    />
  </el-dialog>
</template>

<script>
import Pager from '@/components/Pager.vue'
import Form from '@/views/manager/evaluationactivityset/evaluationTime/Form.vue'
export default {
  props: {
    visible: Boolean
  },
  components: {
    Pager,
    Form
  },
  inject: ['payLoad'],
  data() {
    return {
      searchStr: '',
      tableData: [],
      baseData: [],
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      // 表单相关
      formDialog: {
        visible: false
      },
      SSXQ: '',
      SSXQoption: [],
      currentData: {}
    }
  },

  methods: {
    closeEvaluationTime() {
      this.$emit('closeEvaluationTime')
    },
    getTableData(searchStr = '') {
      this.$api.post(
        'v1/evastage/GetHDKZJDList',
        {
          token: this.$store.state.token,
          SearchStr: searchStr,
          pagerInfo: this.pagerInfo,
          EvaId: this.payLoad.currentActivity.Id
        },
        resp => {
          this.tableData = this.baseData = resp.Target
          this.pagerInfo.totalCount = resp.PagerInfo.RowCount
        }
      )
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val
    },
    search() {
      this.pagerInfo.pageIndex = 1
    },
    editItem(item) {
      this.currentData = item
      this.formDialog.visible = true
    },
    delItem(row) {
      if (row.KSSJ < Date.now()) {
        this.$message({
          message: '活动已开始，不能删除',
          type: 'warning'
        })
      } else {
        let { Token: token, Id: UserId } = JSON.parse(
          localStorage.getItem('userinfo')
        )

        let SSXQ = this.SSXQoption.find(item => item.Id === row.SSXQ)
        this.$api.post(
          '/v1/evastage/DeleteHDKZJD',
          {
            Ids: `${row.Id}`,
            token,
            EvaId: this.payLoad.currentActivity.Id,
            TermYear: SSXQ.SSXN, //学年
            TermType: SSXQ.XQLX,
            UserId,
            IsPD: this.payLoad.currentActivity.HPCS < 2 ? 0 : 1
          },
          res => {
            if (res.Code === 0) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.getTableData()
            }
          }
        )
      }
    },
    // 预生成互评人数据
    createHPperson(row) {
      console.log(row, '')
      this.$api.post(
        'v1/evastage/PreBuildMutualPerson',
        {
          EvaId: this.payLoad.currentActivity.Id,
          PJJDId: row.Id,
          EvaluatedObject: this.payLoad.currentActivity.BPJDXLX,
          token: JSON.parse(localStorage.getItem('userinfo')).Token
        },
        res => {
          if (res.Code === 0) this.$message('成功')
        }
      )
    },
    getTerm() {
      //获取学期
      let { SSZZId: SchoolId, Token: token } = JSON.parse(
        localStorage.getItem('userinfo')
      )
      this.$api.post(
        '/v1/accountmanage/GetTerm',
        {
          SchoolId,
          token
        },
        res => {
          if (res.Code === 0) {
            this.SSXQoption = [{ Name: '全部', Id: 0 }, ...(res.Target || [])]
            this.SSXQ = 0
          }
        }
      )
    },
    SSXQchange(val) {
      if (val) this.tableData = this.baseData.filter(item => item.SSXQ === val)
      else this.tableData = this.baseData
    },
    closeForm(type) {
      if (type === 1) this.getTableData()
      this.formDialog.visible = false
    },
    handleDisabled(row) {
      return new Date(row.JSSJ).getTime() < Date.now()
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getTerm()
        this.getTableData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-box {
  display: flex;
  align-items: center;
  height: 100px;
  .select-block {
    display: flex;
    align-items: center;
    .type-label {
      padding: 0 8px;
      font-family: '微软雅黑';
    }
  }
}
</style>
