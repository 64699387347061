<template>
    <el-dialog top="5vh" :visible.sync="value" custom-class="my-dialog" 
        :close-on-click-modal="false" :before-close="close">
        <el-button @click="downPFB" style="float: left;" size="small" type="primary" >下载报表</el-button>
        <div class="page-container pdfCentent">
        <div class="total-box" v-for="t in tableData" :key="t.total">
            <div class="page-title">{{ pageTitle }}结果名单</div>
            <div class="page-subtitle">({{ pageSubtitle }})</div>
        
            <div class="top-box">
                <div class="top-item">单位(公章):</div>
                <div class="top-item">校长(签字):</div>
            </div>  
        
            <div class="title-box">
                <div class="title-item" v-for="(it,index) in t.total" :key="index">
                    <div class="item-it">
                        <div>序号</div>
                        <div>姓名</div>
                        <div>考核结果</div>
                    </div>                  
                </div>
            </div>

            <div class="box">
                <div class="box-item" v-for="(it,index) in t.total" :key="index">
                    <div class="item-it" v-for="(i,index2) in t.tableData.slice(index*21,(index+1)*21)" :key="index2">
                        <div>{{i.xh}}</div>
                        <div>{{i.JSMC}}</div>
                        <div>{{i.KHJG}}</div>
                    </div>
                </div>
            </div> 
        
        <div class="box-bottom">考核结果填:优秀、合格、不合格。本表作考核工作报告附件。表格不够可加页加行。</div>
        </div>
    </div> 
    </el-dialog>
  </template>
  
  <script>
    import JSZip from "jszip";
    import FileSaver from "file-saver";
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            MBGLID:String,
            pageSubtitle:String,
            // tableRow: {
            //     type: Object,
            //     required: true,
            //     default: function() {
            //         return {};
            //     }
            // },
        },
        model: {
            prop: "visible",
            event: "change"
        },
      data() {
        return {
            value:false,
            pageTitle: JSON.parse(localStorage.getItem('DevelopmentMB')).TTMC,
            
            tableData: [],
            groupCount: 3,
            zips:null,
            user:'',
            total:1,
            page:2,
        }
      },
      created(){
        this.user = JSON.parse(localStorage.getItem("userinfo"));
      },
      mounted(){
        // let arr = []
        // for(let i = 0;i<100;i++){
        //     arr.push({
        //         xh:i+1,
        //         xm:`${i}李`,
        //         khjg:'优秀'
        //     })
        // }
        // this.tableData = arr
        
        // let newArr = arr.reduce((pre,cur,index)=>{
        //     if(index<23){
        //         let obj = {
        //             xh1:index+1,
        //             xm1:cur.xm,
        //             khjg1:cur.khjg,

        //         }
        //         if(arr[index+23]){
        //             obj = {
        //                 ...obj,
        //                 xh2:index+1,
        //             xm2:cur.xm,
        //             khjg2:cur.khjg,

        //             }
        //         }
        //         pre.push(obj)
        //     }
        //     return pre
        // },[])
      },
    
      methods:{
         // 下载
        async downPFB(){
            this.zips = new JSZip();   
            let title = this.pageTitle
            let p = await this.getPdfFromHtml2('pdfCentent',title)
            await this.zips.file(
                `${p.xm}-${new Date().getTime()}.pdf`,
                p.PDF.output("blob")
            );
            this.zips.generateAsync({
                type: "blob",
            }).then((content) => {
                FileSaver.saveAs(
                content,
                this.pageTitle + new Date().getTime() + ".zip"
                );
            });
        },
        close(){
            this.$emit('canceldialog')
        },
        //获取报表详情名单
        getBBxq(){
            if(!this.$parent.bbid)return
            this.$api.post(
                'v1/mbbb/GetZYDDKHBBById',
                {
                    MBGLID:this.MBGLID,
                    SCJHID:this.$parent.bbid,
                    token: this.user.Token
                },r=>{
                    if(r.Code == 0){
                        let list = r.Target||[]
                        list.forEach((item,index) => {
                            item.xh = index+1
                        })
                        console.log(list);
                        this.page = Math.ceil(list.length/63)
                        let arr = []
                        for (let index = 1; index <= this.page; index++) {
                            let obj={
                                page : index,
                                total:3,
                                tableData:[]
                            }
                            obj.tableData = list.slice((index-1)*63,index*63)
                            if(obj.tableData.length<63){
                              let a=  63-obj.tableData.length
                              for(let i=1;i<=a;i++){
                                obj.tableData.push({
                                    JSMC:"",
                                    KHJG:"",
                                    xh:'',

                                })
                              }
                            }
                            arr.push(obj)
                        }
                        
                        this.tableData  = arr
                        console.log(this.tableData,'获取报表详情名单');

                    }
                }
            )
        },
  
      },
        watch:{
            visible(val) {
                this.value = val;
                if(val){
                    this.getBBxq()
                }
            }
        }
    }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep .my-dialog{
    padding: 2px;
    
    min-width: 1200px;

    ::v-deep .el-dialog__body{
        width: 100%;
    }
    
    .top-box{
        display: flex;
        padding-right: 170px;
        justify-content: space-between;
    }
    .box-bottom{
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }
    .title-box{
        width: 95%;
        display: flex;
        border: black solid 1px;
        border-bottom: none;
        .title-item{
            width: 33.333%;
            .item-it{
                display: flex;
                div{
                    width: 33.33%;
                    padding: 5px 4px;
                    text-align: center;
                    border: black solid 1px;
                }
            }
        }
    }
    .box{
        display: flex;
        width: 95%;
        border: black solid 1px;
        border-top: none;
        .box-item{
            width: 33.333%;
            .item-it{
                display: flex;
                div{
                    width: 33.33%;
                    padding: 2px 4px;
                    text-align: center;
                    min-height: 21px;
                    border: black solid 1px;
                }
            }
            
        }
    }
  }
  
  .page-container {
    width: 297mm;
    // height: 210mm;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px 30px;
    box-sizing: border-box;

  }
  .total-box{
    width: 297mm;
    height: 210mm;
  }
   
  .page-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 10px;
  }
  
  .page-subtitle {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  
  }
  </style>