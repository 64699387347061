<template>
  <div>
    <el-table
      v-loading="isLoading"
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 100%;"
    >
      <el-table-column
        fixed
        prop="PJCYDXMC"
        label="对象名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="参与方式" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row | handlePJFS(payLoad.currentActivity) }}
        </template>
      </el-table-column>
      <el-table-column label="对象形式" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
            scope.row.HDXS === 1
              ? "自评"
              : scope.row.HDXS === 2
              ? "互评"
              : scope.row.HDXS === 4
              ? "主评"
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="开展年级" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.GradeOfObjectList | handleGradeStr }}
        </template>
      </el-table-column>
      <el-table-column
        prop="PJFSStr"
        label="评价方式"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="SYZTStr"
        label="状态"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button @click="setIndex(scope.row)" type="text" size="small"
            >设置{{
              scope.row.PJFS === 1
                ? "通用指标"
                : scope.row.PJFS == 2
                ? "星星指标"
                : "问卷"
            }}</el-button
          >
          <el-button
            @click="delItem(scope.row)"
            type="text"
            size="small"
            class="tab-wz-butta c-red"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    handleGradeStr(val) {
      let zh = [
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二"
      ];
      let str = "";
      if (val && val.length > 0) {
        val.forEach((num, index) => {
          str +=
            zh[parseInt(num)] + "年级" + (index === val.length - 1 ? "" : "，");
        });
      }
      return str;
    },
    handlePJFS(row, activity) {
      //  cpr 1老师 2学生  bpr 1学生 2老师 3班级
      if (activity.HDCPR === 2 && activity.BPJDXLX === 2) {
        return "角色参与";
      } else {
        return row.PJCYDXLBStr;
      }
    }
  },
  inject: ["payLoad"],
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    setIndex(data) {
      this.$emit("setIndex", data);
    },
    delItem(data) {
      this.$emit("delItem", data);
    }
  }
};
</script>
