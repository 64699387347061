import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api/http.js";
import * as echarts from "echarts";

//引入富文本框
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
Vue.use(mavonEditor);

// 引入柱状图图表，图表后缀都为 Chart
import {
  BarChart,
  GaugeChart,
  RadarChart,
  LineChart,
  PieChart
} from "echarts/charts";
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from "echarts/components";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

import "babel-polyfill";
import "xe-utils";
import VXETable from "vxe-table";
import VXETablePluginElement from "vxe-table-plugin-element";
import "vxe-table/lib/style.css";
import "vxe-table-plugin-element/dist/style.css";
import "./plugins/element.js";
import { formatDate } from "@/style/js/Common.js";
import "./element-variables.scss";
import Print from "./plugins/print.js";
import html2Pdf from "./plugins/html2Pdf.js";
// 使用Vue.use()方法就会调用工具方法中的install方法
Vue.use(html2Pdf);
//Vue.use(echarts);
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  PieChart,
  RadarChart,
  GaugeChart,
  CanvasRenderer
]);
Vue.prototype.$echarts = echarts;
// import Video from "video.js";
// import "video.js/dist/video-js.css";
// Vue.prototype.$video = Video;
Vue.use(VXETable);
VXETable.use(VXETablePluginElement);
Vue.use(Print);
require("./permission/permission.js");
Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$window = window;
Vue.filter("DateFormat", function (time, fmt) {
  var date = new Date(time.replace(/-/g, "/").replace("T", " "));
  return formatDate(date, fmt);
});
Vue.filter("EncryptPhoneNumber", function (cellValue) {
  if (Number(cellValue) && String(cellValue).length === 11) {
    var mobile = String(cellValue);

    var reg = /^(\d{3})\d{4}(\d{4})$/;

    return mobile.replace(reg, "$1****$2");
  } else {
    return cellValue;
  }
});
Vue.filter("NumToChinese", function (num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    alert("Number is wrong!");
    return "Number is wrong!";
  }
  let AA = new Array(
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九"
  );
  let BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
  let a = ("" + num).replace(/(^0*)/g, "").split("."),
    k = 0,
    re = "";
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re;
        break;
      case 4:
        if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
          re = BB[4] + re;
        break;
      case 8:
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
      re = AA[0] + re;
    if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
    k++;
  }
  if (a.length > 1) {
    //加上小数部分(如果有小数部分)
    re += BB[6];
    for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
  }
  re = re.replace("一十", "十");
  return re;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
