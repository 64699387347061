var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-whole-box"},[_c('el-form',{ref:"formData",staticStyle:{"display":"inline-block"},attrs:{"model":_vm.formData,"label-width":"120px"}},[_c('div',{staticStyle:{"padding-bottom":"10px","margin-top":"20px","font-size":"14px","text-align":"center"}},[_c('el-form-item',{attrs:{"label":"标题：","prop":"BT","rules":[
          { required: true, message: '标题不能为空', trigger: 'change' },
          {
            min: 1,
            max: 50,
            message: '不能超过50 个字符',
            trigger: 'change'
          }
        ]}},[_c('el-input',{staticStyle:{"width":"400px"},attrs:{"placeholder":"请输入标题"},model:{value:(_vm.formData.BT),callback:function ($$v) {_vm.$set(_vm.formData, "BT", $$v)},expression:"formData.BT"}})],1)],1),_c('div',{staticStyle:{"padding-bottom":"10px","margin-top":"20px","font-size":"14px","text-align":"center"}},[_c('el-form-item',{attrs:{"label":"简介：","prop":"BZ","rules":[
          { max: 200, message: '不能超过200个字符', trigger: 'change' }
        ]}},[_c('el-input',{staticStyle:{"width":"400px"},attrs:{"type":"textarea","autosize":{ minRows: 6 },"placeholder":"请输入简介"},model:{value:(_vm.formData.BZ),callback:function ($$v) {_vm.$set(_vm.formData, "BZ", $$v)},expression:"formData.BZ"}})],1)],1),_c('div',{staticStyle:{"margin-top":"20px","font-size":"14px"}},[_c('span',{staticClass:"c-g-9",staticStyle:{"display":"block"}},[_vm._v("内容:")])]),_vm._l((_vm.formData.WJTMList),function(item,index){return _c('div',{key:index,staticClass:"whole-tit-mianbox"},[_c('div',{staticStyle:{"font-size":"0px","box-sizing":"border-box"}},[_c('div',{staticClass:"whole-tit-topleft",staticStyle:{"vertical-align":"top","position":"relative","top":"14px","height":"44px","width":"50px"}},[_vm._v(" "+_vm._s(index + 1)+". ")]),_c('div',{staticClass:"whole-tit-toprig",staticStyle:{"height":"44px"}},[_c('el-form-item',{attrs:{"prop":'WJTMList[' + index + '].FXMC',"rules":[
              { required: true, message: '请输入题目', trigger: 'change' },
              {
                min: 1,
                max: 50,
                message: '不能超过50 个字符',
                trigger: 'change'
              }
            ]}},[_c('el-input',{staticClass:"jf-textarea-wj jf-form",attrs:{"type":"textarea","autosize":"","placeholder":"请输入不超过50字"},model:{value:(item.FXMC),callback:function ($$v) {_vm.$set(item, "FXMC", $$v)},expression:"item.FXMC"}})],1)],1),_c('div',{staticClass:"jf_sele-l-box",staticStyle:{"vertical-align":"top","position":"relative","top":"3px","height":"44px"}},[_c('el-form-item',{attrs:{"prop":'WJTMList[' + index + '].FXLX',"rules":[
              {
                required: true,
                message: '请选择题目类型',
                trigger: 'change'
              }
            ]}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(item.FXLX),callback:function ($$v) {_vm.$set(item, "FXLX", $$v)},expression:"item.FXLX"}},_vm._l((_vm.SSLB),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1)]),_c('div',[_c('el-form-item',{staticClass:"jf_wj_dx",attrs:{"prop":'WJDAList',"rules":[
            {
              validator: _vm.checklength,
              SSLB: item.FXLX,
              message: '缺少答案',
              trigger: 'change'
            }
          ]}})],1),(item.FXLX != 3)?_c('div',{staticClass:"smtit-whole-box"},[_c('div',{staticClass:"smtit-box"},_vm._l((_vm.formData.WJDAList.filter(
              c => c.FXId == item.Id
            )),function(da,i){return _c('div',{key:i,staticClass:"smtit-cent"},[_vm._v(" "+_vm._s(_vm.ZM[i])+". "),(da.DALX == 1)?_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":'WJDAList[' + i + '].DAMC',"rules":[
                {
                  required: true,
                  message: '请输入答案',
                  trigger: 'change'
                },
                {
                  min: 1,
                  max: 20,
                  message: '不能超过20 个字符',
                  trigger: 'change'
                }
              ]}},[_c('el-input',{staticClass:"jf-form",staticStyle:{"width":"300px","margin-left":"5px"},attrs:{"size":"small","placeholder":"请输入内容"},model:{value:(da.DAMC),callback:function ($$v) {_vm.$set(da, "DAMC", $$v)},expression:"da.DAMC"}})],1):_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("其他")]),_c('div',{staticClass:"tab-wz-buttl",staticStyle:{"margin-left":"30px"}},[_c('a',{staticClass:"tab-wz-butta c-red",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.delFX(da.Id)}}},[_vm._v("删除")])])],1)}),0),(item.FXLX != 3)?_c('div',{staticClass:"smtit-butt-box"},[_c('div',{staticClass:"tab-wz-buttl",staticStyle:{"margin-right":"20px"}},[_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.addFX(item.Id, 1)}}},[_vm._v("+添加普通选项")])]),_c('div',{staticClass:"tab-wz-buttl"},[(
                _vm.formData.WJDAList.findIndex(
                  c => c.FXId == item.Id && c.DALX == 2
                ) == -1
              )?_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.addFX(item.Id, 2)}}},[_vm._v("+添加“其他”选项 ")]):_vm._e()])]):_vm._e()]):_vm._e()])}),_c('div',{staticClass:"bigtit-add-box"},[_c('div',{staticClass:"tab-wz-buttl"},[_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":_vm.addItem}},[_vm._v("+ 新增问题")])])]),_c('el-form-item',{attrs:{"label":"发送对象：","prop":"JSDXLX","rules":[
        { required: true, message: '请选择发送对象', trigger: 'change' }
      ]}},[_c('el-row',[_c('el-col',[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.JSDXLX),callback:function ($$v) {_vm.$set(_vm.formData, "JSDXLX", $$v)},expression:"formData.JSDXLX"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),(_vm.formData.JSDXLX == 1)?_c('el-row',[_c('el-col',[_c('el-checkbox',{key:"1",attrs:{"label":"1"},on:{"change":_vm.handleNJCheckAllChange},model:{value:(_vm.njall),callback:function ($$v) {_vm.njall=$$v},expression:"njall"}},[_vm._v("全选")]),_vm._l((_vm.njlist),function(nj){return _c('el-checkbox',{key:nj.Id,attrs:{"label":nj.Id},on:{"change":_vm.handleNJCheckedChange},model:{value:(_vm.xznjlist),callback:function ($$v) {_vm.xznjlist=$$v},expression:"xznjlist"}},[_vm._v(_vm._s(nj.Name))])})],2)],1):_vm._e()],1),_c('el-form-item',{attrs:{"prop":"FSSJ"}},[_c('el-row',[_c('el-col',[_c('el-radio',{attrs:{"label":"1"},on:{"change":_vm.handleFSLXChange},model:{value:(_vm.formData.FSLX),callback:function ($$v) {_vm.$set(_vm.formData, "FSLX", $$v)},expression:"formData.FSLX"}},[_vm._v("立即发送")]),_c('el-radio',{attrs:{"label":"2"},on:{"change":_vm.handleFSLXChange},model:{value:(_vm.formData.FSLX),callback:function ($$v) {_vm.$set(_vm.formData, "FSLX", $$v)},expression:"formData.FSLX"}},[_vm._v("定时发送")]),(_vm.formData.FSLX == 2)?_c('el-date-picker',{attrs:{"type":"datetime","value-format":"yyyy-MM-dd HH:mm","placeholder":"选择日期"},model:{value:(_vm.formData.FSSJ),callback:function ($$v) {_vm.$set(_vm.formData, "FSSJ", $$v)},expression:"formData.FSSJ"}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"cent-bott-butt"},[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","disabled":_vm.isDisabled},on:{"click":_vm.submitshowdata}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.canceladd}},[_vm._v("取消")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }