<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <!--表格区开始-->
      <div class="tab-heng-box margno-t">
        <div class="table-er-box">
          <div
            class="table-er-butt"
            v-if="showBtn(perMission.add)"
            @click="editItem({})"
          >
            <img class="table-er-buttimg" src="@/style/images/icon_ejdaa.png" />
            <span class="table-er-buttspan">新增</span>
          </div>
          <div
            class="table-er-butt"
            v-if="showBtn(perMission.del)"
            @click="delItem"
          >
            <img
              class="table-er-buttimg"
              src="@/style/images/icon_ej_dingdele.png"
            />
            <span class="table-er-buttspan">删除</span>
          </div>
          <el-select v-model="termId" placeholder="请选择">
            <el-option
              v-for="item in terms"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
          <div
            style="
              font-size: 14px;
              margin-bottom: 10px;
              display: inline-block;
              float: right;
            "
          >
            <el-input
              class="jf-inpt"
              style="width: 200px;"
              size="medium"
              v-model="search"
              placeholder="请输入关键字"
            ></el-input>
            <el-link
              style="margin-left: 10px;"
              :underline="false"
              type="primary"
              @click="getData"
              >搜索</el-link
            >
          </div>
        </div>
        <table
          v-loading="loading"
          cellpadding="0"
          cellspacing="0"
          class="jf-checkbox-nolabel"
        >
          <thead>
            <tr>
              <td style="width: 50px;">
                <el-checkbox
                  :disabled="!dataTable.length"
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                ></el-checkbox>
              </td>
              <td>考试名称</td>
              <td>考试类型</td>
              <td>录入开始时间</td>
              <td>录入结束时间</td>
              <td>包含科目</td>
              <td>包含年级</td>
              <td>新增人</td>
              <td>操作</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataTable" :key="index">
              <td style="width: 35px;">
                <el-checkbox-group
                  v-model="checkedDatas"
                  @change="handleCheckedChange"
                >
                  <el-checkbox
                    :label="item.Id"
                    :disabled="item.CJR !== user.Id"
                    >{{ '' }}</el-checkbox
                  >
                </el-checkbox-group>
              </td>
              <td>{{ item.KSMC }}</td>
              <td>
                {{
                  item.KSXZM === 1
                    ? '月考'
                    : item.KSXZM === 2
                    ? '期中考试'
                    : item.KSXZM === 3
                    ? '期末考试'
                    : item.KSXZM === 4
                    ? '月考'
                    : ''
                }}
              </td>
              <td>{{ item.LRKSSJ | DateFormat('yyyy-MM-dd') }}</td>
              <td>{{ item.LRJSSJ | DateFormat('yyyy-MM-dd') }}</td>
              <td>
                {{ item.KSNJXKList | filterXK(basicInfo) }}
              </td>
              <td>
                {{
                  basicInfo.GradeList
                    ? basicInfo.GradeList.filter(c =>
                        Array.from(
                          new Set(item.KSNJXKList.map(c => c.KSNJ))
                        ).includes(c.Number)
                      )
                        .map(c => c.Name)
                        .join('，')
                    : []
                }}
              </td>
              <td>{{ item.CJRXM }}</td>
              <td>
                <div class="tab-wz-butt">
                  <el-link
                    v-if="
                      showBtn(perMission.edit) &&
                        item.CJR === user.Id &&
                        basicInfo.GradeList
                    "
                    type="primary"
                    :underline="false"
                    @click="editItem(item)"
                    >编辑</el-link
                  >
                  <el-link
                    v-if="
                      showBtn(perMission.enter) &&
                        showLR(item) &&
                        basicInfo.GradeList
                    "
                    style="margin-left: 10px;"
                    type="primary"
                    :underline="false"
                    @click="inputItem(item, 1)"
                    >录入成绩</el-link
                  >
                  <el-link
                    v-if="
                      showBtn(perMission.enter) &&
                        showCX(item) &&
                        basicInfo.GradeList
                    "
                    style="margin-left: 10px;"
                    type="primary"
                    :underline="false"
                    @click="inputItem(item, 2)"
                    >查询成绩</el-link
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="!dataTable || dataTable.length === 0"
          style="
            height: 100px;
            font-size: 14px;
            color: #999;
            border-left: 1px #f2f2f2 solid;
            border-right: 1px #f2f2f2 solid;
            border-bottom: 1px #f2f2f2 solid;
            padding-top: 44px;
            text-align: center;
            box-sizing: border-box;
          "
        >
          暂无数据
        </div>
        <!--页码开始-->
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
        <!--页码结束-->
      </div>
      <!--表格区结束-->
    </div>
  </div>
</template>

<script>
import Pager from '@/components/Pager.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Pager
  },
  props: {
    token: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: function() {
        return {}
      }
    },
    schoolId: {
      type: String,
      default: ''
    },
    basicInfo: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      perMission: {
        add: 'addExamination',
        del: 'delExamination',
        edit: 'editExamination',
        enter: 'enteringExamination' //录入
      },
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      loading: false,
      dataTable: [],
      checkAll: false,
      isIndeterminate: false,
      checkedDatas: [],
      search: '',
      termId: '',
      terms: [],
      teacherClass: {}
    }
  },
  mounted() {
    this.getTerms()
  },
  filters: {
    filterXK(val, basicInfo) {
      let arr = []
      basicInfo &&
        basicInfo.CourseList &&
        basicInfo.CourseList.forEach(item => {
          val.forEach(it => {
            if (it.KSNJ === item.Grade) {
              item.GradeCourseList.forEach(course => {
                if (course.Id === it.KCId) {
                  let idx = arr.findIndex(name => name === course.Name)
                  if (idx === -1) arr.push(course.Name)
                }
              })
            }
          })
        })

      return arr.join()
    }
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val
      this.getData()
    },
    getTerms() {
      this.loading = true
      this.$api.post(
        '/v1/accountmanage/GetTerm',
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            let datas = r.Target
            if (datas) {
              this.terms = datas
              let currentTerm = datas.find(c => c.IsCurrent === 1)
              this.termId = currentTerm ? currentTerm.Id : ''
              this.getTeacherClass()
            }
            this.getData()
          }
          this.loading = false
        }
      )
    },
    getData() {
      this.getTeacherClass()
      this.$api.post(
        '/v1/examination/GetExaminationList',
        {
          token: this.token,
          SchoolId: this.schoolId,
          GetGradeCourse: true,
          GetMembers: true,
          TermId: this.termId,
          PagerInfo: this.pagerInfo,
          SearchName: this.search
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target
            this.pagerInfo.totalCount = r.PagerInfo.RowCount
          }
          this.loading = false
        }
      )
    },
    handleCheckAllChange(val) {
      this.checkedDatas = val
        ? this.dataTable.filter(c => c.CJR === this.user.Id).map(c => c.Id)
        : []
      this.isIndeterminate = false
    },
    handleCheckedChange(value) {
      const checkedCount = value.length
      this.checkAll =
        checkedCount ===
        this.dataTable.filter(c => c.CJR === this.user.Id).length
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.dataTable.filter(c => c.CJR === this.user.Id).length
    },
    editItem(data) {
      this.$router.push({
        name: 'examinationmanagerEdit',
        params: { examinationData: JSON.parse(JSON.stringify(data)) }
      })
    },
    inputItem(data, type) {
      this.$store.commit('SET_CURRENTEXAMINATION', data)
      let current = this.terms.find(c => c.Id === data.SSXQ)
      this.$router.push({
        path: type === 1 ? 'Input' : 'ScoreRead',
        query: {
          termId: data.SSXQ,
          termYear: current ? current.SSXN : '',
          termType: current ? current.XQLX : 0
        }
      })
    },
    delItem() {
      if (this.checkedDatas.length === 0) {
        this.$message({
          type: 'message',
          message: '未选择删除行',
          showClose: true
        })
      } else {
        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$api.post(
              '/v1/examination/DelExamination',
              {
                token: this.token,
                Ids: this.checkedDatas,
                SchoolId: this.schoolId,
                UserId: this.user.Id,
                UserName: this.user.XSM
              },
              r => {
                if (r.Code === 0) {
                  this.$message({
                    type: 'success',
                    message: '删除成功',
                    showClose: true
                  })
                  this.getData()
                }
              }
            )
          })
          .catch(() => {})
      }
    },
    // 获取登录人所教班级
    getTeacherClass() {
      this.$api.post(
        'v1/accountmanage/GetTeaherManageClass',
        {
          UserId: this.user.Id, //	是	string	教师Id
          TermId: this.termId, //	是	string	学期id
          token: this.token
        },
        res => {
          if (res.Code === 0) {
            this.teacherClass = res.Target
          }
        }
      )
    },
    // 显隐录入成绩按钮
    showLR(row) {
      /* 1.新增人默认有录入成绩的权限
         2.满足录入人角色的显示
          2.1 科任(所教班级所教课程) 2.2 班主任(全部显示) 2.3 指定录入人(全部显示)
         3.录入时间未开始或者以结束不显示
      */
      if (
        (row.LRJSSJ &&
          new Date().getTime() >
            new Date(row.LRJSSJ.replace(/-/g, '/')).getTime()) ||
        (row.LRKSSJ &&
          new Date().getTime() < new Date(row.LRKSSJ.replace(/-/g, '/')))
      )
        return false
      else if (row.CJR === this.user.Id) return true
      else if (row.LRRLX === 1) {
        // 科任
        return (
          this.teacherClass.LecturerClass &&
          this.teacherClass.LecturerClass.length > 0
        )
      } else if (row.LRRLX === 2) {
        // 班主任
        return (
          this.teacherClass.IsClassTeacher &&
          this.teacherClass.IsClassTeacher === 1
        )
      } else if (
        row.KSLRRYBList &&
        row.KSLRRYBList.find(item => item.CZRId === this.user.Id)
      ) {
        // 指定录入人
        return true
      } else return false
    },
    // 显隐查询按钮
    showCX(row) {
      if (row.CJR === this.user.Id) return true
      let teacherCourseTable =
        this.$attrs.teacherCourse?.ClassScheduleList || []

      teacherCourseTable.forEach(item => {
        this.basicInfo.GradeList?.forEach(grade => {
          if (item.ClassId.includes(grade.Id)) item.Number = grade.Number
        })
      })
      return row.KSNJXKList.some(item => {
        return teacherCourseTable.some(
          it => item.KSNJ === it.Number && item.KCId === it.CourseId
        )
      })
    },
    // 显隐权限按钮
    showBtn(btnCode) {
      // 目录
      let mulu = this.user.menu.find(mulu => mulu.CodeOREId === 'ScoreManager')
      if (mulu) {
        // 菜单
        if (mulu.ChildMenusList) {
          let subMenu = mulu.ChildMenusList.find(
            item => item.CodeOREId === 'ExaminationManage'
          )
          if (subMenu) {
            if (subMenu.ChildMenusList) {
              return subMenu.ChildMenusList.some(
                item => item.CodeOREId === btnCode
              )
            } else {
              return mulu.ChildMenusList.some(
                item => item.CodeOREId === btnCode
              )
            }
          } else return false
        } else return false
      } else return false
    }
  }
}
</script>
