<template>
  <div class="cont-whole-box" style="text-align: center;">
    <el-form
      :model="surveyinfo"
      label-width="120px"
      size="medium"
      :rules="checkrules"
      ref="sbmitfrom"
    >
      <!-- tab -->
      <div class="grid-content bg-purple-light">
        <el-menu
          :default-active="selectType"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">基本设置</el-menu-item>
          <el-menu-item index="2">学校满意度调查</el-menu-item>
          <el-menu-item index="3">班级满意度调查</el-menu-item>
          <el-menu-item index="4">学科老师满意度调查</el-menu-item>
          <el-menu-item index="5">其他</el-menu-item>
        </el-menu>
      </div>
      <br />
      <!-- 基本设置 -->
      <div v-if="selectType == 1">
        <el-form-item label="标题：" prop="HDMC">
          <el-input
            v-model="surveyinfo.HDMC"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="描述：" prop="BZ">
          <el-input
            :rows="10"
            type="textarea"
            placeholder="请输入计划内容"
            v-model="surveyinfo.BZ"
            maxlength="1000"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="开展时间：">
          <el-col :span="11">
            <el-form-item prop="KSSJ">
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                v-model="surveyinfo.KSSJ"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">到</el-col>
          <el-col :span="11">
            <el-form-item prop="JSSJ">
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择时间"
                v-model="surveyinfo.JSSJ"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <br />
        <br />
        <el-form-item>
          <el-button @click="canceladd">取消</el-button>
          <el-button type="primary" @click="handleSelect('2')"
            >下一步</el-button
          >
        </el-form-item>
      </div>
      <!-- 学校满意度 -->
      <div v-if="selectType == 2">
        <div class="smtit-whole-box">
          <div class="smtit-box">
            <div class="smtit-cent" v-for="(da, i) in schooldalist" :key="i">
              <el-checkbox :label="da" v-model="schoolcheckedlist"
                >{{ da.PXH }}.</el-checkbox
              >
              <el-input
                type="textarea"
                :rows="2"
                style="width: 200px;"
                v-model="da.ZBMC"
                maxlength="50"
                show-word-limit
                placeholder="请输入内容"
              ></el-input
              >&nbsp;&nbsp;&nbsp;&nbsp;分数：
              <el-input-number
                v-model="da.ZBDF"
                :precision="2"
                :step="1"
                :min="0"
                :max="100"
                size="small"
                class="jf-form"
                style="width: 110px;"
                placeholder="请输入"
              ></el-input-number
              >&nbsp;&nbsp;
              <el-checkbox
                :key="da.SSLB + da.PXH"
                v-if="da.WZSR == 1"
                :checked="true"
                @change="(checked) => datextinputchange(checked, da)"
                >文字输入</el-checkbox
              >
              <el-checkbox
                :key="da.SSLB + da.PXH"
                v-else
                @change="(checked) => datextinputchange(checked, da)"
                >文字输入</el-checkbox
              >
            </div>
          </div>
        </div>

        <div
          class="whole-tit-mianbox"
          style="margin-top: 10px;"
          v-for="(item, index) in tmlist.filter((c) => c.SSLB == 1)"
          :key="index"
        >
          <div style="font-size: 0px; box-sizing: border-box;">
            <div
              class="whole-tit-topleft"
              style="
                    vertical-align: top;
                    position: relative;
                    top: 14px;
                    height: 44px;
                    width: 50px;
                  "
            >
              {{ index + 1 }}.
            </div>
            <div class="whole-tit-toprig" style="height: 44px;">
              <el-input
                type="textarea"
                :rows="2"
                style="width: 500px;"
                maxlength="50"
                show-word-limit
                placeholder="请输入题目"
                v-model="item.PJXMC"
              ></el-input>
            </div>
            <div class="tab-wz-buttl" style="font-size: 14px; float: right;">
              <el-button
                type="text"
                class="tab-wz-butta c-red"
                @click="delItem(item.Id)"
                >X 删除此题</el-button
              >
            </div>
          </div>
        </div>
        <div class="bigtit-add-box">
          <div class="tab-wz-buttl">
            <a class="tab-wz-butta c-guree" @click="addItem(1)">+ 新增问题</a>
          </div>
          <br />
          <br />
          <div class="tab-wz-buttl">
            <el-button type="primary" @click="handleSelect('3')"
              >下一步</el-button
            >
          </div>
        </div>
      </div>
      <!-- 班级满意度 -->
      <div v-if="selectType == 3">
        <div class="smtit-whole-box">
          <div class="smtit-box">
            <div class="smtit-cent" v-for="(da, i) in classdalist" :key="i">
              <el-checkbox :label="da" v-model="classcheckedlist"
                >{{ da.PXH }}.</el-checkbox
              >
              <el-input
                type="textarea"
                :rows="2"
                style="width: 200px;"
                v-model="da.ZBMC"
                maxlength="50"
                show-word-limit
                placeholder="请输入内容"
              ></el-input
              >&nbsp;&nbsp;&nbsp;&nbsp;分数：
              <el-input-number
                v-model="da.ZBDF"
                :precision="2"
                :step="1"
                :min="0"
                :max="100"
                size="small"
                class="jf-form"
                style="width: 110px;"
                placeholder="请输入"
              ></el-input-number
              >&nbsp;&nbsp;
              <el-checkbox
                :key="da.SSLB + da.PXH"
                v-if="da.WZSR == 1"
                :checked="true"
                @change="(checked) => datextinputchange(checked, da)"
                >文字输入</el-checkbox
              >
              <el-checkbox
                :key="da.SSLB + da.PXH"
                v-else
                @change="(checked) => datextinputchange(checked, da)"
                >文字输入</el-checkbox
              >
            </div>
          </div>
        </div>
        <div
          class="whole-tit-mianbox"
          style="margin-top: 10px;"
          v-for="(item, index) in tmlist.filter((c) => c.SSLB == 2)"
          :key="index"
        >
          <div style="font-size: 0px; box-sizing: border-box;">
            <div
              class="whole-tit-topleft"
              style="
                    vertical-align: top;
                    position: relative;
                    top: 14px;
                    height: 44px;
                    width: 50px;
                  "
            >
              {{ index + 1 }}.
            </div>
            <div class="whole-tit-toprig" style="height: 44px;">
              <el-input
                type="textarea"
                :rows="2"
                style="width: 500px;"
                maxlength="50"
                show-word-limit
                placeholder="请输入题目"
                v-model="item.PJXMC"
              ></el-input>
            </div>
            <div class="tab-wz-buttl" style="font-size: 14px; float: right;">
              <el-button
                type="text"
                class="tab-wz-butta c-red"
                @click="delItem(item.Id)"
                >X 删除此题</el-button
              >
            </div>
          </div>
        </div>
        <div class="bigtit-add-box">
          <div class="tab-wz-buttl">
            <a class="tab-wz-butta c-guree" @click="addItem(2)">+ 新增问题</a>
          </div>
          <br />
          <br />
          <div class="tab-wz-buttl">
            <el-button type="primary" @click="handleSelect('4')"
              >下一步</el-button
            >
          </div>
        </div>
      </div>
      <!-- 老师满意度 -->
      <div v-if="selectType == 4">
        <div class="smtit-whole-box">
          <div class="smtit-box">
            <div class="smtit-cent" v-for="(da, i) in coursedalist" :key="i">
              <el-checkbox :label="da" v-model="coursecheckedlist"
                >{{ da.PXH }}.</el-checkbox
              >
              <el-input
                type="textarea"
                :rows="2"
                style="width: 200px;"
                v-model="da.ZBMC"
                maxlength="50"
                show-word-limit
                placeholder="请输入内容"
              ></el-input
              >&nbsp;&nbsp;&nbsp;&nbsp;分数：
              <el-input-number
                v-model="da.ZBDF"
                :precision="2"
                :step="1"
                :min="0"
                :max="100"
                size="small"
                class="jf-form"
                style="width: 110px;"
                placeholder="请输入"
              ></el-input-number
              >&nbsp;&nbsp;
              <el-checkbox
                v-if="da.WZSR == 1"
                :key="da.SSLB + da.PXH"
                :checked="true"
                @change="(checked) => datextinputchange(checked, da)"
                >文字输入</el-checkbox
              >
              <el-checkbox
                :key="da.SSLB + da.PXH"
                v-else
                @change="(checked) => datextinputchange(checked, da)"
                >文字输入</el-checkbox
              >
            </div>
          </div>
        </div>
        <div
          style="
                margin-left: 1rem;
                margin-top: 2rem;
                margin-bottom: 2rem;
                text-align: left;
                border: none;
              "
        >
          <div
            class="tab-td-box"
            v-for="kcnj in njlist"
            :key="kcnj.NJId"
            style=" margin-top: 3rem;
                margin-bottom: 3rem;  border: none;display:block;"
          >
            <div class="disilb-box-l" style="width: 120px; margin-top: 5px;">
              <el-checkbox
                v-model="kcnj.checkAll"
                @change="checkAllChange($event, kcnj)"
                >{{ kcnj.NJMC }}:</el-checkbox
              >
            </div>
            <el-checkbox-group v-model="xklist">
              <el-checkbox
                v-for="kc in kcnj.CourseList"
                :key="kc.KCId"
                :label="kc.KCId"
                >{{ kc.KCMC }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div
          class="whole-tit-mianbox"
          style="margin-top: 4rem; margin-bottom: 1rem;"
          v-for="(item, index) in tmlist.filter((c) => c.SSLB == 3)"
          :key="index"
        >
          <div style="font-size: 0px; box-sizing: border-box;">
            <div
              class="whole-tit-topleft"
              style="
                    vertical-align: top;
                    position: relative;
                    top: 14px;
                    height: 44px;
                    width: 50px;
                  "
            >
              {{ index + 1 }}.
            </div>
            <div class="whole-tit-toprig" style="height: 44px;">
              <el-input
                type="textarea"
                :rows="2"
                style="width: 500px;"
                maxlength="50"
                show-word-limit
                placeholder="请输入题目"
                v-model="item.PJXMC"
              ></el-input>
            </div>
            <div class="tab-wz-buttl" style="font-size: 14px; float: right;">
              <el-button
                type="text"
                class="tab-wz-butta c-red"
                @click="delItem(item.Id)"
                >X 删除此题</el-button
              >
            </div>
          </div>
        </div>
        <div class="bigtit-add-box" style="margin-top: 1rem;">
          <div class="tab-wz-buttl">
            <a class="tab-wz-butta c-guree" @click="addItem(3)">+ 新增问题</a>
          </div>
          <br />
          <br />
          <div class="tab-wz-buttl">
            <!-- <el-form-item>
                  <el-button @click="canceladd">取消</el-button>
                  <el-button type="primary" @click="handleSelect('5')"
                    >下一步</el-button
                  >
                </el-form-item> -->
            <el-form-item>
              <el-button @click="canceladd">取消</el-button>
              <el-button
                type="primary"
                @click="handleSelect('5')"
                :disabled="isDisabled"
                >下一步</el-button
              >
            </el-form-item>
          </div>
        </div>
      </div>
      <!-- 其它 展示非学科的问卷 单选-->
      <div v-if="selectType == 5" class="WJTMBList-container">
        <el-form-item prop="WJTMBList">
          <el-checkbox-group
            class="WJTMBList-group"
            v-model="surveyinfo.WJTMBList"
          >
            <div class="radio-item" v-for="item in questionList" :key="item.Id">
              <el-checkbox
                :label="item.Id"
                @change="WJTMBList_change($event, item.Id)"
              >
                {{ item.WJMC }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <div class="tab-wz-buttl">
          <el-form-item>
            <el-button @click="canceladd">取消</el-button>
            <el-button
              type="primary"
              @click="submitshowdata()"
              :disabled="isDisabled"
              >保存</el-button
            >
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { isNumber, checkLength } from "@/style/js/Validator.js";

export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    this.surveyinfo.Id = this.$route.params.id;

    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
    }
    this.$set(this.formData, "WJTMBList", []);
  },
  mounted: function() {
    this.loadnjdata();
    this.loaddata();
    this.getQustionList();
  },
  methods: {
    addItemQt() {
      this.formData.WJTMBList.push({
        TMMC: "",
        SSLB: 1,
        WJFXBList: this.answerMB.Id
          ? this.answerMB.WJDAList.map((it) => {
              return {
                TMQZ: it.TMQZ,
                TMMC: it.DAMC,
                DF: it.DF,
                FXLX: it.FXLX,
                TMId: "",
              };
            })
          : [],
      });
    },
    delItemQt(index) {
      this.formData.WJTMBList.splice(index, 1);
    },
    addFX(TM, FXLX) {
      let obj = {
        TMId: TM.Id || "",
        TMMC: FXLX === 2 ? "其他" : "",
        FXLX: FXLX,
        DF: "",
        TMQZ: "",
      };
      if (!TM.WJFXBList) {
        obj.TMQZ = "A";
        this.$set(TM, "WJFXBList", [obj]);
      } else {
        let arr = TM.WJFXBList.map((item) => item.TMQZ);
        arr.sort((a, b) => a.localeCompare(b));
        let idx = this.ZM.findIndex(
          (item) => item.value === arr[arr.length - 1]
        );
        obj.TMQZ = this.ZM[idx + 1].value;
        TM.WJFXBList.push(obj);
      }
    },
    delFX(TM, i) {
      TM.WJFXBList = TM.WJFXBList.filter((item, idx) => i !== idx);
    },
    // 校验最多选项
    validJDXX(rule, val, callback) {
      let idx = rule.field.match(/\d+/);
      if (!val) return callback(new Error("数目不能为空"));
      else if (
        this.formData.WJFXBList &&
        val >
          this.formData.WJFXBList.filter(
            (item) => item.TMId === this.formData.WJTMBList[idx].Id
          ).length
      )
        return callback(new Error("不能超过选项个数"));
      else return callback();
    },
    compare(p) {
      // 这是比较函数
      return function(m, n) {
        var a = m[p];
        var b = n[p];
        return a - b; // 升序
      };
    },
    handleZMabled(WJFXBList, cur) {
      if (WJFXBList && WJFXBList.length > 0) {
        return WJFXBList.some((fx) => fx.TMQZ && fx.TMQZ === cur);
      } else return false;
    },

    datextinputchange: function(val, da) {
      if (val) {
        da.WZSR = 1;
      } else {
        da.WZSR = 2;
      }
    },
    addItem(lb) {
      if (this.tmlist.filter((c) => c.SSLB === 1).length < 20) {
        const tmId = new Date().getTime() + "";
        this.tmlist.push({ Id: tmId, PJXMC: "", XZLX: 1, SSLB: lb });
      } else {
        this.$message({
          type: "info",
          message: "题目不能超过20个!",
        });
      }
    },
    delItem(id) {
      this.tmlist = this.tmlist.filter((c) => c.Id !== id);
    },
    // 切换列表
    handleSelect(key) {
      this.selectType = key;
    },
    // 取消
    canceladd: function() {
      this.$router.push({ path: "/survey" });
    },
    submitshowdata: function() {
      if (this.surveyinfo.HDMC.length <= 0) {
        this.$message({
          type: "info",
          message: "请填写标题!",
        });
        return;
      }
      if (
        this.surveyinfo.KSSJ.length <= 0 ||
        this.surveyinfo.JSSJ.length <= 0
      ) {
        this.$message({
          type: "info",
          message: "请选择开展时间!",
        });
        return;
      }
      if (this.surveyinfo.KSSJ > this.surveyinfo.JSSJ) {
        this.$message({
          type: "info",
          message: "结束日期不能小于开始日期!",
        });
        return;
      }
      if (this.tmlist === null || this.tmlist.length <= 0) {
        this.$message({
          type: "info",
          message: "请设置题目!",
        });
        return;
      }
      var noname = this.tmlist.filter((t) => t.PJXMC === "");
      if (noname.length > 0) {
        this.$message({
          type: "info",
          message:
            this.SZLX.filter((c) => c.Key === noname[0].SSLB)[0].Value +
            "中有题目还未填写名称，请检查!",
        });
        return;
      }
      this.dalist = this.schoolcheckedlist
        .concat(this.classcheckedlist)
        .concat(this.coursecheckedlist);
      var noanswer = this.tmlist.filter(
        (t) => this.dalist.filter((d) => d.SSLB === t.SSLB).length <= 0
      );
      if (noanswer.length > 0) {
        this.$message({
          type: "info",
          message:
            this.SZLX.filter((c) => c.Key === noanswer[0].SSLB)[0].Value +
            "中还未勾选答案!",
        });
        return;
      }

      var noanswername = this.dalist.filter((d) => d.ZBMC === "");
      if (noanswername.length > 0) {
        this.$message({
          type: "info",
          message:
            this.SZLX.filter((c) => c.Key === noanswername[0].SSLB)[0].Value +
            "中勾选答案还未设置名称!",
        });
        return;
      }
      if (
        this.tmlist.filter((t) => t.SSLB === 3).length > 0 &&
        this.xklist.length <= 0
      ) {
        this.$message({
          type: "info",
          message: "请勾选年级课程!",
        });
        return;
      }

      this.$refs.sbmitfrom.validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/survey/AddSurvey",
            {
              Id: this.surveyinfo.Id,
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              CJR: this.cjr,
              HDMC: this.surveyinfo.HDMC,
              KSSJ: this.surveyinfo.KSSJ,
              JSSJ: this.surveyinfo.JSSJ,
              BZ: this.surveyinfo.BZ,
              WJTMList: this.tmlist,
              WJDAList: this.dalist,
              XKList: this.xklist.join(","),
              MYDWJInfo: this.surveyinfo.WJTMBList[0]
                ? {
                    DCId: this.surveyinfo.Id,
                    WJId: this.surveyinfo.WJTMBList[0],
                  }
                : null,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!",
                });
                this.$router.push({ path: "/survey" });
              } else {
                this.$message({
                  type: "info",
                  message: "保存失败!",
                });
                this.isDisabled = false;
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    loaddata: function() {
      if (this.surveyinfo.Id !== undefined && this.surveyinfo.Id !== "") {
        this.$api.post(
          "v1/survey/GetSurvey",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            Id: this.surveyinfo.Id,
            token: this.token,
          },
          (r) => {
            if (r.Code === 0) {
              if (r.Target && !r.Target.WJTMBList) r.Target.WJTMBList = [];
              this.surveyinfo = r.Target;
              if (this.surveyinfo.MYDWJList && this.surveyinfo.MYDWJList[0]) {
                this.surveyinfo.WJTMBList = [this.surveyinfo.MYDWJList[0].WJId];
              }
              this.tmlist = r.Target.WJTMList;
              this.dalist = r.Target.WJDAList;

              this.schoolcheckedlist = this.dalist.filter((d) => d.SSLB === 1);
              if (
                this.schoolcheckedlist !== null &&
                this.schoolcheckedlist.length > 0
              ) {
                this.schooldalist = this.schooldalist.filter(
                  (c) =>
                    this.schoolcheckedlist.filter((s) => s.PXH === c.PXH)
                      .length <= 0
                );
                this.schooldalist = this.schooldalist.concat(
                  this.schoolcheckedlist
                );
              }
              this.schooldalist = this.schooldalist.sort(function(a, b) {
                return a.PXH - b.PXH;
              });
              this.classcheckedlist = this.dalist.filter((d) => d.SSLB === 2);
              if (
                this.classcheckedlist !== null &&
                this.classcheckedlist.length > 0
              ) {
                this.classdalist = this.classdalist.filter(
                  (c) =>
                    this.classcheckedlist.filter((s) => s.PXH === c.PXH)
                      .length <= 0
                );
                this.classdalist = this.classdalist.concat(
                  this.classcheckedlist
                );
              }
              this.classdalist = this.classdalist.sort(function(a, b) {
                return a.PXH - b.PXH;
              });
              this.coursecheckedlist = this.dalist.filter((d) => d.SSLB === 3);
              if (
                this.coursecheckedlist !== null &&
                this.coursecheckedlist.length > 0
              ) {
                this.coursedalist = this.coursedalist.filter(
                  (c) =>
                    this.coursecheckedlist.filter((s) => s.PXH === c.PXH)
                      .length <= 0
                );
                this.coursedalist = this.coursedalist.concat(
                  this.coursecheckedlist
                );
              }
              this.coursedalist = this.coursedalist.sort(function(a, b) {
                return a.PXH - b.PXH;
              });
              var checkedlist = r.Target.XKList;
              this.xklist = [];
              for (var i = 0; i < checkedlist.length; i++) {
                this.xklist.push(
                  checkedlist[i].KCId + "^" + checkedlist[i].NJId
                );
              }
            }
          }
        );
      } else {
        this.$set(this, "tmlist", []);
        this.$set(this, "dalist", []);
        this.$set(this, "xklist", []);
      }
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/survey/GetGradeCourse",
        { SSXX: this.ssxx, token: this.token },
        (r) => {
          if (r.Code === 0) {
            this.njlist = [];
            this.njlist = r.Target;
          }
        }
      );
    },
    // 获取问卷列表
    getQustionList() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/questionnaire/GetQuestionnaireList",
          {
            token: this.token,
            SchoolId: this.ssxx,
          },
          (r) => {
            if (r.Code === 0) {
              this.questionList = r.Target || [];
              // 筛选非学科问卷
              this.questionList = this.questionList.filter(
                (item) => !item.CourseIds
              );
              this.questionList.forEach((item) => {
                if (item.WJTMBList) {
                  item.WJTMBList.forEach((it) => {
                    if (it.WJFXBList && it.WJFXBList.every((i) => i.TMQZ)) {
                      it.WJFXBList.sort((a, b) => a.TMQZ.localeCompare(b.TMQZ));
                    }
                  });
                }
              });
              resolve();
            }
          }
        );
      });
    },
    // 其它中的问卷值变化
    WJTMBList_change(val, id) {
      if (val) {
        this.surveyinfo.WJTMBList = this.surveyinfo.WJTMBList.filter(
          (item) => item === id
        );
      }
    },
    // 课程全选
    checkAllChange(val, kcnj) {
      console.log(val, kcnj, "kcnj");
    },
  },
  data() {
    return {
      validateNumber: isNumber,
      checklength: checkLength,
      isDisabled: false,
      ssxx: "",
      ssxq: "",
      token: "",
      selectType: "1",
      njlist: [],
      tmlist: [],
      dalist: [],
      schoolcheckedlist: [],
      classcheckedlist: [],
      coursecheckedlist: [],
      schooldalist: [
        { PXH: 1, ZBMC: "非常满意", SSLB: 1, ZBDF: 0, WZSR: 2 },
        { PXH: 2, ZBMC: "满意", SSLB: 1, ZBDF: 0, WZSR: 2 },
        { PXH: 3, ZBMC: "不满意", SSLB: 1, ZBDF: 0, WZSR: 2 },
        { PXH: 4, ZBMC: "很不满意", SSLB: 1, ZBDF: 0, WZSR: 2 },
      ],
      classdalist: [
        { PXH: 1, ZBMC: "非常满意", SSLB: 2, ZBDF: 0, WZSR: 2 },
        { PXH: 2, ZBMC: "满意", SSLB: 2, ZBDF: 0, WZSR: 2 },
        { PXH: 3, ZBMC: "不满意", SSLB: 2, ZBDF: 0, WZSR: 2 },
        { PXH: 4, ZBMC: "很不满意", SSLB: 2, ZBDF: 0, WZSR: 2 },
      ],
      coursedalist: [
        { PXH: 1, ZBMC: "非常满意", SSLB: 3, ZBDF: 0, WZSR: 2 },
        { PXH: 2, ZBMC: "满意", SSLB: 3, ZBDF: 0, WZSR: 2 },
        { PXH: 3, ZBMC: "不满意", SSLB: 3, ZBDF: 0, WZSR: 2 },
        { PXH: 4, ZBMC: "很不满意", SSLB: 3, ZBDF: 0, WZSR: 2 },
      ],
      xklist: [],
      cjr: "",
      surveyinfo: {
        Id: "",
        CJR: "",
        CJSJ: "",
        HDMC: "",
        BZ: "",
        KSSJ: "",
        JSSJ: "",
        WJTMBList: [],
      },
      SZLX: [
        { Key: 1, Value: "学校满意度" },
        { Key: 2, Value: "班级满意度" },
        { Key: 3, Value: "学科老师满意度" },
      ],
      SSLB: [
        { Key: 1, Value: "单选" },
        { Key: 2, Value: "多选" },
        { Key: 3, Value: "问答" },
      ],
      ZM: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      checkrules: {
        HDMC: [
          { required: true, message: "请输入标题", trigger: "change" },
          { min: 1, max: 50, message: "长度在1到50个字符", trigger: "change" },
        ],
        BZ: [{ max: 1000, message: "长度在1到1000个字符", trigger: "change" }],
        KSSJ: [
          { required: true, message: "选择正确的日期", trigger: "change" },
        ],
        JSSJ: [
          { required: true, message: "选择正确的日期", trigger: "change" },
        ],
      },
      formData: {},
      answerMB: {},
      rules: {
        WJMC: [
          {
            min: 1,
            max: 20,
            message: "不能超过20 个字符",
            trigger: "change",
          },
        ],
      },
      questionList: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.cont-whole-box {
  .WJTMBList-container {
    .WJTMBList-group {
      width: 100%;
      .radio-item {
        text-align: left !important;
        margin-bottom: 10px;
        border-bottom: 1px solid #fafbfb;
        padding: 10px 0;
      }
    }
  }
}
</style>
