<template>
	<div>
		<el-table ref="multipleTable" :data="dataList" class="jf-table" border tooltip-effect="dark"
			style="width: 100%;" @selection-change="handleSelectionChange">
			<el-table-column fixed type="selection" width="50" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="WDMC" label="维度名称" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="SSQYMC" label="所属区域" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="CJR" label="创建人" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="CJSJ" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column fixed="right" label="操作" width="300">
				<template slot-scope="scope">
					<el-button @click="editItem(scope.row)" type="text" size="small">编辑</el-button>
					<el-button class="c-red" @click="delItem(scope.row)" type="text" size="small">删除</el-button>
					<el-button v-if="scope.row.SYZT == 1" @click="disableItem(scope.row)" type="text"
						size="small">停用</el-button>
					<el-button v-else @click="enableItem(scope.row)" type="text" size="small">启用</el-button>
					<el-button @click="openlevelSet(scope.row)" type="text" size="small">关联等级</el-button>
					<el-button v-if="scope.row.FJId == scope.row.SSQY" @click="childenDimensionManager(scope.row)"
						type="text" size="small">二级维度管理</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="关联等级" :visible.sync="levelSetFormVisible" :close-on-click-modal="false" width="20%" top="15%">
			<el-form :model="currentOBject" ref="currentOBject" :rules="rules">
				<el-form-item label="等级" label-width="80px" prop="QYDJ">
					<el-select v-model="currentOBject.QYDJ" placeholder="请选择">
						<el-option v-for="item in levelSetData.filter(c => c.DJLX === 1)" :key="item.Id"
							:label="item.DJMC" :value="item.Id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="levelSetFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveLevelSet">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				levelSetFormVisible: false,
				currentOBject: {},
				rules: {
					QYDJ: [{
							required: true,
							message: "请选择等级",
							trigger: "change"
						}
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					]
				}
			};
		},
		props: {
			dataList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			levelSetData: {
				type: Array,
				default: function() {
					return [];
				}
			}
		},
		methods: {
			editItem(row) {
				this.$emit("editItem", row);
			},
			delItem(row) {
				this.$emit("delItem", row);
			},
			disableItem(row) {
				this.$emit("disableItem", row);
			},
			enableItem(row) {
				this.$emit("enableItem", row);
			},
			childenDimensionManager(row) {
				this.$emit("childenDimensionManager", row);
			},
			handleSelectionChange(val) {
				this.$emit("handleSelectionChange", val);
			},
			openlevelSet(data) {
				if (this.$refs.currentOBject) {
					this.$refs.currentOBject.resetFields();
				}
				this.levelSetFormVisible = true;
				this.currentOBject = JSON.parse(JSON.stringify(data));
			},
			saveLevelSet() {
				this.$refs.currentOBject.validate(valid => {
					if (valid) {
						this.$confirm("确认提交？")
							// eslint-disable-next-line no-unused-vars
							.then(r => {
								this.$emit("levelSet", this.currentOBject);
								this.levelSetFormVisible = false;
							})
							// eslint-disable-next-line no-unused-vars
							.catch(r => {});
					} else {
						return false;
					}
				});
			}
		}
	};
</script>