<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <el-row>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            <!--tree-->
            <el-tree
              :data="treedata"
              :props="defaultProps"
              @node-click="handleNodeClick"
              :highlight-current="true"
            ></el-tree>
          </div>
        </el-col>

        <el-col :span="19">
          <!--学校课程-->
          <div class="grid-content bg-purple-light" v-show="schoolhtml">
            <!--列表-->
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openinheritareacourse"
              >继承区域课程</el-button
            >

            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openspecialcourse"
              >新增特殊课程</el-button
            >

            <el-button type="primary" icon="el-icon-edit" @click="batchdelete"
              >批量删除</el-button
            >

            <br />
            <br />

            <el-table
              :data="tableData"
              class="jf-table"
              border
              tooltip-effect="dark"
              row-key="Id"
              ref="dragTable"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column
                label="序号"
                width="80"
                prop="PXH"
              ></el-table-column>
              <el-table-column
                prop="KCMC"
                label="课程名称"
                width="180"
              ></el-table-column>

              <el-table-column prop="XD" label="学段" width="180">
                <template slot-scope="scope">
                  <p v-if="scope.row.XD == '1'">小学</p>
                  <p v-else-if="scope.row.XD == '2'">初中</p>
                  <p v-else-if="scope.row.XD == '4'">高中</p>
                  <p v-else></p>
                </template>
              </el-table-column>
              <el-table-column prop="LRFS" label="录入方式">
                <template slot-scope="scope">
                  <p v-if="scope.row.LRFS == '1'">分数</p>
                  <p v-else-if="scope.row.LRFS == '2'">等级</p>
                </template>
              </el-table-column>
              <el-table-column prop="ZSFS" label="展示方式">
                <template slot-scope="scope">
                  <p v-if="scope.row.ZSFS == '1'">分数</p>
                  <p v-else-if="scope.row.ZSFS == '2'">等级</p>
                </template>
              </el-table-column>
              <el-table-column prop="KCLX" label="课程类别">
                <template slot-scope="scope">
                  <p v-if="scope.row.KCLX == '1'">通用</p>
                  <p v-else-if="scope.row.KCLX == '2'">特殊</p>
                </template>
              </el-table-column>
              <el-table-column prop="XKZF" label="总分"></el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="editRow(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="deleteRow(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div class="block">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="rowcount"
              ></el-pagination>
            </div>
          </div>

          <!--年级课程-->
          <div class="grid-content bg-purple-light" v-show="gradehtml">
            <!--列表-->
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openschoolcourse"
              >继承学校课程</el-button
            >
            <br />
            <br />
            <el-table :data="classtableData" border style="width: 100%;">
              <el-table-column prop="PXH" label="序号"></el-table-column>
              <el-table-column prop="KCMC" label="课程名称"></el-table-column>
              <el-table-column prop="NJMC" label="年级"></el-table-column>

              <el-table-column prop="LRFS" label="录入方式">
                <template slot-scope="scope">
                  <p v-if="scope.row.LRFS == '1'">分数</p>
                  <p v-else-if="scope.row.LRFS == '2'">等级</p>
                </template>
              </el-table-column>
              <el-table-column prop="ZSFS" label="展示方式">
                <template slot-scope="scope">
                  <p v-if="scope.row.ZSFS == '1'">分数</p>
                  <p v-else-if="scope.row.ZSFS == '2'">等级</p>
                </template>
              </el-table-column>
              <el-table-column prop="DJMC" label="等级名称"></el-table-column>
              <el-table-column prop="KCLX" label="课程类别">
                <template slot-scope="scope">
                  <p v-if="scope.row.KCLX == '1'">通用</p>
                  <p v-else-if="scope.row.KCLX == '2'">特殊</p>
                </template>
              </el-table-column>
              <el-table-column prop="XKZF" label="总分"></el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="schooleditrow(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="schoolDeleterow(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div class="block">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="rowcount"
              ></el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--继承区域弹出框-->
    <el-dialog
      title="继承区域课程"
      :visible.sync="dialogFormVisiblearea"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="XXKCBInfo" :rules="rules" ref="sbmitfrom">
        <el-form-item label="课程名称" :label-width="formLabelWidth">
          <span v-show="!areaCourseList || areaCourseList.length === 0"
            >暂无课程</span
          >
          <el-checkbox-group
            v-show="areaCourseList && areaCourseList.length > 0"
            v-model="courseCheckList"
          >
            <div
              class="area-course-item"
              style="display:inline-block;padding-right:10px"
              v-for="item in areaCourseList"
              :key="item.Id"
            >
              <el-checkbox
                :label="item.Id"
                @change="clickAreaCourse(item.Id, 1)"
              >
                {{ '' }}
              </el-checkbox>
              <span
                :class="{ curCourse: curCourse === item.Id }"
                style="font-size:12px;padding-right:10px;cursor:pointer"
                @click="clickAreaCourse(item.Id, 2)"
              >
                {{ item.KCMC }}</span
              >
              <el-input
                class="alias"
                style="width: 70px;"
                v-model="item.alias"
              />
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="学段" :label-width="formLabelWidth" prop="XD">
          <el-select
            v-model="XXKCBInfo.XD"
            placeholder="请选择学段"
            style="width: 100%;"
            @change="getQYcourse"
          >
            <el-option
              v-for="sxd in SchoolPeriod"
              :key="sxd.Id"
              :label="sxd.Name"
              :value="sxd.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="area-item-bottom-container">
          <div class="block">
            <div
              class="area-item-bottom"
              v-for="courseData in areaCourseList"
              :key="courseData.Id"
            >
              <el-form-item label="编码" :label-width="formLabelWidth">
                <el-input v-model="courseData.data.KCBM" />
              </el-form-item>
              <el-form-item label="录入方式" :label-width="formLabelWidth">
                <el-radio-group
                  v-model="courseData.data.LRFS"
                  @change="selectinput(courseData.data)"
                >
                  <el-radio :label="1">分数</el-radio>
                  <el-radio :label="2">等级</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="展示方式" :label-width="formLabelWidth">
                <el-radio-group
                  v-model="courseData.data.ZSFS"
                  @change="handleGLDJ(courseData.data)"
                >
                  <el-radio :label="1" :disabled="courseData.data.LRFS == '2'"
                    >分数</el-radio
                  >
                  <el-radio :label="2">等级</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="学科总分" :label-width="formLabelWidth">
                <el-input
                  :disabled="courseData.data.LRFS == '2'"
                  v-model="courseData.data.XKZF"
                  autocomplete="off"
                  style="width: 100%;"
                ></el-input>
              </el-form-item>
              <el-form-item label="关联等级" :label-width="formLabelWidth">
                <el-select
                  v-model="courseData.data.GLDJ2"
                  placeholder="请选择关联等级"
                  style="width: 100%;"
                  @change="GLDJ2change(courseData.data)"
                  @focus="handleGLDJ(courseData.data)"
                >
                  <el-option
                    v-for="item in levellist"
                    :key="item.Id"
                    :label="item.DJMC"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关联末级维度" :label-width="formLabelWidth">
                <el-cascader
                  v-model="courseData.data.WdId"
                  :options="areaDimension"
                  :props="areaProp"
                />
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
      <span style="padding-left:50px;color:#ccc"
        >*默认继承区域课程的相关数据</span
      >
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisiblearea = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="submitinheritareacourse"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--新增特殊弹出框/编辑学校课程-->
    <el-dialog
      :title="specialtitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="XXKCBInfo" :rules="rulests" ref="sbmitfromts">
        <el-form-item
          label="课程名称"
          :label-width="formLabelWidth"
          prop="KCMC"
        >
          <el-input
            v-model="XXKCBInfo.KCMC"
            prop="PXH"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item label="学段" :label-width="formLabelWidth" prop="XD">
          <el-select
            v-model="XXKCBInfo.XD"
            placeholder="请选择学段"
            style="width: 100%;"
          >
            <el-option
              v-for="sxd in SchoolPeriod"
              :key="sxd.Id"
              :label="sxd.Name"
              :value="sxd.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="编码" :label-width="formLabelWidth" prop="KCBM">
          <el-input v-model="XXKCBInfo.KCBM" />
        </el-form-item>
        <el-form-item
          label="录入方式"
          :label-width="formLabelWidth"
          prop="LRFS"
        >
          <el-radio-group
            v-model="XXKCBInfo.LRFS"
            @change="selectinput(XXKCBInfo)"
          >
            <el-radio :label="1">分数</el-radio>
            <el-radio :label="2">等级</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="展示方式"
          :label-width="formLabelWidth"
          prop="ZSFS"
        >
          <el-radio-group
            v-model="XXKCBInfo.ZSFS"
            @change="handleGLDJ(XXKCBInfo)"
          >
            <el-radio :label="1" :disabled="XXKCBInfo.LRFS == '2'"
              >分数</el-radio
            >
            <el-radio :label="2">等级</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="学科总分"
          :label-width="formLabelWidth"
          prop="XKZF"
        >
          <el-input
            :disabled="XXKCBInfo.LRFS === 2"
            v-model="XXKCBInfo.XKZF"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="关联等级"
          :label-width="formLabelWidth"
          prop="GLDJ2"
        >
          <el-select
            v-model="XXKCBInfo.GLDJ2"
            placeholder="请选择关联等级"
            style="width: 100%;"
            @change="GLDJ2change(XXKCBInfo)"
            @focus="handleGLDJ(XXKCBInfo)"
          >
            <el-option
              v-for="item in levellist"
              :key="item.Id"
              :label="item.DJMC"
              :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
          <el-input
            v-model="XXKCBInfo.PXH"
            prop="PXH"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="关联末级维度"
          :label-width="formLabelWidth"
          prop="WdId"
        >
          <el-cascader
            v-model="XXKCBInfo.WdId"
            :options="schoolDimension"
            :props="schoolProp"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitshowdata"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--年级课程新增弹出框-->
    <el-dialog
      :title="XXNJKCGLInfo_title"
      :visible.sync="dialogFormVisibleschool"
      customClass="customWidth"
    >
      <el-form :model="XXNJKCGLInfo" :rules="rulestsss" ref="sbmitfromtsss">
        <div v-if="edittype == '1'">
          <el-form-item label="课程名称" :label-width="formLabelWidth">
            <el-checkbox-group v-model="schoolchecklist">
              <div
                class="school-course-item"
                style="display:inline-block;padding-right:10px"
                v-for="item in schoolcourseList"
                :key="item.Id"
              >
                <el-checkbox
                  :label="item.Id"
                  @change="clickSchoolCourse(item.Id, 1)"
                >
                  {{ '' }}
                </el-checkbox>
                <span
                  :class="{ curCourse: curCourse === item.Id }"
                  style="font-size:12px;padding-right:10px;cursor:pointer"
                  @click="clickSchoolCourse(item.Id, 2)"
                >
                  {{ item.KCMC }}</span
                >
                <el-input
                  class="alias"
                  style="width: 70px;"
                  v-model="item.alias"
                />
              </div>
            </el-checkbox-group>
          </el-form-item>
          <div class="school-item-bottom-container">
            <div class="block">
              <div
                class="school-item-bottom"
                v-for="courseData in schoolcourseList"
                :key="courseData.Id"
              >
                <!-- <el-form-item label="编码" :label-width="formLabelWidth">
                  <el-input v-model="courseData.data.KCBM" readonly />
                </el-form-item> -->
                <el-form-item label="录入方式" :label-width="formLabelWidth">
                  <el-radio-group
                    v-model="courseData.data.LRFS"
                    @change="selectinputnj"
                  >
                    <el-radio :label="1">分数</el-radio>
                    <el-radio :label="2">等级</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="展示方式" :label-width="formLabelWidth">
                  <el-radio-group
                    v-model="courseData.data.ZSFS"
                    @change="handleGLDJ(courseData.data)"
                  >
                    <el-radio :label="1" :disabled="courseData.data.LRFS === 2"
                      >分数</el-radio
                    >
                    <el-radio :label="2">等级</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="学科总分" :label-width="formLabelWidth">
                  <el-input
                    :disabled="courseData.data.LRFS === 2"
                    v-model="courseData.data.XKZF"
                    style="width: 100%;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="关联等级" :label-width="formLabelWidth">
                  <el-select
                    v-model="courseData.data.GLDJ2"
                    placeholder="请选择关联等级"
                    style="width: 100%;"
                    @change="GLDJ2change(courseData.data)"
                    @focus="handleGLDJ(courseData.data)"
                  >
                    <el-option
                      v-for="item in levellist"
                      :key="item.Id"
                      :label="item.DJMC"
                      :value="item.Id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="关联末级维度"
                  :label-width="formLabelWidth"
                >
                  <el-cascader
                    v-model="courseData.data.WdId"
                    :options="schoolDimension"
                    :props="schoolProp"
                  />
                </el-form-item>
                <el-form-item label="排序号" :label-width="formLabelWidth">
                  <el-input-number
                    v-model="courseData.data.PXH"
                    size="small"
                    :min="1"
                    :max="999"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <el-form-item label="课程名称" :label-width="formLabelWidth">
            <el-input
              v-model="XXNJKCGLInfo.KCMC"
              autocomplete="off"
              style="width: 100%;"
            ></el-input>
          </el-form-item>
          <!-- prop="KCBM" -->
          <!-- <el-form-item label="编码" :label-width="formLabelWidth">
            <el-input v-model="XXNJKCGLInfo.KCBM" readonly></el-input>
          </el-form-item> -->
          <el-form-item
            label="录入方式"
            :label-width="formLabelWidth"
            prop="LRFS"
          >
            <el-radio-group v-model="XXNJKCGLInfo.LRFS" @change="selectinputnj">
              <el-radio :label="1">分数</el-radio>
              <el-radio :label="2">等级</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="展示方式"
            :label-width="formLabelWidth"
            prop="ZSFS"
          >
            <el-radio-group
              v-model="XXNJKCGLInfo.ZSFS"
              @change="handleGLDJ(XXNJKCGLInfo)"
            >
              <el-radio :label="1" v-if="XXNJKCGLInfo.LRFS == '2'" disabled
                >分数</el-radio
              >
              <el-radio :label="1" v-else>分数</el-radio>
              <el-radio :label="2">等级</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="学科总分"
            :label-width="formLabelWidth"
            v-if="XXNJKCGLInfo.LRFS == '1'"
            prop="XKZF"
          >
            <el-input
              v-model="XXNJKCGLInfo.XKZF"
              autocomplete="off"
              style="width: 100%;"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="关联等级"
            :label-width="formLabelWidth"
            prop="GLDJ2"
          >
            <el-select
              v-model="XXNJKCGLInfo.GLDJ2"
              placeholder="请选择关联等级"
              style="width: 100%;"
              @change="GLDJ2change(XXNJKCGLInfo)"
              @focus="handleGLDJ(XXNJKCGLInfo)"
            >
              <el-option
                v-for="item in levellist"
                :key="item.Id"
                :label="item.DJMC"
                :value="item.Id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="关联末级维度"
            :label-width="formLabelWidth"
            prop="WdId"
          >
            <el-cascader
              v-model="XXNJKCGLInfo.WdId"
              :options="schoolDimension"
              :props="schoolProp"
            />
          </el-form-item>
          <el-form-item label="排序号" prop="PXH" :label-width="formLabelWidth">
            <el-input-number
              v-model="XXNJKCGLInfo.PXH"
              size="small"
              :min="1"
              :max="999"
            />
          </el-form-item>
        </div>
      </el-form>
      <span
        v-show="XXNJKCGLInfo_title === '继承学校课程'"
        style="padding-left:50px;color:#ccc"
        >*默认继承学校课程的相关数据</span
      >
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleschool = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="saveschoolcourse"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isInteger } from '@/style/js/Validator.js'
import { isIntegerAndZero } from '@/style/js/Validator.js'
import Sortable from 'sortablejs'
import { pinyin } from 'pinyin-pro'
import { handle_SSXD3 } from '@/utils/common'
export default {
  name: 'XxkcbList',
  data() {
    let validZSZF = (rule, value, callback) => {
      if (this.XXKCBInfo.LRFS === 1) {
        if (!Number(value)) {
          callback(new Error('请输入整数'))
        } else {
          const re = /^[0-9]*[1-9][0-9]*$/
          const rsCheck = re.test(value)
          if (!rsCheck) {
            callback(new Error('请输入整数'))
          } else {
            callback()
          }
        }
      } else {
        return callback()
      }
    }
    let valid_KCBM = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9]+$/ //英文字符
      if (!value) callback(new Error('编码不能为空'))
      else if (!reg.test(value)) callback(new Error('请输入英文或者数字'))
      else if (value.length > 10) callback(new Error('编码不能超过10个字符'))
      else callback()
    }
    return {
      token: this.$store.state.token,
      tableData: [],
      loading: false,
      classtableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      dialogFormVisiblearea: false,
      dialogFormVisible: false,
      dialogFormVisibleschool: false,
      formLabelWidth: '120px',
      SchoolPeriod: [],
      XXKCBInfo: {
        Id: '',
        KCMC: '',
        CJR: '',
        SFSC: 0,
        XKZF: 0,
        XD: 1,
        SSXX: '',
        LRFS: 1,
        ZSFS: 0,
        GLDJ: '',
        GLDJ2: '',
        KCLX: 0,
        KCLY: 0,
        SYZT: 0,
        PXH: 0,
        WdId: ''
      },
      treedata: [],
      defaultProps: {
        children: 'subnodeList',
        label: 'name'
      },
      selectType: 1,
      multipleSelection: [],
      levellist: [],
      levellists: [],
      areaCourseList: [],
      curCourse: '',
      courseCheckList: [],
      XXNJKCGLInfo: {
        Id: '',
        KCMC: '',
        XKZF: 0,
        XD: 0,
        LRFS: 0,
        ZSFS: 0,
        GLDJ: '',
        GLDJ2: '',
        CJR: '',
        SFSC: 0,
        SSXX: 'string',
        KCLX: 0,
        KCYSId: 'string',
        SYZT: 0,
        NJGL: 0,
        WdId: ''
      },
      XXNJKCGLInfo_title: '',
      schoolcourseList: [],
      schoolchecklist: [],
      schoolId: '',
      gradeId: '',
      rules: {
        XD: [
          {
            required: true,
            message: '请选择学段',
            trigger: 'blur'
          },
          {
            validator: isInteger,
            message: '请选择学段',
            trigger: 'change'
          }
        ],
        GLDJ2: [
          {
            required: true,
            message: '请选择关联等级',
            trigger: 'blur'
          }
        ]
        // ZSFS: [
        //   {
        //     required: true,
        //     message: "请选择展示方式",
        //     trigger: "blur"
        //   },
        // ],
        // XKZF: [
        //   {
        //     required: true,
        //     message: "请输入总分",
        //     trigger: "blur"
        //   },
        //   {
        //     validator: isInteger,
        //     message: "总分大于0的整数",
        //     trigger: "change"
        //   }
        // ],
        // WdId: [
        //   {
        //     required: true,
        //     message: "请选择末级维度",
        //     trigger: "blur"
        //   }
        // ]
      },
      rulests: {
        KCMC: [
          {
            required: true,
            message: '请选择输入课程名称',
            trigger: 'blur'
          }
        ],
        KCBM: [
          {
            required: true,
            message: '编码不能为空',
            trigger: 'blur'
          },
          {
            validator: valid_KCBM,
            trigger: 'blur'
          }
        ],
        XD: [
          {
            required: true,
            message: '请选择学段',
            trigger: 'blur'
          }
        ],
        LRFS: [
          {
            required: true,
            message: '请选择录入方式',
            trigger: 'blur'
          }
        ],
        ZSFS: [
          {
            required: true,
            message: '请选择展示方式',
            trigger: 'blur'
          }
        ],
        XKZF: [
          {
            validator: validZSZF,
            message: '总分大于0的整数',
            trigger: 'blur'
          }
        ],
        PXH: [
          {
            validator: isIntegerAndZero,
            message: '排序号为整数',
            trigger: 'change'
          }
        ],
        WdId: [
          {
            required: true,
            message: '维度不能为空',
            trigger: 'change'
          }
        ]
      },
      rulestsss: {
        // LRFS: [
        //   {
        //     required: true,
        //     message: "请选择录入方式",
        //     trigger: "change"
        //   }
        // ],
        // ZSFS: [
        //   {
        //     required: true,
        //     message: "请选择展示方式",
        //     trigger: "change"
        //   }
        // ],
        // XKZF: [
        //   {
        //     validator: validZSZF,
        //     trigger: "change"
        //   }
        // ],
        KCBM: [
          {
            required: true,
            message: '编码不能为空',
            trigger: 'change'
          }
        ]
      },
      userName: '',
      userId: '',
      schoolhtml: true,
      gradehtml: false,
      edittype: 1,
      selectcourse: [],
      specialtitle: '新增特殊课程',
      schoolInfo: {},
      // 学校维度
      schoolDimension: [],
      schoolProp: {
        value: 'value',
        label: 'label',
        children: 'children',
        emitPath: false,
        checkStrictly: true
      },
      // 区域维度
      areaDimension: [],
      areaProp: {
        value: 'Id',
        label: 'WDMC',
        children: 'children',
        emitPath: false,
        checkStrictly: true
      },
      btnLoading: false, //按钮转圈
      domType: 1,
      schoolCourse: []
    }
  },
  created: async function() {
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.XXKCBInfo.CJR = userinfo.Id
    this.XXKCBInfo.SSXX = userinfo.SSZZId
    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    this.schoolId = userinfo.SSZZId
    await this.getSchoolInfo(this.schoolId)

    // 获取学校及年级
    this.$api.post(
      'v1/qyxxzzjg/GetSchoolGradeByTree',
      { SchoolId: this.XXKCBInfo.SSXX, token: this.token },
      r => {
        if (r.Code === 0) {
          this.treedata = r.Target
        }
      }
    )

    // 获取等级转换
    this.$api.post(
      'v1/relationgrade/GetRelationGradList',
      { SchoolId: this.XXKCBInfo.SSXX, token: this.token },
      r => {
        if (r.Code === 0) {
          this.levellists = r.Target || []
        }
      }
    )
    this.getQYcourse()
    window.onresize = () => {
      if (this.domType) {
        this.calcDomWidth(this.domType)
        if (this.curCourse) this.handleLeft(this.curCourse, this.domType)
      }
    }
  },
  async mounted() {
    this.setSort()
    await this.getDimension()
    await this.getSchoolCourse()
  },
  methods: {
    setSort() {
      // 表格中需要实现行拖动，所以选中tr的父级元素
      const el = this.$refs.dragTable.$el.querySelectorAll(
        '.el-table__body-wrapper > table > tbody'
      )[0] // table
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData: function(dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          var oldIndex = evt.oldIndex
          const targetRow = this.tableData.splice(oldIndex, 1)[0] // this.list是表格所有数据
          var newindex = evt.newIndex
          this.tableData.splice(newindex, 0, targetRow)
          var impactindex = 0 //影响行
          var frontOrBack = 1
          if (newindex > oldIndex) {
            impactindex = newindex - 1
          } else {
            impactindex = newindex + 1
            frontOrBack = 2
          }
          const impactRow = this.tableData[impactindex] // this.list影响行
          this.eiditcourseordernumber(
            targetRow.Id,
            targetRow.PXH,
            impactRow.PXH,
            frontOrBack
          )
        }
      })
    },
    // 刷新
    refloadList: function() {
      var pagerInfo = { pageIndex: 0, pageSize: this.pagesize }
      if (this.selectType === 1) {
        this.tableData = []
        // 获取学校课程
        this.$api.post(
          'v1/xxkcb/GetXXKCBList',
          { SchoolId: this.schoolId, PagerInfo: pagerInfo, token: this.token },
          r => {
            if (r.Code === 0) {
              this.tableData = r.Target

              this.currentPage = r.PagerInfo.PageIndex
              this.pagesize = r.PagerInfo.PageSize
              this.rowcount = r.PagerInfo.RowCount

              this.getSchoolCourse()
            }
          }
        )
      } else {
        this.classtableData = []
        // 获取年级课程
        this.$api.post(
          'v1/xxkcb/GetGradeCourse',
          {
            SchoolId: this.schoolId,
            GradeId: this.gradeId,
            PagerInfo: pagerInfo,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              if (r.Target) {
                r.Target.sort((a, b) => a.PXH - b.PXH)
              }
              this.classtableData = r.Target
              this.currentPage = r.PagerInfo.PageIndex
              this.pagesize = r.PagerInfo.PageSize
              this.rowcount = r.PagerInfo.RowCount
            }
          }
        )
      }
    },
    // 修改课程排序
    eiditcourseordernumber(
      courseid,
      oldordernumber,
      newOrderNumber,
      frontOrBack
    ) {
      this.$api.post('v1/xxkcb/EditCourseOrderNumber', {
        SchoolId: this.schoolId,
        CourseId: courseid,
        OldOrderNumber: oldordernumber,
        NewOrderNumber: newOrderNumber,
        FrontOrBack: frontOrBack,
        token: this.token
      })
    },
    // tree选中
    handleNodeClick(data) {
      this.selectType = data.type

      if (this.selectType === 1) {
        this.schoolhtml = true
        this.gradehtml = false
      } else {
        this.schoolhtml = false
        this.gradehtml = true
      }

      this.gradeId = data.key

      this.refloadList()
    },
    // 翻页
    handleSizeChange(val) {
      var pagerInfo = { pageIndex: 0, pageSize: val }
      if (this.selectType === 1) {
        this.$api.post(
          'v1/xxkcb/GetXXKCBList',
          { SchoolId: this.schoolId, PagerInfo: pagerInfo, token: this.token },
          r => {
            if (r.Code === 0) {
              this.tableData = r.Target
              this.currentPage = r.PagerInfo.PageIndex
              this.pagesize = r.PagerInfo.PageSize
              this.rowcount = r.PagerInfo.RowCount
              // loading=false;
            }
          }
        )
      } else {
        this.$api.post(
          'v1/xxkcb/GetGradeCourse',
          {
            SchoolId: this.schoolId,
            GradeId: this.gradeId,
            PagerInfo: pagerInfo,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              if (r.Target) {
                r.Target.sort((a, b) => a.PXH - b.PXH)
              }
              this.classtableData = r.Target
              this.currentPage = r.PagerInfo.PageIndex
              this.pagesize = r.PagerInfo.PageSize
              this.rowcount = r.PagerInfo.RowCount
              // loading=false;
            }
          }
        )
      }
    },
    // 翻页查询
    handleCurrentChange(val) {
      var pagerInfo = { pageIndex: val, pageSize: this.pagesize }
      if (this.selectType === 1) {
        this.$api.post(
          'v1/xxkcb/GetXXKCBList',
          { SchoolId: this.schoolId, PagerInfo: pagerInfo, token: this.token },
          r => {
            if (r.Code === 0) {
              this.tableData = r.Target
              this.currentPage = r.PagerInfo.PageIndex
              this.pagesize = r.PagerInfo.PageSize
              this.rowcount = r.PagerInfo.RowCount
              // loading=false;
            }
          }
        )
      } else {
        this.$api.post(
          'v1/xxkcb/GetGradeCourse',
          {
            SchoolId: this.schoolId,
            GradeId: this.gradeId,
            PagerInfo: pagerInfo,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              if (r.Target) {
                r.Target.sort((a, b) => a.PXH - b.PXH)
              }
              this.classtableData = r.Target
              this.currentPage = r.PagerInfo.PageIndex
              this.pagesize = r.PagerInfo.PageSize
              this.rowcount = r.PagerInfo.RowCount
              // loading=false;
            }
          }
        )
      }
    },
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = []
      val.forEach(item => {
        this.multipleSelection.push(item.Id)
      })
    },

    // 继承区域课程
    openinheritareacourse: async function() {
      if (this.areaDimension.length === 0) {
        await this.getAreaDimension()
        for (let i = 0; i < this.areaDimension.length; i++) {
          await this.getAreaDimension(this.areaDimension[i])
        }
      }
      this.dialogFormVisiblearea = true
      this.domType = 1
      this.courseCheckList = []
      var cjr = this.XXKCBInfo.CJR
      var schoolId = this.XXKCBInfo.SSXX

      this.XXKCBInfo = {
        Id: '',
        KCMC: '',
        CJR: cjr,
        SFSC: 0,
        KCBM: '',
        XKZF: 0,
        XD: 1,
        SSXX: schoolId,
        LRFS: 0,
        ZSFS: 0,
        GLDJ: '',
        GLDJ2: '',
        KCLX: 1,
        KCLY: 2,
        SYZT: 0
      }
      this.calcDomWidth(this.domType)
    },
    // 保存区域课程
    submitinheritareacourse: function() {
      if (this.courseCheckList.length === 0)
        return this.$message.error('请勾选课程')
      const reg = /^[0-9]*[1-9][0-9]*$/

      let arr = this.areaCourseList.filter(item =>
        this.courseCheckList.includes(item.Id)
      )

      try {
        arr.forEach(item => {
          let data = item.data
          if (!data.KCBM) {
            let letterArr = []
            letterArr = pinyin(item.KCMC, {
              toneType: 'none',
              pattern: 'first',
              type: 'array'
            })
            if (letterArr) {
              let letter = ''
              letterArr.forEach(it => (letter += it.toUpperCase()))
              data.KCBM = letter
            }
          }

          if (
            data.LRFS === 1 &&
            data.XKZF &&
            (!Number(data.XKZF) || !reg.test(data.XKZF))
          ) {
            this.curCourse = item.Id
            throw new Error('学科总分请填写不为0的整数')
          }

          let reg = /^[A-Za-z0-9]+$/ //英文字符
          if (!reg.test(data.KCBM)) throw new Error('请输入英文或者数字')
          if (data.KCBM.length > 10) throw Error('编码不能超过10个字符')
        })
      } catch (e) {
        return this.$message.error(e.message)
      }

      let courselist = []
      courselist = this.areaCourseList.reduce((pre, cur) => {
        if (this.courseCheckList.includes(cur.Id)) {
          let LRFS = cur.data.LRFS || cur.LRFS
          let ZSFS = cur.data.ZSFS || cur.ZSFS
          return pre.concat({
            Id: cur.Id,
            CourseName: cur.alias || cur.KCMC,
            LRFS,
            ZSFS,
            XKZF: LRFS === 2 ? undefined : cur.data.XKZF || cur.XKZF,
            GLDJ: cur.data.GLDJ2,
            WdId: cur.data.WdId || cur.WDId,
            KCLX: 1,
            XD: this.XXKCBInfo.XD
          })
        } else return pre
      }, [])

      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.btnLoading = true

          this.$api.post(
            'v1/xxkcb/AddXXKCBByArea',
            {
              XXKCBInfo: this.XXKCBInfo,
              CourseList: courselist,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.dialogFormVisiblearea = false

                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.refloadList()
                this.curCourse = ''
                this.areaCourseList.forEach(item => {
                  item.data.GLDJ2 = ''
                  item.data.LRFS = ''
                  item.data.WdId = ''
                  item.data.XKZF = ''
                  item.data.ZSFS = ''
                })
                this.$refs.sbmitfrom.resetFields()
                this.btnLoading = false
              } else {
                this.btnLoading = false
                this.$message.error(r.Message)
              }
            },
            err => {
              this.btnLoading = false
              this.$message.error(err.Message)
            }
          )
        } else {
          return false
        }
      })
    },

    // 打开新增特殊课程-学校课程
    openspecialcourse: function() {
      this.specialtitle = '新增特殊课程'
      this.dialogFormVisible = true

      var cjr = this.XXKCBInfo.CJR
      var schoolId = this.XXKCBInfo.SSXX

      this.XXKCBInfo = {
        Id: '',
        KCMC: '',
        CJR: cjr,
        SFSC: 0,
        XKZF: 0,
        XD: 1,
        SSXX: schoolId,
        LRFS: 1,
        ZSFS: 1,
        GLDJ: '',
        GLDJ2: '',
        KCLX: 2,
        KCLY: 1,
        SYZT: 0,
        PXH: 0
      }
    },
    // 选择录入方式-学校课程
    selectinput: function(data) {
      if (data.LRFS === 2) {
        data.ZSFS = 2
      }
      this.handleGLDJ(data)
    },
    // 选择录入方式-学校年级
    selectinputnj: function() {
      if (this.XXNJKCGLInfo.LRFS === 2) {
        this.XXNJKCGLInfo.ZSFS = 2
      }
      this.handleGLDJ(this.XXNJKCGLInfo)
    },

    // 修改
    editRow: function(index, row) {
      if (row.KCLX === '2') {
        this.specialtitle = '编辑特殊课程'
      } else {
        this.specialtitle = '编辑课程'
      }

      // this.XXKCBInfo = row;

      this.XXKCBInfo = {
        Id: row.Id,
        KCMC: row.KCMC,
        CJR: row.CJR,
        SFSC: row.SFSC,
        XKZF: row.XKZF,
        XD: row.XD,
        SSXX: row.SSXX,
        KCBM: row.KCBM,
        LRFS: row.LRFS,
        ZSFS: row.ZSFS,
        GLDJ: row.GLDJ,
        GLDJ2: row.GLDJ,
        KCLX: row.KCLX,
        PXH: row.PXH,
        KCLY: row.KCLY,
        SYZT: row.SYZT,
        WdId: row.WdId
      }

      this.dialogFormVisible = true
      this.handleGLDJ(this.XXKCBInfo)
    },

    // 删除
    deleteRow: function(index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/xxkcb/XXKCBDelete',
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.refloadList()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    // 批量删除
    batchdelete: function() {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 提交
            this.$api.post(
              'v1/xxkcb/XXKCBDelete',
              {
                IdList: this.multipleSelection,
                UserName: this.userName,
                UserId: this.userId,
                token: this.token
              },
              r => {
                if (r.Code === 0) {
                  this.$notify({
                    type: 'success',
                    message: r.Message,
                    duration: 3000
                  })

                  this.refloadList()
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      } else {
        this.$message({
          type: 'info',
          message: '请选择删除数据！'
        })
      }
    },

    // 保存特殊课程
    submitshowdata: function(event) {
      event.preventDefault()
      if (!this.XXKCBInfo.KCBM) {
        let letterArr = []
        try {
          letterArr = pinyin(this.XXKCBInfo.KCMC, {
            toneType: 'none',
            pattern: 'first',
            type: 'array'
          })
          if (letterArr) {
            let letter = ''
            letterArr.forEach(it => (letter += it.toUpperCase()))
            this.$set(this.XXKCBInfo, 'KCBM', letter)
          }
        } catch (err) {
          //
        }
      }
      this.$refs.sbmitfromts.validate(valid => {
        if (valid) {
          this.btnLoading = true
          this.XXKCBInfo.GLDJ = this.XXKCBInfo.GLDJ2
          this.$api.post(
            'v1/xxkcb/XXKCBAdd',
            {
              XXKCBInfo: this.XXKCBInfo,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.dialogFormVisible = false

                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })
                this.btnLoading = false
                this.$refs.sbmitfromts.resetFields()

                this.refloadList()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
                this.btnLoading = false
              }
            },
            err => {
              this.btnLoading = false
              this.$message.error(err.Message)
            }
          )
        } else {
          return false
        }
      })
    },

    // 录入方式
    lrchange: function() {
      if (this.XXKCBInfo.LRFS === 2) {
        this.XXKCBInfo.ZSFS = 2
      }
    },
    // 新增年级课程
    openschoolcourse: function() {
      this.dialogFormVisibleschool = true
      this.domType = 2
      this.edittype = 1
      this.XXNJKCGLInfo_title = '继承学校课程'

      this.schoolchecklist = []
      this.XXNJKCGLInfo = {
        Id: '',
        KCMC: '',
        XKZF: 0,
        XD: 0,
        LRFS: 0,
        ZSFS: 0,
        GLDJ: '',
        GLDJ2: '',
        CJR: '',
        SFSC: 0,
        SSXX: '',
        KCLX: 0,
        KCYSId: '',
        SYZT: 0,
        NJGL: this.gradeId
      }

      this.XXNJKCGLInfo.CJR = this.userName
      this.XXNJKCGLInfo.SSXX = this.schoolId
      let SSXD = 1
      if (Number(this.gradeId) >= 7 && Number(this.gradeId) <= 9) SSXD = 2
      if (Number(this.gradeId) >= 10 && Number(this.gradeId) <= 12) SSXD = 3
      // 获取年级未继承课程
      this.$api.post(
        'v1/xxkcb/GetGradeSurplusCourse',
        {
          SchoolId: this.XXKCBInfo.SSXX,
          GradeNumber: this.gradeId,
          token: this.token,
          SSXD
        },
        r => {
          if (r.Code === 0) {
            r.Target.forEach(item => {
              // 处理课程编码
              if (!item.KCBM) {
                let letterArr = []
                try {
                  letterArr = pinyin(item.KCMC, {
                    toneType: 'none',
                    pattern: 'first',
                    type: 'array'
                  })
                  if (letterArr) {
                    let letter = ''
                    letterArr.forEach(it => (letter += it.toUpperCase()))
                    item.KCBM = letter
                  }
                } catch (err) {
                  //
                }
              }
              item.data = {
                LRFS: '',
                ZSFS: '',
                GLDJ2: '',
                KCBM: item.KCBM,
                WdId: ''
              }
            })
            this.schoolcourseList = r.Target || []
            this.calcDomWidth(this.domType)

            this.$nextTick(() => {
              this.$refs.sbmitfromtsss.clearValidate()
              if (this.schoolcourseList[0]) {
                this.curCourse = this.schoolcourseList[0].Id
                this.clickSchoolCourse(this.schoolcourseList[0].Id, 2)
              } else this.curCourse = ''
            })
          }
        }
      )
    },
    // 编辑年级课程
    schooleditrow: function(index, row) {
      this.$set(row, 'GLDJ2', row.GLDJ)

      let xxkc = this.schoolCourse.find(item => item.Id === row.KCYSId)
      if (!xxkc.KCBM) {
        let letterArr = []
        try {
          letterArr = pinyin(xxkc.KCMC, {
            toneType: 'none',
            pattern: 'first',
            type: 'array'
          })
          if (letterArr) {
            let letter = ''
            letterArr.forEach(it => (letter += it.toUpperCase()))
            this.$set(this.form, 'HDBM', letter)
            xxkc.KCBM = letter
          }
        } catch (err) {
          //
        }
      }
      // if (xxkc) row.KCBM = xxkc.KCBM

      this.XXNJKCGLInfo = row
      this.schoolchecklist = []
      this.edittype = 2
      this.handleGLDJ(this.XXNJKCGLInfo)
      this.XXNJKCGLInfo_title = '编辑课程'
      this.dialogFormVisibleschool = true
    },
    // 删除
    schoolDeleterow: function(index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/xxkcb/DeleteGradeCourse',
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.refloadList()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 保存学校课程
    saveschoolcourse: function() {
      if (this.edittype == 1 && this.schoolchecklist.length === 0)
        return this.$message.error('请勾选课程')

      if (this.edittype === 1) {
        const reg = /^[0-9]*[1-9][0-9]*$/

        let arr = this.schoolcourseList.filter(item =>
          this.schoolchecklist.includes(item.Id)
        )

        try {
          arr.forEach(item => {
            let data = item.data
            if (
              data.LRFS === 1 &&
              data.XKZF &&
              (!Number(data.XKZF) || !reg.test(data.XKZF))
            ) {
              this.curCourse = item.Id
              throw new Error('学科总分请填写不为0的整数')
            }
          })
        } catch (e) {
          return this.$message.error(e.message)
        }

        // 校验编码
        // try {
        //   arr.forEach((item) => {
        //     let kc = this.schoolCourse.find((it) => it.Id === item.Id);
        //     if (!item.KCBM) {
        //       if (!kc.KCBM) throw new Error(`请先设置学校课程${kc.KCMC}的编码`);
        //       else item.KCBM = kc.KCBM;
        //     } else {
        //       if (item.KCBM !== kc.KCBM) item.KCBM = kc.KCBM;
        //     }
        //   });
        // } catch (e) {
        //   return this.$message.error(e.message);
        // }
      } else {
        // try {
        //   let kc = this.schoolCourse.find(
        //     (it) => it.Id === this.XXNJKCGLInfo.KCYSId
        //   );
        //   if (!this.XXNJKCGLInfo.KCBM) {
        //     if (!kc.KCBM) throw new Error(`请先设置学校课程${kc.KCMC}的编码`);
        //     else this.XXNJKCGLInfo.KCBM = kc.KCBM;
        //   } else {
        //     if (this.XXNJKCGLInfo.KCBM !== kc.KCBM)
        //       this.XXNJKCGLInfo.KCBM = kc.KCBM;
        //   }
        // } catch (e) {
        //   return this.$message.error(e.message);
        // }
      }

      this.$refs.sbmitfromtsss.validate(valid => {
        if (valid) {
          this.XXNJKCGLInfo.GLDJ = this.XXNJKCGLInfo.GLDJ2
          if (Number(this.gradeId) <= 6) {
            this.XXNJKCGLInfo.XD = 1
          } else if (Number(this.gradeId) > 6 && Number(this.gradeId) <= 9) {
            this.XXNJKCGLInfo.XD = 2
          } else if (Number(this.gradeId) > 9 && Number(this.gradeId) <= 12) {
            this.XXNJKCGLInfo.XD = 3
          }

          let CourseIdList = []
          if (this.edittype === 1) {
            CourseIdList = this.schoolcourseList.reduce((pre, cur) => {
              if (this.schoolchecklist.includes(cur.Id)) {
                let LRFS = cur.data.LRFS || cur.LRFS
                let ZSFS = cur.data.ZSFS || cur.ZSFS
                return pre.concat({
                  Id: cur.Id,
                  CourseName: cur.alias || cur.KCMC,
                  LRFS,
                  ZSFS,
                  PXH: cur.PXH,
                  KCBM: cur.KCBM,
                  XKZF: LRFS === 2 ? undefined : cur.data.XKZF || cur.XKZF,
                  GLDJ: cur.data.GLDJ2 || cur.GLDJ,
                  XD: this.XXNJKCGLInfo.XD,
                  WdId: cur.data.WdId || cur.WdId,
                  KCLX: cur.KCLX
                })
              } else return pre
            }, [])
          } else {
            let curSchoolCourse = this.schoolCourse.find(
              item => item.Id === this.XXNJKCGLInfo.KCYSId
            )
            if (!this.XXNJKCGLInfo.KCBM) {
              this.XXNJKCGLInfo.KCBM = curSchoolCourse.KCBM || ''
            }
            CourseIdList = [
              {
                Id: this.XXNJKCGLInfo.Id,
                CourseName: this.XXNJKCGLInfo.KCMC,
                LRFS: this.XXNJKCGLInfo.LRFS,
                ZSFS: this.XXNJKCGLInfo.ZSFS,
                XKZF:
                  this.XXNJKCGLInfo.LRFS === 2
                    ? undefined
                    : this.XXNJKCGLInfo.XKZF,
                KCBM: this.XXNJKCGLInfo.KCBM || '',
                GLDJ: this.XXNJKCGLInfo.GLDJ2,
                XD: this.XXNJKCGLInfo.XD,
                WdId: this.XXNJKCGLInfo.WdId,
                KCLX: this.XXNJKCGLInfo.KCLX,
                PXH: curSchoolCourse.PXH
              }
            ]
          }
          this.btnLoading = true
          this.$api.post(
            'v1/xxkcb/AddGradeCourse',
            {
              XXNJKCGLInfo: this.XXNJKCGLInfo,
              CourseIdList,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.dialogFormVisibleschool = false

                this.$refs.sbmitfromtsss.resetFields()

                this.refloadList()
                this.btnLoading = false
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
                this.btnLoading = false
              }
            },
            err => {
              this.btnLoading = false
              this.$message.error(err.Message)
            }
          )
        } else {
          return false
        }
      })
    },
    // 获取学校维度
    getDimension() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/dimension/GetDimensionTree',
          {
            LX: 1,
            SchoolId: this.schoolId,
            token: this.token
          },
          res => {
            if (res.Code === 0) {
              this.schoolDimension = res.Target || []
              resolve()
            }
          },
          err => reject(err)
        )
      })
    },
    // 获取区域维度
    getAreaDimension(parent) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/dimension/GetDimension',
          {
            AreaId: this.schoolInfo.QYId,
            ParentId: parent ? parent.Id : '',
            PagerInfo: {
              pageIndex: 1,
              pageSize: 99
            },
            token: this.token
          },
          res => {
            if (res.Code === 0 && res.Target) {
              if (parent) {
                if (res.Target.Target && res.Target.Target.length > 0) {
                  parent.children = res.Target.Target
                  parent.disabled = true
                } else parent.children = null
              } else {
                this.areaDimension = res.Target.Target
              }
              resolve()
            } else reject()
          }
        )
      })
    },
    // 根据学校id获取得到区域id
    getSchoolInfo(schoolId) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolMessage',
          {
            SchoolId: schoolId, //	是	string	学校Id
            token: this.token
          },
          res => {
            if (res.Code === 0) {
              this.schoolInfo = res.Target || {}
              this.SchoolPeriod = handle_SSXD3(this.schoolInfo)
              resolve()
            }
          }
        )
      })
    },
    handleGLDJ(data) {
      // 分 分 不控制
      if (data.LRFS === 1 && data.ZSFS === 1) {
        this.levellist = this.levellists
      } else if (data.LRFS === 1 && data.ZSFS === 2) {
        // 分 等 分转等
        this.levellist = this.levellists.filter(item => item.DJLX === 2)
      } else if (data.LRFS === 2 && data.ZSFS === 2) {
        // 等 等 等转分
        this.levellist = this.levellists.filter(item => item.DJLX === 1)
      } else this.levellist = []
      let res2 = this.levellist.find(item => item.Id === data.GLDJ)
      let res = this.levellist.find(item => item.Id === data.GLDJ2)

      if (res2 && !res) data.GLDJ2 = data.GLDJ
      if (!res2 && !res) data.GLDJ2 = ''
    },
    // 获取区域课程
    getQYcourse() {
      this.$api.post(
        'v1/areacourse/GetQYKCBySchoolId',
        {
          SchoolId: this.XXKCBInfo.SSXX,
          token: this.token,
          SSXD: this.XXKCBInfo.XD
        },
        r => {
          if (r.Code === 0) {
            r.Target.forEach(item => {
              item.alias = ''
              item.data = {
                XKZF: '',
                LRFS: '',
                ZSFS: '',
                GLDJ2: '',
                WdId: ''
              }
            })
            this.areaCourseList = r.Target
            if (this.areaCourseList[0]) {
              this.curCourse = this.areaCourseList[0].Id
            }
          }
        }
      )
    },
    // 区域课程点击
    clickAreaCourse(id, type) {
      //type: 1.勾选框点击 2.名称点击
      if (type === 1) {
        if (this.courseCheckList.includes(id)) {
          this.curCourse = id
          this.handleLeft(this.curCourse, this.domType)
        }
      } else {
        if (!this.courseCheckList.includes(id)) this.courseCheckList.push(id)
        this.curCourse = id
        this.handleLeft(this.curCourse, this.domType)
      }
    },
    // 学校课程点击
    clickSchoolCourse(id, type) {
      //type: 1.勾选框点击 2.名称点击
      if (type === 1) {
        if (this.schoolchecklist.includes(id)) {
          this.curCourse = id
          this.handleLeft(this.curCourse, this.domType)
        }
      } else {
        if (!this.schoolchecklist.includes(id)) this.schoolchecklist.push(id)
        this.curCourse = id
        this.handleLeft(this.curCourse, this.domType)
      }
    },
    calcDomWidth(type) {
      this.$nextTick(() => {
        if (type === 1) {
          let containerDom = document.querySelector(
            '.area-item-bottom-container'
          )
          let itemDom = document.querySelectorAll(
            '.area-item-bottom-container .block .area-item-bottom'
          )
          if (itemDom && itemDom.length > 0 && containerDom) {
            itemDom.forEach(item => {
              item.style.width = containerDom.clientWidth + 'px'
            })
          }
        } else {
          let containerDom = document.querySelector(
            '.school-item-bottom-container'
          )
          let itemDom = document.querySelectorAll('.school-item-bottom')
          if (itemDom && itemDom.length > 0 && containerDom) {
            itemDom.forEach(item => {
              item.style.width = containerDom.clientWidth + 'px'
            })
          }
        }
      })
    },
    handleLeft(id, type) {
      if (type === 1) {
        let dom = document.querySelector('.area-item-bottom-container .block')
        let container = document.querySelector('.area-item-bottom-container')
        let index = this.areaCourseList.findIndex(item => item.Id === id)
        if (dom && container && index !== -1) {
          dom.style.left = -(index * container.clientWidth) + 'px'
        }
      } else {
        let dom = document.querySelector('.school-item-bottom-container .block')
        let container = document.querySelector('.school-item-bottom-container')
        let index = this.schoolcourseList.findIndex(item => item.Id === id)
        if (dom && container && index !== -1) {
          dom.style.left = -(index * container.clientWidth) + 'px'
        }
      }
    },
    GLDJ2change(data) {
      if (data.GLDJ2) {
        data.GLDJ = data.GLDJ2
      }
    },
    // 获取所有学校课程 用于判断年级课程编码
    getSchoolCourse() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/xxkcb/GetXXKCBList',
          {
            SchoolId: this.schoolId,
            PagerInfo: {
              pageIndex: 0,
              pageSize: 999
            },
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.schoolCourse = r.Target || []
              resolve()
            } else reject()
          }
        )
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.area-course-item,
.school-course-item {
  .curCourse {
    color: #14d089;
  }
  .alias {
    ::v-deep .el-input__inner {
      padding: 2px 4px;
      height: 20px;
      font-size: 12px;
    }
  }
}
.area-item-bottom-container {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  .block {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    transition: all 0.5s ease;
  }
}
.school-item-bottom-container {
  width: 100%;
  height: 365px;
  position: relative;
  overflow: hidden;
  .block {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    transition: all 0.5s ease;
  }
}
</style>
<style>
.customWidth {
  width: 45%;
}

.cell {
  text-align: left;
}
</style>
