<template>
  <div class="cont-whole-box">
    <div style="padding:30px;">
      <div
        class="table-er-box"
        style="border-bottom: 1px solid #f2f2f2;text-align: right;"
      >
        <div class="table-er-butt" @click="dialogImportVisible = true">
          <span class="table-er-buttspan">导入</span>
        </div>
        <div class="table-er-butt" @click="downloadawardlist">
          <span class="table-er-buttspan">导出</span>
        </div>
        <div class="table-er-butt" @click="handleaddaward()">
          <span class="table-er-buttspan">新增</span>
        </div>
        <div
          style="font-size: 14px;margin-bottom: 10px;display: inline-block;float: left;"
        >
          <el-input
            style="width: 200px;"
            v-model="searchname"
            placeholder="请输入关键字"
            maxlength="20"
            prefix-icon="el-icon-search"
          ></el-input>
          <span
            style="color: #14d089;margin-left: 5px;cursor: pointer;"
            @click="handleSearch()"
            >搜索</span
          >
        </div>
      </div>
      <div class="multi-opt-box" style="margin-top: 0px;">
        <div class="multi-opt">
          <div class="multi-opt-left">时间</div>
          <div class="multi-opt-rig">
            <div class="cele-left" style="width: 140px;">
              <el-date-picker
                popper-class="datepicker-z-index"
                style="width: 140px;"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择开始日期"
                v-model="kssj"
              ></el-date-picker>
            </div>
            <div
              style="display: inline-block;font-size: 1rem;vertical-align: middle;margin: 0px 1rem;"
            >
              ~
            </div>
            <div class="cele-left" style="width: 140px;">
              <el-date-picker
                popper-class="datepicker-z-index"
                style="width: 140px;"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择结束日期"
                v-model="jssj"
              ></el-date-picker>
            </div>
            <div
              style="display: inline-block;vertical-align: middle;font-size: 1rem;margin-left: 2rem;"
            >
              <div style="display: inline-block;vertical-align: middle;">
                快速选择：
              </div>
              <el-radio-group
                v-model="rqlx"
                @change="rqlxchange()"
                style="margin-left:10px; margin-top:-10px;"
              >
                <el-radio-button :label="1">本期</el-radio-button>
                <el-radio-button :label="2">本周</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="multi-opt">
          <div class="multi-opt-left">社团</div>
          <div class="multi-opt-rig">
            <div class="d-word-widt" @click="teamchange('')">
              <a v-if="teamid == ''" class="b-word-widta opt-rigsty whole-back"
                >全部</a
              >
              <a v-else class="b-word-widta whole-back-hover">全部</a>
            </div>
            <div
              v-for="tm in teamlist"
              :key="tm.XZId"
              class="d-word-widt"
              @click="teamchange(tm.XZId)"
            >
              <a
                v-if="teamid == tm.XZId"
                class="b-word-widta opt-rigsty whole-back"
                >{{ tm.XZMC }}</a
              >
              <a v-else class="b-word-widta whole-back-hover">{{ tm.XZMC }}</a>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(hj, index) in hjlist" :key="index">
        <div class="py-tit" style="font-size: 1rem;margin-top: 2rem;">
          &bull;&nbsp;&nbsp; {{ hj.HJMC }}
          <div
            v-if="hj.SHZT == 2 && hj.SJLY == 1"
            class="tab-wz-butt"
            style="float: right;margin-right: 0.9rem;"
          >
            <a
              v-if="hj.SFYX == '1'"
              class="tab-wz-butta c-red"
              style="cursor: pointer;"
              @click="setawardinvalid(hj.Id)"
              >设置为无效</a
            >
            <a
              v-else
              class="tab-wz-butta c-guree"
              style="cursor: pointer;"
              @click="setawardvalid(hj.Id)"
              >设置为有效</a
            >
          </div>
        </div>
        <div class="py-cont-box">
          <div class="displ-il-box-top">
            <div class="displ-il-box">
              <span class="c-g-9">社团：</span>
              {{ hj.CYMC }}
            </div>
            <div class="displ-il-box">
              <span class="c-g-9">获奖类别</span>
              {{ hj.LBMC }}
            </div>
            <div class="displ-il-box">
              <span class="c-g-9">获奖级别：</span>
              {{ hj.HJDJMC }}
            </div>
            <div v-if="hj.SHZT == 2" class="displ-il-box">
              <span class="c-g-9">得分：</span>
              {{ hj.DF }}分
            </div>
          </div>
          <div class="py-text-l">获奖描述：</div>
          <div class="py-text">{{ hj.BZ }}</div>
          <div
            class="py-img-box"
            v-if="hj.FjList != null && hj.FjList.length > 0"
          >
            <el-image
              class="py-img"
              v-for="fj in hj.FjList"
              :key="fj.Id"
              style="width: 100px; height: 100px"
              :src="fj.FJLJ"
              fit="scale-down"
            ></el-image>
          </div>
          <div class="displ-il-boxmain">
            <div class="displ-il-box">
              <span class="c-g-9">添加人：</span>
              {{ hj.CJRXM }} {{ hj.CJSJ | DateFormat("yyyy-MM-dd") }}
            </div>
            <div
              v-if="hj.SHZT == 2 && hj.SFYX == 1 && hj.SJLY == 1"
              class="displ-il-box"
            >
              <span class="c-g-9">审核人：</span>
              {{ hj.SHRXM }} {{ hj.SHRQ | DateFormat("yyyy-MM-dd") }}
            </div>
            <div
              v-if="hj.SHZT == 2 && hj.SFYX == 1 && hj.SJLY == 2"
              class="displ-il-box"
            >
              <span class="c-g-9">审核人：</span>
              自动审核（批量导入）
            </div>
            <div v-if="hj.SHZT == 2 && hj.SFYX == 2" class="displ-il-box">
              <span class="c-g-9">更改人：</span>
              {{ hj.SZRXM }} {{ hj.SZSJ | DateFormat("yyyy-MM-dd") }}
            </div>
            <div v-if="hj.SHZT == 2 && hj.SFYX == 2" class="displ-il-box">
              <span class="c-g-9">原因：</span>
              {{ hj.WXYY }}
            </div>
          </div>
        </div>
      </div>

      <div class="pagin-box">
        <div class="pagin-wz">
          <Pager :pagerInfo="pagerInfo" @changeIndex="handleCurrentChange" />
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleOptionClose"
    >
      <el-form :model="formData" ref="wxyyform">
        <el-form-item
          label="请在下方输入奖项无效的原因："
          :rules="[
            { required: true, message: '无效原因不能为空' },
            { min: 1, max: 50, message: '长度在1到50个字符', trigger: 'change' }
          ]"
          prop="wxyy"
        >
          <el-input
            type="textarea"
            v-model="formData.wxyy"
            placeholder="不超过50个字"
            maxlength="50"
            minlength="1"
            :rows="4"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleOptionClose()">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="导入获奖记录"
      :visible.sync="dialogImportVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleImportClose"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="upload"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <a
            :href="`${publicPath}template/团队获奖导入模板.xlsx`"
            class="essay-bt-textbunn"
            target="_blank"
            style="margin-left:0!important;"
            >团队获奖导入模板下载</a
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportClose()">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Pager from "@/components/Pager.vue";
import XLSX from "xlsx";
export default {
  components: {
    Pager
  },
  created() {
    //YhyTODO:获取所属学校
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
      this.yhid = user.Id;
      this.yhlb = user.YHLX;
    }
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return "/file/file/fileservice/UploadFile?token=" + this.token;
    }
  },
  mounted: function() {
    this.loadteamdata();
    this.loaddata();
  },
  methods: {
    upload(file) {
      let files = { 0: file.raw };
      this.readExcel(files);
    },
    generateData({ results }) {
      //for (let cell of results) {
      //  for (let item in cell) {
      //    if (!isNaN(Number(cell[item]))) {
      //      cell[item] = Number(cell[item]);
      //    }
      //  }
      //}
      this.excelData.results = results;
    },
    readExcel: function(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        this.generateData({ results });
        this.submitimportdata(results);
        this.$refs.upload.value = "";
      };
      fileReader.readAsArrayBuffer(files[0]);
    },
    submitimportdata: function(readdata) {
      if (readdata.length <= 0) {
        this.$message({
          type: "info",
          message: "上传文件中无正确数据!"
        });
        return;
      }
      this.importdata = [];
      for (var i = 0; i < readdata.length; i++) {
        var obj = {
          CYMC: readdata[i].获奖团队,
          HJMC: readdata[i].获奖名称,
          LBMC: readdata[i].获奖类别,
          HJDJMC: readdata[i].获奖级别,
          CJSJ: readdata[i].获奖时间,
          BZ: readdata[i].描述
        };
        this.importdata.push(obj);
      }
      this.$api.post(
        "v1/awards/ImportData",
        {
          CYLB: 4,
          DataList: this.importdata,
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          CJR: this.yhid,
          CJRLB: this.yhlb,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            this.$message({
              type: "info",
              message: r.Message
            });
            this.loaddata();
          } else {
            this.$message({
              type: "info",
              message: r.Message
            });
            return;
          }
        }
      );
    },
    downloadawardlist: function() {
      this.$api.post(
        "v1/awards/DownLoadAwardListForWeb",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          SearchName: this.searchname,
          CYId: this.teamid,
          CYLB: 4,
          BeginDate: this.kssj,
          EndDate: this.jssj,
          RQLX: this.rqlx,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            window.open(r.Message, "_blank");
          } else {
            this.$message({
              type: "info",
              message: r.Message
            });
          }
        }
      );
    },
    handleaddaward: function() {
      this.$router.push({ name: "addteamaward" });
    },
    //设置为无效
    setawardinvalid(id) {
      this.editid = id;
      this.formData.wxyy = "";
      this.dialogVisible = true;
    },
    //确定单选项
    handleConfirm() {
      this.$refs.wxyyform.validate(valid => {
        if (valid) {
          this.$api.post(
            "v1/awards/SetValidAward",
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              Id: this.editid,
              SFYX: "1",
              YHId: this.yhid,
              WXYY: this.formData.wxyy,
              token: this.token
            },
            () => {
              this.formData.wxyy = "";
              this.dialogVisible = false;
              this.loaddata();
            }
          );
        } else {
          return false;
        }
      });
    },
    //关闭单选项
    handleOptionClose() {
      this.formData.wxyy = "";
      this.dialogVisible = false;
    },
    handleImportClose: function() {
      this.dialogImportVisible = false;
    },
    teamchange: function(id) {
      this.orgid = "";
      this.teamid = id;
      this.pagerInfo.pageIndex = 1;
      this.loaddata();
    },
    rqlxchange: function() {
      this.pagerInfo.pageIndex = 1;
      this.kssj = "";
      this.jssj = "";
      this.loaddata();
    },
    handleSearch: function() {
      this.rqlx = 0;
      this.loaddata();
    },
    loaddata: function() {
      this.$api.post(
        "v1/awards/GetAwardListForWeb",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          SearchName: this.searchname,
          CYId: this.teamid,
          CYLB: 4,
          AwardType: 1,
          BeginDate: this.kssj,
          EndDate: this.jssj,
          RQLX: this.rqlx,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            this.hjlist = [];
            this.hjlist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    setawardvalid: function(id) {
      this.$confirm("是否设置该荣誉为有效？")
        .then(() => {
          this.$api.post(
            "v1/awards/SetValidAward",
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              Id: id,
              SFYX: "2",
              YHId: this.yhid,
              token: this.token
            },
            () => {
              this.loaddata();
            }
          );
        })
        .catch(() => {
          return;
        });
    },
    loadteamdata: function() {
      this.$api.post(
        "v1/awards/GetCustomGroup",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          HQLB: 3,
          JSId: this.yhid,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            this.teamlist = [];
            this.teamlist = r.Target;
          }
        }
      );
    },
    //翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    search: function() {
      this.pagerInfo.pageIndex = 1;
      this.loaddata();
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      editid: 0,
      isLogin: true,
      hjlist: [],
      njlist: [],
      bjlist: [],
      teamlist: [],
      importdata: [],
      dialogImportVisible: false,
      yhlb: 0,
      formData: { wxyy: "" },
      dialogVisible: false,
      ssxx: "",
      ssxq: "",
      searchname: "",
      kssj: "",
      jssj: "",
      token: "",
      rqlx: 0,
      yhid: "",
      bjid: "",
      teamid: "",
      orgid: "",
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      },
      excelData: {
        results: null
      }
    };
  }
};
</script>
