<template>
  <div>
    <div class="cont-whole-box">
      <div style="padding: 30px;">
        <div class="margno-t">
          <div class="table-er-box">
            <!--TODO:按钮-->
            <el-button icon="el-icon-edit" plain @click="addItem"
              >新增</el-button
            >
          </div>
          <!--TODO:内容-->
          <Table
            :tableData="
              dataTable.slice(
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                  pagerInfo.pageSize
              )
            "
            :reportType="reportType"
            @editItem="editItem"
            :user="user"
            @delItem="delItem"
            @updateItem="updateItem"
            v-loading="isLoading"
          />
          <div class="pagin-box">
            <div class="pagin-wz">
              <!--分页-->
              <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
            </div>
          </div>
        </div>
      </div>
      <From
        :visible="visible"
        :data="formData"
        :dataList="dataTable"
        :title="dialogTitle"
        :reportType="reportType"
        :shapes="shapes"
        :statu="statu"
        @closeDialog="closeDialog"
        @saveDialog="saveDialog"
      />
    </div>
  </div>
</template>

<script>
import From from "./Form.vue";
import Table from "./Table.vue";
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager,
    Table,
    From
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      schoolId: "",
      visible: false,
      dataTable: [],
      formData: {},
      dialogTitle: "新增模版",
      statu: [
        { Key: 1, Value: "正常" },
        { Key: 2, Value: "停用" }
      ],
      reportType: [],
      isLoading: false,
      shapes: []
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.getReportType();
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    closeDialog() {
      this.visible = false;
    },
    saveDialog(data) {
      if (data) {
        const index = this.dataTable.findIndex(c => c.Id === data.Id);
        if (index === -1) {
          this.dataTable.unshift(data);
        } else {
          this.dataTable.splice(index, 1, data);
        }
      }
      this.pagerInfo.totalCount = this.dataTable.length;
      this.visible = false;
    },
    addItem() {
      this.dialogTitle = "新增模版";
      this.formData = {};
      this.visible = true;
    },
    editItem(data) {
      this.dialogTitle = "编辑模版";
      this.formData = data;
      this.visible = true;
    },
    getData() {
      this.$api.post(
        "/v1/reporttemplate/GetReportTemplateList",
        {
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target;
            console.log(this.dataTable,'this.dataTable');
            this.pagerInfo.totalCount = this.dataTable.length;
            this.isLoading = false;
          }
        }
      );
    },
    getReportType() {
      this.isLoading = true;
      this.$api.post(
        "/v1/reporttemplate/GetReportTypes",
        {
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.reportType = r.Target.TypeList;
            this.shapes = r.Target.ShapeList;
            this.getData();
          }
        }
      );
    },
    updateItem(data) {
      this.dataTable.splice(
        this.dataTable.findIndex(c => c.Id === data.Id),
        1,
        data
      );
    },
    delItem(data) {
      this.dataTable.splice(
        this.dataTable.findIndex(c => c.Id === data.Id),
        1
      );
      this.pagerInfo.totalCount = this.dataTable.length;
    }
  }
};
</script>
