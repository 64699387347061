<template>
  <div>
    <div class="border-image-pesudo"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.border-image-clip-path {
  position: relative;
  width: 200px;
  height: 200px;
  border: 10px solid;
  border-image: linear-gradient(45deg, gold, deeppink) 1;
  clip-path: inset(0 round 50%);
  background-image: url("../style/images/defaultImage.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.border-image-pesudo {
  position: relative;
  width: 200px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.border-image-pesudo::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid;
  border-image: linear-gradient(45deg, gold, deeppink) 1;
}
</style>
