<template>
  <div>
    <el-table
      v-loading="isLoading"
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 100%;"
    >
      <el-table-column
        fixed
        prop="PXH"
        label="排序"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        fixed
        prop="ZBMC"
        label="指标名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="SSWDStr" label="维度" show-overflow-tooltip>
        <template slot-scope="scope">
          <span> {{ scope.row.SSWD | handleSSWDlabel(dimensions) }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="XXGS"
        label="星星数量"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="XXFS"
        label="每颗星分数"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="ZBZGF"
        label="指标满星数"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="BZ"
        label="备注"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-popover
            trigger="click"
            width="160"
            placement="top"
            :ref="'popover-' + scope.row.Id"
            style="margin-left: 10px;"
          >
            <p v-if="scope.row.FJId.length > 0" style="text-align: center;">
              确定删除吗？
            </p>
            <p v-else style="text-align: center;">
              该操作会将当前指标下的所有子指标一并删除，确定删除吗？
            </p>
            <div style="text-align: center; margin: 0;">
              <el-button
                size="mini"
                type="text"
                @click="closepopover(scope.row.Id)"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="delItem(scope.row)"
                >确定</el-button
              >
            </div>
            <el-button
              slot="reference"
              type="text"
              size="small"
              class="tab-wz-butta c-red"
              >删除</el-button
            >
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    dimensions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    handleSSWDlabel(sswd, dimensions) {
      let wdStr = "暂无";
      if (sswd) {
        let res = {};
        dimensions.find(item => {
          if (item.value === sswd) return (res = item);
          else {
            if (item.children) {
              let aa = item.children.find(it => it.value === sswd);
              if (aa) return (res = aa);
            }
          }
        });
        if (res) wdStr = res.label;
      }
      return wdStr;
    }
  },
  methods: {
    editItem(data) {
      this.$emit("editData", data);
    },
    delItem(data) {
      this.closepopover(data.Id);
      this.$emit("delItem", data);
    },
    closepopover(id) {
      this.$refs["popover-" + id].doClose();
    }
  }
};
</script>
