<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + exportType">
			<div :class="'basetitle' + exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + exportType">
				<div style="max-height: 950px;overflow: hidden;">
					<div v-for="(mode,index) in Tearcher" :key="index">
						<el-row v-if="Tearcher != null">
							<el-col :span="3">
								<img :src="xsinfo.ZP" :class="'usericon' + exportType" />
							</el-col>
							<el-col :span="21" :class="'commenttitle' + exportType">{{mode.Name}}</el-col>
						</el-row>
						<el-row v-if="Tearcher != null">
							<el-col :span="24" class="teachercommentback" :class="'comment' +exportType"
								v-for="(pjnr,index) in mode.NRList" :key="index">{{ pjnr }}
								<div style="padding: 5px 0px;"></div></el-col>
						</el-row>
					</div>
				</div>
				<!-- <el-row v-if="ClassMate != null">
					<el-col :span="3">
						<img :src="ClassMate.PJRTX" :class="'usericon' + this.exportType" />
					</el-col>
					<el-col :span="21" :class="'commenttitle' + this.exportType">同学对我说</el-col>
				</el-row>
				<el-row v-if="ClassMate != null">
					<el-col :span="24" class="classmatecommentback"
						:class="'comment' + +this.exportType">{{ ClassMate.PJNR }}</el-col>
				</el-row>
				<el-row v-if="Self != null">
					<el-col :span="3">
						<img :src="xsinfo.ZP" :class="'usericon' + this.exportType" />
					</el-col>
					<el-col :span="21" :class="'commenttitle' + this.exportType">我对自己说</el-col>
				</el-row>
				<el-row v-if="Self != null">
					<el-col :span="24" class="selfcommentback"
						:class="'comment' + +this.exportType">{{ Self.PJNR }}</el-col>
				</el-row> -->
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					logo: require("../../../../style/growth/coverlogo.png"),
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "成长对话"
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1
				},
				Tearcher: null,
				Self: null,
				ClassMate: null
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				// this.xsinfo.SSNJ = xsdata.SSNJ;
				// this.xsinfo.NJ = xsdata.NJ;
				if (this.xsinfo.ZP == null || this.xsinfo.ZP == undefined || this.xsinfo.ZP == "") {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}

				var template = JSON.parse(localStorage.getItem("schooltemplate_" + this.xqid));
				// console.log(template, 'template')
				let czdh_data = JSON.parse(xsdata.CZDHData);
				// console.log(czdh_data, 'czdh_data')
				this.Tearcher = [];
				if (template.CZDH.length > 0) {
					// console.log(template.CZDH)
					for (let i = 0; i < czdh_data.length; i++) {
						// if (this.Tearcher.length < 3) {
							let czdh_temp = template.CZDH.find(o => o.Id == czdh_data[i].Id);
							// console.log(czdh_data[i].Id)
							// console.log(czdh_temp)
							if (czdh_temp) {
								let temp = this.Tearcher.find(o => o.Name == czdh_temp.Name);
								if (temp) {
									// if (temp.NRList.length < 1) {
										temp.NRList.push(czdh_data[i].Name);
									// }
								} else {
									// console.log(czdh_data[i])
									let obj = new Object();
									obj.Name = czdh_temp.Name;
									obj.NRList = [czdh_data[i].Name];
									this.Tearcher.push(obj);
								}
							}
						// }
					}
				}
				// else {
				// 	for (let i = 0; i < czdh_data.length; i++) {
				// 		let temp = this.Tearcher.find(o => o.Name == '我对自己说？');
				// 		if (temp) {
				// 			temp.NRList.push(czdh_data[i].Name);
				// 		} else {
				// 			let obj = new Object();
				// 			obj.Name = '我对自己说？';
				// 			obj.NRList = [czdh_data[i].Name];
				// 			this.Tearcher.push(obj);
				// 		}
				// 	}
				// 	console.log(this.Tearcher)
				// }



				// this.getxsreportdata();
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthfootprint/GetCZZJByStudent", {
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xqid,
						XSId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var templisst = r.Target;
							if (
								templisst != null &&
								templisst != undefined &&
								templisst.length > 0
							) {
								var jscoment = templisst.filter(c => c.PJRLX == 1)[0];
								if (jscoment != null && jscoment != undefined) {
									this.Tearcher = jscoment;
								}
								var classmatecoment = templisst.filter(c => c.PJRLX == 2)[0];
								if (classmatecoment != null && classmatecoment != undefined) {
									this.ClassMate = classmatecoment;
								}
								var selfcoment = templisst.filter(c => c.PJRLX == 3)[0];
								if (selfcoment != null && selfcoment != undefined) {
									this.Self = selfcoment;
								}
							}
						}
					}
				);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #f1833a;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 80px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #f1833a;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/radius_dotted.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 30px 50px 0px 50px;
		height: 980px;
		width: 740px;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/radius_dotted.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 20px 25px 0px 25px;
		height: 705px;
		width: 555px;
	}

	.usericon1 {
		width: 65px;
		height: 65px;
	}

	.usericon2 {
		width: 40px;
		height: 40px;
	}

	.comment1 {
		width: 756px;
		// height: 240px;
		font-size: 28px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		padding: 10px 20px;
		color: #080808;
		line-height: 35px;
	}

	.comment2 {
		width: 555px;
		// height: 183px;
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		padding: 13px 13px;
		color: #080808;
		line-height: 25px;
	}

	.commenttitle1 {
		height: 30px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #080808;
		line-height: 65px;
	}

	.commenttitle2 {
		height: 40px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #080808;
		line-height: 40px;
	}

	.teachercommentback {
		background-image: url(../../../../style/growth/teacher_comment_back.png);
		background-repeat: no-repeat;
		background-size: 740px 100%;
	}

	.classmatecommentback {
		background-image: url(../../../../style/growth/classmate_comment_back.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.selfcommentback {
		background-image: url(../../../../style/growth/self_comment_back.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
</style>