<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="tab-heng-box margno-t">
        <!--列表-->
        <div class="table-er-box">
          <el-button plain icon="el-icon-edit" @click="addschool"
            >新增</el-button
          >
        </div>
        <el-table
          :data="tableData"
          class="jf-table"
          border
          tooltip-effect="dark"
          style="width: 100%;"
        >
          <el-table-column
            prop="XXMC"
            label="学校名称"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="XXBM"
            label="学校编码"
            width="150"
          ></el-table-column>
          <el-table-column prop="SFXSBM" label="别名" width="150">
            <template slot-scope="scope">
              <p v-if="scope.row.SFXSBM == '1'">启用</p>
              <p v-else-if="scope.row.SFXSBM == '2'">禁用</p>
            </template>
          </el-table-column>
          <el-table-column prop="XXXZ" label="是否有小学段" width="150">
            <template slot-scope="scope">
              <p v-if="scope.row.XXXZ == '1'">有</p>
              <p v-else-if="scope.row.XXXZ == '2'">无</p>
            </template>
          </el-table-column>
          <el-table-column prop="CZXZ" label="是否有中学段" width="150">
            <template slot-scope="scope">
              <p v-if="scope.row.CZXZ == '1'">有</p>
              <p v-else-if="scope.row.CZXZ == '2'">无</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="editRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteRow(scope.$index, scope.row)"
                >删除</el-button
              >

              <el-button
                type="text"
                size="mini"
                @click="setmenu(scope.$index, scope.row)"
                >功能模块</el-button
              >
              <!-- <el-button type="text" size="mini" @click>设置</el-button> -->
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="rowcount"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="addtitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="QYXXInfo" :rules="rules" ref="sbmitfrom">
        <el-form-item
          label="学校名称"
          :label-width="formLabelWidth"
          prop="XXMC"
        >
          <el-input v-model="QYXXInfo.XXMC" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item
          label="学校编码"
          :label-width="formLabelWidth"
          prop="XXBM"
        >
          <el-input v-model="QYXXInfo.XXBM" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item
          label="学校人数"
          :label-width="formLabelWidth"
          prop="XXRS"
        >
          <el-input v-model="QYXXInfo.XXRS" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label="教师账号" :label-width="formLabelWidth">
          <el-checkbox
            v-model="QYXXInfo.JSZHBM"
            :true-label="1"
            :false-label="2"
            >学校编码</el-checkbox
          >&nbsp;&nbsp;+自增编号
        </el-form-item>
        <el-form-item label="家长账号" :label-width="formLabelWidth">
          <el-checkbox
            v-model="QYXXInfo.JZZHBM"
            :true-label="1"
            :false-label="2"
            >学校编码</el-checkbox
          >&nbsp;&nbsp;+入学班级&nbsp;&nbsp;+学号
        </el-form-item>
        <el-form-item label="学生账号" :label-width="formLabelWidth">
          <el-checkbox
            v-model="QYXXInfo.XSZHQZ"
            :true-label="1"
            :false-label="2"
          ></el-checkbox>
          <el-input
            v-model="QYXXInfo.XSZHQZBM"
            autocomplete="off"
            style="width: 60px;"
          ></el-input
          >&nbsp;&nbsp;+
          <el-checkbox
            v-model="QYXXInfo.XSZHBM"
            :true-label="1"
            :false-label="2"
            >学校编码</el-checkbox
          >&nbsp;&nbsp;+入学年级班级号&nbsp;&nbsp;+学号
        </el-form-item>

        <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
          <el-input v-model="QYXXInfo.PXH" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label :label-width="formLabelWidth">
          <el-checkbox
            v-model="QYXXInfo.SFXSBM"
            :true-label="1"
            :false-label="2"
            >显示年级别名</el-checkbox
          >
        </el-form-item>
        <el-form-item
          label="联系电话"
          :label-width="formLabelWidth"
          prop="LXDH"
        >
          <el-input v-model="QYXXInfo.LXDH" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item
          label="学校地址"
          :label-width="formLabelWidth"
          prop="XXDZ"
        >
          <el-input v-model="QYXXInfo.XXDZ" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item
          label="是否有中学段"
          :label-width="formLabelWidth"
          prop="CZXZ"
        >
          <el-radio v-model="QYXXInfo.CZXZ" :label="1" autocomplete="on"
            >有</el-radio
          >
          <el-radio v-model="QYXXInfo.CZXZ" :label="2" autocomplete="on"
            >没有</el-radio
          >
        </el-form-item>
        <el-form-item
          label="是否有小学段"
          :label-width="formLabelWidth"
          prop="XXXZ"
        >
          <el-radio v-model="QYXXInfo.XXXZ" :label="1" autocomplete="on"
            >有</el-radio
          >
          <el-radio v-model="QYXXInfo.XXXZ" :label="2" autocomplete="on"
            >没有</el-radio
          >
        </el-form-item>
        <el-form-item
          label="系统名称"
          :label-width="formLabelWidth"
          prop="XTMC"
        >
          <el-input v-model="QYXXInfo.XTMC" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label="logo" :label-width="formLabelWidth">
          <div style="width: 80%;">
            <Upload
              @getResultUrl="getUploadLogo"
              :left="0"
              :width="100"
              :height="100"
              :itemPic="QYXXInfo.XXTB ? QYXXInfo.XXTB : ''"
            />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitshowdata">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isInteger, validatePhoneTwo } from "@/style/js/Validator.js";
import Upload from "@/components/Upload.vue";
export default {
  components: {
    // SchoolPower
    Upload
  },
  name: "SchoolList",
  data() {
    return {
      token: this.$store.state.token,
      // loading:true,
      tableData: [],
      addtitle: "新增",
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      QYXXInfo: {
        Id: "",
        XXMC: "",
        XXBM: "",
        XXRS: 0,
        PXH: 0,
        QYId: "",
        XXXTDZ: "",
        CJR: "",
        CJSJ: "2019-10-08 01:18:31",
        SFSC: 0,
        SYZT: 0,
        XXTB: "",
        LXDH: "",
        XXDZ: "",
        CZXZ: 0,
        XXXZ: 0,
        JSZHBM: 1,
        JZZHBM: 1,
        XSZHBM: 1,
        XSZHQZ: 0,
        XSZHQZBM: "",
        SFXSBM: 0,
        HCZT: 0,
        XTMC: ""
      },
      loadstate: true,
      props: {
        label: "name",
        children: "subnodeList"
      },
      rules: {
        XXMC: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "blur"
          },
          { min: 0, max: 50, message: "不能超过50个字", trigger: "change" }
        ],
        XTMC: [
          { min: 0, max: 50, message: "不能超过50个字", trigger: "change" }
        ],
        XXBM: [
          {
            required: true,
            message: "请输入学校编码",
            trigger: "blur"
          }
        ],
        XXRS: [
          { required: true, message: "请输入学校人数", trigger: "blur" },
          {
            validator: isInteger,
            message: "人数必须为整数",
            trigger: "blur"
          }
        ],
        PXH: [
          { required: true, message: "请输入排序号", trigger: "blur" },
          {
            validator: isInteger,
            message: "排序号必须为整数",
            trigger: "blur"
          }
        ],
        LXDH: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur"
          },
          {
            validator: validatePhoneTwo,
            message: "联系电话格式错误",
            trigger: "blur"
          }
        ],
        XXDZ: [
          {
            required: true,
            message: "请输入学校地址",
            trigger: "blur"
          }
        ],
        CZXZ: [
          {
            required: true,
            message: "请选择中学段",
            trigger: "blur"
          },
          {
            validator: isInteger,
            message: "请选择中学段",
            trigger: "blur"
          }
        ],
        XXXZ: [
          {
            required: true,
            message: "请选择小学段",
            trigger: "blur"
          },
          {
            validator: isInteger,
            message: "请选择小学段",
            trigger: "blur"
          }
        ]
      },
      userName: "",
      userId: ""
    };
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.QYXXInfo.CJR = userinfo.Id;

    this.userName = userinfo.DLM;
    this.userId = userinfo.Id;
    this.QYXXInfo.QYId = userinfo.SSZZId;
    const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };
    this.$api.post(
      "v1/areaschool/GetAreaSchoolList",
      {
        AreaId: this.QYXXInfo.QYId,
        PagerInfo: pagerInfo,
        token: this.token
      },
      r => {
        if (r.Code === 0) {
          this.tableData = r.Target;

          this.currentPage = r.PagerInfo.PageIndex;
          this.pagesize = r.PagerInfo.PageSize;
          this.rowcount = r.PagerInfo.RowCount;
        }
      }
    );
  },
  methods: {
    // 上传图片路径
    getUploadLogo(filePath) {
      this.QYXXInfo.XXTB = filePath;
    },
    // 翻页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);

      var pagerInfo = { pageIndex: 0, pageSize: val };

      this.$api.post(
        "v1/areaschool/GetAreaSchoolList",
        { AreaId: this.QYXXInfo.QYId, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
          }
        }
      );
    },
    // 翻页查询
    handleCurrentChange(val) {
      // console.log(`当前页1: ${val}`);

      var pagerInfo = { pageIndex: val, pageSize: this.pagesize };

      this.$api.post(
        "v1/areaschool/GetAreaSchoolList",
        { AreaId: this.QYXXInfo.QYId, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
          }
        }
      );
    },

    handleCheckAllChange: function() {},
    // 刷新列表
    reflist: function() {
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };

      this.$api.post(
        "v1/areaschool/GetAreaSchoolList",
        { AreaId: this.QYXXInfo.QYId, PagerInfo: pagerInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
          }
        }
      );
    },
    // 新增学校
    addschool: function() {
      if (this.QYXXInfo.QYId === "") {
        this.$message({
          type: "info",
          message: "区域管理员信息不全"
        });
        return;
      }
      if (this.QYXXInfo.QYId.length <= 6) {
        this.$message({
          type: "info",
          message: "该区域不能新增"
        });
        return;
      }

      this.addtitle = "新增";

      var areaId = this.QYXXInfo.QYId;
      var cjr = this.QYXXInfo.CJR;

      this.QYXXInfo = {
        Id: "",
        XXMC: "",
        XXBM: "",
        XXRS: 0,
        PXH: 0,
        QYId: areaId,
        XXXTDZ: "",
        CJR: cjr,
        CJSJ: "2019-10-08 01:18:31",
        SFSC: 0,
        SYZT: 0,
        XXTB: "",
        LXDH: "",
        XXDZ: "",
        CZXZ: 0,
        XXXZ: 0,
        JSZHBM: 0,
        JZZHBM: 0,
        XSZHBM: 0,
        XSZHQZ: 0,
        XSZHQZBM: "",
        SFXSBM: 0,
        HCZT: 0,
        XTMC: ""
      };

      this.dialogFormVisible = true;

      this.validatefileds();
    },

    // 修改
    editRow: function(index, row) {
      this.addtitle = "修改";
      // this.QYXXInfo = row;
      this.dialogFormVisible = true;

      this.QYXXInfo = {
        Id: row.Id,
        XXMC: row.XXMC,
        XXBM: row.XXBM,
        XXRS: row.XXRS,
        PXH: row.PXH,
        QYId: row.QYId,
        XXXTDZ: row.XXXTDZ,
        CJR: row.CJR,
        CJSJ: row.CJSJ,
        SFSC: row.SFSC,
        SYZT: row.SYZT,
        XXTB: row.XXTB,
        LXDH: row.LXDH,
        XXDZ: row.XXDZ,
        CZXZ: row.CZXZ,
        XXXZ: row.XXXZ,
        XTMC: row.XTMC,
        JSZHBM: row.JSZHBM,
        JZZHBM: row.JZZHBM,
        XSZHBM: row.XSZHBM,
        XSZHQZ: row.XSZHQZ,
        XSZHQZBM: row.XSZHQZBM,
        SFXSBM: row.SFXSBM,
        HCZT: row.HCZT
      };

      this.validatefileds();
    },
    // 删除
    deleteRow: function(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/areaschool/DeleteAreaSchool",
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });

                this.reflist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 设置菜单
    setmenu: function(index, row) {
      this.$router.push({
        name: "SchoolPower",
        params: { Id: row.Id, XXBM: row.XXBM }
      });
    },

    // 重置校验结果
    validatefileds: function() {
      //setTimeout(() => {
      //  this.$refs.sbmitfrom.resetFields();
      //}, 100);
      if (this.$refs.sbmitfrom) {
        this.$refs.sbmitfrom.resetFields();
      }
    },

    // 保存
    submitshowdata: function(event) {
      event.preventDefault();

      if (this.QYXXInfo.QYId === "") {
        this.$notify({
          type: "info",
          message: "区域管理员信息不全",
          duration: 3000
        });

        return;
      }
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.$api.post(
            "v1/areaschool/AddAreaSchool",
            {
              QYXXInfo: this.QYXXInfo,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.dialogFormVisible = false;

                this.$notify({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });

                this.reflist();

                var schoolinforesult = r.Target;

                if (schoolinforesult !== null) {
                  sessionStorage.setItem(
                    "schoolinfo",
                    JSON.stringify(schoolinforesult)
                  );
                }
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style>
.customWidth {
  width: 45%;
}

.cell {
  text-align: left;
}
</style>
