export function sha1(s) {
  const data = new Uint8Array(encodeUTF8(s));
  let i, j, t;
  const l = (((data.length + 8) >>> 6) << 4) + 16;
  s = new Uint8Array(l << 2);
  s.set(new Uint8Array(data.buffer));
  s = new Uint32Array(s.buffer);
  for (t = new DataView(s.buffer), i = 0; i < l; i++)
    s[i] = t.getUint32(i << 2);
  s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8);
  s[l - 1] = data.length << 3;
  const w = [];
  const f = [
    function() {
      return (m[1] & m[2]) | (~m[1] & m[3]);
    },
    function() {
      return m[1] ^ m[2] ^ m[3];
    },
    function() {
      return (m[1] & m[2]) | (m[1] & m[3]) | (m[2] & m[3]);
    },
    function() {
      return m[1] ^ m[2] ^ m[3];
    }
  ];
  const rol = function(n, c) {
    return (n << c) | (n >>> (32 - c));
  };
  const k = [1518500249, 1859775393, -1894007588, -899497514];
  const m = [1732584193, -271733879, null, null, -1009589776];
  m[2] = ~m[0];
  m[3] = ~m[1];
  for (i = 0; i < s.length; i += 16) {
    const o = m.slice(0);
    for (j = 0; j < 80; j++) {
      w[j] =
        j < 16 ? s[i + j] : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1);
      t =
        (rol(m[0], 5) + f[(j / 20) | 0]() + m[4] + w[j] + k[(j / 20) | 0]) | 0;
      m[1] = rol(m[1], 30);
      m.pop();
      m.unshift(t);
    }
    for (j = 0; j < 5; j++) m[j] = (m[j] + o[j]) | 0;
  }
  t = new DataView(new Uint32Array(m).buffer);
  for (let i = 0; i < 5; i++) m[i] = t.getUint32(i << 2);

  var hex = Array.prototype.map
    .call(new Uint8Array(new Uint32Array(m).buffer), function(e) {
      return (e < 16 ? "0" : "") + e.toString(16);
    })
    .join("");
  return hex;
}

function encodeUTF8(s) {
  let i;
  const r = [];
  let c;
  let x;
  for (i = 0; i < s.length; i++) {
    if ((c = s.charCodeAt(i)) < 0x80) r.push(c);
    else if (c < 0x800) r.push(0xc0 + ((c >> 6) & 0x1f), 0x80 + (c & 0x3f));
    else {
      if ((x = c ^ 0xd800) >> 10 === 0) {
        c = (x << 10) + (s.charCodeAt(++i) ^ 0xdc00) + 0x10000;
        r.push(0xf0 + ((c >> 18) & 0x7), 0x80 + ((c >> 12) & 0x3f));
      } else {
        r.push(0xe0 + ((c >> 12) & 0xf));
      }
      r.push(0x80 + ((c >> 6) & 0x3f), 0x80 + (c & 0x3f));
    }
  }
  return r;
}

// 处理学段
export  function handle_SSXD(SSXD) {
  let result = []
  switch(SSXD) {
    case 1:
      result=[1]
      break;
    case 2:
      result=[2]
      break;
    case 3:
      result=[1,2]
      break;
    case 4:
      result=[4]
      break;
    case 5:
      result=[1,4]
      break;
    case 6:
      result=[2,4]
      break;
    case 7:
      result=[1,2,4]
      break;
  }
  return result
}
// 处理学段2
export  function handle_SSXD2(SSXD) {
let sum = 0  
if(typeof SSXD === 'number') 
  sum = SSXD
else {
  sum = SSXD.reduce((pre,cur,index) => {
      if(index === 0 && cur ===1) pre+=1
      if(index === 1 && cur ===1) pre+=2
      if(index === 2 && cur ===1) pre+=4
      return pre
    },0)
}
  let result = ''
  switch(sum) {
    case 1:
      result='小学'
      break;
    case 2:
      result='初中'
      break;
    case 3:
      result='小学|初中'
      break;
    case 4:
      result='高中'
      break;
    case 5:
      result='小学|高中'
      break;
    case 6:
      result='初中|高中'
      break;
    case 7:
      result='小学|初中|高中'
      break;
  }
  return result
}
// 处理学段3
export  function handle_SSXD3(schoolinfo) {
  let result = []
  let key = ['XXXZ', 'CZXZ', '', 'GZXD']
  let label = ['小学', '初中', '', '高中']
  key.forEach((it, index) => {
    if (schoolinfo[it] === 1) {
      result.push({
        Id: index + 1,
        Name: label[index]
      })
    }
  })
  return result
}



