<template>
  <div style="padding: 40px;">
    <div class="cont-whole-box">
      <div style="padding: 30px;">
        <div class="essay-name-box">{{ hdmc }}-{{ subname }}</div>
      </div>
      <div class="tab-heng-box margno-t">
        <div class="table-er-box" style="display: block;">
          <div class="table-er-butt" style="border: none;">
            年级：
            <el-select
              @change="handleGradeChange"
              style="width: 100%;"
              v-model="njid"
              placeholder="请选择年级"
            >
              <el-option value="" label="全部" key=""> </el-option>
              <el-option
                v-for="nj in njlist"
                :label="nj.Name"
                :key="nj.Id"
                :value="nj.Id"
              >
              </el-option>
            </el-select>
            <div
              class="table-er-butt"
              style="margin-left: 1rem;"
              @click="downloadclassreport()"
            >
              <span class="table-er-buttspan c-blue">下载</span>
            </div>
          </div>
          <div
            style="
              font-size: 14px;
              margin-top: 10px;
              margin-right: 10px;
              display: block;
              float: right;
            "
          ></div>
        </div>
        <el-table
          border
          empty-text="暂无数据"
          :data="reportlist"
          highlight-current-row
          style="width: 100%;"
        >
          <el-table-column align="center" label="班级" property="BJMC">
            <template slot-scope="scope">
              <span
                class="c-blue"
                v-if="scope.row.AnswerCount > 0"
                @click="handleDetailReport(scope.row.BJId, scope.row.BJMC)"
                >{{ scope.row.BJMC }}</span
              >
              <span v-else>{{ scope.row.BJMC }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="平均得分" property="Score">
          </el-table-column>
          <el-table-column
            align="center"
            label="应调人数"
            property="NeedAnswerCount"
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="实答人数"
            property="AnswerCount"
          >
          </el-table-column>
        </el-table>
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="handleCurrentChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  created() {
    this.ssxq = this.$route.params.xqid;
    var tempuser = localStorage.getItem("userinfo");
    this.dcid = this.$route.params.id;
    this.hdmc = localStorage.getItem("surveyname");
    this.subname = localStorage.getItem("surveysubname");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
  },
  computed: {},
  mounted: function() {
    this.loadnjdata();
    this.loaddata();
  },
  methods: {
    downloadclassreport: function() {
      this.$api.post(
        "v1/survey/DownloadClassReport",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          OrgId: this.njid,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            window.open(r.Message, "_blank");
          } else {
            this.$message({
              type: "info",
              message: r.Message
            });
          }
        }
      );
    },
    handleGradeChange: function() {
      this.pagerInfo.pageIndex = 1;
      this.loaddata();
    },
    handleDetailReport: function(bjid, bjmc) {
      localStorage.setItem("classsurveysubname", this.subname + "-" + bjmc);
      this.$router.push({
        name: "classreportdetail",
        params: { id: this.dcid, bjid: bjid, xqid: this.ssxq }
      });
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    handleClassChange: function() {
      this.pagerInfo.pageIndex = 1;
      this.loaddata();
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolOrg",
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.njlist = [];
            var list = r.Target;
            for (var i = 0; i < list.length; i++) {
              if (list[i].Hierarchy === 1) {
                this.njlist.push(list[i]);
              }
            }
          }
        }
      );
    },
    loaddata: function() {
      this.$api.post(
        "v1/survey/GetClassReport",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          OrgId: this.njid,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.reportlist = [];
            this.reportlist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    }
  },
  data() {
    return {
      ssxx: "",
      ssxq: "",
      token: "",
      hdmc: "",
      subname: "",
      reportlist: [],
      njlist: [],
      dcid: "",
      njid: "",
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      }
    };
  }
};
</script>
