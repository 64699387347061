<template>
  <!-- <div> -->

  <el-table :data="tableData"  style="margin-top: 10px;">
      <el-table-column  width="70" prop="xh" label="序号"></el-table-column>
      <el-table-column v-for="(item,index) in heda" :key="index" :prop="item.prop"  :label="item.label"
       :sortable="index == heda.length-1? true:false">
        <el-table-column v-for="(ch,ind) in item.children" :key="ind" :prop="ch.prop" :label="ch.label">
        </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    heda: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    pageSubtitle: String
  },

  created () {
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    this.DevelopmentMB = JSON.parse(localStorage.getItem("DevelopmentMB"));
  },
  mounted () {
    // this.handleTableRow()
  },
  data () {
    return {
      dialog1: false,
      dialog2: false,
      tableRow: {},
      user: '',
      DevelopmentMB: {},
    }
  },
  methods: {
    handleSelectionChange (selection) {
      this.$emit('handleSelectionChange', selection)
    },
    // handleTableRow () {
    //   console.log(this.tableData, 'this.tableData');
    //   if (this.tableData) {
    //     console.log(this.tableData, 'this.tableData');
    //   }
    // }
  }
}
</script>

<style>
.box {
  display: flex;
  flex-direction: row;
}
.fz {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  padding: 20px;
}
.ch {
  display: flex;
  flex-direction: row;
}
.ch-item {
  border: 1px solid #000;
}
</style>