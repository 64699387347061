<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="35%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="tab-heng-box">
      <div class="table-er-box">
        <span class="ts-span-sm">
          等级名称 :
          <el-input
            v-model="formData.DJMC"
            style="width: 160px;"
            size="small"
            placeholder="请输入"
          ></el-input>
        </span>
      </div>
      <table cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <td v-if="formData.DJLX === 2">分数区间</td>
            <td>等级</td>
            <td v-if="formData.DJLX === 1">分数值</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in details" :key="index">
            <td v-if="formData.DJLX === 2">
              <el-input
                v-model="item.ZDF"
                size="small"
                type="number"
                style="width: 60px;"
                placeholder="请输入"
              ></el-input>
              <div class="disilb-box-ll">～</div>
              <el-input
                v-model="item.ZGF"
                size="small"
                type="number"
                style="width: 60px;"
                placeholder="请输入"
              ></el-input>
            </td>
            <td>
              <el-input
                v-model="item.DJ"
                size="small"
                style="width: 160px;"
                placeholder="请输入"
              ></el-input>
            </td>
            <td v-if="formData.DJLX === 1">
              <el-input
                v-model="item.ZDF"
                type="number"
                size="small"
                style="width: 160px;"
                placeholder="请输入"
              ></el-input>
            </td>
            <td>
              <div class="tab-wz-butt">
                <el-link
                  style="margin-left: 10px;"
                  class="c-red"
                  :underline="false"
                  @click="delItem(index)"
                  >删除</el-link
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="bigtit-add-box">
        <div class="tab-wz-buttl">
          <el-link type="primary" :underline="false" @click="addItem"
            >+ 新增</el-link
          >
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      labelWidth: "250px",
      rules: {},
      formData: {},
      details: []
    };
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    area: {
      type: String,
      default: ""
    },
    dataList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    init() {
      this.formData = JSON.parse(JSON.stringify(this.data));
      this.details = JSON.parse(JSON.stringify(this.dataList));
    },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeDialog");
      });
    },
    confirmDialog() {
      this.details = this.details.map(item => {
        item.ZDF = parseFloat(item.ZDF);
        item.ZGF = isNaN(parseFloat(item.ZGF)) ? 0 : parseFloat(item.ZGF);
        return item;
      });
      this.$api.post(
        "/v1/levelmanager/EditLeaveSet",
        {
          token: this.$store.state.token,
          LevelInfo: this.formData,
          DetailList: this.details,
          SchoolId: this.area,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.$emit("saveDialog");
          }
        }
      );
    },
    addItem() {
      this.details.push({ ZDF: "", ZGF: "", DJ: "" });
    },
    delItem(index) {
      this.details.splice(index, 1);
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        // 隐藏
      }
    }
  }
};
</script>
