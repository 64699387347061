<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="70%"
    top="1%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form :model="formData" :rules="rules" ref="formData">
      <div class="quest-titl-box">
        <div class="quest-titl" style="height: 40px;">
          <el-form-item prop="WJMC">
            <el-input
              class="jf-quest-titl"
              v-model="formData.WJMC"
              placeholder="请输入问卷名称"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div
        class="operate-box"
        style="padding-bottom: 10px; margin-top: 10px; font-size: 14px;"
      >
        <el-form-item
          prop="SSWD"
          label="所属维度"
          style="display: inline-block; margin-bottom: unset;width:262px"
        >
          <el-cascader
            :props="{ expandTrigger: 'hover' }"
            v-model="formData.SSWD"
            :options="dimensions"
            :show-all-levels="false"
            placeholder="请选择末级维度"
            @change="handleWdSelected"
            size="small"
            class="jf-form"
            style="width:180px"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          style="display:inline-block;width:105px"
          label="按学科参与"
          prop="CYXK"
        >
          <el-checkbox v-model="formData.CYXK"></el-checkbox>
        </el-form-item>
        <el-form-item
          style="display:inline-block;width:217px;margin-right:10px"
          label=""
          v-show="formData.CYXK"
          prop="CourseIds"
          :rules="
            formData.CYXK
              ? [
                  {
                    required: true,
                    message: '请选择学科',
                    trigger: 'blur'
                  }
                ]
              : []
          "
        >
          <el-select
            v-model="formData.CourseIds"
            multiple
            size="small"
            placeholder="请选择学科"
          >
            <el-option
              v-for="item in XKoptions"
              :key="item.Id"
              :label="item.KCMC"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-upload
          style="display:inline-block"
          class="upload-demo"
          ref="upload"
          accept=".xls, .xlsx"
          :action="uploadURL"
          :on-change="uploadrfid"
          :show-file-list="false"
          :auto-upload="false"
        >
          <el-button slot="trigger" plain type="primary" size="mini">
            导入
          </el-button>
        </el-upload>
        <a :href="`${publicPath}template/问卷模板.xlsx`" style="margin: 0 10px">
          <el-button plain type="success" size="mini">
            模板下载
          </el-button>
        </a>
        <el-form-item
          style="width:340px;display:inline-block;margin-right:10px"
          label="统一指定答案模板"
        >
          <el-select
            v-model="answerSelect"
            placeholder="统一指定答案模板"
            size="small"
            @change="answerSelectChange"
          >
            <el-option
              v-for="item in answerOption"
              :key="item.Id"
              :label="item.MBMC"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button
          plain
          type="warning"
          size="mini"
          :disabled="
            !formData.WJTMBList ||
              formData.WJTMBList.length === 0 ||
              formData.WJTMBList.every(item => !item.WJFXBList)
          "
          @click="batchFSobj.visible = true"
        >
          批量填充分数
        </el-button>
      </div>
      <el-form-item prop="WJTMBList"></el-form-item>
      <div
        class="whole-tit-mianbox"
        v-for="(item, index) in formData.WJTMBList"
        :key="index"
      >
        <div
          style="font-size: 0px; box-sizing: border-box; padding-bottom: 15px;display:flex;align-items:center"
        >
          <div class="whole-tit-topleft">{{ index + 1 }}.</div>
          <el-form-item
            style="margin-right:10px;margin-bottom:15px;"
            :prop="'WJTMBList[' + index + '].TMMC'"
            :rules="[
              { required: true, message: '请输入题目名称', trigger: 'change' },
              {
                min: 1,
                max: 200,
                message: '不能超过200 个字符',
                trigger: 'change'
              }
            ]"
          >
            <el-input
              type="textarea"
              autosize
              placeholder="请输入不超过100字"
              class="jf-textarea-wj jf-form"
              v-model="item.TMMC"
            ></el-input>
          </el-form-item>

          <el-form-item
            :prop="'WJTMBList[' + index + '].SSLB'"
            :rules="[
              {
                required: true,
                message: '请选择题目类型',
                trigger: 'change'
              }
            ]"
            style="width:80px;margin-bottom:8px"
          >
            <el-select
              v-model="item.SSLB"
              placeholder="请选择"
              size="small"
              class="jf-form"
              style="width: 100%;"
            >
              <el-option
                v-for="item in SSLB"
                :key="item.Key"
                :label="item.Value"
                :value="item.Key"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="titdel-add-box" v-if="item.SSLB === 2">
            <el-form-item
              class="jf_wj_dx form-item-number"
              label="最多"
              :prop="'WJTMBList[' + index + '].JDXX'"
              :rules="[
                {
                  validator: validJDXX,
                  trigger: 'blur'
                }
              ]"
            >
              <el-input-number
                v-model="item.JDXX"
                :min="1"
                :max="9"
              ></el-input-number>
            </el-form-item>
          </div>
          <el-button
            type="danger"
            style="position:relative;top:-4px;margin-left:10px"
            size="mini"
            plain
            @click="delItem(index)"
            >删除此题</el-button
          >
        </div>
        <div>
          <el-form-item
            :prop="'WJTMBList[' + index + '].WJFXBList'"
            :rules="[
              {
                validator: checklength,
                SSLB: item.SSLB,
                message: '缺少答案',
                trigger: 'change'
              }
            ]"
            class="jf_wj_dx"
          >
          </el-form-item>
        </div>
        <div class="smtit-whole-box" v-if="item.SSLB !== 3">
          <div class="smtit-box">
            <div class="smtit-cent" v-for="(fx, i) in item.WJFXBList" :key="i">
              <el-form-item
                :prop="`WJTMBList[${index}].WJFXBList[${i}].TMQZ`"
                :rules="[
                  { required: true, message: '不能为空', trigger: 'blur' }
                ]"
              >
                <el-select
                  size="small"
                  style="width:65px"
                  v-model="fx.TMQZ"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="e in ZM"
                    :key="e.value"
                    :label="e.label"
                    :value="e.value"
                    :disabled="handleZMabled(item.WJFXBList, e.value)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="fx.FXLX === 1"
                :prop="`WJTMBList[${index}].WJFXBList[${i}].TMMC`"
                :rules="[
                  { required: true, message: '请输入答案', trigger: 'change' },
                  {
                    min: 1,
                    max: 1000,
                    message: '不能超过1000个字符',
                    trigger: 'change'
                  }
                ]"
                style="display: inline-block;"
              >
                <el-input
                  v-model="fx.TMMC"
                  size="small"
                  style="width: 300px; margin-left: 5px;"
                  class="jf-form"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <span
                v-else
                style="margin-left: 5px;position: relative;top: -9px;"
                >其他</span
              >
              <span class="text-score">
                分数：
              </span>
              <el-form-item
                :prop="`WJTMBList[${index}].WJFXBList[${i}].DF`"
                :rules="[
                  { required: true, message: '请输入分数', trigger: 'change' },
                  {
                    validator: validateNumber,
                    message: '分数必须为数字',
                    trigger: 'change'
                  }
                ]"
                style="display: inline-block;"
              >
                <el-input
                  v-model="fx.DF"
                  size="small"
                  class="jf-form"
                  style="width: 90px;"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <div class="tab-wz-buttl" style="margin-left: 30px;">
                <a
                  class="tab-wz-butta c-red"
                  href="javascript:;"
                  @click="delFX(item, i)"
                  >删除</a
                >
              </div>
            </div>
          </div>
          <div class="smtit-butt-box" v-if="item.SSLB !== 3">
            <div class="tab-wz-buttl" style="margin-right: 20px;">
              <a
                class="tab-wz-butta c-guree"
                href="javascript:;"
                @click="addFX(item, 1)"
                >+添加普通选项</a
              >
            </div>
            <div class="tab-wz-buttl">
              <a
                v-if="
                  item.WJFXBList && !item.WJFXBList.find(it => it.FXLX === 2)
                "
                class="tab-wz-butta c-guree"
                href="javascript:;"
                @click="addFX(item, 2)"
                >+添加“其他”选项</a
              >
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="bigtit-add-box">
      <div class="tab-wz-buttl">
        <a class="tab-wz-butta c-guree" href="javascript:;" @click="addItem"
          >+ 新增问题</a
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog" :loading="submitting"
        >确 定</el-button
      >
    </span>

    <!-- 批量填充分数弹框 -->
    <el-dialog
      title="填充分数"
      :visible.sync="batchFSobj.visible"
      width="30%"
      :before-close="batchFS"
      append-to-body
    >
      <el-form ref="batchFSobjForm" :model="batchFSobj.form">
        <el-form-item
          v-for="(item, index) in Object.keys(batchFSobj.form)"
          :label="item"
          :prop="item"
          :key="index"
          :rules="[{ validator: batchValChange, trigger: 'change' }]"
          style="display:flex"
        >
          <el-input
            placeholder="请输入自然数或两位小数"
            v-model="batchFSobj.form[item]"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchFS">取 消</el-button>
        <el-button type="primary" @click="batchFS(1)">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { isNumber, checkLength } from '@/style/js/Validator.js'
import XLSX from 'xlsx'
export default {
  data() {
    return {
      validateNumber: isNumber,
      checklength: checkLength,
      submitting: false,
      labelWidth: '250px',
      formData: {},
      SSLB: [
        {
          Key: 1,
          Value: '单选'
        },
        {
          Key: 2,
          Value: '多选'
        },
        {
          Key: 3,
          Value: '问答'
        }
      ],
      ZM: [],
      rules: {
        WJMC: [
          {
            required: true,
            message: '问卷名称不能为空',
            trigger: 'change'
          },
          {
            min: 1,
            max: 20,
            message: '不能超过20 个字符',
            trigger: 'change'
          }
        ],
        SSWD: [
          {
            required: true,
            message: '请选择所属维度',
            trigger: 'change'
          }
        ],
        WJTMBList: [
          {
            required: true,
            message: '请设置题目',
            trigger: 'change'
          }
        ]
      },
      XKoptions: [],
      answerSelect: '',
      answerOption: [],
      // 批量分数相关
      batchFSobj: {
        visible: false,
        form: {
          A: 0,
          B: 0,
          C: 0,
          D: 0
        }
      },
      publicPath: process.env.BASE_URL,
      answerMB: {}
    }
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    dimensions: {
      type: Array,
      default: function() {
        return []
      }
    },
    user: {
      type: Object,
      default: function() {
        return {}
      }
    },
    LX: {
      type: Number
    }
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return (
        '/file/file/fileservice/UploadFile?token=' + this.$store.state.token
      )
    }
  },
  mounted() {
    let arr = []
    for (var i = 0; i < 25; i++) {
      let str = String.fromCharCode(65 + i)
      arr.push({
        label: str,
        value: str
      })
    }
    this.ZM = arr
  },
  methods: {
    init() {
      this.formData = JSON.parse(JSON.stringify(this.data))
      if (!this.formData.Id) {
        this.formData = {}
        this.$set(this.formData, 'WJTMBList', [])
      } else {
        this.formData.CourseIds && this.formData.CourseIds.length > 0
          ? this.$set(this.formData, 'CYXK', true)
          : this.$set(this.formData, 'CYXK', false)
        if (!this.formData.CourseIds) this.formData.CourseIds = []
        this.$forceUpdate()
        this.formData.WJTMBList.sort(this.compare('PXH'))
      }
    },
    closeDialog() {
      this.$confirm('确认关闭？').then(() => {
        this.$emit('closeDialog')
      })
    },
    confirmDialog() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.submitting = true
          this.formData.CourseIds = this.formData.CYXK
            ? this.formData.CourseIds
            : []
          this.formData.SSXX = this.user.SSZZId
          this.formData.WJTMBList.forEach(item => {
            if (item.SSLB === 3) item.WJFXBList = []
          })
          this.formData.WJTMBList.forEach((tm, index) => {
            tm.PXH = index + 1
            if (tm.SSLB !== 3 && tm.WJFXBList) {
              tm.WJFXBList.forEach((da, idx) => (da.PXH = idx + 1))
            }
          })
          this.formData.Lx = this.LX
          this.$api.post(
            '/v1/questionnaire/EditQuestionnaire',
            {
              token: this.$store.state.token,
              Questionnaire: this.formData,
              UserId: this.user.Id
            },
            r => {
              let data = {}
              if (this.formData.Id) {
                data = JSON.parse(JSON.stringify(this.formData))
              }
              if (r.Code === 0) {
                this.$message({
                  type: 'success',
                  message: r.Message
                })
                this.$emit('closeDialog', data)
              }
            }
          )
        } else {
          return false
        }
      })
    },
    handleWdSelected(value) {
      if (value) {
        if (value.length > 1) {
          this.formData.SSWD = value[1]
          const wd = this.dimensions.find(c => c.value === value[0])
          const wdC = wd.children.find(c => c.value === value[1])
          this.formData.SSWDStr = wd.label + '>' + wdC.label
        } else {
          this.formData.SSWD = value[0]
          const wd = this.dimensions.find(c => c.value === value[0])
          this.formData.SSWDStr = wd.label
        }
      }
    },
    addItem() {
      this.formData.WJTMBList.push({
        TMMC: '',
        SSLB: 1,
        WJFXBList: this.answerMB.Id
          ? this.answerMB.WJDAList.map(it => {
              return {
                TMQZ: it.TMQZ,
                TMMC: it.DAMC,
                DF: it.DF,
                FXLX: it.FXLX,
                TMId: ''
              }
            })
          : []
      })
    },
    delItem(index) {
      this.formData.WJTMBList.splice(index, 1)
    },
    addFX(TM, FXLX) {
      console.log(TM, 'TM')
      let obj = {
        TMId: TM.Id || '',
        TMMC: FXLX === 2 ? '其他' : '',
        FXLX: FXLX,
        DF: '',
        TMQZ: ''
      }
      if (!TM.WJFXBList) {
        obj.TMQZ = 'A'
        this.$set(TM, 'WJFXBList', [obj])
      } else {
        let arr = TM.WJFXBList.map(item => item.TMQZ)
        arr.sort((a, b) => a.localeCompare(b))
        let idx = this.ZM.findIndex(item => item.value === arr[arr.length - 1])
        obj.TMQZ = this.ZM[idx + 1].value
        TM.WJFXBList.push(obj)
      }
    },
    delFX(TM, i) {
      TM.WJFXBList = TM.WJFXBList.filter((item, idx) => i !== idx)
    },
    // 校验最多选项
    validJDXX(rule, val, callback) {
      let idx = rule.field.match(/\d+/)
      if (!val) return callback(new Error('数目不能为空'))
      else if (
        this.formData.WJFXBList &&
        val >
          this.formData.WJFXBList.filter(
            item => item.TMId === this.formData.WJTMBList[idx].Id
          ).length
      )
        return callback(new Error('不能超过选项个数'))
      else return callback()
    },
    compare(p) {
      // 这是比较函数
      return function(m, n) {
        var a = m[p]
        var b = n[p]
        return a - b // 升序
      }
    },
    // 批量填充分数
    batchFS(type) {
      if (type === 1) {
        this.$refs.batchFSobjForm.validate(res => {
          if (res) {
            this.formData.WJTMBList.forEach(ele => {
              ele.WJFXBList.forEach((item, idx) => {
                Object.keys(this.batchFSobj.form).forEach((key, index) => {
                  if (idx === index) item.DF = this.batchFSobj.form[key]
                })
              })
            })
            localStorage.setItem(
              'batchFS',
              JSON.stringify(this.batchFSobj.form)
            )
            this.batchFSobj.visible = false
          }
        })
      } else this.batchFSobj.visible = false
    },
    batchValChange(rule, val, callback) {
      let reg = /^([1-9][0-9]?(\.[0-9]{1,2})?)$|^(0(\.0[1-9])?)$|^(0\.[1-9][0-9]?)$|^100$/
      if (val === '' || val === undefined || val === null)
        return callback(new Error('分数不能为空'))
      else if (!reg.test(val))
        return callback(new Error('请输入两位小数或整数'))
      else return callback()
    },
    // 导入
    uploadrfid(file) {
      let files = {
        0: file.raw
      }
      this.readExcel(files)
    },
    readExcel(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, {
          type: 'array'
        })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const res = XLSX.utils.sheet_to_json(worksheet, {
          raw: false
        })
        this.handleExcel(res)
        this.$refs.upload.value = ''
      }
      fileReader.readAsArrayBuffer(files[0])
    },
    handleExcel(excel) {
      let TMarr = [] //题目
      console.log(excel, 'excel')
      excel.forEach(item => {
        let TMobj = {
          WJFXBList: []
        }
        Object.keys(item).forEach((key, index) => {
          if (key === '问题') TMobj.TMMC = item[key]
          if (key.includes('类型')) TMobj.SSLB = Number(item[key])
          if (key.includes('答案') && !key.includes('分数')) {
            TMobj.WJFXBList.push({
              TMQZ: this.ZM[index - 2].value,
              DF: item[`${key}分数`] || 0,
              TMId: '',
              TMMC: item[key],
              FXLX: 1
            })
          }
          if ((key === '其他' || key === '其它') && !key.includes('分数')) {
            TMobj.WJFXBList.push({
              TMQZ: this.ZM[index - 2].value,
              DF: item[`${key}分数`] || 0,
              TMId: '',
              TMMC: '其他',
              FXLX: 2
            })
          }
        })
        TMarr.push(TMobj)
      })

      this.formData.WJTMBList = TMarr
    },
    // 获取学科和答案模板
    getXK_answer() {
      let { SSZZId: SchoolId, Token: token } = JSON.parse(
        localStorage.getItem('userinfo')
      )
      this.$api.post(
        '/v1/xxkcb/GetXXKCBList',
        {
          SchoolId,
          token
        },
        res => {
          if (res.Code === 0) this.XKoptions = res.Target
        }
      )
      this.$api.post(
        'v1/questionnaire/GetQueAnTem',
        {
          SchoolId,
          token,
          PagerInfo: {
            PageIndex: 1,
            PageSize: 100
          }
        },
        res => {
          if (res.Code === 0) {
            if (res.Target && res.Target.length > 0) {
              this.answerOption = res.Target.filter(item => item.SYZT === 1)
              this.answerOption.forEach(item => {
                if (item.WJDAList && item.WJDAList.length > 0) {
                  item.WJDAList.sort(
                    (a, b) => a.TMQZ && b.TMQZ && a.TMQZ.localeCompare(b.TMQZ)
                  )
                }
              })
            }
          }
        }
      )
    },
    answerSelectChange(id) {
      this.answerMB = this.answerOption.find(item => item.Id === id)
      if (!this.formData.WJTMBList) this.$set(this.formData, 'WJTMBList', [])
      if (this.formData.WJTMBList.length === 0) {
        this.formData.WJTMBList.push({
          TMMC: this.answerMB.MBMC
        })
      }
      this.formData.WJTMBList.forEach(tm => {
        tm.TMMC = this.answerMB.MBMC
        this.$set(
          tm,
          'WJFXBList',
          this.answerMB.WJDAList.map(it => {
            return {
              TMQZ: it.TMQZ,
              TMMC: it.DAMC,
              DF: it.DF,
              FXLX: it.FXLX,
              TMId: tm.Id || ''
            }
          })
        )
      })
    },
    handleZMabled(WJFXBList, cur) {
      if (WJFXBList && WJFXBList.length > 0) {
        return WJFXBList.some(fx => fx.TMQZ && fx.TMQZ === cur)
      } else return false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init()
        this.submitting = false
        this.getXK_answer()
        if (localStorage.getItem('batchFS'))
          this.batchFSobj.form = JSON.parse(localStorage.getItem('batchFS'))

        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }

        // 显示
      } else {
        // 隐藏
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.titdel-add-box {
  top: 0 !important;
  display: flex;
  align-items: center;

  .form-item-number {
    display: flex;
    align-items: center;
    width: 165px;
    margin-bottom: 7px;

    ::v-deep .el-form-item__content {
      width: 100px;

      .el-input-number {
        width: 100px;
        line-height: 30px;

        .el-input-number__decrease,
        .el-input-number__increase {
          top: 1px;
          width: 27px;
          height: 27px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .el-input {
          height: 30px;

          .el-input__inner {
            width: 100px;
            height: 30px;
            padding: 0 25px;
          }
        }
      }
    }

    &:after {
      content: '项';
      right: 10px;
    }
  }
}

.smtit-cent {
  display: flex;
  align-items: center;

  .text-score,
  .tab-wz-buttl {
    position: relative;
    top: -11px;
    left: 0;
    width: 50px;
  }
}
</style>
