<template>
  <div class="cont-whole-box">
    <div style="border-bottom: 1px solid #f2f2f2; padding-top: 30px;">
      <div class="top-butt-box">
        <div class="cent-butt whole-col-hov" @click="goto()">计划管理</div>
        <div class="cent-butt whole-col-hov cent-butt-sty whole-col">
          类别管理
        </div>
      </div>
    </div>
    <div class="leftrig-box">
      <div class="tab-heng-box margno-t">
        <div class="table-er-box">
          <div class="table-er-butt" @click="addone()">
            <span class="table-er-buttspan">新增类别</span>
          </div>
        </div>
        <table cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <td style="width: 60px;">序号</td>
              <td>类别名称</td>
              <td>维度</td>
              <td>操作</td>
            </tr>
          </thead>
          <tbody>
            <tr name="datatr" v-for="(lb, key) in lblist" :key="key">
              <td style="width: 60px;">{{ key + 1 }}</td>
              <td>
                <el-input
                  style="width: 300px;"
                  type="text"
                  placeholder="请输入类别名称"
                  v-model="lb.LBMC"
                  v-bind:value="lb.LBMC"
                  maxlength="50"
                  minlength="1"
                  show-word-limit
                ></el-input>
                <input type="hidden" v-model="lb.Id" />
                <input type="hidden" v-model="lb.SSXX" />
                <input type="hidden" v-model="lb.CJR" />
                <input type="hidden" v-model="lb.CJSJ" />
                <input type="hidden" v-model="lb.SFSC" />
              </td>
              <td>
                <el-cascader
                  v-model="lb.SSWD"
                  :props="propsOption"
                  :options="wdlist"
                ></el-cascader>
              </td>
              <td>
                <el-button
                  icon="el-icon-delete"
                  @click="handleDelete(lb.Id, key)"
                  type="danger"
                  plain
                  >删除</el-button
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="cent-bott-butt">
        <el-button @click="submitdata()" type="primary">保&nbsp;存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
      this.cjr = user.Id;
    }
  },
  mounted: function() {
    this.loadwddata();
  },
  methods: {
    addone: function() {
      var object = {};
      object.Id = "";
      object.LBMC = "";
      object.SSWD = "";
      object.SSXX = this.ssxx;
      object.CJR = this.cjr;
      object.CJSJ = this.getdate();
      object.SFSC = 2;
      this.lblist.push(object);
    },
    goto: function() {
      this.$router.push("/jxgy");
    },
    getdate: function() {
      var myDate = new Date();
      // 获取当前年
      var year = myDate.getFullYear();
      // 获取当前月
      var month = myDate.getMonth() + 1;
      // 获取当前日
      var date = myDate.getDate();
      var h = myDate.getHours(); // 获取当前小时数(0-23)
      var m = myDate.getMinutes(); // 获取当前分钟数(0-59)
      var s = myDate.getSeconds();
      // 获取当前时间
      var now =
        year +
        "-" +
        this.conver(month) +
        "-" +
        this.conver(date) +
        " " +
        this.conver(h) +
        ":" +
        this.conver(m) +
        ":" +
        this.conver(s);
      return now;
    },
    conver: function(s) {
      return s < 10 ? "0" + s : s;
    },
    submitdata: function() {
      var existsnotinput = false;
      for (var i = 0; i < this.lblist.length; i++) {
        var lbmc = this.lblist[i].LBMC;
        var sswd = this.lblist[i].SSWD;
        if (lbmc.length <= 0 || sswd.length <= 0) {
          existsnotinput = true;
          break;
        }
      }
      if (existsnotinput) {
        this.$message({
          type: "info",
          message: "数据不能为空!",
        });
        return;
      }
      var multiDatas = JSON.stringify(this.lblist);
      this.$api.post(
        "v1/extracurricular/AddLB",
        {
          SSXX: this.ssxx,
          CJR: this.cjr,
          MultiDatas: multiDatas,
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: "保存成功!",
            });
            this.loaddata();
          } else {
            this.$message({
              type: "info",
              message: "保存不成功!",
            });
          }
        }
      );
    },
    messagebox: function(message) {
      this.$alert("提示", message, {
        confirmButtonText: "确定",
      });
    },
    loaddata: function() {
      this.$api.post(
        "v1/extracurricular/GetLBList",
        { SSXX: this.ssxx, token: this.token },
        (r) => {
          if (r.Code === 0 && r.Target && r.Target.length > 0) {
            r.Target.forEach((item) => {
              this.wdlist.some((element) => {
                if (element.children && element.children.length > 0) {
                  return element.children.some((el) => el.value === item.SSWD);
                } else {
                  return element.value === item.SSWD;
                }
              })
                ? ""
                : (item.SSWD = "");
            });
            this.lblist = r.Target;
          }
        }
      );
    },
    loadwddata: function() {
      this.$api.post(
        "v1/dimension/GetDimensionTree",
        { SchoolId: this.ssxx, LX: 1, token: this.token },
        (r) => {
          if (r.Code === 0) {
            this.wdlist = [];
            var datalist = r.Target;
            if (datalist && datalist.length > 0) {
              this.wdlist = datalist;
              this.loaddata();
            }
            return r.Target;
          }
        }
      );
    },
    handleDelete(id, index) {
      if (id && id.length > 0) {
        this.$confirm("确定要删除该类别?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$api.post(
              "v1/extracurricular/DeleteLB",
              { Id: id, SSXX: this.ssxx, YHId: this.cjr, token: this.token },
              (r) => {
                if (r.Code === 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.lblist.splice(index, 1);
                } else {
                  this.$message({
                    type: "info",
                    message: "删除失败!",
                  });
                }
              }
            );
          })
          .catch(() => {});
      } else {
        this.$confirm("确定要删除该类别?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.lblist.splice(index, 1);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
  },
  data() {
    return {
      isLogin: false,
      lblist: [],
      wdlist: [],
      propsOption: {
        emitPath: false,
      },
      ssxx: "",
      cjr: "",
      token: "",
    };
  },
};
</script>
