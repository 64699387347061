<template>
  <div>
    <el-tabs v-model="tabname" type="card" @tab-click="handleClick">
      <el-tab-pane
        v-for="hd in hdlist"
        :key="hd.ActivityId"
        :label="hd.ActivityName"
        :name="hd.ActivityId"
      >
      </el-tab-pane>
      <el-tab-pane
        v-if="kfhdlist.length > 0"
        label="课辅活动"
        name="kfhd"
      ></el-tab-pane>
    </el-tabs>
    <div v-if="tabname != 'kfhd'" style="padding-top: 15px">
      <div v-if="yhzjhd == 1">
        <div class="cz-cont-name">&bull; &nbsp;子活动选择:</div>
        <div class="cz-cont-box">
          <el-radio-group @change="subchange" v-model="hdid">
            <el-radio v-for="sub in subactivity" :key="sub.Id" :label="sub.Id">
              {{ sub.Name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="cz-cont-name">
        &bull; &nbsp;活动对象选择（一次只能选择一个对象）:
      </div>
      <div class="cz-cont-box">
        <el-radio-group @change="pjcydxchange" v-model="dxid">
          <el-radio
            v-for="pjcydx in objectlist"
            :key="pjcydx.EObjectId"
            :label="pjcydx.EObjectId"
          >
            {{ pjcydx.EObjectName }}
          </el-radio>
        </el-radio-group>
      </div>
      <div v-if="jzkblx != 1">
        <div class="cz-cont-name">&bull; &nbsp;课程（单选）:</div>
        <div class="cz-cont-box">
          <el-radio-group @change="coursechange" v-model="coursechecked">
            <el-radio
              v-for="course in courselist"
              :key="course.KCId"
              :label="course"
              :value="course"
              >{{ course.KCMC }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div class="cz-cont-name">
        <el-radio-group v-model="zbtype" @change="zbtypechange">
          <el-radio :label="1">末级指标选择（可以多选）:</el-radio>
          <el-radio :label="2">倒数第二级指标选择（可以多选）:</el-radio>
        </el-radio-group>
      </div>
      <div class="cz-cont-box" style="padding: 0px 10px 10px 10px;">
        <el-checkbox-group
          @change="indexchange"
          v-model="indexcheckedlist"
          style="display: flex;flex-wrap: wrap;"
        >
          <div
            v-for="zb in indexlist"
            :key="zb.Id"
            style="padding: 10px 10px 0px 0"
          >
            <el-checkbox :label="zb" :value="zb">{{ zb.ZBMC }} </el-checkbox>
            <el-input-number
              v-model="zb.num"
              :min="1"
              size="small"
              label="每个指标生成数量"
              style="margin-left: 5px;"
            ></el-input-number>
          </div>
        </el-checkbox-group>
      </div>
    </div>
    <div v-if="tabname == 'kfhd'" style="padding-top: 15px">
      <div class="cz-cont-name">&bull; &nbsp;课辅活动选择:</div>
      <div class="cz-cont-box">
        <el-radio-group @change="kfhdchange" v-model="hdid">
          <el-radio
            v-for="kfhd in kfhdlist"
            :key="kfhd.ActivityId"
            :label="kfhd.ActivityId"
          >
            {{ kfhd.ActivityName }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="cz-cont-name">
        <el-radio-group v-model="zbtype" @change="zbtypechange">
          <el-radio :label="1">末级指标选择（可以多选）:</el-radio>
          <el-radio :label="2">倒数第二级指标选择（可以多选）:</el-radio>
        </el-radio-group>
      </div>
      <div class="cz-cont-box" style="padding: 10px 10px 0 10px;">
        <!-- <el-checkbox-group v-model="indexcheckedlist">
					<el-checkbox v-for="zb in indexlist" :key="zb.Id" :label="zb"
						:value="zb">{{ zb.ZBMC }}</el-checkbox>
				</el-checkbox-group> -->
        <el-checkbox-group
          @change="indexchange"
          v-model="indexcheckedlist"
          style="display: flex;flex-wrap: wrap;"
        >
          <div
            v-for="zb in indexlist"
            :key="zb.Id"
            style="padding-right: 10px;"
          >
            <el-checkbox :label="zb" :value="zb">{{ zb.ZBMC }} </el-checkbox>
            <el-input-number
              v-model="zb.num"
              :min="1"
              size="small"
              label="每个指标生成数量"
              style="margin-left: 5px;"
            ></el-input-number>
          </div>
        </el-checkbox-group>
      </div>
    </div>
    <!-- <div class="cz-cont-name">&bull; &nbsp;生成设置:</div>
		<div class="popo-twoe-main lss-sty">
			<div class="xj-box" style="display:block; float:left;">
				<div class="xj-name">
					<span class="xj-name-span" style="width:170px !important;">数量(每个指标生成数量)：</span>
					<el-input-number v-model="indexcount" :min="1" :max="maxcount" label="每个指标生成数量"></el-input-number>
				</div>
			</div>
			<div style="clear:both;"></div>
		</div> -->
    <div>
      <el-radio-group v-model="sctype">
        <el-radio :label="1"
          >网页生成(二维码大小、宽度及每行个数自定义)</el-radio
        >
        <el-radio :label="2" v-if="isshowtips"
          >使用工具生成(每页一个二维码)</el-radio
        >
      </el-radio-group>
    </div>
    <div class="cent-bott-butt" style="margin-top:10px;">
      <div v-if="sctype == 1">
        <span class="set-span-tips"
          >注：设置了相应属性后可点击预览查看对应格式，设置为0即代表自动排列(默认宽高为100)</span
        >
        <div class="set-div">
          <span class="set-span-ms">二维码宽度：</span>
          <el-input-number
            class="set-input"
            placeholder="单位:像素(px)"
            type="number"
            v-model="width"
            :min="0"
            @change="setwidth"
            :max="800"
            size="mini"
            style="width: 120px;"
          ></el-input-number>
        </div>
        <div class="set-div">
          <span class="set-span-ms">二维码高度：</span>
          <el-input-number
            class="set-input"
            placeholder="单位:像素(px)"
            type="number"
            v-model="height"
            :min="0"
            :disabled="true"
            :max="10000"
            size="mini"
            style="width: 120px"
          ></el-input-number>
        </div>
        <div class="set-div">
          <span class="set-span-ms">每行个数：</span>
          <el-input-number
            class="set-input"
            placeholder="每行个数"
            type="number"
            v-model="num"
            :min="0"
            :max="
              width == 0 ? 6 : parseInt(820 / (width > 770 ? 800 : width + 30))
            "
            size="mini"
            style="width: 120px"
          ></el-input-number>
        </div>

        <br />
        <el-button @click="preview()" type="primary">预&nbsp;览</el-button>
        <el-button @click="submitdata()" type="primary" :disabled="isDisabled"
          >生成二维码</el-button
        >
      </div>
      <div v-if="sctype == 2">
        <el-button @click="savedata()" type="primary" :disabled="isDisabled"
          >保存数据</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.ssxq = localStorage.getItem('currentterm')
    var tempuser = localStorage.getItem('userinfo')
    if (tempuser) {
      var user = JSON.parse(tempuser)
      this.ssxx = user.SSZZId
      this.userid = user.Id
      this.token = user.Token
      if (user.YHLX > 3) {
        this.isshowtips = true
      }
    }
    this.loaddata()
  },
  mounted: function() {},
  methods: {
    // 预览
    preview: function() {
      var data = {
        width: this.width,
        height: this.height,
        num: this.num
      }
      let routeUrl = this.$router.resolve({
        path: '/qrcodepreview',
        query: {
          data: JSON.stringify(data)
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    loaddata: function() {
      this.$api.post(
        'v1/evaluationactivity/GetSchoolQRCodeActivity',
        {
          SchoolId: this.ssxx,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            var list = r.Target
            if (list != null && list != undefined && list.length > 0) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].UserEvalutionActive.HDLX != 2) {
                  if (list[i].ActivityType == 1) {
                    if (
                      list[i].UserEvalutionActive.EvaChildList == null &&
                      list[i].PJFS != 2
                    ) {
                      // 吕姐要求去除主子活动及星星活动
                      this.kfhdlist.push(list[i])
                    }
                  } else if (list[i].ActivityType == 2) {
                    if (
                      list[i].UserEvalutionActive.EvaChildList == null &&
                      list[i].PJFS != 2
                    ) {
                      // 吕姐要求去除主子活动及星星活动
                      this.hdlist.push(list[i])
                    }
                  }
                }
              }
              if (this.hdlist.length > 0) {
                var hd = this.hdlist[0]
                this.tabname = hd.ActivityId
                this.hdid = hd.ActivityId
                this.mainhdid = hd.ActivityId
                this.zhdid = hd.ActivityId
                this.hdbm = hd.ActivityBM
                this.hdmc = hd.ActivityName
                hd = hd.UserEvalutionActive
                this.subactivity = hd.EvaChildList
                this.yhzjhd = hd.IsChaildActive
                this.jzkblx = hd.CourseTableType
                this.pjfs = hd.EvalutionFormat
                this.glnj = hd.GradeNumber?.join(',')
                this.dcpj = hd.EvFrequency
                if (this.yhzjhd == 2) {
                  this.objectlist = hd.UserEObjectList
                }

                this.qrcodesettype = 2
              }
              if (this.tabname == '' && this.kfhdlist.length > 0) {
                this.tabname = 'kfhd'
                this.qrcodesettype = 3
              }
            }
          }
        }
      )
    },
    pjcydxchange: function() {
      this.qrcodeimgpath = []
      this.indexcheckedlist = []
      var pjcydx = this.objectlist.filter(c => c.EObjectId == this.dxid)[0]
      if (pjcydx != null) {
        this.dxmc = pjcydx.EObjectName
        this.dxbm = pjcydx.EObjectBM
      }
      this.qrcodeimgpath = []
      this.loadcourseinfo()
      if (this.zbtype > 0) {
        this.loadactivityindex()
      }
    },
    loadcourseinfo: function() {
      this.$api.post(
        'v1/curriculavariable/GetCourseInfoForWeb',
        {
          PJCYDXId: this.dxid,
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.courselist = r.Target
            if (this.courselist[0]) {
              this.coursechecked = this.courselist[0]
              this.coursechange()
            }
          }
        }
      )
    },
    loadactivityindex: function() {
      //获取末级指标
      this.$api.post(
        'v1/curriculavariable/GetIndexForObject',
        {
          PJCYDXId: this.dxid,
          PJFS: this.pjfs,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            r.Target.forEach(item => {
              item.num = 1
            })
            this.indexlist = r.Target
          }
        }
      )
    },
    loadactivityinfo: function(activityid) {
      this.$api.post(
        'v1/evaluationactivity/GetEvaluationActivity',
        {
          EvalutionActiveId: activityid,
          SchoolId: this.ssxx,
          UserId: this.userid,
          UserType: 1,
          TermId: this.ssxq,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            var list = r.Target
            if (list != null && list != undefined && list.length > 0) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].ActivityType == 1) {
                  this.kfhdlist.push(list[i])
                } else if (list[i].ActivityType == 2) {
                  this.hdlist.push(list[i])
                }
              }
            }
          }
        }
      )
    },
    loadkfhdindex: function() {
      this.$api.post(
        'v1/curriculavariable/GetIndexForWeb',
        {
          TermId: this.ssxq,
          CurriculaId: this.hdid,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            r.Target.forEach(item => {
              item.num = 1
            })
            this.indexlist = r.Target
          }
        }
      )
    },
    indexchange: function() {
      var currentcount = this.indexcheckedlist.length
      if (currentcount <= 1) {
        this.maxcount = 224
      } else {
        this.maxcount = Math.floor(224 / currentcount)
      }
      if (this.indexcount > this.maxcount) {
        this.indexcount = this.maxcount
      }
    },
    subchange: function() {
      this.qrcodeimgpath = []
      this.hdjb = 2
      this.indexcheckedlist = []
      this.coursechecked = null
      this.dxid = ''
      var subhd = this.subactivity.filter(c => c.Id == this.hdid)[0]
      if (subhd != null) {
        this.hdmc = subhd.Name
        this.objectlist = subhd.UserEObjectList
      }
    },
    coursechange: function() {
      this.qrcodeimgpath = []
      if (this.coursechecked != null) {
        this.fjid = this.coursechecked.KCId
        this.kcmc = this.coursechecked.KCMC
      }
    },
    setwidth: function() {
      this.num = 0
      this.height = this.width
    },
    kfhdchange: function() {
      this.qrcodeimgpath = []
      this.hdmc = this.kfhdlist.filter(
        c => c.ActivityId == this.hdid
      )[0].ActivityName
      this.qrcodeimgpath = []
      this.indexcheckedlist = []
      this.loadkfhdindex()
    },
    submitdata: function() {
      if (this.indexcheckedlist == null || this.indexcheckedlist.length <= 0) {
        this.$message({
          type: 'error',
          message: '请勾选指标',
          showClose: true
        })
        return
      }
      if (
        this.qrcodesettype == 2 &&
        this.jzkblx != 1 &&
        this.coursechecked == null
      ) {
        this.$message({
          type: 'error',
          message: '请勾选课程',
          showClose: true
        })
        return
      }
      this.indexcheckedlist.forEach(item => {
        item.zbtype = this.zbtype
      })
      this.isDisabled = true
      this.$api.post(
        'v1/qrcodegenerate/SaveQrCodeInfo',
        {
          SSXX: this.ssxx,
          ZHDId: this.zhdid,
          HDId: this.hdid,
          HDBM: this.hdbm,
          SSXQ: this.ssxq,
          PJFS: this.pjfs,
          DCPJ: this.dcpj,
          GLNJ: this.glnj,
          PJCYDXId: this.dxid,
          DXMC: this.dxmc,
          DXBM: this.dxbm,
          IndexListStr: JSON.stringify(this.indexcheckedlist),
          ActivityType: this.qrcodesettype,
          HDMC: this.hdmc,
          HDJB: this.hdjb,
          FJId: this.fjid,
          CourseInfo: this.coursechecked,
          SCType: 1,
          token: this.token
        },
        r => {
          this.isDisabled = false
          if (r.Code == 0) {
            this.qrcodeimgpath = r.Target
            if (
              this.qrcodeimgpath != null ||
              this.qrcodeimgpath.QRCodeInfoList.length > 0
            ) {
              sessionStorage.removeItem('qrcodepathlist')
              sessionStorage.setItem(
                'qrcodepathlist',
                JSON.stringify(this.qrcodeimgpath)
              )
              var data = {
                width: this.width,
                height: this.height,
                num: this.num
              }
              let routeUrl = this.$router.resolve({
                path: '/qrcodeprint',
                query: {
                  data: JSON.stringify(data)
                }
              })
              window.open(routeUrl.href, '_blank')
            } else {
              this.$message({
                type: 'error',
                message: '生成二维码不成功，请稍后再重试',
                showClose: true
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    savedata: function() {
      if (this.indexcheckedlist == null || this.indexcheckedlist.length <= 0) {
        this.$message({
          type: 'error',
          message: '请勾选指标',
          showClose: true
        })
        return
      }
      if (
        this.qrcodesettype == 2 &&
        this.jzkblx != 1 &&
        this.coursechecked == null
      ) {
        this.$message({
          type: 'error',
          message: '请勾选课程',
          showClose: true
        })
        return
      }
      this.indexcheckedlist.forEach(item => {
        item.zbtype = this.zbtype
      })
      this.isDisabled = true
      this.$api.post(
        'v1/qrcodegenerate/SaveQrCodeInfo',
        {
          SSXX: this.ssxx,
          ZHDId: this.zhdid,
          HDId: this.hdid,
          HDBM: this.hdbm,
          SSXQ: this.ssxq,
          PJFS: this.pjfs,
          DCPJ: this.dcpj,
          GLNJ: this.glnj,
          PJCYDXId: this.dxid,
          DXMC: this.dxmc,
          DXBM: this.dxbm,
          IndexListStr: JSON.stringify(this.indexcheckedlist),
          ActivityType: this.qrcodesettype,
          HDMC: this.hdmc,
          HDJB: this.hdjb,
          FJId: this.fjid,
          CourseInfo: this.coursechecked,
          SCType: 2,
          token: this.token
        },
        r => {
          this.isDisabled = false
          if (r.Code == 0) {
            this.$message({
              type: 'success',
              message: '保存成功',
              showClose: true
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    handleClick(tab) {
      this.qrcodeimgpath = []
      this.hdjb = 1
      this.indexcheckedlist = []
      this.objectlist = []
      this.subactivity = []
      this.courselist = []
      this.indexlist = []
      this.coursechecked = null
      this.dxid = ''
      this.fjid = ''
      this.zbtype = 0
      // this.hdbm = '';
      if (tab.name == 'kfhd') {
        //课辅活动
        this.dxmc = ''
        this.qrcodesettype = 3
        this.pjfs = 1
        this.dcpj = 1
        this.glnj = ''
        this.zhdid = ''
        // this.hdbm = '';
      } else {
        //自定义活动
        this.qrcodesettype = 2
        this.hdid = tab.name
        this.mainhdid = tab.name
        this.hdmc = tab.label
        this.zhdid = tab.name
        var hd = this.hdlist.filter(c => c.ActivityId == this.hdid)[0]
        if (hd != null) {
          this.hdbm = hd.ActivityBM
          hd = hd.UserEvalutionActive
          this.subactivity = hd.EvaChildList
          this.yhzjhd = hd.IsChaildActive
          this.jzkblx = hd.CourseTableType
          this.pjfs = hd.EvalutionFormat
          this.dcpj = hd.EvFrequency
          // console.log(hd.GradeNumber, "handleClick");
          this.glnj = hd.GradeNumber?.join(',')
          if (this.yhzjhd == 2) {
            this.objectlist = hd.UserEObjectList
          }
        }
      }
    },
    zbtypechange: function(value) {
      this.indexcheckedlist = []
      if (this.dxid) {
        if (value == 1) {
          this.loadactivityindex()
        } else if (value == 2) {
          this.getdsdejzblist()
        }
      }
    },
    getdsdejzblist: function() {
      //获取倒数第二级指标
      this.$api.post(
        'v1/curriculavariable/GetIndexForObject_Penultimate',
        {
          PJCYDXId: this.dxid,
          PJFS: this.pjfs,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            r.Target.forEach(item => {
              item.num = 1
            })
            this.indexlist = r.Target
          }
        }
      )
    }
  },
  data() {
    return {
      isDisabled: false,
      ssxx: '',
      ssxq: '',
      token: '',
      selectType: '1',
      hdlist: [],
      kfhdlist: [],
      subactivity: [],
      courselist: [],
      dxid: '',
      fjid: '',
      objectlist: [],
      jzkblx: 1,
      hdjb: 1,
      yhzjhd: 2,
      pjfs: 1,
      dcpj: 1,
      mainhdid: '',
      tabname: '',
      hdid: '',
      hdbm: '',
      zhdid: '',
      hdmc: '',
      maxcount: 224,
      dxmc: '',
      dxbm: '',
      glnj: '',
      indexcount: 1,
      qrcodeimgpath: [],
      indexlist: [],
      indexcheckedlist: [],
      coursechecked: null,
      userid: '',
      zbtype: 0,
      sctype: 0,
      width: '',
      height: '',
      num: 0,
      isshowtips: false
    }
  }
}
</script>
<style>
.zbxz {
  float: left;
}

.set-input {
  margin: 10px 5px;
}

.set-div {
  display: flex;
  align-items: center;
}

.set-span-ms {
  width: 100px;
  height: 30px;
  display: block;
  color: black;
  font-size: 16px;
  line-height: 30px;
}

.set-span-tips {
  width: 100%;
  height: 30px;
  display: block;
  color: red;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
</style>
