<template>
  <div class="box">
      选择模板：
      <div v-for="(item, index) in MBList" :key="index"
        @click="goPage(item)" class="item">
        <el-button type="primary" size="small">{{ item.BM }}</el-button>
      </div>
    </div>
</template>

<script>
export default {
  data(){
      return{
        MBList:[],
        user:'',
        MBDM:'',

    }
  },
  created(){
    if(this.$route.fullPath === '/teacherGeneralReport'){
            this.MBDM = 'TYJSBB'
        }else this.MBDM = 'ZYDDBB'
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    this.getMBGLBList()
  },
  methods:{
    //报表ID
    getMBGLBList() {
      this.$api.post(
        "/v1/mbbb/GetMBGLBList",
        {
          Express: "",
          // MBID: this.MBID,
          MBDM:this.MBDM,
          pagerInfo: {
            pageIndex: 1,
            pageSize: 9000,
            totalCount: 0,
          },
          token: this.user.Token,
        },
        (r) => {
          if (r.Code === 0) {
            console.log(r.Target, "获取选择模板的列表");
            this.MBList = r.Target;
          }
        }
      );
    },
  },
}
</script>

<style lang="scss" scopted>
.box{
    width: 100%;
    font-size: 16px;
    .item{
        margin: 5px 10px;
       
    }
}
</style>