<template>
	<div class="cont-whole-box" style="background: #f7f7f7;">
		<div style="padding: 30px;" class="no-print">
			<div class="no-print essay-name-box cent">
				<el-button @click="closecurrent" type="primary" icon="el-icon-close">关闭</el-button>
				<el-button @click="printcurrent" type="primary" icon="el-icon-printer"
					:disabled="isDisabled">导出为PDF</el-button>
				<el-button @click="printpdf" type="primary" icon="el-icon-printer" :disabled="isDisabled">打印</el-button>
			</div>
		</div>
		<section ref="print">
			<div class="print">
				<div class="tab-heng-box margno-t qccode-block" v-for="(QRCodeInfo, index) in QRCodeInfoList"
					:key="index">
					<div :style="'width:' +max_width +'px;height:' +(height + 18) +'px;display: flex;margin: 30px 15px;'"
						v-for="item in QRCodeInfo.JsonDataList" :key="item.Id">
						<div
							:style=" 'width:' + width + 'px ;height:' + (height + 18) + 'px;' + (SetInfo.EWMYS == 1 ? 'border: 10px solid ' + SetInfo.BKYSM : '') ">
							{{item}}
							<!-- <img :style="'width:'+width+'px;height:'+height+'px;'" :src="qrCodeimg" /> -->
							<vue-qr :text="JSON.stringify(item)" :logoSrc="SetInfo.Logo" :logoScale="SetInfo ? 0.2 : 0"
								:colorDark="SetInfo.EWMYSM" :size="width" :margin="10"></vue-qr>
							<div style="font-size:10px;">
								<div :style="
                    'overflow: hidden;width: 100%;white-space: nowrap;text-align: center;color:' +
                      SetInfo.ZTYSM +
                      ';background-color:white;font-weight: 600;'
                  ">
									{{ QRCodeInfo.Title }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import vueQr from "vue-qr";
	import JSZip from "jszip";
	import FileSaver from "file-saver";
	export default {
		created() {},
		computed: {},
		components: {
			vueQr
		},
		mounted: function() {
			let tempstr = sessionStorage.getItem("qrcodepathlist");
			let data_temp = [];
			if (tempstr != null || tempstr != "") {
				let temp = JSON.parse(tempstr);
				data_temp = temp.QRCodeInfoList;
				this.SetInfo = temp.SetInfo;
			}
			var data = JSON.parse(this.$route.query.data);
			if (data != null && data != undefined) {
				if (data.width != "0") {
					this.width = Number(data.width);
				}
				if (data.height != "0") {
					this.height = Number(data.height);
				} else {
					if (data.width != "0") {
						this.height = this.width;
					}
				}
				this.num = data.num;
			}
			if (this.num == 0) {
				this.num = parseInt(840 / (this.width + 30));
			}
			this.max_width = 840 / this.num - 31;
			let sum_num = this.num * parseInt(1180 / (this.height + 78));
			data_temp.forEach(item => {
				console.log(item)
				let obj = new Object();
				obj.Title = item.Title;
				obj.JsonDataList = [];

				let count = 0;
				item.JsonDataList.forEach(jsonitem => {
					count++;
					if (
						obj.JsonDataList.length >= sum_num ||
						count == item.JsonDataList.length
					) {
						if (count == item.JsonDataList.length) {
							obj.JsonDataList.push(jsonitem);
						}
						this.QRCodeInfoList.push(obj);

						obj = new Object();
						obj.Title = item.Title;
						obj.JsonDataList = [];

						obj.JsonDataList.push(jsonitem);
					} else {
						obj.JsonDataList.push(jsonitem);
					}
				});
			});
			if (!this.SetInfo) {
				this.SetInfo = {
					Logo: "",
					EWMYSM: "Black",
					ZTYSM: "Black",
					SZLX: 1,
					BKYSM: "white",
					EWMYS: "1"
				};
			}
		},
		methods: {
			async printcurrent() {
				this.isDisabled = true;
				let options = {
					lock: true,
					text: "正在导出为PDF，请稍等"
				};
				let loading = this.$loading(options);
				this.zips = new JSZip();
				// this.$print(this.$refs.print);
				let p = await this.getPdfFromHtml3("qccode-block", "二维码");
				await this.zips.file(
					`${p.name}-${new Date().getTime()}.pdf`,
					p.PDF.output("blob")
				);
				// await new Promise(res => setTimeout(res, 1000)); //等待后执行，1000为1秒
				this.zips
					.generateAsync({
						type: "blob"
					})
					.then(content => {
						FileSaver.saveAs(
							content,
							"二维码生成" + new Date().getTime() + ".zip"
						);
						this.isDisabled = false;
						loading.close();
					});
			},
			closecurrent: function() {
				window.close();
			},
			printpdf: function() {
				this.$print(this.$refs.print);
			}
		},
		data() {
			return {
				isDisabled: false,
				QRCodeInfoList: [],
				SetInfo: null,
				width: 100,
				height: 100,
				num: 0,
				max_width: 100,
				max_row: 0,
				zips: null
			};
		}
	};
</script>
<style lang="scss" scoped>
	.qccode-block {
		width: 840px;
		height: 1180px;
		margin: 10px auto;
		background: #fff;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: flex-start;
	}
</style>