<template>
    <!-- 选择定性定量的弹窗 -->
    <el-dialog width="60%" top="5vh"
      :title="dialogTitle" :visible.sync="tc" :before-close="close"
      :close-on-click-modal="false" append-to-body>
        <el-tree ref="tree" :data="activityList" highlight-current check-on-click-node :default-checked-keys="checkedKeys" node-key="Id"
          accordion show-checkbox check-strictly @node-click="handleNodeClick" @check="handleNodeClick"></el-tree>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
      visible: {
          type: Boolean,
          default: false
      },
      dialogTitle: String,
      activityList: {
            type: Array,
            default() {
            return [];
            }
        },
        checkdData:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    // model: {
    //     prop: "visible",
    //     event: "change"
    // },
    data() {
        return {
            tc:false,
            type:'',
            form: {},
            formName: "form",
            checkList: [],
            checkValue:false,
            formLabelWidth:'25%',
            rules:{},
            radio:'',
            // activityList:[]
            checkedKeys: []
        }
    }, 
    
    mounted(){
        // this.activityList = JSON.parse(localStorage.getItem("SchoolEindex"))
    },
    methods:{
        handleNodeClick(data){
            this.$refs.tree.setCheckedNodes([data.Id])
            // 将当前点击的节点设置为选中
            this.confirmData = [data]
            this.checkedKeys=[data.Id];
        },
        //禁用其他活动(不需要)
        handleDisabled(pj,item){
            if(this.checkList &&this.checkList.length>0){
                let a = this.activityList.find(c=>c.pjlx.find(o=>this.checkList.includes(o.pjlxname)))
                if(a){
                    return !a.pjlx.find(o=>o.pjlxname == pj.pjlxname)
                }else return false
            }else return false
        },
        // 勾选框
        handleChange(checked) {},
        //取消选择活动
        cancel(){
          this.$confirm("确认关闭？").then(() => {
            this.$emit('cancelHDdialog')
          }).catch(()=>{});
        },
        //确认选择活动
        confirm(){
            // this.$refs.form.resetFields();
            // let a = this.activityList.filter(c=>c.checkList.length>0)
            if(this.dialogTitle.includes('定量')){
               this.type = 1
            }else if(this.dialogTitle.includes('定性')){
               this.type = 2
            }
            this.$emit('sumbitForm',{type:this.type,a:this.confirmData})
        },
        //形成闭环
        close(){
            this.$emit('sumbitForm',{})
        }
    },
    watch: {
        visible(val) {
            this.tc = val;
            this.$nextTick(() => {
                this.$refs.tree.setCheckedNodes([this.checkdData.Id])
            })
        },
        
    }, 
}
</script>

<style lang="scss" scoped>
.el-dialog{

padding: 1px;
  ::v-deep  .el-dialog__header{
    width: 100%;
        }
  ::v-deep  .el-dialog__body{
    width: 100%;
    height: 600px;
    max-height:600px;
        }
        .el-tree{
            height: 100%;
            ::v-deep .el-tree-node{
                overflow: hidden;
            }
        }
}
  
</style>