<template>
  <el-dialog
    :title="title"
    top="1%"
    :visible.sync="visible"
    width="83%"
    center
    :before-close="closeDialog"
    class="jf-form"
    :close-on-click-modal="false"
  >
    <el-dialog
      top="15%"
      class="jf-form"
      width="30%"
      title="选择老师"
      :visible.sync="teacherListDialogVisible"
      :close-on-click-modal="false"
      append-to-body
      :before-close="closeDialogTeacherListDialog"
    >
      <div class="stud-box">
        <div style="font-size: 14px; margin-bottom: 10px;">
          <el-input
            style="width: 200px;"
            v-model="searchTeacherName"
            placeholder="请输入关键字"
          ></el-input>
        </div>
        <ul class="stud-box-ul" style="height: 250px;">
          <li
            v-for="item in basicData.TeacherList.filter(
              c => c.Name.indexOf(searchTeacherName) != -1
            )"
            :key="item.Id"
            :class="[
              'stud-box-ulli',
              { 'stud-ul-sty': tmpManagerIds.indexOf(item.Id) != -1 }
            ]"
            @click="selectedTeacher(item.Id)"
          >
            <img
              class="stud-box-ulli-img"
              :src="
                item.Photo
                  ? item.Photo
                  : item.Gender === 2
                  ? $store.state.defaultTeacherFemale
                  : $store.state.defaultTeacherMale
              "
            />
            <div class="stud-name">{{ item.Name }}</div>
          </li>
        </ul>
      </div>
      <div class="cent-bott-butt">
        <el-button @click="closeDialogTeacherListDialog">取消</el-button>
        <el-button @click="confirmSelectTeacher">确定</el-button>
      </div>
    </el-dialog>
    <div class="cont-whole-box">
      <div style="padding: 30px;">
        <!--表格区开始-->
        <div class="tab-shu-box margno-t">
          <el-form :model="formData" ref="formData" inline-message>
            <table cellpadding="0" cellspacing="0">
              <tbody>
                <tr>
                  <td class="tab-shu-titl">活动图标</td>
                  <td>
                    <Upload
                      class="c-guree el-link--inner"
                      @getResultUrl="getResultUrl"
                      :itemPic="formData.HDTB"
                      html="<el-link type='primary' :underline='false'>上传</el-link>"
                      :width="50"
                      :height="50"
                      :circle="true"
                    />
                    <!-- <img
                      class="icon-head-img"
                      :src="
                        formData.HDTB && formData.HDTB
                          ? formData.HDTB
                          : $store.state.defaultImage
                      "
                    />-->
                  </td>
                </tr>
                <tr>
                  <td class="tab-shu-titl">活动名称</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="HDMC"
                      :rules="[
                        {
                          required: true,
                          message: '活动名称不能为空',
                          trigger: 'change'
                        },
                        {
                          min: 1,
                          max: 20,
                          message: '不能超过20 个字符',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-input
                        style="width: 400px;"
                        v-model="formData.HDMC"
                        placeholder="请输入活动名称（不超过20个字）"
                      ></el-input>
                    </el-form-item>
                  </td>
                </tr>
                <tr>
                  <td class="tab-shu-titl">活动关联维度</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="SSWD"
                      :rules="[
                        {
                          required: true,
                          message: '请选择末级维度',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-cascader
                        :props="{ expandTrigger: 'hover' }"
                        v-model="formData.SSWD"
                        :options="dimensions"
                        :show-all-levels="false"
                        placeholder="请选择末级维度"
                        @change="handleWdSelected"
                        class="dateselect"
                      ></el-cascader>
                    </el-form-item>
                  </td>
                </tr>
                <tr>
                  <td class="tab-shu-titl">活动类型</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="LXId"
                      :rules="[
                        {
                          required: true,
                          message: '请选择活动类型',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-select
                        v-model="formData.LXId"
                        class="sele-l-box-p"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in this.basicData.CategoryList
                            ? basicData.CategoryList.filter(c => c.Statu === 1)
                            : []"
                          :key="item.Id"
                          :label="item.Name"
                          :value="item.Id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </td>
                </tr>
                <tr>
                  <td class="tab-shu-titl">活动开始时间</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="KSSJ"
                      :rules="[
                        {
                          required: true,
                          message: '请选择开始时间',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-date-picker
                        class="dateselect"
                        v-model="formData.KSSJ"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :editable="false"
                      ></el-date-picker>
                    </el-form-item>
                  </td>
                </tr>
                <tr>
                  <td class="tab-shu-titl">活动结束时间</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="JSSJ"
                      :rules="[
                        {
                          required: true,
                          message: '请选择结束时间',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-date-picker
                        class="dateselect"
                        v-model="formData.JSSJ"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :editable="false"
                      ></el-date-picker>
                    </el-form-item>
                  </td>
                </tr>
                <tr>
                  <td class="tab-shu-titl">开展设置</td>
                  <td>
                    <div
                      class="operate-box"
                      style="padding-bottom: 10px; margin-top: 10px;"
                    >
                      <el-radio-group v-model="formData.KZSZ">
                        <el-radio :label="1">自主参与</el-radio>
                        <el-radio :label="2">以班级为单位参与</el-radio>
                      </el-radio-group>
                    </div>
                    <div v-if="formData.KZSZ === 1" class="col-back-box">
                      <div
                        class="operate-box"
                        style="padding-bottom: 10px; margin-top: 10px;"
                      >
                        总人数
                        <el-input
                          v-if="totalLimitInput"
                          style="width: 50px;"
                          class="popv-inpt"
                          v-model="totalLimit"
                          placeholder="请输入"
                        ></el-input>
                        <div
                          style="line-height: 39px; display: inline-block;"
                          v-else
                        >
                          {{ totalLimit }}
                        </div>
                        人（选填）
                      </div>
                      <div class="er-mianbox-l-p">
                        <el-checkbox-group
                          class="er-mianbox-l-p"
                          v-model="checkedGrade"
                          @change="handleCheckGrade"
                        >
                          <div class="er-box-l" style="line-height: 36px;">
                            <el-checkbox
                              :indeterminate="isIndeterminate"
                              v-model="checkAll"
                              @change="handleCheckAll"
                            ></el-checkbox>
                            <div
                              style="display: inline-block; padding-left: 10px;"
                            >
                              全选
                            </div>
                          </div>
                          <div
                            class="er-box-l"
                            v-for="(item, index) in joinGradeList"
                            :key="index"
                          >
                            <el-checkbox :label="item.GradeId"></el-checkbox>
                            <div
                              style="display: inline-block; padding-left: 10px;"
                            >
                              {{ item.GradeNumStr }}
                              <el-input
                                class="popv-inpt"
                                style="width: 50px;"
                                v-model="item.CountStr"
                                placeholder="请输入"
                              ></el-input
                              >人（选填）
                            </div>
                          </div>
                        </el-checkbox-group>
                      </div>
                    </div>
                    <div v-if="formData.KZSZ === 1" class="prompt-title-box">
                      温馨提示：勾选但不输入具体数字时代表不限制（但人数上限可能会受到总人数限制）
                    </div>
                    <div v-else class="col-back-box">
                      <div
                        class="operate-box"
                        style="padding-bottom: 10px; margin-top: 10px;"
                      >
                        <div
                          @click="selectGradeHanddel(item.Id)"
                          style="margin-right: 0.2rem;"
                          class="seac-btn-box"
                          v-for="item in basicData.OrgList"
                          :key="item.Id"
                        >
                          <a
                            :class="[
                              'seac-btn-boxa',
                              {
                                'btn-boxa-sty whole-back border-col whole-back-hover':
                                  item.Id === selectGrade
                              }
                            ]"
                            href="#"
                            >{{ item.Name }}</a
                          >
                        </div>
                      </div>
                      <div
                        class="operate-box"
                        style="padding-bottom: 10px; margin-top: 10px;"
                      >
                        一键添加负责老师:
                        <span class="c-g-9" style="margin-left: 10px;"
                          >各班的科任老师:</span
                        >
                        <el-select
                          v-model="selectCourse"
                          size="small"
                          class="sele-l-box-c"
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="(item,
                            index) in basicData.CourseTeacherList.filter(
                              c => c.GradeId === selectGrade
                            )"
                            :key="index"
                            :label="item.CourseName"
                            :value="index"
                          ></el-option>
                        </el-select>
                        <div class="seac-btn-box" style="margin-left: 20px;">
                          <a
                            class="seac-btn-boxa seac-btn-boxa btn-boxa-sty whole-back border-col whole-back-hover"
                            href="#"
                            @click="selectCourseHandle"
                            >确认</a
                          >
                        </div>
                        <div class="seac-btn-box" style="margin-left: 20px;">
                          <a
                            class="seac-btn-boxa seac-btn-boxa btn-boxa-sty whole-back border-col whole-back-hover"
                            href="#"
                            @click="classJoinClearAll"
                            >全部清空</a
                          >
                        </div>
                      </div>
                      <div class="er-mianbox-l-p">
                        <el-checkbox-group
                          class="er-mianbox-l-p"
                          v-model="checkedZZCYGrade"
                          @change="handleCheckedZZCYGrade"
                        >
                          <div
                            class="er-box-l"
                            style="line-height: 50px; width: 325px;"
                          >
                            <el-checkbox
                              :indeterminate="zzcyIsIndeterminate"
                              v-model="zzcyCheckAll"
                              @change="handleCheckAllChange"
                            ></el-checkbox>
                            <div
                              style="display: inline-block; padding-left: 10px;"
                            >
                              全选
                            </div>
                          </div>
                          <div
                            class="er-box-l"
                            v-for="item in basicData.OrgList.find(
                              c => c.Id === selectGrade
                            )
                              ? basicData.OrgList.find(
                                  c => c.Id === selectGrade
                                ).ChidOrgList
                              : []"
                            :key="item.Id"
                            style="width: 325px;"
                          >
                            <el-checkbox :label="item.Id"></el-checkbox>
                            {{ item.Name }}
                            <div class="pope-name-box">
                              <div class="pope-name-boxnone">
                                <div
                                  class="name-del-box"
                                  v-for="tch in classTeacherList.filter(
                                    c => c.ClassId === item.Id
                                  )"
                                  :key="tch.Id"
                                >
                                  {{ tch.Name }}
                                  <div class="name-del-zz">
                                    <a
                                      class="name-del-zza"
                                      href="#"
                                      @click="dropClassTeacher(item.Id, tch.Id)"
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tab-wz-buttl"
                              @click="openClassTeachers(item.Id)"
                            >
                              <a class="tab-wz-butta c-guree" href="#">添加</a>
                            </div>
                          </div>
                        </el-checkbox-group>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="formData.KZSZ === 1">
                  <td class="tab-shu-titl">活动负责老师</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="ManagerIds"
                      :rules="[
                        {
                          type: 'array',
                          required: true,
                          message: '缺少负责老师',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-checkbox-group
                        v-model="formData.ManagerIds"
                        v-show="false"
                      >
                        <el-checkbox
                          v-for="item in formData.ManagerIds"
                          :key="item"
                          :label="item"
                        ></el-checkbox>
                      </el-checkbox-group>
                      <el-tag
                        v-for="item in basicData.TeacherList.filter(
                          c => formData.ManagerIds.indexOf(c.Id) != -1
                        )"
                        :key="item.Id"
                        closable
                        effect="plain"
                        style="margin-right: 10px;"
                        @close="handleCloseManagerTag(item)"
                        >{{ item.Name }}</el-tag
                      >
                      <div class="tab-wz-buttl">
                        <el-link
                          :underline="false"
                          type="primary"
                          @click="openTeachers"
                          >添加</el-link
                        >
                      </div>
                    </el-form-item>
                  </td>
                </tr>
                <tr v-if="formData.KZSZ === 1">
                  <td class="tab-shu-titl">报名是否需要审核</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="BMSH"
                      :rules="[
                        {
                          required: true,
                          message: '请选择是否需要审核',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-select
                        v-model="formData.BMSH"
                        class="sele-l-box-p"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in joinVerify"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </td>
                </tr>
                <tr v-if="formData.KZSZ === 1">
                  <td class="tab-shu-titl">性别限制</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="XBXZ"
                      :rules="[
                        {
                          required: true,
                          message: '请选择性别限制',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-select
                        v-model="formData.XBXZ"
                        class="sele-l-box-p"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in genderLimit"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </td>
                </tr>
                <tr v-if="basicData.HasSchoolArea === 1">
                  <td class="tab-shu-titl">校区限制</td>
                  <td>
                    <el-form-item
                      style="margin-bottom: unset;"
                      prop="XiaoQuId"
                      :rules="[
                        {
                          required: true,
                          message: '请选择校区',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-select
                        v-model="formData.XiaoQuId"
                        class="sele-l-box-p"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in basicData.SchoolAreaList"
                          :key="item.Id"
                          :label="item.Name"
                          :value="item.Id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </td>
                </tr>
                <tr>
                  <td class="tab-shu-titl">活动描述（选填）</td>
                  <td>
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入内容"
                      v-model="formData.BZ"
                    ></el-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog" :loading="submiting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Upload
  },
  data() {
    return {
      uploadHTML: "<el-link :underline='false' type='primary'>上传</el-link>",
      zzcyClassId: "",
      checkedZZCYGrade: [],
      zzcyIsIndeterminate: false,
      zzcyCheckAll: false,
      selectGrade: "",
      selectCourse: "",
      classTeacherList: [],
      totalLimitInput: true,
      checkedGrade: [],
      joinGradeList: [],
      isIndeterminate: false,
      checkAll: false,
      tmpManagerIds: [],
      searchTeacherName: "",
      teacherListDialogVisible: false,
      joinVerify: [
        {
          key: 1,
          value: "需要"
        },
        {
          key: 2,
          value: "不需要"
        }
      ],
      genderLimit: [
        {
          key: 0,
          value: "不限制"
        },
        {
          key: 1,
          value: "仅男生参加"
        },
        {
          key: 2,
          value: "仅女生参加"
        }
      ],
      submiting: false,
      formData: { ManagerIds: [], GradeLimit: [], TotalLimit: "", KZSZ: 1 },
      title: "新增活动",
      rules: {
        PJCYDXMC: [
          { required: true, message: "请输入对象名称", trigger: "change" },
          { min: 1, max: 10, message: "长度不超过10个字", trigger: "change" }
        ]
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: function() {
        return {};
      }
    },
    basicData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    dimensions: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    initForm() {
      // this.$refs["formData"].resetFields();
      this.$set(this.formData, "ManagerIds", this.formData.ManagerIds);
      this.checkAll = false;
      this.submiting = false;
      this.checkedGrade = [];
      this.checkedZZCYGrade = [];
      this.formData.TotalLimit = "";
      if (this.form && this.form.Id) {
        this.formData = JSON.parse(JSON.stringify(this.form));
        this.title = "编辑活动";
      }
      if (!this.formData.KZSZ || this.formData.KZSZ === 0) {
        this.formData.KZSZ = 1;
      }
      this.joinGradeList = JSON.parse(
        JSON.stringify(this.basicData.JoinGradeList)
      );
      if (this.formData.GradeLimit.length > 0) {
        for (let i = 0; i < this.formData.GradeLimit.length; i++) {
          this.checkedGrade.push(this.formData.GradeLimit[i].GradeId);
          this.joinGradeList[
            this.joinGradeList.findIndex(
              c => c.GradeId === this.formData.GradeLimit[i].GradeId
            )
          ].CountStr = this.formData.GradeLimit[i].CountStr;
          this.joinGradeList[
            this.joinGradeList.findIndex(
              c => c.GradeId === this.formData.GradeLimit[i].GradeId
            )
          ].Count = this.formData.GradeLimit[i].Count;
        }
      }
      if (this.formData.KZSZ === 2) {
        this.classTeacherList = this.formData.ManagerList;
        this.formData.ManagerList = [];
        this.formData.ManagerIds = [];

        for (let i = 0; i < this.classTeacherList.length; i++) {
          this.checkedZZCYGrade.push(this.classTeacherList[i].ClassId);
          this.checkedZZCYGrade = this.unique(this.checkedZZCYGrade);
        }
        this.zzcyCheckAll =
          this.checkedZZCYGrade.length === this.joinGradeList.length;
        this.zzcyIsIndeterminate = this.checkedZZCYGrade.length > 0;
      }
    },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeDialog");
      });
    },
    confirmDialog() {
      if (this.formData.TotalLimit === "") {
        this.formData.TotalLimit = 0;
      }
      if (this.formData.KZSZ === 2) {
        // this.formData.TotalLimit=0;
        this.formData.ManagerList = this.classTeacherList.filter(
          c => this.checkedZZCYGrade.findIndex(o => o === c.ClassId) !== -1
        );
      }
      this.formData.GradeLimit = this.joinGradeList.filter(
        c => this.checkedGrade.indexOf(c.GradeId) !== -1
      );

      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.formData.KZSZ === 1) {
            this.$confirm(
              "总人数为" + this.formData.TotalLimit + ", 确认提交?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
              }
            )
              .then(() => {
                this.submiting = true;
                this.$emit("confirmDialog", this.formData);
              })
              .catch(() => {
                return false;
              });
          } else {
            this.submiting = true;
            this.$emit("confirmDialog", this.formData);
          }
        } else {
          return false;
        }
      });
    },
    openTeachers() {
      this.tmpManagerIds = JSON.parse(JSON.stringify(this.formData.ManagerIds));
      this.searchTeacherName = "";
      this.teacherListDialogVisible = true;
    },
    closeDialogTeacherListDialog() {
      this.teacherListDialogVisible = false;
    },
    selectedTeacher(id) {
      const index = this.tmpManagerIds.indexOf(id);
      if (index !== -1) {
        this.tmpManagerIds.splice(index, 1);
      } else {
        this.tmpManagerIds.push(id);
      }
    },
    confirmSelectTeacher() {
      this.teacherListDialogVisible = false;
      if (this.formData.KZSZ === 1) {
        this.formData.ManagerIds = JSON.parse(
          JSON.stringify(this.tmpManagerIds)
        );
        this.formData.ManagerList = this.basicData.TeacherList.filter(
          c => this.tmpManagerIds.indexOf(c.Id) !== -1
        );
      } else {
        if (this.zzcyClassId) {
          for (let i = 0; i < this.tmpManagerIds.length; i++) {
            const tch = this.basicData.TeacherList.find(
              c => c.Id === this.tmpManagerIds[i]
            );
            if (
              tch &&
              this.classTeacherList.findIndex(
                c => c.Id === tch.Id && c.ClassId === this.zzcyClassId
              ) === -1
            ) {
              this.classTeacherList.push({
                ClassId: this.zzcyClassId,
                GradeId: this.selectGrade,
                Id: tch.Id,
                Name: tch.Name,
                Photo: tch.Photo
              });
            }
          }
        }
      }
    },
    handleCloseManagerTag(data) {
      const index = this.formData.ManagerIds.indexOf(data.Id);
      if (index !== -1) {
        this.formData.ManagerIds.splice(index, 1);
      }
    },
    handleCheckAll(val) {
      if (val) {
        for (let i = 0; i < this.joinGradeList.length; i++) {
          this.checkedGrade.push(this.joinGradeList[i].GradeId);
        }
      } else {
        this.checkedGrade = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckGrade(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.joinGradeList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.joinGradeList.length;
    },
    handleWdSelected(value) {
      if (value) {
        if (value.length > 1) {
          this.formData.SSWD = value[1];
        } else {
          this.formData.SSWD = value[0];
        }
      }
    },
    selectGradeHanddel(data) {
      const grade = this.basicData.OrgList.find(c => c.Id === data);
      const checkedCount = grade
        ? grade.ChidOrgList.map(c => c.Id).filter(c =>
            this.checkedZZCYGrade.includes(c)
          ).length
        : 0;
      this.zzcyCheckAll = checkedCount === this.joinGradeList.length;
      this.zzcyIsIndeterminate =
        checkedCount > 0 && checkedCount < this.joinGradeList.length;
      this.selectGrade = data;
      this.selectCourse = "";
    },
    handleCheckedZZCYGrade(value) {
      const checkedCount = value.filter(c => c !== undefined).length;
      this.zzcyCheckAll =
        checkedCount ===
        this.basicData.OrgList.find(c => c.Id === this.selectGrade).ChidOrgList
          .length;
      this.zzcyIsIndeterminate =
        checkedCount > 0 &&
        checkedCount <
          this.basicData.OrgList.find(c => c.Id === this.selectGrade)
            .ChidOrgList.length;
    },
    handleCheckAllChange(val) {
      if (val) {
        const list = this.basicData.OrgList.find(c => c.Id === this.selectGrade)
          .ChidOrgList;
        for (let i = 0; i < list.length; i++) {
          this.checkedZZCYGrade.push(list[i].Id);
        }
      } else {
        this.checkedZZCYGrade = [];
      }
      this.zzcyIsIndeterminate = false;
    },
    selectCourseHandle() {
      if (
        this.selectCourse !== "" &&
        this.basicData.CourseTeacherList[this.selectCourse] &&
        this.basicData.CourseTeacherList[this.selectCourse].CourseTeacherList
      ) {
        this.classTeacherList = this.unique(
          this.classTeacherList.concat(
            this.basicData.CourseTeacherList.filter(
              c => c.GradeId === this.selectGrade
            )[this.selectCourse].CourseTeacherList
          )
        );
      }
    },
    unique(arr) {
      const res = new Map();
      return arr.filter(
        a => !res.has(JSON.stringify(a)) && res.set(JSON.stringify(a), 1)
      );
    },
    openClassTeachers(classId) {
      this.zzcyClassId = classId;
      this.tmpManagerIds = [];
      const dataList = this.classTeacherList.filter(c => c.ClassId === classId);
      for (let i = 0; i < dataList.length; i++) {
        this.tmpManagerIds.push(dataList[i].Id);
      }
      this.searchTeacherName = "";
      this.teacherListDialogVisible = true;
    },
    dropClassTeacher(classId, teacherId) {
      const index = this.classTeacherList.findIndex(
        c => c.Id === teacherId && c.ClassId === classId
      );
      if (index !== 1) {
        this.classTeacherList.splice(index, 1);
      }
    },
    classJoinClearAll() {
      this.selectCourse = "";
      this.$set(this, "checkedZZCYGrade", []);
      this.$set(this, "classTeacherList", []);
      this.zzcyCheckAll = false;
      this.zzcyIsIndeterminate = false;
    },
    getResultUrl(filePath) {
      this.$set(this.formData, "HDTB", filePath);
      // this.formData.HDTB = filePath;
    }
  },
  created: function() {
    this.initForm();
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        this.initForm();
        this.selectGrade = this.basicData.OrgList[0].Id;
      } else {
        // 重置校验结果
        this.formData = {
          ManagerIds: [],
          GradeLimit: [],
          TotalLimit: "",
          KZSZ: 1
        };
        this.selectCourse = "";
        this.classTeacherList = [];
        this.isIndeterminate = false;
        this.$refs.formData.resetFields();
      }
    }
  },
  computed: {
    totalLimit: {
      get() {
        // eslint-disable-next-line no-empty
        if (!this.checkedGrade) {
        }
        const arry = this.joinGradeList.filter(
          c => this.checkedGrade.indexOf(c.GradeId) !== -1
        );
        if (arry.length > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.totalLimitInput = false;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.totalLimitInput = true;
        }
        let result = 0;
        for (let i = 0; i < arry.length; i++) {
          if (!arry[i].CountStr) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalLimitInput = true;
          }
          result += arry[i].CountStr * 1;
        }
        if (!this.totalLimitInput || this.formData.TotalLimit * 1 < result) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.formData.TotalLimit = result;
        }
        return this.formData.TotalLimit;
      },
      set(value) {
        this.formData.TotalLimit = value;
      }
    }
  }
};
</script>
<style scoped>
.sele-l-box-p {
  width: 400px;
}
.dateselect {
  width: 400px;
}
.er-mianbox-l-p {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}
.sele-l-box-c {
  width: 200px;
  margin-left: 0.2rem;
}
</style>
