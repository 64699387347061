<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <div class="table-er-butt" @click="handleAdd()">
                <span class="table-er-buttspan">新增</span>
              </div>
            </div>
            <el-table :data="lblist" highlight-current-row style="width: 100%;">
              <el-table-column align="center" property="BT" label="名称">
              </el-table-column>
              <el-table-column align="center" label="图片">
                <template slot-scope="scope">
                  <el-link
                    :href="scope.row.TP"
                    :underline="false"
                    target="_blank"
                    type="success"
                    ><i class="el-icon-view el-icon--left"></i>预览</el-link
                  >
                </template>
              </el-table-column>
              <el-table-column align="center" property="LB" label="类别">
                <template slot-scope="scope">
                  <p v-if="scope.row.LB == 1">App</p>
                  <p v-else-if="scope.row.LB == 2">移动终端</p>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleEdit(scope.row.Id)"
                    style="color: blue;"
                    type="text"
                    >编辑</el-button
                  >
                  <el-button
                    @click="handleDelete(scope.row.Id)"
                    style="color: red;"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="edittitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="handleEditClose"
    >
      <el-form
        label-width="120px"
        style="width: 500px; display: inline-block;"
        :rules="rules"
        :model="sylbinfo"
        ref="editbbform"
        :inline="true"
      >
        <el-form-item label="名称：" prop="BT">
          <el-input
            v-model="sylbinfo.BT"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="轮播图片：" prop="TP">
          <div style="width: 112px;">
            <Upload
              @getResultUrl="getUploadUrl"
              :left="0"
              :width="100"
              :height="100"
              :itemPic="sylbinfo.TP ? sylbinfo.TP : $store.state.defaultPic"
            />
          </div>
        </el-form-item>
        <el-form-item label="类别" :label-width="formLabelWidth" prop="LB">
          <el-select
            v-model="sylbinfo.LB"
            placeholder="请选择类别"
            :disabled="editid > 0"
          >
            <el-option label="APP" :value="1"></el-option>
            <el-option label="移动终端" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item style="text-align:center;">
          <el-button @click="handleEditClose()">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Upload
  },
  computed: {
    // 文件的上传路径
    uploadURL: function() {
      return "/file/file/fileservice/UploadFile?token=" + this.token;
    }
  },
  created() {
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.yhid = user.Id;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    // 上传图片路径
    getUploadUrl(filePath) {
      this.sylbinfo.TP = filePath;
    },
    // 保存
    handleConfirm() {
      this.$refs.editbbform.validate(valid => {
        if (valid) {
          this.sylbinfo.SSXX = this.ssxx;
          this.sylbinfo.CJR = this.yhid;
          this.$api.post(
            "v1/pageview/AddSYLB",
            {
              SYLBInfo: this.sylbinfo,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!"
                });
                this.handleEditClose();
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: r.Message
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    // 关闭
    handleEditClose() {
      this.$refs.editbbform.resetFields();
      this.dialogVisible = false;
    },
    handleAdd: function() {
      this.edittitle = "新增";
      this.editid = 0;
      this.editsylb();
    },
    editsylb: function() {
      this.dialogVisible = true;
      this.abjcx = false;
      this.dxqcx = false;
      if (this.editid > 0) {
        this.loadpageview();
      } else {
        this.sylbinfo.Id = 0;
        this.sylbinfo.BT = "";
        this.sylbinfo.TP = "";
      }
    },
    loaddata: function() {
      this.$api.post(
        "v1/pageview/GetPageViewList",
        { SSXX: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.lblist = [];
            this.lblist = r.Target;
          }
        }
      );
    },
    loadpageview: function() {
      if (this.editid !== "") {
        this.$api.post(
          "v1/pageview/GetPageView",
          { Id: this.editid, token: this.token },
          r => {
            if (r.Code === 0) {
              this.sylbinfo = r.Target;
            }
          }
        );
      }
    },
    handleEdit: function(id) {
      this.edittitle = "编辑";
      this.editid = id;
      this.editsylb();
    },
    handleDelete: function(id) {
      this.$confirm("确定要删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "v1/pageview/DeleteSYLB",
            { Id: id, SSXX: this.ssxx, token: this.token },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "删除失败!"
                });
                this.loaddata();
              }
            }
          );
        })
        .catch(() => {});
    }
  },
  data() {
    return {
      edittitle: "",
      lblist: [],
      ssxx: "",
      token: "",
      yhid: "",
      editid: 0,
      dialogVisible: false,
      sylbinfo: {
        Id: 0,
        TP: "",
        BT: "",
        LB: 1,
        SSXX: "",
        CJR: ""
      },
      rules: {
        BT: [
          { required: true, message: "请输入轮播名称", trigger: "change" },
          { min: 1, max: 20, message: "长度在1到20个字符", trigger: "change" }
        ],
        TP: [{ required: true, message: "请上传轮播图片", trigger: "change" }]
      }
    };
  }
};
</script>
