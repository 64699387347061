<template>
  <el-dialog
    top="5vh"
    :title="dialogTitle"
    :visible.sync="value"
    width="60%"
    ref="dialog"
    :close-on-click-modal="false"
  >
    <el-form
      status-icon
      :model="form"
      :ref="formName"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
      label-position="right"
    >
      <el-form-item label="模板" :label-width="formLabelWidth" prop="MBID">
        <el-select v-model="form.MBID" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.Id"
            :label="item.MBMC"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="别名" :label-width="formLabelWidth" prop="BM">
        <el-input
          style="max-width: 300px"
          suffix-icon="el-icon-edit"
          v-model="form.BM"
          autocomplete="on"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="抬头名称" :label-width="formLabelWidth" prop="TTMC">
        <el-input
          style="max-width: 300px"
          suffix-icon="el-icon-edit"
          v-model="form.TTMC"
          autocomplete="on"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="关联等级" :label-width="formLabelWidth" prop="GLDJ">
        <el-select v-model="form.GLDJ" placeholder="请选择">
          <el-option
            v-for="item in levelOption"
            :key="item.Id"
            :label="item.DJMC"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dialogTitle: String,
    levelOption: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    formData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    MBLX: Number,
  },
  model: {
    prop: "visible",
    event: "change",
  },
  data() {
    return {
      value: false,
      popoverVisible: false, //导入模板按钮
      form: {},
      user: "",
      token: "",
      formName: "form",
      submitForm: {},
      formLabelWidth: "20%",
      rules: {
        BM: [{ required: true, message: "请选择别名", trigger: "change" }],
        MB: [{ required: true, message: "请输入模板", trigger: "change" }],
        MBMC: [
          { required: true, message: "请输入评价模板名称", trigger: "change" },
          // { max: 10, message: "长度不能超过10个字", trigger: "change" },
        ],
        TTMC: [
          { required: true, message: "请输入评价抬头名称", trigger: "change" },
          // { max: 10, message: "长度不能超过10个字", trigger: "change" },
        ],
        GLDJ: [{ required: true, message: "请选择等级", trigger: "change" }],
      },
      options: "",
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
  },
  mounted() {
    this.getMBIDList();
  },
  methods: {
    //获取模板管理详情
    GetMBGLBById() {
      this.$api.post(
        "/v1/mbbb/GetMBGLBById",
        {
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            console.log(r.Target, "获取模板管理详情");
          }
        }
      );
    },
    //获取模板ID
    getMBIDList() {
      this.$api.post(
        "/v1/mbbb/GetMBBList",
        {
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            console.log(r.Target, "获取模板ID");
            this.options = (r.Target || []).filter(
              (item) => item.MBDM === this.MBLX
            );
          }
        }
      );
    },
    // 取消按钮
    cancel() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.$emit("canceldialog");
        })
        .catch(() => {});
    },
    //确认按钮
    confirm() {
      this.$refs[this.formName].validate((valid) => {
        if (valid) {
          this.$emit("sumbitForm", this.form);
        } else {
          this.$message.error("请完整填写表单！");
        }
      });
    },
    //关联活动
    handleActivity() {
      console.log("关联活动");
    },
  },
  watch: {
    value(val) {
      // this.roleList = [];
      // this.reportTemplateList = [];
      if (val) {
        // this.init();
      } else {
        // this.$refs.dialog.$el.scrollTop = 0;
        this.form = {};
        this.$emit("change", false);
      }
    },
    visible(val) {
      this.value = val;
      if (val) {
        this.$set(this, "form", JSON.parse(JSON.stringify(this.formData)));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity {
  font-size: 14px;
  color: #606266;
  width: 60%;
  .activity-item {
    border: 1px solid #ccc;
    border-color: #000;
    width: 100%;
    // padding: 20px 0;
    .activity-title {
      border-right: 1px solid #ccc;
      border-color: rgb(99, 98, 98);
      min-height: 60px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .activity-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .activity-content-expand {
        margin: 10px 0;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    .top {
      align-items: center;
    }
    .vertical {
      flex-direction: column;
    }
  }
  .activity-item + .activity-item {
    border-top: unset;
  }
}
</style>
