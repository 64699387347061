<template>
  <div>
    班级中心
  </div>
</template>

<script>
export default {
  name: "CenterClassReport",
  data() {
    return {};
  },
  methods: {},
  components: {}
};
</script>

<style></style>
