<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <div class="table-er-butt" @click="addcustominfo()">
                <span class="table-er-buttspan">新增消息</span>
              </div>
            </div>
            <el-table
              ref="singleTable"
              border
              :data="inforlist"
              highlight-current-row
              style="width: 100%;"
            >
              <el-table-column
                align="center"
                label="序号"
                type="index"
                width="50"
              >
              </el-table-column>
              <el-table-column
                align="center"
                property="SYZTMC"
                label="关联活动"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.SYZT == '1'">
                    <el-tag type="success" effect="light">
                      {{ scope.row.SYZTMC }}
                    </el-tag>
                  </span>
                  <span v-else>
                    <el-tag type="danger" effect="light">
                      {{ scope.row.SYZTMC }}
                    </el-tag>
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" property="JHMC" label="发送时间">
              </el-table-column>
            </el-table>
            <div class="pagin-box">
              <div class="pagin-wz">
                <Pager
                  :pagerInfo="pagerInfo"
                  @changeIndex="handleCurrentChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    addcustominfo: function() {
      this.$router.push({ name: "editinfor" });
    },
    messagebox: function(message) {
      this.$alert("提示", message, {
        confirmButtonText: "确定"
      });
    },
    loaddata: function() {
      this.$api.post(
        "v1/custominfor/GetInfoList",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.inforlist = [];
            this.inforlist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    }
  },
  data() {
    return {
      isLogin: true,
      inforlist: [],
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      },
      ssxx: "",
      ssxq: "",
      token: ""
    };
  }
};
</script>
