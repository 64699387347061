<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + schoolinfo.backgroudimage + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ schoolinfo.pagename }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'maindiv' + this.exportType">
					<div :class="'list' + exportType">
						<div class="list-item" v-for="item in kfhd" :key="item.id">
							<div class="activity-name">{{ item.hdmc }}</div>
							<div class="title">
								{{ `本期共计获得${item.beans}颗活动豆豆` }}
							</div>
							<div :class="'charts' + item.id" class="charts"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				exportType: 1,
				xqid:'',
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "课辅活动",
					backgroudimage: require("../../../../style/growth/backgroud_16.png")
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					ZFS: ""
				},
				kfhd: [{
						id: "haha",
						hdmc: "五年级音乐社团",
						beans: 78,
						indexlist: [{
								zbmc: "节奏",
								single: 55,
								average: 66
							},
							{
								zbmc: "音感",
								single: 55,
								average: 66
							},
							{
								zbmc: "书写",
								single: 55,
								average: 66
							}
						]
					},
					{
						id: "xixi",
						hdmc: "灵动社团",
						beans: 98,
						indexlist: [{
								zbmc: "节奏",
								single: 55,
								average: 66
							},
							{
								zbmc: "音感",
								single: 56,
								average: 76
							},
							{
								zbmc: "书写",
								single: 33,
								average: 45
							}
						]
					}
				],
				chartsDoms: []
			};
		},
		created() {
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {};
				}
			}
		},
		methods: {
			drawBar() {
				this.chartsDoms = [];
				this.kfhd.forEach(item => {
					let dom = document.querySelector(`.charts${item.id}`);
					let myChart = this.$echarts.init(dom);
					this.chartsDoms.push(myChart);
					let dataName = item.indexlist.map(it => it.zbmc);
					let single = item.indexlist.map(it => it.single);
					let average = item.indexlist.map(it => it.average);

					let option = {
						color: ["#cd99ff", "#7030a1"],
						legend: {
							bottom: 10,
							left: "center"
						},
						xAxis: {
							type: "category",
							data: dataName
						},
						yAxis: [{
							type: "value",
							min: 0,
							interval: 10
						}],
						series: [{
								name: "个人",
								data: single,
								type: "bar"
							},
							{
								name: "均值",
								data: average,
								type: "bar"
							}
						]
					};

					myChart.setOption(option);
				});
			},
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XQMC = xsdata.XQMC;
				// this.xsinfo.BJMC = xsdata.BJMC;
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.drawBar();
			});
		}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/rectangle_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/rectangle_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.marchart1 {
		height: 395px;
		width: 395px;
		border-radius: 15px;
	}

	.marchart2 {
		height: 270px;
		width: 270px;
		border-radius: 15px;
	}

	.firstcharttitle1 {
		border-radius: 20px;
		height: 80px;
		width: 750px;
		background-image: url("../../../../style/growth/purple_title_bar.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		line-height: 80px;
		padding-left: 30px;
	}

	.firstcharttitle2 {
		border-radius: 15px;
		height: 60px;
		width: 535px;
		background-image: url("../../../../style/growth/purple_title_bar.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		line-height: 60px;
		padding-left: 20px;
	}

	.secondcharttitle1 {
		border-radius: 20px;
		height: 80px;
		width: 750px;
		background-image: url("../../../../style/growth/blue_title_bar.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		line-height: 80px;
		padding-left: 30px;
	}

	.secondcharttitle2 {
		border-radius: 15px;
		height: 60px;
		width: 535px;
		background-image: url("../../../../style/growth/blue_title_bar.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		line-height: 60px;
		padding-left: 20px;
	}

	.maindiv1 {
		height: 960px;
		background: #fbfbfb;
		border-radius: 20px;
		margin: 20px 30px 20px 30px;
	}

	.maindiv2 {
		height: 682px;
		background: #fbfbfb;
		border-radius: 15px;
		margin: 20px 20px 10px 20px;
	}

	.zbrow1 {
		width: 300px;
		height: 100px;
		background: #ffffff;
		border-radius: 15px;
		margin-top: 50px;
	}

	.zbrow2 {
		width: 240px;
		height: 80px;
		background: #ffffff;
		border-radius: 10px;
		margin-top: 35px;
	}

	.zbmccol1 {
		font-size: 45px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 100px;
	}

	.zbmccol2 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 80px;
	}

	.zbscorecol1 {
		font-size: 45px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #602897;
		line-height: 100px;
	}

	.zbscorecol2 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #602897;
		line-height: 80px;
	}

	.list1 {
		.list-item {

			.activity-name,
			.title {
				padding: 20px;
				text-align: center;
				color: black;
				font-size: 20px;
			}

			.title {
				padding: 15px;
				text-align: right;
			}

			.charts {
				width: 780px;
				height: 300px;
			}
		}
	}

	.list2 {
		.list-item {

			.activity-name,
			.title {
				padding: 10px 20px;
				text-align: center;
				color: black;
				font-size: 20px;
			}

			.title {
				padding: 8px 15px;
				text-align: right;
			}

			.charts {
				width: 550px;
				height: 245px;
			}
		}
	}
</style>