<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <el-row>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <Tree
              :treeData="pcmenulist"
              ref="treepc"
              :expandedNode="pcrolelist"
              :checkedNode="pcrolelist"
              :enableCheck="true"
              :disableReverseRelation="true"
              :defaultProps="defaultProps"
              nodeKey="id"
              @onChange="savepcrolemenue"
            />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <Tree
              :treeData="appmenulist"
              ref="treeapp"
              :expandedNode="approlelist"
              :checkedNode="approlelist"
              :enableCheck="true"
              :disableReverseRelation="true"
              :defaultProps="defaultProps"
              nodeKey="id"
              @onChange="saveapprolemenue"
            />
            <!--<el-tree :props="defaultProps"
                                                     :data="appmenulist"
                                                     show-checkbox
                                                     node-key="id"
                                                     :default-checked-keys="appdefaultcheck"
                                                     @check="apphandleCheck"
                                                     default-expand-all
                                                     :expand-on-click-node="false">
                        </el-tree>-->
            <!--:load="apploadNode" check-strictly="true"-->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Tree from "@/components/Tree.vue";

export default {
  name: "rolePowers",
  components: {
    Tree
  },
  data() {
    return {
      token: this.$store.state.token,
      roleId: "",
      schoolId: "",
      pcmenulist: [],
      appmenulist: [],
      approlelist: [],
      pcrolelist: [],
      checkapplist: [],
      checkpclist: [],
      defaultProps: {
        children: "children",
        label: "label",
        parent: "fjId"
      }
    };
  },
  created: function() {
    this.roleId = this.$route.params.roleId;
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.schoolId = userinfo.SSZZId;
    this.$api.post(
      "v1/role/GetRoleMenuTree",
      { SchoolId: this.schoolId, MkType: 1, token: this.token },
      r => {
        if (r.Code === 0) {
          this.pcmenulist = r.Target;
        }
      }
    );
    this.$api.post(
      "v1/role/GetRoleMenuTree",
      { SchoolId: this.schoolId, MkType: 2, token: this.token },
      r => {
        if (r.Code === 0) {
          this.appmenulist = r.Target;
        }
      }
    );
    // 默认选中
    this.pcrefdefaultnode();
    this.apprefdefaultnode();
  },
  methods: {
    // 默认选中
    pcrefdefaultnode: function() {
      this.$api.post(
        "v1/role/GetRoleByMenu",
        {
          RoleId: this.roleId,
          SchoolId: this.schoolId,
          SSYY: 1,
          token: this.token
        },
        r => {
          if (r.Code === 0 && r.Target !== null) {
            this.pcrolelist = r.Target.reduce((pre, cur) => {
              // if (cur.SSLX && cur.SSLX === 2) return pre.concat(cur.CDId);
              // else return pre;
              return pre.concat(cur.CDId);
            }, []);
          }
        }
      );
    },
    apprefdefaultnode: function() {
      this.$api.post(
        "v1/role/GetRoleByMenu",
        {
          RoleId: this.roleId,
          SchoolId: this.schoolId,
          SSYY: 2,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            if (r.Target !== null) {
              this.approlelist = r.Target.map(c => c.SQId || "");
            }
          }
        }
      );
    },
    // 保存app节点
    saveapprolemenue: function() {
      var getSelectNode = [];
      this.checkapplist = [];
      getSelectNode = this.$refs.treeapp.getCheckedNodes();
      if (getSelectNode !== null && getSelectNode.length > 0) {
        for (var i = 0; i < getSelectNode.length; i++) {
          var nodeSet = getSelectNode[i];
          var JSQXBInfo = {
            Id: 0,
            JSId: this.roleId,
            GNMKId: nodeSet.glId,
            CDId: nodeSet.cdid,
            SQId: nodeSet.id,
            SSLX: nodeSet.rolepowertype,
            FJId: nodeSet.fjId,
            SSYY: 2
          };
          this.checkapplist.push(JSQXBInfo);
        }
        this.$api.post(
          "v1/role/AddRolePowerRewrite",
          {
            RolePowerList: this.checkapplist,
            RoleId: this.roleId,
            SchoolId: this.schoolId,
            SSYY: 2,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.$notify({
                type: "success",
                message: r.Message,
                duration: 3000
              });
            } else {
              this.apprefdefaultnode(); // 失败恢复
            }
          }
        );
      }
    },
    // 保存pc节点
    savepcrolemenue: function() {
      var getSelectNode = [];
      this.checkpclist = [];
      getSelectNode = this.$refs.treepc.getCheckedNodes();
      if (getSelectNode !== null && getSelectNode.length > 0) {
        for (var i = 0; i < getSelectNode.length; i++) {
          var nodeSet = getSelectNode[i];
          var JSQXBInfo = {
            Id: 0,
            JSId: this.roleId,
            GNMKId: nodeSet.glId,
            CDId: nodeSet.id,
            SSLX: nodeSet.rolepowertype,
            FJId: nodeSet.fjId,
            SSYY: 1
          };
          this.checkpclist.push(JSQXBInfo);
        }
        this.$api.post(
          "v1/role/AddRolePowerRewrite",
          {
            RolePowerList: this.checkpclist,
            RoleId: this.roleId,
            SSYY: 1,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.$notify({
                type: "success",
                message: r.Message,
                duration: 3000
              });
            } else {
              this.pcrefdefaultnode(); // 失败恢复
            }
          }
        );
      }
    }
    // pc
    // pchandleCheckChange(data, checked, indeterminate) {

    //    //console.log(data.id, checked);

    //    var JSQXBInfo = { Id: 0, JSId: this.roleId, GNMKId: data.glId, CDId: data.id };

    //    this.$api.post('v1/role/AddRolePower', { JSQXBInfo: JSQXBInfo, IsMenu: checked, token: this.token }, r => {

    //        if (r.Code === 0) {

    //            this.$notify({
    //                type: "success",
    //                message: r.Message,
    //                duration: 3000
    //            });
    //        }
    //        else {
    //            this.$message({
    //                type: "error",
    //                message: r.Message,
    //                showClose: true
    //            });
    //        }

    //    });

    // },
    // pchandleCheck(data, checkinfo) {
    //    console.log(data);
    //    console.log(checkinfo);

    //    var checkidList = [];

    //    //是否取消全部
    //    var isdeleteall = false;
    //    var checkedNodelist = checkinfo.checkedNodes;
    //    if (checkedNodelist.length > 0) {

    //        checkedNodelist.forEach((item, index, arr) => {

    //            if (item.cj > 2) {

    //                var JSQXBInfo = { Id: 0, JSId: this.roleId, GNMKId: item.glId, CDId: item.id, SSLX: item.rolepowertype, FJId: item.fjId };
    //                checkidList.push(JSQXBInfo);
    //            }
    //        });
    //    }
    //    else {
    //        isdeleteall = true;
    //    }

    //    if (checkidList.length === 0) {

    //        this.$message({
    //            type: 'info',
    //            message: '请选中有效节点'
    //        });
    //        return;
    //    }
    //    //是否选中
    //    var isselect = false;
    //    if (this.pcdefaultcheck.length <= checkidList.length) {
    //        isselect = true;
    //    }

    //    this.$api.post("v1/role/AddRolePowerNew", { RolePowerList: checkidList, CheckedId: data.id, RoleId: this.roleId, SchoolId: this.schoolId, IsMenu: isdeleteall, IsChecked: isselect, token: this.token }, r => {
    //        if (r.Code === 0) {

    //            this.pcrefdefaultnode();

    //            this.$notify({
    //                type: "success",
    //                message: r.Message,
    //                duration: 3000
    //            });
    //        }
    //    });
    // },

    // app
    // apphandleCheckChange(data, checked, indeterminate) {

    //    var JSQXBInfo = { Id: 0, JSId: this.roleId, GNMKId: data.glId, CDId: data.id };

    //    this.$api.post('v1/role/AddRolePower', { JSQXBInfo: JSQXBInfo, IsMenu: checked, token: this.token }, r => {

    //        if (r.Code === 0) {

    //            this.$notify({
    //                type: "success",
    //                message: r.Message,
    //                duration: 3000
    //            });
    //        }
    //        else {
    //            this.$message({
    //                type: "error",
    //                message: r.Message,
    //                showClose: true
    //            });
    //        }
    //    });
    // },
    // apphandleCheck(data, checkinfo) {

    //    var checkidList = [];

    //    //是否取消全部
    //    var isdeleteall = false;
    //    var checkedNodelist = checkinfo.checkedNodes;
    //    if (checkedNodelist.length > 0) {

    //        checkedNodelist.forEach((item, index, arr) => {

    //            if (item.cj > 2) {

    //                var JSQXBInfo = { Id: 0, JSId: this.roleId, GNMKId: item.glId, CDId: item.id, SSLX: item.rolepowertype };
    //                checkidList.push(JSQXBInfo);
    //            }
    //        });
    //    }
    //    else {
    //        isdeleteall = true;
    //    }

    //    if (checkidList.length === 0) {

    //        this.$message({
    //            type: 'info',
    //            message: '请选中有效节点'
    //        });
    //        return;
    //    }
    //    //是否选中
    //    var isselect = false;
    //    if (this.appdefaultcheck.length <= checkidList.length) {
    //        isselect = true;
    //    }

    //    this.$api.post("v1/role/AddRolePowerNew", { RolePowerList: checkidList, CheckedId: data.id, RoleId: this.roleId, SchooId: this.schoolId, IsMenu: isdeleteall, IsChecked: isselect, token: this.token }, r => {
    //        if (r.Code === 0) {

    //            this.apprefdefaultnode();

    //            this.$notify({
    //                type: "success",
    //                message: r.Message,
    //                duration: 3000
    //            });
    //        }
    //    });
    // },
  }
};
</script>
