<template>
  <el-dialog
    title=""
    :visible.sync="show"
    width="90%"
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="search-block">
      <el-button type="primary" size="small" @click="add">新增</el-button>
      <el-input size="small" placeholder="请输入学科名称" v-model="seachText">
        <el-button
          slot="append"
          size="small"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </div>
    <el-table :data="searchData" style="width: 100%" height="500">
      <el-table-column prop="XKXZ" label="学科"> </el-table-column>
      <el-table-column prop="NJXZ" label="年级"> </el-table-column>
      <el-table-column label="学业总评">
        <template slot-scope="scope">
          <div class="level-select">
            <el-select
              v-model="scope.row.XYZP"
              size="mini"
              style="width:110px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in DJoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="scope.row.DJId"
              v-show="scope.row.XYZP === 1"
              size="mini"
              style="flex:1"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in levelOptions2"
                :key="item.Id"
                :label="item.DJMC"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="editXK(scope.row)"
            >编辑</el-button
          >
          <el-button type="primary" size="small" @click="edit(scope.row)"
            >设置</el-button
          >
          <el-button type="danger" size="small" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$emit('update:show', false)"
        size="small"
        icon="el-icon-circle-close"
        >关闭</el-button
      >
      <el-button
        size="small"
        :disabled="!searchData || searchData.length === 0"
        icon="el-icon-circle-close"
        @click="submit"
        >保存</el-button
      >
    </span>

    <!-- 新增学科 -->
    <XK-Form
      :show.sync="addXK.visible"
      :disabledNj="disabledNj"
      :njOption="njOption"
      :xkOption="xkOption"
      :levelOptions2="levelOptions2"
      :firstLevel="firstLevel"
      @refresh="GetSubjectSet"
      :choseInfo="choseInfo"
      :action="action"
    />

    <!-- 编辑学科模块 -->
    <XKMK
      :show.sync="XKMK.visible"
      :levelOptions="levelOptions"
      :fatherData="XKMK.data"
    />
  </el-dialog>
</template>

<script>
import XK_Form from "./XK_Form.vue";
import XKMK from "./XKMK.vue";
import {
  GetSubjectSet,
  DeleteSubjectSet,
  AddSubjectSet
} from "@/api/growthReportSet.js";

export default {
  components: {
    XKForm: XK_Form,
    XKMK
  },
  props: {
    show: Boolean,
    levelOptions: [Array],
    firstLevel: [Object]
  },
  data() {
    return {
      action:0,//新增0，编辑1
      userInfo: {},
      xkList: [],
      xkOption: [],
      njOption: [],
      disabledNj: [],
      addXK: {
        visible: false,
        form: {}
      },
      XKMK: {
        data: {},
        visible: false
      },
      // 等级option
      DJoption: [
        {
          label: "分数转等级",
          value: 1
        },
        {
          label: "星数",
          value: 2
        }
      ],
      levelOptions2: [],
      seachText: "",
      searchData: [],
      choseInfo:{}
    };
  },
  async mounted() {
    let user = localStorage.getItem("userinfo");
    if (user) this.userInfo = JSON.parse(user);
    else this.$message.error("没有获取到userinfo");
    await this.getNJ();
    await this.getXk();
    this.levelOptions2 =
      this.levelOptions.filter(item => item.DJLX === 2) || [];
  },
  methods: {
    //编辑学科
    editXK(value){
      this.addXK.visible = true;
      this.choseInfo = value
      this.action = 1
    },
    // 获取学科
    getXk() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/xxkcb/GetXXKCBList",
          {
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.xkOption = res.Target || [];
              resolve();
            } else reject(res);
          },
          err => reject(err)
        );
      });
    },
    // 获取学科表
    async GetSubjectSet() {
      let res = await GetSubjectSet({
        Id: this.firstLevel.Id,
        token: this.userInfo.Token
      });
      this.searchData = this.xkList = res;
    },
    add() {
      this.action = 0
      let arr = [];
      // 搜集所有学科
      let XKarr = this.xkList.reduce((pre, cur) => {
        return pre.concat(cur.XKXZS);
      }, []);
      // 去重
      let arr2 = [];
      XKarr.forEach(item => {
        let idx = arr2.findIndex(el => el === item);
        if (idx === -1) arr2.push(item);
      });
      // 搜集每个学科已有的年级
      arr2.forEach(item => {
        let nj = this.xkList.reduce((pre, cur) => {
          if (cur.XKXZS.includes(item)) {
            cur.NJXZS.forEach(njNum => {
              let idx = pre.findIndex(el => el === njNum);
              if (idx === -1) pre = pre.concat(njNum);
            });
            return pre;
          } else return pre;
        }, []);
        arr.push({
          xk: item,
          nj
        });
      });
      this.disabledNj = arr;
      this.choseInfo = {}
      this.addXK.visible = true;
    },
    del(data) {
      this.$confirm("确认删除？")
        .then(async () => {
          await DeleteSubjectSet({ id: data.FZId, token: this.userInfo.Token });
          this.$message.success("操作成功");
          this.GetSubjectSet();
        })
        .catch(() => {
          //
        });
    },
    edit(data) {
      this.XKMK.data = data;
      this.XKMK.visible = true;
    },
    getNJ() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolOrg",
          {
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.njOption = res.Target || [];
              this.njOption.forEach(item => this.$set(item, "disabled", false));
              resolve();
            } else reject(res);
          },
          err => reject(err)
        );
      });
    },
    async submit() {
      if (this.xkList.length > 0) {
        await AddSubjectSet({
          id: this.firstLevel.Id,
          XKSZInfo: this.xkList.reduce((pre, cur) => {
            return pre.concat({
              Id: cur.FZId,
              XKXZ: cur.XKXZS,
              NJXZ: cur.NJXZS,
              XYZP: cur.XYZP,
              DJId: cur.XYZP === 1 ? cur.DJId : ""
            });
          }, []),
          token: this.userInfo.Token
        });
        this.GetSubjectSet();
        this.$message.success("操作成功");
      }
    },
    handleSearch() {
      if (this.seachText && this.seachText.trim()) {
        let res = this.xkList.filter(item =>
          item.XKXZ.includes(this.seachText.trim())
        );
        this.searchData = res;
      } else {
        this.searchData = this.xkList;
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.GetSubjectSet();
        this.levelOptions2 =
          this.levelOptions.filter(item => item.DJLX === 2) || [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-block {
  display: flex;
  align-items: center;
  .el-input {
    width: 200px;
    margin-left: 15px;
  }
}
.level-select {
  display: flex;
  align-items: center;
}
</style>
