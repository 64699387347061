<template>
  <el-dialog
    title="学科选择"
    :visible.sync="show"
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
    width="90%"
  >
    <div class="select-xk">
      <div class="label">选择学科</div>
      <el-checkbox-group v-model="xkSelected" @change="xkChange">
        <el-checkbox v-for="item in xkOption" :key="item.Id" :label="item.Id">{{
          item.KCMC
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="select-nj">
      <div class="label">选择年级</div>
      <el-checkbox-group v-model="njSelected">
        <el-checkbox
          v-for="item in njOption"
          :key="item.Id"
          :disabled="item.disabled"
          v-show="item.GradeNumber >= 1"
          :label="item.GradeNumber"
          >{{ item.GradeNumber + "年级" }}</el-checkbox
        >
      </el-checkbox-group>
    </div>
    <div class="XYZP">
      <div class="label">学业总评</div>
      <el-select v-model="XYZPDJtype" size="small" placeholder="请选择">
        <el-option
          v-for="item in DJoption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-show="XYZPDJtype === 1"
        v-model="XYZPDJId"
        size="small"
        filterable
        placeholder="请选择"
      >
        <el-option
          v-for="item in levelOptions2"
          :key="item.Id"
          :label="item.DJMC"
          :value="item.Id"
        >
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:show', false)">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddSubjectSet } from "@/api/growthReportSet.js";

export default {
  props: {
    action: {
      type: Number,
      default:0   //新增0，编辑1
    },
    show: {
      type: Boolean,
      required: true
    },
    disabledNj: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    choseInfo: {
      type: Object,
      default: function() {
        return {}
      }
    },
    levelOptions2: {
      type: Array,
      default() {
        return [];
      }
    },
    firstLevel: [Object],
    njOption: [Array],
    xkOption: [Array]
  },
  data() {
    return {
      xkSelected: [],
      njSelected: [],
      diabledList: [],
      userInfo: {},
      // 等级option
      DJoption: [
        {
          label: "分数转等级",
          value: 1
        },
        {
          label: "星数",
          value: 2
        }
      ],
      XYZPDJtype: 1,
      XYZPDJId: "",
    
    };
  },
  async mounted() {
    let user = localStorage.getItem("userinfo");
    if (user) this.userInfo = JSON.parse(user);
  },
  methods: {
    async submit() {
      if (this.xkSelected.length === 0)
        return this.$message.error("学科不能为空");
      if (this.njSelected.length === 0)
        return this.$message.error("年级不能为空");
      let id = ''
      if(this.action){
        id = this.choseInfo.FZId
      }
      await AddSubjectSet({
        id: this.firstLevel.Id,
        XKSZInfo: [
          {
            Id: id,
            XKXZ: [...this.xkSelected],
            NJXZ: [...this.njSelected],
            XYZP: this.XYZPDJtype,
            DJId: this.XYZPDJtype === 1 ? this.XYZPDJId : ""
          }
        ],
        token: this.userInfo.Token
      });
      this.xkSelected = [];
      this.njSelected = [];
      this.njOption.forEach(item => (item.disabled = false));
      this.$message.success("操作完毕");
      this.$emit("update:show", false);
      this.$emit("refresh");
    },
    xkChange(val) {
      let arr = [];
      this.disabledNj.forEach(item => {
        val.forEach(xk => {
          if (xk === item.xk) arr = arr.concat(item.nj);
        });
      });
      this.njOption.forEach(item => {
        let idx = arr.findIndex(el => el === item.GradeNumber);
        if (idx === -1) {
          item.disabled = false;
        } else {
          item.disabled = true;
          let idx2 = this.njSelected.findIndex(el => el === arr[idx]);
          if (idx2 !== -1) this.njSelected.splice(idx2, 1);
        }
      });
    }
  },
  watch: {
    show(val) {
      if (val){
        if (this.levelOptions2.length > 0){
          this.XYZPDJId = this.levelOptions2[0].Id;
        }
      }
    },
    choseInfo(val){
      if(!val.DJId){
        this.xkSelected = []
        this.njSelected = []
      }else{
        this.xkSelected = val.XKXZS
        this.njSelected = val.NJXZS
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.select-xk,
.select-nj,
.XYZP {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  margin: 0 10px 10px;
  box-shadow: 1px 1px 2px 2px #f9fbfb;
  padding-bottom: 20px;
  .label {
    margin-right: 15px;
  }
}
</style>
