<template>
  <div class="cont-whole-box">
  <div style="padding: 30px;">
    <div class="margno-t">
      <div class="table-er-box">
        <el-button @click="opendialog">新增</el-button>
      </div>
      <el-table border :data = "tableData">
        <el-table-column prop="PXH" fixed width="60" label="编号"></el-table-column>
        <el-table-column fixed prop="KHXM" min-width="80" label="考核项目" show-overflow-tooltip></el-table-column>
        <el-table-column fixed prop="FZ" min-width="40" label="分值" show-overflow-tooltip></el-table-column>
        <el-table-column fixed prop="DLZB" min-width="80" label="定量指标" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ getDLZBName(row.DLGLZB) }}
          </template>
        </el-table-column>
        <el-table-column fixed prop="DXZB" min-width="80" label="定性指标" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ getDLZBName(row.DXGLZB) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
                <el-button @click="editItem(scope.row)" type="text" size="small">编辑</el-button>
                <el-button @click="delItem(scope.row)" type="text" size="small">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
      
      <Adddialog
        @canceldialog="dialogVisible = false" 
        @confirmdialog="confirmdialog" 
        :dialogTitle="dialogTitle" 
        :formData="formData" 
        :IndexList ="IndexList"
        v-model="dialogVisible"
      />
    </div>
  </div>
</div>
</template>

<script>
import Adddialog from './Adddialog.vue';


export default {
  components:{Adddialog},
    data() {
        return {
          tableData:'',
          dialogTitle:'新增项目',
          dialogVisible:false,
          formData:{},
          user:'',
          rowData:'',
          activityList:[],
          activityList2:[],
          IndexList:[],
          baseIndex:[]
        }
    },
    created(){
        this.user = JSON.parse(localStorage.getItem("userinfo"));
        this.rowData = this.$route.params.data
        this.getGLXMBList()
        this.getActivityList()
        
    },
    methods:{
      //获取指标列表
      getActivityList(){
          return new Promise((resolve, reject) => {
              this.$api.post(
              "/v1/mbbb/GetIndexListByMBGLID",
              {
                  MBGLID:this.rowData.Id,
                  token: this.user.Token
              },
              res => {
                  if (res.Code === 0) {
                      localStorage.setItem("SchoolEindex",JSON.stringify(res.Target))
                      let arr = [
                        {
                          FID: "ed91b05a-6f0d-4277-b6a1-7d9adb5f1e90",
                          Id: "123",
                          LB: 3,
                          LX: 2,
                          ZBMC: "学习态度1"
                        },
                        {
                          FID: "ed91b05a-6f0d-4277-b6a1-7d9adb5f1e90",
                          Id: "245",
                          LB: 3,
                          LX: 2,
                          ZBMC: "学习态度2"
                        },
                        {
                          FID: "245",
                          Id: "245-123",
                          LB: 3,
                          LX: 2,
                          ZBMC: "学习态度2-1"
                        }
                      ]
                      this.baseIndex = res.Target.concat(arr)
                      let topIndex = this.baseIndex.filter(i=>i.FID == '0')
                      this.handleTreeIndex(topIndex)
                      this.IndexList = topIndex
                      console.log(this.IndexList,'this.IndexList');
                      resolve();
                  }
              },
              err => reject(err)
              )
          })           
        },
      //匹配指标名称
      getDLZBName(data){
        let name = ''
        let a = this.baseIndex.find(i=>i.Id == data)
        if(a){
          name = a.ZBMC
        }else name = ''
        return name
      },
      //编辑打开弹窗
      editItem(data){
        this.formData = data
        this.formData.DLGLZBMC  = this.getDLZBName(data.DLGLZB)
        this.formData.DXGLZBMC  = this.getDLZBName(data.DXGLZB)

        console.log(this.formData,'this.formData');

        this.dialogTitle = "编辑"
        this.dialogVisible = true
      },
      //删除
      delItem(data){
        this.$confirm("确认删除？").then(() => {
          this.$api.post(
            '/v1/mbbb/DeleteGLXMB',
            {
              Id:data.Id,
              token:this.user.Token
            },
            r=>{
              if(r.Code == 0){
                this.$message({
                    type: "success",
                    message: "删除成功",
                    showClose: true
                })
                this.getGLXMBList()
              }
            }
          )
        }).catch(()=>{});
        
      },
      //获取关联项目列表
      getGLXMBList(){
        this.$api.post(
          '/v1/mbbb/GetGLXMBList',
          {
            MBGLID:this.rowData.Id,
            token:this.user.Token
          },
          r=>{
            if(r.Code == 0){
              let list = r.Target ||[]
              this.tableData = list.sort((a,b)=>a.PXH-b.PXH)
              console.log(r.Target,'12222');
            }
          }
        )
      },
      // 新增打开弹窗
      opendialog(){
        this.dialogTitle = '新增项目',
        this.formData = {}
        this.dialogVisible = true
      },
        
      // 确认按钮
      confirmdialog(data){
        console.log(data,'确认新增弹窗');
        data.MBGLID = this.rowData.Id
        this.dialogVisible = false;
        if(this.dialogTitle.includes('新增')){
          this.$api.post(
            "/v1/mbbb/AddGLXMB",
            {
                GLXMBInfo:data,
                token: this.user.Token
            },
            res => {
              if (res.Code === 0) {
                  console.log(res.Target,'新增项目');
                  this.$message({
                      type: "success",
                      message: "新增成功",
                      showClose: true
                  });
                  this.getGLXMBList()
              }else{
                  this.$message.error(res.Message)
              }
            }
          )
        }else{
          this.$api.post(
            "/v1/mbbb/UpdateGLXMB",
            {
                GLXMBInfo:data,
                token: this.user.Token
            },
            res => {
              if (res.Code === 0) {
                  console.log(res.Target,'编辑项目');
                  this.$message({
                      type: "success",
                      message: "编辑成功",
                      showClose: true
                  });
                  this.getGLXMBList()
              }else{
                  this.$message.error(res.Message)
              }
            }
          )
        }
        
      },

      handleTreeIndex(IndexList){
        IndexList.forEach(item => {
          item.label = item.ZBMC
         let children = this.baseIndex.filter(o=>o.FID==item.Id);
         item.children = children
          if(children.length>0){
            this.handleTreeIndex(item.children)
            item.isLeaf = true
          }else{
            item.isLeaf = false
          }
        });
      },
    },
}
</script>

<style>

</style>