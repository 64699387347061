<template>
    <div class="mian-box">
        <div class="top-title-box">
            <span>系统使用帮助</span>
        </div>
        <div v-html="this.HTML" class="top-title-box">
        </div>
    </div>
</template>

<script>

export default {  
  name: "UsingHelpIndex",
  data() {
    return {
      HTML:""
    };
  },
  
  mounted: function() {
    this.getData();
  },
  methods: {    
    getData() {
      this.$api.get(
        "/v1/evaluationreport/GetSybzglAll",
        {
        },
        r => {
          if (r.Code === 0 && r.Target !== null) {
                 var htmlBT = "<table>";
                for (var i = 0; i < r.Target.length; i++) {
                    htmlBT += "<tr style='text-align:left;'>";
                    
                    var typeName = "";
                    if (r.Target[i].LB === 1) {
                        typeName = "图文";
                    } else if (r.Target[i].LB === 2) {
                        typeName = "语音";
                    } else if (r.Target[i].LB === 3) {
                        typeName = "视频";
                    }

                    htmlBT += "<td>";
                    htmlBT += "<p>" + (i + 1) + ".&nbsp;" + typeName + "</p>";
                    htmlBT += "</td>";
                    htmlBT += "<td style='width:10%;'>";
                    htmlBT += "</td>";
                    htmlBT += "<td>";
                    htmlBT += "<a href='/UsingHelpListIndex?Id="+ r.Target[i].Id +"&lb="+ r.Target[i].LB +"&'>" + r.Target[i].BT + "</a>";
                    htmlBT += "</td>";
                    htmlBT += "</tr>";
                }
                 htmlBT += "</table>";
                 this.HTML = htmlBT;
             }
        },
        err => {
        }
      );
    }
  },
  watch: {
  }
};
</script>

<style>
  html {
      font-size: 14px;
  }

  html, body {
      margin: 0px;
      padding: 0px;
      width: 100%;
      height: 100%;
      font-family: "苹方", "Microsoft YaHei", "Arial"
  }

  input, textarea, img {
      margin: 0px;
      padding: 0px;
  }

  .mian-box {
      box-sizing: border-box;
      padding: 0rem 2rem 1rem 2rem;
  }

  .top-title-box {
      font-size: 1.6rem;
      color: #333333;
      text-align: center;
      box-sizing: border-box;
      padding: 2rem 0rem 1rem 0rem;
  }

  .firs-text-box {
      font-size: 1rem;
      color: #333333;
      font-weight: bolder;
      line-height: 1.5rem;
      text-indent: 2rem;
  }

  .main-text {
      font-size: 1.2rem;
      font-weight: bolder;
      color: #333333;
      line-height: 1.5rem;
      text-indent: 2rem;
  }

  .text-tit-box {
      margin-top: 2.6rem;
      margin-bottom: 1rem;
      color: #333333;
      font-weight: 800;
  }

  .text-p {
      font-size: 1rem;
      color: #666666;
      font-weight: 300;
      letter-spacing: 0.06rem
  }

  .text-p-l {
      font-size: 1rem;
      color: #666666;
  }

  @media screen and (max-width:768px) {
      html {
          font-size: 13px;
      }

      .mian-box {
          padding: 0rem 1.2rem 1rem 1.2rem;
      }
  }
</style>