<template>
  <div>
    <!--头部信息开始-->
    <div class="public-hd whole-back">
      <div class="contanier">
        <div class="hd-top paddno-l">
          <div class="l-box f-l">
            <img src="../style/images/logo_index.png" />
          </div>
          <div class="r-box f-r">
            <div class="btn-login">
              <router-link
                :to="{ path: '/login' }"
                class="btn-login-a whole-col"
                >登录</router-link
              >
            </div>
          </div>
        </div>
        <div class="con-top">
          <div class="tp-caption">
            <span class="t-mk">中小学生综合素质评价</span>
            <div
              class="btn_download my_btn_download"
              style="cursor:pointer"
              @click="showEWM = true"
            >
              <span class="btn_download-a whole-col-hov">下载app</span>
            </div>
            <div
              class="btn_download my_btn_download"
              style="margin-top:20px;cursor:pointer"
              @click="downloadCS"
            >
              <a class="btn_download-a whole-col-hov">下载PC端</a>
            </div>
            <div
              class="btn_download my_btn_download"
              style="margin-top:20px;cursor:pointer"
              @click="download_ZZZD"
            >
              <a class="btn_download-a whole-col-hov">自助评价终端机</a>
            </div>
          </div>
          <div class="tp-img">
            <img src="../style/images/img_index.png" />
          </div>
        </div>
      </div>
    </div>
    <!--头部信息结束-->
    <!--数据分析开始-->
    <div class="assayplan-box">
      <div class="contanier">
        <div class="title-head">
          <span class="s-tit">Data analysis</span>
          <span class="b-tit">大数据分析 &frasl; 模型数据分析</span>
          <img class="title-head-img" src="../style/images/tit_line.jpg" />
        </div>
        <div class="assayplan-bd">
          <img src="../style/images/assayplan_img.png" />
        </div>
      </div>
    </div>
    <!--数据分析结束-->
    <!--产品特色开始-->
    <div class="product-box">
      <div class="contanier">
        <div class="title-head">
          <span class="s-tit">Product features</span>
          <span class="b-tit">产品特色</span>
          <img src="../style/images/tit_line.jpg" />
        </div>
        <div class="product-con">
          <div class="pdt-list">
            <img class="pdt-list-img" src="../style/images/img_01.jpg" />
            <div class="pdt-txt">
              借助智能设备实现对学生部分行为数据实现自动采集。
            </div>
          </div>
          <div class="pdt-list">
            <img class="pdt-list-img" src="../style/images/img_02.jpg" />
            <div class="pdt-txt">
              分析学生行为数据,发现偏离及时纠偏,实现过程反馈和控制。
            </div>
          </div>
          <div class="pdt-list">
            <img class="pdt-list-img" src="../style/images/img_03.jpg" />
            <div class="pdt-txt">
              分析每个学生的个体情况定制养成方案。
            </div>
          </div>
          <div class="pdt-list">
            <img class="pdt-list-img" src="../style/images/img_04.jpg" />
            <div class="pdt-txt">
              对于学生的发展潜能偏好进行基于数字化的分析。
            </div>
          </div>
          <div class="pdt-list">
            <img class="pdt-list-img" src="../style/images/img_05.jpg" />
            <div class="pdt-txt">
              与西南大学合作建立科学的评价模型，为学生成长和学校发展提供伴随式服务。
            </div>
          </div>
          <div class="pdt-list">
            <img class="pdt-list-img" src="../style/images/img_06.jpg" />
            <div class="pdt-txt">
              家长实时掌控孩子在校的表现情况。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--产品特色结束-->
    <!--成功案例开始-->
    <div class="case-box">
      <div class="contanier">
        <div class="title-head">
          <span class="s-tit">Successful case</span>
          <span class="b-tit">成功案例</span>
          <img src="../style/images/tit_line.jpg" />
        </div>
        <div class="case-con">
          <img src="../style/images/case_img.png" />
        </div>
      </div>
    </div>
    <!--成功案例结束-->

    <div class="features-box">
      <div class="contanier">
        <div class="features-con">
          <div class="ft-list">
            <div class="hd">
              合作
            </div>
            <div class="con">
              <img class="con-img" src="../style/images/ft-tit-line.jpg" />
              <span class="con-span">电话：023-6869 4689</span>
              <span class="con-span">邮箱：junfa01@163.com</span>
              <span class="con-span">地址：重庆石桥铺西部商城C座19-1</span>
            </div>
          </div>
          <div class="ft-list">
            <div class="ft-box">
              <img src="../style/images/code-img.jpg" />
              <span class="ft-box-span">扫一扫 客户端下载</span>
            </div>
          </div>
          <div class="ft-list">
            <div class="t-r" style="text-align: right;">
              <div class="hd">
                服务
              </div>
              <div class="con">
                <img class="con-img" src="../style/images/ft-tit-line.jpg" />
                <span class="con-span">电话：023-6869 4689</span>
                <span class="con-span">QQ： 6869 4689</span>
                <span class="con-span">邮箱： junfa02@163.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-box">
      Copyright © 2012 - 2022 重庆骏发科技发展有限公司. All Rights Reserved.
      <a target="_blank" href="https://beian.miit.gov.cn"
        >渝ICP备15010168号-2</a
      >
    </div>

    <!-- app 二维码下载弹窗 -->
    <el-dialog title="app下载" :visible.sync="showEWM" width="50%">
      <div class="app-erm">
        <img :src="require('@/style/images/图片2.png')" alt="" />
        <img :src="require('@/style/images/图片1.png')" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showEWM: false
    };
  },
  methods: {
    downloadCS() {
      window.location.href =
        "http://120.76.154.134:5009/appdownload/growthcompassv4.exe";
    },
    download_ZZZD() {
      window.location.href =
        "http://120.76.154.134:5009/appdownload/terminal.apk";
    }
  }
};
</script>
<style lang="scss" scoped>
.app-erm {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  padding-bottom: 40px;
  img {
    width: 200px;
    height: 200px;
  }
}
.my_btn_download {
  width: 220px;
}
</style>
