<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="operate-box">
        <el-button @click="addItem">新增对象</el-button>
        <div class="sel-box">
          <div class="czq-name">参与方式:</div>
          <el-select
            v-model="objectJoinType"
            @change="changeTotalCount"
            placeholder
          >
            <el-option
              v-for="item in filter_baseInfo.EvaluationObjectJoinType"
              :key="item.Key"
              :label="item.Value"
              :value="item.Key"
            ></el-option>
          </el-select>
        </div>

        <div class="sel-box">
          <div class="czq-name">评价方式:</div>
          <el-select
            v-model="evaluationMethod"
            @change="changeTotalCount"
            placeholder
          >
            <el-option
              v-for="item in filter_baseInfo.EvaluationMethod"
              :key="item.Key"
              :label="item.Value"
              :value="item.Key"
            ></el-option>
          </el-select>
        </div>

        <div class="sel-box">
          <div class="czq-name">状态:</div>
          <el-select v-model="status" @change="changeTotalCount" placeholder>
            <el-option
              v-for="item in filter_baseInfo.SYZT"
              :key="item.Key"
              :label="item.Value"
              :value="item.Key"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!--表格区开始-->
      <Table
        :isLoading="tableDataIsLoading"
        :tableData="
          tableData
            .filter(
              c =>
                (c.PJCYDXLB == objectJoinType || objectJoinType == 0) &&
                (c.PJFS == evaluationMethod || evaluationMethod == 0) &&
                c.SYZT === status
            )
            .slice(
              (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
              (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                pagerInfo.pageSize
            )
        "
        @editItem="editItem"
        @setIndex="openSetIndex"
        @delItem="delItem"
      />
      <!--表格区结束-->
      <div class="pagin-box">
        <div class="pagin-wz">
          <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
        </div>
      </div>
    </div>
    <FormDialog
      :baseInfo="baseInfo"
      :form="form"
      :visible="formVisiable"
      @closeDialog="formCloseDialog"
      @confirmDialog="formConfirmDialog"
    />
    <SetIndex
      :title="setIndexTitle"
      :treeData="indexData[PJFS - 1]"
      :checkedData="indexSetData"
      :visible="indexVisiable"
      :evaluationObj="evaluationObj"
      :setIndexType="setIndexType"
      @canceletIndex="canceletIndex"
      @saveSetIndex="saveSetIndex"
    />
  </div>
</template>

<script>
import Table from "./Table.vue";
import FormDialog from "./FormDialog.vue";
import SetIndex from "./SetIndex.vue";
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager,
    Table,
    FormDialog,
    SetIndex
  },
  data() {
    return {
      setIndexType: 0,
      setIndexTitle: "",
      PJFS: 1,
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      schoolId: "",
      tableDataIsLoading: false,
      tableData: [],
      baseInfo: {},
      filter_baseInfo: {},
      form: {},
      formVisiable: false,
      indexVisiable: false,
      objectJoinType: 0,
      evaluationMethod: 0,
      status: 1,
      evaluationObj: "",
      indexData: [],
      objectId: "",
      indexSetData: []
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    const user = JSON.parse(localStorage.getItem("userinfo"));
    if (user) {
      if (user.SSZZId) {
        this.schoolId = user.SSZZId;
      }
      this.user = user;
    }
    this.getBaseData();
    this.getData();
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    formCloseDialog() {
      this.formVisiable = false;
    },
    formConfirmDialog(data) {
      this.formVisiable = false;
      this.submit(data);
    },
    getBaseData() {
      this.baseInfo.GradeList = [];
      this.$api.post(
        "/v1/evaluationobject/GetEvaluationObjectBaseInfo",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.baseInfo = r.Target;
            this.filter_baseInfo = JSON.parse(JSON.stringify(this.baseInfo));
            this.filter_baseInfo.EvaluationObjectJoinType.unshift({
              Key: 0,
              Value: "全部"
            });
            this.filter_baseInfo.EvaluationMethod.unshift({
              Key: 0,
              Value: "全部"
            });
          }
        }
      );
    },
    getData() {
      this.tableDataIsLoading = true;
      this.$api.post(
        "/v1/evaluationobject/GetEvaluationObjectList",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;
            this.pagerInfo.totalCount = this.tableData.length;
          }
          this.tableDataIsLoading = false;
          this.getIndexData();
        }
      );
    },
    submit(data) {
      data.CJR = this.user.XSM;
      data.SSXX = this.schoolId;
      this.$api.post(
        "/v1/evaluationobject/EditEvaluationObject",
        {
          token: this.token,
          EvaluationObjectInfo: data
        },
        r => {
          if (r.Code === 0) {
            const result = r.Target;
            this.$message({
              type: "success",
              message: r.Message
            });
            if (data.Id && data.Id.length) {
              this.tableData.splice(
                this.tableData.findIndex(c => c.Id === data.Id),
                1,
                data
              );
            } else {
              data.Id = result.Id;
              this.tableData.unshift(data);
            }
            this.pagerInfo.totalCount = this.tableData.length;
          }
        }
      );
    },
    editItem(data) {
      this.form = JSON.parse(JSON.stringify(data));
      this.formVisiable = true;
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "/v1/evaluationobject/DeleteEvaluationObject",
            {
              token: this.token,
              Id: data.Id
            },
            r => {
              if (r.Code === 0) {
                // let result = r.Target;
                this.$message({
                  type: "success",
                  message: r.Message
                });
                this.tableData.splice(
                  this.tableData.findIndex(c => c.Id === data.Id),
                  1
                );
                this.pagerInfo.totalCount = this.tableData.length;
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    addItem() {
      this.form = {};
      this.formVisiable = true;
    },
    getIndexData() {
      this.$set(this, "indexData", []);
      // 通用指标
      this.$api.post(
        "/v1/schoolindex/GetTreeAllData",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.indexData[0] = r.Target;
            // this.indexData=this.indexData.filter(c=>c.children.length>0)
          }
        }
      );
      // 星星指标
      this.$api.post(
        "/v1/starsindex/GetStarsIndex",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.indexData[1] = r.Target.TreeDataList;
            // this.indexData=this.indexData.filter(c=>c.children.length>0)
          }
        }
      );
      // 问卷
      this.$api.post(
        "/v1/questionnaire/GetQuestionnaireList",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            const result = r.Target;
            const wjList = [];
            for (let i = 0; i < result.filter(c => c.SYZT === 1).length; i++) {
              wjList.push({
                value: result[i].Id,
                parentCode: "",
                label: result[i].WJMC,
                children: []
              });
            }
            this.indexData[2] = wjList;
            // this.indexData=this.indexData.filter(c=>c.children.length>0)
          }
        }
      );
    },
    openSetIndex(data) {
      if (data.PJFS === 1) {
        this.setIndexTitle = "设置通用指标";
        this.setIndexType = 1;
      } else if (data.PJFS === 2) {
        this.setIndexTitle = "设置星星指标";
        this.setIndexType = 2;
      } else if (data.PJFS === 3) {
        this.setIndexTitle = "设置问卷";
        this.setIndexType = 3;
      }
      this.PJFS = data.PJFS;
      this.objectId = data.Id;
      // this.indexSetData = JSON.parse(data.EvaluationObjectIndexListStr);
      this.indexSetData = data.EvaluationObjectIndexList;
      this.indexVisiable = true;
    },
    canceletIndex() {
      this.indexVisiable = false;
    },
    saveSetIndex(indexList) {
      this.indexVisiable = false;
      const dataList = [];
      const newindexData = [];
      for (const index of indexList) {
        dataList.push({ ZBId: index.value, PJCYDXId: this.objectId });
        newindexData.push(index.value);
      }
      const user = JSON.parse(localStorage.getItem("userinfo"));
      this.$api.post(
        "/v1/evaluationobject/SetIndexForEvaluationObject",
        {
          token: this.token,
          SetIndexList: dataList,
          UserId: user.Id,
          UserName: user.XSM,
          IndexType: this.setIndexType,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            const result = this.tableData.find(c => c.Id === this.objectId);
            if (result) {
              result.EvaluationObjectIndexList = newindexData;
            }
          }
        }
      );
    },
    changeTotalCount() {
      this.pagerInfo.totalCount = this.tableData.filter(
        c =>
          (c.PJCYDXLB == this.objectJoinType || this.objectJoinType == 0) &&
          (c.PJFS == this.evaluationMethod || this.evaluationMethod == 0) &&
          c.SYZT === this.status
      ).length;
    }
  }
};
</script>

<style scoped>
.sel-box {
  display: inline;
}
</style>
