<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <div class="leftrig-left-box" style="width: -webkit-calc(11% - 0px);">
        <!--树-->
        <Tree
          :isLoading="treeDataIsLoading"
          :treeData="treeData"
          @nodeClick="nodeClick"
          @filterNode="filterNode"
        />
      </div>
      <div class="leftrig-rig-box" style="max-width: calc(88% - 1px);">
        <div class="margno-t">
          <div class="table-er-box">
            <!--按钮-->
            <el-button
              v-if="!parentId && parentId.length == 0"
              @click="
                formDialogVisiable = true;
                title = '新增指标';
              "
              >新增指标</el-button
            >
            <div class="prompt-title-box" style="color: red;" v-else>
              星星指标最多两级
            </div>
          </div>
          <!--内容-->
          <Table
            :isLoading="tableDataIsLoading"
            :tableData="
              tableData
                .filter((c) => c.FJId == nodeId || c.FJId == c.Id)
                .slice(
                  (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
                  (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                    pagerInfo.pageSize
                )
            "
            :dimensions="dimensions"
            @editData="editData"
            @delItem="delItem"
          />
          <div class="pagin-box">
            <div class="pagin-wz">
              <!--分页-->
              <Pager :pagerInfo="dynamicPager" @changeIndex="changeIndex" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormDialog
      :dimensionsSelectDisable="isChild"
      :visible="formDialogVisiable"
      :dimensions="dimensions"
      :form="formData"
      :title="title"
      @closeDialog="closeFormDialog"
      @confirmDialog="confirmDialog"
    />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Tree from "@/components/Tree.vue";
import Table from "@/views/manager/starsIndex/Table.vue";
import FormDialog from "@/views/manager/starsIndex/FormDialog.vue";
import { Compare } from "@/style/js/Common.js";
export default {
  components: {
    Tree,
    Pager,
    Table,
    FormDialog,
  },
  data() {
    return {
      title: "",
      isChild: false,
      treeDataIsLoading: false,
      tableDataIsLoading: false,
      parentId: "",
      nodeId: "",
      tableData: [],
      dimensions: [],
      formData: {},
      pagerInfo: {
        pageIndex: 1,
        pageSize: 9,
        totalCount: 0,
      },
      token: "",
      user: {},
      schoolId: "",
      treeData: [
        {
          label: "全部",
          value: "",
          parentCode: "",
          children: [],
        },
      ],
      formDialogVisiable: false,
      LX: 0,
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.resetData();
    this.getAreaId();
    this.getTableData();
  },
  methods: {
    resetData() {
      // 根据不同路由地址，返回不同数据
      if (this.$route.fullPath === "/starsindex1") {
        this.LX = 1;
      } else if (this.$route.fullPath === "/starsindex2") {
        this.LX = 2;
      }
    },
    delItem(data) {
      this.$api.post(
        "/v1/starsindex/DelStarsIndex",
        {
          token: this.token,
          Id: data.Id,
        },
        (r) => {
          if (r.Code === 0) {
            this.updateTableData(data, data, 1);
            this.updateTreeData(data, data, 1);
            this.$message({
              type: "success",
              message: r.Message,
            });
          }
        }
      );
    },
    editData(data) {
      this.title = "编辑指标";
      this.formData = data;
      this.formDialogVisiable = true;
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    // eslint-disable-next-line no-unused-vars
    nodeClick(data, node) {
      this.changeIndex(1);
      this.nodeId = data.value;
      this.parentId = data.parentCode;
      this.isChild = !!(data.value && data.value.length > 0);
    },
    getTableData() {
      this.treeDataIsLoading = true;
      this.tableDataIsLoading = true;
      this.$api.post(
        "/v1/starsindex/GetStarsIndex",
        {
          token: this.token,
          SchoolId: this.schoolId,
          LX: this.LX,
        },
        (r) => {
          if (r.Code === 0) {
            this.tableData = r.Target.StarsIndexList;
            this.treeData[0].children = r.Target.TreeDataList;
          }
          this.tableDataIsLoading = false;
          this.treeDataIsLoading = false;
        }
      );
    },
    closeFormDialog() {
      this.formData = {};
      this.formDialogVisiable = false;
    },
    confirmDialog(data) {
      this.formData = {};
      this.formDialogVisiable = false;
      this.submitForm(data);
    },
    submitForm(data) {
      data.CJR = this.user.XSM;
      data.SSXX = this.schoolId;
      data.FJId = this.nodeId;
      data.LX = this.LX;
      this.$api.post(
        "/v1/starsindex/EditStarsIndex",
        {
          token: this.token,
          ZBXingXingInfo: data,
        },
        (r) => {
          if (r.Code === 0) {
            const result = r.Target;
            result.SSWDStr = data.SSWDStr;
            this.updateTableData(result, data);
            this.updateTreeData(result, data);
            this.$message({
              type: "success",
              message: r.Message,
            });
          }
        }
      );
    },
    getAreaId() {
      this.$api.post(
        "/v1/areaschool/GetAreaSch",
        {
          token: this.$store.state.token,
          Id: this.schoolId,
        },
        (r) => {
          const resp = r.Target;
          const data = resp.Target;
          if (data && data.QYId) {
            this.areaId = data.QYId;
            this.getDimensions();
          }
        }
      );
    },
    getDimensions() {
      const pageinfo = {
        PageIndex: 0,
        PageSize: parseInt(Number.MAX_SAFE_INTEGER / 10000000),
      };
      this.dimensions = [];
      this.$api.post(
        "/v1/dimension/GetDimensionTree",
        {
          LX: this.LX,
          token: this.token,
          AreaId: this.areaId,
          PagerInfo: pageinfo,
        },
        (r) => {
          this.dimensions = JSON.parse(JSON.stringify(r.Target));
        }
      );
    },
    updateTableData(data, source, isDel) {
      this.tableDataIsLoading = true;
      if (isDel) {
        this.tableData.splice(
          this.tableData.findIndex((c) => c.Id === data.Id),
          1
        );
      } else {
        if (source.Id && source.Id.length) {
          this.tableData.splice(
            this.tableData.findIndex((c) => c.Id === data.Id),
            1,
            data
          );
        } else {
          this.tableData.unshift(data);
        }
      }
      this.tableData.sort(Compare("PXH"));
      this.tableDataIsLoading = false;
    },
    updateTreeData(data, source, isDel) {
      this.treeDataIsLoading = true;
      const newnode = {
        label: data.ZBMC,
        value: data.Id,
        parentCode: data.FJId || "",
        ordernum: data.PXH,
        children: [],
      };
      if (isDel) {
        newnode.label = "remove";
      }
      this.$children
        .find((c) => c.$options._componentTag === "Tree")
        .$refs.tree.filter(newnode);
      this.tableData.sort(Compare("PXH"));
      this.treeDataIsLoading = false;
    },
    filterNode(newnode, data) {
      if (newnode.value === data.parentCode) {
        newnode.children.push(data);
        newnode.children.sort(Compare("ordernum"));
      }
      if (newnode.parentCode === data.value) {
        const index = data.children.findIndex((c) => c.value === newnode.value);
        if (index === -1) {
          data.children.unshift(newnode);
        } else {
          if (newnode.label === "remove") {
            data.children.splice(index, 1);
          } else {
            data.children.splice(index, 1, newnode);
          }
        }
        data.children.sort(Compare("ordernum"));
      }
      return true;
    },
  },
  computed: {
    dynamicPager() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagerInfo.totalCount = this.tableData.filter(
        (c) => c.FJId === this.nodeId
      ).length;
      return this.pagerInfo;
    },
  },
  watch: {
    $route: {
      handler: "resetData",
    },
  },
};
</script>
