<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->
      <div class="tab-shu-box margno-t">
        <el-form :model="data" ref="ruleForm">
          <table cellpadding="0" cellspacing="0">
            <tbody>
              <tr>
                <td class="tab-shu-titl">考试名称</td>
                <td>
                  <el-form-item
                    prop="KSMC"
                    :rules="[
                      {
                        required: true,
                        message: '请输入考试名称',
                        trigger: 'change'
                      },
                      {
                        min: 1,
                        max: 50,
                        message: '不能超过50 个字符',
                        trigger: 'change'
                      }
                    ]"
                    :inline-message="true"
                    style="margin-bottom: unset;"
                  >
                    <el-input
                      class="jf-inpt"
                      style="width: 400px;"
                      size="medium"
                      v-model="data.KSMC"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td class="tab-shu-titl">考试类型</td>
                <td>
                  <div style="margin-left: 8px; display: inline-block;">
                    <el-form-item
                      prop="KSXZM"
                      :rules="[
                        {
                          required: true,
                          message: '请选择考试类型',
                          trigger: 'change'
                        }
                      ]"
                      :inline-message="true"
                      style="margin-bottom: unset;"
                    >
                      <el-radio-group v-model="data.KSXZM">
                        <el-radio
                          v-for="(item, index) in kslx"
                          :key="index"
                          :label="item.value"
                          >{{ item.label }}</el-radio
                        >
                      </el-radio-group>
                    </el-form-item>
                  </div>
                </td>
              </tr>
              <!-- 展示范围 -->
              <tr>
                <td class="tab-shu-titl">展示范围</td>
                <td>
                  <div style="margin-left: 8px; display: inline-block;">
                    <el-form-item prop="ZSFW" :inline-message="true">
                      <el-radio-group v-model="data.ZSFW">
                        <el-radio :label="0">不展示</el-radio>
                        <el-radio :label="1">班级平均</el-radio>
                        <el-radio :label="2">年级平均</el-radio>
                        <el-radio :label="3">班级和年级平均</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="tab-shu-titl">课程</td>
                <td>
                  <div
                    class="tab-td-box"
                    v-for="(item, index) in basicInfo.GradeList"
                    :key="index"
                    style="height:auto"
                  >
                    <div
                      class="disilb-box-l"
                      style="width: 80px;vertical-align:top"
                    >
                      <div class="tablil-xz-name c-g-9">{{ item.Name }}:</div>
                    </div>
                    <div style="display: inline-block;width: 89%;">
                      <el-checkbox
                        :disabled="
                          basicInfo.CourseList.filter(
                            c => c.Grade == item.Number
                          ).length === 0
                        "
                        style="margin-right: 30px;margin-bottom:6px"
                        :indeterminate="courseCheck[index].isIndeterminate"
                        v-model="courseCheck[index].checkAll"
                        @change="
                          handleCheckAll(
                            courseCheck[index].checkAll,
                            item.Number,
                            item.MultiSchool,
                            index
                          )
                        "
                        >全选</el-checkbox
                      >
                      <el-checkbox-group
                        style="display: inline-block;"
                        v-model="courseCheck[index].KSNJXKList"
                        @change="
                          handleChecked(courseCheck[index].KSNJXKList, index)
                        "
                      >
                        <!-- :disabled="handleDisabled(course)" -->
                        <el-checkbox
                          v-for="(course, i) in basicInfo.CourseList.find(
                            c => c.Grade === item.Number
                          )
                            ? basicInfo.CourseList.find(
                                c => c.Grade === item.Number
                              ).GradeCourseList
                            : []"
                          :label="courseCheckData[index][i]"
                          :key="i"
                          >{{ course.Name }}</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="tab-shu-titl">录入时间段</td>
                <td>
                  <el-form-item
                    :prop="'dateRange'"
                    :rules="[
                      {
                        validator: (rule, value, callback) => {
                          if (value.length < 2 || !value[0] || !value[1]) {
                            return callback(new $window.Error(rule.message));
                          } else return callback();
                        },
                        message: '请选择录入时间',
                        trigger: 'change'
                      }
                    ]"
                    :inline-message="true"
                    style="margin-bottom: unset;"
                  >
                    <el-date-picker
                      size="medium"
                      style="background-color: #fbfbfb;"
                      v-model="data.dateRange"
                      type="daterange"
                      class="jf-inpt"
                      range-separator="~"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td class="tab-shu-titl">录入人</td>
                <td>
                  <div style="margin-left: 8px;">
                    <el-form-item
                      prop="LRRLX"
                      :rules="[
                        {
                          required: true,
                          message: '请选择录入人',
                          trigger: 'change'
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (
                              value == 3 &&
                              (!rule.zdlrr || rule.zdlrr.length === 0)
                            ) {
                              return callback(new $window.Error(rule.message));
                            } else return callback();
                          },
                          message: '请选择指定录入人',
                          trigger: 'change',
                          zdlrr: data.KSLRRYBList
                        }
                      ]"
                      :inline-message="true"
                      style="margin-bottom: unset;"
                    >
                      <el-radio-group v-model="data.LRRLX">
                        <el-radio
                          v-for="(item, index) in lrrlx"
                          :key="index"
                          :label="item.value"
                          >{{ item.label }}</el-radio
                        >
                      </el-radio-group>
                      <div class="tab-td-ovebox" v-show="data.LRRLX === 3">
                        <div
                          class="name-del-box"
                          v-for="(item, index) in data.KSLRRYBList
                            ? data.KSLRRYBList.filter(c => c.BSLX === 1)
                            : []"
                          :key="index"
                        >
                          {{
                            basicInfo.TeacherList.find(c => c.Id === item.CZRId)
                              ? basicInfo.TeacherList.find(
                                  c => c.Id === item.CZRId
                                ).Name
                              : ""
                          }}
                          <div class="name-del-zz">
                            <a
                              class="name-del-zza"
                              @click="removeTeacher(item.CZRId, 1)"
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="data.LRRLX === 3"
                        class="tab-wz-buttl"
                        style="margin-left: 15px;"
                      >
                        <a
                          class="tab-wz-butta c-guree"
                          @click="openTeacherDialog(1)"
                          >去指定录入人</a
                        >
                      </div>
                    </el-form-item>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="tab-shu-titl">修正人</td>
                <td>
                  <div
                    class="name-del-box"
                    v-for="(item, index) in data.KSLRRYBList
                      ? data.KSLRRYBList.filter(c => c.BSLX === 2)
                      : []"
                    :key="index"
                  >
                    {{
                      basicInfo.TeacherList.find(c => c.Id === item.CZRId)
                        ? basicInfo.TeacherList.find(c => c.Id === item.CZRId)
                            .Name
                        : ""
                    }}
                    <div class="name-del-zz">
                      <a
                        class="name-del-zza"
                        @click="removeTeacher(item.CZRId, 2)"
                      ></a>
                    </div>
                  </div>
                  <div class="tab-wz-buttl">
                    <a
                      class="tab-wz-butta c-guree"
                      @click="openTeacherDialog(2)"
                      >添加</a
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form>
      </div>
      <!--表格区结束-->
      <div class="cent-bott-butt">
        <a class="cent-bott-butta" @click="submit">确&nbsp;认</a>
        <a class="cent-bott-butta" @click="cancel">取&nbsp;消</a>
      </div>
    </div>
    <el-dialog
      top="15%"
      class="jf-form"
      width="30%"
      title="选择老师"
      :visible.sync="teacherListDialogVisible"
      :close-on-click-modal="false"
      append-to-body
      :before-close="closeDialogTeacherListDialog"
    >
      <div class="stud-box">
        <div style="font-size: 14px; margin-bottom: 10px;">
          <el-input
            style="width: 200px;"
            v-model="searchTeacherName"
            placeholder="请输入关键字"
          ></el-input>
        </div>
        <ul class="stud-box-ul" style="height: 250px;">
          <li
            v-for="item in basicInfo.TeacherList.filter(
              c => c.Name.indexOf(searchTeacherName) != -1
            )"
            :key="item.Id"
            :class="[
              'stud-box-ulli',
              { 'stud-ul-sty': tmpManagerIds.indexOf(item.Id) != -1 }
            ]"
            @click="selectedTeacher(item.Id)"
          >
            <img
              class="stud-box-ulli-img"
              :src="
                item.Photo
                  ? item.Photo
                  : item.Gender === 2
                  ? $store.state.defaultTeacherFemale
                  : $store.state.defaultTeacherMale
              "
            />
            <div class="stud-name">{{ item.Name }}</div>
          </li>
        </ul>
      </div>
      <div class="cent-bott-butt">
        <el-button @click="confirmSelectTeacher">确定</el-button>
        <el-button @click="closeDialogTeacherListDialog">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    },
    schoolId: {
      type: String,
      default: ""
    },
    basicInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    examinationData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    teacherCourse: Object
  },
  data() {
    return {
      tmpManagerIds: [],
      searchTeacherName: "",
      teacherListDialogVisible: false,
      dateRange: [],
      data: {},
      kslx: [
        {
          value: 1,
          label: "月考"
        },
        {
          value: 2,
          label: "期中考试"
        },
        {
          value: 3,
          label: "期末考试"
        },
        {
          value: 4,
          label: "其他"
        }
      ],
      lrrlx: [
        {
          value: 1,
          label: "科任老师"
        },
        {
          value: 2,
          label: "班主任"
        },
        {
          value: 3,
          label: "指定录入人"
        }
      ],
      courseCheck: [],
      courseCheckData: [],
      selectTeacherType: 0
    };
  },
  created: function() {
    if (JSON.stringify(this.basicInfo) === "{}") {
      this.initBasicInfo();
    } else {
      this.init();
    }
  },
  methods: {
    init() {
      scrollTo(0, 0);
      // const params = decodeURIComponent(this.$route.query.data);
      if (JSON.stringify(this.examinationData) !== "{}") {
        this.data = this.$set(this, "data", this.examinationData);
      } else {
        this.$set(this, "data", {});
        this.$set(this.data, "KSNJXKList", []);
        this.$set(this.data, "KSLRRYBList", []);
      }
      let index = 0;
      for (const item of this.basicInfo.GradeList) {
        this.courseCheckData.push([]);
        const getGradeCourse = this.basicInfo.CourseList.find(
          c => c.Grade === item.Number
        );
        if (getGradeCourse != null && getGradeCourse.GradeCourseList != null) {
          this.courseCheckData[index] = getGradeCourse.GradeCourseList.map(
            c => ({
              NJId: item.Id,
              KCId: c.Id,
              KSNJ: item.Number,
              XDLX: item.MultiSchool
            })
          );
        }
        var datas = [];
        if (
          this.data.KSNJXKList != null &&
          this.data.KSNJXKList.length > 0 &&
          this.courseCheckData[index] != null &&
          this.courseCheckData[index].length > 0
        ) {
          const gradeCouse = this.data.KSNJXKList.filter(
            c => c.KSNJ === item.Number
          ).map(c => c.KCId);
          if (gradeCouse != null && gradeCouse.length > 0) {
            datas = this.courseCheckData[index].filter(c =>
              gradeCouse.includes(c.KCId)
            );
          }
        }
        //const datas = this.data.KSNJXKList
        //  ? this.courseCheckData[index].filter(c =>
        //      this.data.KSNJXKList.filter(c => c.KSNJ === item.Number)
        //        .map(c => c.KCId)
        //        .includes(c.KCId)
        //    )
        //  : [];
        this.courseCheck.push({
          isIndeterminate: !!(
            datas.length < this.courseCheckData[index].length &&
            datas.length > 0
          ),
          checkAll:
            datas.length > 0 &&
            datas.length === this.courseCheckData[index].length,
          KSNJXKList: datas
        });
        index++;
      }
      if (this.data.LRKSSJ && this.data.LRJSSJ) {
        this.dateRange.push(this.data.LRKSSJ);
        this.dateRange.push(this.data.LRJSSJ);
      }
      this.$set(this.data, "courseCheck", this.courseCheck);
      this.$set(this.data, "courseCheckData", this.courseCheckData);
      this.$set(this.data, "dateRange", this.dateRange);
    },
    initBasicInfo() {
      this.$api.post(
        "/v1/examination/GetExaminationBasicInfo",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.basicInfo = r.Target;
            this.init();
          }
        }
      );
    },
    closeDialogTeacherListDialog() {
      this.teacherListDialogVisible = false;
    },
    confirmSelectTeacher() {
      this.teacherListDialogVisible = false;
      this.data.KSLRRYBList = this.data.KSLRRYBList.filter(
        c => c.BSLX !== this.selectTeacherType
      );
      const data = [];
      for (const item of this.tmpManagerIds) {
        data.push({ CZRId: item, BSLX: this.selectTeacherType });
      }
      this.data.KSLRRYBList = this.data.KSLRRYBList.concat(data);
    },
    submit() {
      if (this.data.dateRange && this.data.dateRange.length === 2) {
        this.data.LRKSSJ = this.data.dateRange[0];
        this.data.LRJSSJ = this.data.dateRange[1];
      }
      this.data.KSNJXKList = [];
      for (const item of this.courseCheck
        .filter(c => c.KSNJXKList.length > 0)
        .map(c => c.KSNJXKList)) {
        this.data.KSNJXKList = this.data.KSNJXKList.concat(item);
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$api.post(
            "/v1/examination/EditExamination",
            {
              token: this.token,
              KSBInfo: this.data,
              KSNJXKList: this.data.KSNJXKList,
              KSLRRYBList: this.data.KSLRRYBList,
              UserId: this.user.Id,
              UserName: this.user.XSM,
              SchoolId: this.schoolId
            },
            r => {
              if (r.Code === 0) {
                let txt = "新增";
                if (this.data.Id) {
                  txt = "修改";
                }
                this.$message({
                  type: "success",
                  message: txt + "成功",
                  showClose: true
                });
                this.cancel();
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$router.push({
        // name: "examinationmanagerList"
        name: "examinationmanager"
      });
    },
    handleCheckAll(val, nj, xd, index) {
      this.courseCheck[index].KSNJXKList = val
        ? this.courseCheckData[index].filter(item => item.KSNJ === nj)
        : [];
      this.courseCheck[index].isIndeterminate = false;
    },
    handleChecked(val, index) {
      const checkedCount = val.length;
      this.courseCheck[index].checkAll =
        checkedCount === this.basicInfo.CourseList.length;
      this.courseCheck[index].isIndeterminate =
        checkedCount > 0 && checkedCount < this.basicInfo.CourseList.length;
    },
    openTeacherDialog(type) {
      this.selectTeacherType = type;
      this.tmpManagerIds = [];
      this.searchTeacherName = "";
      const dataList = this.data.KSLRRYBList.filter(c => c.BSLX === type);
      for (const item of dataList) {
        this.tmpManagerIds.push(item.CZRId);
      }
      this.teacherListDialogVisible = true;
    },
    selectedTeacher(id) {
      const index = this.tmpManagerIds.indexOf(id);
      if (index !== -1) {
        this.tmpManagerIds.splice(index, 1);
      } else {
        this.tmpManagerIds.push(id);
      }
    },
    removeTeacher(id, type) {
      const index = this.data.KSLRRYBList.findIndex(
        c => c.CZRId === id && c.BSLX === type
      );
      if (index !== -1) {
        this.data.KSLRRYBList.splice(index, 1);
      }
    },
    // 禁用全选
    handleDisabledAll(courseList) {
      if (
        courseList &&
        courseList[0] &&
        courseList[0].GradeCourseList &&
        this.teacherCourse.ClassScheduleList
      ) {
        return !courseList[0].GradeCourseList.some(item => {
          return this.teacherCourse.ClassScheduleList.some(
            it =>
              it.ClassId.split("-")[0] === item.gradeId &&
              it.CourseId === item.Id
          );
        });
      } else return true;
    },
    // 禁用老师不教的课程
    handleDisabled(course) {
      if (this.teacherCourse.ClassScheduleList) {
        return !this.teacherCourse.ClassScheduleList.some(
          item =>
            item.ClassId.split("-")[0] === course.gradeId &&
            item.CourseId === course.Id
        );
      } else return false;
    }
  }
};
</script>
