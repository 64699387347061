<template>
  <div class="cont-whole-box" style="min-height: 500px;">
    <div class="leftrig-box">
      <div class="leftrig-left-box">
        <Tree
          :treeData="treedata"
          ref="treemenu"
          :enableCheck="false"
          :defaultProps="defaultProps"
          nodeKey="id"
          expandAll
          @nodeClick="handleNodeClick"
        />
      </div>
      <div class="leftrig-rig-box">
        <!--表格区开始-->
        <div class="tab-heng-box margno-t">
          <div class="table-er-box">
            <!--按钮-->
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openaddCatalog('1')"
              >新增目录</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openaddCatalog('2')"
              >新增菜单</el-button
            >

            <el-button type="primary" icon="el-icon-edit" @click="deletetree"
              >删除</el-button
            >
          </div>
          <br />
          <br />
          <br />
          <el-form
            ref="CDBInfo"
            :model="CDBInfo"
            label-width="80px"
            v-if="dialogVisibleForm"
          >
            <el-form-item :label="ssmc">
              <el-input v-model="CDBInfo.CDMC"></el-input>
            </el-form-item>
            <el-form-item label="排序号">
              <el-input v-model="CDBInfo.PXH"></el-input>
            </el-form-item>
            <el-form-item label="权限等级" v-if="dialogVisibleFormQXDJ">
              <el-radio-group v-model="CDBInfo.QXDJ">
                <el-radio :label="1">全部</el-radio>
                <el-radio :label="2">局部</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="2X图标" v-if="dialogVisibleForm2x">
              <div style="width: 80%;">
                <Upload
                  @getResultUrl="getUpload2XUrl"
                  :left="0"
                  :width="100"
                  :height="100"
                  :itemPic="CDBInfo.TB ? CDBInfo.TB : ''"
                />
              </div>
            </el-form-item>
            <el-form-item label="3X图标" v-if="dialogVisibleForm3x">
              <div style="width: 80%;">
                <Upload
                  @getResultUrl="getUploadUrl"
                  :left="0"
                  :width="100"
                  :height="100"
                  :itemPic="CDBInfo.SBTB ? CDBInfo.SBTB : ''"
                />
              </div>
            </el-form-item>
            <el-form-item label="对应菜单" v-if="dialogVisibleFormdycd">
              <el-input
                v-model="cdbdyname"
                style="width: 80%;"
                :disabled="true"
              ></el-input
              >&nbsp;
              <el-button type="primary" @click="openacitivty">选择</el-button>
            </el-form-item>
            <!-- v-if="h5path" -->
            <el-form-item label="H5地址">
              <el-input
                v-model="CDBInfo.WBDZ"
                placeholder="菜单跳转地址"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitshowdata"
                >确 定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!--表格区结束-->
      </div>
    </div>
    <!--弹出框-->
    <el-dialog
      title="对应菜单"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="CDBInfo" label-width="80px">
        <div
          class="popv-cent"
          style="padding: 10px; position: relative; height: 516px;"
        >
          <div class="popo-xk-box">
            <div class="top-butt-box">
              <!--"width: --px"可根据内容调节宽度最好不要超过700px-->
              <div
                :class="item.selectclass"
                v-for="(item, index) in activitytitle"
                :key="index"
                @click="selectactivitytype(item, index)"
              >
                {{ item.selectname }}
              </div>
            </div>
          </div>
          <div class="popo-xk-cent" style="height: 450px;">
            <div
              class="disilb-box"
              v-for="(item, index) in moduleList"
              :key="index"
              @click="checkedactivity(item, index)"
            >
              <div :class="item.ActivityClass"></div>
              <div class="tablil-xz-name epp-ov" :title="item.ActivityName">
                {{ item.ActivityName }}
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkedselection">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style>
.cdcolorml {
  color: #ffa500;
}

.cdcolormk {
  color: #32cd32;
}
</style>
<script>
import Upload from '@/components/Upload.vue'
import Tree from '@/components/Tree.vue'
export default {
  name: 'MenuList',
  components: {
    Upload,
    Tree
  },
  data() {
    return {
      uploadHtml: '<a class="c-blue" style="cursor:pointer" >上传</a>',
      token: this.$store.state.token,
      dialogVisibleForm: false,
      dialogVisibleForm2x: false,
      dialogVisibleForm3x: false,
      dialogVisibleFormdycd: false,
      dialogFormVisible: false,
      h5path: false,
      ssmc: '菜单名称',
      cdbdyname: '',
      diafrom: {
        mclist: [],
        hdlist: []
      },
      selectNodeId: '',
      selectNodeCj: 0,
      selectNodeSSYY: 0,
      selectNodeCDLX: 0,
      selecttNode: {},
      CDBInfo: {
        Id: '',
        FJId: '0',
        CDMC: '',
        DYGNMK: 0,
        CDGL: '',
        SSXX: '',
        TB: '',
        SBTB: '',
        CDLB: 0,
        PXH: 0,
        SSYY: 0,
        RYID: '',
        WBDZ: '',
        CJ: 0,
        CDLX: 0,
        QXDJ: 2
      },
      treedata: [],
      defaultProps: {
        children: 'children',
        label: 'label',
        parent: 'fjId',
        class: 'nodecolor'
      },
      props: {
        label: 'label',
        children: 'children'
      },
      moduleList: [],
      checkNodes: [],
      userName: '',
      userId: '',
      menutype: '',
      activitytitle: [],
      fixedactivitylist: [],
      customactivitylist: [],
      customreport: [],
      scanlist: []
    }
  },
  created: function() {
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.CDBInfo.SSXX = userinfo.SSZZId
    this.userName = userinfo.DLM
    this.userId = userinfo.Id

    // 加载tree
    this.$api.post(
      'v1/menus/GetMenuTreeBySchoolId',
      { SchoolId: this.CDBInfo.SSXX, token: this.token },
      r => {
        if (r.Code === 0) {
          this.treedata = r.Target
        }
      }
    )
    this.activitytitle = [
      {
        selectclass: 'cent-butt cent-butt-sty whole-col whole-col-hov',
        selectname: '固定活动',
        selecttype: 1
      },
      {
        selectclass: 'cent-butt whole-col-hov',
        selectname: '自定义活动及相关报表',
        selecttype: 2
      },
      {
        selectclass: 'cent-butt whole-col-hov',
        selectname: '自定义报表',
        selecttype: 4
      }
    ]
  },
  methods: {
    // tree选中
    handleNodeClick(data, node) {
      this.selecttNode = node
      this.selectNodeId = data.id
      this.selectNodeSSYY = data.type
      this.menutype = data.cdtype
      this.selectNodeCDLX = data.cdlx
      this.selectNodeCj = data.cj
      this.dialogVisibleForm = false
      this.detail()
    },
    // 上传图片路径
    getUploadUrl(filePath) {
      this.CDBInfo.SBTB = filePath
    },
    // 上传图片路径
    getUpload2XUrl(filePath) {
      this.CDBInfo.TB = filePath
    },
    // 刷新列表
    reflist: function() {
      // 加载tree
      this.$api.post(
        'v1/menus/GetMenuTreeBySchoolId',
        { SchoolId: this.CDBInfo.SSXX, token: this.token },
        r => {
          if (r.Code === 0) {
            this.treedata = r.Target
          }
        }
      )
    },
    // 打开新增
    openaddCatalog: function(type) {
      if (this.selectNodeCj === 1) {
        this.$message({
          type: 'error',
          message: '固定层级不能新增！',
          showClose: true
        })
        return
      }
      if (this.menutype === 2) {
        this.$message({
          type: 'error',
          message: '菜单下不能创建',
          showClose: true
        })
        return
      }

      if (this.selectNodeCDLX === 0) {
        this.$message({
          type: 'error',
          message: '请选择二级目录',
          showClose: true
        })
        return
      }

      if (this.selectNodeSSYY === 0) {
        this.$message({
          type: 'error',
          message: '未选中所属目录',
          showClose: true
        })
        return
      }
      if (this.menutype === 2 && type === '1') {
        this.$message({
          type: 'error',
          message: '菜单下不能创建目录',
          showClose: true
        })
        return
      }
      this.h5path = false
      this.CDBInfo.Id = ''
      this.CDBInfo.CDMC = ''
      this.CDBInfo.TB = ''
      this.CDBInfo.SBTB = ''
      this.cdbdyname = ''
      this.CDBInfo.CDLB = type
      this.CDBInfo.CDGL = ''
      this.CDBInfo.RYID = ''
      this.CDBInfo.WBDZ = ''
      this.CDBInfo.FJId = this.selectNodeId
      this.CDBInfo.SSYY = this.selectNodeSSYY
      this.CDBInfo.CDLX = this.selectNodeCDLX
      this.CDBInfo.CJ = this.selectNodeCj + 1
      this.dialogVisibleForm = true
      if (type === '1') {
        this.ssmc = '目录名称'
        this.CDBInfo.QXDJ = 0
        this.CDBInfo.DYGNMK = 0
        this.dialogVisibleFormdycd = false
        this.dialogVisibleFormQXDJ = false
        if (this.selectNodeSSYY === 1) {
          this.dialogVisibleForm2x = false
          this.dialogVisibleForm3x = false
        } else {
          this.dialogVisibleForm2x = true
          this.dialogVisibleForm3x = true
        }
      } else {
        this.ssmc = '菜单名称'
        this.CDBInfo.QXDJ = 2
        this.dialogVisibleFormdycd = true
        this.dialogVisibleFormQXDJ = true
        if (this.selectNodeSSYY === 1) {
          this.dialogVisibleForm2x = false
          this.dialogVisibleForm3x = false
        } else {
          this.dialogVisibleForm2x = true
          this.dialogVisibleForm3x = true
        }
        this.menusaactivity()
      }
    },
    // 关闭
    colseaddCatalog: function() {
      this.dialogVisibleForm = false
    },
    // 删除
    deletetree: function() {
      if (this.selectNodeCj === 1 || this.selectNodeCj === 2) {
        this.$message({
          type: 'error',
          message: '固定菜单不能删除！',
          showClose: true
        })
        return
      }
      if (this.selectNodeId === '' || this.selectNodeId === '0') {
        this.$message({
          type: 'error',
          message: '未选中菜单',
          showClose: true
        })
        return
      }

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/menus/DeleteMenu',
            {
              Id: this.selectNodeId,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.selectNodeId = ''
                this.selectNodeSSYY = 0
                this.menutype = 0
                this.selectNodeCDLX = 0
                this.selectNodeCj = 2
                this.$refs.treemenu.removeNode(this.selecttNode)
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 详细
    detail: function() {
      if (this.dialogVisibleForm == true) {
        return
      }
      if (this.selectNodeCj == 1 || this.selectNodeCj == 2) {
        return
      }
      this.dialogVisibleForm = true
      if (this.selecttNode.data.dygnmk == 4) {
        this.h5path = true
      } else {
        this.h5path = false
      }

      if (this.menutype == 1) {
        this.ssmc = '目录名称'
        this.CDBInfo.QXDJ = 0
        this.CDBInfo.DYGNMK = 0
        this.dialogVisibleFormdycd = false
        this.dialogVisibleFormQXDJ = false
        if (this.selectNodeSSYY == 1) {
          this.dialogVisibleForm2x = false
          this.dialogVisibleForm3x = false
        } else {
          this.dialogVisibleForm2x = true
          this.dialogVisibleForm3x = true
        }
      } else {
        this.ssmc = '菜单名称'
        this.CDBInfo.QXDJ = 2
        this.dialogVisibleFormdycd = true
        this.dialogVisibleFormQXDJ = true
        if (this.selectNodeSSYY == 1) {
          this.dialogVisibleForm2x = false
          this.dialogVisibleForm3x = false
        } else {
          this.dialogVisibleForm2x = true
          this.dialogVisibleForm3x = true
        }
        this.menusaactivity()
      }
      this.CDBInfo = {
        Id: this.selecttNode.data.id,
        FJId: this.selecttNode.data.fjId,
        CDMC: this.selecttNode.data.label,
        DYGNMK: this.selecttNode.data.dygnmk,
        CDGL: this.selecttNode.data.glId,
        SSXX: this.selecttNode.data.ssxx,
        TB: this.selecttNode.data.twoxp,
        SBTB: this.selecttNode.data.threexp,
        CDLB: this.selecttNode.data.cdtype,
        PXH: this.selecttNode.data.pxh,
        SSYY: this.selecttNode.data.type,
        RYID: this.selecttNode.data.ryid,
        CJ: this.selecttNode.data.cj,
        CDLX: this.selecttNode.data.cdlx,
        QXDJ: this.selecttNode.data.qxdj,
        WBDZ: this.selecttNode.data.h5path
      }

      this.cdbdyname = this.selecttNode.data.glmc
    },
    // 打开选择
    openacitivty: function() {
      this.dialogFormVisible = true
      for (var i = 0; i < this.activitytitle.length; i++) {
        if (i == 0) {
          this.activitytitle[i].selectclass =
            'cent-butt cent-butt-sty whole-col whole-col-hov'
        } else {
          this.activitytitle[i].selectclass = 'cent-butt whole-col-hov'
        }
      }
      this.initactivity(1)
    },

    // 查询菜单及活动
    menusaactivity: function() {
      this.fixedactivitylist = []
      this.customactivitylist = []
      this.customreport = []

      this.$api.post(
        'v1/menus/GetMenuByAcitivty',
        {
          ModularType: this.selectNodeSSYY,
          SchoolId: this.CDBInfo.SSXX,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            var activtyall = r.Target

            if (activtyall !== null && activtyall.length > 0) {
              for (const item of activtyall) {
                var menurow = {
                  ActivityClass: 'tablil-xz',
                  ActivityId: item.ActivityId,
                  ActivityName: item.ActivityName,
                  ActivityType: item.ActivityType,
                  RYID: item.RYID
                }
                if (item.ActivityType === 1) {
                  this.fixedactivitylist.push(menurow)
                } else if (item.ActivityType === 2 || item.ActivityType === 3) {
                  this.customactivitylist.push(menurow)
                } else {
                  this.customreport.push(menurow)
                }
              }
              this.fixedactivitylist.sort((a, b) =>
                a.ActivityName.localeCompare(b.ActivityName)
              )
              this.customactivitylist.sort((a, b) =>
                a.ActivityName.localeCompare(b.ActivityName)
              )
              this.customreport.sort((a, b) =>
                a.ActivityName.localeCompare(b.ActivityName)
              )
              this.initactivity(1)
            }
          }
        }
      )
    },
    // 选择活动类型
    selectactivitytype: function(row, index) {
      row.selectclass = 'cent-butt cent-butt-sty whole-col whole-col-hov'
      for (var i = 0; i < this.activitytitle.length; i++) {
        if (i !== index) {
          this.activitytitle[i].selectclass = 'cent-butt whole-col-hov'
        }
      }
      this.initactivity(row.selecttype)
    },
    // 初始化活动
    initactivity: function(type) {
      this.moduleList = []
      if (type === 1) {
        for (const item of this.fixedactivitylist) {
          if (this.CDBInfo.CDGL === item.ActivityId) {
            item.ActivityClass = 'tablil-xz tablil-xz-sty'
          } else {
            item.ActivityClass = 'tablil-xz'
          }
          this.moduleList.push(item)
        }
      } else if (type === 2) {
        for (const item of this.customactivitylist) {
          if (this.CDBInfo.DYGNMK === 2 && item.ActivityType === 2) {
            if (this.CDBInfo.CDGL === item.ActivityId) {
              item.ActivityClass = 'tablil-xz tablil-xz-sty'
            } else {
              item.ActivityClass = 'tablil-xz'
            }
          } else {
            if (this.CDBInfo.DYGNMK === 3 && item.ActivityType === 3) {
              if (
                this.CDBInfo.RYID === item.RYID &&
                this.CDBInfo.CDGL === item.ActivityId
              ) {
                item.ActivityClass = 'tablil-xz tablil-xz-sty'
              } else {
                item.ActivityClass = 'tablil-xz'
              }
            } else {
              item.ActivityClass = 'tablil-xz'
            }
          }
          this.moduleList.push(item)
        }
      } else {
        for (const item of this.customreport) {
          if (this.CDBInfo.CDGL === item.ActivityId) {
            item.ActivityClass = 'tablil-xz tablil-xz-sty'
          } else {
            item.ActivityClass = 'tablil-xz'
          }
          this.moduleList.push(item)
        }
      }
    },
    // 选择活动
    checkedactivity: function(row, index) {
      var checkedclass = 'tablil-xz tablil-xz-sty'
      this.CDBInfo.CDGL = row.ActivityId
      this.CDBInfo.DYGNMK = row.ActivityType
      this.cdbdyname = row.ActivityName
      if (row.ActivityType == 4) {
        this.h5path = true //自定义报表才设置
      }
      this.CDBInfo.RYID = row.RYID
      for (var i = 0; i < this.moduleList.length; i++) {
        if (index === i) {
          this.moduleList[i].ActivityClass = checkedclass
        } else {
          this.moduleList[i].ActivityClass = 'tablil-xz'
        }
      }
    },
    // 确定选中活动
    checkedselection: function() {
      this.dialogFormVisible = false
    },
    // 保存
    submitshowdata: function(event) {
      event.preventDefault()

      if (this.selectNodeId === '0') {
        this.$message({
          type: 'error',
          message: '未选中所属模块',
          showClose: true
        })
        return
      }

      if (this.CDBInfo.CDMC === '') {
        this.$message({
          type: 'error',
          message: '名称不能为空',
          showClose: true
        })
        return
      }
      this.$api.post(
        'v1/menus/AddMenus',
        {
          CDBInfo: this.CDBInfo,
          UserName: this.userName,
          UserId: this.userId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.dialogFormVisible = false
            this.dialogVisibleForm = false
            if (this.CDBInfo.Id != null && this.CDBInfo.Id.length > 8) {
              this.addNodeOrUpdate(2)
            } else {
              this.CDBInfo.Id = r.Target.Id
              this.addNodeOrUpdate(1)
            }

            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    //添加节点
    addNodeOrUpdate: function(addorupdate) {
      var cdcolor = 'cdcolorml'
      if (this.CDBInfo.CDLB == 2) {
        cdcolor = 'cdcolormk'
      }
      var node = {
        id: this.CDBInfo.Id,
        fjId: this.CDBInfo.FJId,
        label: this.CDBInfo.CDMC,
        dygnmk: this.CDBInfo.DYGNMK,
        glmc: this.cdbdyname,
        glId: this.CDBInfo.CDGL,
        ssxx: this.CDBInfo.SSXX,
        twoxp: this.CDBInfo.TB,
        threexp: this.CDBInfo.SBTB,
        cdtype: this.CDBInfo.CDLB,
        pxh: this.CDBInfo.PXH,
        type: this.CDBInfo.SSYY,
        ryid: this.CDBInfo.RYID,
        cj: this.CDBInfo.CJ,
        cdlx: this.CDBInfo.CDLX,
        h5path: this.CDBInfo.WBDZ,
        qxdj: this.CDBInfo.QXDJ,
        nodecolor: cdcolor
      }
      if (addorupdate == 1) {
        this.$refs.treemenu.addNode(this.selecttNode, node, 'pxh', 'asc')
      } else {
        node.children = this.selecttNode.data.children
        this.$refs.treemenu.updateNode(this.selecttNode, node, 'pxh', 'asc')
      }
    }
  }
}
</script>

<style>
.cell {
  text-align: left;
}

.customWidth {
  width: 70%;
}
</style>
