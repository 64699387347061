<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="multi-opt-box" style="margin-top: 0px;" v-loading="loading">
          <div
            class="multi-opt"
            v-if="hd.GradeNumber && hd.GradeNumber.length > 0"
          >
            <div class="multi-opt-left">年级</div>
            <div class="multi-opt-rig ">
              <div
                v-for="nj in njlist"
                class=" nj-item"
                :class="{ 'current-nj': currentNJ.Id === nj.Id }"
                :key="nj.Id"
                @click="loadbjdata(nj)"
              >
                {{ nj.Name }}
              </div>
            </div>
          </div>
          <!-- 班级 -->
          <div
            class="multi-opt"
            v-if="hd.GradeNumber && hd.GradeNumber.length > 0"
          >
            <div class="multi-opt-left">班级</div>
            <div class="multi-opt-rig ">
              <div
                class=" bj-item"
                :class="{ 'current-bj': currentBJ.Id === bj.Id }"
                v-for="bj in bjlist"
                :key="bj.Id"
                @click="loadcoursedata(bj)"
              >
                {{ bj.Name }}
              </div>
            </div>
          </div>
          <!-- 课程 -->
          <div class="multi-opt" v-if="hd.CourseTableType === 3">
            <div class="multi-opt-left">课程</div>
            <div class="multi-opt-rig ">
              <div
                class=" kc-item"
                :class="{ 'current-kc': currentKC.courseId === kc.courseId }"
                v-for="kc in kclist"
                :key="kc.courseId"
                @click="currentKC = kc"
              >
                {{ kc.courseName }}
              </div>
            </div>
          </div>
          <!-- 阶段 -->
          <div class="multi-opt stage">
            <div class="multi-opt-left">阶段</div>
            <div style="z-index:10;" class=" multi-opt-rig">
              <div v-if="noStarted">
                {{
                  `${
                    !hd.ActEvaStageList || hd.ActEvaStageList.length === 0
                      ? "暂无阶段"
                      : "阶段未开始"
                  }`
                }}
              </div>
              <div
                class="stage-block"
                v-for="jd in hd.ActEvaStageList"
                :key="jd.Id"
              >
                <div
                  class="stage-item"
                  :class="{
                    'over-stage': jd.state === 0,
                    'current-stage': jd.state === 1,
                  }"
                  v-if="jd.state !== 2"
                  @click="jdClick(jd)"
                >
                  {{ jd.KSSJ | handleTime }}~{{ jd.JSSJ | handleTime }}
                </div>
                <span v-if="jd.state === 1">*点击当前阶段进入评价</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { compareVerson } from "@/utils/versionNo.js";
import { formatDate } from "@/utils/date.js";

export default {
  name: "stagecourse",
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      this.user = JSON.parse(tempuser);
    }
  },
  data() {
    return {
      teacherSign: {},
      hd: {},
      allNJ: [],
      njlist: [],
      classCourseList: [],
      currentNJ: {},
      bjlist: [],
      currentBJ: {},
      kclist: [],
      currentKC: {},
      user: {},
      ssxq: "",
      term: {},
      indexList: [], //通用指标
      starList: [], //星星指标
      questionnaireList: [], //问卷指标
      questionnaireList2: [], //问卷指标
      bprType: [], //被评老师 1.班主任 2.科任 3.全部角色 4.机构
      allTeacher: [], //所有老师
      allJG: [], //所有机构
      loading: false,
      noStarted: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "stageactivity") vm.init();
    });
  },

  beforeRouteLeave(to, from, next) {
    this.loading = false;
    if (
      (to.name === "stagedata2" || to.name === "stagedata") &&
      from.name === "stagecourse"
    ) {
      if (this.hd.GradeNumber) {
        to.meta.title = `${this.hd.Name}${
          this.currentKC.courseName ? "--" + this.currentKC.courseName : ""
        }${this.currentBJ.Name ? "--" + this.currentBJ.Name : ""}  ${
          this.hd.EvalutionFormat === 1
            ? "通用指标--请输入分数"
            : this.hd.EvalutionFormat === 2
            ? "星星指标--请输入星数"
            : "问卷指标--请选择答案"
        }`;
      }
    }
    next();
  },
  filters: {
    // 处理阶段时间
    handleTime(val) {
      return val.split(" ")[0];
    },
  },
  methods: {
    init() {
      this.hd = this.$route.params.hd;
      this.allNJ = this.$route.params.allNJ;
      this.njlist = this.$route.params.userNJ
        ? JSON.parse(JSON.stringify(this.$route.params.userNJ))
        : [];
      this.classCourseList = this.njlist.reduce((pre, cur) => {
        return pre.concat(
          cur.ChidOrgList?.reduce((p, c) => {
            return p.concat(c.course || []);
          }, [])
        );
      }, []);
      this.teacherSign = this.$route.params.teacherSign;
      this.term = this.$route.params.term;

      this.getIndex2();
    },
    // 预处理年级 班级 学科 阶段
    handleTeacher() {
      let objlist = this.hd.UserEObjectList;
      let CourseTableType = this.hd.UserEObjectList[0].my_custom
        .CourseTableType;
      // 被评人为学生

      if (this.hd.GradeNumber && this.hd.GradeNumber.length > 0) {
        if (CourseTableType === 1) {
          //  无课表
          let cprType = [];
          let cprBJ = [];

          objlist.forEach((obj) => {
            cprType = cprType.concat(obj.my_custom.cprType);
            obj.my_custom.cprBJ.forEach((bj) => {
              let idx = cprBJ.findIndex((item) => item.bj === bj.bj);
              if (idx === -1) cprBJ.push(bj);
            });
          });
          // 参评人为全部老师 或自定义老师
          let njarr = [];
          this.allNJ.forEach((nj) => {
            cprBJ.forEach((bj) => {
              if (bj.nj === nj.Id) {
                let idx = njarr.findIndex((item) => item.Id === nj.Id);
                if (idx === -1)
                  njarr.push({
                    ChidOrgList: [],
                    Id: nj.Id,
                    Name: nj.Name,
                    number: nj.GradeNumber + "",
                  });

                let njRes = njarr.find((item) => item.Id === nj.Id);
                let idx2 = njRes.ChidOrgList.findIndex(
                  (item) => item.Id === bj.bj
                );
                if (idx2 === -1)
                  njRes.ChidOrgList.push({
                    Id: bj.bj,
                    Name: bj.Name,
                  });
              }
            });
          });
          this.njlist = njarr;
        } else if (CourseTableType === 3) {
          // 评价人课表
          this.njlist.forEach((nj) => {
            nj.ChidOrgList.forEach((bj) => {
              let courArr = [];
              objlist.forEach((obj) => {
                obj.my_custom.cprCourse.forEach((kc) => {
                  if (
                    kc.nj === nj.Id &&
                    kc.bj === bj.Id &&
                    !courArr.some((item) => item.courseId === kc.courseId)
                  ) {
                    bj.xkcy = true;
                    nj.xkcy = true;
                    courArr.push(kc);
                  }
                });
              });
              bj.course = courArr;
            });
          });
          this.njlist = this.njlist.filter((nj) => {
            nj.ChidOrgList = nj.ChidOrgList.filter((bj) => bj.xkcy);
            return nj.xkcy;
          });
        } else {
          //  班级课表
        }
        this.currentNJ = this.njlist[0] || {};
        this.bjlist = this.currentNJ.ChidOrgList || [];
        this.currentBJ = this.bjlist[0] || {};
        if (CourseTableType === 3) {
          this.kclist = this.currentBJ.course || [];
          this.currentKC = this.kclist[0] || {};
        }
      } else {
        // 被评人为老师
        objlist.forEach((obj) => {
          if (obj.my_custom.bprType.length > 0)
            this.bprType = [...this.bprType, ...obj.my_custom.bprType];
        });
        let arr = [];
        this.bprType.forEach((bpr) => {
          if (bpr.type === 1 && !arr.some((item) => item.type === 1))
            arr.push(bpr);
          if (bpr.type === 2 && !arr.some((item) => item.type === 2))
            arr.push(bpr);
          if (bpr.type === 3 && !arr.some((item) => item.typeId === bpr.typeId))
            arr.push(bpr);
          if (
            bpr.type === 4 &&
            !arr.some(
              (item) =>
                item.type === 4 &&
                item.JGPJLX === bpr.JGPJLX &&
                item.JGId === bpr.JGId
            )
          )
            arr.push(bpr);
          if (bpr.type === 5 && !arr.some((item) => item.type === 5))
            arr.push(bpr);
        });
        this.bprType = arr;
      }
      // 处理阶段
      this.handleStage(this.hd);

      this.loading = false;
    },
    // 处理阶段
    handleStage(hd) {
      //  服务器时间在开展阶段时间内的 则这个阶段就是当前阶段 这个阶段之前的就是阶段就只能查看 这个阶段之后的阶段不显示
      hd.ActEvaStageList &&
        hd.ActEvaStageList.sort(
          (a, b) =>
            new Date(formatDate(a.KSSJ, "/")).getTime() -
            new Date(formatDate(b.KSSJ, "/")).getTime()
        ).forEach((stage) => {
          let begin = new Date(formatDate(stage.KSSJ, "/")).getTime();
          let end = new Date(formatDate(stage.JSSJ, "/")).getTime();

          let serverTime = new Date(
            formatDate(this.user.ServerTime, "/")
          ).getTime();
          //查看标识
          if (end < serverTime) stage.state = 0;
          //当前标识
          else if (begin <= serverTime && serverTime <= end) stage.state = 1;
          else stage.state = 2; //隐藏标识
        });

      if (
        !hd.ActEvaStageList ||
        hd.ActEvaStageList.length === 0 ||
        hd.ActEvaStageList.every((item) => item.state === 2)
      ) {
        this.noStarted = true;
      } else this.noStarted = false;
    },
    // 班级
    loadbjdata(currentNJ) {
      this.currentNJ = currentNJ;
      this.bjlist = currentNJ.ChidOrgList || [];
      this.currentBJ = this.bjlist[0] || {};
      this.kclist = this.currentBJ.course || [];
      this.currentKC = this.kclist[0] || {};
    },
    // 课程
    loadcoursedata(bj) {
      this.currentBJ = bj;
      if (this.hd.CourseTableType === 3) {
        this.kclist = bj.course || [];
        this.currentKC = bj.course[0] || {};
      }
    },
    // 阶段
    jdClick(jd) {
      this.loading = true;
      let indexType = this.hd.EvalutionFormat;
      let bprType = this.hd.EvaluatedObject;
      let index = this.filterIndex(indexType, bprType);

      if (bprType === 2) {
        this.currentNJ = {};
        this.currentBJ = {};
        this.currentKC = {};
      }
      if (this.hd.CourseTableType !== 3) {
        this.currentKC = {};
      }
      // 通用和星星
      if (indexType === 1 || indexType === 2) {
        this.getBPR((bpr) => {
          this.$router.push({
            name: "stagedata2",
            params: {
              hd: this.hd,
              nj: this.currentNJ,
              bj: this.currentBJ,
              kc: this.currentKC,
              jd,
              index,
              bpr,
              term: this.term,
            },
          });
        });
      } else if (indexType === 3) {
        // 问卷
        this.getBPR((bpr) => {
          this.$router.push({
            name: "stagedata",
            params: {
              hd: this.hd,
              nj: this.currentNJ,
              bj: this.currentBJ,
              kc: this.currentKC,
              jd,
              bpr,
              index,
              term: this.term,
            },
          });
        });
      }
    },
    // 根据当前的年级 班级 学科 筛选匹配的对象的指标(如果是有学科的 要根据当前所选班级的课表来筛选对应的学科)
    filterIndex(indexType, bprType) {
      if (bprType === 1) {
        //被评人为学生 需要根据当前点击的年级 学科 对对象进行筛选
        let indexList = [];
        if (indexType === 1 || indexType === 2) {
          this.hd.UserEObjectList.forEach((obj) => {
            if (
              obj.GradeNumbers &&
              (obj.GradeNumbers.includes(this.currentNJ.number) ||
                obj.GradeNumbers.includes(this.currentNJ.GradeNumber + ""))
            ) {
              // 遍历对象 判断当前点击的年级是否包含在对象中
              if (this.hd.CourseTableType === 3) {
                // 是否课表参与
                if (
                  obj.RoleOrCourse &&
                  obj.RoleOrCourse.some(
                    (item) =>
                      item.DataType === 1 &&
                      item.PJLX === 1 &&
                      item.RoleCourseId === this.currentKC.courseId
                  )
                ) {
                  // 判断当前点击的课程是否包含在对象中
                  obj.EvaIndexList.forEach((item) => {
                    indexList.findIndex((el) => el.id === item.GLId) === -1
                      ? indexList.push({
                          id: item.GLId,
                          objId: obj.EObjectId,
                        })
                      : "";
                  });
                }
              } else {
                // 这4种判断方式会导致指标重复 但是指标添加的时候有去重

                // 对象班主任参与 当前班级是否是班主任班级
                if (obj.RoleOrCourse.some((item) => item.RoleType === 1)) {
                  let arr = obj.my_custom.cprBJ.filter((it) => it.bjType === 1);
                  if (arr && arr.length > 0) {
                    if (arr.some((bj) => bj.bj === this.currentBJ.Id)) {
                      obj.EvaIndexList.forEach((item) => {
                        indexList.findIndex((el) => el.id === item.GLId) === -1
                          ? indexList.push({
                              id: item.GLId,
                              objId: obj.EObjectId,
                            })
                          : "";
                      });
                    }
                  }
                }
                // 对象科任参与 当前班级是否科任班级
                if (obj.RoleOrCourse.some((item) => item.RoleType === 3)) {
                  let arr = obj.my_custom.cprBJ.filter((it) => it.bjType === 2);
                  if (arr && arr.length > 0) {
                    if (arr.some((bj) => bj.bj === this.currentBJ.Id)) {
                      obj.EvaIndexList.forEach((item) => {
                        indexList.findIndex((el) => el.id === item.GLId) === -1
                          ? indexList.push({
                              id: item.GLId,
                              objId: obj.EObjectId,
                            })
                          : "";
                      });
                    }
                  }
                }
                // 对象科任且班主任参与 当前班级是否科任班级或班主任班级
                if (
                  obj.RoleOrCourse.some((item) => item.RoleType === 3) &&
                  obj.RoleOrCourse.some((item) => item.RoleType === 1)
                ) {
                  let arr = obj.my_custom.cprBJ.filter((it) => it.bjType === 1);
                  let arr2 = obj.my_custom.cprBJ.filter(
                    (it) => it.bjType === 2
                  );
                  if (arr && arr.length > 0) {
                    if (arr.some((bj) => bj.bj === this.currentBJ.Id)) {
                      obj.EvaIndexList.forEach((item) => {
                        indexList.findIndex((el) => el.id === item.GLId) === -1
                          ? indexList.push({
                              id: item.GLId,
                              objId: obj.EObjectId,
                            })
                          : "";
                      });
                    }
                  }
                  if (arr2 && arr2.length > 0) {
                    if (arr2.some((bj) => bj.bj === this.currentBJ.Id)) {
                      obj.EvaIndexList.forEach((item) => {
                        indexList.findIndex((el) => el.id === item.GLId) === -1
                          ? indexList.push({
                              id: item.GLId,
                              objId: obj.EObjectId,
                            })
                          : "";
                      });
                    }
                  }
                }
                // 全部老师或者其它老师
                if (
                  !obj.RoleOrCourse.some((item) => item.RoleType === 1) &&
                  !obj.RoleOrCourse.some((item) => item.RoleType === 3)
                ) {
                  obj.EvaIndexList.forEach((item) => {
                    indexList.findIndex((el) => el.id === item.GLId) === -1
                      ? indexList.push({
                          id: item.GLId,
                          objId: obj.EObjectId,
                        })
                      : "";
                  });
                }
              }
            } else {
              console.log(1, "对象不包含该年级");
            }
          });
        }
        if (indexType === 1) {
          return this.indexList.reduce((pre, cur) => {
            let res = indexList.find((item) => item.id === cur.Id);
            if (res) {
              cur.objId = res.objId;
              return pre.concat(cur);
            } else return pre;
          }, []);
        } else if (indexType === 2) {
          return this.starList.reduce((pre, cur) => {
            let res = indexList.find((item) => item.id === cur.Id);
            if (res) {
              cur.objId = res.objId;
              return pre.concat(cur);
            } else return pre;
          }, []);
        } else {
          return this.handleTM(bprType);
        }
      } else {
        // 被评人为老师 不做年级学科筛选
        if (indexType === 1) {
          let arr = [];
          this.hd.UserEObjectList.forEach((obj) => {
            if (obj.EvaIndexList && obj.EvaIndexList.length > 0) {
              obj.EvaIndexList.forEach((el) => {
                this.indexList.forEach((e) => {
                  if (e.Id === el.GLId) {
                    if (!arr.some((it) => it.Id === e.Id)) {
                      arr.push(e);
                      e.objId = obj.EObjectId;
                    }
                  }
                });
              });
            }
          });
          return arr;
        } else if (indexType === 2) {
          let arr = [];
          this.hd.UserEObjectList.forEach((obj) => {
            if (obj.EvaIndexList && obj.EvaIndexList.length > 0) {
              obj.EvaIndexList.forEach((el) => {
                this.starList.forEach((e) => {
                  if (e.Id === el.GLId) {
                    if (!arr.some((it) => it.Id === e.Id)) {
                      arr.push(e);
                      e.objId = obj.EObjectId;
                    }
                  }
                });
              });
            }
          });
          return arr;
        } else return this.handleTM(bprType);
      }
    },
    //获取指标
    async getIndex2() {
      this.loading = true;
      let type = this.hd.EvalutionFormat;
      if (type === 1 || type === 2) {
        // 通用或星星指标
        await this.getIndex(type);
      } else if (type === 3) {
        // 问卷指标
        let arr = [];
        await this.getAllCourse();
        let questionIds = [];
        this.hd.UserEObjectList.forEach((obj) => {
          obj.EvaIndexList.forEach((item) => {
            questionIds.findIndex((el) => el.GLId === item.GLId) === -1
              ? questionIds.push({
                  objId: obj.EObjectId,
                  GLId: item.GLId,
                })
              : "";
          });
        });
        arr = await this.getQuestion(questionIds.map((item) => item.GLId));
        arr.forEach((item) => {
          questionIds.forEach((el) => {
            if (el.GLId === item.Id) item.objId = el.objId;
          });
          if (item.CourseIds && item.CourseIds.length > 0) {
            let arr2 = [];
            this.allCourse.forEach((el) => {
              item.CourseIds.forEach((e) => {
                if (e === el.Id) {
                  arr2.push({
                    name: el.KCMC,
                    id: el.Id,
                  });
                }
              });
            });
            item.xk = arr2;
          }
        });
        this.questionnaireList2 = arr;
      } else {
        // 自定义指标
      }
      if (this.hd.EvaluatedObject === 1) {
        this.handleTeacher();
      } else {
        await this.getAllTeacher();

        for (let i = 0; i < this.allTeacher.length; i++) {
          await this.getTeacherBJ(this.allTeacher[i]);
        }
        let allJG = JSON.parse(localStorage.getItem("allJG"));
        if (!compareVerson(5, allJG && allJG.length > 0)) {
          await this.getAllOrganization();
          for (let i = 0; i < this.allJG.length; i++) {
            await this.getSecondOrganization(this.allJG[i]);
          }
          localStorage.setItem("allJG", JSON.stringify(this.allJG || []));
        } else this.allJG = allJG;

        this.handleTeacher();
        this.loading = false;
      }
    },
    // 获取通用或星星指标
    getIndex(type) {
      let starsIndex = JSON.parse(localStorage.getItem("starsIndex"));
      let generalIndex = JSON.parse(localStorage.getItem("generalIndex"));
      return new Promise((resolve, reject) => {
        if (
          !compareVerson(
            type === 1 ? 15 : 16,
            type === 1
              ? generalIndex && generalIndex.length > 0
              : starsIndex && starsIndex.length > 0
          )
        ) {
          this.$api.post(
            "v1/evaluationactivity/GetSchoolEindex",
            {
              LX: 1,
              token: this.user.Token,
              SchoolId: this.user.SSZZId,
              IndexType: type,
              TermYear: this.term.SSXN,
              BeginTime: this.term.BeginTime,
              EndTime: this.term.EndTime,
            },
            (res) => {
              if (res.Code === 0) {
                if (type === 1) {
                  localStorage.setItem(
                    "generalIndex",
                    JSON.stringify(res.Target || [])
                  );
                  this.indexList = res.Target;
                } else {
                  localStorage.setItem(
                    "starsIndex",
                    JSON.stringify(res.Target || [])
                  );
                  this.starList = res.Target;
                }
                resolve();
              } else {
                this.loading = false;
                this.$message.err(res.Message);
              }
            },
            (err) => {
              this.loading = false;
              this.$message.err(err);
              reject(err);
            }
          );
        } else {
          type === 1
            ? (this.indexList = generalIndex)
            : (this.starList = starsIndex);
          resolve();
        }
      });
    },
    // 根据id获取问卷
    getQuestion(ids) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetQuestionnaire",
          {
            token: this.user.Token,
            QuestionnaireIds: ids,
          },
          (r) => {
            if (r.Code === 0) {
              if (r.Target) {
                if (r.Target) {
                  r.Target.forEach((item) =>
                    item.TMLis.sort((a, b) => a.PXH - b.PXH)
                  );
                }
                resolve(r.Target || []);
              }
            }
          },
          (err) => {
            this.loading = false;
            this.$message.err(err);
            reject(err);
          }
        );
      });
    },
    // 问卷题目处理
    handleTM(type) {
      let arr = [];
      let questionList = [];
      if (type === 1) {
        this.hd.UserEObjectList.forEach((obj) => {
          if (
            obj.GradeNumbers &&
            (obj.GradeNumbers.includes(this.currentNJ.number) ||
              obj.GradeNumbers.includes(this.currentNJ.GradeNumber + ""))
          ) {
            // 遍历对象 判断当前点击的年级是否包含在对象中
            if (this.hd.CourseTableType === 3) {
              // 是否课表参与
              if (
                obj.RoleOrCourse &&
                obj.RoleOrCourse.some(
                  (item) =>
                    item.DataType === 1 &&
                    item.PJLX === 1 &&
                    item.RoleCourseId === this.currentKC.courseId
                )
              ) {
                // 判断当前点击的课程是否包含在对象中
                obj.EvaIndexList.forEach((item) => {
                  questionList.findIndex((el) => el.id === item.GLId) === -1
                    ? questionList.push({
                        objId: obj.EObjectId,
                        id: item.GLId,
                      })
                    : "";
                });
              }
            } else {
              // 无课表参与
              obj.EvaIndexList.forEach((item) => {
                questionList.findIndex((el) => el.id === item.GLId) === -1
                  ? questionList.push({
                      objId: obj.EObjectId,
                      id: item.GLId,
                    })
                  : "";
              });
            }
          }
        });
        let arr = [];

        this.questionnaireList2.forEach((question) => {
          questionList.forEach((el) => {
            if (el.id === question.Id) {
              arr.push(question);
            }
          });
        });
        questionList = arr;
      } else {
        questionList = this.questionnaireList2;
      }
      questionList.forEach((wj) => {
        wj.TMLis.forEach((TM) => {
          if (typeof TM.WJFXBJson === "string") {
            TM.WJFXBJson = JSON.parse(TM.WJFXBJson).sort((a, b) =>
              a.TMQZ.localeCompare(b.TMQZ)
            );
          }
        });
        if (wj.xk && wj.xk.length > 0) {
          wj.xk.forEach((xkItem) => {
            // 对象无学科参与的 但是问卷是有学科的 要判断问卷的学科是否在当前班级课表中 且当前老师是否教这个学科
            wj.TMLis.forEach((TM) => {
              arr.push({
                objId: wj.objId,
                WJId: wj.Id,
                Id: TM.Id,
                key: TM.Id + xkItem.id,
                WJMC: wj.Name,
                TMMC: TM.TMMC + "-" + xkItem.name,
                xkId: xkItem.id,
                WJFXBList: TM.WJFXBJson,
                SSLB: TM.SSLB,
                TB: TM.TB,
                xk: wj.xk,
              });
            });
          });
        } else {
          wj.TMLis.forEach((TM) => {
            arr.push({
              objId: wj.objId,
              WJId: wj.Id,
              Id: TM.Id,
              key: TM.Id,
              WJMC: wj.Name,
              TMMC: TM.TMMC,
              WJFXBList: TM.WJFXBJson,
              SSLB: TM.SSLB,
              TB: TM.TB,
            });
          });
        }
      });

      return arr;
    },
    // 获取被评人
    getBPR(callback) {
      let bpr = [];
      // 当被评人为学生
      if (this.hd.EvaluatedObject === 1) {
        this.$api.post(
          "v1/accountmanage/GetOrgStudent",
          {
            OrgType: 2, // 0 学校 1.年级 2班级3.小组4.自定义小组
            SchoolOrgId: this.currentBJ.Id,
            SourceType: 1,
            TermYearStr: this.term.SSXN,
            SchoolId: this.user.SSZZId,
            token: this.user.Token,
          },
          (res) => {
            if (res.Code === 0) {
              callback(res.Target);
            } else {
              this.loading = false;
              this.$message.err(res.Message);
            }
          }
        );
      } else {
        // 被评人为老师
        if (this.bprType.length > 0) {
          this.bprType.forEach((item) => {
            this.allTeacher.forEach((tc) => {
              // 角色之全部老师
              if (item.type === 5) {
                bpr = this.allTeacher;
              } else {
                if (item.type === 1) {
                  // 班主任
                  if (tc.bj.IsClassTeacher === 1) bpr.push(tc);
                } else if (item.type === 2) {
                  // 科任
                  if (tc.bj.IsClassTeacher === 2) bpr.push(tc);
                } else if (item.type === 3) {
                  //其它角色
                  bpr.push(tc);
                } else if (item.type === 4) {
                  // 机构
                  this.allJG.forEach((jg) => {
                    if (jg.key === item.JGId) {
                      if (item.JGPJLX === 1) {
                        // 组长被评
                        jg.subnodeList.forEach((el) => {
                          this.teacherSign.JGIds.forEach((e) => {
                            if (e.JGId === el.key) {
                              if (el.jgcy) {
                                el.jgcy.forEach((it) => {
                                  if (it.ZZLB === 1 && it.JSId === tc.Id)
                                    bpr.push(tc);
                                });
                              }
                            }
                          });
                        });
                      } else if (item.JGPJLX === 2) {
                        // 组员被评
                        jg.subnodeList.forEach((el) => {
                          this.teacherSign.JGIds.forEach((e) => {
                            if (e.JGId === el.key) {
                              if (el.jgcy) {
                                el.jgcy.forEach((it) => {
                                  if (it.ZZLB === 2 && it.JSId === tc.Id)
                                    bpr.push(tc);
                                });
                              }
                            }
                          });
                        });
                      } else {
                        // 整个组被评
                        jg.subnodeList.forEach((el) => {
                          this.teacherSign.JGIds.forEach((e) => {
                            if (e.JGId === el.key) {
                              if (el.jgcy) {
                                el.jgcy.forEach((it) => {
                                  if (it.JSId === tc.Id) bpr.push(tc);
                                });
                              }
                            }
                          });
                        });
                      }
                    }
                  });
                }
              }
            });
          });
        }
        // 去重
        let ids = Array.from(new Set(bpr.map((tc) => tc.Id)));
        let arr = [];
        ids.forEach((id) => arr.push(bpr.find((tc) => tc.Id === id)));
        arr = arr.filter((item) => item.Id !== this.user.Id);
        callback(arr);
      }
    },
    // 获取所有老师
    getAllTeacher() {
      let allTeacher = JSON.parse(localStorage.getItem("allTeacher"));
      return new Promise((resolve, reject) => {
        if (!compareVerson(4, allTeacher && allTeacher.length > 0)) {
          this.$api.post(
            "v1/accountmanage/GetSchoolTeacher",
            {
              SchoolId: this.user.SSZZId,
              token: this.user.Token,
            },
            (res) => {
              if (res.Code === 0) {
                localStorage.setItem(
                  "allTeacher",
                  JSON.stringify(res.Target || [])
                );
                this.allTeacher = res.Target;
                resolve();
              } else {
                this.loading = false;
                this.$message.err(res.Message);
              }
            },
            (err) => {
              this.loading = false;
              this.$message.err(err);
              reject(err);
            }
          );
        } else {
          this.allTeacher = allTeacher;
          resolve();
        }
      });
    },
    // 获取每个老师所带班级
    getTeacherBJ(tc) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetUserClassOrCurriculum",
          {
            UserId: tc.Id,
            TermId: this.ssxq,
            token: this.user.Token,
          },
          (r) => {
            if (r.Code === 0) {
              tc.bj = r.Target;
              resolve();
            }
          },
          (err) => {
            this.loading = false;
            this.$message.err(err);
            reject(err);
          }
        );
      });
    },
    // 获取1级机构
    getAllOrganization() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/schoolteaorg/GetXXJGTree",
          {
            SchoolId: this.user.SSZZId,
            ParentId: 0,
            token: this.user.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.allJG = res.Target;
              resolve();
            } else {
              this.loading = false;
              this.$message.err(res.Message);
            }
          },
          (err) => {
            this.loading = false;
            this.$message.err(err);
            reject(err);
          }
        );
      });
    },
    // 获取2级机构
    getSecondOrganization(parent) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/schoolteaorg/GetXXJGTree",
          {
            SchoolId: this.user.SSZZId,
            ParentId: parent.key,
            token: this.user.Token,
          },
          (res) => {
            if (res.Code === 0) {
              parent.subnodeList = res.Target;
              resolve();
            } else {
              this.loading = false;
              this.$message.err(res.Message);
            }
          },
          (err) => {
            this.loading = false;
            this.$message.err(err);
            reject(err);
          }
        );
      });
    },
    // 获取所有学科
    getAllCourse() {
      let allCourse = JSON.parse(localStorage.getItem("allCourse"));
      return new Promise((resolve, reject) => {
        if (!compareVerson(8, allCourse && allCourse.length > 0)) {
          this.$api.post(
            "/v1/xxkcb/GetXXKCBList",
            {
              SchoolId: this.user.SSZZId,
              token: this.user.Token,
            },
            (res) => {
              if (res.Code === 0) {
                localStorage.setItem(
                  "allCourse",
                  JSON.stringify(res.Target || [])
                );
                this.allCourse = res.Target;
                resolve();
              } else {
                this.loading = false;
                this.$message.err(res.Message);
              }
            },
            (err) => {
              this.loading = false;
              this.$message.err(err);
              reject(err);
            }
          );
        } else {
          this.allCourse = allCourse;
          resolve();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.multi-opt {
  display: flex;
  align-items: center;
  &.stage {
    padding-bottom: 0;
  }
  .multi-opt-left {
    margin-bottom: 10px;
  }
  .multi-opt-rig {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    .stage-block {
      position: relative;
      span {
        position: absolute;
        left: 12px;
        top: 33px;
        color: #ccc;
      }
    }
    .nj-item,
    .bj-item,
    .kc-item,
    .stage-item {
      padding: 5px 10px;
      border: 1px solid #ccc;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #91dfc1;
      }
      &.current-nj,
      &.current-bj,
      &.current-kc {
        background-color: #14d089;
        color: #fff;
        border: none;
      }
    }
    .current-stage {
      background-color: #14d089;
      color: #fff;
    }
    .over-stage {
      padding: 5px 10px;
      border: 1px solid #ccc;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #ccc;
      }
    }
  }
}
</style>
