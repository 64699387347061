<template>
  <div class="jf-center" style="flex-direction:column">
    <div style="width:255px;" class="br">
      学期:
      <el-select v-model="termId" placeholder @change="getData">
        <el-option
          v-for="item in terms"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        ></el-option>
      </el-select>
    </div>
    <div style="width:311px" class="br">
      活动类别:
      <el-select v-model="categoryId" placeholder>
        <el-option
          v-for="item in categorysData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        ></el-option>
      </el-select>
    </div>
    <div class="br" v-loading="isLoading" style="max-width:300px">
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="activityIds" @change="handleCheckedChange">
        <el-checkbox
          style="margin-bottom:10px"
          v-for="item in activitiesResult"
          :label="item.Id"
          :key="item.Id"
          >{{ item.HDMC }}</el-checkbox
        >
      </el-checkbox-group>
    </div>
    <div class="br" style="margin-top: 40px;">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      termId: "",
      categoryId: "",
      isIndeterminate: false,
      checkAll: false,
      activityIds: [],
      activities: [],
      categorysData: [],
      isLoading: false,
      visible: false
    };
  },
  model: {
    prop: "dialogVisible",
    event: "change"
  },
  props: {
    terms: {
      type: Array,
      default: function() {
        return [];
      }
    },
    categorys: {
      type: Array,
      default: function() {
        return [];
      }
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: ""
    },
    schoolId: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.visible = this.dialogVisible;
    this.init();
  },
  methods: {
    init() {
      if (this.terms.length > 0 && this.categorys.length > 0) {
        this.termId = this.terms[0].Id;
        this.categorysData = JSON.parse(JSON.stringify(this.categorys));
        this.categorysData.unshift({ Id: "", Name: "全部" });
        this.getData();
      }
    },
    getData() {
      this.isLoading = true;
      this.$api.post(
        "/v1/curriculavariable/GetAllCurriculaList",
        {
          token: this.token,
          TermId: this.termId,
          SchoolId: this.schoolId,
          IsEnd: 1
        },
        r => {
          if (r.Code === 0) {
            this.activities = r.Target.filter(c => c.KZSZ === 1);
          }
          this.isLoading = false;
        }
      );
    },
    submit() {
      this.visible = false;
    },
    handleCheckAllChange(val) {
      this.activityIds = val ? this.activitiesResult.map(c => c.Id) : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.activitiesResult.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.activitiesResult.length;
    }
  },
  computed: {
    activitiesResult: function() {
      return this.activities
        ? this.activities
            .filter(c => c.LXId === this.categoryId || !this.categoryId)
            .map(c => ({ Id: c.Id, HDMC: c.HDMC }))
        : [];
    }
  },
  watch: {
    dialogVisible: function(newVal) {
      this.visible = newVal;
      if (newVal) {
        this.init();
      }
    },
    visible: function(val) {
      if (!val) {
        this.$emit("change", false);
      }
    }
  }
};
</script>
<style scoped>
.br {
  margin-bottom: 30px;
}
</style>
