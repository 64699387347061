<template>
	<el-menu router :default-active="$store.state.router" class="el-menu-vertical-demo">
		<template v-for="(item, index) in menu.filter(c => !c.hidden && c.meta && c.meta.title)">
			<el-submenu v-if="item.groupcode && item.children && item.children.length > 0" :index="index + ''"
				:key="index">
				<template slot="title">
					<!-- <i class="el-icon-location"></i> -->
					<span>{{ item.meta.title }}</span>
				</template>

				<template v-for="(child, i) in item.children.filter(c => !c.hidden && c.meta && c.meta.title)">
					<el-submenu v-if="child.groupcode && child.children && child.children.length > 0"
						:index="index+'-'+i" :key="i">
						<template slot="title">
							<!-- <i class="el-icon-location"></i> -->
							<span>{{ child.meta.title }}</span>
						</template>
						<el-menu-item
							v-for="(son, y) in child.children.filter(c => !c.hidden && c.meta && c.meta.title)" :key="y"
							:index="son.path">
							{{ son.meta.title }}
						</el-menu-item>
					</el-submenu>
					<el-menu-item v-else :index="child.path" :key="i">
						<!-- <i class="el-icon-menu"></i> -->
						<span slot="title">{{ child.meta.title }}</span>
					</el-menu-item>




				</template>
			</el-submenu>
			<el-menu-item v-else :index="item.path" :key="index">
				<!-- <i class="el-icon-menu"></i> -->
				<span slot="title">{{ item.meta.title }}</span>
			</el-menu-item>
		</template>
	</el-menu>
</template>

<script>
	export default {
		data() {
			return {
				menu: this.$store.state.menu
			};
		},
		mounted: function() {
			this.$store.commit("updateRouter", window.location.pathname);
		}
	};
</script>