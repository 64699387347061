<template>
    <el-dialog :title="title" :visible.sync="visible"
      width="30%" center :before-close="closeDialog"
      :close-on-click-modal="false" >
      <div class="tab-shu-box">
        <el-form :model="formData" ref="ruleForm" @validate="validate">
          <table cellpadding="0" cellspacing="0">
            <tbody>
              <!-- 报表代码 -->
              <tr>
                <td class="tab-shu-titl pos-r">
                  报表代码
                  <div class="prompt-butt-box">注</div>
                  <div class="prompt-butt-center">
                    注：报表代码需要和前端开发人员协商一致，且报表代码不允许重复。
                  </div>
                </td>
                <td>
                  <el-form-item :rules="[
                      {
                        required: true,
                        message: '请选择代码',
                        trigger: 'change'
                      }
                    ]"
                    prop="MBDM"
                    ref="MBDM"
                    style="height:16px"
                  >
                  <el-select v-model="formData.MBDM" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.Value"
                      :label="`${item.Name}(${item.Description})`"
                      :value="item.Value">
                    </el-option>
                  </el-select>
                  <!-- <el-input
                      v-model="formData.MBDM"
                      placeholder="请选择代码" size="small"
                      class="jf-form" prop="MBDM"
                    ></el-input> -->
                  </el-form-item>
                </td>
              </tr>
              <!-- 报表名称 -->
              <tr>
                <td class="tab-shu-titl">报表名称</td>
                <td>
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请输入报表名称',
                        trigger: 'change'
                      }
                    ]"
                    prop="MBMC"
                    ref="MBMC"
                    style="height:16px"
                  >
                    <el-input
                      v-model="formData.MBMC"
                      placeholder="请输入内容"
                      size="small"
                      class="jf-form"
                      prop="MBMC"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <!-- 选择对象 -->
              <tr>
                <td class="tab-shu-titl">对象类型</td>
                <td>
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请选择对象',
                        trigger: 'change'
                      }
                    ]"
                    prop="DXLX"
                    ref="DXLX"
                    style="height:16px"
                  >
                    <el-select
                      v-model="formData.DXLX"
                      placeholder="请选择"
                      size="small"
                      class="jf-form"
                      style="width: 100%;"
                      prop="DXLX"
                    >
                      <el-option
                        v-for="item in reportType"
                        :key="item.Key"
                        :label="item.Value"
                        :value="item.Key"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </td>
              </tr>
              
            </tbody>
          </table>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="confirmDialog">确 定</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        reportFilter: [],
        shapeLimit: [],
        validateResult: false,
        labelWidth: "250px",
        options:[],
        rules: {
          BBDM: [
            { required: true, message: "请输入报表代码", trigger: "change" },
            {
              min: 0,
              max: 20,
              message: "长度在 0 到 20 个字符",
              trigger: "change"
            }
          ],
          BBMC: [
            { required: true, message: "请输入报表名称", trigger: "change" },
            {
              min: 0,
              max: 20,
              message: "长度在 0 到 20 个字符",
              trigger: "change"
            }
          ],
          BBLX: [
            { required: true, message: "请选择报表类别", trigger: "change" }
          ],
          SYZT: [{ required: true, message: "请选择状态", trigger: "change" }]
        },
        formData: {},
        token:'',
        userid:'',
        reportType:[
          {Key:1,Value:'学生'},
          {Key:2,Value:'老师'}
        ]
      };
    },
    props: {
      data: {
        type: Object,
        default: function() {
          return {};
        }
      },
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ""
      },
      shapes: {
        type: Array,
        default: function() {
          return [];
        }
      },
      statu: {
        type: Array,
        default: function() {
          return [];
        }
      },
      dataList: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },
    created() {
      // this.ssxq = localStorage.getItem("currentterm");
      var tempuser = localStorage.getItem("userinfo");
      if (tempuser) {
        var user = JSON.parse(tempuser);
        // this.ssxx = user.SSZZId;
        // this.xxbm = user.SchoolCode;
        this.userid = user.Id;
        this.token = user.Token;
      }
      this.init()
    },
    methods: {
      
      // 获取模板代码
      init() {
        this.$api.post(
          '/v1/mbbb/GetMBDMType',
          {
            token:this.token
          },
          r=>{
            if (r.Code === 0) {
              
              this.options = r.Target
            }
          }
        )
      },
      
      closeDialog() {
        this.$confirm("确认关闭？").then(() => {
          this.$emit("closeDialog");
        }).catch(()=>{});
      },
      // 确认添加
      confirmDialog() {
        
        this.formData.CJR = this.userid
        console.log("this",this.formData)
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit("saveDialog",this.formData);
           }
        })
        
      },
      querySearchAsync() {
        this.validateResult = false;
        if (this.dataList.findIndex(c => c.BBDM === this.formData.BBDM) !== -1) {
          this.validateResult = true;
        }
      },
      validate(val, result) {
        this.$refs[val].$el.style.height = "16px";
        if (!result && this.visible) {
          this.$refs[val].$el.style.height = "unset";
        }
      },
      
    },
    watch: {
      // visible(val) {
      //   if (val) {
      //     this.init();
      //     // 显示
      //   } else {
      //     this.$refs.ruleForm.validate();
      //     this.$refs.ruleForm.resetFields();
      //     // 隐藏
      //   }
      // },
      visible(val) {
        this.visible = val;
        if(val){
          this.$set(this, "formData", JSON.parse(JSON.stringify(this.data)));
        }
        if (this.$refs.ruleForm) {
          this.$refs.ruleForm.resetFields()
        }
      }
    }
  };
  </script>
  