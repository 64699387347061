<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="operate-box paddno">
        <el-button class="butt-box" @click="openAdd">新增活动</el-button>
        <el-button class="butt-box" @click="openSetDialog(1, '70%', '5%')"
          >基本设置</el-button
        >
        <el-button class="butt-box" @click="openSetDialog(2, '30%')"
          >活动类型</el-button
        >
        <el-button class="butt-box" @click="openSetDialog(3)"
          >选课规则</el-button
        >
        <el-button class="butt-box" @click="openSetDialog(4)"
          >设置选中活动指标</el-button
        >
        <el-button class="butt-box" @click="openSetDialog(5)"
          >活动导入模版</el-button
        >
        <el-button class="butt-box" @click="openSetDialog(6)"
          >成员导入模版</el-button
        >
        <UploadExcel
          style="display: inline-block;margin-left: 10px;margin-right: 10px;"
          :on-success="importActivitys"
          ><el-button class="butt-box">批量导入活动</el-button></UploadExcel
        >

        <el-button class="butt-box" @click="openSetDialog(8)"
          >导出选中活动</el-button
        >
        <el-button class="butt-box" @click="openSetDialog(9)"
          >一键导入往期活动</el-button
        >
        <el-button class="butt-box" @click="openSetDialog(10)"
          >一键填充选中活动成员</el-button
        >
      </div>
      <!--表格区开始-->
      <div class="tab-heng-box" style="margin-top: 10px;">
        <div class="operate-box borderno-l">
          <div class="czq-box">
            <div class="czq-name-p">名称:</div>
            <div class="inp-box-p">
              <el-input
                v-model="searchName"
                placeholder="关键字搜索"
              ></el-input>
            </div>
          </div>
          <div class="czq-box">
            <div class="czq-name-p">学期:</div>
            <div class="inp-box-p">
              <el-select v-model="termFilter" placeholder>
                <el-option
                  v-for="item in basicData.TermList"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="czq-box">
            <div class="czq-name-p">类型:</div>
            <div class="inp-box-p">
              <el-select v-model="categoryFilter" placeholder>
                <el-option
                  v-for="item in categoryList"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="czq-box">
            <div class="czq-name-p">状态:</div>
            <div class="inp-box-p">
              <el-select v-model="statuFilter" placeholder>
                <el-option
                  v-for="item in status"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="czq-box">
            <div class="inp-box-p">
              <el-button class="butt-box" @click="getData">搜索</el-button>
            </div>
          </div>
        </div>
        <Table
          :isLoading="isLoading"
          :tableData="tableData"
          :dimensions="dimensions"
          @editItem="editItem"
          @memberManage="memberManage"
          @importMember="importMember"
          @delItem="delItem"
          @handleSelectionChange="handleSelectionChange"
        />

        <!--页码开始-->
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
        <!--页码结束-->
      </div>
      <!--表格区结束-->
    </div>
    <!--内容区结束-->
    <Form
      :visible="formVisible"
      :form="form"
      :basicData="basicData"
      :dimensions="dimensions"
      @closeDialog="closeDialog"
      @confirmDialog="confirmDialog"
    />
    <el-dialog
      :title="dialogTitle"
      :visible.sync="otherDialogVisible"
      :top="otherDialogTop"
      :width="otherDialogWith"
      :close-on-click-modal="false"
      center
    >
      <BasicManager
        v-if="formType === 1"
        :SchoolId="schoolId"
        :TermId="termFilter"
        :gradeList="basicData.JoinGradeList"
        :visible="otherDialogVisible"
        @closeOtherDialog="closeOtherDialog"
      />
      <CategoryManager
        v-if="formType === 2"
        :Data="basicData.CategoryList"
        @updateAddCategoryList="updateAddCategoryList"
        @updateDelCategoryList="updateDelCategoryList"
        @updateCategoryList="updateCategoryList"
      />
      <RulesManager
        v-if="formType === 3"
        :basicData="basicData"
        :SchoolId="schoolId"
        :visible="otherDialogVisible"
        @closeOtherDialog="closeOtherDialog"
      />
      <IndexSet
        v-if="formType === 4"
        :SchoolId="schoolId"
        :TermId="termFilter"
        :visible="otherDialogVisible"
        :CurriculaList="multipleSelection"
        :User="user"
        @closeOtherDialog="closeOtherDialog"
      />
      <CopyActivity
        v-if="formType === 9"
        :terms="basicData.TermList.filter((c) => c.IsCurrent !== 1)"
        v-model="otherDialogVisible"
        :categorys="basicData.CategoryList"
        :token="token"
        :schoolId="schoolId"
      />
    </el-dialog>
    <MemberManager
      :visible="memberManagerVisible"
      :user="user"
      :orgList="basicData.OrgList"
      :curriculaId="currentCurriculaId"
      :joinGradeStr="currentMemberManagerJoinGradeStr"
      :categoryId="currentCategoryId"
      :endDate="currentEndDate"
      :termId="termFilter"
      :onlyMember="memberManagerOnlyMember"
      :curriculaData="form"
      @closeMemberManagerDialog="closeMemberManagerDialog"
      @updateTableData="updateTableData"
    />
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel.vue";
import Pager from "@/components/Pager.vue";
import Table from "@/views/manager/curriculavariablemanager/Table.vue";
import Form from "@/views/manager/curriculavariablemanager/Form.vue";
import CategoryManager from "@/views/manager/curriculavariablemanager/CategoryManager.vue";
import BasicManager from "@/views/manager/curriculavariablemanager/BasicManager.vue";
import RulesManager from "@/views/manager/curriculavariablemanager/RulesManager.vue";
import IndexSet from "@/views/manager/curriculavariablemanager/IndexSet.vue";
import MemberManager from "@/views/manager/curriculavariablemanager/MemberManager.vue";
import CopyActivity from "@/views/manager/curriculavariablemanager/CopyActivity.vue";
import { handleDownload } from "@/style/js/Common.js";
export default {
  components: {
    Pager,
    Table,
    Form,
    CategoryManager,
    BasicManager,
    RulesManager,
    IndexSet,
    MemberManager,
    UploadExcel,
    CopyActivity,
  },
  data() {
    return {
      memberManagerOnlyMember: false,
      currentEndDate: "",
      currentCategoryId: "",
      currentMemberManagerJoinGradeStr: "",
      currentCurriculaId: "",
      otherDialogTop: "15vh",
      otherDialogWith: "0%",
      otherdialogType: [
        "",
        "基本设置",
        "活动类型",
        "选课规则",
        "设置选中活动指标",
        "活动导入模版",
        "成员导入模版",
        "批量导入活动",
        "导出选中活动",
        "一键导入往期活动",
        "一键填充选中活动成员",
      ],
      formType: 0,
      dialogTitle: "",
      otherDialogVisible: false,
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0,
      },
      multipleSelection: [],
      token: "",
      user: {},
      schoolId: "",
      basicData: { TeacherList: [], JoinGradeList: [] },
      termFilter: "",
      categoryFilter: "",
      statuFilter: 2,
      searchName: "",
      status: [
        {
          key: 2,
          value: "未结束",
        },
        {
          key: 1,
          value: "已结束",
        },
      ],
      isLoading: false,
      formVisible: false,
      memberManagerVisible: false,
      tableData: [],
      form: {},
      dimensions: [],
      categoryList: [{ Id: "", Name: "全部", Statu: 1 }],
      allStudents: [],
      allOrgs: [],
    };
  },
  created: function() {
    this.isLoading = true;
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.getSchoolStudents().then(() => {
      this.getSchoolOrgs().then(() => {
        this.getBasic();
      });
    });
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
      this.getData();
    },
    getDimensions() {
      const pageinfo = {
        PageIndex: 0,
        PageSize: parseInt(Number.MAX_SAFE_INTEGER / 10000000),
      };
      this.dimensions = [];
      this.$api.post(
        "/v1/dimension/GetDimensionTree",
        {
          LX: 1,
          token: this.token,
          AreaId: this.areaId,
          PagerInfo: pageinfo,
          SchoolId: this.schoolId,
        },
        (r) => {
          this.dimensions = JSON.parse(JSON.stringify(r.Target));
        }
      );
    },
    getBasic() {
      this.$api.post(
        "/v1/curriculavariable/GetCurriculaBasicInfo",
        {
          token: this.token,
          SchoolId: this.schoolId,
        },
        (r) => {
          if (r.Code === 0) {
            this.basicData = r.Target;
            if (this.basicData.TermList && this.basicData.TermList.length > 0) {
              let currentTerm = this.basicData.TermList.find(
                (c) => c.IsCurrent === 1
              );
              this.termFilter = currentTerm
                ? currentTerm.Id
                : this.basicData.TermList[0].Id;
            }
            this.categoryList.push(...this.basicData.CategoryList);

            this.getDimensions();
            this.getData();
          }
        }
      );
    },
    getData() {
      this.isLoading = true;
      this.$api.post(
        "/v1/curriculavariable/GetAllCurriculaList",
        {
          token: this.token,
          TermId: this.termFilter,
          SchoolId: this.schoolId,
          PagerInfo: this.pagerInfo,
          SearchName: this.searchName,
          IsEnd: this.statuFilter,
          CategoryId: this.categoryFilter,
        },
        (r) => {
          if (r.Code === 0) {
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
            this.tableData = r.Target;
          }
          this.isLoading = false;
        }
      );
    },
    editItem(data) {
      this.form = data;
      this.formVisible = true;
    },
    memberManage(data, onlymember) {
      this.memberManagerOnlyMember = false;
      if (onlymember) {
        this.memberManagerOnlyMember = true;
      }
      this.memberManagerVisible = true;
      this.currentMemberManagerJoinGradeStr = data.JoinGradeStr;
      this.currentCurriculaId = data.Id;
      this.currentCategoryId = data.LXId;
      this.currentEndDate = data.JSSJ;
      this.form = data;
    },
    // eslint-disable-next-line no-unused-vars
    importMember(datas, activity) {
      datas = datas.reduce((prev, item) => {
        let grade = this.allOrgs.find((c) => c.GradeNumber === item["年级"]);
        if (grade) {
          let classData = grade.ChidOrgList.find(
            (c) => c.Number === item["班级"]
          );
          if (classData) {
            item.ClassId = classData.Id;
            prev.push(item);
            return prev;
          }
        }
      }, []);
      let members = this.allStudents
        .filter(
          (c) =>
            datas.map((item) => item["姓名"]).includes(c.Name) &&
            datas.map((item) => item.ClassId).includes(c.ClassId)
        )
        .map((c) => ({
          MemberId: c.Id,
          StudentCode: c.XH,
          GradeId: c.GradeId,
        }));
      this.$api.post(
        "/v1/curriculavariable/AddMember",
        {
          token: this.$store.state.token,
          TermId: this.termFilter,
          SchoolId: this.schoolId,
          CurriculaId: activity.Id,
          CategoryId: activity.LXId,
          TeacherId: this.user.Id,
          MemberList: members,
        },
        (r) => {
          if (r.Code === 0) {
            let result = r.Target;
            this.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message:
                "<strong>导入成功" +
                result.Success +
                "条数据，导入失败" +
                result.Failed +
                "条数据</strong>",
              center: true,
              type: result.Failed > 0 ? "warning" : "success",
            });
          }
        }
      );
    },
    getSchoolStudents() {
      return new Promise((resolve) => {
        this.$api.post(
          "/v1/accountmanage/GetOrgStudent",
          {
            token: this.token,
            SchoolOrgId: this.schoolId,
            SchoolId: this.schoolId,
          },
          (r) => {
            if (r.Code === 0) {
              this.allStudents = r.Target;
              resolve();
            }
          }
        );
      });
    },
    getSchoolOrgs() {
      return new Promise((resolve) => {
        this.$api.post(
          "/v1/accountmanage/GetSchoolOrg",
          {
            token: this.token,
            SchoolId: this.schoolId,
          },
          (r) => {
            if (r.Code === 0) {
              this.allOrgs = r.Target;
              resolve();
            }
          }
        );
      });
    },
    delItem(data) {
      if (data) {
        this.multipleSelection = [];
        this.multipleSelection.push(data.Id);
      }
      this.$api.post(
        "/v1/curriculavariable/DelCurricula",
        {
          token: this.token,
          CurriculaIds: this.multipleSelection,
          UserId: this.user.Id,
          UserName: this.user.XSM,
          TermId: this.termFilter,
          GradeList: this.tableData
            .filter((c) => this.multipleSelection.includes(c.Id))
            .map((c) => c.GradeLimit.map((item) => item.GradeId))
            .reduce((prev, item) => {
              return prev.concat(item);
            }, []),
          SchoolId: this.schoolId,
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message,
            });
            this.getData();
          }
        },
        (f) => {
          if (f.Code === -6) {
            this.$alert(f.Message, "提示", {
              confirmButtonText: "确定",
              type: "warning",
              center: true,
              callback: () => {
                this.getData();
              },
            });
          }
        }
      );
    },
    closeDialog() {
      this.formVisible = false;
    },
    closeMemberManagerDialog() {
      this.memberManagerVisible = false;
    },
    confirmDialog(data) {
      let submitData = JSON.parse(JSON.stringify(data));
      submitData.SSXQ = this.termFilter;
      submitData.SSXX = this.schoolId;
      submitData.CJR = this.user.Id;
      this.submit([].concat(submitData));
      this.formVisible = false;
    },
    openAdd() {
      this.form = {};
      this.formVisible = true;
    },
    submit(datas) {
      this.$api.post(
        "/v1/curriculavariable/EditCurricula",
        {
          token: this.token,
          CurriculaVariableList: datas,
          UserName: this.user.XSM,
          TermId: this.termFilter,
          SchoolId: this.schoolId,
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message,
            });
            this.getData();
          }
        }
      );
    },
    handleSelectionChange(list) {
      this.multipleSelection = list;
    },
    closeOtherDialog() {
      this.otherDialogVisible = false;
    },
    openSetDialog(type, width, top) {
      if (type === 4 && this.multipleSelection.length <= 0) {
        this.$alert("请够选要设置的活动", "提示信息", {
          confirmButtonText: "确定",
        });
      } else if (type === 5) {
        handleDownload(
          "/template/课辅活动导入模板.xlsx",
          "课辅活动导入模板.xlsx"
        );
      } else if (type === 6) {
        handleDownload("/template/成员导入模板.xlsx", "成员导入模板.xlsx");
      } else {
        this.otherDialogWith = "50%";
        this.otherDialogTop = "15vh";
        if (top) {
          this.otherDialogTop = top;
        }
        if (width) {
          this.otherDialogWith = width;
        }
        this.formType = type;
        this.dialogTitle = this.otherdialogType[type];
        this.otherDialogVisible = true;
      }
    },
    updateAddCategoryList(data) {
      this.basicData.CategoryList.push(data);
    },
    updateDelCategoryList(data) {
      this.basicData.CategoryList.splice(
        this.basicData.CategoryList.findIndex((c) => c.Id === data.Id),
        1
      );
    },
    updateCategoryList(data) {
      this.basicData.CategoryList.splice(
        this.basicData.CategoryList.findIndex((c) => c.Id === data.Id),
        1,
        data
      );
    },
    updateTableData(data) {
      let index = this.tableData.findIndex((c) => c.Id === data.Id);
      if (index !== -1) {
        this.tableData.splice(index, 1, data);
      }
    },
    importActivitys({ results }) {
      let activities = [];
      for (let activity of results) {
        let data = {};
        data.KZSZ = 1;
        data.SSXQ = this.termFilter;
        data.SSXX = this.schoolId;
        data.CJR = this.user.Id;
        data.HDMC = activity["活动名称"];
        data.KSSJ = activity["活动开始时间"];
        data.JSSJ = activity["活动结束时间"];
        let category = this.basicData.CategoryList.filter(
          (c) => c.Name === activity["活动类型"] + ""
        );
        data.LXId = category && category.length > 0 ? category[0].Id : "";
        this.dimensions.reduce((prev, item) => {
          return prev.concat(item.children);
        }, []);
        let dimension = this.dimensions
          .concat(
            this.dimensions
              .filter((c) => c.children && c.children.length > 0)
              .reduce((prev, item) => {
                return prev.concat(item.children);
              }, [])
          )

          .filter((c) => c.label === activity["活动关联维度"]);
        data.SSWD = dimension && dimension.length > 0 ? dimension[0].value : "";
        data.TotalLimit = activity["总人数"];
        data.XBXZ = activity["性别限制（0无，1男，2女）"];
        data.BMSH = activity["是否审核（1需要，2不需要）"];
        data.BZ = activity["描述"];
        data.ManagerList = this.basicData.TeacherList.filter((c) =>
          activity["负责人"].includes(c.Name)
        ).map((item) => {
          return { Id: item.Id, Name: item.Name };
        });
        data.GradeLimit = this.basicData.JoinGradeList.filter((c) =>
          (activity["开展年级"] + "")
            .split(",")
            .filter((c) => !isNaN(c))
            .map((item) => Number(item))
            .includes(c.GradeNum)
        ).map((item) => {
          return { Count: -1, CountStr: "", GradeId: item.GradeId };
        });
        activities.push(data);
      }
      this.submit(activities);
    },
  },
};
</script>

<style scoped>
.butt-box {
  margin-bottom: 10px;
}
.inp-box-p {
  display: inline-block;
}
.czq-name-p {
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  padding-right: 5px;
  text-align: center;
  margin-bottom: 10px;
}
</style>
