import { render, staticRenderFns } from "./bodyfigure.vue?vue&type=template&id=b4335dcc&scoped=true"
import script from "./bodyfigure.vue?vue&type=script&lang=js"
export * from "./bodyfigure.vue?vue&type=script&lang=js"
import style0 from "./bodyfigure.vue?vue&type=style&index=0&id=b4335dcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4335dcc",
  null
  
)

export default component.exports