<template>
  <el-tree
    :data="data"
    :props="defaultProps"
    @node-click="handleNodeClick"
    :expand-on-click-node="false"
    node-key="value"
    ref="tree"
  ></el-tree>
</template>

<script>
let id = 1;
export default {
  data() {
    return {
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  props: {
    areaId: {
      type: String,
      default: "",
    },
  },
  methods: {
    append(data) {
      const newChild = { id: id++, label: "testtest", children: [] };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
    },

    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
    handleNodeClick(data) {
      const list = [];
      if (data.children) {
        for (let i = 0; i < data.children.length; i++) {
          list.push(data.children[i].value);
        }
      } else {
        list.push(data.value);
      }
      this.$emit("changeNode", list.join(","));
    },
    getNodeData() {
      this.$api.post(
        "/v1/dimension/GetDimensionTree",
        {
          token: this.$store.state.token,
          AreaId: this.areaId,
        },
        (r) => {
          var data = r.Target;
          this.data = data;
          if (data && data.length > 0) {
            const list = [];
            if (data[0].children) {
              for (let i = 0; i < data[0].children.length; i++) {
                list.push(data[0].children[i].value);
              }
            } else {
              list.push(data[0].value);
            }
            this.$emit("changeNode", list.join(","));
          }
        }
      );
    },
  },
  created: function() {
    this.getNodeData();
  },
};
</script>
