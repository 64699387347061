<template>
  <el-tree
    :props="nodes"
    :load="loadNode"
    @node-click="nodeClikc"
    :expand-on-click-node="false"
    lazy
    highlight-current
    node-key="data_id"
    ref="tree"
  ></el-tree>
</template>

<script>
export default {
  data() {
    return {
      label: 'name',
      isLeaf: 'leaf',
      nodes: {
        label: 'name',
        children: 'zones',
        isLeaf: 'leaf'
      },
      resolve: function() {},
      currentNode: {}
    }
  },
  props: {
    indexFilter: {
      type: Number,
      default: 1
    },
    schoolId: {
      type: String,
      default: ''
    },
    parentId: {
      type: String,
      default: ''
    }
  },
  methods: {
    loadNode(node, resolve) {
      this.currentNode = node
      this.resolve = resolve
      var pid = '0'
      if (node.data !== undefined) {
        pid = node.data.data_id
        this.parentId = pid
      }
      this.$api.post(
        '/v1/schoolindex/GetTree',
        {
          ParentId: pid,
          token: this.$store.state.token,
          IndexFilter: this.indexFilter,
          SchoolId: this.schoolId
        },
        r => {
          var resp = r.Target
          var data = resp.Target
          var list = []
          if (pid === '0') {
            list.push({
              name: '全部',
              data_nodetype: 0,
              data_id: this.schoolId,
              data_ordernum: 0,
              data_remark: '',
              data_level: 0,
              data_parentid: '0',
              leaf: !data.length > 0
            })
          } else {
            for (var i = 0; i < data.length; i++) {
              var leaf = true
              if (data[i].HasChilden === 1) {
                leaf = false
              }
              list.push({
                name: data[i].Name,
                data_nodetype: data[i].NodeType,
                data_id: data[i].Id,
                data_ordernum: data[i].OrderNum,
                data_remark: data[i].Remark,
                data_level: data[i].Level,
                data_parentid: data[i].ParentCode,
                leaf: leaf
              })
            }
          }
          resolve(list)
        }
      )
    },
    nodeClikc(data, node) {
      var param = {}
      param.Id = data.data_id
      param.Nodetype = data.data_nodetype
      param.level = data.data_level
      param.ParentId = data.data_parentid
      // param.nodeId=node.id
      this.$emit('changeNode', param, node)
    }
  }
}
</script>
