<template>
  <div class="FinalArchives_Grade">
    <div style="margin-bottom:15px">
      <el-select v-model="curTermId" placeholder="学期选择">
        <el-option
          v-for="item in terms"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="gradeList" v-loading="loading">
      <div
        class="grade"
        v-for="grade in schoolGrades"
        :key="grade.Id"
        @click="dowLoad(grade)"
      >
        <div class="name">{{ grade.Name }}</div>
        <el-button type="primary" size="mini">下载</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinalArchives_Grade',
  data() {
    return {
      userInfo: {},
      terms: [],
      curTermId: '',
      curTerm: {},
      schoolGrades: [],
      teachers: [],
      students: [],
      loading: false
    }
  },
  async mounted() {
    let userInfo = localStorage.getItem('userinfo')
    if (userInfo) this.userInfo = JSON.parse(userInfo)

    let terms = localStorage.getItem('terms')
    if (terms) {
      this.terms = JSON.parse(terms)
      this.curTerm = this.terms.find(item => item.IsCurrent === 1)
      this.curTermId = this.curTerm.Id
    }

    this.loading = true
    await this.getSchoolOrg()
    await this.getTeachers()
    await this.getSchoolStudent()
    this.loading = false
  },
  methods: {
    // 获取学校所有老师
    getTeachers() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/role/GetRoleSchoolTeacher',
          {
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.teachers = res.Target || []
              resolve()
            }
          }
        )
      })
    },
    // 获取学生
    getSchoolStudent() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetOrgStudent',
          {
            SchoolOrgId: this.userInfo.SSZZId, //	是	string	组织机构ID/活动Id
            OrgType: 0, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: this.curTerm.SSXN, //	是	string	学年
            SchoolId: this.userInfo.SSZZId, //	否	string	学校Id
            IsDelete: 2, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.students = res.Target || []
              resolve()
            }
          }
        )
      })
    },
    // 获取学校年级
    getSchoolOrg() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          {
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            IsDelete: 2, //	是	int	1.表示需要获取已删除和禁用数据
            GradeId: '', //	是	string	根据年级Id获取年级和年级以下的组织机构（不能传学校Id不是优先获取是全校的住址机构）
            token: this.userInfo.Token
          },
          res => {
            if (res.Code == 0) {
              this.schoolGrades = res.Target || []
              resolve()
            }
          }
        )
      })
    },
    // 下载
    dowLoad(grade) {
      localStorage.setItem('schoolStudents', JSON.stringify(this.students))
      localStorage.setItem('schoolTeachers', JSON.stringify(this.teachers))
      localStorage.setItem('schoolGrades', JSON.stringify(this.schoolGrades))
      this.$router.push({
        name: 'FinalArchives_Export',
        query: {
          gradeId: grade.Id,
          curTermId: this.curTermId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.FinalArchives_Grade {
  font-size: 14px;
  .gradeList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .grade {
      width: 150px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top: 6px solid #43d9a1;
      margin-right: 20px;
      margin-bottom: 20px;
      background-color: #f5f3f3;
      border-radius: 5px;
      cursor: pointer;
      .name {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
