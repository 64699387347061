<template>
  <div>
    <div class="table-er-box">
      <el-button plain @click="addItem">新增</el-button>
    </div>
    <Table
      :tableData="
        dataTable.slice(
          (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
          (pagerInfo.pageIndex - 1) * pagerInfo.pageSize + pagerInfo.pageSize
        )
      "
      :activitysData="activities"
      :isLoading="isLoading"
      :user="user"
      @editItem="editItem"
      @delItem="getData"
    />
    <!--页码开始-->
    <div class="pagin-box">
      <div class="pagin-wz">
        <!--分页-->
        <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
      </div>
    </div>
    <!--内容区结束-->
    <Form
      :visible="formVisible"
      :title="formTitle"
      :activitysData="activities"
      :disablePropData="dataTable"
      :currentActivity="currentActivity"
      :user="user"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Table from "./Table";
import Form from "./Form";
export default {
  components: {
    Pager,
    Table,
    Form
  },
  data() {
    return {
      currentActivity: {},
      isLoading: false,
      dataTable: [],
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      schoolId: "",
      formVisible: false,
      formTitle: ""
    };
  },
  props: {
    activities: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    // this.getData();
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    getData() {
      this.$api.post(
        "/v1/growthsystem/GetPersonAndGroupSetList",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target;
            this.pagerInfo.totalCount = this.dataTable.length;
            this.pagerInfo.pageIndex = 1;
          }
          this.isLoading = false;
        }
      );
    },
    addItem() {
      this.formTitle = "新增";
      this.currentActivity = {};
      this.formVisible = true;
    },
    closeDialog(submit) {
      if (submit) {
        this.getData();
      }
      this.formVisible = false;
    },
    editItem(data) {
      this.currentActivity = data;
      this.formTitle = "编辑";
      this.formVisible = true;
    }
  }
};
</script>
