<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->
      <div class="operate-box">
        <el-form :inline="true" class="demo-form-inline" @submit.native.prevent>
          <el-form-item label="教师姓名/编号">
            <el-input
              v-model="searchName"
              placeholder="教师姓名/编号"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="teachersearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <br />
      <div class="table-er-box">
        <el-button type="primary" icon="el-icon-edit" @click="openteacheradd"
          >新增</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-folder-delete"
          @click="batchoddupdatestate('2')"
          >禁用</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-folder-checked"
          @click="batchoddupdatestate('1')"
          >启用</el-button
        >

        <el-button type="primary" icon="el-icon-delete" @click="batchdeleteRow"
          >删除</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-edit"
          @click="batchinitteacherpassword"
          >重置密码</el-button
        >

        <el-button type="primary" icon="el-icon-top-right" @click="openupload"
          >导入数据</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-bottom-right"
          @click="exportschoolteacherdata"
          >导出数据</el-button
        >
      </div>
      <br />
      <br />
      <el-table
        :data="tableData"
        class="jf-table"
        border
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        :span-method="arraySpanMethod"
        style="width: 100%;"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column prop="XM" label="姓名"></el-table-column>
        <el-table-column prop="BH" label="编号"></el-table-column>
        <el-table-column prop="LXDH" label="联系电话"></el-table-column>
        <el-table-column prop="SSXD" label="所属学段">
          <template slot-scope="scope">
            <p>{{ scope.row | handle_XD_Str }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="SYZT" label="状态">
          <template slot-scope="scope">
            <p v-if="scope.row.SYZT == '1'">启用</p>
            <p v-else-if="scope.row.SYZT == '2'">禁用</p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="oddupdatestateRow(scope.row, 1)"
              v-if="scope.row.SYZT == 2"
              >启用</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="oddupdatestateRow(scope.row, 2)"
              v-else
              >禁用</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="editRow(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="initteacherpassword(scope.$index, scope.row)"
              >重置密码</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="opendetail(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="rowcount"
        ></el-pagination>
      </div>
      <!--表格区结束-->
    </div>
    <!--弹出框-->
    <el-dialog
      title="导入教师"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleImportClose"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="upload"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <a
            :href="`${publicPath}template/教师导入模板.xlsx`"
            class="essay-bt-textbunn"
            target="_blank"
            style="margin-left:0!important;"
            >教师导入模板下载</a
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportClose()">取 消</el-button>
        </el-form-item>
        <div class="py-text" v-if="errormessage != ''">{{ errormessage }}</div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { handle_SSXD2 } from '@/utils/common'
export default {
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return '/file/file/fileservice/UploadFile?token=' + this.token
    }
  },
  name: 'TeacherList',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      token: this.$store.state.token,
      // loading:true,
      schoolId: '',
      fileServerApiPath: '',
      webFilePath: '',
      searchName: '',
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      multipleSelection: [],
      munlipleStudentId: '',
      formLabelWidth: '120px',
      pathUrl: '',
      dialogFormVisible: false,
      userName: '',
      userId: '',
      errormessage: '',
      excelData: {
        results: null
      }
    }
  },
  created: function() {
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.schoolId = userinfo.SSZZId
    this.fileServerApiPath = userinfo.fileServerApiPath
    this.webFilePath = userinfo.webFilePath
    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    const pagerInfo = { pageIndex: 0, pageSize: this.pagesize }
    this.$api.post(
      'v1/teacher/GetTeacherManagerList',
      { schoolId: this.schoolId, PagerInfo: pagerInfo, token: this.token },
      r => {
        if (r.Code === 0) {
          this.tableData = r.Target

          this.currentPage = r.PagerInfo.PageIndex
          this.pagesize = r.PagerInfo.PageSize
          this.rowcount = r.PagerInfo.RowCount
        }
      }
    )
  },
  filters: {
    handle_XD_Str(val) {
      return handle_SSXD2(val.SSXD)
    }
  },
  methods: {
    upload(file) {
      let files = { 0: file.raw }
      this.readExcel(files)
    },
    generateData({ results }) {
      //for (let cell of results) {
      //  for (let item in cell) {
      //    if (!isNaN(Number(cell[item]))) {
      //      cell[item] = Number(cell[item]);
      //    }
      //  }
      //}
      this.excelData.results = results
    },
    openupload: function() {
      this.errormessage = ''
      this.dialogFormVisible = true
    },
    readExcel: function(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false })
        this.generateData({ results })
        this.submitimportdata(results)
        this.$refs.upload.value = ''
      }
      fileReader.readAsArrayBuffer(files[0])
    },
    submitimportdata: function(readdata) {
      if (readdata.length <= 0) {
        this.$message({
          type: 'info',
          message: '上传文件中无正确数据!'
        })
        return
      }
      this.importdata = []
      for (var i = 0; i < readdata.length; i++) {
        var obj = {
          BH: readdata[i].编号,
          XM: readdata[i].姓名,
          XB: readdata[i].性别,
          LXDH: readdata[i].联系电话,
          SFZJH: readdata[i].身份证号,
          DZYX: readdata[i].邮箱,
          LXSJ: readdata[i].来校时间,
          JSGW: readdata[i].教师岗位,
          ZGXL: readdata[i].最高学历
        }
        this.importdata.push(obj)
      }
      this.$api.post(
        'v1/teacher/ImportSchoolTeacher',
        {
          DataList: this.importdata,
          SchoolId: this.schoolId,
          CJR: this.userId,
          token: this.token
        },
        r => {
          this.errormessage = r.Message
          if (r.Code == 0) {
            this.teachersearch()
          }
        }
      )
    },
    handleImportClose: function() {
      this.errormessage = ''
      this.dialogFormVisible = false
    },
    handleSizeChange() {
      // 页选择
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize }
      this.$api.post(
        'v1/teacher/GetTeacherManagerList',
        {
          schoolId: this.schoolId,
          TeacherName: this.searchName,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    handleCurrentChange(val) {
      const pagerInfo = { pageIndex: val, pageSize: this.pagesize }
      this.$api.post(
        'v1/teacher/GetTeacherManagerList',
        {
          schoolId: this.schoolId,
          TeacherName: this.searchName,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },

    // 合并行
    arraySpanMethod() {
      // if (rowIndex % 2 === 0) {
      // if (columnIndex === 0) {
      //    return [1, 2];
      // } else if (columnIndex === 1) {
      //    return [0, 0];
      // }
      // }
    },

    // 复选框选中
    handleSelectionChange(val) {
      this.multipleSelection = val

      this.munlipleStudentId = []
      for (var i = 0; i < this.multipleSelection.length; i++) {
        this.munlipleStudentId += this.multipleSelection[i].Id + ','
      }
    },

    // 查询
    teachersearch: function() {
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize }

      this.$api.post(
        'v1/teacher/GetTeacherManagerList',
        {
          schoolId: this.schoolId,
          TeacherName: this.searchName,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target

            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 打开新增
    openteacheradd: function() {
      this.$router.push({ name: 'EditTeacher' })
    },
    // 修改
    editRow: function(index, row) {
      this.$router.push({ name: 'EditTeacher', params: { JSXXBInfo: row } })
    },
    // 批量启用状态
    batchoddupdatestate: function(trueOrFalse) {
      if (this.munlipleStudentId === '') {
        this.$message({
          type: 'error',
          message: '未选中教师',
          showClose: true
        })

        return
      }

      // 提交
      this.$api.post(
        'v1/teacher/UpdateTeacherTrueOrFalse',
        {
          Ids: this.munlipleStudentId,
          trueOrFalse: trueOrFalse,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })

            this.tableData.forEach((item, index) => {
              if (this.munlipleStudentId.indexOf(item.Id) > -1) {
                this.tableData[index].SYZT = trueOrFalse
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 启用状态
    oddupdatestateRow: function(row, trueOrFalse) {
      // 提交
      this.$api.post(
        'v1/teacher/UpdateTeacherTrueOrFalse',
        { Ids: row.Id, trueOrFalse: trueOrFalse, token: this.token },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })

            row.SYZT = trueOrFalse
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },

    // 重置密码
    initteacherpassword: function(index, row) {
      // 提交
      this.$api.post(
        'v1/accountmanage/BatchResetPassword',
        {
          Ids: [row.Id],
          UserType: 1,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 批量重置密码
    batchinitteacherpassword: function() {
      if (this.munlipleStudentId === '') {
        this.$message({
          type: 'error',
          message: '未选中教师',
          showClose: true
        })

        return
      }

      // 提交
      this.$api.post(
        'v1/accountmanage/BatchResetPassword',
        {
          Ids: this.multipleSelection.map(item => item.Id),
          UserType: 1, //未知 0  教师 1 学生 2 家长 3
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 删除
    batchdeleteRow: function() {
      if (this.munlipleStudentId === '') {
        this.$message({
          type: 'info',
          message: '请选择教师'
        })
        return
      }

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/teacher/DeleteTeacher',
            {
              Id: this.munlipleStudentId,
              UserName: this.userName,
              UserId: this.userId,
              SchoolId: this.schoolId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })

                this.teachersearch()
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 批量导出
    exportschoolteacherdata: function() {
      // 提交
      this.$api.post(
        'v1/teacher/ExportSchoolTeacherData',
        { SchoolId: this.schoolId, token: this.token },
        r => {
          if (r.Code === 0) {
            window.open(r.Message)
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 详情
    opendetail: function(index, row) {
      this.$router.push({ name: 'TeacherDetail', params: { JSXXBInfo: row } })
    }
  }
}
</script>

<style>
.cell {
  text-align: left;
}
</style>
