<template>
  <div class="cont-whole-box">
    <div style="padding: 30px">
      <div class="table-er-box" style="display: block">
        <div class="table-er-butt" style="border: none">
          <div class="table-er-butt" @click="importdatafromarea()">
            <!--<span class="table-er-buttspan c-guree">导入区域获奖管理</span>-->
            <el-button type="primary">导入区域获奖管理</el-button>
          </div>
        </div>
        <div
          style="
            font-size: 14px;
            margin-top: 10px;
            margin-right: 10px;
            display: block;
            float: right;
          "
        ></div>
      </div>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-light">
            <el-menu
              :default-active="selectType"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item index="1">学生获奖</el-menu-item>
              <el-menu-item index="2">教师获奖</el-menu-item>
              <!-- <el-menu-item index="3">班级获奖</el-menu-item>
              <el-menu-item index="4">社团获奖</el-menu-item> -->
            </el-menu>
          </div>
          <br />
          <!--改版后未区分学生，教师，全部都展示在这一个里面-->
          <div class="grid-content bg-purple-light">
            <el-button type="primary" icon="el-icon-edit" @click="openrecordset"
              >设置</el-button
            >
            <!-- <el-button type="primary" icon="el-icon-edit" @click="opencategory" >类别管理</el-button > -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openaddstudent"
              >新增获奖类别</el-button
            >
            <br />
            <br />
            <el-table :data="studenttableData" border style="width: 100%">
              <el-table-column
                prop="PXH"
                label="序号"
                width="100"
              ></el-table-column>
              <el-table-column prop="LBMC" label="类别名称"></el-table-column>
              <el-table-column prop="SSWDMC" label="关联维度"></el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="DJsetting(scope.$index, scope.row)"
                    >等级设置</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="studenteditRow(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="studentdeleteRow(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--教师获奖 v-show="studenthtml-->
          <div class="grid-content bg-purple-light" v-show="teacherhtml">
            <el-button type="primary" icon="el-icon-edit" @click="openrecordset"
              >设置</el-button
            >
            <!-- <el-button type="primary" icon="el-icon-edit" @click="opencategory" >类别管理</el-button > -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openaddteacher"
              >新增获奖类别</el-button
            >
            <br />
            <br />
            <el-table :data="teachertableData" border style="width: 100%">
              <el-table-column
                type="index"
                label="序号"
                width="100"
              ></el-table-column>
              <!-- <el-table-column prop="SJBS" label="数据标识"></el-table-column>
              <el-table-column prop="HJDJMC" label="奖项等级"></el-table-column>
              <el-table-column prop="JF" label="加分"></el-table-column> -->
              <el-table-column prop="LBMC" label="类别名称"></el-table-column>
              <el-table-column prop="SSWDMC" label="关联维度"></el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="DJsetting(scope.$index, scope.row)"
                    >等级设置</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="teachereditRow(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="teacherdeleteRow(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--班级获奖-->
          <div class="grid-content bg-purple-light" v-show="classhtml">
            <el-button type="primary" icon="el-icon-edit" @click="openrecordset"
              >设置</el-button
            >
            <!--列表-->
            <el-button type="primary" icon="el-icon-edit" @click="opencategory"
              >类别管理</el-button
            >
            <el-button type="primary" icon="el-icon-edit" @click="openaddclass"
              >新增获奖等级</el-button
            >
            <br />
            <br />
            <el-table :data="classtableData" border style="width: 100%">
              <el-table-column
                prop="PXH"
                label="序号"
                width="100"
              ></el-table-column>
              <el-table-column prop="HJDJMC" label="奖项等级"></el-table-column>
              <el-table-column prop="BJDF" label="班级得分"></el-table-column>
              <el-table-column
                prop="BZRDF"
                label="班主任加分"
              ></el-table-column>
              <el-table-column
                prop="BJCYDF"
                label="班级成员得分"
              ></el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="classeditRow(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="classdeleteRow(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--班级获奖-->
          <div class="grid-content bg-purple-light" v-show="associationhtml">
            <el-button type="primary" icon="el-icon-edit" @click="openrecordset"
              >设置</el-button
            >
            <!--列表-->
            <el-button type="primary" icon="el-icon-edit" @click="opencategory"
              >类别管理</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="openaddassociation"
              >新增获奖等级</el-button
            >
            <br />
            <br />
            <el-table :data="associationtableData" border style="width: 100%">
              <el-table-column
                prop="PXH"
                label="序号"
                width="100"
              ></el-table-column>
              <el-table-column prop="HJDJMC" label="奖项等级"></el-table-column>
              <el-table-column
                prop="DTJSDF"
                label="带团教师得分"
              ></el-table-column>
              <el-table-column
                prop="TDCYDF"
                label="团队成员得分"
              ></el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="associationeditRow(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="associationdeleteRow(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--改版后未区分学生，教师，全部都展示在这一个里面,新增获奖类别-->
    <el-dialog
      title="新增"
      :visible.sync="studentdialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="XSHJSZInfo" :rules="stduentrules" ref="stduentsbmitfrom">
        <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
          <el-input-number
            :min="0"
            :max="100"
            v-model="XSHJSZInfo.PXH"
            autocomplete="off"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="类别名称"
          :label-width="formLabelWidth"
          prop="LBMC"
        >
          <el-input v-model="XSHJSZInfo.LBMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="关联维度" :label-width="formLabelWidth">
          <el-cascader
            :props="{ expandTrigger: 'hover' }"
            v-model="XSHJSZInfo.SSWD"
            :options="sswd"
            :show-all-levels="false"
            placeholder="请选择末级维度"
            @change="handleWdSelected"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="计分比例设置"
          :label-width="formLabelWidth"
          prop="DJSZTJ"
        >
          <div class="BL-list" v-for="(item, index) in BLLB" :key="index">
            <div class="BL-item">
              {{ item.MC }}--{{ item.BL }}
              <el-button
                type="text"
                @click="delBLList(item)"
                style="margin-left: 10px"
                >删除</el-button
              >
            </div>
          </div>
          <el-button type="primary" @click="setJFDialogFormVisible = true"
            >添加</el-button
          >
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="studentdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitstudentdata">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 等级设置弹窗 -->
    <el-dialog
      title="等级设置"
      :visible.sync="setGradeDialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth my-dialog"
      :before-close="closeDJ"
    >
      <GradeSet
        v-model="setGradeDialogFormVisible"
        ref="setGradeInfo"
        :setGradeList="setGradeList"
        @refresh="refresh"
        :GradeInfo="GradeInfo"
        @cancelDJdialog="setGradeDialogFormVisible = false"
        @closeDJ="closeDJ"
        @sumbitFormDJ="sumbitFormDJ"
      />
    </el-dialog>

    <!-- 计分比例设置弹窗 -->
    <el-dialog
      title="计分比例设置"
      :visible.sync="setJFDialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="setJFInfo">
        <el-form-item
          label="比例名称"
          :label-width="formLabelWidth"
          prop="JFMC"
        >
          <el-input
            v-model="setJFInfo.MC"
            style="max-width: 300px"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="margin-top: 10px"
          label="比例"
          :label-width="formLabelWidth"
          prop="JFBL"
        >
          <el-input-number
            :precision="2"
            :min="0"
            :max="100"
            v-model="setJFInfo.BL"
          />%
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer center">
        <el-button @click="setJFDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmSetJFDialog">确 定</el-button>
      </div>
    </el-dialog>

    <!--教师获奖弹出框-->
    <el-dialog
      title="新增"
      :visible.sync="teacherdialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="XSHJSZInfo" :rules="stduentrules" ref="stduentsbmitfrom">
        <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
          <el-input
            v-model.number="XSHJSZInfo.PXH"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="类别名称"
          :label-width="formLabelWidth"
          prop="LBMC"
        >
          <el-input v-model="XSHJSZInfo.LBMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="关联维度" :label-width="formLabelWidth">
          <el-cascader
            :props="{ expandTrigger: 'hover' }"
            v-model="XSHJSZInfo.SSWD"
            :options="sswd"
            :show-all-levels="false"
            placeholder="请选择末级维度"
            @change="handleWdSelected"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="计分比例设置"
          :label-width="formLabelWidth"
          prop="DJSZTJ"
        >
          <div class="BL-list" v-for="(item, index) in BLLB" :key="index">
            <div class="BL-item">
              {{ item.MC }}--{{ item.BL }}
              <el-button
                type="text"
                @click="delBLList(item)"
                style="margin-left: 10px"
                >删除</el-button
              >
            </div>
          </div>
          <el-button type="primary" @click="setJFDialogFormVisible = true"
            >添加</el-button
          >
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="teacherdialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitstudentdata"
          :disabled="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--班级获奖弹出框-->
    <el-dialog
      title="新增"
      :visible.sync="classdialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form :model="BJHJSZInfo" :rules="classrules" ref="classsbmitfrom">
        <el-form-item
          label="奖项等级"
          :label-width="formLabelWidth"
          prop="HJDJMC"
        >
          <el-input v-model="BJHJSZInfo.HJDJMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
          <el-input-number
            v-model="BJHJSZInfo.PXH"
            :min="1"
            :max="10000"
            label="排序号"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="班级得分"
          :label-width="formLabelWidth"
          prop="BJDF"
        >
          <el-input v-model="BJHJSZInfo.BJDF" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="班主任得分"
          :label-width="formLabelWidth"
          prop="BZRDF"
        >
          <el-input v-model="BJHJSZInfo.BZRDF" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="班级成员得分"
          :label-width="formLabelWidth"
          prop="BJCYDF"
        >
          <el-input v-model="BJHJSZInfo.BJCYDF" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="classdialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitclassdata"
          :disabled="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--社团获奖弹出框-->
    <el-dialog
      title="新增"
      :visible.sync="associationdialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form
        :model="STHJSZInfo"
        :rules="associationrules"
        ref="associationsbmitfrom"
      >
        <el-form-item
          label="奖项等级"
          :label-width="formLabelWidth"
          prop="HJDJMC"
        >
          <el-input v-model="STHJSZInfo.HJDJMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
          <el-input-number
            v-model="STHJSZInfo.PXH"
            :min="1"
            :max="10000"
            label="排序号"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="带团教师得分"
          :label-width="formLabelWidth"
          prop="DTJSDF"
        >
          <el-input v-model="STHJSZInfo.DTJSDF" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="团队成员得分"
          :label-width="formLabelWidth"
          prop="TDCYDF"
        >
          <el-input v-model="STHJSZInfo.TDCYDF" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="associationdialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="submitassociationdata"
          :disabled="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--类别设置弹出框-->
    <el-dialog
      title="类别管理"
      :visible.sync="categorydialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <div class="table-er-box" style="float: left">
        <el-button type="primary" icon="el-icon-edit" @click="addcategory"
          >新增</el-button
        >
      </div>
      <br />
      <br />
      <el-table :data="categorylist">
        <el-table-column prop="LBMC" label="类别名称">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.LBMC"
              placeholder="类别名称"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="SSWD" label="所属维度">
          <template slot-scope="scope">
            <el-select v-model="scope.row.SSWD" placeholder="请选择维度">
              <el-option
                v-for="option in wdlist"
                :key="option.Id"
                :label="option.WDMC"
                :value="option.Id"
                >{{ option.WDMC }}</el-option
              >
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="数据标识">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.SJBS"
              :min="1"
              :max="100"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="deletecategory(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="categorydialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="savecategory"
          :disabled.sync="isDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--学生获奖设置-->
    <el-dialog
      title="学生获奖设置"
      :visible.sync="studentrecordersetvisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <StudentSet ref="studentsetchild" />
    </el-dialog>

    <!--班级获奖设置-->
    <el-dialog
      title="班级获奖设置"
      :visible.sync="classrecordersetvisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <ClassSet ref="classsetchild" />
    </el-dialog>

    <!--社团获奖设置-->
    <el-dialog
      title="社团获奖设置"
      :visible.sync="grouprecordersetvisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <GroupSet ref="groupsetchild" />
    </el-dialog>

    <!--教师获奖设置-->
    <el-dialog
      title="教师获奖设置"
      :visible.sync="teacherrecordersetvisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <TeacherSet ref="teachersetchild" />
    </el-dialog>
  </div>
</template>

<script>
import { isNumber } from "@/style/js/Validator.js";

import StudentSet from "@/views/schoolawardmanage/StudentSet.vue";
import ClassSet from "@/views/schoolawardmanage/ClassSet.vue";
import GroupSet from "@/views/schoolawardmanage/GroupSet.vue";
import TeacherSet from "@/views/schoolawardmanage/TeacherSet.vue";
import GradeSet from "@/views/schoolawardmanage/GradeSet.vue";

export default {
  components: {
    StudentSet: StudentSet,
    ClassSet: ClassSet,
    GroupSet: GroupSet,
    TeacherSet: TeacherSet,
    GradeSet,
  },
  name: "schoolprizeset",
  data() {
    return {
      isDisabled: false,
      token: this.$store.state.token,
      studenttableData: [],
      teachertableData: [],
      classtableData: [],
      associationtableData: [],
      ssxx: "",
      areaId: "",
      studentdialogFormVisible: false,
      teacherdialogFormVisible: false,
      classdialogFormVisible: false,
      associationdialogFormVisible: false,
      setGradeDialogFormVisible: false, //等级设置弹窗
      setJFDialogFormVisible: false, //计分设置弹窗
      sswd: [], //关联维度
      studenthtml: true,
      teacherhtml: false,
      classhtml: false,
      studentrecordersetvisible: false,
      teacherrecordersetvisible: false,
      classrecordersetvisible: false,
      grouprecordersetvisible: false,
      associationhtml: false,
      formLabelWidth: "120px",
      userId: "",
      username: "",
      selectType: "1",
      // 类别管理
      categorydialogFormVisible: false,
      categorylist: [],
      wdlist: [],
      // 学生获奖
      XSHJSZInfo: {
        Id: "",
        HJDJMC: "",
        JF: "",
        JSJF: "",
        JSJFLX: 1,
        CJR: "",
        CJSJ: "",
        SSXX: "",
        PXH: "",
        SYZT: 1,
        SFSC: 2,
        BLLB: [],
      },
      setGradeType: 1, //设置等级
      setGradeList: [],
      GradeInfo: {},
      setJFInfo: {}, //设置计分弹窗
      BLLB: [], //计分比例列表
      // 教师获奖
      JSHJSZInfo: {
        Id: "",
        HJDJMC: "",
        JF: "",
        CJR: "",
        CJSJ: "",
        SJBS: 1,
        SSXX: "",
        PXH: "",
        SYZT: 1,
        SFSC: 2,
      },
      // 班级获奖
      BJHJSZInfo: {
        Id: "",
        HJDJMC: "",
        BJDF: "",
        BZRDF: "",
        BJCYDF: "",
        CJR: "",
        CJSJ: "",
        SSXX: "",
        PXH: 0,
        SYZT: 1,
        SFSC: 2,
      },
      // 社团获奖
      STHJSZInfo: {
        Id: "",
        HJDJMC: "",
        DTJSDF: "",
        TDCYDF: "",
        CJR: "",
        CJSJ: "",
        SSXX: "",
        PXH: 0,
        SYZT: 1,
        SFSC: 2,
      },
      //规则
      stduentrules: {
        HJDJMC: [
          {
            required: true,
            message: "请输入获奖等级",
            trigger: "blur",
          },
        ],
        JF: [
          {
            validator: isNumber,
            message: "加分必须为数字",
            trigger: "blur",
          },
        ],
        JSJF: [
          {
            validator: isNumber,
            message: "教师加分必须为数字",
            trigger: "blur",
          },
        ],
      },
      teacherrules: {
        HJDJMC: [
          {
            required: true,
            message: "请输入获奖等级",
            trigger: "blur",
          },
        ],
        JF: [
          {
            validator: isNumber,
            message: "加分必须为数字",
            trigger: "blur",
          },
        ],
      },
      classrules: {
        HJDJMC: [
          {
            required: true,
            message: "请输入获奖等级",
            trigger: "blur",
          },
        ],
        BJDF: [
          {
            validator: isNumber,
            message: "班级得分必须为数字",
            trigger: "blur",
          },
        ],
        BZRDF: [
          {
            validator: isNumber,
            message: "班主任加分必须为数字",
            trigger: "blur",
          },
        ],
        BJCYDF: [
          {
            validator: isNumber,
            message: "班级成员得分必须为数字",
            trigger: "blur",
          },
        ],
      },
      associationrules: {
        HJDJMC: [
          {
            required: true,
            message: "请输入获奖等级",
            trigger: "blur",
          },
        ],
        DTJSDF: [
          {
            validator: isNumber,
            message: "带团教师得分必须为数字",
            trigger: "blur",
          },
        ],
        TDCYDF: [
          {
            validator: isNumber,
            message: "团队成员得分必须为数字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created: function () {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.username = userinfo.DLM;
    this.ssxx = userinfo.SSZZId;
    this.userId = userinfo.Id;
  },
  async mounted() {
    await this.getAreaId();
    await this.getWDList();
    this.getDimensions();
    this.getTableData();
    // this.refloadlist();
  },
  methods: {
    closeDJ() {
      console.log("关闭");
      this.setGradeDialogFormVisible = false;
    },
    async refresh() {
      await this.getTreeDate();
    },
    //获取等级树
    getTreeDate() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/schoolprizeset/GetLevelTree",
          {
            SSXX: this.ssxx,
            LBID: this.GradeInfo.Id,
            token: this.$store.state.token,
          },
          (r) => {
            let list = r.Target || [];
            this.setGradeList = list.sort((a, b) => a.pxh - b.pxh);
            resolve();
          }
        );
      });
    },
    //删除比例列表
    delBLList(data) {
      this.BLLB = this.BLLB.filter((i) => i.MC !== data.MC);
    },

    //等级设置弹窗的确认
    sumbitFormDJ(data) {
      this.setGradeDialogFormVisible = false;
    },

    //等级设置
    async DJsetting(index, row) {
      this.GradeInfo = row;
      await this.getTreeDate();
      this.setGradeDialogFormVisible = true;
    },

    // 选中子级
    handleWdSelected(value) {
      this.XSHJSZInfo.GLWD = value[1];
    },

    //获取末级维度
    getDimensions() {
      this.$api.post(
        "/v1/dimension/GetDimensionTree",
        {
          LX: this.selectType,
          token: this.$store.state.token,
          AreaId: this.areaId,
          // PagerInfo: pageinfo
        },
        (r) => {
          this.sswd = JSON.parse(JSON.stringify(r.Target || []));
        }
      );
    },

    //确认计分设置弹窗
    confirmSetJFDialog() {
      this.setJFDialogFormVisible = false;
      this.BLLB.push(this.setJFInfo);
      this.setJFInfo = {};
    },
    //确认等级设置弹窗
    confirmSetGradeDialog() {
      this.setGradeDialogFormVisible = false;
    },
    //设置等级弹窗
    setGrade(type, data) {
      this.setGradeType = type;
      this.setGradeDialogFormVisible = true;
    },
    //导入
    importdatafromarea: function () {
      this.$api.post(
        "/v1/schoolprizeset/ImportDataFromArea",
        { token: this.$store.state.token, SSXX: this.ssxx, CJR: this.userId },
        (r) => {
          if (r.Code === 0) {
            this.refloadlist();
            this.$message({
              type: "info",
              message: "导入区域数据成功",
            });
          } else {
            this.$message({
              type: "info",
              message: r.Message,
            });
          }
        }
      );
    },
    // 切换列表
    handleSelect(key) {
      this.selectType = key;
      this.getDimensions();
      this.studenttableData = [];

      this.getTableData();
      // this.refloadlist();
    },
    getAreaId: function () {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/areaschool/GetAreaSch",
          { token: this.$store.state.token, Id: this.ssxx },
          (r) => {
            var resp = r.Target;
            var data = resp.Target;
            if (
              data !== undefined &&
              data !== null &&
              data.QYId !== undefined &&
              data.QYId !== null
            ) {
              this.areaId = data.QYId;
            }
            resolve();
          }
        );
      });
    },
    // 获取维度
    getWDList() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/areaprizeset/GetWDBListTreeByAreaId",
          { AreaId: this.areaId, token: this.token },
          (r) => {
            if (r.Code === 0) {
              this.wdlist = r.Target;
              resolve();
            } else {
              reject();
            }
          }
        );
      });
    },
    //获取表格数据（新）
    getTableData() {
      this.$api.post(
        "v1/schoolprizeset/GetXXHJLBGLListByType",
        { SSXX: this.ssxx, token: this.token, HQLB: this.selectType },
        (r) => {
          if (r.Code === 0) {
            let list = r.Target || [];
            list.forEach((item) => {
              let a = this.wdlist.find((i) => i.Id == item.SSWD);
              if (a) {
                item.SSWDMC = a.WDMC;
              }
            });
            this.studenttableData = list.sort((a, b) => a.PXH - b.PXH);
          }
        }
      );
    },
    // 刷新列表
    refloadlist: function () {
      this.studenthtml = false;
      this.teacherhtml = false;
      this.classhtml = false;
      this.associationhtml = false;
      if (this.selectType == 1) {
        this.studenthtml = true;
        this.$api.post(
          "v1/schoolprizeset/GetStudentPrizeList",
          { SchoolId: this.ssxx, token: this.token },
          (r) => {
            if (r.Code === 0) {
              this.studenttableData = r.Target;
            }
          }
        );
      } else if (this.selectType == 3) {
        this.classhtml = true;
        this.$api.post(
          "v1/schoolprizeset/GetClassPrizeList",
          { SchoolId: this.ssxx, token: this.token },
          (r) => {
            if (r.Code === 0) {
              this.classtableData = r.Target;
            }
          }
        );
      } else if (this.selectType == 2) {
        this.teacherhtml = true;
        this.$api.post(
          "v1/schoolprizeset/GetTeacherPrizeList",
          { SchoolId: this.ssxx, token: this.token },
          (r) => {
            if (r.Code === 0) {
              this.teachertableData = r.Target;
            }
          }
        );
      } else if (this.selectType == 4) {
        this.associationhtml = true;
        this.$api.post(
          "v1/schoolprizeset/GetAssociationPrizeList",
          { SchoolId: this.ssxx, token: this.token },
          (r) => {
            if (r.Code === 0) {
              this.associationtableData = r.Target;
            }
          }
        );
      }
    },
    // 打开获奖设置
    openrecordset: function () {
      switch (this.selectType) {
        case "1":
          this.studentrecordersetvisible = true;
          if (
            this.$refs.studentsetchild !== null &&
            this.$refs.studentsetchild !== undefined
          ) {
            if (
              this.$refs.studentsetchild.loadroles !== null &&
              this.$refs.studentsetchild.loadroles !== undefined
            ) {
              this.$refs.studentsetchild.loadroles();
            }
            if (
              this.$refs.studentsetchild.loadrecorders !== null &&
              this.$refs.studentsetchild.loadrecorders !== undefined
            ) {
              this.$refs.studentsetchild.loadrecorders();
            }
            if (
              this.$refs.studentsetchild.loadverifiers !== null &&
              this.$refs.studentsetchild.loadverifiers !== undefined
            ) {
              this.$refs.studentsetchild.loadverifiers();
            }
          }
          break;
        case "3":
          this.classrecordersetvisible = true;
          if (
            this.$refs.classsetchild !== null &&
            this.$refs.classsetchild !== undefined
          ) {
            if (
              this.$refs.classsetchild.loadroles !== null &&
              this.$refs.classsetchild.loadroles !== undefined
            ) {
              this.$refs.classsetchild.loadroles();
            }
            if (
              this.$refs.classsetchild.loadrecorders !== null &&
              this.$refs.classsetchild.loadrecorders !== undefined
            ) {
              this.$refs.classsetchild.loadrecorders();
            }
            if (
              this.$refs.classsetchild.loadverifiers !== null &&
              this.$refs.classsetchild.loadverifiers !== undefined
            ) {
              this.$refs.classsetchild.loadverifiers();
            }
          }
          break;
        case "2":
          this.teacherrecordersetvisible = true;
          if (
            this.$refs.teachersetchild !== null &&
            this.$refs.teachersetchild !== undefined
          ) {
            if (
              this.$refs.teachersetchild.loadroles !== null &&
              this.$refs.teachersetchild.loadroles !== undefined
            ) {
              this.$refs.teachersetchild.loadroles();
            }
            if (
              this.$refs.teachersetchild.loadrecorders !== null &&
              this.$refs.teachersetchild.loadrecorders !== undefined
            ) {
              this.$refs.teachersetchild.loadrecorders();
            }
            if (
              this.$refs.teachersetchild.loadverifiers !== null &&
              this.$refs.teachersetchild.loadverifiers !== undefined
            ) {
              this.$refs.teachersetchild.loadverifiers();
            }
            // if (this.$refs.teachersetchild.setopenteacherselectstudentstatus !== null && this.$refs.teachersetchild.setopenteacherselectstudentstatus !== undefined)
            //   this.$refs.teachersetchild.setopenteacherselectstudentstatus();
          }
          break;
        case "4":
          this.grouprecordersetvisible = true;
          if (
            this.$refs.groupsetchild !== null &&
            this.$refs.groupsetchild !== undefined
          ) {
            if (
              this.$refs.groupsetchild.loadroles !== null &&
              this.$refs.groupsetchild.loadroles !== undefined
            ) {
              this.$refs.groupsetchild.loadroles();
            }
            if (
              this.$refs.groupsetchild.loadrecorders !== null &&
              this.$refs.groupsetchild.loadrecorders !== undefined
            ) {
              this.$refs.groupsetchild.loadrecorders();
            }
            if (
              this.$refs.groupsetchild.loadverifiers !== null &&
              this.$refs.groupsetchild.loadverifiers !== undefined
            ) {
              this.$refs.groupsetchild.loadverifiers();
            }
          }
          break;
      }
    },
    //* **********************学生获奖***********************
    // 打开新增学生
    openaddstudent: function () {
      this.studentdialogFormVisible = true;
      this.isDisabled = false;
      this.XSHJSZInfo = {
        Id: "",
        HJDJMC: "",
        JF: "",
        JSJF: "",
        JSJFLX: 1,
        CJR: "",
        CJSJ: new Date(),
        SSXX: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0,
      };
      this.BLLB = [];
      this.XSHJSZInfo.SSXX = this.ssxx;
      this.XSHJSZInfo.CJR = this.userId;
    },

    // 学生修改
    studenteditRow: function (index, row) {
      this.XSHJSZInfo = row;
      this.XSHJSZInfo.SSWD = this.getSelectWD(row.SSWD);
      this.BLLB = row.bl_list;
      console.log(this.XSHJSZInfo);
      this.studentdialogFormVisible = true;
      this.isDisabled = false;
    },
    getSelectWD(wdId) {
      let arr = [];
      this.sswd.forEach((item) => {
        if (item.value === wdId) {
          arr.push(item.value);
        } else {
          if (item.children) {
            item.children.forEach((child) => {
              if (child.value === wdId) {
                arr.push(item.value);
                arr.push(child.value);
              }
            });
          }
        }
      });
      return arr;
    },

    // 学生删除
    studentdeleteRow: function (index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 提交
          this.$api.post(
            // "v1/schoolprizeset/DeleteStudentPrize",
            "v1/schoolprizeset/DeleteXXHJLB",
            {
              Id: row.Id,
              // SSXX: this.ssxx,
              // YHId: this.userId,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000,
                });
                // this.refloadlist();
                this.getTableData();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true,
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 保存学生
    submitstudentdata: function () {
      this.XSHJSZInfo.BLLB = this.$refs.stduentsbmitfrom.validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/schoolprizeset/AddXXHJLB_New",
            {
              XXHJLBGLInfo: {
                Id: this.XSHJSZInfo.Id ? this.XSHJSZInfo.Id : "",
                PXH: this.XSHJSZInfo.PXH,
                LBMC: this.XSHJSZInfo.LBMC,
                SSWD:
                  this.XSHJSZInfo.SSWD.length == 1
                    ? this.XSHJSZInfo.SSWD[0]
                    : this.XSHJSZInfo.SSWD[this.XSHJSZInfo.SSWD.length - 1],
                SSLB: this.selectType, //1-学生，2-老师
              },
              BLLB: this.BLLB,
              token: this.token,
            },
            (r) => {
              if (r.Code == 0) {
                this.studentdialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000,
                });
                this.getTableData();
              } else this.isDisabled = false;
            }
          );
        }
      });
    },
    //* **********************教师获奖***************************

    // 打开新增
    openaddteacher: function () {
      this.teacherdialogFormVisible = true;
      this.isDisabled = false;
      this.JSHJSZInfo = {
        Id: "",
        HJDJMC: "",
        JF: "",
        CJR: "",
        CJSJ: new Date(),
        SSXX: "",
        SJBS: 1,
        PXH: 0,
        SYZT: 0,
        SFSC: 0,
      };
      this.JSHJSZInfo.SSXX = this.ssxx;
      this.JSHJSZInfo.CJR = this.userId;
    },

    // 修改
    teachereditRow: function (index, row) {
      this.JSHJSZInfo = row;
      this.teacherdialogFormVisible = true;
      this.isDisabled = false;
    },
    // 教师删除
    teacherdeleteRow: function (index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/schoolprizeset/DeleteTeacherPrize",
            {
              Id: row.Id,
              SSXX: this.ssxx,
              YHId: this.userId,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000,
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true,
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 保存
    submitteacherdata: function (event) {
      event.preventDefault();
      if (this.JSHJSZInfo.HJDJMC.length > 8) {
        this.$message({
          type: "info",
          message: "奖项等级过长",
        });
      }
      this.$refs.teachersbmitfrom.validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/schoolprizeset/AddTeacherPrize",
            { XXJSHJSZInfo: this.JSHJSZInfo, token: this.token },
            (r) => {
              if (r.Code === 0) {
                this.teacherdialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000,
                });
                this.refloadlist();
              } else {
                this.isDisabled = false;
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true,
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    //* **********************班级获奖***************************
    // 打开新增
    openaddclass: function () {
      this.classdialogFormVisible = true;
      this.isDisabled = false;
      this.BJHJSZInfo = {
        Id: "",
        HJDJMC: "",
        BJDF: "",
        BZRDF: "",
        BJCYDF: "",
        CJR: "",
        CJSJ: new Date(),
        SSXX: "",
        PXH: 0,
        SYZT: 0,
        SFSC: 0,
      };
      this.BJHJSZInfo.SSXX = this.ssxx;
      this.BJHJSZInfo.CJR = this.userId;
    },

    // 修改
    classeditRow: function (index, row) {
      this.BJHJSZInfo = row;
      this.classdialogFormVisible = true;
      this.isDisabled = false;
    },
    // 删除
    classdeleteRow: function (index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/schoolprizeset/DeleteClassPrize",
            {
              Id: row.Id,
              SSXX: this.ssxx,
              YHId: this.userId,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000,
                });
                this.refloadlist();
              } else {
                this.isDisabled = false;
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true,
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 保存
    submitclassdata: function (event) {
      event.preventDefault();
      if (this.BJHJSZInfo.HJDJMC.length > 8) {
        this.$message({
          type: "info",
          message: "奖项等级过长",
        });
      }
      this.$refs.classsbmitfrom.validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/schoolprizeset/AddClassPrize",
            { XXBJHJSZInfo: this.BJHJSZInfo, token: this.token },
            (r) => {
              if (r.Code === 0) {
                this.classdialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000,
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true,
                });
                this.isDisabled = false;
              }
            }
          );
        } else {
          return false;
        }
      });
    },

    //* **********************社团获奖***************************
    // 打开新增
    openaddassociation: function () {
      this.associationdialogFormVisible = true;
      this.isDisabled = false;
      this.STHJSZInfo = {
        Id: "",
        HJDJMC: "",
        DTJSDF: "",
        TDCYDF: "",
        CJR: "",
        CJSJ: new Date(),
        SSXX: "",
        SJBS: 1,
        PXH: 0,
        SYZT: 0,
        SFSC: 0,
      };
      this.STHJSZInfo.SSXX = this.ssxx;
      this.STHJSZInfo.CJR = this.userId;
    },

    // 修改
    associationeditRow: function (index, row) {
      this.STHJSZInfo = row;
      this.associationdialogFormVisible = true;
      this.isDisabled = false;
    },
    // 删除
    associationdeleteRow: function (index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/schoolprizeset/DeleteAssociationPrize",
            {
              Id: row.Id,
              SSXX: this.ssxx,
              YHId: this.userId,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000,
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true,
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 保存
    submitassociationdata: function (event) {
      event.preventDefault();
      if (this.STHJSZInfo.HJDJMC.length > 8) {
        this.$message({
          type: "info",
          message: "奖项等级过长",
        });
      }
      this.$refs.associationsbmitfrom.validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          this.$api.post(
            "v1/schoolprizeset/AddAssociationPrize",
            { XXSTHJSZInfo: this.STHJSZInfo, token: this.token },
            (r) => {
              if (r.Code === 0) {
                this.associationdialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: r.Message,
                  duration: 3000,
                });
                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true,
                });
                this.isDisabled = false;
              }
            }
          );
        } else {
          return false;
        }
      });
    },

    /** **********************类别管理*************************************/
    // 打开类别
    opencategory: function () {
      this.categorylist = [];
      this.categorydialogFormVisible = true;
      // 获取维度
      this.$api.post(
        "v1/areaprizeset/GetWDBListTreeByAreaId",
        { AreaId: this.areaId, token: this.token },
        (r) => {
          if (r.Code === 0) {
            this.wdlist = r.Target;
            console.log("获取维度", this.wdlist);
          }
        }
      );
      // 获取类别列表
      this.loadlbdata();
    },
    loadlbdata: function () {
      // 获取类别列表
      this.$api.post(
        "v1/schoolprizeset/GetXXHJLBGLListByType",
        { SSXX: this.ssxx, HQLB: this.selectType, token: this.token },
        (r) => {
          if (r.Code === 0) {
            this.isDisabled = false;
            var resultlist = r.Target;
            if (resultlist !== null) {
              this.categorylist = resultlist;
            }
          }
        }
      );
    },
    // 打开新增
    addcategory: function () {
      var categoryinfo = {
        Id: "",
        CJR: this.userId,
        CJSJ: new Date(),
        SSXX: this.ssxx,
        SYZT: 1,
        SFSC: 2,
        LBMC: "",
        SSWD: "",
        SJBS: "",
        SSLB: this.selectType,
      };
      this.isDisabled = false;
      this.categorylist.push(categoryinfo);
    },
    // 删除类别
    deletecategory: function (index, row) {
      this.categorylist.splice(this.categorylist.indexOf(row), 1);
      if (row.Id !== "") {
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 提交
            this.$api.post(
              "v1/schoolprizeset/DeleteXXHJLB",
              {
                Id: row.Id,
                SSXX: this.ssxx,
                YHId: this.userId,
                token: this.token,
              },
              (r) => {
                if (r.Code === 0) {
                  this.loadlbdata();
                  this.$message({
                    type: "success",
                    message: r.Message,
                    duration: 3000,
                  });
                }
              }
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    // 保存
    savecategory: function () {
      if (this.categorylist.length === 0) {
        this.$message({
          type: "info",
          message: "未添加类别",
          showClose: true,
        });
        return;
      }

      let different = this.categorylist.some((item, index) => {
        if (this.categorylist[index + 1])
          return item.SJBS === this.categorylist[index + 1].SJBS;
      });
      if (different) return this.$message.error("数据标识不能重复");

      var isadd = false;
      var result = "";
      this.categorylist.forEach((item, index) => {
        if (!isadd) {
          var row = index + 1;
          if (item.LBMC === "") {
            result = "第" + row + "行,类别名称";
            isadd = true;
            return;
          }
          if (item.SSWD === "") {
            result = "第" + row + "行,未选择维度";
            isadd = true;
          }
        }
      });
      if (isadd) {
        this.$message({
          type: "info",
          message: result,
          showClose: true,
        });
        return;
      }
      // 提交
      this.isDisabled = true;
      this.$api.post(
        "v1/schoolprizeset/AddXXHJLB",
        { CategoryList: this.categorylist, token: this.token },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message,
              duration: 3000,
            });
            this.loadlbdata();
          } else {
            this.isDisabled = false;
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true,
            });
          }
        }
      );
    },
  },
};
</script>

<style>
.customWidth {
  width: 70%;
}
.my-dialog {
  max-height: 680px;
  overflow-y: scroll;
}
</style>
