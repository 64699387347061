<template>
  <div class="cont-whole-box" style="text-align: center;">
    <el-form
      :model="JSXXBInfo"
      :rules="rules"
      ref="sbmitfrom"
      label-width="80px"
      style="width: 500px; display: inline-block;"
    >
      <el-form-item style="margin-top:22px" label="姓名" prop="XM">
        <el-input v-model="JSXXBInfo.XM"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="XBM">
        <el-radio-group
          v-model="JSXXBInfo.XBM"
          style="float: left; margin-top: 15px;"
        >
          <el-radio :label="1" se>男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="联系电话" prop="LXDH">
        <el-input v-model="JSXXBInfo.LXDH"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="SFZJH">
        <el-input v-model="JSXXBInfo.SFZJH"></el-input>
      </el-form-item>
      <el-form-item label="邮      箱">
        <el-input v-model="JSXXBInfo.DZYX"></el-input>
      </el-form-item>
      <el-form-item label="来校时间" prop="LXSJ">
        <el-date-picker
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          v-model="JSXXBInfo.LXSJ"
          style="width: 100%;"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="教师岗位">
        <el-input v-model="JSXXBInfo.JSGW"></el-input>
      </el-form-item>
      <el-form-item label="所属学段" prop="SSXD">
        <el-select
          v-model="JSXXBInfo.SSXD"
          style="width:100%"
          placeholder="请选择所属学段"
          multiple
        >
          <el-option
            v-for="item in SSXDoption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最高学历">
        <el-select
          v-model="JSXXBInfo.ZGXW"
          placeholder="请选择学历"
          style="width: 100%;"
        >
          <el-option label="大专" :value="1"></el-option>
          <el-option label="本科" :value="2"></el-option>
          <el-option label="研究生" :value="3"></el-option>
          <el-option label="博士" :value="4"></el-option>
          <el-option label="博士后" :value="5"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="照片">
        <div style="width: 112px;">
          <Upload
            @getResultUrl="getUploadUrl"
            :left="0"
            :width="100"
            :height="100"
            :itemPic="JSXXBInfo.ZP ? JSXXBInfo.ZP : $store.state.defaultPhoto"
          />
        </div>
      </el-form-item>
      <br />
      <br />
      <br />
      <br />
      <el-form-item>
        <el-button
          type="primary"
          @click="submitshowdata"
          :loading="submitLoading"
          >保存</el-button
        >
        <el-button @click="canceladd">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  isInteger,
  validatePhoneTwo,
  validateIdNo
} from '@/style/js/Validator.js'
import { handle_SSXD, handle_SSXD3 } from '@/utils/common'
import Upload from '@/components/Upload.vue'
export default {
  name: 'EditTeacher',
  components: {
    Upload
  },
  data() {
    return {
      token: this.$store.state.token,
      submitLoading: false,
      JSXXBInfo: {
        Id: '',
        XM: '',
        XBM: 1,
        LXDH: '',
        SFZJH: '',
        DZYX: '',
        LXSJ: '2019-09-09 01:29:45',
        JSGW: '',
        ZGXW: 1,
        ZP: '',
        SSXX: '',
        SYZT: 0,
        SFSC: 0,
        BH: '',
        SSXD: []
      },
      fileList: [],
      webFilePath: '',
      fileServerApiPath: '',
      rules: {
        XM: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        XBM: [
          {
            required: true,
            message: '请选择性别',
            trigger: 'blur'
          },
          {
            validator: isInteger,
            message: '请选择性别',
            trigger: 'change'
          }
        ],
        LXDH: [
          { required: true, message: '请输入联系电话', trigger: 'change' },
          {
            validator: validatePhoneTwo,
            message: '请输入正确联系电话',
            trigger: 'change'
          }
        ],
        SFZJH: [
          {
            validator: validateIdNo,
            message: '格式不正确',
            trigger: 'change'
          }
        ]
      },
      userName: '',
      userId: '',
      // 所属学段option
      SSXDoption: []
    }
  },
  async created() {
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    const schoolinfo = JSON.parse(sessionStorage.getItem('schoolinfo'))
    this.SSXDoption = handle_SSXD3(schoolinfo)

    this.JSXXBInfo.SSXX = userinfo.SSZZId
    this.fileServerApiPath = userinfo.fileServerApiPath
    this.webFilePath = userinfo.webFilePath
    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    if (this.$route.params.JSXXBInfo) {
      let form = this.$route.params.JSXXBInfo
      if (form.SSXD === 0) {
        this.JSXXBInfo.SSXD = []
      } else {
        form.SSXD = handle_SSXD(form.SSXD)
      }
      this.JSXXBInfo = form
    } else {
      this.JSXXBInfo.SSXD = [this.SSXDoption[0].Id]
    }
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 上传图片路径
    getUploadUrl(filePath) {
      this.JSXXBInfo.ZP = filePath
    },
    // 取消
    canceladd: function() {
      this.$router.push({ path: 'TeacherList' })
    },
    // 保存
    submitshowdata: function() {
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          let JSXXBInfo = {
            ...this.JSXXBInfo
          }
          let sum = 0
          if (JSXXBInfo.SSXD) {
            JSXXBInfo.SSXD.forEach(item => (sum += item))
          }
          JSXXBInfo.SSXD = sum

          this.submitLoading = true
          this.$api.post(
            'v1/teacher/AddTeacher',
            {
              JSXXBInfo,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })
                this.$router.push({ path: 'TeacherList' })
                this.submitLoading = false
              } else this.submitLoading = false
            }
          )
        } else {
          this.submitLoading = false
          return false
        }
      })
    }
  }
}
</script>
