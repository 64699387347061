<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'totalscore' + this.exportType">
					<img :src="xsinfo.ZP" :class="'studenticon' + this.exportType" />
					<div :class="'scoredesc' + this.exportType">
						<el-row>
							<el-col :class="'scoretitle' + this.exportType">
								{{ xsinfo.XM }}
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="8" :class="'scoredescstr' + this.exportType">
								性别：{{ xsinfo.XBM==1?'男':'女' }}
							</el-col>
							<!-- <el-col :span="8" :class="'scoredescstr' + this.exportType">
								年龄：{{ xsinfo.NL }}岁
							</el-col> -->
							<el-col :span="8" :class="'scoredescstr' + this.exportType">
								班级：{{ xsinfo.BJMC }}
							</el-col>
						</el-row>
					</div>
					<div class="clboth"></div>
				</div>
				<div :class="'detailinfo' + this.exportType" v-if="scorelist.length > 0">
					<table style="width:100%;height:100%;border-radius: 15px;overflow: hidden;"
						:class="'tableboder' + this.exportType">
						<tr style="background: #E7F6FD;">
							<th :class="'detailstr' + this.exportType">项目</th>
							<th :class="'detailstr' + this.exportType">个人成绩</th>
							<th :class="'detailstr' + this.exportType">年级平均成绩</th>
							<th :class="'detailstr' + this.exportType">评价</th>
						</tr>
						<tr v-for="(item, index) in scorelist" :key="index" :class="'detailscore' + exportType">
							<td style="font-weight:bold;" :class="'tableboder' + exportType">
								{{ item.TypeName }}
							</td>
							<td :class="'tableboder' + exportType">
								{{ item.IndexNumberScore }}
							</td>
							<td :class="'tableboder' + exportType">
								{{ item.IndexNameNJScore }}
							</td>
							<td :class="'tableboder' + exportType">{{ item.LevelName }}</td>
						</tr>
					</table>
				</div>
				<div id="physiqueLineChart" :class="'scorebar' + this.exportType"></div>
				<div :class="'detailrow' + this.exportType">
					<!-- <div style="background-color: #FFFFFF;width: 100%;height: 100%; padding: 10px 20px;margin: -10px -20px; border-radius: 15px;"> -->
					{{ xsinfo.stsz }}
					<!-- </div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				backgroudimage: "",
				exportType: 1,
				myChart: null,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "身心健康-身体素质"
				},
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					stsz: ""
				},
				stszdata: [],
				scorelist: []
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			drawLine(xarry, xsarr, bjarr) {
				if (this.myChart) {
					this.myChart.dispose();
				}
				this.myChart = this.$echarts.init(
					document.getElementById("physiqueLineChart")
				);

				let min_xs = Math.floor(Math.min(...xsarr));
				let min_bj = Math.floor(Math.min(...bjarr));
				let max_xs = Math.ceil(Math.max(...xsarr));
				let max_bj = Math.ceil(Math.max(...bjarr));

				this.myChart.setOption({
					tooltip: {},
					xAxis: {
						data: xarry
					},
					backgroundColor: "#ffffff",
					yAxis: {
						type: 'value',
						min: min_xs < min_bj ? min_xs : min_bj,
						// max: max_xs > max_bj ? max_xs : max_bj
					},
					legend: {
						bottom: "10",
						data: ["个人身体素质", "年级身体素质"]
					},
					series: [{
							name: "个人身体素质",
							type: "line",
							data: xsarr,
							itemStyle: {
								color: "#009830"
							}
						},
						{
							name: "年级身体素质",
							type: "line",
							data: bjarr,
							itemStyle: {
								color: "#B861BB"
							}
						}
					]
				});
			},
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				this.getxsreportdata();
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthreport/GetStudentPhysicalFitness", {
						ClassId: this.xsinfo.SSBJ,
						SchoolId: this.xsinfo.SSXX,
						TermId: this.xqid,
						SSXN: this.xsinfo.SSXN,
						SchoolCode: this.xsinfo.XXBM,
						StudentId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var info = r.Target;
							if (info != null && info != undefined) {
								if (
									info.ScoreList != null &&
									info.ScoreList != undefined &&
									info.ScoreList.length > 0
								) {
									this.scorelist = [];
									if (info.ScoreList.length <= 3) {
										this.scorelist = info.ScoreList;
									} else {
										for (var i = 0; i < 3; i++) {
											this.scorelist.push(info.ScoreList[i]);
										}
									}
								}
								if (info.STSZ != null && info.STSZ != undefined) {
									this.stszdata = JSON.parse(info.STSZ);
								}
								if (info.AnalysisContent) {
									this.xsinfo.stsz = info.AnalysisContent;
								}

								this.drawcharts();
							}
						}
					}
				);
			},
			drawcharts: function() {
				this.drawLine(
					this.stszdata.map(item => {
						return item.MC;
					}),
					this.stszdata.map(item => {
						return item.Score;
					}),
					this.stszdata.map(item => {
						return item.NScore;
					})
				);
			}
		},
		mounted() {},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 125px;
		height: 125px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 20px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 2px;
		margin-left: 2px;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/blue_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/blue_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.totalscore1 {
		height: 150px;
		width: 760px;
		margin-top: 10px;
		margin-left: 20px;
		/*background: #ffffff;*/
		border-radius: 25px;
	}

	.totalscore2 {
		height: 90px;
		width: 535px;
		margin-top: 20px;
		margin-left: 30px;
		/*background: #ffffff;*/
		border-radius: 15px;
	}

	.scoretitle1 {
		/*margin-top: 25px;*/
		width: 125px;
		height: 30px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.scoretitle2 {
		width: 80px;
		height: 25px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.scoredescstr1 {
		width: 180px;
		height: 22px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		vertical-align: middle;
		margin-top: 20px;
	}

	.scoredescstr2 {
		width: 125px;
		height: 18px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		vertical-align: middle;
		margin-top: 10px;
	}

	.scoredesc1 {
		width: 560px;
		height: 112px;
		text-align: left;
		float: left;
		vertical-align: middle;
		margin-top: 40px;
		margin-left: 20px;
	}

	.scoredesc2 {
		width: 410px;
		height: 55px;
		text-align: left;
		float: left;
		vertical-align: middle;
		margin-top: 12px;
		margin-left: 10px;
	}

	.scorebar1 {
		height: 350px;
		width: 760px;
		margin-left: 40px;
		margin-top: 10px;
		// background: #ffffff;
		border-radius: 15px;
		overflow: hidden;
	}

	.scorebar2 {
		height: 280px;
		width: 535px;
		margin-top: 10px;
		margin-left: 30px;
		// background: #ffffff;
		border-radius: 10px;
	}

	.detailinfo1 {
		width: 760px;
		height: 280px;
		margin-top: 10px;
		margin-left: 25px;
		// background: #ffffff;
		// background-image: url("../../../../style/growth/rectangle_back.png");
		// border-radius: 10px;
		text-align: center;
		padding: 15px;
	}

	.detailinfo2 {
		width: 515px;
		height: 180px;
		margin-top: 10px;
		margin-left: 30px;
		// background: #ffffff;
		// background-image: url("../../../../style/growth/rectangle_back.png");
		border-radius: 10px;
		text-align: center;
		padding: 10px;
	}

	.detailrow1 {
		height: 90px;
		width: 720px;
		margin-top: 10px;
		margin-left: 40px;
		background-color: #ffffff;
		// background-image: url("../../../../style/growth/rectangle_back.png");
		backcolor: #ffffff;
		border-radius: 15px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		padding: 10px 20px;
		color: #080808;
	}

	.detailrow2 {
		height: 60px;
		width: 515px;
		margin-top: 10px;
		margin-left: 30px;
		background: #ffffff;
		border-radius: 10px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		padding: 5px 10px;
		color: #080808;
	}

	.detailstr1 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		width: 175px;
	}

	.detailstr2 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		width: 127px;
	}

	.detailscore1 {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		// background-image: url("../../../../style/growth/rectangle_back.png");
		background-color: #ffffff;
		color: #333333;
	}

	.detailscore2 {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		background-color: #ffffff;
		color: #333333;
	}

	.tableboder1 {
		border: 2px solid #cccccc;
		// border-radius: 10px;
		// overflow: hidden;
	}

	.tableboder2 {
		border: 1px solid #cccccc;
	}
</style>