<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="essay-name-box">{{ hdmc }}-结果查看</div>
    </div>
    <div class="tab-heng-box margno-t">
      <div class="table-er-box" style="float: right;">
        <div
          class="table-er-butt"
          @click="downloadnotfinish()"
          :disabled="isDisabled"
        >
          <span class="table-er-buttspan c-blue">未完成情况表</span>
        </div>
        <div
          class="table-er-butt"
          @click="downloadresultreport()"
          :disabled="isDisabled"
        >
          <span class="table-er-buttspan c-blue">一键下载结果</span>
        </div>
      </div>
      <el-table
        border
        :data="reportlist"
        highlight-current-row
        style="width: 100%;"
      >
        <el-table-column align="center" label="调查项目">
          <template slot-scope="scope">
            <span
              class="c-blue"
              v-if="scope.row.SSLB == 1 && scope.row.AnswerCount > 0"
              @click="handleSchoolReport"
              >{{ SZLX.filter(c => c.Key == scope.row.SSLB)[0].Value }}</span
            >
            <span v-if="scope.row.SSLB == 1 && scope.row.AnswerCount <= 0">
              {{ SZLX.filter(c => c.Key == scope.row.SSLB)[0].Value }}
            </span>

            <span
              class="c-blue"
              v-else-if="scope.row.SSLB == 2 && scope.row.AnswerCount > 0"
              @click="handleClassReport"
              >{{ SZLX.filter(c => c.Key == scope.row.SSLB)[0].Value }}</span
            >
            <span
              v-else-if="scope.row.SSLB == 2 && scope.row.AnswerCount <= 0"
              >{{ SZLX.filter(c => c.Key == scope.row.SSLB)[0].Value }}</span
            >
            <span
              class="c-blue"
              v-else-if="scope.row.SSLB == 0 && scope.row.AnswerCount > 0"
              @click="handleSuggestionReport"
              >意见建议</span
            >
            <span v-else-if="scope.row.SSLB == 0 && scope.row.AnswerCount <= 0"
              >意见建议</span
            >
            <span
              class="c-blue"
              v-else-if="scope.row.SSLB == 3 && scope.row.AnswerCount > 0"
              @click="handleTeacherReport(scope.row.KCId, scope.row.KCMC)"
            >
              {{ scope.row.KCMC
              }}{{ SZLX.filter(c => c.Key == scope.row.SSLB)[0].Value }}
            </span>
            <span v-else-if="scope.row.SSLB == 3 && scope.row.AnswerCount <= 0">
              {{ scope.row.KCMC
              }}{{ SZLX.filter(c => c.Key == scope.row.SSLB)[0].Value }}
            </span>
            <span
              class="c-blue"
              v-else-if="scope.row.SSLB == 4"
              @click="clickQuestion"
            >
              其它
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="平均得分">
          <template slot-scope="scope">
            <span v-if="scope.row.SSLB != 0">{{ scope.row.Score }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="NeedAnswerCount"
          label="应调人数"
        ></el-table-column>
        <el-table-column
          align="center"
          property="AnswerCount"
          label="实答人数"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 问卷弹窗 -->
    <el-dialog
      :title="question.WJMC"
      :visible.sync="showQuestion"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-table :data="questionTableData" style="width: 100%">
        <el-table-column prop="TMMC" label="问题"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              plain
              @click="showQuestionDetail(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showQuestion = false" size="mini">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 问题满意度详情 -->
    <el-dialog
      :title="curTM.TMMC"
      :visible.sync="showTM"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-table :data="tmTableData" style="width: 100%">
        <el-table-column
          v-for="(item, index) in tmTableHeader"
          :key="index"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span v-if="curTM.SSLB !== 3">{{ scope.row[item.prop] }}</span>
            <span v-else>
              <span v-if="item.prop === 'className'">
                {{ scope.row[item.prop] }}
              </span>
              <el-button
                v-else
                type="text"
                plain
                size="mini"
                @click="clickAnswerDetail(scope.row)"
                >详情</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTM = false" size="mini">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 问答类型详情 -->
    <el-dialog
      :title="curTM.TMMC"
      :visible.sync="showAnswerTable"
      width="70%"
      :close-on-click-modal="false"
    >
      <el-table :data="answerTableList" style="width: 100%">
        <el-table-column label="姓名" prop="Name" />
        <el-table-column label="评价内容" prop="PJNR" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAnswerTable = false" size="mini"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    // 获取所属学校
    this.ssxq = this.$route.params.xqid;
    var tempuser = localStorage.getItem("userinfo");
    this.dcid = this.$route.params.id;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.userInfo = user;
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
    this.term = JSON.parse(localStorage.getItem("curTerm"));
  },
  async mounted() {
    await this.getSchoolClass();
    await this.getQuestionList();
    this.loaddata();
  },
  methods: {
    loaddata: function() {
      this.isDisabled = true;
      this.$api.post(
        "v1/survey/GetSurveResultReport",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          token: this.token
        },
        r => {
          // this.isDisabled = false;
          if (r.Code === 0) {
            this.reportlist = [];
            this.hdmc = r.Target.HDMC;
            this.reportlist = r.Target.ReportList;
          }
        }
      );
    },
    downloadnotfinish: function() {
      this.isDisabled = true;
      this.$api.post(
        "v1/survey/GetNotFinishStudent",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          token: this.token
        },
        r => {
          this.isDisabled = false;
          if (r.Code === 0) {
            window.open(r.Message, "_blank");
          } else {
            this.$message({
              type: "info",
              message: r.Message
            });
          }
        }
      );
    },
    downloadresultreport: function() {
      this.$api.post(
        "v1/survey/DownloadSurveResult",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            window.open(r.Message, "_blank");
          } else {
            this.$message({
              type: "info",
              message: r.Message
            });
          }
        }
      );
    },
    handleSuggestionReport: function() {
      localStorage.setItem("surveyname", this.hdmc);
      this.$router.push({
        name: "suggestionreport",
        params: { id: this.dcid, xqid: this.ssxq }
      });
    },
    handleSchoolReport: function() {
      localStorage.setItem("surveyname", this.hdmc);
      localStorage.setItem("surveysubname", "学校满意度");
      this.$router.push({
        name: "schoolreport",
        params: { id: this.dcid, xqid: this.ssxq }
      });
    },
    handleClassReport: function() {
      localStorage.setItem("surveyname", this.hdmc);
      localStorage.setItem("surveysubname", "班级满意度");
      this.$router.push({
        name: "classreport",
        params: { id: this.dcid, xqid: this.ssxq }
      });
    },
    handleTeacherReport: function(kcid, kcmc) {
      localStorage.setItem("surveyname", this.hdmc);
      localStorage.setItem("surveysubname", kcmc + "老师");
      this.$router.push({
        name: "teacherreport",
        params: { id: this.dcid, kcid: kcid, xqid: this.ssxq }
      });
    },
    // 获取问卷
    getQuestionList() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/questionnaire/GetQuestionnaireList",
          {
            token: this.token,
            SchoolId: this.ssxx
          },
          res => {
            if (res.Code === 0) {
              this.questionList = res.Target || [];
              resolve();
            }
          }
        );
      });
    },
    // 点击问卷
    async clickQuestion() {
      let res = await this.getQuestionSet();
      if (res.MYDWJList && res.MYDWJList.length > 0) {
        let questionId = res.MYDWJList[0].WJId;
        let WJ = this.questionList.find(item => item.Id === questionId);
        this.question = WJ;
        this.questionTableData = WJ.WJTMBList;
        this.showQuestion = true;
      }
    },
    // 获取设置的问卷
    getQuestionSet() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/survey/GetSurvey",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            Id: this.dcid,
            token: this.token
          },
          res => {
            if (res.Code === 0) {
              resolve(res.Target);
            }
          }
        );
      });
    },
    // 获取问卷问题满意度详情
    getQuestionSurvy() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/survey/GetQuestionnaireStatistics",
          {
            WTId: this.curTM.Id,
            SchoolCode: this.userInfo.SchoolCode,
            token: this.token
          },
          res => {
            if (res.Code === 0) {
              this.tmDetail = res.Target || [];
              resolve();
            }
          }
        );
      });
    },
    // 获取学校所有班级
    getSchoolClass() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolOrg",
          {
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            IsDelete: 2, //	是	int	1.表示需要获取已删除和禁用数据
            GradeId: null, //	是	string	根据年级Id获取年级和年级以下的组织机构（不能传学校Id不是优先获取是全校的住址机构）
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              if (res.Target && res.Target.length > 0) {
                this.schoolClass = res.Target.reduce((pre, cur) => {
                  return pre.concat(cur.ChidOrgList || []);
                }, []);
              }
              resolve();
            }
          }
        );
      });
    },
    // 获取当前班级学生
    getClassStudent(classId) {
      console.log(222, "222");
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetOrgStudent",
          {
            SchoolOrgId: classId, //	是	string	组织机构ID/活动Id
            OrgType: 2, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: this.term.SSXN, //	是	string	学年
            SchoolId: this.userInfo.SSZZId, //	否	string	学校Id
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              resolve(res.Target || []);
            }
          }
        );
      });
    },
    // 点击问题查看
    async showQuestionDetail(tm) {
      this.curTM = tm;
      await this.getQuestionSurvy();
      let arr = this.tmDetail.reduce((pre, cur) => {
        if (!pre.some(BJId => BJId === cur.BJId)) pre.push(cur.BJId);
        return pre;
      }, []);

      let arr2 = arr.reduce((pre, cur) => {
        let obj = {};
        obj.BJId = cur;
        let classIt = this.schoolClass.find(classIt => classIt.Id === cur);
        if (classIt) obj.className = classIt.Name;
        let res = this.tmDetail.filter(item => item.BJId === cur);
        res.forEach(item => {
          obj[item.DAId] = item.RS;
          obj[item.DAId + "LX"] = item.WJDALX;
        });
        pre.push(obj);
        return pre;
      }, []);

      this.tmTableHeader = [
        {
          label: "班级",
          prop: "className"
        }
      ];
      let sum = {};
      if (this.curTM.SSLB === 3) {
        this.tmTableHeader.push({
          label: "操作"
        });
      } else {
        this.curTM.WJFXBList.forEach(item => {
          arr2.forEach(el => {
            if (!sum[item.Id]) sum[item.Id] = 0;
            sum[item.Id] = sum[item.Id] + (el[item.Id] || 0);
          });
          this.tmTableHeader.push({
            label: item.TMMC,
            prop: item.Id
          });
        });
        sum.className = "总和";
        arr2.push(sum);
      }
      this.tmTableData = arr2;
      this.showTM = true;
    },
    // 点击问答类详情
    async clickAnswerDetail(row) {
      let res = await this.getClassAnswerDetail(row);
      let students = await this.getClassStudent(row.BJId);
      res.forEach(item => {
        let student = students.find(el => el.Id === item.XSId);
        if (student) item.Name = student.Name;
      });
      this.answerTableList = res;
      this.showAnswerTable = true;
    },
    // 获取每个班级的问答详情
    getClassAnswerDetail(row) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/survey/GetQuestionnaireDetal",
          {
            DCId: this.dcid, //调查id
            WTId: this.curTM.Id, //题目id
            BJId: row.BJId, //班级id
            SchoolCode: this.userInfo.SchoolCode, //学校编码
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) resolve(res.Target || []);
          }
        );
      });
    }
  },
  data() {
    return {
      isDisabled: false,
      reportlist: [],
      hdmc: "",
      dcid: "",
      ssxx: "",
      ssxq: "",
      token: "",
      userInfo: {},
      term: {},
      SZLX: [
        { Key: 1, Value: "学校满意度调查" },
        { Key: 2, Value: "班级满意度" },
        { Key: 3, Value: "学科老师满意度" },
        { Key: 4, Value: "其它" }
      ],
      schoolClass: [],
      questionList: [], //所有问卷
      question: {}, //匹配到的问卷
      questionTableData: [], //处理后的题目列表数据
      showQuestion: false, //显示题目列表
      curTM: {}, //当前选中的题目
      tmDetail: [], //题目满意度详情
      tmTableHeader: [],
      tmTableData: [], //题目满意度详情列表
      showTM: false, //显示题目满意度详情
      answerTableList: [],
      showAnswerTable: false
    };
  }
};
</script>
