<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'schoolLogoDiv' + this.exportType">
				<img :src="xsinfo.XXTB" :class="'schoolLogo' + this.exportType" v-if="xsinfo.XXTB != ''" />
			</div>
			<div class="clboth"></div>
			<div :class="'schoolinfo' + this.exportType">
				{{ xsinfo.XXMC }}
			</div>
			<div :class="'pagename' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'infodiv' + this.exportType">
				<div>
					<div :class="'labletext' + this.exportType">
						姓 名:
					</div>
					<div :class="'underline' + this.exportType">
						<font :class="'textcolr' + this.exportType">{{ xsinfo.XM }}</font>
					</div>
				</div>
				<div>
					<div :class="'labletext' + this.exportType">
						班 级:
					</div>
					<div :class="'underline' + this.exportType">
						<font :class="'textcolr' + this.exportType">{{ xsinfo.BJMC }}</font>
					</div>
				</div>
				<div>
					<div :class="'labletext' + this.exportType">
						学 期:
					</div>
					<div :class="'underline' + this.exportType">
						<font :class="'textcolr' + this.exportType">{{ xsinfo.XQMC }}</font>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				exportType: 1,
				schoolinfo: {
					backgroudimage: ""
				},
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: ""
				}
			};
		},
		created() {
			this.exportType = this.$route.query.exportType ? this.$route.query.exportType * 1 : 1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		mounted() {},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo = xsdata.xsxx;
				// console.log(xsdata.xsxx)
				// this.info = xsdata.pageData;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.schooldiv {
		width: 100%;
		height: 100px;
	}

	.schoolLogoDiv1 {
		width: 90px;
		height: 90px;
		text-align: center;
		margin-top: 290px;
		margin-left: calc(50% - 45px);
		float: left;
	}

	.schoolLogoDiv2 {
		width: 50px;
		height: 50px;
		text-align: center;
		margin-top: 215px;
		margin-left: calc(50% - 25px);
		float: left;
	}

	.schoolLogo1 {
		width: 90px;
		height: 90px;
	}

	.schoolLogo2 {
		width: 50px;
		height: 50px;
	}

	.schoolinfo1 {
		text-align: center;
		margin-top: 15px;
		width: 100%;
		font-size: 30px;
		line-height: 60px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
	}

	.schoolinfo2 {
		text-align: center;
		margin-top: 10px;
		width: 100%;
		font-size: 20px;
		line-height: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
	}

	.clboth {
		clear: both;
	}

	.labletext1 {
		margin-top: 20px;
		display: inline-block;
		width: 89px;
		height: 32px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
	}

	.underline1 {
		margin-top: 20px;
		display: inline-block;
		width: 314px;
		border-bottom: 1px solid #080808;
		height: 42px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
	}

	.textcolr1 {
		color: #080808;
		margin-left: 10px;
		margin-bottom: 5px;
	}

	.pagename1 {
		text-align: center;
		margin-top: 2rem;
		width: 100%;
		height: 70px;
		font-size: 70px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #00a1e9;
		-webkit-text-stroke: 3px #ffffff;
		text-stroke: 3px #ffffff;
	}

	.nameline1 {
		margin-top: 30px;
		display: inline-block;
	}

	.labletext2 {
		width: 57px;
		height: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #080808;
		margin-top: 20px;
		display: inline-block;
	}

	.underline2 {
		margin-top: 20px;
		display: inline-block;
		width: 214px;
		border-bottom: 1px solid #080808;
		height: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
	}

	.textcolr2 {
		color: #080808;
		margin-left: 10px;
		margin-bottom: 5px;
	}

	.pagename2 {
		text-align: center;
		margin-top: 10px;
		width: 100%;
		height: 53px;
		font-size: 52px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #00a1e9;
		-webkit-text-stroke: 3px #ffffff;
		text-stroke: 3px #ffffff;
	}

	.nameline2 {
		margin-top: 20px;
		display: inline-block;
	}

	.infodiv1 {
		margin-top: 5rem;
		margin-left: calc(50% - 12rem);
	}

	.infodiv2 {
		margin-top: 2rem;
		margin-left: calc(50% - 10rem);
	}

	.bkimg {
		background-size: 100% 100%;
	}
</style>