<template>
  <router-view></router-view>
</template>

<script>
export default {
  created: function() {
    this.$router.push({ name: "StudentList" });
  }
};
</script>
