<template>
  <div style="padding: 40px;">
    <div class="cont-whole-box">
      <div style="padding: 30px;">
        <div class="essay-name-box">{{ hdmc }}-{{ subname }}</div>
      </div>
      <div class="tab-heng-box margno-t">
        <div class="table-er-box" style="float: right;">
          <div class="table-er-butt" @click="downloadreporttextdetail()">
            <span class="table-er-buttspan c-blue">下载</span>
          </div>
        </div>
        <div class="table-er-box" style="display: block;">
          <div
            style="
              font-size: 14px;
              margin-top: 10px;
              margin-right: 10px;
              display: block;
            "
          >
            {{ tmmc }}
          </div>
        </div>
        <el-table
          border
          :data="reportlist"
          highlight-current-row
          style="width: 100%;"
        >
          <el-table-column align="center" property="BJMC" label="班级">
          </el-table-column>
          <el-table-column align="center" property="XSXM" label="姓名">
          </el-table-column>
          <el-table-column align="center" property="PJNR" label="内容">
          </el-table-column>
        </el-table>
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="handleCurrentChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager
  },
  created() {
    this.ssxq = this.$route.params.xqid;
    var tempuser = localStorage.getItem("userinfo");
    this.dcid = this.$route.params.id;
    this.tmid = this.$route.params.tmid;
    this.daid = this.$route.params.daid;
    this.sslb = this.$route.params.sslb;
    this.atid = this.$route.params.atid;
    this.kcid = this.$route.params.kcid;
    this.hdmc = localStorage.getItem("surveyname");
    this.subname = localStorage.getItem("textsurveysubname");
    this.tmmc = localStorage.getItem("surveypjx");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
  },
  computed: {},
  mounted: function() {
    this.loaddata();
  },
  methods: {
    downloadreporttextdetail: function() {
      this.$api.post(
        "v1/survey/DownloadReportDetailText",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          TMId: this.tmid,
          DAId: this.daid,
          ATId: this.atid,
          KCId: this.kcid,
          SSLB: this.sslb,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            window.open(r.Message, "_blank");
          } else {
            this.$message({
              type: "info",
              message: r.Message
            });
          }
        }
      );
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    loaddata: function() {
      this.$api.post(
        "v1/survey/GetReportDetailText",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          DCId: this.dcid,
          TMId: this.tmid,
          DAId: this.daid,
          ATId: this.atid,
          KCId: this.kcid,
          SSLB: this.sslb,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.reportlist = [];
            this.reportlist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    }
  },
  data() {
    return {
      ssxx: "",
      ssxq: "",
      token: "",
      hdmc: "",
      subname: "",
      tmmc: "",
      jsid: "",
      tmid: "",
      daid: "",
      atid: "",
      kcid: "",
      reportlist: [],
      dcid: "",
      sslb: 1,
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      }
    };
  }
};
</script>
