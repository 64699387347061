import Vue from "vue";
import Vuex from "vuex";
import { menuRoutes } from "@/router/menuroutes.js";
import modules from "./modules";
Vue.use(Vuex);

const store = new Vuex.Store({
  // ��ʼ��������
  state: {
    formDatas: null,
    token: null,
    menuRouteList: null,
    permissions: "[]",
    // defaultPhoto: require("../style/images/defaultPhoto.jpg"),
    defaultTeacherMale: require("../style/images/defaultTeacherMale.png"),
    defaultTeacherFemale: require("../style/images/defaultTeacherFemale.png"),
    defaultStudentBoy: require("../style/images/defaultStudentBoy.png"),
    defaultStudentGirl: require("../style/images/defaultStudentGirl.png"),
    defaultImage: require("../style/images/defaultImage.png"),
    defaultPic: require("../style/images/default_photo.jpg"),
    router: "/",
    menu: JSON.parse(JSON.stringify(menuRoutes.filter(c => c.path !== "/"))),
    userInfo: {},
    appSecret: "3fab1e4e-bd45-486f-a5cf-3685dd1fd084"
  },
  // �ı�state�����ֵ�÷���
  mutations: {
    getFormData(state, data) {
      state.formDatas = data;
    },
    saveToken(state, data) {
      state.token = data;
      window.localStorage.setItem("Token", data);
    },
    saveMenuList(state, data) {
      state.menuRouteList = data;
      window.localStorage.setItem("MenuList", data);
    },
    savePermission(state, data) {
      state.permissions = data;
      window.localStorage.setItem("PermList", data);
    },
    updateRouter(state, url) {
      state.router =
        url.slice(1, url.length).indexOf("/") === -1
          ? url
          : url.slice(0, url.slice(1, url.length).indexOf("/") + 1);
    },
    updateMenu(state, menu) {
      state.menu = menu;
    },
    SET_USERINFO(state, data) {
      state.userInfo = data;
    }
  },
  getters: {
    userInfo: state => state.userInfo,
    appSecret: state => state.appSecret
  },
  modules
});
// ���ģ��
export default store;
