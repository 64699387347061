<template>
  <div>
    <el-form :model="form" :rules="rules" ref="form" inline-message>
      <!-- 体系分类  TXLX===1-->
      <!-- <div
        v-if="form.GRCZTXInfo.TXLX === 1"
        class="cent-topbutt-box"
        style="height: 56px;"
      >
        <span class="ts-span-sm">
          名称 :
          <span class="ts-span-fontw">个人综合成长体系</span>
        </span>
        <span class="ts-span-sm">
          体系编码 :
          <span class="ts-span-fontw">XC-GRZHCZTX</span>
        </span>
      </div> -->
      <!-- 体系类型 !== 1 -->
      <!-- v-else -->
      <div class="cent-topbutt-box" style="height: 75px; padding-top: 10px">
        <el-form-item
          prop="GRCZTXInfo.MC"
          style="display: inline-block"
          label-width="0px"
        >
          <span class="ts-span-sm">
            名称：
            <el-input
              size="medium"
              style="width: 160px; vertical-align: middle"
              v-model="form.GRCZTXInfo.MC"
              placeholder="请输入"
            ></el-input>
          </span>
        </el-form-item>
        <el-form-item prop="GRCZTXInfo.TXBM" style="display: inline-block">
          <span class="ts-span-sm">
            体系编码 :
            <el-input
              size="medium"
              style="width: 160px; vertical-align: middle"
              v-model="form.GRCZTXInfo.TXBM"
              placeholder="请输入"
            ></el-input>
          </span>
        </el-form-item>
        <el-form-item prop="GRCZTXInfo.PXH" style="display: inline-block">
          <span class="ts-span-sm">
            排序 :
            <el-input-number
              size="medium"
              :min="1"
              style="width: 160px; vertical-align: middle"
              v-model="form.GRCZTXInfo.PXH"
            ></el-input-number>
          </span>
        </el-form-item>
      </div>
      <!-- 维度勾选 -->
      <div
        class="cent-topbutt-box"
        v-if="form.scfs === 1"
        style="height: 75px; padding-top: 10px"
      >
        <span class="ts-span-sm">
          <el-checkbox-group v-model="checkedCities">
            <el-checkbox
              v-for="(item, index) in cities"
              :label="item.name"
              :key="index"
              @change="handleCheckedCitiesChange($event, item.value)"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </span>
      </div>
      <!-- 活动和晋级 -->
      <div class="activities-block">
        <div
          class="activities-title"
          style="font-size: 16px; text-align: center; padding: 10px 0"
        >
          请选择个人参与 该成长体系的类目及系数
        </div>
        <!-- 自定义活动 课辅活动 固定活动 体系活动勾选 体系分按维度参与和按活动按参与的 只有按活动参与才有全部参与和指标设置-->
        <div class="activities" style="height: 600px; overflow-y: scroll">
          <el-checkbox-group
            v-model="checkedActivities"
            @change="checkedActivityChange"
          >
            <div
              class="operate-box-l"
              style="margin: 0; padding: 0"
              v-for="(item, index) in form.activitiesData.filter(
                (el) => el.SFYXZJHD !== 1
              )"
              :key="item.Id"
            >
              <el-form-item
                :rules="[
                  {
                    validator: validateCheckMethod,
                    message: '',
                    trigger: 'change',
                    checkIndexList: checkIndexList,
                    checkedActivities: checkedActivities,
                    form: form,
                  },
                ]"
                :prop="`activitiesData[${index}].Id`"
              >
                <div
                  class="disilb-box-l"
                  style="padding-right: 15px; min-width: 150px"
                >
                  <el-checkbox :label="item">{{ item.Name }}</el-checkbox>
                </div>
                <!-- 成绩管理 -->
                <span v-if="item.Name === '成绩管理'">
                  <el-checkbox-group v-model="ksCheckedCities">
                    <el-checkbox
                      v-for="(it, index) in ksCities"
                      :label="it.name"
                      :key="index"
                      @change="handleCheckedKsChange($event, index + 1)"
                      >{{ it.name }}
                      <el-input-number
                        size="small"
                        placeholder="输入"
                        style="
                          width: 60px;
                          vertical-align: middle;
                          margin-right: 20px;
                        "
                        v-model="it.value"
                        :controls="false"
                        :precision="0"
                        :min="0"
                        >{{ it.value }}</el-input-number
                      >%
                    </el-checkbox>
                  </el-checkbox-group>
                </span>
                <span
                  v-if="
                    item.Id !== '9' && item.CYFS !== 42 && item.CYFS !== '42'
                  "
                >
                  <el-input-number
                    size="small"
                    placeholder="输入"
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 20px;
                    "
                    v-model="item.DF"
                    :controls="false"
                    :precision="0"
                    :min="0"
                    >{{ item.DF }}</el-input-number
                  >
                  %
                </span>
                <!-- 班级体系 -->
                <span v-if="item.CYFS === 42 || item.CYFS === '42'">
                  <span>
                    <el-radio v-model="item.KSLX" :label="1"
                      >优秀班级固定加分</el-radio
                    >
                    <el-input-number
                      size="small"
                      placeholder="输入"
                      style="
                        width: 60px;
                        vertical-align: middle;
                        margin-right: 20px;
                      "
                      v-model="item.DF1"
                      :controls="false"
                      :precision="0"
                      :min="0"
                    />
                    分
                  </span>
                  <span>
                    <el-radio v-model="item.KSLX" :label="2"
                      >班级体系实际得分</el-radio
                    >
                    <el-input-number
                      size="small"
                      placeholder="输入"
                      style="
                        width: 60px;
                        vertical-align: middle;
                        margin-right: 20px;
                      "
                      v-model="item.DF2"
                      :controls="false"
                      :precision="0"
                      :min="0"
                    />
                    %
                  </span>
                </span>
                <!-- 体系的生成方式是按活动的 且当前勾选的活动是自定义活动 课辅活动 才显示全部参与 -->
                <div
                  v-if="
                    (item.EvaluationActivityType === 1 ||
                      item.EvaluationActivityType === 3) &&
                    form.scfs === 2
                  "
                  class="disilb-box-l"
                >
                  <el-radio
                    :disabled="
                      checkedActivities.findIndex((c) => c === item) === -1
                    "
                    v-model="item.CYFS"
                    :label="1"
                    >全部参与时</el-radio
                  >
                </div>
                <!-- 体系的生成方式是按活动的 且当前勾选的活动是自定义活动 课辅活动 才显示指标设置 -->
                <div
                  v-if="
                    (item.EvaluationActivityType === 1 ||
                      item.EvaluationActivityType === 3) &&
                    form.scfs === 2
                  "
                  class="disilb-box-l"
                >
                  <el-radio
                    :disabled="
                      checkedActivities.findIndex((c) => c === item) === -1
                    "
                    v-model="item.CYFS"
                    :label="3"
                    >按指标参与</el-radio
                  >
                </div>
                <el-button
                  v-if="
                    (item.EvaluationActivityType === 1 ||
                      item.EvaluationActivityType === 3) &&
                    item.CYFS === 3 &&
                    form.scfs === 2
                  "
                  size="mini"
                  type="primary"
                  @click="handdleCYFS(item.CYFS, index)"
                  >编辑</el-button
                >
              </el-form-item>
            </div>
          </el-checkbox-group>
          <div
            v-for="(item, index) in form.activitiesData.filter(
              (el) => el.SFYXZJHD === 1
            )"
            :key="item.Id"
            class="main-activity"
          >
            <div class="has-child">
              <el-checkbox
                v-model="item.all"
                v-if="item.children.length > 0"
                @change="checkAll(item)"
                >{{ item.Name }}</el-checkbox
              >
              {{
                item.children.length === 0
                  ? `${item.Name}(本学期暂无子活动)`
                  : ""
              }}
              <span
                class="has-child-arrow"
                :class="{
                  'arrow-rotate90': item.show,
                  'arrow-rotate0': !item.show,
                }"
                @click="item.show = !item.show"
              >
                <i class="el-icon-arrow-right"></i>
              </span>
            </div>
            <!-- 子活动 -->
            <div
              v-if="item.children && item.children.length > 0"
              class="item-child"
              :class="{
                'show-child': item.show,
                'close-child': !item.show,
              }"
            >
              <el-checkbox-group
                v-model="item.checked"
                @change="checkChild(item)"
              >
                <el-checkbox
                  v-for="(it, idx) in item.children"
                  :label="it.Id"
                  :key="idx"
                >
                  {{ it.Name }}
                  <el-input-number
                    size="small"
                    placeholder="输入分值"
                    style="
                      width: 60px;
                      vertical-align: middle;
                      margin-right: 20px;
                    "
                    v-model="it.DF"
                    :controls="false"
                    :precision="0"
                    :min="0"
                    >{{ it.DF }}</el-input-number
                  >%
                  <!-- 体系的生成方式是按活动的 且当前勾选的活动是自定义活动 课辅活动 才显示全部参与 -->
                  <div
                    v-if="
                      (it.EvaluationActivityType === 1 ||
                        it.EvaluationActivityType === 3) &&
                      form.scfs === 2
                    "
                    class="disilb-box-l"
                  >
                    <el-radio
                      :disabled="
                        item.checked.findIndex((c) => c === it.Id) === -1
                      "
                      v-model="it.CYFS"
                      :label="1"
                      >全部参与时</el-radio
                    >
                  </div>
                  <!-- 体系的生成方式是按活动的 且当前勾选的活动是自定义活动 课辅活动 才显示指标设置 -->
                  <div
                    v-if="
                      (it.EvaluationActivityType === 1 ||
                        it.EvaluationActivityType === 3) &&
                      form.scfs === 2
                    "
                    class="disilb-box-l"
                  >
                    <el-radio
                      :disabled="
                        item.checked.findIndex((c) => c === it.Id) === -1
                      "
                      v-model="it.CYFS"
                      :label="3"
                      >按指标参与</el-radio
                    >
                  </div>
                  <el-button
                    v-if="
                      (it.EvaluationActivityType === 1 ||
                        it.EvaluationActivityType === 3) &&
                      it.CYFS === 3 &&
                      form.scfs === 2
                    "
                    size="mini"
                    type="primary"
                    @click.prevent.stop="handdleCYFS(it.CYFS, index, idx)"
                    >编辑</el-button
                  >
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="fg-box-2"></div>
        <!-- <div v-if="form.GRCZTXInfo.TXLX === 1">
          <div class="leftrig-left-box" style="vertical-align: middle;"></div>
          <div class="leftrig-rig-box" style="vertical-align: middle;">
            <div class="operate-box-l">
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">班级内每周得星人数:</div>
              </div>
              <div class="disilb-box-l" style="width: 200px;">
                <el-radio
                  style="margin-right: 5px;"
                  v-model="form.GRCZTXInfo.PBLX"
                  label="1"
                  >固定人数</el-radio
                >
                <div class="tablil-xz-name">
                  <el-input-number
                    :min="0"
                    :controls="false"
                    size="small"
                    placeholder="输入"
                    style="
                    width: 60px;
                    vertical-align: middle;
                    margin-right: 5px;
                  "
                    v-model="ZBSZ_fixed"
                  ></el-input-number
                  >人
                </div>
              </div>
              <div class="disilb-box-l" style="width: 200px;">
                <el-radio
                  style="margin-right: 5px;"
                  v-model="form.GRCZTXInfo.PBLX"
                  label="2"
                  >人数占比</el-radio
                >
                <div class="tablil-xz-name">
                  <el-input-number
                    :min="0"
                    :controls="false"
                    :precision="1"
                    size="small"
                    placeholder="输入"
                    style="
                    width: 60px;
                    vertical-align: middle;
                    margin-right: 5px;
                  "
                    v-model="ZBSZ_percent"
                  ></el-input-number
                  >%
                </div>
              </div>
            </div>
            <div class="operate-box-l">
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">班级星名称:</div>
              </div>
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  <el-input
                    size="small"
                    placeholder="输入"
                    style="
                    width: 160px;
                    vertical-align: middle;
                    margin-left: 8px;
                  "
                    v-model="form.CZTXJJList[0].DJMC"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="operate-box-l">
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">起算分:</div>
              </div>
              <el-select
                size="small"
                style="width: 80px; margin-left: 8px;"
                v-model="form.GRCZTXInfo.QSFLX"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in 2"
                  :key="item"
                  :label="item == 1 ? '>' : '>='"
                  :value="item"
                ></el-option>
              </el-select>
              <el-input-number
                :min="-99"
                :controls="false"
                size="small"
                placeholder="输入"
                style="width: 60px; vertical-align: middle; margin-left: 10px;"
                v-model="form.GRCZTXInfo.QSF"
              ></el-input-number>
            </div>
            <div class="operate-box-l" style="border-bottom: none;">
              <div
                v-for="(item, index) in this.form.CZTXJJList.filter(
                  c => c.JJLX === 2
                )"
                :key="index"
                style="margin-top: 20px;"
              >
                <div
                  class="disilb-box-l"
                  style="width: 180px; text-align: right;"
                >
                  <div class="tablil-xz-name epp-ov-l">
                    起始星
                    <el-input-number
                      :min="0"
                      :controls="false"
                      size="small"
                      placeholder="输入"
                      style="
                      width: 60px;
                      vertical-align: middle;
                      margin-left: 10px;
                    "
                      v-model="item.SZNR"
                    ></el-input-number
                    >颗
                  </div>
                  <div style="display: inline-block; vertical-align: middle;">
                    <Upload
                      :itemPic="item.JJTB ? item.JJTB : ''"
                      :width="50"
                      :height="50"
                      @getResultUrl="getResultUrl"
                      :circle="true"
                      :itemId="index + ''"
                      :html="uploadBtnHtml"
                    />
                  </div>
                </div>
              </div>
              <div
                class="disilb-box-l"
                style="margin-top: 20px; width: 80px; text-align: right;"
              >
                <el-link :underline="false" type="primary" @click="addItem"
                  >添加项+</el-link
                >
              </div>
            </div>
          </div>
        </div> -->
        <!-- 分类成长树 -->
        <div>
          <div class="leftrig-left-box" style="vertical-align: middle">
            <div class="tx-ertop-box">
              <span>分类成长树设置</span>
            </div>
          </div>
          <div class="leftrig-rig-box" style="vertical-align: middle">
            <div class="operate-box-l">
              <el-form-item
                class="jf-form-item"
                v-for="(item, index) in this.FLCZSZData"
                :key="item.Id"
              >
                <span>{{ index + 1 }}、</span>
                <div
                  class="disilb-box-l"
                  style="width: 180px; text-align: left"
                >
                  <el-input
                    v-model="item.MC"
                    maxlength="6"
                    placeholder="不超过6个字"
                    size="small"
                    style="width: 150px; vertical-align: middle"
                  ></el-input>
                </div>
                <span>起算分</span>
                <el-input-number
                  :min="0"
                  :controls="false"
                  size="small"
                  placeholder="输入"
                  style="width: 60px; vertical-align: middle; margin-left: 10px"
                  v-model="item.QSF"
                ></el-input-number>
                <div class="tab-wz-buttl">
                  <Upload
                    :itemPic="item.TPLJ !== '' ? item.TPLJ : ''"
                    :width="50"
                    :height="50"
                    :circle="true"
                    @getResultUrl="getResultUrl1"
                    item-id="1"
                    :itemId="index + ''"
                    :html="uploadBtnHtml"
                  />
                </div>
                &nbsp;&nbsp;
                <el-link
                  :underline="false"
                  type="primary"
                  @click="deleteItem(item)"
                  style="color: red"
                  >删除项-</el-link
                >
              </el-form-item>
              <el-link :underline="false" type="primary" @click="addFLCZItem"
                >添加项+</el-link
              >
            </div>
          </div>
        </div>
        <div class="fg-box-2"></div>
        <!-- 星级个人 -->
        <div>
          <div class="leftrig-left-box" style="vertical-align: middle">
            <div class="tx-ertop-box">
              <el-checkbox
                v-model="form.GRCZTXInfo.SFQYXJGR"
                :true-label="1"
                :false-label="2"
                >星级个人设置</el-checkbox
              >
            </div>
          </div>
          <div
            class="leftrig-rig-box"
            style="vertical-align: middle; position: relative"
          >
            <div class="operate-box-l">
              <el-form-item
                class="jf-form-item"
                :rules="[
                  {
                    validator: (rule, value, callback) => {
                      if (rule.formInfo.GRCZTXInfo.SFQYXJGR === 1) {
                        if (
                          !value ||
                          (value === '1' && !rule.ZBSZ_fixed) ||
                          (value === '2' && !rule.ZBSZ_percent)
                        ) {
                          return callback(new $window.Error('该项未设置完整'));
                        } else return callback();
                      } else return callback();
                    },
                    formInfo: form,
                    trigger: 'change',
                    ZBSZ_fixed: ZBSZ_fixed,
                    ZBSZ_percent: ZBSZ_percent,
                  },
                ]"
                prop="GRCZTXInfo.PBLX"
              >
                <div class="operate-box-l">
                  <div class="tablil-xz-name epp-ov-l" style="width: 200px">
                    <el-radio
                      style="margin-right: 5px"
                      v-model="form.GRCZTXInfo.HDFW"
                      label="1"
                      >以班级为单位获得</el-radio
                    >
                  </div>
                  <div class="tablil-xz-name epp-ov-l" style="width: 200px">
                    <el-radio
                      style="margin-right: 5px"
                      v-model="form.GRCZTXInfo.HDFW"
                      label="2"
                      >以年级为单位获得</el-radio
                    >
                  </div>
                </div>
                <div class="disilb-box-l" style="width: 200px">
                  <el-radio
                    style="margin-right: 5px"
                    v-model="form.GRCZTXInfo.PBLX"
                    label="1"
                    >固定人数</el-radio
                  >
                  <div class="tablil-xz-name">
                    <el-input-number
                      :min="0"
                      :controls="false"
                      size="small"
                      placeholder="输入"
                      style="
                        width: 60px;
                        vertical-align: middle;
                        margin-right: 5px;
                      "
                      v-model="ZBSZ_fixed"
                    ></el-input-number
                    >人
                  </div>
                </div>
                <div class="disilb-box-l" style="width: 200px">
                  <el-radio
                    style="margin-right: 5px"
                    v-model="form.GRCZTXInfo.PBLX"
                    label="2"
                    >人数占比</el-radio
                  >
                  <div class="tablil-xz-name">
                    <el-input-number
                      :min="0"
                      :controls="false"
                      :precision="1"
                      size="small"
                      placeholder="输入"
                      style="
                        width: 60px;
                        vertical-align: middle;
                        margin-right: 5px;
                      "
                      v-model="ZBSZ_percent"
                    ></el-input-number
                    >%
                  </div>
                </div>
              </el-form-item>
            </div>
            <div class="operate-box-l">
              <el-form-item
                class="jf-form-item"
                :rules="[
                  {
                    validator: (rule, value, callback) => {
                      if (rule.formInfo.GRCZTXInfo.SFQYXJGR === 1) {
                        if (!value || (value && !rule.QSF && rule.QSF !== 0)) {
                          return callback(new $window.Error('该项未设置完整'));
                        } else return callback();
                      } else return callback();
                    },
                    trigger: 'change',
                    formInfo: form,
                    QSF: form.GRCZTXInfo.QSF,
                  },
                ]"
                prop="GRCZTXInfo.QSFLX"
              >
                <div
                  class="disilb-box-l"
                  style="width: 180px; text-align: right"
                >
                  <div class="tablil-xz-name epp-ov-l">起算分:</div>
                </div>
                <el-select
                  size="small"
                  style="width: 80px; margin-left: 8px"
                  v-model="form.GRCZTXInfo.QSFLX"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in 2"
                    :key="item"
                    :label="item == 1 ? '>' : '>='"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-input-number
                  :min="0"
                  :controls="false"
                  size="small"
                  placeholder="输入"
                  style="width: 60px; vertical-align: middle; margin-left: 10px"
                  v-model="form.GRCZTXInfo.QSF"
                ></el-input-number>
                <span>（起算分不包含活动默认分）</span>
              </el-form-item>
            </div>
            <div class="operate-box-l" style="border-bottom: none">
              <div style="margin-top: 20px">
                <div
                  class="disilb-box-l"
                  style="width: 180px; text-align: right"
                >
                  <div class="tablil-xz-name epp-ov-l">星级名称:</div>
                </div>
                <div class="disilb-box-l" style="width: 200px">
                  <div class="tablil-xz-name">
                    <el-form-item
                      class="jf-form-item"
                      :rules="[
                        {
                          validator: (rule, value, callback) => {
                            if (rule.formInfo.GRCZTXInfo.SFQYXJGR === 1) {
                              if (!rule.formInfo.CZTXJJList[0].DJMC) {
                                return callback(
                                  new $window.Error('星级名称不能为空')
                                );
                              } else return callback();
                            } else {
                              return callback();
                            }
                          },
                          formInfo: form,
                          trigger: 'change',
                        },
                      ]"
                      prop="CZTXJJList[0].DJMC"
                    >
                      <el-input
                        size="small"
                        placeholder="输入"
                        style="
                          width: 160px;
                          vertical-align: middle;
                          margin-left: 8px;
                        "
                        v-model="form.CZTXJJList[0].DJMC"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div style="margin-top: 20px">
                <div
                  class="disilb-box-l"
                  style="width: 180px; text-align: right"
                >
                  <div class="tablil-xz-name epp-ov-l">基础图标:</div>
                  <div class="tab-wz-buttl">
                    <Upload
                      :itemPic="
                        form.CZTXJJList[0].JJTB ? form.CZTXJJList[0].JJTB : ''
                      "
                      :width="50"
                      :height="50"
                      @getResultUrl="getResultUrl"
                      :circle="true"
                      :itemId="'0'"
                      :html="uploadBtnHtml"
                    />
                  </div>
                </div>
              </div>
              <div style="margin-top: 20px">
                <div
                  class="disilb-box-l"
                  style="width: 180px; text-align: right"
                >
                  <div class="tablil-xz-name epp-ov-l">星级描述:</div>
                </div>
                <div class="disilb-box-l" style="width: 200px">
                  <div class="tablil-xz-name">
                    <el-form-item
                      class="jf-form-item"
                      :rules="[
                        {
                          validator: (rule, value, callback) => {
                            if (rule.formInfo.GRCZTXInfo.SFQYXJGR === 1) {
                              if (!rule.formInfo.CZTXJJList[0].DJMC) {
                                return callback(
                                  new $window.Error('星级名称不能为空')
                                );
                              } else return callback();
                            } else {
                              return callback();
                            }
                          },
                          formInfo: form,
                          trigger: 'change',
                        },
                        {
                          max: 60,
                          message: '描述需在60个字以内',
                          trigger: 'change',
                        },
                      ]"
                      prop="CZTXJJList[0].MS"
                    >
                      <el-input
                        size="small"
                        placeholder="输入"
                        style="
                          width: 160px;
                          vertical-align: middle;
                          margin-left: 8px;
                        "
                        v-model="form.CZTXJJList[0].MS"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mask enabledStar"
              v-show="form.GRCZTXInfo.SFQYXJGR !== 1"
            ></div>
          </div>
        </div>
        <div>
          <div class="leftrig-left-box" style="vertical-align: middle">
            <span>分类成长树设置</span>
          </div>
          <div class="leftrig-rig-box" style="vertical-align: middle">
            <el-select
              size="small"
              v-model="form.GRCZTXInfo.DJId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in levelList"
                :key="item.Id"
                :label="item.DJMC"
                :value="item.Id"
                >{{ item.DJMC }}</el-option
              >
            </el-select>
          </div>
        </div>
        <div class="cent-bott-butt">
          <a @click="save" class="cent-bott-butta">确&nbsp;认</a>
          <a @click="cancel" class="cent-bott-butta">取&nbsp;消</a>
        </div>
      </div>
    </el-form>
    <!-- 对象分值比重设置弹窗 -->
    <el-dialog
      title="设置对象分值比重"
      :visible.sync="objectVisible"
      :close-on-click-modal="false"
      top="15%"
      width="30%"
      center
    >
      <div class="tab-shu-box over-css" style="height: 300px">
        <el-checkbox-group v-model="checkObjectList">
          <div
            class="operate-box-l"
            v-for="item in objectList"
            :key="item.ObjectId"
          >
            <div class="disilb-box-l" style="width: 190px">
              <el-checkbox :label="item">{{ item.ObjectName }}</el-checkbox>
            </div>
            <el-input
              size="small"
              style="width: 60px; vertical-align: middle; margin-right: 5px"
              v-model="item.DF"
              关
              闭
              placeholder="输入"
            ></el-input
            >%
          </div>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="objectVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 指标分值比重设置弹窗 -->
    <el-dialog
      title="设置指标分值比重"
      :visible.sync="indexVisible"
      :close-on-click-modal="false"
      top="15%"
      width="50%"
      center
    >
      <div class="tab-shu-box over-css index-checkbox" style="height: 300px">
        <indexTree :indexList="indexList" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="checkedIndexSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from "@/components/Upload.vue";
import indexTree from "./indexTree.vue";
// import { editCurrentNaviName } from "@/style/js/Common.js";
export default {
  components: {
    Upload,
    indexTree,
  },
  data() {
    var validateCheck = function (rule, value, callback) {
      let activity = rule.form.activitiesData.find((c) => c.Id === value);

      if (activity && activity.Id) {
        let activity = rule.checkedActivities.find((c) => c.Id === value.Id);

        if (activity) {
          let msg = [];
          if (
            rule.form.scfs === 2 &&
            activity.CYFS === 3 &&
            rule.checkIndexList.filter((c) => c.ActivityId === value.Id)
              .length === 0
          ) {
            // 课辅活动？
            msg.push("请设置指标");
          } else if (
            rule.form.scfs === 2 &&
            activity.CYFS === 3 &&
            rule.checkIndexList
              .filter((c) => c.ActivityId === value.Id)
              .reduce((total, item) => total + parseFloat(item.DF), 0) !== 100
          ) {
            //msg.push("指标占比之和不等于100%");
          }

          rule.message = msg.join(",");
          if (rule.message) {
            return callback(new Error(rule.message));
          }
          return callback();
        } else {
          return callback();
        }
      } else {
        rule.message = "未知校验错误";
        return callback(new Error(rule.message));
      }
    };

    return {
      currentActivityIndex: -1,
      validateCheckMethod: validateCheck,
      currentActivityId: "",
      form: {
        GRCZTXInfo: {
          SFQYXJGR: 1,
        },
        activitiesData: [],
        checkedActivities: [],
        CZTXJJList: [],
      },
      checkedActivities: [],
      dialogTitle: "",
      objectVisible: false,
      indexVisible: false,
      objectList: [],
      indexList: [],
      checkObjectList: [],
      checkIndexList: [],
      activitiesData: [],
      tempActivitiesData: [],
      FLCZSZData: [],
      schoolId: "",
      checkedCities: [],
      cities: [],
      ksCheckedCities: [],
      ksCities: [
        { name: "月考", value: 0 },
        { name: "期中", value: 0 },
        { name: "期末", value: 0 },
        { name: "其他", value: 0 },
      ],
      WDSJ: [],
      KSLX: [],
      ksCheckedCitiesValue: [],
      uploadBtnHtml:
        '<a class="tab-wz-butta c-guree" href="javascript:void(0);">上传图标</a>',
      ZBSZ_fixed: undefined,
      ZBSZ_percent: undefined,
      rules: {
        "form.GRCZTXInfo.MC": [
          { required: true, message: "请输入体系名称", trigger: "change" },
          { min: 0, max: 20, message: "不能超过20个字", trigger: "change" },
        ],
        "form.GRCZTXInfo.TXBM": [
          { required: true, message: "请输入体系编码", trigger: "change" },
          { min: 0, max: 20, message: "不能超过20个字", trigger: "change" },
        ],
      },
      user: {},
      term: {},
      schoolIndex: [],
      activity: {},
      enabledStar: false,
      levelList: [],
    };
  },
  props: {
    activities: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  async created() {
    Object.assign(this.form, this.$route.query.data);
    this.levelList = this.$route.query.levelList;
    this.term = JSON.parse(localStorage.getItem("curTerm"));

    this.user = JSON.parse(localStorage.getItem("userinfo"));

    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    await this.getSchoolIndex();
    this.getTableWDData();
    this.init();
  },
  methods: {
    handleCheckedCitiesChange(e, value) {
      if (e) {
        this.WDSJ.push(value);
      } else {
        var index = this.WDSJ.indexOf(value);
        this.WDSJ.splice(index, 1);
      }
    },
    handleCheckedKsChange(e, value) {
      if (e) {
        let res = this.checkedActivities.find((item) => item.Id === "9");
        if (!res)
          this.checkedActivities.push(
            this.activitiesData.find((item) => item.Id === "9")
          );

        this.KSLX.push(value);
      } else {
        var index = this.KSLX.indexOf(value);
        this.KSLX.splice(index, 1);

        if (!this.KSLX || this.KSLX.length === 0) {
          this.checkedActivities = this.checkedActivities.filter(
            (item) => item.Id !== "9"
          );
        }
      }
      if (this.KSLX !== []) {
        this.KSLX.sort((a, b) => {
          return a - b;
        });
      }
    },
    async init() {
      this.ksCities.forEach((item) => (item.value = 0));
      //只要被评人为学生的活动 EvaluatedObject 0.固定活动 1.学生 2.老师 3.班级
      this.activitiesData = JSON.parse(
        JSON.stringify(
          this.activities.filter(
            (item) => item.EvaluatedObject === 0 || item.EvaluatedObject === 1
          )
        )
      );

      this.activitiesData.forEach((item) => {
        let children = [];
        if (
          item.SFYXZJHD === 1 &&
          item.ChildEvaList &&
          item.ChildEvaList.length > 0
        ) {
          // 子活动
          item.ChildEvaList.forEach((it) => {
            children.push({
              AllEvaluationIndexList: it.AllEvaluationIndexList,
              CYFS: it.CYFS,
              DF: it.DF,
              EvaluatedObject: it.EvaluatedObject,
              EvaluationActivityType: it.EvaluationActivityType,
              EvaluationObjectList: it.EvaluationObjectList,
              EvaluationObjectListStr: it.EvaluationObjectListStr,
              Id: it.Id,
              KSBFB: it.KSBFB,
              KSLX: it.KSLX,
              Name: it.Name,
            });
          });
        }
        this.tempActivitiesData.push({
          AllEvaluationIndexList: item.AllEvaluationIndexList,
          CYFS: item.CYFS,
          DF: item.DF,
          EvaluatedObject: item.EvaluatedObject,
          EvaluationActivityType: item.EvaluationActivityType,
          EvaluationObjectList: item.EvaluationObjectList,
          EvaluationObjectListStr: item.EvaluationObjectListStr,
          Id: item.Id,
          KSBFB: item.KSBFB,
          KSLX: item.KSLX,
          Name: item.Name,
          SFYXZJHD: item.SFYXZJHD,
          checked: [],
          children,
          show: false,
          all: false,
        });
      });

      this.activitiesData = this.tempActivitiesData;

      this.form.activitiesData = this.tempActivitiesData;
      // 排序 将有子活动的 没子活动的 固定活动和体系 各自排序
      let fixId = [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "9",
      ];
      //子活动 且是自定义
      let main = this.form.activitiesData.filter(
        (item) => item.SFYXZJHD === 1 && item.CYFS === 1
      );
      main.sort((a, b) => a.Name.localeCompare(b.Name));

      //无子活动 且是自定义
      let unMain = this.form.activitiesData.filter(
        (item) =>
          item.SFYXZJHD !== 1 && item.CYFS === 1 && !fixId.includes(item.Id)
      );
      unMain.sort((a, b) => a.Name.localeCompare(b.Name));

      let fix = this.form.activitiesData.filter((item) =>
        fixId.includes(item.Id)
      ); //固定活动
      fix.sort((a, b) => a.Name.localeCompare(b.Name));

      let tx = this.form.activitiesData.filter((item) => item.CYFS === 42); //体系的参与方式是42
      tx.sort((a, b) => a.Name.localeCompare(b.Name));
      // 获取部分活动的详情(这些活动需要指标的详情)
      await this.getActivityInfo([
        ...unMain,
        ...main,
        fix.find((item) => item.Id === "3"),
      ]);

      this.form.activitiesData = [...fix, ...tx, ...unMain, ...main];

      this.form.GRCZTXInfo.HDFW = this.form.GRCZTXInfo.HDFW + "";
      if (this.form.GRCZTXInfo.Id) {
        this.form.GRCZTXInfo.PBLX = this.form.GRCZTXInfo.PBLX + "";
        if (this.form.GRCZTXInfo.PBLX === "1") {
          this.ZBSZ_fixed = this.form.GRCZTXInfo.ZBSZ + "";
        } else if (this.form.GRCZTXInfo.PBLX === "2") {
          this.ZBSZ_percent = this.form.GRCZTXInfo.ZBSZ + "";
        }

        // 回显
        if (
          this.form.GRCZTXInfo &&
          this.form.GRCZTXInfo.GRCZTXGLNRList &&
          this.form.GRCZTXInfo.GRCZTXGLNRList.length > 0
        ) {
          let activities = [];

          this.form.GRCZTXInfo.GRCZTXGLNRList.forEach((item) => {
            let info = this.activitiesData?.find((c) => c.Id === item.GLId);
            if (info) {
              info.DF = item.DF + "";
              info.CYFS = item.CYFS;
              info.KSLX = item.KSLX;
              if (item.CYFS === 42) {
                if (item.KSLX === 1) info.DF1 = item.DF;
                if (item.KSLX === 2) info.DF2 = item.DF;
              }
              activities.push(info);
            } else {
              this.activitiesData.forEach((c) => {
                if (c.SFYXZJHD === 1 && c.children && c.children.length > 0) {
                  c.all = false;
                  let child = c.children?.find((it) => it.Id === item.GLId);
                  if (child) {
                    c.checked.push(child.Id);
                    child.DF = item.DF + "";
                    child.CYFS = item.CYFS;
                    c.show = true;
                  }
                }
              });
            }
          });

          this.$set(this, "checkedActivities", activities);
        }
      }

      // if (this.form.GRCZTXInfo.TXLX === 1) {
      //   this.$set(this.form, "CZTXJJList", []);
      //   this.form.CZTXJJList.push({
      //     JJTB:
      //       this.form.GRCZTXInfo.CZTXJJList &&
      //       this.form.GRCZTXInfo.CZTXJJList[0]
      //         ? this.form.GRCZTXInfo.CZTXJJList[0].JJTB
      //         : "",
      //     SZNR: 0,
      //     JJLX: 2,
      //     SZLX: 1,
      //     DJMC:
      //       this.form.GRCZTXInfo.CZTXJJList &&
      //       this.form.GRCZTXInfo.CZTXJJList[0]
      //         ? this.form.GRCZTXInfo.CZTXJJList[0].DJMC
      //         : "",
      //     MS:
      //       this.form.GRCZTXInfo.CZTXJJList &&
      //       this.form.GRCZTXInfo.CZTXJJList[0]
      //         ? this.form.GRCZTXInfo.CZTXJJList[0].MS
      //         : ""
      //   });
      //   this.form.CZTXJJList = this.form.GRCZTXInfo.CZTXJJList
      //     ? this.form.GRCZTXInfo.CZTXJJList.filter(c => c.JJLX === 2)
      //     : [];
      //   if (this.form.CZTXJJList.length <= 1) {
      //     this.addItem();
      //   }
      // } else {
      //   }

      if (!this.form.GRCZTXInfo.QSFLX) this.form.GRCZTXInfo.QSFLX = 1;
      this.$set(this.form, "CZTXJJList", [
        {
          JJTB:
            this.form.GRCZTXInfo.CZTXJJList &&
            this.form.GRCZTXInfo.CZTXJJList[0]
              ? this.form.GRCZTXInfo.CZTXJJList[0].JJTB
              : "",
          SZNR: 0,
          JJLX: 1,
          SZLX: 2,
          DJMC:
            this.form.GRCZTXInfo.CZTXJJList &&
            this.form.GRCZTXInfo.CZTXJJList[0]
              ? this.form.GRCZTXInfo.CZTXJJList[0].DJMC
              : "",
          MS:
            this.form.GRCZTXInfo.CZTXJJList &&
            this.form.GRCZTXInfo.CZTXJJList[0]
              ? this.form.GRCZTXInfo.CZTXJJList[0].MS
              : "",
        },
        {
          JJTB:
            this.form.GRCZTXInfo.CZTXJJList &&
            this.form.GRCZTXInfo.CZTXJJList[1]
              ? this.form.GRCZTXInfo.CZTXJJList[1].JJTB
              : "",
          SZNR:
            this.form.GRCZTXInfo.CZTXJJList &&
            this.form.GRCZTXInfo.CZTXJJList[1]
              ? this.form.GRCZTXInfo.CZTXJJList[1].SZNR + ""
              : undefined,
          JJLX: 2,
          SZLX: 2,
          DJMC:
            this.form.GRCZTXInfo.CZTXJJList &&
            this.form.GRCZTXInfo.CZTXJJList[1]
              ? this.form.GRCZTXInfo.CZTXJJList[1].DJMC
              : "",
          MS:
            this.form.GRCZTXInfo.CZTXJJList &&
            this.form.GRCZTXInfo.CZTXJJList[1]
              ? this.form.GRCZTXInfo.CZTXJJList[1].MS
              : "",
        },
      ]);

      if (this.form.GRCZTXInfo.Id) {
        for (let item of Array.from(
          new Set(this.form.GRCZTXInfo.EvaluationObjectAndIndexList)
        )) {
          let activity = this.activitiesData.find((c) => c.Id === item.HDId);
          if (activity && parseInt(activity.CYFS) === 2) {
            let info = activity.EvaluationObjectList?.find(
              (c) => c.ObjectId === item.GLId
            );
            if (info) {
              info.DF = item.DF + "";
              this.checkObjectList.push(info);
            }
          }
        }
        for (let item of Array.from(
          new Set(
            this.form.GRCZTXInfo.EvaluationObjectAndIndexList.map((c) => c.HDId)
          )
        )) {
          let activity = this.activitiesData.find((c) => c.Id === item);
          if (activity && parseInt(activity.CYFS) === 3) {
            this.$set(
              this,
              "checkIndexList",
              this.checkIndexList.concat(
                this.form.GRCZTXInfo.EvaluationObjectAndIndexList.filter(
                  (c) => c.HDId === activity.Id
                ).map((c) => ({
                  ActivityId: c.HDId,
                  DF: c.DF,
                  IndexId: c.GLId,
                  IndexName: c.IndexName,
                }))
              )
            );
          }
        }

        this.activitiesData.forEach((activity) => {
          let indexArr =
            this.form.GRCZTXInfo.EvaluationObjectAndIndexList.filter(
              (index) => index.HDId === activity.Id
            );
          // 找子活动
          if (indexArr.length === 0 && activity.children.length > 0) {
            activity.children.forEach((child) => {
              let childIndexArr =
                this.form.GRCZTXInfo.EvaluationObjectAndIndexList.filter(
                  (index) => index.HDId === child.Id
                );

              child.indexList = childIndexArr.reduce((pre, cur) => {
                pre.push({
                  ActivityId: cur.HDId,
                  DF: cur.DF,
                  Id: cur.GLId,
                  IndexId: cur.GLId,
                });
                return pre;
              }, []);
            });
          } else {
            activity.indexList = indexArr.reduce((pre, cur) => {
              pre.push({
                ActivityId: cur.HDId,
                DF: cur.DF,
                Id: cur.GLId,
                IndexId: cur.GLId,
              });
              return pre;
            }, []);
          }
        });
      }

      if (
        this.form.GRCZTXInfo.FLCZSZList !== "" &&
        this.form.GRCZTXInfo.FLCZSZList !== null &&
        this.form.GRCZTXInfo.FLCZSZList !== undefined
      ) {
        if (this.form.GRCZTXInfo.FLCZSZList) {
          this.FLCZSZData = JSON.parse(
            JSON.stringify(this.form.GRCZTXInfo.FLCZSZList)
          ).sort((a, b) => a.QSF - b.QSF);
        }
      } else {
        this.FLCZSZData.push({
          Id: "",
          CZTXId: "",
          MC: "",
          QSF: "",
          TPLJ: "",
        });
      }

      let currentNaviName = "新增体系";

      if (this.form.GRCZTXInfo.Id) {
        currentNaviName = "编辑体系";

        this.$api.post(
          "/v1/dimension/GetDimension",
          {
            token: this.$store.state.token,
            SchoolId: this.schoolId,
            PagerInfo: this.pagerInfo,
          },
          (r) => {
            if (r.Code === 0) {
              let resp = r.Target;
              let data = resp.Target;
              data = data.sort((a, b) => {
                return a.PXH - b.PXH;
              });
              var wdsjArr =
                this.form.GRCZTXInfo.GRCZTXGLNRList?.filter(
                  (m) => m.SJLX === 1
                ) || [];
              for (let n = 0; n < wdsjArr.length; n++) {
                let wdsj = wdsjArr[n].GLId;
                var option = data.filter((m) => m.Id === wdsjArr[n].GLId);
                if (option && option[0]) {
                  this.checkedCities.push(option[0].WDMC);
                  this.WDSJ.push(wdsj);
                }
              }
            }
          }
        );

        var cjgl =
          this.form.GRCZTXInfo.GRCZTXGLNRList?.filter(
            (m) => m.KSLX > 0 && m.GLId === "9"
          ) || [];

        for (let n = 0; n < cjgl.length; n++) {
          var df = cjgl[n].DF;
          let kslx = parseInt(cjgl[n].KSLX);
          var ksName = this.ksCities[kslx - 1];
          this.ksCheckedCities.push(ksName.name);
          this.ksCities.filter((m) => m.name === ksName.name)[0].value = df;
          this.KSLX.push(kslx);
        }
      } else {
        this.checkedCities = [];
        this.ksCheckedCities = [];
      }
      // editCurrentNaviName(this, currentNaviName);
    },
    getTableWDData() {
      this.$api.post(
        "/v1/dimension/GetDimension",
        {
          token: this.$store.state.token,
          SchoolId: this.schoolId,
          PagerInfo: this.pagerInfo,
        },
        (r) => {
          if (r.Code === 0) {
            var resp = r.Target;
            var data = resp.Target;
            data = data.sort((a, b) => {
              return a.PXH - b.PXH;
            });
            for (let i = 0; i < data.length; i++) {
              var obj = {
                name: data[i].WDMC,
                value: data[i].Id,
              };
              this.cities.push(obj);
            }
          }
        }
      );
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // eslint-disable-next-line no-unused-vars
          this.$confirm("确认提交？").then((r) => {
            // 执行提交方法
            this.submit();
          });
          // .catch(r => {});
        } else {
          scrollTo(0, 0);
          return false;
        }
      });
    },
    submit() {
      // 分类成长树校验
      for (let i = 0; i < this.FLCZSZData.length; i++) {
        if (
          this.FLCZSZData[i].MC === "" ||
          this.FLCZSZData[i].MC === undefined
        ) {
          return this.$message({
            type: "error",
            message: "成长树设置" + (i + 1) + "行:请输入名称!",
          });
        }
      }

      // 处理勾选的活动数据
      let GRCZTXGLNRList = [];
      // 不包含成绩管理
      GRCZTXGLNRList = this.checkedActivities.filter((item) => {
        if (item.CYFS === 42) {
          item.DF = item.KSLX === 1 ? item.DF1 : item.DF2;
        }
        return item.GLId !== "9" && item.Id !== "9";
      });

      // 筛选子活动被勾选的
      let child = this.form.activitiesData.reduce((pre, cur) => {
        if (cur.SFYXZJHD === 1 && cur.children && cur.children.length > 0) {
          return pre.concat(
            cur.children.filter((it) => cur.checked.includes(it.Id))
          );
        }
        return pre;
      }, []);

      GRCZTXGLNRList = [...GRCZTXGLNRList, ...child];
      this.form.GRCZTXGLNRList = GRCZTXGLNRList.reduce((pre, cur) => {
        pre.push({
          CYFS: cur.CYFS, //1.全部参与 2.对象参与 3.指标参与
          DF: cur.DF,
          Id: cur.Id,
          EvaluationActivityType: cur.EvaluationActivityType, //活动类型： 1.自定义 9.成绩管理 42.体系
          KSLX: cur.KSLX,
        });
        return pre;
      }, []);
      // 处理活动勾选对象
      this.form.checkObjectList = this.checkObjectList;
      // 处理活动勾选指标
      this.form.checkIndexList = this.form.activitiesData.reduce((pre, cur) => {
        if (cur.SFYXZJHD === 1 && cur.children && cur.children.length > 0) {
          cur.children.forEach((child) => {
            if (child.indexList) {
              child.indexList.forEach((item) => {
                pre.push({
                  ActivityId: item.ActivityId,
                  DF: item.DF,
                  Id: item.Id,
                  IndexId: item.IndexId,
                  ObjectId: item.ObjectId || "",
                  ParentId: item.ParentId || "",
                });
              });
            } else {
              if (child.EvaluationObjectList) {
                child.EvaluationObjectList.forEach((element) => {
                  if (element.indexList)
                    pre = pre.concat(
                      element.indexList.filter((el) => el.checked)
                    );
                });
              }
            }
          });
        } else {
          if (cur.indexList) {
            cur.indexList.forEach((item) => {
              pre.push({
                ActivityId: item.ActivityId,
                DF: item.DF,
                Id: item.Id,
                IndexId: item.IndexId,
                ObjectId: item.ObjectId,
                ParentId: item.ParentId,
              });
            });
          } else {
            if (cur.EvaluationObjectList) {
              cur.EvaluationObjectList.forEach((element) => {
                if (element.indexList) {
                  pre = pre.concat(
                    element.indexList.filter((el) => el.checked)
                  );
                }
              });
            }
          }
        }

        return pre;
      }, []);
      // 星级个人
      let CZTXJJList = null;
      // 判断是否开启星级个人 没开启不传值
      if (this.form.GRCZTXInfo.SFQYXJGR == 1) {
        CZTXJJList = this.form.CZTXJJList.filter((m) => m.JJLX === 1);
        this.form.GRCZTXInfo.ZBSZ =
          this.form.GRCZTXInfo.PBLX === "1"
            ? Number(this.ZBSZ_fixed || 0)
            : Number(this.ZBSZ_percent || 0);
      } else {
        CZTXJJList = [];
        this.form.GRCZTXInfo.ZBSZ = 0;
      }
      //  将第一个星级个人的名称赋值给每一个星级个人
      for (let i = 1; i < this.form.CZTXJJList.length; i++) {
        this.form.CZTXJJList[i].DJMC = this.form.CZTXJJList[0].DJMC;
      }

      this.form.GRCZTXInfo.TXLX = 1;
      this.form.HDFW = Number(this.form.HDFW);

      if (this.FLCZSZData.length > 0) {
        for (let i = 0; i < this.FLCZSZData.length; i++) {
          this.FLCZSZData[i].CZTXId = this.form.GRCZTXInfo.Id;
          this.FLCZSZData[i].PXH = i + 1;
        }
        this.form.FLCZSZList = this.FLCZSZData;
      }

      if (this.form.scfs === 1) {
        if (this.WDSJ.length <= 0) {
          return this.$message({
            type: "error",
            message: "请勾选维度",
          });
        }
        this.form.WDSJ = this.WDSJ.join(",");
      }
      if (this.KSLX !== [] && this.KSLX !== undefined) {
        var glnrFind = this.checkedActivities.find(
          (m) => m.Name === "成绩管理"
        );
        if (glnrFind) {
          var strKSLX = "";
          var strKSBFB = "";
          for (let i = 0; i < this.KSLX.length; i++) {
            var citiesValue = this.ksCities[this.KSLX[i] - 1];
            strKSLX += this.KSLX[i] + ",";
            strKSBFB += citiesValue.value + ",";
          }
          glnrFind.KSLX = strKSLX.substring(0, strKSLX.lastIndexOf(","));
          glnrFind.KSBFB = strKSBFB.substring(0, strKSBFB.lastIndexOf(","));
          glnrFind.AllEvaluationIndexList = [];
          glnrFind.CYFS = 1;
          glnrFind.EvaluatedObject = 0;
          glnrFind.EvaluationActivityType = 9;
          glnrFind.EvaluationObjectList = [];
          glnrFind.Id = "9";
          this.form.GRCZTXGLNRList.push(glnrFind);
        }
      }

      this.form.GRCZTXInfo.SCFS = this.form.scfs;

      this.$api.post(
        "/v1/growthsystem/EditPersonSet",
        {
          token: this.$store.state.token,
          SchoolCode: this.user.SchoolCode,
          GRCZTXSubmitInfo: {
            ...this.form,
            activitiesData: [],
            CZTXJJList,
          },
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message,
            });
            this.$router.push({ path: "/growthsystem/personSpecialGrowth" });
          }
        }
      );
    },
    getClassAll(activitiesData) {
      this.$api.post(
        "/v1/growthsystem/GetClassAll",
        {
          token: this.$store.state.token,
          SSXX: this.schoolId,
        },
        (r) => {
          if (r.Code === 0) {
            for (let i = 0; i < r.Target.length; i++) {
              var data = activitiesData.find(
                (m) => m.Name === r.Target[i].MC && m.CYFS === 42
              );
              if (!data) {
                this.activitiesData.push({
                  CYFS: 42,
                  Id: r.Target[i].Id,
                  Name: r.Target[i].MC,
                  DF: 0.0,
                });
              }
            }
          }
        }
      );
    },
    cancel() {
      this.$router.push("/growthsystem/personSpecialGrowth");
    },
    objectJoin(index) {
      this.currentActivityId = this.activitiesData[index].Id;
      this.objectList = this.activitiesData[index].EvaluationObjectList;
      for (let item of this.checkObjectList) {
        this.objectList = this.objectList.map((c) => {
          if (
            c.ObjectId === item.ObjectId &&
            c.ActivityId === item.ActivityId
          ) {
            c = item;
          }
          return c;
        });
      }

      this.checkObjectList = this.checkObjectList.filter(
        (c) =>
          !this.checkObjectList
            .filter(
              (c) =>
                c.ActivityId === this.currentActivityId &&
                !this.objectList
                  .map((item) => item.ObjectId)
                  .includes(c.ObjectId)
            )
            .map((c) => c.ObjectId)
            .includes(c.ObjectId)
      );
      this.objectVisible = true;
    },
    //指标参与
    indexJoin(index, idx) {
      this.currentActivityIndex = index;
      let mainActivity = {};
      let activity = {};
      if (idx === 0 || idx) {
        let mainActivityList = this.form.activitiesData.filter(
          (el) => el.SFYXZJHD === 1
        );
        mainActivity = mainActivityList[index];
        activity = mainActivity.children[idx];
      } else {
        activity = this.form.activitiesData[index];
      }

      if (activity) {
        this.activity = activity;
        if (activity.EvaluationActivityType === 3) {
          this.indexList = activity.AllEvaluationIndexList.map((item) => ({
            IndexId: item.IndexId,
            IndexName: item.IndexName,
            DF: "",
            ActivityId: activity.Id,
          }));
          for (let item of this.checkIndexList) {
            this.indexList = this.indexList.map((c) => {
              if (
                c.IndexId === item.IndexId &&
                c.ActivityId === item.ActivityId
              ) {
                c = item;
              }
              return c;
            });
          }

          this.checkIndexList = this.checkIndexList.filter(
            (c) =>
              this.indexList.filter(
                (item) =>
                  item.ActivityId === c.ActivityId && item.IndexId === c.IndexId
              ).length > 0
          );
          this.indexVisible = true;
        } else {
          this.currentActivityId = activity.Id;
          let objectList = activity.EvaluationObjectList || [];
          // 找出指标的父级指标 整理成树形结构
          let arr = []; //该活动对象的所有指标
          objectList.forEach((item) => {
            if (item.indexList) {
              item.indexList.forEach(
                (index) => (index.IndexClassify = item.PJFS)
              );
            }
            arr = [...arr, ...(item.indexList || [])];
          });

          let allArr = [];
          arr.forEach((item) => {
            item.Id = item.IndexId;
            let res = this.schoolIndex.find(
              (it) =>
                it.Id === item.IndexId &&
                it.IndexClassify === item.IndexClassify
            );
            item.ParentId = res.ParentId;
            // 递归找出树
            this.findParentsTree(item, allArr, item);
          });
          let allArr2 = []; //顶级的指标放在里面
          allArr.forEach((item) => {
            if (item.ParentId) {
              let res = allArr.find((el) => el.Id === item.ParentId);
              if (!res) {
                if (!allArr2.some((el) => el.Id === item.Id))
                  allArr2.push(item);
              } else if (!res.children) res.children = [item];
              else {
                if (!res.children.some((el) => el.Id === item.Id))
                  res.children.push(item);
              }
            } else {
              if (!allArr2.some((el) => el.Id === item.Id)) allArr2.push(item);
            }
          });
          this.indexList = allArr2;
          this.indexVisible = true;
        }
      } else {
        this.checkIndexList = this.checkIndexList.filter(
          (c) =>
            !this.checkIndexList
              .filter(
                (c) =>
                  c.ActivityId === this.currentActivityId &&
                  !this.indexList
                    .map((item) => item.IndexId)
                    .includes(c.IndexId)
              )
              .map((c) => c.IndexId)
              .includes(c.IndexId)
        );
      }
    },
    handdleCYFS(type, index, idx) {
      type = type ? parseInt(type) : 0;
      if (type === 2) {
        this.objectJoin(index, idx);
      } else if (type === 3) {
        this.indexJoin(index, idx);
      }
    },
    getResultUrl(filePath, index) {
      this.form.CZTXJJList[Number(index)].JJTB = filePath;
    },
    getResultUrl1(filePath, index) {
      this.FLCZSZData[Number(index)].TPLJ = filePath;
    },
    addItem() {
      this.form.CZTXJJList.push({
        JJTB: "",
        SZNR: undefined,
        JJLX: 2,
        SZLX: 1,
        DJMC: "",
      });
    },
    addFLCZItem() {
      this.FLCZSZData.push({
        Id: "",
        CZTXId: "",
        MC: "",
        QSF: "",
        TPLJ: "",
      });
    },
    deleteItem(row) {
      this.FLCZSZData.splice(this.FLCZSZData.indexOf(row), 1);
    },
    checkedActivityChange(e) {
      if (!e || !e.find((item) => item.Id === "9")) {
        this.KSLX = [];
        this.ksCheckedCities = [];
        this.ksCities.forEach((item) => (item.value = 0));
      }
      this.activitiesData.forEach((item) => {
        if (item.CYFS === 42 && !e.find((it) => it.Id === item.Id)) {
          item.KSLX = 0;
        }
      });
    },
    checkAll(item) {
      if (item.all) {
        item.checked = item.children.map((it) => it.Id);
        item.show = true;
      } else {
        item.checked = [];
      }
    },
    checkChild(item) {
      item.all = item.checked.length === item.children.length;
    },
    // 获取学校通用星星指标
    getSchoolIndex() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetSchoolEindex",
          {
            SchoolId: this.user.SSZZId, //	是	string	学校Id
            IndexType: 0, //	是	string	指标类型0全部1通用指标2星星指标
            token: this.user.Token,
          },
          (res) => {
            if (res.Code === 0 && res.Target) {
              this.schoolIndex = res.Target;
              resolve();
            }
          }
        );
      });
    },
    // 获取活动详情 得到对象中的指标
    getActivityInfo(activities) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetEvaluationActivity",
          {
            EvaIds: activities.map((item) => item.Id), //	是	字符数组	活动Id数组
            TermId: this.term.Id, //	是	string	当前学期Id
            TermBeginTime: this.term.BeginTime, //	是	datetime	学期开始时间
            TermEndTime: this.term.EndTime, //	是	datetime	学期结束时间
            SchoolId: this.user.SSZZId, //	是	string	学校Id
            token: this.user.Token,
          },
          (res) => {
            if (res.Code === 0 && res.Target) {
              res.Target.forEach((activity2) => {
                let activity1 = activities?.find(
                  (item) => item.Id === activity2.Id
                );
                if (activity2.UserEObjectList) {
                  activity2.UserEObjectList.forEach((obj) => {
                    if (typeof obj.EvaIndexList === "string")
                      obj.EvaIndexList = JSON.parse(obj.EvaIndexList);
                  });
                }
                if (activity1 && activity1.EvaluationObjectList) {
                  activity1.EvaluationObjectList.forEach((obj1) => {
                    let obj2 = activity2.UserEObjectList?.find(
                      (obj2) => obj2.EObjectId === obj1.ObjectId
                    );
                    if (obj2 && obj2.EvaIndexList) {
                      obj1.indexList = obj2.EvaIndexList.reduce((pre, cur) => {
                        let indexInfo = this.schoolIndex?.find(
                          (it) =>
                            it.Id === cur.GLId && it.IndexClassify === obj2.PJFS
                        );
                        if (
                          indexInfo &&
                          !pre.some(
                            (e) =>
                              e.IndexId === indexInfo.Id &&
                              e.ObjectId === cur.EObjectId
                          )
                        ) {
                          // 回显
                          let checkedIndex =
                            this.form.GRCZTXInfo.EvaluationObjectAndIndexList?.find(
                              (it) =>
                                it.CZTXId === this.form.GRCZTXInfo.Id &&
                                it.HDId === activity1.Id &&
                                it.GLId === indexInfo.Id
                            );

                          pre.push({
                            ObjectId: obj1.ObjectId,
                            IndexId: indexInfo.Id,
                            IndexName: indexInfo.IndexName,
                            DF: checkedIndex ? checkedIndex.DF : "",
                            ActivityId: activity2.Id,
                            checked: !!checkedIndex,
                          });
                        }
                        return pre;
                      }, []);
                    }
                  });
                  if (activity1.children) {
                    activity1.children.forEach((child) => {
                      if (child.EvaluationObjectList) {
                        child.EvaluationObjectList.forEach((childObj) => {
                          let res = activity1.EvaluationObjectList?.find(
                            (parentObj) =>
                              parentObj.ObjectId === childObj.ObjectId
                          );
                          if (res) {
                            if (res.indexList) {
                              res.indexList.forEach((element) => {
                                element.ActivityId = childObj.ActivityId;
                                element.ObjectId = childObj.ObjectId;
                              });
                              childObj.indexList = JSON.parse(
                                JSON.stringify(res.indexList)
                              );
                              // 回显
                              childObj.indexList.forEach((childIndex) => {
                                childIndex.ActivityId = child.Id;
                                let checkedIndex =
                                  this.form.GRCZTXInfo?.EvaluationObjectAndIndexList?.find(
                                    (it) =>
                                      it.CZTXId === this.form.GRCZTXInfo.Id &&
                                      it.HDId === child.Id &&
                                      it.GLId === childIndex.IndexId
                                  );
                                if (checkedIndex) {
                                  childIndex.DF = checkedIndex.DF;
                                  childIndex.checked = true;
                                }
                              });
                            }
                          }
                        });
                      }
                    });
                  }
                }
              });
              resolve();
            }
          }
        );
      });
    },
    // 指标勾选确定
    checkedIndexSubmit() {
      let arr = [];
      let arr2 = [];
      let findChecked = (index, arr) => {
        if (index.checked) {
          arr2.push(JSON.parse(JSON.stringify(index)));
          arr.push(index);
        }
        if (index.children) {
          index.children.forEach((item) => findChecked(item, arr));
        }
      };
      this.indexList.forEach((item) => findChecked(item, arr));
      this.activity.indexList = arr;
      this.activity.checked = arr2;
      this.indexVisible = false;
    },
    // 用递归的方法找到指标的父级链,用一个数组存放已经发现的所有指标
    findParentsTree(index, arr, baseItem) {
      if (!arr.some((item) => item.Id === index.Id)) {
        if (index.Id !== baseItem.Id) {
          index.IndexId = index.Id;
          index.ActivityId = baseItem.ActivityId;
          index.DF = 0;
          index.checked = false;
          index.ObjectId = baseItem.ObjectId;
          if (
            this.form.GRCZTXInfo &&
            this.form.GRCZTXInfo.EvaluationObjectAndIndexList
          ) {
            let res = this.form.GRCZTXInfo.EvaluationObjectAndIndexList.find(
              (element) =>
                element.HDId === this.activity.Id &&
                element.GLId === index.IndexId
            );
            if (res) {
              index.checked = true;
              index.DF = res.DF;
            } else {
              if (this.activity.checked) {
                let res = this.activity.checked.find(
                  (el) => el.Id === index.Id
                );
                if (res) {
                  index.DF = res.DF;
                  index.checked = true;
                }
              }
            }
          }
        }
        // 找到的所有父级链 因为是从学校指标中得来 所以用的是同一个对象 如果不把children置空 那么很容易重复
        index.children = [];
        arr.push(index);
        if (index.ParentId) {
          let res2 = this.schoolIndex.find(
            (item) =>
              item.Id === index.ParentId &&
              item.IndexClassify === index.IndexClassify
          );
          if (res2) this.findParentsTree(res2, arr, baseItem);
        }
      }
    },
  },
  watch: {
    checkedActivities(newValue, oldValue) {
      for (let i = 0; i < newValue.length; i++) {
        if (oldValue[i] != newValue[i]) {
          this.$watch(
            function () {
              return newValue[i];
            },
            function () {
              this.$refs.form.validateField("activitiesData[" + i + "].Id");
            },
            {
              deep: true,
            }
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-activity {
  width: 100% !important;
  font-size: 14px;
  margin-bottom: 15px;
  min-height: 50px;
  border-bottom: 1px solid #ccc;
  .has-child {
    position: relative;
    padding-left: 20px;
    .has-child-arrow {
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      &.arrow-rotate90 {
        transform: rotate(90deg);
        transition: all 0.3s;
      }
      &.arrow-rotate0 {
        transform: rotate(0deg);
        transition: all 0.3s;
      }
    }
  }
}
.item-child {
  &.show-child {
    height: auto;
    transition: all 0.3s;
  }
  &.close-child {
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
  }
}
.index-checkbox {
  margin-right: 15px !important;
  margin-bottom: 8px;
}
.enabledStar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(221, 221, 221, 0.5);
  cursor: not-allowed;
}
</style>
