<template>
  <!-- 学生家长评活动弹窗 -->
  <el-dialog width="60%" top="5vh"
      :title="dialogTitle" :visible.sync="tc" :before-close="close"
      :close-on-click-modal="false" append-to-body>
      <el-form status-icon :model="form" :ref="formName"
      label-width="100px" class="demo-ruleForm" label-position="right">
        <el-form-item :label="item.PJHDMC" v-for="item in activityList" :key="item.Id" :label-width="formLabelWidth">
            <el-checkbox-group v-model="item.checkList" >
                <el-checkbox v-for="(pj,index2) in item.HDLXarr"  :key="index2" :label="pj.pjlxname" @change ="handleChange($event)">
                    <div>{{ pj.pjlxname }}</div>
                </el-checkbox>
            </el-checkbox-group>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
      visible: {
          type: Boolean,
          default: false
      },
      dialogTitle: String,
      activityList: {
        type: Array,
        default() {
          return [];
        }
      },
    },
    model: {
        prop: "visible",
        event: "change"
    },
    data() {
        return {
            tc:false,
            type:'',
            form: {},
            formName: "form",
            checkList: [],
            checkValue:false,
            formLabelWidth:'25%',
            rules:{},
        }
    }, 
    
    mounted(){
        
    },
    methods:{
        //禁用其他活动(不需要)
        handleDisabled(pj,item){
            if(this.checkList &&this.checkList.length>0){
                let a = this.activityList.find(c=>c.pjlx.find(o=>this.checkList.includes(o.pjlxname)))
                if(a){
                    return !a.pjlx.find(o=>o.pjlxname == pj.pjlxname)
                }else return false
            }else return false
        },
        // 勾选框
        handleChange(checked) {},
        //取消选择活动
        cancel(){
          this.$confirm("确认关闭？").then(() => {
            this.$emit('cancelHDdialog')
          }).catch(()=>{});
        },
        //确认选择活动
        confirm(){
            this.$refs.form.resetFields();
            let a = this.activityList.filter(c=>c.checkList.length>0)
            if(this.dialogTitle.includes('定量')){
               this.tpye = 1
            }else if(this.dialogTitle.includes('学生家长评活动')){
               this.tpye = 2
            }else if(this.dialogTitle.includes('教师自评/互评')){
                this.tpye = 3
            }else if(this.dialogTitle.includes('考核小组评')){
                this.tpye = 4
            }
            this.$emit('sumbitForm',{type:this.tpye,a})
        },
        //形成闭环
        close(){
            this.$emit('sumbitForm',{})
        }
    },
   
    watch: {
        visible(val) {
            this.tc = val;
        },
        
    }, 
}
</script>

<style lang="scss" scoped>
.el-form-item{
    margin-bottom: 0;
}
</style>