<template>
  <!-- 教师通用报表 -->
  <div class="center-study-report">
    <!-- 年级以及学科等筛选 -->
    <div class="filter-container">
      <!-- 学期筛选 -->
      <div style="display: flex;align-items:center">
        <!-- @change="handleSelectChange" -->
        <div style="width: 100px;">报表名称：</div>
        <el-select
          v-model="bbid"
          size="small"
          placeholder="请选择"
          @change="handleSelectChange"
        >
          <el-option
            v-for="item in bbList"
            :key="item.Id"
            :label="item.JHMC"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 姓名搜索 -->
      <div class="search">
        <el-input
          v-model="searchname"
          placeholder="请输入关键字"
          size="small"
          maxlength="20"
          prefix-icon="el-icon-search"
        />
        <el-button size="small" type="primary" @click="handleSearch"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="btn-box">
      <el-button
        size="small"
        v-if="permissionBtn.add"
        type="primary"
        @click="opendialog"
        >生成新报表</el-button
      >
      <el-button
        size="small"
        v-if="permissionBtn.del"
        type="primary"
        @click="delItem"
        >删除报表</el-button
      >
      <el-button size="small" type="primary" @click="handleExport"
        >下载报表</el-button
      >
      <el-button
        size="small"
        type="primary"
        :loading="createReportDataLoading"
        @click="createReportData"
        >生成报表数据</el-button
      >
    </div>
    <Table :tableData="tableData" :heda="heda"></Table>
    <AddDialog
      v-model="addDialog"
      :termList="termList"
      @canceldialog="addDialog = false"
      @sumbitForm="sumbitForm"
    ></AddDialog>
    <DelDialog
      v-model="delDialog"
      @refresh="refresh"
      :bbList="bbList"
      @canceldialog="delDialog = false"
      @sumbitForm="sumbitForm"
    />
    <!-- 下载报表的隐藏弹窗 -->
    <multiplePdf
      class="pdf1"
      :tableRow="tableRow"
      :heda="heda"
      :pageSubtitle="pageSubtitle"
    ></multiplePdf>
  </div>
</template>

<script>
import AddDialog from './Dialog/addDialog.vue'
import multiplePdf from './Dialog/multiplePdf.vue'
// import multiplePdf from "../ZYDDBB/multiplePdf.vue";

import DelDialog from './Dialog/delDialog.vue'
import Table from './Table.vue'

import JSZip from 'jszip'
import FileSaver from 'file-saver'
export default {
  components: { AddDialog, Table, DelDialog, multiplePdf },
  data() {
    return {
      addDialog: false, //生成新报表弹窗
      delDialog: false, //生成新报表弹窗
      bbList: [],
      termList: [],
      searchname: '',
      bbid: '',
      permissionBtn: {
        del: false,
        add: false
      },
      user: '',
      MBGLID: this.$route.params.MBGLID,
      tableData: [],
      tableRow: [],
      tableColData: [],
      heda: [{ prop: 'KHRXM', label: '姓名' }],
      baseList: [],
      pageSubtitle: '通用教师报表名单',
      createReportDataLoading: false
    }
  },
  async created() {
    this.user = JSON.parse(localStorage.getItem('userinfo'))
    this.user.menu.forEach(i => {
      if (i.CodeOREId == 'DevelopmentCenter2') {
        if (i.ChildMenusList && i.ChildMenusList.length > 0) {
          i.ChildMenusList.forEach(it => {
            if (it.CodeOREId == 'TYJSBB') {
              if (it.ChildMenusList && it.ChildMenusList.length > 0) {
                it.ChildMenusList.forEach(item => {
                  // if(item.CodeOREId == 'downtyjsbb')this.permissionBtn.down = true
                  if (item.CodeOREId == 'addtyjsbb')
                    this.permissionBtn.add = true
                  if (item.CodeOREId == 'deltyjsbb')
                    this.permissionBtn.del = true
                })
              }
            }
          })
        }
      }
    })
    await this.getTermList()
    await this.getMBSCJHBList()
  },
  methods: {
    //导出excel表
    async handleExport() {
      await this.handleDownData()
      let header = this.heda.map(i => {
        return i.label
      })
      console.log(header)
      // let merges = ['A1:B1']
      import('@/style/js/Export2ExcelSheetArray').then(excel => {
        // let tHeader = ['序号','姓名','定量考核','学生家长评','教师自互评','考核小组评','职业道德考核最后得分'];
        let tHeader = header
        let data = [
          {
            Sheet: '自定义报表',
            Data: this.tableData.map((item, index) => {
              return this.heda.map(o => item[o.prop])
            })
          }
        ]
        excel.export_json_to_excel({
          header: tHeader, //表头 必填
          data, //具体数据 必填
          // merges,
          filename: this.pageSubtitle //非必填
        })
      })
    },
    //下载
    // async downItem(){
    //   await this.handleDownData()
    //   this.zips = new JSZip();
    //   let title = this.pageSubtitle
    //   //报错的地方
    //   let p = await this.getPdfFromHtml2('pdf1',title)
    //   await this.zips.file(
    //       `${p.name}-${new Date().getTime()}.pdf`,
    //       p.PDF.output("blob")
    //   );
    //   this.zips.generateAsync({
    //       type: "blob",
    //   }).then((content) => {
    //       FileSaver.saveAs(
    //       content,
    //       this.pageSubtitle + new Date().getTime() + ".zip"
    //       );
    //   });
    // },
    //重新获取数据
    refresh() {
      this.getMBSCJHBList()
    },
    //获取学期列表
    getTermList() {
      return new Promise((resolve, rej) => {
        this.$api.post(
          'v1/accountmanage/GetTerm',
          {
            SchoolId: this.user.SSZZId,
            token: this.user.Token
          },
          res => {
            if (res.Code === 0) {
              this.termList = res.Target || []
              localStorage.setItem('termList', JSON.stringify(res.Target || []))
              resolve()
            } else {
              this.$message.error(res.Message)
            }
          }
        )
      })
    },
    //下拉报表列表，选择报表名称
    handleSelectChange() {},
    //搜索按钮
    handleSearch() {
      this.tableData = this.baseList.filter(i =>
        i.KHRXM.includes(this.searchname)
      )
    },
    //生成新报表按钮
    opendialog() {
      this.addDialog = true
    },
    //生成新报表弹窗确认
    sumbitForm(data) {
      this.addDialog = false
      data.MBGLID = this.MBGLID
      data.IsSelect = data.IsSelect ? 1 : 2

      this.$api.post(
        '/v1/mbbb/AddMBSCJHB',
        {
          MBSCJHBInfo: data,
          token: this.user.Token
        },
        res => {
          if (res.Code === 0) {
            this.$message({
              type: 'success',
              message: '新增成功',
              showClose: true
            })
            this.getMBSCJHBList()
          } else {
            this.$message.error(res.Message)
          }
        }
      )
    },
    //删除报表按钮
    delItem() {
      this.delDialog = true
    },
    //获取下拉报表名称列表
    getMBSCJHBList() {
      return new Promise((resolve, rej) => {
        this.$api.post(
          '/v1/mbbb/GetMBSCJHBList',
          {
            MBGLID: this.MBGLID,
            token: this.user.Token
          },
          res => {
            if (res.Code === 0) {
              // console.log( res.Target ,'获取下拉报表名称列表');
              let arr = res.Target || []
              this.bbList = arr.reduce((pre, cur) => {
                let terms = []
                let a = cur.XQJH.split(',')

                this.termList.forEach(item => {
                  terms.push({
                    Id: item.Id,
                    Name: item.Name,
                    checked: false
                  })
                })
                if (a) {
                  terms.forEach(i => {
                    i.checked = !!a.find(t => t == i.Id)
                  })
                }
                pre.push({
                  JHMC: cur.JHMC,
                  SFWC: cur.SFWC,
                  Id: cur.Id,
                  terms
                })
                return pre
              }, [])
              if (this.bbList && this.bbList.length > 0) {
                this.bbid = this.bbList[0].Id
                this.getTableData()
              }
              resolve()
            } else {
              this.$message.error(res.Message)
            }
          }
        )
      })
    },

    //获取Table表数据
    getTableData() {
      this.$api.post(
        '/v1/mbbb/GetZYDDBBList',
        {
          MBGLID: this.MBGLID,
          SCJHID: this.bbid,
          JSMC: '',
          token: this.user.Token
        },
        r => {
          if (r.Code === 0) {
            let list = r.Target || []

            // list.forEach(i => { // 没有分到，是不是说JG这里分父子，父的fid是0，子的fid是对应父的id对
            //   i.JG = JSON.parse(i.JGJSON)
            //   let jgJson = i.JG
            // console.log(list)
            // // })

            // let tableData = []
            // for (let index = 0; index < list.length; index++) {
            //   let element = list[index];
            //   let jg = JSON.parse(element.JGJSON)
            //   let zfData = [] // 存放子父数据

            //   const fjData = jg.filter(item => {
            //     return item.fid == "0"
            //   })
            //   zfData = fjData.map(item => {
            //     return {
            //       ...item,

            //       children: []
            //     }
            //   })

            //   for (let jgIndex = 0; jgIndex < jg.length; jgIndex++) {
            //     const jgItem = jg[jgIndex];

            //     const zfIndex = zfData.findIndex(item => {
            //       return jgItem.fid === item.id
            //     })
            //     if (zfIndex > -1) {
            //       zfData[zfIndex].children.push(jgItem)
            //     }

            //   }
            //   element["fzData"] = zfData

            //   // console.log(zfData)
            //   // tableData = [...tableData, ...zfData]
            // }
            // console.log(list)
            // this.tableData = list

            // console.log(list, '获取Table表数据this.tableData');
            // this.tableData = list
            // 下面那些就是分子的？

            if (list.length > 0) {
              let i = 0
              //因为每一行的数据都是一样的，只需要找表头
              let JG = JSON.parse(list[0].JGJSON)
              let first_list = JG.filter(o => o.fid == '0')

              first_list.forEach(o => {
                let sort = 0
                let son_list = JG.filter(y => y.fid == o.id)
                let hedajson = {
                  prop: 'xmmc' + i,
                  label: o.xmmc,
                  sort: sort, //合计最后
                  id: o.id, //自定义添加查找数据
                  children: []
                }
                i++
                // 保证合计是最后一列
                if (o.xmmc == '合计') {
                  hedajson.sort = first_list.length
                  hedajson.prop = 'ZF'
                }
                //子项目
                if (son_list && son_list.length > 0) {
                  sort = 1
                  son_list.forEach(o => {
                    let children_json = {
                      prop: 'xmmc' + i,
                      label: o.xmmc,
                      id: o.id,
                      sort: sort
                    }
                    sort++
                    hedajson.children.push(children_json)
                    i++
                  })
                  //有子项目的需要加小计
                  let children_json = {
                    prop: hedajson.prop,
                    label: '小计'
                  }
                  hedajson.children.push(children_json)
                }
                // 表头加
                this.heda.push(hedajson)
              })
            }

            this.heda = this.heda.sort((a, b) => {
              return a.sort - b.sort
            })

            list.forEach(o => {
              // 姓名和合计
              let userInfo = { KHRXM: o.KHRXM, ZF: o.ZF }
              this.heda.forEach(y => {
                // console.log('y',y);

                let jg = JSON.parse(o.JGJSON)
                let xm = jg.find(p => p.id == y.id)
                if (xm) {
                  // console.log('userInfo[y.prop]',userInfo[y.prop]);

                  userInfo[y.prop] = xm.df
                }
                //如果有子项目
                if (y.children && y.children.length > 0) {
                  y.children.forEach(p => {
                    if (p.id) {
                      // 如果有子项目，就找id一致的父项目
                      let children_jg = jg.find(m => m.id == p.id)

                      if (children_jg) {
                        userInfo[p.prop] = children_jg.df
                        // console.log('userInfo[p.prop]',userInfo[p.prop]);
                      }
                    }
                  })
                }
              })

              this.tableData.push(userInfo)
              this.tableData = this.tableData.sort((a, b) => b.ZF - a.ZF)
              this.tableData.forEach((item, index) => {
                item.xh = index + 1
              })
              this.baseList = this.tableData
              console.log(this.tableData, 'tableData')
              console.log(this.heda, 'heda')
            })
            // console.log(this.heda)
            // this.tableData = list;
          }
        }
      )
    },
    //传递给下载表的
    handleDownData() {
      return new Promise((resolve, rej) => {
        if (this.tableData && this.tableData.length > 0) {
          let list = this.tableData
          let arr = []
          let totalpage = Math.ceil(list.length / 18) //一页18，共page页
          for (let index = 1; index <= totalpage; index++) {
            let obj = {
              page: index,
              totalSize: 18,
              tableData: []
            }
            obj.tableData = list.slice((index - 1) * 18, index * 18)
            arr.push(obj)
          }
          this.tableRow = arr
          resolve()
        }
      })
    },
    createReportData() {
      this.createReportDataLoading = true
      this.$api.post(
        '/v1/mbbb/ExecuteBuildTask',
        {
          MBLX: 'TYJSBB',
          token: this.user.Token
        },
        res => {
          if (res.Code == 0) {
            this.$message.success('生成数据成功！')
            this.createReportDataLoading = false
          } else this.createReportDataLoading = false
        },
        err => {
          this.createReportDataLoading = false
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.center-study-report {
  font-size: 14px;
  .filter-container {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .search {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
}
.pdf1 {
  position: fixed;
  left: 280px;
  top: 0;
  z-index: -1;
  width: 297mm;
  margin: 0 auto;
}
</style>
