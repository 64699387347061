<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="essay-name-box">{{ tzgginfo.BT }}</div>
      <div class="essay-news-box">
        <span class="essay-news-sapn">{{
          tzgginfo.CJSJ | DateFormat("yyyy-MM-dd")
        }}</span>
        <span
          @click="handleReport()"
          v-if="readvisible"
          class="essay-news-sapn"
        >
          <span class="c-guree" style="font-weight: 800;">
            {{ tzgginfo.YDS }} </span
          >人已读， <span style="font-weight: 800;">{{ tzgginfo.WDS }}</span
          >人未读
        </span>
      </div>
      <p class="essay-p">{{ tzgginfo.NR }}</p>
    </div>
    <div style="margin-top: 10px; padding: 5px;" v-if="fileList.length > 0">
      <el-image
        style="margin: 5px; width: 200px; height: 200px;"
        v-for="file in fileList"
        :key="file.id"
        :src="file.url"
        fit="scale-down"
      ></el-image>
    </div>
  </div>
</template>
<style></style>
<script>
export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    this.tzgginfo.Id = this.$route.params.ggid;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
    }
  },
  computed: {},
  mounted: function() {
    this.loaddata();
  },
  methods: {
    handleReport: function() {
      this.$router.push({
        name: "noticereadreport",
        params: { ggid: this.tzgginfo.Id }
      });
    },
    loaddata: function() {
      if (this.tzgginfo.Id !== undefined && this.tzgginfo.Id !== "") {
        this.$api.post(
          "v1/notice/GetSingle",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            Id: this.tzgginfo.Id,
            YHId: this.cjr,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.tzgginfo = r.Target;
              var fjlist = this.tzgginfo.FJList;
              if (fjlist !== null && fjlist.length > 0) {
                this.fileList = [];
                for (var i = 0; i < fjlist.length; i++) {
                  this.fileList.push({
                    id: fjlist[i].Id,
                    name: fjlist[i].FJMC,
                    url: fjlist[i].FJLJ
                  });
                }
              }
              if (this.tzgginfo.CJR === this.cjr) {
                this.readvisible = true;
              }
            }
          }
        );
      }
    }
  },
  data() {
    return {
      isDisabled: false,
      ssxx: "",
      ssxq: "",
      token: "",
      fileList: [],
      njall: false,
      jyzall: false,
      readvisible: false,
      bjid: "",
      fjarr: [],
      njlist: [],
      jyzlist: [],
      xznjlist: [],
      xzjyzlist: [],
      cjr: "",
      tzgginfo: {
        Id: "",
        CJR: "",
        CJSJ: "",
        BT: "",
        NR: "",
        FSSJ: "",
        FSLX: "1",
        JSDXLX: "",
        YDS: 0,
        WDS: 0
      }
    };
  }
};
</script>
