<template>
  <div>
    <div class="cont-whole-box">
      <div style="padding: 30px;">
        <div class="essay-name-box">{{ zdybbinfo.BBMC }}</div>
        <div class="tab-heng-box" style="margin-top: 10px">
          <div class="operate-box borderno-l">
            <div class="czq-box" v-if="zdybbinfo.RQLX == 1">
              <div class="czq-name">周次:</div>
              <div class="inp-box">
                <el-select
                  @change="handleWeekChange"
                  v-model="weekid"
                  placeholder="请选择周次"
                >
                  <el-option label="全部" value=""> </el-option>
                  <el-option
                    v-for="week in weeklist"
                    :key="week.Id"
                    :label="week.ZS"
                    :value="week.Id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="czq-box" v-else-if="zdybbinfo.RQLX == 3">
              <div class="czq-name">时间:</div>
              <div class="multi-opt-rig" style="width:340px !important;">
                <div class="cele-left" style="width: 120px">
                  <el-date-picker
                    popper-class="datepicker-z-index"
                    style="width: 140px;"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择开始日期"
                    v-model="begindate"
                  ></el-date-picker>
                </div>
                <div
                  style="display: inline-block;font-size: 1rem;vertical-align: middle;margin: 0px 5px 0px 25px;"
                >
                  ~
                </div>
                <div class="cele-left" style="width: 120px">
                  <el-date-picker
                    popper-class="datepicker-z-index"
                    style="width: 140px;"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择结束日期"
                    v-model="enddate"
                  ></el-date-picker>
                </div>
              </div>
            </div>
            <div class="czq-box" v-if="zdybbinfo.DXQ == 1">
              <div class="czq-name">学期:</div>
              <div class="inp-box">
                <el-select
                  @change="handleTermChange"
                  v-model="ssxq"
                  placeholder="请选择学期"
                >
                  <el-option
                    v-for="term in termlist"
                    :key="term.Id"
                    :label="term.Id"
                    :value="term.Id"
                  >
                    {{ term.XQMC }}
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="czq-box" v-if="zdybbinfo.BJCX == 1">
              <div class="czq-name">年级:</div>
              <div class="inp-box">
                <el-select
                  @change="handleGradeChange"
                  v-model="gradeid"
                  placeholder="请选择年级"
                >
                  <el-option
                    v-for="nj in gradelist"
                    :label="nj.Name"
                    :key="nj.Id"
                    :value="nj.Id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="czq-name">班级:</div>
              <div class="inp-box">
                <el-select
                  @change="handleClassChange"
                  v-model="classid"
                  placeholder="请选择年级"
                >
                  <el-option
                    v-for="bj in classlist"
                    :label="bj.Name"
                    :key="bj.Id"
                    :value="bj.Id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="czq-box">
              <div class="seac-btn-box">
                <a class="seac-btn-boxa" @click="loaddata">搜索</a>
              </div>
            </div>
          </div>
          <table cellpadding="0" cellspacing="0" v-if="headers.length > 0">
            <thead>
              <tr>
                <td v-for="(header, key) in headers" :key="key">
                  {{ header }}
                </td>
              </tr>
            </thead>
            <tbody v-if="rows.length > 0">
              <tr v-for="(row, key) in rows" :key="key">
                <td v-for="(column, key) in row.Value" :key="key">
                  {{ column }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.bbid = this.$route.params.reportid;
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
      this.userid = user.Id;
    }
  },
  mounted: function() {
    this.loadweeklist();
    this.loadreportdata();
  },
  methods: {
    handleTermChange: function() {
      this.loaddata();
    },
    handleWeekChange: function() {
      this.loaddata();
    },
    handleGradeChange: function() {
      this.classid = "";
      this.loadbjdata();
      this.loaddata();
    },
    handleClassChange: function() {
      this.loaddata();
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolOrg",
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.gradelist = [];
            var list = r.Target;
            this.zzjglist = list;
            for (var i = 0; i < list.length; i++) {
              if (list[i].Hierarchy === 1) {
                this.gradelist.push(list[i]);
              }
            }
            if (this.gradelist != null && this.gradelist.length > 0) {
              this.gradeid = this.gradelist[0].Id;
            }
            this.loadbjdata();
          }
        }
      );
    },
    loadbjdata: function() {
      if (this.gradeid.length > 0) {
        this.classlist = this.zzjglist.filter(
          c => c.Id === this.gradeid
        )[0].ChidOrgList;
      } else {
        this.classlist = [];
      }
      if (this.classlist != null && this.classlist.length > 0) {
        this.classid = this.classlist[0].Id;
      }
    },
    loadreportdata: function() {
      this.$api.post(
        "v1/customreport/GetReport",
        {
          Id: this.bbid,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.zdybbinfo = r.Target;
            if (this.zdybbinfo.BJCX == 1) {
              this.loadnjdata();
            }
            if (this.zdybbinfo.DXQ == 1) {
              this.loadtermlist();
            }
            this.loaddata();
          }
        }
      );
    },
    loadweeklist: function() {
      this.$api.post(
        "v1/xqzcb/GetWeekList",
        {
          TermId: this.ssxq,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.weeklist = [];
            var list = r.Target;
            if (list != null && list.length > 0) {
              for (var i = 0; i < list.length; i++) {
                list[i].ZS = "第" + list[i].ZS + "周";
                this.weeklist.push(list[i]);
              }
              this.weekid = this.weeklist[0].Id;
            }
          }
        }
      );
    },
    loadtermlist: function() {
      this.$api.post(
        "v1/xqb/GetXQBList",
        {
          AreaOrSchoolId: this.ssxx,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.termlist = r.Target;
          }
        }
      );
    },
    loaddata: function() {
      let zzjgid = this.gradeid;
      if (this.classid.length > 0) {
        zzjgid = this.classid;
      }
      this.$api.post(
        "v1/customreport/GetReportData",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          BBId: this.bbid,
          WeekId: this.weekid,
          BeginDate: this.begindate,
          EndDate: this.enddate,
          YHId: this.userid,
          ZZJGId: zzjgid,
          token: this.token
        },
        r => {
          if (r.Code === 0 && r.Target != null) {
            this.headers = [];
            this.rows = [];
            this.headers = r.Target.Headers;
            this.rows = r.Target.Rows;
          }
        }
      );
    }
  },
  data() {
    return {
      weeklist: [],
      headers: [],
      zzjglist: [],
      rows: [],
      userid: "",
      weekid: "",
      gradeid: "",
      classid: "",
      gradelist: [],
      classlist: [],
      begindate: null,
      enddate: null,
      termlist: [],
      ssxx: "",
      ssxq: "",
      token: "",
      bbid: "",
      zdybbinfo: {
        Id: "",
        SSXX: "",
        CJR: "",
        LTMC: "",
        BBMC: "",
        RQLX: 0,
        BJCX: 0,
        DXQ: 0,
        SQLText: ""
      }
    };
  }
};
</script>
