<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="30%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="tab-shu-box">
      <el-form :model="formData" ref="ruleForm" @validate="validate">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl pos-r">
                报表代码
                <div class="prompt-butt-box">注</div>
                <div class="prompt-butt-center">
                  注：报表代码需要和前端开发人员协商一致，且报表代码不允许重复。
                </div>
              </td>
              <td>
                <el-form-item
                  :rules="[
                    {
                      required: true,
                      message: '请输入报表代码',
                      trigger: 'change'
                    }
                  ]"
                  prop="BBDM"
                  ref="BBDM"
                  style="height:16px"
                >
                  <el-input
                    v-model="formData.BBDM"
                    placeholder="请输入内容"
                    size="small"
                    class="jf-form"
                    prop="BBDM"
                  ></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">报表名称</td>
              <td>
                <el-form-item
                  :rules="[
                    {
                      required: true,
                      message: '请输入报表名称',
                      trigger: 'change'
                    }
                  ]"
                  prop="BBMC"
                  ref="BBMC"
                  style="height:16px"
                >
                  <el-input
                    v-model="formData.BBMC"
                    placeholder="请输入内容"
                    size="small"
                    class="jf-form"
                    prop="BBMC"
                  ></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">支撑评价方式</td>
              <td>
                <el-checkbox @change="checkedPJFS" v-model="formData.TYZB"
                  >通用指标</el-checkbox
                >
                <el-checkbox @change="checkedPJFS" v-model="formData.XXZB"
                  >星星指标</el-checkbox
                >
                <el-checkbox @change="checkedPJFS" v-model="formData.WJ"
                  >问卷</el-checkbox
                >
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">报表类别</td>
              <td>
                <el-form-item
                  :rules="[
                    {
                      required: true,
                      message: '请选择报表类别',
                      trigger: 'change'
                    }
                  ]"
                  prop="BBLX"
                  ref="BBLX"
                  style="height:16px"
                >
                  <el-select
                    v-model="formData.BBLX"
                    placeholder="请选择"
                    size="small"
                    class="jf-form"
                    style="width: 100%;"
                    prop="BBLX"
                    @change="checkedPJFS"
                  >
                    <el-option
                      v-for="item in reportType.filter(c =>
                        reportFilter.includes(c.Key)
                      )"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr
              v-if="
                (formData.TYZB || formData.XXZB || formData.WJ) && formData.BBLX
              "
            >
              <td class="tab-shu-titl">报表形状</td>
              <td>
                <el-form-item
                  :rules="[
                    {
                      required: true,
                      message: '请选择报表形状',
                      trigger: 'change'
                    }
                  ]"
                  prop="BBXZ"
                  ref="BBXZ"
                  style="height:16px"
                >
                  <el-select
                    v-model="formData.BBXZ"
                    placeholder="请选择"
                    size="small"
                    class="jf-form"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="item in shapes.filter(c =>
                        shapeLimit.includes(c.Key)
                      )"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td class="tab-shu-titl">状态</td>
              <td>
                <el-form-item
                  :rules="[
                    {
                      required: true,
                      message: '请选择状态',
                      trigger: 'change'
                    }
                  ]"
                  prop="SYZT"
                  ref="SYZT"
                  style="height:16px"
                >
                  <el-select
                    v-model="formData.SYZT"
                    placeholder="请选择"
                    size="small"
                    class="jf-form"
                    style="width: 100%;"
                    prop="SYZT"
                  >
                    <el-option
                      v-for="item in statu"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      reportFilter: [],
      shapeLimit: [],
      validateResult: false,
      labelWidth: '250px',
      rules: {
        BBDM: [
          { required: true, message: '请输入报表代码', trigger: 'change' },
          {
            min: 0,
            max: 20,
            message: '长度在 0 到 20 个字符',
            trigger: 'change'
          }
        ],
        BBMC: [
          { required: true, message: '请输入报表名称', trigger: 'change' },
          {
            min: 0,
            max: 20,
            message: '长度在 0 到 20 个字符',
            trigger: 'change'
          }
        ],
        BBLX: [
          { required: true, message: '请选择报表类别', trigger: 'change' }
        ],
        SYZT: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      formData: {}
    }
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    reportType: {
      type: Array,
      default: function() {
        return []
      }
    },
    shapes: {
      type: Array,
      default: function() {
        return []
      }
    },
    statu: {
      type: Array,
      default: function() {
        return []
      }
    },
    dataList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  methods: {
    init() {
      this.formData = JSON.parse(JSON.stringify(this.data))
      if (this.formData.Id) {
        this.$set(this.formData, 'TYZB', this.formData.TYZB === 1)
        this.$set(this.formData, 'XXZB', this.formData.XXZB === 1)
        this.$set(this.formData, 'WJ', this.formData.WJ === 1)
      } else {
        this.$set(this.formData, 'TYZB', false)
        this.$set(this.formData, 'XXZB', false)
        this.$set(this.formData, 'WJ', false)
        this.$set(this.formData, 'BBLX', undefined)
        this.$set(this.formData, 'BBXZ', undefined)
      }

      // this.formData.TYZB = this.formData.TYZB === 1 ? true : false;
      // this.formData.XXZB = this.formData.XXZB === 1 ? true : false;
      // this.formData.WJ = this.formData.WJ === 1 ? true : false;
      this.checkedPJFS()
    },
    // closeDialog() {
    //   this.$emit("closeDialog");
    // },
    closeDialog() {
      this.$confirm('确认关闭？').then(() => {
        this.$emit('closeDialog')
      })
      // this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "保存",
      //   cancelButtonText: "放弃修改"
      // })
      //   .then(() => {
      //     //执行提交方法,执行成功后回调函数执行以下代码
      //     this.confirmDialog();
      //   })
      //   .catch(action => {
      //     if (action === "cancel") {
      //       this.$emit("closeDialog");
      //     }
      //   });
    },
    confirmDialog() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$api.post(
            '/v1/reporttemplate/EditReportTemplate',
            {
              token: this.$store.state.token,
              BBGLInfo: this.formData
            },
            r => {
              let data = ''
              if (r.Code === 0) {
                data = r.Target
                data.EvaluationMethod = ''
                if (data.TYZB === 1) {
                  data.EvaluationMethod += '通用指标,'
                }
                if (data.XXZB === 1) {
                  data.EvaluationMethod += '星星指标,'
                }
                if (data.WJ === 1) {
                  data.EvaluationMethod += '问卷,'
                }
                data.EvaluationMethod = data.EvaluationMethod.substr(
                  0,
                  data.EvaluationMethod.length - 1
                )
                this.$message({
                  type: 'success',
                  message: r.Message
                })
                this.$emit('saveDialog', data)
              }
            }
          )
        } else {
          return false
        }
      })
    },
    querySearchAsync() {
      this.validateResult = false
      if (this.dataList.findIndex(c => c.BBDM === this.formData.BBDM) !== -1) {
        this.validateResult = true
      }
    },
    validate(val, result) {
      this.$refs[val].$el.style.height = '16px'
      if (!result && this.visible) {
        this.$refs[val].$el.style.height = 'unset'
      }
    },
    checkedPJFS() {
      let reportArr = [
        {
          name: 'TYZB',
          value: [1, 2, 3, 4]
        },
        {
          name: 'XXZB',
          value: [1, 2, 3, 4, 5]
        },
        {
          name: 'WJ',
          value: [1, 3, 4]
        }
      ]
      // 取交集 以最少的作为交集
      let checked = reportArr.filter(item => this.formData[item.name])
      let minLength = checked.sort((a, b) => a.value.length - b.value.length)[0]
      let result = []
      let arr = minLength ? minLength.value : []
      arr.forEach(num => {
        if (checked.every(item => item.value.includes(num))) result.push(num)
      })
      this.reportFilter = result

      let shapesKey = this.shapes.map(item => item.Key)

      if (this.formData.BBLX) {
        // 个人报表
        if (this.formData.BBLX === 1) {
          this.shapeLimit = shapesKey.filter(item => [1, 5, 7].includes(item))
        }
        // 小组报表
        if (this.formData.BBLX === 2) {
          this.shapeLimit = shapesKey.filter(item => [2].includes(item))
        }
        // 班级报表
        if (this.formData.BBLX === 3) {
          this.shapeLimit = shapesKey.filter(item =>
            [3, 6, 8, 9].includes(item)
          )
        }
        // 总报表
        if (this.formData.BBLX === 4) {
          this.shapeLimit = shapesKey.filter(item => [4, 12].includes(item))
        }
        // 主活动报表
        if (this.formData.BBLX === 5) {
          this.shapeLimit = shapesKey.filter(item => [10, 11].includes(item))
        }
      }

      this.formData.BBLX = this.reportType
        .filter(c => this.reportFilter.includes(c.Key))
        .map(c => c.Key)
        .includes(this.formData.BBLX)
        ? this.formData.BBLX
        : undefined

      this.formData.BBXZ = this.shapes
        .filter(c => this.shapeLimit.includes(c.Key))
        .map(c => c.Key)
        .includes(this.formData.BBXZ)
        ? this.formData.BBXZ
        : undefined
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init()
        // 显示
      } else {
        this.$refs.ruleForm.validate()
        this.$refs.ruleForm.resetFields()
        // 隐藏
      }
    }
  }
}
</script>
