<template>
  <!-- 学科中心-->
  <div class="center-study-report" v-loading="loading">
    <!-- 年级以及学科等筛选 -->
    <div class="filter-container">
      <!-- 学期筛选 -->
      <div>
        <div style="margin-bottom:15px;display: flex;">
          <div style="width: 100px;">学期：</div>
          <el-select v-model="xqid" placeholder="请选择">
            <el-option
              v-for="item in terms"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 年级筛选 -->
      <div class="grades">
        <div class="label">年级：</div>
        <div class="grade-content">
          <div
            class="grade-item"
            :class="{ current: item.Id === curGrade.Id }"
            v-for="item in teacherGrades"
            :key="item.Id"
            @click="clickGrade(item)"
          >
            {{ item.Name }}
          </div>
          <div v-if="!teacherGrades || teacherGrades.length === 0">
            暂无年级
          </div>
        </div>
      </div>
      <!-- 学科筛选 -->
      <div class="subjects">
        <div class="label">学科：</div>
        <div class="subject-content">
          <div
            class="subject-item"
            :class="{ current: item.CourseId === curSubject.CourseId }"
            v-for="item in teacherSubject"
            :key="item.CourseId"
            @click="clickSubject(item)"
          >
            {{ item.CourseName }}
          </div>
          <div v-if="!teacherSubject || teacherSubject.length === 0">
            暂无学科
          </div>
        </div>
      </div>
    </div>
    <div class="info-button">
      <el-button size="small" type="primary" @click="showInfo"
        >查看详情</el-button
      >
    </div>
    <!-- 表格 -->
    <div class="table-container"></div>

    <!-- 详情弹窗 -->
    <StudyReportInfoDialog
      :show.sync="info.show"
      :title="info.title"
      :QXDJ="QXDJ"
      :formData="info.data"
      :curSubject="curSubject"
      :curGrade="curGrade"
      :xqid="xqid"
    />

    <!-- echats -->
    <reportEcharts :userInfo="userInfo" :curSubject="curSubject" />
  </div>
</template>

<script>
import StudyReportInfoDialog from "./info.vue";
import reportEcharts from "./echarts.vue";
import { getiFrstDimensionReport, GetSubjectSet } from "@/api/growthReportSet";

export default {
  name: "CenterStudyReport",
  components: {
    StudyReportInfoDialog,
    reportEcharts,
  },
  data() {
    return {
      // 登录人详情
      userInfo: {},
      // 菜单权限
      QXDJ: 2,
      // 当前学期id
      currentterm: "",
      // 教师所教班级
      teacherClass: [],
      grades: [],
      subjects: [],
      // 教师匹配的年级以及年级对应的学科
      teacherGrades: [],
      teacherSubject: [],
      curGrade: {},
      curSubject: {},
      info: {
        show: false,
        data: {},
        title: "",
      },
      dimensionSubject: [], //成长报告设置的学科
      loading: false,
      terms: [],
      xqid: "",
    };
  },
  /*
		根据登录人角色显示年级和学科
		1.全局角色：显示所有年级 当前年级下的所有学科
		2.班主任：查看自己班上的所有学科
		3.科任：查看自己所教年级下的学科
		4.班主任兼科任：同2，3
		*/
  async mounted() {
    let userInfo = localStorage.getItem("userinfo");
    if (userInfo) this.userInfo = JSON.parse(userInfo);
    else this.$message.error("未获取到用户详情");
    this.currentterm = localStorage.getItem("currentterm");
    this.loading = true;
    // 获取学业维度(综合素养中的学业报告/成长报告)
    let dimensionReport = await getiFrstDimensionReport({
      SSXX: this.userInfo.SSZZId,
      token: this.userInfo.Token,
    });

    await this.getTerms();
    if (
      dimensionReport &&
      dimensionReport.WDZSBGInfo &&
      dimensionReport.WDZSBGInfo.find((item) => item.LX === 1)
    ) {
      // 获取成长报告设置的学科
      let dimensionSubject = await GetSubjectSet({
        id: dimensionReport.WDZSBGInfo.find((item) => item.LX === 1).Id,
        token: this.userInfo.Token,
      });
      if (dimensionSubject && dimensionSubject.length > 0)
        this.dimensionSubject = dimensionSubject;
      else this.$message.error("请先设置学业报告对应的学科！");
    } else this.$message.error("请先设置学业报告！");

    await this.getGrades();
    // 获取年级所有课程
    for (let i = 0; i < (this.grades || []).length; i++) {
      let subjects = await this.getSubjects(this.grades[i].GradeNumber, 2);
      this.grades[i].subjects = subjects;
    }
    // 根据登录人是否全局角色判断是否请求
    await this.getTeacherClass();
    let menu = this.userInfo.menu.find(
      (item) => item.CodeOREId === "ReportCenter"
    );
    this.QXDJ = menu.QXDJLX;
    if (this.QXDJ === 1) {
      // 全局菜单
      this.teacherGrades = this.grades;
      this.curGrade = this.teacherGrades[0];
    } else {
      // 局部菜单
      this.handleGrade();
      // 获取老师所教课程
      this.subjects = await this.getBJcourse();
    }
    this.handleSubject(this.curGrade);
  },
  methods: {
    // 获取综合素养中的学业维度id 进一步获取学业维度的学科设置

    // 获取教师授课班级
    getTeacherClass() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetUserClassOrCurriculum",
          {
            UserId: this.userInfo.Id, //	是	string	用户Id
            TermId: this.currentterm, //	是	string	学期ID
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0 && res.Target) {
              if (res.Target.TeacherClass)
                res.Target.TeacherGrade = res.Target.TeacherClass.split("-")[0];
              if (
                res.Target.LecturerClass &&
                res.Target.LecturerClass.length > 0
              ) {
                res.Target.LecturerGrade = res.Target.LecturerClass.reduce(
                  (pre, cur) => {
                    let idx = pre.findIndex((key) => key === cur.split("-")[0]);
                    if (idx === -1) pre.push(cur.split("-")[0]);
                    return pre;
                  },
                  []
                );
              } else res.Target.LecturerGrade = [];

              this.teacherClass = res.Target;
              resolve();
            } else this.$message.error("未获取到教师班级");
          }
        );
      });
    },
    // 获取所有年级
    getGrades() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolOrg",
          {
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            IsDelete: 0, //	是	int	1.表示需要获取已删除和禁用数据
            GradeId: "", //	是	string	根据年级Id获取年级和年级以下的组织机构（不能传学校Id不是优先获取是全校的住址机构）
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0 && res.Target) {
              res.Target.forEach((item) => (item.subjects = []));
              this.grades = res.Target;
              resolve();
            } else this.$message.error("未获取到年级");
          }
        );
      });
    },
    // 根据登录人的角色筛选年级
    handleGrade() {
      this.teacherGrades = this.grades.reduce((pre, cur) => {
        let nj = "";
        let type = "";

        // 班主任年级
        if (
          cur.Id === this.teacherClass.TeacherGrade &&
          !this.teacherClass.LecturerGrade.some((el) => el === cur.Id)
        ) {
          nj = cur;
          type = 1;
          if (cur.ChidOrgList && cur.ChidOrgList.length > 0) {
            cur.ChidOrgList.forEach((it) => {
              // 班主任班级
              if (it.Id === this.teacherClass.TeacherClass) it.type = 1;
            });
          }
        }
        // 任课年级
        if (
          cur.Id !== this.teacherClass.TeacherGrade &&
          this.teacherClass.LecturerGrade.some((el) => el === cur.Id)
        ) {
          nj = cur;
          type = 2;
          if (nj.ChidOrgList && nj.ChidOrgList.length > 0) {
            nj.ChidOrgList.forEach((it) => {
              // 任课班级
              if (this.teacherClass.LecturerClass.some((e) => e === it.Id))
                it.type = 2;
            });
          }
        }

        // 既是班主任年级 又是任课年级
        if (
          cur.Id === this.teacherClass.TeacherGrade &&
          this.teacherClass.LecturerGrade.some((el) => el === cur.Id)
        ) {
          nj = cur;
          type = 3;
          if (nj.ChidOrgList && nj.ChidOrgList.length > 0) {
            nj.ChidOrgList.forEach((it) => {
              if (this.teacherClass.LecturerClass.some((el) => el === it.Id))
                it.type = 2;
              if (it.Id === this.teacherClass.TeacherClass) it.type = 3;
            });
          }
        }

        if (nj) {
          let idx = pre.findIndex((item) => item.Id === cur.Id);
          if (idx === -1)
            pre.push({
              Id: cur.Id,
              Name: cur.Name,
              GradeNumber: cur.GradeNumber,
              type, //1.班主任班级，2.科任班级 3.全局
              subjects: nj.subjects,
              ChidOrgList: nj.ChidOrgList || [],
            });
          return pre;
        } else return pre;
      }, []);

      if (this.teacherGrades && this.teacherGrades.length > 0) {
        this.curGrade = this.teacherGrades[0];
      }
    },
    // 获取年级所有课程
    getSubjects(role, type) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetUserCurriculum",
          {
            UserIdOrGrade: role, //	是	string	教师Id或年级数字
            TermId: this.currentterm, //	是	string	学期Id
            SchoolId: this.userInfo.SSZZId, //	是	string	学校信息
            GetDataType: type, //	是	int	1 教师所受课程 2班级所属课程
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0 && res.Target) {
              resolve(res.Target);
            } else this.$message.error("未获取到课程");
          }
        );
      });
    },
    // 获取老师所教课程
    getBJcourse(bj) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/evaluationactivity/GetClassSchedule",
          {
            UserId: bj ? "" : this.userInfo.Id, //	否	string	教师Id
            ClassId: bj ? bj.Id : "", //否	string	班级Id
            ClassScheduleType: bj ? 2 : 1, //	是	int	获取个人课程表1获取班级课程表2
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            TermId: this.currentterm, //	是	string	学期Id
            token: this.userInfo.Token,
          },
          (res) => {
            if (
              res.Code == 0 &&
              res.Target &&
              res.Target.ClassScheduleList &&
              res.Target.ClassScheduleList.length > 0
            ) {
              resolve(res.Target.ClassScheduleList);
            } else {
              this.$message.error("未获取到教师课表");
              resolve([]);
            }
          },
          (err) => reject(err)
        );
      });
    },
    // 点击年级
    clickGrade(grade) {
      this.curGrade = grade;
      this.handleSubject(grade);
    },
    // 根据登录人筛选学科
    async handleSubject(grade) {
      console.log(grade, "grade");
      // 筛选出当前年级对应的学业维度学科
      let dimensionSubject = this.dimensionSubject.filter((item) =>
        item.NJXZS?.includes(grade.GradeNumber)
      );

      if (this.QXDJ === 2) {
        if (grade.type === 1 || grade.type === 3) {
          if (grade.ChidOrgList && grade.ChidOrgList.length > 0) {
            let bjarr = grade.ChidOrgList.filter(
              (bj) => bj.type === 1 || bj.type === 3
            );
            if (bjarr.length > 0) {
              for (var i = 0; i < bjarr.length; i++) {
                let res = await this.getBJcourse(bjarr[i]);
                res.forEach((subject) => {
                  grade.subjects.forEach((subject2) => {
                    if (subject2.CourseId === subject.CourseId)
                      subject.CourseName = subject2.CourseName;
                  });
                });
                bjarr[i].subjects = res;
              }

              this.teacherSubject = bjarr.reduce((pre, cur) => {
                cur.subjects.forEach((element) => {
                  let idx = pre.findIndex(
                    (item) => item.CourseId === element.CourseId
                  );
                  if (
                    idx === -1 &&
                    dimensionSubject.some((item) =>
                      item.XKXZS.includes(element.CourseId)
                    )
                  ) {
                    pre.push({
                      CourseId: element.CourseId,
                      CourseName: element.CourseName,
                    });
                  }
                });
                return pre;
              }, []);
            }
          }
        } else if (grade.type === 2) {
          if (grade.ChidOrgList && grade.ChidOrgList.length > 0) {
            let bjarr = grade.ChidOrgList.filter(
              (bj) => bj.type === 2 || bj.type === 3
            );

            if (bjarr.length > 0) {
              for (var k = 0; k < bjarr.length; k++) {
                let res = await this.getBJcourse(bjarr[k]);
                res.forEach((subject) => {
                  i;
                  grade.subjects.forEach((subject2) => {
                    if (subject2.CourseId === subject.CourseId)
                      subject.CourseName = subject2.CourseName;
                  });
                });
                bjarr[k].subjects = res;
              }
              this.teacherSubject = bjarr.reduce((pre, cur) => {
                console.log(cur, "currr222222");
                if (cur.type === 3) {
                  cur.subjects.forEach((element) => {
                    let idx = pre.findIndex(
                      (item) => item.CourseId === element.CourseId
                    );

                    if (
                      idx === -1 &&
                      dimensionSubject.some((item) =>
                        item.XKXZS.includes(element.CourseId)
                      )
                    ) {
                      pre.push({
                        CourseId: element.CourseId,
                        CourseName: element.CourseName,
                      });
                    }
                  });
                  return pre;
                } else {
                  let subjectArr = this.subjects.filter(
                    (item) => item.ClassId === cur.Id
                  );
                  cur.subjects.forEach((element) => {
                    subjectArr.forEach((el) => {
                      if (el.CourseId === element.CourseId) {
                        let idx = pre.findIndex(
                          (item) => item.CourseId === element.CourseId
                        );
                        if (
                          idx === -1 &&
                          dimensionSubject.some((item) =>
                            item.XKXZS.includes(element.CourseId)
                          )
                        ) {
                          pre.push({
                            CourseId: element.CourseId,
                            CourseName: element.CourseName,
                          });
                        }
                      }
                    });
                  });
                  return pre;
                }
              }, []);
            }
          }
        }
      } else {
        this.teacherSubject = grade.subjects.filter((element) =>
          dimensionSubject.some((item) => item.XKXZS.includes(element.CourseId))
        );
      }
      this.teacherSubject.sort((a, b) =>
        a.CourseName.localeCompare(b.CourseName)
      );
      this.curSubject = this.teacherSubject[0] || {};
      this.loading = false;
    },
    // 点击学科
    clickSubject(subject) {
      this.curSubject = subject;
    },
    // 显示详情
    showInfo() {
      if (Object.keys(this.curSubject || {}).length === 0)
        return this.$message.error("未获取到学科");
      if (!this.xqid) return this.$message.error("请选择学期");
      this.info.show = true;
      this.info.title = this.curGrade.Name + this.curSubject.CourseName;
    },
    // 获取学期
    getTerms() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetTerm",
          {
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              this.terms = res.Target || [];
              if (this.terms[0]) {
                this.xqid = this.terms[0].Id;
              }
              resolve();
            } else reject();
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.center-study-report {
  font-size: 14px;

  .filter-container {
    padding: 15px 0;

    .grades,
    .subjects {
      display: flex;
      align-content: center;
      margin-bottom: 10px;
      padding-top: 10px;
      box-shadow: 1px 1px 2px 2px #f9fbfb;

      .label {
        width: 100px;
      }

      .grade-content,
      .subject-content {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        .grade-item,
        .subject-item {
          margin: 0 15px 15px 0;
          border-radius: 5px;
          background-color: #67c23a;
          padding: 4px 6px;
          color: #fff;
          cursor: pointer;

          &.current {
            background-color: #14d089;
          }
        }
      }
    }
  }

  .info-button {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
