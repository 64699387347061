<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="50%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="200px"
      class="demo-ruleForm"
      status-icon
      label-position="right"
    >
      <el-form-item
        :label-width="labelWidth"
        label="名称"
        prop="PJCYDXMC"
        class="el-item-input"
      >
        <el-input
          style="max-width: 217px"
          v-model="formData.PJCYDXMC"
        ></el-input>
      </el-form-item>

      <!-- 参评人 -->
      <el-form-item
        label="参评人"
        :label-width="labelWidth"
        class="el-item"
        prop="HDCPRname"
      >
        <el-input
          v-model="formData.HDCPRname"
          readonly
          style="max-width: 217px"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-show="formData.HDCPR === 1"
        label="范围选择"
        :label-width="labelWidth"
        class="el-item"
        prop="PJCYDXLB"
      >
        <el-select v-model="formData.PJCYDXLB" @change="CPRchange" placeholder>
          <el-option
            v-for="item in baseInfo.EvaluationObjectJoinType"
            v-show="
              (formData.HDCPR === 2 && item.Key === 3) ||
                (formData.HDCPR === 1 && item.Key !== 3)
            "
            :key="item.Key"
            :disabled="handle_FW_disabled(item)"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="formData.HDCPR === 1 && payLoad.currentActivity.HDLX === 1"
        label="限制条件"
        :label-width="labelWidth"
        class="el-item"
        prop="XZCSLX"
      >
        <el-checkbox
          name="noLimit"
          @change="XZCSLXchange"
          v-model="XZCSLXOption.noLimit.state"
          >不限制</el-checkbox
        >
        <el-checkbox
          name="weekLimit"
          @change="XZCSLXchange"
          v-model="XZCSLXOption.weekLimit.state"
          >按周限评价次数</el-checkbox
        >
        <el-checkbox
          name="QXlimit"
          @change="XZCSLXchange"
          v-model="XZCSLXOption.QXlimit.state"
          >按期限评价次数</el-checkbox
        >
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        prop="PJCYDXGLLXList"
        class="el-item"
      >
        <template>
          <!-- 按学科参与时显示 -->
          <div v-if="formData.HDCPR === 1 && formData.PJCYDXLB === 1">
            <el-checkbox-group v-model="formData.PJCYDXGLLXList">
              <el-checkbox
                v-for="(item, index) in baseInfo.CourseList.filter(
                  c => c.MultiSchool == multischool || multischool == 0
                )"
                :key="item.Id"
                :label="item.Id"
                >{{ item.Name }}
                <el-input
                  v-show="payLoad.currentActivity.HDLX === 1"
                  type="number"
                  class="checkbox-input"
                  v-model.number="item.frequency"
                  @change.native="
                    handleEvaluationNumber(
                      $event,
                      'CourseList',
                      index,
                      'frequency'
                    )
                  "
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 按角色参与时显示 -->
          <div v-if="formData.HDCPR === 1 && formData.PJCYDXLB === 2">
            <el-checkbox-group v-model="formData.PJCYDXGLLXList">
              <el-checkbox
                v-for="(item, index) in baseInfo.RoleList"
                :key="item.Id"
                :label="item.Id"
                >{{ item.Name }}
                <el-input
                  v-show="payLoad.currentActivity.HDLX === 1"
                  type="number"
                  class="checkbox-input"
                  v-model.number="item.frequency"
                  @change.native="
                    handleEvaluationNumber(
                      $event,
                      'RoleList',
                      index,
                      'frequency'
                    )
                  "
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 按机构参与时显示 -->
          <div v-if="formData.HDCPR === 1 && formData.PJCYDXLB === 4">
            <el-checkbox-group
              v-model="formData.PJCYDXGLLXList"
              @change="jgChange"
            >
              <el-checkbox
                v-for="(item, index) in payLoad.currentActivity.HDCPR === 1 &&
                payLoad.currentActivity.BPJDXLX === 2 &&
                formData.PJCYDXLB === 4
                  ? jgOption1
                  : jgOption2"
                :key="item.Key"
                :label="item.Key"
                >{{ item.Name }}
                <el-input
                  v-show="payLoad.currentActivity.HDLX === 1"
                  type="number"
                  class="checkbox-input"
                  v-model.number="item.frequency"
                  @change.native="
                    handleEvaluationNumber($event, 'jigou', index, 'frequency')
                  "
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 按学生参与时显示 -->
          <div v-if="formData.HDCPR === 2">
            <el-checkbox-group v-model="formData.GradeOfObjectList">
              <el-checkbox
                v-for="item in schoolGrades.filter(item =>
                  gradeFilter.includes(item.SSXDLX)
                )"
                :key="item.Id"
                :label="item.Id"
                :disabled="disabledGradeCheckebox(item, 2)"
                >{{ item.Name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
      </el-form-item>

      <!-- 被评人 -->
      <el-form-item
        :label-width="labelWidth"
        label="被评人"
        prop="EvaluatedObjectStr"
        class="el-item-input"
      >
        <el-input
          v-model="formData.EvaluatedObjectStr"
          readonly
          style="max-width: 217px"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-show="formData.BPJDXLX === 2"
        label="范围选择"
        :label-width="labelWidth"
        class="el-item"
        prop="BPJDXLXselect"
      >
        <el-select
          v-model="formData.BPJDXLXselect"
          :disabled="formData.PJCYDXLB === 4"
          placeholder
        >
          <el-option
            v-for="item in baseInfo.EvaluationObjectJoinType"
            v-show="
              ((formData.BPJDXLX === 1 || formData.BPJDXLX === 3) &&
                item.Key === 3) ||
                (formData.BPJDXLX === 2 && item.Key !== 3)
            "
            :key="item.Key"
            :disabled="
              (payLoad.currentActivity.JZKBLX !== 3 &&
                payLoad.currentActivity.JZKBLX !== 2 &&
                item.Key === 1) ||
                (payLoad.currentActivity.JZKBLX === 2 &&
                  (item.Key === 2 || item.Key === 4)) ||
                (payLoad.currentActivity.BPJDXLX === 2 &&
                  payLoad.currentActivity.HDCPR === 2 &&
                  item.Key === 4)
            "
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="formData.BPJDXLX"
        :label-width="labelWidth"
        :label="payLoad.currentActivity.HDLX === 2 ? '限制条件' : ''"
        prop="GradeOfObjectList"
        class="el-item"
      >
        <el-checkbox
          v-if="payLoad.currentActivity.HDLX === 2"
          name="singleLimit"
          disabled
          v-model="XZCSLXOption.singleLimit.state"
          >单次评价限制</el-checkbox
        >
      </el-form-item>
      <el-form-item
        label="参与年级"
        :label-width="labelWidth"
        class="el-item"
        v-show="formData.BPJDXLX === 3 || formData.BPJDXLX === 1"
      >
        <el-checkbox-group v-model="formData.GradeOfObjectList">
          <el-checkbox
            v-for="item in schoolGrades.filter(item =>
              gradeFilter.includes(item.SSXDLX)
            )"
            :key="item.Id"
            :label="item.Id"
            :disabled="disabledGradeCheckebox(item, 1)"
            >{{ item.Name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="参与学科"
        :label-width="labelWidth"
        class="el-item"
        v-if="formData.BPJDXLXselect === 1 && formData.BPJDXLX === 2"
      >
        <el-checkbox-group v-model="BPRrangeCheckbox">
          <el-checkbox
            v-for="item in baseInfo.CourseList.filter(
              c => c.MultiSchool == multischool || multischool == 0
            )"
            :key="item.Id"
            :label="item.Id"
            >{{ item.Name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="参与角色"
        :label-width="labelWidth"
        class="el-item"
        v-if="formData.BPJDXLXselect === 2 && formData.BPJDXLX === 2"
      >
        <el-checkbox-group v-model="BPRrangeCheckbox">
          <el-checkbox
            v-for="item in baseInfo.RoleList"
            :key="item.Id"
            :label="item.Id"
            >{{ item.Name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="参与机构"
        :label-width="labelWidth"
        class="el-item"
        v-if="formData.BPJDXLXselect === 4 && formData.BPJDXLX === 2"
      >
        <el-checkbox-group v-model="BPRrangeCheckbox">
          <el-checkbox
            v-for="item in jgOption2"
            :key="item.Key"
            :label="item.Key"
            >{{ item.Name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="编码"
        class="el-item"
        :label-width="labelWidth"
        prop="PJCYDXBM"
      >
        <el-input style="width:217px" v-model="formData.PJCYDXBM" />
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="状态"
        prop="SYZT"
        class="el-item"
      >
        <el-select v-model="formData.SYZT" placeholder>
          <el-option
            v-for="item in baseInfo.SYZT"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { pinyin } from 'pinyin-pro'
import { handle_SSXD } from '@/utils/common'
var checkEvaluationObjectJoinType = function(rule, value, callback) {
  if (value.length === 0 && rule.joinType !== 3) {
    return callback(new Error(rule.message))
  } else return callback()
}
export default {
  data() {
    let valid_HDBM = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9]+$/ //英文字符
      if (!value) callback(new Error('编码不能为空'))
      else if (!reg.test(value)) callback(new Error('请输入英文或者数字'))
      else if (value.length > 10) callback(new Error('编码不能超过10个字符'))
      else callback()
    }
    return {
      gradeList: [],
      showMultischool: true,
      gradeFilter: [],
      multischool: 0,
      title: '新增对象',
      labelWidth: '120px',
      formData: {
        SYZT: 1,
        GradeOfObjectList: [],
        PJCYDXGLLXList: [],
        XZCSLX: 0,
        BPJDXLXselect: null //被评人的范围选择
      },
      dialogVisiable: false,
      ruleEvaluationObjectJoinTypeList: [
        {
          validator: checkEvaluationObjectJoinType,
          message: '请选择主评人员',
          trigger: 'change',
          type: 'Array',
          joinType: 3
        }
      ],
      rulePJCYDXLB: [
        { required: true, message: '请选择主评人员', trigger: 'change' }
      ],
      rules: {
        HDXS: [
          { required: true, message: '请选择对象形式', trigger: 'change' }
        ],
        PJCYDXMC: [
          { required: true, message: '请输入对象名称', trigger: 'change' },
          { min: 1, max: 200, message: '长度不超过200个字', trigger: 'change' }
        ],
        PJFS: [
          { required: true, message: '请选择评价方式', trigger: 'change' }
        ],
        // PJCYDXLB: [
        //   { required: true, message: "请选择主评人员", trigger: "change" }
        // ],
        SYZT: [{ required: true, message: '请选择状态', trigger: 'change' }],
        PJCYDXBM: [
          { required: true, message: '编码不能为空', trigger: 'blur' },
          { validator: valid_HDBM, trigger: 'blur' }
        ]
        // EvaluationObjectJoinTypeList: [
        //   {
        //     validator: checkEvaluationObjectJoinType,
        //     message: "请选择主评人员",
        //     trigger: "change",
        //     type: "Array",
        //     joinType: 3
        //   }
        // ]
      },
      // 限制次数辅助数据
      XZCSLXOption: {
        noLimit: {
          value: 0,
          state: false
        },
        singleLimit: {
          value: 1,
          state: false
        },
        QXlimit: {
          value: 2,
          state: false,
          frequency: 0
        },
        weekLimit: {
          value: 3,
          state: false,
          frequency: 0
        }
      },
      //班级内互评选项
      inGradeEvaluateOption: [
        {
          label: '班级内互评',
          value: 1
        },
        {
          label: '班级内小组互评',
          value: 2
        }
      ],
      // 被评人范围选择多选框
      BPRrangeCheckbox: [],
      jgOption2: [],
      jgOption1: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: function() {
        return {}
      }
    },
    baseInfo: {
      type: Object,
      required: true
    },
    jgOption: Array,
    schoolGrades: Array
  },
  inject: ['payLoad'],
  methods: {
    initForm() {
      this.handleGradeBy_SSXD()
      this.title = '新增对象'

      Object.assign(this.formData, {
        GradeOfObjectList: [],
        EvaluationObjectJoinTypeList: []
      })

      if (this.form && (this.form.Id || this.form.Id > 0)) {
        if (
          this.form.XZCSLX !== undefined &&
          this.form.XZCSLX !== null &&
          this.form.XZCSLX !== ''
        ) {
          //回显限制条件
          Object.keys(this.XZCSLXOption).forEach(key => {
            if (this.XZCSLXOption[key].value === this.form.XZCSLX) {
              this.XZCSLXOption[key].state = true
            } else this.XZCSLXOption[key].state = false
          })
        }
        Object.assign(
          this.formData,
          JSON.parse(JSON.stringify(this.form)) || {}
        )
        // 回显年级
        this.formData.GradeOfObjectList = this.form.GradeOfObjectList.reduce(
          (pre, number) => {
            let grade = this.schoolGrades.find(
              grade => grade.GradeNumber === number
            )
            if (grade) pre.push(grade.Id)
            return pre
          },
          []
        )
        this.title = '编辑对象'
      }
      // 阶段活动只能强制单次评价  过程活动只能选下面的三种评价限制中的一种
      if (this.payLoad.currentActivity.HDLX === 2)
        this.XZCSLXOption.singleLimit.state = true
      else this.XZCSLXOption.singleLimit.state = false

      if (this.formData.HDXS && this.formData.HDXS === 0) {
        this.$delete(this.formData, 'HDXS')
      }

      if (this.form && this.form.PJCYDXGLLXList) {
        let arr = []
        this.form.PJCYDXGLLXList.map(item => {
          if (item.GLLX === 4 && this.payLoad.currentActivity.BPJDXLX === 2) {
            //机构参与 被评人为老师
            //机构
            if (item.JGPJLX === 1) {
              // 组长评组员
              this.jgOption1.forEach(it => {
                if (it.Key.includes(`${item.GLId + '组长'}`)) {
                  it.frequency = item.PJXZCS
                  arr.push(it.Key)
                }
              })
            } else if (item.JGPJLX === 2) {
              // 组员评组长
              this.jgOption1.forEach(it => {
                if (it.Key.includes(`${item.GLId + '组员'}`)) {
                  it.frequency = item.PJXZCS
                  arr.push(it.Key)
                }
              })
            } else arr.push(item.GLId)
          } else {
            if (item.PJLX === 1) {
              // PJLX  1.评价人 2.被评人
              // GLLX 1.学科 2.角色 3.机构
              arr.push(item.GLId)
              if (item.GLLX === 1) {
                this.baseInfo.CourseList.forEach(xk => {
                  if (xk.Id === item.GLId) xk.frequency = item.PJXZCS
                })
              }
              if (item.GLLX === 2) {
                this.baseInfo.RoleList.forEach(role => {
                  if (role.Id === item.GLId) role.frequency = item.PJXZCS
                })
              }
              if (item.GLLX === 4) {
                this.jgOption2.forEach(jg => {
                  if (jg.Key === item.GLId) jg.frequency = item.PJXZCS
                })
              }
            }
          }
        })
        this.formData.PJCYDXGLLXList = arr
      }
    },
    // closeDialog() {
    //   this.$emit("closeDialog");
    // },
    closeDialog() {
      this.$confirm('确认关闭？').then(() => {
        this.$emit('closeDialog')
      })
      // this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "保存",
      //   cancelButtonText: "放弃修改"
      // })
      //   .then(() => {
      //     //执行提交方法,执行成功后回调函数执行以下代码
      //     this.confirmDialog()
      //   })
      //   .catch(action => {
      //     if (action === "cancel") {
      //       this.$emit("closeDialog");
      //     }
      //   });
    },
    // 处理关联类型
    handleGLLX() {
      let GLLXListArr = []
      let GLkey1 = ''
      let GLkey2 = ''
      let valueKey1 = ''
      let valueKey2 = ''
      switch (this.formData.PJCYDXLB) {
        case 1:
          GLkey1 = 'CourseList'
          valueKey1 = 'Id'
          break
        case 2:
          GLkey1 = 'RoleList'
          valueKey1 = 'Id'
          break
      }
      switch (this.formData.BPJDXLXselect) {
        case 1:
          GLkey2 = 'CourseList'
          valueKey2 = 'Id'
          break
        case 2:
          GLkey2 = 'RoleList'
          valueKey2 = 'Id'
          break
      }
      //参评人关联
      // 如果参评人范围为机构
      if (
        this.formData.PJCYDXLB === 4 &&
        (this.formData.BPJDXLX === 1 || this.formData.BPJDXLX === 3)
      ) {
        console.log(1, '师机构 评生 评班级')
        // 师评生
        let jg = []
        if (this.formData.BPJDXLX === 3) {
          console.log(111, '1111')
          if (
            this.payLoad.currentActivity.HDCPR === 1 &&
            this.payLoad.currentActivity.BPJDXLX === 2 &&
            this.formData.PJCYDXLB === 4
          ) {
            console.log(3333, '3333')
            this.jgOption1.forEach(item => {
              this.formData.PJCYDXGLLXList.forEach(
                key => key === item.Key && jg.push(item)
              )
            })
          } else {
            console.log(5555, '555')
            this.jgOption2.forEach(item => {
              this.formData.PJCYDXGLLXList.forEach(key => {
                console.log(key, item, '666')
                if (key === item.Key) {
                  jg.push(item)
                }
              })
            })
          }
        } else {
          console.log(222, '2222')
          this.jgOption.forEach(item => {
            this.formData.PJCYDXGLLXList.forEach(
              key => key === item.key && jg.push(item)
            )
          })
        }
        jg.forEach(jg => {
          GLLXListArr.push({
            GLID: jg.key || jg.Key, //自己id
            GLLX: 4, //学科 角色 机构
            PJLX: 1, //评价人或被评人
            PJXZCS: jg.frequency //后面填的次数
          })
        })
      } else if (this.formData.PJCYDXLB === 4 && this.formData.BPJDXLX === 2) {
        // 师评师 机构
        console.log(22, '师评师 机构')
        let jg = this.jgOption1.find(
          item => item.Key === this.formData.PJCYDXGLLXList[0]
        )
        console.log(jg, 'jg')
        try {
          if (!jg) return false
        } catch (err) {
          //
        }
        GLLXListArr.push({
          GLID: jg.Id, //自己id
          GLLX: 4, //学科角色机构
          PJLX: 1, //评价人或被评人
          JGPJLX: jg.type, //组员评组长反之
          PJXZCS: jg.frequency //后面填的次数
        })
      } else if (
        this.formData.PJCYDXLB === 2 &&
        this.formData.BPJDXLX === 2 &&
        this.formData.BPJDXLXselect === 4
      ) {
        console.log(333, '师评师 角色评机构')
        // 参评老师角色参与 被评老师机构参与
        GLkey1 &&
          this.formData.PJCYDXGLLXList.forEach(item => {
            this.baseInfo[GLkey1].forEach(el => {
              if (item === el[valueKey1]) {
                GLLXListArr.push({
                  GLID: item, //自己id
                  GLLX: this.formData.PJCYDXLB, //学科角色机构
                  PJLX: 1, //评价人或被评人
                  PJXZCS: el.frequency //后面填的次数
                })
              }
            })
          })
        this.BPRrangeCheckbox.forEach(item => {
          this.jgOption2.forEach(jg => {
            if (jg.Id === item) {
              GLLXListArr.push({
                GLID: jg.Id, //自己id
                GLLX: 4, //学科角色机构
                PJLX: 2, //评价人或被评人
                JGPJLX: 0, //组员评组长反之
                PJXZCS: jg.frequency //后面填的次数
              })
            }
          })
        })
      } else {
        console.log(this.formData.PJCYDXGLLXList, GLkey1, 'asdfs')
        GLkey1 &&
          this.formData.PJCYDXGLLXList.forEach(item => {
            this.baseInfo[GLkey1].forEach(el => {
              if (item === el[valueKey1]) {
                GLLXListArr.push({
                  GLID: item, //自己id
                  GLLX: this.formData.PJCYDXLB, //学科角色机构
                  PJLX: 1, //评价人或被评人
                  PJXZCS: el.frequency //后面填的次数
                })
              }
            })
          })
        console.log(GLkey2, 'GLkey2')
        // 被评人关联
        GLkey2 &&
          this.BPRrangeCheckbox.forEach(item => {
            this.baseInfo[GLkey2].forEach(el => {
              if (item === el[valueKey2]) {
                GLLXListArr.push({
                  GLID: item, //自己id
                  GLLX: this.formData.BPJDXLXselect, //学科角色机构
                  PJLX: 2 //评价人或被评人
                })
              }
            })
          })
      }
      return GLLXListArr
    },
    confirmDialog() {
      const data = JSON.parse(JSON.stringify(this.formData))
      // cpr 1老师 2学生  bpr 1学生 2老师 3班级
      if (
        (this.payLoad.currentActivity.BPJDXLX === 1 ||
          this.payLoad.currentActivity.BPJDXLX === 3 ||
          this.payLoad.currentActivity.HDCPR === 2) &&
        (!this.formData.GradeOfObjectList ||
          this.formData.GradeOfObjectList.length === 0)
      )
        return this.$message.error('年级不能为空')
      if (!this.formData.PJCYDXBM) {
        let letterArr = []
        try {
          letterArr = pinyin(this.formData.PJCYDXMC, {
            toneType: 'none',
            pattern: 'first',
            type: 'array'
          })
          if (letterArr) {
            let letter = ''
            letterArr.forEach(it => (letter += it.toUpperCase()))
            this.$set(this.formData, 'PJCYDXBM', letter)
            data.PJCYDXBM = letter
          }
        } catch (err) {
          //
        }
      }
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.$confirm('确认提交？').then(r => {
            // 执行提交方法
            const joinType = this.baseInfo.EvaluationObjectJoinType.find(
              c => c.Key === data.PJCYDXLB
            )
            if (joinType && joinType.Value) {
              data.PJCYDXLBStr = joinType.Value
            }
            const evaluationMethod = this.baseInfo.EvaluationMethod.find(
              c => c.Key === data.PJFS
            )
            if (evaluationMethod && evaluationMethod.Value) {
              data.PJFSStr = evaluationMethod.Value
            }
            data.GradeOfObjectList = data.GradeOfObjectList.map(Id => {
              let grade = this.schoolGrades.find(grade => grade.Id === Id)
              if (grade) return grade.GradeNumber
            })
            data.GradeOfObject = data.GradeOfObjectList.length > 0 ? '' : '无'
            data.GradeOfObjectList.forEach(GradeNumber => {
              let grade = this.schoolGrades.find(
                c => c.GradeNumber === GradeNumber
              )
              data.GradeOfObject += grade ? grade.Name : ''
            })
            data.SYZTStr = data.SYZT === 1 ? '正常' : '停用'
            // data.GradeOfObjectListStr = JSON.stringify(data.GradeOfObjectList); //好像没地方使用到

            // 处理参/被评对象关联类型
            data.PJCYDXGLLXList = this.handleGLLX()
            console.log(data, 'PJCYDXGLLXList')
            try {
              // cpr 1老师 2学生  bpr 1学生 2老师 3班级

              // 老师评学生 此情况只能选学科或者角色
              if (
                this.payLoad.currentActivity.HDCPR === 1 &&
                this.payLoad.currentActivity.BPJDXLX === 1 &&
                (!data.PJCYDXGLLXList || data.PJCYDXGLLXList.length === 0)
              )
                return this.$message.error(
                  `请选择${
                    this.formData.PJCYDXLB
                      ? this.formData.PJCYDXLB === 2
                        ? '角色'
                        : '机构'
                      : '参评人范围'
                  }`
                )

              // 学生评老师 老师只能角色参与
              if (
                this.payLoad.currentActivity.HDCPR === 2 &&
                this.payLoad.currentActivity.BPJDXLX === 2 &&
                (!data.PJCYDXGLLXList || data.PJCYDXGLLXList.length === 0)
              )
                return this.$message.error(`请选择角色`)

              //老师评老师 老师只能角色或机构参与
              if (
                this.payLoad.currentActivity.HDCPR === 1 &&
                this.payLoad.currentActivity.BPJDXLX === 2
              ) {
                if (!this.formData.PJCYDXLB)
                  return this.$message.error('请选择参评人范围')
                if (
                  data.PJCYDXGLLXList &&
                  this.formData.PJCYDXLB === 2 &&
                  !this.formData.BPJDXLXselect
                )
                  return this.$message.error('请选择被评人人范围')

                // 参老师角色参与
                if (this.formData.PJCYDXLB === 2) {
                  if (
                    !this.formData.PJCYDXGLLXList ||
                    this.formData.PJCYDXGLLXList.length === 0
                  )
                    return this.$message.error('请选择参评人角色')

                  // 被评老师角色参与
                  if (this.formData.BPJDXLXselect === 2) {
                    if (
                      !this.BPRrangeCheckbox ||
                      this.BPRrangeCheckbox.length === 0
                    )
                      return this.$message.error('请选择被评人角色')
                  }
                  // 被评老师机构参与
                  if (this.formData.BPJDXLXselect === 4) {
                    if (
                      !this.BPRrangeCheckbox ||
                      this.BPRrangeCheckbox.length === 0
                    )
                      return this.$message.error('请选择被评人机构')
                  }
                }
              }

              // 师评师 机构
              if (!data.PJCYDXGLLXList) return this.$message.error('请选择机构')
            } catch (err) {
              //
            }
            // 对象的参评人是老师 对象的PJCYDXLB就以参评人的评价对象类别PJCYDXLB为准 如果对象的参评人是学生 就以被评老师的PJCYDXLB为准
            //  cpr 1老师 2学生  bpr 1学生 2老师 3班级
            if (
              this.payLoad.currentActivity.HDCPR === 2 &&
              this.payLoad.currentActivity.BPJDXLX === 2
            ) {
              data.PJCYDXLB = this.formData.BPJDXLXselect
            }

            this.$emit('confirmDialog', data)
          })
          // .catch(r => {});
        } else {
          return false
        }
      })
    },
    // 判断学段年级
    handleGradeBy_SSXD() {
      let SSXD = this.payLoad.currentActivity.SSXD[0]
      this.gradeFilter = handle_SSXD(SSXD)
    },
    // 限制次数辅助数据变化
    XZCSLXchange(val, e) {
      if (val) {
        Object.keys(this.XZCSLXOption).forEach(key => {
          if (key !== e.target.name) {
            this.XZCSLXOption[key].state = false
          } else {
            this.formData.XZCSLX = this.XZCSLXOption[key].value
          }
        })
      }
    },
    // 处理当前活动
    handleCurrentActivity() {
      let HDCPRname = ''
      switch (this.payLoad.currentActivity.HDCPR) {
        case 1:
          HDCPRname = '老师'
          break
        case 2:
          HDCPRname = '学生'
          break
        default:
          HDCPRname = '无'
      }
      let {
        BPJDXLX, //被评人1.学生 2.老师
        HDCPR,
        EvaluatedObjectStr,
        PJFS,
        SSXX,
        SFSC,
        // HDXS,
        PJFSStr
      } = this.payLoad.currentActivity

      if (this.form.PJCYDXGLLXList) {
        let arr = []
        this.form.PJCYDXGLLXList.forEach(item => {
          if (item.PJLX === 2) arr.push(item.GLId)
        })
        this.BPRrangeCheckbox = arr
      }

      this.formData = {
        ...this.formData,
        BPJDXLX,
        BPJDXLXselect:
          BPJDXLX === 2 && this.formData.PJCYDXLB === 4
            ? ''
            : this.form.PJCYDXGLLXList
            ? this.form.PJCYDXGLLXList.find(item => item.PJLX === 2)?.GLLX
            : '',
        HDCPR,
        EvaluatedObjectStr,
        PJFS,
        SSXX,
        SFSC,
        HDXS: 4,
        PJFSStr,
        HDCPRname
      }
    },
    // 禁用年级多选框
    disabledGradeCheckebox(item, type) {
      let arr = JSON.parse(
        JSON.stringify(this.formData.GradeOfObjectList)
      ).sort((a, b) => a - b)
      let grade = this.schoolGrades.find(grade => grade.Id === arr[0])
      if (grade && item.SSXDLX !== grade.SSXDLX) return true
    },
    // 处理参评人的评价次数
    handleEvaluationNumber(e, type, idx, key) {
      let reg = /^(0|[1-9]([0-9]{1,3})?)$/
      // 主评
      if (this.payLoad.type === 4) {
        if (!reg.test(e.target.value)) {
          this.baseInfo[type][idx][key] = 0
        }
      }
      // 自评
      if (this.payLoad.type === 1) {
        if (!this.XZCSLXOption.noLimit.state) {
          if (!reg.test(e.target.value)) {
            this.XZCSLXOption[type].frequency = 0
          }
        }
      }
    },
    // 参评人变化
    CPRchange(val) {
      if (val === 4) {
        this.formData.BPJDXLXselect = ''
      }
    },
    // 机构变化
    jgChange(val) {
      if (this.payLoad.currentActivity.BPJDXLX === 2) {
        this.formData.PJCYDXGLLXList = [val[val.length - 1]]
      }
    },
    // 处理机构options
    handleJGoption() {
      // let { HDCPR, BPJDXLX } = this.payLoad.currentActivity;
      // 老师评老师 机构只出现在参评人那边 且只能选择一个勾选框 例如：选择了年级组长评组员 那么被评人默认为年级组员
      let arr1 = []
      let arr2 = []
      this.jgOption.forEach(item => {
        arr1 = [
          ...arr1,
          ...[
            {
              Key: item.key + '组长',
              Id: item.key,
              Name: `${item.name}组长评组员`,
              type: 1,
              frequency: 0
            },
            {
              Key: item.key + '组员',
              Id: item.key,
              Name: `${item.name}组员评组长`,
              type: 2,
              frequency: 0
            }
          ]
        ]
        arr2 = [
          ...arr2,
          ...[
            {
              Key: item.key,
              Id: item.key,
              Name: `${item.name}`,
              type: 1,
              frequency: 0
            }
          ]
        ]
      })
      this.jgOption1 = arr1
      this.jgOption2 = arr2
    },
    // 处理参与方式禁用启用
    handle_FW_disabled(item) {
      // 活动是评价人课表 禁用角色和机构参与
      if (
        this.payLoad.currentActivity.JZKBLX === 3 &&
        (item.Key === 2 || item.Key === 4)
      ) {
        return true
      }

      // 活动是无课表 禁用学科参与
      if (this.payLoad.currentActivity.JZKBLX !== 3 && item.Key === 1) {
        return true
      }

      //  参评人为老师 被评人为学生 禁用机构参与
      if (
        this.payLoad.currentActivity.BPJDXLX === 1 &&
        this.payLoad.currentActivity.HDCPR === 1 &&
        item.Key === 4
      ) {
        return true
      }
    }
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        this.handleJGoption()
        this.initForm()
        this.handleCurrentActivity()
        console.log(this.formData, '编辑')
      } else {
        // 重置校验结果
        this.$refs.formData.resetFields()
        this.formData = {
          SYZT: 1,
          GradeOfObjectList: [],
          PJCYDXGLLXList: [],
          XZCSLX: null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-item {
  max-width: 600px;
  margin-left: 5%;
}
.el-item-input {
  max-width: 500px;
  margin-left: 5%;
}
.checkbox-input {
  width: 40px;
  height: 22px;
  ::v-deep .el-input__inner {
    padding: 0 !important;
    line-height: 22px;
    height: 100%;
    width: 100%;
  }
}
</style>
