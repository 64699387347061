<template>
  <!-- 图片管理 -->
  <div class="image-manage">
    <!-- 筛选框组 -->
    <div class="filter-btns">
      <div class="filter-item">
        <div class="label">年级：</div>
        <el-select
          size="small"
          v-model="filter.gradeId"
          placeholder="请选择"
          @change="clickGrade"
        >
          <el-option
            v-for="item in gradeOption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-item">
        <div class="label">班级：</div>
        <el-select
          size="small"
          v-model="filter.classId"
          placeholder="请选择"
          @change="clickClass"
        >
          <el-option
            v-for="item in classOption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-item">
        <div class="label">活动：</div>
        <el-select
          size="small"
          v-model="filter.activityId"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in activityOption"
            :key="item.Id"
            :label="item.PJHDMC"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-item">
        <el-input
          size="small"
          v-model="filter.searchStr"
          placeholder="请选择学生"
          clearable
          @focus="clickSearchStr"
        ></el-input>
      </div>
      <el-button size="small" type="primary" @click="handleSearch"
        >搜索</el-button
      >
    </div>
    <!-- 是否展示 -->
    <div class="filter-btns2">
      <el-button
        size="small"
        :type="filter.show === 1 ? 'primary' : 'text'"
        @click="filterShowChange(1)"
        >已展示</el-button
      >
      <el-button
        size="small"
        :type="filter.show === 2 ? 'primary' : 'text'"
        @click="filterShowChange(2)"
        >未展示</el-button
      >
      <span>（鼠标右键单击图片进行图片删除）</span>
    </div>
    <!-- 全部展示 -->
    <div class="show-all">
      <el-button
        type="primary"
        size="small"
        :disabled="filter.show === 1 && images && images.length > 0"
        @click="checkAll"
        >全部展示</el-button
      >
    </div>
    <!-- 图片 -->
    <div class="main">
      <div class="images-block">
        <div
          class="item"
          v-for="image in images"
          :key="image.Id"
          @contextmenu.prevent="rightClick(image)"
        >
          <el-image
            v-if="image.FJXHGG === 1"
            :src="image.FJLJ"
            :preview-src-list="images.map(el => el.FJLJ)"
          >
          </el-image>
          <!-- <video v-if="image.FJXHGG === 2" :src="image.FJLJ" controls></video> -->
          <div class="text">
            <div class="create-time">{{ image.CreateTime }}</div>
            <div
              class="check"
              :class="{ uncheck: image.SHZT === 2 }"
              @click="checkImage([image.Id])"
            >
              {{ image.SHZT === 2 || !image.SHZT ? '展示' : '隐藏' }}
            </div>
          </div>
        </div>
      </div>
      <Pager :pagerInfo="pagerInfo" @changeIndex="handleCurrentChange" />
    </div>

    <!-- 学生选择弹窗 -->
    <el-dialog
      title="学生选择"
      :visible.sync="studentSelectObj.show"
      width="90%"
    >
      <div class="students-fitler-block">
        <div class="students-fitler-search">
          <el-input
            style="width:200px"
            v-model="studentSelectObj.searchStr"
            placeholder="请输入学生名称"
          ></el-input>
          <el-button @click="selectStudentSearch">搜索</el-button>
        </div>
        <div class="students-filter-btns">
          <div class="grade">
            <div
              class="grade-item"
              :class="{ current: studentSelectObj.grade.Id === grade.Id }"
              v-for="grade in gradeOption2"
              :key="grade.Id"
              @click="selectStudentGradeClick(grade)"
            >
              {{ grade.Name }}
            </div>
          </div>
          <div class="class">
            <div
              class="class-item"
              :class="{
                current: studentSelectObj.classObj.Id === classItem.Id
              }"
              v-for="classItem in classOption2"
              :key="classItem.Id"
              @click="selectStudentClassClick(classItem)"
            >
              {{ classItem.Name }}
            </div>
          </div>
          <div class="name">
            <div
              class="student-item"
              :class="{ current: studentSelectObj.student.Id === student.Id }"
              v-for="student in students2"
              :key="student.Id"
              @click="selectStudentClick(student)"
            >
              {{ student.Name }}
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="studentSelectObj.show = false">取 消</el-button>
        <el-button type="primary" @click="studentSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pager from '@/components/Pager'

export default {
  name: 'ImageManage',
  components: {
    Pager
  },
  data() {
    return {
      userInfo: {},
      term: {},
      filter: {
        gradeId: '0',
        classId: '0',
        activityId: '0',
        searchStr: '',
        searchId: '',
        show: 1
      },
      gradeOption: [],
      classOption: [],
      activityOption: [
        {
          label: '全部',
          value: '0'
        },
        {
          label: '家校共育',
          value: '1'
        }
      ],
      schoolStudents: [],
      schoolGrade: [],
      studentSelectObj: {
        show: false,
        searchStr: '',
        grade: {},
        classObj: {},
        student: {}
      },
      gradeOption2: [],
      classOption2: [],
      students2: [],
      images: [],
      pagerInfo: {
        PageIndex: 1,
        PageSize: 32,
        totalCount: 0
      }
    }
  },
  async mounted() {
    let userInfo = localStorage.getItem('userinfo')
    if (userInfo) this.userInfo = JSON.parse(userInfo)
    let term = localStorage.getItem('curTerm')
    if (term) this.term = JSON.parse(term)

    await this.getSchoolStudents()
    await this.getSchoolGrade()
    this.handleSchoolGrade()
    await this.get_JXGY()
    await this.getActivities()
    await this.getImages()
  },
  methods: {
    // 获取学校活动
    getActivities() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/evaluationactivity/GetEvaluationActivityList',
          {
            SchoolId: this.userInfo.SSZZId,
            pagerInfo: { pageIndex: 1, pageSize: 999 },
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              // 学生参与的活动 主评 自评 互评 且开展时间在当前学期时间内
              let selfActivity = res.Target?.filter(item => {
                // 学生评老师 只有主评
                if (item.HDCPR === 2 && item.BPJDXLX === 2) return true
                // 老师评学生 并且有自评互评
                if (item.HDCPR === 1 && item.BPJDXLX === 1 && item.HDXS !== 4)
                  return true
                else return false
              })
              selfActivity.sort((a, b) => a.PJHDMC.localeCompare(b.PJHDMC))
              this.activityOption = [
                { PJHDMC: '全部', Id: '0' },
                ...(selfActivity || []),
                ...this.JXGY_list
              ]
              resolve()
            } else reject()
          }
        )
      })
    },
    // 获取家校共育
    get_JXGY() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/extracurricular/GetJXGYList',
          {
            SSXQ: this.term.Id,
            SSXX: this.userInfo.SSZZId,
            PagerInfo: {
              pageIndex: 0,
              pageSize: 1000
            },
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              let termStart = new Date(this.term.BeginTime)
              let termEnd = new Date(this.term.EndTime)
              this.JXGY_list = (res.Target || []).filter(item => {
                item.PJHDMC = item.JHMC
                return (
                  (new Date(item.KSRQ) >= termStart &&
                    new Date(item.KSRQ) <= termEnd) ||
                  (new Date(item.JFRQ) >= termStart &&
                    new Date(item.JFRQ) <= termEnd)
                )
              })

              this.JXGY_list.sort((a, b) => a.JHMC.localeCompare(b.JHMC))

              resolve()
            }
          }
        )
      })
    },
    // 获取学校组织机构
    getSchoolGrade() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          {
            SchoolId: this.userInfo.SSZZId, //	是	string	学校Id
            IsDelete: 2, //	是	int	1.表示需要获取已删除和禁用数据
            // GradeId: "", //	是	string	根据年级Id获取年级和年级以下的组织机构（不能传学校Id不是优先获取是全校的住址机构）
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.schoolGrade = res.Target || []
              this.gradeOption2 = [
                { Name: '全部', Id: '0' },
                ...(res.Target || [])
              ]
              this.selectStudentGradeClick(this.gradeOption2[0])

              resolve()
            } else reject()
          }
        )
      })
    },
    // 获取学校学生
    getSchoolStudents() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetOrgStudent',
          {
            SchoolOrgId: this.userInfo.SSZZId, //	是	string	组织机构ID/活动Id
            OrgType: 0, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: '', //	是	string	学年
            SchoolId: this.userInfo.SSZZId, //	否	string	学校Id
            IsDelete: 2, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.schoolStudents = res.Target || []
              resolve()
            } else reject()
          }
        )
      })
    },
    // 处理年级
    handleSchoolGrade() {
      let obj = {
        Name: '全部',
        Id: '0'
      }
      this.gradeOption = [obj, ...this.schoolGrade]
      this.clickGrade(this.gradeOption[0].Id)
    },
    // 年级点击
    clickGrade(val) {
      let grade = this.gradeOption.find(item => item.Id === val)
      let obj = { Name: '全部', Id: '0' }
      this.classOption = [obj, ...(grade.ChidOrgList || [])]
      this.clickClass(this.classOption[0].Id)
    },
    // 班级点击
    clickClass(val) {
      console.log(val, 'val')
    },
    // 选择学生
    clickSearchStr() {
      this.studentSelectObj.show = true
    },
    // 弹窗学生搜索
    selectStudentSearch() {
      if (this.studentSelectObj.grade.Id === '0') {
        this.students2 = this.schoolStudents.filter(item =>
          item.Name.includes(this.studentSelectObj.searchStr)
        )
      } else {
        this.students2 = this.schoolStudents.filter(
          item =>
            item.ClassId === this.studentSelectObj.classObj.Id &&
            item.Name.includes(this.studentSelectObj.searchStr)
        )
      }

      if (this.students2 && this.students2.length > 0) {
        this.students2.sort((a, b) => a.Name.localeCompare(b.Name))
      }
    },
    // 弹框年级点击
    selectStudentGradeClick(grade) {
      this.classOption2 = []
      this.studentSelectObj.student = ''
      this.studentSelectObj.classObj = ''
      this.studentSelectObj.grade = grade

      if (grade.Id === '0') {
        this.students2 = this.schoolStudents
        if (this.students2 && this.students2.length > 0) {
          this.students2.sort((a, b) => a.Name.localeCompare(b.Name))
        }
      } else {
        this.classOption2 = grade.ChidOrgList
        this.students2 = []
      }
    },
    // 弹框班级点击
    selectStudentClassClick(classItem) {
      this.studentSelectObj.student = ''
      this.studentSelectObj.classObj = classItem
      this.students2 = this.schoolStudents.filter(
        item => item.ClassId === classItem.Id
      )
      if (this.students2.length > 0) {
        this.students2.sort((a, b) => a.Name.localeCompare(b.Name))
        if (this.studentSelectObj.searchStr) {
          this.students2 = this.students2.filter(item =>
            item.Name.includes(this.studentSelectObj.searchStr)
          )
        }
      }
    },
    // 弹框学生点击
    selectStudentClick(student) {
      this.studentSelectObj.student = student
    },
    // 选择学生确认
    studentSubmit() {
      if (!this.studentSelectObj.student)
        return this.$message.error('未选择学生')
      let student = this.studentSelectObj.student
      this.filter.gradeId = student.GradeId
      this.clickGrade(student.GradeId)
      this.filter.classId = student.ClassId
      this.clickClass(student.ClassId)
      this.filter.searchStr = student.Name
      this.filter.searchId = student.Id
      this.studentSelectObj.show = false
    },
    // 获取图片
    getImages() {
      let grade = this.gradeOption.find(item => item.Id === this.filter.gradeId)
      let activity = this.activityOption.find(
        item => item.Id === this.filter.activityId
      )

      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/xjzstp/GetImageList',
          {
            NJSZ: grade.Id === '0' ? 0 : grade.GradeNumber, //年级数字
            NJID: grade.Id === '0' ? '' : grade.Id, //年级ID
            BJID: this.filter.classId === '0' ? '' : this.filter.classId, //班级ID
            HDID: this.filter.activityId === '0' ? '' : this.filter.activityId, //活动ID
            HDLX: activity.Id === '0' ? 0 : activity.HDLX, //活动类型（1-过程性活动，2-阶段性活动，3-家校共育）
            XSID: this.filter.searchStr ? this.filter.searchId : '', //学生ID
            SSXN: this.term.SSXN, //所属学年
            XQLX: this.term.XQLX, //学期类型
            XQID: this.term.Id,
            SHZT: this.filter.show, //
            pagerInfo: this.pagerInfo,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              let arr = res.Target || []
              let images = arr.filter(item => item.FJXHGG === 1)
              let video = arr.filter(item => item.FJXHGG === 2)
              this.images = [...images, ...video]
              this.pagerInfo.totalCount = res.PagerInfo.RowCount
              resolve()
            } else reject()
          }
        )
      })
    },
    // 图片审核
    checkImage(ids) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/xjzstp/EditFJShzt',
          {
            Id: ids,
            SHZT: this.filter.show === 1 ? 2 : 1,
            SSXN: this.term.SSXN,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.getImages()
              resolve()
            } else reject()
          }
        )
      })
    },
    // 图片删除
    delImage(ids) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/xjzstp/EditFJSCZT',
          {
            Id: ids,
            SSXN: this.term.SSXN,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              resolve()
            } else reject()
          }
        )
      })
    },
    handleCurrentChange(val) {
      this.pagerInfo.PageIndex = val
      this.getImages()
    },
    // 展示切换
    filterShowChange(val) {
      this.pagerInfo.PageIndex = 1
      this.filter.show = val
      this.getImages()
    },
    // 展示当前全部
    checkAll() {
      this.$confirm('此操作将展示当前页所有图片和视频, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.checkImage(this.images.map(item => item.Id))
        })
        .catch(() => {
          //
        })
    },
    // 搜索
    handleSearch() {
      this.pagerInfo.PageIndex = 1
      this.getImages()
    },
    rightClick(image) {
      this.$confirm(
        `此处删除的${
          image.FJXHGG === 1 ? '图片' : '视频'
        }在家长端及教师端均会删除，你确定要删除吗？`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }
      )
        .then(async () => {
          await this.delImage([image.Id])
          this.getImages()
        })
        .catch(() => {
          //
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-manage {
  .filter-btns {
    display: flex;
    align-items: center;
    padding: 10px;
    .filter-item {
      margin-right: 15px;
      display: flex;
      align-items: center;
      .label {
        min-width: 40px;
        flex-shrink: 0;
        font-size: 14px;
      }
    }
  }
  .filter-btns2 {
    padding: 10px;
  }
  .show-all {
    padding: 10px;
  }
  .students-fitler-block {
    .students-fitler-search {
      padding-left: 15px;
    }
    .students-filter-btns {
      .grade,
      .class,
      .name {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        .grade-item,
        .class-item,
        .student-item {
          border: 1px solid green;
          border-radius: 5px;
          padding: 5px 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          &.current {
            border: none;
            background-color: green;
            color: #fff;
          }
        }
      }
      .name {
        max-height: 300px;
        overflow: scroll;
      }
    }
  }
  .main {
    margin-top: 10px;
    padding-left: 10px;
    .images-block {
      width: 100%;
      height: 500px;
      overflow-y: scroll;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .item {
        width: 150px;
        height: 100px;
        margin-right: 15px;
        margin-bottom: 15px;
        box-shadow: 1px 1px 2px 2px #dddcdc;
        .el-image {
          width: 100%;
          height: calc(100% - 20px);
        }
        video {
          width: 100%;
          height: calc(100% - 20px);
        }
        .text {
          display: flex;
          justify-content: space-between;
          .check {
            padding: 0 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
