<template>
  <div>
    <!-- 体检数据 -->
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <span class="table-er-buttspan" style="font-size:14px;">学期：</span>
              <el-select @change="loaddata" v-model="xqid" placeholder="请选择学期">
                <el-option v-for="xq in xqlist" :key="xq.Id" :label="xq.XQMC" :value="xq.Id"></el-option>
              </el-select>
              <span class="table-er-buttspan" style="font-size:14px;margin-left:10px;">年级：</span>
              <el-select @change="handleGradeChange" v-model="njid" placeholder="请选择年级">
                <el-option v-for="nj in njlist" :label="nj.Name" :key="nj.Id" :value="nj.Id">
                </el-option>
              </el-select>
              <span class="table-er-buttspan" style="font-size:14px;margin-left:10px;">班级：</span>
              <el-select @change="handleClassChange" style="margin-left: 10px;" v-model="bjid" placeholder="请选择班级">
                <el-option v-for="bj in bjlist" :label="bj.Name" :key="bj.Id" :value="bj.Id">
                </el-option>
              </el-select>
            </div>
            <div style="font-size: 14px;display: inline-block;margin-top:10px; ">
              <el-input style="width: 200px;" type="text" placeholder="输入学籍号或姓名" v-model="cxmc"
                maxlength="50"></el-input>
              <span style="color: #14d089; margin-left: 5px; cursor: pointer;" @click="loaddata()">搜索</span>
              <span style="color: #14d089;margin-left: 5px;cursor: pointer;" @click="downloadlist">下载全部</span>
              <span @click="handleImportOpen()" style="color: #14d089;margin-left: 5px;cursor: pointer;">上传数据</span>
              <span class="table-er-buttspan" style="font-size:14px;margin-left:10px;">共{{ datacount }}条数据</span>
            </div>
            <el-table :data="sjlist" highlight-current-row style="width: 100%;">
              <el-table-column fixed width="110" align="center" label="操作">
                <template slot-scope="scope">
                  <el-button @click="handleEdit(scope.row.XSId)" type="text" style="color: blue;">编辑</el-button>
                  <el-button @click="handleDelete(scope.row.XSId)" style="color: red;" type="text">删除</el-button>
                </template>
              </el-table-column>
              <el-table-column fixed align="center" label="班级" property="BJMC">
              </el-table-column>
              <el-table-column fixed align="center" label="学号" property="XJH">
              </el-table-column>
              <el-table-column fixed align="center" label="姓名" property="XSXM">
              </el-table-column>
              <el-table-column align="center" label="民族" property="MZ">
              </el-table-column>
              <el-table-column align="center" label="性别">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.XBM == '1'">
                      男
                    </span>
                    <span v-else>
                      女
                    </span>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column align="center" label="年龄" property="NL">
              </el-table-column> -->
              <el-table-column align="center" property="SG" label="身高">
              </el-table-column>
              <el-table-column align="center" property="TZ" label="体重">
              </el-table-column>
              <el-table-column align="center" property="FHL" label="肺活量">
              </el-table-column>
              <el-table-column align="center" label="收缩压" property="SSY">
              </el-table-column>
              <el-table-column align="center" label="舒张压" property="SZY">
              </el-table-column>
              <el-table-column align="center" label="血红蛋白" property="XHDB">
              </el-table-column>
              <el-table-column align="center" label="左眼视力" property="ZYSL">
              </el-table-column>
              <el-table-column align="center" label="右眼视力" property="YYSL">
              </el-table-column>
              <el-table-column align="center" label="结膜炎" property="JMY">
              </el-table-column>
              <el-table-column align="center" label="左眼沙眼" property="ZYSY">
              </el-table-column>
              <el-table-column align="center" label="右眼沙眼" property="YYSY">
              </el-table-column>
              <el-table-column align="center" label="色觉" property="SJ">
              </el-table-column>
              <el-table-column align="center" label="眼病" property="YB">
              </el-table-column>
              <el-table-column align="center" label="龋失补" property="QSB">
              </el-table-column>
              <el-table-column align="center" label="乳龋患" property="RQH">
              </el-table-column>
              <el-table-column align="center" label="乳龋失" property="RQS">
              </el-table-column>
              <el-table-column align="center" label="乳龋补" property="RQB">
              </el-table-column>
              <el-table-column align="center" label="恒龋患" property="HQH">
              </el-table-column>
              <el-table-column align="center" label="恒龋失" property="HQS">
              </el-table-column>
              <el-table-column align="center" label="恒龋补" property="HQB">
              </el-table-column>
              <el-table-column align="center" label="牙周" property="YZ">
              </el-table-column>
              <el-table-column align="center" label="牙龈" property="YY">
              </el-table-column>
              <el-table-column align="center" label="心" property="Xin">
              </el-table-column>
              <el-table-column align="center" label="肺" property="Fei">
              </el-table-column>
              <el-table-column align="center" label="肝" property="Gan">
              </el-table-column>
              <el-table-column align="center" label="脾" property="Pi">
              </el-table-column>
              <el-table-column align="center" label="腹部" property="FB">
              </el-table-column>
              <el-table-column align="center" label="头部" property="TB">
              </el-table-column>
              <el-table-column align="center" label="颈部" property="JB">
              </el-table-column>
              <el-table-column align="center" label="脊柱" property="JZ">
              </el-table-column>
              <el-table-column align="center" label="四肢" property="SZ">
              </el-table-column>
              <el-table-column align="center" label="皮肤" property="PF">
              </el-table-column>
              <el-table-column align="center" label="甲状腺" property="JZX">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="导入记录" :visible.sync="dialogImportVisible" :close-on-click-modal="false" width="30%"
      :before-close="handleImportClose">
      <el-form>
        <el-form-item label="导入：">
          <el-upload class="upload-demo" ref="upload" accept=".xls, .xlsx" :action="uploadURL" :on-change="upload"
            :show-file-list="false" :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <a :href="`${publicPath}template/体检数据导入模板.xls`" class="essay-bt-textbunn" target="_blank"
            style="margin-left:0!important;">体检数据导入模板下载</a>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportClose()">取 消</el-button>
        </el-form-item>
        <div class="py-text" v-if="errormessage != ''">{{ errormessage }}</div>
      </el-form>
    </el-dialog>
    <!-- 导入错误 -->
    <el-dialog title="导入异常" :visible.sync="showErrResult" :close-on-click-modal="false" width="40%">
      <div class="err-result-container">
        <div style="margin-bottom:10px;font-weight:bold;color:#1e1e1e">
          当前所选年级：{{ grade.Name }}
        </div>
        <div class="err-result-item" v-for="(student, index) in errResult" :key="index">
          <div class="student-name">{{ student.XSXM }}:</div>
          <div class="err-msg">{{ student.errMsg }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
export default {
  components: {},
  created() {
    this.xqid = localStorage.getItem('currentterm')

    var tempuser = localStorage.getItem('userinfo')
    if (tempuser) {
      var user = JSON.parse(tempuser)
      this.cjr = user.Id
      this.ssxx = user.SSZZId
      this.token = user.Token
    }
  },
  computed: {
    //文件的上传路径
    uploadURL: function () {
      return '/file/file/fileservice/UploadFile?token=' + this.token
    },
  },
  async mounted() {
    await this.loadxqdata()
    this.loadnjdata()
  },
  methods: {
    handleEdit: function (xsid) {
      this.$router.push({
        name: 'EditHealthCheck',
        params: { xsid: xsid, xqid: this.xqid },
      })
    },
    handleDelete: function (xsid) {
      this.$confirm('确定要删除该条体检记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.post(
            'v1/physicalquality/DeleteHealthCheck',
            {
              SSXX: this.ssxx,
              SSXQ: this.xqid,
              XSId: xsid,
              YHId: this.cjr,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: 'info',
                  message: '删除成功!',
                })
                this.loaddata()
              } else {
                this.$message({
                  type: 'info',
                  message: '删除失败!',
                })
                this.loaddata()
              }
            }
          )
        })
        .catch(() => {})
    },
    handleGradeChange() {
      this.grade = this.njlist.find((item) => item.Id === this.njid)
      this.handleGetGradeStudent()
      this.bjid = ''
      this.loadbjdata()
    },
    handleClassChange: function () {
      this.loaddata()
    },
    upload(file) {
      let files = { 0: file.raw }
      this.readExcel(files)
    },
    generateData({ results }) {
      this.excelData.results = results
      console.log('this.excelData.results',this.excelData.results);
      
    },
    readExcel: function (files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false })
        this.generateData({ results })
        this.submitimportdata(results)
        this.$refs.upload.value = ''
      }
      fileReader.readAsArrayBuffer(files[0])
    },
    // 提交上传数据
    submitimportdata: function (readdata) {
      if (readdata.length <= 0) {
        this.$message({
          type: 'info',
          message: '上传文件中无正确数据!',
        })
        return
      }
      this.importdata = []
      for (var i = 0; i < readdata.length; i++) {
        var obj = {
          BJMC: readdata[i].班级名称,
          XSXM: readdata[i].姓名,
          SG: readdata[i].身高 || '0',
          TZ: readdata[i].体重 || '0',
          FHL: readdata[i].肺活量 || '0',
          SSY: readdata[i].收缩压 || '0',
          SZY: readdata[i].舒张压 || '0',
          XHDB: readdata[i].血红蛋白 || '0',
          ZYSL: readdata[i].左眼视力 || '0',
          YYSL: readdata[i].右眼视力 || '0',
          JMY: readdata[i].结膜炎 || '0',
          ZYSY: readdata[i].左眼沙眼 || '0',
          YYSY: readdata[i].右眼沙眼 || '0',
          SJ: readdata[i].色觉 || '0',
          YB: readdata[i].眼病 || '0',
          QSB: readdata[i].龋失补 || '0',
          RQH: readdata[i].乳龋患 || '0',
          RQS: readdata[i].乳龋失 || '0',
          RQB: readdata[i].乳龋补 || '0',
          HQH: readdata[i].恒龋患 || '0',
          HQS: readdata[i].恒龋失 || '0',
          HQB: readdata[i].恒龋补 || '0',
          YZ: readdata[i].牙周 || '0',
          YY: readdata[i].牙龈 || '0',
          Xin: readdata[i].心 || '0',
          Fei: readdata[i].肺 || '0',
          Gan: readdata[i].肝 || '0',
          Pi: readdata[i].脾 || '0',
          FB: readdata[i].腹部 || '0',
          TB: readdata[i].头部 || '0',
          JB: readdata[i].颈部 || '0',
          JZ: readdata[i].脊柱 || '0',
          SZ: readdata[i].四肢 || '0',
          PF: readdata[i].皮肤 || '0',
          JZX: readdata[i].甲状腺 || '0',
          NL: readdata[i].年龄 || '0',
        }
        this.importdata.push(obj)
      }
      let { errResult, rightResult } = this.compareStudent(this.importdata)
      // let aa = rightResult.slice(0, 30);

      if (rightResult.length > 0) {
        this.$api.post(
          'v1/physicalquality/ImportDataHealthCheck',
          {
            DataList: rightResult,
            SSXX: this.ssxx,
            SSXQ: this.xqid,
            CJR: this.cjr,
            token: this.token,
          },
          (r) => {
            this.errormessage = r.Message
            if (r.Code == 0) {
              this.loaddata()
            }
          }
        )
      }
      if (errResult.length > 0) {
        this.errResult = errResult
        this.showErrResult = true
      }
    },
    handleImportOpen: function () {
      this.errormessage = ''
      this.dialogImportVisible = true
    },
    handleImportClose: function () {
      this.errormessage = ''
      this.dialogImportVisible = false
    },
    downloadlist: function () {
      this.$api.post(
        'v1/physicalquality/DownLoadHealthCheckList',
        {
          SSXX: this.ssxx,
          SSXQ: this.xqid,
          BJId: this.bjid,
          SearchName: this.cxmc,
          token: this.token,
        },
        (r) => {
          if (r.Code == 0) {
            window.open(r.Message, '_blank')
          } else {
            this.$message({
              type: 'info',
              message: r.Message,
            })
          }
        }
      )
    },
    loaddata: function () {
      if (this.ssxx == '' || this.xqid == '' || this.bjid == '') {
        return
      }
      this.$api.post(
        'v1/physicalquality/GetHealthCheckList',
        {
          SSXX: this.ssxx,
          SSXQ: this.xqid,
          BJId: this.bjid,
          SearchName: this.cxmc,
          token: this.token,
        },
        async (r) => {
          if (r.Code === 0) {
            this.editstatus = false
            this.sjlist = []
            this.sjlist = r.Target
            this.datacount = r.PagerInfo.RowCount
          }
        }
      )
    },
    loadxqdata: function () {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/xqb/GetXQBList',
          { AreaOrSchoolId: this.ssxx, token: this.token },
          (r) => {
            if (r.Code === 0) {
              this.xqlist = r.Target || []
              resolve()
            }
          }
        )
      })
    },
    loadnjdata: function () {
      this.$api.post(
        'v1/accountmanage/GetSchoolOrg',
        { SchoolId: this.ssxx, token: this.token },
        async (r) => {
          if (r.Code === 0) {
            this.njlist = []
            var list = r.Target
            if (list !== null) {
              this.zzjglist = list
              for (var i = 0; i < list.length; i++) {
                if (list[i].Hierarchy === 1) {
                  this.njlist.push(list[i])
                }
              }
              if (this.njid == '' && this.njlist.length > 0) {
                this.grade = this.njlist[0]
                this.njid = this.njlist[0].Id
                this.handleGetGradeStudent()
                this.loadbjdata()
              }
            }
          }
        }
      )
    },
    loadbjdata: function () {
      if (this.njid.length > 0) {
        this.bjlist = this.zzjglist.filter(
          (c) => c.Id === this.njid
        )[0].ChidOrgList
      } else {
        this.bjlist = []
      }
      if (this.bjid == '' && this.bjlist.length > 0) {
        this.bjid = this.bjlist[0].Id
      }
      this.loaddata()
    },
    // 获取班级学生
    getGradeStudent(bj, nj) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetOrgStudent',
          {
            SchoolOrgId: bj.Id, //	是	string	组织机构ID/活动Id
            OrgType: 2, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            // SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: this.xqlist.find((item) => item.Id === this.xqid).SSXN, //	是	string	学年
            SchoolId: this.ssxx, //	否	string	学校Id
            IsDelete: 2, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
            token: this.token,
          },
          (res) => {
            if (res.Code === 0) {
              if (res.Target) {
                res.Target.forEach((item) => {
                  item.gradeName = nj.Name
                  item.gradeId = nj.Id
                  item.className = bj.Name
                })
                this.gradeStudents = [...res.Target, ...this.gradeStudents]
              }
              resolve()
            }
          }
        )
      })
    },
    // 封装获取年级的所有班级学生
    async handleGetGradeStudent() {
      this.gradeStudents = []
      if (this.njlist.find((item) => item.Id === this.njid).ChidOrgList) {
        let nj = this.njlist.find((item) => item.Id === this.njid)
        let bjlist = nj.ChidOrgList

        for (let i = 0; i < bjlist.length; i++) {
          await this.getGradeStudent(bjlist[i], nj)
        }
      }
    },
    // 排查学生班级姓名是否正确
    compareStudent(data) {
      let rightResult = []
      let errResult = data.reduce((pre, cur) => {
        // console.log('cur',cur,cur.BJMC);
        
        let studentList = this.gradeStudents.filter(
          (student) => student.className === cur.BJMC
        )
        console.log('studentList',studentList);
        
        if (studentList.length > 0) {
          let student = studentList.find((student) => student.Name === cur.XSXM)
          console.log('sss',student);
          // 通过名字没有找到该学生 并且在errResult中没有重复
          if (!student && !pre.some((el) => el.XSXM === cur.XSXM)) {
            pre.push({
              XSXM: cur.XSXM,
              errMsg: `未在${cur.BJMC}找到该学生`,
            })

          }
          
          
          // 通过名字找到了该学生 并且在rightResult中没有重复
          if (student && !rightResult.some((el) => el.XSXM === cur.XSXM)) {
            let obj = {
              BJId: student.ClassId,
              XSId: student.Id,
              ...cur,
            }
            rightResult.push(obj)
          }
        } else {
          if (!pre.some((el) => el.XSXM === cur.XSXM)) {
            pre.push({
              XSXM: cur.XSXM,
              errMsg: '未在当前年级中找到该学生班级',
            })
          }
        }
        return pre
      }, [])
      return { errResult, rightResult }
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      isLogin: true,
      sjlist: [],
      datacount: 0,
      zzjglist: [],
      bjlist: [],
      njlist: [],
      xqlist: [],
      importdata: [],
      bjid: '',
      njid: '',
      cxmc: '',
      ssxx: '',
      xqid: '',
      cjr: '',
      token: '',
      errormessage: '',
      dialogImportVisible: false,
      excelData: {
        results: null,
      },
      gradeStudents: [],
      errResult: [],
      showErrResult: false,
      grade: {},
    }
  },
}
</script>
<style lang="scss" scoped>
.err-result-container {
  .err-result-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    .student-name {
      width: 70px;
    }
    .err-msg {
      flex: 1;
    }
  }
}
</style>
