<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <div class="leftrig-rig-box" style="width:100%;">
        <div class="margno-t">
          <div class="table-er-box" v-if="areaId.includes('-')">
            <!--TODO:按钮-->
            <el-button plain @click="addItem(1)">新增等级转分数</el-button>
            <el-button plain @click="addItem(2)">新增分数转等级</el-button>
          </div>
          <div class="prompt-title-box" style="color: red;" v-else>
            只能在区域下添加等级
          </div>
          <!--TODO:内容-->
          <Table
            :tableData="
              dataTable.slice(
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
                (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                  pagerInfo.pageSize
              )
            "
            @editItem="editItem"
            :user="user"
            @delItem="saveDialog"
            v-loading="isLoading"
          />
          <div class="pagin-box">
            <div class="pagin-wz">
              <!--分页-->
              <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <From
      :visible="visible"
      :data="formData"
      :dataList="details"
      :title="dialogTitle"
      :user="user"
      :area="areaId"
      @closeDialog="closeDialog"
      @saveDialog="saveDialog"
    />
  </div>
</template>

<script>
import From from "./Form.vue";
import Table from "./Table.vue";
import Pager from "@/components/Pager.vue";
export default {
  components: {
    Pager,
    Table,
    From
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      areaId: "",
      visible: false,
      dataTable: [],
      formData: {},
      details: [],
      dialogTitle: "新增等级",
      statu: [
        { Key: 1, Value: "正常" },
        { Key: 2, Value: "停用" }
      ],
      isLoading: false,
      treeData: []
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    this.areaId = this.user.SSZZId;
    this.changeIndex(1);
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
      this.getData();
    },
    closeDialog() {
      this.visible = false;
    },
    saveDialog() {
      this.getData();
      this.visible = false;
    },
    addItem(type) {
      this.dialogTitle = "新增等级";
      this.formData = {};
      this.formData.DJLX = type;
      this.details = [];
      this.visible = true;
    },
    editItem(data) {
      this.dialogTitle = "编辑等级";
      this.formData = data;
      this.details = data.Details;
      this.visible = true;
    },
    getData() {
      this.isLoading = true;
      this.$api.post(
        "/v1/levelmanager/GetLeaveSet",
        {
          token: this.token,
          SchoolId: this.areaId
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target;
            this.pagerInfo.totalCount = this.dataTable.length;
            this.isLoading = false;
          }
        }
      );
    }
  }
};
</script>
