<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="margno-t">
        <div class="table-er-box">
          <!--按钮-->
          <el-button plain @click="addItem">新增物品</el-button>
          <!-- <el-button plain @click="setGenerateScoreRule"
            >设置积分生成规则</el-button
          > -->
          <el-button plain @click="categoryVisible = true">分类设置</el-button>
          <el-select
            style="margin-left: 10px; width: 150px;"
            v-model="categoryId"
            placeholder="请选择物品分类"
          >
            <el-option
              v-for="item in [{ Id: '', ArticleCategoryName: '全部' }].concat(
                category
              )"
              :key="item.Id"
              :label="item.ArticleCategoryName"
              :value="item.Id"
            ></el-option>
          </el-select>
          <el-select
            style="margin-left: 10px; width: 80px;"
            v-model="articleStatus"
            placeholder="请选择使用状态"
          >
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            style="margin-left: 10px; width: 150px;"
            v-model="search"
            placeholder="关键字搜索"
          ></el-input>
          <el-button style="margin-left: 10px;" type="primary" @click="getData"
            >搜索</el-button
          >
        </div>
        <!--内容-->
        <Table
          v-loading="isLoading"
          :tableData="dataTable"
          :token="token"
          @editItem="editItem"
          @delItem="delItem"
          @editInventory="openEditInventoryDialog"
        />
        <div class="pagin-box">
          <div class="pagin-wz">
            <!--分页-->
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
      </div>
    </div>
    <From
      :visible="visible"
      :user="user"
      :data="formData"
      :title="dialogTitle"
      :gradeList="gradeList"
      :category="category"
      :schoolId="schoolId"
      :token="token"
      @closeDialog="closeDialog"
    />
    <RuleFrom
      :visible="ruleVisible"
      :user="user"
      :data="ruleData"
      :schoolId="schoolId"
      :token="token"
      @closeDialog="closeRuleDialog"
    />

    <el-dialog
      v-loading="isLoading"
      title="修改库存"
      :visible.sync="editInventoryVisible"
      width="20%"
      top="15%"
      center
      :close-on-click-modal="false"
    >
      <div style="text-align: center;">
        <el-input-number
          v-model="editInventoryNumber"
          :min="-1 * editInventoryNumberMin"
          :step="1"
          step-strictly
        ></el-input-number>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editInventoryVisible = false">取 消</el-button>
        <el-button type="primary" @click="editInventory">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分类设置 -->
    <Category :visible.sync="categoryVisible" @refreshCategory="getCategory" />
  </div>
</template>

<script>
import Pager from '@/components/Pager.vue'
import RuleFrom from './FormGenerateScoreRule.vue'
import From from './Form.vue'
import Table from './Table.vue'
import Category from './Category.vue'
export default {
  components: {
    Pager,
    From,
    Table,
    RuleFrom,
    Category
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: '',
      user: {},
      schoolId: '',
      visible: false,
      dataTable: [],
      formData: {},
      dialogTitle: '',
      isLoading: false,
      ruleVisible: false,
      ruleData: {},
      category: [],
      editInventoryVisible: false,
      editInventoryNumber: 0,
      editInventoryNumberMin: 0,
      editInventoryId: '',
      categoryId: '',
      articleStatus: 0,
      search: '',
      status: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '正常'
        },
        {
          value: 2,
          label: '暂停'
        }
      ],
      categoryVisible: false,
      gradeList: []
    }
  },
  created: async function() {
    this.token = this.$store.state.token
    this.user = JSON.parse(localStorage.getItem('userinfo'))
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId
      }
    }
    this.getCategory()
    this.getRolesData()
    this.isLoading = true
    this.getData()
    await this.getGradeList()
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val
      this.getData()
    },
    addItem() {
      this.dialogTitle = '新增物品'
      this.visible = true
      this.formData = {}
    },
    editItem(data) {
      this.dialogTitle = '编辑物品'
      this.formData = data
      this.visible = true
    },
    delItem(data) {
      const index = this.dataTable.findIndex(c => c.Id === data.Id)
      if (index !== -1) {
        this.dataTable.splice(index, 1)
        this.pagerInfo.totalCount = this.dataTable.length
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }
    },
    getRolesData() {
      this.$api.post(
        '/v1/StudentExchangeArticle/GetRolesSetData',
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.ruleData = r.Target
          }
        }
      )
    },
    openEditInventoryDialog(id, inventory) {
      this.editInventoryId = id
      this.editInventoryNumberMin = inventory
      this.editInventoryNumber = 0
      this.editInventoryVisible = true
    },
    editInventory() {
      this.isLoading = true
      this.$api.post(
        '/v1/StudentExchangeArticle/EditInventory',
        {
          token: this.token,
          ExchangeId: this.editInventoryId,
          ChangeNumber: this.editInventoryNumber,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          if (r.Code === 0) {
            this.editInventoryVisible = false
            this.editInventoryNumberMin = 0
            this.editInventoryNumber = 0
            this.editInventoryId = ''
            this.$message({
              type: 'success',
              message: r.Message
            })
          }
          this.isLoading = false
          this.getData()
        }
      )
    },
    closeDialog() {
      this.visible = false
      this.getData()
    },
    closeRuleDialog() {
      this.ruleVisible = false
      this.getRolesData()
    },
    setGenerateScoreRule() {
      this.ruleVisible = true
    },
    getData() {
      this.isLoading = true
      this.$api.post(
        '/v1/StudentExchangeArticle/GetArticlesForWeb',
        {
          token: this.token,
          SchoolId: this.schoolId,
          CategoryId: this.categoryId,
          ArticleStatus: this.articleStatus,
          Search: this.search,
          PagerInfo: this.pagerInfo
        },
        r => {
          if (r.Code === 0) {
            let arr = r.Target || []
            arr.sort((a, b) => a.PXH - b.PXH)
            this.dataTable = arr
            this.pagerInfo.totalCount = r.PagerInfo.RowCount
          }
          this.isLoading = false
        }
      )
    },
    getCategory() {
      this.$api.post(
        '/v1/StudentExchangeArticle/getcategory',
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.category = r.Target
          }
        }
      )
    },
    getGradeList() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          {
            SchoolId: this.user.SSZZId,
            token: this.token
          },
          res => {
            if (res.Code === 0) {
              this.gradeList = res.Target || []
              resolve()
            }
          }
        )
      })
    }
  }
}
</script>
