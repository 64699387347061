<template>
  <el-table :data="tableData" border style="width: 100%;">
    <el-table-column fixed prop="WJMC" label="问卷名称"></el-table-column>
    <el-table-column prop="SSWDStr" label="维度"></el-table-column>
    <el-table-column prop="SYZTStr" label="状态">
      <template slot-scope="scope">{{
        scope.row.SYZT === 1 ? "正常" : "停用"
      }}</template>
    </el-table-column>
    <el-table-column fixed="right" label="操作" width="250">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="editItem(scope.row)"
          >编辑</el-button
        >
        <el-button @click="updateItem(scope.row)" type="text" size="small">
          {{ scope.row.SYZT === 1 ? "停用" : "启用" }}
        </el-button>
        <el-button
          @click="delItem(scope.row)"
          type="text"
          size="small"
          class="tab-wz-butta c-red"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "/v1/questionnaire/DelQuestionnaire",
            {
              Id: data.Id,
              token: this.$store.state.token,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              let typeStatu = "success";
              if (r.Code !== 0) {
                typeStatu = "error";
              }
              this.$message({
                type: typeStatu,
                message: r.Message
              });
              this.$emit("delItem");
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    updateItem(data) {
      let Statu = 1;
      if (data.SYZT === 1) {
        Statu = 2;
      }
      this.$api.post(
        "/v1/questionnaire/UpdateQuestionnaire",
        {
          Id: data.Id,
          Statu: Statu,
          token: this.$store.state.token,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          let typeStatu = "success";
          if (r.Code !== 0) {
            typeStatu = "error";
          } else {
            data.SYZT = Statu;
          }
          this.$message({
            type: typeStatu,
            message: r.Message
          });
          this.$emit("updateItem", data);
        }
      );
    }
  }
};
</script>
