<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="margno-t">
        <div class="table-er-box">
          <!--TODO:按钮-->
          <el-button plain @click="addItem(2)">新增分数转等级</el-button>
          <el-button plain @click="addItem(1)">新增等级转分数</el-button>
          <el-button plain @click="addItem(3)">新增百分比转等级</el-button>
          <el-input v-model="searchText">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch"
            ></el-button
          ></el-input>
          <a
            :href="`${publicPath}template/等级导入模板.xlsx`"
            style="margin: 0 10px"
          >
            <el-button plain>
              模板下载
            </el-button>
          </a>
          <el-upload
            style="display:inline-block"
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="uploadChange"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button icon="el-icon-upload2">等级导入</el-button>
          </el-upload>
        </div>
        <!--TODO:内容-->
        <Table
          :tableData="
            searchData.slice(
              (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
              (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                pagerInfo.pageSize
            )
          "
          @editItem="editItem"
          :user="user"
          @delItem="saveDialog"
          v-loading="isLoading"
        />
        <div class="pagin-box">
          <div class="pagin-wz">
            <!--分页-->
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
      </div>
    </div>
    <From
      :visible="visible"
      :data="formData"
      :dataList="details"
      :title="dialogTitle"
      :user="user"
      @closeDialog="closeDialog"
      @saveDialog="saveDialog"
    />

    <!-- 导入错误提示 -->
    <el-dialog title="导入失败列表" :visible.sync="errDialog" width="50%">
      <div class="err-block">
        <div class="err-item" v-for="(item, index) in errData" :key="index">
          {{ item.msg }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="errDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import From from "./Form.vue";
import Table from "./Table.vue";
import Pager from "@/components/Pager.vue";
import XLSX from "xlsx";

export default {
  components: {
    Pager,
    Table,
    From
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      schoolId: "",
      visible: false,
      searchData: [],
      dataTable: [],
      formData: {},
      details: [],
      dialogTitle: "新增等级",
      statu: [
        { Key: 1, Value: "正常" },
        { Key: 2, Value: "停用" }
      ],
      reportType: [],
      isLoading: false,
      shapes: [],
      searchText: "",
      publicPath: process.env.BASE_URL,
      uploadURL: "",
      errData: [], //导入错误数据
      errDialog: false
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }

    this.getData();
  },
  methods: {
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    closeDialog() {
      this.visible = false;
    },
    saveDialog() {
      this.getData();
      this.visible = false;
    },
    addItem(type) {
      this.dialogTitle = "新增等级";
      this.formData = {};
      this.formData.DJLX = type;
      this.details = [];
      this.visible = true;
    },
    editItem(data) {
      this.dialogTitle = "编辑等级";
      this.formData = data;
      this.details = data.Details;
      this.visible = true;
    },
    getData() {
      this.isLoading = true;
      this.$api.post(
        "/v1/levelmanager/GetLeaveSet",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.searchData = this.dataTable = r.Target;
            this.pagerInfo.totalCount = this.dataTable.length;
            this.isLoading = false;
          }
        }
      );
    },
    // 导入
    uploadChange(file) {
      this.uploading = true;
      let files = { 0: file.raw };
      this.readExcel(files);
    },
    readExcel(files) {
      if (files.length <= 0) return false;
      else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const res = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        this.handleExcel(res);
        this.$refs.upload.value = "";
      };
      fileReader.readAsArrayBuffer(files[0]);
    },
    handleExcel(arr) {
      console.log(arr, "arrrr");
      let numberArr = [];
      for (let i = 1; i <= 7; i++) {
        numberArr.push("等级" + i);
      }
      let result = arr.reduce((pre, cur) => {
        console.log(cur, "cur");
        let DJLX = 0;
        let LX = 0;
        if (cur["等级类型"] === "分数转等级") DJLX = 2;
        if (cur["等级类型"] === "百分比转等级") {
          DJLX = 3;
          LX = cur["范围"] === "年级" ? 1 : 2;
        }
        if (cur["等级类型"] === "等级转分数") DJLX = 1;

        let obj = {
          DJLX,
          DJMC: cur["等级名称"],
          LX,
          Details: []
        };

        for (let i = 0; i < numberArr.length; i++) {
          if (cur[numberArr[i]]) {
            obj.Details.push({
              DJ: cur[numberArr[i]],
              MS: DJLX !== 1 ? cur[`${numberArr[i]}描述`] : "",
              ZGF:
                DJLX === 1
                  ? cur[`${numberArr[i]}分数`]
                  : i === 0
                  ? cur["满分"]
                  : cur[`${numberArr[i - 1]}分数`],
              ZDF: cur[`${numberArr[i]}分数`]
            });
          }
        }
        console.log(obj, "obj");
        pre.push(obj);
        return pre;
      }, []);

      result.forEach((item, index) => this.validExcel(item, index));
      console.log(result, "result");
      let right = result.filter(item => !item.msg);
      this.errData = result.filter(item => item.msg);
      if (result.length > 0) {
        this.$api.post(
          "v1/levelmanager/ImportLeavetRequest",
          {
            SchoolId: this.schoolId,
            UserId: this.user.Id,
            UserName: this.user.DLM,
            token: this.user.Token,
            ImportDJList: right
          },
          res => {
            if (res.Code === 0) {
              this.$message.success("导入成功");
              this.getData();
              setTimeout(() => {
                if (this.errData.length > 0) this.errDialog = true;
              }, 1000);
            }
          }
        );
      }
    },
    // 校验导入数据
    validExcel(obj, index) {
      if (obj) {
        if (!obj.DJMC) obj.msg = `第${index + 2}行等级名称不规范`;
        if (!obj.DJLX) obj.msg = `第${index + 2}行等级类型不规范`;
        if (obj.DJLX === 3 && !obj.LX) obj.msg = `第${index + 2}行范围不规范`;
        if (!obj.Details || obj.Details.length === 0)
          obj.msg = `第${index + 2}行缺少等级1234...`;
        obj.Details.some((item, idx) => {
          if (obj.DJLX !== 1) {
            if (idx === 0 && !item.ZGF)
              return (obj.msg = `第${index + 2}行满分不规范`);
            if (idx === 0 && item.ZDF !== 0 && !item.ZDF)
              return (obj.msg = `第${index + 2}行${item.DJ}分数不规范`);
            if (idx === 0 && item.ZDF >= item.ZGF)
              return (obj.msg = `第${index + 2}行${item.DJ}的分数必须小于满分`);
            if (item.ZDF !== 0 && item.ZDF !== 0 && !item.ZDF)
              return (obj.msg = `第${index + 2}行${item.DJ}的分数不规范`);
            if (idx !== 0 && item.ZDF >= item.ZGF)
              return (obj.msg = `第${index + 2}行${item.DJ}的分数必须小于${
                obj.Details[idx - 1].DJ
              }的分数`);
          } else {
            if (item.ZDF !== 0 && !item.ZDF)
              return (obj.msg = `第${index + 2}行${item.DJ}的分数不规范`);
            if (idx !== 0 && item.ZDF >= obj.Details[idx - 1].ZDF)
              return (obj.msg = `第${index + 2}行${item.DJ}的分数必须小于${
                obj.Details[idx - 1].DJ
              }的分数`);
          }
        });
      }
    },
    handleSearch() {
      if (!this.searchText.trim()) {
        this.searchData = this.dataTable;
        this.pagerInfo.totalCount = this.dataTable.length;
      } else {
        this.searchData = this.dataTable.filter(item =>
          item.DJMC.includes(this.searchText)
        );
        this.pagerInfo.totalCount = this.searchData.length;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table-er-box {
  ::v-deep .el-input {
    width: 200px;
    margin-left: 10px;
  }
}
.err-block {
  .err-item {
    padding: 10px 5px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 2px #ccc;
  }
}
</style>
