<template>
  <div>
    <div class="cz-mainbox">
      <div class="cz-mainbox-tit">三.课堂表现活动的挂接</div>
      <div class="cz-cont-box">
        <div class="disilb-box-l" style="width: 180px; text-align: right;">
          <div class="tablil-xz-name epp-ov-l" style="margin-bottom: 7px;">
            选择活动:
          </div>
        </div>

        <el-select
          size="small"
          v-model="setData.KTBXHDId"
          placeholder="请选择"
          style="width: 180px; margin-left: 8px;"
        >
          <el-option
            v-for="item in basicInfo.Activities.filter(c => c.Type === 1)"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="cz-mainbox">
      <div class="cz-mainbox-tit">二.评语设置</div>
      <div class="py-tit">
        <el-checkbox
          v-model="setData.ClassEvaluationGoodComment.Enable"
        ></el-checkbox>
        <div class="disilb-box-ll" style="margin-top: -10px;">
          课堂表现表扬项:(取表扬次数最多的前几项进行评语展示)
        </div>
        <el-input
          :disabled="!setData.ClassEvaluationGoodComment.Enable"
          size="medium"
          style="width: 60px;"
          placeholder="输入"
          v-model="setData.ClassEvaluationGoodComment.Count"
        ></el-input>
        <div class="disilb-box-ll">项</div>
      </div>
      <div class="py-cont-box">
        <div class="py-input-box">
          <el-input
            :disabled="!setData.ClassEvaluationGoodComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.ClassEvaluationGoodComment.Comment1"
          ></el-input>
          <div class="disilb-box-ll" style="margin-top: -10px;">xxxx、xxxx</div>
          <el-input
            :disabled="!setData.ClassEvaluationGoodComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.ClassEvaluationGoodComment.Comment2"
          ></el-input>
        </div>
        <div class="py-text-l">例子：</div>
        <div class="py-text">
          孩子在课堂上多次得到 课前准备 、 积极发言
          方面的表扬，老师给你大大的赞！
        </div>
        <div class="py-img-box">
          <img class="py-img" :src="this.$store.state.defaultPic" />
          <img class="py-img" :src="this.$store.state.defaultPic" />
          <img class="py-img" :src="this.$store.state.defaultPic" />
        </div>
      </div>
      <div class="py-tit" style="margin-top: 20px;">
        <el-checkbox
          v-model="setData.ClassEvaluationBadComment.Enable"
        ></el-checkbox>
        <div class="disilb-box-ll" style="margin-top: -10px;">
          课堂表现批评项:(取批评次数最多的项且该项累计次数超过N次的进行评语展示)
        </div>
        <el-input
          :disabled="!setData.ClassEvaluationBadComment.Enable"
          size="medium"
          style="width: 60px;"
          placeholder="输入"
          v-model="setData.ClassEvaluationBadComment.Count"
        ></el-input>
        <div class="disilb-box-ll">项</div>
      </div>
      <div class="py-cont-box">
        <div class="py-input-box">
          <el-input
            :disabled="!setData.ClassEvaluationBadComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.ClassEvaluationBadComment.Comment1"
          ></el-input>
          <div class="disilb-box-ll" style="margin-top: -10px;">xxxx、xxxx</div>
          <el-input
            :disabled="!setData.ClassEvaluationBadComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.ClassEvaluationBadComment.Comment2"
          ></el-input>
        </div>
        <div class="py-text-l">例子：</div>
        <div class="py-text">
          孩子你在课堂上表现欠佳的是：上课不认真听讲、下课打闹
          下次可得注意改进哟！
        </div>
      </div>
      <div class="py-tit" style="margin-top: 20px;">
        <el-checkbox
          v-model="setData.CurriculaVariableComment.Enable"
        ></el-checkbox>
        <div class="disilb-box-ll" style="margin-top: -10px;">课辅活动项</div>
      </div>
      <div class="py-cont-box">
        <div class="py-input-box">
          <el-input
            :disabled="!setData.CurriculaVariableComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.CurriculaVariableComment.Comment1"
          ></el-input>
          <div class="disilb-box-ll" style="margin-top: -10px;">xxxx、xxxx</div>
          <el-input
            :disabled="!setData.CurriculaVariableComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.CurriculaVariableComment.Comment2"
          ></el-input>
          <div class="disilb-box-ll" style="margin-top: -10px;">xxxx、xxxx</div>
          <el-input
            :disabled="!setData.CurriculaVariableComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.CurriculaVariableComment.Comment3"
          ></el-input>
        </div>
        <div class="py-text-l">例子：</div>
        <div class="py-text">
          孩子在课堂上多次得到 课前准备 、 积极发言
          方面的表扬，老师给你大大的赞！
        </div>
        <div class="py-img-box">
          <img class="py-img" :src="this.$store.state.defaultPic" />
          <img class="py-img" :src="this.$store.state.defaultPic" />
          <img class="py-img" :src="this.$store.state.defaultPic" />
        </div>
      </div>
      <div
        v-for="(item, index) in setData.OtherActivityCommentList"
        :key="index"
      >
        <div class="py-tit" style="margin-top: 20px;">
          <el-checkbox v-model="item.Enable"></el-checkbox>
          <div class="disilb-box-ll" style="margin-top: -10px;">其它活动项</div>
          <el-select
            size="small"
            v-model="item.HDId"
            placeholder="请选择"
            style="width: 120px; margin-left: 8px;"
          >
            <el-option
              v-for="activity in basicInfo.Activities.filter(c => c.Type === 1)"
              :key="activity.Id"
              :label="activity.Name"
              :value="activity.Id"
            ></el-option>
          </el-select>
        </div>
        <div class="py-cont-box">
          <div class="py-input-box">
            <el-input
              :disabled="!item.Enable"
              size="medium"
              style="width: 160px;"
              placeholder="输入"
              v-model="item.Comment1"
            ></el-input>
            <div class="disilb-box-ll" style="margin-top: -10px;">
              xxxx、xxxx
            </div>
            <el-input
              :disabled="!item.Enable"
              size="medium"
              style="width: 160px;"
              placeholder="输入"
              v-model="item.Comment2"
            ></el-input>
            <div class="disilb-box-ll" style="margin-top: -10px;">
              xxxx、xxxx
            </div>
            <el-input
              :disabled="!item.Enable"
              size="medium"
              style="width: 160px;"
              placeholder="输入"
              v-model="item.Comment3"
            ></el-input>
            <div class="disilb-box-ll" style="margin-top: -10px;">
              xxxx、xxxx
            </div>
            <el-input
              :disabled="!item.Enable"
              size="medium"
              style="width: 160px;"
              placeholder="输入"
              v-model="item.Comment4"
            ></el-input>
            <div class="disilb-box-ll" style="margin-top: -10px;">
              xxxx、xxxx
            </div>
            <el-input
              :disabled="!item.Enable"
              size="medium"
              style="width: 160px;"
              placeholder="输入"
              v-model="item.Comment5"
            ></el-input>
          </div>
          <div class="py-text-l">例子：</div>
          <div class="py-text">
            认真参与学校的主题活动，并得到了不得16颗星获得了“优秀达人”称号；
          </div>
          <div class="py-img-box">
            <img class="py-img" :src="defaultPic" />
            <img class="py-img" :src="defaultPic" />
            <img class="py-img" :src="defaultPic" />
          </div>
        </div>
      </div>
      <div class="py-tit" style="margin-top: 20px;">
        <el-checkbox
          v-model="setData.SchoolEvaluationComment.Enable"
        ></el-checkbox>
        <div class="disilb-box-ll" style="margin-top: -10px;">校园有礼</div>
        <el-select
          size="small"
          v-model="setData.SchoolEvaluationComment.HDId"
          placeholder="请选择"
          style="width: 120px; margin-left: 8px;"
        >
          <el-option
            v-for="item in basicInfo.Activities.filter(c => c.Type === 1)"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
      </div>
      <div class="py-cont-box">
        <div class="py-input-box">
          <el-input
            :disabled="!setData.SchoolEvaluationComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.SchoolEvaluationComment.Comment1"
          ></el-input>
          <div class="disilb-box-ll" style="margin-top: -10px;">xxxx、xxxx</div>
          <el-input
            :disabled="!setData.SchoolEvaluationComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.SchoolEvaluationComment.Comment2"
          ></el-input>
          <div class="disilb-box-ll" style="margin-top: -10px;">xxxx、xxxx</div>
          <el-input
            :disabled="!setData.SchoolEvaluationComment.Enable"
            size="medium"
            style="width: 160px;"
            placeholder="输入"
            v-model="setData.SchoolEvaluationComment.Comment3"
          ></el-input>
        </div>
        <div class="py-text-l">例子：</div>
        <div class="py-text">
          认真参与学校的主题活动，并得到了不得16颗星获得了“优秀达人”称号；
        </div>
        <div class="py-img-box">
          <img class="py-img" :src="this.$store.state.defaultPic" />
          <img class="py-img" :src="this.$store.state.defaultPic" />
          <img class="py-img" :src="this.$store.state.defaultPic" />
        </div>
      </div>
      <div class="py-tit" style="margin-top: 20px;">
        <el-checkbox v-model="setData.EndComment.Enable"></el-checkbox>
        <div class="disilb-box-ll" style="margin-top: -10px;">
          结束语（分值为个人统计出来的本期总得分,如"0~60"表示 60 ≥ 分数 ≥ 0
        </div>
      </div>
      <div class="py-cont-box">
        <div
          v-for="(item, index) in setData.EndComment.CommentList"
          :key="index"
          class="py-input-box"
          style="margin-bottom: 20px;"
        >
          <el-input
            :disabled="!setData.EndComment.Enable"
            size="medium"
            style="width: 60px;"
            v-model="item.DF"
            placeholder="请输入"
          ></el-input>
          <div class="disilb-box-ll" style="margin-top: -10px;">～</div>
          <el-input
            :disabled="!setData.EndComment.Enable"
            size="medium"
            style="width: 60px;"
            v-model="item.GF"
            placeholder="请输入"
          ></el-input>
          <div
            class="disilb-box-ll"
            style="
              color: #cccccc;
              margin: 0px 15px;
              font-size: 20px;
              margin-top: -10px;
            "
          >
            ↔
          </div>
          <el-input
            :disabled="!setData.EndComment.Enable"
            size="medium"
            style="width: 280px;"
            v-model="item.MSY"
            placeholder="请输入"
          ></el-input>
          <el-button
            @click="removeCommentItem(index)"
            plain
            style="margin-left:20px"
            type="danger"
            size="medium"
            >移除</el-button
          >
        </div>
        <el-button
          plain
          icon="el-icon-plus"
          size="medium"
          @click="addCommentItem"
        ></el-button>
      </div>
    </div>
    <div class="cent-bott-butt">
      <a class="cent-bott-butta" @click="toSet1">上一步</a>
      <a class="cent-bott-butta" @click="save">保存</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setData: {},
      defaultPic: this.$store.state.defaultPic,
      token: "",
      user: {},
      schoolId: ""
    };
  },
  props: {
    setInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    basicInfo: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.init();
  },
  methods: {
    init() {
      this.setData = JSON.parse(JSON.stringify(this.setInfo));
      this.setData.KTBXHDId = this.basicInfo.Activities.find(
        c => c.Type === 1 && c.Id === this.setData.KTBXHDId
      )
        ? this.setData.KTBXHDId
        : "";
    },
    toSet1() {
      this.$emit("toGeneral1", this.setData);
      scrollTo(0, 0);
    },
    save() {
      for (const item of this.setData.MJWDGLHDList) {
        item.Id = 0;
      }
      for (const item of this.setData.ZCSSZInfo.TYCZTPList) {
        item.Id = 0;
      }
      this.setData.EndComment.CommentList = this.setData.EndComment.CommentList.filter(
        c => (c.DF || c.DF === 0) && (c.GF || c.GF === 0)
      );
      this.$api.post(
        "/v1/growthreport/SetGeneralGrowthReportSet",
        {
          token: this.token,
          GrwothSetInfo: this.setData,
          SchoolId: this.schoolId,
          UserId: this.user.Id,
          UserName: this.user.XSM
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: r.Message
            });
            this.toSet1();
          }
        }
      );
    },
    addCommentItem() {
      this.setData.EndComment.CommentList.push({
        DF: undefined,
        GF: undefined,
        MSY: undefined
      });
    },
    removeCommentItem(index) {
      this.setData.EndComment.CommentList.splice(index, 1);
    }
  }
};
</script>
