<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="essay-name-box">{{ surveyinfo.HDMC }}</div>
      <div
        style="padding-bottom: 10px;margin-top: 10px;font-size: 14px;box-sizing: border-box;padding-top: 10px;padding-left: 10px;border: 1px solid #f2f2f2"
      >
        <div class="essay-news-box">
          <span class="essay-news-sapn">
            {{ surveyinfo.KSSJ | DateFormat("yyyy-MM-dd") }}至{{
              surveyinfo.JSSJ | DateFormat("yyyy-MM-dd")
            }}
          </span>
        </div>
        <p class="essay-p">{{ surveyinfo.BZ }}</p>
      </div>
      <div
        v-if="tmlist.filter(c => c.SSLB == 1).length > 0"
        style="padding-bottom: 10px;margin-top: 10px;font-size: 14px;box-sizing: border-box;padding-top: 10px;padding-left: 10px;border: 1px solid #f2f2f2"
      >
        <p class="essay-p">一、学校满意度调查：</p>
        <div class="tab-heng-box" style="margin-top: 1rem;">
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <td>排序号</td>
                <td>答案</td>
                <td>分数</td>
                <td>文字输入</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(da, i) in schoolcheckedlist" :key="i">
                <td>{{ da.PXH }}</td>
                <td>{{ da.ZBMC }}</td>
                <td>{{ da.ZBDF }}</td>
                <td>
                  <span v-if="da.WZSR == 1">是</span><span v-else>否</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p
          class="essay-p"
          v-for="(item, index) in tmlist.filter(c => c.SSLB == 1)"
          :key="index"
        >
          {{ index + 1 }}.&nbsp;{{ item.PJXMC }}
        </p>
      </div>
      <div
        v-if="tmlist.filter(c => c.SSLB == 2).length > 0"
        style="padding-bottom: 10px;margin-top: 10px;font-size: 14px;box-sizing: border-box;padding-top: 10px;padding-left: 10px;border: 1px solid #f2f2f2"
      >
        <p class="essay-p">二、班级满意度调查：</p>
        <div class="tab-heng-box" style="margin-top: 1rem;">
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <td>排序号</td>
                <td>答案</td>
                <td>分数</td>
                <td>文字输入</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(da, i) in classcheckedlist" :key="i">
                <td>{{ da.PXH }}</td>
                <td>{{ da.ZBMC }}</td>
                <td>{{ da.ZBDF }}</td>
                <td>
                  <span v-if="da.WZSR == 1">是</span><span v-else>否</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p
          class="essay-p"
          v-for="(item, index) in tmlist.filter(c => c.SSLB == 2)"
          :key="index"
        >
          {{ index + 1 }}.&nbsp;{{ item.PJXMC }}
        </p>
      </div>
      <div
        v-if="tmlist.filter(c => c.SSLB == 3).length > 0"
        style="padding-bottom: 10px;margin-top: 10px;font-size: 14px;box-sizing: border-box;padding-top: 10px;padding-left: 10px;border: 1px solid #f2f2f2"
      >
        <p class="essay-p">三、学科老师满意度调查：</p>
        <div class="tab-heng-box" style="margin-top: 1rem;">
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <td>排序号</td>
                <td>答案</td>
                <td>分数</td>
                <td>文字输入</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(da, i) in coursecheckedlist" :key="i">
                <td>{{ da.PXH }}</td>
                <td>{{ da.ZBMC }}</td>
                <td>{{ da.ZBDF }}</td>
                <td>
                  <span v-if="da.WZSR == 1">是</span><span v-else>否</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-heng-box" style="margin-top: 1rem;">
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <td>年级</td>
                <td>课程</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(nj, ki) in njlist" :key="ki">
                <td>{{ nj.NJMC }}</td>
                <td>
                  <span v-for="(xk, xki) in nj.CourseList" :key="xki">
                    <span v-if="xklist.filter(s => s == xk.KCId).length > 0"
                      >{{ xk.KCMC }}&nbsp;</span
                    >
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p
          class="essay-p"
          v-for="(item, index) in tmlist.filter(c => c.SSLB == 3)"
          :key="index"
        >
          {{ index + 1 }}.&nbsp;{{ item.PJXMC }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.ssxq = this.$route.params.xqid;
    var tempuser = localStorage.getItem("userinfo");
    this.surveyinfo.Id = this.$route.params.id;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
  },
  mounted: function() {
    this.loadnjdata();
    this.loaddata();
  },
  methods: {
    loaddata: function() {
      if (this.surveyinfo.Id !== undefined && this.surveyinfo.Id !== "") {
        this.$api.post(
          "v1/survey/GetSurvey",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            Id: this.surveyinfo.Id,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.surveyinfo = r.Target;
              this.tmlist = r.Target.WJTMList;
              this.dalist = r.Target.WJDAList;

              this.schoolcheckedlist = this.dalist.filter(d => d.SSLB === 1);
              this.classcheckedlist = this.dalist.filter(d => d.SSLB === 2);
              this.coursecheckedlist = this.dalist.filter(d => d.SSLB === 3);
              var checkedlist = r.Target.XKList;
              this.xklist = [];
              if (checkedlist !== null && checkedlist.length > 0) {
                for (var i = 0; i < checkedlist.length; i++) {
                  this.xklist.push(
                    checkedlist[i].KCId + "^" + checkedlist[i].NJId
                  );
                }
                this.njlist = this.njlist.filter(
                  c => checkedlist.filter(s => s.NJId === c.NJId).length > 0
                );
              }
            }
          }
        );
      } else {
        this.$set(this, "tmlist", []);
        this.$set(this, "dalist", []);
        this.$set(this, "xklist", []);
      }
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/survey/GetGradeCourse",
        { SSXX: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.njlist = [];
            this.njlist = r.Target;
          }
        }
      );
    }
  },
  data() {
    return {
      isDisabled: false,
      ssxx: "",
      ssxq: "",
      token: "",
      njlist: [],
      tmlist: [],
      dalist: [],
      schoolcheckedlist: [],
      classcheckedlist: [],
      coursecheckedlist: [],
      xklist: [],
      cjr: "",
      surveyinfo: {
        Id: "",
        CJR: "",
        CJSJ: "",
        HDMC: "",
        BZ: "",
        KSSJ: "",
        JSSJ: ""
      }
    };
  }
};
</script>
