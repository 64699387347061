<template>
	<div>
		<el-radio-group v-model="selectData" class="selectTemplates">
			<div v-for="item in templates" :key="item.value" style="margin:0 10px 10px 0">
				<el-image style="width: 150px; height: 170px;background:#f5f7fa;cursor:pointer" src="" fit="cover"
					@click.native="selectData = item.value">
					<div slot="error" class="image-slot">
						<i class="el-icon-picture-outline"></i>
					</div>
				</el-image>
				<el-radio style="text-align: center;width: 100%;" :label="item.value">{{ item.label }}</el-radio>
			</div>
		</el-radio-group>
		<div class="templates-center">
			<el-button type="primary" @click="confirm">确定</el-button>
			<el-button @click="close">返回</el-button>
		</div>
	</div>
</template>

<script>
	// import Pager from "@/components/Pager.vue";
	export default {
		components: {
			// Pager
		},
		data() {
			return {
				pagerInfo: {
					pageIndex: 0,
					pageSize: 2,
					totalCount: 21
				},
				selectData: undefined
			};
		},
		props: {
			showTemplateDialog: {
				type: Boolean,
				default: false
			},
			templates: {
				type: Array,
				default: () => {
					return [];
				}
			}
		},
		created() {},
		methods: {
			changeIndex(val) {
				this.pagerInfo.pageIndex = val;
			},
			confirm() {
				this.$emit("selectTemplate", this.selectData);
			},
			close() {
				this.$emit("selectTemplate");
			}
		},
		watch: {
			showTemplateDialog() {
				this.selectData = undefined;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.el-image {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.el-image::v-deep .image-slot {
		font-size: 30px;
		color: #909399;
	}

	.selectTemplates {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
	}

	.templates-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>