<template>
<div>
  <div class="box" v-for="(page,i) in tableRow" :key="i">
    <div class="box-top">{{pageSubtitle}}</div>
    <el-table :data="page.tableData" border>
      <el-table-column  width="70" prop="xh" label="序号"></el-table-column>
      <el-table-column v-for="(item,index) in heda" :key="index" :prop="item.prop"  :label="item.label"
       :sortable="index == heda.length-1? true:false">
        <el-table-column v-for="(ch,ind) in item.children" :key="ind" :prop="ch.prop" :label="ch.label">
        </el-table-column>
    </el-table-column>
  </el-table>
  </div>
</div>
</template>

<script>
export default {
  props:{
    heda: {
      type: Array,
      default: function() {
          return [];
      }
    },
    tableRow: {
        type: Array,
        default: function() {
            return [];
        }
    },
    pageSubtitle:String

    },
  created(){
    
  },
  data(){
    return{
      arr:[]
    }
  },
}
</script>

<style lang="scss" scoped>
.el-table{
    border-color:black ;
    border-right: 1px solid black!important;
    border-bottom: 1px solid black!important;
  ::v-deep .el-table__body-wrapper{
      .el-table__row{
          .el-table__cell{
              padding:  5px 0;
              color: black;
              border-color:black ;
              .cell{
                  padding: 0 5px;
              }
          }
      }
  }
  ::v-deep .el-table__header{
      .el-table__cell{
          padding:  5px 0;
          color: black;
          border-color:black ;
          .cell{
                  padding: 0 5px;
              }
      }
  }
}
.box{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  width: 297mm;
  height: 210mm;
  overflow: hidden;
    .box-top{
      font-size: 20px;
      color: black;
      font-weight: 600;
      padding:15px 5px;
      margin: 0 auto 18px;
    }
}
</style>