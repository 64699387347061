<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="600px"
    top="10%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      label-width="110px"
    >
      <el-form-item label="子活动名称" prop="PJHDMC">
        <el-input v-model="form.PJHDMC"></el-input>
      </el-form-item>
      <el-form-item label="选择对象" prop="PJHDGLCYDXList">
        {{
          form.PJHDGLCYDXList
            ? objectList
                .filter(c => form.PJHDGLCYDXList.includes(c.Id) && c.SYZT === 1)
                .map(c => c.PJCYDXMC)
                .join(',')
            : ''
        }}
        <el-link type="primary" :underline="false" @click="innerVisible = true"
          >选择</el-link
        >
      </el-form-item>
      <el-form-item
        v-if="currentActivity.HDLX === 1"
        :key="currentActivity.HDLX"
        label="活动开始日期"
        prop="KSSJ"
      >
        <el-date-picker
          style="width:440px"
          v-model="form.KSSJ"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动描述" prop="BZ">
        <el-input type="textarea" :rows="4" v-model="form.BZ"></el-input>
      </el-form-item>
      <el-form-item label="活动排序" prop="PXH">
        <el-input-number
          v-model="form.PXH"
          :min="1"
          :max="100"
          :precision="0"
          :step="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="状态" prop="SYZT">
        <el-switch
          v-model="form.SYZT"
          :disabled="!form.Id"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
          active-text="启用"
          inactive-text="禁用"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否结束" prop="SFJSSY">
        <el-switch
          v-model="form.SFJSSY"
          :disabled="!form.Id"
          active-color="#ff4949"
          inactive-color="#13ce66"
          :active-value="1"
          :inactive-value="2"
          active-text="结束"
          inactive-text="开启"
        >
        </el-switch>
      </el-form-item>

      <el-form-item label="活动图片">
        <Upload
          class="c-guree el-link--inner"
          @getResultUrl="saveImg"
          :itemPic="form.HDTP"
          :html="uploadBtnHtml"
          :width="150"
          :height="150"
          :left="50"
          :top="20"
          :circle="true"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>

    <el-dialog
      width="80%"
      top="12%"
      title="选择对象"
      :visible.sync="innerVisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <div>已选择：</div>
      <el-tag
        v-for="item in currentSetObject"
        :key="item"
        closable
        style="margin-right:10px"
        class="objtag"
        @close="delObject(item)"
      >
        {{
          objectList.find(c => c.Id === item)
            ? objectList.find(c => c.Id === item).PJCYDXMC
            : ''
        }}
      </el-tag>
      <div class="popo-ovebox-titbox" style="padding-top: 40px; width: 100%;">
        <div class="popo-ovebox-tit">主评对象</div>
        <div class="popo-ovebox-l" style="background: unset;">
          <el-checkbox-group v-model="currentSetObject">
            <el-checkbox
              :label="item.Id"
              v-for="item in objectList.filter(
                c => c.HDXS === 4 && c.SYZT === 1
              )"
              :key="item.Id"
              >{{ item.PJCYDXMC }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div
          style="color:#ccc"
          v-show="objectList.filter(c => c.HDXS === 4).length === 0"
        >
          暂无主评对象
        </div>
      </div>
      <div
        v-show="hdxs.includes(2)"
        class="popo-ovebox-titbox"
        style="width: 100%;"
      >
        <div class="popo-ovebox-tit">互评对象</div>
        <div class="popo-ovebox-l" style="background: unset;">
          <el-checkbox-group v-model="currentSetObject">
            <el-checkbox
              :label="item.Id"
              v-for="item in objectList.filter(
                c => c.HDXS === 2 && c.SYZT === 1
              )"
              :key="item.Id"
              >{{ item.PJCYDXMC }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div
          style="color:#ccc"
          v-show="objectList.filter(c => c.HDXS === 2).length === 0"
        >
          暂无互评对象
        </div>
      </div>
      <div class="popo-ovebox-titbox" style="width: 100%;">
        <div class="popo-ovebox-tit">自评对象</div>
        <div class="popo-ovebox-l" style="background: unset;">
          <el-checkbox-group v-model="currentSetObject">
            <el-checkbox
              :label="item.Id"
              v-for="item in objectList.filter(
                c => c.HDXS === 1 && c.SYZT === 1
              )"
              :key="item.Id"
              >{{ item.PJCYDXMC }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div
          style="color:#ccc"
          v-show="objectList.filter(c => c.HDXS === 1).length === 0"
        >
          暂无自评对象
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSetObject">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import Upload from '@/components/Upload.vue'
import { formatDate } from '@/utils/date.js'

export default {
  components: {
    Upload
  },
  data() {
    let _that = this
    return {
      uploadBtnHtml:
        '<el-link type="primary" :underline="false">选择图片</el-link>',
      innerVisible: false,
      currentSetObject: [],
      form: {},
      rules: {
        PJHDMC: [
          { required: true, message: '请输入活动名称', trigger: 'change' },
          {
            min: 1,
            max: 50,
            message: '长度需在 1 到 50 个字符',
            trigger: 'change'
          }
        ],
        KSSJ: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        BZ: [
          { required: true, message: '活动描述', trigger: 'change' },
          {
            min: 1,
            max: 500,
            message: '长度需在 1 到 500 个字符',
            trigger: 'change'
          }
        ],
        PJHDGLCYDXList: [
          { required: true, message: '对象不能为空', trigger: 'change' }
        ]
      }
    }
  },
  props: {
    formData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    objectList: {
      type: Array,
      default: function() {
        return []
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    PJFS: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    token: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: function() {
        return {}
      }
    },
    schoolId: {
      type: String,
      default: ''
    },
    hdxs: {
      type: Array
    },
    mainActivityId: {
      type: String,
      default: ''
    },
    currentActivity: {
      type: Object,
      default() {
        return {}
      }
    },
    term: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    init() {
      this.form = JSON.parse(JSON.stringify(this.formData))
      this.form.HDId = this.mainActivityId
      this.$set(this.form, 'KSSJ', this.form.KSSJ || this.term.BeginTime)
      if (!this.form.Id) {
        this.$set(this.form, 'SYZT', 1)
        this.$set(this.form, 'SFJSSY', 2)
      }

      if (!this.form.PJHDGLCYDXList) {
        this.$set(this.form, 'PJHDGLCYDXList', JSON.parse(JSON.stringify([])))
      }
      this.$set(
        this,
        'currentSetObject',
        JSON.parse(JSON.stringify(this.form.PJHDGLCYDXList))
      )
    },
    closeDialog() {
      this.$confirm('确认关闭？').then(() => {
        this.$emit('closeDialog')
      })
    },
    confirmDialog() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.currentActivity.HDLX !== 1) {
            this.form.KSSJ = this.term.BeginTime
          }

          let YHZJHDInfo = {
            ...this.form,
            JSSJ: null
          }

          this.$api.post(
            '/v1/evaluationmainactivity/EditActivity',
            {
              token: this.token,
              YHZJHDInfo,
              PJHDGLCYDXList: this.form.PJHDGLCYDXList,
              SchoolId: this.schoolId,
              UserId: this.user.Id,
              UserName: this.user.XSM,
              TermId: this.term.Id //termId是下拉框选择的id term是当前学期
            },
            r => {
              if (r.Code === 0) {
                const data = r.Target
                this.$message({
                  type: 'success',
                  message: r.Message
                })
                this.$emit('closeDialog', data)
              }
            }
          )
        } else {
          return false
        }
      })
    },
    saveImg(filePath) {
      this.form.HDTP = filePath
    },
    delObject(data) {
      this.currentSetObject.splice(
        this.currentSetObject.findIndex(c => c === data),
        1
      )
    },
    saveSetObject() {
      this.$set(
        this.form,
        'PJHDGLCYDXList',
        JSON.parse(JSON.stringify(this.currentSetObject))
      )
      this.innerVisible = false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init()

        // 显示
      } else {
        this.$refs.form.resetFields()
        // 隐藏
      }
    }
  }
}
</script>
