<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="margno-t">
        <div class="table-er-box">
          <el-button @click="opendialog">新增</el-button>
        </div>
        <!-- :tableData = "tableData" -->
        <Table  
          @editItem="editItem"
          @delItem="delItem"
        />
      </div>
    </div>
    <!-- 新增编辑弹窗 :formData="formData"-->
    <Dialog @canceldialog="dialogVisible = false" @sumbitForm="sumbitForm"
      :dialogTitle="dialogTitle"  v-model="dialogVisible"/>
  </div>
</template>

<script>
import Table from './Table.vue'
import Dialog from './Dialog.vue'
export default {
    components:{Table,Dialog},
    data(){
        return{
            dialogVisible:false,
            dialogTitle:''
        }
    },
    methods:{
        opendialog(){
            this.dialogVisible = true
            this.dialogTitle = '新增'
        },
        sumbitForm(){

        },
        editItem(){

        },
        delItem(){

        }

    }
}
</script>

<style>

</style>