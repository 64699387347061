<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <div class="leftrig-left-box" style="width: 150px;">
        <!--机构树-->
        <el-tree
          :props="defaultProps"
          :data="treeData"
          :highlight-current="true"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="leftrig-rig-box">
        <!--表格区开始-->
        <div class="tab-heng-box margno-t">
          <div class="table-er-box">
            <el-button type="primary" icon="el-icon-edit" @click="addJG"
              >新增机构</el-button
            >
          </div>
        </div>
        <el-table :data="tableData.subnodeList" style="width: 100%">
          <el-table-column prop="name" label="子机构名称" width="180" />
          <el-table-column
            label="组长"
            prop="managerStr"
            width="180"
            v-if="
              currentTreeNode.key !== '0' &&
                currentTreeNode.type &&
                currentTreeNode.type === 1
            "
          />
          <el-table-column
            label="组员"
            prop="menberStr"
            v-if="
              currentTreeNode.key !== '0' &&
                currentTreeNode.type &&
                currentTreeNode.type === 1
            "
          />

          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button type="text" size="mini" @click="edit(row)"
                >编辑</el-button
              >
              <el-button
                class="tab-wz-butta c-red"
                type="text"
                size="mini"
                @click="del(row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--表格区结束-->
      </div>
    </div>

    <!-- 表单 -->
    <Form
      :visible.sync="formVisible"
      @closeForm="closeForm"
      :formData="formData"
      :currentTreeNode="currentTreeNode"
    />
  </div>
</template>

<script>
import Form from "@/views/organization/form.vue";
export default {
  name: "Organization",
  components: {
    Form
  },
  data() {
    return {
      token: this.$store.state.token,
      treeData: [
        {
          key: "0",
          name: "全部机构",
          parentId: null,
          subnodeList: []
        }
      ],
      defaultProps: {
        children: "subnodeList",
        label: "name"
      },
      // 点击过的机构树且被保存起来的
      currentTreeNode: {},
      leafNode: {}, //当前点击的时候子节点
      tableData: [],
      // 表单显隐
      formVisible: false,
      formData: {}
    };
  },
  created() {
    this.getJGtree();
  },
  methods: {
    // tree选中
    handleNodeClick(data) {
      if (data.type !== 2) {
        this.currentTreeNode = data;
        this.leafNode = {};
      } else {
        this.leafNode = data;
      }
      if (data.type === 1 || data.key === "0") {
        this.tableData = data;
      }
    },
    // 加载tree
    getJGtree() {
      let { SSZZId: SchoolId, Token: token } = JSON.parse(
        localStorage.getItem("userinfo")
      );
      this.$api.post(
        "v1/schoolteaorg/GetXXJGTree",
        {
          ParentId: "0",
          SchoolId,
          token
        },
        res => {
          if (res.Code === 0) {
            this.treeData[0].subnodeList = res.Target;
            if (!(JSON.stringify(this.currentTreeNode) === "{}")) {
              if (this.currentTreeNode.key === "0") {
                this.currentTreeNode = this.treeData[0];
                this.tableData = this.currentTreeNode;
              }
              if (this.currentTreeNode.type === 1) {
                this.currentTreeNode = this.treeData[0].subnodeList.find(
                  item => item.key === this.currentTreeNode.key
                );
                this.tableData = this.currentTreeNode;
              }
            }

            this.treeData[0].subnodeList &&
              this.loadTree(this.treeData[0].subnodeList);
          }
        }
      );
    },
    // 递归请求子数据
    loadTree(data) {
      let { SSZZId: SchoolId, Token: token } = JSON.parse(
        localStorage.getItem("userinfo")
      );
      data.forEach(el => {
        this.$api.post(
          "v1/schoolteaorg/GetXXJGTree",
          {
            ParentId: el.key,
            SchoolId,
            token
          },
          res => {
            if (res.Code === 0) {
              if (res.Target && res.Target instanceof Array) {
                res.Target.forEach(item => {
                  item.managerStr = [];
                  item.menberStr = [];
                  if (item.jgcy && item.jgcy instanceof Array) {
                    item.jgcy.forEach(it => {
                      if (it.ZZLB === 1) item.managerStr.push(it.XM);
                    });
                    item.jgcy.forEach(it => {
                      if (it.ZZLB === 2) item.menberStr.push(it.XM);
                    });
                  }
                  item.managerStr = item.managerStr.join();
                  item.menberStr = item.menberStr.join();
                });
              }
              el.subnodeList = res.Target;
            }
          }
        );
      });
    },
    addJG() {
      if (JSON.stringify(this.currentTreeNode) === "{}")
        this.$message("请选择父级机构");
      if (
        (this.currentTreeNode.key === "0" || this.currentTreeNode.type === 1) &&
        JSON.stringify(this.leafNode) === "{}"
      ) {
        this.formData = {};
        this.formVisible = true;
      }
      if (JSON.stringify(this.leafNode) !== "{}")
        this.$message("只有机构目录才能添加机构");
    },
    edit(row) {
      this.formData = row;
      this.formVisible = true;
    },
    del(row) {
      this.$confirm("此操作将永久删除该机构及其子机构, 是否继续?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "v1/schoolteaorg/DeleteXXJG",
            {
              Id: row.key,
              UserId: JSON.parse(localStorage.getItem("userinfo")).Id,
              token: JSON.parse(localStorage.getItem("userinfo")).Token
            },
            () => {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.getJGtree();
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    closeForm(type) {
      if (type === 1) {
        this.getJGtree();
      }
      this.formVisible = false;
    }
  }
};
</script>
