<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="30%"
    center
    custom-class="stars-dialog"
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="200px"
      class="demo-ruleForm"
      status-icon
      label-position="right"
    >
      <el-form-item
        :label-width="labelWidth"
        label="指标名称"
        prop="ZBMC"
        class="el-item"
      >
        <el-input
          v-model="formData.ZBMC"
          placeholder="不能超过20个字"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="星星个数"
        prop="XXGS"
        class="el-item"
      >
        <el-input v-model="formData.XXGS"></el-input>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="每颗星的分数"
        prop="XXFS"
        class="el-item"
      >
        <el-input v-model="formData.XXFS"></el-input>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="指标满星数"
        prop="ZBZGF"
        class="el-item ZBZGF"
      >
        <el-input-number
          size="small"
          style="width:210px"
          :min="form.XXGS"
          :max="10000"
          :precision="0"
          v-model="formData.ZBZGF"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label-width="labelWidth"
        label="末级维度"
        prop="SSWD"
        class="el-item"
      >
        <el-cascader
          :disabled="dimensionsSelectDisable"
          :props="{ expandTrigger: 'hover' }"
          v-model="formData.SSWD"
          :options="dimensions"
          :show-all-levels="false"
          placeholder="请选择末级维度"
          @change="handleWdSelected"
        ></el-cascader>
      </el-form-item>
      <el-form-item :label-width="labelWidth" label="排序号" class="el-item">
        <el-input v-model="formData.PXH"></el-input>
      </el-form-item>
      <el-form-item :label-width="labelWidth" label="备注" class="el-item">
        <el-input type="textarea" v-model="formData.BZ"></el-input>
      </el-form-item>
      <el-form-item
        label="注释"
        :label-width="labelWidth"
        prop="ZS"
        class="el-item"
      >
        <el-input
          type="textarea"
          :maxlength="200"
          :rows="4"
          placeholder="请输入内容，最多200个字符"
          v-model="formData.ZS"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  isInteger,
  isFloat,
  isNumber,
  isUintNumber,
  numberRange
} from '@/style/js/Validator.js'

export default {
  data() {
    return {
      labelWidth: '150px',
      formData: {},
      dialogVisiable: false,
      rules: {
        ZBMC: [
          { required: true, message: '请输入指标名称', trigger: 'change' },
          { min: 0, max: 20, message: '不能超过20个字', trigger: 'change' }
        ],
        XXGS: [
          { required: true, message: '请输入星星个数', trigger: 'change' },
          {
            validator: numberRange,
            min: 1,
            max: 5,
            message: '星星个数需为1-5',
            trigger: 'change'
          },
          {
            validator: isNumber,
            message: '星星个数必须为数字',
            trigger: 'change'
          },
          {
            validator: isInteger,
            message: '星星个数必须为正整数',
            trigger: 'change'
          }
        ],
        XXFS: [
          { required: true, message: '请输入每颗星的分数', trigger: 'change' },
          {
            validator: isUintNumber,
            message: '分数必须为自然数',
            trigger: 'change'
          },
          {
            validator: isFloat,
            message: '分数最高精确到2位小数',
            trigger: 'change'
          },
          {
            validator: numberRange,
            min: 0,
            max: 1000,
            message: '分数范围为0-1000',
            trigger: 'change'
          }
        ],
        ZBZGF: [
          {
            required: true,
            message: '请输入指标满星数',
            trigger: 'blur'
          }
        ],
        SSWD: [{ required: true, message: '请选择末级维度', trigger: 'change' }]
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: function() {
        return {}
      }
    },
    dimensions: {
      type: Array,
      default: function() {
        return []
      }
    },
    dimensionsSelectDisable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        this.rules.SSWD[0].required = !this.dimensionsSelectDisable
        this.initForm()
      } else {
        // 重置校验结果
        this.$refs.formData.resetFields()
      }
    }
  },
  methods: {
    initForm() {
      this.formData = {}
      this.formData.SSWDStr = '无'
      if (this.form && (this.form.Id || this.form.Id > 0)) {
        this.formData = JSON.parse(JSON.stringify(this.form))
      }
    },
    closeDialog() {
      this.$confirm('确认关闭？').then(() => {
        this.formData = {}
        this.$emit('closeDialog')
      })
    },
    confirmDialog() {
      const data = JSON.parse(JSON.stringify(this.formData))
      this.$refs.formData.validate(valid => {
        if (valid) {
          // eslint-disable-next-line no-unused-vars
          this.$confirm('确认提交？').then(r => {
            // 执行提交方法
            this.formData = {}
            this.$emit('confirmDialog', data)
          })
          // .catch(r => {});
        } else {
          return false
        }
      })
    },
    handleWdSelected(value) {
      if (value) {
        if (value.length > 1) {
          this.formData.SSWD = value[1]
          const wd = this.dimensions.find(c => c.value === value[0])
          const wdC = wd.children.find(c => c.value === value[1])
          this.formData.SSWDStr = wd.label + '>' + wdC.label
        } else {
          this.formData.SSWD = value[0]
          const wd = this.dimensions.find(c => c.value === value[0])
          this.formData.SSWDStr = wd.label
        }
      }
    }
  }
}
</script>
<style lang="scss">
.stars-dialog {
  min-width: 500px;
  .el-dialog__body {
    min-width: 500px;
  }
}
</style>
<style lang="scss" scoped>
.ZBZGF {
  .el-input-number {
    padding: 0;
    ::v-deep .el-input-number__decrease {
      display: none;
    }
    ::v-deep .el-input-number__increase {
      display: none;
    }
  }
}

.el-item {
  max-width: 360px;
  margin-left: 5%;
}
</style>
