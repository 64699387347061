<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + exportType">
			<div :class="'basetitle' + exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + exportType">
				<div :class="'detailinfo' + exportType">
					<!-- 学生信息 -->
					<div :class="'xsdiv' + exportType">
						<img :src="xsinfo.ZP" :class="'studenticon' + exportType" />
						<div :class="'xsinfodiv' + exportType">
							<div :class="'xsinfotitle' + exportType">
								{{ xsinfo.XM }}
							</div>
							<br />
							<div :class="'xsinfoclass' + exportType">
								{{ xsinfo.BJMC }}
							</div>
						</div>
					</div>
					<!-- 总分 -->
					<div :class="'totalscore' + exportType">
						<div :class="'scoretext' + exportType">
							{{ xsinfo.ZFS }}
						</div>
						<div :class="'scoredesc' + exportType">
							总分数
						</div>
					</div>
					<!-- 指标 -->
					<div :class="'index-list' + exportType">
						<div :class="'index-item' + exportType" v-for="(mode, index) in activitylist" :key="index">
							<div :class="'title' + exportType">
								<img :src="mode.img" alt="" v-if="mode.img" />
								<div :class="'name' + exportType">{{ mode.name }}</div>
							</div>
							<div :class="'content' + exportType">
								<div class="type">{{ mode.type }}</div>
								<div class="score">{{ mode.score }}</div>
							</div>
						</div>
						<!-- 活动 -->
						<div :class="'act-list' + exportType">
							<div :class="'act-item' + exportType" v-for="(item,index) in zblist" :key="index">
								<div class="num">{{item.Value}}次</div>
								<div class="text">{{item.Name}}</div>
							</div>
						</div>
						<!-- 图片 -->
						<div :class="'img-list' + exportType">
							<img :src="item" alt="" v-for="(item,index) in imgs" :key="index" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "家庭实践活动"
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					ZFS: "0"
				},
				activitylist: [],
				zblist: [],
				imgs: []
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				// this.xsinfo.SSNJ = xsdata.SSNJ;
				// this.xsinfo.NJ = xsdata.NJ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}

				// this.xsinfo.ZFS = "3.0";

				var template = JSON.parse(
					localStorage.getItem("schooltemplate_" + this.xqid)
				).ZHSY;
				// console.log(template)
				var templatedata = JSON.parse(xsdata.ZHSY);
				// console.log(templatedata)
				if (templatedata) {
					let template_temp = template.WDBG.find(
						o => o.Id == "W" + this.info.GLId[0]
					);
					let subdata_temp = templatedata.find(
						o => o.Id == "W" + this.info.GLId[0]
					);
					// console.log(template_temp, "template_temp");
					// console.log(subdata_temp, "subdata_temp");
					if (template_temp && subdata_temp) {
						let hd_template_temp = template_temp.Hd.find(
							o => o.Id == "E" + this.info.GLId[1]
						);
						let hd_data_temp = subdata_temp.WDBG.find(
							o => o.Id == "E" + this.info.GLId[1]
						);

						// console.log(hd_template_temp, "hd_template_temp");
						// console.log(hd_data_temp, "hd_data_temp");
						this.xsinfo.ZFS = hd_data_temp ? parseFloat(hd_data_temp.Score).toFixed(1) : 0;

						this.activitylist = [];
						if (hd_data_temp) {
							// console.log(hd_data_temp)
							for (let i = 0; i < hd_data_temp.TYOrXXPJ.length; i++) {
								if (this.activitylist.length < 2) {
									let temp_template = hd_template_temp.TYOrXXPJ.find(
										o => o.Id == hd_data_temp.TYOrXXPJ[i].Id
									);
									if (temp_template) {
										let obj = new Object();
										obj.name = temp_template.Name;
										obj.img = temp_template.Image;
										if (hd_data_temp.TYOrXXPJ[i].PJXS == 1) {
											obj.type = "自评";
										} else if (hd_data_temp.TYOrXXPJ[i].PJXS == 2) {
											obj.type = "互评";
										} else {
											obj.type = "主评";
										}

										obj.score = hd_data_temp.TYOrXXPJ[i].Value;

										this.activitylist.push(obj);
									}
								}
							}
							this.imgs = [];
							if (hd_data_temp.Images) {
								let image = hd_data_temp.Images.split(',');
								for (let i = 0; i < image.length; i++) {
									if (this.imgs.length < 6) {
										this.imgs.push(image[i]);
									}
								}
							}

							this.zblist = [];
							if (hd_data_temp.zblb) {
								// console.log(hd_data_temp.zblb)
								let qtobj = [];
								for (let i = 0; i < hd_data_temp.zblb.length; i++) {
									if (this.zblist.length < 6) {
										if (hd_data_temp.zblb[i].Id == 'qt') {
											let zb = {
												Name: '其他',
												Value: hd_data_temp.zblb[i].Value
											};
											qtobj.push(zb);
										} else {
											let temp_template = hd_template_temp.TYOrXXPJ.find(o => o.Id == hd_data_temp
												.zblb[i].Id);
											if (temp_template) {
												let zb = {
													Name: temp_template.Name,
													Value: hd_data_temp.zblb[i].Value
												};
												this.zblist.push(zb);
											}
										}
									}
								}
								this.zblist.sort((a, b) => b.Value - a.Value);
								if (qtobj && qtobj.length > 0) {
									this.zblist.push(qtobj[0]);
								}
							}
						}
					}
				}
				// let obj = new Object();
				// obj.name = '课堂礼仪';
				// obj.type = '主评';

				// obj.score = 4.5;

				// this.activitylist.push(obj);
				// let obj1 = new Object();
				// obj1.name = '整洁度';
				// obj1.type = '自评';

				// obj1.score = 0.5;

				// this.activitylist.push(obj1);

				// this.getxsreportdata();
				// this.gettotalscore();
			},
			getxsreportdata: function() {
				let pageinfo = {
					PageIndex: 1,
					PageSize: 2
				};
				this.$api.post(
					"v1/evaluationreport/GetStudentStarRecent", {
						HDId: this.info.GLId,
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xsinfo.SSXQ,
						XSId: this.xsinfo.Id,
						token: this.token,
						PagerInfo: pageinfo
					},
					r => {
						if (r.Code == 0) {
							var templist = r.Target;
							this.activitylist = [];
							if (
								templist != null &&
								templist != undefined &&
								templist.length > 0
							) {
								for (var i = 0; i < templist.length; i++) {
									if (
										templist[i].IndexList != null &&
										templist[i].IndexList != undefined &&
										templist[i].IndexList.length > 0
									) {
										templist[i].DXS = templist[i].IndexList.reduce(function(
												total,
												item
											) {
												return item.DXS + total;
											},
											0);
									} else {
										templist[i].DXS = 0;
									}
									templist[i].PJNR = "这是一条无用的描述消息";
									this.activitylist.push(templist[i]);
								}
							}
						}
					}
				);
			},
			gettotalscore: function() {
				this.$api.post(
					"v1/evaluationreport/GetStudentTotalStar", {
						HDId: this.info.GLId,
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xsinfo.SSXQ,
						XSId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							this.xsinfo.ZFS = r.Target.DXS;
						}
					}
				);
			}
		},
		mounted: function() {},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 120px;
		height: 120px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 20px;
		border-radius: 50%;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 20px;
		border-radius: 50%;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		// background-image: url("../../../../style/growth/rectangle_back.png");
		background-image: url("../../../../style/growth/blue_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 20px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		// background-image: url("../../../../style/growth/rectangle_back.png");
		background-image: url("../../../../style/growth/blue_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 720px;
		width: 100%;
	}

	.xsdiv1 {
		height: 170px;
		width: 400px;
	}

	.xsdiv2 {
		height: 120px;
		width: 430px;
	}

	.detailinfo1 {
		position: relative;
		// background: #2caeff;
		// background-image: url("../../../../style/growth/blue_back.png");
		border: 0px solid #cbe0fd;
		border-radius: 25px;
		width: 800px;
		height: 980px;
		margin-left: 20px;
	}

	.detailinfo2 {
		position: relative;
		// background: #2caeff;
		// background-image: url("../../../../style/growth/blue_back.png");
		border: 0px solid #cbe0fd;
		border-radius: 15px;
		width: 570px;
		height: 712px;
		margin-left: 10px;
	}

	.xsinfodiv1 {
		margin-top: 40px;
		margin-left: 20px;
		float: left;
	}

	.xsinfodiv2 {
		margin-top: 30px;
		margin-left: 10px;
		float: left;
	}

	.xsinfotitle1 {
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 1.5;
	}

	.xsinfotitle2 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}

	.xsinfoclass1 {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #000000;
		line-height: 1.5;
		opacity: 0.8;
	}

	.xsinfoclass2 {
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #000000;
		line-height: 20px;
		opacity: 0.8;
		margin-top: -10px;
	}

	.indexscore1 {
		width: 30px;
		height: 30px;
		line-height: 30px;
		margin: 0 0 0 5px;
	}

	.indexscore2 {
		width: 25px;
		height: 25px;
		line-height: 25px;
		margin: 0 0 0 3px;
	}

	.activityname1 {
		width: 400px;
		height: 40px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 50px;
	}

	.activityname2 {
		width: 300px;
		height: 35px;
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 40px;
	}

	.activityscore1 {
		width: 100px;
		height: 40px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		color: #333333;
		line-height: 40px;
	}

	.activityscore2 {
		width: 100px;
		height: 40px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		color: #333333;
		line-height: 40px;
	}

	.indexname1 {
		width: 120px;
		height: 25px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 350;
		color: #333333;
		line-height: 25px;
		margin-top: 3px;
	}

	.indexname2 {
		width: 100px;
		height: 20px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 350;
		color: #333333;
		line-height: 20px;
		margin-top: 3px;
	}

	.performancedetaildiv1 {
		width: 760px;
		margin: 0 10px 10px 10px;
		max-height: 303px;
		background: #ffffff;
		border-radius: 20px;
		padding: 10px 10px 0 10px;
	}

	.performancedetaildiv2 {
		width: 530px;
		margin: 0 10px 10px 10px;
		max-height: 210px;
		background: #ffffff;
		border-radius: 15px;
		padding: 10px 10px 0 10px;
	}

	.totalscore1 {
		position: absolute;
		left: 50%;
		top: 100px;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 483px;
		text-align: center;
		height: 249px;
		padding-top: 5px;
		background-image: url("../../../../style/growth/score_back2.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 10px -16px;

	}

	.totalscore2 {
		position: absolute;
		left: 50%;
		top: 65px;
		transform: translateX(-50%);
		display: flex;
		width: 362px;
		text-align: center;
		height: 138px;
		padding-top: 5px;
		background-image: url("../../../../style/growth/score_back2.png");
		background-repeat: no-repeat;
		background-size: 100% 176px;
		background-position: 6px -33px;
		flex-direction: column;
		justify-content: center;
	}

	.scoretext1 {
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #22a049;
		line-height: normal;
	}

	.scoretext2 {
		font-size: 33px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #22a049;
		line-height: normal;
	}

	.scoredesc1 {
		width: 100%;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #666666;
	}

	.scoredesc2 {
		width: 100%;
		font-size: 10px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #666666;
	}

	.activitydesc1 {
		width: 770px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		padding: 10px 5px;
		color: #080808;
		line-height: 30px;
	}

	.activitydesc2 {
		width: 540px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		padding: 10px 5px;
		color: #080808;
		line-height: 20px;
	}

	.attachimage1 {
		width: 246.66px;
		height: 185px;
		margin: 10px 5px 0 5px;
	}

	.attachimage2 {
		width: 166.66px;
		height: 120px;
		margin: 10px 5px 0 5px;
	}

	.pjricon1 {
		width: 50px;
		height: 50px;
		border-radius: 15px;
	}

	.pjricon2 {
		width: 40px;
		height: 40px;
		border-radius: 10px;
	}

	// 指标
	.index-list1 {
		height: calc(100% - 350px);
		width: 780px;
		margin: 172px auto 0;
		border-radius: 10px;
		background-color: #fff;

		.index-item1 {
			padding: 10px 35px 0;

			.title1 {
				display: flex;
				align-items: center;
				font-size: 25px;

				img {
					width: 35px;
					height: 35px;
					margin-right: 15px;
				}
			}

			.content1 {
				font-size: 20px;
				padding: 0 20px 0 50px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		// 活动
		.act-list1 {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			margin-top: 15px;
			padding-top: 10px;

			.act-item1 {
				width: 25%;
				height: 90px;
				margin-bottom: 10px;
				color: #fff;
				// background-color: #71b603;
				background-image: url("../../../../style/growth/green.png");
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
				padding: 10px;
				font-size: 30px;
			}
		}

		.img-list1 {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			img {
				width: 27%;
				height: 100px;
				margin: 0 10px;
				margin-bottom: 10px;
			}
		}
	}

	// 指标
	.index-list2 {
		height: calc(100% - 230px);
		width: 540px;
		margin: 94px auto 0;
		border-radius: 10px;
		background-color: #fff;

		.index-item2 {
			padding: 3px 28px 0;

			.title2 {
				display: flex;
				align-items: center;
				font-size: 20px;

				img {
					width: 35px;
					height: 35px;
					margin-right: 15px;
				}
			}

			.content2 {
				font-size: 16px;
				padding: 0 20px 0 50px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		// 活动
		.act-list2 {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			margin-top: -5px;
			padding-top: 10px;

			.act-item2 {
				width: 24%;
				height: 65px;
				margin-bottom: 8px;
				color: #fff;
				background-color: #71b603;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
				padding: 10px;
				font-size: 20px;
			}
		}

		.img-list2 {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			img {
				width: 27%;
				height: 83px;
				margin: 0 10px;
				margin-bottom: 10px;
			}
		}
	}
</style>