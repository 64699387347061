import Vue from "vue";
import store from "@/store";

/**
 * 判断用户是否拥有操作权限
 * 根据传入的权限标识，查看是否存在用户权限标识集合
 * @param perm
 */

Vue.prototype.hasPermission = function(perm) {
  if (!perm.length) return true;
  const permissions = JSON.parse(store.state.permissions);
  if (permissions === null || permissions.length <= 0) return false;
  return permissions.find(c => c.sqbs === perm);
};

/**
 * 获取用户的按钮权限殂
 * 根据传入的用户Id，获取按钮权限列表
 * @param userid
 */
Vue.prototype.getUserPermission = function(userid) {
  this.$api.post(
    "v1/perm/GetUserButtionPermList",
    { token: this.$store.state.token, UserId: userid },
    r => {
      this.$store.commit("savePermission", JSON.stringify(r.target));
    },
    () => {}
  );
};

/**
 * 获取用户的菜单列表
 * 根据传入的用户Id，获取用户的菜单自定义列表
 * @param userid
 */
Vue.prototype.getUserMenu = function(userid) {
  this.$api.post(
    "v1/perm/GetUserMenuList",
    { token: this.$store.state.token, UserId: userid },
    () => {
      const indexroute = [];
      indexroute.push({
        path: "/index",
        name: "首页",
        component: resolve => require(["@/views/Index.vue"], resolve),
        meta: { title: "首页" },
        // children: dataRouter
        children: []
      });
      indexroute.push({
        path: "/*",
        name: "404",
        component: resolve => require(["@/views/404.vue"], resolve),
        hidden: true
      });
      localStorage.removeItem("saveMenuList");
      this.$store.commit("saveMenuList", JSON.stringify(indexroute));
      this.$router.addRoutes(indexroute);
      this.$router.options.routes = indexroute.concat(this.$sysroutes);
    },
    () => {}
  );
};
