<template>
  <div class="other-set">
    <div class="top-button">
      <el-button type="primary" size="small" @click="add_edit(1, 'interior')"
        >新增系统内部活动</el-button
      >
      <el-button type="primary" size="small" @click="add_edit(1, 'external')"
        >新增外联活动</el-button
      >
      <!-- <el-input v-model="search" placeholder="请输入内容"></el-input>
       <el-button icon="el-icon-search" circle @click="handleSearch"></el-button> -->
    </div>
    <el-table :data="tableData" stripe>
      <el-table-column prop="XH" label="排序"> </el-table-column>
      <el-table-column prop="XSMC" label="显示名称"> </el-table-column>
      <el-table-column label="对应活动">
        <template slot-scope="scope">
          <span>{{ scope.row.DYHD | handleActivityName(VM) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="活动类型">
        <template slot-scope="scope">
          <span>{{ scope.row.HDLX === 1 ? "内部活动" : "外联活动" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="add_edit(2, scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" size="small" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增/编辑内部活动 -->
    <el-dialog
      :title="interior.title"
      :visible.sync="interior.show"
      width="50%"
    >
      <el-form
        label-width="80px"
        class="interior-form"
        :model="interior.form"
        :rules="interior.rules"
        ref="interiorForm"
      >
        <el-form-item label="显示名称" prop="XSMC">
          <el-input v-model="interior.form.XSMC" :max="32"></el-input>
        </el-form-item>
        <el-form-item label="对应活动" prop="DYHD">
          <el-select
            v-model="interior.form.DYHD"
            filterable
            placeholder="请选择活动"
          >
            <el-option
              v-for="item in activities"
              :key="item.GLId"
              :label="item.label"
              :value="item.GLId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序号" prop="XH">
          <el-input-number
            :min="1"
            :max="10000"
            :precision="0"
            v-model="interior.form.XH"
          />
        </el-form-item>
        <el-form-item label="展示方式" prop="ZSFS">
          <el-select v-model="interior.form.ZSFS" placeholder="请选择展示方式">
            <el-option
              v-for="item in ZSFSoption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="level"
          v-show="interior.form.ZSFS === 2"
          :key="interior.form.ZSFS"
          :prop="interior.form.ZSFS === 2 ? 'ZSDJId' : ''"
        >
          <el-radio-group v-model="interior.form.ZSDJId">
            <el-radio
              v-for="item in levelOption.filter(
                (item) => item.GLDJLX !== 1 && item.GLDJLX !== 2
              )"
              :key="item.Id"
              :label="item.Id"
              >{{ item.DJMC }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="interior.show = false">取 消</el-button>
        <el-button type="primary" @click="submit('interior')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增/编辑外联活动 -->
    <el-dialog
      :title="external.title"
      :visible.sync="external.show"
      width="50%"
    >
      <el-form
        label-width="80px"
        class="external-form"
        :model="external.form"
        :rules="external.rules"
        ref="externalForm"
      >
        <el-form-item label="显示名称" prop="XSMC">
          <el-input v-model="external.form.XSMC"></el-input>
        </el-form-item>
        <el-form-item label="排序号" prop="XH">
          <el-input-number
            :min="1"
            :max="10000"
            :precision="0"
            v-model="external.form.XH"
          />
        </el-form-item>
        <el-form-item label="外联地址" prop="WLDZ">
          <el-input v-model="external.form.WLDZ" />
        </el-form-item>
        <el-form-item label="外联关联">
          <el-radio v-model="external.form.ZSDJ" :label="1">
            使用入学年份+班级号+姓名(202203/李明)
          </el-radio>
          <el-radio v-model="external.form.ZSDJ" :label="2">
            使用学生身份证号(510214198006051334...)
          </el-radio>
        </el-form-item>
        <el-form-item label="外联类型">
          <el-radio v-model="external.form.WLLX" :label="1">
            pdf
          </el-radio>
          <el-radio v-model="external.form.WLLX" :label="2">
            html
          </el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="external.show = false">取 消</el-button>
        <el-button type="primary" @click="submit('external')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOtherSetTabel,
  addOtherSetForm,
  editOtherSetForm,
  delOtherSetROw,
} from "@/api/growthReportSet";

let interiorForm = {
  Id: "",
  XH: "", //	int	序号
  XSMC: "", //	string	显示名称
  DYHD: "", //	是	string	对应的活动 传名称
  ZSFS: 1, //	int	展示方式(1分数，2等级，3均无)
  ZSDJ: "",
  ZSDJId: "",
  HDLX: 1, //	int	活动类型(1内部/2外联)
  SSXX: "", //	string	所属学校Id
  UserId: "",
};
let externalForm = {
  Id: "",
  XH: "", //	int	序号
  XSMC: "", //	string	显示名称
  WLDZ: "", //否	string	外联地址URL
  ZSDJ: 1,
  HDLX: 2, //	int	活动类型(1内部/2外联)
  SSXX: "", //	string	所属学校Id
  UserId: "",
  WLLX: 1,
};

export default {
  props: {
    activities: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    levelOption: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    let checkURL = (rule, val, callback) => {
      let reg = /^((?:(?:25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d?\d))$/;
      console.log(val, "val");
      if (!reg.test(val)) {
        return callback(new Error("外联地址不规范"));
      } else return callback();
    };
    return {
      userInfo: {},
      tableData: [],
      page: {
        PageIndex: "0",
        PageSize: "99",
      },
      // 内部活动相关数据
      interior: {
        title: "新增内部活动",
        show: false,
        form: {},
        rules: {
          XSMC: [
            {
              required: true,
              max: 32,
              message: "名称不符合规范",
              trigger: "blur",
            },
          ],
          DYHD: [{ required: true, message: "请选择活动", trigger: "blur" }],
          ZSFS: [
            { required: true, message: "请选择展示方式", trigger: "blur" },
          ],
          ZSDJId: [{ required: true, message: "请选择等级", trigger: "blur" }],
        },
      },
      ZSFSoption: [
        {
          label: "分数",
          value: 1,
        },
        {
          label: "等级",
          value: 2,
        },
        {
          label: "均无",
          value: 3,
        },
      ],
      // 外联活动相关
      external: {
        show: false,
        title: "新增外联活动",
        form: {},
        rules: {
          XSMC: [
            {
              required: true,
              max: 32,
              message: "名称不符合规范",
              trigger: "blur",
            },
          ],
          WLDZ: [
            { required: true, message: "请输入外联地址", trigger: "blur" },
          ],
        },
      },
      VM: this,
    };
  },
  filters: {
    handleActivityName(val, VM) {
      let name = "";
      let activity = VM.activities.find(
        (item) => item.GLId === val || item.GLId + "" === val
      );
      if (activity) name = activity.label;
      return name;
    },
  },
  mounted() {
    let userInfo = localStorage.getItem("userinfo");
    if (userInfo) this.userInfo = JSON.parse(userInfo);

    this.interior.form = JSON.parse(JSON.stringify(interiorForm));
    this.external.form = JSON.parse(JSON.stringify(externalForm));

    this.getTableData();
  },
  methods: {
    // 获取其它设置的表格数据
    async getTableData() {
      let res = await getOtherSetTabel({
        token: this.userInfo.Token,
        PageIndex: this.page.PageIndex,
        PageSize: this.page.PageSize,
        SSXX: this.userInfo.SSZZId,
      });
      this.tableData = res.sort((a, b) => a.XH - b.XH);
    },

    // 新增/编辑内部/外联活动 a:1.新增 2.编辑 b: 内部活动/外联活动
    add_edit(a, b) {
      console.log(a, b, "ab");
      if (a === 1) {
        this[b].title = `新增${b === "interior" ? "内部" : "外联"}活动`;
        this[b].show = true;
        this[b].form = JSON.parse(
          JSON.stringify(b === "interior" ? interiorForm : externalForm)
        );
      } else {
        let type = b.HDLX === 1 ? "interior" : "external";
        Object.assign(this[type].form, b);
        this[type].title = `编辑${b.HDLX === 1 ? "内部" : "外联"}活动`;
        this[type].show = true;
      }
    },
    submit(type) {
      this[type].form.SSXX = this.userInfo.SSZZId;
      this[type].form.UserId = this.userInfo.Id;
      this[type].form.token = this.userInfo.Token;

      this.$refs[`${type}Form`].validate(async (res) => {
        if (res) {
          if (type === "interior") {
            let level = this.levelOption.find(
              (item) => item.Id === this.interior.form.ZSDJId
            );
            //"GLDJLX": 0,关联等级类型 1分数，2不展示，3关联等级 4百分百转等级 5分数转等级
            //ZSDJ 1分数转等级 2百分百转等级 3分数 4不展示 1使用入学年份 2使用学生身份证号
            if (level) {
              let val = 1;
              switch (level.GLDJLX) {
                case 1:
                  val = 3;
                  break;
                case 2:
                  val = 4;
                  break;
                case 3:
                  if (level.DJLX === 2) val = 1;
                  if (level.DJLX === 3) val = 2;
                  break;
              }
              this.interior.form.ZSDJ = val;
            } else this.interior.form.ZSDJ = "";
          }
          this[type].form.Id
            ? await editOtherSetForm({
                ...this[type].form,
                token: this.userInfo.Token,
              })
            : await addOtherSetForm({
                ...this[type].form,
                token: this.userInfo.Token,
              });

          this.$message.success("保存成功");
          this[type].form = JSON.parse(
            JSON.stringify(type === "interior" ? interiorForm : externalForm)
          );
          this.getTableData();
          this[type].show = false;
        }
      });
    },
    async del(row) {
      await delOtherSetROw({ Id: row.Id, token: this.userInfo.Token });
      this.$message.success("删除成功");
      this.getTableData();
    },
  },
  watch: {
    "external.show"(val) {
      if (!val) this.$refs.externalForm.clearValidate();
    },
    "interiorForm.show"(val) {
      if (!val) this.$refs.interiorForm.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.other-set {
  .top-button {
    padding: 10px 0;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
    margin-bottom: 15px;
  }
  .el-table {
    width: 100%;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
  }
  .interior-form {
    .el-input,
    .el-select {
      width: 100%;
    }
    .level {
      .el-radio {
        margin-top: 18px;
      }
    }
  }
}
</style>
