<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="essay-name-box">{{ tzgginfo.BT }}</div>
    </div>
    <div class="leftrig-box">
      <div class="tab-heng-box margno-t">
        <el-tabs v-model="yhlb" @tab-click="handleClick">
          <el-tab-pane v-if="tzgginfo.JSDXLX != 2" label="学生" name="2"
            >学生</el-tab-pane
          >
          <el-tab-pane
            v-if="tzgginfo.JSDXLX == 2 || tzgginfo.JSDXLX == 3"
            label="教师"
            name="1"
            >教师</el-tab-pane
          >
        </el-tabs>
        <div class="table-er-box" style="display: block;">
          <div class="table-er-butt" style="border: none;">
            <el-select
              @change="handleGradeChange"
              v-if="tzgginfo.JSDXLX != 2 && tzgginfo.JSDXLX != 4"
              style="width: 100%;"
              v-model="njid"
              placeholder="请选择年级"
            >
              <el-option value label="全部" key></el-option>
              <el-option
                v-for="nj in njlist"
                :label="nj.Name"
                :key="nj.Id"
                :value="nj.Id"
              ></el-option>
            </el-select>
            <el-select
              @change="handleClassChange"
              v-if="tzgginfo.JSDXLX != 2 && tzgginfo.JSDXLX != 4"
              style="width: 100%; margin-left: 10px;"
              v-model="bjid"
              placeholder="请选择班级"
            >
              <el-option value label="全部" key></el-option>
              <el-option
                v-for="bj in bjlist"
                :label="bj.Name"
                :key="bj.Id"
                :value="bj.Id"
              ></el-option>
            </el-select>
            <el-select
              style="width: 100%; margin-left: 10px;"
              v-model="readstatus"
              placeholder="请选择"
              @change="handleReadStatusChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div
            style="
                font-size: 14px;
                margin-top: 10px;
                margin-right: 10px;
                display: block;
                float: right;
              "
          >
            <span style="color: #14d089; margin-left: 5px; cursor: pointer;"
              >{{ wds }}人未阅，{{ yds }}人已阅</span
            >
          </div>
        </div>
        <el-table
          border
          empty-text="暂无数据"
          :data="reportlist"
          highlight-current-row
          style="width: 100%;"
        >
          <el-table-column
            align="center"
            v-if="yhlb == 2"
            label="班级"
            property="ZZJGMC"
          ></el-table-column>
          <el-table-column
            align="center"
            label="姓名"
            property="CYMC"
          ></el-table-column>
          <el-table-column align="center" label="状态">{{
            readstatusstr
          }}</el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="rowcount"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    this.tzgginfo.Id = this.$route.params.ggid;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
    }
  },
  computed: {},
  mounted: function() {
    this.loaddata();
  },
  methods: {
    // 翻页
    handleSizeChange(val) {
      this.pagesize = val;
      this.loadreportdata();
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.loadreportdata();
    },
    handleClick: function() {
      this.njid = "";
      this.bjid = "";
      this.loadreportdata();
    },
    handleGradeChange: function() {
      this.pageIndex = 1;
      this.loadbjdata();
      this.loadreportdata();
    },
    handleClassChange: function() {
      this.pageIndex = 1;
      this.loadreportdata();
    },
    handleReadStatusChange: function() {
      if (this.readstatus === 1) {
        this.readstatusstr = "已阅";
      } else {
        this.readstatusstr = "未阅";
      }
      this.pageIndex = 1;
      this.loadreportdata();
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolOrg",
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.njlist = [];
            var list = r.Target;
            this.zzjglist = list;
            for (var i = 0; i < list.length; i++) {
              if (list[i].Hierarchy === 1) {
                if (this.xznjlist.length > 0) {
                  if (this.xznjlist.filter(c => c === list[i].Id).length > 0) {
                    this.njlist.push(list[i]);
                  }
                } else {
                  this.njlist.push(list[i]);
                }
              }
            }
          }
        }
      );
    },
    loadbjdata: function() {
      if (this.njid.length > 0) {
        this.bjlist = this.zzjglist.filter(
          c => c.Id === this.njid
        )[0].ChidOrgList;
      } else {
        this.bjlist = [];
      }
    },
    loaddata: function() {
      if (this.tzgginfo.Id !== undefined && this.tzgginfo.Id !== "") {
        this.$api.post(
          "v1/notice/GetSingle",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            Id: this.tzgginfo.Id,
            YHId: this.cjr,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.tzgginfo = r.Target;
              if (this.tzgginfo.JSDXLX === 2) {
                this.yhlb = "1";
              } else {
                this.yhlb = "2";
                var jsdxlist = r.Target.AttendList;
                for (var i = 0; i < jsdxlist.length; i++) {
                  if (this.tzgginfo.JSDXLX === 1) {
                    if (jsdxlist[i].JSDXLX === 1) {
                      this.njall = true;
                      this.xznjlist = [];
                      break;
                    } else {
                      this.xznjlist.push(jsdxlist[i].DXId);
                    }
                  } else if (this.tzgginfo.JSDXLX === 3) {
                    this.njall = true;
                  }
                }
                this.loadnjdata();
              }
              this.loadreportdata();
            }
          }
        );
      }
    },
    loadreportdata: function() {
      var pagerInfo = { pageIndex: this.pageIndex, pageSize: this.pagesize };
      let zzjgid = this.njid;
      if (this.bjid.length > 0) {
        zzjgid = this.bjid;
      }
      this.$api.post(
        "v1/notice/GetReadReport",
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          TZId: this.tzgginfo.Id,
          YHLB: this.yhlb,
          YDZT: this.readstatus,
          ZZJGId: zzjgid,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.reportlist = [];
            this.reportlist = r.Target.filter(c => c.CYId.length > 0);
            const total = r.Target[0];
            if (total !== null || total !== undefined) {
              this.yds = total.YDS;
              this.wds = total.WDS;
            }
            this.rowcount = r.PagerInfo.RowCount;
          }
        }
      );
    }
  },
  data() {
    return {
      isDisabled: false,
      ssxx: "",
      ssxq: "",
      token: "",
      yhlb: "",
      fileList: [],
      readstatus: "2",
      njall: false,
      readvisible: false,
      readstatusstr: "未阅",
      bjid: "",
      njid: "",
      fjarr: [],
      njlist: [],
      xznjlist: [],
      yds: 0,
      wds: 0,
      bjlist: [],
      zzjglist: [],
      jyzlist: [],
      reportlist: [],
      cjr: "",
      tzgginfo: {
        Id: "",
        CJR: "",
        CJSJ: "",
        BT: "",
        NR: "",
        FSSJ: "",
        FSLX: "1",
        JSDXLX: "",
        YDS: 0,
        WDS: 0
      },
      options: [
        {
          value: "2",
          label: "未阅"
        },
        {
          value: "1",
          label: "已阅"
        }
      ],
      pageIndex: 1,
      currentPage: 0,
      pagesize: 10,
      rowcount: 0
    };
  }
};
</script>
