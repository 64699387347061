var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-whole-box"},[_c('div',{staticClass:"leftrig-box"},[_c('div',{staticClass:"leftrig-left-box",staticStyle:{"width":"150px"}},[_c('el-tree',{attrs:{"props":_vm.defaultProps,"data":_vm.treeData,"highlight-current":true},on:{"node-click":_vm.handleNodeClick}})],1),_c('div',{staticClass:"leftrig-rig-box"},[_c('div',{staticClass:"tab-heng-box margno-t"},[_c('div',{staticClass:"table-er-box"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-edit"},on:{"click":_vm.addJG}},[_vm._v("新增机构")])],1)]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData.subnodeList}},[_c('el-table-column',{attrs:{"prop":"name","label":"子机构名称","width":"180"}}),(
            _vm.currentTreeNode.key !== '0' &&
              _vm.currentTreeNode.type &&
              _vm.currentTreeNode.type === 1
          )?_c('el-table-column',{attrs:{"label":"组长","prop":"managerStr","width":"180"}}):_vm._e(),(
            _vm.currentTreeNode.key !== '0' &&
              _vm.currentTreeNode.type &&
              _vm.currentTreeNode.type === 1
          )?_c('el-table-column',{attrs:{"label":"组员","prop":"menberStr"}}):_vm._e(),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.edit(row)}}},[_vm._v("编辑")]),_c('el-button',{staticClass:"tab-wz-butta c-red",attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")])]}}])})],1)],1)]),_c('Form',{attrs:{"visible":_vm.formVisible,"formData":_vm.formData,"currentTreeNode":_vm.currentTreeNode},on:{"update:visible":function($event){_vm.formVisible=$event},"closeForm":_vm.closeForm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }