<template>
  <div class="cont-whole-box">
    <div class="leftrig-box">
      <!--表格区开始-->
      <div class="margno-t">
        <div class="table-er-box">
          <el-button plain icon="el-icon-edit" @click="openDialog"
            >新增体系</el-button
          >
        </div>
        <Table
          :dataTable="
            dataTable.slice(
              (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
              (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                pagerInfo.pageSize
            )
          "
          :isLoading="isLoading"
          @editItem="openDialog"
          @delItem="delItem"
        />
        <!--页码开始-->
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
        <!--页码结束-->
      </div>
      <!--表格区结束-->
    </div>
    <Form
      v-if="!isLoading"
      :user="user"
      :token="token"
      :schoolId="schoolId"
      :title="formTitle"
      :visible="formVisible"
      :formData="formData"
      :dataTable="dataTable"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import Table from "./Table.vue";
import Form from "./Form.vue";
export default {
  components: {
    Pager,
    Table,
    Form
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: "",
      user: {},
      schoolId: "",
      dataTable: [],
      isLoading: false,
      formData: {},
      formVisible: false,
      formTitle: ""
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.getData();
  },
  methods: {
    getData() {
      this.$api.post(
        "/v1/evaluationsystem/GetEvaluationSystemList",
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            this.dataTable = r.Target;
            this.pagerInfo.totalCount = this.dataTable.length;
          }
        }
      );
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "/v1/evaluationsystem/DelEvaluationSystem",
            {
              token: this.token,
              Id: data.Id,
              SchoolId: this.schoolId,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                  showClose: true
                });
                const index = this.dataTable.findIndex(c => c === data);
                if (index !== -1) {
                  this.dataTable.splice(index, 1);
                  this.pagerInfo.totalCount--;
                }
              }
            }
          );
        })
        .catch(() => {});
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    openDialog(data) {
      this.formData = {};
      this.formTitle = "新增";
      if (JSON.stringify(data) !== "{}") {
        this.formData = data;
        this.formTitle = "编辑";
      }
      this.formVisible = true;
    },
    closeDialog(data) {
      if (data && JSON.stringify(data) !== "{}") {
        const index = this.dataTable.findIndex(c => c.Id === data.Id);
        if (index === -1) {
          this.dataTable.unshift(data);
          this.pagerInfo.totalCount++;
        } else {
          this.dataTable.splice(index, 1, data);
        }
      }
      this.formVisible = false;
    }
  }
};
</script>
