<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    width="90%"
    custom-class="study-report-dialog"
    append-to-body
    :close-on-click-modal="false"
    :before-close="() => $emit('update:show', false)"
  >
    <!-- 筛选栏 -->
    <div class="filter-btns">
      <!-- 班级筛选 -->
      <div class="filter-grade">
        <el-select
          v-model="search.class"
          size="small"
          placeholder="请选择"
          @change="handleSearch"
          @visible-change="$forceUpdate()"
        >
          <el-option
            v-for="item in classOption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 姓名筛选 -->
      <div class="filter-name">
        <el-input
          v-model="search.name"
          size="small"
          placeholder="请输入内容"
        ></el-input>
        <el-button size="small" type="primary" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <!-- 导出 -->
      <div class="filter-export">
        <el-button size="small" type="primary" @click="exportData"
          >导出</el-button
        >
      </div>
      <!-- 排序 -->
      <div class="filter-PX">
        <el-select
          v-model="search.PX"
          size="small"
          placeholder="请选择"
          @change="handleSearch"
        >
          <el-option
            v-for="item in PXoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      ref="table"
      :data="tableData"
      :loading="tableLoading"
      border
      current-row-key="BJMC"
      stripe
      style="width: 100%"
    >
      <template v-for="item in tabelHeader">
        <el-table-column
          v-if="
            item.prop === 'BJMC' ||
              item.prop === 'QMCC' ||
              item.prop === 'XM' ||
              item.prop === 'XYZP'
          "
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          align="center"
        ></el-table-column>
        <el-table-column
          v-else
          :key="item.prop"
          :label="item.label"
          align="center"
          :width="handleColumnWidth(item)"
        >
          <template slot-scope="scope">
            <div class="ZD-container" style="min-height:20px">
              <div
                class="ZD-item"
                v-for="ZD in scope.row[item.prop]
                  ? scope.row[item.prop].ZDSJ
                  : []"
                :key="ZD.Id"
              >
                <div class="ZD-name">
                  {{ ZD.MC }}
                </div>
                <div class="ZD-value">
                  {{ ZD.Value }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- page -->
    <div class="page-block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.index"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
    <div class="footer-btns">
      <el-button size="small" type="info" @click="$emit('update:show', false)"
        >关闭</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {
  GetXXBBData,
  GetXXBBDataToExcel,
  GetXXBBReportData,
} from "@/api/studyCenter.js";

export default {
  props: {
    show: [Boolean],
    QXDJ: [Number],
    curSubject: [Object],
    curGrade: [Object],
    title: [String],
    xqid: [String],
  },
  data() {
    return {
      userInfo: {},
      search: {
        class: "",
        name: "",
        PX: 1,
      },
      classOption: [],
      PXoptions: [
        {
          label: "不排序",
          value: 1,
        },
        {
          label: "总评倒序",
          value: 2,
        },
        {
          label: "总评升序",
          value: 3,
        },
      ],
      tabelHeader: [],
      tableData: [],
      page: {
        index: 1, //是	int	分页
        size: 10, //是	int	分页
        total: 0,
      },
      tableLoading: false,
      url: "",
      schoolDetail: {},
    };
  },
  async mounted() {
    // console.log(222, '222')
    let user = localStorage.getItem("userinfo");
    if (user) this.userInfo = JSON.parse(user);
    else this.$message.error("未获取到用户详情");
    await this.getSchoolDetail();
  },
  methods: {
    // 获取表格数据
    async GetXXBBData() {
      this.tableLoading = true;
      let res = await GetXXBBData({
        ssxx: this.userInfo.SSZZId, //	是	string	学校Id
        xqid: this.xqid,
        bjid: this.search.class, //	否	string	班级Id
        xkId: this.curSubject.CourseId, //	是	string	学科Id
        njid: this.curGrade.GradeNumber, //	是	string	年级(1,2,3)
        queryKeys: this.search.name, //否	string	姓名关键字
        px: this.search.PX, //	是	int	排序(1不排序,2总评降序,3总评升序)
        pageIndex: this.page.index, //	是	int	0
        pageSize: this.page.size, //	是	int	10
        userId: this.userInfo.Id,
        menuType: this.QXDJ,
        token: this.userInfo.Token,
      });

      this.page.total = res.PagerInfo.RowCount;
      this.url = res.Message;
      if (res && res.Target && res.Target.length > 0) {
        res.Target.forEach((item) => {
          if (item.ObjData) item.ObjData = JSON.parse(item.ObjData);
          console.log(item, "objectdata");
          if (item.ObjData && item.ObjData.length > 0) {
            // item.MkJson.forEach(el => {
            //   let ObjData = item.ObjData.find((MK,index) => MK.Id === el.Id)
            //   if(ObjData) {

            //   }else {

            //   }
            // })
            item.ObjData.forEach((MK, index) => {
              item.MkJson.forEach((el) => {
                if (el.Id === MK.Id) {
                  MK.MC = el.MC;
                  // console.log(MK, "item");
                  MK.index = index;
                  item[MK.Id] = MK;
                  item.dataLength = item.ObjData?.length || 0;
                  item.Id = item.XM + item.BJMC + item.dataLength;
                }

                el.ZDMC.forEach((e) => {
                  MK.ZDSJ.forEach((element) => {
                    if (element.Id === e.Id) {
                      element.MC = e.MC;
                    }
                  });
                });
              });
            });
          } else item.ObjData = [];
        });
        this.tableData = res.Target;
        console.log(this.tableData, "tabledata");
        // 找出评价的数据最多的那个
        let arr = this.tableData.map((el) => {
          return {
            id: el.Id,
            length: el.dataLength,
          };
        });
        arr.sort((a, b) => b.length - a.length);
        // 因为是根据数据有好多条来成header的 但是每个学生的数据不一定相同 所以要找出数据最多的那个学生
        console.log(arr, arr[0], "评价数据最多的一个");
        let obj22 = this.tableData.find((el) => el.Id === arr[0].id);

        let tabelHeader = Object.keys(obj22 || this.tableData[0]).reduce(
          (pre, cur, index) => {
            console.log(pre, cur, "dsss");
            if (cur === "BJMC") {
              return pre.concat({
                prop: cur,
                label: "班级名称",
                order: 1,
              });
            } else if (cur === "QMCC") {
              return pre.concat({
                prop: cur,
                label: "期末测查",
                order: 999,
              });
            } else if (cur === "XM") {
              return pre.concat({
                prop: cur,
                label: "姓名",
                order: 2,
              });
            } else if (cur === "XYZP") {
              return pre.concat({
                prop: cur,
                label: "学业总评",
                order: 1000,
              });
            } else {
              if (cur && cur.length > 20) {
                return pre.concat({
                  prop: obj22 ? obj22[cur].Id : this.tableData[0][cur].Id,
                  label:
                    (obj22 ? obj22[cur].MC : this.tableData[0][cur].MC) ||
                    "----",
                  ZDSJ:
                    (obj22 ? obj22[cur].ZDSJ : this.tableData[0][cur].ZDSJ) ||
                    [],
                  index: obj22
                    ? obj22[cur].index
                    : this.tableData[0][cur].index,
                  order: index + 3,
                });
              } else return pre;
            }
          },
          []
        );

        tabelHeader.sort((a, b) => a.order - b.order);
        this.tabelHeader = tabelHeader;
        this.tableLoading = false;
      } else this.tableData = [];
    },
    handleSearch() {
      this.page.index = 1;
      this.GetXXBBData();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.index = 1;
      this.GetXXBBData();
    },
    handleCurrentChange(val) {
      this.page.index = val;
      this.GetXXBBData();
    },
    // 获取学校详情
    getSchoolDetail() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolMessage",
          {
            SchoolId: this.userInfo.SSZZId,
          },
          (res) => {
            if (res.Code === 0) {
              this.schoolDetail = res.Target;
              resolve();
            } else reject();
          }
        );
      });
    },
    // 导出
    async exportData() {
      await GetXXBBDataToExcel({
        url: process.env.NODE_ENV === "development" ? this.url : "",
        ssxx: this.userInfo.SSZZId, //	是	string	学校Id
        bjid: this.search.class, //	否	string	班级Id
        xkId: this.curSubject.CourseId, //	是	string	学科Id
        njid: this.curGrade.GradeNumber, //	是	string	年级(1,2,3)
        queryKeys: this.search.name, //否	string	姓名关键字
        px: this.search.PX, //	是	int	排序(1不排序,2总评降序,3总评升序)
        pageIndex: this.page.index, //	是	int	0
        pageSize: this.page.size, //	是	int	10
        token: this.userInfo.Token,
      });
    },
    handleColumnWidth(item) {
      let width = 0;
      item.ZDSJ.forEach((el) => {
        width += el.MC ? el.MC.length : 0;
      });
      return width * 28;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.page.Index = 1;
        let bjArr = [];
        if (this.QXDJ === 2) {
          bjArr = this.curGrade.ChidOrgList.filter((item) => item.type);
        } else {
          bjArr = this.curGrade.ChidOrgList.map((item) => item);
        }
        this.classOption = bjArr;
        this.search.class = this.classOption[0] ? this.classOption[0].Id : "";
        this.GetXXBBData();
      }
    },
  },
};
</script>
<style lang="scss">
.study-report-dialog {
  min-width: 750px;
}
</style>
<style lang="scss" scoped>
.filter-btns {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 1px 1px 2px 2px #f9fbfb;

  .filter-grade,
  .filter-name,
  .filter-export,
  .filter-PX {
    margin-right: 20px;

    .el-select,
    .el-input {
      width: 150px;
    }
  }

  .filter-name {
    display: flex;
    align-items: center;
  }

  .filter-name {
    ::v-deep .el-input__inner {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .el-button {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.el-table {
  ::v-deep .el-tooltip {
    padding: 0;
  }

  ::v-deep .el-table__row {
    .el-table__cell {
      padding: 0;
    }
  }

  .ZD-container {
    display: flex;
    align-items: center;

    .ZD-item:last-child {
      .ZD-name,
      .ZD-value {
        border-right: none;
      }
    }

    .ZD-item {
      flex: 1;
      width: 200px;

      .ZD-name,
      .ZD-value {
        width: 100%;
        overflow: hidden;
        word-break: normal;
        padding: 10px;
      }

      .ZD-name {
        border-right: 1px solid #ebeef5;
      }

      .ZD-value {
        border-top: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
      }
    }
  }
}

.footer-btns,
.page-block {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
