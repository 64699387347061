<template>
  <el-tree
    :props="props"
    :load="loadNode"
    @node-click="nodeClikc"
    :expand-on-click-node="false"
    lazy
    highlight-current
    ref="tree"
    node-key="data_id"
  >
  </el-tree>
</template>

<script>
export default {
  data() {
    return {
      props: {
        label: "name",
        isLeaf: "leaf",
        indexFilter: {
          type: Number,
          required: true
        },
        newNode: {
          type: Array,
          required: false
        }
      }
    };
  },
  methods: {
    loadNode(node, resolve) {
      var pid = "0";
      var nodetype = 1;
      if (node.data !== undefined) {
        pid = node.data.data_id;
        this.parentId = pid;
      }
      if (node.data !== undefined) {
        nodetype = node.data.data_nodetype;
      }
      this.$api.post(
        "/v1/areaindex/GetTree",
        {
          Id: pid,
          token: this.$store.state.token,
          IndexFilter: this.$attrs.indexFilter,
          NodeType: nodetype
        },
        r => {
          var resp = r.Target;
          var data = resp.Target;
          var list = [];

          for (var i = 0; i < data.length; i++) {
            var leaf = true;
            if (data[i].HasChilden === 1) {
              leaf = false;
            }
            list.push({
              name: data[i].Name,
              data_nodetype: data[i].NodeType,
              data_id: data[i].Id,
              data_ordernum: data[i].OrderNum,
              data_remark: data[i].Remark,
              data_level: data[i].Level,
              data_parentid: data[i].ParentCode,
              data_areaid: data[i].AreaId,
              leaf: leaf
            });
          }
          resolve(list);
        }
      );
    },
    nodeClikc(data, node) {
      var param = {};
      param.Id = data.data_id;
      param.Nodetype = data.data_nodetype;
      param.level = data.data_level;
      param.ParentId = data.data_parentid;
      param.AreaId = data.data_areaid;
      // param.nodeId=node.id
      this.$emit("changeNode", param, node);
    }
  }
};
</script>
