<template>
  <div class="cont-whole-box">
    <div style="padding: 30px">
      <div class="grid-content bg-purple-light">
        <el-menu
          :default-active="selectType"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">学生获奖</el-menu-item>
          <!-- <el-menu-item index="3">班级获奖</el-menu-item> -->
          <el-menu-item index="2">教师获奖</el-menu-item>
          <!-- <el-menu-item index="4">社团获奖</el-menu-item> -->
        </el-menu>
      </div>
      <br />
      <div
        class="table-er-box"
        style="border-bottom: 1px solid #f2f2f2; text-align: right"
      >
        <el-radio-group
          v-model="shzt"
          @change="shztchange()"
          style="margin-left: 10px; float: left"
        >
          <el-radio-button :label="1">未审核</el-radio-button>
          <el-radio-button :label="2">审核通过</el-radio-button>
          <el-radio-button :label="3">审核不通过</el-radio-button>
        </el-radio-group>
        <div
          style="
            font-size: 14px;
            margin-bottom: 10px;
            margin-left: 10px;
            display: inline-block;
            float: left;
          "
        >
          <el-input
            style="width: 200px"
            v-model="searchname"
            placeholder="请输入关键字"
            maxlength="20"
            prefix-icon="el-icon-search"
          ></el-input>
          <span
            style="color: #14d089; margin-left: 5px; cursor: pointer"
            @click="handleSearch()"
            >搜索</span
          >
        </div>
      </div>
      <div class="list">
        <div class="list-item" v-for="(hj, index) in hjlist" :key="index">
          <!-- 标题 -->
          <div class="list-item-title">
            <div class="name">{{ hj.HJMC }}</div>
            <div>
              <el-button
                type="primary"
                size="small"
                v-if="hj.SHZT == '1'"
                @click="setawardvalid(hj)"
              >
                审核通过
              </el-button>
              <el-button
                size="small"
                type="danger"
                v-if="hj.SHZT == '1'"
                @click="setawardinvalid(hj.Id, hj.NJId)"
              >
                审核不通过
              </el-button>
              <el-button size="small" v-if="hj.SHZT == '2'" disabled
                >审核通过</el-button
              >
              <el-button size="small" v-if="hj.SHZT == '3'" disabled>
                审核不通过</el-button
              >
            </div>
          </div>
          <div class="py-cont-box">
            <div class="displ-il-box-top">
              <div class="displ-il-box">
                <span class="c-g-9" v-if="selectType == 1">学生姓名：</span>
                <span class="c-g-9" v-else-if="selectType == 2">班级：</span>
                <span class="c-g-9" v-else-if="selectType == 3"
                  >教师姓名：</span
                >
                <span class="c-g-9" v-else-if="selectType == 4">社团：</span>
                {{ hj.CYMC }}
              </div>
              <div class="displ-il-box" v-if="selectType == 1">
                <span class="c-g-9">班级：</span>
                {{ getClassName(hj.BJId) }}
              </div>
              <div class="displ-il-box">
                <span class="c-g-9">获奖类别:</span>
                {{ hj.LBMC }}
              </div>
              <div class="displ-il-box">
                <span class="c-g-9">获奖级别：</span>
                {{ hj.DJMC }}
              </div>
              <div v-if="hj.SHZT == 2" class="displ-il-box">
                <span class="c-g-9">得分：</span>
                {{ hj.DF }}分
              </div>
            </div>
            <div class="py-text-l">获奖描述：</div>
            <div class="py-text">{{ hj.BZ }}</div>
            <div
              class="py-img-box"
              v-if="hj.FjList != null && hj.FjList.length > 0"
            >
              <el-image
                class="py-img"
                v-for="fj in hj.FjList"
                :key="fj.Id"
                style="width: 100px; height: 100px"
                :src="fj.FJLJ"
                fit="scale-down"
              ></el-image>
            </div>
            <div class="displ-il-boxmain">
              <div class="displ-il-box">
                <span class="c-g-9">添加人：</span>
                <!--  -->
                {{ getCJRName(hj.CJR) }}
                {{ hj.HJSJ | DateFormat('yyyy-MM-dd') }}
              </div>
              <div v-if="hj.SHZT == 2 && hj.SFYX == 1" class="displ-il-box">
                <span class="c-g-9">审核人：</span>
                <!-- {{ hj.SHRQ | DateFormat("yyyy-MM-dd") }} -->
                {{ getCJRName(hj.SHR) }}
              </div>
              <div v-if="hj.SHZT == 2 && hj.SFYX == 2" class="displ-il-box">
                <span class="c-g-9">更改人：</span>
                <!-- {{ hj.SZSJ | DateFormat("yyyy-MM-dd") }} -->
                {{ getCJRName(hj.SZR) }}
              </div>
              <div v-if="hj.SHZT == 2 && hj.SFYX == 2" class="displ-il-box">
                <span class="c-g-9">原因：</span>
                {{ hj.WXYY }}
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagin-box">
          <div class="pagin-wz">
            <Pager :pagerInfo="pagerInfo" @changeIndex="handleCurrentChange" />
          </div>
        </div>
      </div>
    </div>
    <!-- 审核通过 -->
    <el-dialog
      title="审核通过"
      :visible.sync="validdialogVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handlevalidClose"
    >
      <el-form ref="validform">
        <el-form-item>
          <el-input-number
            v-model="num"
            :precision="2"
            :min="0"
            :step="0.1"
            :max="max"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button @click="handlevalidClose()">取 消</el-button>
          <el-button type="primary" @click="handlevalidConfirm()"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 审核不通过 -->
    <el-dialog
      title="审核不通过"
      :visible.sync="invaliddialogVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleInvalidClose"
    >
      <el-form ref="invalidform">
        <el-form-item>
          <el-button
            type="primary"
            @click="handleinvalidConfirm('图片看不清楚，请重新上传')"
            >图片看不清楚，请重新上传</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleinvalidConfirm('图片看不清楚，请重新上传')"
            >未达学校审核标准</el-button
          >
        </el-form-item>
        <el-form-item
          label="其它："
          :rules="[
            {
              min: 1,
              max: 50,
              message: '长度在1到50个字符',
              trigger: 'change'
            }
          ]"
          prop="itgyy"
        >
          <el-input
            type="textarea"
            v-model="itgyy"
            placeholder="不超过50个字"
            maxlength="50"
            minlength="1"
            :rows="2"
            show-word-limit
          ></el-input>
          <br />
          <el-button
            type="primary"
            style="margin-top: 5px"
            @click="handleinvalidConfirm(itgyy)"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Pager from '@/components/Pager.vue'
export default {
  components: {
    Pager
  },
  created() {
    //YhyTODO:获取所属学校
    this.ssxq = localStorage.getItem('currentterm')
    this.term = JSON.parse(localStorage.getItem('curTerm'))
    //获取教师
    if (!localStorage.getItem('SchoolOrg')) {
      // this.loadnjdata()
    }
    let list = JSON.parse(localStorage.getItem('SchoolOrg'))
    if (list) {
      this.schoolOrg = list
      this.bjlist = []
      list.forEach(item => {
        item.ChidOrgList.forEach(it => {
          this.bjlist.push(it)
        })
      })
    }
    var tempuser = localStorage.getItem('userinfo')
    if (tempuser) {
      var user = JSON.parse(tempuser)
      this.ssxx = user.SSZZId
      this.token = user.Token
      this.yhid = user.Id
    }
  },
  async mounted() {
    this.loaddata()
    // this.loadnjdata()
    //获取学生
    if (!localStorage.getItem('OrgStudent')) {
      await this.getOrgStudent()
    }
    //获取教师
    if (!localStorage.getItem('OrgTeacher')) {
      await this.getSchoolTeacher()
    }
    this.schoolTeacher = JSON.parse(localStorage.getItem('OrgTeacher'))
    this.schoolStudent = JSON.parse(localStorage.getItem('OrgStudent'))
  },
  methods: {
    // 获取年级
    loadnjdata() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          { SchoolId: this.ssxx, token: this.token },
          r => {
            if (r.Code == 0) {
              let list = r.Target || []
              localStorage.setItem('SchoolOrg', JSON.stringify(list))
              resolve()
            }
          }
        )
      })
    },
    //获取学校教师
    getSchoolTeacher() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolTeacher',
          {
            schoolId: this.ssxx,
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              let list = r.Target || []
              localStorage.setItem('OrgTeacher', JSON.stringify(list))
              resolve()
            }
          }
        )
      })
    },
    // 获取学生
    getOrgStudent() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetOrgStudent',
          {
            SchoolOrgId: this.ssxx, //	是	string	组织机构ID/活动Id
            OrgType: 0, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: this.term.SSXN, //	是	string	学年
            SchoolId: this.ssxx, //	否	string	学校Id
            IsDelete: 2, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              let list = r.Target || []
              localStorage.setItem('OrgStudent', JSON.stringify(list))
              resolve()
            }
          }
        )
      })
    },
    //匹配班级名称
    getClassName(value) {
      let name = ''
      let a = this.bjlist.find(i => i.Id == value)
      if (a) {
        name = a.Name
      }
      return name
    },
    //匹配添加人名称
    getCJRName(value) {
      let name = ''
      //先匹配教师
      let a = this.schoolTeacher.find(i => i.Id == value)
      if (a) {
        name = a.Name
      } else {
        let b = this.schoolStudent.find(i => i.Id == value)
        if (b) {
          name = b.Name
        } else {
          name = ''
        }
      }
      return name
    },
    handleaddaward: function() {
      this.$router.push({ name: 'addteacheraward' })
    },
    //设置通过
    setawardvalid(hj) {
      console.log('hj', hj)

      this.editid = hj.Id
      this.num = hj.DF
      this.validdialogVisible = true
    },
    //设置不通过
    setawardinvalid(id, njid) {
      this.editid = id
      this.itgyy = ''
      this.xsnjid = njid
      this.invaliddialogVisible = true
    },
    //确定
    handleinvalidConfirm(yy) {
      if (yy.length <= 0) {
        this.$message({
          type: 'info',
          message: '请输入不通过原因!'
        })
        return
      }
      this.$api.post(
        'v1/awards/VerifyAward',
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          Id: this.editid,
          NJId: this.xsnjid,
          SHZT: 3,
          SHR: this.yhid,
          SHBZ: yy,
          DF: 0,
          SSXN: this.term.SSXN,
          XQLX: this.term.XQLX,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: 'info',
              message: '操作成功!'
            })
          } else {
            this.$message({
              type: 'info',
              message: r.Message
            })
          }
          this.handleInvalidClose()
          this.loaddata()
        }
      )
    },
    //关闭
    handleInvalidClose() {
      this.itgyy = ''
      this.invaliddialogVisible = false
    },
    //审核通过弹窗确认
    handlevalidConfirm() {
      this.$api.post(
        'v1/awards/VerifyAward',
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          Id: this.editid,
          NJId: '',
          SHR: this.yhid,
          SHBZ: this.SHBZ,
          DF: this.num,
          SHZT: 2,
          SSXN: this.term.SSXN,
          XQLX: this.term.XQLX,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: 'info',
              message: '审核成功!'
            })
          } else {
            this.$message({
              type: 'info',
              message: r.Message
            })
          }
          this.handlevalidClose()
          this.loaddata()
        }
      )
    },
    //关闭
    handlevalidClose() {
      this.num = 0
      this.max = 0
      this.validdialogVisible = false
    },
    shztchange: function() {
      this.pagerInfo.pageIndex = 1
      this.loaddata()
    },
    //切换列表
    handleSelect(key) {
      this.shzt = 1
      this.pagerInfo.pageIndex = 1
      this.selectType = key
      this.loaddata()
    },
    handleSearch: function() {
      this.loaddata()
    },
    loaddata: function() {
      this.hjlist = []
      if (this.bjid.length > 0) {
        this.orgid = this.bjid
      } else if (this.njid.length > 0) {
        this.orgid = this.njid
      }
      this.$api.post(
        'v1/awards/GetAwardVerifyList',
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          SSXN: this.term.SSXN,
          Searchname: this.searchname,
          CYLB: this.selectType,
          VerifyStatus: this.shzt,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            let list = r.Target
            if (list.length > 0 && this.shzt == 1) {
              ///升序
              list.sort((a, b) => {
                const dateA = new Date(a.CJSJ).getTime()
                const dateB = new Date(b.CJSJ).getTime()

                return dateA - dateB
              })
            } else {
              list.sort((a, b) => {
                const dateA = new Date(a.HJSJ).getTime()
                const dateB = new Date(b.HJSJ).getTime()

                return dateB - dateA
              })
            }
            this.hjlist = list
            this.pagerInfo.totalCount = r.PagerInfo.RowCount
          }
        }
      )
    },
    //翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val
      this.loaddata()
    }
  },
  data() {
    return {
      term: {},
      editid: 0,
      isLogin: true,
      selectType: '1',
      hjlist: [],
      njlist: [],
      bjlist: [],
      zzjglist: [],
      itgyy: '',
      invaliddialogVisible: false,
      validdialogVisible: false,
      max: 0,
      num: 0,
      ssxx: '',
      ssxq: '',
      searchname: '',
      hjdjmc: '',
      kssj: '',
      shzt: 1,
      jssj: '',
      token: '',
      rqlx: 0,
      yhid: '',
      bjid: '',
      xsnjid: '',
      njid: '',
      orgid: '',
      schoolOrg: '', //学校年级
      schoolTeacher: '', //学校教师
      schoolStudent: '', //学校学生
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.list {
  margin-top: 15px;
  background-color: #fff;
  .list-item {
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px #0000001a;
    .list-item-title {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      border-bottom: 1px solid #ccc;
      .name {
        color: red;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .detail {
      padding: 10px;
      .detail-top {
        display: flex;
        align-items: center;
        .top-item {
          margin-right: 15px;
          font-size: 14px;
          .label {
            font-weight: 600;
          }
        }
      }
      .detail-center {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
      .detail-center > .center-item {
        font-weight: 600;
        margin-right: 20px;
        color: #666;
        font-size: 14px;
      }
      .detail-description {
        margin-top: 10px;
        font-size: 14px;
        display: flex;
        .label {
          flex-shrink: 0;
          font-weight: 600;
        }
        .value {
          flex: 1;
        }
      }
      .images {
        margin-top: 15px;
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #ccc;
        .img-item {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
