<template>
  <!-- 学科报表图表 -->
  <div class="report-echarts" v-show="allData.length > 0">
    <div class="echarts-item" v-for="(item, index) in allData" :key="index">
      <div class="title">{{ item.title }}</div>
      <!-- 柱状图 -->
      <div class="echart-container" :class="`echart${index}`"></div>
      <!-- 表格 -->
      <div class="table-container">
        <el-table :data="item.table.data" border fit stripe style="width: 100%">
          <template v-for="(it, idx) in item.table.header">
            <el-table-column :key="idx" :prop="it" :label="idx ? it : ''">
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { GetXXBBReportData } from "@/api/studyCenter.js";

export default {
  props: {
    userInfo: [Object],
    search: [Object],
    curSubject: [Object]
  },
  data() {
    return {
      allData: [],
      allData2: [
        {
          Name: "过程性评价",
          ObjReportData: [
            {
              Name: "六年级1班",
              ClassGrade: 12.0,
              YearGrade: 11.0
            },
            {
              Name: "六年级2班",
              ClassGrade: 10.0,
              YearGrade: 13.0
            },
            {
              Name: "六年级3班",
              ClassGrade: 11.0,
              YearGrade: 11.0
            },
            {
              Name: "六年级4班",
              ClassGrade: 9.0,
              YearGrade: 8.0
            },
            {
              Name: "六年级5班",
              ClassGrade: 23.0,
              YearGrade: 21.0
            },
            {
              Name: "六年级6班",
              ClassGrade: 11.0,
              YearGrade: 15.0
            },
            {
              Name: "六年级7班",
              ClassGrade: 14.0,
              YearGrade: 13.0
            },
            {
              Name: "六年级8班",
              ClassGrade: 12.0,
              YearGrade: 13.0
            }
          ],
          ObjTableData: {
            TableName: [
              "学业总评",
              "均值以上人数比",
              "B等级人数",
              "C等级人数",
              "D等级人数"
            ],
            TableData: [
              [
                "年级均值",
                "学业总评100",
                "均值以上人数比68%",
                "2541",
                "499",
                "29"
              ],
              ["六年级1班", "学业总评10", "均值以上人数比18%", 43, 0, 1],
              ["六年级2班", "学业总评10", "均值以上人数比18%", 55, 0, 0],
              ["六年级3班", "学业总评10", "均值以上人数比18%", 50, 0, 0],
              ["六年级4班", "学业总评10", "均值以上人数比18%", 51, 0, 0],
              ["六年级5班", "学业总评10", "均值以上人数比18%", 48, 0, 0],
              ["六年级6班", "学业总评10", "均值以上人数比18%", 48, 0, 0],
              ["六年级7班", "学业总评10", "均值以上人数比18%", 53, 1, 1],
              ["六年级8班", "学业总评10", "均值以上人数比18%", 52, 0, 1]
            ]
          }
        },
        {
          Name: "阶段性评价",
          ObjReportData: [
            {
              Name: "六年级1班",
              ClassGrade: 12.0,
              YearGrade: 11.0
            },
            {
              Name: "六年级2班",
              ClassGrade: 10.0,
              YearGrade: 9.0
            },
            {
              Name: "六年级3班",
              ClassGrade: 16.0,
              YearGrade: 19.0
            },
            {
              Name: "六年级4班",
              ClassGrade: 15.0,
              YearGrade: 18.0
            },
            {
              Name: "六年级5班",
              ClassGrade: 13.0,
              YearGrade: 17.0
            },
            {
              Name: "六年级6班",
              ClassGrade: 23.0,
              YearGrade: 22.0
            },
            {
              Name: "六年级7班",
              ClassGrade: 25.0,
              YearGrade: 24.0
            },
            {
              Name: "六年级8班",
              ClassGrade: 21.0,
              YearGrade: 20.0
            }
          ],
          ObjTableData: {
            TableName: [
              "学业总评",
              "均值以上人数比",
              "B等级人数",
              "C等级人数",
              "D等级人数"
            ],
            TableData: [
              [
                "年级均值",
                "学业总评100",
                "均值以上人数比68%",
                "2541",
                "499",
                "29"
              ],
              ["六年级1班", "学业总评10", "均值以上人数比18%", 43, 0, 1],
              ["六年级2班", "学业总评10", "均值以上人数比18%", 55, 0, 0],
              ["六年级3班", "学业总评10", "均值以上人数比18%", 50, 0, 0],
              ["六年级4班", "学业总评10", "均值以上人数比18%", 51, 0, 0],
              ["六年级5班", "学业总评10", "均值以上人数比18%", 48, 0, 0],
              ["六年级6班", "学业总评10", "均值以上人数比18%", 48, 0, 0],
              ["六年级7班", "学业总评10", "均值以上人数比18%", 53, 1, 1],
              ["六年级8班", "学业总评10", "均值以上人数比18%", 52, 0, 1]
            ]
          }
        }
      ]
    };
  },
  mounted() {
    //this.init();
    // this.GetXXBBReportData();
  },
  methods: {
    async GetXXBBReportData() {
      let res = await GetXXBBReportData({
        ssxx: this.userInfo.SSZZId, //	是	string	学校Id
        bjid: "", //	否	string	班级Id
        xkId: this.curSubject.CourseId, //	是	string	学科Id
        token: this.userInfo.Token
      });
      if (res && res.Target && res.Target.length > 0) {
        let allData = [];
        res.Target.forEach((item, index) => {
          let obj = {
            title: item.Name,
            barData: {
              index,
              name: [],
              classBar: [],
              gradeBar: []
            },
            table: {
              header: ["name"],
              data: []
            }
          };
          // 柱状图数据处理
          item.ObjReportData.forEach(it => {
            obj.barData.name.push(it.Name);
            obj.barData.classBar.push(it.ClassGrade);
            obj.barData.gradeBar.push(it.YearGrade);
          });
          // 表格数据处理
          obj.table.header = obj.table.header.concat(
            item.ObjTableData.TableName
          );
          item.ObjTableData.TableData.forEach(el => {
            let obj2 = {};
            obj.table.header.forEach((key, idx) => (obj2[key] = el[idx]));
            obj.table.data.push(obj2);
          });

          allData.push(obj);
        });

        this.allData = allData;
        this.initBar();
      }
    },
    initBar() {
      this.$nextTick(() => {
        let barDoms = document.querySelectorAll(".echart-container");

        barDoms.forEach((item, index) => {
          let echart = this.$echarts.init(item);
          let option = {
            color: ["#7c9cd6", "#f2a46e"],
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow"
              }
            },
            legend: {
              orient: "horizontal",
              bottom: "12"
            },
            xAxis: {
              data: this.allData[index].barData.name
            },
            yAxis: {},
            series: [
              {
                name: "班级均值",
                type: "bar",
                data: this.allData[index].barData.classBar
              },
              {
                name: "年级均值",
                type: "bar",
                data: this.allData[index].barData.gradeBar
              }
            ]
          };

          echart.setOption(option);
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.report-echarts {
  .echarts-item {
    margin-top: 35px;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
    .title {
      text-align: center;
      box-shadow: 1px 1px 2px 2px #f9fbfb;
      font-size: 16px;
      padding: 20px;
      font-weight: 700;
    }
    .echart-container {
      height: 400px;
      margin-bottom: 15px;
    }
  }
}
</style>
