<template>
    <div>
    <el-table :data="tableData" style="margin-top: 10px;" @selection-change="handleSelectionChange">
        <el-table-column type="selection"></el-table-column>
        <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
        <el-table-column label="姓名" width="80" align="center" prop="KHRXM"></el-table-column>
        <el-table-column label="定量考核"  width="100" align="center" prop="dldf"></el-table-column>
        <el-table-column label="学生家长评"  width="100" align="center" prop="xsjz"></el-table-column>
        <el-table-column label="教师自互评"  width="100" align="center"  prop="jszhp"></el-table-column>
        <el-table-column label="考核小组评"  width="100" align="center"  prop="khldxz"></el-table-column>
        <el-table-column label="职业道德考核最后得分" align="center" prop="zhdf"></el-table-column>
        <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
                <el-button @click="lookGR(scope.row)" type="text" size="small">查看个人评分表</el-button>
                <!-- <el-button @click="lookKH(scope.row)" type="text" size="small">查看考核结果名单</el-button> -->
            </template>
        </el-table-column>
    </el-table>
	<Dialog1 v-model="dialog1" :tableRow="tableRow" :pageSubtitle="pageSubtitle" @canceldialog="dialog1 = false"></Dialog1>
	<!-- <Dialog2 v-model="dialog2" :tableRow="tableRow" @canceldialog="dialog2 = false"></Dialog2> -->

</div>
</template>

<script>
import Dialog1 from './Dialog.vue'

export default {
    components:{Dialog1},
    props:{
        tableData: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        
    },
    created(){
        this.user = JSON.parse(localStorage.getItem("userinfo"));
        
        this.DevelopmentMB = JSON.parse(localStorage.getItem("DevelopmentMB"));
    },
    data() {
        return {
            dialog1:false,
            dialog2:false,
            tableRow:{},
            user:'',
            DevelopmentMB:{},
            pageSubtitle: '',
        }
    },
    methods:{
        handleSelectionChange(selection){
            
            this.$emit('handleSelectionChange',selection)
        },
        // //获取大标题
        // getpageTitle(data){
        //     console.log(data,'data');
        //     this.$api.post(
		// 		"/v1/mbbb/GetMBGLBById",
		// 		{
		// 			Id:data.MBGLID,
		// 			token: this.user.Token
		// 		},
		// 		r=>{
		// 			if (r.Code === 0) {
		// 				console.log(r.Target,'获取大标题');

		// 			}
		// 		}
		// 	)
        // },

        //获取副标题
        getpageSubtitle(){
            
            this.$api.post(
				"/v1/mbbb/GetMBSCJHBById",
				{
					Id:this.$parent.bbid,
					token: this.user.Token
				},
				r=>{
					if (r.Code === 0) {
						console.log(r.Target,'获取副标题');
                        this.pageSubtitle = r.Target.JHMC
					}
				}
			)
        },
        //查看下载个人评分表
        lookGR(data){
            this.dialog1 = true
            this.tableRow = data
            // this.getpageTitle(data)
            this.getpageSubtitle()
        },
        
    }
}
</script>

<style>

</style>