<template>
  <div>
    <el-dialog
      title="导出"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      :before-close="handleClose"
    >
      <div class="multi-opt-box">
        <div class="multi-opt">
          <div class="multi-opt-left">年级</div>
          <div class="multi-opt-rig">
            <el-radio-group
              v-model="gradeId"
              @change="handleGrade"
              class="jf-radio"
              size="small"
            >
              <el-row>
                <el-col
                  :span="24 / gradesList.length"
                  v-for="item in gradesList"
                  :key="item.Id"
                  ><el-radio :label="item.Id" border>{{
                    item.Name
                  }}</el-radio></el-col
                >
              </el-row>
            </el-radio-group>
          </div>
        </div>
        <div class="multi-opt class-block">
          <div class="multi-opt-left">班级</div>
          <div class="multi-opt-rig  class-item">
            <el-checkbox
              v-if="classDatas && classDatas.length > 0"
              :indeterminate="isIndeterminateClass"
              v-model="classCheckAll"
              class="all-check"
              @change="handleCheckAllClass"
              style="position:relative;top:-5px;"
              >全选</el-checkbox
            >
            <el-checkbox-group
              v-model="classIds"
              @change="handleClass"
              size="small"
            >
              <div style="margin: 15px 0;display:flex;flex-wrap:wrap">
                <div
                  style="margin-bottom: 10px;margin-right:10px"
                  v-for="item in classDatas"
                  :key="item.Id"
                >
                  <el-checkbox :label="item.Id">{{ item.Name }}</el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
          </div>
        </div>
        <div class="multi-opt kc-block ">
          <div class="multi-opt-left">课程</div>
          <div class="multi-opt-rig kc-item ">
            <el-checkbox
              v-if="courseList"
              :indeterminate="isIndeterminateCourse"
              v-model="courseCheckAll"
              class="all-check"
              @change="handleCheckAllCourse"
              style="position:relative;top:-5px;"
              >全选</el-checkbox
            >
            <el-checkbox-group
              v-model="courseIds"
              @change="handleCourse"
              size="small"
            >
              <div style="margin: 15px 0;display:flex;flex-wrap:wrap">
                <div
                  style="margin-bottom: 10px;margin-right:10px"
                  v-for="(item, index) in courseList.filter(
                    c => c.NJId === gradeId || !c.NJId
                  )"
                  :key="index"
                >
                  <el-checkbox :label="item.Id">{{ item.Name }}</el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportData" :loading="isExporting"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      gradeId: '',
      classIds: [],
      courseIds: [],
      grades: [],
      classDatas: [],
      courseList: [],
      classCheckAll: false,
      isIndeterminateClass: false,
      courseCheckAll: false,
      isIndeterminateCourse: false,
      isExporting: false,
      studentData: [],
      studentScore: [],
      checkClass: [],
      checkCourse: []
    }
  },
  created() {},
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: [
    'visible',
    'examinationCourse',
    'basicInfo',
    'userId',
    'token',
    'termId',
    'examinationId',
    'gradesList'
  ],
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    handleGrade() {
      this.classCheckAll = false
      this.isIndeterminateClass = false
      let grade = this.gradesList.find(c => c.Id === this.gradeId)
      if (grade) {
        this.classDatas = grade.ChidOrgList
        this.courseList = this.classDatas.reduce((pre, cur) => {
          cur.finalCourse.forEach(e => {
            if (e.Name !== '全部' && !pre.some(el => el.Id == e.Id)) pre.push(e)
          })
          return pre
        }, [])
      }
      this.classIds = []
      this.courseIds = []
    },
    handleClass(val) {
      let checkedCount = val.length
      this.classCheckAll = checkedCount === this.classDatas.length
      this.isIndeterminateClass =
        checkedCount > 0 && checkedCount < this.classDatas.length
    },
    handleCheckAllClass(val) {
      this.classIds = val ? this.classDatas.map(c => c.Id) : []
      this.isIndeterminateClass = false
    },
    handleCourse(val) {
      let checkedCount = val.length
      this.courseCheckAll = checkedCount === this.courseList.length
      this.isIndeterminateCourse =
        checkedCount > 0 && checkedCount < this.courseList.length
    },
    handleCheckAllCourse(val) {
      this.courseIds = val ? this.courseList.map(c => c.Id) : []
      this.isIndeterminateCourse = false
    },
    getStudentData() {
      this.isExporting = true
      return new Promise(resolve => {
        this.$api.post(
          'v1/accountmanage/GetOrgStudent',
          {
            token: this.token,
            SchoolOrgId: this.gradeId,
            OrgType: 1,
            SourceType: 1
          },
          r => {
            if (r.Code === 0) {
              this.studentData = r.Target
              this.getStudentScore().then(() => {
                this.isExporting = false
                resolve()
              })
            }
          }
        )
      })
    },
    getStudentScore() {
      return new Promise(resolve => {
        this.$api.post(
          '/v1/examination/GetExaminationStudentScore',
          {
            token: this.token,
            SchoolId: this.schoolId,
            ExaminationId: this.examinationId,
            TermId: this.termId
          },
          r => {
            if (r.Code === 0) {
              this.studentScore = r.Target
              this.initTableData()
              resolve()
            }
          }
        )
      })
    },
    initTableData() {
      this.checkClass = this.classDatas
        .filter(c => this.classIds.includes(c.Id))
        .map(c => ({ Id: c.Id, Name: c.Name, finalCourse: c.finalCourse }))

      this.checkCourse = this.courseList
        .filter(
          c =>
            (c.NJId === this.gradeId || !c.NJId) &&
            this.courseIds.includes(c.Id)
        )
        .map(c => ({ Id: c.Id, Name: c.Name }))
      this.studentData = this.studentData
        .filter(c => this.checkClass.map(item => item.Id).includes(c.ClassId))
        .map(c => {
          for (let course of this.checkCourse) {
            let level = {}
            this.basicInfo.CourseList.forEach(item => {
              if (item.GradeCourseList) {
                item.GradeCourseList.forEach(it => {
                  if (it.Id === course.Id && it.gradeId === this.gradeId)
                    level = it
                })
              }
            })
            let score = this.studentScore.find(
              item => item.KCH === course.Id && item.XSId === c.Id
            )
            let levelData = ''
            if (level && level.LevelList && score) {
              let data = level.LevelList.find(c => c.Id === score.KCDJ)
              if (data) {
                levelData = data.DJ
              }
            }
            c[course.Name] = score
              ? score.SFQK === 1
                ? '缺考'
                : score.DFFS === 1
                ? score.KCCJ
                : levelData
              : ''
          }
          return c
        })
    },
    exportData() {
      if (this.classIds && this.classIds.length === 0)
        return this.$message.error('请勾选班级')
      if (this.courseIds && this.courseIds.length === 0)
        return this.$message.error('请勾选课程')
      this.getStudentData().then(() => {
        this.exportExcel()
      })
    },
    exportExcel() {
      import('@/style/js/Export2ExcelSheetArray').then(excel => {
        let tHeader = this.checkClass.reduce((pre, cur) => {
          let course = cur.finalCourse.filter(item =>
            this.checkCourse.some(it => it.Id === item.Id)
          )
          if (course.length > 0) {
            pre.push(['学号', '姓名'].concat(course.map(c => c.Name)))
          } else {
            pre.push(['学号', '姓名'])
          }
          return pre
        }, [])

        this.studentData.sort((a, b) => a.Name.localeCompare(b.Name))

        let data = this.checkClass.map(c => ({
          Sheet: c.Name,
          Data: this.formatJson(
            ['XH', 'Name'].concat(this.checkCourse.map(c => c.Name)),
            this.studentData.filter(item => item.ClassId === c.Id)
          )
        }))
        excel.export_json_to_excel({
          header: tHeader, //表头 必填
          data, //具体数据 必填
          filename: new Date().getTime() + '' //非必填
        })
      })
      this.dialogVisible = false
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j]
        })
      )
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$emit('change', false)
      } else {
        this.classIds = []
        this.gradeId = ''
        this.courseIds = []
        this.classDatas = []
        this.classCheckAll = false
        this.isIndeterminateClass = false
        this.courseCheckAll = false
        this.isIndeterminateCourse = false
        this.isExporting = false
        this.studentData = []
        this.studentScore = []
        this.checkClass = []
        this.checkCourse = []

        this.gradeId = this.gradesList[0].Id
        this.handleGrade()
      }
    },
    visible(val) {
      this.dialogVisible = val
    }
  }
}
</script>
<style lang="scss" scoped>
.class-block,
.kc-block {
  display: flex;
  align-items: center;
  .class-item,
  .kc-item {
    display: flex;
    align-items: center;
    .all-check {
      margin-right: 10px;
    }
  }
}
</style>
