<template>
  <el-table
    :data="tableData"
    class="jf-table"
    border
    tooltip-effect="dark"
    style="width: 100%;"
  >
    <el-table-column fixed prop="ANMC" label="按钮名称"></el-table-column>
    <el-table-column prop="PXH" label="排序"></el-table-column>
    <el-table-column label="位置">
      <template slot-scope="scope">
        {{
          scope.row.ZW === 1
            ? "上"
            : scope.row.ZW === 2
            ? "中"
            : scope.row.ZW === 3
            ? "下"
            : ""
        }}
      </template>
    </el-table-column>
    <el-table-column prop="EvaluationMethod" label="支撑评价方式">
      <template slot-scope="scope">
        {{
          [
            scope.row.TYZB === 1 ? "通用指标" : "",
            scope.row.XXZB === 1 ? "星星指标" : "",
            scope.row.WJ === 1 ? "问卷" : ""
          ]
            .filter(c => c.length > 0)
            .join(",")
        }}
      </template>
    </el-table-column>
    <el-table-column label="状态">
      <template slot-scope="scope">
        {{ scope.row.SYZT === 1 ? "正常" : "停用" }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="操作" width="250">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="editItem(scope.row)"
          >编辑</el-button
        >
        <el-button
          @click="delItem(scope.row)"
          type="text"
          size="small"
          class="tab-wz-butta c-red"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    editItem(data) {
      this.$emit("editItem", data);
    },
    delItem(data) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api.post(
            "/v1/buttonmanager/DelButton",
            {
              token: this.$store.state.token,
              Id: data.Id,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message
                });
                this.$emit("delItem", data);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>
