<template>
    <div class="mian-box">
        <div class="top-title-box">
            <span>{{this.BT}}</span>
        </div>
        <p v-html="this.FWB" class="firs-text-box">
        </p>
    </div>
</template>

<script>

export default {  
  name: "UsingHelpListIndex",
  data() {
    return {
      BT:"",
      FWB:""
    };
  },
  created(){
  },
  mounted: function() {
    this.getData();
  },
  methods: {    
    getData() {
      this.$api.get(
        "/v1/evaluationreport/GetSybzglDetail",
        { 
          id: this.getQueryString("id")
        },
        r => {
          if (r != null && r.Code === 0) {
            if (parseInt(this.getQueryString("lb")) === 1) {
                    this.BT = r.Target.BT;
                    this.FWB = r.Target.FWB;
                } else {
                    var json = eval(r.FWB);
                    window.location = json[0].url;
                }
            }
        },
        err => {
        }
      );
    },
    getQueryString(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        };
        return null;
    }
  },
  watch: {
  }
};
</script>

<style>
  html {
      font-size: 14px;
  }

  html, body {
      margin: 0px;
      padding: 0px;
      width: 100%;
      height: 100%;
      font-family: "苹方", "Microsoft YaHei", "Arial"
  }

  input, textarea, img {
      margin: 0px;
      padding: 0px;
  }

  .mian-box {
      box-sizing: border-box;
      padding: 0rem 2rem 1rem 2rem;
  }

  .top-title-box {
      font-size: 1.6rem;
      color: #333333;
      text-align: center;
      box-sizing: border-box;
      padding: 2rem 0rem 1rem 0rem;
  }

  .firs-text-box {
      font-size: 1rem;
      color: #333333;
      font-weight: bolder;
      line-height: 1.5rem;
      text-indent: 2rem;
  }

  .main-text {
      font-size: 1.2rem;
      font-weight: bolder;
      color: #333333;
      line-height: 1.5rem;
      text-indent: 2rem;
  }

  .text-tit-box {
      margin-top: 2.6rem;
      margin-bottom: 1rem;
      color: #333333;
      font-weight: 800;
  }

  .text-p {
      font-size: 1rem;
      color: #666666;
      font-weight: 300;
      letter-spacing: 0.06rem
  }

  .text-p-l {
      font-size: 1rem;
      color: #666666;
  }

  @media screen and (max-width:768px) {
      html {
          font-size: 13px;
      }

      .mian-box {
          padding: 0rem 1.2rem 1rem 1.2rem;
      }
  }
</style>