<template>
  <div class="cont-whole-box" style="text-align: center;">
    <el-form
      class="form"
      :model="jxgyinfo"
      label-width="120px"
      style="width: 500px; display: inline-block;"
      :rules="rules"
      ref="sbmitfrom"
    >
      <el-form-item label="计划名称：" prop="JHMC">
        <el-input
          v-model="jxgyinfo.JHMC"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="计划类别：" prop="JFLBId">
        <el-select
          style="width: 100%;"
          v-model="jxgyinfo.JFLBId"
          placeholder="请选择计划类别"
        >
          <el-option
            v-for="item in lblist"
            :key="item.Id"
            :label="item.LBMC"
            :value="item.Id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="计划内容：" prop="JHNR">
        <el-input
          type="textarea"
          placeholder="请输入计划内容"
          v-model="jxgyinfo.JHNR"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="附件：">
        <el-upload
          class="upload-demo"
          :on-success="handleAvatarSuccess"
          accept="image/jpeg, image/gif, image/png"
          :action="uploadURL"
          :on-error="upLoadError"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :limit="6"
          :on-exceed="handleExceed"
          :auto-upload="true"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item style="display:inline-block" label="折合时长：" prop="ZHSC">
        <el-input
          style="width:100px"
          type="ZHSC"
          v-model="jxgyinfo.ZHSC"
        ></el-input>
      </el-form-item>
      <span style="font-size:12px;padding-left:10px">分钟</span>
      <el-form-item
        style="display:inline-block"
        label="统计入："
        prop="TJRHDLX"
      >
        <el-select
          style="width:125px"
          v-model="jxgyinfo.TJRHDLX"
          placeholder="请选择统计入类别"
        >
          <el-option label="阅读数据" value="2"></el-option>
          <el-option label="运动数据" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="display:inline-block" label="开展时间：" prop="KSRQ">
        <el-date-picker
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          v-model="jxgyinfo.KSRQ"
          :picker-options="pickerOptions"
          style="width: 155px;"
        ></el-date-picker>
      </el-form-item>
      <span style="font-size:12px;padding:0 25px;">到</span>
      <el-form-item class="endtime" prop="JFRQ">
        <el-date-picker
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择时间"
          v-model="jxgyinfo.JFRQ"
          style="width: 155px;"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="执行频次：" prop="XZZXPC">
        <el-select
          style="width: 100%;"
          v-model="jxgyinfo.XZZXPC"
          placeholder="请选择"
          @change="switchType"
        >
          <el-option label="每周定期" :value="1"></el-option>
          <el-option label="每周随机" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="每周定期：" prop="weekday" v-show="visibleDQ">
        <el-select
          style="width: 100%;"
          v-model="weekday"
          multiple
          placeholder="请选择"
        >
          <el-option label="周一" value="1"></el-option>
          <el-option label="周二" value="2"></el-option>
          <el-option label="周三" value="3"></el-option>
          <el-option label="周四" value="4"></el-option>
          <el-option label="周五" value="5"></el-option>
          <el-option label="周六" value="6"></el-option>
          <el-option label="周日" value="7"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="每周随机：" prop="MZSJ" v-show="visibleSJ">
        <el-input-number
          v-model="jxgyinfo.MZSJ"
          :min="1"
          :max="7"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="排序号" prop="PXH">
        <el-input-number
          v-model="jxgyinfo.PXH"
          :min="1"
          controls-position="left"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="开展年级" prop="checkednjs">
        <el-checkbox v-model="kznjall" @change="handleNJCheckAllChange"
          >全选</el-checkbox
        >
        <el-checkbox
          v-for="nj in njlist"
          v-model="jxgyinfo.checkednjs"
          :label="nj.Id"
          @change="handleNJCheckedChange"
          :key="nj.Id"
          >{{ nj.Name }}</el-checkbox
        >
      </el-form-item>
      <el-form-item label="输入设置：">
        <el-row>
          <el-col>
            <el-checkbox-group v-model="srfs" style="width: 310px;">
              <el-checkbox label="1">图片</el-checkbox>
              <el-checkbox label="2">音频</el-checkbox>
              <el-checkbox label="3">视频</el-checkbox>
              <el-checkbox label="4">文字</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-checkbox label="数字" v-model="szxz"></el-checkbox>
          </el-col>
          <el-col :span="4">单位</el-col>
          <el-col :span="8">
            <el-select
              v-model="dwxz"
              placeholder="请选择"
              v-bind:disabled="!szxz"
            >
              <el-option label="个" value="个"></el-option>
              <el-option label="分钟" value="分钟"></el-option>
              <el-option label="米" value="米"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-checkbox label="输入单选项" v-model="srdxxcheck"></el-checkbox>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-tag
              v-for="dxx in srdxx"
              :key="dxx"
              closable
              @close="handleClose(dxx)"
              >{{ dxx }}</el-tag
            >
            <el-button
              v-bind:disabled="!srdxxcheck"
              type="primary"
              @click="dialogVisible = true"
              icon="el-icon-plus"
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-col :span="8">
          <el-checkbox
            label="是否公开展示打卡成果"
            v-model="sfgkzs"
          ></el-checkbox>
        </el-col>
      </el-form-item>
      <br />
      <br />
      <el-form-item>
        <el-button
          type="primary"
          @click="submitshowdata"
          :loading="submitLoading"
          >保存</el-button
        >
        <el-button @click="canceladd">取消</el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      title="输入项"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleOptionClose"
    >
      <el-form
        :model="optiontextValidateForm"
        ref="optiontextValidateForm"
        :inline="true"
      >
        <el-form-item
          :rules="[
            { required: true, message: '单选项不能为空' },
            {
              min: 1,
              max: 20,
              message: '长度在1到20个字符',
              trigger: 'change'
            }
          ]"
          prop="optiontext"
        >
          <el-input
            type="optiontext"
            v-model="optiontextValidateForm.optiontext"
            placeholder="不超过20个字"
            maxlength="20"
            minlength="1"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleOptionClose()">取 消</el-button>
          <el-button
            type="primary"
            @click="handleConfirm(optiontextValidateForm)"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { isIntegerAndZero } from '@/style/js/Validator.js'
import { formatDate } from '@/utils/date.js'
export default {
  created() {
    this.ssxq = localStorage.getItem('currentterm')
    let term = localStorage.getItem('curTerm')
    if (term) this.term = JSON.parse(term)
    var tempuser = localStorage.getItem('userinfo')
    this.jxgyinfo.Id = this.$route.params.hdid
    if (tempuser) {
      var user = JSON.parse(tempuser)
      this.ssxx = user.SSZZId
      this.cjr = user.Id
      this.token = user.Token
      this.fileServerApiPath = user.FileServerApiPath
      this.webFilePath = user.WebFilePath
    }
  },
  computed: {
    // 文件的上传路径
    uploadURL: function() {
      return '/file/file/fileservice/UploadFile?token=' + this.token
    }
  },
  mounted: function() {
    this.loadlbdata()
    this.loadnjdata()
    this.loaddata()
    if (this.jxgyinfo.Id !== undefined && this.jxgyinfo.Id.length === 36) {
      this.xsmc = '编辑'
    }
    this.jxgyinfo.CJSJ = this.getdate()
  },
  methods: {
    // 文件上传失败时的钩子
    upLoadError() {
      this.$message.info('附件上传失败')
    },
    switchType(type) {
      if (type === 1) {
        this.visibleDQ = true
        this.visibleSJ = false
      } else if (type === 2) {
        this.visibleDQ = false
        this.visibleSJ = true
      }
    },
    handleRemove(fileList) {
      this.filearr = []
      for (var i = 0; i < fileList.length; i++) {
        var response = fileList[i].response
        this.filearr.push({
          FJMC: response.Target.FileName,
          FJLJ: response.Target.FilePath
        })
      }
    },
    handleAvatarSuccess(response) {
      if (response.Code === -3) {
        this.$message.info('附件上传失败')
      } else {
        this.filearr.push({
          FJMC: response.Target.FileName,
          FJLJ: response.Target.FilePath
        })
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleClose(tag) {
      this.srdxx.splice(this.srdxx.indexOf(tag), 1)
    },
    // 取消
    canceladd: function() {
      this.$router.push({ path: '/jxgy' })
    },
    replacePos: function(strObj, pos, replacetext) {
      var str =
        strObj.substr(0, pos - 1) +
        replacetext +
        strObj.substring(pos, strObj.length)
      return str
    },
    submitshowdata: function() {
      if (this.jxgyinfo.KSRQ > this.jxgyinfo.JFRQ) {
        this.$message({
          type: 'info',
          message: '结束日期不能小于开始日期!'
        })
        return
      }
      var termdate = new Date()
      var year = termdate.getFullYear()
      termdate = this.dateformat(new Date(++year, 8, 1, 0, 0, 0), true)
      if (this.jxgyinfo.JFRQ >= termdate) {
        this.$message({
          type: 'info',
          message: '结束日期必须在' + termdate + '之前'
        })
        return
      }
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          if (this.sfgkzs) {
            this.jxgyinfo.GKZS = 1
          } else {
            this.jxgyinfo.GKZS = 2
          }
          this.jxgyinfo.KZNJ = this.jxgyinfo.checkednjs.join(',')
          const srarr = []
          var list = this.srfs
          for (var i = 0; i < list.length; i++) {
            srarr.push({ SRFSLX: list[i], FJNR: '' })
          }
          const dw = this.dwxz
          if (this.szxz) {
            srarr.push({ SRFSLX: 5, FJNR: dw })
          }
          if (this.srdxxcheck) {
            if (this.srdxx.length <= 0) {
              this.$message({
                type: 'info',
                message: '输入单选项不能为空!'
              })
              return
            }
            const sr = this.srdxx
            for (let i = 0; i < sr.length; i++) {
              srarr.push({ SRFSLX: 6, FJNR: sr[i] })
            }
          }
          this.jxgyinfo.SRSZ = JSON.stringify(srarr)
          var text = '0000000'
          if (this.jxgyinfo.XZZXPC === 1) {
            var weekdayarr = this.weekday
            for (let i = 0; i < weekdayarr.length; i++) {
              text = this.replacePos(text, weekdayarr[i], 1)
            }
          }
          var fjstr = ''
          if (
            this.filearr !== undefined &&
            this.filearr !== null &&
            this.filearr.length > 0
          ) {
            fjstr = JSON.stringify(this.filearr)
          }
          this.jxgyinfo.ZXPC = text
          this.submitLoading = true
          this.$api.post(
            'v1/extracurricular/AddJXGY',
            {
              JXGYInfo: this.jxgyinfo,
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              CJR: this.cjr,
              FJStr: fjstr,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: 'info',
                  message: '保存成功!'
                })
                this.submitLoading = false
                this.$router.push({ path: '/jxgy' })
              } else {
                this.submitLoading = false
              }
            }
          )
        } else {
          this.submitLoading = false
          return false
        }
      })
    },
    // 确定单选项
    handleConfirm() {
      this.$refs.optiontextValidateForm.validate(valid => {
        if (valid) {
          this.srdxx.push(this.optiontextValidateForm.optiontext)
          this.optiontextValidateForm.optiontext = ''
          this.dialogVisible = false
        } else {
          return false
        }
      })
    },
    // 关闭单选项
    handleOptionClose() {
      this.$refs.optiontextValidateForm.resetFields()
      this.dialogVisible = false
    },
    handleNJCheckAllChange: function(val) {
      if (!val) {
        this.jxgyinfo.checkednjs = []
      } else {
        this.jxgyinfo.checkednjs = this.njlist.map(item => item.Id)
      }
    },
    handleNJCheckedChange: function() {
      const checkedCount = this.jxgyinfo.checkednjs.length
      this.kznjall = checkedCount === this.njlist.length
    },
    loaddata: function() {
      if (this.jxgyinfo.Id !== undefined && this.jxgyinfo.Id !== '') {
        this.$api.post(
          'v1/extracurricular/GetJXGYById',
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            Id: this.jxgyinfo.Id,
            token: this.token
          },
          r => {
            if (r.Code === 0 && r.Target !== null) {
              this.jxgyinfo = r.Target
              // if(this.jxgyinfo.ZXPC !== "" && this.jxgyinfo.ZXPC !== null && this.jxgyinfo.ZXPC !== undefined && this.jxgyinfo.XZZXPC !== 2){
              //     this.jxgyinfo.XZZXPC = 1;
              // }
              this.switchType(this.jxgyinfo.XZZXPC)
              this.jxgyinfo.TJRHDLX = '' + this.jxgyinfo.TJRHDLX + ''
              this.jxgyinfo.checkednjs = this.jxgyinfo.KZNJ.split(',')
              this.kznjall =
                this.jxgyinfo.checkednjs.length === this.njlist.length
              var fjlist = this.jxgyinfo.FJList
              if (fjlist !== null && fjlist.length > 0) {
                this.fileList = []
                for (var i = 0; i < fjlist.length; i++) {
                  this.fileList.push({
                    name: fjlist[i].FJMC,
                    url: fjlist[i].FJLJ
                  })
                  this.filearr.push({
                    FJMC: fjlist[i].FJMC,
                    FJLJ: fjlist[i].FJLJ
                  })
                }
              }
              if (this.jxgyinfo.GKZS === 1) {
                this.sfgkzs = true
              }
              var arr = this.jxgyinfo.ZXPC.split('')
              for (let i = 0; i < arr.length; i++) {
                if (arr[i] === '1') {
                  this.weekday.push('' + (i + 1) + '')
                }
              }
              this.loadsrfsdata()
            } else {
              this.$message({
                type: 'info',
                message: '没有获取到相关数据!'
              })
            }
          }
        )
      }
    },
    loadsrfsdata: function() {
      if (this.jxgyinfo.Id !== undefined && this.jxgyinfo.Id !== '') {
        this.$api.post(
          'v1/extracurricular/GetJXGYSRFSList',
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            HDId: this.jxgyinfo.Id,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              var list = r.Target
              for (var i = 0; i < list.length; i++) {
                if (list[i].SRFSLX === 5) {
                  this.szxz = true
                  this.dwxz = list[i].FJNR
                } else if (list[i].SRFSLX === 6) {
                  this.srdxx.push(list[i].FJNR)
                  this.srdxxcheck = true
                } else {
                  this.srfs.push('' + list[i].SRFSLX + '')
                }
              }
            }
          }
        )
      }
    },
    loadnjdata: function() {
      this.$api.post(
        'v1/accountmanage/GetSchoolOrg',
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.njlist = []
            var list = r.Target
            for (var i = 0; i < list.length; i++) {
              if (list[i].Hierarchy === 1) {
                this.njlist.push(list[i])
              }
            }
          }
        }
      )
    },
    loadlbdata: function() {
      this.$api.post(
        'v1/extracurricular/GetLBList',
        { SSXX: this.ssxx, token: this.token },
        r => {
          if (r.Code === 0) {
            this.lblist = r.Target
          }
        }
      )
    },
    dateformat: function(mydate, onlydate) {
      // 获取当前年
      var year = mydate.getFullYear()
      // 获取当前月
      var month = mydate.getMonth() + 1
      // 获取当前日
      var date = mydate.getDate()
      if (onlydate) {
        return year + '-' + this.conver(month) + '-' + this.conver(date)
      } else {
        var h = mydate.getHours() // 获取当前小时数(0-23)
        var m = mydate.getMinutes() // 获取当前分钟数(0-59)
        var s = mydate.getSeconds()
        return (
          year +
          '-' +
          this.conver(month) +
          '-' +
          this.conver(date) +
          ' ' +
          this.conver(h) +
          ':' +
          this.conver(m) +
          ':' +
          this.conver(s)
        )
      }
    },
    getdate: function() {
      var myDate = new Date()
      // 获取当前年
      var year = myDate.getFullYear()
      // 获取当前月
      var month = myDate.getMonth() + 1
      // 获取当前日
      var date = myDate.getDate()
      var h = myDate.getHours() // 获取当前小时数(0-23)
      var m = myDate.getMinutes() // 获取当前分钟数(0-59)
      var s = myDate.getSeconds()
      // 获取当前时间
      var now =
        year +
        '-' +
        this.conver(month) +
        '-' +
        this.conver(date) +
        ' ' +
        this.conver(h) +
        ':' +
        this.conver(m) +
        ':' +
        this.conver(s)
      return now
    },
    conver: function(s) {
      return s < 10 ? '0' + s : s
    }
  },
  data() {
    let _that = this
    var validateweekday = (rule, value, callback) => {
      // if (this.weekday.length > 0) {
      //   callback();
      // } else {
      //   callback(new Error("请选择执行频次"));
      // }
      callback()
    }
    var validatekznj = (rule, value, callback) => {
      if (this.jxgyinfo.checkednjs.length > 0) {
        callback()
      } else {
        callback(new Error('请选择开展年级'))
      }
    }
    return {
      submitLoading: false,
      optiontextValidateForm: {
        optiontext: ''
      },
      webFilePath: '',
      fileServerApiPath: '',
      dialogFormVisible: false,
      ssxx: '',
      ssxq: '',
      dialogVisible: false,
      token: '',
      weekday: [],
      checkednjs: [],
      srfs: [],
      type: [],
      xsmc: '新增',
      szxz: false,
      kznjall: false,
      dwxz: '个',
      isIndeterminate: false,
      sfgkzs: false,
      lblist: [],
      njlist: [],
      srfslx: [],
      srdxx: [],
      filearr: [],
      fileList: [],
      cjr: '',
      srdxxcheck: false,
      visibleDQ: false,
      visibleSJ: false,
      jxgyinfo: {
        Id: '',
        CJR: '',
        CJSJ: '',
        JHMC: '',
        JFLBId: '',
        JHNR: '',
        FJBS: '',
        ZHSC: '',
        TJRHDLX: '',
        SYZT: 1,
        KSRQ: '',
        JFRQ: '',
        ZXPC: '',
        KZNJ: '',
        checkednjs: [],
        SRSZ: '',
        GKZS: 2,
        FJLB: '',
        XZZXPC: '',
        MZSJ: 0,
        PXH: 0
      },
      rules: {
        XZZXPC: [
          { required: true, message: '请选择执行频次', trigger: 'change' }
        ],
        JHMC: [
          { required: true, message: '请输入计划名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在1到50个字符', trigger: 'change' }
        ],
        ZHSC: [
          { required: true, message: '请输入折合时长', trigger: 'change' },
          {
            validator: isIntegerAndZero,
            message: '请输0或正整数',
            trigger: 'change'
          }
        ],
        JHNR: [
          { required: true, message: '请输入计划内容', trigger: 'change' },
          {
            min: 1,
            max: 500,
            message: '长度在1到500个字符',
            trigger: 'change'
          }
        ],
        JFLBId: [
          { required: true, message: '请选择计划类别', trigger: 'change' }
        ],
        TJRHDLX: [
          { required: true, message: '请选择统计入类别', trigger: 'change' }
        ],
        weekday: [
          {
            validator: validateweekday,
            message: '请选择执行频次',
            trigger: 'change'
          }
        ],
        checkednjs: [
          { required: true, message: '选择开展年级', trigger: 'change' },
          {
            validator: validatekznj,
            message: '请选择开展年级',
            trigger: 'change'
          }
        ],
        KSRQ: [
          { required: true, message: '选择正确的日期', trigger: 'change' }
        ],
        JFRQ: [{ required: true, message: '选择正确的日期', trigger: 'change' }]
      },
      term: {},
      pickerOptions: {
        disabledDate(date) {
          return (
            date < new Date(formatDate(_that.term.BeginTime)) ||
            date > Date(formatDate(_that.term.EndTime))
          )
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form {
  .endtime {
    display: inline-block;
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  .PX-input {
    ::v-deep .el-input-number__increase {
      display: none;
    }
    ::v-deep .el-input-number__decrease {
      display: none;
    }
  }
}
</style>
