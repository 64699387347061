<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div
        class="cz-cont-name-l"
        style="font-size: 1rem; color: #333333; border: none;"
        v-for="(item, tmindex) in formData"
        :key="tmindex"
      >
        {{ tmindex + 1 }}.&nbsp;{{ item.FXMC }}({{ SSLB[item.FXLX - 1].Value }})
        <span
          class="essay-bt-textbunn"
          @click="handleDetail(item.FXId)"
          v-if="item.AnswerCount > 0"
          >点击查看详情</span
        >
        <div class="tab-heng-box" style="margin-top: 1rem;">
          <div class="essay-t-ms">
            回答人数：
            <span style="font-weight: 800; color: #14d089;">
              {{ item.FXCount }} </span
            >人
          </div>
          <el-table
            cellpadding="0"
            border
            cellspacing="0"
            v-if="item.FXLX != 3"
            :data="item.DAList"
            highlight-current-row
            style="width: 100%;"
          >
            <el-table-column
              property="DAMC"
              align="center"
              label="选项"
              width="60"
            >
              <template slot-scope="scope">{{
                ZM[scope.row.DAPXH - 1]
              }}</template>
            </el-table-column>
            <el-table-column property="DAMC" align="center" label="名称">
              <template slot-scope="scope">
                <span v-if="scope.row.DALX == 1">{{ scope.row.DAMC }}</span>
                <span v-else>其他</span>
              </template>
            </el-table-column>
            <el-table-column
              property="AnswerCount"
              align="center"
              label="人数"
            ></el-table-column>
            <el-table-column property="Ratio" align="center" label="选择占比">
              <template slot-scope="scope">{{ scope.row.Ratio }}%</template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
export default {
  created() {
    this.ssxq = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    this.tzggid = this.$route.params.ggid;
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.ssxx = user.SSZZId;
      this.cjr = user.Id;
      this.token = user.Token;
    }
  },
  computed: {
    listTemp: function() {
      var list = this.list;
      var arrTemp = [];
      var index = 0;
      var sectionCount = 3;
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp;
    }
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    loaddata: function() {
      if (this.tzggid !== undefined && this.tzggid !== "") {
        this.$api.post(
          "v1/notice/GetQuestionnaireReport",
          {
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            TZGGId: this.tzggid,
            token: this.token
          },
          r => {
            if (r.Code === 0) {
              this.formData = r.Target;
            }
          }
        );
      }
    },
    handleDetail: function(fxid) {
      this.$router.push({
        name: "questionnaireanswer",
        params: { ggid: this.tzggid, fxid: fxid }
      });
    }
  },
  data() {
    return {
      isDisabled: false,
      webFilePath: "",
      fileServerApiPath: "",
      tzggid: "",
      ssxx: "",
      ssxq: "",
      token: "",
      xsmc: "新增",
      njall: false,
      jyzall: false,
      njlist: [],
      jyzlist: [],
      xznjlist: [],
      xzjyzlist: [],
      bjid: "",
      formData: {},
      cjr: "",
      SSLB: [
        { Key: 1, Value: "单选" },
        { Key: 2, Value: "多选" },
        { Key: 3, Value: "问答" }
      ],
      ZM: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ],
      options: [
        {
          value: "1",
          label: "学生"
        },
        {
          value: "2",
          label: "老师"
        },
        {
          value: "3",
          label: "全体师生"
        }
      ]
    };
  }
};
</script>
