<template>
  <div>
    <Tree
      :treeData="treeData"
      ref="tree"
      nodeKey="Id"
      :disableReverseRelation="true"
      enableCheck
      expandAll
      :defaultProps="defaultProps"
      @onChange="onChange"
      @nodeClick="nodeClick"
      :enableDraggable="true"
      @onDrop="onDrop"
    />
  </div>
</template>

<script>
import Tree from "@/components/Tree.vue";
export default {
  components: {
    Tree
  },
  data() {
    return {
      defaultProps: {
        children: "ch",
        label: "name",
        parent: "pid",
        class: "cls",
        icon: "ic"
      },
      addData: {
        Id: 9,
        pid: 5,
        name: "1-1-1-3",
        ch: []
      },
      currentNode: {},
      treeData: [
        {
          ch: [
            {
              Id: 3,
              pid: 1,
              name: "1-1",
              ch: [
                {
                  Id: 5,
                  pid: 3,
                  name: "1-1-1",
                  ch: [
                    {
                      Id: 7,
                      pid: 5,
                      name: "1-1-1-1",
                      ch: []
                    },
                    {
                      Id: 8,
                      pid: 5,
                      name: "1-1-1-2",
                      ch: [],
                      cl: "red"
                    }
                  ]
                },
                {
                  Id: 6,
                  pid: 3,
                  name: "1-1-2",
                  ch: []
                }
              ]
            },
            {
              Id: 4,
              pid: 1,
              name: "1-2",
              ch: []
            }
          ],
          name: "1",
          Id: 1,
          cls: "text-color-test"
        },
        {
          ch: [],
          name: "2",
          Id: 2
        }
      ],
      addData2: {
        Id: 10,
        pid: 1,
        name: "1-3",
        ch: []
      },
      delNode: {}
    };
  },
  created: function() {},
  methods: {
    nodeClick(node) {
      this.currentNode = node;
      this.delNode = node;
    },
    onChange() {},
    onDrop() {},
    add() {
      this.$refs.tree.addNode(this.currentNode, this.addData);
    },
    remove() {
      this.$refs.tree.removeNode(this.delNode);
    },
    update() {
      this.$refs.tree.updateNode(this.currentNode, this.addData2);
    }
  }
};
</script>
<style>
.text-color-test {
  color: #4ed1de;
}
</style>
