<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->

      <div class="tab-heng-box margno-t">
        <div class="table-er-box">
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="openteachinggroup"
            >新增教研组</el-button
          >
        </div>

        <br />

        <el-table :data="tableData" border style="width: 100%;">
          <el-table-column prop="JYZMC" label="教研组名称"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="editRow(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteRow(scope.$index, scope.row)"
                >删除</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="opengroupnumber(scope.$index, scope.row)"
                >管理成员</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--表格区结束-->
    </div>
    <!--弹出框-->
    <el-dialog
      title="新增教研组"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form label-width="80px" :model="JYZGLInfo">
        <el-form-item label="教研组名称" :label-width="formLabelWidth">
          <el-input v-model="JYZGLInfo.JYZMC" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitshowdata">确 定</el-button>
      </div>
    </el-dialog>

    <!--管理成员弹出框-->
    <el-dialog
      title="管理成员"
      :visible.sync="dialogFormVisiblegroup"
      :close-on-click-modal="false"
      customClass="customWidth"
    >
      <el-form>
        <el-tabs type="border-card">
          <el-tab-pane label="教研组成员">
            <div style="min-height: 200px;">
              <el-tag
                v-for="item in groupTeacherList"
                :key="item.Id"
                closable
                type="info"
                @close="handleClose(item)"
                >{{ item.Name }}</el-tag
              >
            </div>
          </el-tab-pane>
        </el-tabs>
        <br />
        <br />
        <el-tabs type="border-card">
          <el-tab-pane label="教师选择">
            <div>
              <el-input
                v-model="searchTeacher"
                autocomplete="off"
                style="width: 200px;"
              ></el-input
              >&nbsp;&nbsp;&nbsp;
              <el-button type="primary" @click="searchgroupteacher"
                >查询</el-button
              >
            </div>
            <br />
            <br />
            <div style="height: 200px; overflow-y: auto; overflow-x: hidden;">
              <el-tag
                v-for="item in selectTeacherList"
                :key="item"
                @click="groupselectteacher(item)"
                >{{ item.Name }}</el-tag
              >
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisiblegroup = false">取 消</el-button>
        <el-button type="primary" @click="savegGroupTeacher">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TeachingGroupList",
  data() {
    return {
      token: this.$store.state.token,
      // loading:true,
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      dialogFormVisible: false,
      dialogFormVisiblegroup: false,
      formLabelWidth: "120px",
      JYZGLInfo: {
        Id: 0,
        JYZMC: "",
        CJR: "",
        CJSJ: "2019-10-16 01:19:22",
        SSXX: "",
        SYZT: 0,
        SFSC: 0
      },
      searchTeacher: "",
      groupId: "",
      schoolTeacherList: [],
      selectTeacherList: [],
      groupTeacherList: []
    };
  },
  created: function() {
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.JYZGLInfo.SSXX = userinfo.SSZZId;
    this.JYZGLInfo.CJR = userinfo.Id;

    this.$api.post(
      "v1/role/GetRoleSchoolTeacher",
      { SchoolId: this.JYZGLInfo.SSXX, token: this.token },
      r => {
        if (r.Code === 0) {
          this.schoolTeacherList = r.Target;
        }
      }
    );

    this.refloadlist();
  },
  methods: {
    // 刷新
    refloadlist: function() {
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize };

      this.$api.post(
        "v1/teachinggroup/TeachingGroupList",
        {
          SchoolId: this.JYZGLInfo.SSXX,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target;

            this.currentPage = r.PagerInfo.PageIndex;
            this.pagesize = r.PagerInfo.PageSize;
            this.rowcount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    // 打开新增
    openteachinggroup: function() {
      this.dialogFormVisible = true;

      var schoolId = this.JYZGLInfo.SSXX;
      var cjr = this.JYZGLInfo.CJR;

      this.JYZGLInfo = {
        Id: "",
        JYZMC: "",
        CJR: cjr,
        CJSJ: "2019-10-16 01:19:22",
        SSXX: schoolId,
        SYZT: 0,
        SFSC: 0
      };
    },
    // 修改
    editRow: function(index, row) {
      this.JYZGLInfo = {
        Id: row.Id,
        JYZMC: row.JYZMC,
        CJR: row.CJR,
        CJSJ: row.CJSJ,
        SSXX: row.SSXX,
        SYZT: row.SYZT,
        SFSC: row.SFSC
      };

      // this.JYZGLInfo = row;
      this.dialogFormVisible = true;
    },

    // 删除
    deleteRow: function(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 提交
          this.$api.post(
            "v1/teachinggroup/TeachingGroupDelete",
            { Id: row.Id, token: this.token },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: "success",
                  message: r.Message,
                  duration: 3000
                });

                this.refloadlist();
              } else {
                this.$message({
                  type: "error",
                  message: r.Message,
                  showClose: true
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    // 保存
    submitshowdata: function(event) {
      event.preventDefault();

      this.$api.post(
        "v1/teachinggroup/TeachingGroupAdd",
        { JYZGLInfo: this.JYZGLInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.dialogFormVisible = false;

            this.$notify({
              type: "success",
              message: r.Message,
              duration: 3000
            });

            this.refloadlist();
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    },
    // 管理成员
    opengroupnumber: function(index, row) {
      this.dialogFormVisiblegroup = true;

      this.groupId = row.Id;

      this.selectTeacherList = [];
      this.schoolTeacherList.forEach(item => {
        this.selectTeacherList.push(item);
      });

      // this.selectTeacherList = this.schoolTeacherList;
      this.groupTeacherList = [];

      this.$api.post(
        "v1/teachinggroup/GetTeacheingGroupByGroupId",
        { JYZId: row.Id, token: this.token },
        r => {
          if (r.Code === 0) {
            if (r.Target.length > 0) {
              this.groupTeacherList = r.Target;

              // 移除选中
              if (this.groupTeacherList.length > 0) {
                this.selectTeacherList.forEach(item => {
                  this.groupTeacherList.forEach(item1 => {
                    if (item.Id === item1.Id) {
                      this.selectTeacherList.splice(
                        this.selectTeacherList.indexOf(item),
                        1
                      );
                    }
                  });
                });
              }
            }
          }
        }
      );
    },
    // 查询教师
    searchgroupteacher: function() {
      if (
        this.schoolTeacherList !== null &&
        this.schoolTeacherList.length > 0
      ) {
        this.selectTeacherList = [];
        if (this.searchTeacher !== "") {
          this.schoolTeacherList.forEach(item => {
            if (item.Name.indexOf(this.searchTeacher) !== -1) {
              this.selectTeacherList.push(item);
            }
          });
        } else {
          this.selectTeacherList = this.schoolTeacherList;
        }
      }
    },
    // 选择教师
    groupselectteacher: function(row) {
      let result = true;

      if (this.groupTeacherList.length > 0) {
        // 取消选中
        this.groupTeacherList.forEach(item => {
          if (item.Id === row.Id) {
            result = false;
          }
        });
      }

      if (result) {
        this.groupTeacherList.push(row);

        // 选择移除
        this.selectTeacherList.splice(this.selectTeacherList.indexOf(row), 1);
      }
    },
    // 移除选中
    handleClose: function(row) {
      this.groupTeacherList.splice(this.groupTeacherList.indexOf(row), 1);

      // 选择添加
      this.selectTeacherList.push(row);
    },
    // 保存教研成员
    savegGroupTeacher: function() {
      if (this.groupTeacherList.length <= 0) {
        this.$message({
          type: "info",
          message: "请选择成员"
        });
        return;
      }

      var teacherselect = [];

      this.groupTeacherList.forEach(item => {
        teacherselect.push(item.Id);
      });

      var JYZCYGLInfo = {
        Id: 0,
        JYZId: this.groupId,
        JSId: "",
        ZZLB: 0
      };

      this.$api.post(
        "v1/teachinggroup/TeachingGroupMemberAdd",
        {
          JYZCYGLInfo: JYZCYGLInfo,
          TeacherList: teacherselect,
          SchoolId: this.JYZGLInfo.SSXX,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.dialogFormVisiblegroup = false;

            this.$notify({
              type: "success",
              message: r.Message,
              duration: 3000
            });
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
    }
  }
};
</script>

<style>
.customWidth {
  width: 40%;
}

.el-tag {
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.cell {
  text-align: left;
}
</style>
