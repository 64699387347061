<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    width="40%"
    center
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="tab-shu-box">
      <el-form ref="ruleForm">
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td class="tab-shu-titl">所属学校</td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      type: 'array',
                      trigger: 'change'
                    }
                  ]"
                  prop="checkedPJFSData"
                >
                  <el-select
                      v-model="formData.JTSSXXInfo"
                      multiple
                      collapse-tags
                      class="jf-form">
                      <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>

                </el-form-item>
              </td>
              </tr>
          </tbody>
        </table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="saveItem()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    visibleXX: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    shapes: {
      type: Array,
      default: function() {
        return [];
      }
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      shapeLimit: [],
      validateResult: false,
      labelWidth: "250px",
      formData:{
        JTSSXXInfo:[]
      },
      options:[],
      pagerInfo: {
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0
      }
    };
  },
  methods: {
    init() {
      this.getSchoolAll();
      this.formData = [];    
    },
    handleClose() {
      this.$emit("update:show", false);
    },
    getSchoolAll(){
      this.$api.get(
            "/v1/areaschool/GetAreaSchoolAll",
            {
              token: this.$store.state.token
            },
            r => {
              if (r.Code === 0) {
                let data = r.Target;
                if(data !== null){
                  this.options = [];
                  for(let i = 0;i < data.length;i++){
                     this.options.push(data[i]);
                  }
                }
              }
            }
          );
    },
    closeDialog() {
        this.visibleXX = false;
    },
    saveItem: function() {
      if(this.formData.JTSSXXInfo === undefined || this.formData.JTSSXXInfo <= 0){
          this.$message({
          type: "info",
          message: "请选择学校",
          showClose: true
        });
        return;
      }
      var obj = Array();
      for(let i = 0; i < this.options.length; i++) {
        var arr = this.formData.JTSSXXInfo.map(function(o) {return o });
        if ((arr.indexOf(this.options[i].id) > -1)) {
                 obj.push({"SSXX":this.options[i].id,"XXMC":this.options[i].text,"JTBId":this.$route.query.Id});
            }
      }
      // 提交
      this.$api.post("v1/Growth/AddJTSSXX",
        { JTSSXXList: obj, token: this.token },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: "操作成功"
            });
            this.handleClose();
            this.$parent.getData();
          } else {
            this.$message({
              type: "error",
              message: r.Message,
              showClose: true
            });
          }
        }
      );
  },
    },
  watch: {
    show(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        // 隐藏
        this.$refs.ruleForm.resetFields();
      }
    }
  }
};
</script>
