<template>
  <!-- 教师获奖 -->
  <div class="cont-whole-box">
    <div class="teacher-award">
      <!-- 顶部功能按钮 -->
      <div class="top-btns">
        <div class="left-btns">
          <el-input
            v-model="searchname"
            placeholder="请输入关键字"
            size="small"
            maxlength="20"
            prefix-icon="el-icon-search"
          ></el-input>
          <el-button size="small" type="primary" @click="handleSearch()"
            >搜索</el-button
          >
        </div>
        <div class="right-btns">
          <el-button
            type="primary"
            size="small"
            @click="dialogImportVisible = true"
            >导入</el-button
          >
          <el-button type="primary" size="small" @click="downloadawardlist()"
            >导出</el-button
          >
          <el-button type="primary" size="small" @click="handleaddaward()"
            >新增</el-button
          >
        </div>
      </div>

      <!-- 筛选区域 -->
      <div class="filter-toolbar">
        <!-- 时间筛选 -->
        <div class="filter-item">
          <div class="label">时间</div>
          <div class="content">
            <el-date-picker
              size="small"
              v-model="start_end"
              type="daterange"
              :picker-options="pickerOption"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="start_end_change"
            >
            </el-date-picker>

            <div class="week-term">
              <div class="label-week-term">学期：</div>
              <el-select
                v-model="rqlx"
                placeholder="请选择"
                size="small"
                @change="rqlxchange"
              >
                <el-option
                  v-for="item in termOptions"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- 获奖类型筛选 -->
        <div class="filter-item">
          <div class="label">状态</div>
          <div class="content content-wrap">
            <div
              class="wrap-item"
              :class="{ 'cur-wrap-item': SFYX == 1 }"
              @click="awardtypechange(1)"
            >
              有效
            </div>
            <div
              class="wrap-item"
              :class="{ 'cur-wrap-item': SFYX == 2 }"
              @click="awardtypechange(2)"
            >
              无效
            </div>
          </div>
        </div>
      </div>
      <!-- 获奖列表 -->
      <div class="list">
        <div class="list-item" v-for="(hj, index) in hjlist" :key="index">
          <div class="list-item-title">
            <div class="name">{{ hj.HJMC }}</div>
            <div>
              <el-button
                :type="hj.SFYX == '1' ? 'danger' : 'primary'"
                size="small"
                @click="setawardvalid(hj)"
                >{{ hj.SFYX == '1' ? '设置为无效' : '设置为有效' }}</el-button
              >
              <el-button type="primary" size="small" @click="handleaddaward(hj)"
                >编辑</el-button
              >
            </div>
          </div>
          <!-- 详情 -->
          <div class="detail">
            <!-- 详情顶部 -->
            <div class="detail-top">
              <div class="top-item">
                <span class="label">教师姓名：</span>
                {{ hj.CYMC }}
              </div>
              <div class="top-item">
                <span class="label">获奖类别：</span>
                {{ hj.LBMC }}
              </div>
              <div class="top-item">
                <span class="label">获奖级别：</span>
                {{ hj.DJMC }}
              </div>
              <div v-if="hj.SHZT == 2" class="top-item">
                <span class="label">得分：</span>
                {{ hj.DF }}分
              </div>
            </div>
            <!-- 主办单位 获奖日期 -->
            <div class="detail-center">
              <div class="center-item">
                辅助学生：<span style="font-weight: normal">{{ hj.FZXS }}</span>
              </div>
              <div class="center-item">
                主办单位：<span style="font-weight: normal">{{ hj.ZBDW }}</span>
              </div>
              <div class="center-item">
                获奖日期：<span style="font-weight: normal">{{
                  hj.HJSJ | DateFormat('yyyy-MM-dd')
                }}</span>
              </div>
            </div>
            <!-- 详情描述 -->
            <div class="detail-description">
              <div class="label">获奖描述：</div>
              <div class="value">
                <div>{{ hj.BZ }}</div>
              </div>
            </div>
            <!-- 图片 -->
            <div
              class="images"
              v-if="hj.FjList != null && hj.FjList.length > 0"
            >
              <el-image
                class="img-item"
                v-for="fj in hj.FjList"
                :key="fj.Id"
                :src="fj.FJLJ"
                fit="scale-down"
                :preview-src-list="hj.FjList.map(fj => fj.FJLJ)"
              />
            </div>
            <div class="displ-il-boxmain">
              <div class="displ-il-box">
                <span class="c-g-9">添加人：</span>
                <!--  -->
                {{ getCJRName(hj.CJR) }} {{ hj.CJSJ }}
              </div>
              <div
                v-if="hj.SHZT == 2 && hj.SFYX == 1 && hj.SJLY == 1"
                class="displ-il-box"
              >
                <span class="c-g-9">审核人：</span>
                <!--   -->
                {{ getCJRName(hj.SHR) }} {{ hj.SHRQ }}
              </div>
              <div
                v-if="hj.SHZT == 2 && hj.SFYX == 1 && hj.SJLY == 2"
                class="displ-il-box"
              >
                <span class="c-g-9">审核人：</span>
                自动审核（批量导入）
              </div>
              <div v-if="hj.SHZT == 2 && hj.SFYX == 2" class="displ-il-box">
                <span class="c-g-9">更改人：</span>
                <!--  -->
                {{ getCJRName(hj.SZR) }}
                {{ hj.SZSJ | DateFormat('yyyy-MM-dd') }}
              </div>
              <div v-if="hj.SHZT == 2 && hj.SFYX == 2" class="displ-il-box">
                <span class="c-g-9">无效原因：</span>
                {{ hj.WXYY }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagin-box">
        <div class="pagin-wz">
          <Pager :pagerInfo="pagerInfo" @changeIndex="handleCurrentChange" />
        </div>
      </div>
    </div>
    <!-- 确定无效弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleOptionClose"
    >
      <el-form :model="formData" ref="wxyyform">
        <el-form-item
          label="请在下方输入奖项无效的原因："
          :rules="[
            { required: true, message: '无效原因不能为空' },
            {
              min: 1,
              max: 50,
              message: '长度在1到50个字符',
              trigger: 'change'
            }
          ]"
          prop="wxyy"
        >
          <el-input
            type="textarea"
            v-model="formData.wxyy"
            placeholder="不超过50个字"
            maxlength="50"
            minlength="1"
            :rows="4"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleOptionClose()">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="导入获奖记录"
      :visible.sync="dialogImportVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleImportClose"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="upload"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <span
            class="essay-bt-textbunn"
            style="margin-left:0!important;"
            @click="downLoadTemplate"
            >教师获奖导入模板下载</span
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportClose()">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Pager from '@/components/Pager.vue'
import { formatDate2 } from '@/utils/date'
import XLSX from 'xlsx'
export default {
  components: {
    Pager
  },
  async created() {
    //YhyTODO:获取所属学校
    this.ssxq = localStorage.getItem('currentterm')
    this.term = localStorage.getItem('curTerm')
    var tempuser = localStorage.getItem('userinfo')
    if (tempuser) {
      this.user = JSON.parse(tempuser)
      let menu = this.user.menu.find(item => item.CodeOREId === 'PrizeManage')
      if (menu) this.menuType = menu.QXDJLX //1.全部 2.局部（局部时才筛选学段年级
      this.ssxx = this.user.SSZZId
      this.token = this.user.Token
      this.yhid = this.user.Id
      this.yhlb = this.user.YHLX
    }
    //判断是否有获奖缓存
    // if (!localStorage.getItem("XXHJLBList")) {
    await this.getHJJB()
    // }
    //获取学生
    if (!localStorage.getItem('OrgStudent')) {
      await this.getOrgStudent()
    }
    //获取教师
    if (!localStorage.getItem('OrgTeacher')) {
      await this.getSchoolTeacher()
    }
    this.schoolTeacher = JSON.parse(localStorage.getItem('OrgTeacher'))
    this.schoolStudent = JSON.parse(localStorage.getItem('OrgStudent'))
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return '/file/file/fileservice/UploadFile?token=' + this.token
    }
  },
  async mounted() {
    await this.getTerms()
    await this.loadlbdata()
    await this.loaddjdata()
    this.loadnjdata()
    this.loaddata()
  },
  methods: {
    //匹配添加人名称
    getCJRName(value) {
      let name = ''
      //先匹配教师
      let a = this.schoolTeacher.find(i => i.Id == value)
      if (a) {
        name = a.Name
      } else {
        let b = this.schoolStudent.find(i => i.Id == value)
        if (b) {
          name = b.Name
        } else {
          name = ''
        }
      }
      return name
    },
    upload(file) {
      let files = { 0: file.raw }
      this.readExcel(files)
    },
    readExcel: function(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false })
        this.submitimportdata(results)
        this.$refs.upload.value = ''
      }
      fileReader.readAsArrayBuffer(files[0])
    },
    submitimportdata: function(readdata) {
      if (readdata.length <= 0) {
        this.$message({
          type: 'info',
          message: '上传文件中无正确数据!'
        })
        return
      }
      this.importdata = []

      // 模板导入校验
      try {
        for (let i = 0; i < readdata.length; i++) {
          let teacher = this.schoolTeacher.find(
            item => item.Name === readdata[i][this.header[0]].trim()
          )
          if (!teacher)
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + '行未识别获奖教师，导入失败'
            )
          // 获奖名称
          if (!readdata[i][this.header[2]])
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + '获奖名称未识别，导入失败'
            )
          // 校验获奖类别
          let LB = this.ImpData.find(item =>
            item.LBMC.includes(readdata[i][this.header[2]].trim())
          )
          let DJ = {}
          if (!LB) {
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行类别未识别，导入失败'
            )
          }
          // 获奖级别
          let JB = LB.child.find(
            item => item.MC === readdata[i][this.header[3]].trim()
          )
          if (!JB) {
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行级别未识别，导入失败'
            )
          }

          //如果子级奖项，判断子奖项
          if (JB.children && JB.children.length > 0) {
            DJ = JB.children.find(
              item => item.MC === readdata[i][this.header[4]].trim()
            )
            if (!DJ) {
              throw new Error(
                '第 ' + readdata[i]['__rowNum__'] + ' 行等级未识别，导入失败'
              )
            }
          }

          // 校验时间
          try {
            new Date(readdata[i][this.header[5]])
          } catch (err) {
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行时间格式未识别，导入失败'
            )
          }

          if (isNaN(new Date(readdata[i][this.header[5]]).getTime()))
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行时间格式未识别，导入失败'
            )
          // 获奖比例
          let BL = LB.children.find(
            item => item.MC == readdata[i][this.header[6]].trim()
          )
          if (!BL) {
            throw new Error(
              '第 ' + readdata[i]['__rowNum__'] + ' 行计分比例未识别，导入失败'
            )
          }

          let obj = {
            CYMC: readdata[i][this.header[0]], //参与人
            BJMC: '',
            HJMC: readdata[i][this.header[1]],
            LBMC: LB ? LB.LBMC : '',
            HJLB: LB ? LB.Id : '', //类别ID
            JBMC: JB ? JB.MC : '', //级别名称
            HJJB: JB ? JB.Id : '', //级别ID
            DJMC: DJ ? DJ.MC : '', //等级名称
            HJDJ: DJ ? DJ.Id : '', //等级ID
            BlMC: BL ? BL.MC : '', //比例名称
            HJBL: BL ? BL.Id : '', //比例ID
            BZ: readdata[i][this.header[9]],
            HJSJ: readdata[i][this.header[5]],
            ZBDW: readdata[i][this.header[7]], //主办单位
            FZXS: readdata[i][this.header[8]] //辅助学生
          }
          this.importdata.push(obj)
        }

        this.$api.post(
          'v1/awards/ImportData',
          {
            CYLB: 2,
            DataList: this.importdata,
            SSXX: this.ssxx,
            SSXQ: this.ssxq,
            CJR: this.yhid,
            CJRLB: 2, //1-学生，2-教师，3-家长
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              this.$message({
                type: 'info',
                message: r.Message
              })
              this.loaddata()
              this.handleImportClose()
            }
          }
        )
      } catch (err) {
        this.$message.error(err.message)
      }
    },

    //导出
    downloadawardlist: function() {
      let term = this.termOptions.find(item => item.Id === this.ssxq)
      let kssj = this.kssj || term.BeginTime
      let jssj = this.jssj || term.EndTime
      this.$api.post(
        'v1/awards/DownLoadAwardListForWeb',
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          SearchName: this.searchname,
          CYLB: 2,
          SFYX: this.SFYX,
          BeginDate: kssj,
          EndDate: jssj,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            window.open(r.Message, '_blank')
          } else {
            this.$message({
              type: 'info',
              message: r.Message
            })
          }
        }
      )
    },
    // 新增/编辑弹窗
    handleaddaward(data) {
      this.$router.push({
        name: 'addteacheraward',
        params: { form: data || {} }
      })
    },
    //处理导入数据（新）
    handleImportData(list) {
      let arr = list.XXHJFLList
      let newArr = arr.filter(i => {
        if (i.HJFLDJList?.length > 0) {
          i.HJFLDJList.forEach(it => {
            it.value = it.Id
            it.label = it.MC
            it.level = it.CJ
            let res = i.HJFLDJList.filter(t => t.FID == it.Id)

            it.children = res.length > 0 ? res : null
          })
          i.child = i.HJFLDJList.filter(o => o.CJ == 1)
          i.children = i.HJJFBLList
        }
        return i.SSLB == 2
      })
      newArr.sort((a, b) => {
        a.PXH - b.PXH
      })
      console.log('newArr', newArr)

      this.ImpData = newArr.map(item => {
        return {
          Id: item.Id,
          LBMC: item.LBMC,
          PXH: item.PXH,
          SSLB: item.SSLB,
          child: item.child,
          children: item.children
        }
      })
    },
    //获取获奖类别
    getHJJB(HQLB) {
      this.$api.post(
        '/v1/awards/GetXXHJJCSZ',
        { SSXX: this.ssxx, HQLB: HQLB },
        r => {
          if (r.Code == 0) {
            this.hjlblist = r.Target
            this.handleImportData(r.Target || [])
            if (this.hjlblist.length > 1) {
              localStorage.setItem('XXHJLBList', JSON.stringify(this.hjlblist))
            }
          }
        }
      )
    },
    //获取学校教师
    getSchoolTeacher() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolTeacher',
          {
            schoolId: this.ssxx,
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              let list = r.Target || []
              localStorage.setItem('OrgTeacher', JSON.stringify(list))
              resolve()
            }
          }
        )
      })
    },
    // 获取学生
    getOrgStudent() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetOrgStudent',
          {
            SchoolOrgId: this.ssxx, //	是	string	组织机构ID/活动Id
            OrgType: 0, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: this.term.SSXN, //	是	string	学年
            SchoolId: this.ssxx, //	否	string	学校Id
            IsDelete: 2, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              let list = r.Target || []
              localStorage.setItem('OrgStudent', JSON.stringify(list))
              resolve()
            }
          }
        )
      })
    },
    //设置为无效
    setawardinvalid(id) {
      this.editid = id
      this.formData.wxyy = ''
      this.dialogVisible = true
    },
    //确定无效
    handleConfirm() {
      this.$refs.wxyyform.validate(valid => {
        if (valid) {
          this.$api.post(
            'v1/awards/SetValidAward',
            {
              SSXX: this.ssxx,
              SSXQ: this.ssxq,
              Id: this.editid,
              SFYX: 2,
              YHId: this.yhid,
              WXYY: this.formData.wxyy,
              token: this.token
            },
            () => {
              this.formData.wxyy = ''
              this.dialogVisible = false
              this.$message.success('设置成功')
              this.loaddata()
            }
          )
        } else {
          return false
        }
      })
    },
    //关闭单选项
    handleOptionClose() {
      this.formData.wxyy = ''
      this.dialogVisible = false
    },
    handleImportClose: function() {
      this.dialogImportVisible = false
    },
    start_end_change(val) {
      if (val) {
        this.kssj = val[0]
        this.jssj = val[1]
      } else {
        let term = this.termOptions.find(item => item.IsCurrent === 1)
        if (term) {
          this.kssj = term.BeginTime
          this.jssj = term.EndTime
        } else {
          this.kssj = ''
          this.jssj = ''
        }
      }
      this.loaddata()
    },
    rqlxchange(val) {
      this.pagerInfo.pageIndex = 1
      let term = this.termOptions.find(item => item.Id === val)
      if (term) {
        this.kssj = term.BeginTime
        this.jssj = term.EndTime
        this.start_end = []
      }

      this.loaddata()
    },
    handleSearch: function() {
      this.loaddata()
    },
    loaddata: function() {
      this.$api.post(
        'v1/awards/GetAwardListForWeb',
        {
          SSXX: this.ssxx,
          SSXQ: this.ssxq,
          SearchName: this.searchname,
          CYLB: 2,
          SFYX: this.SFYX,
          AwardType: 1,
          BeginDate: this.kssj,
          EndDate: this.jssj,
          PagerInfo: this.pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code == 0) {
            let arr = r.Target || []
            arr.forEach(item => {
              if (item.FjList && item.FjList.length > 0) {
                item.FjList.forEach(it => {
                  if (it.FJLJ && !it.FJLJ.includes('http://')) {
                    it.FJLJ = 'http://' + it.FJLJ
                  }
                })
              }
            })
            this.hjlist = arr.filter(i => i.SHZT == 2)
            this.pagerInfo.totalCount = this.hjlist.length

            // this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      )
    },
    // 设置是否有效
    setawardvalid(hj) {
      this.$nextTick(() => {
        this.$refs.wxyyform.clearValidate()
      })
      if (hj.SFYX === 1) {
        // 设置无效
        this.editid = hj.Id
        this.formData.hjbjid = hj.BJId
        this.formData.wxyy = ''
        this.dialogVisible = true
      } else {
        this.$confirm('是否设置该荣誉为有效？')
          .then(() => {
            this.$api.post(
              'v1/awards/SetValidAward',
              {
                SSXX: this.ssxx,
                SSXQ: this.ssxq,
                Id: hj.Id,
                SFYX: 1,
                YHId: this.yhid,
                token: this.token
              },
              () => {
                this.$message.success('设置成功')
                this.loaddata()
              }
            )
          })
          .catch(() => {
            return
          })
      }
    },
    loadnjdata: function() {
      this.$api.post(
        'v1/accountmanage/GetSchoolOrg',
        { SchoolId: this.ssxx, token: this.token },
        r => {
          if (r.Code == 0) {
            this.njlist = []
            var list = r.Target
            this.zzjglist = list
            for (var i = 0; i < list.length; i++) {
              if (list[i].Hierarchy == 1) {
                this.njlist.push(list[i])
              }
            }
          }
        }
      )
    },
    //翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val
      this.loaddata()
    },
    search: function() {
      this.pagerInfo.pageIndex = 1
      this.loaddata()
    },
    awardtypechange: function(at) {
      this.SFYX = at
      this.pagerInfo.pageIndex = 1
      this.loaddata()
    },
    getTerms() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/accountmanage/GetTerm',
          {
            SchoolId: this.user.SSZZId,
            token: this.user.Token
          },
          res => {
            if (res.Code === 0) {
              this.termOptions = res.Target || []
              let term = this.termOptions.find(item => item.IsCurrent === 1)
              if (term) {
                this.rqlx = term.Id
                this.kssj = term.BeginTime
                this.jssj = term.EndTime
                this.start_end = []
              }
              resolve()
            } else reject()
          }
        )
      })
    },
    // 获取类别
    loadlbdata() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/awards/GetHJLBList',
          { SSXX: this.ssxx, HQLB: 2, token: this.token },
          r => {
            if (r.Code == 0) {
              this.lblist = r.Target || []
              this.lblist.sort((a, b) => a.DF - b.DF)
              resolve()
            }
          }
        )
      })
    },
    // 获取等级
    loaddjdata() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/awards/GetHJDJList',
          { SSXX: this.ssxx, HQLB: 2, token: this.token },
          r => {
            if (r.Code == 0) {
              this.djlist = r.Target || []
              this.djlist.sort((a, b) => a.DF - b.DF)
              resolve()
            }
          }
        )
      })
    },
    // 处理下载模板
    export2Excel() {
      import('@/style/js/Export2ExcelSheetArray').then(excel => {
        let str1 =
          '获奖类别：' +
          this.lblist.map(item => `${item.SJBS}.${item.LBMC} `).join()
        let str2 =
          '    获奖级别：' +
          this.djlist.map(item => `${item.SJBS}.${item.HJDJMC} `).join()
        let multiHeader = [str1 + str2]

        let tHeader = [
          '获奖老师',
          '获奖名称',
          '获奖类别',
          '获奖级别',
          '获奖时间',
          '描述'
        ]

        let data = [
          {
            Sheet: '老师获奖记录',
            Data: [
              [
                '老师姓名',
                '获奖名称',
                this.lblist[0].SJBS,
                this.djlist[0].SJBS,
                '2020-06-05（注意是文本格式）',
                '获奖备注'
              ]
            ]
          }
        ]
        excel.export_json_to_excel({
          multiHeader: [multiHeader],
          header: tHeader, //表头 必填
          data, //具体数据 必填
          autoWidth: false,
          merges: ['A1:G1'], //合并单元格
          filename: '教师获奖模板' + '' //非必填
        })
      })
    },
    // 下载模板
    downLoadTemplate() {
      let a = document.createElement('a')
      a.href = `${this.publicPath}template/teacherAwardTemplate.xlsx`
      a.download = '教师获奖导入模板'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  },
  data() {
    let _that = this
    return {
      term: {},
      publicPath: process.env.BASE_URL,
      editid: 0,
      isLogin: true,
      hjlist: [],
      njlist: [],
      bjlist: [],
      zzjglist: [],
      importdata: [],
      ImpData: [], //处理过后导入的数据格式
      formData: { wxyy: '' },
      dialogVisible: false,
      dialogImportVisible: false,
      ssxx: '',
      ssxq: '',
      searchname: '',
      start_end: [],
      kssj: '',
      jssj: '',
      token: '',
      yhid: '',
      bjid: '',
      yhlb: 0,
      njid: '',
      orgid: '',
      schoolTeacher: '', //学校教师
      schoolStudent: '', //学校学生
      pagerInfo: {
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      },
      awardtype: '2',
      SFYX: 1,
      termOptions: [],
      rqlx: '',
      pickerOption: {
        disabledDate(val) {
          let term = _that.termOptions.find(item => item.Id === _that.rqlx)
          if (term) {
            if (
              val.getTime() < new Date(term.BeginTime).getTime() ||
              val.getTime() > new Date(term.EndTime).getTime()
            )
              return true
            else return false
          } else return false
        }
      },
      menuType: 1,
      djlist: [],
      lblist: [],
      header: [
        '获奖老师',
        '获奖名称',
        '获奖类别',
        '获奖级别',
        '获奖等级',
        '获奖时间',
        '计分比例',
        '主办单位',
        '辅助学生',
        '描述'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.teacher-award {
  padding: 30px;
  .top-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-btns {
      display: flex;
      align-items: center;
    }
  }
  .filter-toolbar {
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px #0000001a;
    .filter-item {
      display: flex;
      align-items: center;
      padding: 10px;
      .label {
        width: 100px;
        height: 100%;
        margin-right: 15px;
        border-right: 1px solid #ccc;
        text-align: center;
        font-size: 16px;
      }
      .content {
        flex: 1;
        display: flex;
        align-items: center;
        .week-term {
          display: flex;
          align-items: center;
          margin-left: 10px;
          font-size: 14px;
        }
      }
      .content-wrap {
        flex-wrap: wrap;
        padding-top: 10px;
        padding-right: 10px;
        .wrap-item {
          width: 65px;
          text-align: center;
          font-size: 14px;
          border-radius: 5px;
          padding: 4px 6px;
          background-color: #ffffff;
          color: #14d089;
          border: 1px solid #14d089;
          margin-bottom: 12px;
          margin-left: 10px;
          cursor: pointer;
          &.cur-wrap-item {
            color: #fff;
            background-color: #14d089;
          }
          &.disabled {
            border: none;
            color: #9b9898;
            background-color: #d9d9d9;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .list {
    margin-top: 15px;
    background-color: #fff;
    .list-item {
      margin-bottom: 15px;
      border-radius: 10px;
      box-shadow: 0px 0px 4px #0000001a;
      .list-item-title {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid #ccc;
        .name {
          color: red;
          font-size: 18px;
          font-weight: 600;
        }
      }
      .detail {
        padding: 10px;
        .detail-top {
          display: flex;
          align-items: center;
          .top-item {
            margin-right: 15px;
            font-size: 14px;
            .label {
              font-weight: 600;
            }
          }
        }
        .detail-center {
          display: flex;
          align-items: center;
          margin-top: 10px;
        }
        .detail-center > .center-item {
          font-weight: 600;
          margin-right: 20px;
          color: #666;
          font-size: 14px;
        }
        .detail-description {
          margin-top: 10px;
          font-size: 14px;
          display: flex;
          .label {
            flex-shrink: 0;
            font-weight: 600;
          }
          .value {
            flex: 1;
          }
        }
        .images {
          margin-top: 15px;
          padding-top: 10px;
          display: flex;
          flex-wrap: wrap;
          border: 1px solid #ccc;
          .img-item {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
