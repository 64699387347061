<template>
  <div>
    <!-- 体质数据 -->
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <span class="table-er-buttspan" style="font-size:14px;"
                >学期：
              </span>
              <el-select
                @change="loaddata"
                v-model="xqid"
                placeholder="请选择学期"
              >
                <el-option
                  v-for="xq in xqlist"
                  :key="xq.Id"
                  :label="xq.XQMC"
                  :value="xq.Id"
                ></el-option>
              </el-select>
              <span
                class="table-er-buttspan"
                style="font-size:14px;margin-left:10px;"
                >年级：</span
              >
              <el-select
                @change="handleGradeChange"
                v-model="njid"
                placeholder="请选择年级"
              >
                <el-option
                  v-for="nj in njlist"
                  :label="nj.Name"
                  :key="nj.Id"
                  :value="nj.Id"
                >
                </el-option>
              </el-select>
              <span
                class="table-er-buttspan"
                style="font-size:14px;margin-left:10px;"
                >班级：</span
              >
              <el-select
                @change="handleClassChange"
                style="margin-left: 10px;"
                v-model="bjid"
                placeholder="请选择班级"
              >
                <el-option
                  v-for="bj in bjlist"
                  :label="bj.Name"
                  :key="bj.Id"
                  :value="bj.Id"
                >
                </el-option>
              </el-select>
            </div>
            <div
              style="font-size: 14px;display: inline-block;margin-top:10px; "
            >
              <el-input
                style="width: 200px;"
                type="text"
                placeholder="输入学籍号或姓名"
                v-model="cxmc"
                maxlength="50"
              ></el-input>
              <span
                style="color: #14d089; margin-left: 5px; cursor: pointer;"
                @click="loaddata()"
                >搜索</span
              >
              <span
                style="color: #14d089;margin-left: 5px;cursor: pointer;"
                @click="downloadlist"
                >下载全部</span
              >
              <span
                @click="handleImportOpen"
                style="color: #14d089;margin-left: 5px;cursor: pointer;"
                >上传数据</span
              >
            </div>
            <el-table :data="sjlist" highlight-current-row style="width: 100%;">
              <el-table-column
                fixed
                align="center"
                label="班级"
                property="BJMC"
              >
              </el-table-column>
              <el-table-column fixed align="center" label="学号" property="XJH">
              </el-table-column>
              <el-table-column
                fixed
                align="center"
                label="姓名"
                property="XSXM"
              >
              </el-table-column>
              <el-table-column align="center" label="民族" property="MZ">
              </el-table-column>
              <el-table-column align="center" label="性别">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.XBM == '1'">
                      男
                    </span>
                    <span v-else>
                      女
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                width="160"
                align="center"
                property="SG"
                label="身高"
              >
                <template slot-scope="scope">
                  <el-input-number
                    size="mini"
                    :precision="2"
                    :max="300"
                    :step="10"
                    :min="0"
                    v-model="scope.row.SG"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
                width="160"
                align="center"
                property="TZ"
                label="体重"
              >
                <template slot-scope="scope">
                  <el-input-number
                    size="mini"
                    :precision="2"
                    :max="300"
                    :step="1"
                    :min="0"
                    v-model="scope.row.TZ"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
                width="160"
                align="center"
                property="FHL"
                label="肺活量"
              >
                <template slot-scope="scope">
                  <el-input-number
                    size="mini"
                    :max="10000"
                    :step="100"
                    :min="0"
                    v-model="scope.row.FHL"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
                width="160"
                align="center"
                property="DP"
                label="50米跑"
              >
                <template slot-scope="scope">
                  <el-input-number
                    size="mini"
                    :precision="2"
                    :max="100"
                    :step="0.1"
                    :min="0"
                    v-model="scope.row.DP"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
                width="160"
                align="center"
                property="ZWTQQ"
                label="坐位体前屈"
              >
                <template slot-scope="scope">
                  <el-input-number
                    size="mini"
                    :precision="2"
                    :max="150"
                    :step="0.1"
                    :min="-300"
                    v-model="scope.row.ZWTQQ"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
                width="160"
                align="center"
                property="TS"
                label="一分钟跳绳"
              >
                <template slot-scope="scope">
                  <el-input-number
                    size="mini"
                    :max="500"
                    :step="10"
                    :min="0"
                    v-model="scope.row.TS"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
                width="160"
                align="center"
                property="YWQZ"
                label="一分钟仰卧起坐"
              >
                <template slot-scope="scope">
                  <el-input-number
                    size="mini"
                    :max="500"
                    :step="10"
                    :min="0"
                    v-model="scope.row.YWQZ"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
                width="160"
                align="center"
                property="WFP"
                label="50米*8往返跑"
              >
                <template slot-scope="scope">
                  <el-input-number
                    size="mini"
                    :precision="2"
                    :max="100"
                    :step="0.1"
                    :min="0"
                    v-model="scope.row.WFP"
                  ></el-input-number>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="cent-bott-butt">
            <el-button @click="submitdata()" type="primary"
              >保&nbsp;存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="导入记录"
      :visible.sync="dialogImportVisible"
      width="30%"
      :before-close="handleImportClose"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="upload"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <a
            :href="`${publicPath}template/体质数据导入模板.xls`"
            class="essay-bt-textbunn"
            target="_blank"
            style="margin-left:0!important;"
            >体质数据导入模板下载</a
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportClose()">取 消</el-button>
        </el-form-item>
        <div class="py-text" v-if="errormessage != ''">{{ errormessage }}</div>
      </el-form>
    </el-dialog>

    <!-- 导入错误 -->
    <el-dialog
      title="导入异常"
      :visible.sync="showErrResult"
      :close-on-click-modal="false"
      width="40%"
    >
      <div class="err-result-container">
        <div style="margin-bottom:10px;font-weight:bold;color:#1e1e1e">
          当前所选年级：{{ grade.Name }}
        </div>
        <div
          class="err-result-item"
          v-for="(student, index) in errResult"
          :key="index"
        >
          <div class="student-name">{{ student.XSXM }}:</div>
          <div class="err-msg">{{ student.errMsg }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  components: {},
  created() {
    this.xqid = localStorage.getItem("currentterm");
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.cjr = user.Id;
      this.ssxx = user.SSZZId;
      this.token = user.Token;
    }
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return "/file/file/fileservice/UploadFile?token=" + this.token;
    },
  },
  async mounted() {
    await this.loadxqdata();
    this.loadnjdata();
  },
  methods: {
    submitdata: function() {
      if (this.sjlist.length <= 0) {
        this.$message({
          type: "success",
          message: "无数据!",
        });
        return;
      }
      this.$api.post(
        "v1/physicalquality/SaveDataPhysicalQuality",
        {
          DataList: this.sjlist,
          SSXX: this.ssxx,
          SSXQ: this.xqid,
          CJR: this.cjr,
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "success",
              message: "保存成功!",
            });
            this.loaddata();
          } else {
            this.$message({
              type: "info",
              message: "保存不成功!",
            });
          }
        }
      );
    },
    handleGradeChange: function() {
      this.grade = this.njlist.find((item) => item.Id === this.njid);
      this.handleGetGradeStudent();
      this.bjid = "";
      this.loadbjdata();
    },
    handleClassChange: function() {
      this.loaddata();
    },
    upload(file) {
      let files = { 0: file.raw };
      this.readExcel(files);
    },
    generateData({ results }) {
      this.excelData.results = results;
    },
    readExcel: function(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        this.generateData({ results });
        this.submitimportdata(results);
        this.$refs.upload.value = "";
      };
      fileReader.readAsArrayBuffer(files[0]);
    },
    submitimportdata: function(readdata) {
      if (readdata.length <= 0) {
        this.$message({
          type: "info",
          message: "上传文件中无正确数据!",
        });
        return;
      }
      this.importdata = [];
      for (var i = 0; i < readdata.length; i++) {
        var obj = {
          BJMC: readdata[i].班级名称,
          XSXM: readdata[i].姓名,
          SG: 0,
          TZ: 0,
          FHL: 0,
          DP: 0,
          ZWTQQ: 0,
          TS: 0,
          YWQZ: 0,
          WFP: 0,
        };
        if (
          readdata[i]["身高"] != undefined &&
          readdata[i]["身高"] != "" &&
          parseFloat(readdata[i]["身高"]) != isNaN
        ) {
          obj.SG = parseFloat(readdata[i]["身高"]);
        }

        if (
          readdata[i]["体重"] != undefined &&
          readdata[i]["体重"] != "" &&
          parseFloat(readdata[i]["体重"]) != isNaN
        ) {
          obj.TZ = parseFloat(readdata[i]["体重"]);
        }

        if (
          readdata[i]["肺活量"] != undefined &&
          readdata[i]["肺活量"] != "" &&
          parseFloat(readdata[i]["肺活量"]) != isNaN
        ) {
          obj.FHL = parseFloat(readdata[i]["肺活量"]);
        }

        if (
          readdata[i]["50米跑"] != undefined &&
          readdata[i]["50米跑"] != "" &&
          parseFloat(readdata[i]["50米跑"]) != isNaN
        ) {
          obj.DP = parseFloat(readdata[i]["50米跑"]);
        }

        if (
          readdata[i]["坐位体前屈"] != undefined &&
          readdata[i]["坐位体前屈"] != "" &&
          parseFloat(readdata[i]["坐位体前屈"]) != isNaN
        ) {
          obj.ZWTQQ = parseFloat(readdata[i]["坐位体前屈"]);
        }

        if (
          readdata[i]["一分钟跳绳"] != undefined &&
          readdata[i]["一分钟跳绳"] != "" &&
          parseFloat(readdata[i]["一分钟跳绳"]) != isNaN
        ) {
          obj.TS = parseFloat(readdata[i]["一分钟跳绳"]);
        }

        if (
          readdata[i]["一分钟仰卧起坐"] != undefined &&
          readdata[i]["一分钟仰卧起坐"] != "" &&
          parseFloat(readdata[i]["一分钟仰卧起坐"]) != isNaN
        ) {
          obj.YWQZ = parseFloat(readdata[i]["一分钟仰卧起坐"]);
        }

        if (
          readdata[i][`50米*8往返跑`] != undefined &&
          readdata[i][`50米*8往返跑`] != "" &&
          parseFloat(readdata[i][`50米*8往返跑`]) != isNaN
        ) {
          obj.WFP = parseFloat(readdata[i][`50米*8往返跑`]);
        }
        this.importdata.push(obj);
      }

      let { errResult, rightResult } = this.compareStudent(this.importdata);
      if (rightResult.length > 0) {
        this.$api.post(
          "v1/physicalquality/ImportDataPhysicalQuality",
          {
            DataList: rightResult,
            SSXX: this.ssxx,
            SSXQ: this.xqid,
            CJR: this.cjr,
            token: this.token,
          },
          (r) => {
            this.errormessage = r.Message;
            if (r.Code == 0) {
              this.loaddata();
            } else {
              return;
            }
          }
        );
      }

      if (errResult.length > 0) {
        this.errResult = errResult;
        this.showErrResult = true;
      }
    },
    handleImportOpen: function() {
      this.errormessage = "";
      this.dialogImportVisible = true;
    },
    handleImportClose: function() {
      this.errormessage = "";
      this.dialogImportVisible = false;
    },
    downloadlist: function() {
      this.$api.post(
        "v1/physicalquality/DownLoadPhysicalQualityList",
        {
          SSXX: this.ssxx,
          SSXQ: this.xqid,
          BJId: this.bjid,
          SearchName: this.cxmc,
          token: this.token,
        },
        (r) => {
          if (r.Code == 0) {
            window.open(r.Message, "_blank");
          } else {
            this.$message({
              type: "info",
              message: r.Message,
            });
          }
        }
      );
    },
    // 批量保存
    handleBatchSave() {
      if (this.sjlist.length <= 0) {
        this.$message({
          type: "info",
          message: "无可保存数据!",
        });
        return;
      }
      this.$api.post(
        "v1/physicalquality/SavePhysicalQualityRule",
        {
          DataList: this.sjlist,
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "info",
              message: "保存成功!",
            });
            this.loaddata();
          } else {
            this.$message({
              type: "info",
              message: "保存失败!",
            });
          }
        }
      );
    },
    loaddata: function() {
      if (this.ssxx == "" || this.xqid == "" || this.bjid == "") {
        return;
      }
      this.$api.post(
        "v1/physicalquality/GetPhysicalQualityList",
        {
          SSXX: this.ssxx,
          SSXQ: this.xqid,
          BJId: this.bjid,
          SearchName: this.cxmc,
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            this.editstatus = false;
            this.sjlist = [];
            this.sjlist = r.Target;
          }
        }
      );
    },
    loadxqdata() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/xqb/GetXQBList",
          { AreaOrSchoolId: this.ssxx, token: this.token },
          (r) => {
            if (r.Code === 0) {
              this.xqlist = r.Target || [];
              if (this.xqlist[0]) {
                this.xqid = this.xqlist[0].Id;
              } else this.$message.error("未获取到学期");
              resolve();
            }
          }
        );
      });
    },
    loadnjdata: function() {
      this.$api.post(
        "v1/accountmanage/GetSchoolOrg",
        { SchoolId: this.ssxx, token: this.token },
        (r) => {
          if (r.Code === 0) {
            this.njlist = [];
            var list = r.Target;
            if (list !== null) {
              this.zzjglist = list;
              for (var i = 0; i < list.length; i++) {
                if (list[i].Hierarchy === 1) {
                  this.njlist.push(list[i]);
                }
              }
              if (this.njid == "" && this.njlist.length > 0) {
                this.grade = this.njlist[0];
                this.njid = this.njlist[0].Id;
                this.handleGetGradeStudent();
                this.loadbjdata();
              }
            }
          }
        }
      );
    },
    loadbjdata: function() {
      if (this.njid.length > 0) {
        this.bjlist = this.zzjglist.filter(
          (c) => c.Id === this.njid
        )[0].ChidOrgList;
      } else {
        this.bjlist = [];
      }
      if (this.bjid == "" && this.bjlist.length > 0) {
        this.bjid = this.bjlist[0].Id;
      }
      this.loaddata();
    },
    // 获取班级学生
    getGradeStudent(bj, nj) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetOrgStudent",
          {
            SchoolOrgId: bj.Id, //	是	string	组织机构ID/活动Id
            OrgType: 2, //	是	int	0 学校 1.年级 2班级3.小组4.自定义小组
            // SourceType: 1, //	是	int	1 通用 2：课辅活动
            TermYearStr: this.xqlist.find((item) => item.Id === this.xqid).SSXN, //	是	string	学年
            SchoolId: this.ssxx, //	否	string	学校Id
            IsDelete: 2, //	否	int	是否获取已删除数据1表示要获取删除和禁用数据
            token: this.token,
          },
          (res) => {
            if (res.Code === 0) {
              if (res.Target) {
                res.Target.forEach((item) => {
                  item.gradeName = nj.Name;
                  item.gradeId = nj.Id;
                  item.className = bj.Name;
                });
                this.gradeStudents = [...res.Target, ...this.gradeStudents];
              }
              resolve();
            }
          }
        );
      });
    },
    // 封装获取年级的所有班级学生
    async handleGetGradeStudent() {
      this.gradeStudents = [];
      if (this.njlist.find((item) => item.Id === this.njid).ChidOrgList) {
        let nj = this.njlist.find((item) => item.Id === this.njid);
        let bjlist = nj.ChidOrgList;

        for (let i = 0; i < bjlist.length; i++) {
          await this.getGradeStudent(bjlist[i], nj);
        }
      }
    },
    // 排查学生班级姓名是否正确
    compareStudent(data) {
      let rightResult = [];
      let errResult = data.reduce((pre, cur) => {
        console.log(cur, "cur");
        let studentList = this.gradeStudents.filter(
          (student) => student.className === cur.BJMC
        );
        if (studentList.length > 0) {
          let student = studentList.find(
            (student) => student.Name === cur.XSXM
          );
          // 通过名字没有找到该学生 并且在errResult中没有重复
          if (!student && !pre.some((el) => el.XSXM === cur.XSXM)) {
            pre.push({
              XSXM: cur.XSXM,
              errMsg: `未在${cur.BJMC}找到该学生`,
            });
          }
          // 通过名字找到了该学生 并且在rightResult中没有重复
          if (student && !rightResult.some((el) => el.XSXM === cur.XSXM)) {
            let obj = {
              BJId: student.ClassId,
              XSId: student.Id,
              ...cur,
            };
            rightResult.push(obj);
          }
        } else {
          if (!pre.some((el) => el.XSXM === cur.XSXM)) {
            pre.push({
              XSXM: cur.XSXM,
              errMsg: "未在当前年级中找到该学生班级",
            });
          }
        }
        return pre;
      }, []);
      return { errResult, rightResult };
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      errormessage: "",
      isLogin: true,
      sjlist: [],
      zzjglist: [],
      bjlist: [],
      njlist: [],
      xqlist: [],
      importdata: [],
      bjid: "",
      njid: "",
      cxmc: "",
      ssxx: "",
      xqid: "",
      cjr: "",
      token: "",
      dialogImportVisible: false,
      excelData: {
        results: null,
      },
      gradeStudents: [],
      errResult: [],
      showErrResult: false,
      grade: {},
    };
  },
};
</script>
<style lang="scss" scoped>
.err-result-container {
  .err-result-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    .student-name {
      width: 70px;
    }
    .err-msg {
      flex: 1;
    }
  }
}
</style>
