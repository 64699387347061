<template>
    <!-- 新增编辑弹窗 -->
    <el-dialog top="5vh" :title="dialogTitle" :visible.sync="value" width="60%" ref="dialog"
     :close-on-click-modal="false" :before-close="close">
        <el-form status-icon :model="form" ref="form" :rules="rules"
        label-width="100px" class="demo-ruleForm" label-position="right">
            <el-form-item label="排序号" :label-width="formLabelWidth" prop="PXH">
                <el-input style="max-width: 218px" suffix-icon="el-icon-edit" 
                    v-model="form.PXH"
                    autocomplete="on" >
                </el-input>
            </el-form-item>
            <el-form-item label="考核项目" :label-width="formLabelWidth" prop="KHXM">
                <el-input style="max-width: 218px" suffix-icon="el-icon-edit" 
                    v-model="form.KHXM"
                    autocomplete="on" >
                </el-input>
            </el-form-item>
            <el-form-item label="分值" :label-width="formLabelWidth" prop="FZ">
                <el-input style="max-width: 218px" suffix-icon="el-icon-edit" 
                    v-model="form.FZ"
                    autocomplete="on" >
                </el-input>
            </el-form-item>
            <el-form-item label="定量指标" :label-width="formLabelWidth" prop="DLZB">
                <el-input style="width:218px;margin-right:10px" v-model="form.DLZB" readonly></el-input>
                <el-button type="primary" size="mini" @click="handleZBSet(1)">{{dialogActicity1.IndexName?"重新设置":"设置"}}</el-button>
            </el-form-item>
            <el-form-item label="定性指标" :label-width="formLabelWidth" prop="DXZB">
                <el-input style="width:218px;margin-right:10px" v-model="form.DXZB" readonly></el-input>
                <el-button type="primary" size="mini" @click="handleZBSet(2)">{{dialogActicity2.IndexName?"重新设置":"设置"}}</el-button>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
        <Dialog :dialogTitle="dialogTitle2" 
        :activityList="activityList2"
        :checkdData="checkdData"
        @cancelHDdialog="setZBDialog = false" 
        @sumbitForm="sumbitForm"
        :visible="setZBDialog"/>

        <el-dialog
            title="选择指标" :visible.sync="indexTypeDialog.show"
            width="30%" :before-close="handleClose"  append-to-body>
            <div class="IndexType">
                <div v-if="indexTypeDialog.arr.includes(1)" @click="filterIndexTpye(1)">通用指标</div>
                <div v-if="indexTypeDialog.arr.includes(2)" @click="filterIndexTpye(2)">星星指标</div>
                <div v-if="indexTypeDialog.arr.includes(3)" @click="filterIndexTpye(3)">问卷</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="indexTypeDialog.show = false">取 消</el-button>
               
            </span>
            </el-dialog>
    </el-dialog> 
</template>

<script>
import Dialog from './tc/setHDDialog2.vue'
export default {
    components:{
        Dialog
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        dialogTitle: String,
        formData: {
            type: Object,
            default: function() {
            return {};
            }
        },
        IndexList: {
            type: Array,
            default() {
            return [];
            }
        },
    },
    model: {
        prop: "visible",
        event: "change"
    },
    data() {
        return {
            value: false,
            form:{},
            indexTypeDialog:{
                show:false,
                value:1,
                arr:[1,2,3]
            },
            rules:{
                PXH:[
                    { required: true, message: "请输入排序号", trigger: "change" }
                ],
                KHXM:[
                    {required: true, message: "请输入考核项目", trigger: "change"}
                ],
                FZ:[
                    {required: true, message: "请输入分值", trigger: "change"}
                ],
                // DLZB:[
                //     {required: true, message: "请选择定量指标", trigger: "change"}
                // ],
                // DXZB:[
                //     {required: true, message: "请选择定性指标", trigger: "change"}
                // ],

            },
            formLabelWidth:'20%',
            dialogTitle2: "定量指标",
            setZBDialog:false,
            user:'',
            activityList:[],
            activityList2:[],
            dialogActicity1:[], //第1个弹窗返回的勾选列表
            dialogActicity2:[], //第2个弹窗返回的勾选列表
            checkdData:{}
        }
    },
    created(){
        this.user = JSON.parse(localStorage.getItem("userinfo"));
    },
    mounted(){
    },
    methods:{
        handleClose(){
            this.indexTypeDialog.show = false
        },
        //形成闭环
        close(){
            this.$emit('canceldialog',{})
        },
        // 活动形式
        getHDXSarr(data){
            let arr = []
            switch (data) {
                case 1: //主评
                arr.push({pjlxname:'自评',pjlx:1})
                break;
                case 2: //互评
                arr.push({pjlxname:'互评',pjlx:2})
                break;
                case 3:
                arr.push({pjlxname:'自评',pjlx:1},{pjlxname:'互评',pjlx:2})
                break;
                case 4: //主评和自评
                arr.push({pjlxname:'主评',pjlx:4})
                break;
                case 5: 
                arr.push({pjlxname:'自评',pjlx:1},{pjlxname:'主评',pjlx:4})
                break;
                case 6: 
                arr.push({pjlxname:'互评',pjlx:2},{pjlxname:'主评',pjlx:4})
                break;
                case 7: 
                arr.push({pjlxname:'自评',pjlx:1},{pjlxname:'互评',pjlx:2},{pjlxname:'主评',pjlx:4})
                break;
            }
            return arr
        },
        
        //打开指标弹窗 
        handleZBSet(type){
          
            this.checkdData = type ===1 ? this.dialogActicity1[0] : this.dialogActicity2[0]
            this.dialogTitle2 = type == 1?'定量指标':'定性指标'
            this.activityList2 = type == 1? this.IndexList.filter(item=>item.LX == 1):this.IndexList.filter(item=>item.LX == 2)
            if(type == 2){
             this.indexTypeDialog.arr =   this.activityList2.reduce((pre,cur)=>{
                    if(!pre.includes(cur.LB)){
                        pre.push(cur.LB)
                    }
                    return pre
                },[])
            if( this.indexTypeDialog.arr.length == 1){
                this.activityList2 = this.activityList2.filter(i=>this.indexTypeDialog.arr.includes(i.LB))

            this.setZBDialog = true
            }else if(this.indexTypeDialog.arr.length > 1){
                this.indexTypeDialog.show=true
            }
            }else  this.setZBDialog = true
        },
        
        cancel(){
            this.$confirm("确认关闭？").then(() => {
                this.$emit("canceldialog");
            }).catch(()=>{});
        },
        confirm(){
            this.$refs.form.validate((valid)=>{
                if (valid) {       
                    
                    
                    this.$emit("confirmdialog",{
                        Id:this.form.Id,
                        KHXM:this.form.KHXM,
                        DLGLZB: this.dialogActicity1.Id,
                        DLGLZBLB: this.dialogActicity1.LB,
                        DXGLZB: this.dialogActicity2.Id,
                        DXGLZBLB: this.dialogActicity2.LB,
                        SSXX: this.user.SSZZId,
                        CJR: this.user.Id,
                        FZ :Number(this.form.FZ),
                        PXH : Number(this.form.PXH),
                    }
                    );
                    this.dialogActicity1 = []
                    this.dialogActicity2 = []
                    this.setZBDialog = false
                }else{
                    this.$message.error('请完整填写表单！')
                }
            })
        },
        sumbitForm({type,a}){
            console.log(type,a);
            this.setZBDialog = false
            if(!type)return
            let keyObj ={
                1:'dialogActicity1',
                2:'dialogActicity2'
            }
            if(a){
                this[keyObj[type]] =a[0]
                if(type ===1) {
                    this.form.DLZB= a[0].ZBMC
                }else {
                    this.form.DXZB = a[0].ZBMC
                }
            }else {
                this.form.DLZB= ''
                this.form.DXZB = ''
                this[keyObj[type]] =[]
            }
            
        },
        //筛选指标类型
        filterIndexTpye(type){

              this.activityList2 = this.activityList2.filter(i=>i.LB == type)
              this.indexTypeDialog.show = false
              this.setZBDialog = true
        }
    },
    watch: {
        visible(val) {
            this.value = val;
            
            if(val){
              this.$set(this, "form", JSON.parse(JSON.stringify(this.formData)));
              this.sumbitForm({type:1,a:[
             {   Id:this.form.DLGLZB,
               LX:this.form.DLGLZBLB,
               LB:this.form.DLGLZBLB,
               lable:this.form.DLGLZBMC,
               ZBMC:this.form.DLGLZBMC}
              ]
               
              })
              this.sumbitForm({type:2,a:[{
                Id:this.form.DXGLZB,
                LX:this.form.DXGLZBLB,
                LB:this.form.DXGLZBLB,
                lable:this.form.DXGLZBMC,
                ZBMC:this.form.DXGLZBMC
              }]})
            }
            if (this.$refs.form) {
                console.log('充值');
                this.$refs.form.resetFields()
            }
            // this.$nextTick(()=>{
            //     console.log('充值1');
            //     this.$refs.form.resetFields()
            // })
        }
    }
    
}
</script>

<style lang="scss" scoped>
.IndexType{
   
    div{
        margin: 5px;
        cursor: pointer;
    }
}
.hd-name{
    font-size: 14px;font-weight: 600;
}
.HDdialog{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .item{
        margin: 8px 0;
        display: flex;
        flex-direction: row;

    }
    .dialog-footer{
        display: flex;
        justify-content: flex-end;
    }
    
}
</style>