<template>
  <div>
    <div style="padding: 40px;">
      <div class="cont-whole-box">
        <div class="leftrig-box">
          <div class="tab-heng-box margno-t">
            <div class="table-er-box">
              <div class="table-er-butt" @click="handleAdd()">
                <span class="table-er-buttspan">新增</span>
              </div>
              <div
                class="table-er-butt"
                v-if="!editstatus"
                @click="handleEdit()"
              >
                <span class="table-er-buttspan">开启编辑</span>
              </div>
              <div
                class="table-er-butt"
                v-if="editstatus"
                @click="handleBatchSave()"
              >
                <span class="table-er-buttspan">保存并关闭编辑</span>
              </div>
              <span class="table-er-buttspan" style="font-size:14px;"
                >类型：</span
              >
              <el-select
                @change="loaddata"
                v-model="sjlx"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="lx in sjlxlist"
                  :key="lx.label"
                  :label="lx.label"
                  :value="lx.value"
                ></el-option>
              </el-select>
              <span class="table-er-buttspan" style="font-size:14px;"
                >年级：</span
              >
              <el-select
                @change="loaddata"
                v-model="nj"
                placeholder="请选择年级"
              >
                <el-option
                  v-for="nj in njlist"
                  :key="nj.label"
                  :label="nj.label"
                  :value="nj.value"
                ></el-option>
              </el-select>
              <span class="table-er-buttspan" style="font-size:14px;"
                >性别：</span
              >
              <el-select
                @change="loaddata"
                v-model="xb"
                placeholder="请选择性别"
              >
                <el-option
                  v-for="xb in xblist"
                  :key="xb.label"
                  :label="xb.label"
                  :value="xb.value"
                ></el-option>
              </el-select>
              <div
                style="font-size: 14px;display: inline-block;margin-top:10px; float: right;"
              >
                <span
                  style="color: #14d089;margin-left: 5px;cursor: pointer;"
                  @click="downloadlist"
                  >下载全部</span
                >
                <span
                  @click="dialogImportVisible = true"
                  style="color: #14d089;margin-left: 5px;cursor: pointer;"
                  >上传数据</span
                >
              </div>
            </div>
            <el-table :data="djlist" highlight-current-row style="width: 100%;">
              <el-table-column
                align="center"
                label="序号"
                type="index"
                width="50"
              >
              </el-table-column>
              <el-table-column align="center" property="NJ" label="年级">
                <template slot-scope="scope">
                  <el-select
                    v-if="editstatus"
                    style="width: 100%;"
                    v-model="scope.row.NJ"
                    placeholder="请选择年级"
                  >
                    <el-option
                      v-for="nj in njlist"
                      :key="nj.label"
                      :label="nj.label"
                      :value="nj.value"
                    ></el-option>
                  </el-select>
                  <span v-else>{{ scope.row.NJ }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="性别">
                <template slot-scope="scope">
                  <el-select
                    v-if="editstatus"
                    style="width: 100%;"
                    v-model="scope.row.XSXB"
                    placeholder="请选择性别"
                  >
                    <el-option
                      v-for="xb in xblist"
                      :key="xb.label"
                      :label="xb.label"
                      :value="xb.value"
                    ></el-option>
                  </el-select>
                  <div v-else>
                    <span v-if="scope.row.XSXB == '1'">
                      男
                    </span>
                    <span v-else>
                      女
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                property="KSZS"
                label="开始指数(>=)"
              >
              </el-table-column>
              <el-table-column
                align="center"
                property="JSZS"
                label="结束指数(<=)"
              >
              </el-table-column>
              <el-table-column align="center" property="DF" label="得分">
              </el-table-column>
              <el-table-column align="center" property="DJMC" label="等级名称">
                <template slot-scope="scope">
                  <el-select
                    v-if="editstatus"
                    style="width: 100%;"
                    v-model="scope.row.DJMC"
                    placeholder="请选择等级名称"
                  >
                    <el-option
                      v-for="dj in djmclist"
                      :key="dj"
                      :label="dj"
                      :value="dj"
                    ></el-option>
                  </el-select>
                  <span v-else>{{ scope.row.DJMC }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleDelete(scope.row.Id)"
                    style="color: red;"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagin-box">
              <div class="pagin-wz">
                <Pager
                  :pagerInfo="pagerInfo"
                  @changeIndex="handleCurrentChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="edittitle"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleEditClose"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="120px"
        style="width: 350px; display: inline-block;"
        :rules="rules"
        :model="tzsjdfinfo"
        ref="editbbform"
        :inline="true"
      >
        <el-form-item label="类型：">
          <el-select
            style="width: 100%;"
            v-model="tzsjdfinfo.SJLX"
            placeholder="请选择类型"
          >
            <el-option
              v-for="lx in sjlxlist"
              :key="lx.label"
              :label="lx.label"
              :value="lx.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级：">
          <el-select
            style="width: 100%;"
            v-model="tzsjdfinfo.NJ"
            placeholder="请选择年级"
          >
            <el-option
              v-for="nj in njlist"
              :key="nj.label"
              :label="nj.label"
              :value="nj.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别：">
          <el-select
            style="width: 100%;"
            v-model="tzsjdfinfo.XSXB"
            placeholder="请选择性别"
          >
            <el-option
              v-for="xb in xblist"
              :key="xb.label"
              :label="xb.label"
              :value="xb.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始指数：" prop="KSZS">
          <el-input-number
            :precision="2"
            v-model="tzsjdfinfo.KSZS"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="结束指数：" prop="JSZS">
          <el-input-number
            :precision="2"
            v-model="tzsjdfinfo.JSZS"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="得分：" prop="DF">
          <el-input-number
            v-model="tzsjdfinfo.DF"
            :max="100"
            :step="1"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="等级名称：" prop="DJMC">
          <el-select
            style="width: 100%;"
            v-model="tzsjdfinfo.DJMC"
            placeholder="请选择等级名称"
          >
            <el-option
              v-for="dj in djmclist"
              :key="dj"
              :label="dj"
              :value="dj"
            ></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item>
          <div style="width:350px;text-align:center;">
            <el-button @click="handleEditClose()">取 消</el-button>
            <el-button type="primary" @click="handleConfirm()">确 定</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="导入记录"
      :visible.sync="dialogImportVisible"
      width="30%"
      :before-close="handleImportClose"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="upload"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <a
            :href="`${publicPath}template/体质数据设置导入模板.xlsx`"
            class="essay-bt-textbunn"
            target="_blank"
            style="margin-left:0!important;"
            >体质数据设置导入模板下载</a
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportClose()">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue";
import XLSX from "xlsx";
export default {
  components: {
    Pager,
  },
  created() {
    var tempuser = localStorage.getItem("userinfo");
    if (tempuser) {
      var user = JSON.parse(tempuser);
      this.cjr = user.Id;
      this.token = user.Token;
    }
  },
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return "/file/file/fileservice/UploadFile?token=" + this.token;
    },
  },
  mounted: function() {
    this.loaddata();
  },
  methods: {
    upload(file) {
      let files = { 0: file.raw };
      this.readExcel(files);
    },
    generateData({ results }) {
      //for (let cell of results) {
      //  for (let item in cell) {
      //    if (!isNaN(Number(cell[item]))) {
      //      cell[item] = Number(cell[item]);
      //    }
      //  }
      //}
      this.excelData.results = results;
    },
    readExcel: function(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        this.generateData({ results });
        this.submitimportdata(results);
        this.$refs.upload.value = "";
      };
      fileReader.readAsArrayBuffer(files[0]);
    },
    submitimportdata: function(readdata) {
      if (readdata.length <= 0) {
        this.$message({
          type: "info",
          message: "上传文件中无正确数据!",
        });
        return;
      }
      this.importdata = [];
      for (var i = 0; i < readdata.length; i++) {
        var obj = {
          NJ: this.nj,
          SJLX: this.sjlx,
          XSXB: readdata[i].性别,
          KSZS: readdata[i].开始指数,
          JSZS: readdata[i].结束指数,
          DF: readdata[i].得分,
          DJMC: readdata[i].等级名称,
        };
        this.importdata.push(obj);
      }
      this.$api.post(
        "v1/physicalquality/ImportPhysicalQualityRule",
        {
          DataList: this.importdata,
          CJR: this.cjr,
          token: this.token,
        },
        (r) => {
          if (r.Code == 0) {
            this.$message({
              type: "info",
              message: r.Message,
            });
            this.loaddata();
          } else {
            this.$message({
              type: "info",
              message: r.Message,
            });
            return;
          }
        }
      );
    },
    handleImportClose: function() {
      this.dialogImportVisible = false;
    },
    downloadlist: function() {
      this.$api.post(
        "v1/physicalquality/DownLoadPhysicalQualityRule",
        {
          FitnessTypes: this.sjlx,
          Grade: this.nj,
          token: this.token,
        },
        (r) => {
          if (r.Code == 0) {
            window.open(r.Message, "_blank");
          } else {
            this.$message({
              type: "info",
              message: r.Message,
            });
          }
        }
      );
    },
    // 批量保存
    handleBatchSave() {
      if (this.djlist.length <= 0) {
        this.$message({
          type: "info",
          message: "无可保存数据!",
        });
        return;
      }
      this.$api.post(
        "v1/physicalquality/SavePhysicalQualityRule",
        {
          DataList: this.djlist,
          CJR: this.cjr,
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            this.$message({
              type: "info",
              message: "保存成功!",
            });
            this.loaddata();
          } else {
            this.$message({
              type: "info",
              message: "保存失败!",
            });
          }
        }
      );
    },
    // 保存
    handleConfirm() {
      if (this.tzsjdfinfo.KSZS > this.tzsjdfinfo.JSZS) {
        this.$message({
          type: "info",
          message: "开始指数不能大于结束指数!",
        });
        return;
      }
      this.$refs.editbbform.validate((valid) => {
        if (valid) {
          this.$api.post(
            "v1/physicalquality/AddPhysicalQualityRule",
            {
              XSTZSJDFInfo: this.tzsjdfinfo,
              token: this.token,
            },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "info",
                  message: "保存成功!",
                });
                this.$refs.editbbform.resetFields();
                this.dialogVisible = false;
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "保存失败!",
                });
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    // 关闭
    handleEditClose() {
      this.$refs.editbbform.resetFields();
      this.dialogVisible = false;
    },
    handleAdd: function() {
      this.editstatus = false;
      this.dialogVisible = true;
      this.tzsjdfinfo.SJLX = this.sjlx;
      this.tzsjdfinfo.NJ = this.nj;
      this.tzsjdfinfo.KSZS = 0;
      this.tzsjdfinfo.JSZS = 0;
      this.tzsjdfinfo.DF = 0;
      this.tzsjdfinfo.CJR = this.cjr;
      this.tzsjdfinfo.DJMC = "";
      this.tzsjdfinfo.XSXB = 1;
    },
    handleEdit: function() {
      this.editstatus = true;
    },
    loaddata: function() {
      this.$api.post(
        "v1/physicalquality/GetPhysicalQualityRuleList",
        {
          FitnessTypes: this.sjlx,
          Grade: this.nj,
          Gender: this.xb,
          PagerInfo: this.pagerInfo,
          token: this.token,
        },
        (r) => {
          if (r.Code === 0) {
            this.editstatus = false;
            this.djlist = [];
            this.djlist = r.Target;
            this.pagerInfo.totalCount = r.PagerInfo.RowCount;
          }
        }
      );
    },
    // 翻页
    handleSizeChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    // 翻页查询
    handleCurrentChange(val) {
      this.pagerInfo.pageIndex = val;
      this.loaddata();
    },
    handleDelete: function(id) {
      this.$confirm("确定要删除该数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.post(
            "v1/physicalquality/DeletePhysicalQualityRule",
            { Id: id, YHId: this.cjr, token: this.token },
            (r) => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.loaddata();
              } else {
                this.$message({
                  type: "info",
                  message: "删除失败!",
                });
                this.loaddata();
              }
            }
          );
        })
        .catch(() => {});
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      isLogin: true,
      edittitle: "",
      djlist: [],
      ltmcarr: [],
      sjlx: 1,
      nj: 1,
      xb: 1,
      ssxx: "",
      cjr: "",
      editstatus: false,
      token: "",
      inputValue: "",
      editid: "",
      dialogImportVisible: false,
      dialogVisible: false,
      innerVisible: false,
      inputVisible: false,
      pagerInfo: {
        pageIndex: 1,
        pageSize: 20,
        totalCount: 0,
      },
      abjcx: false,
      dxqcx: false,
      tzsjdfinfo: {
        SJLX: 1,
        NJ: 1,
        KSZS: 0,
        JSZS: 0,
        DF: 0,
        CJR: "",
        DJMC: "",
        XSXB: 1,
      },
      xblist: [
        { label: "男", value: 1 },
        { label: "女", value: 2 },
      ],
      njlist: [
        { label: "1年级", value: 1 },
        { label: "2年级", value: 2 },
        { label: "3年级", value: 3 },
        { label: "4年级", value: 4 },
        { label: "5年级", value: 5 },
        { label: "6年级", value: 6 },
        { label: "7年级", value: 7 },
        { label: "8年级", value: 8 },
        { label: "9年级", value: 9 },
      ],
      sjlxlist: [
        { label: "1.BMI指数", value: 1 },
        { label: "2.肺活量", value: 2 },
        { label: "3.50米跑", value: 3 },
        { label: "4.坐位体前屈", value: 4 },
        { label: "5.跳绳", value: 5 },
        { label: "6.跳绳加分", value: 6 },
        { label: "7.一分钟仰卧起坐", value: 7 },
        { label: "8.50*8往返跑", value: 8 },
      ],
      djmclist: [
        "优秀",
        "良好",
        "及格",
        "不及格",
        "正常",
        "低体重",
        "超重",
        "肥胖",
      ],
      rules: {},
      excelData: {
        results: null,
      },
    };
  },
};
</script>
